export const letterSetupTabList = [
    {
        label: "License Request Confirmation",
        value: 1,
    },
    {
        label: "Quote Requests",
        value: 2,
    },
    {
        label: "Check Transmittals",
        value: 3,
    },
    {
        label: "Ad Card Language",
        value: 4,
    },
]
export const letterRequestTabList = [
    {
        label: "'Issue To' Language",
        value: 1,
    },
    {
        label: "User Defined",
        value: 2,
    }
]
export const postPayload = {
    "letter_language_id": null,
    "text_type": null,
    "production_entity_id": null,
    "letter_type_id": null,
    "term_id": null,
    "description": null,
    "user_defined": 0,
    "is_active": null,
    "show_season_id": null
};
export const quoteRequestTabList = [
    {
        label: "Exec Card Language",
        value: 1,
    },
    {
        label: "Pilot Language",
        value: 2,
    },
    {
        label: "Pre-air Marketing",
        value: 3,
    },
    {
        label: "Quote Extension",
        value: 4,
    },
    {
        label: "Include Proposed Fees",
        value: 5,
    },
    {
        label: "User Defined",
        value: 6,
    }
]
export const checkTransmittalTabList = [{ label: "User Defined", value: 1 }]

export const adCardData = {
    "is_adcard": false,
    "letterType": "M",
    "rights": null,
    "selectedLetterType": {},
    "selectedTerm": {}
}