
export const AllBatchHeading = {
    headings: [
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Composition",
            icon: "",
            width: "20%",
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%",
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "8%",
        },
    ],
    dataNodes: ["batch_no", "composition", "payee_company", "total_fee"],
    primaryKey: "clearance_check_request_id",
    highlightRow:["generated_date"]
}
export const NewBatchesHeading = {
    headings: [
        {
            headingLabel: "Select",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Composition",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "8%",
        },
    ],
    dataNodes: ["check","batch_no", "composition", "payee_company","total_fee"],
    primaryKey: "clearance_check_request_id",
    highlightRow:["generated_date"]
}
export const batchNumberHeading = {
    headings: [
        {
            headingLabel: "Select",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Composition",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Show Cost",
            icon: "",
            width: "8%"
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "8%",
        },
    ],
    dataNodes: ["check","batch_no", "composition", "payee_company", "show_cost", "total_fee"],
    primaryKey: "clearance_check_request_id"
}

export const AllMTBatchesHeading = {
    headings: [
        {
            headingLabel: "Bch",
            icon: "",
            width: "6%",
        },
        {
            headingLabel: "Eps",
            icon: "",
            width: "6%",
        },

        {
            headingLabel: "Composition",
            icon: "",
            width: "15%",
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%",
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "8%",
        },
    ],
    dataNodes: ["batch_no", "eps_count","composition", "payee_company", "total_fee"],
    primaryKey: "main_title_check_request_id",
    highlightRow:["generated_date"]
}
export const NewMTBatchesHeading = {
    headings: [
        {
            headingLabel: "Select",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Bch",
            icon: "",
            width: "6%",
        },
        {
            headingLabel: "Eps",
            icon: "",
            width: "6%",
        },

        {
            headingLabel: "Composition",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "8%",
        },
    ],
    dataNodes: ["check","batch_no", "eps_count", "composition", "payee_company", "total_fee"],
    primaryKey: "main_title_check_request_id",
    highlightRow:["generated_date"]
}
export const MTBatchNumberHeading = {
    headings: [
        {
            headingLabel: "Select",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Composition",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Show Cost",
            icon: "",
            width: "8%"
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "8%",
        },
    ],
    dataNodes: ["check","batch_no", "composition", "payee_company", "show_cost", "total_fee"],
    primaryKey: "main_title_check_request_id"
}
export const businessAffairsHeading = {
    headings: [
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Episode",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Check Amt",
            icon: "",
            width: "8%"
        },
        {
            headingLabel: "Category",
            icon: "",
            width: "8%",
        },
        {
            headingLabel: "Cost Center",
            icon: "",
            width: "10%",
        },
    ],
    dataNodes: ["batch_no", "episode_name", "payee_company", "check_amount", "category_name","cost_center_code"],
    primaryKey: "show_season_music_supervisor_check_requests_id"
}
export const businessAffairsNumberHeading = {
    headings: [
        {
            headingLabel: "Select",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Episode",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Check Amt",
            icon: "",
            width: "8%"
        },
        {
            headingLabel: "Category",
            icon: "",
            width: "8%",
        },
        {
            headingLabel: "Cost Center",
            icon: "",
            width: "10%",
        },
    ],
    dataNodes: ["check","batch_no", "episode_name", "payee_company", "check_amount", "category_name","cost_center_code"],
    primaryKey: "show_season_music_supervisor_check_requests_id"
}


export const dynamicColumns = [
    {value : 'show_cost', heading : 'Show Cost'},
    {value : 'perp_cost', heading : 'Perp Cost'},
    {value : 'am_cost', heading : 'AM Cost'},
    {value : 'video_cost', heading : 'Video'},
    {value : 'television_cost', heading : 'Television'},
    {value : 'one_yr_cost', heading : '1_YR_Download'},
    {value : 'six_yr_home_cost', heading : '6_YR_Home'},
    {value : 'perp_home_cost', heading : 'Perp_Home'},
]

export const dynamicColumnsEp = [
    {value : 'show_cost', heading : 'Show Cost'},
    {value : 'coa_cost', heading : 'COA'},
    {value : 'perp_cost', heading : 'Perp Cost'},
    {value : 'am_cost', heading : 'AM Cost'},
    {value : 'video_cost', heading : 'Video'},
    {value : 'television_cost', heading : 'Television'},
    {value : 'one_yr_cost', heading : '1_YR_Download'},
    {value : 'six_yr_home_cost', heading : '6_YR_Home'},
    {value : 'perp_home_cost', heading : 'Perp_Home'},
]