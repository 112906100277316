export const costSplitsConfig = {
    headings: [
        {
            headingLabel: "CostCenter",
            icon: "",
            width: "63%",
        },
        // {
        //     headingLabel: "Pct",
        //     icon: "",
        //     width: "17%",
        //     inputType: "checkbox"
        // },
        {
            headingLabel: "Cost",
            icon: "",
            width: "10%"
        },
    ],
    dataNodes: ["cost_center_name", "cost"],
    primaryKey: "season_cost_split_id",
}
export const budgetConfig = {
    headings: [
        {
            headingLabel: "CostCenter",
            icon: "",
            width: "63%",
        },
        {
            headingLabel: "Budget",
            icon: "",
            width: "17%",
            // inputType: "checkbox"
        },
        {
            headingLabel: "Actual",
            icon: "",
            width: "10%",
            inputType: "text",
            
        },
    ],
    dataNodes: ["cost_center_name", "budget","cost"],
    primaryKey: "show_season_clearance_budgets_id",
    inlineEdits:[{dataNode : "cost",width : "10%",inputType : "number",}]
}

export const songList = [
    // {
    //     id: 1,
    //     cost_center: "4568",
    //     pct: "75%",
    //     cost: "$28.35",
    // },
    // {
    //     id: 2,
    //     cost_center: "4568",
    //     pct: "75%",
    //     cost: "$28.35",
    // },
    
    
]
export const initialCostAllocationDetails = {
    season_id: null,
    term_id: null,
    std: null,
    lib: null,
    mt: null,
    ad_card: null,
    include_hv:0
}