export const unionConfig = {
    headings: [
        {
            headingLabel: "Active",
            width: "10%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Union",
            width: "85%",
            inputType: "text"
        },

    ],
    dataNodes: ["is_active", "name"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "9%"
        },
        {
            dataNode: "name",
            width: "84%"
        }
    ],
}