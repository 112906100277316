import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import AutoCompleteSearch from "../../../SharedComponents/AutoCompleteSearch/AutoCompleteSearch";
import "../LookupMaintenance.scss";
import BasicLabel from "../../../SharedComponents/BasicLabel/BasicLabel";
import SearchSelectField from "../../../SharedComponents/SearchSelectField";
import BasicTextField from "../../../SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import TableComponent from "../../../SharedComponents/Table";
import {
  writersConfig,
  writersIntialDetails,
} from "../CueSheetPublisher/Config";
import TablePagination from "@material-ui/core/TablePagination";
import SelectField from "../../../SharedComponents/SelectField/SelectField";
import * as Constants from "../../../../constants/constants";
import ClearTrackService from "../../../../services/service";
import { CircularProgress } from "@material-ui/core";
import { handlePaginationList } from "./../../../../Common/TableHelper";
import { withUserContext } from "../../../../contexts/UserContext";
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import {
  updateTableSortConfigObject,
  tableSortList,
} from "../../../../Common/TableHelper";
import axios from "axios";

class Writers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      writersDetails: { ...writersIntialDetails },
      affilationOptions: [],
      isSubmit: false,
      renderList: [],
      // rowsPerPage: 10,
      page: 1,
      isSortByAsc: false,
      sortCount: 0,
      isDelete: false,
      postInitiated: false,
      currentList: [],
      showSeasonId: null,
      openEditSeasonModal: false,
      division_id: null,
      showSeasonPermission: [],
      sortBy: "Value",
      sortCount: 0,
      existingWriters: [],
      allEntities: [],
      selectedEntity: null,
      allWritersList: [],
      isFetchingWriters: false,
      searchCancelToken: null,
      totalRecord: 0,
    };
  }

  componentDidMount() {
    this.getAffilation();
    this.getWriters();
  }

  getWriterDetails = (id) => {
    this.setState({ isLoading: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/clearanceRecordList?entity=WRITER&entity_id=${id.writer_id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        this.handleEdit("writer_name", id.writer_name);
        this.handleEdit("writer_id", id.writer_id);
        this.handleEdit("affiliation_name", id.affiliation_name);
        this.handleEdit("affiliation_id", id.affiliation_id);
        let data = { ...this.state.writersDetails };
        data.writer_name = id.writer_name;
        data.writer_id = id.writer_id;
        data.affiliation_name = id.affiliation_name;
        data.affiliation_id = id.affiliation_id;
        let writerDetailList = [...response?.data];
        writerDetailList?.map((item, index) => {
          writerDetailList[index].show_season =
            item?.show_name && item?.season_name
              ? `${item?.show_name} / ${item?.season_name}`
              : !item?.show_name && item?.season_name
              ? `${item?.season_name}`
              : item?.show_name && !item?.season_name
              ? `${item?.show_name}`
              : "";
        });
        writerDetailList?.map((item) => {
          return (item.initial_fee = item.initial_fee
            ? "$" + parseFloat(item.initial_fee).toFixed(2)
            : null);
        });
        this.setState(
          {
            renderList: writerDetailList,
            isLoading: false,
            writersDetails: data,
          },
          () => {
            this.handleTableColumnClick("Show/Season");
          }
        );
      })
      .catch((err) => {
        console.log("Error in fetching Details:", err);
        this.setState({ isLoading: false });
      });
  };

  handleEdit = (field, value) => {
    this.setState((prevState) => ({
      writersDetails: {
        ...prevState.writersDetails,
        [field]: value,
      },
      postInitiated: false,
    }));
    if (field === "writer_id") {
      this.getWriterDetails(value);
    }
  };

  getAffilation = () => {
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        "/staticData?staticTable=lu_affiliation",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((response) => {
      let useList = response?.data?.map((item) => ({
        label: item.name,
        value: item.id,
        is_active: item.is_active,
      }));
      this.setState({ affilationOptions: useList });
    });
  };

  handleSubmit = (deleteFlag = 0) => {
    this.setState({ postInitiated: true });
    if (this.state.writersDetails?.writer_name && this.state.writersDetails?.affiliation_id) {
      this.setState({ postInitiated: false });
      let writerData = {
        ...this.state.writersDetails,
        is_active: 1,
       // table_name: "lu_performer",
      };
      if (deleteFlag === 1) {
        writerData.is_delete = 1;
      } else {
        this.setState({ isSubmit: true });
      }
      ClearTrackService.postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/writer`,
        writerData,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.props.toastMessageNotification(true, "fail");
            this.setState({ isSubmit: false });
          } else {
            this.props.toastMessageNotification(true, "success");
            this.setState({ isSubmit: false });
            this.setState({
              writersDetails: { ...writersIntialDetails },
              renderList: [], page: 1
            },()=>{this.getWriters(false)});
          }
          this.setState({ isDelete: false });
        },
        (err) => {
          this.setState({ isSubmit: false, isDelete: false });
          this.props.toastMessageNotification(true, "fail");
        }
      );
    }
  };

  handleDeleteWriter = () => {
    this.setState({ isDelete: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/checkRelationalTables?tableName=writer&tableId=${this.state.writersDetails?.writer_id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((res) => {
      if (res?.data[0]?.status === "SUCCESS") {
        this.handleSubmit(1);
      } else {
        this.props?.fieldChanged(true, "fail");
        this.setState({ isDelete: false });
      }
    }),
      (err) => {
        this.props?.fieldChanged(true);
        this.setState({ isLoading: false, isDelete: false });
      };
  };

  navigationCallback = (obj, node) => {
    if (obj?.show_season_id && node === "show_season") {
      window.open(`/showseason/${obj.show_season_id}`, "_blank");
    } else if (obj?.show_season_episode_id && node === "episode_number") {
      window.open(
        `/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`,
        "_blank"
      );
    }
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      });
    } else
      this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      });
  };

  handleTableSort = (colName) => {
    this.setState(
      {
        isSortByAsc: this.state.sortCount % 2 !== 0 ? true : false,
        config: updateTableSortConfigObject(
          writersConfig,
          this.state.sortCount % 2 !== 0 ? true : false,
          this.state.sortBy,
          colName
        ),
      },
      () => {
        this.setState({
          sortBy: colName,
          renderList: tableSortList(
            "Name",
            this.getSortNode(colName),
            this.state.renderList,
            this.state.isSortByAsc
          ),
        });
      }
    );
  };

  getSortNode = (col) => {
    switch (col) {
      case "Show/Season":
        return "show_season";
      case "Episode#":
        return "episode_number";
      case "Song":
        return "song_title";
      case "Use":
        return "abbreviation";
      case "Status":
        return "status";
      case "Intial Fee":
        return "initial_fee";
    }
  };

  getWriters = (scrollLoad = false) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    this.setState({ isFetchingWriters: true });
    let postData = {
      entity_text: "WRITER",
      entity_search_text: this.state.searchString,
      page_count: 100,
      page_no: this.state.page,
    };
    let encryptTedStr = btoa(JSON.stringify(postData));
    ClearTrackService.getDataWithCancel(
      Constants.ClearTrackServiceBaseUrl +
        `/dashboardLookups?input==${encryptTedStr}`,
      cancelToken,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        this.setState({ totalRecord: response.data?.query_record_count });
        if (response.data?.records?.length > 0) {
          let allList = [];
          if (scrollLoad) {
            allList = [...this.state.allWritersList, ...response.data?.records];
          } else {
            allList = response.data?.records;
          }
          this.setState({
            allWritersList: allList,
            isFetchingWriters: false,
            renderList: [],
            writersDetails: JSON.parse(
              JSON.stringify({ ...writersIntialDetails })
            ),
          });
        } else {
          this.setState({
            writersDetails: JSON.parse(
              JSON.stringify({ ...writersIntialDetails })
            ),
            isFetchingWriters: false,
            renderList: [],
            allWritersList: [],
          });
        }
      })
      .catch((err) => {
        this.setState({ isFetchingWriters: false });
      });
  };

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="CueSheetContainer">
        <MDBRow>
          <MDBCol md={3}>
            <div>
              <BasicTextField
                id="writers"
                placeholder={"- Search by Writers Name -"}
                value={this.state.searchString}
                onChange={(e) => {
                  this.setState(
                    {
                      searchString:
                        e.target.value.length > 0 ? e.target.value : null,
                      page: 1,
                    },
                    this.getWriters
                  );
                }}
              />
            </div>
            <div
              className="existing-records"
              onScroll={(e) => {
                let totalScrollHeight = Math.floor(e.currentTarget.clientHeight)
                if (
                  [totalScrollHeight-1, totalScrollHeight, totalScrollHeight +1]?.includes(Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop)) &&
                  this.state.totalRecord != this.state.allWritersList.length
                ) {
                  this.setState(
                    (prevState) => ({
                      page: prevState.page + 1,
                    }),
                    () => {
                      this.getWriters(true);
                    }
                  );
                }
              }}
            >
              <>
                {this.state.allWritersList.length > 0 ? (
                  <>
                    {this.state.allWritersList?.map((item, index) => (
                      <div
                        key={index}
                        className="entity-item"
                        onClick={() => {
                          this.setState({
                            writersDetails: {
                              id: item?.writer_id,
                              name: item?.writer_name,
                            },
                          });
                          this.getWriterDetails(item);
                        }}
                      >
                        <span
                          className={`${
                            item.writer_id === this.state.writersDetails.id
                              ? "highlight-entity"
                              : ""
                          }`}
                        >
                          {item.writer_name}
                        </span>
                      </div>
                    ))}
                    {this.state.isFetchingWriters && (
                      <CircularProgress color="white" size={18} />
                    )}
                  </>
                ) : (
                  <span className="no-content">
                    {!this.state.isFetchingWriters && "No Data Found"}{" "}
                  </span>
                )}
              </>
            </div>
          </MDBCol>
          <MDBCol md={9}>
            <MDBRow className="mt-pd">
              <div className="text-btn">
                <MDBCol md={3}>
                  <BasicTextField
                    className="composer-name"
                    label="Selected Writers"
                    isMandatory={true}
                    disabled={
                      (this.state.writersDetails?.id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor'))

                    }
                    showMandatory={this.state.postInitiated || false}
                    placeholder={"- Selected Writers Name -"}
                    value={this.state.writersDetails?.writer_name}
                    onChange={(e) =>
                      this.handleEdit(
                        "writer_name",
                        e.target.value.length > 0 ? e.target.value : null
                      )
                    }
                  />
                </MDBCol>
                <MDBCol md={3}>
                  <div id="Affilation">
                    <SelectField
                      id="affilation"
                      label="Affiliation"
                      placeholder={"-Affilation -"}
                      isMandatory={true}
                      showMandatory={this.state.postInitiated || false}
                      value={this.state.writersDetails?.affiliation_id || null}
                      options={this.state.affilationOptions || []}
                      onChange={(e) =>
                        this.handleEdit("affiliation_id", e.target.value)
                      }
                    />
                  </div>
                </MDBCol>
                <MDBCol md={1}>
                {permissionValue[0].edit === 1 &&
                  <BasicButton
                    className={"save-btn-2"}
                    variant="contained"
                    text={
                      this.state.isSubmit ? (
                        <CircularProgress color="white" size={14} />
                      ) : (
                        " "
                      )
                    }
                    icon={"save"}
                    onClick={this.handleSubmit}
                  />
                }
                </MDBCol>
                {this.state.writersDetails?.writer_id ? (
                  <MDBCol md={1} className="mt-4">
                    <span className="add-prod-entity-btn">
                      <BasicButton
                        type="inline"
                        color="primary"
                        text={" "}
                        icon={"plus-circle"}
                        onClick={() =>
                          this.setState({
                            writersDetails: JSON.parse(
                              JSON.stringify({ ...writersIntialDetails })
                            ),
                            selectedEntity: null,
                            renderList: [],
                          })
                        }
                      />
                    </span>
                  </MDBCol>
                ) : null}
                 {permissionValue[0].delete === 1 &&
              <>
                {this.state.writersDetails?.writer_id ? (
                  <MDBCol md={2} className="delete-font mt-4">
                    {this.state.isDelete ? (
                      <CircularProgress size={18} />
                    ) : (
                      <span
                        className="delete-btn"
                        onClick={this.handleDeleteWriter}
                      >
                        Delete
                      </span>
                    )}
                  </MDBCol>
                ) : null}
                </>}
              </div>
            </MDBRow>
            {this.state.isLoading ? (
              <div className="contentLoader">
                <CircularProgress />
              </div>
            ) : (
              <MDBRow className="mt-2">
                <h6>Clearance Record</h6>
                <div id="table-bg-sync-cue">
                  <TableComponent
                    list={this.state.renderList || []}
                    className={"clearencRecord"}
                    config={writersConfig}
                    stickyHeader={true}
                    hyperLinkNavigationCallback={this.navigationCallback}
                    sortCallback={this.handleTableColumnClick}
                  />
                </div>
              </MDBRow>
            )}
            {this.state.openEditSeasonModal && (
              <MainViewModalComponent
                open={this.state.openEditSeasonModal}
                handleClose={() =>
                  this.setState({
                    showSeasonPermission: [],
                    openEditSeasonModal: false,
                    showSeasonId: null,
                    division_id: null,
                  })
                }
                headerText={"Show Details"}
                mode={"Edit"}
                modalName={"Edit ShowDetails"}
                show_season_id={this.state.showSeasonId}
                fetchData={() =>
                  this.setState({
                    showSeasonPermission: [],
                    openEditSeasonModal: false,
                    showSeasonId: null,
                    division_id: null,
                  })
                }
                division_id={this.state.division_id}
                permissionArr={this.state.showSeasonPermission}
              />
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(Writers);
