import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { withUserContext } from '../../../contexts/UserContext';
import TabsComponent from "../../SharedComponents/Tabs/Tabs"
import { costAllocationList } from "./config"
import HomeVideoOptionTable from "./HomeVideoOptionTable";
import InitialRightsTable from "./InitialRightsTable";


class CostAllocationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        renderList: [],
        tabValue:1,
        costAllowcationPermissions : [],
    };
    }

    componentDidMount() {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let costAllowcationPermissionsRecord = this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_group_name === "Cost Allocation Calculator/Table")
            this.setState({ costAllowcationPermissions: costAllowcationPermissionsRecord})
        }
    }
    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };
    renderComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <InitialRightsTable permission = {this.state.costAllowcationPermissions.find(item => item.permission_name === "Cost Allocation Table")}/>
                break;
            case 2:
                return <HomeVideoOptionTable permission = {this.state.costAllowcationPermissions.find(item => item.permission_name === "Cost Allocation Table")}/>
                break;
    }
}
  render() {
    //let permissionValue = this.state.costAllowcationPermissions.find(item => item.permission_name === "Cost Allocation Table")
    return (
      <MDBContainer fluid className="costAllocationContainer">
      <MDBRow className="tab-content">
                        <MDBCol md={12}>
                            <TabsComponent
                                tabList={costAllocationList}
                                value={this.state.tabValue}
                                onChange={(e, v) => this.handleTabChange(v)}
                            />
                        </MDBCol>
            </MDBRow>
            <MDBRow>
                {this.renderComponent()}
            </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(CostAllocationTable);
