export const CueSheetsTabConfig = {
    headings: [
        {
            headingLabel: "Episode",
            icon: "",
            width: "20%"
        },

        {
            headingLabel: "Cue Sheet for",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Airing",
            icon: "",
            width: "8%",
        },
        {
            headingLabel: "Submitted Date",
            icon: "",
            width: "11%"
        },
        {
            headingLabel: "Release Date",
            icon: "",
            width: "20%",
        },
        {
            headingLabel: "Status",
            icon: "",
            width: "9%"
        },
        {
            headingLabel: "Ack",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "XML",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "CS",
            icon: "",
            width: "5%"
        },
    ],
    dataNodes: ["episode", "cuesheet_for", "airing", "submitted_date", "released_date", "status", "ack", "xml", "cs"],
    primaryKey: "cue_sheet_header_id",
    buttonFields: [
        {
            column: "ack",
            icon: "",
            disabled: true
        },
        {
            column: "xml",
            icon: "",
            disabled: true
        },
        {
            column: "cs",
            icon: "",
            disabled: true
        },
    ],
    hyperlinks: ["episode"],
};
