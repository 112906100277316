import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import DateField from '../../SharedComponents/DateField/DateField';
import './Budget.scss';
import { getFormattedDate } from '../../../Common/Helper';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import { intialPilotHeaderDetails } from './Config';
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';
import messages from '../../../Common/Messages.json';

class PilotModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isPosting: false,
            // pilotHeaderDetails: intialPilotHeaderDetails
            pilotBudgetHeaderDetails: this.props?.pilotBudgetHeaderDetails,
            errorFlag: false,
            errorMsg: null
        };
    }

    // getPilotBudgetDetails = () => {
    //     this.setState({ isLoading: true })
    //     ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
    //         `/showSeasonPilotBudgetDetails?show_season_id=${this.props?.show_season_id}&apply_to=PILOT`,
    //         this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
    //         .then(response => {
    //             this.setState({ pilotHeaderDetails: response.data[0], isLoading: false })
    //         })
    //         .catch((err) => {
    //             this.setState({ isLoading: false });
    //         });
    // }

    handleSubmit = () => {
        this.setState({ isPosting: true });
        let postJson = JSON.parse(JSON.stringify(this.state?.pilotBudgetHeaderDetails));
        console.log("postJson", postJson);
        if (postJson?.script_date !== null && postJson?.script_date?.trim()?.length > 0) {
            this.setState({ errorFlag: false, errorMsg: null });
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonPilotBudgetHeader`,
                postJson,
                this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
                .then(
                    (response) => {
                        this.setState({ isPosting: false });
                        console.log("response", response)
                        if (response.data.error) {

                            this.props?.notificationComponent("error")
                        } else {
                            this.props?.notificationComponent("success")
                            this.props?.toggleRefresh();
                            this.props?.handleCloseModal()
                        }
                    },
                    (err) => {
                        this.setState({ isPosting: false });
                        this.props?.notificationComponent("error")
                    }
                )
        } else {
            this.setState({ isPosting: false, errorFlag: true, errorMsg: "Please fill 'Script Date' field." });
        }

    };
    handleOnChange = (field, value) => {
        this.setState({
            pilotBudgetHeaderDetails: {
                ...this.state.pilotBudgetHeaderDetails,
                [field]: value,
            },
        });
    };
    render() {
        return (
            <>
                <div className="pilotModal">
                    {this.state.errorFlag && <p className="errorMessage">{this.state.errorMsg}</p>}
                    <MDBRow>
                        <MDBCol md={8}>
                            <DateField
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                mandatoryWarning={messages.mandatoryWarning}
                                label={"Script Date"}
                                value={this.state?.pilotBudgetHeaderDetails?.script_date?.split("T")[0] || null}
                                onChange={(e) => this.handleOnChange("script_date", e.target.value.trim().length > 0 ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <DateField
                                label={"Preparation Date "}
                                value={this.state?.pilotBudgetHeaderDetails?.preparation_date?.split("T")[0] || null}
                                onChange={(e) => this.handleOnChange("preparation_date", e.target.value.trim().length > 0 ? e.target.value : null)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <BasicTextArea label={"Comments"}
                            placeholder={"Comments go here"}
                            value={this.state?.pilotBudgetHeaderDetails.comments || null}
                            onChange={(e) => this.handleOnChange("comments", e.target.value.trim().length > 0 ? e.target.value : null)}
                        />
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={4}></MDBCol>
                        <MDBCol md={8} className="saveCancelWrapper">
                            <div>
                                <BasicButton
                                    variant="contained"
                                    icon={this.state.isPosting ? " " : "save"}
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                    onClick={this.handleSubmit}
                                />
                            </div>
                            <div>
                                <BasicButton
                                    variant="outlined"
                                    icon={"times"}
                                    text=" "
                                    onClick={() => this.props.handleCloseModal()}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            </>
        )
    }
}
export default withUserContext(PilotModal);