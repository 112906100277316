import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from 'mdbreact';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextArea from '../../../components/SharedComponents/BasicTextArea/BasicTextArea';
import BasicTextField from '../../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import TableComponent from '../../SharedComponents/Table';

class PublicDomainSong extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            songDetails: {}
        }
    }

    handleAddNewItem = (newItem) => {
        this.props?.handleAddNewItem("pd-composers", newItem)
    }

    handleInlineEdits = (id, dataItem) => {
        this.props?.handleInlineEdits("pd-composers", id, dataItem)
    }

    saveInlineEdits = (dataItem, index, field, newDataItem) => {
        this.props?.saveInlineEdits("pd-composers", dataItem, index, field, newDataItem)
    }

    render() {
        return (
            <MDBContainer fluid className="public-domain-song-container p-0">
                <MDBRow>
                    <MDBCol md={4}>
                        <MDBRow>
                            <BasicTextField
                                label="Song Title"
                                isMandatory={true}
                                showMandatory={this.props?.postInitiated}
                                mandatoryWarning={this.props?.messages.mandatoryWarning}
                                value={this.props?.songDetails?.song_title}
                                onChange={(e) => this.props?.handleChange("song_title", e.target.value)}
                            />
                        </MDBRow>
                        {
                            this.props?.songDetails?.music_type?.map((musicType, index) => (
                                (musicType.music_type_is_delete === 0 ? <>
                                    <MDBCol md={6} className="col-content d-inline-block" id="musicType">
                                        <SelectField
                                            id={"music-type-select"}
                                            label={index === 0 ? "Music Type" : ""}
                                            defaultMenuText={"-Select-"}
                                            options={this.props?.musicTypeOptions || []}
                                            value={musicType?.music_type_id || null}
                                            onChange={(e, value) => this.props.handleListEdit("music_type_id", e.target.value, musicType, "music_type")}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="actionButons2 d-inline-block">
                                        <MDBRow className="actionsRow">
                                            <MDBCol md={1}>
                                                {
                                                    (musicType.music_type_name?.length > 0) ?
                                                        <RemoveCircleIcon
                                                            onClick={(e) =>
                                                                this.props?.removeField("music_type", musicType, index)
                                                            }
                                                        />
                                                        : null
                                                }
                                            </MDBCol>
                                            <MDBCol
                                                md={1}
                                                className={`${this.props?.songDetails?.music_type?.length === 1 ? 'addIconMargin' : ''}`}
                                            >
                                                {
                                                    (musicType.music_type_name?.length > 0 && index === (this.props?.songDetails?.music_type.length - 1)) ?
                                                        <AddCircleIcon
                                                            onClick={(e) =>
                                                                this.props?.addAdditionalField("music_type", index)
                                                            }
                                                        /> : null
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </> : null)
                            ))
                        }
                    </MDBCol>
                    <MDBCol md={2}>
                        <MDBRow className="IconSize">
                            <BasicLabel
                                text={"Sheet Music"}
                            />
                            <MDBRow className="IconHeight">
                                <MDBCol md={2}>  <i class="fas fa-paperclip"></i></MDBCol>
                                <MDBCol md={2}>  <i class="fas fa-exchange-alt"></i></MDBCol>
                            </MDBRow>
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id="song_is_do_not_use"
                                label="Do not Use"
                                align="end"
                                checked={this.props?.songDetails?.song_is_do_not_use}
                                onChange={(e) => this.props?.handleChange("song_is_do_not_use", e.target.checked)}
                            />
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={2}>
                        <MDBRow className='IconSize'>
                            <BasicLabel
                                text={"Lyrics"}
                            />
                            <i class="fas fa-upload"></i>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextArea
                            label={"Notes"}
                            rows={4}
                            value={this.props?.songDetails?.notes}
                            onChange={(e) => this.props?.handleChange("notes", e.target.value)}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="pd-composer-table mt1">
                    <MDBCol id="table-width" md={7}>
                        <TableComponent
                            id={"pd-composers"}
                            list={this.props?.songDetails?.pd_composer}
                            config={this.props?.tableConfig}
                            isLoading={false}
                            handleAddNewItem={this.handleAddNewItem}
                            editCallback={this.handleInlineEdits}
                            saveCallback={this.saveInlineEdits}
                            addItemToList={true}
                            searchString={(value) => this.props?.searchString("pdComposers", "PD_COMPOSER",value)}
                            
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default PublicDomainSong;