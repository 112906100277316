import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../../../SharedComponents/BasicTextField/BasicTextField";
import "../MusicEditorDetails.scss";
import { addressConfig, agentAddressConfig } from "../config";
import AddressComponent from "../../../EpisodeAndClearence/CommonAddressComponent";
import messages from '../../../../Common/Messages.json';
import { validateEmail, validateAllPhone, formatPhoneNumbers } from '../../../../Common/Helper';
import { withUserContext } from '../../../../contexts/UserContext';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import clearTrackService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
class AgentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      composerDetails: this.props?.details,
      postInitiated: true,
      nameOptions: [],
      selectedPayee:null
    };
  }
  componentDidMount() {
    this.setState({selectedPayee:{value:this.props?.details?.payee_id,text:this.props?.details?.payee_company}})
  }
  onChangeCallback = (field, value) => {
    this.props.handleChange(field, value);
  }
  fetchSearchDetails = (event) => {
    let entity = null;
    switch (this.props.modeType) {
      case 'Show Composer':
      case 'SHOW_COMPOSER':
        entity = "SC_PAYEE";
        break;
      case 'Music Supervisor':
      case 'MUSIC_SUPERVISOR':
        entity = "MS_PAYEE";
        break;
    }
    if (event.target?.value) {
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${entity}&searchString=${event.target.value}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
          //let allexistingPeopleId = this.state.peopleDetailsData.map(item => item.people_id);
          let formattedList = response.data.map(item => ({ value: item.payee_id, text: item.payee_name, is_active: item.is_active }));
          //let newFormattedList = formattedList.filter(item => !allexistingPeopleId.includes(item.value));
          this.setState({ nameOptions: formattedList });
        },
          (err) => {
            console.log("Error in fetching Address Types:", err)
          })
    } else {
      this.setState({ nameOptions: [] });
    }
  }
  handleChange = (objPassed) => {
    if (objPassed) {
      //let selected_obj = this.state.nameOptions.filter(item => item.value === objPassed?.value);
      this.props?.handleChange("payee_company", objPassed);
      this.setState({selectedPayee:objPassed})
      // this.setState(prevState => ({
      //   composerDetails: {
      //     ...prevState.composerDetails,
      //     ["payee_id"]: objPassed.value,
      //     ["payee_company"]: objPassed.text,
      //   }
      // }))
    }
    else {
      this.props?.handleChange("payee_company", null);
      // this.setState(prevState => ({
      //   composerDetails: {
      //     ...prevState.composerDetails,
      //     ["payee_id"]: null,
      //     ["payee_company"]: null
      //   }
      // }))
    }
  }
  render() {
    return (
      <MDBContainer fluid className="AgentDetailsContainer">
        <br />
        <MDBRow>
          <MDBCol md={4}>
            <BasicTextField
              label={"Full Name"}
              isMandatory={this.props?.view ? false : true}
              mandatoryWarning={this.props?.view ? false : messages.mandatoryWarning}
              showMandatory={this.props?.view ? false : this.props.postInitiated || false}
              value={this.props?.view ? this.props?.details?.agent_full_name : this.props?.details?.full_name}
              onChange={(e) => this.props.handleChange(this.props?.view ? "agent_full_name" : "full_name", e.target.value.trim().length === 0 ? null : e.target.value)}
            />
          </MDBCol>
          <MDBCol md={4}>
            <BasicTextField
              label={"Company"}
              value={this.props?.view ? this.props?.details?.agent_company : this.props?.details?.company}
              onChange={(e) => this.props?.handleChange(this.props?.view ? "agent_company" : "company", e.target.value)}
            />
          </MDBCol>
          <MDBCol md={2}></MDBCol>
          {/* {this.props?.view ? null : <MDBCol md={2}>
            <BasicTextField
              label={"Federal ID No."}
              value={this.props?.details?.federal_id_no}
              onChange={(e) =>
                this.props?.handleChange("federal_id_no", e.target.value)
              }
            />
          </MDBCol>} */}
        </MDBRow>
        <MDBRow>
          <MDBCol md={4}>
            <MDBRow>
              <BasicTextField
                label={"Email"}
                value={this.props?.view ? this.props?.details?.agent_email : this.props?.details?.email}
                fieldValid={(this.props?.view ? this.props?.details?.agent_email : this.props?.details?.email) ? !validateEmail(this.props?.view ? this.props?.details?.agent_email : this.props?.details?.email) ? true : false : false}
                onChange={(e) =>
                  this.props.handleChange(
                    this.props?.view ? "agent_email" : "email",
                    e.target.value
                  )}
                inValidInput={messages.invalidInput}
              />
            </MDBRow>
            <MDBRow>
              <MDBCol md={6}>
                <BasicTextField
                  label={"Work Phone"}
                  // fieldValid={this.props?.view ? (this.props?.details?.agent_work_phone ? !validateAllPhone(this.props?.details?.agent_work_phone) : false) : (this.props?.details?.work_phone ? !validateAllPhone(this.props?.details?.work_phone) : false)}
                  // inValidInput={"Please enter valid phone"}
                  value={this.props?.view ? this.props?.details?.agent_work_phone : this.props?.details?.work_phone}
                  onChange={(e) =>
                    this.props?.handleChange(this.props?.view ? "agent_work_phone" : "work_phone", formatPhoneNumbers(e.target.value))
                  }
                  placeholder="(___) ___-____"
                />
              </MDBCol>
              <MDBCol md={6}>
                <BasicTextField
                  label={"Cell Phone"}
                  // fieldValid={this.props?.view ? (this.props?.details?.agent_cell_phone ? !validateAllPhone(this.props?.details?.agent_cell_phone) : false) : (this.props?.details?.cell_phone ? !validateAllPhone(this.props?.details?.cell_phone) : false)}
                  // inValidInput={"Please enter valid phone"}
                  value={this.props?.view ? this.props?.details?.agent_cell_phone : this.props?.details?.cell_phone}
                  onChange={(e) =>
                    this.props?.handleChange(this.props?.view ? "agent_cell_phone" : "cell_phone", formatPhoneNumbers(e.target.value))
                  }
                  placeholder="(___) ___-____"
                />
              </MDBCol>
            </MDBRow>
            {this.props?.origin === "BusinessAffairs" && this.props?.newOrEdit === "Edit" &&
              < MDBRow >
                <MDBCol md={12}>
                  {/* <SelectField
                    placeHolderText={"-Select-"}
                    label={"Linked Payee"}
                  // value={}
                  // options={}
                  // onChange={(e) => this.handleChange("", e.target.value)}
                  /> */}
                  <SearchSelectField
                    id={"linked_payee"}
                    label={"Linked Payee"}
                    placeholder={`- Search Payee by Name -`}
                    options={this.state.nameOptions || []}
                    width={'100%'}
                    detail_selected={this.props?.details?.payee_id ? this.state.selectedPayee : null}
                    valueSelected={(e, newValue) => this.handleChange(newValue)}
                    searchText={this.fetchSearchDetails}
                    multiple={false}
                    searchSelect={true}
                  />
                </MDBCol>
              </MDBRow>
            }
          </MDBCol>
          <MDBCol md={8}>
            <AddressComponent
              editCallback={this.props?.handleChange}
              addressConfig={this.props?.view ? agentAddressConfig : addressConfig}
              addressDetails={this.props?.details}
              disabled={false}
            />
          </MDBCol>
        </MDBRow>

        <br />
      </MDBContainer >
    );
  }
}
export default withUserContext(AgentDetails);