import React from 'react';
import HeaderContent from '../header/HeaderContent';
import { MDBContainer } from 'mdbreact';

function ErrorPage(props){
    return( 
        <>      
         <MDBContainer fluid style={{padding: "0"}}>
            <HeaderContent isLoggedIn = {props.isLoggedIn}/> 
            <div style={{margin: "65px"}}>
                <h1>Error Id : {props.errorId}</h1>
                <p>Error Description: {props.errorDesc}</p>
            </div>
        </MDBContainer>
        
        </>
    )}

export default ErrorPage;