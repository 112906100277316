export const productionEntityConfig = {
    production_entity:null,
    production_entity_id: null,
    production_group_id: null,
    company: null,
    short_name: null,
    address_1: null,
    address_2: null,
    city: null,
    state: null,
    country: null,
    zip: null,
    phone: null,
    co: null,
    rapid_cue_name: null,
    rapid_cue_id: null,
    division_id: null,
    is_union: 0,
    is_active: 1,
    selected_production_group:{},
    source_code_company: null
}

export const addressConfig = {
    "address1": "address_1",
    "address2": "address_2",
    "country": "country_id",
    "state": "state",
    "city": "city",
    "zipcode": "zip",
}