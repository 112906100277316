import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import "./Reports.scss";
import AdHocReport from "../../Navigation/Reports/AdHocReport";
import PublicDomainReport from "../../Navigation/Reports/PublicDomainReport";
import ShowSeasonSong from "../../Navigation/Reports/ShowSeasonSong";
import MainTitleReport from "../../Navigation/Reports/MainTitleReport";
import MixDateReport from "../../Navigation/Reports/MixDateReport";
import MusicReplacementReport from "../../Navigation/Reports/MusicReplacementReport";
import OptionAcquisitionReport from "../../Navigation/Reports/OptionAcquisitionReport";
import PayeeCheckReport from "../../Navigation/Reports/PayeeCheckReport";
import CableDistributionPerp from "../../Navigation/Reports/CableDistributionPerp";
import CableDistributionProjections from "../../Navigation/Reports/CableDistributionProjections";
import HomeVideoSummary from "../../Navigation/Reports/HomeVideoSummary";
import HomeVideoProjections from "../../Navigation/Reports/HomeVideoProjections";
import IntlTVDistributionOption from "../../Navigation/Reports/IntlTVDistributionOption";
import IntlTVDistributionPerp from "../../Navigation/Reports/IntlTVDistributionPerp";
import IntlTVDistributionProjection from "../../Navigation/Reports/IntlTVDistributionProjection";
import BudgetVsEFC from "../../Navigation/Reports/BudgetVsEFC";
import LicenseTracking from "../../Navigation/Reports/LicenseTracking";
import { withUserContext } from "../../../contexts/UserContext";
import {
  ReportAdHocLabel,
  ShowSeasonLabel,
  LicensingLabel,
  Budgeting,
  WebReports,
} from "./config";
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import clearTrackService from "../../../services/service";
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';


export default withUserContext(class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      subTabValue: 1,
      reportName: "",
      showChangeContactModal: false,
      searchPayload: [],
      showError: false,
      showSuccess: false,
      showFail: false,
      isPosting: false,
      saveTitle: "Success",
      saveEditMessage: "The changes are saved successfully!",
      failTitle: "Failure",
      failMessage: "Saving changes failed!",
      unsavedTitle: "Unsaved Changes",
      unsavedMessage: "Are you sure you want to discard your changes?",
      saveFlag: false,
      showEmailSave: false,
      noData:false,
      canNavigateToReportsPermission:[],
      reportPermissions:[]
    };
  }
  componentDidMount() {
    document.title = 'Reports'
    if (this.props?.userContext?.reportTabData?.length > 0) {
      this.handleReport(this.props?.userContext?.reportTabData[0])
    }
    if(this.props?.userContext?.active_tenant?.tenant_id){
     let canNavigateToReportsRecord = this.props?.userContext?.active_tenant?.common_permissions?.filter((obj) => obj.permission_name === "Can navigate to Reports tab" && obj.permission_group_name === "Reports" && obj.view === 1);
      canNavigateToReportsRecord = canNavigateToReportsRecord.length > 0 ? true : false;
      let reportsPermissionRecord = this.props?.userContext?.active_tenant?.common_permissions?.filter((obj) => obj.permission_group_name === "Reports" && obj.view === 1);
      this.setState({canNavigateToReportsPermission: canNavigateToReportsRecord , reportPermissions: reportsPermissionRecord})
    }
  }
  handleshowChangeContactModal = () => {
    this.setState({ showChangeContactModal: true });
  }
  handleHideChangeContactModal = () => {
    this.setState({ showChangeContactModal: false });
  }
  handleTabChange = (newTabValue, type) => {
    this.setState({
      [type]: newTabValue,
    });
  };

  handleReport = (value) => {
    this.setState({ reportName: value });
  };

  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success": return this.setState({ showSuccess: value, saveFlag: true });
      case "fail": return this.setState({ showFail: value });
      case "emailSaved": return this.setState({ showEmailSave: value })
      case "noData": return this.setState({ noData: value },()=>{
        setTimeout(() => {
          this.setState({noData : false})
        }, 3000);
      })
    }
  }
  
  handleResetNotify = () => {
    this.setState({ showFail: false, showSuccess: false, showEmailSave: false,noData:false });
    if (this.state.saveFlag) {
      this.setState({ saveFlag: false })
    }
  }

  generateReport = (reportName, searchPayload, fileName, reportType) => {
    if (!searchPayload || !reportName) {
      return;
    }
    Object.keys(searchPayload).map(obj => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    })
    let encryptTedStr = btoa(JSON.stringify(searchPayload));
    let load = null
    if (fileName === "License Tracking Report") {
      if (reportType) {
        load = {
          'payload': 'exportPdf',
          'reportName': reportName,
          'fileName': fileName,
          'searchJson': encryptTedStr,
          'showHtml': 0,
          'encryptionType': 1,
          'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
          // 'divisionId': 1,
        }
      } else {
        load = {
          'payload': 'exportExcel',
          'reportName': "rptLicenseTrackExcel",
          'fileName': fileName,
          'searchJson': encryptTedStr,
          'showHtml': 0,
          'encryptionType': 1,
          'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
          // 'divisionId': 1,
        }
      }
    } else if (fileName === "Adhoc") {
      load = {
        'payload': 'exportExcel',
          'reportName': reportName,
          'fileName': fileName,
          'searchJson': encryptTedStr,
          'showHtml': 0,
          'encryptionType': 1,
          'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
          // 'divisionId': 1,
      }
    } else {
      load = {
        'payload': 'exportPdf',
        'reportName': reportName,
        'fileName': fileName,
        'searchJson': encryptTedStr,
        'showHtml': 0,
        'encryptionType': 1,
        'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
        // 'divisionId': 1,
      }
    }
    return clearTrackService.getDataWS(load)

    // let queryParams = `reportName=${reportName}&searchJson=${encryptTedStr}&fileName=${fileName}`
    // return clearTrackService.getDataWS(Constants.ClearTrackServiceBaseUrl + `/exportPdf?${load}`, this.props?.userContext?.active_tenant?.tenant_id, 1, true)
  }

  downloadUrl = (url) => {
    if (url) {
      fetch(url).then(response => response.blob()).then(blob => {
        const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        link.href = url;
        // link.setAttribute('download', reportName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
    }
  }

  // downloadUrl = (url, reportName) => {
  //   if(url){
  //     clearTrackService.downloadFile(url).then(res => {
  //       const url = window.URL.createObjectURL(res.data);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', reportName);
  //       document.body.appendChild(link);
  //       link.click();
  //       // this.setState({ pdfGenerate: false })
  //   }, (err) => {
  //       console.log("File Download Failed:" + err);
  //       // this.setState({ pdfGenerate: false })
  //   });
  //   }
  // }


  getTabComponent = () => {
    switch (this.state.reportName) {
      case "adHocReportTool":
        return <AdHocReport
          generateReport={this.generateReport}
          reportName="rptPublicDomainReport"
          reportDownloadName="PublicDomainReport"
          downloadUrl={this.downloadUrl}
        />;
      case "publicDomainReport":
        return <PublicDomainReport
          generateReport={this.generateReport}
          reportName="rptPublicDomainReport"
          reportDownloadName="PublicDomainReport"
          downloadUrl={this.downloadUrl}
        />;
      case "showSeasonSongCostToDate":
        return <ShowSeasonSong
          generateReport={this.generateReport}
          reportName="rptShowSeasonEpisodeCost"
          reportDownloadName="ShowSeasonEpisodeCost"
          downloadUrl={this.downloadUrl} />;
      case "mainTitleReport":
        return <MainTitleReport
          generateReport={this.generateReport}
          reportName="rptMainTitleSummary"
          reportDownloadName="MainTitleReport"
          notificationComponent={this.notificationComponent}
          downloadUrl={this.downloadUrl} />;
      case "mixDateReport":
        return <MixDateReport
          generateReport={this.generateReport}
          reportName="rptmixdate"
          reportDownloadName="MixDate"
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "musicReplacementReport":
        return <MusicReplacementReport
          generateReport={this.generateReport}
          reportName="rptMusicReplacement"
          reportDownloadName="MusicReplacement"
          notificationComponent={this.notificationComponent}
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "licenseTrackingReport":
        return <LicenseTracking
          generateReport={this.generateReport}
          reportName="rptLicenseTrack"
          reportDownloadName="LicenseTracking"
          notificationComponent={this.notificationComponent}
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "payeeCheckReport":
        return <PayeeCheckReport
          generateReport={this.generateReport}
          reportName="rptpayeechecks"
          reportDownloadName="PayeeCheck"
          notificationComponent={this.notificationComponent}
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "optionAcquisitionReport":
        return <OptionAcquisitionReport
          generateReport={this.generateReport}
          reportName="rptOptionAcquisition"
          reportDownloadName="OptionAcquisition"
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "budgetvsEFCbyEpisode":
        return <BudgetVsEFC
          generateReport={this.generateReport}
          reportName="rptBudgetVsefc"
          reportDownloadName="BudgetVsEfc"
          notificationComponent={this.notificationComponent}
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "cableDistributionExtendInitialIntoPerp":
        return <CableDistributionPerp
          generateReport={this.generateReport}
          reportName="rptOptionAcquisition"
          reportDownloadName="OptionAcquisition"
          downloadUrl={this.downloadUrl} />;
      case "cableDistributionSumamaryProjections":
        return <CableDistributionProjections
          generateReport={this.generateReport}
          reportName="rptcableDistributionSummaryProjections"
          reportDownloadName="CableDistributionProjections"
          downloadUrl={this.downloadUrl} />;
      case "homeVideoSummary":
        return <HomeVideoSummary
          generateReport={this.generateReport}
          reportName="rptOptionAcquisition"
          reportDownloadName="OptionAcquisition"
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "homeVideoSummaryProjections":
        return <HomeVideoProjections
          generateReport={this.generateReport}
          reportName="rptOptionAcquisition"
          reportDownloadName="OptionAcquisition"
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "intlTVDistributionYearallTVOption":
        return <IntlTVDistributionOption
          generateReport={this.generateReport}
          reportName="rptOptionAcquisition"
          reportDownloadName="OptionAcquisition"
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "intlTvDistributionExtendInitialIntoPerp":
        return <IntlTVDistributionPerp
          generateReport={this.generateReport}
          reportName="rptOptionAcquisition"
          reportDownloadName="OptionAcquisition"
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      case "intlTvDistributionSummaryProjections":
        return <IntlTVDistributionProjection
          generateReport={this.generateReport}
          reportName="rptOptionAcquisition"
          reportDownloadName="OptionAcquisition"
          // fieldChanged={this.props.fieldChanged}
          // handleClose={this.props.handleClose}
          downloadUrl={this.downloadUrl} />;
      default:
        return <span>Please chose Report Type </span>;
    }
  };
  LicenseTracking;

  handleText = () => {
    switch (this.state.tabValue) {
      case 1:
        return "Edit";
      case 2:
        return "Add File";
    }
  };

  handleIcon = () => {
    switch (this.state.tabValue) {
      case 1:
        return "pen";
      case 2:
        return "plus-circle";
    }
  };

  handleSubmit = () => {
    switch (this.state.tabValue) {
      case 1:
        return this.handleshowChangeContactModal;
    }
  };

  render() {
    let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
      (this.state.showSuccess && (this.props.mode === "Edit")) ? this.state.saveEditMessage :
        (this.state.showSuccess && (this.props.mode === "New")) ? `New ${this.props?.headerText} has been created!` :
          (this.state.showSuccess && (this.props.mode === "Both")) ? this.state.saveEditMessage : "";
    let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
      this.state.showSuccess ? this.state.saveTitle : "";
    let severity = this.state.showFail|| this.state.noData ? "error" : this.state.showSuccess ? "success" : this.state.showEmailSave ? "success" : "";
    let messageNotification = this.state.showSuccess ? "Post successful!" : this.state.showFail ? "Saving changes failed!" : this.state.showEmailSave ? "Emails have been saved to your Drafts folder!" : this.state.noData ? "No Data Found" : "";
    return (
      <div>
        <NotificationComponent open={this.state.showSuccess || this.state.showFail || this.state.showEmailSave || this.state.noData} message={messageNotification} severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)} />
        <MDBRow>
          <h3 style={{ marginTop: "1%", marginRight: "40%" }}>
            <b >Reports</b>
          </h3>
        </MDBRow>
        <MDBContainer
          fluid
          style={{ marginTop: "2%" }}
          className="weekend-detail-container"
        >
          <div className="weekend">
            <MDBCol>
              <MDBRow>
                <p>
                  <b>Ad Hoc</b>
                </p>
                {ReportAdHocLabel.map((report, index) => (
                  <a
                    href="javascript:void(0);"
                    onClick={(e) => this.handleReport(report.value)}
                  >
                    {(this.state.reportPermissions.filter(item=>item.permission_name === report.permissionName)).length > 0 && report.label}
                  </a>
                ))}
              </MDBRow>
            </MDBCol>
            <MDBCol>
              <MDBRow>
                <p>
                  <b>Show/Season</b>
                </p>
                {ShowSeasonLabel.map((report, index) => (
                  <a
                    href="javascript:void(0);"
                    onClick={(e) => this.handleReport(report.value)}
                  >
                  {(this.state.reportPermissions.filter(item=>item.permission_name === report.permissionName)).length > 0 && report.label}

                  </a>
                ))}
              </MDBRow>
            </MDBCol>
            <MDBCol>
              <MDBRow>
                <p>
                  <b>Licensing</b>
                </p>
                {LicensingLabel.map((report, index) => (
                  <a
                    href="javascript:void(0);"
                    onClick={(e) => this.handleReport(report.value)}
                  >
                  {(this.state.reportPermissions.filter(item=>item.permission_name === report.permissionName)).length > 0 && report.label}

                  </a>
                ))}
              </MDBRow>
            </MDBCol>
            <MDBCol>
              <MDBRow>
                <p>
                  <b>Budgeting</b>
                </p>
                {Budgeting.map((report, index) => (
                  <a
                    href="javascript:void(0);"
                    onClick={(e) => this.handleReport(report.value)}
                  >
                    {(this.state.reportPermissions.filter(item=>item.permission_name === report.permissionName)).length > 0 && report.label}

                  </a>
                ))}
              </MDBRow>
            </MDBCol>
            {/* <MDBCol md={3}>
              <MDBRow>
                <p>
                  <b>Web Reports</b>
                </p>
                {WebReports.map((report, index) => (
                  <a
                    href="javascript:void(0);"
                    onClick={(e) => this.handleReport(report.value)}
                  >
                    {(this.state.reportPermissions.filter(item=>item.permission_name === report.permissionName)).length > 0 && report.label}

                  </a>
                ))}
              </MDBRow>
            </MDBCol> */}
          </div>
          {this.getTabComponent()}
        </MDBContainer>
        <br />

        {this.state.reportName !== "" &&
          this.state.reportName !== "adHocReportTool" &&
          this.state.reportName !== "licenseTrackingReport" &&
          this.state.reportName !== "mixDateReport" &&
          this.state.reportName !== "optionAcquisitionReport" &&
          this.state.reportName !== "homeVideoSummary" &&
          this.state.reportName !== "homeVideoSummaryProjections" &&
          this.state.reportName !== "intlTvDistributionExtendInitialIntoPerp" &&
          this.state.reportName !== "optionAcquisitionReport" &&
          this.state.reportName !== "cableDistributionExtendInitialIntoPerp" &&
          this.state.reportName !== "cableDistributionSumamaryProjections" &&
          this.state.reportName !== "intlTVDistributionYearallTVOption" &&
          this.state.reportName !== "showSeasonSongCostToDate" &&
          this.state.reportName !== "publicDomainReport" &&
          this.state.reportName !== "musicReplacementReport" &&
          this.state.reportName !== "intlTvDistributionSummaryProjections" &&
          this.state.reportName !== "mainTitleReport" &&
          this.state.reportName !== "payeeCheckReport" &&
          this.state.reportName !== "budgetvsEFCbyEpisode" ? (
          <>
            <div>
              <MDBRow>
                <MDBCol md={10}></MDBCol>
                <MDBCol md={2}>
                  <MDBRow>
                    <MDBCol id="genpdf-btn">
                      <BasicButton className="efcBtn" text={"Generate PDF"} />
                    </MDBCol>
                    <MDBCol>
                      <BasicButton variant="outlined" text={"Clear Filters"} />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </div>{" "}
          </>
        ) : null}
        {this.state.showChangeContactModal && (
          <MainViewModalComponent
            open={this.state.showChangeContactModal}
            handleClose={this.handleHideChangeContactModal}
            headerText={"Default Licensor Contact"}
            mode={""}
            modalName={"Change Contact"}
          />
        )}
      </div>
    );
  }
})
