// export const initialClearanceRecord = {
//     "main_title_id": null,
//     "show_season_id": null,
//     "show_id": null,
//     "season_id": null,
//     "songs": [],
//     "duration": null,
//     // "music_use": {},
//     "guaranteed_eps": null,
//     "main_title_credits": null,
//     "is_apply_to_HV": 0,
//     "music_use_id": null,
//     "clearance_rep_id": null,
//     "is_active": null
// }
// export const SongsList = {
//     "song_id": null,
//     "song_name": null,
//     "row_no": 1,
//     "selected_song": { value: null, text: null }
// }

export const initialClearanceRecord = {
    "clearance_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "show_season_episode_id": null,
    "show_name": null,
    "episode_title": null,
    "scene": null,
    // "duration": null,
    // "music_uses_id": null,
    "existing_uses": null,
    "clearance_option_id": null,
    "clearance_option": null,
    "is_subtitle_allowed": 0,
    "is_film_tv_clip": 0,
    "is_override_cost_center": 0,
    "is_no_lic_music": 0,
    "is_apply_dollar_to_HV": 0,
    "is_translation_allowed": 0,
    "is_active": 0,
    "songs_list": [],
    "is_songs_updated": null,
    "performer_id":null
}
export const mockSong = {
    song_id: null,
    is_do_not_use: null,
    performer_list:[]
}

export const mockSong2 = {
    main_title_song_id:null,
    song_id: null,
    is_do_not_use: null,
    performer_list: [],
    min: null,
    sec: null,
    music_uses_id: null,
    is_delete:0
}

export const initialMaintitleClearanceRecord = {
    "main_title_id": null,
    "show_season_id": null,
    "show_id" :null,
    "season_id" :null,
  "guaranteed_eps" :null,
  "main_title_credits" :null,
  "clearance_rep_id" :null,
  "is_apply_to_HV" :0,
  "show_yr":null,
  "no_mt":0,
  "notes":null,
  "is_active":0,
 "songs_list":[]
}

export const mockPerformer = {
    performer_id: null,
    performer_name: null
}

export const episodeOptions = [{ "label": "Episode 1", "value": 1 }, { "label": "Episode 2", "value": 2 }, { "label": "Episode 3", "value": 3 }, { "label": "Episode 4", "value": 4 }]
export const songOptions = [{ "label": "Song 1", "value": 1 }, { "label": "Song 2", "value": 2 }, { "label": "Song 3", "value": 3 }, { "label": "Song 4", "value": 4 }]
export const adCardOptions = [{ "label": "ALT 6-YR AD CARD", "value": "alt_6-yr_ad_card" }, { "label": "ALT XXx-YR AD CARD", "value": "alt_xxx_yr_ad_card" }]
export const checkBoxOptionsLeft = [{ "id": "is_subtitle_allowed", "label": "Subtitle Allowed" }, { "id": "is_film_tv_clip", "label": "Film TV Clip" }, { "id": "is_override_cost_center", "label": "Override Cost Center" }]
export const checkBoxOptionsRight = [{ "id": "is_no_lic_music", "label": "No Lic. Misc" }, { "id": "is_apply_dollar_to_HV", "label": "Apply $ to HV" },{ "id": "is_translation_allowed", "label": "Translation Allowed" }]





