import React from "react";
import { MDBContainer, MDBRow, MDBCol} from "mdbreact";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import './EditShowDetails.scss';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import { withUserContext } from '../../../contexts/UserContext';
import { validateNumbersOnly } from '../../../Common/Helper';
import messages from '../../../Common/Messages.json';
var postObj = [], sum, is_initial_rights_updated = 0, is_optional_rights_updated = 0, is_alt_initial_rights_updated = 0, is_cost_center_updated = 0, canEditShowDetailsExceptBudget, currentSeasonOptions = [], initialRecordData = {},canEditShowSeasonBudgets;
class EditShowDetailsTabModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPosting: false,
            isLoading: false,
            initialRecordData: JSON.parse(JSON.stringify(this.props.initialRecordData1)),
            currentSeasonOptions: [],
            termOptions: [],
            territoryOptions: [],
            clearanceRepOptions: [],
            initialRightsOptions: [],
            optionalRightsOptions: [],
            show_season_id: this.props.show_season_id,
            costCenterOptions:[]
        };
    }
    // handleSubmit = () => {
    //     this.setState({ isPosting: true })
    //     postObj = JSON.parse(JSON.stringify(this.state.initialRecordData));
    //     delete postObj["company"];
    //     delete postObj["network_name"];
    //     delete postObj["duration_name"];
    //     delete postObj["duration_name"];
    //     delete postObj["seasons"];
    //     if (typeof postObj["initial_term"] === "string") {
    //         postObj["initial_term"] = postObj["initial_term_id"];
    //     }
    //     if (typeof postObj["initial_exp_term"] === "string") {
    //         postObj["initial_exp_term"] = postObj["initial_exp_term_id"];
    //     }
    //     if (typeof postObj["initial_territory"] === "string") {
    //         postObj["initial_territory"] = postObj["initial_territory_id"];
    //     }
    //     if (typeof postObj["alt_initial_term"] === "string") {
    //         postObj["alt_initial_term"] = postObj["alt_initial_term_id"];
    //     }
    //     if (typeof postObj["alt_initial_exp_term"] === "string") {
    //         postObj["alt_initial_exp_term"] = postObj["alt_initial_exp_term_id"];
    //     }
    //     if (typeof postObj["alt_initial_territory"] === "string") {
    //         postObj["alt_initial_territory"] = postObj["alt_initial_territory_id"];
    //     }
    //     let durationTemp = postObj["show_duration_id"];
    //     postObj["duration_id"] = durationTemp;
    //     postObj["is_initial_rights_updated"] = is_initial_rights_updated;
    //     postObj["is_optional_rights_updated"] = is_optional_rights_updated;
    //     postObj["is_alt_initial_rights_updated"] = is_alt_initial_rights_updated;
    //     postObj["production_exc"] = postObj["production_exc_id"];
    //     postObj["clearance_rep"] = postObj["clearance_rep_id"];
    //     postObj["clearance_rep_asst"] = postObj["clearance_rep_asst_id"];
    //     postObj.optional_rights.map((item, index) => {
    //         if (item.right_id == "" || item.right_id == null) {
    //             item.right_id = null;
    //         }
    //         if (item.term == "" || item.term == null) {
    //             item.term = null;
    //         }
    //         if (item.exp_term == "" || item.exp_term == null) {
    //             item.term = null;
    //         }
    //         if (item.territory == "" | item.territory == null) {
    //             item.territory = null;
    //         }
    //         if ((item.right_id == "" || item.right_id == null) && (item.term == "" || item.term == null) && (item.territory == "" | item.territory == null)) {
    //             postObj.optional_rights.splice(index, 1);
    //         }

    //         delete item["right_abbr"];
    //         if (typeof item["term"] === "string") {
    //             item["term"] = item["term_id"];
    //         }
    //         if (typeof item["exp_term"] === "string") {
    //             item["exp_term"] = item["exp_term_id"];
    //         }
    //         if (typeof item["territory"] === "string") {
    //             item["territory"] = item["territory_id"];
    //         }
    //     })
    //     postObj.clearance_budgets.map((item, index) => {
    //         if (item.cost_center_id == "") {
    //             item.cost_center_id = null;
    //         }
    //         if (item.budget == "") {
    //             item.budget = null;
    //         }
    //         if ((item.budget == "" || item.budget == null) && (item.cost_center_id == "" || item.cost_center_id == null)) {
    //             postObj.clearance_budgets.splice(index, 1);
    //         }
    //         delete item["cost_center_name"];
    //         delete item["internal_show_season_clearance_budgets_id"];
    //     })
    //     if (postObj.defaultFromDuration === false) {
    //         let from_duration = String(postObj.from_duration.hour) + ":" + String(postObj.from_duration.min) + ":00";
    //         postObj.from_duration = from_duration;
    //     }
    //     if (postObj.defaultToDuration === false) {
    //         let to_duration = String(postObj.to_duration.hour) + ":" + String(postObj.to_duration.min);
    //         postObj.to_duration = to_duration;
    //     }
    //     if (postObj.defaultFromDuration === true) {
    //         postObj.from_duration = null;
    //     }
    //     if (postObj.defaultToDuration === true) {
    //         postObj.to_duration = null;
    //     }

    //     if (postObj?.mpm?.trim().length == 0) { postObj.mpm = null; }
    //     if (postObj?.proj_eps?.length == 0) { postObj.proj_eps = null; }
    //     if (postObj?.notes?.trim().length == 0) { postObj.notes = null; }
    //     if (postObj.estimating_manager?.trim()?.length == 0) { postObj.estimating_manager = null; }
    //     if (postObj?.estimator?.trim()?.length == 0) { postObj.estimator = null; }
    //     if (postObj?.music_amort_code?.trim()?.length == 0) { postObj.music_amort_code = null; }
    //     if (postObj?.series_amort_code?.trim()?.length == 0) { postObj.series_amort_code = null; }
    //     this.postSeasonData(postObj);
    // };
    // postSeasonData = (postJson) => {
    //     this.setState({ isPosting: true });
    //     let extensionUrl = '/showSeason';
    //     clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.state.initialRecordData.division_id)
    //         .then((response) => {
    //             if (response.data.error) {
    //                 this.setState({ isPosting: false });
    //                 this.props?.notificationComponent(true, "fail");
    //             } else {
    //                 this.setState({ isPosting: false });
    //                 this.props.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
    //                 this.props?.notificationComponent(true, "success");
    //                 console.log("post response", response);
    //             }
    //         },
    //             (err) => {
    //                 this.setState({ isPosting: false });
    //                 this.props?.notificationComponent(true, "fail");
    //                 console.log("Post agency details error: " + err);
    //             });
    // }

    handleTimeChange = (field, value, keyToModify) => {
        if (value !== null) {
            let flagToChange;
            if (keyToModify === "from_duration") {
                flagToChange = "defaultFromDuration";
            }
            if (keyToModify === "to_duration") {
                flagToChange = "defaultToDuration";
            }
            initialRecordData[keyToModify] = { "hour": value._d.getHours(), "min": value._d.getMinutes()};
            initialRecordData[flagToChange] = false;
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    [keyToModify]: { "hour": value._d.getHours(), "min": value._d.getMinutes() },
                    [flagToChange]: false
                }
            }));
        } else {
            if (keyToModify === "from_duration") {
                // let d = new Date();
                // let from_hour = d.getHours();
                // let from_min = d.getMinutes();
                initialRecordData[keyToModify] = { "hour": null, "min": null };
                initialRecordData["defaultFromDuration"] = true;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [keyToModify]: { "hour": null, "min": null},
                        ["defaultFromDuration"]: true
                    }
                }))
            }
            if (keyToModify === "to_duration") {
                // let d = new Date();
                // let from_hour = d.getHours();
                // let from_min = d.getMinutes();
                initialRecordData[keyToModify] = { "hour": null, "min": null };
                initialRecordData["defaultToDuration"] = true;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [keyToModify]: { "hour": null, "min": null },
                        ["defaultToDuration"]: true
                    }
                }))
            }
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    // for text fields change event
    handleChange = (field, value, index, objPassed) => {
        switch (field) {
            case 'budget':
                let budgetArr = this.state.initialRecordData.clearance_budgets;
                var budget_obj = budgetArr.filter((obj) => (obj.internal_show_season_clearance_budgets_id === objPassed.internal_show_season_clearance_budgets_id) ? obj : null);
                budget_obj[0][field] = Number(value);
                budgetArr.splice(index, 1, budget_obj[0]);
                initialRecordData["clearance_budgets"] = budgetArr;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["clearance_budgets"]: budgetArr
                    },
                }));
                break;
            default:
                initialRecordData[field] = value;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [field]: value
                    },
                }));
                break;
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }

    onCheckboxChange = (checkboxKey, checkedValue, index, objPassed, parentKey) => {
        var val = (checkedValue == true) ? 1 : 0;
        switch (checkboxKey) {
            case 'is_active':
                let is_active = this.state.initialRecordData.is_active;
                initialRecordData["is_active"] = (checkedValue == true) ? 0 : 1;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["is_active"]: (checkedValue == true) ? 0 : 1
                    }
                }));
                break;
            case 'sum':
                let budgetArr = this.state.initialRecordData.clearance_budgets;
                var budget_obj = budgetArr.filter((obj) => (obj.internal_show_season_clearance_budgets_id === objPassed.internal_show_season_clearance_budgets_id) ? obj : null);
                budget_obj[0]["sum"] = val;
                budgetArr.splice(index, 1, budget_obj[0]);
                initialRecordData["clearance_budgets"] = budgetArr;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["clearance_budgets"]: budgetArr
                    },
                }));
                break;
            default:
                initialRecordData[checkboxKey] = val;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [checkboxKey]: val
                    },
                }));
                break;
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }

    addBudgetRow() {
        let budget_arr = this.state.initialRecordData.clearance_budgets;
        budget_arr.splice(this.state.initialRecordData.clearance_budgets.length, 0, {
            "internal_show_season_clearance_budgets_id": this.state.initialRecordData.clearance_budgets.length,
            "show_season_clearance_budgets_id": null,
            "cost_center_id": "",
            "cost_center_name": "",
            "budget": 0,
            "sum": 0
        });

        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["clearance_budgets"]: budget_arr
            }

        }));
    }
    removeBudgetRow(index) {
        let budget_arr = this.state.initialRecordData.clearance_budgets;
        budget_arr.splice(index, 1);
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["clearance_budgets"]: budget_arr
            }
        }));
    }
    // shouldComponentUpdate(nextProps) {
    //     if (nextProps !== this.state) {
    //         return true;
    //     }
    //     else { return false; }
    // }
    onDropdownChange(whatChanged, parentKey, index, keyToModify, valPassed, objPassed) {
        switch (whatChanged) {
            case 'costCenterChanged':
                let budgetArr = this.state.initialRecordData.clearance_budgets;
                objPassed["cost_center_id"] = valPassed;
                budgetArr.splice(index, 1, objPassed);
                is_cost_center_updated = 1;
                initialRecordData["clearance_budgets"] = budgetArr;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["clearance_budgets"]: budgetArr
                    },
                }));
                break;
            case 'durationChanged':
                initialRecordData[keyToModify] = valPassed;
                let initialMinutes = this.props.durationOptions.filter(item => item.value === valPassed ? item : null);
                if (initialMinutes?.length > 0) {
                    initialRecordData["duration_minute"] = initialMinutes[0].minutes;
                    initialRecordData["duration_seconds"] = parseInt(0);
                    this.setState((prevState) => ({
                        initialRecordData: {
                            ...prevState.initialRecordData,
                            [keyToModify]: valPassed,
                            ["duration_minute"]: initialMinutes[0].minutes,
                            ["duration_seconds"]: parseInt(0)
                        },
                    }));
                } else {
                    initialRecordData["duration_minute"] = parseInt(0);
                    initialRecordData["duration_seconds"] = parseInt(0);
                    this.setState((prevState) => ({
                        initialRecordData: {
                            ...prevState.initialRecordData,
                            [keyToModify]: valPassed,
                            ["duration_minute"]: parseInt(0),
                            ["duration_seconds"]: parseInt(0)
                        },
                    }));
                }

                break;
            default:
                initialRecordData[keyToModify] = valPassed;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [keyToModify]: valPassed
                    },
                }));
                break;
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    render() {
        return (
            <div>
                <MDBContainer fluid className="EditShowDetailsModalContainer">
                    <MDBRow>
                        <MDBCol md={2} style={{ cursor: "not-allowed" }}>
                            <BasicCheckbox
                                id={"is_pilot_disabled"}
                                label={"Pilot"}
                                checked={this.state.initialRecordData?.is_pilot || false}
                                disabled={true}
                            />
                        </MDBCol>
                        <MDBCol md={4}></MDBCol>

                    </MDBRow>
                    {/* Type,season,show season name, mark season as inactive, mark season as complete row starts*/}
                    <MDBRow>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicLabel text={"Type"} isMandatory={true}/>
                            <BasicLabel text={this.state.initialRecordData.show_type || ""} />
                        </MDBCol>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <SelectField
                                label={"Season"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                mandatoryWarning={messages.mandatoryWarning}
                                value={this.state.initialRecordData.season_id || ""}
                                options={this.props.seasonOptions || []}
                                onChange={(e) => this.onDropdownChange("seasonChanged", null, null, "season_id", e.target.value, null)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicLabel text={"Show Season Name"} isMandatory={true}/>
                            <BasicLabel text={this.state.initialRecordData.show_name || ""} />
                        </MDBCol>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicCheckbox id="no_mt"
                                label="No MT"
                                checked={this.state.initialRecordData?.no_mt}
                                onChange={(e) => this.onCheckboxChange("no_mt", e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicCheckbox id="is_active"
                                label="Mark Season as Inactive"
                                checked={!this.state.initialRecordData.is_active}
                                onChange={(e) => this.onCheckboxChange("is_active", e.target.checked)}
                            />
                            {this.state.initialRecordData.is_show_season_completed !== undefined && this.state.initialRecordData.is_show_season_completed !== null && <BasicCheckbox id="is_show_season_completed"
                                label="Mark Show Season as Complete"
                                checked={this.state.initialRecordData.is_show_season_completed}
                                onChange={(e) => this.onCheckboxChange("is_show_season_completed", e.target.checked)}
                            />}
                        </MDBCol>
                    </MDBRow>
                    {/* Type,season,show season name, mark season as inactive, mark season as complete row ends*/}
                    {/* Mpm , production entity row starts*/}
                    <MDBRow>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicTextField
                                label={"MPM #"}
                                // isMandatory={true}
                                // showMandatory={this.state.postInitiated || false}
                                // mandatoryWarning={messages.mandatoryWarning}
                                value={this.state.initialRecordData.mpm || ""}
                                fieldValid={this.state?.initialRecordData?.mpm ? (!validateNumbersOnly(this.state?.initialRecordData?.mpm) ? true : false) : false}
                                onChange={(e) => this.handleChange('mpm', e.target.value.trim().length === 0 ? null : e.target.value)}
                                inValidInput={messages.invalidInput} />
                        </MDBCol>
                        <MDBCol md={7} className="secondaryLabelContainer">
                            <BasicLabel text={"Production Entity"}  isMandatory={true} />
                            <SelectField
                                isMandatory={true}
                                showMandatory = {this.props?.showError || false}
                                //mandatoryWarning={messages.mandatoryWarning}
                                value={this.state.initialRecordData.production_entity_id || ""}
                                options={this.props.productionEntityOptions || []}
                                onChange={(e) => this.onDropdownChange("productionEntityChange", null, null, "production_entity_id", e.target.value, null)}
                            />
                        </MDBCol>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicTextField
                                label={"Show Year"}
                                value={this.state.initialRecordData.show_yr || ""}
                                onChange={(e) => this.handleChange('show_yr', e.target.value?.length === 0 ? null : e.target.value)}
                                 />
                        </MDBCol>
                    </MDBRow>
                    {/* Mpm , production entity row ends*/}
                    <MDBRow className="mt-2">
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicLabel text={"Network"} />
                            <SelectField
                                value={this.state.initialRecordData.network_id || ""}
                                options={this.props.networkOptions || []}
                                onChange={(e) => this.onDropdownChange("networkChanged", null, null, "network_id", e.target.value, null)}
                            />
                        </MDBCol>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicLabel text={"Duration"} />
                            <SelectField
                                value={this.state.initialRecordData.show_duration_id || ""}
                                options={this.props.durationOptions || []}
                                onChange={(e) => this.onDropdownChange("durationChanged", null, null, "show_duration_id", e.target.value, null)}
                            />
                        </MDBCol>
                        <MDBCol md={1} className="secondaryLabelContainer">
                            <BasicLabel text={"Minutes"} />
                            <BasicTextField
                                value={this.state.initialRecordData?.duration_minute || null}
                                fieldValid={this.state?.initialRecordData?.duration_minute ? (!validateNumbersOnly(this.state?.initialRecordData?.duration_minute) ? true : false) : this.state?.initialRecordData?.initialMinutes ? (!validateNumbersOnly(this.state?.initialRecordData?.initialMinutes) ? true : false) : false}
                                onChange={(e) => this.handleChange('duration_minute', e.target.value.trim().length === 0 ? null : e.target.value)}
                                inValidInput={messages.invalidInput} />
                        </MDBCol>
                        <MDBCol md={1} className="secondaryLabelContainer">
                            <BasicLabel text={"Seconds"} />
                            <BasicTextField
                                value={this.state.initialRecordData?.duration_seconds || null}
                                fieldValid={this.state?.initialRecordData?.duration_seconds ? (!validateNumbersOnly(this.state?.initialRecordData?.duration_seconds) ? true : false) : this.state?.initialRecordData?.initialSeconds ? (!validateNumbersOnly(this.state?.initialRecordData?.initialSeconds) ? true : false) : false}
                                onChange={(e) => this.handleChange('duration_seconds', e.target.value.trim().length === 0 ? null : e.target.value)}
                                inValidInput={messages.invalidInput} />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicLabel text={"Time"} />
                            <MDBRow>
                                <MDBCol md={6} style={{ padding: 0 }}>
                                    {/* {this.state.initialRecordData.from_duration != undefined && this.state.initialRecordData?.from_duration.hour !== undefined && this.state.initialRecordData?.from_duration.min !== undefined && */}
                                        <TimePicker
                                            showSecond={false}
                                            defaultValue={(this.state.initialRecordData?.from_duration?.hour && (this.state.initialRecordData?.from_duration?.min || this.state.initialRecordData?.from_duration?.min == 0 )) ?                                              moment().hour(this.state.initialRecordData?.from_duration?.hour).minute(this.state.initialRecordData?.from_duration?.min) : null}
                                            onChange={(e) => this.handleTimeChange("timeChanged", e, "from_duration")}
                                            format={'hh:mm a'}
                                            use12Hours
                                            inputReadOnly
                                        />
                                    {/* } */}
                                </MDBCol>
                                <MDBCol md={6} style={{ padding: 0 }}>
                                    {/* {this.state.initialRecordData.to_duration != undefined && this.state.initialRecordData?.to_duration.hour !== undefined && this.state.initialRecordData?.to_duration.min !== undefined && */}
                                        <TimePicker
                                            showSecond={false}
                                            defaultValue={(this.state.initialRecordData?.to_duration?.hour && (this.state.initialRecordData?.to_duration?.min == 0 || this.state.initialRecordData?.to_duration?.min)) ?                                                moment().hour(this.state.initialRecordData?.to_duration?.hour).minute(this.state.initialRecordData?.to_duration?.min) : null}
                                            onChange={(e) => this.handleTimeChange("timeChanged", e, "to_duration")}
                                            format={'hh:mm a'}
                                            use12Hours
                                            inputReadOnly
                                        />
                                    {/* } */}
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicLabel text={"Proj. Eps"} />
                            <BasicTextField
                                value={this.state.initialRecordData.proj_eps || ""}                            
                                onChange={(e) => e.target.value.trim().length === 0 ? this.handleChange('proj_eps', null) : validateNumbersOnly(e.target.value) ? this.handleChange('proj_eps',e.target.value) : null} />
                        </MDBCol>

                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6} className="secondaryLabelContainer">
                            <BasicLabel text={"Notes"} />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicLabel text={"Show Season Notes for Budget vs EFC Report"} />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicCheckbox id="is_budget_vs_efc_rpt_notes_new" label="This note is new or has changed"
                                checked={this.state.initialRecordData?.is_budget_vs_efc_rpt_notes_new}
                                onChange={(e) => this.onCheckboxChange("is_budget_vs_efc_rpt_notes_new", e.target.checked)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicTextArea
                                value={this.state.initialRecordData.notes || ""}
                                onChange={(e) => this.handleChange('notes', e.target.value.trim().length === 0 ? null : e.target.value)} />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicTextArea
                                value={this.state.initialRecordData.notes_for_budget_vs_efc_rpt || ""}
                                onChange={(e) => this.handleChange('notes_for_budget_vs_efc_rpt', e.target.value.trim().length === 0 ? null : e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicCheckbox id="musicReplaced" label="Music has been replaced"
                                checked={this.state.initialRecordData.is_music_has_replaced}
                                onChange={(e) => this.onCheckboxChange("is_music_has_replaced", e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicCheckbox id="createExcelWithBatch" label="Create Excel w/Batch"
                                checked={this.state.initialRecordData.can_create_excel_batch}
                                onChange={(e) => this.onCheckboxChange("can_create_excel_batch", e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicCheckbox id="includeInGlobalBudget" label="Include in Global Budget/EFC Report"
                                checked={this.state.initialRecordData.is_include_in_global_budget_vs_efc_rpt}
                                onChange={(e) => this.onCheckboxChange("is_include_in_global_budget_vs_efc_rpt", e.target.checked)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicCheckbox id="OverrideCostSheet" label="Override CostSheet - MS"
                                checked={this.state.initialRecordData.override_cost_sheet_ms}
                                onChange={(e) => this.onCheckboxChange("override_cost_sheet_ms", e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicCheckbox id="SeparateHV" label="Seperate HV Checks/Batch"
                                checked={this.state.initialRecordData.is_separate_hv_checks_batch}
                                onChange={(e) => this.onCheckboxChange("is_separate_hv_checks_batch", e.target.checked)}
                            />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicCheckbox id="includeGlobalCueSheetRecipients" label="Include Global Cue Sheet Recipients"
                                checked={this.state.initialRecordData.is_include_global_cue_sheet_recipients}
                                onChange={(e) => this.onCheckboxChange("is_include_global_cue_sheet_recipients", e.target.checked)}
                            />
                        </MDBCol>
                    </MDBRow>
                    {this.state.initialRecordData.override_cost_sheet_ms === 1  && (
                    <MDBRow>
                        <MDBCol md={3} className="secondaryLabelContainer">
                        {/* <BasicLabel text={"Proj. Eps"} /> */}
                            <BasicTextField
                                value={this.state.initialRecordData?.override_cost_sheet_ms_text}
                                onChange={(e) => this.handleChange("override_cost_sheet_ms_text",  e.target.value.trim().length === 0 ? null : e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    )}
                    <MDBRow style={{ paddingTop: '30px' }}>
                        {(canEditShowDetailsExceptBudget || canEditShowSeasonBudgets) ?
                            <MDBCol md={8}>
                                <h5>
                                    <b>Show Season Budget</b>
                                </h5>
                            </MDBCol> :
                            <MDBCol md={8}>
                            </MDBCol>
                        }
                        <MDBCol md={4} className="secondaryLabelContainer" style={{ marginBottom: "-25px" }}>
                            <BasicLabel text={"Music Amort Code"} />
                            <BasicTextField
                                value={this.state.initialRecordData.music_amort_code || ""}
                                onChange={(e) => this.handleChange('music_amort_code', e.target.value.trim().length === 0 ? null : e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    {(canEditShowDetailsExceptBudget || canEditShowSeasonBudgets) &&
                        <MDBRow className="clearanceBudgetRow secondaryLabelContainer">
                            <MDBCol md={4}>
                                <BasicLabel text={"Cost Center"} />
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicLabel text={"Budget"} />
                            </MDBCol>
                            <MDBCol md={1}></MDBCol>
                            <MDBCol md={1}></MDBCol>
                        </MDBRow>
                    }
                    <MDBRow>
                        {(canEditShowDetailsExceptBudget || canEditShowSeasonBudgets) ?
                            <MDBCol md={8}>
                                {this.state.initialRecordData?.clearance_budgets && this.state.initialRecordData.clearance_budgets.map((item, index) => (
                                    <MDBRow key={"clearanceBudgetRow" + index} id={"clearanceBudgetRow" + index} className="clearanceBudgetRow">
                                        <MDBCol md={6} xl={5}>
                                            <SelectField
                                                value={item.cost_center_id || ""}
                                                // options={this.props?.costCenterOptions?.filter((item) => this.state.initialRecordData?.clearance_budgets.map((data) => {
                                                //     if (item.value === data?.cost_center_id) {
                                                //         return item.is_active = 0
                                                //     }
                                                // })) || []}
                                                options={this.props?.costCenterOptions?.map((item) => {
                                                    let newItem = {...item}
                                                    if(this.state.initialRecordData?.clearance_budgets?.map(data => data?.cost_center_id)?.includes(item?.value)) {
                                                        newItem.is_active = 0
                                                    } else [
                                                        newItem.is_active = 1
                                                    ]
                                                    return newItem
                                                }) || []}
                                                onChange={(e) => this.onDropdownChange("costCenterChanged", null, index, "cost_center_id", e.target.value, item)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={4}>
                                            <BasicTextField
                                                value={Number(item.budget) || ""}
                                                //isMandatory={item.cost_center_id ? true : false}
                                                onChange={(e) =>  e.target.value.trim().length === 0 ?  this.handleChange('budget',null,index, item) : validateNumbersOnly(e.target?.value) ?  this.handleChange('budget',e.target.value, index, item): null}
                                               // showMandatory = {this.props?.showError || false}
                                           />
                                        </MDBCol>
                                        <MDBCol md={1} xl={2} style={{ paddingRight: "0" }}>
                                            <BasicCheckbox id={"Sum" + index}
                                                label="Sum"
                                                checked={item.sum}
                                                onChange={(e) => this.onCheckboxChange("sum", e.target.checked, index, item)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={1} style={{ paddingLeft: "0" }}>
                                            {index === this.state.initialRecordData.clearance_budgets.length - 1 && <AddCircleIcon fontSize="small" onClick={() => this.addBudgetRow()} />
                                            }
                                            {this.state.initialRecordData.clearance_budgets.length > 1 && <RemoveCircleIcon fontSize="small"
                                                onClick={() => this.removeBudgetRow(index)}
                                            />}
                                        </MDBCol>
                                    </MDBRow>
                                ))}
                            </MDBCol> : <MDBCol md={8}></MDBCol>
                        }
                        <MDBCol md={4} className={canEditShowDetailsExceptBudget ? "secondaryLabelContainer" : "secondaryLabelContainer SeriesAmortCodeWrapper"}>
                            <BasicLabel text={"Series Amort Code (for MT)"} />
                            <BasicTextField style={{ margintop: "5px" }}
                                value={this.state.initialRecordData.series_amort_code || ""}
                                onChange={(e) => this.handleChange('series_amort_code', e.target.value.trim().length === 0 ? null : e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    {(canEditShowDetailsExceptBudget || canEditShowSeasonBudgets) &&
                        <MDBRow>
                            <MDBCol md={4}>
                            </MDBCol>
                            <MDBCol md={4}>
                                <>
                                    <span className="sum">=$</span>
                                    {this.state.initialRecordData.clearance_budgets != undefined &&
                                        <>
                                            <span className="sum">{sum = this.state.initialRecordData.clearance_budgets.reduce((accum, item) => (item.sum == 1) ? accum + item.budget : accum + 0, 0)}</span>
                                        </>
                                    }
                                </>
                            </MDBCol>
                            <MDBCol md={1}></MDBCol>
                            <MDBCol md={1}></MDBCol>
                        </MDBRow>

                    }
                    {/* <MDBRow>
                                <MDBCol md={10}></MDBCol>
                                <MDBCol md={2} className="saveCancelWrapper">
                                    <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"} onClick={this.handleSubmit} />
                                    <BasicButton
                                        onClick={() => this.props.handleClose()}
                                        variant="outlined"
                                        text={"Cancel"}
                                    />
                                </MDBCol>
                            </MDBRow> */}
                </MDBContainer>
            </div>
        );
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            canEditShowDetailsExceptBudget = permissions.filter((obj, index) => (obj.permission_name === "Edit Show Details - Details tab (All minus Show Season Budgets)" && obj.view === 1 && obj.edit === 1));
            canEditShowDetailsExceptBudget = canEditShowDetailsExceptBudget.length > 0 ? true : false;
            canEditShowSeasonBudgets = permissions.filter((obj, inex) => (obj.permission_name === "Edit Show Details - Details tab (Show Season Budgets section)" && (obj.view === 1 && obj.edit === 1)))
            canEditShowSeasonBudgets = canEditShowSeasonBudgets?.length > 0 ? true : false;
        }
    }
    componentDidUpdate(prevProps,prevState){
        if(this.props.initialRecordData1 !== prevProps.initialRecordData1){
            this.setState({
                initialRecordData:this.props?.initialRecordData1
            })
        }
    }
    componentDidMount() {
        this.getPermissions(this.props.permissionArr);
        initialRecordData = JSON.parse(JSON.stringify(this.props.initialRecordData1));
        let initialMinutes = this.props.durationOptions.filter(item => item.value === initialRecordData?.show_duration_id ? item : null);
        if (initialMinutes?.length > 0) {
            initialRecordData["initialMinutes"] = initialMinutes[0].minutes;
            initialRecordData["duration_seconds"] = parseInt(0);
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    ["initialMinutes"]: initialMinutes[0].minutes,
                    ["duration_seconds"]: parseInt(0),
                },
            }));
        } else {
            initialRecordData["initialMinutes"] = parseInt(0);
            initialRecordData["initialSeconds"] = parseInt(0);
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    ["initialMinutes"]: parseInt(0),
                    ["initialSeconds"]: parseInt(0),
                },
            }));
        }
}
}
export default withUserContext(EditShowDetailsTabModal);
