import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
} from "mdb-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import AddressComponent from "../../EpisodeAndClearence/CommonAddressComponent";
import { productionEntityConfig, addressConfig } from "./Config";
import ClearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import {validateAllPhone, formatPhoneNumbers, validateNumbersOnly, validateCharNumOnly } from '../../../Common/Helper';
import { withUserContext } from "../../../contexts/UserContext";
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import axios from 'axios';

class ProductionEntities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      existingProdEntities: [],
      allEntities: [],
      prodEntityDetails: JSON.parse(
        JSON.stringify({ ...productionEntityConfig })
      ),
      mode: "default",
      productionGroupOptions: [],
      divisionOptions:[],
      isPosting: false,
      postInitiated: false,
      isFetchingProductionEntities: false,
      isLoading: false,
      selectedEntity: null,
      searchEntity:null,
      isProductionGroupFetching: false,
      searchCancelToken: null,
      isReset: false
    };
  }

  componentDidMount() {
    this.getProductionEntities("PRODUCTION_ENTITY", "");
    // this.getProductionGroups(); 
    this.getDivisions();
  }

  getDivisions = () => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/staticData?staticTable=division', this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
            let allowedDivsions = this.props?.userContext?.active_tenant?.division_role?.map(item => item?.division_id)
            this.setState({ divisionOptions: formattedList?.filter(ele => allowedDivsions?.includes(ele?.value)) });
        },
            (err) => {
                console.log("Error in fetching Tenant Details:", err)
            })
}

  getProductionEntities = (entityValue, searchString,currentItem = null) => {
    this.setState({ isFetchingProductionEntities: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${entityValue}&searchString=${searchString}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        if(response.data.length > 0 ) {
          this.getProductionEntity(currentItem ? currentItem : response.data[0]?.production_entity_id)
          this.setState({
            existingProdEntities: response?.data,
            allEntities: response?.data,
            isFetchingProductionEntities: false,
          });
      } else {
          this.setState({ prodEntityDetails: JSON.parse(
            JSON.stringify({ ...productionEntityConfig })
          ),selectedId:null,
            existingProdEntities: [], allEntities: [], isFetchingProductionEntities: false,  mode: "edit" })
      }
      })
      .catch((err) => {
        this.setState({ isFetchingProductionEntities: false });
      });
  };

  getProductionGroups = (entityValue,searchString) => {
    if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken, isProductionGroupFetching: true });
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${entityValue}&searchString=${searchString}`, 
          cancelToken, this.props.userContext?.active_tenant?.tenant_id)
      .then(
      (response) => {
        let formattedList = response.data?.map((item) => ({
          value: item.production_group_id,
          text: item.production_group_name
        }));
        this.setState({ productionGroupOptions: formattedList, isProductionGroupFetching: false ,isReset: false});
      },
      (err) => {
        console.log("Error in fetching production groups:", err);
      }
    );
  };
  getProductionEntity = (id) => {
    this.setState({ isLoading: true, selectedEntity:id });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/productionEntity?production_entity_id=${id}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        this.setState({
          prodEntityDetails: response.data[0],
          mode: "edit",
          isLoading: false,
          isReset: false
        });
      })
      .catch((err) => {
        console.log("Error in fetching production entity:", err);
        this.setState({ isLoading: false });
      });
  };

  handleGeneralEdit = (field, value) => {
    this.setState((prevState) => ({
      prodEntityDetails: {
        ...prevState.prodEntityDetails,
        [field]: value,
      },
      postInitiated:false,
    }));
  };

  handleSubmit = () => {
    this.setState({postInitiated: true})
    if(this.validateErrors(this.state.prodEntityDetails)) {
      let productionEntityDetails = {...this.state.prodEntityDetails};
      //  productionEntityDetails.country = productionEntityDetails?.country_id;
      this.setState({ postInitiated: false,isPosting: true });
      ClearTrackService.postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/productionEntity`,
        productionEntityDetails,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.setState({ isPosting: false });
            this.props?.toastMessageNotification(true, "fail");
          } else {
            this.setState({
              postInitiated: false,
              isPosting: false,
              mode: "default",
            });
            this.props?.toastMessageNotification(true, "success");
          }
          if(!this.state.prodEntityDetails?.production_entity_id) {
            this.getProductionEntities("PRODUCTION_ENTITY", "", response?.data[0]?.production_entity_id);
          } else {
              if(this.state.existingProdEntities?.find(item => item?.company === this.state.prodEntityDetails?.company))
                { this.getProductionEntity(response?.data[0]?.production_entity_id) }
              else  {
                  this.getProductionEntities("PRODUCTION_ENTITY", "", response?.data[0]?.production_entity_id);
              }
          }
        },
        (err) => {
          this.props?.toastMessageNotification(true, "fail");
          this.setState({ postInitiated: false, isPosting: false });
        }
      ); 
    }
  };

  validateErrors = (postData) => {
    let canSubmit = postData?.production_group_id && postData?.company && 
                    (postData?.phone ? validateAllPhone(postData?.phone) : true) 
                    //&&
                    // (postData?.zip ? validateCharNumOnly(postData?.zip) && 
                    //                 (postData?.country === 'United States' ? 
                    //                 postData?.zip?.length <= 5 :  postData?.zip?.length <= 10 ): true)
    return canSubmit
  }

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="prodEntityContainer">
        <MDBRow>
          <span className="description">
          Selectable Production Entities for a Show/Season.
          </span>
        </MDBRow>
        <MDBRow>
          <MDBCol md={3} className="existing-records-heading">
            <div className="display-flex">
              <BasicLabel
                type={"darkFieldHeading"}
                text="Existing Production Entities"
              />
              <span className="add-prod-entity-btn">
                <BasicButton
                  type="inline"
                  color="primary"
                  text={" "}
                  icon={"plus-circle"}
                  disabled={permissionValue.edit === 0}
                  onClick={() =>{
                    this.setState({ 
                      prodEntityDetails: JSON.parse(
                        JSON.stringify({ ...productionEntityConfig })
                      ),selectedEntity:null,
                    }, () => {
                      let data = {...this.state.prodEntityDetails}
                      data.source_company_code = "2300"
                      this.setState({prodEntityDetails: data})
                      let checkedDivision = this.state.divisionOptions[0]
                      this.handleGeneralEdit('division_id', checkedDivision?.value)
                      this.handleGeneralEdit('division_name', checkedDivision?.label)
                    })
                  }
                  }
                />
              </span>
            </div>
            <div className="search-entity">
              <BasicTextField
                id="prodEntity"
                placeholder={"- Search Production Entities -"}
               value={this.state.prodEntityDetails?.production_entity}
                onChange={(e) => {
                  this.handleGeneralEdit(
                    "production_entity",
                    e.target.value
                  )
                  if (e.target.value.length === 0) {
                    this.getProductionEntities("PRODUCTION_ENTITY", "");
                  }
                 
                  if (e.target.value !== null || e.target.value !== "") {
                    var newArray = this.state.allEntities.filter((d) => {
                      return (d.company?.toString().toLowerCase())?.indexOf(e.target.value.toLowerCase()) > -1
                    });
                    this.setState({
                      existingProdEntities: newArray
                    })
                  }  
                }
                }
              />
            </div>
            <div className="existing-records">
              {this.state.isFetchingProductionEntities ? (
                <div className="contentLoader">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {this.state.existingProdEntities.length > 0 ? (
                    this.state.existingProdEntities?.map((item, index) => (
                      <div
                        key={index}
                        className="entity-item"
                        onClick={() =>
                          this.getProductionEntity(item.production_entity_id)}
                      >
                        <span
                          className={`${
                            item.production_entity_id === this.state.selectedEntity ? "highlight-entity" : ""
                          }`}
                        >
                          {item.company}
                        </span>
                      </div>
                    ))
                  ) : (
                    <span className="no-content">No Data Found</span>
                  )}
                </>
              )}
            </div>
          </MDBCol>
            <MDBCol className="entity-details">
              <div className="display-flex">
                <BasicLabel
                  type={"darkFieldHeading"}
                  text={
                    this.state.prodEntityDetails?.company ||
                    "Production Entity"
                  }
                />
                {permissionValue.edit === 1 &&
                <BasicButton
                  type="inline"
                  color="primary"
                  disabled={!this.state.prodEntityDetails?.company || !this.state.prodEntityDetails?.production_group_id
                  ||!this.state.prodEntityDetails?.co || !this.state.prodEntityDetails?.rapid_cue_name || !this.state.prodEntityDetails?.rapid_cue_id}
                  text={
                    this.state.isPosting ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Save"
                    )
                  }
                  icon={"save"}
                  onClick={this.handleSubmit}
                />
                }
              </div>

              {this.state.isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress size={24} />
                </div>
              ) : (
                <MDBCard className="entity-card">
                  <MDBCardBody>
                    <MDBCardText>
                      <MDBRow>
                        <MDBCol md={3}>
                          <SearchSelectField
                            id="prodGroup"
                            label="Production Group"
                            isMandatory={true}
                            searchSelect={true}
                            showMandatory={this.state.postInitiated || false}
                            options={this.state.productionGroupOptions}
                            loading={this.state.isProductionGroupFetching}
                            detail_selected={{value:this.state.prodEntityDetails?.production_group_id, text: this.state.prodEntityDetails?.production_group_name}}
                            placeholder="- Select Production Group -"
                            valueSelected={(e, item) =>{
                              this.handleGeneralEdit("production_group_id",item.value)
                              this.handleGeneralEdit("production_group_name",item.text)
                              this.handleGeneralEdit("selected_production_group", item)
                            }}
                            // value={
                            //   this.state.prodEntityDetails?.production_group_id
                            // }
                            searchText={ev => {
                              if (ev.target.value !== "" && ev.target.value !== null) {
                                  this.getProductionGroups("PRODUCTION_GROUP",ev.target.value)
                              }else{
                                this.setState({isProductionGroupFetching: false})
                              }}}
                          />
                        </MDBCol>
                        <MDBCol md={4} className="prod-comp">
                          <BasicTextField
                            id="company"
                            label={"Company"}
                            showMandatory={this.state.postInitiated || false}
                            isMandatory={true}
                            value={this.state.prodEntityDetails?.company || ""}
                            onChange={(e) =>
                              this.handleGeneralEdit("company", e.target.value?.length > 0 ? e.target.value : null)
                            }
                          />
                        </MDBCol>
                        <MDBCol className="marginTop p-0">
                          <BasicCheckbox
                            id={"inactive"}
                            label="Inactive"
                            checked={this.state.prodEntityDetails?.is_active === 0}
                            onChange={(e) =>
                              this.handleGeneralEdit(
                                "is_active",
                                e.target.checked ? 0 : 1
                              )
                            }
                          />
                        </MDBCol>
                        <MDBCol className="marginTop p-0">
                          <BasicCheckbox
                            id={"isUnion"}
                            label="Is Union"
                            checked={this.state.prodEntityDetails?.is_union}
                            onChange={(e) =>
                              this.handleGeneralEdit(
                                "is_union",
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        </MDBCol>
                        <MDBCol className="marginTop p-0">
                          <SelectField
                            id="production entity divsion"
                            label='Division'
                            value={this.state.prodEntityDetails?.division_id}
                            placeHolderText="-division-"
                            options={this.state.divisionOptions}
                            onChange={(e) => {
                                let checkedDivision = this.state.divisionOptions?.find(ele => ele?.value === e.target.value)
                                this.handleGeneralEdit('division_id', checkedDivision?.value)
                                this.handleGeneralEdit('division_name', checkedDivision?.label)
                            }}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md={3}></MDBCol>
                        <MDBCol md={3}>
                          <BasicTextField
                            id="shortName"
                            label={"Short Name"}
                            value={
                              this.state.prodEntityDetails?.short_name || ""
                            }
                            onChange={(e) =>
                              this.handleGeneralEdit(
                                "short_name",
                                e.target.value?.length > 0 ? e.target.value : null
                              )
                            }
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol md={6}>
                          {/* <AddressComponent /> */}
                          <AddressComponent
                            editCallback={this.handleGeneralEdit}
                            addressConfig={addressConfig}
                            addressDetails={this.state.prodEntityDetails}
                            postInitiated={this.state.postInitiated}
                            disabled={false}
                          />
                        </MDBCol>
                        <MDBCol md={6}>
                          <MDBRow>
                            <MDBCol md={4}>
                              <BasicTextField
                                id="phoneNumber"
                                label={"Phone Number"}
                                value={
                                  this.state.prodEntityDetails?.phone || ""
                                }
                                placeholder="(___) ___-____"
                                // fieldValid={ this.state.prodEntityDetails?.phone ? !validateAllPhone(this.state.prodEntityDetails?.phone) : false}
                                // inValidInput={"Please enter valid phone"}
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "phone",
                                    e.target.value?.length > 0 ? formatPhoneNumbers(e.target.value) : null
                                  )
                                }
                              />
                            </MDBCol>
                            <MDBCol md={4}>
                              <BasicTextField
                                id="co#"
                                label={"Co.#"}
                                placeholder={9999}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.prodEntityDetails?.co || ""}
                                onChange={(e) =>
                                  this.handleGeneralEdit("co", e.target.value?.length > 0 ? e.target.value : null)
                                }
                              />
                            </MDBCol>
                            <MDBCol md={4}>
                              <BasicTextField
                                id="source_company_code"
                                label={"Source Company Code"}
                                placeholder={2300}
                                // isMandatory={true}
                                // showMandatory={this.state.postInitiated || false}
                                // disabled = {this.state.isReset}
                                value={this.state.prodEntityDetails?.source_company_code}
                                onChange={(e) =>
                                  this.handleGeneralEdit("source_company_code", e.target.value?.length > 0 ? e.target.value : null)
                                }
                              />
                            </MDBCol>
                          </MDBRow>.
                          <MDBRow className="rapidcue-helpertext">
                            <span>
                              RapidCue Name and ID must be requested from the
                              RapidCue team at ASCAP/BMI
                            </span>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md={7}>
                              <BasicTextField
                                id="rapidcue_name"
                                label={"RapidCue Name"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={
                                  this.state.prodEntityDetails?.rapid_cue_name || ""
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "rapid_cue_name",
                                    e.target.value?.length > 0 ? e.target.value : null
                                  )
                                }
                              />
                            </MDBCol>
                            <MDBCol>
                              <BasicTextField
                                id="rapidcue_id"
                                label={"RapidCue ID"}
                                isMandatory={true}
                                placeholder={"RapidCue Id"}
                                showMandatory={this.state.postInitiated || false}
                                value={
                                  this.state.prodEntityDetails?.rapid_cue_id ||
                                  ""
                                }
                                onChange={(e) => validateNumbersOnly(e.target.value) ?
                                  this.handleGeneralEdit(
                                    "rapid_cue_id",
                                    e.target.value?.length > 0 ? e.target.value : null
                                  ) : null
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              )}
            </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(ProductionEntities);
