
export const AllBatchHeading = {
    headings: [
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Episode #",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Composition",
            icon: "",
            width: "20%",
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "20%",
        },
        {
            headingLabel: "Share",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "10%",
        },
    ],
    dataNodes: ["batch_no","episode_number", "composition", "payee_company", "share", "total_fee"],
    primaryKey: "clearance_licensor_optional_check_requests_id",
    highlightRow:["generated_date"]
}
export const batchesHeading = {
    headings: [
        {
            headingLabel: "Select",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Episode #",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Composition",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Share",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "10%",
        },
    ],
    dataNodes: ["check","batch_no","episode_number", "composition", "payee_company","share", "total_fee"],
    primaryKey: "clearance_licensor_optional_check_requests_id",
    highlightRow:["generated_date"]
}

export const AllMTBatchHeading = {
    headings: [
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Episodes",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Composition",
            icon: "",
            width: "20%",
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "20%",
        },
        {
            headingLabel: "Share",
            icon: "",
            width: "10%",
        },

        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "10%",
        },
    ],
    dataNodes: ["batch_no","eps_count", "composition", "payee_company", "share", "total_fee"],
    primaryKey: "main_title_licensor_optional_check_requests_id",
    highlightRow:["generated_date"]
}
export const MTBatchesHeading = {
    headings: [
        {
            headingLabel: "Select",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Bch",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Episodes",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Composition",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Payee",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Share",
            icon: "",
            width: "10%",
        },
        {
            headingLabel: "Tot. Fee",
            icon: "",
            width: "10%",
        },
    ],
    dataNodes: ["check","batch_no","eps_count", "composition", "payee_company","share", "total_fee"],
    primaryKey: "main_title_licensor_optional_check_requests_id",
    highlightRow:["generated_date"]
}

