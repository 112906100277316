import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import DateField from '../../SharedComponents/DateField/DateField';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import clearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from "../../../constants/constants";
import { filterConfig, rightsFilter } from './config';
import axios from 'axios';
export default withUserContext(class Rights extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterSearchList: [],
            searchCancelToken: null,
            clearFilters: this.props?.clearFilters,
            sortBy: "Show",
            isLicensorFetching: false,
            selectedLicensorForSearch: null,
            isShowFetching: false,
            selectedShowForSearch: null,
            filterList: {
                showList: [],
                seasonList: [],
                termsList: [],
                territoryList: [],
                licensorList: [],
                showBasedSeasonList: [],
                rightsList: [{ label: "Select", value: null },
                { label: "Initial Rights", value: 1 },
                { label: "Alt Initial Rights", value: 2 },
                { label: "Optional Rights", value: 3 }
                ],
                expiringList: [],
                // expiringList: [{ label: "Select", value: null }, { label: "Expiring", value: 1 }, { label: "OptedExpiring", value: 2 }],
                acquiredList: [{ label: "Select", value: null }, { label: "Yes", value: "1" }, { label: "No", value: "0" }]
            }
        }
    }

    componentDidMount() {
        document.title = 'Rights'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.fetchEntityList('SEASON', filterConfig.season, 'seasonList')
        this.fetchEntityList('TERRITORY', filterConfig.territory, 'territoryList')
        this.fetchEntityList('TERM', filterConfig.term, 'termsList')
        // this.fetchEntityList('RIGHTS', filterConfig.rights, 'rightsList')
        //this.getLicensorDetails("LICENSOR", filterConfig.licensor, "licensorOptions", null);
        this.getExpiryOptions()
        this.getShowDetails("SHOW", filterConfig.show, "showList", "");
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.fetchEntityList('SEASON', filterConfig.season, 'seasonList')
            this.fetchEntityList('TERRITORY', filterConfig.territory, 'territoryList')
            this.fetchEntityList('TERM', filterConfig.term, 'termsList')
            this.getExpiryOptions()
            this.getShowDetails("SHOW", filterConfig.show, "showList", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Show" })
        }
        if (this.props?.userContext.dashboardName === "Rights" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    getExpiryOptions = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_term`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                formattedList.unshift({ label: "Select", value: null })
                let filterList = this.state.filterList;
                filterList.expiringList = formattedList
                this.setState({ filterList });
            },
                (err) => {
                    console.log("Error in fetching Union:", err)
                })
    }


    fetchEntityList = (entity, config, node) => {
        this.setState({ isLoading: true })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({
                    value: item[config.id],
                    label: item[config.name],
                }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
                this.setState({ isLoading: false })
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching entity list:", err)
                })
    }

    getLicensorDetails = (entity, config, node, searchString) => {
        let option = {
            "searchString": searchString
        }
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken, isLicensorFetching: true });
        clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
            .then(response => {
                let formattedList = response.data?.map((item) => ({
                    value: item[config.id],
                    text: item[config.name],
                }));
                this.updateListState(formattedList, node, "filterList");
                this.setState({ isLicensorFetching: false })
            },
                (err) => {
                    console.log("Error in fetching license data:", err)
                    this.setState({ isLicensorFetching: false })
                })

    }

    getShowDetails = (entity, config, node, searchString) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": searchString
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            this.setState({ isShowFetching: true })
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name],
                    }));
                    this.updateListState(formattedList, node, "filterList");
                    this.setState({ isShowFetching: false })
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }
    selectedShowBasedSeason = (newValue) => {
        this.props.updateListState(null, 'season', 'rightsSelectedFilters')
        this.handleFilterListSelection('season', null, null, 'season')
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/seasonsBasedOnShows?show_id=${newValue.value}`,
                this.props?.userContext?.active_tenant?.tenant_id
            ).then(
                (response) => {
                    let selectedShow = response?.data[0]?.seasons?.find(
                        (item) => response?.data[0]?.show_id === newValue.value
                    )?.show_season_id;
                    let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                        (item, index, data) =>
                            index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                    ) : [];
                    let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                        value: item.season_id,
                        label: item.season_name,
                    })) : [];
                    let seasonBasedEpisodes = response?.data[0]?.seasons;
                    this.setState({ seasonBasedEpisodes })
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                    }
                })
            .catch(error => {
                console.log("error--", error)
            })
    }

    handleFilterListSelection = (type, val, name, node) => {
        let list = this.state.filterSearchList.filter(item => item.type !== type);
        this.setState({ filterSearchList: list });
        let filteredRights = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }
        let check = filteredRights.filter((item, index) => {
            if (item.type === type) {
                return filteredRights[index] = newFilterValue

            }
        })
        if (check.length === 0) {
            filteredRights.push(newFilterValue)
        }
        this.setState({ filterSearchList: filteredRights })

        let rightsJson = {
            right_type_search_ids: filteredRights.find((item) => item.type === "rightsType")?.value || null,
            licensor_search_ids: filteredRights.find((item) => item.type === "licensor")?.value || null,
            expiring_search_ids: filteredRights.find((item) => item.type === "expiring")?.value || null,
            term_search_ids: filteredRights.find((item) => item.type === "term")?.value || null,
            territory_search_ids: filteredRights.find((item) => item.type === "territory")?.value || null,
            opt_expire_search_ids: filteredRights.find((item) => item.type === "optExpire")?.value || null,
            expire_date_search_text: filteredRights.find((item) => item.type === "expireDate")?.label || null,
            acquired_search_flag: filteredRights.find((item) => item.type === "acquired")?.value || null,
            show_search_ids: filteredRights.find((item) => item.type === "show")?.value || null,
            season_search_ids: filteredRights.find((item) => item.type === "season")?.value || null,
            show_year_search_text: filteredRights.find((item) => item.type === "showYear")?.label || null,
            episode_title_search_text: filteredRights.find((item) => item.type === "episodeName")?.value || null,
            episode_no_search_text: filteredRights.find((item) => item.type === "episode")?.label || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: 10,
            page_no: 1
        }
        this.props.updateFilteredList(filteredRights, rightsJson)
    }

    getShowBasedEpisodes =(showSeasonId) => {
        this.setState({ isFetchingEpisodes: true });
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
                this.props.userContext?.active_tenant?.tenant_id,
                1
            )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item.show_season_episode_id,
                        label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "seasonBasedEpisodeList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'filterList')
                    }
                },
                (err) => {
                    console.log("Error in fetching Details:", err);
                }
            );
    };


    render() {
        return (
            <div className="filterFieldsContainer">
                <MDBRow>
                    <MDBCol md={8}>
                        <MDBRow>
                            <MDBCol md={12}>Licenses</MDBCol>
                            <MDBCol md={4}>
                                <SelectField
                                    id="rightsType"
                                    value={this.props.rightsSelectedFilters?.rightsType || null}
                                    options={this.state.filterList?.rightsList || []}
                                    placeHolderText=" -  Rights Type  -  "
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.rightsList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'rightsType', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('rightsType', selectedValue?.value, selectedValue?.label, 'rightsType')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <SelectField
                                    id="term"
                                    value={this.props.rightsSelectedFilters?.term || ''}
                                    placeHolderText="- Term -"
                                    options={this.state.filterList?.termsList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.termsList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'term', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('term', selectedValue?.value, selectedValue?.label, 'term')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="mt-2">
                                <DateField
                                    id="expireDate"
                                    onHoverDateText="- Exp. Date Before -"
                                    placeholder="- Exp. Date Before -"
                                    value={this.props?.rightsSelectedFilters?.expireDate || null}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('expireDate', 'expireDate', e.target.value, 'expireDate');
                                        this.props.updateListState(e.target.value, 'expireDate', 'rightsSelectedFilters')
                                    }} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <SearchSelectField
                                    id="licensor"
                                    placeholder="- Licensor -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    loading={this.state.isLicensorFetching}
                                    detail_selected={this.props?.rightsSelectedFilters?.licensor ? this.state.selectedLicensorForSearch : null}
                                    // detail_selected={this.props?.rightsSelectedFilters?.licensor ? {
                                    //     value: this.props?.rightsSelectedFilters?.licensor,
                                    //     text: this.state.filterList?.licensorList.find(item => item.value === this.props?.rightsSelectedFilters?.licensor)?.text
                                    // } : null}
                                    options={this.state.filterList?.licensorList || []}
                                    valueSelected={(e, newValue) => {
                                        let selectedShow = this.state.filterList?.licensorList?.find(item => item.value === (newValue != null ? newValue.value : null));
                                        this.props.updateListState(newValue != null ? newValue.value : null, 'licensor', 'rightsSelectedFilters')
                                        this.setState({ selectedLicensorForSearch: newValue })
                                        this.handleFilterListSelection("licensor", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "licensor")
                                    }}
                                    searchText={ev => {
                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                            this.getLicensorDetails("LICENSOR", filterConfig.licensor, "licensorList", ev.target.value);
                                        } else {
                                            this.setState({ isLicensorFetching: false })
                                        }
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="initialRights-territory"
                                    value={this.props.rightsSelectedFilters?.territory || ''}
                                    placeHolderText="- Territory - "
                                    options={this.state.filterList?.territoryList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.territoryList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'territory', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('territory', selectedValue?.value, selectedValue?.label, 'territory')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="acquired"
                                    placeHolderText="- Acquired -"
                                    value={this.props.rightsSelectedFilters?.acquired || null}
                                    options={this.state.filterList?.acquiredList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.acquiredList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'acquired', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('acquired', selectedValue?.value, selectedValue?.label, 'acquired')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={3}></MDBCol>
                            {/* <MDBCol md={3}>
                                <SelectField
                                    id="expiring"
                                    value={this.props.rightsSelectedFilters?.expiring || ''}
                                    placeHolderText="- Expiring -"
                                    options={this.state.filterList?.expiringList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.expiringList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'expiring', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('expiring', selectedValue?.value, selectedValue?.label, 'expiring')
                                    }}
                                />
                            </MDBCol> */}
                            <MDBCol md={1}></MDBCol>
                            <MDBCol md={2} className="lh-1">
                                <SelectField
                                    id="optionalExpiration"
                                    value={this.props.rightsSelectedFilters?.optExpire || ''}
                                    placeHolderText="- Optional Expiration -"
                                    options={this.state.filterList?.expiringList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.expiringList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'optExpire', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('optExpire', selectedValue?.value, selectedValue?.label, 'optExpire')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={4}>
                        <MDBRow>
                            <MDBCol md={12}>Show</MDBCol>
                            <MDBCol md={6}>
                                <SearchSelectField
                                    id="show"
                                    placeholder="- Show -"
                                    width="100%"
                                    searchSelect={true}
                                    multiple={false}
                                    loading={this.state.isShowFetching}
                                    detail_selected={this.props.rightsSelectedFilters?.show ? this.state.selectedShowForSearch : null}
                                    // detail_selected={this.props?.rightsSelectedFilters?.show ? {
                                    //     value: this.props?.rightsSelectedFilters?.show,
                                    //     text: this.state.filterList?.showList.find(item => item.value === this.props?.rightsSelectedFilters?.show)?.text
                                    // } : null}
                                    options={this.state.filterList?.showList || []}
                                    valueSelected={(e, newValue) => {
                                        if (newValue) {
                                            this.setState({ selectedShowForSearch: newValue })
                                            this.selectedShowBasedSeason(newValue)
                                            // let selectedShow = this.state.filterList?.showList?.find(item => item.value === (newValue != null ? newValue.value : null));
                                            this.props.updateListState(newValue != null ? newValue.value : null, 'show', 'rightsSelectedFilters')
                                            this.handleFilterListSelection("show", (newValue != null ? newValue?.value : null), newValue != null ? newValue?.text : null, "show")
                                        } else {
                                            this.setState({ selectedShowForSearch: null }, () => {
                                                if (!this.state.selectedShowForSearch) {
                                                    this.props.updateListState(null, 'season', 'rightsSelectedFilters')
                                                    this.handleFilterListSelection('season', null, null, 'season')
                                                }
                                            })
                                            this.props.updateListState(null, 'show', 'rightsSelectedFilters')
                                            this.handleFilterListSelection("show", null, null, "show")
                                        }
                                    }}
                                    searchText={ev => {
                                        // dont fire API if the user delete or not entered anything
                                        if (ev.target.value !== "" && ev.target.value !== null) {
                                            this.getShowDetails("SHOW", filterConfig.show, "showList", ev.target.value);
                                        } else {
                                            this.setState({ isShowFetching: false })
                                        }
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={6} className="lh-1">
                                <SelectField
                                    id="episode"
                                    // label={"Seasons"}
                                    placeHolderText="- Episode Name -"
                                    options={this.props?.rightsSelectedFilters?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption || []}
                                    width="80%"
                                    value={this.props?.rightsSelectedFilters?.episodeName || null}
                                    onChange={(e) => {
                                        let selectedValue = (this.props?.rightsSelectedFilters?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'episodeName', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('episodeName', selectedValue?.value, selectedValue?.label, 'episodeName')
                                    }}
                                />
                                {/* <BasicTextField
                                    id="episodeName"
                                    placeholder="- Episode Name -"
                                    value={this.props.rightsSelectedFilters?.episodeName || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('episodeName', 'episodeName', e.target.value, 'episodeName');
                                        this.props.updateListState(e.target.value, 'episodeName', 'rightsSelectedFilters')
                                    }}
                                /> */}

                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={3} className="lh-1">
                                <SelectField
                                    id="season"
                                    value={this.props.rightsSelectedFilters?.season || ''}
                                    placeHolderText="- Season -"
                                    options={this.props.rightsSelectedFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList || []}
                                    onChange={(e) => {
                                        let selectedValue = (this.props.rightsSelectedFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'season', 'rightsSelectedFilters')
                                        this.handleFilterListSelection('season', selectedValue?.value, selectedValue?.label, 'season')
                                        let selectedShowSeason =
                                            this.state.seasonBasedEpisodes?.find(
                                                (item) => item.season_id === e.target.value
                                            )?.show_season_id;
                                        this.setState(
                                            { show_season_id: selectedShowSeason },
                                            () => {
                                                this.getShowBasedEpisodes(this.state.show_season_id);
                                            }
                                        );
                                    }}

                                />
                            </MDBCol>
                            <MDBCol md={3} className="lh-1">
                                <BasicTextField
                                    id="showYr"
                                    placeholder="- Show Yr. -"
                                    value={this.props.rightsSelectedFilters?.showYear || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('showYear', 'showYear', e.target.value, 'showYear');
                                        this.props.updateListState(e.target.value, 'showYear', 'rightsSelectedFilters')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={1}></MDBCol>
                            <MDBCol md={3} className="lh-1">
                                <BasicTextField
                                    id="episode#"
                                    placeholder="- Episode # -"
                                    value={this.props.rightsSelectedFilters?.episode || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('episode', 'episode', e.target.value, 'episode');
                                        this.props.updateListState(e.target.value, 'episode', 'rightsSelectedFilters')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div >
        )
    }
});