import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import * as Constants from '../../../../constants/constants';
import ClearTrackService from '../../../../services/service';
import { withUserContext } from '../../../../contexts/UserContext';
import { cueSheetModalConfig } from './Config';
import { CircularProgress } from "@material-ui/core";
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import './SyncLicensor.scss';

class EditCueSheetPublisherModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false,
            isLoading: false,
            isPosting: false,
            cueSheetModalDetails:JSON.parse(JSON.stringify(cueSheetModalConfig))
        };
    }

    componentDidMount = () => {
        let value = {...this.state.cueSheetModalDetails}
        value.cue_sheet_publisher_name = this.props?.cueSheetPublisherInfoDetails?.cue_sheet_publishers?.find(item => item.is_editing === true)?.cue_sheet_publisher_name;
        this.setState({cueSheetModalDetails:value})
    }

    handleOnChange = (field, value) => {
        this.setState({
            cueSheetModalDetails: {
                ...this.state.cueSheetModalDetails,
                [field]: value,
            },
        });
    };

    handleSubmit = () => {
        this.setState({  isPosting: true });
        let postJson = { 
            name: this.state.cueSheetModalDetails.cue_sheet_publisher_name,
            id: this.props?.cueSheetPublisherInfoDetails?.cue_sheet_publishers?.find(item => item.is_editing === true)?.cue_sheet_publisher_id, 
            is_active: 1, 
            table_name: "lu_cue_sheet_publisher" 
        }
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/staticData`,
            postJson,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.divisionId)
            .then(
                (response) => {
                    console.log("response", response)
                    if (response.data.error) {
                        this.setState({  isPosting: false })
                        this.props?.notificationComponent(true, "fail")
                    } else {
                        this.setState({  isPosting: false })
                        let index = this.props?.index
                        let value = {
                            value: response.data[0].id,
                            text: this.state.cueSheetModalDetails.cue_sheet_publisher_name
                        }
                        this.props?.handleOnChange('publishers', 'cue_sheet_publisher', value, index, 'update')
                        this.props?.notificationComponent(true, "success")
                        this.props?.handleCloseModal()
                    }
                },
                (err) => {
                    this.setState({ isLoading: false, isPosting: false })
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent(true, "fail")
                }
            )
    };
    render() {
        return (

            <>
                {
                    this.state.isLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (
                            <div className="cuesheetModal">
                                <MDBRow>
                                    <BasicLabel text={"Publisher name"} />
                                    <BasicTextField
                                        value={ this.state.cueSheetModalDetails.cue_sheet_publisher_name  || null }
                                        onChange={(e) => this.handleOnChange("cue_sheet_publisher_name", e.target.value)}
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={4}></MDBCol>
                                    <MDBCol md={8}>
                                        <MDBRow className={"btn-w"} >
                                            <MDBCol >
                                                <BasicButton
                                                    variant="contained"
                                                    icon={this.state.isPosting ? <CircularProgress color="inherit" size={6} /> : "save"}
                                                    text=" "
                                                    onClick={this.handleSubmit}
                                                />
                                            </MDBCol>
                                            <MDBCol>
                                                <BasicButton
                                                    variant="outlined"
                                                    icon={"times"}
                                                    text=" "
                                                    onClick={() => this.props.handleCloseModal()}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                        
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        )
                }
        
            </>
        )
    }
}
export default withUserContext(EditCueSheetPublisherModal);