import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Chip from '@material-ui/core/Chip'
import BasicButton from "../SharedComponents/BasicButton/BasicButton"
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { NetworkLockedOutlined } from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CircularProgress} from '@material-ui/core';
import { dashboardCreateEntityPermissions } from './Config';

let editShowModalPermission, editClearanceModalPermission, canEditSongPermission, canEditLicensorInformation, createMusicSuperviors, canEditLicenseTracking,canEditPayeeInformation, canImportADDRPermission
export default class ContentHeaderFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewSongModal: false,
            showNewClearanceModal: false,
            openFromDashboard: false,
            setModal: null,
            classification: null,
            permissionsConfig: dashboardCreateEntityPermissions
        }
    }

    render() {
        editShowModalPermission = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "Create New" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        canImportADDRPermission = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "Import ADDR" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        editClearanceModalPermission = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "Create New" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        canEditSongPermission = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "Create New" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        canEditLicensorInformation = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "Create New" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        createMusicSuperviors = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "New MS" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        canEditLicenseTracking = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "Create New" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        canEditPayeeInformation = this.props?.commonPermissions?.find((item) => this.state.permissionsConfig?.find(per => per.buttonName === "Create New" && per.headerName.includes(this.props?.heading) && per.permissions.includes(item.permission_name)))
        return (
            <>
                <div className='firstRow'>
                    <MDBRow md={12}>
                        <MDBCol md={this.props?.heading === "Show Seasons" || (this.props?.heading === "People" || this.props?.heading === "People (SC's, MS's, ME's)") ? 2 : this.props?.heading === "Music Supervisors/Show Composers" ? 4 : 3}>
                            <h4>{this.props?.heading === "People (SC's, MS's, ME's)" ? "People" : this.props?.heading === "Can see License Tracking tab" ? "License Tracking": this.props?.heading}</h4>
                        </MDBCol>
                        {this.props?.heading === "Show Seasons" && canImportADDRPermission?.edit == 1 ?
                            <MDBCol md={2} className="import-addr-btn" xl={2}>
                                <BasicButton
                                    variant="contained"
                                    text="Import ADDR"
                                    onClick={(e) => this.props?.handleADDR(e)}
                                />
                            </MDBCol>
                            : this.props?.heading === "Music Supervisors/Show Composers" && createMusicSuperviors?.edit == 1 ?
                                <MDBCol md={2} className="musicButtons">
                                    {createMusicSuperviors && <>
                                        <BasicButton
                                            variant="contained"
                                            text="New MS"
                                            onClick={() => this.props.handleShowComposerModal({ people_type: "MUSIC_SUPERVISOR" }, null, "New")}
                                        />
                                        <BasicButton
                                            variant="contained"
                                            text="New SC"
                                            onClick={() => this.props.handleShowComposerModal({ people_type: "SHOW_COMPOSER" }, null, "New")}
                                        /> </>}
                                </MDBCol>
                                : (this.props?.heading === "People" || this.props?.heading === "People (SC's, MS's, ME's)") && this.props?.peopleModalPopupPermissions?.edit == 1 ?
                                <MDBCol md={4} className="musicButtons">
                                 <>
                                    <BasicButton
                                        variant="contained"
                                        text="New MS"
                                        onClick={() => this.props.handleShowPersonModal({ people_type: "MUSIC_SUPERVISOR" }, null, "New")}
                                    />
                                    <BasicButton
                                        variant="contained"
                                        text="New SC"
                                        onClick={() => this.props.handleShowPersonModal({ people_type: "SHOW_COMPOSER" }, null, "New")}
                                            />
                                            <BasicButton
                                        variant="contained"
                                        text="New ME"
                                        onClick={() => this.props.handleShowPersonModal({ people_type: "MUSIC_EDITOR" }, null, "New")}
                                    />
                                        </>
                            </MDBCol>:null}
                        <MDBCol md={2}>
                            {this.props?.heading === "Cue Sheets" || this.props?.heading === "TV Creative Music Budget" || this.props?.heading === "Rights" || this.props?.heading === "Music Supervisors/Show Composers"|| (this.props?.heading === "License Tracking" || this.props?.heading === "Can see License Tracking tab") || (this.props?.heading === "People" || this.props?.heading === "People (SC's, MS's, ME's)") ? null :
                                this.props?.heading === "Show Seasons" && editShowModalPermission?.edit === 1 || this.props?.heading === "Clearance Records" && editClearanceModalPermission?.edit === 1 || this.props?.heading === "Songs" && canEditSongPermission?.edit === 1 || this.props?.heading === "Licensors" && canEditLicensorInformation?.edit === 1 || this.props?.heading === "Payees" && canEditPayeeInformation?.edit===1 ?
                                    <BasicButton
                                        variant="contained"
                                        text="Create New"
                                        onClick={(event) => this.props?.handleCreateNewModal(event)}
                                    /> :null}

                        </MDBCol>
                        <MDBCol md={this.props?.heading === "Music Supervisors/Show Composers" || (this.props?.heading === "People" || this.props?.heading === "People (SC's, MS's, ME's)") ? 2 : this.props?.heading === "Show Seasons" && editShowModalPermission?.edit === 1 ? 4 : this.props?.heading === "Show Seasons" && !editShowModalPermission?.edit === 1 ? 6 : 5 }></MDBCol>
                        <MDBCol md={2} className="flex justify-content-between">
                            <BasicButton
                                variant="contained"
                                text={
                                    this.props.isLoading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : (
                                      "Search"
                                    )
                                  }
                                onClick={this.props?.handleFilterSearch}
                            />
                            <BasicButton
                                variant="outlined"
                                text="Clear Filters"
                                onClick={this.props?.clearFilter}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            </>
        )
    }
}
