import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import BasicTextField from './BasicTextField/BasicTextField';
import BasicCheckbox from './BasicCheckbox/BasicCheckbox';
import SelectField from './SelectField/SelectField';
import BasicTextArea from './BasicTextArea/BasicTextArea';
import DateField from '../SharedComponents/DateField/DateField';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import { validateAllPhone, formatPhoneNumbers, formatDuration, validateEmail, validateNumbersOnly, validateNumDotOnly, validateDuration, validateNumDotWithNegative, validateNumDotWith3Decimals, getFormattedDate } from '../../Common/Helper'
import BasicButton from './BasicButton/BasicButton';
import "./shared.scss";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../contexts/UserContext';

class TableInlineEdits extends React.Component {
  constructor(props) {
    super(props);
  }

  inlineValidations(method, value) {
    switch (method) {
      case 'validateAllPhone':
        return validateAllPhone(value)
        break;
      case 'validateEmail':
        return validateEmail(value)
        break;
      case 'validateNumbersOnly':
        return validateNumbersOnly(value)
        break;
      case 'validateNumDotOnly':
        return validateNumDotOnly(value)
        break;
      case 'validateDurationSec':
        return validateNumbersOnly(value) && value < 60
        break;
      case 'validateNumDotWithNegative':
        return validateNumDotWithNegative(value)
      case 'validateNumDotWith3Decimals':
        return validateNumDotWith3Decimals(value)
      default:
        true
    }
  }

  pickInlineEdits(inputType, inputValue, index, nodeName, selectOptions, inlineObject, visibleIcon) {

    const unhideIcon = (this.props?.division_id === undefined ? (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Clearance (Admin)', 'Super Admin'].includes(item.role_name)))) 
    : (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Clearance (Admin)', 'Super Admin'].includes(item.role_name) && item.division_id === this.props?.division_id)))) ;          
    
    const unhideIconMS = (this.props?.division_id === undefined ? (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Supervisor'].includes(item.role_name)))) 
    : (this.props?.userContext?.active_tenant?.division_role?.some(item => (['Music Supervisor'].includes(item.role_name) && item.division_id === this.props?.division_id)))) ;          
    switch (inputType) {
      case 'icon':
        if (inlineObject?.fieldDisabled) {
          return <div style={{ width: this.props?.width ? this.props?.width : "" }}></div>
        }
        else if((!unhideIcon && !unhideIconMS && !visibleIcon && ["song_cue_sheet_publisher_id", "song_performer_id", "song_licensor_id", "performer_licensor_id"].includes(inlineObject?.dataNode)) || (unhideIconMS && !unhideIcon && !visibleIcon && ["song_performer_id", "song_cue_sheet_publisher_id"].includes(inlineObject?.dataNode))) {
            return null;
        }
        else {
          return <MDBIcon
          icon={inlineObject?.icon || ""}
          onClick={(e) => { this.props.handleOnlyIconColumn(inlineObject?.dataNode, index, inlineObject?.resetField, this.props?.newRowForTable ? 'new' : 'edit') }}
        />;
        }
        break;
      case 'text':
        return <BasicTextField
          id={this.props?.idForFocus ? "txt-" + nodeName + "-" + index + this.props?.idForFocus : "txt-" + nodeName + "-" + index}
          width={this.props?.width}
          value={inputValue != undefined && inputValue != null ? inputValue : ""}
          placeholder={this.props?.placeholder ? this.props?.placeholder : ''}
          fieldValid={this.props?.validateMethod && inputValue ? !this.inlineValidations(this.props?.validateMethod, inputValue) : false}
          inValidInput={this.props?.validateMessage ? this.props?.validateMessage : null}
          disabled={this.props?.fieldDisabled}
          onChange={(e) => {
            if (this.props?.validateMethod === 'validateAllPhone') {
              this.props.handleInlineEditChange(e.target.value.length > 0 ? formatPhoneNumbers(e.target.value) : null, index, nodeName)
            }
            else if (this.props?.validateMethod === 'validateDuration') {
              this.props.handleInlineEditChange(e.target.value.length > 0 ? formatDuration(e.target.value) : null, index, nodeName)
            }
            else {
              this.props.handleInlineEditChange(e.target.value.length > 0 ? e.target.value : null, index, nodeName)
            }
          }}
          InputProps={this.props?.inlineIcon ?
            {
              startAdornment: (
                <InputAdornment position="start">
                  {this.props?.inlineIcon}
                </InputAdornment>
              ),
            } : null
          }
        />;
        break;
      case 'textarea':
        return <BasicTextArea
          id={"txtarea-" + nodeName + "-" + index}
          width={this.props?.width}
          value={inputValue}
          rows={2}
          showAsLabel={false}
          onChange={(e) => this.props.handleInlineEditChange(e.target.value.length > 0 ? e.target.value : null, index, nodeName)}
          InputProps={this.props?.icon ?
            {
              endAdornment: (
                <Tooltip title={this.props?.tooltip} placement="top">
                  <InputAdornment position="end">
                    <BasicButton icon={this.props?.icon}
                      className="textarea-inline-icon"
                      text=" "
                      variant="contained"
                      onClick={this.props?.inlineIconClick}
                    />
                  </InputAdornment>
                </Tooltip>
              ),
            } : null
          }
        />;
        break;
      case 'checkbox':
        return (inputValue !== null ?
          <BasicCheckbox
            id={`cb-${nodeName}-${this.props?.newRowForTable?"new":index}-${this.props?.classNameForTable ? this.props?.classNameForTable : ""}`}
            checked={this.props?.newIsActive && (this.props?.nodeName === 'is_active' || this.props?.nodeName === 'is_cc_active') ? true : inputValue ? inputType : false}
            className={("cb-" + nodeName + "-" )+ index ?index:"new"}
            //onChange={(e) => this.props.addFilterChangeHandler((e.target.checked ? "1": "0"))}
            width={this.props?.width}
            disabled={this.props?.fieldDisabled || (this.props?.newIsActive && (this.props?.nodeName === 'is_active' || this.props?.nodeName === 'is_cc_active'))}
            onChange={(e) => this.props.handleInlineEditChange((e.target.checked ? true : false), index, nodeName)}
          /> : <> </>);
        break;
      case 'select':
        return (this.props?.isURM && this.props?.isFetchingSeason) ? <CircularProgress color="inherit" size={15} /> : 
        <SelectField
          id={"sel-" + nodeName + "-" + index}
          size="small"
          // label={name}
          width={this.props?.width}
          value={inputValue || ""}
          options={selectOptions}
          disabled={this.props?.fieldDisabled}
          onChange={(e) => this.props.handleInlineEditChange(e.target.value, index, nodeName)}
        />;
        break;
      case 'datefield':
        return <DateField
          id={"date-" + nodeName + "-" + index}
          size="small"
          // label={name}
          width={this.props?.width}
          // value={inputValue && getFormattedDate(inputValue,true) || ""}
          value={inputValue || ""}
          onChange={(e) => this.props.handleInlineEditChange(e.target.value.length > 0 ? e.target.value : null, index, nodeName)}
          disabled={this.props?.fieldDisabled}
        />;
        break;
      case 'searchselect':
        // return <SearchSelectField
        //   id={"roles-select" + nodeName + "-" + index}
        //   size="small"
        //   fieldWidth={this.props?.width}
        //   multiple={false}
        //   value={inputValue || null}
        //   options={selectOptions}
        //   onChange={(e, value) => this.props.handleInlineEditChange(value, index, nodeName)}
        // />;
        return <SearchSelectField
          id={"roles-select" + nodeName + "-" + index}
          size="small"
          placeholder="-Search-"
          fieldWidth={this.props?.width}
          multiple={false}
          detail_selected={inputValue || null}
          options={selectOptions}
          searchSelect={true}
          searchText={(e) => this.props?.searchString ? this.props?.searchString(e.target.value, this.props.nodeName) : null}
          valueSelected={(e, value) => this.props.handleInlineEditChange ? this.props.handleInlineEditChange(value, index, nodeName) : null}
          disabled={this.props?.fieldDisabled}
        />;
        break;
      case 'button':
        return <div id="inline-add-color" className="buttonField-label">
          <BasicButton
            variant="contained"
            id={"roles-select" + nodeName + "-" + index}
            size="small"
            fieldWidth={this.props?.width}
            value={inputValue || null}
            text={this.props?.text ? this.props?.text : " "}
            icon={this.props?.icon === "" ? "" : this.props?.icon}
            onClick={(e) => this.props.handleOnButtonClick(e.target.value, index, nodeName)}
          />
        </div>;
        break;
      case 'label':
        if(inputValue) {
          return <BasicLabel type={this.props?.className ?this.props?.className : '' } text={inputValue || ""} />
        } else {
          return <div style={{ width: this.props?.width ? this.props?.width : "" }}></div>;
        }
      default:
        return <div style={{ width: this.props?.width ? this.props?.width : "" }}></div>;
    }

  }


  render() {
    return (
      <>
        {this.pickInlineEdits(this.props?.inputType, this.props?.inputValue, this.props?.index, this.props?.nodeName, this.props?.selectOptions, this.props?.inlineObject, this.props?.visibleIcon)}
      </>)
  }
}

export default withUserContext(TableInlineEdits);