import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import {
    initialCostSplitConfig,
    initialHvCostAllocationConfig,
    initialOtherOptionConfig,
    includeHvOptions,
    costSplitConfig,
    hvCostAllocationConfig,
    optionCostAllocationCongif,
    costSplitPostJsonConfig,
    initialSeasonHvCostAllocationConfig,
    initialSeasonCostSplitConfig,
    initialSeasonOtherOptionConfig
} from "./config";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import TableComponent from "../../../components/SharedComponents/Table";
import MessageModal from "../../../components/SharedComponents/MessageModal";
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import "./CostSplitsDefault.scss";
import messages from '../../../Common/Messages.json';
import axios from 'axios';
import { validateNumDotOnly } from '../../../Common/Helper';
let canEditCostSplit =false;
class CostSplits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            costSplitDetails: this.props?.isMaintitle ? initialCostSplitConfig : initialSeasonCostSplitConfig,
            hvCostAllocationDetails: this.props?.isMaintitle ? initialHvCostAllocationConfig : initialSeasonHvCostAllocationConfig,
            otherOptionCostAllocDetails: this.props?.isMaintitle ? initialOtherOptionConfig : initialSeasonOtherOptionConfig,
            isOpen: false,
            hvCostAllocationList: [],
            otherOptionCostAllocationList: [],
            term_id: null,
            include_HV: null,
            is_non_streaming_perp_HV: 0,
            perp_split: 0,
            postInitiated: false,
            isError: false,
            isPosting: false,
            isFetchingOtherOptionAllocationDetails: false,
            isFetchingHvAllocationDetails: false,
            isFetchingCostSplitDetails: false,
            searchCancelToken: null,
            costSplitConfig: costSplitConfig,
            hvCostAllocationConfig: hvCostAllocationConfig,
            optionCostAllocationCongif: optionCostAllocationCongif,
            isCostSplitError: false,
            isCostSplitLoading: false,
            ishvCostSplitError: false,
            ishvCostSplitLoading: false,
            isOptionCostSplitLoading: false,
            isOptionCostSplitError: false,
            costSplits: [],
            costCenterData: [],
            filteredCostSplits:[]
        };
    }

    componentDidMount() {
        this.getHvCostAllocations();
        this.getOtherOptionCostAllocations();
        this.getEntity('costCenters', 'COST_CENTER', '');
        this.getEntity('terms', 'TERM', null);
        this.getEntity('costAllocationTerms', 'COST_ALLOCATION_TERMS', null);
        this.getEntity('rights', 'RIGHTS', '');
        this.setState({ include_HV: includeHvOptions[1].value })
        canEditCostSplit=this.props?.commonPermissions?.delete === 0?false:true
        if (!this.props?.isMaintitle) {
            let config = { ...costSplitConfig }
            config.primaryKey = "season_cost_split_id"
            this.setState({costSplitConfig:config})
        }
        if (!this.props?.isMaintitle) {
            let config = {...hvCostAllocationConfig} 
            config.primaryKey = "season_HV_cost_allocation_id"
            this.setState({ hvCostAllocationConfig: config })
        }
        else {
            let config = {...hvCostAllocationConfig} 
            if (canEditCostSplit === false) {
                let actionsToRemove = ["times"];
                let tempActions = config.actions.filter(item => !actionsToRemove.includes(item));
                config.actions = tempActions;
                this.setState({ hvCostAllocationConfig: config })
            } else {
                this.setState({ hvCostAllocationConfig: config })
            }
        }
        if (!this.props?.isMaintitle) {
            let config = {...optionCostAllocationCongif} 
            config.primaryKey = "season_option_cost_allocation_id"
            this.setState({optionCostAllocationCongif:config})
        } else {
            let config = {...optionCostAllocationCongif} 
            if (canEditCostSplit === false) {
                let actionsToRemove = ["times"];
                let tempActions = config.actions.filter(item => !actionsToRemove.includes(item));
                config.actions = tempActions;
                this.setState({ optionCostAllocationCongif: config })
            } else {
                this.setState({ optionCostAllocationCongif: config })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.showSeasonDetails?.is_cost_split !== this.props?.showSeasonDetails?.is_cost_split ||
            prevProps?.showSeasonDetails?.is_hv_cost_allocation !== this.props?.showSeasonDetails?.is_hv_cost_allocation ||
            prevProps?.showSeasonDetails?.is_other_cost_allocation !== this.props?.showSeasonDetails?.is_other_cost_allocation
        ) {
            this.getHvCostAllocations();
            this.getOtherOptionCostAllocations();
        }
    }

    getEntity = (entity, entityType, searchString) => {
        if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken, ishvCostSplitLoading:true, isOptionCostSplitLoading: true});
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`,cancelToken,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (entityType === "COST_CENTER") {
                    let costCenters = response?.data?.map(item => ({
                        label: item.cost_center_name,
                        value: item.cost_center_id,
                    }));
                    this.setState({ [entity]: costCenters })
                    //let costSplitConfig = { ...this.state.costSplitConfig }
                    let hvCostAllocationConfig = {...this.state.hvCostAllocationConfig}
                   // costSplitConfig.headings[0].selectOptions = costCenters
                    hvCostAllocationConfig.headings[1].selectOptions = costCenters
                    this.setState({hvCostAllocationConfig})
                } else if (entityType === "COST_ALLOCATION_TERMS") {
                    let costAllocTerms = response?.data?.map(item => ({
                        label: item.term_name,
                        value: item.term_id,
                    }));
                    this.setState({ [entity]: costAllocTerms, term_id: costAllocTerms[0].value })
                    this.getCostAllocationDetails(costAllocTerms[0].value,includeHvOptions[1].value)
                   // this.state.hvCostAllocationConfig.headings[0].selectOptions = terms
                }else if (entityType === "TERM") {
                    let terms = response?.data?.map(item => ({
                        label: item.term_name,
                        value: item.term_id,
                    }));
                    this.setState({ [entity]: terms })
                    this.state.hvCostAllocationConfig.headings[0].selectOptions = terms
                }
                else if (entityType === "RIGHTS") {
                    let rights = response?.data?.map(item => ({
                        label: item.right_abbr,
                        value: item.right_id,
                    }));
                    this.setState({ [entity]: rights })
                    this.state.optionCostAllocationCongif.headings[0].selectOptions = rights
                }
                this.setState({ ishvCostSplitLoading: false, isOptionCostSplitLoading: false})
            })
    }
    getCostAllocationDetails = (termId, includeHV) => {
        this.setState({ isCostSplitLoading: true })
        let seasonId = !this.props?.isMaintitle ? this.props?.seasonId : null;
        let showSeasonId=this.props?.isMaintitle ? this.props?.showSeasonDetails?.show_season_id : null;
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/costAllocation?season_id=${seasonId}&show_season_id=${showSeasonId}&term_id=${termId}&include_HV=${includeHV}`,
            this.props?.userContext?.active_tenant?.tenant_id,
            1
        ).then((response) => {
            this.setState({ isCostSplitLoading: false, costCenterData: response?.data })
            let costCenterDataList = response?.data?.find((item) => item?.is_checked )
            let costDataList= costCenterDataList?.cost_centers?.map((item) => {
                return {...item, ca_header_id :costCenterDataList?.ca_header_id }
            })
            this.setState({ costSplits: costDataList })
            this.handleShowInlineCheckBox(costDataList)
        }).catch((err) => {
            console.log("error")
            this.setState({ isCostSplitLoading: false })
        })
    }

    getHvCostAllocations = () => {
        this.setState({ isFetchingHvAllocationDetails: true })
        let url = `/showSeasonHVCostAllocation`;
        if (this.props?.costSpiltFromSeason || this.props?.showSeasonDetails?.is_hv_cost_allocation === 0 && !this.props.isMaintitle) {
            url = url + `?season_id=${this.props?.seasonId ? this.props?.seasonId : this.props?.showSeasonDetails?.season_id}`;
        } else {
            url = url + `?show_season_id=${this.props?.showSeasonDetails?.show_season_id}`
        }
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            url,
            this.props?.userContext?.active_tenant?.tenant_id,
            !this.props?.costSpiltFromSeason ? this.props?.division_id : null
        )
            .then(response => {
                this.setState({
                    hvCostAllocationList: response.data,
                    isFetchingHvAllocationDetails: false
                })
            })
    }

    getOtherOptionCostAllocations = () => {
        this.setState({ isFetchingOtherOptionAllocationDetails: true })
        let url;
        if (this.props?.costSpiltFromSeason || this.props?.showSeasonDetails?.is_other_cost_allocation === 0  && !this.props.isMaintitle) {
            url = `/showSeasonOtherOptionCostAllocation?season_id=${this.props?.seasonId ? this.props?.seasonId : this.props?.showSeasonDetails?.season_id}`;
        } else {
            url = `/showSeasonOtherOptionCostAllocation?show_season_id=${this.props?.showSeasonDetails?.show_season_id}`
        }
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            url,
            this.props?.userContext?.active_tenant?.tenant_id,
            !this.props?.costSpiltFromSeason ? this.props?.division_id : null
        )
            .then(response => {
                this.setState({
                    otherOptionCostAllocationList: response.data,
                    isFetchingOtherOptionAllocationDetails: false
                })
            })
    }

    onChangeHandler = (field, value) => {
        if (field === "term_id" || field === "include_HV") {
            this.setState({ [field]: value })
        } else if (field === "is_non_streaming_perp_HV" || field === "perp_split") {
            this.setState({ [field]: value ? 1 : 0 })
        }
        this.props.fieldChanged(true, "change");
    }

    formatPostJson = () => {
        let postJson = { ...costSplitPostJsonConfig };
        postJson.cost_split=[]
        this.state.filteredCostSplits.map((item) => {
            if (this.props?.isMaintitle) {
                postJson.cost_split.push({
                    "ca_header_id": item?.ca_header_id,
                    "ca_detail_id": item?.ca_detail_id,
                    "term_id": this.state.term_id,
                    "include_HV": this.state.include_HV,
                    "std": item?.standard_pct,
                    "lib": item?.library_pct,
                    "ad_card": item?.ad_card_pct,
                    "mt": item?.MT_pct,
                    "cost_center_id": item?.cost_center_id,
                    "show_season_id": !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_season_id : null,
                    "season_id": this.props?.seasonId,
                    "show_id": !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_id : null,
                    "show_season_cost_split_id": item.show_season_cost_split_id || null,
                    "is_checked": item?.check ? item?.check : 0,
                    "check": item?.check,
                    "is_delete": item?.is_delete ? item?.is_delete : 0
                })
            } else {
                postJson.cost_split.push({
                    "ca_header_id": item?.ca_header_id,
                    "ca_detail_id": item?.ca_detail_id,
                    "term_id": this.state.term_id,
                    "include_HV": this.state.include_HV,
                    "std": item?.standard_pct,
                    "lib": item?.library_pct,
                    "ad_card": item?.ad_card_pct,
                    "mt": item?.MT_pct,
                    "cost_center_id": item?.cost_center_id,
                    "show_season_id": !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_season_id : null,
                    "season_id": this.props?.seasonId,
                    "show_id": !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_id : null,
                    "season_cost_split_id": item.season_cost_split_id || null,
                    "is_checked": item?.check ? item?.check : 0,
                    "check": item?.check,
                    "is_delete": item?.is_delete ? item?.is_delete : 0
                })
            }
        })
        postJson.hv_cost_allocation = this.state.hvCostAllocationList;
        postJson.hv_cost_allocation?.map(item => {
            if (this.props?.isMaintitle) {
                item.show_season_HV_cost_allocation_id = (!this.props?.costSpiltFromSeason && this.props?.showSeasonDetails?.is_hv_cost_allocation === 0) ?
                null : item.show_season_HV_cost_allocation_id;
            } else {
                item.season_HV_cost_allocation_id = item.season_HV_cost_allocation_id || null;
            }
            item.show_season_id = !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_season_id : null;
            item.season_id = !this.props?.costSpiltFromSeason ? null : item.season_id;
            item.show_id = !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_id : null;
        })
        postJson.option_cost_allocation = this.state.otherOptionCostAllocationList;
        postJson.option_cost_allocation?.map(item => {
            if (this.props?.isMaintitle) {
                item.show_season_option_cost_allocation_id = (!this.props?.costSpiltFromSeason && this.props?.showSeasonDetails?.is_other_cost_allocation === 0) ?
                null : item.show_season_option_cost_allocation_id;
            } else {
                item.season_option_cost_allocation_id = item.season_option_cost_allocation_id || null;}
            item.show_season_id = !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_season_id : null;
            item.season_id = !this.props?.costSpiltFromSeason ? null : item.season_id;
            item.show_id = !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_id : null;
        })
        return postJson;
    }

    validateTotalPercentage = (postJson) => {
        let filteredList = this.state.costSplits?.filter((item) => item?.check === true)
        if (filteredList?.length > 0) {
            let costSplitValues = filteredList?.reduce(function (previousValue, currentValue) {
                return {
                    standard_pct: parseInt(previousValue.standard_pct) + parseInt(currentValue.standard_pct),
                    library_pct: parseInt(previousValue.library_pct) + parseInt(currentValue.library_pct),
                    ad_card_pct: parseInt(previousValue.ad_card_pct) + parseInt(currentValue.ad_card_pct),
                    MT_pct: parseInt(previousValue.MT_pct) + parseInt(currentValue.MT_pct),
                }
            });
            this.setErrorText(costSplitValues)
        }  
    }

    setErrorText = (costSplitValues, hvAllocationValues, otherOptionAllocationValues) => {
        if (costSplitValues?.standard_pct > 100) {
            this.setState({ csErrorText: "Standard Song Split Percentage cannot be greater than 100.", showCsError: true })
        } else if (costSplitValues?.standard_pct < 100) {
            this.setState({ csErrorText: "Standard Song Split Percentage cannot be lesser than 100.", showCsError: true })
        }

         if (costSplitValues?.library_pct > 100) {
            this.setState({ csErrorText: "Lib Percentage cannot be greater than 100.", showCsError: true })
         }
         else if (costSplitValues?.library_pct < 100) {
            this.setState({ csErrorText: "Lib Percentage cannot be lesser than 100.", showCsError: true })
        }

         if (costSplitValues?.ad_card_pct > 100) {
            this.setState({ csErrorText: "Ad Card Percentage cannot be greater than 100.", showCsError: true })
        } else if (costSplitValues?.ad_card_pct < 100) {
            this.setState({ csErrorText: "Ad Card Percentage cannot be lesser than 100.", showCsError: true })
        }

        if (costSplitValues?.MT_pct > 100) {
            this.setState({ csErrorText: "MT Percentage cannot be greater than 100.", showCsError: true })
        } else if (costSplitValues?.MT_pct < 100) {
            this.setState({ csErrorText: "MT Percentage cannot be lesser than 100.", showCsError: true })
        }
        if(costSplitValues?.standard_pct === 100 && costSplitValues?.MT_pct === 100 && costSplitValues?.ad_card_pct === 100 && costSplitValues?.MT_pct === 100 ) {
            this.setState({ csErrorText: null, showCsError: false })
        }
    }
    validateFields = (postJson) => {
        let canSubmit = false;
         postJson?.cost_split?.find((item) => {
            canSubmit= item.term_id && item.include_HV ?true:false
        })
        return canSubmit;
    }
    handleSubmit = async () => {
        this.setState({ postInitiated: true })
        let postJson = this.formatPostJson();
        await this.validateTotalPercentage(postJson)
        if (this.state.csErrorText) {
            return;
        }
        //if (this.validateFields(postJson)) {
            this.setState({ isPosting: true });
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
                `/showSeasonCostSplits`, postJson,
                this.props?.userContext?.active_tenant?.tenant_id,
                !this.props?.costSpiltFromSeason ? this.props?.division_id : null
            )
                .then(response => {
                    if (response.data.error) {
                        this.setState({ isPosting: false, postInitiated: false });
                        this.props?.notificationComponent(true, "fail");
                    } else {
                        this.setState({ isPosting: false,postInitiated: false });
                        this.props?.notificationComponent(true, "success");
                        if (!this.props?.costSpiltFromSeason) {
                            this.getHvCostAllocations();
                            this.getOtherOptionCostAllocations();
                        }
                    }
                })
       //}
    };

    handleAddNewItem = (tableName, newDataItem) => {
        this.setState({ csErrorText: null, isCostSplitError: false, ishvCostSplitError: false, isOptionCostSplitError: false })
        if (newDataItem === undefined || Object.entries(newDataItem).length === 0 || newDataItem === null) {
            tableName === "cost_splits" ? this.setState({ isCostSplitError: true }) : tableName === "hv_cost_allocation" ? this.setState({ ishvCostSplitError: true }) : tableName === "other_cost_allocation" ? this.setState({ isOptionCostSplitError: true }) : null
        } else {
            switch (tableName) {
                case 'hv_cost_allocation': {
                    let hvCostAllocationList = this.state.hvCostAllocations ? [...this.state.hvCostAllocations] :
                        [...this.state.hvCostAllocationList]
                    let hvCostAllocationDetails = { ...this.state.hvCostAllocationDetails };
                    if (newDataItem?.cost_center_id && validateNumDotOnly(newDataItem?.std) && newDataItem?.term_id) {
                        hvCostAllocationDetails.show_season_id = !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_season_id : null;
                        hvCostAllocationDetails.show_id = this.props?.showSeasonDetails?.show_id || null;
                        hvCostAllocationDetails.season_id = this.props?.costSpiltFromSeason ? this.props?.seasonId : null;
                        hvCostAllocationDetails.term_id = newDataItem?.term_id || null;
                        hvCostAllocationDetails.cost_center_id = newDataItem?.cost_center_id || null;
                        hvCostAllocationDetails.std = newDataItem?.std || null;
                        hvCostAllocationList?.push(hvCostAllocationDetails)
                        if (this.state.hvCostAllocations) {
                            let primaryList = [...this.state.hvCostAllocationList];
                            primaryList.push(hvCostAllocationDetails);
                            this.setState({
                                hvCostAllocations: hvCostAllocationList,
                                hvCostAllocationList: primaryList
                            })
                        } else {
                            this.setState({ hvCostAllocationList: hvCostAllocationList });
                        }
                    } else {
                        this.setState({ ishvCostSplitError: true })
                    }
                }
                    break
                case 'other_cost_allocation': {
                    let otherOptionCostAllocationList = this.state.otherOptionAllocations ? [...this.state.otherOptionAllocations] :
                        [...this.state.otherOptionCostAllocationList]
                    let otherOptionCostAllocDetails = { ...this.state.otherOptionCostAllocDetails };
                    if (newDataItem?.right_id && validateNumDotOnly(newDataItem?.share)) {
                        otherOptionCostAllocDetails.show_season_id = !this.props?.costSpiltFromSeason ? this.props?.showSeasonDetails?.show_season_id : null;
                        otherOptionCostAllocDetails.show_id = this.props?.showSeasonDetails?.show_id || null;
                        otherOptionCostAllocDetails.season_id = this.props?.costSpiltFromSeason ? this.props?.seasonId : null;
                        otherOptionCostAllocDetails.right_id = newDataItem?.right_id || null;
                        otherOptionCostAllocDetails.share = newDataItem?.share || null;
                        otherOptionCostAllocDetails.receiving_code = newDataItem?.receiving_code || null;
                        otherOptionCostAllocDetails.gl_code = newDataItem?.gl_code || null;
                        otherOptionCostAllocDetails.cc_code = newDataItem?.cc_code || null;
                        otherOptionCostAllocDetails.loc_code = newDataItem?.loc_code || null;
                        otherOptionCostAllocationList?.push(otherOptionCostAllocDetails)
                        if (this.state.otherOptionAllocations) {
                            let primaryList = [...this.state.otherOptionCostAllocationList];
                            primaryList.push(otherOptionCostAllocDetails);
                            this.setState({
                                otherOptionAllocations: otherOptionCostAllocationList,
                                otherOptionCostAllocationList: primaryList
                            })
                        } else {
                            this.setState({ otherOptionCostAllocationList: otherOptionCostAllocationList });
                        }
                    } else {
                        this.setState({ isOptionCostSplitError: true })
                    }

                }
                    break;
            }
        }
    }

    handleInlineEdits = (primaryListName, secondaryListName, primaryKey, id, dataItem, index) => {
        if (this.state[secondaryListName]) {
            let secondaryList = this.state[secondaryListName]
            secondaryList[index]['editing'] = true;
            this.setState({
                [secondaryListName]: secondaryList
            });
        } else {
            let primaryList = this.state[primaryListName]
            primaryList[index]['editing'] = true;
            this.setState({
                [primaryListName]: primaryList
            });
        }

    };

    saveInlineEdits = (primaryListName, secondaryListName, dataItem, index) => {
        this.setState({ isCostSplitError: false, ishvCostSplitError: false, isOptionCostSplitError: false })
        let testValue =  primaryListName === "otherOptionCostAllocationList" ?  dataItem[index].right_id && validateNumDotOnly(dataItem[index].share) :  primaryListName === "hvCostAllocationList" ? dataItem[index].cost_center_id && validateNumDotOnly(dataItem[index].std) && dataItem[index].term_id : null
        if (testValue) {
            if (this.state[secondaryListName]) {
                let primaryRenderList = [...this.state[primaryListName]]
                let secondaryRenderList = [...this.state[secondaryListName]];
                primaryRenderList[index] = dataItem[index];
                primaryListName === "otherOptionCostAllocationList" ?   primaryRenderList[index].right_id = dataItem[index].right_id :
                primaryRenderList[index].cost_center_id = dataItem[index].cost_center_id;
                primaryRenderList[index].editing = false;
                secondaryRenderList[index] = dataItem[index];
                secondaryRenderList[index].editing = false;
                this.setState({
                    [primaryListName]: primaryRenderList,
                    [secondaryListName]: secondaryRenderList
                })
            } else {
                let renderList = [...this.state[primaryListName]];
                renderList[index] = dataItem[index];
                primaryListName === "otherOptionCostAllocationList" ?   renderList[index].right_id = dataItem[index].right_id :
                renderList[index].cost_center_id = dataItem[index].cost_center_id;
                renderList[index].editing = false;
                this.setState({ [primaryListName]: renderList })
            }
            this.props.fieldChanged(true, "change");
            this.setState({ csErrorText: null })
        } else {
            primaryListName === "hvCostAllocationList" ? this.setState({ ishvCostSplitError: true }) : null;
            primaryListName === "otherOptionCostAllocationList" ? this.setState({ isOptionCostSplitError: true }) : null;
        }
    };

    handleInlineDelete = (primaryListName, secondaryListName, primaryKey, id,dataItem,index) => {
        let renderList = this.state[secondaryListName] ? [...this.state[secondaryListName]] :
            [...this.state[primaryListName]];
        if (id === null || id === undefined ){
             let removeList = renderList.findIndex((item,indexRow)=> {
                 return indexRow === index
             });
            renderList.splice(removeList,1)
            this.setState({[primaryListName]: renderList})
        } else {
            let updatedList = renderList.filter(item => {
                return item[primaryKey] !== id;
            });
            this.setState({
                [primaryListName]: renderList?.map(item => {
                    if (item[primaryKey] === id) {
                        item.is_delete = 1;
                    }
                    return item
                }),
                [secondaryListName]: updatedList
            })
        }
    }

    handleReadCallback = (primaryListName, secondaryListName, index) => {
        if (this.state[secondaryListName]) {
            let primaryRenderList = [...this.state[primaryListName]]
            let secondaryRenderList = [...this.state[secondaryListName]];
            primaryRenderList[index].editing = false;
            secondaryRenderList[index].editing = false;
            this.setState({
                [primaryListName]: primaryRenderList,
                [secondaryListName]: secondaryRenderList
            })
        } else {
            let renderList = [...this.state[primaryListName]];
            renderList[index].editing = false;
            this.setState({ [primaryListName]: renderList })
        }
    }

    handleReset = () => {
        let url = `/resetToDefaultSeason?show_season_id=${this.props?.showSeasonDetails?.show_season_id}&season_id=${this.props?.showSeasonDetails?.season_id}`
        this.setState({ isResetting: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            url,
            this.props?.userContext?.active_tenant?.tenant_id,
            !this.props?.costSpiltFromSeason ? this.props?.division_id : null
        )
            .then(response => {
                if (response.data.error) {
                    this.setState({ isResetting: false });
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({ isResetting: false, isOpen: false });
                    this.getCostAllocationDetails(this.state.term_id,this.state.include_HV)
                    this.getHvCostAllocations();
                    this.getOtherOptionCostAllocations();
                }
            })
    }
    handleShowInlineCheckBox = (CostCenterData) => {
        let costCenterList = [...CostCenterData];
        let updateCostCenterList = []
        if (costCenterList?.length > 0) {
          updateCostCenterList = this.state.costSplits?.map(item => {
              let newItem = { ...item }
            
            if(costCenterList?.find(ele => ele?.cost_center_id === item?.cost_center_id && (item?.season_cost_split_id? item?.is_checked === 1:item?.show_season_cost_split_id?item?.is_show_season_checked===1:0))){
              newItem.check = true;
            } else {
              newItem.check = false;
            }
            return newItem
          })
        } else {
          updateCostCenterList = this.state.costSplits?.map((item) => {
            let newItem = {...item}
            newItem.check = false;
            return newItem
          });
        }
        let costSplitData =  [...this.state.costSplits ];
        costSplitData = updateCostCenterList;
        this.setState({ costSplits: costSplitData });
      };
    arrayCheckUncheck = (id, dataItem) => {
        let updateList = this.state.costSplits?.map((item) => {
            let newItem = { ...item };
            if (newItem?.ca_detail_id === dataItem?.ca_detail_id) {
                newItem.check = id;
            }
            return newItem
            
        })
        let updateDataList = []
        if (this.props?.isMaintitle) {
            updateList?.find((dt) => {
                if (dt?.show_season_cost_split_id && dt?.is_show_season_checked === 1) {
                    if (dt?.is_show_season_checked === 1 && dt?.check === false) {
                        updateDataList?.push({ ...dt, show_season_cost_split_id: dt?.show_season_cost_split_id, is_delete: 1 })
                    }
                } else {
                    if (dt?.check === true && dt?.is_show_season_checked === 0) {
                        updateDataList?.push({ ...dt, show_season_cost_split_id: null, is_delete: 0 })
                    }
                }
            })
        } else {
            updateList?.find((dt) => {
                if (dt?.season_cost_split_id && dt?.is_checked === 1) {
                    if (dt?.is_checked === 1 && dt?.check === false) {
                        updateDataList?.push({ ...dt, season_cost_split_id: dt?.season_cost_split_id, is_delete: 1 })
                    }
                } else {
                    if (dt?.check === true && dt?.is_checked === 0 && dt?.season_cost_split_id === null) {
                        updateDataList?.push({ ...dt, season_cost_split_id: null, is_delete: 0 })
                    }
                }
            })
        }
        this.setState({ filteredCostSplits: updateDataList })

    }

    render() {
        return (
            <>
                {(this.state.isFetchingCostSplitDetails || this.state.isFetchingHvAllocationDetails ||
                    this.state.isFetchingOtherOptionAllocationDetails) ?
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div> :
                    <>
                        <MDBContainer fluid className="CostSplits-Container">
                        <MDBRow>
                            <span className="desc">Select Term and Include HV to see Cost Center Table</span>
                        </MDBRow>
                            <MDBRow>
                                <MDBCol md={3} className="term-select">
                                    <SelectField
                                        id="Type"
                                        label="Term"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.term_id}
                                        options={this.state.costAllocationTerms || []}
                                        onChange={(e) => {
                                            this.onChangeHandler("term_id", e.target.value)
                                            if (e.target.value && this.state.include_HV) {
                                                this.setState({costCenterData:[],costSplits:[]},()=>this.getCostAllocationDetails(e.target.value , this.state.include_HV))
                                                
                                            }
                                        }
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={2} className="include-hv-select">
                                    <SelectField
                                        id="Type"
                                        label="Include HV"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.include_HV}
                                        options={includeHvOptions}
                                        onChange={(e) => {
                                            this.onChangeHandler("include_HV", e.target.value)
                                            if (this.state.term_id && e.target.value) {
                                                this.setState({costCenterData:[],costSplits:[]},()=>
                                                this.getCostAllocationDetails(this.state.term_id, e.target.value))
                                            }
                                        }
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={5} xl={4}></MDBCol>
                                {!this.props?.costSpiltFromSeason ?
                                    <MDBCol md={2} xl={3} className="reset-btn">
                                        <BasicButton
                                            text={"Reset to Default"}
                                            onClick={() => this.setState({ isOpen: true })}
                                        />
                                    </MDBCol> : null
                                }
                            </MDBRow>
                            {this.state.isCostSplitLoading ? (
                                    <div style={{ textAlign: "center" }}>
                                        <CircularProgress size={24} />
                                    </div>
                                ):<>
                            <MDBRow>
                                {this.state.costCenterData?.length > 0 && this.state.costCenterData?.map((item) =>
                                    <MDBCol md={12}>
                                        <BasicCheckbox
                                            disabled
                                            id={`${item.ca_header_id}`}
                                            label={`${item.description}`}
                                            checked={item.is_checked?item.is_checked:0}
                                            onChange={(e) => this.onChangeHandler("is_checked", e.target.checked)}
                                        />
                                    </MDBCol>)}
                            </MDBRow>
                            <MDBRow className="field-width mt-1">
                                            {/* {this.state.isCostSplitError ? <span className="errorText">Please Enter all field's </span> : this.state.isCostSplitLoading = false} */}
                                            
                                        {this.state.costCenterData?.length > 0 && <>
                                            <TableComponent
                                                className="id-table"
                                                list={this.state.costSplits || []}
                                                config={this.state.costSplitConfig}
                                                arrayCheckUncheck={this.arrayCheckUncheck}
                                            />
                                        </>}
                                            
                            </MDBRow>
                                    </>}
                            <MDBRow>
                                <MDBCol className="hv-label">
                                    <BasicLabel
                                        type={"subHeading"}
                                        text={"Home Video Cost Allocation"}
                                        className='table-name'
                                    />
                                    {this.state.ishvCostSplitLoading ? (
                                        <div style={{ textAlign: "center" }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    ) : (
                                            <>
                                                {this.state.ishvCostSplitError ? <span className="errorText">Please Enter all field's Allocation</span> : this.state.ishvCostSplitLoading = false}
                                                {(this.state.hvCostAllocationConfig?.headings[0]?.selectOptions?.length > 0 &&
                                                    this.state.hvCostAllocationConfig?.headings[1]?.selectOptions?.length > 0) &&
                                                    <>
                                                        <TableComponent
                                                            list={this.state.hvCostAllocations ? this.state.hvCostAllocations :
                                                                this.state.hvCostAllocationList
                                                            }
                                                            className={"agentList"}
                                                            config={this.state.hvCostAllocationConfig}
                                                            handleAddNewItem={this.handleAddNewItem.bind(this, 'hv_cost_allocation')}
                                                            editCallback={
                                                                this.handleInlineEdits.bind(
                                                                    this,
                                                                    'hvCostAllocationList',
                                                                    'hvCostAllocations',
                                                                    'show_season_HV_cost_allocation_id'
                                                                )
                                                            }
                                                            saveCallback={this.saveInlineEdits.bind(this, 'hvCostAllocationList', 'hvCostAllocations')}
                                                            handleDelCallBack={
                                                                this.handleInlineDelete.bind(
                                                                    this,
                                                                    'hvCostAllocationList',
                                                                    'hvCostAllocations',
                                                                    this.props?.isMaintitle ? 'show_season_HV_cost_allocation_id' : 'season_HV_cost_allocation_id'
                                                                )
                                                            }
                                                            readCallback={this.handleReadCallback.bind(this, 'hvCostAllocationList', 'hvCostAllocations')}
                                                            isLoading={this.props.isLoading || false}
                                                            addItemToList={this.props?.commonPermissions?.edit === 1}
                                                        />
                                                    </>
                                                }
                                            </>
                                        )}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                {this.props?.isMaintitle && <MDBCol className="other-option-label">
                                    <BasicLabel
                                        type={"subHeading"}
                                        text={"Other Option Cost Allocation"}
                                        className='table-name'
                                    />
                                    {this.state.isOptionCostSplitLoading ? (
                                        <div style={{ textAlign: "center" }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    ) : (
                                        <>
                                            {this.state.isOptionCostSplitError ? <span className="errorText">Please Enter Rights and share field's Allocation</span> : this.state.isOptionCostSplitLoading = false}
                                            {this.state.optionCostAllocationCongif?.headings[0]?.selectOptions?.length > 0 &&
                                                <>
                                                    <TableComponent
                                                        className="other-cost-allocation"
                                                        list={this.state.otherOptionAllocations ? this.state.otherOptionAllocations :
                                                            this.state.otherOptionCostAllocationList
                                                        }
                                                        config={this.state.optionCostAllocationCongif}
                                                        handleAddNewItem={this.handleAddNewItem.bind(this, 'other_cost_allocation')}
                                                        editCallback={
                                                            this.handleInlineEdits.bind(
                                                                this,
                                                                'otherOptionCostAllocationList',
                                                                'otherOptionAllocations',
                                                                'show_season_option_cost_allocation_id'
                                                            )
                                                        }
                                                        saveCallback={this.saveInlineEdits.bind(this, 'otherOptionCostAllocationList', 'otherOptionAllocations')}
                                                        handleDelCallBack={
                                                            this.handleInlineDelete.bind(
                                                                this,
                                                                'otherOptionCostAllocationList',
                                                                'otherOptionAllocations',
                                                                this.props?.isMaintitle ? 'show_season_option_cost_allocation_id' : 'season_option_cost_allocation_id'
                                                            )
                                                        }
                                                        readCallback={this.handleReadCallback.bind(this, 'otherOptionCostAllocationList', 'otherOptionAllocations')}
                                                        isLoading={this.props.isLoading || false}
                                                        addItemToList={this.props?.commonPermissions?.edit === 1}
                                                    />
                                                </>
                                            }
                                        </>
                                    )}
                                </MDBCol>}
                            </MDBRow>
                            <MDBRow className="mt-4">
                                <MDBCol md={10}></MDBCol>
                                <MDBCol md={2}>
                                    <MDBRow>
                                        <MDBCol md={6}>
                                            <BasicButton
                                                text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                                onClick={this.handleSubmit}
                                                disabled={this.props?.commonPermissions?.edit === 0}
                                            />
                                        </MDBCol>
                                        <MDBCol md={6}>
                                            <BasicButton
                                                onClick={() => this.props.handleClose()}
                                                variant="outlined"
                                                text={"Cancel"}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                        <MessageModal
                            open={this.state.isOpen}
                            title={"Confirm"}
                            message={`Are you sure you would like to reset Cost Splits for ${this.props?.showSeasonDetails?.show_name} ${" "} 
                            ${this.props?.showSeasonDetails?.seasons?.find((item) => item?.season_id === this.props?.showSeasonDetails?.season_id)?.season_name}  (Splits will be defaulted to AdminSetup  ${" "} ${this.props?.showSeasonDetails?.seasons?.find((item) => item?.season_id === this.props?.showSeasonDetails?.season_id)?.season_name} )?`}
                            primaryButtonText={this.state.isResetting ? <CircularProgress color="inherit" size={18} /> : "confirm"}
                            secondaryButtonText={"Cancel"}
                            onConfirm={this.handleReset}
                            handleClose={(e) => this.setState({ isOpen: false })}
                        />
                        <MessageModal
                            open={this.state.showCsError}
                            title={"Saving changes failed!"}
                            message={this.state.csErrorText}
                            hideCancel={(this.state.showCsError) || false}
                            primaryButtonText={"OK"}
                            // secondaryButtonText={"Cancel"}
                            onConfirm={(e) => this.setState({ showCsError: false })}
                            handleClose={(e) => this.setState({ showCsError: false })}
                        />
                    </>
                }
            </>
        );
    }
}
export default withUserContext(CostSplits);
