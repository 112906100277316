export const LicensorTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Contacts",
        value: 2,
    },
    {
        label: "Payees",
        value: 3,
    },
    {
        label: "Cl.Records",
        value: 4,
    }
]

export const MSLicensorTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Contacts",
        value: 2,
    },
]

export const NewLicensorTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Contacts",
        value: 2,
    },
    {
        label: "Payees",
        value: 3,
    }
]
export const ShowSeasonTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Main Title",
        value: 2,
    },
    {
        label: "Cue Sheets",
        value: 3,
    },
    {
        label: "Budget",
        value: 4,
    },
    {
        label: "Business Affairs",
        value: 5,
    },
    {
        label: "People",
        value: 6,
    },
    {
        label: "Files",
        value: 7
    }
]

export const EditPayeeTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Cl.Records",
        value: 2,
    }
]

export const AddSongTabList = [
    {
        label: "Details",
        value: 1,
    },
]

export const EditSongTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "Clearance Records",
        value: 2,
    },
]

export const RemitTabListInitial = [
    {
        label: "Payee",
        value: 1,
    }
]

export const MusicEditorTabList = [
    {
        label: "Details",
        value: 1,
    },
    {
        label: "ShowSeasons",
        value: 2,
    }
]

export const UserPopUpTabList = [
    {
        label: "Details",
        value: 1
    },
    {
        label: "Credentials",
        value: 2
    },
    {
        label: "Show Seasons",
        value: 3
    }
]

export const CueSheetAcknowledgementWarningList = [
    {
        label: "Warnings",
        value: 1
    },
    {
        label : "Rejected",
        value: 2
    },
    {
        label: "Not Acknowledged",
        value: 3
    }
]

export const EditSongList = [
    {
        label: "Detils",
        value: 1
    },
    {
        label : "Clearance Records",
        value: 2
    },
]