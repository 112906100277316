export const showSeasonsFilter = {
    show: null,
    season: null,
    showYear: null,
    episodeName: null,
    episode: null,
    projEpds: null,
    compEpds: null,
    network: null,
    showDuration: null,
    internetShow: null,
    productionEntity: null,
    productionExec: null,
    estimator: null,
    clearenceRep: null,
    composers: null,
    initialRights: null,
    currentRights: null,
    finalRights: null,
    initialTerm: null,
    currentTerm: null,
    finalTerm: null,
    initialTerritory: null,
    currentTerritory: null,
    finalTerritory: null
}

export const showSeasonsTblConfig = {
    headings: [{
        headingLabel: "Show",
        icon: "",
        width: "20%",
        dataNode:"show_name"
    },
    {
        headingLabel: "Season",
        icon: "",
        width: "20%",
        dataNode:"season_name"
    },
    {
        headingLabel: "Prod. Entity",
        icon: "",
        width: "10%",
        dataNode:"production_entity_name"
    },
    {
        headingLabel: "Prod. Exec",
        icon: "",
        width: "10%",
        dataNode:"production_executive_name"
    },
    {
        headingLabel: "Clearance Rep",
        icon: "",
        width: "10%",
        dataNode:"clearance_rep_name"
    },
    {
        headingLabel: "Network",
        icon: "",
        width: "10%",
        dataNode:"network_name"
    },
    {
        headingLabel: "Initial Rights",
        icon: "",
        width: "20%",
        dataNode:"initial_rights_name"
    },
    // {
    //     headingLabel: "",
    //     icon: "",
    //     width: "5%",
    // }
    ],
    dataNodes: ["show_name", "season_name", "production_entity_name", "production_executive_name", "clearance_rep_name", "network_name", "initial_rights_name"],
    //actions: ["share"],
    primaryKey: "show_season_id",
    hyperlinks: ["season_name"],
    dashboardFilters:true
}


export const showOptions = [
    { label: "The Office", value: "1" }, 
    { label: "Being Human", value: "2" },
    { label: "Little Britain", value: "3" }
]

export const seasonOptions = [
    { label: "Damages", value: "1" }, 
    { label: "Lost", value: "2" },
    { label: "Chuck", value: "3" }
]

export const filterConfig = {
    show: {id: 'show_id', name: 'show_name'},
    season: {id: 'season_id', name: 'season_name'},
    user: {id: 'user_id', name: 'full_name'},
    term: {id: 'term_id', name: 'term_name'},
    territory: {id: 'territory_id', name: 'territory_abbr'},
    production_entity: {id: 'production_entity_id', name: 'short_name'},
    show_durations: {id: 'show_duration_id', name: 'show_duration'},
    rights: {id: 'right_id', name: 'right_abbr'}
}

export const showSeasonSelectedFilters =  {
    episodeName: null,
    episode: null,
    projEpisode: null,
    compEpisode: null,
    showYear: null,
    productionExec: null,
    estimator: null,
    composers: null,
    selectedInitialRight: null,
    initialRightsTerm: null,
    initialRightsTerritory: null,
    selectedAltInitialRight: null,
    altRightsTerm: null,
    altRightsTerritory:null,
    show: null,
    selectedSeason: null,
    selectedShowDuration: null,
    user: null,
    selectedNetwork: null,
    selectedProductionEntity: null,
    selectedNetworkShow: null
}
export const showSeasonsDetailsConfig = {
    show_search_ids:null,
    season_search_ids: null,
    episode_title_search_text: null,
    episode_no_search_text: null,
    projected_eps_search_no: null,
    completed_eps_search_no: null,
    network_search_ids: null,
    show_duration_search_ids: null,
    is_internet_show_flag:null,
    production_entity_search_ids: null,
    production_exec_search_text: null,
    estimator_search_text: null,
    clearance_rep_search_ids: null,
    composer_search_text: null,
    init_right_search_ids:null,
    init_term_search_ids:null,
    init_territory_search_ids:null,
    alt_init_right_search_ids:null,
    alt_init_term_search_ids: null,
    alt_init_territory_search_ids:null,
    order_by_clause: "Show",
    order_by: null,
    page_count: 25,
    page_no:1
  }