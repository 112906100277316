import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Radio from "@material-ui/core/Radio";
import SelectField from '../../SharedComponents/SelectField/SelectField';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import CircularProgress from "@material-ui/core/CircularProgress";;
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import axios from 'axios';
let allAiring = [{ label: 'Initial', value: 'Initial' },
{ label: 'Alternate 1', value: 'Alternate 1' },
{ label: 'Alternate 2', value: 'Alternate 2' }]

class CueSheetCopyVersion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPosting: false,
            searchCancelToken: null,
            isShowFetching: false,
            showList: [],
            divisionId: null,
            isFetchingSeason: false,
            showBasedSeasonList: [],
            episodeOptions: [],
            isFetchingEpisode: false,
            season: null,
            episode: null,
            show: null,
            selectedAiring: null,
            isFetchingAiring: false,
            airingList: [],
            copyOption: this.props?.availableAiring?.length === 3 ? '2' : '1'
        }
    }

    getShowDetails = (searchString) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        this.setState({ isShowFetching: true })
        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map((item) => ({
                    value: item.show_id,
                    text: item.show_name,
                    divisionId: item.division_id
                }));
                this.setState({ isShowFetching: false, showList: formattedList })
            },
                (err) => {
                    console.log("Error in fetching show data:", err)
                })
    }

    getSeasonsBasedOnShow = (newValue) => {
        this.setState({ isFetchingSeason: true })
        ClearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${newValue.value}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.state?.divisionId
        ).then(
            (response) => {
                let formattedList = response.data[0]?.seasons?.map(item => ({ label: item?.season_name, value: item?.show_season_id, season_id: item?.season_id }))
                this.setState({ showBasedSeasonList: formattedList, isFetchingSeason: false })
            })
            .catch(error => {
                console.log("error--", error)
            })

    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisode: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes?show_season_id=${showSeasonId}`, this.props.userContext?.active_tenant?.tenant_id, this.state?.divisionId)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.show_season_episode_id, label: `${item?.episode_number ? item?.episode_number : ''}-${item?.episode_title ?  item?.episode_title : ''}` }));
                this.setState({ episodeOptions: formattedList, isFetchingEpisode: false });
            },
                (err) => {
                    console.log("Error in fetching Details:", err)
                })
    }

    getCueSheetHeader = (episode_id) => {
        this.setState({ isFetchingAiring: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetHeaders?show_season_id=${this.state.season}&show_season_episode_id=${episode_id}`,
            this.props.userContext?.active_tenant?.tenant_id, this.state?.divisionId)
            .then(response => {
                let airing = [...allAiring]
                if (response.data.length === 3 || response.data.length === 2) {
                    this.setState({ airingList: airing })
                } else if (response.data.length === 1) {
                    airing?.splice(2, 1)
                    this.setState({ airingList: airing })
                } else {
                    airing?.splice(1, 2)
                    this.setState({ airingList:  airing})
                }
                this.setState({ isFetchingAiring: false })
            })
    }


    getNewAiring = (exsitingArr) => {
        if (exsitingArr?.length === 1) {
            return 'Alternate 1'
        } else if (exsitingArr?.length === 2) {
            return 'Alternate 2'
        }
    }

    postCopyVersion = () => {
        this.setState({ isPosting: true })
        let newData = {
            "show_season_episode_id": this.state.copyOption === '1' ? this.props?.show_season_episode_id : this.state.episode,
            "show_season_id": this.state.copyOption === '1' ? this.props?.show_season_id : this.state.season,
            "show_id": this.state.copyOption === '1' ? this.props?.show_id : this.state.show?.value,
            "airing": this.state.copyOption === '1' ? this.getNewAiring(this.props?.availableAiring) : this.state.selectedAiring,
            "season_id": this.state.copyOption === '1' ? this.props?.season_id : this.state.showBasedSeasonList?.find(item => item?.value === this.state.season)?.season_id,
            "source_cue_sheet_header_id": this.props?.cue_sheet_header_id || null
        }
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetVersion`, newData, this.props.userContext?.active_tenant?.tenant_id, this.state.copyOption === '1' ? this.props?.division_id : this.state?.divisionId)
            .then(response => {
                if (response) {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props?.notificationComponent('error', response?.data?.message)
                    } else {
                        if (this.state.copyOption === "1" || (this.state.show?.value === this.props?.show_id && this.state.season === this.props?.show_season_id && this.state?.episode === this.props?.show_season_episode_id)) {
                            this.props?.toggleRefresh()
                        }
                        this.props?.notificationComponent('success')
                        this.props?.handleClose()
                    }
                }
            }),
            (err) => {
                this.setState({ isPosting: false })
                this.props?.notificationComponent('error')
            }

    }

    render() {
        return (
            <MDBContainer fluid className="cue-sheet-copy-version-popup">
                {this.props?.availableAiring?.length < 3 && <Radio
                    name="radio-buttons"
                    id="copyVersionOption1"
                    checked={this.state.copyOption === "1"}
                    onChange={(e) => this.setState({ copyOption: e.target.value })}
                    value={"1"}
                />}
                {this.props?.availableAiring?.length < 3 && <span>Copy to new Alternate</span>}
                <br />
                <Radio
                    name="radio-buttons"
                    id="copyVersionOption2"
                    checked={this.state.copyOption === "2"}
                    onChange={(e) => this.setState({ copyOption: e.target.value })}
                    value={"2"}
                />
                <span>Another Episode</span>
                <br />
                {this.state.copyOption === "2" &&
                    <div>
                        <span>Show/Season/Episode/Airing</span>
                        <SearchSelectField
                            label=""
                            options={this.state.showList || []}
                            loading={this.state.isShowFetching}
                            width="80%"
                            multiple={false}
                            detail_selected={this.state?.show ? this.state.show : null}
                            searchSelect={true}
                            valueSelected={(e, newValue) => {
                                if (newValue) {
                                    this.setState({
                                        divisionId: newValue?.divisionId, showBasedSeasonList: [],
                                        episodeOptions: [], show: newValue, season: null, showBasedSeasonList: [],
                                        episode: null, selectedAiring: null, airingList: []
                                    }, () => {
                                        this.getSeasonsBasedOnShow(newValue)
                                    })
                                } else {
                                    this.setState({ divisionId: null, showBasedSeasonList: [], episodeOptions: [], show: null, season: null, episode: null, airing: null })
                                }
                            }}
                            placeholder={'- Search program -'}
                            searchText={ev => {
                                // dont fire API if the user delete or not entered anything
                                if (ev.target.value !== "/" && ev.target.value !== null) {
                                    this.getShowDetails(ev.target.value);
                                } else {
                                    this.setState({ isShowFetching: false })
                                }
                            }}
                        />
                        {this.state.isFetchingSeason ? <CircularProgress /> :
                            <SelectField
                                id="Season"
                                value={this.state.season || null}
                                placeHolderText="- Select Season -"
                                options={this.state.showBasedSeasonList || []}
                                onChange={(e) => {
                                    this.setState({ season: e.target.value, episodeOptions: [], episode: null, airingList: [], selectedAiring: null },() => {
                                        this.getShowBasedEpisodes(e.target.value)
                                    })
                                }}
                            />
                        }
                        {this.state.isFetchingEpisode ? <CircularProgress /> :
                            <SelectField
                                width={"100%"}
                                id={"episode"}
                                placeHolderText="- Select Episode-"
                                value={this.state.episode}
                                options={this.state.episodeOptions || []}
                                onChange={(e) => {
                                    this.setState({ episode: e.target.value, airingList: [], selectedAiring: null }, () => {
                                        this.getCueSheetHeader(e.target.value)
                                    })
                                }}
                            />
                        }
                        {this.state.isFetchingAiring ? <CircularProgress /> : <SelectField
                            id={"cueSheet-airing"}
                            placeHolderText="- Select Airing-"
                            options={this.state.airingList || []}
                            value={this.state.selectedAiring || null}
                            onChange={(e) => {
                                this.setState({ selectedAiring: e.target.value })
                            }}
                        />}
                    </div>}
                <MDBRow className='mt-2'>
                    <MDBCol md={6}></MDBCol>
                    <MDBCol md={3}>
                        <BasicButton
                            variant="contained"
                            disabled={this.state.copyOption === "1" ? false :
                                !(this.state.show?.value && this.state.season && this.state?.episode && this.state.selectedAiring)}
                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                            icon={!this.state.isPosting ? "save" : " "}
                            onClick={this.postCopyVersion}
                        />
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicButton
                            variant="outlined"
                            text=" "
                            icon={"times"}
                            onClick={this.props?.handleClose} />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withUserContext(CueSheetCopyVersion);