export const budgetRecipientConfig = {
    "budget_email_recipients_id":null,
    "full_name":null,
    "email":null,
    "is_cc":null,
    "is_clearance_budget_recipient":null,
    "is_proposed_budget_recipient":null,
    "is_active":null
}


export const budgetRecipientRecordConfig = {
    headings: [

        {
            headingLabel: "Full Name",
            width: "20%",
            inputType: "text"
        },
        {
            headingLabel: "Email",
            width: "20%",
            inputType: "text"
        },

        {
            headingLabel: "CC?",
            width: "10%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Rcv Clr?",
            width: "10%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Rcv Final?",
            width: "15%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Active?",
            width: "15%",
            inputType: "checkbox"
        },



    ],
    dataNodes: ["full_name", "email", "is_cc", "is_clearance_budget_recipient", "is_proposed_budget_recipient","is_active"],
    primaryKey: "budget_email_recipients_id",
    actions: ["pen", "times"],
    //toggleFields: ["is_cc", "is_clearance_budget_recipient", "is_proposed_budget_recipient","is_active"],
    inlineEdits: [
       {
            dataNode: "full_name",
            width: "20%",
            // inputType:"text",
        },
        {
            dataNode: "email",
            width: "20%",
            validateMethod: 'validateEmail',
            validateMessage: 'Please enter valid Email'
        },
        {
            dataNode: "is_cc",
            width: "10%",
            // inputType:"check"
        },
        {
            dataNode: "is_clearance_budget_recipient",
            width: "10%",
            // inputType:"check"
        },
        {
            dataNode: "is_proposed_budget_recipient",
            width: "10%",
            // inputType:"check"
        },
        {
            dataNode: "is_active",
            width: "10%",
        },
    ],
    nodeFields: [
        {
            column: "is_cc",
            icon: "check"
        },
        {
            column: "is_clearance_budget_recipient",
            icon: "check"
        },
        {
            column: "is_proposed_budget_recipient",
            icon: "check"
        },
        {
            column: "is_active",
            icon: "check"
        },
    ],
}