import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import TableComponent from '../../SharedComponents/Table';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import ClearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { InitialCostCenterDetails } from "./CostCenterConfig";
import { CircularProgress } from '@material-ui/core';
import { withUserContext } from '../../../contexts/UserContext';
import messages from '../../../Common/Messages.json';
import { updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';

class CostCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            existingCostCenters: [],
            costCenterDetails: InitialCostCenterDetails,
            constCenterSeasonList: [],
            isSubmit: false,
            isLoading: false,
            isExistCostCenterLoading: false,
            mode: "default",
            postInitiated: false,
            selectedCostCenter: null,
            selectedId: null,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0
        }
    }
    componentDidMount() {
        this.getExistingCostCenter("COST_CENTER", "");
        this.handleTableColumnClick("Season");
    }
    getExistingCostCenter = (entityType, searchString, currentItem = null) => {
        this.setState({ isExistCostCenterLoading: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id).then(res => {
                if (res.data.length > 0) {
                    this.handleUpdateExistingRecord(currentItem ? currentItem : res.data[0]?.cost_center_id)
                    this.setState({ existingCostCenters: res.data, isExistCostCenterLoading: false, selectedId: currentItem ? currentItem : res.data[0]?.cost_center_id, mode: "edit" })
                } else {
                    this.setState({ costCenterDetails: { ...InitialCostCenterDetails }, selectedId: null, existingCostCenters: [], isExistCostCenterLoading: false, mode: "edit" })
                }

            }).catch(err => {
                this.setState({ existingCostCenters: res.data, isExistCostCenterLoading: false })
            })
    }
    handleUpdateExistingRecord = (id) => {
        this.setState({ isLoading: true, selectedId: id })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/costCenter?cost_center_id=${id}`, this.props?.userContext?.active_tenant?.tenant_id).then(res => {
            this.setState({ costCenterDetails: res.data[0], isLoading: false, mode: "edit", constCenterSeasonList: res.data[0]?.season_list })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }
    handleGeneralEdit = (field, value) => {
        if (field === "is_available_for_selection" || field === "is_music_clearance_cc" || field === "is_all_media_charge_off" || field === "is_home_video_cost_center" || field === "is_budgeting_cost_center" || field === "is_application_budget") {
            this.setState({
                costCenterDetails: {
                    ...this.state.costCenterDetails,
                    [field]: value ? 1 : 0,
                },
            });
        } else {
            this.setState({
                costCenterDetails: {
                    ...this.state.costCenterDetails,
                    [field]: value,
                },
            });
        }
    }
    handleSubmit = () => {
        this.setState({ isSubmit: true, postInitiated: true })
        if (!this.state.costCenterDetails?.cost_center_name || !this.state.costCenterDetails?.sap_code
            || !this.state.costCenterDetails?.cost_center_code) {
                this.setState({
                    isSubmit: false
                });
                return;
        } else {
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/costCenter`, this.state.costCenterDetails, this.props?.userContext?.active_tenant?.tenant_id)
                .then(
                    (response) => {
                        if (response.data.error) {
                            this.setState({ isSubmit: false });
                            this.props?.toastMessageNotification(true, "fail");
                        } else {
                            this.setState({
                                isSubmit: false, mode: "default", postInitiated: false
                            });
                            this.props?.toastMessageNotification(true, "success");
                            if (!this.state.costCenterDetails?.cost_center_id) {
                                this.getExistingCostCenter("COST_CENTER", "", response?.data[0]?.cost_center_id);
                            } else {
                                if (this.state.existingCostCenters?.find(item => item?.cost_center_name === this.state.costCenterDetails?.cost_center_name)) { this.handleUpdateExistingRecord(response?.data[0]?.cost_center_id) }
                                else {
                                    this.getExistingCostCenter("COST_CENTER", "", response?.data[0]?.cost_center_id);
                                }
                            }
                        }
                    },
                    (err) => {
                        this.setState({ isSubmit: false });
                        this.props?.toastMessageNotification(true, "fail");
                        console.log("Post Cost Center details error: " + err);
                    }
                );
        }
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.props?.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                constCenterSeasonList: tableSortList("Name", this.getSortNode(colName), this.state.constCenterSeasonList, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Season": return "season";
            case "Display Name": return "display_name";
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <>
                <MDBContainer fluid className="costCenterContainer">
                    <MDBRow>
                        <span className="description">Budget billing groups used in Show Season Cost Splits</span>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md={3} className="existing-records-heading">
                            <div className="display-flex">
                                <BasicLabel type={"darkFieldHeading"} text="Existing Cost Centers" />
                                <span className="add-cost-center-btn">
                                    {permissionValue?.edit === 1 &&
                                        <BasicButton
                                            type="inline"
                                            color="primary"
                                            text={" "}
                                            icon={"plus-circle"}
                                            onClick={() => this.setState({ costCenterDetails: { ...InitialCostCenterDetails }, selectedId: null })}
                                        />}
                                </span>
                            </div>
                            <div className="existing-records">
                                {this.state.isExistCostCenterLoading ? <div style={{ textAlign: "center" }} ><CircularProgress size={24} /></div> : this.state.existingCostCenters?.map((item, index) => (
                                    <div key={index} className="cost-center-item" onClick={() => this.handleUpdateExistingRecord(item.cost_center_id)}>
                                        <span style={{ color: this.state.selectedId === item.cost_center_id ? "#164f9f" : "" }}
                                        > {item.cost_center_name ? `${[item.cost_center_name]}` : null}</span>
                                    </div>
                                ))}
                            </div>
                        </MDBCol>
                        <MDBCol className="cost-center-details">
                            {/* <div>* Marked fields are mandatory</div> */}
                            <div className="display-flex">
                                <BasicLabel type={"darkFieldHeading"} text={this.state.existingCostCenters?.find(item => item.cost_center_id === this.state.selectedId)?.cost_center_name || "Cost Center"} />
                                {permissionValue?.edit === 1 &&
                                    <BasicButton
                                        type="inline"
                                        color="primary"
                                        text={this.state.isSubmit ? <CircularProgress color="inherit" size={18} /> :
                                            "Save"}
                                        icon={"save"}
                                        // disabled={(!this.state.costCenterDetails?.cost_center_name || !this.state.costCenterDetails?.sap_code
                                        // || !this.state.costCenterDetails?.cost_center_code)}
                                        onClick={this.handleSubmit}
                                    />}
                            </div>

                            {this.state.isLoading ? <div style={{ textAlign: "center" }} ><CircularProgress size={24} /></div> : <MDBCard className="cost-center-card">
                                <MDBCardBody>
                                    <MDBCardText>
                                        <MDBRow>
                                            <MDBCol md={4}>                                                <BasicTextField
                                                id="displayName"
                                                label={"Cost Center Display Name"}
                                                isMandatory={true}
                                                value={this.state.costCenterDetails?.cost_center_name || null}
                                                onChange={(e) => this.handleGeneralEdit('cost_center_name', e.target.value?.length > 0 ? e.target.value : null)}
                                            />
                                            </MDBCol>
                                            <MDBCol md={3} className="marginTop">
                                                <BasicCheckbox
                                                    className="selectionAvailable"
                                                    id={"availableSelection"}
                                                    label="Available for Selection"
                                                    checked={this.state.costCenterDetails?.is_available_for_selection}
                                                    onChange={(e) => this.handleGeneralEdit('is_available_for_selection', e.target.checked)}
                                                />
                                            </MDBCol>
                                            <MDBCol className="marginTop p-0">
                                                <BasicCheckbox
                                                    className="musicClearance"
                                                    id={"musicClearance"}
                                                    label="Music Clearance CC"
                                                    checked={this.state.costCenterDetails?.is_music_clearance_cc}
                                                    onChange={(e) => this.handleGeneralEdit('is_music_clearance_cc', e.target.checked)}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol md={4} className="seasonTable">
                                                <TableComponent
                                                    list={this.state.constCenterSeasonList || []}
                                                    config={this.props?.config}
                                                    maxHeight={"200"}
                                                    sortCallback={this.handleTableColumnClick}
                                                />
                                            </MDBCol>
                                            <MDBCol md={4}>
                                                <div className="display-flex">
                                                    <div className="gl_code">
                                                        <BasicTextField
                                                            id="glCode"
                                                            label={"GL Code"}
                                                            value={this.state.costCenterDetails?.gl_code || null}
                                                            onChange={(e) => this.handleGeneralEdit('gl_code', e.target.value?.length > 0 ? e.target.value : null)}
                                                        />
                                                    </div>
                                                    <BasicTextField
                                                        id="rcvCo"
                                                        label={"Rcv Co."}
                                                        value={this.state.costCenterDetails?.receiving_co || null}
                                                        onChange={(e) => this.handleGeneralEdit('receiving_co', e.target.value?.length > 0 ? e.target.value : null)}
                                                    />
                                                </div>
                                                <div className="display-flex">
                                                    <div className="sap_code">
                                                        <BasicTextField
                                                            id="sapCode"
                                                            label={"SAP Code"}
                                                            isMandatory={true}
                                                            showMandatory={this.state.postInitiated || false}
                                                            mandatoryWarning={messages.mandatoryWarning}
                                                            value={this.state.costCenterDetails?.sap_code || null}
                                                            onChange={(e) => this.handleGeneralEdit('sap_code', e.target.value?.length > 0 ? e.target.value : null)}
                                                        />
                                                    </div>
                                                    <BasicTextField
                                                        id="locCode"
                                                        label={"Loc Code"}
                                                        value={this.state.costCenterDetails?.loc_code || ""}
                                                        onChange={(e) => this.handleGeneralEdit('loc_code', e.target.value?.length > 0 ? e.target.value : null)}
                                                    />
                                                </div>
                                                <div>
                                                    <BasicTextField
                                                        id="costCenterCode"
                                                        label={"Cost Center Code"}
                                                        isMandatory={true}
                                                        showMandatory={this.state.postInitiated || false}
                                                        mandatoryWarning={messages.mandatoryWarning}
                                                        value={this.state.costCenterDetails?.cost_center_code || ""}
                                                        onChange={(e) => this.handleGeneralEdit('cost_center_code', e.target.value?.length > 0 ? e.target.value : null)}
                                                    />
                                                </div>
                                                <BasicTextArea
                                                    label="Notes"
                                                    value={this.state.costCenterDetails?.notes || null}
                                                    onChange={(e) => this.handleGeneralEdit('notes', e.target.value?.length > 0 ? e.target.value : null)} />
                                            </MDBCol>
                                            <MDBCol md={4} className="marginTop">
                                                <BasicCheckbox
                                                    id={"allMediaChargeOff"}
                                                    label='"All Media Charge-Off" Cost Center'
                                                    checked={this.state.costCenterDetails?.is_all_media_charge_off}
                                                    onChange={(e) => this.handleGeneralEdit('is_all_media_charge_off', e.target.checked)}
                                                />
                                                <BasicCheckbox
                                                    id={"homeVideo"}
                                                    label="Home Video Cost Center"
                                                    checked={this.state.costCenterDetails?.is_home_video_cost_center}
                                                    onChange={(e) => this.handleGeneralEdit('is_home_video_cost_center', e.target.checked)}
                                                />
                                                <BasicCheckbox
                                                    id={"budgetingPurpose"}
                                                    label="Cost Center for budgeting purposes only"
                                                    checked={this.state.costCenterDetails?.is_budgeting_cost_center}
                                                    onChange={(e) => this.handleGeneralEdit('is_budgeting_cost_center', e.target.checked)}
                                                />
                                                <BasicCheckbox
                                                    id={"applicableBudget"}
                                                    label="Cost Center not applicable for budgets"
                                                    checked={this.state.costCenterDetails?.is_application_budget}
                                                    onChange={(e) => this.handleGeneralEdit('is_application_budget', e.target.checked)}
                                                />
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>}
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </>
        )
    }
}

export default withUserContext(CostCenter);