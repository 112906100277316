import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import AutoCompleteSearch from '../../../SharedComponents/AutoCompleteSearch/AutoCompleteSearch';
import '../LookupMaintenance.scss';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import TableComponent from '../../../SharedComponents/Table';
import { cueSheetPublisherConfig, cueSheetPublisherIntialDetails } from './Config';
import { TablePagination } from '@mui/material';
import * as Constants from '../../../../constants/constants';
import ClearTrackService from '../../../../services/service'
import { withUserContext } from '../../../../contexts/UserContext';
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import { CircularProgress } from '@material-ui/core';
import { updateTableSortConfigObject, tableSortList } from '../../../../Common/TableHelper';
import axios from 'axios';

class CueSheetPublisher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cueSheetPublisherDetails: { ...cueSheetPublisherIntialDetails },
            isLoading: false,
            publisherCleranceRecord: [],
            isSubmit: false,
            page: 1,
            searchString :null,
            isSortByAsc: true,
            sortCount: 0,
            showLoading: false,
            isDelete: false,
            postInitiated: false,
            showSeasonId: null,
            openEditSeasonModal: false,
            division_id: null,
            showSeasonPermission: [],
            sortBy: "Value",
            cueSheetAllPublishers:[],
            isFetchingpublisher: false,
            searchCancelToken:null,
            totalRecord:0,
            permissionArrList: []
        };
    }
    componentDidMount() {
        this.getCuesheetPublisher();
    }

    // componentDidUpdate(prevProps) {
    //     // Typical usage (don't forget to compare props):
    //     if (this.props.permissionArr !== prevProps.permissionArr) {
    //         this.setState({ permissionArrList: this.props.permissionArr });
    //     }
    // }
    

    getPublishersClearanceRecord = (id) => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl +
            `/clearanceRecordList?entity=CUE_SHEET_PUBLISER&entity_id=${id}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                let publisherDetailList =[...res?.data];
                publisherDetailList?.map((item, index) => {
                    publisherDetailList[index].show_season = (item?.show_name && item?.season_name) ? `${item?.show_name} / ${item?.season_name}` :
                        (!item?.show_name && item?.season_name) ? `${item?.season_name}` :
                            (item?.show_name && !item?.season_name) ? `${item?.show_name}` : ""
                });
                publisherDetailList?.map((item) => {
                    return item.initial_fee=item?.initial_fee?'$'+parseFloat(item?.initial_fee).toFixed(2):null
                })
                this.setState({publisherCleranceRecord: publisherDetailList, isLoading: false});
                this.handleTableColumnClick("Show/Season");
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    handleEdit = (field, value) => {
        this.setState((prevState) => ({
            cueSheetPublisherDetails: { ...prevState.cueSheetPublisherDetails, [field]: value },
            postInitiated: false
        }
        ))
    };

    handleSubmit = (deleteFlag = 0) => {
        this.setState({ postInitiated: true })
        if (this.state.cueSheetPublisherDetails?.name) {
            this.setState({ postInitiated: false });
            let cuesheetPublisherData = { ...this.state.cueSheetPublisherDetails, is_active: 1, table_name: "lu_cue_sheet_publisher" }
            if (deleteFlag === 1) {
                cuesheetPublisherData.is_delete = 1
            } else {
                this.setState({ showLoading: true })
            }
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/staticData`,
                cuesheetPublisherData,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(
                    (response) => {
                        if (response.data.error) {
                            this.props.toastMessageNotification(true, "fail");
                            this.setState({ showLoading: false})
                        } else {
                            this.setState({ cueSheetPublisherDetails: { ...cueSheetPublisherIntialDetails }, showLoading: false, page:1}, () => {
                                this.getCuesheetPublisher(false)})
                            this.props.toastMessageNotification(true, "success");
                        }
                        this.setState({ isDelete: false })
                    },
                    (err) => {
                        this.setState({ showLoading: false, isDelete: false });
                        this.props.toastMessageNotification(true, "fail");
                    }
                )
        }
    };

    navigationCallback = (obj, node) => {
        if (obj?.show_season_id && node === 'show_season') {
            window.open(`/showseason/${obj.show_season_id}`, '_blank');
        } else if (obj?.show_season_episode_id && node === 'episode_title') {
            window.open(`/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`, '_blank');
        }
    }

    handleDeleteCuesheetPublisher = () => {
        this.setState({ isDelete: true });
        ClearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl +
            `/checkRelationalTables?tableName=lu_cue_sheet_publisher&tableId=${this.state.cueSheetPublisherDetails?.id}`,
            this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                if (res?.data[0]?.status === 'SUCCESS') {
                    this.handleSubmit(1)
                } else {
                    this.props?.fieldChanged(true, 'fail')
                    this.setState({ isDelete: false });
                }
            }),
            ((err) => {
                this.props?.fieldChanged(true)
                this.setState({ isLoading: false, isDelete: false });
            });
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(cueSheetPublisherConfig, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                publisherCleranceRecord: tableSortList("Name", this.getSortNode(colName), this.state.publisherCleranceRecord, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Show/Season": return "show_season";
            case "Episode#": return "episode_title";
            case "Song": return "song_title";
            case "Use": return "licensor_name";
            case "Status": return "status";
            case "Intial Fee": return "initial_fee";
        }
    }

    getCuesheetPublisher = (scrollLoad = false) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        this.setState({ isFetchingpublisher: true });
        let postData = {
            "entity_text": "cue_sheet_publisher",
            "entity_search_text":this.state.searchString,
            "page_count":100,
            "page_no":this.state.page
        }
        let encryptTedStr = btoa(JSON.stringify(postData));
        ClearTrackService.getDataWithCancel(
          Constants.ClearTrackServiceBaseUrl +`/dashboardLookups?input==${encryptTedStr}`, cancelToken,
            this.props?.userContext?.active_tenant?.tenant_id,
        )
          .then((response) => {
            this.setState({totalRecord : response.data?.query_record_count})
            if(response.data?.records?.length > 0 ) {
                let allList = []
                if(scrollLoad) {
                    allList = [...this.state.cueSheetAllPublishers, ...response.data?.records]
                } else {
                    allList = response.data?.records
                }
                this.setState({
                    cueSheetAllPublishers: allList,
                    isFetchingpublisher: false,
                    publisherCleranceRecord:[],
                    cueSheetPublisherDetails: JSON.parse(JSON.stringify({ ...cueSheetPublisherIntialDetails }))
                });
          } else {
              this.setState({ cueSheetPublisherDetails: JSON.parse(JSON.stringify({ ...cueSheetPublisherIntialDetails })),isFetchingpublisher: false, cueSheetAllPublishers : [], publisherCleranceRecord:[]})
          }
          })
          .catch((err) => {
            this.setState({ isFetchingProductionEntities: false });
          });
    };

    
    render() {
        let permissionValue = this.props?.permissionArray
        return (
            < MDBContainer fluid className='CueSheetContainer' >
                <MDBRow className="tab-content">
                    <MDBCol md={3}>
                        <div>
                    <BasicTextField
                        id="publisher"
                        placeholder={"- Search by Publisher Name -"}
                        value={this.state.searchString}
                        onChange={(e) => { 
                            this.setState({searchString : e.target.value.length > 0 ? e.target.value :null, page:1},this.getCuesheetPublisher)
                            }
                }
              />
              </div>

              <div className="existing-records" onScroll={(e) => {
                let totalScrollHeight = Math.floor(e.currentTarget.clientHeight)
                if([totalScrollHeight-1, totalScrollHeight, totalScrollHeight +1]?.includes(Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop)) && this.state.totalRecord != this.state.cueSheetAllPublishers.length ) {
                    this.setState((prevState) => ({
                        page: prevState.page + 1,
                    }
                    ), () => {
                        this.getCuesheetPublisher(true);
                    }
                    )
                }
                }}>
              <>
                  {
                  this.state.cueSheetAllPublishers.length > 0 ? (
                    <>
                    {this.state.cueSheetAllPublishers?.map((item, index) => (
                      <div
                        key={index}
                        className="entity-item"
                        onClick={() => {
                          this.setState({cueSheetPublisherDetails : {
                            id : item?.cue_sheet_publisher_id,
                            name : item?.cue_sheet_publisher_name
                          }})  
                          this.getPublishersClearanceRecord(item.cue_sheet_publisher_id)}}
                      >
                        <span
                          className={`${
                            item.cue_sheet_publisher_id === this.state.cueSheetPublisherDetails.id ? "highlight-entity" : ""
                          }`}
                        >
                          {item.cue_sheet_publisher_name}
                        </span>
                      </div>
                    ))}
                    {this.state.isFetchingpublisher && <CircularProgress color='white' size={18} />}
                    </>
                  ) : (
                    <span className="no-content">{!this.state.isFetchingpublisher && 'No Data Found'} </span>
                  )}
                </>
              </div>
                
                    </MDBCol>
            <MDBCol md={9}>
                <MDBRow className="mt-cue">
                    <MDBCol md={5} className="text-btn">
                        <BasicTextField
                            label="Selected Publisher"
                            id="cueSheetPublisherName"
                            className="CueSheetPublisherName"
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            placeholder={"- Selected Cue Sheet Publisher Name -"}
                            value={this.state.cueSheetPublisherDetails?.name}
                            onChange={(e) =>
                                this.handleEdit("name", e.target.value.length > 0 ? e.target.value : null)}
                                />
                    <div className="save-btn-alignment">
                        {permissionValue[0]?.edit === 1 &&
                        <BasicButton
                            className={"save-btn"}
                            variant="contained"
                            text={this.state.showLoading ? <CircularProgress color='white' size={18} /> : " "}
                            icon={"save"}
                            onClick={this.handleSubmit}/>
                        }
                        </div>
                                </MDBCol>                    
                    <MDBCol md={1}></MDBCol>
                    {this.state.cueSheetPublisherDetails?.name?  <MDBCol md={1} className="mt-4">
                    <span className="add-prod-entity-btn">
                        <BasicButton
                            type="inline"
                            color="primary"
                            text={" "}
                            icon={"plus-circle"}
                            onClick={() =>
                            this.setState({
                                cueSheetPublisherDetails : JSON.parse(
                                JSON.stringify({ ...cueSheetPublisherIntialDetails })
                                ),publisherCleranceRecord: []
                            })
                        }
                        />
                    </span>
                    </MDBCol> : null}
                    {permissionValue[0]?.delete === 1 &&
                    <>
                    {this.state.cueSheetPublisherDetails?.name && this.state.cueSheetPublisherDetails?.id ? <MDBCol md={1} className="delete-font mt-4">
                        {this.state.isDelete ? <CircularProgress size={18} /> :
                            <span className="delete-btn" onClick={this.handleDeleteCuesheetPublisher}>Delete</span>}
                                </MDBCol> : null} 
                                </>
                                }                              
                </MDBRow>
                {this.state.isLoading ? <CircularProgress size={24} /> : <MDBRow className="mt-2">
                    <h6>Clearance Record</h6>
                    <div id="table-bg-sync-cue">
                        <TableComponent
                            list={this.state.publisherCleranceRecord || []}
                            className={"clearencRecord"}
                            stickyHeader={true}
                            config={cueSheetPublisherConfig}
                            // isLoading={this.state.isSubmit}
                            postInitiated={this.state.isSubmit}
                            hyperLinkNavigationCallback={this.navigationCallback}
                            // handleOpenPopUp={(event, dataItem) => this.handleShareClick(event, dataItem, this)}
                            //handleShareClick={(event, dataItem) => this.handleEditSeasonDetailsShareClick(event, dataItem, this)}
                            sortCallback={this.handleTableColumnClick}
                        />
                    </div>
                    <div>
                        {/* <TablePagination
                            component="div"
                            count={this.state.publisherCleranceRecord ? this.state.publisherCleranceRecord?.length : 0}
                            rowsPerPageOptions={[]}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                        /> */}
                    </div>
                </MDBRow>}
                    </MDBCol>
                </MDBRow>
            </ MDBContainer>
        )
    }
}

export default withUserContext(CueSheetPublisher);
