import React from 'react';
import TabsComponent from "../../SharedComponents/Tabs/Tabs";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import "./ShowSeasonDetails.scss";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ShowSeasonTabDetails from './ShowSeasonTabDetails';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import Popover from '@mui/material/Popover';
import { PopoverOptions, PopoverReportOptions } from "./config";
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import MainTitleTabDetails from '../MainTitleTab/MainTitleTabDetails';
import Budget from '../Budget/Budget';
import CueSheetsTab from '../CueSheetsTab/CueSheetsTab';
import BussinessAffairs from '../BusinessAffairs/BusinessAffairs';
import People from '../People/People';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { CircularProgress } from "@material-ui/core";
import Files from '../Files/Files';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import { clearanceRecordPermissionsConfig } from '../../EpisodeAndClearence/EpisodeAndClearenceDetails/Config';
import { withRouter } from 'react-router-dom';

let divisionIdObj = null, allReportsPermission =[], permissionArrDetailsTab = [], permissionArrBudgetTab = [], permissionArrCSTab = [], permissionArrBusinessAffairs = [], permissionArrPeople = [], displayGraphButton, canAddEditTvCrMusicBudgetUnion = false, displayCopyButton, displayCostSpitsButton, displayShowDetailsButton, displayDistributionButton, canCreateEditBatches = false, canApplyPayIncrease = false, displayNewSeasonButton = false, scAndMSdetails = [], permissionArrMainTitile = [], permissionArrFile = false, cueSheetTabVisibility = false, canNavigateToReportButton = false, selectedSeason, queryParam = {}, show_name, mainTitlePermissions, songAndPerformerPermissions, batchesPermissions, peopleModalPopupPermissions, budgetingSetupPermission, budgetingReportPermission,episodeSongPermissions;
class ShowSeasonDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userContext: {},
            tabValue: null,
            tabLabel: '',
            isLoading: false,
            setPopover: null,
            setPopoverReports: null,
            setActionsPopover: null,
            showMainBatchesModal: false,
            showCueSheetDistribution: false,
            ShowSeasonTabList: [],
            setBusinessAffairsActionsPopover: null,
            applyPayIncreaseModal: false,
            showEditBudgetTabModal: false,
            showEditUnionTabModal: false,
            setBudgetActionsPopover: null,
            showCostSplitsModal: false,
            showNewShowSeasonModal: false,
            showEditShowDetailsModal: false,
            initialRecordData: {},
            editInitialRecordData: {},
            seasonOptions: [],
            setSeasonPopover: null,
            show_season_id: this.props.match.params.id ? this.props.match.params.id : null,
            show_id: 1,
            season_id: 1,
            main_title_id: 1,
            division_id: null,
            showError: false,
            showSuccess: false,
            showFail: false,
            mode: null,
            nonPilotTabName: null,
            is_this_not_pilot: 0,
            episodesArray: [],
            productionEntityOptions: [],
            episodeLength: null,
            triggerGetFileData: null,
            payIncreaseList: null,
            graphValues: [],
            isBusinessAffairs: false,
            initialRecordDataBackup: {},
            showBudgetSetupModal: false,
            showBudgetReportModal: false,
            commonPermissions:[],
            bussinessAffairsEpisodeList:[],
        }
    }
    handleTabChange = (newTabValue) => {
        let tabLabel = this.state.ShowSeasonTabList.filter(item => (item.value == newTabValue) ? item : null);
        this.setState({
            tabValue: newTabValue,
            tabLabel: tabLabel[0].label
        })
    }
    handleEpisodesArray = (episodesArray) => {
        this.setState({ episodesArray: episodesArray });
    }
    getTabComponent = () => {
        switch (this.state.tabLabel) {
            case 'Details': return <ShowSeasonTabDetails permissionArr={permissionArrDetailsTab} show_season_id={this.state.show_season_id} initialRecordData={this.state.initialRecordData} main_title_id={this.state.main_title_id} division_id={this.state.division_id} getProductionEntityOptions={this.getProductionEntityOptions.bind(this)} episodeLenght={this.episodeLenght} />
            case 'Main Title': return <MainTitleTabDetails
                {...this.props}
                show_season_id={this.state.show_season_id}
                division_id={this.state.division_id}
                main_title_id={this.state.initialRecordData?.main_title_id}
                showSeasonDetails={this.state.initialRecordData}
                episodeLength={this.state.episodeLength}
                toggleRefreshPage={this.props?.toggleRefreshPage}
                refreshPage={this.props?.refreshPage}
                permissionArrMainTitile={permissionArrMainTitile}
                fetchData={this.fetchData.bind(this)}
                songAndPerformerPermissions={songAndPerformerPermissions}
                mainTitlePermissions={mainTitlePermissions}
                batchesPermissions={batchesPermissions}
                episodeSongPermissions={episodeSongPermissions}
            />
            case 'Cue Sheets': return <CueSheetsTab division_id={this.state.division_id} show_season_id={this.state.show_season_id} permissionArr={permissionArrCSTab} {...this.props} />
            case 'Budget': return <Budget
                is_pilot={this.state.initialRecordData?.is_pilot}
                show_season_id={this.state.show_season_id}
                show_id={this.state?.initialRecordData.show_id}
                season_id={this.state.initialRecordData.season_id}
                initialRecordData={this.state.initialRecordData}
                division_id={this.state.division_id}
                permissionArr={permissionArrBudgetTab}
                is_this_not_pilot={this.state.is_this_not_pilot}
                handleEpisodesArray={this.handleEpisodesArray.bind(this)}
                handleEditUnionTabModal={this.handleEditUnionTabModal.bind(this)}
                episodesArray={this.state.episodesArray}
                productionEntityOptions={this.state.productionEntityOptions}
                {...this.props}
                window_name={window.name} />
            case 'Business Affairs': return <BussinessAffairs dataList={this.state.bussinessAffairsEpisodeList}initialRecordData={this.state.initialRecordData} division_id={this.state.division_id} show_season_id={this.state.show_season_id} show_id={this.state.initialRecordData.show_id} season_id={this.state.initialRecordData.season_id} {...this.props} permissionArr={permissionArrBusinessAffairs} permissionArrDetailsTab={permissionArrDetailsTab} payIncreaseList={this.state.payIncreaseList} getScAndMSDetails={this.getScAndMSDetails.bind(this)} />
            case 'People': return <People initialRecordData={this.state.initialRecordData} division_id={this.state.division_id} show_season_id={this.state.show_season_id} show_id={this.state?.initialRecordData.show_id} season_id={this.state.initialRecordData.season_id} permissionArr={permissionArrPeople} {...this.props} peopleModalPopupPermissions={peopleModalPopupPermissions} />
            case 'Files': return <Files permissionArr={permissionArrFile} initialRecordData={this.state.initialRecordData} division_id={this.state.division_id} disabletriggerGetFileData={this.disabletriggerGetFileData.bind(this)} triggerGetFileData={this.state.triggerGetFileData} {...this.props} />
        }
    }

    getShowBasedEpisodes = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes?show_season_id=${this.state.show_season_id}`, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id)
            .then(response => {
                this.setState({ bussinessAffairsEpisodeList: response.data});
            },
                (err) => {
                    console.log("Error in fetching Details:", err)
                })
    }

    episodeLenght = (value) => {
        this.setState({ episodeLength: value })
    }
    handleClickChart = (event) => {
        this.setState({
            setPopover: event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({
            setPopover: null
        })
    };
    handleClickReports = (e) => {
        this.setState({
            setPopoverReports: e.currentTarget
        })
    }
    handleCloseReports = () => {
        this.setState({
            setPopoverReports: null
        })
    };
    handleReportItem = (report) => {
        this.props.userContext.handleSideNavActiveMain(13, "Reports", null,
            [report, `Show:${this.state.initialRecordData?.show_name}`,
                `Season:${this.state.initialRecordData?.seasons?.find(item => item?.season_id === this.state.initialRecordData?.season_id)?.season_name}`,
                this.state.initialRecordData?.show_id, this.state.initialRecordData?.season_id])
    }
    handleBusinessAffairs = (e) => {
        this.setState({
            setBusinessAffairsActionsPopover: e.currentTarget
        })
    }
    handleUploadFile = (e) => {
        this.setState({
            setFileModal: e.currentTarget
        })
    }
    closeUploadFile = (e) => {
        this.setState({
            setFileModal: null
        })
    }
    handleCloseBusinessAffairsActions = () => {
        this.setState({
            setBusinessAffairsActionsPopover: null
        })
    };
    handleClickActions = (e) => {
        this.setState({
            setActionsPopover: e.currentTarget
        })
    }
    handleCloseActions = () => {
        this.setState({
            setActionsPopover: null
        })
    };
    handleClickBudgetActions = (e) => {
        this.setState({
            setBudgetActionsPopover: e.currentTarget
        })
    }
    handleCloseBudgetActions = () => {
        this.setState({
            setBudgetActionsPopover: null
        })
    };

    handleShowApplyPayToIncrease = () => {
        this.setState({ applyPayIncreaseModal: true, setBusinessAffairsActionsPopover: null });
    }
    handleHideApplyPayToIncrease = () => {
        this.setState({ applyPayIncreaseModal: false, setBusinessAffairsActionsPopover: null });
    }
    // handleShowBudgetTabModal = () => {
    //     this.setState({ showEditBudgetTabModal: true, setBudgetActionsPopover: null });
    // }
    // handleHideBudgetTabModal = () => {
    //     this.setState({ showEditBudgetTabModal: false });
    // }
    handleEditUnionTabModal = (mode) => {
        console.log("mode", mode);
        this.setState({ showEditUnionTabModal: true, setBudgetActionsPopover: null, mode });
    }
    handleHideEditUnionTabModal = () => {
        this.setState({ showEditUnionTabModal: false, mode: null });
    }
    handleShowCostSplitsModal = () => {
        this.setState({ showCostSplitsModal: true });
    }
    handleHideCostSplitsModal = () => {
        this.setState({ showCostSplitsModal: false });
    }
    handleShowNewShowSeasonModal = () => {
        this.setState({ showNewShowSeasonModal: true, setSeasonPopover: null });
    }
    handleHideNewShowSeasonModal = () => {
        this.setState({ showNewShowSeasonModal: false });
    }
    handleShowEditShowDetailsModal = () => {
        this.setState({ showEditShowDetailsModal: true })
    }
    handleHideEditShowDetailsModal = () => {
        this.setState({ showEditShowDetailsModal: false, editInitialRecordData: this.state.initialRecordData });
    }

    handleText = () => {
        switch (this.state.tabLabel) {
            case 'Main Title': return "Actions";
            case 'Budget': return "Actions"
            case 'Business Affairs': return "Actions"
            case 'Files': return "Add File"
        }
    }

    handleIcon = () => {
        switch (this.state.tabLabel) {
            case 'Main Title': return "chevron-down";
            case 'Budget': return "chevron-down"
            case 'Business Affairs': return "chevron-down"
        }
    }
    handleTabActions = () => {
        switch (this.state.tabLabel) {
            case 'Main Title': return this.handleClickActions;
            case 'Budget': return this.handleClickBudgetActions;
            case 'Business Affairs': return this.handleBusinessAffairs;
            case 'Files': return this.handleUploadFile;
        }
    }
    handleShowMainBatchesModal = (isBusinessAffairs) => {
        this.setState({ showMainBatchesModal: true, setBusinessAffairsActionsPopover: null, setActionsPopover: null, isBusinessAffairs: isBusinessAffairs })
    }
    handleHideMainBatchesModal = () => {
        this.setState({ showMainBatchesModal: false })
    }
    handleShowCueSheetDistribution = () => {
        this.setState({ showCueSheetDistribution: true });
    }
    handleHideCueSheetDistribution = () => {
        this.setState({ showCueSheetDistribution: false });
    }
    fetchData = (field, url, loader = true, origin = null) => {
        this.getShowBasedEpisodes()
        this.setState({ isLoading: loader })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, field === "getInitialValues" ? null : this.state.division_id)
            .then(response => {
                this.setState({ isLoading: false })
                let formattedList = [];
                switch (field) {
                    case 'graphValues':
                        this.setState({ graphValues: response.data });
                        break;
                    case 'getInitialValues':
                        formattedList = { ...response.data[0] };
                        formattedList["is_pilot"] = formattedList.is_pilot === null || formattedList.is_pilot === 0 ? 0 : 1;
                        // for time values in timepicker
                        let tempFromTime = "", tempToTime = "", finalTime = "";
                        if (formattedList.from_duration !== null) {
                            let from_duration = formattedList?.from_duration?.split(":");
                            formattedList["from_duration"] = { "hour": Number(from_duration[0]), "min": Number(from_duration[1]) }
                            if (formattedList?.from_duration.hour > 12) {
                                tempFromTime = (formattedList?.from_duration.hour - 12) + ":" + (formattedList?.from_duration.min == 0 ? "00" : formattedList?.from_duration.min) + " " + "pm";
                            }
                            else {
                                tempFromTime = formattedList?.from_duration.hour + ":" + (formattedList?.from_duration.min == 0 ? "00" : formattedList?.from_duration.min) + " " + "am";
                            }
                            formattedList["defaultFromDuration"] = false;
                        } else {
                            let d = new Date();
                            let from_hour = d.getHours();
                            let from_min = d.getMinutes();
                            formattedList["from_duration"] = { "hour": Number(from_hour), "min": Number(from_min) }
                            formattedList["defaultFromDuration"] = true;
                        }
                        if (formattedList.to_duration !== null) {
                            let to_duration = formattedList?.to_duration?.split(":");
                            formattedList["to_duration"] = { "hour": Number(to_duration[0]), "min": Number(to_duration[1]) }
                            if (formattedList?.to_duration?.hour > 12) {
                                tempToTime = (formattedList?.to_duration?.hour - 12) + ":" + (formattedList?.to_duration?.min == 0 ? "00" : formattedList?.to_duration?.min) + " " + "pm"
                            }
                            else {
                                tempToTime = formattedList?.to_duration.hour + ":" + (formattedList?.to_duration?.min == 0 ? "00" : formattedList?.to_duration?.min) + " " + "am"
                            }
                            formattedList["defaultToDuration"] = false;
                        } else {
                            let d = new Date();
                            let to_hour = d.getHours();
                            let to_min = d.getMinutes();
                            formattedList["to_duration"] = { "hour": Number(to_hour), "min": Number(to_min) }
                            formattedList["defaultToDuration"] = true;
                        }

                        finalTime = tempFromTime + "-" + tempToTime + " ";
                        formattedList.timeToDisplay = finalTime;
                        if (formattedList.optional_rights.length == 0) {
                            formattedList.optional_rights.push({
                                "show_season_rights_id": null,
                                "right_id": "",
                                "term": "",
                                "exp_term": "",
                                "territory": "",
                                "incl_init": 0,
                                "is_initial_right": null,
                                "is_optional": 1,
                                "is_alt_initial_right": null
                            })
                        }
                        if (origin !== "clearanceBudget" && origin !== "mainTitle") {
                            this.props?.history?.push(`/showseason/${formattedList.show_season_id}`);
                        }
                        let seasonOptions = formattedList?.seasons.map((item, index) => ({ value: item.season_id, label: item.season_name, rights_discount: item.rights_discount, show_season_id: item.show_season_id }));
                        selectedSeason = formattedList?.seasons?.filter(item => (item.season_id == formattedList?.season_id) ? item : null);
                        show_name = formattedList.show_name;
                        let fileQueryParam = {
                            "showName": formattedList?.show_name,
                            "season": selectedSeason.length > 0 && selectedSeason[0]?.season_name,
                            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                            "module": "showseason",
                            "isBoxUpload": 1
                        }
                        document.title = `${formattedList?.show_name} ${selectedSeason.length > 0 && selectedSeason[0]?.season_name}`;
                        this.setState({
                            initialRecordData: formattedList,
                            initialRecordDataBackup: response.data[0],
                            show_season_id: formattedList.show_season_id,
                            division_id: formattedList.division_id,
                            seasonOptions: seasonOptions,
                            editInitialRecordData: formattedList
                        }, () => {
                            if (origin !== "clearanceBudget" && origin !== "mainTitle") {
                                if (this.props.userContext?.active_tenant?.division_role?.length > 0) {
                                    divisionIdObj = this.props.userContext?.active_tenant?.division_role?.filter((item) => (item.division_id === this.state.division_id));
                                    if (divisionIdObj?.length > 0 && divisionIdObj[0]?.role_name !== 'Music Supervisor') {
                                        this.getPermissions(divisionIdObj[0].permissions);
                                        this.getMailTitlePermissions(formattedList.division_id);
                                        this.fetchData("graphValues", `/showSeasonRights?show_season_id=${this.state.show_season_id}`);
                                    } else {
                                        this.props?.history?.push(`/`);
                                    }
                                } else {
                                    this.props?.history?.push(`/`);
                                }
                            }
                        });

                        selectedSeason = selectedSeason.length > 0 && selectedSeason[0].season_name;
                        queryParam = {
                            "showName": show_name,
                            "season": selectedSeason,
                            "module": "showseason",
                            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                            "isBoxUpload": 1
                        }
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false }, () => {
                        this.props?.history?.push(`/`);
                    })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    popOverAction = (event) => {
        this.setState({
            setSeasonPopover: event.currentTarget
        })
    }
    handleSeasonClose = (event) => {
        this.setState({
            setSeasonPopover: null
        })
    }
    disabletriggerGetFileData = (value) => {
        this.setState({ triggerGetFileData: value });
    }
    triggerGetFileData = (value) => {
        this.setState({ triggerGetFileData: value });
    }
    seasonSelected = (item) => {

        this.setState((prevState) => ({
            show_season_id: item.show_season_id, isLoading: true
        }));
        this.fetchData("getInitialValues", `/showSeason?showSeasonId=${item.show_season_id}`);
        this.handleSeasonClose();
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            let displayFilesTab = [], displayPeopleTab = [], displayBusinessAffairsTab = [], displayBudgetTab = [], displayCueSheetTab = [], displayMainTitleTab = [], displaySeasonDetailsTab = []
            let tempShowSeasonTabList = [], ShowSeasonTabList = [];
            canNavigateToReportButton = permissions.filter((obj) => obj.permission_name === "Can navigate to Reports tab" && obj.permission_group_name === "Reports" && obj.view === 1);
            canNavigateToReportButton = canNavigateToReportButton.length > 0 ? true : false;
            canAddEditTvCrMusicBudgetUnion = permissions.filter((obj) => obj.permission_name === "TV Cr. Music Budget: Edit Unions" && obj.permission_group_name === "SS - Budgeting" && obj.view === 1);
            canAddEditTvCrMusicBudgetUnion = canAddEditTvCrMusicBudgetUnion.length > 0 ? true : false;
            permissionArrDetailsTab = permissions.filter(obj => (obj.permission_group_name === "SS - Details" || obj.permission_group_name === "SS - Episode Table" || obj.permission_group_name === "New/Edit Episode"));
            permissionArrBudgetTab = permissions.filter(obj => obj.permission_group_name === "SS - Budgeting");
            permissionArrCSTab = permissions.filter(obj => obj.permission_group_name === "SS - Cue Sheets");
            permissionArrBusinessAffairs = permissions.filter(obj => obj.permission_group_name === "SS - Business Affairs" || obj.permission_group_name === "Music Supervisors/Show Composers");
            permissionArrPeople = permissions.filter(obj => obj.permission_group_name === "SS - People");
            permissionArrFile = permissions.filter(obj => obj.permission_group_name === "SS - Files");
            permissionArrMainTitile = permissions.filter(obj => obj.permission_group_name === "SS - Main Title");
            displaySeasonDetailsTab = permissions.filter(obj => obj.permission_name === "Show Season - Details section" && obj.permission_group_name === "SS - Details" && obj.view === 1);
            if (displaySeasonDetailsTab.length > 0) { tempShowSeasonTabList.push("Details"); }
            displayMainTitleTab = permissions.filter(obj => obj.permission_group_name === "SS - Main Title");
            if (displayMainTitleTab.length > 0) { tempShowSeasonTabList.push("Main Title"); }
            displayCueSheetTab = permissions.filter(obj => obj.permission_name === "View Cue Sheets tab + table" && obj.permission_group_name === "SS - Cue Sheets");
            if (displayCueSheetTab.length > 0) { tempShowSeasonTabList.push("Cue Sheets"); cueSheetTabVisibility = true }
            displayBudgetTab = permissions.filter(obj => (this.state.initialRecordData?.is_pilot === 1 ? ((obj.permission_name === "Clearance Budget" && obj.view === 1) || (obj.permission_name === "Pilot Budget" && obj.view === 1) || (obj.permission_name === "TV Cr. Music Budget" && obj.view === 1) || (obj.permission_name === "TV Cr. Music Budget: Edit Unions" && obj.view === 1)) : ( (obj.permission_name === "TV Cr. Music Budget" && obj.view === 1) || (obj.permission_name === "TV Cr. Music Budget: Edit Unions" && obj.view === 1))));
            if (displayBudgetTab.length > 0) { tempShowSeasonTabList.push("Budget"); }
            displayBusinessAffairsTab = permissions.filter(obj => obj.permission_name === "Show Composer/Music Supervisors" && obj.permission_group_name === "SS - Business Affairs" && obj.view === 1);
            if (displayBusinessAffairsTab.length > 0) { tempShowSeasonTabList.push("Business Affairs"); }
            displayPeopleTab = permissions.filter(obj => obj.permission_name === "People (SCs/MSs/MEs) + Other Contacts (Producers, Publicist, and Marketing Contacts)" && obj.view === 1);
            if (displayPeopleTab.length > 0) { tempShowSeasonTabList.push("People"); }
            displayFilesTab = permissions.filter(obj => obj.permission_name === "Files tab" && obj.view === 1);
            if (displayFilesTab.length > 0) { tempShowSeasonTabList.push("Files"); }
            tempShowSeasonTabList.map((item, index) => { ShowSeasonTabList.push({ label: item, value: index + 1 }) });
            if (ShowSeasonTabList.length > 0) {
                if (window.name === 're-route') {
                    this.setState({ tabValue: 2, tabLabel: 'Main Title', ShowSeasonTabList: ShowSeasonTabList }, this.getTabComponent);
                } else if (window.name === 're-route to Cue sheets') {
                    this.setState({ tabValue: 3, tabLabel: 'Cue Sheets', ShowSeasonTabList: ShowSeasonTabList }, this.getTabComponent);
                } else if (window.name === 're-route creative budget') {
                    this.setState({ tabValue: 4, tabLabel: 'Budget', ShowSeasonTabList: ShowSeasonTabList }, this.getTabComponent);
                } else {
                    this.setState({ tabValue: ShowSeasonTabList[0].value, tabLabel: ShowSeasonTabList[0].label, ShowSeasonTabList: ShowSeasonTabList }, this.getTabComponent);
                }
            }
            //can be used for new season
            //displayCopyButton = permissions.filter(obj => obj.permission_name === "Can copy a Show/Season");
            //displayCopyButton = displayCopyButton.length > 0 ? true : false;
            displayGraphButton = permissions.filter(obj => obj.permission_name === "Show/Season statistics (graph icon)");
            displayGraphButton = displayGraphButton.length > 0 ? true : false;
            displayCostSpitsButton = permissions.filter(obj => obj.permission_name === "Cost Splits modal");
            displayCostSpitsButton = displayCostSpitsButton.length > 0 ? true : false;
            displayShowDetailsButton = permissions.filter(obj => (obj.permission_name === "Edit Show Details - Details tab (All minus Show Season Budgets)" && obj.view === 1 && obj.edit === 1) || (obj.permission_name === "Edit Show Details - Details tab (Show Season Budgets section)" && obj.view === 1 && obj.edit === 1));
            displayShowDetailsButton = displayShowDetailsButton.length > 0 ? true : false;
            displayDistributionButton = permissions.filter(obj => obj.permission_name === "Cue Sheet Distribution pop-up");
            displayDistributionButton = displayDistributionButton.length > 0 ? displayDistributionButton[0].view === 1 : false;
            canCreateEditBatches = permissions.filter(obj => obj.permission_name === "Batches" && obj.view === 1 && obj.edit === 1 && obj.delete === 1);
            canCreateEditBatches = canCreateEditBatches.length > 0 ? true : false;
            budgetingSetupPermission = permissions?.find(obj => obj.permission_name === "Budgeting Setup");
            budgetingReportPermission = permissions?.find(obj => obj.permission_name === "Budgeting Report");
            canApplyPayIncrease = permissions.filter(obj => obj.permission_name === "Apply pay increase modal" && obj.view === 1);
            canApplyPayIncrease = canApplyPayIncrease.length > 0 ? true : false;
            displayNewSeasonButton = permissions.filter((obj, index) => obj.permission_name === "New Show Season, Import ADDR, Show Season pop-up" && obj.view === 1);
            displayNewSeasonButton = displayNewSeasonButton.length > 0 ? true : false;    
            allReportsPermission = permissions.filter((obj, index) =>  obj.permission_group_name === "Reports" && obj.view === 1);
            episodeSongPermissions = permissions?.find((obj) => obj.permission_group_name==="Songs");
        }
        this.setState({commonPermissions:permissions})
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }
    getScAndMSDetails(showCompAndMusicSupDetails) {
        scAndMSdetails = [...showCompAndMusicSupDetails];
    }
    getProductionEntityOptions(options) {
        this.setState({ productionEntityOptions: options });
    }
    getUnionList = (url) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
            .then(response => {
                this.setState({ isLoading: false })
                let formattedListUnion = response?.data[0]?.unions;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["unions"]: formattedListUnion
                    }
                }));
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    getChildDetails = (initialRecordData) => {
        this.setState({ editInitialRecordData: initialRecordData });
    }
    getApplyPayIncrease = (payIncreaseList) => {
        this.setState({ payIncreaseList });
    }
    getFav = (field, url) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isPostingFav: false });
                switch (field) {
                    case 'getFavData':
                        if (response.data.length > 0) {
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    ["is_favourite"]: response.data[0].is_favourite
                                }
                            }));
                        }
                        break;
                }
            },
                (err) => {
                    this.setState({ isPostingFav: false });
                    console.log("Error in fetching Favorite", err)
                })
    }
    addFav(isFavFlag) {
        this.setState({ isPostingFav: true });
        let postObj = {
            'is_favourite': isFavFlag,
            "show_id": this.state.show_id,
            "season_id": this.state.season_id,
            "show_season_id": this.state.show_season_id
        }
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + '/favoriteShowSeason', postObj, this.props.userContext?.active_tenant?.tenant_id, this.state.initialRecordData.division_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isPostingFav: false });
                    this.notificationComponent(true, "fail");
                } else {
                    this.getFav("getFavData", `/showSeason?showSeasonId=${this.state.initialRecordDataBackup?.show_season_id}`);
                    this.notificationComponent(true, "success");
                }
            },
                (err) => {
                    this.setState({ isPostingFav: false });
                    this.notificationComponent(true, "fail");
                    console.log("Post agency details error: " + err);
                });
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        // if (this.state.saveFlag) {

        // }
    }
    handleShowBudgetSetupModal = () => {
        this.setState({ showBudgetSetupModal: true, setBusinessAffairsActionsPopover: null })
    }
    handleBudgetingReportModal = () => {
        this.setState({ showBudgetReportModal: true, setBusinessAffairsActionsPopover: null })
    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    render() {
        let commonPermissions=this.props.userContext?.active_tenant?.common_permissions?.find((item) => item.permission_name === "Cost Splits modal");
        let open = Boolean(this.state.setPopover);
        let openSeason = Boolean(this.state.setSeasonPopover);
        let openReports = Boolean(this.state.setPopoverReports);
        let openActions = Boolean(this.state.setActionsPopover);
        let openBusinessAffairsActions = Boolean(this.state.setBusinessAffairsActionsPopover);
        let openBudgetActions = Boolean(this.state.setBudgetActionsPopover);
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        return (

            <MDBContainer fluid className="showseason-detail-container">
                {this.state.isLoading === true ?
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={40} />
                    </div>
                    : <>
                        <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                            handleResetNotify={this.handleResetNotify.bind(this)} />
                        <MDBRow>
                            <MDBCol md={9}>
                                <MDBRow>
                                    <div className='title'>
                                        <div style={{ display: 'flex' }}>
                                            {/* <BasicButton className="plus-icon-Details"
                                            icon={"plus-circle"} text=" "
                                            variant="contained"
                                            onClick={() => this.state.episodeData?.length > 0 ? this.episodeIncrement() : this.handleShowEpisodeModal("New")} /> */}
                                            {this.state.isPostingFav ? (
                                                <div style={{ textAlign: "center" }}>
                                                    <CircularProgress size={10} />
                                                </div>
                                            ) : (<>
                                                {this.state.initialRecordData?.is_favourite === 0 && <StarBorderIcon color="primary" onClick={() => this.addFav(1)} />}
                                                {this.state.initialRecordData?.is_favourite === 1 && <StarIcon color="primary" onClick={() => this.addFav(0)} />}
                                            </>)}

                                            <span className="headingContainer">{this.state.initialRecordData.show_name}</span>
                                            {this.state.initialRecordData?.seasons && this.state.initialRecordData.seasons.length > 0 && this.state.initialRecordData.seasons.map((item, index) => (
                                                <div className="SeasonDropdownWrapper" onClick={this.popOverAction}>
                                                    {item.season_id == this.state.initialRecordData.season_id && <div className="secondaryLabelContainer">
                                                        <BasicLabel className="title-label" text={item.season_name} />
                                                        <span className='ml5'>
                                                            <ExpandMoreIcon fontSize='medium' className='expand-more-icon' />
                                                        </span>
                                                    </div>}
                                                </div>
                                            ))}
                                            {(this.state.initialRecordData?.is_pilot === 1) && <span className="pilotIndicator">This Show Contains a Pilot</span>}
                                            <Popover
                                                open={openSeason}
                                                anchorEl={this.state.setSeasonPopover}
                                                onClose={this.handleSeasonClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }} >
                                                {this.state.initialRecordData?.seasons && this.state.initialRecordData?.season_id && this.state.initialRecordData.seasons.length > 0 && this.state.initialRecordData.seasons.map((item, index) => {
                                                    return <MDBRow key={"season" + index} id={"season" + index} onClick={() => this.seasonSelected(item)}><span classname="popover-content-title">{item.season_name}</span></MDBRow>
                                                })}
                                                {displayNewSeasonButton && <BasicButton text="New Season"
                                                    variant="contained" onClick={this.handleShowNewShowSeasonModal} />}
                                            </Popover>
                                        </div>
                                    </div>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3} className="flex justify-content-between" align="end">
                                {/*  <MDBRow>
                                <MDBCol md={1}></MDBCol>
                                <MDBCol md={2} align="end">
                                </MDBCol>
                                <MDBCol md={2} align="end"> */}
                                {displayGraphButton && <>
                                    <BasicButton className="show-season-icons"
                                        icon={"chart-bar"} text=" "
                                        variant="contained" onClick={this.handleClickChart} />
                                    <Popover
                                        open={open}
                                        anchorEl={this.state.setPopover}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        PaperProps={{ style: { width: '30%',padding:'1rem' } }}
                                    >
                                        {this.state.graphValues?.length > 0 ? this.state.graphValues?.map((item, index) => {
                                            return <MDBRow className="popoverRightsWrapper" key={"graphRight" + index}>
                                                <p className="popoverHeading">{item.right_abbr}</p>
                                                <p className="popover-content-title">{item.term_name}</p>
                                                <p className="popover-content-title">{item.total_fee}</p>
                                            </MDBRow>
                                        }) : null}
                                    </Popover>
                                </>}
                                {/* </MDBCol>
                                <MDBCol md={3} align="end"> */}
                                {canNavigateToReportButton && <div className='icon-details'>
                                    <BasicButton
                                        icon={"chevron-down"} text="Reports"
                                        variant="contained" iconRight={true} onClick={this.handleClickReports} />
                                    <Popover
                                        open={openReports}
                                        anchorEl={this.state.setPopoverReports}
                                        onClose={this.handleCloseReports}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        PaperProps={{ style: { width: '18%' } }} >
                                        {PopoverReportOptions.map((item, index) => {
                                            if(allReportsPermission?.find(ele => ele?.permission_name === item?.permissionName && ele?.view === 1)) {
                                                return <Link onClick={() => this.handleReportItem(item.value)} component={RouterLink} to={'/'}><div>{item.label}</div></Link>
                                            }
                                            //return <MDBRow className="popoverRightsWrapper"><p onClick={() => this.handleReportItem(item)}className="popover-content-title">{item}</p></MDBRow>
                                        })}
                                    </Popover>
                                </div>
                                }
                                {/* </MDBCol>
                                <MDBCol md={4} align="end"> */}
                                {displayCostSpitsButton &&
                                    <BasicButton
                                        text="Cost Splits "
                                        variant="contained" onClick={this.handleShowCostSplitsModal} />
                                }
                                {/* </MDBCol>
                            </MDBRow> */}
                            </MDBCol>
                        </MDBRow >

                        <MDBRow>
                            <MDBCol md={12}>
                                <MDBRow className='tab-content'>
                                    <MDBCol md={10}>
                                        <TabsComponent
                                            tabList={this.state.ShowSeasonTabList}
                                            value={this.state.tabValue}
                                            //badgeContent={this.state.fileCount || 0}
                                            onChange={(e, v) => this.handleTabChange(v)}
                                        />
                                    </MDBCol>


                                    <MDBCol md={2} style={{ marginTop: '12px' }} align="end">
                                        {this.state.tabLabel === "Details" && displayShowDetailsButton &&
                                            (<BasicButton className="show-detials-btn"
                                                variant="contained"
                                                text={"Show Details"}
                                                icon={"pen"}
                                                iconRight={false}
                                                onClick={this.handleShowEditShowDetailsModal}
                                            />)
                                        }
                                        {this.state.tabLabel === "Cue Sheets" && displayDistributionButton &&
                                            (<BasicButton className="show-detials-btn"
                                                variant="contained"
                                                text={"Distribution"}
                                                icon={""}
                                                iconRight={false}
                                                onClick={this.handleShowCueSheetDistribution}
                                            />)
                                        }
                                        {this.state.tabLabel !== "Details" && this.state.tabLabel !== "Cue Sheets" && this.state.tabLabel !== "People" && (this.state.tabLabel === "Main Title" || this.state.tabLabel === "Budget" || (this.state.tabLabel === "Business Affairs" && (budgetingReportPermission?.view == 1 || canApplyPayIncrease || canCreateEditBatches || budgetingSetupPermission?.view == 1 ))|| this.state.tabLabel === "Files") && <>
                                            <BasicButton className="show-detials-btn"
                                                variant="contained"
                                                text={this.handleText()}
                                                icon={this.handleIcon()}
                                                iconRight={(this.state.tabLabel === "Main Title" || this.state.tabLabel === "Budget" || this.state.tabLabel === "Business Affairs") ? true : false}
                                                onClick={this.handleTabActions()}
                                            />
                                            <Popover
                                                open={openActions}
                                                anchorEl={this.state.setActionsPopover}
                                                onClose={this.handleCloseActions}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}>
                                                {mainTitlePermissions?.find(item => item?.permission_name === 'MT CR: Can VIEW/USE/EXPORT Batches' && item?.view === 1) && <p className="popover-content-value" onClick={() => this.handleShowMainBatchesModal(false)}>MT Batches</p>}
                                                {allReportsPermission?.find(item => item?.permission_name === 'Main Title Report' && item?.view === 1) && <p className="popover-content-value"><Link onClick={() => this.handleReportItem("mainTitleReport")} component={RouterLink} to={'/'}>
                                                    <p>Main Title Reports</p></Link></p>}
                                            </Popover>
                                            <Popover
                                                open={openBusinessAffairsActions}
                                                anchorEl={this.state.setBusinessAffairsActionsPopover}
                                                onClose={this.handleCloseBusinessAffairsActions}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }} >
                                                {budgetingSetupPermission?.view === 1 ? <p className="popover-content-value" onClick={this.handleShowBudgetSetupModal}>Budgeting Setup</p> : null}
                                                {budgetingReportPermission?.view === 1 ? <p className="popover-content-value" onClick={this.handleBudgetingReportModal}>Budgeting Report</p> : null}
                                                {canCreateEditBatches ? (<p className="popover-content-value" onClick={() => this.handleShowMainBatchesModal(true)}>Batches</p>) : null}
                                                {canApplyPayIncrease ? (<p className="popover-content-value" onClick={this.handleShowApplyPayToIncrease}>Apply Pay Increase</p>) : null}
                                            </Popover>
                                            {canAddEditTvCrMusicBudgetUnion &&
                                                <Popover
                                                    open={openBudgetActions}
                                                    anchorEl={this.state.setBudgetActionsPopover}
                                                    onClose={this.handleCloseBudgetActions}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }} >
                                                    {/* <p className="popover-content-value" onClick={this.handleShowBudgetTabModal}>Edit budget details</p> */}
                                                    <p className="popover-content-value" onClick={() => this.handleEditUnionTabModal("Edit")}>{this.state.initialRecordData?.unions?.length === 0 ? "Add Unions" : "Edit Unions"}</p>
                                                    {/* <p className="popover-content-value">Export budget as excel</p>
                                        <p className="popover-content-value" >Export budget as pdf</p> */}
                                                    {/* <p className="popover-content-value" >Email budget</p> */}
                                                </Popover>
                                            }
                                        </>}
                                    </MDBCol>

                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={12}>
                                {this.state.isLoading ? (
                                    <div style={{ textAlign: "center" }}>
                                        <CircularProgress size={24} />
                                    </div>
                                ) : this.state.initialRecordData?.show_season_id && this.state.ShowSeasonTabList.length > 0 && (permissionArrDetailsTab.length > 0 || permissionArrBudgetTab.length > 0 || permissionArrBusinessAffairs.length > 0 || permissionArrPeople.length > 0 || permissionArrMainTitile.length > 0 || cueSheetTabVisibility) && this.getTabComponent()}

                            </MDBCol>
                        </MDBRow>

                    </>}
                {this.state.setFileModal &&
                    <MainViewModalComponent
                        open={this.state.setFileModal}
                        division_id={this.state.division_id}
                        handleClose={this.closeUploadFile}
                        headerText={"Upload File"}
                        mode={""}
                        modalName={"UploadFile"}
                        initialRecordData={this.state.initialRecordData}
                        triggerGetFileData={this.triggerGetFileData.bind(this)}
                        origin={"Files"}
                    />}
                {
                    this.state.showMainBatchesModal &&
                    <MainViewModalComponent
                        open={this.state.showMainBatchesModal}
                        handleClose={this.handleHideMainBatchesModal}
                        headerText={"Batches"}
                        mode={""}
                        modalName={"Batches"}
                        isMainTitle={true}
                        main_title_id={this.state.initialRecordData?.main_title_id}
                        show_season_id={this.state.show_season_id}
                        isBusinessAffairs={this.state.isBusinessAffairs}
                        batchesPermissions={this.state.isBusinessAffairs ? permissionArrBusinessAffairs?.find(item => item?.permission_name === 'Batches'):batchesPermissions}
                        division_id={this.state.division_id}
                    />
                }
                {
                    this.state.showCueSheetDistribution && (
                        <MainViewModalComponent
                            open={this.state.showCueSheetDistribution}
                            handleClose={this.handleHideCueSheetDistribution}
                            headerText={"Cue Sheet Distribution"}
                            mode={""}
                            modalName={"Cue Sheet Distribution"}
                            showSeasonData={this.state.initialRecordData}
                            permissionArr={permissionArrCSTab}
                        />
                    )
                }
                {
                    this.state.applyPayIncreaseModal &&
                    <MainViewModalComponent
                        open={this.state.applyPayIncreaseModal}
                        handleClose={this.handleHideApplyPayToIncrease}
                        headerText={"Apply Pay Increase"}
                        mode={""}
                        modalName={"Apply Pay Increase"}
                        scAndMSdetails={scAndMSdetails}
                        divisionId={this.state.division_id}
                        showSeasonId={this.state.show_season_id}
                        showId={this.state?.initialRecordData.show_id}
                        getApplyPayIncrease={this.getApplyPayIncrease.bind(this)}
                    />
                }
                {/* {
                    this.state.showEditBudgetTabModal &&
                    <>
                        {console.log("this.state.initialRecordData", this.state.initialRecordData)}
                        {console.log("this.state.initialRecordData?.is_pilot", this.state.initialRecordData?.is_pilot)}
                        <MainViewModalComponent
                            open={this.state.showEditBudgetTabModal}
                            handleClose={this.handleHideBudgetTabModal}
                            headerText={this.state.initialRecordData?.show_name.concat(" Tv Budget Details")}
                            mode={" "}
                            modalName={"Edit Budget Tab Modal"}
                            initialRecordData={this.state.initialRecordData}
                            is_pilot={this.state.initialRecordData?.is_pilot}
                            show_season_id={this.state.show_season_id}
                            season_id={this.state?.initialRecordData?.season_id}
                            show_id={this.state?.initialRecordData?.show_id}
                            episodesArray={this.state.episodesArray}
                        />
                    </>
                } */}
                {
                    this.state.showEditUnionTabModal &&
                    <MainViewModalComponent
                        open={this.state.showEditUnionTabModal}
                        handleClose={this.handleHideEditUnionTabModal}
                        headerText={this.state.initialRecordData?.show_name.concat(" TV Cr. Music Budget-Unions")}
                        mode={this.state.mode}
                        modalName={"Edit Union"}
                        initialRecordData={this.state.initialRecordData}
                        show_season_id={this.state.show_season_id}
                        season_id={this.state?.initialRecordData?.season_id}
                        show_id={this.state?.initialRecordData?.show_id}
                        is_pilot={this.state?.initialRecordData?.is_pilot}
                        getUnionList={this.getUnionList.bind(this)}
                    />
                }
                {
                    this.state.showCostSplitsModal &&
                    <MainViewModalComponent
                        open={this.state.showCostSplitsModal}
                        handleClose={this.handleHideCostSplitsModal}
                        headerText={"Edit Cost Splits"}
                        mode={""}
                        modalName={"Edit Cost Splits"}
                        showSeasonDetails={this.state.initialRecordData}
                        division_id={this.state.division_id}
                        toggleRefresh={this.fetchData}
                        isMaintitle={true}
                        commonPermissions={this.state.commonPermissions?.find((item) => item.permission_name === "Cost Splits modal")}
                    />
                }
                {
                    this.state.showNewShowSeasonModal &&
                    <MainViewModalComponent
                        open={this.state.showNewShowSeasonModal}
                        handleClose={this.handleHideNewShowSeasonModal}
                        headerText={"New Season for ".concat(this.state.initialRecordData?.show_name)}
                        mode={""}
                        modalName={"NewSeason"}
                        division_id={this.state.division_id}
                        show_season_id={this.state.show_season_id}
                        seasonOptions={this.state.seasonOptions}
                        show_id={this.state?.initialRecordData.show_id}
                        fetchData={this.fetchData.bind(this)}
                        initialRecordData={this.state.initialRecordData}
                    />
                }
                {
                    this.state.showEditShowDetailsModal &&
                    <MainViewModalComponent
                        open={this.state.showEditShowDetailsModal}
                        handleClose={this.handleHideEditShowDetailsModal.bind(this)}
                        headerText={"Show Details"}
                        mode={"Edit"}
                        modalName={"Edit ShowDetails"}
                        show_season_id={this.state.show_season_id}
                        fetchData={this.fetchData.bind(this)}
                        division_id={this.state.division_id}
                        permissionArr={permissionArrDetailsTab}
                        initialRecordData={this.state?.editInitialRecordData}
                        seasonOptions={this.state.seasonOptions}
                        getChildDetails={this.getChildDetails.bind(this)}
                        origin={"showSeasonDetails"}
                    />
                }
                {
                    this.state.showBudgetSetupModal &&
                    <MainViewModalComponent
                        open={this.state.showBudgetSetupModal}
                        handleClose={() => this.setState({ showBudgetSetupModal: false }, this.getShowBasedEpisodes)}
                        headerText={"SC/MS Budgeting Setup"}
                        mode={""}
                        modalName={"SC/MS Budgeting Setup"}
                        showSeasonId={this.state.show_season_id}
                        divisionId={this.state.division_id}
                        seasonId={this.state.initialRecordData.season_id}
                        showId={this.state.initialRecordData.show_id}
                    />
                }
                {
                    this.state.showBudgetReportModal &&
                    <MainViewModalComponent
                        open={this.state.showBudgetReportModal}
                        handleClose={() => this.setState({ showBudgetReportModal: false })}
                        headerText={"Budgeting Report Options"}
                        mode={""}
                        modalName={"Budgeting Report Options"}
                        showSeasonId={this.state.show_season_id}
                        divisionId={this.state.division_id}
                    />
                }
            </MDBContainer >
        )
    }
    getMailTitlePermissions = (div_id) => {
        let tenantInfo = this.props.userContext?.user_profile?.tenants?.filter((item) => item.tenant_id == this.props.userContext?.active_tenant?.tenant_id)
        let divisionInfo = tenantInfo[0]?.division_role?.filter((item) => item.division_id === div_id)
        mainTitlePermissions = divisionInfo[0]?.permissions?.filter((item) => item.permission_group_name === "SS - Main Title")
        peopleModalPopupPermissions = divisionInfo[0]?.permissions?.find((item) => item.permission_group_name === "People (SS)")
        songAndPerformerPermissions = mainTitlePermissions?.filter((per) => clearanceRecordPermissionsConfig.find((item) => item.buttonName == "song/performerInfo" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)));
        batchesPermissions = mainTitlePermissions?.find((per) => clearanceRecordPermissionsConfig.find((item) => item.buttonName == "ClearanceBatches" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)));
    }
    componentDidMount() {
        if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Editor')) {
            this.props.history.push('/cueSheet');
        }
        // if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
        //     this.props.history.push('/userRoleManagement');
        if (this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
            this.props.history.push('/userRoleManagement');
        } else {
            if (this.props.userContext?.active_tenant?.tenant_id) {
                this.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.state.show_season_id}`);
            }
        }
        // if(this.props?.tabValue && this.props?.tabLabel){
        //     // console.log("checking props",this.props?.tabValue, this.props?.tabLabel)
        //     this.setState({
        //         tabValue: this.props?.tabValue,
        //         tabLabel: this.props?.tabLabel
        //     }, () => {console.log("after setstate",this.state.tabValue, this.state.tabLabel)})
        //     // this.handleTabChange(this.props?.tabValue)
        // }
    }
    componentDidUpdate(prevProps, prevState) {
        // if ((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
        //     && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
        //     this.props.history.push('/userRoleManagement');
        // }
        // if ((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
        //     && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
        //     this.props.history.push('/userRoleManagement');
        if (this.props?.userContext?.user_profile?.is_user_provisioner && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
            this.props.history.push('/userRoleManagement');
        } else {
            if (this.props.userContext?.active_tenant?.tenant_id !== prevProps.userContext.active_tenant?.tenant_id) {
                this.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.state.show_season_id}`);
                this.fetchData("graphValues", `/showSeasonRights?show_season_id=${this.state.show_season_id}`);
            }
        }
    }
}

export default withRouter(withUserContext(ShowSeasonDetails));