import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import DateField from "../../SharedComponents/DateField/DateField";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import TableComponent from "../../SharedComponents/Table";
import { withUserContext } from "../../../contexts/UserContext";
import {
  AllBatchHeading,
  NewBatchesHeading,
  AllMTBatchesHeading,
  NewMTBatchesHeading,
  businessAffairsHeading,
  businessAffairsNumberHeading,
  dynamicColumns,
  dynamicColumnsEp,
} from "./config";
import "./Batches.scss";
import moment from "moment";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import MessageModal from "../../SharedComponents/MessageModal";
import { getFormattedDate } from "../../../Common/Helper";
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent";
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";

export default withUserContext(
  class Batches extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        BatchOption: [],
        selectedOption: "ALL",
        batchesData: [],
        batchesRecordData: {},
        selectedBatches: [],
        isLoading: false,
        isSubmit: false,
        isDelete: false,
        isUpdate: false,
        isRemove: false,
        isCreateBatch: false,
        isOpen: false,
        postInitiated: false,
        isExcelPosting: false,
        isOpenPaidModal: false,
        showSuccess: false,
        showFail: false,
        saveFlag: false,
        selectedField: null,
        isFieldChanged: false,
        isSelectedRemoveBatch: false,
        paidSongDetailText: null,
        paidBatchList: [],
        isEmailBatch: false,
        isOpenEmailModal: false,
        emailTextMsg: null,
        callFromExcelBatch: false,
        clearanceBatchesPermissions: this.props?.clearanceBatchesPermissions,
        isCheckTransmittal: false,
        checkTransmittalList: [],
        isCheckTransmittalButton: false,
        showExcelAndDocModal: false,
        searchPayload: {},
        isOpenExisting: false,
        showDocModal: false,
        AllMTBatchesHeading :JSON.parse(JSON.stringify(AllMTBatchesHeading)),
        NewMTBatchesHeading :JSON.parse(JSON.stringify(NewMTBatchesHeading)),
        AllBatchHeading :JSON.parse(JSON.stringify(AllBatchHeading)),
        NewBatchesHeading :JSON.parse(JSON.stringify(NewBatchesHeading)),
      };
    }
    componentDidMount() {
      this.getBatchesList("ALL");
    }
    handleShowExcelAndDocModal = () => {
      this.setState({ showExcelAndDocModal: true });
    };
    handleHideExcelAndDocModal = () => {
      this.setState({ showExcelAndDocModal: false });
    };
    handleShowDocModal = () => {
      this.setState({ showDocModal: true });
    };
    handleHideDocModal = () => {
      this.setState({ showDocModal: false });
    };
    handleGeneratePreviewBatch = (previewFlag, batchType) => {
      this.setState({ postInitiated: true, isPreview: previewFlag });
      let reportName = this.props?.isBusinessAffairs
        ? "rptSCMSExcelBatchPdf"
        : this.props?.isMainTitle
        ? "rptMainTitleExcelBatchPdf"
        : "rptClearanceExcelBatchPdf";
      let searchPayload = {};
      if (this.props?.isBusinessAffairs) {
        searchPayload = {
          show_season_id: this.props?.show_season_id,
          batch_no: this.state.selectedOption,
        };
      } else if (this.props?.isMainTitle) {
        searchPayload = {
          main_title_id: this.props?.main_title_id,
          batch_no: this.state.selectedOption,
        };
      } else {
        searchPayload = {
          show_season_episode_id: this.props?.show_season_episode_id,
          batch_no: this.state.selectedOption,
        };
      }
      this.downloadExcelReport(reportName, searchPayload, "isPdf")
        .then((webSocketConn) => {
          webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            this.setState({ isPreview: false });
            if (batchType === "isPdf") {
              if (response?.statusCode == 200) {
                this.setState({ isPreview: false });
                this.downloadUrlPdf(response?.body);
                this.notificationComponent(true, "success");
              } else {
                //this.notificationComponent(true, 'success')
              }
            }
          };
        })
        .catch((err) => {
          this.setState({ isPreview: false });
          this.notificationComponent(true, "fail");
        });
    };
    downloadUrlPdf = (url, reportName) => {
      if (url) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            //link.href = window.URL.createObjectURL(blob);
            //link.setAttribute('download', reportName);
            link.href = url;
            document.body.appendChild(link);
            this.handleHideDocModal();
            link.click();
            link.remove();
            //this.setState({ isExcelPosting: false});
            //this.notificationComponent(true, "success");
          });
      }
    };
    downloadUrl = (url, reportName) => {
      console.log('check the report url', url)
      if (url) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", reportName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            //this.handleHideExcelAndDocModal();
            //this.setState({ isExcelPosting: false});
            //this.notificationComponent(true, "success");
          });
      }
    };
    downloadExcelReport = (reportName, searchPayload, reportType) => {
      if (!searchPayload || !reportName) {
        return;
      }
      Object.keys(searchPayload).map((obj) => {
        if (searchPayload[obj] === undefined) {
          searchPayload[obj] = null;
        }
      });
      let encryptTedStr = btoa(JSON.stringify(searchPayload));
      let load = {};
      if (reportType === "isPdf") {
        load = {
          payload: "exportPdf",
          reportName: reportName,
          fileName: this.state.callFromExcelBatch
            ? this.state.batchesRecordData?.fileName
            : this.state.batchesRecordData?.previewFileName,
          searchJson: encryptTedStr,
          showHtml: 0,
          encryptionType: 1,
          tenantId: this.props?.userContext?.active_tenant?.tenant_id,
          divisionId: this.props?.division_id,
        };
        return clearTrackService.getDataWS(load);
      } else if (reportType === "isExcel") {
        load = {
          payload: "exportExcel",
          reportName: reportName,
          fileName: null, //this.props?.isBusinessAffairs?"SCMSExcelBatch":this.props?.isMainTitle?"MainTitleExcelBatch":"ClearanceExcelBatch",
          searchJson: encryptTedStr,
          showHtml: 0,
          encryptionType: 1,
          tenantId: this.props?.userContext?.active_tenant?.tenant_id,
          divisionId: this.props?.division_id,
        };
        return clearTrackService.getDataWS(load);
      } else if (reportType === "isDocx") {
        load = {
          payload: "exportDocx",
          reportName: reportName,
          fileName: null, // this.props?.isMainTitle?"MainTitleOptExcelBatch":"ClearanceOptExcelBatch",
          searchJson: encryptTedStr,
          showHtml: 0,
          encryptionType: 1,
          tenantId: this.props?.userContext?.active_tenant?.tenant_id,
          divisionId: this.props?.division_id,
        };
        return clearTrackService.getDataWS(load);
      }
    };
    isOpenExisting = (data) => {
      this.setState({ isOpenExisting: data });
    };

    downLoadExcelForBox = () => {
        if(this.props?.isBusinessAffairs) {
          this.downloadExcel('rptSCMSExcelBatch')
          //this.downloadExcel('rptSCMSExcelBatchFiori', true)
        } else if(this.props?.isMainTitle) {
          this.downloadExcel('rptMainTitleExcelBatch')
          //this.downloadExcel('rptMainTitleExcelBatchFiori', true)
        } else {
          this.downloadExcel('rptClearanceExcelBatch')
          // this.downloadExcel('rptClearanceExcelBatchFiori', true)
        }
    }

    downloadExcel = (reportNameDownload, showNotification=false) => {
      this.setState({
        postInitiated: true,
        isExcelPosting: true,
        emailTextMsg: null,
      });
      let reportName = reportNameDownload;
      let searchPayload = {};
      if (this.props?.isBusinessAffairs) {
        searchPayload = {
          show_season_id: this.props?.show_season_id,
          batch_no: this.state.selectedOption,
        };
      } else if (this.props?.isMainTitle) {
        searchPayload = {
          main_title_id: this.props?.main_title_id,
          batch_no: this.state.selectedOption,
        };
      } else {
        searchPayload = {
          show_season_episode_id: this.props?.show_season_episode_id,
          batch_no: this.state.selectedOption,
        };
      }
      searchPayload["isOpenExisting"] = this.state.isOpenExisting ? 1 : 0;
      this.downloadExcelReport(reportName, searchPayload, "isExcel")
        .then((webSocketConn) => { 
          webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            console.log('all responses', response)
            if (response?.statusCode == 200) {
              console.log('report url is here for', reportName, response?.body)
            let responseBodyData = response?.body
            // if (responseBodyData?.length > 0) {
            //   responseBodyData?.forEach((item) => {
                if (this.props?.isBusinessAffairs) {
                  if (reportName === 'rptSCMSExcelBatch') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_${this.state.selectedOption}`
                    );
                    this.downloadExcel('rptSCMSExcelBatchFiori', true)
                  }
                  if (reportName === 'rptSCMSExcelBatchFiori') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_FIORI_${this.state.selectedOption}`
                    );
                  }
                } else if (this.props?.isMainTitle) {
                  if (reportName === 'rptMainTitleExcelBatch') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_${this.state.selectedOption}`
                    );
                    this.downloadExcel('rptMainTitleExcelBatchFiori', true)
                  }
                  if (reportName === 'rptMainTitleExcelBatchFiori') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_FIORI_${this.state.selectedOption}`
                    );
                  }
                } else {
                  if (reportName === 'rptClearanceExcelBatch') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_${this.state.selectedOption}`
                    );
                    this.downloadExcel('rptClearanceExcelBatchFiori', true)
                  }
                  if (reportName === 'rptClearanceExcelBatchFiori') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_FIORI_${this.state.selectedOption}`
                    );
                  }
                }
              // });
            // }
            if(showNotification) {
              this.setState({ isExcelPosting: false , showExcelAndDocModal:false});
              this.handleHideExcelAndDocModal();
              this.notificationComponent(true, "success");
            }
          }
          };
        })
        // .catch((err) => {
        //   this.setState({ isExcelPosting: false });
        //   this.notificationComponent(true, "fail");
        // });
    };
    downloadCheckTransmittalLetter = () => {
      this.setState({ isCheckTransmittal: true });
      let reportName = "rptShowSeasonMusicSupervisorCheckTransmittalLetter";

      let searchPayload = {};
      let showSeasonMSCheckReqIdList = [];
      this.state.checkTransmittalList?.map((item) => {
        showSeasonMSCheckReqIdList.push(
          item?.show_season_music_supervisor_check_requests_id
        );
      });
      searchPayload = {
        show_season_id: this.props?.show_season_id,
        show_season_music_supervisor_check_requests_id:
          showSeasonMSCheckReqIdList.toString(),
      };
      searchPayload["isOpenExisting"] = this.state.isOpenExisting ? 1 : 0;
      searchPayload["divisionId"] = this.props?.division_id;
      this.generateCheckTransmit(reportName, searchPayload, "isDocx");
    };
    generateCheckTransmit = (reportName, searchPayload, reportType) => {
      this.downloadExcelReport(reportName, searchPayload, reportType)
        .then((webSocketConn) => {
          webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (response?.statusCode == 200) {
              this.downloadUrlPdf(response?.body?.url);
              this.notificationComponent(true, "success");
              this.setState({ isCheckTransmittal: false });
              this.selectedBatchList(this.state.selectedOption);
            } else {
              this.setState({ isCheckTransmittal: false });
              this.selectedBatchList(this.state.selectedOption);
            }
          };
        })
        .catch((err) => {
          this.setState({ isCheckTransmittal: false });
          this.notificationComponent(true, "fail");
        });
    };
    getBatchesList = (batch_option) => {
      this.setState({ isLoading: true });
      let url = this.props?.isBusinessAffairs
        ? `/showSeasonSCMSCRQBatches?show_season_id=${this.props?.show_season_id}&batch_option=${batch_option}`
        : this.props?.isMainTitle
        ? `/mainTitleCRQBatches?main_title_id=${this.props?.main_title_id}&batch_option=${batch_option}`
        : `/clearanceCRQBatches?show_season_episode_id=${this.props?.show_season_episode_id}&batch_option=${batch_option}`;
      clearTrackService
        .getData(
          Constants.ClearTrackServiceBaseUrl + url,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props?.division_id
        )
        .then((response) => {
          let filteredBatchList = [];
          response.data?.map((item) => {
            if (item?.batch_no) {
              filteredBatchList.push({
                value: item.batch_no,
                label: item.batch_no,
              });
            }
          });
          filteredBatchList.unshift({ value: "NEW", label: "NEW" });
          filteredBatchList.unshift({ value: "ALL", label: "ALL" });
          let dataList = [];
          response.data?.map((data) => {
            data?.batch_list?.map((item) => {
              dataList.push({ ...item, batch_no: data.batch_no });
            });
          });
          dataList?.map((batchItem) => {
            if (batchItem.batch_no === null) {
              return (batchItem.batch_no = "None");
            }
          });
          if(!this.props?.isBusinessAffairs) {
            this.setConfigOfTable(batch_option, dataList)
          }
          this.setState({
            BatchOption: filteredBatchList,
            batchesData: dataList,
            isLoading: false,
          });
        }),
        ((error) => {
          this.setState({ isLoading: false });
          console.log("error");
        });
    };
    selectedBatchList = (selectedBatch) => {
      if(!this.props?.isBusinessAffairs) {
        this.setConfigOfTable(selectedBatch, [])
      }
      this.setState({
        batchesData: [],
        isLoading: true,
        isFieldChanged: false,
        isSelectedRemoveBatch: false,
        isCheckTransmittalButton: false,
        checkTransmittalList: [],
      });
      let url = this.props?.isBusinessAffairs
        ? `/showSeasonSCMSCRQBatches?show_season_id=${this.props?.show_season_id}&batch_option=${selectedBatch}`
        : this.props?.isMainTitle
        ? `/mainTitleCRQBatches?main_title_id=${this.props?.main_title_id}&batch_option=${selectedBatch}`
        : `/clearanceCRQBatches?show_season_episode_id=${this.props?.show_season_episode_id}&batch_option=${selectedBatch}`;
      clearTrackService
        .getData(
          Constants.ClearTrackServiceBaseUrl + url,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props?.division_id
        )
        .then((response) => {
          let batchDataList = [];
          let updateOrDeleteBatchList = [];
          response.data?.map((data) => {
            data?.batch_list?.map((item) => {
              if (!(selectedBatch === "ALL" || selectedBatch === "NEW")) {
                updateOrDeleteBatchList.push({
                  ...item,
                  batch_no: data.batch_no,
                });
              }
              batchDataList.push({ ...item, batch_no: data.batch_no });
            });
          });
          batchDataList?.map((batchItem) => {
            if (batchItem.batch_no === null) {
              return (batchItem.batch_no = "None");
            }
          });
          if (this.state.selectedField === "is_create") {
            let filteredBatchList = [...this.state.BatchOption];
            response.data?.map((item) => {
              if (item?.batch_no) {
                filteredBatchList.push({
                  value: item.batch_no,
                  label: item.batch_no,
                });
              }
            });
            this.setState({ BatchOption: filteredBatchList });
          }
          if(!this.props?.isBusinessAffairs) {
            this.setConfigOfTable(selectedBatch, batchDataList)
          }
          this.setState({
            batchesData: batchDataList,
            selectedBatches: updateOrDeleteBatchList,
            batchesRecordData: {
              ...this.state.batchesRecordData,
              batchesNotes: response.data[0]?.batch_notes,
              releaseDate: response.data[0]?.release_date ? getFormattedDate(
                response.data[0]?.release_date,
                true
              ) : moment(new Date())?.format("YYYY-MM-DD"),
              batchNo: response.data[0]?.batch_no,
              fileName: response.data[0]?.file_name,
              previewFileName: response.data[0]?.preview_file_name,
            },
            isLoading: false,
          });
        })

        .catch((error) => {
          this.setState({ isLoading: false });
          console.log("error");
        });
    };
    arrayCheckUncheck = (id, dataItem) => {
      let updateBatchList = this.state.batchesData?.map((item) => {
        let newItem = { ...item };
        if (this.props?.isBusinessAffairs) {
          if (
            item?.show_season_music_supervisor_check_requests_id ===
            dataItem?.show_season_music_supervisor_check_requests_id
          ) {
            newItem.check = id;
          }
        } else if (this.props?.isMainTitle) {
          if (
            item?.main_title_check_request_id ===
            dataItem?.main_title_check_request_id
          ) {
            newItem.check = id;
          }
        } else if (
          item?.clearance_check_request_id ===
          dataItem?.clearance_check_request_id
        ) {
          newItem.check = id;
        }
        return newItem;
      });
      let filteredBatch = updateBatchList?.filter(
        (item) => item.check === true
      );
      if (filteredBatch?.length > 0) {
        filteredBatch?.find((item) => {
          if (item?.check === true) {
            return this.setState({ isSelectedRemoveBatch: true });
          } else {
            return this.setState({ isSelectedRemoveBatch: false });
          }
        });
      } else {
        return this.setState({
          isSelectedRemoveBatch: false,
          isCheckTransmittalButton: false,
        });
      }
      let checkTransmittalList = [];
      if (!this.props?.isBusinessAffairs) {
        filteredBatch?.find((selectedItem) => {
          if (
            selectedItem?.check === true &&
            selectedItem?.generated_date === null
          ) {
            return this.setState({ isCreateBatch: true });
          } else {
            return this.setState({ isCreateBatch: false });
          }
        });
      } else {
        filteredBatch?.find((item) => {
          if (item?.check === true) {
            checkTransmittalList.push(item);
            return this.setState({ isCheckTransmittalButton: true });
          } else {
            return this.setState({ isCheckTransmittalButton: false });
          }
        });
      }
      let paidBatchList = filteredBatch?.filter(
        (item2) => item2?.check === true && item2?.paid_flag === 1
      );
      if(!this.props?.isBusinessAffairs) {
        this.setConfigOfTable(this.state.selectedOption, updateBatchList)
      }
      this.setState({
        batchesData: updateBatchList,
        selectedBatches: filteredBatch,
        paidBatchList: paidBatchList,
        batchesRecordData: {
          ...this.state.batchesRecordData,
          composition: dataItem?.composition,
          payee_company: dataItem?.payee_company,
          check: dataItem?.check,
          paid_flag: dataItem?.paid_flag,
          generated_date: dataItem?.generated_date,
        },
        checkTransmittalList: checkTransmittalList,
      });
    };
    handleChange = (field, value) => {
      this.setState((prevState) => ({
        batchesRecordData: {
          ...prevState.batchesRecordData,
          [field]: value,
        },
        isFieldChanged: true,
      }));
    };
    handleCreateBatch = (field) => {
      if (this.state.selectedBatches?.length > 0) {
        this.setState({
          isSubmit: true,
          isDelete:
            field === "is_update" || field === "is_remove" ? false : true,
          isUpdate:
            field === "is_delete" || field === "is_remove" ? false : true,
          isRemove:
            field === "is_update" || field === "is_delete" ? false : true,
        });
        let selectedBatchData = this.state.selectedBatches?.map((item) => {
          if (this.props?.isBusinessAffairs) {
            return {
              is_delete: field === "is_remove" ? 1 : 0,
              show_season_music_supervisor_check_request_batches_id:
                item?.show_season_music_supervisor_check_request_batches_id,
              show_season_id: item?.show_season_id,
              show_season_music_supervisor_check_requests_id:
                item?.show_season_music_supervisor_check_requests_id,
            };
          } else if (this.props?.isMainTitle) {
            return {
              is_delete: field === "is_remove" ? 1 : 0,
              main_title_check_request_batches_id:
                item?.main_title_check_request_batches_id,
              main_title_id: item?.main_title_id,
              main_title_check_request_id: item?.main_title_check_request_id,
            };
          } else {
            return {
              is_delete: field === "is_remove" ? 1 : 0,
              clearance_check_request_batches_id:
                item?.clearance_check_request_batches_id,
              clearance_id: item?.clearance_id,
              clearance_check_request_id: item?.clearance_check_request_id,
            };
          }
        });
        let batchPayload = {
          batch_no:
            field === "is_delete" ||
            field === "is_update" ||
            field === "is_remove"
              ? this.state.selectedOption
              : null,
          delete_batch: field === "is_delete" ? 1 : 0,
          update_batch: field === "is_update" ? 1 : 0,
          release_date: getFormattedDate(
            this.state.batchesRecordData?.releaseDate,
            true
          ),
          batch_notes: this.state.batchesRecordData?.batchesNotes,
          batch_list: selectedBatchData?.length > 0 ? selectedBatchData : [],
        };
        let url = this.props?.isBusinessAffairs
          ? `/showSeasonSCMSCRQBatches`
          : this.props?.isMainTitle
          ? `/mainTitleCRQBatches`
          : `/clearanceCRQBatches`;
        clearTrackService
          .postDataParams(
            Constants.ClearTrackServiceBaseUrl + url,
            batchPayload,
            this.props?.userContext?.active_tenant?.tenant_id,
            this.props?.division_id
          )
          .then((response) => {
            if (response.data.error) {
              this.notificationComponent(true, "fail");
              this.setState({
                isSubmit: false,
                isDelete: false,
                isUpdate: false,
                isRemove: false,
                isFieldChanged: false,
                isSelectedRemoveBatch: false,
                emailTextMsg: null,
                isCheckTransmittalButton: false,
              });
            } else {
              this.notificationComponent(true, "success");
              if (field === "is_create") {
                this.setState({ selectedOption: response?.data[0]?.batch_no });
                this.downLoadExcelForBox();
                this.setState(
                  {
                    //isExcelPosting: false,
                    isDelete: false,
                    isUpdate: false,
                    isRemove: false,
                    isOpen: false,
                    isFieldChanged: false,
                    isSelectedRemoveBatch: false,
                    emailTextMsg: null,
                    isCheckTransmittalButton: false,
                    selectedField: field === "is_create" ? "is_create" : null,
                  },
                  () => {
                    this.setState({
                      isSubmit: false,
                      selectedOption: response?.data[0]?.batch_no,
                    });
                    this.selectedBatchList(response?.data[0]?.batch_no);
                  }
                );
              } else if (field === "is_delete" || field === "is_remove") {
                this.setState({
                  selectedOption: "ALL",
                  isSubmit: false,
                  isDelete: false,
                  isUpdate: false,
                  isRemove: false,
                  isOpen: false,
                  isFieldChanged: false,
                  isSelectedRemoveBatch: false,
                  emailTextMsg: null,
                  isCheckTransmittalButton: false,
                });
                this.getBatchesList("ALL");
              } else {
                this.setState({
                  selectedOption: response?.data[0]?.batch_no,
                  isSubmit: false,
                  isDelete: false,
                  isUpdate: false,
                  isRemove: false,
                  isOpen: false,
                  isFieldChanged: false,
                  isSelectedRemoveBatch: false,
                  emailTextMsg: null,
                  isCheckTransmittalButton: false,
                });
                this.selectedBatchList(response?.data[0]?.batch_no);
              }
            }
          }),((error) => {
            this.setState({
              isSubmit: false,
              isDelete: false,
              isUpdate: false,
              isRemove: false,
              isFieldChanged: false,
              isSelectedRemoveBatch: false,
              isCheckTransmittalButton: false,
            });
            this.notificationComponent(true, "fail");
          });
      }
    };

    setConfigOfTable = (batchOption, data) => {
    let tableConfig = batchOption === 'ALL' ?  this.props?.isMainTitle ? JSON.parse(JSON.stringify(AllMTBatchesHeading)) : 
                      JSON.parse(JSON.stringify(AllBatchHeading)) : this.props?.isMainTitle ? JSON.parse(JSON.stringify(NewMTBatchesHeading)) :
                      JSON.parse(JSON.stringify(NewBatchesHeading))
    let extraColumns = this.props?.isMainTitle ? dynamicColumns : dynamicColumnsEp
    for(let i =0 ; i< extraColumns?.length ; i++) {
       if(data?.some(item => item[extraColumns[i]?.value])) {
        tableConfig?.headings?.splice(tableConfig?.headings?.length - 1 , 0 , {
          headingLabel: extraColumns[i]?.heading,
          icon: "",
          width: "7%",
        })
        tableConfig?.dataNodes?.splice(tableConfig?.dataNodes?.length - 1 , 0 ,extraColumns[i]?.value)
       }
    }
    if(batchOption === 'ALL') {
      if(this.props?.isMainTitle) {
        this.setState({AllMTBatchesHeading: tableConfig})      
      } else {
        this.setState({AllBatchHeading: tableConfig})      
      }
    } else {
      if(this.props?.isMainTitle) {
        this.setState({NewMTBatchesHeading: tableConfig})          
      } else {
        this.setState({NewBatchesHeading: tableConfig})      
      }
    }
    }

    allBatches = () => {
      return (
        <div>
          <MDBRow id={"Batches-table"}>
            <TableComponent
              list={this.state.batchesData || []}
              config={
                this.props?.isBusinessAffairs
                  ? this.state.selectedOption === "ALL"
                    ? businessAffairsHeading
                    : businessAffairsNumberHeading
                  : this.props?.isMainTitle
                  ? this.state.selectedOption === "ALL"
                    ? this.state.AllMTBatchesHeading
                    : this.state.selectedOption === "NEW"
                    ? this.state.NewMTBatchesHeading
                    : this.state.NewMTBatchesHeading
                  : this.state.selectedOption === "ALL"
                  ? this.state.AllBatchHeading
                  : this.state.selectedOption === "NEW"
                  ? this.state.NewBatchesHeading
                  : this.state.NewBatchesHeading
              }
              isLoading={this.state.isLoading}
              arrayCheckUncheck={this.arrayCheckUncheck}
              stickyHeader={true}
            />
          </MDBRow>
          {this.state.selectedOption !== "ALL" ? (
            <MDBRow>
              <MDBCol md={2}>
                <DateField
                  label={"Release Date"}
                  id="release Date"
                  placeholder="- Release Date -"
                  value={this.state.batchesRecordData?.releaseDate || ""}
                  onChange={(e) =>
                    this.handleChange(
                      "releaseDate",
                      e.target.value?.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
              <MDBCol md={10}>
                <BasicTextArea
                  id={"textarea-performer"}
                  rows={1}
                  label={"Batches Notes"}
                  value={this.state.batchesRecordData?.batchesNotes}
                  onChange={(e) =>
                    this.handleChange("batchesNotes", e.target.value)
                  }
                />
              </MDBCol>
            </MDBRow>
          ) : null}
          <MDBRow>
            <MDBCol md={11}></MDBCol>
            <MDBCol md={1} className="mt-2">
              <BasicButton
                type="inline"
                variant="outlined"
                text={"Close"}
                onClick={this.props?.handleClose}
              />
            </MDBCol>
          </MDBRow>
        </div>
      );
    };
    onChange = (label, value) => {
      this.setState({
        selectedOption: value,
        selectedField: null,
      });
      this.selectedBatchList(value);
    };
    handleSelectAllBatches = () => {
      let batchesList = [...this.state.batchesData];
      let batchListChecked = batchesList?.map((item) => {
        return (item = { ...item, check: true });
      });
      batchListChecked?.find((item) => {
        if (!this.props?.isBusinessAffairs && item?.generated_date === null) {
          return this.setState({ isCreateBatch: true });
        }
      });

      let paidList = batchListChecked?.filter(
        (pd) => pd?.check === true && pd?.paid_flag === 1
      );
      let checkTransmittalList = [];
      if (batchListChecked?.length > 0) {
        batchListChecked?.find((data) => {
          if (data?.check === true) {
            checkTransmittalList.push(data);
            return this.setState({
              isSelectedRemoveBatch: true,
              isCheckTransmittalButton: true,
            });
          } else {
            return this.setState({
              isSelectedRemoveBatch: false,
              isCheckTransmittalButton: false,
            });
          }
        });
      } else {
        this.setState({
          isSelectedRemoveBatch: false,
          isCheckTransmittalButton: false,
        });
      }
      if(!this.props?.isBusinessAffairs) {
        this.setConfigOfTable(this.state.selectedOption,batchListChecked)
      }
      this.setState({
        batchesData: batchListChecked,
        selectedBatches: batchListChecked,
        paidBatchList: paidList,
        checkTransmittalList: checkTransmittalList,
      });
    };
    handleDeSelectAllBatches = () => {
      let batchesList = [...this.state.batchesData];
      let batchListUnChecked = batchesList?.map((item) => {
        if (!this.props?.isBusinessAffairs && item?.generated_date === null) {
          this.setState({ isCreateBatch: true });
        } else {
          this.setState({ isCreateBatch: false });
        }
        return (item = { ...item, check: false });
      });
      if (batchListUnChecked?.length > 0) {
        batchListUnChecked?.find((data) => {
          if (data?.check === true) {
            return this.setState({ isSelectedRemoveBatch: true });
          } else {
            return this.setState({
              isSelectedRemoveBatch: false,
              isCheckTransmittalButton: false,
            });
          }
        });
      } else {
        this.setState({
          isSelectedRemoveBatch: false,
          isCheckTransmittalButton: false,
        });
      }
      if(!this.props?.isBusinessAffairs) {
        this.setConfigOfTable(this.state.selectedOption, batchListUnChecked)
      }
      this.setState({
        batchesData: batchListUnChecked,
        selectedBatches: [],
        paidBatchList: [],
        checkTransmittalList: [],
      });
    };
    closePopup = () => {
      this.setState({ isOpenPaidModal: false });
    };
    closeEmailPopup = () => {
      this.setState({ isOpenEmailModal: false });
    };
    notificationComponent = (value, mode = "fail") => {
      switch (mode) {
        case "success":
          return this.setState({ showSuccess: value });
        case "fail":
          return this.setState({ showFail: value });
      }
    };

    handleResetNotify = () => {
      this.setState({ showFail: false, showSuccess: false });
      if (this.state.saveFlag) {
        this.setState({ saveFlag: false });
      }
    };
    handleEmailBatch = () => {
      this.setState({ isEmailBatch: true, callFromExcelBatch: true });
      let searchPayload = {};
      searchPayload = {
        opening_dialog_from: this.props?.isBusinessAffairs
          ? "SC_MS"
          : this.props?.isMainTitle
          ? "MT"
          : "CL",
        batch_no: this.state.selectedOption,
      };
      let encryptTedStr = btoa(JSON.stringify(searchPayload));

      //preview batch API call
      this.handleGeneratePreviewBatch(false, "isEmail");
      clearTrackService
        .getData(
          Constants.ClearTrackServiceBaseUrl +
            `/emailBatches?searchJson=${encryptTedStr}`,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props?.division_id,
          true
        )
        .then((res) => {
          console.log("response email", res);
          this.setState({
            isEmailBatch: false,
            emailTextMsg: res?.data?.msg,
            isOpenEmailModal: true,
            callFromExcelBatch: false,
          });
        })
        .catch((err) => {
          this.setState({
            isEmailBatch: false,
            emailTextMsg: null,
            isOpenEmailModal: false,
            callFromExcelBatch: false,
          });
          this.notificationComponent(true, "fail");
          console.log(err);
        });
    };
    render() {
      let messageNotification = this.state.showSuccess
        ? "The changes are saved successfully!"
        : this.state.showFail
        ? "Saving changes failed!"
        : "";
      let severity = this.state.showFail
        ? "error"
        : this.state.showSuccess
        ? "success"
        : "";
      return (
        <MDBContainer fluid className="Batches-container">
          <NotificationComponent
            open={this.state.showSuccess || this.state.showFail}
            message={messageNotification}
            severity={severity}
            handleResetNotify={this.handleResetNotify.bind(this)}
          />
          <MDBRow className="mt-2">
            <MDBCol md={2}>
              <SelectField
                id={"batch-type"}
                size="small"
                //label={"Status"}
                value={this.state.selectedOption}
                options={this.state.BatchOption || []}
                onChange={(e) =>
                  this.onChange("selectedOption", e.target.value)
                }
              />
            </MDBCol>
            {this.state.selectedOption === "ALL" ? (
              <MDBCol md={10}>
                <span className="message">
                  Highlighted rows indicate a Check Request has not yet been
                  saved to the network and cannot be added to a batch
                </span>
              </MDBCol>
            ) : null}

            {this.state.selectedOption === "NEW" &&
            this.state.clearanceBatchesPermissions?.edit == 1 ? (
              <MDBCol md={10}>
                <MDBRow>
                  <MDBCol md={2}>
                    <div className="create-batch">
                      <BasicButton
                        variant="contained"
                        type="inline"
                        color="primary"
                        disabled={this.state.isCreateBatch ? true : false}
                        onClick={() => {
                          let paidSongDetail = "";
                          if (this.state.paidBatchList?.length > 0) {
                            this.state.selectedBatches?.find((item) => {
                              if (
                                this.state.selectedOption === "NEW" &&
                                item?.check === true &&
                                item?.paid_flag === 1 &&
                                item?.generated_date !== null
                              ) {
                                paidSongDetail =
                                  paidSongDetail +
                                  item?.composition +
                                  " Payee " +
                                  item?.payee_company +
                                  ",";
                                this.setState({
                                  isOpenPaidModal: true,
                                  paidSongDetailText: paidSongDetail,
                                });
                              }
                            });
                          } else {
                            this.handleCreateBatch("is_create");
                          }
                        }}
                        text={
                          this.state.isSubmit ? (
                            <CircularProgress color="inherit" size={18} />
                          ) : (
                            "Create Batch"
                          )
                        }
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md={8}>
                    <span className="message">
                        Highlighted rows indicate a Check Request has not yet been
                        saved to the network and cannot be added to a batch
                    </span>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            ) : null}

            {!(
              this.state.selectedOption === "NEW" ||
              this.state.selectedOption === "ALL"
            ) ? (
              <MDBCol md={8}>
                
                  <MDBRow>
                    {this.state.clearanceBatchesPermissions?.edit == 1 && (
                      <MDBCol md={2}>
                        <div className="self-update">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            disabled={!this.state.isFieldChanged ? true : false}
                            text={
                              this.state.isUpdate ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Update Batch"
                              )
                            }
                            onClick={() => {
                              this.setState({ selectedField: null });
                              this.handleCreateBatch("is_update");
                            }}
                          />
                        </div>
                      </MDBCol>
                    )}
                    {this.state.clearanceBatchesPermissions?.view == 1 && (
                      <MDBCol md={3}>
                        <div className="self-preview">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            text={
                              this.state.isPreview ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Preview Batch"
                              )
                            }
                            onClick={() =>
                              this.handleGeneratePreviewBatch(true, "isPdf")
                            }
                          />
                        </div>
                      </MDBCol>
                    )}
                    {this.state.clearanceBatchesPermissions?.edit == 1 && (
                      <MDBCol md={2}>
                        <div className="self-email">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            text={
                              this.state.isEmailBatch ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Email Batch"
                              )
                            }
                            onClick={this.handleEmailBatch}
                          />
                        </div>
                      </MDBCol>
                    )}
                    {this.state.clearanceBatchesPermissions?.edit == 1 && (
                      <MDBCol md={2}>
                        <div className="self-excel">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            text={
                              //this.state.selectedField !== "is_create" &&
                              this.state.isExcelPosting ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Excel Batch"
                              )
                            }
                            onClick={this.handleShowExcelAndDocModal}
                            // onClick={this.downloadExcel}
                            postInitiated={this.state.isExcelPosting}
                          />
                        </div>
                      </MDBCol>
                    )}

                    {this.props?.isBusinessAffairs
                      ? this.state.clearanceBatchesPermissions?.edit == 1 && (
                          <MDBCol md={3}>
                            <div className="self-transmit">
                              <BasicButton
                                variant="contained"
                                type="inline"
                                color="primary"
                                disabled={
                                  this.state.isCheckTransmittalButton
                                    ? false
                                    : true
                                }
                                text={
                                  this.state.isCheckTransmittal ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={18}
                                    />
                                  ) : (
                                    "Check Transmittal"
                                  )
                                }
                                onClick={this.handleShowDocModal}
                                postInitiated={this.state.isCheckTransmittal}
                              />
                            </div>
                          </MDBCol>
                        )
                      : null}
                  </MDBRow>
              </MDBCol>
            ) : null}

            {!(
              this.state.selectedOption === "NEW" ||
              this.state.selectedOption === "ALL"
            ) ? (
              <MDBCol md={2} className="alignEnd2">
                <div className="dlt-btnn">
                  {this.state.clearanceBatchesPermissions?.edit == 1 && (
                    <BasicButton
                      variant="contained"
                      type="inline"
                      color="primary"
                      text={"Delete Batch"}
                      onClick={() => this.setState({ isOpen: true })}
                    />
                  )}
                </div>
              </MDBCol>
            ) : null}
          </MDBRow>
          <MDBRow className="mt-2">
            <MDBCol md={3}>
              {this.state.selectedOption !== "ALL" ? (
                <div className="main-Bactch">
                  <div>
                    <span
                      className="selectAll"
                      onClick={this.handleSelectAllBatches}
                    >
                      Select All
                    </span>
                  </div>
                  <div>
                    <span
                      className="deSelect"
                      onClick={this.handleDeSelectAllBatches}
                    >
                      DeSelect All
                    </span>
                  </div>
                </div>
              ) : null}
            </MDBCol>
            <MDBCol md={7}></MDBCol>
            <MDBCol md={2} xl={2}>
              {!(
                this.state.selectedOption === "NEW" ||
                this.state.selectedOption === "ALL"
              )
                ? this.state.clearanceBatchesPermissions?.edit === 1 && (
                    <div
                      className="sub-Batch"
                      disabled={
                        !this.state.isSelectedRemoveBatch ? true : false
                      }
                    >
                      <span
                        className="del-batch"
                        onClick={() => this.handleCreateBatch("is_remove")}
                      >
                        {this.state.isRemove ? (
                          <CircularProgress color="inherit" size={18} />
                        ) : (
                          "Delete Sel. From Batch"
                        )}
                      </span>
                    </div>
                  )
                : null}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <div>{this.allBatches()}</div>
          </MDBRow>
          <MessageModal
            open={this.state.isOpen}
            title={"Confirm"}
            message={`Delete Batch ${this.state.batchesRecordData?.batchNo}?`}
            primaryButtonText={
              this.state.isDelete ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                "confirm"
              )
            }
            secondaryButtonText={"Cancel"}
            onConfirm={() => this.handleCreateBatch("is_delete")}
            handleClose={(e) => this.setState({ isOpen: false })}
          />
          {this.state.isOpenPaidModal && (
            <MessageModal
              open={this.state.isOpenPaidModal}
              title={"Message"}
              message={`Check Request for song ${this.state.paidSongDetailText}. cannot be added to the batch because it has already been Paid.`}
              hideCancel={true}
              primaryButtonText={"OK"}
              onConfirm={() => this.closePopup()}
              handleClose={(e) => this.setState({ isOpenPaidModal: false })}
            />
          )}
          {this.state.isOpenEmailModal && (
            <MessageModal
              open={this.state.isOpenEmailModal}
              title={"Message"}
              message={this.state.emailTextMsg}
              hideCancel={true}
              primaryButtonText={"OK"}
              onConfirm={() => this.closeEmailPopup()}
              handleClose={(e) => this.setState({ isOpenEmailModal: false })}
            />
          )}
          {this.state.showExcelAndDocModal && (
            <>
              <MainViewModalComponent
                open={this.state.showExcelAndDocModal}
                handleClose={this.handleHideExcelAndDocModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={this.downLoadExcelForBox.bind(this)}
                isOpenExisting={this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isExcelPosting}
              />
            </>
          )}
          {this.state.showDocModal && (
            <>
              <MainViewModalComponent
                open={this.state.showDocModal}
                handleClose={this.handleHideDocModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={this.downloadCheckTransmittalLetter}
                isOpenExisting={this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isCheckTransmittal}
              />
            </>
          )}
        </MDBContainer>
      );
    }
  }
);
