import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact"
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import "./ScheduleBudgetVsEFC.scss";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from '../../../contexts/UserContext';
import CircularProgress from "@material-ui/core/CircularProgress";
class ScheduledBudgetVsEFC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seasonOptions: [],
            isPosting: false
        };
    }

    componentDidMount() {
        let formattedList = this.props?.seasons.map(item => ({ value: item.season_id, label: item.season_date, is_current: item.is_current }))
        this.setState({ seasonOptions: formattedList, selectedSeason: formattedList.filter(item => item.is_current === 1)[0]?.value })
    }

    onChange = (value) => {
        this.setState({ selectedSeason: value })
    }

    downloadExcel = () => {
        this.setState({ isPosting: true })
        let { selectedSeason } = this.state;
        let reportName = "rptbudgetvsefcexl";
        let searchPayload = {
            "season_id": selectedSeason,
            "all_shows": 1,
            "all_recipients": 1,
            "scheduleEmail": 1,
            "budget_vs_efc_scheduling_id": this.props?.budget_vs_efc_scheduling_id
        }
        this.generateEmails(reportName, searchPayload, "isExcel").then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if (response?.statusCode == 200) {
                    if (response?.body === "Emails have been saved to your Drafts folder.") {
                        this.setState({ isPosting: false })
                        this.props?.handleRemoveIcon()
                        this.props?.notificationComponent(true, 'emailSaved')
                        // this.downloadUrl(response?.body);
                        // this.props?.notificationComponent(true, 'success')
                    } else if (response?.body === "No Data Found") {
                        this.props?.notificationComponent(true, 'noData')
                    }
                    else {
                        this.setState({ isPosting: false })
                        // this.props?.notificationComponent(true, 'fail')
                    }
                }
            }
        })
        // this.props?.handleNavigation()
    }

    generateEmails = (reportName, searchPayload, fileName) => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let load = {
            'payload': 'exportExcel',
            'reportName': reportName,
            'fileName': "BudgetVSEFC",
            'searchJson': encryptTedStr,
            'showHtml': 0,
            'encryptionType': 1,
            'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
            'divisionId': 1
        }
        return clearTrackService.getDataWS(load)
    }

    downloadUrl = (url) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                // link.href = window.URL.createObjectURL(blob);
                link.href = url;
                // link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }
    render() {
        return (
            <div>
                <MDBContainer className="ScheduleBudgetContainer">
                    <MDBRow className="mt-2">
                        <MDBRow>
                            <BasicLabel text="As the authorized user, a scheduled Budget vs EFC report (Warner Bros) has been initiated. Please complete immediately." />
                        </MDBRow>
                        <MDBRow className="scheduledControls">
                            <BasicLabel text="Season" />
                            <SelectField
                                placeHolderText={"-Season-"}
                                value={this.state.selectedSeason}
                                options={this.state.seasonOptions}
                                onChange={(e) => this.onChange(e.target.value)}
                            />
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={5}></MDBCol>
                            <MDBCol md={5}>
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate Emails"}
                                    onClick={this.downloadExcel}
                                // onClick={this.props?.handleNavigation}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    onClick={this.props?.handleClose}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBRow>
                </MDBContainer>
            </div >
        )
    }
}
export default withUserContext(ScheduledBudgetVsEFC);
