import React from 'react';
class SearchResultsLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: props.searchResult ? props.searchResult : null
        }
    }
    componentDidMount() {
    }

    render() {
        return (
            <>
                <div class='row'>
                    <div class='col-12'>
                        {this.props?.searchResult?.name}
                    </div>
                    <div class='col-10 FR W16'>
                        {this.props?.searchResult?.sub_group} 
                    </div>
                    <div class='col-2 LR'>
                        {this.props?.searchResult?.data_type}
                    </div>
                </div>
                <hr></hr>
            </>
        )
        // switch (this.state.searchResult.result_type) {
        //     case 'Show':
        //         return (<>
        //             <div class='row'>
        //                 <div class='col-12'>
        //                     {this.state.searchResult.show_name} - {this.state.searchResult.gl}
        //                 </div>
        //                 <div class='col-10 FR W16'>
        //                     {this.state.searchResult.show_start_date} - {this.state.searchResult.show_end_Date}
        //                 </div>
        //                 <div class='col-2 LR'>
        //                     {this.state.searchResult.result_type}
        //                 </div>
        //             </div>
        //             <hr></hr>

        //         </>
        //         );
        //         break;
        //     case 'Episode':
        //         return (<>
        //             <div class='row'>
        //                 <div class='col-12'>
        //                     {this.state.searchResult.episode_name} - {this.state.searchResult.episode_number}
        //                 </div>
        //                 <div class='col-10 FR W16'>
        //                     {this.state.searchResult.show_name} -&nbsp;Season - &nbsp; No {this.state.searchResult.episode_number}
        //                 </div>
        //                 <div class='col-2 LR'>
        //                     {this.state.searchResult.result_type}
        //                 </div>
        //             </div>
        //             <hr></hr>
        //         </>
        //         );
        //         break;
        //     case 'Role':
        //         return (<>
        //             <div class='row'>
        //                 <div class='col-12'>
        //                     {this.state.searchResult.role_name}
        //                 </div>
        //                 <div class='col-10 FR W16'>
        //                     {this.state.searchResult.show_name} -&nbsp;Season - &nbsp; No {this.state.searchResult.episode_number}
        //                 </div>
        //                 <div class='col-2 LR'>
        //                     {this.state.searchResult.result_type}
        //                 </div>
        //             </div>
        //             <hr></hr>

        //         </>
        //         );
        //         break;
        //     case 'Talent':
        //         return (<>
        //             <div class='row'>
        //                 <div class='col-12'>
        //                     {this.state.searchResult.talent_name}
        //                 </div>
        //                 <div class='col-10 FR W16'>
        //                     {this.state.searchResult.episode_name} -&nbsp;Season - &nbsp; No {this.state.searchResult.episode_number}
        //                 </div>
        //                 <div class='col-2 LR'>
        //                     {this.state.searchResult.result_type}
        //                 </div>
        //             </div>
        //             <hr></hr>

        //         </>
        //         );
        //         break;
        //     case 'recordCount':
        //         return (<>
        //             <div class='row'>
        //                 <div class='col-12'>
        //                     {this.state.searchResult.totalRecords + ' Search Results'}
        //                 </div>
        //             </div>
        //         </>
        //         );
        //         break;

        //     default:
        //         return (<>
        //             <div class='row'>
        //                 <div class='col-12'>
        //                     {this.state.searchResult.talent_name}
        //                 </div>
        //                 <div class='col-10 FR W16'>
        //                     {this.state.searchResult.show_name} -&nbsp;Season - &nbsp; No {this.state.searchResult.episode_number}
        //                 </div>
        //                 <div class='col-2 LR'>
        //                     {this.state.searchResult.result_type}
        //                 </div>
        //             </div>
        //             <hr></hr>
        //         </>
        //         );
        //         break;
        //}
    }
}
export default SearchResultsLayout;