import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  initialComposers,
  costSplitConfig,
  allContactList,
  installationcostvalue,
  optionCostAllocation,
} from "./config";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import TableComponent from "../../../components/SharedComponents/Table";

import "./CostSplitsDefault.scss";

class CostSplitsDefault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      composerDetails: initialComposers,
      composerOptions: [],
      composerName: "Composer Name",
      affiliationOptions: [],
      pctOptions: [],

      renderList: allContactList,
      config: costSplitConfig,
      iconfig: hvCostAllocationConfig,
      renderListi: "",
      pconfig: optionCostAllocationCongif,
    };
  }
  ResettoDefault = () => {
    console.log("submit", this.state.composerDetails);
  };
  handleSubmit = () => {
    console.log("submit", this.state.composerDetails);
  };
  handleComposerList = (fieldArray, field, value, index) => {
    let composerDetails = { ...this.state.composerDetails };
    switch (fieldArray) {
      case "composers":
        let composerList = composerDetails[fieldArray][index];
        let composer_item = {
          ...composerList,
          [field]: value,
        };
        composerDetails[fieldArray][index] = composer_item;
        break;
    }
    this.setState({
      composerDetails: composerDetails,
    });
  };

  handleInlineEdits = (id, dataItem) => {
    let allDetails = [...this.state.renderList];
    this.setState({
      renderList: allDetails.map((item) => {
        let newItem = {...item}
        if (item[this.state.config.primaryKey] == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };
  saveInlineEdits = (data, index, id, dataItem) => {
    let allDetails = [...this.state.renderList];
    this.setState({
      renderList: allDetails.map((item) => {
        if (item[this.state.config.primaryKey] == id) item["editing"] = false;
        return item;
      }),
    });
  };
  render() {
    return (
      <div>
        <MDBContainer className="TalentRepresentationDetails RepContainer">
          <MDBRow>
            <MDBCol md={3}>
              <BasicLabel className="repText" text={"Term"} />
              <SelectField
                id="Type"
                value="0"
                options={[{ label: "Perp", value: "0" }]}
                onChange={(e) =>
                  this.props?.onChangeHandler("type", e.target.value)
                }
              />
            </MDBCol>
            <MDBCol md={2}>
              <BasicLabel className="repText" text={"Include HV"} />

              <SelectField
                id="Type"
                value="0"
                options={[{ label: "No", value: "0" }]}
                onChange={(e) =>
                  this.props?.onChangeHandler("type", e.target.value)
                }
              />
            </MDBCol>
            <MDBCol md={5} xl={4}></MDBCol>
            <MDBCol md={2} xl={3}>
              <br />

              <BasicButton
                text={"Reset to Default"}
                onClick={this.ResettoDefault}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md={12}>
              <BasicCheckbox
                id="seasonComplete"
                label="Mark Show Season as Complete"
                checked={false}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow id="last-one">
            <TableComponent
              list={this.state.renderList || []}
              className={"agentList"}
              config={this.state.config}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              isLoading={this.props.isLoading || false}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol id="splitmain1">
              <SelectField
                placeHolderText={"- Select -"}
                value={this.state.selectedType}
                options={this.state.lookupType}
                onChange={this.GenerateCleranceDetails}
              />
            </MDBCol>
            <MDBCol></MDBCol>
            <MDBCol id="splitmain2">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
                width={"10%"}
              />
            </MDBCol>
            <MDBCol id="splitmain3">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
                width={"10%"}
              />
            </MDBCol>
            <MDBCol id="splitmain4">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol id="splitmain5">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol></MDBCol>
            <MDBCol id="plus">
              <MDBIcon icon="plus-circle" className="circle" />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <br />
              <p>
                <b>Home Video Cost Allocation</b>
              </p>
              <br />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <TableComponent
              className={"agentList"}
              config={this.state.iconfig}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              isLoading={this.props.isLoading || false}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol id="splitAlloc" md={3}>
              <SelectField
                placeHolderText={"- Select -"}
                value={this.state.selectedType}
                options={this.state.lookupType}
                onChange={this.GenerateCleranceDetails}
              />
            </MDBCol>
            <MDBCol md={1}></MDBCol>
            <MDBCol id="splitAlloc" md={3}>
              <SelectField
                placeHolderText={"- Select -"}
                value={this.state.selectedType}
                options={this.state.lookupType}
                onChange={this.GenerateCleranceDetails}
              />
            </MDBCol>
            <MDBCol md={1}></MDBCol>
            <MDBCol id="splitAlloc1" md={1}>
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol md={2}></MDBCol>
            <MDBCol id="plus">
              <MDBIcon icon="plus-circle" className="circle" />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol>
              <br />
              <p>
                <b>Other Option Cost Allocation</b>
              </p>
              <br />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <TableComponent
              className={"agentList"}
              config={this.state.pconfig}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              isLoading={this.props.isLoading || false}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol id="splitmain" md={3}>
              <SelectField
                placeHolderText={"- Select -"}
                value={this.state.selectedType}
                options={this.state.lookupType}
                onChange={this.GenerateCleranceDetails}
              />
            </MDBCol>

            <MDBCol md={1} id="split1">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol md={1} id="split2">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol md={1} id="split3">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol md={1} id="split3">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol md={1} id="split5">
              <BasicTextField
                isMandatory={false}
                showMandatory={this.state.postInitiated || false}
                value={this.state.textField || ""}
                // onChange={(e) => this.handleChange('talent_name', e.target.value)}
                className="payIncrease"
              />
            </MDBCol>
            <MDBCol md={1}></MDBCol>
            <MDBCol id="plus">
              <MDBIcon icon="plus-circle" className="circle" />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-4">
            <MDBCol md={10}></MDBCol>
            <MDBCol md={2}>
              <MDBRow>
                <MDBCol>
                  <BasicButton text={"Save"} onClick={this.handleSubmit} />
                </MDBCol>
                <MDBCol>
                  <BasicButton
                    onClick={() => this.props.handleClose()}
                    variant="outlined"
                    text={"Cancel"}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}
export default CostSplitsDefault;
