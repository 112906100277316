import React from 'react';
import { MusicEditorTabList } from '../../../constants/TabList';
import TabsComponent from "../../../components/SharedComponents/Tabs/Tabs";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import MusicEditorDetails from './MusicEditorDetails';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import ShowSeasons from './ShowSeasons';

export default class MusicEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
        }
    }
    handleTabChange = (newTabValue, type) => {
        this.setState({
            [type]: newTabValue,
        });
    };
    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <MusicEditorDetails />;
            case 2:
                return <ShowSeasons />;
        }
    };
    render() {
        return (
            <MDBContainer fluid className="MusicEditorContainer">
                <div>
                    <MDBRow className="tab-content">
                        <MDBCol md={10}>
                            <TabsComponent
                                tabList={MusicEditorTabList}
                                value={this.state.tabValue}
                                onChange={(e, v) => this.handleTabChange(v, "tabValue")}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
                {this.getTabComponent()}
                <MDBRow>
                    <MDBCol md={10}></MDBCol>
                    <MDBCol md={2}>
                        <MDBRow>
                            <MDBCol>
                                <BasicButton text={"Save"} />
                            </MDBCol>
                            <MDBCol>
                                <BasicButton variant="outlined" text={"Cancel"} />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}