import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import './EditShowDetails.scss';
import 'rc-time-picker/assets/index.css';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import { withUserContext } from '../../../contexts/UserContext';
var postObj = [], sum, is_initial_rights_updated = 0, is_optional_rights_updated = 0, is_alt_initial_rights_updated = 0, initialRecordData = {}, initialRecordData_backup, initial_right_id_new, initial_right_id_backup, alt_initial_right_id_new, alt_initial_right_id_backup, initial_option_right_id_backup, initial_option_right_id_new;
class EditDefaultRights extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPosting: false,
            initialRecordData: JSON.parse(JSON.stringify(this.props.initialRecordData1)),
            show_season_id: this.props.show_season_id,
            postInitiated: false,
        };
    }

    onCheckboxChange = (checkboxKey, checkedValue, index, objPassed, parentKey) => {
        var val = (checkedValue == true) ? 1 : 0;
        switch (checkboxKey) {
            case 'incl_init':
                let optionRightsArr = this.state.initialRecordData.optional_rights;
                optionRightsArr[index]['incl_init'] = val;
                initialRecordData["is_optional_rights_updated"] = 1;
                initialRecordData[parentKey] = optionRightsArr;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [parentKey]: optionRightsArr,
                        ["is_optional_rights_updated"]: 1
                    }
                }));
                break;
            default:
                initialRecordData[checkboxKey] = val;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [checkboxKey]: val
                    },
                }));
                break;
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }
    onDropdownChange(whatChanged, parentKey, index, keyToModify, valPassed, objPassed) {
        switch (whatChanged) {
            case 'optionalRightsChanged':
                initial_option_right_id_backup = this.state.initialRecordData.optional_rights[index].right_id;
                let optionRightsObj = {};
                let rightsArr = this.state.initialRecordData.optional_rights;
                objPassed[keyToModify] = valPassed;
                if (keyToModify === "right_id") {
                    initial_option_right_id_new = valPassed;
                }
                rightsArr.splice(index, 1, objPassed);
                initialRecordData["is_optional_rights_updated"] = 1;
                initialRecordData[parentKey] = rightsArr;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [parentKey]: rightsArr,
                        ["is_optional_rights_updated"]: 1
                    },
                    postInitiated: false,
                }));

                // console.log("initial_option_right_id_new", initial_option_right_id_new);
                // console.log("this.state.initialRecordData.optional_rights[index].right_id", this.state.initialRecordData.optional_rights[index].right_id);
                // console.log("this.state.initialRecordData.optional_rights[index].term_id", this.state.initialRecordData.optional_rights[index].term_id);
                // console.log("this.state.initialRecordData.optional_rights[index].exp_term_id", this.state.initialRecordData.optional_rights[index].exp_term_id);
                // console.log("this.state.initialRecordData.optional_rights[index].territory_id", this.state.initialRecordData.optional_rights[index].territory_id);
                // console.log("initialRecordData_backup.optional_rights[index].right_id", initialRecordData_backup.optional_rights[index].right_id);
                if (this.state.initialRecordData.optional_rights[index].right_id === null || this.state.initialRecordData.optional_rights[index].right_id === undefined || this.state.initialRecordData.optional_rights[index].right_id.length === 0 || this.state.initialRecordData.optional_rights[index].term_id === null || this.state.initialRecordData.optional_rights[index].term_id === undefined || this.state.initialRecordData.optional_rights[index].term_id.length === 0 || this.state.initialRecordData.optional_rights[index].exp_term_id === null || this.state.initialRecordData.optional_rights[index].exp_term_id === undefined || this.state.initialRecordData.optional_rights[index].exp_term_id.length === 0 || (initial_option_right_id_backup !== initial_option_right_id_new)) {
                    optionRightsObj = this.props?.optionalRightsOptions?.filter((item) => (keyToModify === "right_id" && item.value === valPassed) ? item : null);
                    console.log("optionRightsObj", optionRightsObj);
                    if (optionRightsObj.length > 0) {
                        if (this.state.initialRecordData.optional_rights[index].term_id === null || this.state.initialRecordData.optional_rights[index].term_id === undefined || this.state.initialRecordData.optional_rights[index].term_id.length === 0 || (initial_option_right_id_backup !== initial_option_right_id_new)) {
                            objPassed["term_id"] = optionRightsObj[0].default_term_id;
                            rightsArr.splice(index, 1, objPassed);
                            initialRecordData.rightsArr = rightsArr;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    optional_rights: rightsArr
                                },
                                postInitiated: false,
                            }));
                        }
                        if (this.state.initialRecordData.optional_rights[index].territory_id === null || this.state.initialRecordData.optional_rights[index].territory_id === undefined || this.state.initialRecordData.optional_rights[index].territory_id.length === 0 || (initial_option_right_id_backup !== initial_option_right_id_new)) {
                            objPassed["territory_id"] = optionRightsObj[0].default_territory_id;
                            rightsArr.splice(index, 1, objPassed);
                            initialRecordData.rightsArr = rightsArr;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    optional_rights: rightsArr
                                },
                                postInitiated: false,
                            }));
                        }
                        if (this.state.initialRecordData.optional_rights[index].exp_term_id === null || this.state.initialRecordData.optional_rights[index].exp_term_id === undefined || this.state.initialRecordData.optional_rights[index].exp_term_id.length === 0 || (initial_option_right_id_backup !== initial_option_right_id_new)) {
                            objPassed["exp_term_id"] = optionRightsObj[0].default_exp_term_id;
                            rightsArr.splice(index, 1, objPassed);
                            initialRecordData.rightsArr = rightsArr;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    optional_rights: rightsArr
                                },
                                postInitiated: false,
                            }));
                        }
                    }
                }
                break;
            case 'initialRightsChanged':
                let rightsObj = {};
                is_initial_rights_updated = 1;
                initialRecordData[keyToModify] = valPassed;
                initial_right_id_backup = this.state.initialRecordData.initial_right_id;
                if (keyToModify === "initial_right_id") {
                    initial_right_id_new = valPassed;
                }
                initialRecordData["is_initial_rights_updated"] = 1;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [keyToModify]: valPassed,
                        ["is_initial_rights_updated"]: 1
                    },
                    postInitiated: false,
                }));
                if (this.state.initialRecordData.initial_term_id === null || this.state.initialRecordData.initial_term_id === undefined || this.state.initialRecordData.initial_territory_id === null || this.state.initialRecordData.initial_territory_id === undefined || ((initialRecordData_backup.initial_right_id !== initial_right_id_new) && (initial_right_id_backup !== initial_right_id_new))) {
                    rightsObj = this.props?.initialRightsOptions?.filter((item) => (keyToModify === "initial_right_id" && item.value === valPassed) ? item : null);

                    if (rightsObj.length > 0) {
                        if (this.state.initialRecordData.initial_term_id === null || this.state.initialRecordData.initial_term_id === undefined || ((initialRecordData_backup.initial_right_id !== initial_right_id_new) && (initial_right_id_backup !== initial_right_id_new))) {
                            initialRecordData["initial_term_id"] = rightsObj[0].default_term_id;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    initial_term_id: rightsObj[0].default_term_id
                                },
                                postInitiated: false,
                            }));
                        }
                        if (this.state.initialRecordData.initial_territory_id === null || this.state.initialRecordData.initial_territory_id === undefined || ((initialRecordData_backup.initial_right_id !== initial_right_id_new) && (initial_right_id_backup !== initial_right_id_new))) {
                            initialRecordData["initial_territory_id"] = rightsObj[0].default_territory_id;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    initial_territory_id: rightsObj[0].default_territory_id
                                },
                                postInitiated: false,
                            }));
                        }
                        if (((this.state.initialRecordData.initial_term_id === null || this.state.initialRecordData.initial_term_id === undefined) && (this.state.initialRecordData.initial_territory_id === null || this.state.initialRecordData.initial_territory_id === undefined)) || ((initialRecordData_backup.initial_right_id !== initial_right_id_new) && (initial_right_id_backup !== initial_right_id_new))) {
                            initialRecordData["initial_term_id"] = rightsObj[0].default_term_id;
                            initialRecordData["initial_territory_id"] = rightsObj[0].default_territory_id;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    initial_term_id: rightsObj[0].default_term_id,
                                    initial_territory_id: rightsObj[0].default_territory_id
                                },
                                postInitiated: false,
                            }));
                        }
                    }
                }

                break;
            case 'altInitialRightsChanged':
                let altRightsObj = {};
                is_alt_initial_rights_updated = 1;
                initialRecordData[keyToModify] = valPassed;
                alt_initial_right_id_backup = this.state.initialRecordData.alt_initial_right_id;
                if (keyToModify === "alt_initial_right_id") {
                    alt_initial_right_id_new = valPassed;
                }
                initialRecordData["is_alt_initial_rights_updated"] = 1;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [keyToModify]: valPassed,
                        ["is_alt_initial_rights_updated"]: 1
                    },
                    postInitiated: false,
                }));
                if (this.state.initialRecordData.alt_initial_term_id === null || this.state.initialRecordData.alt_initial_term_id === undefined || this.state.initialRecordData.alt_initial_territory_id === null || this.state.initialRecordData.alt_initial_territory_id === undefined || ((initialRecordData_backup.alt_initial_right_id !== alt_initial_right_id_new) && (alt_initial_right_id_backup !== alt_initial_right_id_new))) {
                    altRightsObj = this.props?.initialRightsOptions?.filter((item) => (keyToModify === "alt_initial_right_id" && item.value === valPassed) ? item : null);
                    if (altRightsObj.length > 0) {
                        if (this.state.initialRecordData.alt_initial_term_id === null || this.state.initialRecordData.alt_initial_term_id === undefined || ((initialRecordData_backup.alt_initial_right_id !== alt_initial_right_id_new) && (alt_initial_right_id_backup !== alt_initial_right_id_new))) {
                            initialRecordData["alt_initial_term_id"] = altRightsObj[0].default_term_id;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    alt_initial_term_id: altRightsObj[0].default_term_id
                                },
                                postInitiated: false,
                            }));
                        }
                        if (this.state.initialRecordData.alt_initial_territory_id === null || this.state.initialRecordData.alt_initial_territory_id === undefined || ((initialRecordData_backup.alt_initial_right_id !== alt_initial_right_id_new) && (alt_initial_right_id_backup !== alt_initial_right_id_new))) {
                            initialRecordData["alt_initial_territory_id"] = altRightsObj[0].default_territory_id;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    alt_initial_territory_id: altRightsObj[0].default_territory_id
                                },
                                postInitiated: false,
                            }));
                        }
                        if (((this.state.initialRecordData.alt_initial_term_id === null || this.state.initialRecordData.alt_initial_term_id === undefined) && (this.state.initialRecordData.alt_initial_territory_id === null || this.state.initialRecordData.alt_initial_territory_id === undefined)) || ((initialRecordData_backup.alt_initial_right_id !== alt_initial_right_id_new) && (alt_initial_right_id_backup !== alt_initial_right_id_new))) {
                            initialRecordData["alt_initial_term_id"] = altRightsObj[0].default_term_id;
                            initialRecordData["alt_initial_territory_id"] = altRightsObj[0].default_territory_id;
                            this.setState((prevState) => ({
                                initialRecordData: {
                                    ...prevState.initialRecordData,
                                    alt_initial_term_id: altRightsObj[0].default_term_id,
                                    alt_initial_territory_id: altRightsObj[0].default_territory_id
                                },
                                postInitiated: false,
                            }));
                        }
                    }
                }

                break;

            default:
                initialRecordData[keyToModify] = valPassed;
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        [keyToModify]: valPassed
                    },
                    postInitiated: false,
                }));
                break;
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    componentDidMount() {
        initialRecordData = JSON.parse(JSON.stringify(this.state.initialRecordData));
        initialRecordData_backup = JSON.parse(JSON.stringify(this.state.initialRecordData));
    }
    addOptionRight() {
        let rights_arr = this.state.initialRecordData.optional_rights;
        initialRecordData["is_optional_rights_updated"] = 1;
        rights_arr.splice(this.state.initialRecordData.optional_rights.length, 0, {
            "show_season_rights_id": null,
            "right_id": "",
            "term": "",
            "exp_term": "",
            "territory": "",
            "incl_init": 0,
            "is_optional": 1
        });
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["optional_rights"]: rights_arr,
                ["is_optional_rights_updated"]: 1
            }
        }));
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    removeOptionRight(index) {
        let optionRightsArr = this.state.initialRecordData.optional_rights;
        initialRecordData["is_optional_rights_updated"] = 1;
        optionRightsArr.splice(index, 1);
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["optional_rights"]: optionRightsArr,
                ["is_optional_rights_updated"]: 1
            }
        }));
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    render() {
        return (
            <div>
                <MDBContainer fluid className="EditShowDetailsModalContainer">
                    {/* Initial Rights with values start */}
                    <MDBRow className="withoutPaddingColumnsRowIR" id={"initialRight"}>
                        <MDBCol md={3}>
                            <SelectField
                                value={this.state.initialRecordData.initial_right_id || ""}
                                options={this.props.initialRightsOptions || []}
                                onChange={(e) => this.onDropdownChange("initialRightsChanged", null, null, "initial_right_id", e.target.value, null)}
                                label="Initial Rights"
                                id="InitialRights"
                                placeHolderText="- Select Initial Right -"
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <SelectField
                                disabled={this.state?.initialRecordData?.initial_right_id ? false : true}
                                value={this.state.initialRecordData.initial_term_id}
                                //value={typeof this.state.initialRecordData.initial_term === "string" ? this.state.initialRecordData.initial_term_id : this.state.initialRecordData.initial_term}
                                options={this.props.termOptions || []}
                                onChange={(e) => this.onDropdownChange("initialRightsChanged", null, null, "initial_term_id", e.target.value, null)}
                                label="Term"
                                id="TermInitialRights"
                                isMandatory={this.state?.initialRecordData.initial_right_id === null || this.state?.initialRecordData.initial_right_id === undefined ? false : true}
                                showMandatory={this.state.postInitiated || false}
                                placeHolderText="- Select Term -"
                            />
                        </MDBCol>
                        <MDBCol md={2}>
                            <SelectField
                                disabled={this.state?.initialRecordData?.initial_right_id ? false : true}
                                value={this.state.initialRecordData.initial_territory_id}
                                // value={typeof this.state.initialRecordData.initial_territory === "string" ? this.state.initialRecordData.initial_territory_id : this.state.initialRecordData.initial_territory}
                                options={this.props.territoryOptions || []}
                                onChange={(e) => this.onDropdownChange("initialRightsChanged", null, null, "initial_territory_id", e.target.value, null)}
                                label="Territory"
                                id="TerritoryInitialRights"
                                isMandatory={this.state?.initialRecordData.initial_right_id === null || this.state?.initialRecordData.initial_right_id === undefined ? false : true}
                                showMandatory={this.state.postInitiated || false}
                                placeHolderText="- Select Territory -"
                            />
                        </MDBCol>
                    </MDBRow>
                    {/* Existing Initial Rights with values end */}
                    {/* for alt initial existing rights start  */}

                    <MDBRow id={"altInitialRight"} className="withoutPaddingColumnsRowIR">
                        <MDBCol md={3}>
                            <SelectField
                                value={this.state.initialRecordData.alt_initial_right_id || ""}
                                options={this.props.altInitialRightsOptions || []}
                                onChange={(e) => this.onDropdownChange("altInitialRightsChanged", null, null, "alt_initial_right_id", e.target.value, null)}
                                label="Alt Initial Rights"
                                id="AltInitialRights"
                                placeHolderText="- Select Alt Initial Right -"
                                isMandatory={false}
                            />
                        </MDBCol>

                        <MDBCol md={2}>
                            <SelectField
                                disabled={this.state?.initialRecordData?.alt_initial_right_id ? false : true}
                                value={this.state.initialRecordData.alt_initial_term_id}
                                //value={typeof this.state.initialRecordData.alt_initial_term === "string" ? this.state.initialRecordData.alt_initial_term_id : this.state.initialRecordData.alt_initial_term}
                                options={this.props.termOptions || []}
                                onChange={(e) => this.onDropdownChange("altInitialRightsChanged", null, null, "alt_initial_term_id", e.target.value, null)}
                                label="Term"
                                id="AltTermInitialRights"
                                isMandatory={this.state?.initialRecordData.alt_initial_right_id === null || this.state?.initialRecordData.alt_initial_right_id === undefined ? false : true}
                                showMandatory={this.state.postInitiated || false}
                                placeHolderText="- Select Term -"
                            />
                        </MDBCol>

                        <MDBCol md={2}>
                            <SelectField
                                disabled={this.state?.initialRecordData?.alt_initial_right_id ? false : true}
                                value={this.state.initialRecordData.alt_initial_territory_id}
                                //value={typeof this.state.initialRecordData.alt_initial_territory === "string" ? this.state.initialRecordData.alt_initial_territory_id : this.state.initialRecordData.alt_initial_territory}
                                options={this.props.territoryOptions || []}
                                onChange={(e) => this.onDropdownChange("altInitialRightsChanged", null, null, "alt_initial_territory_id", e.target.value, null)}
                                label="Territory"
                                id="AltTerritoryInitialRights"
                                isMandatory={this.state?.initialRecordData.alt_initial_right_id === null || this.state?.initialRecordData.alt_initial_right_id === undefined ? false : true}
                                showMandatory={this.state.postInitiated || false}
                                placeHolderText="- Select Territory -"
                            />
                        </MDBCol>
                    </MDBRow>
                    {/* for alt initial rights end */}
                    {/* <MDBRow className="optionRightRow">
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicLabel text={"Option Rights"} />
                        </MDBCol>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicLabel text={"Term"} />
                        </MDBCol>
                        <MDBCol md={2} className="secondaryLabelContainer">
                            <BasicLabel text={"Exp Term"} />
                        </MDBCol>
                        <MDBCol md={3} className="secondaryLabelContainer">
                            <BasicLabel text={"Territory"} />
                        </MDBCol>
                        <MDBCol md={1} className="secondaryLabelContainer">
                            <BasicLabel text={"Incl. Init"} />
                        </MDBCol>
                        <MDBCol md={1}> </MDBCol>
                    </MDBRow> */}
                    {/* Option rights with multiple records  */}
                    {this.state.initialRecordData.optional_rights != undefined && this.state.initialRecordData.optional_rights != null && this.state.initialRecordData.optional_rights.map((item, index) => (
                        <MDBRow className="optionRightRow" key={"optionRightRow" + index} id={"optionRightRow" + index}>
                            <MDBCol md={3}>
                                <SelectField
                                    value={item.right_id}
                                    options={this.props.optionalRightsOptions || []}
                                    onChange={(e) => this.onDropdownChange("optionalRightsChanged", "optional_rights", index, "right_id", e.target.value, item)}
                                    label={index === 0 ? "Option Rights" : null}
                                    id={"OptionalRights" + index}
                                    placeHolderText="- Select Optional Right -"
                                    isMandatory={false}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <SelectField
                                    disabled={item?.right_id || item?.right_id?.trim()?.length !== 0 ? false : true}
                                    value={item.term_id}
                                    options={this.props.termOptions || []}
                                    onChange={(e) => this.onDropdownChange("optionalRightsChanged", "optional_rights", index, "term_id", e.target.value, item)}
                                    label={index === 0 ? "Term" : null}
                                    id={"OptionalRightsTerm" + index}
                                    isMandatory={item?.right_id === null || item?.right_id === undefined || item?.right_id?.length === 0 ? false : true}
                                    showMandatory={this.state.postInitiated || false}
                                    placeHolderText="- Select Term -"
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <SelectField
                                    label={index === 0 ? "Exp Term" : null}
                                    disabled={item?.right_id || item?.right_id?.trim()?.length !== 0 ? false : true}
                                    value={item.exp_term_id}
                                    options={this.props.termOptions || []}
                                    onChange={(e) => this.onDropdownChange("optionalRightsChanged", "optional_rights", index, "exp_term_id", e.target.value, item)}
                                    id={"OptionalRightsExpTerm" + index}
                                    isMandatory={item?.right_id === null || item?.right_id === undefined || item?.right_id?.length === 0 ? false : true}
                                    showMandatory={this.state.postInitiated || false}
                                    placeHolderText="- Select Exp Term -"
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <SelectField
                                    label={index === 0 ? "Territory" : null}
                                    disabled={item?.right_id || item?.right_id?.trim()?.length !== 0 ? false : true}
                                    value={item.territory_id || ""}
                                    options={this.props.territoryOptions || []}
                                    onChange={(e) => this.onDropdownChange("optionalRightsChanged", "optional_rights", index, "territory_id", e.target.value, item)}
                                    id={"OptionalRightsTerritory" + index}
                                    isMandatory={item?.right_id === null || item?.right_id === undefined || item?.right_id?.length === 0 ? false : true}
                                    showMandatory={this.state.postInitiated || false}
                                    placeHolderText="- Select Territory -"
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicCheckbox id={"incl_init" + index} className={index === 0 ? "addMarginTop" : null}
                                    checked={item.incl_init}
                                    onChange={(e) => this.onCheckboxChange("incl_init", e.target.checked, index, item, "optional_rights")}
                                    label="Incl. Init"
                                />
                            </MDBCol>
                            <MDBCol md={1}>
                                {index === this.state.initialRecordData.optional_rights.length - 1 && <AddCircleIcon className={index === 0 ? "addMarginTop" : null} fontSize="small" onClick={() => this.addOptionRight()} />}
                                {this.state.initialRecordData.optional_rights.length > 1 && <RemoveCircleIcon className={index === 0 ? "addMarginTop" : null} fontSize="small"
                                    onClick={() => this.removeOptionRight(index)}
                                />}
                            </MDBCol>
                        </MDBRow>
                    ))}
                    {/* Optional rights dropdown row end*/}

                    {/* <MDBRow>
                                <MDBCol md={10}></MDBCol>
                                <MDBCol md={2} className="saveCancelWrapper">
                                    <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"} onClick={this.handleSubmit} />
                                    <BasicButton
                                        onClick={() => this.props.handleClose()}
                                        variant="outlined"
                                        text={"Cancel"}
                                    />
                                </MDBCol>
                            </MDBRow> */}
                </MDBContainer>
            </div>
        );
    }
}
export default withUserContext(EditDefaultRights);
