import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import TableComponent from "../../SharedComponents/Table";
import { licenseEntityInfoConfig, licenseEntityList } from "./Config";
import "./EditLicensor.scss";
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import { validateEmail, formatPhoneNumbers, validateAllPhone } from '../../../Common/Helper';
import sizeLimits from '../../../Common/SizeLimits.json';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import {CircularProgress} from '@material-ui/core'

class LicensorEntityInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseEntityData: [],
            tableData: [],
            stateList: [],
            countryList: [],
            selectedCountryValue: null,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            showSuccess: false,
            showFail: false,
            fieldChanged: false,
            license_entity_id: this.props?.license_entity_id,
            isSubmit:false,
            isDelete:false
        }
    }

    componentDidMount() {
        this.getStateList();
        this.getCountryList();
        this.getLicenseEntityInfo();
    }

    getStateList = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_state`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ stateList: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getCountryList = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_country`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                let defaultCountry = formattedList.filter(item => {
                    return item.label === "United States"
                })
                this.setState({
                    countryList: formattedList, selectedCountryValue: defaultCountry[0].value
                });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getLicenseEntityInfo = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/licenseEntityInfo?license_entity_info_id=${this.props.license_entity_id}`, this.props.userContext?.active_tenant?.tenant_id, null)
            .then((response) => {
                if (response.data.error) {
                    console.log("Error while fetching Entity Info", response);
                }
                else {
                    let formattedList = response.data[0]
                    let tableData = response.data[0].licency_contact_info
                    this.setState({ licenseEntityData: formattedList, tableData: tableData })
                }
            },
                (err) => {
                    console.log("Error in fetching Entity Info:", err)
                })
    }

    postLicenseEntityInfo = (entityData) => {       
        if(this.props?.licensor_id){

      
        this.setState({isSubmit:true})
        entityData.licensor_id = this.props?.licensor_id
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/licenseEntityInfo`, entityData, this.props.userContext?.active_tenant?.tenant_id, null)
            .then((response) => {
                if (response.data.error) {
                    this.setState({isSubmit: false}, () => {
                        console.log("Error while Posting Entity Info", response);
                        this.props?.notificationComponent(true, "fail");
                    })
                }
                else {
                    console.log("Posted Data Successfully");
                    this.setState({isSubmit:false})
                    this.fieldChanged(false, "error");
                    this.props?.handleGeneralEdit('licensor_entity_info_id',response?.data?.[0]?.licensor_entity_info_id)
                    this.props?.notificationComponent(true, "success");
                }
            },
                (err) => {
                    this.setState({isSubmit:false})
                    console.log("Error in posting Entity Info:", err);
                    this.props?.notificationComponent(true, "fail");
                })
            }
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
          case "error": return this.setState({ fieldChanged: value });
          case "success": return this.setState({ showSuccess: value });
          case "fail": return this.setState({ showFail: value });
        }
      }

    handleInlineEdits = (id, dataItem) => {
        let allDetails = this.state.tableData
        this.setState({
            tableData: allDetails.map((item) => {
                let newItem = {...item}
                if (item.licency_contact_info_id == id)
                    newItem['editing'] = true
                return newItem
            })
        })
        this.fieldChanged(true, "error")
    }

    handleReadCallback = (index) => {
        let allDetails = this.state.tableData
        allDetails[index].editing = false
        this.setState({ tableData: allDetails })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
         let entityData = this.state.licenseEntityData
        let allDetails = [...data]
        let tableValue = [...this.state.tableData]
        allDetails[index].editing = false;
        tableValue[index].licency_contact_name = allDetails[index]?.licency_contact_name;
        tableValue[index].licency_contact_email = allDetails[index]?.licency_contact_email;
        tableValue[index].is_cc = allDetails[index]?.is_cc === true ? 1 : 0;
        this.setState({ tableData: allDetails, licenseEntityData: entityData });
    }

    handleAddNewItem = (newDataItem) => {
        let allDetails = [...this.state.tableData]
        let index = allDetails.length
        allDetails[index] = {
            "licency_contact_name": newDataItem?.licency_contact_name,
            "licency_contact_email": newDataItem?.licency_contact_email,
            "is_cc": newDataItem.is_cc === true?1:0
        }

        let entityData = this.state.licenseEntityData
        entityData.licency_contact_info = allDetails
        this.setState({ tableData: allDetails, licenseEntityData: entityData });
    }
    deleteLicensorContactInfo = (item) => {
        let allDetails = [...this.state.tableData]
        let details = allDetails.map(data => {
            if (data === item) {
                return data = {
                  "licency_contact_info_id":item?.licency_contact_info_id,
                    "licency_contact_name": item?.licency_contact_name,
                    "licency_contact_email": item?.licency_contact_email,
                    "is_cc": item.is_cc === true ? 1 : 0,
                    "is_delete":1
               }
            } else {
                return data
           }
        })
        let filteredContacts = details.filter((item1) => item1?.is_delete !== 1)
        let entityData = this.state.licenseEntityData
        entityData.licency_contact_info = details
        this.setState({ tableData: filteredContacts, licenseEntityData: entityData });
    }

    handleLicenseChange = (field, value) => {
        value = value ? value : null;
        this.setState(prevState => ({
            licenseEntityData: {
                ...prevState.licenseEntityData,
                [field]: value
            }
            // postInitiated: false
        }))
        this.fieldChanged(true, "error")
    }

    removeLicensor = (id) => {
        this.setState({isDelete:true})
        ClearTrackService.deleteParam(Constants.ClearTrackServiceBaseUrl + `/licenseEntityInfo?license_entity_info_id=${id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    console.log("Error while Posting Entity Info", response);
                    this.setState({isDelete:false})
                    this.props?.notificationComponent(true, "fail");
                }
                else {
                    console.log("Posted Data Successfully")
                    this.setState({isDelete:false})
                    this.props?.notificationComponent(true, "success");
                }
            },
                (err) => {
                    console.log("Error in posting Entity Info:", err)
                    this.setState({isDelete:false})
                    this.props?.notificationComponent(true, "fail");
                })
    }

    closeState = () => {
        if (this.state.fieldChanged) {
          this.setState({ showError: true });
        }
        else {
          this.props?.handleClose();
        }
    }

    onConfirm = () => {
        this.props?.handleClose();
    }

    render() {
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveEditMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failTitle :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <MDBContainer fluid className="licenseEntity">
                <br />
                <h6>Enter Information ONLY if different from Licensor.</h6>
                <BasicButton
                    text={
                        this.state.isDelete ? (
                          <CircularProgress color="inherit" size={18} />
                        ) : (
                          "Remove from Licensor"
                        )
                      }
                    disabled={this.state.licenseEntityData?.licensor_entity_info_id?false:true}
                    onClick={() => this.removeLicensor(this.state.licenseEntityData?.licensor_entity_info_id)}
                />
                <MDBRow>
                    <MDBCol md={6}>
                        <BasicTextField
                            label="Company"
                            placeholder="Company Name"
                            value={this.state.licenseEntityData.company}
                            onChange={(e) => this.handleLicenseChange("company", e.target.value)}
                        />
                        <BasicTextField
                            label="Contact Name"
                            placeholder="Contact Name"
                            value={this.state.licenseEntityData.contact_name}
                            onChange={(e) => this.handleLicenseChange("contact_name", e.target.value)}
                        />
                        <BasicTextField
                            label="Email"
                            placeholder="Email"
                            value={this.state.licenseEntityData.email}
                            onChange={(e) => this.handleLicenseChange("email", e.target.value)}
                            inValidInput={"Invalid Email"}
                            fieldValid={!validateEmail(this.state.licenseEntityData?.email) && this.state.licenseEntityData?.email}
                        />
                        <BasicTextField
                            label="Phone"
                            value={this.state.licenseEntityData.phone}
                            placeholder="(___) ___-____"
                                // fieldValid={ this.state.licenseEntityData?.phone ? !validateAllPhone(this.state.licenseEntityData?.phone) : false}
                                // inValidInput={"Please enter valid phone"}
                                onChange={(e) =>
                                  this.handleLicenseChange(
                                    "phone",
                                    e.target.value?.length > 0 ? formatPhoneNumbers(e.target.value) : null
                                  )
                                }
                        />
                    </MDBCol>
                    <MDBCol md={6}>
                        <BasicTextField
                            label="Address"
                            placeholder="Line 1"
                            value={this.state.licenseEntityData.address_line_1}
                            onChange={(e) => this.handleLicenseChange("address_line_1", e.target.value)}
                        />
                        <BasicTextField
                            placeholder="Line 2"
                            value={this.state.licenseEntityData.address_line_2}
                            onChange={(e) => this.handleLicenseChange("address_line_2", e.target.value)}
                        />
                        <BasicTextField
                            label="City"
                            placeholder="City"
                            value={this.state.licenseEntityData.city}
                            onChange={(e) => this.handleLicenseChange("city", e.target.value)}
                        />
                        <MDBRow>
                            <MDBCol md={5}>                           
                                {this.state.licenseEntityData?.country_id === 1 ?
                                <SelectField
                                label="State"
                                placeHolderText="State"
                                value={this.state.licenseEntityData?.state ? this.state.stateList?.find(item => item?.label === this.state.licenseEntityData?.state)?.value : null}
                                options={this.state.stateList}
                                onChange={(e, item) => this.handleLicenseChange("state", item?.props?.children)}
                                /> :
                                <BasicTextField
                                placeholder="State"
                                label="State"
                                value={this.state.licenseEntityData?.state}
                                onChange={(e) => this.handleLicenseChange("state", e.target.value)}
                                />
                                }
                            </MDBCol>
                            <MDBCol md={7}>
                                <BasicTextField
                                    label="Zip Code"
                                    placeholder="Zip Code"
                                    value={this.state.licenseEntityData.zip_code}
                                    onChange={(e) => this.handleLicenseChange("zip_code", e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <SelectField
                            label="Country"
                            placeHolderText="- Country -"
                            options={this.state.countryList}
                            value={this.state.licenseEntityData.country_id}
                            onChange={(e,item) => {
                                this.handleLicenseChange("country_id", e.target.value)
                                this.handleLicenseChange("country", item?.props?.children)
                                this.handleLicenseChange("state", null)
                            }}
                        />
                    </MDBCol>
                </MDBRow>
                <br />
                <MDBRow className="adjustTablewidth">
                    <TableComponent
                        config={licenseEntityInfoConfig}
                        list={this.state.tableData}
                        editCallback={this.handleInlineEdits.bind(this)}
                        saveCallback={this.saveInlineEdits.bind(this)}
                        readCallback={this.handleReadCallback.bind(this)}
                        handleAddNewItem={this.handleAddNewItem.bind(this)}
                        isLoading={this.props.isLoading}
                        addItemToList={true}
                        handleDelCallBack={(id, item) =>
                            this.deleteLicensorContactInfo(item)
                          }
                    />
                </MDBRow>
                <br />
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            text={
                                this.state.isSubmit ? (
                                  <CircularProgress color="inherit" size={18} />
                                ) : (
                                  "Save"
                                )
                              }
                            onClick={() => this.postLicenseEntityInfo(this.state.licenseEntityData)}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            variant="outlined"
                            text="Cancel"
                            onClick={this.closeState}
                        />
                    </MDBCol>
                </MDBRow>
                <MessageModal
                    open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                    title={comfirmModalTitle}
                    message={message}
                    hideCancel={this.state.showFail || this.state.showSuccess || false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={this.onConfirm}
                    handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
            </MDBContainer>
        )
    }
}

export default withUserContext(LicensorEntityInfo);