import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TableComponent from "../SharedComponents/Table";
import ClearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import {withUserContext} from '../../contexts/UserContext'
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class ShowSeasonOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      hebOptions: [],
      showSeasonConfig: JSON.parse(JSON.stringify({ ...this.props?.config})),
      isLoading: false,
      isSubmit:false,
      mandatoryNotExist:false,
      sortBy: null,
      isSortByAsc: true,
      sortCount: 0
    };
  }
  componentDidMount() {
    this.getHEBoptions();
    this.getShowSeasonOptions("SHOW_SEASON_OPTIONS", "");
  }
  getShowSeasonOptions = (showSeasonOptions, searchString, loader = true) => {
    this.setState({isLoading:loader})
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${showSeasonOptions}&searchString=${searchString}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let showSeasonDeta = [...res.data];
        showSeasonDeta.map((data) => {
          return (data.H_E_B = this.state.hebOptions?.find(
            (item) => item?.label === data?.H_E_B
          )?.value);
        });
        let configRecord = {...this.state.showSeasonConfig}
        this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
        this.setState({ renderList: showSeasonDeta,isLoading:false, isSubmit :false,  sortBy: null,
          isSortByAsc: true, showSeasonConfig: configRecord,
          sortCount: 0} , () => {
            this.handleTableColumnClick("Option")
          });
      })
        .catch((err) => {
          console.log("err", err)
          this.setState({isLoading:false})
      });
  };
  getHEBoptions = () => {
    let showSeasonConfigValue = { ...this.state.showSeasonConfig };
    let hebOptionSelect = [
      { label: "H", value: 1 },
      { label: "E", value: 2 },
      { label: "B", value: 3 },
    ];
    showSeasonConfigValue.headings[2].selectOptions = hebOptionSelect;

    this.setState({
      showSeasonConfig: showSeasonConfigValue,
      hebOptions: hebOptionSelect,
    });
  };

  handleInlineEdits = (id, dataItem) => {
    let showSeasonOptions = [...this.state.renderList];
    this.setState({
      mandatoryNotExist:false,
      renderList: showSeasonOptions.map((item) => {
        let newItem = {...item}
        if (item.show_season_option_id == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({mandatoryNotExist : false })
    if(data[index]?.option && data[index]?.option?.length > 0  && data[index]?.H_E_B) {
      let showSeasonOptions = [...data];
      let showSeasonOptionDetails = [...this.state.renderList];
      let hebValue = this.state.hebOptions?.find(
        (item) => item?.value === showSeasonOptions[index]?.H_E_B
      )?.label;
      showSeasonOptionDetails[index].is_active = showSeasonOptions[index]
        ?.is_active
        ? 1
        : 0;
      showSeasonOptionDetails[index].option = showSeasonOptions[index]?.option || null;
      showSeasonOptionDetails[index].H_E_B =hebValue;
      showSeasonOptionDetails[index].is_default = showSeasonOptions[index]
        ?.is_default
        ? 1
        : 0;
      showSeasonOptionDetails[index].notes = showSeasonOptions[index]?.notes || null;
      this.setState({ renderList: showSeasonOptionDetails });
      this.postShowSeasonOptions(showSeasonOptionDetails[index],showSeasonOptionDetails[index]?.show_season_option_id);
    } else {
      this.setState({mandatoryNotExist : true })
    }
  };

  postShowSeasonOptions = (showSeasonOptionDetails, postFlag) => {
    this.setState({ isSubmit: postFlag });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/showSeasonOptions`,
      showSeasonOptionDetails,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false });
          this.props?.toastMessageNotification(true, "fail");
          this.getShowSeasonOptions("SHOW_SEASON_OPTIONS", "",);
        } else {
         // this.setState({
           // isSubmit: false,
          //});
          if (showSeasonOptionDetails?.editing) {
            showSeasonOptionDetails.editing = false;
          }
          this.props?.toastMessageNotification(true, "success");
          this.getShowSeasonOptions("SHOW_SEASON_OPTIONS", "",false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props?.toastMessageNotification(true, "fail");
        console.log("Post budget details error: " + err);
      }
    );
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({mandatoryNotExist : false })
    if(newDataItem?.option && newDataItem?.option?.length > 0  && newDataItem?.H_E_B) {
    let hebValue = this.state.hebOptions?.find(
      (item) => item?.value === newDataItem?.H_E_B
    )?.label;
    let newShowSeasonData = {
      is_active: 1,
      option: newDataItem?.option || null,
      H_E_B: hebValue,
      is_default: newDataItem?.is_default ? 1 : 0,
      notes: newDataItem?.notes || null,
    };
    // let showSeasonDetails = [...this.state.renderList];

    // showSeasonDetails.push(newShowSeasonData);
    this.postShowSeasonOptions(newShowSeasonData, 'newItemPost');
    //this.setState({ renderList: showSeasonDetails });
  } else {
    this.setState({mandatoryNotExist : true })
  }
  };
  
  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        showSeasonConfig: updateTableSortConfigObject(this.state.showSeasonConfig, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: tableSortList(colName === ("Active" || "Default") ? "Number" : "Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Option": return "option";
      case "H/E/B": return "H_E_B";
      case "Notes": return "notes";
      case "Active": return "is_active";
      case "Default": return "is_default";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="ShowSeasonOptionsContainer">
        <br/>
        {/* <MDBRow>
          <span className="description">[Description]</span>
        </MDBRow> */}
        <MDBRow className="pd">
        <span className="errorText">{this.state.mandatoryNotExist && 'Please enter Options and H/E/B fields value'}</span>
        </MDBRow>
        {this.state.isLoading ? (<div className="ContentLoader">
          <CircularProgress />
      </div>) : (<MDBRow className="ShowSeasonOptionsTable">
            <TableComponent
              newIsActive={true}
              list={this.state.renderList || []}
              config={this.state.showSeasonConfig}
              handleAddNewItem={this.handleAddNewItem.bind(this)}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              addItemToList={permissionValue?.edit === 1}
              postInitiated={this.state.isSubmit}
              //isLoading={this.state.isSubmit}
              readCallback={(index) => {
                let seasonOptions = [...this.state.renderList];
                seasonOptions[index].editing = false
                this.setState({ renderList: seasonOptions, mandatoryNotExist:false });
              }}
              stickyHeader={true}
              sortCallback={this.handleTableColumnClick}
            />
        </MDBRow>)}
      </MDBContainer>
    );
  }
}

export default withUserContext(ShowSeasonOptions);
