export const showSeasonsConfig = {
    headings: [
        {
            headingLabel: "Program",
            inputType: "text",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Season",
            inputType: "text",
            icon: "",
            width: "30%"
        },
        {
            headingLabel: "Union",
            inputType: "text",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Fee per Episode",
            inputType: "text",
            icon: "",
            width: "15%"
        },
        // {
        //     headingLabel: "Status",
        //     inputType: "text",
        //     icon: "",
        //     width: "10%"
        // },
        {
            headingLabel: "",
            inputType: "",
            icon: "",
            width: "5%"
        }
    ],
    dataNodes: ["show_name", "season_name", "union_name", "fee_per_episode", "deal_status_name"],
    primaryKey: "show_season_people_id",
    //actions: ["share"],
    hyperlinks: ["show_name", "season_name"]
}

export const seasonsList = [
    {
        id: 1,
        program: "Shameless",
        season: "2017-2018",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 2,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 3,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 4,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 5,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 6,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 7,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 8,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 9,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    },
    {
        id: 10,
        program: "[Show Name]",
        season: "[Show Years]",
        union: "[Union]",
        feePerEpisode: "$[Fee]",
        status: "[Deal Status]"
    }
]

export const addressConfig = {
    "address1": "address_1",
    "address2": "address_2",
    "country": "country_id",
    "state": "state",
    "city": "city",
    "zipcode": "zip_code",
}
export const agentAddressConfig = {
    "address1": "agent_address_1",
    "address2": "agent_address_2",
    "country": "agent_country_id",
    "state": "agent_state",
    "city": "agent_city",
    "zipcode": "agent_zip_code",
}
export const addShowRecordData = {
    "people_id": null,
    "company": null,
    "agent_full_name": null,
    "full_name": null,
    "user_id": null
}

export const initialComposerDetails =
{
    "show_season_id": null,
    "people_type": null,
    "show_season_people_id": null,
    "people_id": null,
    "user_id": null,
    "full_name": null,
    "company": null,
    "email": null,
    "work_phone": null,
    "cell_phone": null,
    "address_1": null,
    "address_2": null,
    "city": null,
    "state": null,
    "country": null,
    "country_id": null,
    "zip_code": null,
    "federal_id_no": null,
    "agent_full_name": null,
    "agent_email": null,
    "agent_company": null,
    "agent_work_phone": null,
    "agent_cell_phone": null,
    "agent_address_1": null,
    "agent_address_2": null,
    "agent_city": null,
    "agent_state": null,
    "agent_country": null,
    "agent_country_id": null,
    "agent_zip_code": null,
    "tenant_id": 1,
    "is_active": 1,
    "is_individual": 1
}