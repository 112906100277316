

const selectOptions = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "In-Active",
        value: 2
    },
]
const selectTenant = [
    {
        label: "Animation",
        value: 1
    },
    {
        label: "Television",
        value: 2
    },
    {
        label: "test",
        value: 3
    }
]

const selectRole = [
    {
        label: "Manager",
        value: 1,
    },
    {
        label: "Read only",
        value: 2,
    },
    {
        label: "Team Leader",
        value: 3,
    }
]
const selectNewGroup = [
    {
        label: "Kevin Parsnip",
        value: 1
    },
    {
        label: "Johhney Mcgeiver",
        value: 2
    },
    {
        label: "Shawn",
        value: 3
    }
]

export const showsList = [
    {
        text: "Shameless",
        value: 1
    },
    {
        text: "17 Again",
        value: 2
    },
    {
        text: "Queen Sugar",
        value: 3
    }
]
export const mockSelectedShow = [
    {
        value: null,
        text: null
    }
]
export const seasonsList = [
    {
        show_id: 1,
        seasons: [{
            season_id: 1,
            season_name: "2017-2018"
        },
        {
            season_id: 2,
            season_name: "2018-2019"
        },
        {
            season_id: 3,
            season_name: "2019-2020"
        }]
    },
    {
        show_id: 2,
        seasons: [{
            season_id: 1,
            season_name: "2017-2018"
        },
        {
            season_id: 2,
            season_name: "2018-2019"
        },
        {
            season_id: 3,
            season_name: "2019-2020"
        }]
    },
    {
        show_id: 3,
        seasons: [{
            season_id: 1,
            season_name: "2017-2018"
        },
        {
            season_id: 2,
            season_name: "2018-2019"
        },
        {
            season_id: 3,
            season_name: "2019-2020"
        }]
    }
]

export const userLimits = {
    "fullName": 50,
    "displayName": 50,
    "title": 50,
    "phone": 10,
    "email": 50
}

export const userPostJSON = {
    "user_id": null,
    "full_name": "Shravani Neravati",
    "display_name": "Neravati Shravani",
    "title": "Developer",
    "company": null,
    "login_email": "neravati.shravani@wbconsultant.com",
    "notification_email": "neravati.shravani@wbconsultant.com",
    "supervisor_id": 1,
    "supervisor_name": "Padma",
    "assistant_id": 1,
    "assistant_name": "Harini",
    "phone": null,
    "building": null,
    "room": null,
    "tenant_id": 1,
    "tenant_name": "WBTV",
    "divisions": [
        {
            "user_division_id": null,
            "division_id": 1,
            "division_name": "Animation"
        }
    ],
    "role_id": 1,
    "role_name": "COA",
    "level_id": 1,
    "level_name": "Regular User",
    "default_home_page": "Show Seasons",
    "show_seasons": [
        {
            "show_id": 1,
            "show_name": "Shameless",
            "season_id": 1,
            "season_name": "2017-2018"
        }
    ]
}
export const showOptions = {
    "value": "All Shows",
    "label": "All Shows"
}
export const levelOptions = {
    "value": null,
    "label": ""
}
export const mockSeasons = {
    "show_season_id": null,
    "show_id": null,
    "show_name": null,
    "season_id": null,
    "season_name": null,
    "division_id": null
    // "show_season_options": []
}
export const mockDivsions = {
    "division_id": null,
    "division_name": null,
    "role_id": null,
    "role_name": null,
    // "security_level_id": 0,
    // "security_level_name": null,
    "is_all_shows": 0,
    // "security_level_options": [],
    "is_read_only": 0
}
export const mockTenant1 = {
    "tenant_id":null,
    "tenant_name": null,
    "default_home_page": null,
    "default_home_page_option_id": null,
    "default_home_page_options": [],
    // "is_user_provisioner": 0,
    "division_role": []
}
export const mockTenant2 = {
    "tenant_id": null,
    "tenant_name": null,
    "default_home_page": null,
    "division_role": []
}

export const showSeasonConfig = {
    headings: [
        {
            headingLabel: "Show",
            inputType: "searchselect",
            icon: "",
            // border: true,
            width: "55%",
            selectOptions: []
        },
        {
            headingLabel: "Season",
            inputType: "select",
            icon: "",
            selectOptions: [],
            // border: true,
            width: "30%"
        },
        {
            headingLabel: "",
            // inputType: "text",
            // border: true,
            width: "15%"
        },
    ],
    dataNodes: ["show_id", "season_id"],
    primaryKey: ["show_id"],
    // nodeFields: [
    //     {
    //         column: "incl_init",
    //         icon: "check"
    //     },
    //     // {
    //     //     column: "is_cr",
    //     //     icon: "check"
    //     // },
    //     {
    //         column: "bch",
    //         icon: "money-check"
    //     },
    // ],
    // buttonFields: [
    //     {
    //         column: "option_acquired_date",
    //         icon: "",
    //         showDataNodeValue: true
    //     }
    // ],
    actions: ["pen", "times"],
    isClearFields: true,
    inlineEdits: [
        {
            dataNode: "show_id",
            width: "53%",
            fieldDisabled: false
        },
        {
            dataNode: "season_id",
            width: "30%",
            fieldDisabled: false
        },
    ],
};

export const showSeasonList = [
    {
        id: 1,
        show_name: "ABC",
        show_id: 1,
        season_id: 1,
        season_name: "XYZ"
    },
    {
        id: 2,
        show_name: "ABDEFC",
        show_id: 2,
        season_id: 2,
        season_name: "WXY"

    },
]

export const getJSON = {
    "user_id": null,
    "full_name": null,
    "display_name": null,
    "title": null,
    "company": null,
    "login_email": null,
    "notification_email": null,
    "supervisor_id": null,
    "supervisor_full_name": null,
    "supervisor_login_email": null,
    "assistant_id": null,
    "assistant_full_name": null,
    "assistant_login_email": null,
    "phone": null,
    "building": null,
    "room": null,
    "default_home_page": null,
    "is_active": 1,
    "tenants": [],
    "is_credential_update": 0,
    "is_all_shows": 1,
    "show_season": [],
    "is_user_provisioner": 0,
    "is_check_request_batch_receiver": 0,
    "is_overdue_license_signer": 0,
    // "is_read_only": 0,
    "is_void_notification_recipient": 0,
    "is_cost_allocation_notification_receiver": 0
}

export const defaultHomePage = [
    {
        label: "Show Season",
        value: "Show Season"
    },
    {
        label: "Clearance Records",
        value: "Clearance Records"
    },
    {
        label: "Songs",
        value: "Songs"
    },
    {
        label: "Licensors",
        value: "Licensors"
    },
    {
        label: "License Tracking",
        value: "License Tracking"
    },
    {
        label: "Payees",
        value: "Payees"
    },
    {
        label: "Rights",
        value: "Rights"
    },
    {
        label: "Cue Sheets",
        value: "Cue Sheets"
    },
    {
        label: "Music Supervisors/Show Composers",
        value: "Music Supervisors/Show Composers"
    },
    {
        label: "Music Editor",
        value: "Music Editor"
    },
    {
        label: "Budgets",
        value: "Budgets"
    },
    {
        label: "TV Cr. Music Budgeting Template",
        value: "TV Cr. Music Budgeting Template"
    },
    {
        label: "Reports",
        value: "Reports"
    }
]



export { selectOptions, selectTenant, selectRole, selectNewGroup }