import React from 'react';
import TabsComponent from "../../../../components/SharedComponents/Tabs/Tabs";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import ShowComposerDetails from './ShowComposerDetails';
import BasicButton from '../../../../components/SharedComponents/BasicButton/BasicButton';
import ShowComposerSeasons from './ShowComposerSeasons';
import clearTrackService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import { withUserContext } from '../../../../contexts/UserContext';

class ShowComposer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            MusicEditorTabList: [],
            associatedSeasonsDetails: []
        }
    }
    handleTabChange = (newTabValue, type) => {
        this.setState({
            [type]: newTabValue,
        });
    };
    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <ShowComposerDetails
                    fieldChanged={this.props?.fieldChanged}
                    modeType={this.props?.modeType}
                    setPostFlag={this.props?.setPostFlag}
                    handleClose={this.props?.handleClose}
                    mode={this.props?.mode}
                    show_season_id={this.props?.show_season_id}
                    show_id={this.props?.show_id}
                    season_id={this.props?.season_id}
                    people_id={this.props?.people_id}
                    notificationComponent={this.props?.notificationComponent}
                    division_id={this.props.division_id}
                    fetchData={this.props?.fetchData}
                    handleSubmit={this.props?.handleHideShowComposer}
                    {...this.props}
                />;
            case 2:
                return <ShowComposerSeasons modeType={this.props?.modeType} associatedSeasonsDetails={this.state.associatedSeasonsDetails} {...this.props} />;
        }
    };
    onLoadFetchData = (field, url) => {
        this.setState({ isLoading: true, isFetchingShowComposer: true, isFetchingMusicSupervisor: true });
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, null)
            .then(response => {

                let formattedList = [];
                switch (field) {
                    case 'getAssociatedSeasonsDetails':
                        formattedList = response?.data;
                        this.setState({ associatedSeasonsDetails: formattedList })
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false, isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
                    console.log("Error in fetching Address Types:", err)
                })
    }
    componentDidMount() {
        let MusicEditorTabList = []
        if (!this.props.showSecondtab) {
            MusicEditorTabList = [
                {
                    label: "Details",
                    value: 1,
                }
            ]
            this.setState({ MusicEditorTabList: MusicEditorTabList });
        }
        else {
            MusicEditorTabList = [
                {
                    label: "Details",
                    value: 1,
                },
                {
                    label: "ShowSeasons",
                    value: 2,
                }
            ]
            this.onLoadFetchData("getAssociatedSeasonsDetails", `/peopleDeals?people_id=${this.props?.people_id}`);
            this.setState({ MusicEditorTabList: MusicEditorTabList });
        }
    }
    render() {
        let canEditShowComposer = this.props?.commonPermissions?.find(item => item?.permission_name === "Show Composers/Music Supervisors (BA)")
           return (
           <MDBContainer fluid className="ShowComposerContainer">
                <div>
                    <MDBRow className="tab-content">
                        <MDBCol md={10}>
                            <TabsComponent
                                tabList={this.state.MusicEditorTabList}
                                value={this.state.tabValue}
                                onChange={(e, v) => this.handleTabChange(v, "tabValue")}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
                <div disabled={canEditShowComposer ? canEditShowComposer?.edit === 0 : true}>
                    {this.getTabComponent()}
                </div>
            </MDBContainer>
        )
    }
}
export default withUserContext(ShowComposer);