import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import SelectField from '../SharedComponents/SelectField/SelectField';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { withUserContext } from '../../contexts/UserContext';
import {
    AdminSetupSelectOptions,
    budgetDetailCodeConfig,
    clearanceOptionsConfig,
    costCenterConfig,
    fringeDetailConfig,
    licenseCoverConfig,
    licenseTrackingConfig,
    licenseTypesConfig,
    musicUsesConfig,
    networkConfig,
    termConfig,
    prodExecutiveConfig,
    productionGroupConfig,
    programsConfig,
    publicDomainGenreConfig,
    rapidCueMusicUseConfig,
    emailConfig,
    ccConfig,
    existingRightConfig,
    showDurationConfig,
    showSeasonConfig,
    territoryConfig,
    seasonsConfig,
} from './config';
import {
    seasonData,
    fringeDetailList,
    licenseCoverData,
    licenseTrackingData,
    licenseTypes,
    musicUsesList,
    networksList,
    prodExecutives,
    productionGroupList,
    programsList,
    publicDomainGenreList,
    rapidCueMusicUseList,
    emailList,
    ccList,
    showDurations,
    seasonsListData
} from './MockData';
import BudgetDetailCodes from './BudgetDetailCodes';
import ClearanceOptions from './ClearanceOptions';
import CostCenter from './CostCenter/CostCenter';
import FringeDetail from './FringeDetail';
import LicenseCoverLetterTypes from './LicenseCoverLetterTypes';
import LicenseTrackingActions from './LicenseTrackingActions';
import LicenseTypes from './LicenseTypes';
import MusicUses from './MusicUses';
import Networks from './Networks';
import Terms from './Terms';
import ProductionEntities from './ProductionEntities/ProductionEntities';
import ProductionExecutives from './ProductionExecutives';
import ProductionGroups from './ProductionGroups';
import Programs from './Programs';
import PublicDomainGenres from './PublicDomainGenres';
import RapidCueMusicUses from './RapidCueMusicUses';
import RestrictedPromoEmailList from './RestrictedPromoEmailList';
import ExistingRights from './ExistingRights';
import ShowDurations from './ShowDurations';
import ShowSeasonOptions from './ShowSeasonOptions';
import Territories from './Territories';
import './AdminSetup.scss';
import MessageModal from '../SharedComponents/MessageModal';
import Seasons from './Seasons';
import { CircularProgress } from "@material-ui/core";
import NotificationComponent from "../../Common/NotificationComponent/NotificationComponent"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CostAllocationTable from './CostAllocationTable/CostAllocationTable';

let permissionArrayList = []
export default withUserContext(class AdminSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOptions: AdminSetupSelectOptions,
            setCurrentSeasonDetails: null,
            selectSeasonOptions: [],
            seasonDetails: [],
            selectedSeasonOption: null,
            selectedOption: null,
            showError: false,
            showSuccess: false,
            showFail: false,
            unsavedTitle: "Unsaved Changes",
            fieldChanged: false,
            failMessage: "Saving changes failed!",
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            renderList: [],
            loadingOptions: false,
            showSuccessMessage: false,
            showFailMessage: false,
            showErrorMessage: false,
            isSaveData: false,
            budgetData: [],
            isPosting: false,
            adminSetupPermissions:[]
        }
    }

    componentDidMount() {
        document.title = 'Admin Setup'
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            this.geSetCurrentSeasonList("selectSeasonOptions", "SEASON", null);
            let adminSetupPermissionsRecord = this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_group_name === "Admin Setup")
            this.setState({ adminSetupPermissions: adminSetupPermissionsRecord})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props?.userContext?.active_tenant?.tenant_id !== prevProps?.userContext?.active_tenant?.tenant_id) {
            this.geSetCurrentSeasonList("selectSeasonOptions", "SEASON", null);
        }
    }

    geSetCurrentSeasonList = (entity, entitytype, searchString, loader = true) => {
        this.setState({ isLoading: loader });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then((res) => {
                let formattedList = res?.data?.map(item => ({ value: item.season_id, label: item.season_name }));
                this.setState({ selectSeasonOptions: formattedList, seasonDetails: res.data, isSaveData: false, isLoading: false, setCurrentSeasonDetails: res?.data?.find(ele => ele?.is_current === 1) });
            })

            .catch((err) => {
                this.setState({ isLoading: false });
            });

    };

    handleSubmit = () => {
        this.setState({ isSubmit: true });
        let newData = this.state.seasonDetails?.find(item => item.season_id === this.state.setCurrentSeasonDetails?.season_id)
        newData.is_current = 1
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/season`,
            newData,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(
                (response) => {
                    if (response.data.error) {
                        this.notificationComponent(true, "fail");
                    } else {
                        this.notificationComponent(true, "success")
                        this.geSetCurrentSeasonList("selectSeasonOptions", "SEASON", null);
                    }
                    this.setState({ isSubmit: false });
                },
                (err) => {
                    this.setState({ isSubmit: false });
                    this.notificationComponent(true, "fail");
                }
            )
    }

    onSelectChange(e) {
        this.setState({ selectedOption: e.target.value })
        if (['Fringe Detail', 'License Tracking Actions', 'License Types', 'Production Groups', 'Networks',
            'Public Domain Genres', 'RapidCue Music Uses'].includes(e.target.value)) {
            this.getStaticData(e.target.value);
        }
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    closePopup = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        }
        this.setState({ showError: false, showFail: false, showSuccess: false });
    }

    getStaticData = (name, loader = true) => {
        this.setState({ loadingOptions: loader })
        let tableName = (name === "Fringe Detail" ? "lu_fringe_detail" : name === "License Tracking Actions" ? "lu_license_tracking_actions" :
            name === "License Types" ? "lu_license_type" : name === "Production Groups" ? "lu_production_group" :
                name === "Networks" ? "lu_network" : name === "Public Domain Genres" ? "lu_public_domain_genre" :
                    name === "RapidCue Music Uses" ? "lu_rapid_cue_music" : name);
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/staticData?staticTable=${tableName}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (tableName === 'lu_fringe_detail') {
                    let formattedList = response?.data?.map(item => ({ detail: item.name, id: item.id, is_active: item.is_active }));
                    this.setState({ renderList: formattedList })
                }
                else if (tableName === 'lu_license_tracking_actions') {
                    let formattedList = response?.data?.map(item => ({ action: item.name, id: item.id, is_active: item.is_active }));
                    this.setState({ renderList: formattedList })
                } else if (tableName === 'lu_license_type') {
                    let formattedList = response?.data?.map(item => ({ license_type: item.name, id: item.id, is_active: item.is_active }));
                    this.setState({ renderList: formattedList })
                }
                else if (tableName === 'lu_production_group') {
                    let formattedList = response?.data?.map(item => ({ production_group: item.name, id: item.id, is_active: item.is_active }));
                    this.setState({ renderList: formattedList })
                }
                else if (tableName === 'lu_network') {
                    let formattedList = response?.data?.map(item => ({ network: item.name, id: item.id, is_active: item.is_active }));
                    this.setState({ renderList: formattedList })
                }
                else if (tableName === 'lu_public_domain_genre') {
                    let formattedList = response?.data?.map(item => ({ public_domain_genre: item.name, id: item.id, is_active: item.is_active }));
                    this.setState({ renderList: formattedList })
                }
                else if (tableName === 'lu_rapid_cue_music') {
                    let formattedList = response?.data?.map(item => ({ music_use: item.name, id: item.id, is_active: item.is_active }));
                    this.setState({ renderList: formattedList })
                }
                this.setState({ loadingOptions: false, isSaveData: false })
            },
                (err) => {
                    console.log("Error in fetching Static Data:", err)
                    this.setState({ loadingOptions: false, isSaveData: false })
                })
    }

    handleSave = (list, tableName, postFlag) => {
        let queryParam = {
            table_name: tableName,
            id: list.id,
            name: list.name,
            is_active: list.is_active
        }
        this.setState({ isSaveData: postFlag })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/staticData`, queryParam, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isSaveData: false })
                    console.log("Error in saving Static Data:", response.data.error)
                    this.notificationComponent(true, "fail")
                }
                else {
                    this.getStaticData(tableName, false)
                    // this.setState({isSaveData:false})
                    this.notificationComponent(true, "success")
                }
            }, (err) => {
                this.setState({ isSaveData: false })
                this.notificationComponent(true, "fail")
                console.log("Post details error: " + err);
            }
            )
    }

    reportData = (data) => {
        this.setState({
            budgetData : data
        })
    }



    getComponent = () => {
        if(this.state.selectedOption){
          permissionArrayList = this.state.adminSetupPermissions?.find((item)=>item.permission_name ===  this.state.selectOptions.find((item)=>item.value === this.state?.selectedOption).permissionName)
        }
        switch (this.state.selectedOption) {
            case 'Budget Detail Codes': return <BudgetDetailCodes
                config={budgetDetailCodeConfig}
                toastMessageNotification={this.notificationComponent}
                reportGeneration={this.state.reportGeneration}
                reportData={this.reportData}
                permissionArray = {permissionArrayList}
            />;
            case 'Clearance Options': return <ClearanceOptions
                config={clearanceOptionsConfig}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Cost Center': return <CostCenter
                config={costCenterConfig}
                seasonList={seasonData}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Fringe Detail': return <FringeDetail
                config={fringeDetailConfig}
                list={this.state.renderList}
                handleSave={this.handleSave}
                loadingOptions={this.state.loadingOptions}
                isSaveData={this.state.isSaveData}
                permissionArray = {permissionArrayList}
            />
            case 'License Cover Letter Types': return <LicenseCoverLetterTypes
                toastMessageNotification={this.notificationComponent}
                config={licenseCoverConfig}
                list={licenseCoverData}
                permissionArray = {permissionArrayList}
            />
            case 'License Tracking Actions': return <LicenseTrackingActions
                config={licenseTrackingConfig}
                list={this.state.renderList}
                loadingOptions={this.state.loadingOptions}
                handleSave={this.handleSave}
                isSaveData={this.state.isSaveData}
                permissionArray = {permissionArrayList}
            />
            case 'License Types': return <LicenseTypes
                config={licenseTypesConfig}
                list={this.state.renderList}
                loadingOptions={this.state.loadingOptions}
                handleSave={this.handleSave}
                isSaveData={this.state.isSaveData}
                permissionArray = {permissionArrayList}
            />
            case 'Music Uses': return <MusicUses
                toastMessageNotification={this.notificationComponent}
                config={musicUsesConfig}
                list={musicUsesList}
                permissionArray = {permissionArrayList}
            />
            case 'Networks': return <Networks
                config={networkConfig}
                list={this.state.renderList}
                loadingOptions={this.state.loadingOptions}
                handleSave={this.handleSave}
                isSaveData={this.state.isSaveData}
                permissionArray = {permissionArrayList}
            />
            case 'Terms': return <Terms
                config={termConfig}
                list={this.state.renderList}
                loadingOptions={this.state.loadingOptions}
                handleSave={this.handleSave}
                isSaveData={this.state.isSaveData}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Production Entities': return <ProductionEntities
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Production Executives': return <ProductionExecutives
                toastMessageNotification={this.notificationComponent}
                config={prodExecutiveConfig}
                list={prodExecutives}
                permissionArray = {permissionArrayList}
            />
            case 'Production Groups': return <ProductionGroups
                loadingOptions={this.state.loadingOptions}
                handleSave={this.handleSave}
                isSaveData={this.state.isSaveData}
                toastMessageNotification={this.notificationComponent}
                userContext= {this.props?.userContext}
                permissionArray = {permissionArrayList}
            />
            case 'Programs': return <Programs
                toastMessageNotification={this.notificationComponent}
                config={programsConfig}
                list={programsList}
                permissionArray = {permissionArrayList}
            />
            case 'Public Domain Genres': return <PublicDomainGenres
                config={publicDomainGenreConfig}
                list={this.state.renderList}
                loadingOptions={this.state.loadingOptions}
                handleSave={this.handleSave}
                isSaveData={this.state.isSaveData}
                permissionArray = {permissionArrayList}
            />
            case 'RapidCue Music Uses': return <RapidCueMusicUses
                config={rapidCueMusicUseConfig}
                list={this.state.renderList}
                loadingOptions={this.state.loadingOptions}
                handleSave={this.handleSave}
                isSaveData={this.state.isSaveData}
                permissionArray = {permissionArrayList}
            />
            case 'Restricted Promo Email Lists': return <RestrictedPromoEmailList
                emailConfig={emailConfig}
                ccConfig={ccConfig}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            // emailList={this.state.emailList}
            // ccList={this.state.ccList}
            // networkOptions={this.state.networkOptions}
            // loadingOptions = {this.state.loadingOptions}
            //handleNetworkEmails = {this.handleNetworkEmails}
            //handleSave={this.handleSave}
            // isSaveData={this.state.isSaveData}
            />
            case 'Existing Rights': return <ExistingRights
                config={existingRightConfig}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Seasons': return <Seasons
                config={seasonsConfig}
                list={seasonsListData}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Show Durations': return <ShowDurations
                toastMessageNotification={this.notificationComponent}
                config={showDurationConfig}
                list={showDurations}
                permissionArray = {permissionArrayList}
            />
            case 'Show Season Options': return <ShowSeasonOptions
                config={showSeasonConfig}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Territories': return <Territories
                config={territoryConfig}
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
            />
            case 'Cost Allocation Table': return <CostAllocationTable
                toastMessageNotification={this.notificationComponent}
                permissionArray = {permissionArrayList}
                userContext = {this.props?.userContext}
            />
        }
    }
    closePopup = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        }
        this.setState({ showError: false, showFail: false, showSuccess: false });
    }
    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccessMessage: value });
            case "fail": return this.setState({ showFailMessage: value });
        }
    }
    handleResetNotify = () => {
        this.setState({ showFailMessage: false, showSuccessMessage: false });
        if (this.state.saveFlag) {
            this.closeToastMessage();
        }
    }
    closeToastMessage = () => {
        this.setState({ showErrorMessage: false, showFailMessage: false, showSuccessMessage: false });
        if (this.state.saveFlag) {
            this.props?.handleClose();
        }
    }


    generatePDF = () => {
        this.setState({ isPosting : true })
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        //doc.setFontSize(15);
        const title = "Budget Detail Codes";
        const headers = [["Detail", "Description", "Secondary Description", "Clearance", "Clearance MKT", "Clearance MT"]];
        const data = this.state.budgetData.map(item => [item.detail_code, item.description, item.secondary_description, item.is_clearance, item.is_clearance_MKT, item.is_clearance_MT]);
        let content = {
            startY: 50,
            head: headers,
            body: data
        };
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("BudgetDetailCodes.pdf")
        this.setState({ isPosting : false })
    }



    render() {
        let adminSetupList = this.state.selectOptions.filter(item => this.state.adminSetupPermissions?.find(obj => obj.permission_name === item.permissionName && obj.view === 1))
        let permissionValue = permissionArrayList
        let severity = this.state.showFailMessage ? "error" : this.state.showSuccessMessage ? "success" : "";
        let messageNotification = this.state.showSuccessMessage ? "Saved Changes Successfully" : this.state.showFailMessage ? "Saved Changes Failed" : "";
        //success modal popup
        let message = this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveEditMessage : null
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        return (
            <MDBContainer fluid className='AdminSetupContainer'>
                <div>
                    <NotificationComponent open={this.state.showSuccessMessage || this.state.showFailMessage} message={messageNotification} severity={severity}
                        handleResetNotify={this.handleResetNotify.bind(this)} />
                </div>
                <MessageModal
                    open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                    title={comfirmModalTitle}
                    message={message}
                    hideCancel={this.state.showFail || this.state.showSuccess || false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.closePopup()}
                    handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                <MDBRow>
                    <MDBCol md={12} className="headingStyles">
                        <BasicLabel text="Admin Setup" type="heading" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={11} className="lookupType">
                        <SelectField
                            options={adminSetupList}
                            placeHolderText="- Select Lookup Type -"
                            onChange={(e) => this.onSelectChange(e)}
                            value={this.state?.selectedOption}
                        />
                    </MDBCol>
                    {this.state.selectedOption === 'Budget Detail Codes' ?
                        <MDBCol>
                            <BasicButton
                                variant="contained"
                                text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Report"}
                                onClick={this.generatePDF}
                            />
                        </MDBCol> : null
                    }
                </MDBRow>
                {this.state.selectedOption === "Set Current Season"  ?
                    <>
                        <MDBRow className="seasonMargin">
                            <span className="description">
                                Set the most recent system Season. When copying Show Seasons, this season will be used as a target.
                            </span>
                        </MDBRow>
                        <MDBRow className="seasonMargin">
                            <MDBCol md={3} className="season-select">
                                <SelectField
                                    options={this.state?.selectSeasonOptions}
                                    placeHolderText="- Select Season -"
                                    onChange={(e) => {
                                        this.setState({ setCurrentSeasonDetails: this.state?.seasonDetails?.find(item => item?.season_id === e.target.value) })
                                    }}
                                    value={this.state?.setCurrentSeasonDetails?.season_id}

                                />
                            </MDBCol>
                            <MDBCol className="set-btn">
                                {this.state.adminSetupPermissions[0]?.edit === 1 &&
                                <BasicButton
                                    variant="contained"
                                    text={this.state.isSubmit ? <CircularProgress color="inherit" size={18} /> :
                                        "Set"}
                                    onClick={(e) => this.handleSubmit()}
                                />}
                            </MDBCol>
                        </MDBRow>
                    </>
                    : this.getComponent()}

            </MDBContainer>
        )
    }
})
