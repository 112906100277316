import React, { Component } from 'react';
import { MDBContainer, MDBRow } from "mdbreact";
//import TableComponent from '../../SharedComponents/Table/Table';
import TableComponent from '../../SharedComponents/Table'
import { ClRecordList,AllCLRecords } from '../Licensor/Config'
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import './Licensor.scss'; 

class CLRecords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 20,
            page: 0,
            renderList: handlePaginationList(0, 20,this.props?.clearanceRecordList),
            config: ClRecordList,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Value",
        }
    }
    
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage,this.props?.clearanceRecordList ) });
    }
    navigationCallback = (item, node) => {
        if (node === 'show_name' || node === "season_name") {
            window.open(`/showSeason/${item.show_season_id}`, '_blank');
        } else if (node === 'episode') {
            window.open(`/episodeAndClearance/ss/${item?.show_season_id}/ep/${item?.show_season_episode_id}`);
        }
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.props?.clearanceRecordList)
        });
    }
    
    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                newItem['editing'] = true
                return newItem
            })
        })
    }
    saveInlineEdits = (data, index, pkValue, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                item['editing'] = false
                return item
            })
        })
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(ClRecordList, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: handlePaginationList(this.state.page, this.state.rowsPerPage,
                    tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc))
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Program": return "show_name";
            case "Season": return "season_name";
            case "Episode #": return "episode";
            case "Composition": return "song_title";
            case "Use": return "use";
            case "Status": return "status";
            case "Rights": return "rights";
            case "Lic. Fee": return "lic_fee";
            case "Song Fee": return "song_fee";
        }
    }

    render() {
        return (
            <MDBContainer fluid className="ModalTableContainer">
                <MDBRow className='g-0 licensorCLRecords'>
                    <TableComponent
                        list={this.state.renderList || []}
                        className={"agentList"}
                        config={this.state.config}
                        stickyHeader={true}
                        isLoading={this.props.isLoading || false}
                        hyperLinkNavigationCallback={(item, node) => this.navigationCallback(item, node)}
                        sortCallback={this.handleTableColumnClick} 
                        />
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.renderList ? this.props?.clearanceRecordList?.length : 0}
                        rowsPerPageOptions={[20,30,50,100]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </MDBRow>
            </MDBContainer>
        );
    }

    

}

export default CLRecords;