import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import BasicTextField from "../../../SharedComponents/BasicTextField/BasicTextField";
import BasicTextArea from "../../../SharedComponents/BasicTextArea/BasicTextArea";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import { personalDetails, addressConfig } from "./config";
import ClearTrackServices from "../../../../services/service";
import * as Constants from "../../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { validateEmail, validateAllPhone, formatPhoneNumbers } from '../../../../Common/Helper';
import messages from '../../../../Common/Messages.json';
import AddressComponent from "../../../EpisodeAndClearence/CommonAddressComponent";
import { withUserContext } from '../../../../contexts/UserContext';
import '../../People/People.scss';
let invalidValuesField = [];
class PersonModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { details: personalDetails, isSubmit: false, postInitiated: false };
  }
  componentDidMount() {
    if (this.props.mode === "Edit") {
      this.setState({ isLoading: true });
      let division_id, show_season_people_id, people_id;
      if (this.props.origin === "profile" || this.props?.origin === "dashboard") {
        division_id = null;
        people_id = this.props?.people_id;
        show_season_people_id = null;
      }
      else {
        division_id = this.props?.division_id;
        people_id = null;
        show_season_people_id = this.props?.show_season_people_id;
      }
      this.fetchData("peopleDetails", `/people?show_season_people_id=${show_season_people_id}&people_id=${people_id}`, "onLoadFetch");
    } else {
      this.setState({ isLoading: false, details: personalDetails });
    }
  }
  validateBeforeSubmit=(personDetailsData)=>{
  let canSubmit = false
    canSubmit = 
    personDetailsData?.full_name && personDetailsData?.full_name?.trim()?.length > 0 &&
      (personDetailsData?.email ? validateEmail(personDetailsData?.email) : true) &&
    (personDetailsData?.cell_phone ? validateAllPhone(personDetailsData?.cell_phone) : true) &&
    (personDetailsData?.work_phone ? validateAllPhone(personDetailsData?.work_phone) : true)
    
    return canSubmit
  }
  handleSubmit = () => {
    let personDetailsData = { ...this.state.details };
    this.setState({ postInitiated: true });
    if (this.validateBeforeSubmit(personDetailsData)) {
      this.setState({ isSubmit: true });
      if (this.props?.modeType === "Music Supervisor") {
        personDetailsData.people_type = "MUSIC_SUPERVISOR";
      }
      if (this.props?.modeType === "Show Composer") {
        personDetailsData.people_type = "SHOW_COMPOSER";
      }
      if (this.props?.modeType === "Music Editor") {
        personDetailsData.people_type = "MUSIC_EDITOR";
      }
      let division_id, people_id, show_season_people_id;
      if (this.props?.mode === "Edit") {
        personDetailsData.show_season_people_id = this.props?.show_season_people_id;
        if (this.props.origin === "profile" || this.props?.origin === "dashboard") {
          division_id = null;
          personDetailsData.people_id = this.props?.people_id;
          personDetailsData.is_profile = 1;
          personDetailsData.show_season_people_id = null;
        }
        else {
          division_id = this.props?.division_id;
          personDetailsData.people_id = null;
          personDetailsData.show_season_people_id = this.props?.show_season_people_id;
        }
      }
      if (this.props?.mode === "New") {
        division_id = this.props?.division_id;
        personDetailsData.people_id = null;
        personDetailsData.show_season_id = this.props?.show_season_id ? this.props.show_season_id : null;
        personDetailsData.show_id = this.props?.show_id ? this.props.show_id : null;
        personDetailsData.season_id = this.props?.season_id ? this.props.season_id : null;
        personDetailsData.division_id = this.props?.division_id ? this.props.division_id : null;
        personDetailsData.is_dashboard = this.props?.is_dashboard ? this.props?.is_dashboard : null
      }
      let personDetailsKeyArr = Object.keys(personDetailsData).filter(item => typeof item === "string");
      personDetailsKeyArr.map(item => personDetailsData[item] === "" ? personDetailsData[item] = null : null);
      if (personDetailsData?.full_name) {
        ClearTrackServices.postDataParams(
          Constants.ClearTrackServiceBaseUrl + `/people`,
          personDetailsData,
          this.props.userContext?.active_tenant?.tenant_id, division_id
        ).then(
          (response) => {
            if (response.data.error) {
              this.setState({ isSubmit: false });
              this.props?.notificationComponent(true, "fail");
            } else {
              this.setState({ isSubmit: false });
              let people_id, show_season_people_id;
              if (this.props.fetchData) {
                // if (this.props.origin === "profile") {
                //   division_id = null;
                //   people_id = this.props?.people_id;
                //   show_season_people_id = null;
                // }
                // else {
                //   division_id = this.props?.division_id;
                //   people_id = this.props?.people_id;
                //   show_season_people_id = this.props?.show_season_people_id;
                // }
                // this.props.fetchData("peopleDetails", `/people?show_season_people_id=${show_season_people_id}&people_id=${people_id}`, "onLoadFetch");
                this.props.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.props.show_season_id}`, "editing");
              }
              this.props?.notificationComponent(true, "success");
            }
          },
          (err) => {
            this.setState({ postInitiated: false, isSubmit: false });
            this.props?.notificationComponent(true, "fail");
            console.log("Post details error: " + err);
          }
        );
      
      }
    } 
  };

  handleChange = (field, value) => {
    this.setState(prevState => ({
      details: {
        ...prevState.details,
        [field]: value
      },
      postInitiated: false
    }))
  };

  fetchData(field, url, onLoadFetch) {
    if (onLoadFetch !== "onLoadFetch") {
      this.setState({ isLoading: false });
    }
    this.setState({ isLoading: true });
    let division_id;
    if (this.props.origin === "profile" || this.props?.origin === "dashboard") {
      division_id = null;
    }
    else {
      division_id = this.props?.division_id;
    }
    ClearTrackServices.getData(
      Constants.ClearTrackServiceBaseUrl + url,
      this.props.userContext?.active_tenant?.tenant_id, division_id
    )
      .then((res) => {
        this.setState({ isLoading: false, details: res?.data[0] });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("err--");
      });
  }

  render() {
    return (
      <>
        <MDBContainer className="Container">
          {this.state.isLoading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress size={24} />
            </div>
          ) : (<>
            <MDBRow>
              <MDBCol md={4} className="person-container">
                <BasicTextField
                  label={"Full Name"}
                  isMandatory={true}
                  showMandatory={this.state.postInitiated || false}
                  mandatoryWarning={messages.mandatoryWarning}
                  value={this.state.details?.full_name}
                  onChange={(e) => this.handleChange("full_name", e.target.value.trim().length === 0 ? null : e.target.value)}
                />
              </MDBCol>
              <MDBCol md={4} className="person-container">
                <BasicTextField
                  label={"Company"}
                  value={this.state.details?.company}
                  onChange={(e) => this.handleChange("company", e.target.value)}
                />
              </MDBCol>
              <MDBCol md={4}></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={8}>
                <MDBRow>
                  <MDBCol md={6}>
                    <BasicTextField
                      label={"Email"}
                      value={this.state.details?.email || null}
                      fieldValid={this.state.details?.email ? (!validateEmail(this.state.details?.email) ? true : false) : false}
                      onChange={(e) => this.handleChange("email", e.target.value)}
                      inValidInput={messages.invalidInput}
                    />
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicTextField
                      label={"Work Phone"}
                      value={this.state.details?.work_phone}
                      //fieldValid={this.state.details?.work_phone ? (!validateAllPhone(this.state.details?.work_phone) ? true : false) : false}
                      onChange={(e) =>
                        this.handleChange("work_phone", formatPhoneNumbers(e.target.value))
                      }
                      //inValidInput={messages.invalidInput}
                    />
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicTextField
                      label={"Cell Phone"}
                      value={this.state.details?.cell_phone}
                      //fieldValid={this.state.details?.cell_phone ? (!validateAllPhone(this.state.details?.cell_phone) ? true : false) : false}
                      onChange={(e) =>
                        this.handleChange("cell_phone", formatPhoneNumbers(e.target.value))
                      }
                      //inValidInput={messages.invalidInput}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={12}>
                    <AddressComponent
                      editCallback={this.handleChange}
                      addressConfig={addressConfig}
                      addressDetails={this.state.details}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md={4}>
                <BasicTextArea
                  label={"Notes"}
                  id={"notes"}
                  rows={3}
                  value={this.state.details?.notes}
                  onChange={(e) => this.handleChange("notes", e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={9}></MDBCol>
              <MDBCol md={3} className="saveCancelWrapper">
                <BasicButton
                  className="btnwidth"
                  variant="contained"
                  type="inline"
                  text={
                    this.state.isSubmit ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : ("Save")
                  }
                  onClick={this.handleSubmit}
                  disabled={this.props?.peopleModalPopupPermissions?.edit === 1 ? false : true}
                />
                <BasicButton
                  onClick={() => this.props.handleClose()}
                  variant="outlined"
                  text={"Cancel"}
                />
              </MDBCol>
            </MDBRow>
          </>)}
        </MDBContainer>
      </>
    );
  }
}
export default withUserContext(PersonModal);