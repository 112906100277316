import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import { withUserContext } from "../../../contexts/UserContext";
import { InputAdornment } from "@material-ui/core";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import "./BudgetingSetupModal.scss";
import TableComponent from "../../SharedComponents/Table";
import { BudgetingSetupConfig } from "./Config";
import ClearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { validateNumDotOnly } from "../../../Common/Helper";

class BudgetingSetupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      budgetingSteupDetails: {},
      renderList: [],
      isLoading: false,
      isSubmit: false,
      isEpisodeLoading: false,
    };
  }
  componentDidMount() {
    this.getBudgetSetupList();
    this.getBudgetEpisodeList();
    this.fetchData(
      "ms_cost_center_id",
      `/entitySearch?entity=COST_CENTER&searchString=SHOW_COMPOSER`
    );
    this.fetchData(
      "sc_cost_center_id",
      `/entitySearch?entity=COST_CENTER&searchString=MUSIC_SUPERVISOR`
    );
  }
  fetchData = (entity, url) => {
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + url,
      this.props.userContext?.active_tenant?.tenant_id,
      this.props?.divisionId
    )
      .then((response) => {
        this.setState({ [entity]: response.data[0].cost_center_id });
      })
      .catch((err) => {
        console.log("error");
      });
  };
  getBudgetSetupList = () => {
    this.setState({ isLoading: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/showSeasonSCMSBudget?show_season_id=${this.props?.showSeasonId}`,
      this.props.userContext?.active_tenant?.tenant_id,
      this.props?.divisionId
    ).then(
      (response) => {
        this.setState({
          isLoading: false,
          dataList: response.data[0],
          budgetingSteupDetails: {
            ...this.state.budgetingSteupDetails,
            ms_cost_center_id:
              response.data[0]?.show_season_sc_ms_budget[0]?.cost_center_id,
            musicSupervisor:
              response.data[0]?.show_season_sc_ms_budget[0]?.budget,
            sc_cost_center_id:
              response.data[0]?.show_season_sc_ms_budget[1]?.cost_center_id,
            showComposers:
              response.data[0]?.show_season_sc_ms_budget[1]?.budget,
          },
        });
      },
      (err) => {
        console.log("Error in fetching Tenant Details:", err);
      }
    );
  };
  getBudgetEpisodeList = () => {
    this.setState({ isEpisodeLoading: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/showSeasonEpisodes?show_season_id=${this.props?.showSeasonId}`,
      this.props.userContext?.active_tenant?.tenant_id,
      this.props?.divisionId
    ).then(
      (response) => {
        let formatData = response.data?.map((item) => ({
          ms_budget_cost: item?.ms_budget_cost,
          editing: true,
          sc_budget_cost: item?.sc_budget_cost,
          show_season_episode_id: item?.show_season_episode_id,
          episode_number: item?.episode_number,
          code: (item?.episode_number ? item?.episode_number : '')+(item?.part_number?'.'+item?.part_number:''),
        }));
        this.setState({
          isEpisodeLoading: false,
          renderList: formatData,
        });
      },
      (err) => {
        console.log("Error in fetching Tenant Details:", err);
      }
    );
  };
  handleChange = (field, value) => {
    this.setState({
      budgetingSteupDetails: {
        ...this.state.budgetingSteupDetails,
        [field]: value,
      },
    });
    if (field === "musicSupervisor") {
      let formatMSData = [...this.state.renderList]?.map((item) => ({
        ms_budget_cost: value ? value : 0,
        editing: true,
        sc_budget_cost: item?.sc_budget_cost,
        show_season_episode_id: item?.show_season_episode_id,
        episode_number: item?.episode_number,
        code: item?.code,
      }));
      this.setState({ renderList: formatMSData });
    }
    if (field === "showComposers") {
      let formatSCData = [...this.state.renderList]?.map((item) => ({
        sc_budget_cost: value ? value : 0,
        editing: true,
        ms_budget_cost: item?.ms_budget_cost,
        show_season_episode_id: item?.show_season_episode_id,
        episode_number: item?.episode_number,
        code: item?.code,
      }));
      this.setState({ renderList: formatSCData });
    }
  };
  handleSubmit = () => {
    let budgetDataList = { ...this.state.dataList };
    budgetDataList = {
      show_season_id: this.props?.showSeasonId,
      season_id: this.props?.seasonId,
      show_id: this.props?.showId,
      is_active: 1,
      show_season_sc_ms_budget: [
        {
          cost_center_id: this.state.budgetingSteupDetails?.ms_cost_center_id
            ? this.state.budgetingSteupDetails?.ms_cost_center_id
            : this.state.ms_cost_center_id,
          cost_center_name: "Music Supervisor",
          budget: this.state.budgetingSteupDetails?.musicSupervisor,
        },
        {
          cost_center_id: this.state.budgetingSteupDetails?.sc_cost_center_id
            ? this.state.budgetingSteupDetails?.sc_cost_center_id
            : this.state.sc_cost_center_id,
          cost_center_name: "Show Composer",
          budget: this.state.budgetingSteupDetails?.showComposers,
        },
      ],
      show_season_episode: this.state.renderList,
    };
   let validateBudgetList= budgetDataList?.show_season_episode?.filter(item => ((item?.ms_budget_cost !== null && validateNumDotOnly(item.ms_budget_cost) === false) || (item.sc_budget_cost !== null && validateNumDotOnly(item.sc_budget_cost) === false)))
     
    if (validateBudgetList?.length === 0) {
      this.setState({ isSubmit: true });
      ClearTrackService.postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/showSeasonSCMSBudget`,
        budgetDataList,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props?.divisionId
      )
        .then((res) => {
          this.props?.notificationComponent(true, "success");
          this.setState({ isSubmit: false });
        })
        .catch((err) => {
          this.setState({ isSubmit: false });
          this.props?.notificationComponent(true, "fail");
        });
    }
  }; 
  updateOptCheckRequest = (updatedList, index, nodeName) => {
    updatedList[index].ms_budget_cost = updatedList[index].ms_budget_cost;
    updatedList[index].sc_budget_cost = updatedList[index].sc_budget_cost;
    updatedList[index].show_season_episode_id =
      updatedList[index].show_season_episode_id;
    this.setState({ renderList: updatedList });
  };
  render() {
    return (
      <MDBContainer fluid className="BudgetingSetupContainer">
        <div className="content">
          Budget values here will default in the Check Amount field in the Check
          Requests grid.
        </div>

        <MDBRow>
          <MDBCol md={6}>
            <BasicLabel text="Music Supervisors" />
            <BasicTextField
              id="musicSupervisor"
              placeholder="Music Supervisors"
              value={this.state.budgetingSteupDetails?.musicSupervisor || ""}
              onChange={(e) =>
                validateNumDotOnly(e.target.value)
                  ? this.handleChange("musicSupervisor", e.target.value)
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" size="small">
                    {"$"}
                  </InputAdornment>
                ),
              }}
            />
          </MDBCol>
          <MDBCol md={6}>
            <BasicLabel text="Show Composers" />
            <BasicTextField
              id="showComposers"
              placeholder="Show Composers"
              value={this.state.budgetingSteupDetails?.showComposers || ""}
              onChange={(e) =>
                validateNumDotOnly(e.target.value)
                  ? this.handleChange("showComposers", e.target.value)
                  : null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" size="small">
                    {"$"}
                  </InputAdornment>
                ),
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <div id="budgetingSetupTable">
            <TableComponent
              list={this.state.renderList || []}
              config={BudgetingSetupConfig}
              stickyHeader={true}
              mode={"edit"}
              idForFocus="budget"
              isLoading={this.state.isEpisodeLoading}
              updateOptCheckRequest={this.updateOptCheckRequest.bind(this)}
            />
          </div>
        </MDBRow>
        <MDBRow>
          <MDBCol md={8}></MDBCol>
          <MDBCol md={2}>
            <BasicButton
              text={
                this.state.isSubmit ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  "Save"
                )
              }
              onClick={this.handleSubmit}
            />
          </MDBCol>
          <MDBCol md={2}>
            <BasicButton
              variant="outlined"
              text={"Cancel"}
              onClick={this.props?.handleClose}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default withUserContext(BudgetingSetupModal);
