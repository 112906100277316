import React from 'react';
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import '../NewUserPopUp/CreateOrEditUserModal.scss';
import { mockSeasons, userLimits, getJSON, showSeasonConfig, mockTenant1, mockDivsions, levelOptions } from './mock';
import BasicCheckbox from '../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import ClearTrackService from '../../services/service';
import * as Constants from '../../constants/constants';
import MessageModal from '../../components/SharedComponents/MessageModal';
import messages from '../../Common/Messages.json';
import { validateEmail, validatePlusCharacter, validateAllPhone, formatPhoneNumbers } from '../../Common/Helper';
import CircularProgress from "@material-ui/core/CircularProgress";
import CancelIcon from '@material-ui/icons/Cancel';
import { withUserContext } from '../../contexts/UserContext';
import { UserPopUpTabList } from '../../constants/TabList';
import Tabs from '../../components/SharedComponents/Tabs/Tabs';
import sizeLimits from '../../Common/SizeLimits.json';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import TableComponent from '../../components/SharedComponents/Table';
class CreateOrEditUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusValue: 1,
            // userDetails: this.formatUserDetails(JSON.parse(JSON.stringify({ ...getJSON }))),
            userDetails: {},
            isFetchingUserDetails: false,
            divisionOptions: [],
            roleOptions: [],
            levelOptions: [],
            tenantOptions: [],
            showOptions: [],
            userOptions: [],
            seasonOptions: [],
            fullName: "",
            accountEmail: "",
            phone: "",
            fieldValid: true,
            postInitiated: false,
            fieldChanged: false,
            showSuccess: false,
            showFail: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            isPosting: false,
            tabValue: 1,
            show: [],
            seasonsOptions: {},
            searchCancelToken: null,
            showSuccess: false,
            showFail: false,
            saveFlag: false,
            emailList: this.props?.emailList,
            existingEmail: false,
            config: showSeasonConfig,
            updatedListEdits: [],
            urmIndex: 0,
            isFetchingSeason: false,
            showSeasonExists: false,
            showHasAllAccess: false,
            tabList: UserPopUpTabList,
            mainError: false
        }
    }

    componentDidMount() {
        this.getUserRoles();
        this.getTenants();
        this.getEntity("userOptions", "USER", "")
        this.getEntity("homepageOptions", "HOME_PAGE_OPTIONS", "")
        // this.getEntity("showOptions", "SHOW_DIVISION", "");
        // if (this.props?.id > 0) {
        //     // this.getEntity("showOptions", "SHOW", "");
        //     this.getEditUser(this.props?.id);
        // }
    }

    // getNewSeasonsArray (id) {
    //     ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${id}&all=1`, 1)
    //         .then(response => {
    //             let formattedList = [];
    //             formattedList = response.data[0]?.seasons?.map(item => ({ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }));
    //             return formattedList;
    //         })
    // }

    formatUserDetails = (response) => {
        if (response?.tenants?.length === 0) { // adding mock tenant
            response?.tenants.push(JSON.parse(JSON.stringify({ ...mockTenant1 })));
            response?.tenants?.map((tenant, index) => {
                if (tenant?.tenant_id === null && tenant?.tenant_name === null) {
                    tenant["tenant_id"] = this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value || null
                    tenant["tenant_name"] = this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.label || null
                }
            })
        }
        if (this.props.title === "Edit User") {
            response?.tenants?.map((tenant, index) => {
                if (tenant?.tenant_id === null && tenant?.tenant_name === null) {
                    tenant["tenant_id"] = this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value || null
                    tenant["tenant_name"] = this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.label || null
                }
            })
            response?.tenants?.map((tenant, index) => tenant?.division_role.map((division, divisionIndex) => division["security_level_options"] = []))
            response?.tenants?.map((tenant, index) => tenant?.division_role.map((division, divisionIndex) => division.security_level_options.push(JSON.parse(JSON.stringify({ ...levelOptions })))))
            response.show_season?.length > 0 ?
                response.show_season?.map((item, index) => {
                    let searchSelectOptions = [];
                    searchSelectOptions = [{ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }]
                    item["searchSelectOptions"] = searchSelectOptions;
                    item["edit"] = true;
                    return item;
                })
                : null
            if (response.show_season?.length > 0) {
                response["is_all_shows"] = 0
            } else {
                response["is_all_shows"] = 1
            }
            if (response.is_user_provisioner === 1) {
                let tabList = [...this.state.tabList];
                let newTabList = tabList.filter(item => item.value !== 3)
                this.setState({ tabList: newTabList })
            }
            // response?.tenants?.map((tenant, tenantIndex) => tenant?.division_role.map((division, divisionIndex) => division.show_season.map((show, showIndex) => show["show_season_options"] = [])))
            // response?.tenants?.map((tenant, index) => tenant?.division_role.map((division, divisionIndex) => division.show_season.map((show, showIndex) => show.show_season_options.push(JSON.parse(JSON.stringify({ ...showOptions }))))))
            // response?.tenants?.map((tenant, tenantIndex) => tenant?.division_role.map((division, divisionIndex) => division.show_season.map((show, showIndex) => show.show_id > 0 ?
            //     this.getSeasons(show.show_id, tenantIndex, divisionIndex, showIndex) : null)))
        }
        if (response?.tenants?.map((tenant, index) => tenant?.division_role?.length === 0 ?
            response?.tenants[index].division_role.push(JSON.parse(JSON.stringify({ ...mockDivsions }))) : null)) // adding mock division
            if (response?.tenants?.map((tenant, tenantIndex) => tenant?.division_role?.map((division, divisionIndex) => division.security_level_options?.length === 0 ?
                response.tenants[tenantIndex].division_role[divisionIndex].security_level_options.push(JSON.parse(JSON.stringify({ ...levelOptions }))) : null))) //including secrurity level options to user details []
                // if (response?.show_season?.length === 0 ?
                //     response.show_season.push(JSON.parse(JSON.stringify({ ...mockSeasons }))) : null) // adding mock season
                // if (response?.tenants?.map((tenant, tenantIndex) => tenant?.division_role.map((division, divisionIndex) => division.show_season.map((show, showIndex) => show.show_season_options?.length === 0) ?
                //     response.tenants[tenantIndex].division_role[divisionIndex].show_season[0].show_season_options?.push(JSON.parse(JSON.stringify({ ...levelOptions }))) : null)))
                // if (response?.tenants?.map((tenant, tenantIndex) => tenant?.division_role.map((division, divisionIndex) => division.role_id > 0 ?
                //     this.getUserLevels(division.role_id, tenantIndex, divisionIndex) : null)))
                //             console.log("Mock Tenants:", response);
                return response;
    }
    addTenant = () => {
        let newUserDetails = this.state.userDetails
        let newTenantIndex = newUserDetails?.tenants.length;
        newUserDetails?.tenants.push(JSON.parse(JSON.stringify({ ...mockTenant1 })))
        this.setState({ userDetails: newUserDetails })
        newUserDetails?.tenants[newTenantIndex]?.division_role.push(JSON.parse(JSON.stringify({ ...mockDivsions })))
        this.setState({ userDetails: newUserDetails })
        let divisionIndex = newUserDetails?.tenants[newTenantIndex]?.division_role.length - 1;
        newUserDetails.is_credential_update = 1
        // this.addShowSeasonCallback(newTenantIndex, divisionIndex);
        this.fieldChanged(true, "error")
    }
    addDivisionCallback = (field, tenantIndex) => {
        let newUserDetails = this.state.userDetails
        if (field === 'division') {
            newUserDetails.is_credential_update = 1
            let newDivisionIndex = newUserDetails?.tenants[tenantIndex]?.division_role.length;
            newUserDetails?.tenants[tenantIndex]?.division_role.push(JSON.parse(JSON.stringify({ ...mockDivsions })))
            this.setState({ userDetails: newUserDetails })
            // newUserDetails?.tenants[tenantIndex]?.division_role[newDivisionIndex].show_season.push(JSON.parse(JSON.stringify({ ...mockSeasons })))
            // this.setState({ userDetails: newUserDetails })
        }
        this.fieldChanged(true, "error")
    }
    removeCallback = (field, tenantIndex, callbackItem) => {
        let newUserDetails = this.state.userDetails
        let index = newUserDetails?.tenants[tenantIndex]?.division_role.indexOf(callbackItem)
        if (index > -1) {
            newUserDetails?.tenants[tenantIndex]?.division_role.splice(index, 1)
            newUserDetails.is_credential_update = 1
            newUserDetails.tenants[tenantIndex].default_home_page_options.map(item => {
                let indexFind = item.divisionIndexArr.indexOf(index)
                if (indexFind > -1) {
                    item.divisionIndexArr.splice(indexFind, 1)
                }
                if (index !== newUserDetails?.tenants[tenantIndex]?.division_role?.length) {
                    item.divisionIndexArr = item.divisionIndexArr.map(itemValue => {
                        if (itemValue > index) {
                            itemValue = itemValue - 1
                            return itemValue
                        } else {
                            return itemValue
                        }
                    })
                }
            })

            let newHomePageOptions = newUserDetails.tenants[tenantIndex].default_home_page_options.filter(item => item.divisionIndexArr.length !== 0)
            newUserDetails.tenants[tenantIndex].default_home_page_options = newHomePageOptions;
            newUserDetails.tenants[tenantIndex].default_home_page_option_id = newHomePageOptions?.find(item => item.is_default === 1)?.value
        }
        this.setState({ userDetails: newUserDetails }, () => {
            if (this.state.userDetails?.tenants[tenantIndex].division_role?.length === 0) {
                let config = this.state.config;
                config.inlineEdits[0].fieldDisabled = true
                config.inlineEdits[1].fieldDisabled = true
                this.setState({ config })
            }
            this.filterShowOptionsBasedOnDivision(tenantIndex)
        })
        this.fieldChanged(true, "error")
    }
    removeTenantCallback = (tenantIndex) => {
        let newUserDetails = this.state.userDetails
        if (tenantIndex > -1) {
            newUserDetails?.tenants.splice(tenantIndex, 1)
            newUserDetails.is_credential_update = 1
        }
        this.setState({ userDetails: newUserDetails })
        this.fieldChanged(true, "error")
    }
    addShowSeasonCallback = (tenantIndex, divisionIndex, showIndex) => {
        let showArrayIndex = showIndex + 1
        let newUserDetails = this.state.userDetails
        // newUserDetails?.tenants[tenantIndex]?.division_role[divisionIndex].show_season.push(JSON.parse(JSON.stringify({ ...mockSeasons })))
        newUserDetails.is_credential_update = 1
        this.setState({ userDetails: newUserDetails })
        this.fieldChanged(true, "error")
    }
    removeShowSeasonCallback = (field, tenantIndex, divisionIndex, callbackItem) => {
        let newUserDetails = this.state.userDetails
        let index = newUserDetails?.tenants[tenantIndex]?.division_role[divisionIndex].show_season.indexOf(callbackItem)
        if (index > -1) {
            // newUserDetails?.tenants[tenantIndex]?.division_role[divisionIndex].show_season[index].show_season_options.splice(index, 1)
            newUserDetails?.tenants[tenantIndex]?.division_role[divisionIndex].show_season.splice(index, 1)
            newUserDetails.is_credential_update = 1
        }
        this.setState({ userDetails: newUserDetails })
        this.fieldChanged(true, "error")
    }
    getEditUser = (id) => {
        // this.setState({ isFetchingUserDetails: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/user?user_id=' + id, this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value, null)
            .then(response => {
                let formattedList = response.data?.find(item => item.user_id === id);
                if (response.data.error) {
                    console.log("response error", response)
                } else {
                    console.log("response", response?.data[0])
                    this.setState({ userDetails: this.formatUserDetails(JSON.parse(JSON.stringify({ ...response?.data[0] }))), isFetchingUserDetails: false }, () => {
                        this.state.userDetails?.tenants?.map((tenant, tenantIndex) =>
                            tenant?.division_role.map((division, divisionIndex) => this.getDefaultHomePageOptions(division.role_id, division.security_level_id, tenantIndex, divisionIndex)))
                        this.state.userDetails?.tenants?.map((tenant, tenantIndex) =>
                            this.filterShowOptionsBasedOnDivision(tenantIndex))
                    })
                }
            },
                (err) => {
                    this.setState({ isFetchingUserDetails: false });
                    console.log("Error in fetching User Details:", err)
                })
    }

    // getShows = (value) => {
    //     let option = {
    //         "searchString": value,
    //     }
    //     if (this.state.searchCancelToken != null)
    //         this.state.searchCancelToken.cancel("Operation canceled due to new request");
    //     var cancelToken = axios.CancelToken.source();
    //     this.setState({ searchCancelToken: cancelToken });
    //     ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
    //         `/entitySearch?entity=SHOW&searchString=${value}&all=1`, cancelToken, this.props.userContext.active_tenant.tenant_id, 1)
    //         .then(response => {
    //             let formattedList = response?.data?.map(item => ({ value: item.show_id, text: item.show_name }));;
    //             formattedList.unshift({ value: "null", text: "All Shows" });
    //             this.setState({ showOptions: formattedList });
    //         },
    //             (err) => {
    //                 console.log("Error in fetching entities:", err)
    //             })
    // }

    getEntity = (entity, entitytype, searchString, tenantId = null) => {
        let loading = (entitytype === 'SHOW') ? 'isShowLoading' : (entitytype === 'USER') ? 'isUserLoading' : 'loading';
        this.setState({ [loading]: true, isFetchingUserDetails: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, entitytype === 'SHOW_DIVISION' ? tenantId ? tenantId : (this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value || null) : (this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value|| null), null)
            .then(response => {
                let formattedList = [];
                if (entitytype === 'SHOW_DIVISION') {
                    formattedList = response?.data?.map(item => ({ value: item.show_id, text: item.show_name, division_id: item.division_id }));
                    // formattedList.unshift({ value: null, label: "All Shows" });
                } else if (entitytype === 'USER') {
                    formattedList = response?.data?.map(item => ({ value: item.user_id, label: item.full_name }));
                } else if (entitytype === 'HOME_PAGE_OPTIONS') {
                    formattedList = response?.data?.map(item => ({ value: item.home_page_option_id, label: item.home_page_option_name }));
                }

                this.setState({ [entity]: formattedList, [loading]: false }, () => {
                    if (entitytype === 'SHOW_DIVISION') {
                        // let config = { ...this.state.config }
                        // config.headings[0].selectOptions = this.state.showOptions;
                        // this.setState({ config, backUpShowOptions: formattedList })
                        this.setState({ backUpShowOptions: formattedList }, () => {
                            if (this.props?.id > 0 && tenantId === null) {
                                // this.getEntity("showOptions", "SHOW", "");
                                this.getEditUser(this.props?.id);
                            } else {
                                this.setState({ isFetchingUserDetails: false })
                            }
                        })
                    }
                });
            },
                (err) => {
                    this.setState({ [loading]: false });
                    console.log("Error in fetching entities:", err)
                })
    }

    getDivisions = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/staticData?staticTable=division', this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ divisionOptions: formattedList, });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }
    getUserRoles = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/staticData?staticTable=role', null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ roleOptions: formattedList, });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }
    getUserLevels = (role_id, tenantIndex, divisionIndex) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/roleSecurityLevel?role_id=${role_id}`, this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value)
            .then(response => {
                let formattedList = response.data[0]?.security_levels.map(item => ({ value: item.security_level_id, label: item.security_level_name }));
                let userDetails = this.state.userDetails;
                userDetails.tenants[tenantIndex].division_role[divisionIndex].security_level_options[divisionIndex] = formattedList
                this.setState({ userDetails: userDetails });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }
    getTenants = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/staticData?staticTable=tenant', null)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }))
                this.setState({ tenantOptions: formattedList, }, () => {
                    this.getDivisions();
                    this.getEntity("showOptions", "SHOW_DIVISION", "",)
                    this.setState({
                        userDetails: this.formatUserDetails(JSON.parse(JSON.stringify({ ...getJSON }))),
                    })
                });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    getSeasons = (showId, tenantIndex, divisionIndex, showIndex) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${showId}&all=1`, this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value)
            .then(response => {
                let formattedList = []
                formattedList = response.data[0]?.seasons?.map(item => ({ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }));
                formattedList.unshift({ value: "All Seasons", label: "All Seasons" });
                let userDetails = this.state.userDetails;
                userDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].show_season_options = formattedList
                this.setState({ userDetails: userDetails });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ fieldChanged: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    closeState = () => {
        if (this.state.fieldChanged) {
            this.setState({ showError: true });
        }
        else {
            this.props?.handleClose();
        }
    }

    postUserDetails = (postJson) => {
        this.setState({ isPosting: true })
        let extensionUrl = '/user'
        let finalJSON = postJson
        // finalJSON.tenants.map((tenant, tenantIndex) => tenant.division_role.map((division, divisionIndex) => division.is_all_shows === 1 ? (division.show_season = [], division.show_season.push(JSON.parse(JSON.stringify({ ...mockSeasons })))) : null))
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, finalJSON, this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value)
            .then((response) => {
                if (!response.data.error) {
                    this.setState({ isPosting: false })
                    this.notificationComponent(true, "success")
                    this.props?.handleRefresh(true);
                    console.log("post response", response);
                }
                else {
                    this.setState({ isPosting: false })
                    this.notificationComponent(true, "fail")
                }
            },
                (err) => {
                    this.setState({ isPosting: false })
                    this.notificationComponent(true, "fail")
                    console.log("Post agency details error: " + err);
                });
    }

    onChangeCallback = (field, value) => {
        if (value) {
            this.setState({ fieldValid: validateEmail(value) });
        } else {
            this.setState({ fieldValid: true });
        }
        this.handleGeneralEdit(field, value)
        // this.setState({
        //     accountEmail: value,
        //     postInitiated: false
        // }, () => { this.fieldChanged(true, "error") })
    }

    onConfirm = () => {
        this.props?.handleClose();
        this.props?.handleRefresh(true);
    }

    handleSubmitUser = () => {
        this.setState({
            postInitiated: true
        }, () => {
            let finalArray = this.state.userDetails
            if (this.validateErrorFlags(finalArray)) {
                console.log("Post JSON", finalArray)
                this.postUserDetails(finalArray);
            } else {
                console.log("Post JSON", finalArray)
                // alert("Submit fail")
            }
        })

    }

    validateErrorFlags = (postUserJson) => {
        let isValid = false;
        let canSubmit = ((postUserJson?.full_name && postUserJson?.display_name && postUserJson?.title && postUserJson?.login_email && postUserJson.notification_email) && (validateEmail(postUserJson?.login_email) && validateEmail(postUserJson?.notification_email)) &&
            (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant => postUserJson.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            // (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant =>  tenant.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0 && div.security_level_id > 0))) : false) &&
            (postUserJson?.tenants.length > 0 ? postUserJson.tenants.find(tenant => postUserJson.is_user_provisioner === 1 ? (tenant.tenant_id > 0) : ((tenant.tenant_id > 0 && tenant.default_home_page_option_id) && tenant.division_role.find(div => div.division_id > 0 && div.role_id > 0))) : false) &&
            (postUserJson?.phone ? validateAllPhone(postUserJson?.phone) : true) && (this.getDistinctArray(postUserJson?.tenants).length === postUserJson?.tenants.length) && (!isValid)) ? true : false;
        if (canSubmit === false) {
            this.setState({ mainError: true })
        } else {
            this.setState({ mainError: false })
        }
        if (canSubmit === false && postUserJson?.full_name && postUserJson?.display_name && postUserJson?.title && postUserJson?.login_email && postUserJson.notification_email && (validateEmail(postUserJson?.login_email) && validateEmail(postUserJson?.notification_email))) {
            this.handleTabChange(2)
        }
        return canSubmit;
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    showChangeCallback = (field, val, tenantIndex, divisionIndex, showIndex) => {
        let newUserDetails = this.state.userDetails
        if (field === "show") {
            newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].show_id = val === null ? val : val.value
            newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].show_name = val === null ? val : val.text
            newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].season_id = null
            newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].show_season_options = []
            if (val !== null && val.text === "All Shows") {
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season = []
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season.push(JSON.parse(JSON.stringify({ ...mockSeasons })))
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[0].season_id = null
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].is_all_shows = 1
            }
            else {
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].is_all_shows = 0
                if (val !== null) {
                    this.getSeasons(val.value, tenantIndex, divisionIndex, showIndex)
                }
            }
        }
        if (field === "season") {
            if (val.props.value === "All Seasons") {
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].season_id = null
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].season_name = val.props.value
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].show_season_id = null
            }
            else {
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].season_id = val.props.value
                let season = null
                season = newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].show_season_options.filter(data => data.label === val.props.children)
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].show_season[showIndex].show_season_id = season[0].show_season_id
            }
            newUserDetails.tenants[tenantIndex].division_role[divisionIndex].is_all_shows = 0
        }
        newUserDetails.is_credential_update = 1
        this.setState({ userDetails: newUserDetails })
        this.fieldChanged(true, "error")
    }

    onChangeHandler = (value, tenantIndex, fieldName, divisionIndex) => {
        let newUserDetails = { ...this.state.userDetails };
        switch (fieldName) {
            case "tenant_id": newUserDetails.tenants[tenantIndex].tenant_id = value.props.value
                break;
            case "default_home_page_option_id": newUserDetails.tenants[tenantIndex].default_home_page_option_id = value.props.value
                break;
            case "division_id": newUserDetails.tenants[tenantIndex].division_role[divisionIndex].division_id = value.props.value;
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].role_id = null;
                break;
            case "is_read_only": newUserDetails.tenants[tenantIndex].division_role[divisionIndex].is_read_only = value ? 1 : 0;
                break;
            case "role_id": newUserDetails.tenants[tenantIndex].division_role[divisionIndex].role_id = value.props.value;
                newUserDetails.tenants[tenantIndex].division_role[divisionIndex].security_level_id = null;
                if (newUserDetails.tenants[tenantIndex].division_role.length === 1) {
                    newUserDetails.tenants[tenantIndex].default_home_page_options = [],
                        newUserDetails.tenants[tenantIndex].default_home_page_option_id = null;
                } else {
                    this.clearDefaultHomePageOptions(tenantIndex, divisionIndex)
                }
                break;
            case "security_level_id": newUserDetails.tenants[tenantIndex].division_role[divisionIndex].security_level_id = value.props.value
                break;
            case "is_user_provisioner": newUserDetails.tenants[tenantIndex].is_user_provisioner = value
                break;
        }
        newUserDetails.is_credential_update = 1
        this.setState({ userDetails: newUserDetails }, () => {
            if (fieldName === "division_id") {
                if (this.state.userDetails?.tenants[tenantIndex].division_role?.length > 0) {
                    let config = this.state.config;
                    config.inlineEdits[0].fieldDisabled = false
                    config.inlineEdits[1].fieldDisabled = false
                    this.setState({ config })
                }
                this.filterShowOptionsBasedOnDivision(tenantIndex)
            }
        })
        if (fieldName === "role_id") {
            // this.getUserLevels(value.props.value, tenantIndex, divisionIndex);
            this.getDefaultHomePageOptions(newUserDetails.tenants[tenantIndex].division_role[divisionIndex].role_id, newUserDetails.tenants[tenantIndex].division_role[divisionIndex].security_level_id, tenantIndex, divisionIndex)
        }
        if (fieldName === "security_level_id") {
            this.getDefaultHomePageOptions(newUserDetails.tenants[tenantIndex].division_role[divisionIndex].role_id, newUserDetails.tenants[tenantIndex].division_role[divisionIndex].security_level_id, tenantIndex, divisionIndex)
        }
        this.fieldChanged(true, "error")
    }

    clearDefaultHomePageOptions = (tenantIndex, divisionIndex) => {
        let userDetails = this.state.userDetails;
        userDetails.tenants[tenantIndex].default_home_page_options?.map(item => {
            let indexFind = item.divisionIndexArr.indexOf(divisionIndex)
            if (indexFind > -1) {
                item.divisionIndexArr.splice(indexFind, 1)
            }
        })
        let newHomePageOptions = userDetails.tenants[tenantIndex].default_home_page_options.filter(item => item.divisionIndexArr.length !== 0)
        userDetails.tenants[tenantIndex].default_home_page_options = newHomePageOptions;
        this.setState({
            userDetails
        })
    }

    filterShowOptionsBasedOnDivision = (tenantIndex) => {
        let userDetails = this.state.userDetails;
        let division_ids = [];
        userDetails.tenants[tenantIndex].division_role?.map(item => {
            if (item.division_id !== null) {
                division_ids.push(item.division_id)
            }
        })
        let newShowOptions = this.state.backUpShowOptions;
        let filteredShowOptions = [];
        if (division_ids?.length === 1) {
            filteredShowOptions = newShowOptions.filter(item => item.division_id === division_ids[0])
        } else if (division_ids?.length > 1) {
            filteredShowOptions = newShowOptions.filter(item => item.division_id === division_ids[0] || item.division_id === division_ids[1])
        }
        let config = this.state.config;
        config.headings[0].selectOptions = filteredShowOptions;
        let details = userDetails?.show_season?.map(item => {
            if (division_ids.includes(item.division_id)) {
                return item;
            } else {
                return null;
            }
        })
        let newDetails = details.filter(item => item != null);
        this.setState(prevState => ({
            userDetails: {
                ...prevState.userDetails,
                show_season: newDetails || [],
                ['is_credential_update']: 1
            },
            showOptions: filteredShowOptions,
            config
        }))
        // this.setState({ config, showOptions: filteredShowOptions, userDetails: details })
    }


    getDefaultHomePageOptions = (role_id, level_id, tenantIndex, divisionIndex) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=HOME_PAGE_OPTIONS&searchString=""`, this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value, null)
            .then(response => {
                let formattedList = response?.data?.filter(item => item.role_id === role_id);
                let filteredList = formattedList?.map(item => ({ value: item.home_page_option_id, label: item.home_page_option_name, is_default: item.is_default, role_id, level_id }));
                let userDetails = this.state.userDetails;
                let unique = []
                let duplicates = []
                unique = filteredList.filter(object1 => {
                    return !userDetails.tenants[tenantIndex].default_home_page_options?.some(object2 => {
                        return object1.value === object2.value
                    })
                })
                duplicates = filteredList.filter(object1 => {
                    return userDetails.tenants[tenantIndex].default_home_page_options?.some(object2 => {
                        return object1.value === object2.value
                    })
                })
                if (unique.length !== 0) {
                    userDetails.tenants[tenantIndex].default_home_page_options = userDetails.tenants[tenantIndex].default_home_page_options ? userDetails.tenants[tenantIndex].default_home_page_options.concat(unique) : unique;
                    userDetails.tenants[tenantIndex].default_home_page_options?.map(item => {
                        unique.map(itemList => {
                            if (itemList.value === item.value) {
                                item.divisionIndexArr = [divisionIndex]
                            }
                        })
                    })
                }
                if (duplicates.length) {
                    userDetails.tenants[tenantIndex].default_home_page_options?.map(item => {
                        duplicates.map(itemList => {
                            if (itemList.value === item.value) {
                                item.divisionIndexArr.push(divisionIndex)
                                if (itemList.is_default !== item.is_default) {
                                    item.is_default = itemList.is_default
                                }
                            }

                        })
                    })
                }
                userDetails.tenants[tenantIndex].default_home_page_option_id = userDetails.tenants[tenantIndex].default_home_page_option_id ? userDetails.tenants[tenantIndex].default_home_page_option_id : userDetails.tenants[tenantIndex].default_home_page_options ? userDetails.tenants[tenantIndex].default_home_page_options?.find(item => item.is_default === 1 && item.divisionIndexArr.indexOf(0) > -1)?.value : null;
                // userDetails.is_credential_update = 1;
                this.setState({ userDetails: userDetails })
            })
    }

    handleGeneralEdit = (field, newValue) => {
        if (field === 'supervisor' || field === 'assistant') {
            let newUserDetails = this.state.userDetails
            newUserDetails[field + '_id'] = newValue
            this.setState({ userDetails: newUserDetails })
            console.log("user Details", this.state.userDetails)
        }
        else if (field === "is_active" || field === "is_user_provisioner" || field === "is_void_notification_recipient" || field === "is_overdue_license_signer" || field === "is_check_request_batch_receiver" || field === "is_read_only" || field === "is_cost_allocation_notification_receiver") {
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: newValue ? 1 : 0
                },
                postInitiated: false,
                mainError: false
            }), () => {
                if (field === "is_user_provisioner" && this.state.userDetails?.is_user_provisioner === 0) {
                    this.getEntity("showOptions", "SHOW_DIVISION", "", this.state.userDetails?.tenants[0].tenant_id);
                    let tabList = [...this.state.tabList];
                    let newTabList = [...UserPopUpTabList]
                    this.setState({ tabList: newTabList })
                } else if (field === "is_user_provisioner" && this.state.userDetails?.is_user_provisioner === 1) {
                    let tabList = [...this.state.tabList];
                    let newTabList = tabList.filter(item => item.value !== 3)
                    this.setState({ tabList: newTabList })
                }
            });
        }
        else if (field === "login_email") {
            if (newValue) {
                let emailExists = false
                let validEmail = validateEmail(newValue)
                this.setState({ fieldValid: validEmail });
                if (validEmail && this.props?.title === 'New User') {
                    emailExists = this.props?.emailList.find(item => item.email === newValue.toLowerCase())
                    emailExists ? this.setState({ existingEmail: true }) : this.setState({ existingEmail: false })
                }
            } else {
                this.setState({ fieldValid: true });
            }
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: newValue || null
                },
                postInitiated: false,
                mainError: false
            }));
        }
        else {
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    [field]: newValue
                },
                postInitiated: false,
                mainError: false
            }))
        }
        this.fieldChanged(true, "error")
    }

    getDistinctArray = (arr) => {
        const distincitUserRoles = arr.filter((ele, index, arr) =>
            index === arr.findIndex((item) => (
                item.tenant_id == ele.tenant_id
            )))
        return distincitUserRoles
    }

    getDistinctDivisionArray = (tenentItem) => {
        const distincitDivision =
            tenentItem.filter((ele1, index1, arr1) =>
                index1 === arr1.findIndex((item) => (
                    item.division_id === ele1.division_id
                )
                ))
        return distincitDivision
    }


    getDistinctshowArray = (showItem) => {
        const distincitDivision =
            showItem.filter((ele1, index1, arr1) =>
                index1 === arr1.findIndex((item) => (
                    item.show_id === ele1.show_id && item.show_season_id === ele1.show_season_id
                )

                ))
        return distincitDivision
    }

    showChangeGetSeasons = (value, mode = null, rowIndex) => {
        if (mode === null) {
            this.setState({ isFetchingSeason: true })
        }
        console.log("showid", value?.show_id)
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${value?.show_id?.value}&all=1`, this.state.tenantOptions.find(item => item.label?.toLowerCase() === "wb television")?.value)
            .then(response => {
                console.log("response", response)
                let formattedList = []
                formattedList = response.data[0]?.seasons?.map(item => ({ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }));
                // formattedList.unshift({ value: "All Seasons", label: "All Seasons" });
                if (mode === 'edit') {
                    let show_season = this.state.userDetails?.show_season?.map((item, index) => {
                        if (rowIndex === index) {
                            // let newItem = { ...item }
                            item.searchSelectOptions = formattedList
                            //this.setState({ "editingPerformers": true });
                            // return newItem;
                        }
                        return item;
                    })
                    // this.setState({ updatedListEdits: formattedList, urmIndex: rowIndex })
                    this.setState(prevState => ({
                        userDetails: {
                            ...prevState.userDetails,
                            show_season: show_season || [],
                        },
                        updatedListEdits: formattedList,
                        urmIndex: rowIndex
                    }), () => {
                        console.log("new user details", this.state.userDetails)
                    })
                } else {
                    let config = { ...this.state.config }
                    config.headings[1].selectOptions = formattedList;
                    this.setState({ config, seasonOptions: formattedList, isFetchingSeason: false }, () => {
                        console.log("newConfif", this.state.config)
                    })
                }
            },
                (err) => {
                    this.setState({ isFetchingSeason: false })
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    handleAddNewItem = (newDataItem) => {
        console.log("newDataItem", newDataItem)
        let userDetails = this.state.userDetails
        let season = this.state.seasonOptions?.filter(item => item.value === newDataItem?.season_id);
        if (Object.entries(newDataItem).length !== 0) {
            let newShowSeason = {
                show_season_id: season?.[0]?.show_season_id || null,
                show_id: newDataItem?.show_id?.value,
                show_name: newDataItem?.show_id?.text,
                season_id: newDataItem?.season_id || null,
                season_name: season?.[0]?.label || null,
                division_id: newDataItem?.show_id?.division_id,
                searchSelectOptions: this.state.seasonOptions
            };
            let show_exists = userDetails?.show_season.find(item => item.show_id === newShowSeason?.show_id && item.season_id === null)
            let show_season_show_ids = userDetails?.show_season.find(item => item.show_id === newShowSeason?.show_id && item.season_id === newShowSeason?.season_id)
            if (!show_exists) {
                if (!show_season_show_ids) {
                    userDetails?.show_season.push(newShowSeason);
                    userDetails.is_credential_update = 1;
                    userDetails.is_all_shows = 0;
                    this.setState({ userDetails })
                } else {
                    this.setState({ showSeasonExists: true }, () => {
                        setTimeout(() => { this.setState({ showSeasonExists: false }) }, 5000)
                    })
                }
            } else {
                this.setState({ showHasAllAccess: true }, () => {
                    setTimeout(() => { this.setState({ showHasAllAccess: false }) }, 5000)
                })
            }
        }
    };



    handleInlineEdits = async (id, dataItem, rowIndex) => {
        if (dataItem?.edit === true) {
            let show_season = [];
            for (let i = 0; i < this.state.userDetails?.show_season?.length; i++) {
                let newItem = { ...this.state.userDetails?.show_season[i] }
                if (newItem.show_id === dataItem.show_id && rowIndex === i) {
                    newItem.show_id = {
                        text: dataItem.show_name,
                        value: dataItem.show_id
                    };
                    await ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${newItem.show_id?.value}&all=1`, this.props.userContext?.active_tenant?.tenant_id)
                        .then(response => {
                            console.log("response", response)
                            let formattedList = []
                            formattedList = response.data[0]?.seasons?.map(item => ({ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }));
                            this.setState({ newSeasonEditOptions: formattedList }, () => {
                                newItem['searchSelectOptions'] = this.state.newSeasonEditOptions;
                                newItem['editing'] = true;
                                show_season.push(newItem);
                            })
                        })
                } else {
                    show_season.push(newItem);
                }
            }
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    show_season: show_season || [],
                },
            }))
        } else {
            let show_season = this.state.userDetails?.show_season?.map((item, index) => {
                let newItem = { ...item }
                if (item.show_id === dataItem.show_id && rowIndex === index) {
                    newItem.show_id = {
                        text: dataItem.show_name,
                        value: dataItem.show_id
                    };
                    newItem['editing'] = true;
                }
                return newItem;
            })
            this.setState(prevState => ({
                userDetails: {
                    ...prevState.userDetails,
                    show_season: show_season || [],
                },
            }))
        }
    }

    // handleInlineEdits = (id, dataItem, rowIndex) => {
    //             let show_season = this.state.userDetails?.show_season?.map((item, index) => {
    //                 let newItem = { ...item }
    //                 if (item.show_id === dataItem.show_id && rowIndex === index) {
    //                     newItem.show_id = {
    //                         text: dataItem.show_name,
    //                         value: dataItem.show_id
    //                     };
    //                     if (item.edit === true) {
    //                         ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${newItem.show_id?.value}&all=1`, 1)
    //                             .then(response => {
    //                                 console.log("response", response)
    //                                 let formattedList = []
    //                                 formattedList = response.data[0]?.seasons?.map(item => ({ value: item.season_id, label: item.season_name, show_season_id: item.show_season_id }));
    //                                 this.setState({ newSeasonEditOptions: formattedList }, () => {
    //                                     newItem['searchSelectOptions'] = this.state.newSeasonEditOptions;
    //                                     newItem['editing'] = true;
    //                                     return newItem;
    //                                 })
    //                             })
    //                     } else {
    //                         newItem['editing'] = true;
    //                         return newItem;
    //                     }
    //                 }
    //                 this.setState(prevState => ({
    //                     userDetails: {
    //                         ...prevState.userDetails,
    //                         show_season: show_season || [],
    //                     },
    //                 }))
    //             })
    //             // this.setState(prevState => ({
    //             //     userDetails: {
    //             //         ...prevState.userDetails,
    //             //         show_season: show_season || [],
    //             //     },
    //             // }))
    //         };

    saveInlineEdits = (dataItem, index, field, newDataItem) => {
        let userDetails = JSON.parse(JSON.stringify(this.state.userDetails));
        //postObj = JSON.parse(JSON.stringify(this.state.initialRecordData));
        //songDetails.licensors_list = [...dataItem];
        let updatedItem = { ...dataItem[index] };
        console.log("searchSelectOptions", updatedItem.searchSelectOptions)
        let season = updatedItem?.searchSelectOptions?.filter(item => item.value === updatedItem?.season_id);
        if (updatedItem.show_id) {
            let show_exists = userDetails?.show_season.find(item => item.show_id === updatedItem?.show_id?.value && item.season_id === null)
            let show_season_show_ids = userDetails?.show_season.find(item => item.show_id === updatedItem?.show_id?.value && item.season_id === updatedItem?.season_id)
            if (!show_exists) {
                if (!show_season_show_ids) {
                    userDetails.show_season[index].show_name = updatedItem?.show_id ? updatedItem?.show_id?.text ? updatedItem?.show_id?.text : null : null;
                    userDetails.show_season[index].show_id = updatedItem?.show_id ? updatedItem?.show_id?.value ? updatedItem?.show_id?.value : null : null;
                    userDetails.show_season[index].season_name = updatedItem?.season_id ? season?.[0]?.label : null;
                    userDetails.show_season[index].season_id = updatedItem?.season_id ? updatedItem?.season_id : null;
                    userDetails.show_season[index].division_id = updatedItem?.division_id ? updatedItem?.division_id : null;
                    userDetails.show_season[index].searchSelectOptions = updatedItem?.searchSelectOptions ? updatedItem?.searchSelectOptions : null;
                    userDetails.show_season[index].show_season_id = updatedItem?.show_season_id ? season?.[0]?.show_season_id : null;
                    userDetails.show_season[index]['editing'] = false;
                    userDetails.is_credential_update = 1;
                    this.setState({ userDetails: userDetails })
                } else {
                    this.setState({ showSeasonExists: true }, () => {
                        setTimeout(() => { this.setState({ showSeasonExists: false }) }, 5000)
                    })
                    userDetails.show_season[index]['editing'] = true;
                }
            } else {
                this.setState({ showHasAllAccess: true }, () => {
                    setTimeout(() => { this.setState({ showHasAllAccess: false }) }, 5000)
                })
                userDetails.show_season[index]['editing'] = true;
            }
            //     LicenseHeading.headings[0].selectOptions = [];
        } else {
            userDetails.show_season[index]['editing'] = true;
            // this.setState({ editingSyncLicensors: true, MandatorySyncLicensorsError: true })
        }
    }

    handleReadCallback = (rowIndex) => {
        let show_season = this.state.userDetails?.show_season?.map((item, index) => {
            if (rowIndex === index) {
                // let newItem = { ...item }
                item.show_id = item.show_id?.value,
                    item['editing'] = false;
                //this.setState({ "editingPerformers": true });
            }
            return item;
        })
        this.setState(prevState => ({
            userDetails: {
                ...prevState.userDetails,
                show_season: show_season || [],
            },
        }))
    }

    handleDelCallBack = (id, dataItem, index) => {
        let userDetails = { ...this.state.userDetails }
        userDetails.show_season?.splice(index, 1)
        userDetails.is_credential_update = 1;
        if (userDetails.show_season?.length === 0) {
            userDetails.is_all_shows = 1;
        }
        this.setState({ userDetails })
    }

    showSeason = () => {
        return (
            <div className="tableContainerURM">
                <p className='errorMessage' >{this.state.showSeasonExists && `Show Season combination already exists` || this.state.showHasAllAccess && `You have access to all the seasons for this show`}</p>
                <TableComponent
                    // className={"tableContainerURM"}
                    list={this.state.userDetails?.show_season}
                    // list={this.state.userDetails?.show_season}
                    config={this.state.config}
                    addItemToList={true}
                    isURM={true}
                    onShowChangeCallBack={this.showChangeGetSeasons}
                    handleAddNewItem={this.handleAddNewItem.bind(this)}
                    editCallback={this.handleInlineEdits.bind(this)}
                    saveCallback={this.saveInlineEdits.bind(this)}
                    readCallback={this.handleReadCallback.bind(this)}
                    handleDelCallBack={this.handleDelCallBack.bind(this)}
                    updatedListEdits={this.state.updatedListEdits}
                    urmIndex={this.state.urmIndex}
                    isFetchingSeason={this.state.isFetchingSeason}
                // stickyHeader={true}
                />
            </div>
        )
    }

    credentials = () => {
        let checkCheckBox = this.state.userDetails?.tenants[0].division_role.filter(item => item.role_id === 1 || item.role_id === 2)
        return (
            <div>
                {this.state.userDetails?.tenants?.map((tenant, tenantIndex) => (
                    <div>
                        {/* {tenantIndex === 1 || tenantIndex > 0 ? <div><br /><hr /></div> : ""} */}
                        {this.state.userDetails?.tenants[tenantIndex]?.division_role?.map((division, divisionIndex) => (
                            <MDBRow id="seasons" md={11}>
                                {divisionIndex > 0 ? <MDBCol md={3}></MDBCol> :
                                    <MDBCol md={3}>
                                        <SelectField
                                            label={"Tenant"}
                                            id="tenant_id"
                                            value={tenant.tenant_id || 0}
                                            options={this.state.tenantOptions}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated}
                                            showAsLabel={true}
                                            onChange={(e, newValue) => this.onChangeHandler(newValue, tenantIndex, "tenant_id", divisionIndex)}
                                        />
                                        {this.getDistinctArray(this.state.userDetails.tenants).length !== this.state.userDetails?.tenants.length ?
                                            <span className="errorText">Please Remove the Duplicate Tenant</span>
                                            : null}
                                        {this.state.userDetails?.is_user_provisioner ? " " :
                                            <SelectField
                                                label={"Default Home Page"}
                                                placeHolderText={" - Select -"}
                                                isMandatory={true}
                                                showMandatory={this.state.postInitiated}
                                                id="default_home_page"
                                                value={tenant.default_home_page_option_id || 0}
                                                options={tenant?.default_home_page_options?.length > 0 ? tenant?.default_home_page_options : ""}
                                                onChange={(e, newValue) => this.onChangeHandler(newValue, tenantIndex, "default_home_page_option_id", divisionIndex)}
                                            />
                                        }
                                        {/* <BasicCheckbox
                                            label={"User Provisioner"}
                                            id={"is_user_provisioner" + tenantIndex}
                                            disabled={false}
                                            checked={this.state.userDetails?.is_user_provisioner === 1 ? true : false}
                                            onChange={(e) => this.handleGeneralEdit("is_user_provisioner", e.target.checked)}
                                        /> */}
                                    </MDBCol>}
                                {this.state.userDetails?.is_user_provisioner ? " " :
                                    <MDBCol md={9}>
                                        <MDBRow
                                            //  disabled={this.state.userDetails.is_user_provisioner === 1 ? true : false} 
                                            className={divisionIndex > 0 ? "marginTop" : ""}>
                                            <MDBCol md={5} className="borderRadiusLeft">
                                                <SelectField
                                                    label={"Division"}
                                                    placeHolderText={"- Select -"}
                                                    id="division"
                                                    value={division?.division_id || 0}
                                                    options={this.state.divisionOptions}
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated}
                                                    onChange={(e, newValue) => this.onChangeHandler(newValue, tenantIndex, "division_id", divisionIndex)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={5} className="marginLeft borderRadiusRight">
                                                <SelectField placeHolderText={"- Select -"}
                                                    label={"Role"}
                                                    value={division?.role_id || 0}
                                                    options={this.state.roleOptions}
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated}
                                                    onChange={(e, newValue) => this.onChangeHandler(newValue, tenantIndex, "role_id", divisionIndex)}
                                                />
                                            </MDBCol>
                                            <MDBCol className="readOnly">
                                                <BasicCheckbox
                                                    label={"Read Only"}
                                                    id={"is_read_only" + divisionIndex}
                                                    disabled={false}
                                                    checked={division?.is_read_only ? true : false}
                                                    onChange={(e, newValue) => this.onChangeHandler(e.target.checked, tenantIndex, "is_read_only", divisionIndex)}
                                                />
                                            </MDBCol>
                                            {/* <MDBCol md={4} className="marginLeft borderRadiusRight">
                                                <SelectField placeHolderText={"- Select -"}
                                                    label={"Level"}
                                                    value={division?.security_level_id || 0}
                                                    options={division?.security_level_options[divisionIndex]?.length > 0 ? division?.security_level_options[divisionIndex] : ""}
                                                    // options={division?.length > 0 ? division?.security_level_options[divisionIndex] : ""}
                                                    isMandatory={true}
                                                    showMandatory={this.state.postInitiated}
                                                    onChange={(e, newValue) => this.onChangeHandler(newValue, tenantIndex, "security_level_id", divisionIndex)}
                                                />
                                            </MDBCol> */}
                                            <MDBCol md={1} className="d-flex actions1">
                                                {/* {this.state.userDetails?.tenants[tenantIndex]?.division_role.length - 1 === divisionIndex && division?.division_id > 0 ?
                                                    <MDBIcon icon="plus-circle" className="float-right" onClick={() => this.addDivisionCallback("division", tenantIndex)} /> : ""
                                                } */}
                                                {!(this.state.userDetails?.tenants[tenantIndex]?.division_role.length === 1) ?
                                                    <MDBIcon icon="trash-alt" className="float-right" onClick={() => this.removeCallback("division", tenantIndex, division)} /> : ""
                                                }
                                                {/* {this.state.userDetails?.tenants.length > 1 && divisionIndex === 0 ?
                                                    <MDBIcon icon="trash-alt" className="float-right" onClick={() => this.removeTenantCallback(tenantIndex)} /> : ""
                                                } */}
                                            </MDBCol>
                                            {this.getDistinctDivisionArray(this.state.userDetails.tenants[tenantIndex].division_role).length !== this.state.userDetails.tenants[tenantIndex].division_role.length ?
                                                <span className="errorText">Please Remove the Duplicate Divisions</span>
                                                : null}
                                        </MDBRow>
                                        {/* {division?.show_season.map((show, showIndex) =>
                                            <MDBRow id="seasons">
                                                <MDBCol md={6} className="borderRadiusLeftSearch">
                                                    <SearchSelectField
                                                        label={showIndex > 0 ? "" : "Show"}
                                                        id="show"
                                                        placeholder="- Search -"
                                                        width="100%"
                                                        searchSelect={true}
                                                        multiple={false}
                                                        detail_selected={division.is_all_shows ? { value: null, text: "All Shows" } : show.show_id && show.show_name ? { value: show.show_id, text: show.show_name } : null}
                                                        options={this.state.showOptions || []}
                                                        valueSelected={(e, newValue) => this.showChangeCallback('show', newValue, tenantIndex, divisionIndex, showIndex)}
                                                        searchText={ev => {
                                                            // dont fire API if the user delete or not entered anything
                                                            if (ev.target.value !== "" || ev.target.value !== null) {
                                                                this.getShows(ev.target.value)
                                                            }
                                                        }}
                                                    />
                                                    {this.getDistinctshowArray(this.state.userDetails.tenants[tenantIndex].division_role[divisionIndex].show_season).length !== this.state.userDetails.tenants[tenantIndex].division_role[divisionIndex].show_season.length ?
                                                 <span className="errorText">Please Remove the Duplicate Shows</span>
                                                : null}
                                                </MDBCol>
                                                <MDBCol md={4} className="marginLeft selectBorderRadius jointwithshow">
                                                    {console.log("Shows: ", show)} */}
                                        {/* <SelectField
                                                        label={showIndex > 0 ? "" : "Season"}
                                                        placeHolderText={"- Search -"}
                                                        id="season"
                                                        // isMandatory={true}
                                                        // showMandatory={this.state.postInitiated}
                                                        value={show?.season_name === "All Seasons" ? "All Seasons" : show?.season_id || 0}
                                                        options={show?.show_season_options?.length > 0 ? show?.show_season_options : ""}
                                                        onChange={(e, newValue) => this.showChangeCallback("season", newValue, tenantIndex, divisionIndex, showIndex)}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={1} className="d-flex actions2">
                                                    {(this.state.userDetails?.tenants[tenantIndex]?.division_role[divisionIndex]?.show_season.length - 1 === showIndex && show?.show_id > 0) ?
                                                        <MDBIcon icon="plus-circle" className="float-right ml5" onClick={() => this.addShowSeasonCallback(tenantIndex, divisionIndex, showIndex)} /> : ""
                                                    }
                                                    {!(this.state.userDetails?.tenants[tenantIndex]?.division_role[divisionIndex]?.show_season.length === 1) ?
                                                        <MDBIcon icon="minus-circle" className="float-right ml5" onClick={() => this.removeShowSeasonCallback("show", tenantIndex, divisionIndex, show)} /> : ""
                                                    }
                                                </MDBCol>

                                            </MDBRow>)} */}
                                    </MDBCol>
                                } {this.state.userDetails?.tenants.length > 1 && divisionIndex === 0 ?
                                    <MDBIcon icon="trash-alt" className="float-right icon-style" onClick={() => this.removeTenantCallback(tenantIndex)} /> : ""
                                }
                            </MDBRow>
                        ))}
                        {this.state.userDetails?.is_user_provisioner ? " " :
                            <MDBRow className="optionsMarginTop">
                                <MDBRow>
                                    <BasicLabel text={"Options"} />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <BasicCheckbox
                                            label={"Check Request Batch Receiver"}
                                            id={"checkReqBatcRec"}
                                            disabled={false}
                                            checked={this.state.userDetails?.is_check_request_batch_receiver === 1 ? true : false}
                                            onChange={(e) => this.handleGeneralEdit("is_check_request_batch_receiver", e.target.checked)}
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <BasicCheckbox
                                            label={"Overdue License Signer"}
                                            id={"overDueLiceSig"}
                                            disabled={false}
                                            checked={this.state.userDetails?.is_overdue_license_signer === 1 ? true : false}
                                            onChange={(e) => this.handleGeneralEdit("is_overdue_license_signer", e.target.checked)}
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <BasicCheckbox
                                            label={"Void Notification Recipient"}
                                            id={"voidNotRecp"}
                                            disabled={false}
                                            checked={this.state.userDetails?.is_void_notification_recipient === 1 ? true : false}
                                            onChange={(e) => this.handleGeneralEdit("is_void_notification_recipient", e.target.checked)}
                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        {checkCheckBox.length > 0 ?
                                            <BasicCheckbox
                                                label={"Cost Allocation Notification Receiver"}
                                                id={"costAlloc"}
                                                disabled={false}
                                                checked={this.state.userDetails?.is_cost_allocation_notification_receiver === 1 ? true : false}
                                                onChange={(e) => this.handleGeneralEdit("is_cost_allocation_notification_receiver", e.target.checked)}
                                            /> : null
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBRow>
                        }
                    </div>
                ))}
            </div>
        )
    }
    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <div>
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicTextField
                                label="Title"
                                isMandatory={true}
                                value={this.state.userDetails?.title}
                                showMandatory={this.state.postInitiated}
                                limit={userLimits.title}
                                limitWarning={messages.exceed50CharacterWarning || ""}
                                onChange={(e) => this.handleGeneralEdit("title", e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicTextField
                                label="Company"
                                //isMandatory={true}
                                value={this.state.userDetails?.company}
                                //showMandatory={this.state.postInitiated}
                                onChange={(e) => this.handleGeneralEdit("company", e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicTextField
                                label="Login Email"
                                isMandatory={true}
                                value={this.state.userDetails?.login_email}
                                showMandatory={this.state.postInitiated}
                                limit={userLimits.email}
                                limitWarning={messages.exceed50CharacterWarning || ""}
                                onChange={(e) => this.handleGeneralEdit("login_email", e.target.value)}
                                fieldValid={this.state.fieldValid === false ? true : this.state.existingEmail ? true : (!validateEmail(this.state.userDetails?.login_email) && this.state.userDetails?.login_email) ? true : false}
                                inValidInput={this.state.fieldValid === false ? messages.invalidInput : this.state.existingEmail ? "Email Already Exists" : (!validateEmail(this.state.userDetails?.login_email) && this.state.userDetails?.login_email) ? "Invalid Email" : null}
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicTextField
                                label="Notification Email"
                                isMandatory={true}
                                value={this.state.userDetails?.notification_email}
                                limit={userLimits.email}
                                limitWarning={messages.exceed50CharacterWarning || ""}
                                fieldValid={!validateEmail(this.state.userDetails?.notification_email) && this.state.userDetails?.notification_email}
                                inValidInput={"Invalid Email"}
                                showMandatory={this.state.postInitiated}
                                onChange={(e) => this.handleGeneralEdit("notification_email", e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={6}>
                            <BasicLabel text="Supervisor" />
                            <SelectField
                                id="supervisor"
                                value={this.state.userDetails?.supervisor_id || null}
                                options={this.state.userOptions}
                                onChange={(e) => this.handleGeneralEdit("supervisor", e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicLabel text="Assistant" />
                            <SelectField
                                id="assistant"
                                value={this.state.userDetails?.assistant_id || ""}
                                options={this.state.userOptions}
                                onChange={(e) => this.handleGeneralEdit("assistant", e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={3}>
                            <BasicTextField
                                label="Phone"
                                value={this.state.userDetails?.phone || null}
                                //limit={this.state.userDetails?.phone && validatePlusCharacter((this.state.userDetails?.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS}
                                //limitWarning={`Exceeded ${this.state.userDetails?.phone && validatePlusCharacter((this.state.userDetails?.phone.trim()).charAt(0)) ? sizeLimits.phoneLimitIntl : sizeLimits.phoneLimitUS} Numbers limit. Please re-enter`}
                                //fieldValid={this.state.userDetails?.phone ? !validateAllPhone(this.state.userDetails?.phone) : false}
                                //inValidInput={"Please enter valid phone"}
                                onChange={(e) => this.handleGeneralEdit('phone', e.target.value.length > 0 ? formatPhoneNumbers(e.target.value) : null)}
                            />
                        </MDBCol>
                        <MDBCol md={6}>
                            <BasicTextField
                                label="Building"
                                //isMandatory={true}
                                value={this.state.userDetails?.building || null}
                                //showMandatory={this.state.postInitiated}
                                onChange={(e) => this.handleGeneralEdit("building", e.target.value)}
                            />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicTextField
                                label="Room"
                                //isMandatory={true}
                                value={this.state.userDetails?.room || null}
                                //showMandatory={this.state.postInitiated}
                                onChange={(e) => this.handleGeneralEdit("room", e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
            case 2: return this.credentials()
            case 3: return this.showSeason()
        }
    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value, saveFlag: true });
            case "fail": return this.setState({ showFail: value });
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.props?.handleClose();
        }
    }

    render() {
        const { classes } = this.props;
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            (this.state.showSuccess && (this.props?.id)) ? this.state.saveEditMessage :
                (this.state.showSuccess && (this.props?.id === "")) ? `New User has been created!` : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failTitle :
            this.state.showSuccess ? this.state.saveTitle : "";
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.showSuccess ? "Saved successfully" : this.state.showFail ? "Save failed" : "";
        return (
            <div className="MainViewModalContainerforURM" >
                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                <MDBModal staticBackdrop closeOnEsc={false} tabIndex='-1'
                    show={this.props?.open ? this.props?.open : false}
                    onClose={this.props?.handleClose}
                    hideBackdrop={true}
                    id="modal">
                    <MDBModalDialog id="Userpopup">
                        <MDBModalBody>
                            <MDBModalContent>
                                <MDBContainer size='lg' className='CreateOrEditUserModalContainer'>
                                    <MDBRow className="ModalHeader">
                                        <MDBCol md={5} className="HeaderText" >{this.props?.title}</MDBCol>
                                        <MDBCol md={6} className="HeaderText" ></MDBCol>
                                        <MDBCol md={1} className="closeIcon"
                                            onClick={this.closeState}
                                        ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                                    </MDBRow>
                                    {!this.state.isFetchingUserDetails ?
                                        <MDBRow className="ModalContent">
                                            <MDBCol>
                                                <MDBRow>
                                                    <MDBCol md="6">
                                                        <BasicTextField
                                                            label="Full Name"
                                                            isMandatory={true}
                                                            value={this.state.userDetails?.full_name}
                                                            showMandatory={this.state.postInitiated}
                                                            limit={userLimits.fullName}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            onChange={(e) => this.handleGeneralEdit("full_name", e.target.value)}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md="6">
                                                        <BasicTextField
                                                            label="Display Name"
                                                            isMandatory={true}
                                                            value={this.state.userDetails?.display_name}
                                                            showMandatory={this.state.postInitiated}
                                                            limit={userLimits.displayName}
                                                            limitWarning={messages.exceed50CharacterWarning || ""}
                                                            onChange={(e) => this.handleGeneralEdit("display_name", e.target.value)}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_active"
                                                        label="Active"
                                                        align="end"
                                                        checked={this.state.userDetails?.is_active === 1 ? true : false}
                                                        onChange={(e) => this.handleGeneralEdit("is_active", e.target.checked)}
                                                    />
                                                </MDBRow>
                                                <MDBRow> {this.state.mainError ?
                                                    <span className="errorText">Please complete all mandatory fields</span>
                                                    : null}</MDBRow>
                                                <MDBRow className="tabsURM">
                                                    <MDBCol md={6}>
                                                        <Tabs
                                                            tabList={this.state.tabList}
                                                            value={this.state.tabValue}
                                                            onChange={(e, v) => this.handleTabChange(v)}
                                                        />
                                                    </MDBCol>
                                                    {this.state.tabValue === 2 ?
                                                        <MDBCol md={6} className="tenantAddButton">
                                                            {/* <BasicCheckbox
                                                                label={"Read Only"}
                                                                id={"is_read_only"}
                                                                disabled={false}
                                                                checked={this.state.userDetails?.is_read_only === 1 ? true : false}
                                                                onChange={(e) => this.handleGeneralEdit("is_read_only", e.target.checked)}
                                                            /> */}
                                                            <BasicCheckbox
                                                                label={"User Provisioner"}
                                                                id={"is_user_provisioner"}
                                                                disabled={false}
                                                                checked={this.state.userDetails?.is_user_provisioner === 1 ? true : false}
                                                                onChange={(e) => this.handleGeneralEdit("is_user_provisioner", e.target.checked)}
                                                            />
                                                            {/* {this.state.tabValue === 2 ? <BasicButton text={"Add Role"} onClick={() => this.addTenant()} /> : " "} */}
                                                            <BasicButton text={"Add Role"} disabled={this.state.userDetails?.tenants[0]?.division_role?.length >= 2 || this.state.userDetails?.is_user_provisioner} onClick={() => this.addDivisionCallback("division", 0)} />
                                                        </MDBCol> : " "}
                                                </MDBRow>
                                                <MDBRow>
                                                    {this.getTabComponent()}
                                                </MDBRow>
                                                <MDBRow className="p-3">
                                                    <MDBCol md={8}>
                                                    </MDBCol>
                                                    <MDBCol md={2}>
                                                        <BasicButton
                                                            variant="contained"
                                                            type="inline"
                                                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : this.props?.primaryButtonText}
                                                            onClick={this.handleSubmitUser}
                                                            disabled={false}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md={2}>
                                                        <BasicButton
                                                            type="inline"
                                                            variant="outlined"
                                                            text={this.props?.secondaryButtonText}
                                                            onClick={this.closeState}
                                                            disabled={false}
                                                        />
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                        // </MDBRow>
                                        //     </MDBRow>
                                        : <div className="ContentLoader">
                                            <CircularProgress />
                                        </div>
                                    }
                                </MDBContainer>
                                {/* </div> */}
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
                <MessageModal
                    open={this.state.showError || false}
                    title={comfirmModalTitle}
                    message={message}
                    hideCancel={this.state.showFail || this.state.showSuccess || false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={this.onConfirm}
                    handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
            </div>
        );
    }
}
export default withUserContext(CreateOrEditUserModal);