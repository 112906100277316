export const initialRecordData = [
    {
        "show_season_id": 4,
        "show_id": 1,
        "show_name": "Tom & jerry1",
        "season_id": 1,
        "mpm": "mpm2123 Tom & jerry1234",
        "production_entity_id": 2,
        "type": 1,
        "company": "TV Animation",
        "network_id": 1,
        "network_name": "Cartoon Netowrk",
        "duration_id": 1,
        "duration_name": "Duration1",
        "minutes": 50,
        "from_duration": "03:32:00",
        "to_duration": "00:00:00",
        "proj_eps": 221,
        "notes": "notes 123 Tom & jerry1123 Tom & jerry1234",
        "is_music_has_replaced": 1,
        "can_create_excel_batch": 1,
        "override_cost_sheet_ms": 1,
        "is_separate_hv_checks_batch": 1,
        "is_active": 1,
        "is_show_season_completed": 0,
        "production_exc": 1,
        "clearance_rep": 65,
        "clearance_rep_asst": 99,
        "estimating_manager": "Tom & jerry1234",
        "estimator": "Tom & jerry1234",
        "music_amort_code": "Tom & jerry1234",
        "series_amort_code": "Tom & jerry1234",
        "initial_rights": [],
        "optional_rights": [
            {
                "show_season_rights_id": 30,
                "right_id": 5,
                "right_abbr": "Television Right",
                "term": 2,
                "exp_term": 1,
                "territory": 2,
                "incl_init": 1,
                "is_initial_right": 0,
                "is_optional": 1,
                "is_alt_initial_right": 0
            },
            {
                "show_season_rights_id": 31,
                "right_id": 5,
                "right_abbr": "Television Right",
                "term": 1,
                "exp_term": 2,
                "territory": 1,
                "incl_init": 1,
                "is_initial_right": 0,
                "is_optional": 1,
                "is_alt_initial_right": 0
            }
        ],
        "alt_initial_rights": [],
        "clearance_budgets": [
            {
                "show_season_clearance_budgets_id": 2,
                "cost_center_id": 2,
                "cost_center_name": "Warner Bros2",
                "budget": 100,
                "sum": 1
            },
            {
                "show_season_clearance_budgets_id": 8,
                "cost_center_id": 2,
                "cost_center_name": "Warner Bros2",
                "budget": 100,
                "sum": 0
            },
            {
                "show_season_clearance_budgets_id": 1,
                "cost_center_id": 2,
                "cost_center_name": "Warner Bros2",
                "budget": 50,
                "sum": 1
            }
        ]
    }
]
export const initialComposers = {
    composers: [{
        "composer_name": "Hans Zimmerman",
        "affiliation_name": "ASCAP",
        "selected_affiliation": { value: 1, text: "ASCAP" },
        "affiliation_id": 1,
        "pct_value": '50.00'
    },
    {
        "composer_name": "John Smith",
        "affiliation_name": "BMI",
        "selected_affiliation": { value: 2, text: "BMI" },
        "affiliation_id": 2,
        "pct_value": '30.00'
    },
    {
        "composer_name": null,
        "affiliation_name": null,
        "selected_affiliation": {},
        "affiliation_id": null,
        "pct_value": ''
    }]
}


export const costSplitsValues = [
    {
        value: 1,

        label: "Mark Show Season as Complete",

        icon: "angle-double-right",
    },



]

export const costSplitsCheckBoxValus = [
    {
        value: 1,

        label: "Mark Season as Inactive",

        icon: "angle-double-right",
    },


]

export const initialCostSplitConfig = {
    "show_season_cost_split_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "term_id": null,
    "include_HV": null,
    "is_non_streaming_perp_HV": null,
    "perp_split": null,
    "cost_center_id": null,
    "std": null,
    "lib": null,
    "ad_card": null,
    "mt": null,
    "is_active": 1,
    "is_delete": 0
};

export const initialSeasonCostSplitConfig = {
    "season_cost_split_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "term_id": null,
    "include_HV": null,
    "is_non_streaming_perp_HV": null,
    "perp_split": null,
    "cost_center_id": null,
    "std": null,
    "lib": null,
    "ad_card": null,
    "mt": null,
    "is_active": 1,
    "is_delete": 0
};

export const initialHvCostAllocationConfig = {
    "show_season_HV_cost_allocation_id": null,
    "show_season_id": 1,
    "show_id": 1,
    "season_id": 1,
    "term_id": 1,
    "cost_center_id": 1,
    "std": 1,
    "is_active": 1,
    "is_delete": 0
};

export const initialSeasonHvCostAllocationConfig = {
    "season_HV_cost_allocation_id": null,
    "show_season_id": 1,
    "show_id": 1,
    "season_id": 1,
    "term_id": 1,
    "cost_center_id": 1,
    "std": 1,
    "is_active": 1,
    "is_delete": 0
};

export const initialOtherOptionConfig = {
    "show_season_option_cost_allocation_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "right_id": null,
    "share": null,
    "receiving_code": null,
    "gl_code": null,
    "cc_code": null,
    "loc_code": null,
    "is_delete": 0,
    "is_active": 1
};

export const initialSeasonOtherOptionConfig = {
    "season_option_cost_allocation_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "right_id": null,
    "share": null,
    "receiving_code": null,
    "gl_code": null,
    "cc_code": null,
    "loc_code": null,
    "is_delete": 0,
    "is_active": 1
};

export const costSplitPostJsonConfig = {
    "cost_split": [],
    "hv_cost_allocation": [],
    "option_cost_allocation": []
};

export const includeHvOptions = [
    {
        label: "Yes",
        value: '1'
    },
    {
        label: "No",
        value: '0'
    },
]

export const costSplitConfig = {
    headings: [
        {
            headingLabel: "Use This Record?",
            inputType: "checkbox",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "Cost Center",
            width: "50%",
            icon: ""
        },
        {
            headingLabel: "Std %",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "Lib %",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "Ad Card %",
            width: "10%",
            icon: ""
        },
        {
            headingLabel: "MT %",
            width: "17%",
            icon: ""
        }
    ],
    dataNodes: ["check", "cost_center_name", "standard_pct", "library_pct", "ad_card_pct", "MT_pct"],
    primaryKey: "ca_detail_id"
};

export const allContactList = [
    {
        id: 1,
        CostCenter: "4646-PROD",
        Std: "100%",
        Lib: "100%",
        AdCard: "100%",
        MT: "100%",

    }

]

export const allCostSplitList = [
    {
        id: 1,
        CostCenter: "4646-PROD",
        Std: "100%",
        Lib: "100%",
        AdCard: "100%",
        MT: "100%",

    },
    {
        id: 2,
        CostCenter: "4675-PERP",
        Std: "25%",
        Lib: "25%",
        AdCard: "25%",
        MT: "25%",

    },
    {
        id: 3,
        CostCenter: "Home Video-DOM",
        Std: "0%",
        Lib: "0%",
        AdCard: "0%",
        MT: "0%",

    },
    {
        id: 4,
        CostCenter: "Domestic Television Distribution",
        Std: "0%",
        Lib: "0%",
        AdCard: "0%",
        MT: "0%",

    },
    {
        id: 5,
        CostCenter: "Home Video-Int",
        Std: "0%",
        Lib: "0%",
        AdCard: "0%",
        MT: "0%",

    },
]

export const hvCostAllocationConfig = {
    headings: [
        {
            headingLabel: "Term",
            inputType: "select",
            selectOptions: [],
            width: "35%",
            icon: ""
        },
        {
            headingLabel: "Cost Center",
            inputType: "select",
            selectOptions: [],
            width: "35%",
            icon: ""
        },
        {
            headingLabel: "STD%",
            inputType: "text",
            width: "20%",
            icon: ""
        },
        {
            headingLabel: "",
            inputType: "",
            width: "10%",
            icon: ""
        },
    ],
    dataNodes: ["term_id", "cost_center_id", "std"],
    primaryKey: "show_season_HV_cost_allocation_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "term_id",
            width: "34.5%"
        },
        {
            dataNode: "cost_center_id",
            width: "34.5%"
        },
        {
            dataNode: "std",
            width: "20.5%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        }
    ]
};

export const renderListCostAllocation = [
    {
        id: 1,
        Terms: "6 Years",
        CostCenter: "Home Video-Dopm",
        STD: "10%",


    },
    {
        id: 2,
        Terms: "6 Years",
        CostCenter: "Home Video-Intl",
        STD: "25%",


    },
    {
        id: 3,
        Terms: "6 Years",
        CostCenter: "Digital Domestic",
        STD: "10%",


    }


]


export const optionCostAllocationCongif = {
    headings: [
        {
            headingLabel: "Rights",
            inputType: "select",
            selectOptions: [],
            width: "32%",
            icon: ""
        },
        {
            headingLabel: "Share%",
            inputType: "text",
            width: "11%",
            icon: ""
        },
        {
            headingLabel: "Rcv Co",
            inputType: "text",
            width: "11%",
            icon: ""
        },
        {
            headingLabel: "GL Code",
            inputType: "text",
            width: "11%",
            icon: ""
        },
        {
            headingLabel: "CC Code",
            inputType: "text",
            width: "11%",
            icon: ""
        },
        {
            headingLabel: "Loc Code",
            inputType: "text",
            width: "14%",
            icon: ""
        },
        {
            headingLabel: "",
            inputType: "",
            width: "10%",
            icon: ""
        },
    ],
    dataNodes: ["right_id", "share", "receiving_code", "gl_code", "cc_code", "loc_code"],
    primaryKey: "show_season_option_cost_allocation_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "right_id",
            width: "31%"
        },
        {
            dataNode: "share",
            width: "10.5%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "receiving_code",
            width: "10.5%"
        },
        {
            dataNode: "gl_code",
            width: "10.5%"
        },
        {
            dataNode: "cc_code",
            width: "10.5%"
        },
        {
            dataNode: "loc_code",
            width: "14.5%"
        },
    ]
};
export const renderListOptionalCostAllocation = [
    {
        id: 1,
        rights: "1YR DOWNLOAD",
        Share: "50.00",
        RcvCo: "1",
        GLCode: "",
        CCCode: "",
        LocCode: ""
    },
    {
        id: 2,
        rights: "EXTEND INITIAL TO PERP",
        Share: "50.00",
        RcvCo: "2",
        GLCode: "",
        CCCode: "",
        LocCode: ""
    }
]

export const newShowSeasonPostJson = {
    "type": null,
    "mpm": null,
    "copy_season_flag": 0,
    "copy_main_title_flag": 0,
    "copy_business_affairs_flag": 0,
    "pay_increase": null,
    "old_season_id": null,
    'new_season_id': null
}

export const intialEpisodeDetails = {
    "show_season_id": null,
    "show_id": null,
    "show_name": null,
    "season_id": null,
    "season_name": null,
    "episode_number": null,
    "episode_title": null,
    "code": null,
    "main_title_song_id": null,
    "song_title": null,
    "air_date": null,
    "ngtd_4646": null,
    "ngtd_4675": null,
    "is_ngtd_new": 0,
    "is_no_mt": 1,
    "is_budget_difference": 0,
    "episode_cost": [],
    "episode_cost_centers": [{
        "cost_center_id": null,
        "budget": null,
        "show_season_episode_cost_center_budget_id": null
    }],
    "is_music_replaced": null,
    "is_excl_from_budget_to_efc_report": null,
    "is_mixed": null,
    "notes": null,
    "episode_efc_notes": [],
    "is_completed": 0,
    "is_active": null,
    "tenant_id": null,
    "division_id": null,
    "is_pilot": 0
}
export const episodeEFCNotesJSON={ show_season_episode_efc_notes_id: null, notes: null, is_new: 0,is_delete:0 }
export const EpisodeCostCenterConfig = {
    cost_center_id: null,
    budget: null
}
export const EpisodeCostConfig = {
    cost: null,
    description: null,
}
export const intialShowSeasonRecords = {
    // "show_season_id": null,
    "show_id": null,
    "type": null,
    "synopsis": null,
    "new_season_id": null,
    // "division_id": null,
    "old_season_id": null

}
export const pilotMaintananceJSON = {
    "show_season_pilot_id": null,
    "show_season_id": null,
    "season_id": null,
    "show_id": null,
    "customize_title": null,
    "is_this_not_pilot": 0,
    "production_entity_id": null,
    "network_id": null,
    "script_date": null,
    "rights_discount": null,
    "apply_to": null,
    "marketing_cost": null,
    "production_executive_name": null,
    "production_executive_email": null,
    "estimator_name": null,
    "estimator_email": null,
    "developer_executive_name": null,
    "notes": null,
    "is_active": null,
    "tenant_id": null,
    "division_id": null,
    "executive_producers": []
}
export const mockExecutiveProducer = {
    "show_season_pilot_executive_producer_id": null,
    "executive_producer": null
}

export const networkList = [
    {
        value: 1,
        label: 'network 1'
    },
    {
        value: 2,
        label: 'network 2'
    },
    {
        value: 3,
        label: 'network 3'
    },
]

export const productionEntityList = [
    {
        value: 1,
        label: 'production 1'
    },
    {
        value: 2,
        label: 'production 2'
    },
    {
        value: 3,
        label: 'production 3'
    },
]