import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
// import TestBasicComp from '../pages/TestBasicComp/TestBasicComp';
import UserRoleManagement from '../pages/UserManagement/UserManagement';
import DashboardContainer from "../components/Dashboard/DashboardContainer"
import EpisodeAndClearence from '../components/EpisodeAndClearence/EpisodeAndClearenceDetails/EpisodeAndClearence';
import ShowSeasonDetails from '../components/ShowSeason/ShowSeasonDetails/ShowSeasonDetails';
import { withUserContext } from '../contexts/UserContext';
import GlobalSearchResult from '../components/SharedComponents/AsynchronousSerach/GlobalSearchResults';
import CuesheetDashboard from '../components/EpisodeAndClearence/EpisodeAndClearenceDetails/CuesheetDashboard';


export default withOktaAuth(withUserContext(class Routes extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Switch>
        <Route exact={true} path='/' render={(props) => <DashboardContainer {...props} toggleRefreshPage={this.props.toggleRefreshPage} refreshPage={this.props?.refreshPage}/>} />
        {/* <Route exact={true} path='/test-basic-comp' render={(props) => <TestBasicComp {...props} />} /> */}
        <Route exact={true} path='/userRoleManagement' render={(props) => <UserRoleManagement {...props} />} />
        <Route exact={true} path='/episodeAndClearance/ss/:ssid/ep/:epid' render={(props) => <EpisodeAndClearence {...props} {...this.props.functions} toggleRefreshPage={this.props.toggleRefreshPage} refreshPage={this.props?.refreshPage} />} />
        <Route exact={true} path='/showseason/:id' render={(props) => <ShowSeasonDetails {...props} {...this.props.functions} toggleRefreshPage={this.props.toggleRefreshPage} refreshPage={this.props?.refreshPage}/> } />
        <Route exact={true} path='/userSearch/:category/:keyWord' render={(props) => <GlobalSearchResult {...props} />} />
        <Route exact={true} path='/cueSheet' render={(props) => <CuesheetDashboard {...props} userContext={this.props.userContext}/>} 
        />

      </Switch>
    );
  }
}
));