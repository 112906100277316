import React from "react";
import { MDBContainer} from "mdb-react-ui-kit";
import { withUserContext } from "../../contexts/UserContext";
import "./DashboardContainer.scss";
import ContentHeaderFilter from "./ContentHeaderFilter";
import SearchResults from "./SearchResults";
import { tableConfig, filterObj, tableData } from "./Config";
import { showSeasonSelectedFilters, showSeasonsDetailsConfig } from "./ShowSeasons/config";
import { payeesSelectedFilters, payeeRecordJSON } from './Payees/config';
import { songLibraryJson, songLibraryFilter } from './SongLibrary/Config';
import { musicEditorFilter, musicEditorConfig } from './MusicEditor/Config'
import { licenseTrackingFilter, licenseTrackingConfig } from './LicenseTracking/config'
import ClearanceRecords from "./ClearanceRecords/ClearanceRecords";
import ShowSeasons from "./ShowSeasons/ShowSeasons";
import Licensors from "./Licensors/Licensors";
import LicenseTracking from "./LicenseTracking/LicenseTracking";
import Payees from "./Payees/Payees";
import Rights from "./Rights/Rights";
import SongLibrary from "./SongLibrary/SongLibrary";
import CueSheets from "./CueSheets/CueSheets";
import MusicEditor from "./MusicEditor/MusicEditor";
import Budget from "./Budget/Budget";
import TvMusicBudget from "../Navigation/TvMusicBudget/TvMusicBudget";
import Reports from "../Navigation/Reports/Reports";
import AdminSetup from "../AdminSetup/AdminSetup";
import LookupMaintenance from "../Navigation/LookupMaintenance/LookupMaintenance";
import clearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { clearanceRecordsFilter, clearenceRecordJSON } from "./ClearanceRecords/Config";
import { handlePaginationList, updateTableSortConfigObject } from "../../Common/TableHelper";
import { licensorsFilter, licensorRecordJson } from './Licensors/config';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import { cueSheetsFilter, cueSheetsFilterJSON } from './CueSheets/Config';
import { budgetFilter, budgetRecordJson } from './Budget/Config';
import { rightsFilter, rightsRecordJson } from './Rights/config';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LetterSetup from "../LetterSetup/LetterSetup"
import { peopleConfig, peopleFilter } from "./People/config";
import People from "./People/People";
import axios from 'axios';
import { dashboardCreateEntityPermissions } from './Config';
let defaultSort, currentSortBy, selectedItem = {}, songModalPermissions, peopleModalPopupPermissions;
export default withUserContext(
    class DashboardContainer extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                filterSearchList: [],
                sectionName: "",
                tableConfig: {},
                tableData: [],
                list1: [],
                peopleId: null,
                clearFilters: false,
                showSeasonSelectedFilters: JSON.parse(
                    JSON.stringify({ ...showSeasonSelectedFilters })
                ),
                clearanceRecordsFilter: JSON.parse(
                    JSON.stringify({ ...clearanceRecordsFilter })
                ),
                payeesSelectedFilters: JSON.parse(JSON.stringify({ ...payeesSelectedFilters })),
                licensorSelectedFilters: JSON.parse(JSON.stringify({ ...licensorsFilter })),
                songLibraryFilters: JSON.parse(JSON.stringify({ ...songLibraryFilter })),
                licenseTrackingFilter: JSON.parse(JSON.stringify({ ...licenseTrackingFilter })),
                cueSheetsFilter: JSON.parse(JSON.stringify({ ...cueSheetsFilter })),
                musicEditorFilter: JSON.parse(JSON.stringify({ ...musicEditorFilter })),
                budgetSelectedFilters: JSON.parse(JSON.stringify({ ...budgetFilter })),
                rightsSelectedFilters: JSON.parse(JSON.stringify({ ...rightsFilter })),
                licenseTrackingSelectedFilters: JSON.parse(JSON.stringify({ ...licenseTrackingFilter })),
                peopleFilter: JSON.parse(JSON.stringify({ ...peopleFilter })),
                filterObj: {},
                filterList: [],
                isLoading: false,
                searchShowDetails: null,
                rowsPerPage: 25,
                page: 1,
                selectedFilterData: null,
                currentList: [],
                showError: false,
                showSuccess: false,
                showFail: false,
                saveFlag: true,
                currentList: [],
                no_of_records: 0,
                sortBy: "Value",
                isSortByAsc: true,
                sortCount: 0,
                mode: null,
                showPayeeModal: false,
                showId: null,
                setModal: null,
                classification: null,
                showComposerModal: false,
                musicEditorsModal: false,
                openFromDashboard: false,
                showEditSeasonModal: false,
                openImportSeason: false,
                lookupPermissions: [],
                commonPermissions: [],
                clearance_divisionId: null,
                clearFilters: false,
                gt1_valid: true,
                gt2_valid: true,
                gt3_valid: true,
                sessionCostValid: true,
                performerValid: true,
                recordingValid: true,
                showSesonIdToEdit: null,
                isSearchData: false,
                initialRecordData: {},
                clearanceRedirect: [],
                showPersonModal: false,
                payeePermissions:[],
                searchCancelToken: null,
                anyErrorMessage:null,
            };
        }
        componentDidMount() {
            if (this.props.userContext.dashboardName && this.props.userContext.sectionName) {
                let path = this.props.userContext.dashboardName === "Show Seasons" ? "dashboardShowSeasons" : this.props.userContext.dashboardName === "Clearance Records" ? "dashboardClearanceRecords" : this.props.userContext.sectionName === "payees" ? "dashboardPayees" : this.props.userContext.dashboardName === "Licensors" ? "dashboardLicensors" : this.props.userContext.sectionName === "songLibrary" ? "dashboardSongs" : this.props.userContext.sectionName === "cueSheets" ? "dashboardCueSheets" : this.props?.userContext.sectionName === "musicEditor" ? "dashboardMusicEditorsShowComposers" : this.props.userContext.sectionName === "budget" ? "dashboardBudgets" : this.props.userContext.sectionName === "rights" ? "dashboardRights" : this.props.userContext.sectionName === "licenseTracking" ? "dashboardLicenseTracking" : this.props.userContext.sectionName === "people" ? "dashboardMusicEditorsShowComposers" : null;
                let mockJSON = this.props.userContext.sectionName === "clearanceRecords" ? clearenceRecordJSON : this.props.userContext.sectionName === "payees" ? payeeRecordJSON : this.props.userContext.sectionName === "showSeasons" ? showSeasonsDetailsConfig : this.props.userContext.sectionName === "licensors" ? licensorRecordJson : this.props.userContext.sectionName === "songLibrary" ? songLibraryJson : this.props.userContext.sectionName === "cueSheets" ? cueSheetsFilterJSON : this.props?.userContext.sectionName === "musicEditor" ? musicEditorConfig : this.props.userContext.sectionName === "budget" ? budgetRecordJson : this.props.userContext.sectionName === "rights" ? rightsRecordJson : this.props.userContext.sectionName === "licenseTracking" ? licenseTrackingConfig : this.props.userContext.sectionName === "people" ? peopleConfig : null;
                this.setState({ sortBy: mockJSON.order_by_clause })
                if (path && this.props?.userContext?.active_tenant?.tenant_id) {
                    this.getFilterData(mockJSON, path)
                    // this.getRecentlyAccessedShows("userRecentlyAccessedShows")
                }
            }
            if(this.props?.userContext?.user_profile?.is_user_provisioner === 1 ){
                this.props.history.push('/userRoleManagement');
            }
            // if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
            //     this.props.history.push('/userRoleManagement');
            // }
            if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Editor')) {
                this.props.history.push('/cueSheet');
            }
        }
        // shouldComponentUpdate(nextProps) {
        //     if (nextProps !== this.state) {
        //         return true;
        //     }
        //     else { return false; }
        // }
        componentDidUpdate(prevProps, prevState) {
            if (this.props?.userContext?.user_profile?.is_user_provisioner
                && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
                this.props.history.push('/userRoleManagement');
            }
            if ((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
                && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Editor')) {
                this.props.history.push('/cueSheet');
            }
            if (prevProps.userContext.sectionName !== this.props.userContext.sectionName || prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
                this.setState({ page: 1, rowsPerPage: 25, selectedFilterData: [] })
                let path = this.props.userContext.dashboardName === "Show Seasons" ? "dashboardShowSeasons" : this.props.userContext.dashboardName === "Clearance Records" ? "dashboardClearanceRecords" : this.props.userContext.sectionName === "payees" ? "dashboardPayees" : this.props.userContext.dashboardName === "Licensors" ? "dashboardLicensors" : this.props.userContext.sectionName === "songLibrary" ? "dashboardSongs" : this.props.userContext.sectionName === "cueSheets" ? "dashboardCueSheets" : this.props?.userContext.sectionName === "musicEditor" ? "dashboardMusicEditorsShowComposers" : this.props.userContext.sectionName === "budget" ? "dashboardBudgets" : this.props.userContext.sectionName === "rights" ? "dashboardRights" : this.props.userContext.sectionName === "licenseTracking" ? "dashboardLicenseTracking" : this.props.userContext.sectionName === "people" ? "dashboardMusicEditorsShowComposers" : null
                let mockJSON = this.props.userContext.sectionName === "clearanceRecords" ? clearenceRecordJSON : this.props.userContext.sectionName === "payees" ? payeeRecordJSON : this.props.userContext.sectionName === "showSeasons" ? showSeasonsDetailsConfig : this.props.userContext.sectionName === "licensors" ? licensorRecordJson : this.props.userContext.sectionName === "songLibrary" ? songLibraryJson : this.props.userContext.sectionName === "cueSheets" ? cueSheetsFilterJSON : this.props?.userContext.sectionName === "musicEditor" ? musicEditorConfig : this.props.userContext.sectionName === "budget" ? budgetRecordJson : this.props.userContext.sectionName === "rights" ? rightsRecordJson : this.props.userContext.sectionName === "licenseTracking" ? licenseTrackingConfig : this.props.userContext.sectionName === "people" ? peopleConfig : null;
                defaultSort = this.props.userContext.dashboardName === "Show Seasons" ? "Show" : this.props.userContext.dashboardName === "Clearance Records" ? "Show/Season" : this.props.userContext.sectionName === "payees" ? "Contact" : this.props.userContext.dashboardName === "Licensors" ? "Licensor" : this.props.userContext.sectionName === "songLibrary" ? "Song Title" : this.props.userContext.sectionName === "cueSheets" ? "Show" : this.props?.userContext.sectionName === "musicEditor" ? "Name" : this.props.userContext.sectionName === "budget" ? "Show" : this.props.userContext.sectionName === "rights" ? "Show" : this.props.userContext.sectionName === "licenseTracking" ? "Show" : null
                // this.getRecentlyAccessedShows("userRecentlyAccessedShows")
                if (path) {
                    mockJSON.order_by_clause = defaultSort
                    mockJSON.page_count = this.state.rowsPerPage
                    currentSortBy = this.state.tableConfig.headings?.find(item => item.icon != "")?.headingLabel
                    if (currentSortBy) {
                        this.setState({ sortBy: currentSortBy, tableConfig: updateTableSortConfigObject(this.state.tableConfig, true, currentSortBy, defaultSort) }, () => {
                            this.handleClearFilter()
                            // this.props.userContext.sectionName === "payees" ? this.handleClearFilter() : this.props.userContext.sectionName === "licenseTracking" ? null : this.handleClearFilter();
                        })
                    }
                    else {
                        this.props.userContext.sectionName === "payees" ? this.setState({ sortBy: mockJSON.order_by_clause, tableConfig: updateTableSortConfigObject(this.state.tableConfig, true, mockJSON.order_by_clause, defaultSort) }, () => this.handleClearFilter()) : this.setState({ sortBy: mockJSON.order_by_clause, tableConfig: updateTableSortConfigObject(this.state.tableConfig, true, this.state.sortBy, defaultSort) }, () => this.handleClearFilter());
                    }
                }
            }
            if (prevProps?.userContext?.active_tenant?.common_permissions?.length != this.props?.userContext?.active_tenant?.common_permissions?.length || prevState?.commonPermissions?.length === 0) {
                let common_permissions = this.props?.userContext?.active_tenant?.common_permissions
                let lookupMaintenancePermissions = this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_group_name === "Lookup Maintenance")
                let payeePermissions=this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_name === "Clearance Payees")
                songModalPermissions = this.props?.userContext?.active_tenant?.common_permissions?.find(item => item.permission_group_name === "Songs")
                peopleModalPopupPermissions = this.props?.userContext?.active_tenant?.common_permissions?.find((item) => item.permission_group_name === "People (SS)")
                this.setState({ lookupPermissions: lookupMaintenancePermissions, commonPermissions: common_permissions,payeePermissions:payeePermissions })
            }
        }

        // getRecentlyAccessedShows = (path) => {
        //     this.setState({ isLoadingShows: true })
        //     clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/${path}`, this.props.userContext?.active_tenant?.tenant_id)
        //         .then((response) => {
        //             this.setState({
        //                 accessedShowsData: response.data, isLoadingShows: false
        //             });
        //         })
        //         .catch((err) => {
        //             this.setState({ isLoadingShows: false })
        //             console.log("error", err);
        //         });
        // }

        updateListState = (value, node, list) => {
            this.setState((prevState) => ({
                [list]: {
                    ...prevState[list],
                    [node]: value,
                },
                gt1_valid: true,
                gt2_valid: true,
                gt3_valid: true,
                sessionCostValid: true,
                performerValid: true,
                recordingValid: true
            }));
        };

        handleFilterListSelection = (type, val, name, node) => {
            let list = this.state.filterList.filter((item) => item.type !== type);
            this.setState({ filterList: list }, () => {
                if (name) {
                    this.setState((prevState) => ({
                        filterList: [
                            ...prevState.filterList,
                            {
                                type: type,
                                label: name,
                                value: val,
                                node: node,
                            },
                        ],
                    }));
                }
            });
        };
        updateFilteredList = (filteredData, selectedFilterObject) => {
            this.setState({
                filterList: filteredData,
                selectedFilterData: selectedFilterObject,
                page: 1
            });
        };
        handleFilterSearch = () => {
            this.setState({
                gt3_valid: false,
                gt1_valid: false,
                gt2_valid: false,
                sessionCostValid: false,
                performerValid: false,
                recordingValid: false,
                isSearchData: true
            })
            // if (this.state.gt1_valid === false || this.state.gt2_valid === false || this.state.gt3_valid === false || this.state.performerValid === false || this.state.recordingValid === false || this.state.sessionCostValid === false) {
            let path = this.props.userContext.dashboardName === "Show Seasons" ? "dashboardShowSeasons" : this.props.userContext.dashboardName === "Clearance Records" ? "dashboardClearanceRecords" : this.props.userContext.sectionName === "payees" ? "dashboardPayees" : this.props.userContext.dashboardName === "Licensors" ? "dashboardLicensors" : this.props.userContext.sectionName === "songLibrary" ? "dashboardSongs" : this.props.userContext.sectionName === "cueSheets" ? "dashboardCueSheets" : this.props.userContext.dashboardName === "Music Supervisors/Show Composers" ? "dashboardMusicEditorsShowComposers" : this.props.userContext.sectionName === "budget" ? "dashboardBudgets" : this.props.userContext.sectionName === "rights" ? "dashboardRights" : this.props.userContext.sectionName === "licenseTracking" ? "dashboardLicenseTracking" : this.props.userContext.sectionName === "people" ? "dashboardMusicEditorsShowComposers" : null
            if (path != null && this.state.selectedFilterData != null) {
                let mockJSON = { ...this.state.selectedFilterData }
                mockJSON.page_no = this.state.page
                mockJSON.page_count = this.state.rowsPerPage
                let gt1_valid = this.props.userContext.sectionName !== "budget" ? true : mockJSON?.gt1_search_ids && (mockJSON?.performer_cost_search_text === null || mockJSON?.performer_cost_search_text === "") === true ? false : true
                let gt2_valid = this.props.userContext.sectionName !== "budget" ? true : mockJSON?.gt2_search_ids && (mockJSON?.record_cost_search_text === null || mockJSON?.record_cost_search_text === "") === true ? false : true
                let gt3_valid = this.props.userContext.sectionName !== "budget" ? true : mockJSON?.gt3_search_ids && (mockJSON?.session_cost_search_text === null || mockJSON?.session_cost_search_text === "") === true ? false : true
                let canFilter = this.props.userContext.sectionName === "budget" ? gt1_valid && gt2_valid && gt3_valid : true
                this.props.userContext.sectionName === "budget" ? this.setState({ gt1_valid: gt1_valid, gt2_valid: gt2_valid, gt3_valid: gt3_valid }) : null
                if (canFilter) {
                    this.getFilterData(mockJSON, path);
                }
            }
            if (this.state.selectedFilterData === null) {
                let mockJSON = this.props.userContext.sectionName === "clearanceRecords" ? clearenceRecordJSON : this.props.userContext.sectionName === "payees" ? payeeRecordJSON : this.props.userContext.sectionName === "showSeasons" ? showSeasonsDetailsConfig : this.props.userContext.sectionName === "licensors" ? licensorRecordJson : this.props.userContext.sectionName === "songLibrary" ? songLibraryJson : this.props.userContext.sectionName === "cueSheets" ? cueSheetsFilterJSON : this.props?.userContext.sectionName === "musicEditor" ? musicEditorConfig : this.props.userContext.sectionName === "budget" ? budgetRecordJson : this.props.userContext.sectionName === "rights" ? rightsRecordJson : this.props.userContext.sectionName === "licenseTracking" ? licenseTrackingFilter : this.props.userContext.sectionName === "people" ? peopleConfig : null
                mockJSON.page_no = this.state.page
                mockJSON.page_count = this.state.rowsPerPage
                this.getFilterData(mockJSON, path);
            }
            // }
        };

        handleFilterListDeletion = (obj) => {
            if (this.props.userContext.dashboardName === "Show Seasons") {
                this.updateListState(null, obj?.node, "showSeasonSelectedFilters");
            } else if (this.props.userContext.dashboardName === "Clearance Records") {
                this.updateListState(null, obj?.node, "clearanceRecordsFilter");
            } else if (this.props.userContext.dashboardName === "Payees") {
                this.updateListState(null, obj?.node, "payeesSelectedFilters");
            } else if (this.props.userContext.dashboardName === "Licensors") {
                this.updateListState(null, obj?.node, "licensorSelectedFilters");
            } else if (this.props.userContext.dashboardName === "Songs") {
                this.updateListState(null, obj?.node, "songLibraryFilters");
            } else if (this.props.userContext.dashboardName === "Cue Sheets") {
                this.updateListState(null, obj?.node, "cueSheetsFilter");
            } else if (this.props.userContext.dashboardName === "Music Supervisors/Show Composers") {
                this.updateListState(null, obj?.node, "musicEditorFilter");
            } else if (this.props.userContext.dashboardName === "Music Editor") {
                this.updateListState(null, obj?.node, "musicPeopleFilter");
            } else if (this.props.userContext.dashboardName === "TV Creative Music Budget") {
                this.updateListState(null, obj?.node, "budgetSelectedFilters");
            } else if (this.props.userContext.dashboardName === "Rights") {
                this.updateListState(null, obj?.node, "rightsSelectedFilters");
            } else if (this.props.userContext.dashboardName === "License Tracking") {
                this.updateListState(null, obj?.node, "licenseTrackingFilter");
            } else if (this.props.userContext.dashboardName === "People") {
                this.updateListState(null, obj?.node, "peopleFilter");
            }
            let list = this.state.filterList.filter((item) => item.node !== obj.node);
            this.setState({ filterList: list });
        };

        getFilterData = (selectedFilters, path) => {
            if(this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");
            var cancelToken = axios.CancelToken.source();
            this.setState({searchCancelToken: cancelToken, isLoading: true});
            clearTrackService.getDataParamsWithCancel(Constants.ClearTrackServiceBaseUrl + `/${path}`, selectedFilters, this.props.userContext?.active_tenant?.tenant_id, null, cancelToken)
                .then((response) => {
                    let data = this.props.userContext.dashboardName === "Show Seasons" ?  response.data?.records?.map(item => {
                        let newItem = {...item}
                        newItem.season_name = item?.favourite_flag === 1 ? item?.season_name+'*' : item?.season_name
                        return newItem
                    }):[...response.data?.records]
                    this.setState({
                        tableData: data,
                        no_of_records: response.data?.query_record_count,
                        // list1:handlePaginationList(this.state.page, this.state.rowsPerPage, response.data?.records),
                        currentList: response.data?.records,
                        isLoading: false,
                        isSearchData: false
                    });
                })
                .catch((err) => {
                    this.setState({ isLoading: false, isSearchData: false });
                    console.log("error", err);
                });
        }

        handleClearFilter = () => {
            let tableSortIcon = this.state.tableConfig.headings?.find(item => item.icon != "")?.headingLabel
            this.setState({
                filterList: [],
                tableData: [],
                selectedFilterData: null,
                no_of_records: 0,
                rowsPerPage: 25,
                page: 1,
                clearFilters: !this.state.clearFilters,
                sortBy: defaultSort,
                tableConfig: updateTableSortConfigObject(this.state.tableConfig, true, tableSortIcon, defaultSort)
            });
            if (this.props.userContext.sectionName === "songLibrary") {
                this.setState({ songLibraryFilters: JSON.parse(JSON.stringify({ ...songLibraryFilter })) })
                this.getFilterData(songLibraryJson, "dashboardSongs");
            }
            if (this.props.userContext.sectionName === "licenseTracking") {
                this.setState({ licenseTrackingFilter: JSON.parse(JSON.stringify({ ...licenseTrackingFilter })) })
                this.getFilterData(licenseTrackingConfig, "dashboardLicenseTracking");
            }
            if (this.props.userContext.sectionName === "cueSheets") {
                this.setState({ cueSheetsFilter: JSON.parse(JSON.stringify({ ...cueSheetsFilter })) })
                this.getFilterData(cueSheetsFilterJSON, "dashboardCueSheets");
            }
            if (this.props.userContext.sectionName === "musicEditor") {
                this.setState({ musicEditorFilter: JSON.parse(JSON.stringify({ ...musicEditorFilter })) })
                this.getFilterData(musicEditorConfig, "dashboardMusicEditorsShowComposers");
            }
            else if (this.props.userContext.sectionName === "budget") {
                this.setState({ budgetSelectedFilters: JSON.parse(JSON.stringify({ ...budgetFilter })) })
                this.getFilterData(budgetRecordJson, "dashboardBudgets");
            }
            else if (this.props.userContext.sectionName === "showSeasons") {
                this.setState({ showSeasonSelectedFilters: JSON.parse(JSON.stringify({ ...showSeasonSelectedFilters })) })
                this.getFilterData(showSeasonsDetailsConfig, "dashboardShowSeasons");
            }
            else if (this.props.userContext.sectionName === "licensors") {
                this.setState({ licensorSelectedFilters: JSON.parse(JSON.stringify({ ...licensorsFilter })) })
                this.getFilterData(licensorRecordJson, "dashboardLicensors");
            }
            else if (this.props.userContext.sectionName === "clearanceRecords") {
                this.setState({ clearanceRecordsFilter: JSON.parse(JSON.stringify({ ...clearanceRecordsFilter })) })
                this.getFilterData(clearenceRecordJSON, "dashboardClearanceRecords");
            }
            else if (this.props.userContext.sectionName === "rights") {
                this.setState({ rightsSelectedFilters: JSON.parse(JSON.stringify({ ...rightsFilter })) })
                this.getFilterData(rightsRecordJson, "dashboardRights");
            }
            else if (this.props.userContext.sectionName === "payees") {
                this.setState({ payeesSelectedFilters: JSON.parse(JSON.stringify({ ...payeesSelectedFilters })) })
                this.getFilterData(payeeRecordJSON, "dashboardPayees");
            }
            else if (this.props.userContext.sectionName === "people") {
                this.setState({ peopleFilter: JSON.parse(JSON.stringify({ ...peopleFilter })) })
                this.getFilterData(peopleConfig, "dashboardMusicEditorsShowComposers");
            }
        };

        static getDerivedStateFromProps(props, state) {
            if (props?.userContext.sectionName !== state?.sectionName) {
                let tableConfigName = `${props?.userContext.sectionName}TblConfig`;
                let filterObjName = `${props?.userContext.sectionName}Filter`;
                let tableDataName = `${props?.userContext.sectionName}DataConfig`;
                return {
                    sectionName: props?.userContext.sectionName,
                    tableConfig: tableConfig[tableConfigName],
                    filterObj: filterObj[filterObjName],
                    tableData: tableData[tableDataName],
                    no_of_records: 0,
                    rowsPerPage: 25,
                    page: 1,
                };
            } else {
                return null
            }
        }
        handleChangeFilter(field, value) {
            this.setState((prevState) => ({
                filterObj: {
                    ...prevState.filterObj,
                    [field]: value,
                },
                postInitiated: false,
            }));
        }

        getDashboardFilterContent = () => {
            switch (this.props.userContext.dashboardId) {
                case 1:
                    return <ShowSeasons
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        showSeasonSelectedFilters={this.state.showSeasonSelectedFilters}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        selectedFilterData={this.state.selectedFilterData}
                        clearFilters={this.state.clearFilters}
                        sortBy={this.state.sortBy}
                    />
                    break;
                case 2:
                    return <ClearanceRecords
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        clearanceRecordsFilter={this.state.clearanceRecordsFilter}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        clearFilters={this.state.clearFilters}
                        sortBy={this.state.sortBy}
                        handleFilterSearch={this.handleFilterSearch}
                    />
                    break;
                case 3:
                    return <LicenseTracking
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        licenseTrackingFilter={this.state.licenseTrackingFilter}
                        page={this.state.page}
                        clearFilters={this.state.clearFilters}
                        rowsPerPage={this.state.rowsPerPage}
                        sortBy={this.state.sortBy} />
                    break;
                case 4: return <Rights
                    updateFilteredList={this.updateFilteredList}
                    updateListState={this.updateListState}
                    rightsSelectedFilters={this.state.rightsSelectedFilters}
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    clearFilters={this.state.clearFilters}
                    sortBy={this.state.sortBy}
                />
                    break;
                case 5:
                    return <CueSheets
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        cueSheetsFilter={this.state.cueSheetsFilter}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        clearFilters={this.state.clearFilters}
                        sortBy={this.state.sortBy}
                    />
                    break;
                case 6:
                    return <Budget
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        budgetSelectedFilters={this.state.budgetSelectedFilters}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        clearFilters={this.state.clearFilters}
                        sortBy={this.state.sortBy}
                        gt1_valid={this.state.gt1_valid}
                        gt2_valid={this.state.gt2_valid}
                        gt3_valid={this.state.gt3_valid}
                        sessionCostValid={this.state.sessionCostValid}
                        recordingValid={this.state.recordingValid}
                        performerValid={this.state.performerValid}
                    />
                    break;
                case 7:
                    return <SongLibrary
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        songLibraryFilters={this.state.songLibraryFilters}
                        page={this.state.page}
                        clearFilters={this.state.clearFilters}
                        sortBy={this.state.sortBy}
                        rowsPerPage={this.state.rowsPerPage} />
                    break;
                case 8:
                    return <Licensors licensorSelectedFilters={this.state.licensorSelectedFilters}
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        clearFilters={this.state.clearFilters}
                        sortBy={this.state.sortBy}
                    />
                    break;
                
                case 9: return <Payees
                    updateFilteredList={this.updateFilteredList}
                    updateListState={this.updateListState}
                    payeesSelectedFilters={this.state.payeesSelectedFilters}
                    page={this.state.page}
                    clearFilters={this.state.clearFilters}
                    rowsPerPage={this.state.rowsPerPage}
                    sortBy={this.state.sortBy}
                />
                    break;
                case 10:
                    return <MusicEditor
                        onChangeHandler={this.handleChangeFilter.bind(this)} musicEditorFields={this.state?.filterObj}
                        updateFilteredList={this.updateFilteredList}
                        updateListState={this.updateListState}
                        musicEditorFilter={this.state.musicEditorFilter}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        clearFilters={this.state.clearFilters}
                        sortBy={this.state.sortBy}
                    />
                    break;
                case 11:
                    return <People
                    updateFilteredList={this.updateFilteredList}
                    updateListState={this.updateListState}
                    peopleFilter={this.state.peopleFilter}
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    clearFilters={this.state.clearFilters}
                    sortBy={this.state.sortBy}
                />
                    break;
                case 12: return <LookupMaintenance lookupMaintenancePermissions={this.state.lookupPermissions} />
                    break;
                case 13: return <Reports />
                    break;
                case 16: return <TvMusicBudget notificationComponent={this.notificationComponent} />
                    break;
                case 17: return <LetterSetup userContext={this.props?.userContext}/>
                    break;

                case 18: return <AdminSetup />
                    break;
            }
        }

        handleSortColumn = (column) => {
            if (column === this.state.sortBy) {
                this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                    this.handleTableSort(column);
                })
            }
            else this.setState({ sortCount: 0 }, () => {
                this.handleTableSort(column);
            })
        }

        handleTableSort = (colName) => {
            let isSortByAsc = this.state.isSortByAsc;
            isSortByAsc = this.state.sortCount % 2 === 0 ? true : false,
                this.setState({
                    isSortByAsc,
                    page:1,
                    tableConfig: updateTableSortConfigObject(this.state.tableConfig, isSortByAsc, this.state.sortBy, colName)
                }, () => { this.sortMethod(colName) })
        }

        sortMethod = (colName) => {
            let path = this.props.userContext.dashboardName === "Show Seasons" ? "dashboardShowSeasons" : this.props.userContext.dashboardName === "Clearance Records" ? "dashboardClearanceRecords" : this.props.userContext.sectionName === "payees" ? "dashboardPayees" : this.props.userContext.dashboardName === "Licensors" ? "dashboardLicensors" : this.props.userContext.sectionName === "songLibrary" ? "dashboardSongs" : this.props.userContext.sectionName === "cueSheets" ? "dashboardCueSheets" : this.props.userContext.dashboardName === "Music Supervisors/Show Composers" ? "dashboardMusicEditorsShowComposers" : this.props.userContext.sectionName === "budget" ? "dashboardBudgets" : this.props.userContext.sectionName === "rights" ? "dashboardRights" : this.props.userContext.sectionName === "licenseTracking" ? "dashboardLicenseTracking" : this.props.userContext.sectionName === "people" ? "dashboardMusicEditorsShowComposers" : null;
            if (path != null && this.state.selectedFilterData != null) {
                let mockJSON = { ...this.state.selectedFilterData }
                mockJSON.order_by = this.state.isSortByAsc === true ? "ASC" : "DESC";
                mockJSON.order_by_clause = colName
                mockJSON.page_count = this.state.rowsPerPage
                this.setState({
                    sortBy: colName
                }, () => this.getFilterData(mockJSON, path))
            }
            if (this.state.selectedFilterData === null) {
                let mockJSON = this.props.userContext.sectionName === "clearanceRecords" ? clearenceRecordJSON : this.props.userContext.sectionName === "payees" ? payeeRecordJSON : this.props.userContext.sectionName === "showSeasons" ? showSeasonsDetailsConfig : this.props.userContext.sectionName === "licensors" ? licensorRecordJson : this.props.userContext.sectionName === "songLibrary" ? songLibraryJson : this.props.userContext.sectionName === "cueSheets" ? cueSheetsFilterJSON : this.props?.userContext.sectionName === "musicEditor" ? musicEditorConfig : this.props.userContext.sectionName === "budget" ? budgetRecordJson : this.props.userContext.sectionName === "rights" ? rightsRecordJson : this.props.userContext.sectionName === "licenseTracking" ? licenseTrackingConfig : this.props.userContext.sectionName === "people" ? peopleConfig : null
                mockJSON.order_by = this.state.isSortByAsc === true ? "ASC" : "DESC";
                mockJSON.order_by_clause = colName
                mockJSON.page_count = this.state.rowsPerPage
                this.setState({
                    sortBy: colName
                }, () => this.getFilterData(mockJSON, path))
            }
        }

        handleEdit = (field, value) => {
            this.setState((prevState) => ({ searchShowDetails: { ...prevState.searchShowDetails, [field]: value } }))
            if (this.state.searchShowDetails?.show_id !== null) {
                this.props?.history.push(`/showseason/${value}`)
            }
        };
        handleChangePage = (event, newPage) => {
            this.setState({ page: newPage + 1 }, () => { this.handleFilterSearch() });
        };
        handleChangeRowsPerPage = (event) => {
            this.setState({
                rowsPerPage: event.target.value, page: 1
            }, () => { this.handleFilterSearch() });
        }

        closPopup = () => {
            this.setState({ showError: false, showFail: false, showSuccess: false });
        }
        handleResetNotify = () => {
            this.setState({ showFail: false, showSuccess: false , anyErrorMessage:null});
            if (this.state.saveFlag) {
                this.closPopup();
            }
        }
        notificationComponent = (value, mode = "fail", anyErrorMessage = null) => {
            switch (mode) {
                case "success": return this.setState({ showSuccess: value });
                case "fail": return this.setState({ showFail: value , anyErrorMessage:anyErrorMessage});
            }
        }

        handleClickModal = (event) => {
            this.setState({
                setModal: event.currentTarget
            })
        };

        handleCloseModal = () => {
            this.setState({
                setModal: null
            })
        };

        handleGeneralEdit = (field, value) => {
            console.log("value", value)
            let musicEditor = "Music Editor";
            let showComposer = "Show Composer";
            value === "MusicEditors" ? this.setState({ showComposerModal: true, setModal: false, modeType: musicEditor }) : this.setState({ showComposerModal: true, setModal: false, modeType: showComposer })

        }
        handleShowComposerModal = (dataItem, Id, mode) => {
            (dataItem.people_type === "SHOW_COMPOSER_HB" || dataItem.people_type === "SHOW_COMPOSER" || dataItem.people_type === "MUSIC_SUPERVISOR") && this.setState({ showComposerModal: true, peopleId: Id, modeType: dataItem.people_type, mode });
        }
        handleShowPersonModal = (dataItem, Id, mode) => {
            (dataItem.people_type === "SHOW_COMPOSER_HB" || dataItem.people_type === "SHOW_COMPOSER" || dataItem.people_type === "MUSIC_SUPERVISOR" || dataItem.people_type === "MUSIC_EDITOR") && this.setState({ showPersonModal: true, peopleId: Id, modeType: dataItem.people_type, mode });
        }
        handleShareClick = (Id, dataItem, dataItem2) => {
            this.setState({ mode: "Edit" })
            console.log("share", Id, dataItem, dataItem2, this.state.sectionName)
            let popupModal = this.state.sectionName === "payees" ? this.setState({ showPayeeModal: true, showId: Id }) :
                this.state.sectionName === "licensors" ? this.setState({ showLicensorModal: true, showId: Id })
                    : this.state.sectionName === "songLibrary" ? this.setState({ showNewSongModal: true, showId: Id })
                        : this.state.sectionName === "clearanceRecords" ? dataItem["episode_number"] === "" ? window.open(`/showseason/${dataItem.show_season_id}`, '_blank') : window.open(`/episodeAndClearance/ss/${dataItem.show_season_id}/ep/${dataItem.show_season_episode_id}`, dataItem?.clearance_id)
                            // this.setState({ showNewClearanceModal: true, showId: Id, clearenceId: Id, openFromDashboard: false, clearance_divisionId: dataItem.division_id })
                            : this.state.sectionName === "showSeasons" ? window.open(`/showseason/${dataItem.show_season_id}`, '_blank')
                                // this.setState({ showEditSeasonModal: true, showId: Id })
                                : this.state.sectionName === "cueSheets" ? window.open(`/showseason/${dataItem.show_season_id}`, 're-route to Cue sheets') : this.state.sectionName === "budget" ? window.open(`/showseason/${dataItem.show_season_id}`, 're-route creative budget') :
                                        this.state.sectionName === "licenseTracking" || this.state.sectionName === "rights" ? dataItem["episode_number"] === "" ? window.open(`/showseason/${dataItem.show_season_id}`, '_blank') : dataItem["clearance_id"] !== null ? window.open(`/episodeAndClearance/ss/${dataItem.show_season_id}/ep/${dataItem.show_season_episode_id}`, dataItem?.clearance_id) : window.open(`/showseason/${dataItem.show_season_id}`, 're-route')
                                            : null
            if (this.state.sectionName === "musicEditor") {
                this.handleShowComposerModal(dataItem, Id, "Edit");
                //   console.log("data",data)
            }
            if (this.state.sectionName === "people") {
                this.handleShowPersonModal(dataItem, Id, "Edit");
            }
        }
        handleEditSeasonDetailsShareClick = (Id, dataItem, dataItem2) => {
            console.log("Id in handleEditSeasonDetailsShareClick", Id, "dataItem", dataItem, "dataItem2", dataItem2);
            window.open(`/showseason/${dataItem.show_season_id}`, '_blank');
            // let showSesonIdToEdit = null;
            // this.setState({ mode: "Edit" })
            // console.log("share", Id, "dataItem", dataItem, "dataItem2", dataItem2, "this.state.sectionName", this.state.sectionName);
            // selectedItem = dataItem;
            // showSesonIdToEdit = dataItem.show_season_id;
            // this.setState({ showEditSeasonModal: true, showSesonIdToEdit: showSesonIdToEdit, showId: showSesonIdToEdit });
        }

        handleCreateNewModal = (event) => {
            console.log("New")
            this.setState({ mode: "New" })
            let createModal = this.state.sectionName === "payees" ? this.setState({ showPayeeModal: true })
                : this.state.sectionName === "licensors" ? this.setState({ showLicensorModal: true }) : this.state.sectionName === "musicEditor" ? this.handleClickModal(event) : this.state.sectionName === "songLibrary" ? this.setState({ showNewSongModal: true })
                    : this.state.sectionName === "clearanceRecords" ? this.setState({ showNewClearanceModal: true, openFromDashboard: true }) : this.state.sectionName === "showSeasons" ? this.setState({ showSeasonModal: true }) : null
        }
        // getChildDetails = (initialRecordData) => {
        //     this.setState({ initialRecordData });
        // }
        render() {
            let editClearanceModalPermission = this.state?.commonPermissions?.find((item) => dashboardCreateEntityPermissions?.find(per => per.buttonName === "Create New" && per.headerName.includes(this.props.userContext.dashboardName) && per.permissions.includes(item.permission_name)))
            console.log('check here the notification', this.state.showFail)
            let openModal = Boolean(this.state.setModal);
            let idModal = open ? 'modal-popover' : undefined;
            let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
            let messageNotification = this.state.anyErrorMessage ? this.state.anyErrorMessage : this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
            return (
                <div> 
                    <div>
                        <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                            handleResetNotify={this.handleResetNotify.bind(this)} />
                    </div>
                    {
                        this.props.userContext.dashboardId != 12 &&
                        this.props.userContext.dashboardId != 13 &&
                        this.props.userContext.dashboardId != 14 &&
                        this.props.userContext.dashboardId != 15 &&
                        this.props.userContext.dashboardId != 16 &&
                        this.props.userContext.dashboardId != 17 &&
                        this.props.userContext.dashboardId != 18 ?  (
                            <>
                                <MDBContainer fluid className="DashboardContainer">
                                    <div className="dashboardContent">
                                        <ContentHeaderFilter
                                            heading={this.props.userContext.dashboardName}
                                            filters={[{ key: 'id', value: '1' }]}
                                            // filterList={this.state.filterList || []}
                                            // handleChipClose={this.handleFilterListDeletion}
                                            clearFilter={this.handleClearFilter}
                                            handleFilterSearch={this.handleFilterSearch}
                                            handleADDR={(e) => this.setState({ openImportSeason: true })}
                                            handleCreateNewModal={(event) => this.handleCreateNewModal(event)}
                                            handleShowComposerModal={this.handleShowComposerModal}
                                            handleShowPersonModal={this.handleShowPersonModal}
                                            commonPermissions={this.state.commonPermissions}
                                            selectedFilterData={this.state.selectedFilterData}
                                            isLoading={this.state.isSearchData}
                                            songModalPermissions={songModalPermissions}
                                            peopleModalPopupPermissions={peopleModalPopupPermissions}
                                        />
                                        <div>{this.getDashboardFilterContent()}</div>
                                        <br />
                                        <SearchResults
                                            config={this.state?.tableConfig}
                                            listData={this.state?.tableData?.length > 0 ? this.state?.tableData : []}
                                            currentList={this.state.no_of_records}
                                            isLoading={this.state.isLoading}
                                            handleChangePage={this.handleChangePage}
                                            page={this.state.page}
                                            rowsPerPage={this.state.rowsPerPage}
                                            history={this.props.history}
                                            handleChipClose={this.handleFilterListDeletion}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            handleSortColumn={this.handleSortColumn}
                                            sectionName={this.props?.userContext?.sectionName}
                                            handleShareClick={(event, dataItem) => this.handleShareClick(event, dataItem, this)}
                                            toggleRefreshPage={this.props.toggleRefreshPage}
                                            refreshPage={this.props?.refreshPage}
                                            openPersonModal={this.state.showPersonModal}
                                            commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
                                            songModalPermissions={songModalPermissions}
                                            peopleModalPopupPermissions={peopleModalPopupPermissions}
                                            handleFilterSearch={this.handleFilterSearch}
                                        />
                                    </div>
                                </MDBContainer>
                            </>
                        ) : (
                            <>
                                <div>
                                    <div>{this.getDashboardFilterContent()}</div>
                                </div>
                            </>
                        )}
                    <Popover
                        id={idModal}
                        open={openModal}
                        className={"pop-over"}
                        anchorEl={this.state.setModal}
                        onClose={this.handleCloseModal}
                        anchorReference="anchorOrigin"
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom "
                        }}
                        transformOrigin={{
                            horizontal: "left",
                            vertical: "top"
                        }}
                        PaperProps={{ style: { width: '16%' } }}
                        anchorPosition={{ top: 700, left: 300 }}
                    >
                        <RadioGroup
                            aria-label="Classification"
                            value={this.state.classification}
                            onChange={(e) => this.handleGeneralEdit('classification', e.target.value)}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="MusicEditors" control={<Radio size="small" />} label="Music Editors" />
                            <FormControlLabel value="ShowComposer" control={<Radio size="small" />} label="Show Composer" />
                        </RadioGroup>
                    </Popover>
                    {
                        this.state.showPayeeModal &&
                        <MainViewModalComponent
                            open={this.state.showPayeeModal}
                            handleClose={() => this.setState({ showPayeeModal: false })}
                            headerText={"Payee"}
                            mode={this.state.mode}
                            modalName={"Payee"}
                            id={this.state.showId}
                            modeType="CLEARANCE_PAYEE"
                            openFromDashboard={this.state.mode === 'New' ? true : false}
                            handleSubmit={() => {
                                let mockJSON = { ...this.state.selectedFilterData }
                                mockJSON.page_no = this.state.page
                                mockJSON.page_count = this.state.rowsPerPage
                                this.getFilterData(mockJSON, "dashboardPayees");
                            }}
                            commonPermissions={this.state.payeePermissions}
                        />
                    }

                    {
                        this.state.showComposerModal &&
                        <MainViewModalComponent
                            open={this.state.showComposerModal}
                            handleClose={() => this.setState({ showComposerModal: false, peopleId: null })}
                            headerText={`${this.state.modeType === "SHOW_COMPOSER" && "Show Composer" || this.state.modeType === "MUSIC_SUPERVISOR" && "Music Supervisor"}`}
                            mode={this.state.mode}
                            modeType={this.state.modeType}
                            modalName={`${this.state.modeType === "SHOW_COMPOSER" && "Show Composer" || this.state.modeType === "MUSIC_SUPERVISOR" && "Music Supervisor"}`}
                            show_season_id={null}
                            people_id={this.state.mode === "New" ? null : this.state.peopleId}
                            show_id={null}
                            season_id={null}
                            division_id={null}
                            showSecondtab={this.state.mode === "Edit" ? true : false}
                            origin={"dashboard"}
                            handleSubmit={() => {
                                let mockJSON = { ...this.state.selectedFilterData }
                                mockJSON.page_no = this.state.page
                                mockJSON.page_count = this.state.rowsPerPage
                                this.getFilterData(mockJSON, "dashboardMusicEditorsShowComposers");
                                this.setState({ showComposerModal: false, peopleId: null })
                            }}
                            commonPermissions={this.state.commonPermissions}
                            handleShareClick={(event, dataItem) => this.handleEditSeasonDetailsShareClick(event, dataItem, this)}
                            
                        />
                    }
                    {
                        this.state.showPersonModal &&
                        <MainViewModalComponent
                            open={this.state.showPersonModal}
                            handleClose={() => this.setState({ showPersonModal: false, peopleId: null })}
                            headerText={`Person- ${this.state.modeType === "SHOW_COMPOSER" && "Show Composer" || this.state.modeType === "MUSIC_SUPERVISOR" && "Music Supervisor" || this.state.modeType === "MUSIC_EDITOR" && "Music Editor"}`}
                            mode={this.state.mode}
                            modeType={`${this.state.modeType === "SHOW_COMPOSER" && "Show Composer" || this.state.modeType === "MUSIC_SUPERVISOR" && "Music Supervisor" || this.state.modeType === "MUSIC_EDITOR" && "Music Editor"}`}
                            modalName={"Person"}
                            show_season_id={null}
                            people_id={this.state.mode === "New" ? null : this.state.peopleId}
                            show_id={null}
                            season_id={null}
                            division_id={null}
                            is_dashboard={1}
                            showSecondtab={this.state.mode === "Edit" ? true : false}
                            origin={"dashboard"}
                            commonPermissions={this.state.commonPermissions}
                            peopleModalPopupPermissions={peopleModalPopupPermissions}
                        />
                    }
                    {
                        this.state.showLicensorModal &&
                        <MainViewModalComponent
                            open={this.state.showLicensorModal}
                            handleClose={() => this.setState({ showLicensorModal: false })}
                            headerText={"Licensor"}
                            mode={this.state.mode}
                            modalName={"Edit Licensor"}
                            id={this.state.showId}
                            commonPermissions={this.state.commonPermissions}
                        />
                    }
                    {
                        this.state.showNewSongModal &&
                        <MainViewModalComponent
                            open={this.state.showNewSongModal}
                            handleClose={() => this.setState({ showNewSongModal: false })}
                            headerText={"Song"}
                            mode={this.state.mode}
                            modalName={"Song"}
                            songId={this.state.showId}
                            commonPermissions={this.state.commonPermissions}
                        />
                    }
                    {
                        this.state.showNewClearanceModal &&
                        <MainViewModalComponent
                            open={this.state.showNewClearanceModal}
                            handleClose={() => this.setState({ showNewClearanceModal: false }, () => {
                                if (this.state.clearanceRedirect.length > 0) {
                                    this.props?.history?.push(`/episodeAndClearance/ss/${this.state.clearanceRedirect[0]}/ep/${this.state.clearanceRedirect[1]}`, this.state.clearanceRedirect[2])
                                }
                            })}
                            headerText={"Clearance Record"}
                            handleSubmit={(ssId, epId, clId) => this.setState({ clearanceRedirect: [ssId, epId, clId] })}
                            mode={this.state.mode}
                            modalName={"Clearance Record"}
                            clearenceId={this.state.clearenceId}
                            from={this.state.openFromDashboard}
                            clearance_divisionId={this.state.clearance_divisionId}
                            commonPermissions={this.state.commonPermissions}
                            viewNewCRPermission={editClearanceModalPermission}
                            applyHVPermission={this.props?.userContext?.active_tenant?.common_permissions?.find(item => item?.permission_name === 'Status, CR Options (MFN, Apply $ to HV, Recording Share)')}
                            clearanceRecordsFilter={this.state.clearanceRecordsFilter}
                        />
                    }
                    {
                        this.state.showEditSeasonModal &&
                        <MainViewModalComponent
                            open={this.state.showEditSeasonModal}
                            handleClose={() => this.setState({ showEditSeasonModal: false, showId: null })}
                            headerText={"Show Details"}
                            mode={"Edit"}
                            modalName={"Edit ShowDetails"}
                            show_season_id={this.state.showId}
                            // initialRecordData={this.state.initialRecordData}
                            fetchData={() => this.setState({ showEditSeasonModal: false, showId: null })}
                            permissionArr={this.state.sectionName === "musicEditor" ? this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === selectedItem.division_id)?.permissions?.filter(ele => ele?.permission_group_name === 'Show Season - Details tab') : this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === this.state?.tableData?.find(item => item?.show_season_id === this.state?.showId)?.division_id)?.permissions?.filter(ele => ele?.permission_group_name === 'Show Season - Details tab')}
                            origin={"dashboard"}
                            notificationComponent={this.notificationComponent.bind(this)}
                        />
                    }
                    {
                        this.state.showSeasonModal &&
                        <MainViewModalComponent
                            open={this.state.showSeasonModal}
                            handleClose={() => this.setState({ showSeasonModal: false })}
                            headerText={"Show Season"}
                            mode={this.state.mode}
                            modalName={"Show Season"}
                            show_id={this.state.showId}
                        />
                    }
                    {
                        this.state.openImportSeason &&
                        <MainViewModalComponent
                            open={this.state.openImportSeason}
                            handleClose={() => this.setState({ openImportSeason: false })}
                            headerText={"Import Season"}
                            mode={""}
                            modalName={"Import Season"}
                            handleSubmit={() => {
                                let mockJSON = { ...this.state.selectedFilterData }
                                mockJSON.page_no = this.state.page
                                mockJSON.page_count = this.state.rowsPerPage
                                this.getFilterData(mockJSON, "dashboardShowSeasons");
                            }}
                        />
                    }
                </div>
            );
        }
    }
);
