export const composerConfig = {
	"composer_id": null,
	"composer_name": null,
	"affiliation_id": null,
	"affiliation_name": null,
	"percentage": null,
	"index": 1,
    "is_delete": 0,
    "selected_composer": null,
    "selected_affiliation": null,
}

export const initialComposers = {
    composers: [{
        "composer_id": 1,
        "composer_name": "Hans Zimmerman",
        "affiliation_name": "ASCAP",
        "selected_affiliation": { value: 1, text: "ASCAP" },
        "affiliation_id": 1,
        "pct_value": '50.00'
    },
    {
        "composer_id": 2,
        "composer_name": "John Smith",
        "affiliation_name": "BMI",
        "selected_affiliation": { value: 2, text: "BMI" },
        "affiliation_id": 2,
        "pct_value": '30.00'
    },
    {
        "composer_id": 3,
        "composer_name": null,
        "affiliation_name": null,
        "selected_affiliation": {},
        "affiliation_id": null,
        "pct_value": ''
    }]
}
