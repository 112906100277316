import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from '../../contexts/UserContext';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class LicenseTrackingActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            actionNotExist: false,
            actionDuplicate: false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config: JSON.parse(JSON.stringify({ ...this.props?.config }))
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps?.list !== this.props?.list && this.props?.list?.length > 0) {
            let configRecord = {...this.state.config}
            this.props?.permissionArray?.edit === 1 ? configRecord.actions = ["pen"] : configRecord.actions = []
            this.setState({renderList:this.props.list,sortCount:0,sortBy:null,isSortByAsc: true, config: configRecord}, () => {
                this.handleTableColumnClick('Action')
            })
        }
    }


    handleInlineEdits = (id, dataItem) => {
        let licenseTrackingActions = [...this.state?.renderList]
        this.setState({
            actionNotExist: false,
            renderList: licenseTrackingActions.map((item) => {
                let newItem = { ...item }
                if (item.id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({ actionNotExist: false, actionDuplicate: false })


        if (!data[index]?.action || data[index]?.action?.length == 0) {
            this.setState({ actionNotExist: true })
        } else if (data[index]?.action && this.state?.renderList?.some((item, i) => data[index]?.action?.toLowerCase() === item?.action?.toLowerCase() && index != i)) {
            this.setState({ actionDuplicate: true })
        } else {
            let licenseTrackingActions = [...this.state?.renderList]
            licenseTrackingActions[index].id = data[index].id,
                licenseTrackingActions[index].action = data[index].action || null,
                licenseTrackingActions[index].is_active = data[index].is_active ? 1 : 0
            this.setState({
                renderList: licenseTrackingActions.map((item) => {
                    if (item.id == pkValue)
                        item['editing'] = false;
                    return item
                })
            })
            let editedList = this.state.renderList.filter(item => item.id === pkValue);
            let list = {
                id: editedList[0].id,
                name: editedList[0].action || null,
                is_active: editedList[0].is_active ? 1 : 0
            }
            this.props.handleSave(list, "lu_license_tracking_actions", list?.id);
        }



    }

    handleAddNewItem = (newDataItem) => {
        this.setState({ actionNotExist: false,actionDuplicate:false })
        if (Object.keys(newDataItem)?.length == 0 || !newDataItem?.action || newDataItem?.action?.length == 0) {           
            this.setState({ actionNotExist: true })
        } 
        else if (newDataItem?.action && this.state?.renderList?.some(item=>item?.action?.toLowerCase() == newDataItem?.action?.toLowerCase())){
            this.setState({actionDuplicate:true})
        }
        else {
            let addList = {
                id: null,
                name: newDataItem.action || null,
                is_active: 1,
            }
            this.props.handleSave(addList, "lu_license_tracking_actions", 'newItemPost');
        }
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
            this.setState({
                sortBy: colName,
                config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, this.state.sortBy, colName),
                renderList: tableSortList(colName === 'Active' ? 'Number' : "Name", this.getSortNode(colName), this.state?.renderList, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Action": return "action";
            case "Active": return "is_active";
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="detailContainer">
                <MDBRow>
                    <span className="description">Available actions for tracking licenses on Clearance Records.</span>
                </MDBRow>
                <MDBRow className='pd'>
                    <span className="errorText">{this.state.actionNotExist && 'Please enter action field value'}</span>
                    <span className="errorText">{this.state.actionDuplicate && 'Action is already exist'}</span>
                </MDBRow>
                {this.props?.loadingOptions ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                    ) : (
                        <MDBRow className="tableComponent">
                            <TableComponent
                                newIsActive={true}
                                list={this.state?.renderList || []}
                                config={this.state?.config}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1 ? true : false}
                                postInitiated={this.props?.isSaveData}
                               // isLoading={this.props?.isSaveData}
                                readCallback={(index) => {
                                    let licenseTrackingOptions = [...this.state.renderList];
                                    licenseTrackingOptions[index].editing = false
                                    this.setState({ renderList: licenseTrackingOptions, actionNotExist:false });
                                  }}
                                stickyHeader={true}
                                sortCallback={this.handleTableColumnClick}
                            />
                        </MDBRow>
                    )
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(LicenseTrackingActions);