import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel'
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import DateField from "../../SharedComponents/DateField/DateField"
import TimePicker from 'rc-time-picker';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { typeOptions, musicEditorFilter, filterConfig, gtOptions } from './Config';
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import { withUserContext } from '../../../contexts/UserContext';
import axios from 'axios';
import '../DashboardContainer.scss';
import InputAdornment from "@material-ui/core/InputAdornment"

export default withUserContext(class MusicEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // type: typeOptons,
            // typeOptions: typeOptions,
            // showsOptions: [],
            filterSearchList: [],
            clearFilters: this.props?.clearFilters,
            sortBy: "Name",
            musicEditorFilter: JSON.parse(JSON.stringify({ ...musicEditorFilter })),
            selectedShowValue: null,
            filterList: {
                stateList: [],
                countrytList: [],
                showOptions: [],
                typeOptions: typeOptions,
                gtOptions: gtOptions,
                seasonList: [],
                unionOptions: [],
                showBasedSeasonList: []
            }
        }
    }

    componentDidMount() {
        document.title = 'Music Editor'
        if(this.props?.userContext?.active_tenant?.tenant_id) {
        this.fetchStaticData("lu_state", "stateList");
        this.fetchStaticData("lu_country", "countrytList");
        this.getEntity('SEASON', filterConfig.season, 'seasonList');
        this.fetchStaticData("lu_union", "unionOptions");
        this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }}

    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.fetchStaticData("lu_state", "stateList");
            this.fetchStaticData("lu_country", "countrytList");
            this.getEntity('SEASON', filterConfig.season, 'seasonList');
            this.fetchStaticData("lu_union", "unionOptions");
            this.getShows("SHOW", filterConfig.show, "showOptions", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Name" })
        }
        if (this.props?.userContext.dashboardName === "Music Supervisors/Show Composers" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    getEntity = (entity, config, node) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext.active_tenant.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        label: item[config.name],
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }

    getShows = (entity, config, node, value) => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            let option = {
                "searchString": value
            }
            if (this.state.searchCancelToken != null)
                this.state.searchCancelToken.cancel("Operation canceled due to new request");
            var cancelToken = axios.CancelToken.source();
            this.setState({ searchCancelToken: cancelToken });
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${value}`, cancelToken, this.props.userContext.active_tenant.tenant_id, null)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name],
                    }));
                    this.updateListState(formattedList, node, "filterList");
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }
    selectedShowBasedSeason = (newValue) => {
        this.props.updateListState(
            null,
            "season",
            "musicEditorFilter"
        );
        this.handleFilterListSelection(
            "season",
            null,
            null,
            "season"
        );
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/seasonsBasedOnShows?show_id=${newValue.value}`,
                this.props?.userContext?.active_tenant?.tenant_id
            ).then(
                (response) => {
                    let selectedShow = response?.data[0]?.seasons?.find(
                        (item) => response?.data[0]?.show_id === newValue.value
                    )?.show_season_id;
                    let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                        (item, index, data) =>
                            index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                    ) : [];
                    let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                        value: item.season_id,
                        label: item.season_name,
                    })) : [];
                    let seasonBasedEpisodes = response?.data[0]?.seasons;
                    this.setState({ seasonBasedEpisodes })
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                    }
                })
            .catch(error => {
                console.log("error--", error)
            })

    }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }

    fetchStaticData = (entity, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }

    handleFilterListSelection = (type, val, name, node) => {
        let list = this.state.filterSearchList.filter(item => item.type !== type);
        this.setState({ filterSearchList: list });
        let filteredMusicList = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }

        let check = filteredMusicList.filter((item, index) => {
            if (item.type === type) {
                return filteredMusicList[index] = newFilterValue
            }
        })
        if (check.length === 0) {
            filteredMusicList.push(newFilterValue)
        }

        this.setState({
            filterSearchList: filteredMusicList
        })
        let musicEditorDetails = {
            sc_ms_flag: filteredMusicList.find((item) => item.type === "type")?.value === null ? 1 : 0,
            type_search_ids: filteredMusicList.find((item) => item.type === "type")?.value || null,
            full_name_search_text: filteredMusicList.find((item) => item.type === "fullName")?.label || null,
            email_search_text: filteredMusicList.find((item) => item.type === "email")?.label || null,
            work_phone_search_text: filteredMusicList.find((item) => item.type === "workPhone")?.label || null,
            cell_phone_search_text: filteredMusicList.find((item) => item.type === "cellPhone")?.label || null,
            federal_id_no_search_text: filteredMusicList.find((item) => item.type === "fedaralIdNo")?.label || null,
            address_1_search_text: filteredMusicList.find((item) => item.type === "addressName")?.label || null,
            address_2_search_text: filteredMusicList.find((item) => item.type === "streetAddress")?.label || null,
            city_search_text: filteredMusicList.find((item) => item.type === "city")?.label || null,
            state_search_ids: filteredMusicList.find((item) => item.type === "state")?.label || null,
            country_search_ids: filteredMusicList.find((item) => item.type === "selectedCountry")?.value || null,
            zip_search_text: filteredMusicList.find((item) => item.type === "zipcode")?.label || null,
            show_search_ids: filteredMusicList.find((item) => item.type === "show")?.value || null,
            season_search_ids: filteredMusicList.find((item) => item.type === "season")?.value || null,
            show_year_search_text: filteredMusicList.find((item) => item.type === "Showyr")?.label || null,
            episode_title_search_text: filteredMusicList.find((item) => item.type === "episodeName")?.value || null,
            episode_no_search_text: filteredMusicList.find((item) => item.type === "episode")?.label || null,
            gt_search_ids: filteredMusicList.find((item) => item.type === "selectedgt")?.value || null,
            fee_per_ep_search_text: filteredMusicList.find((item) => item.type === "feePerEpisode")?.label || null,
            union_search_ids: filteredMusicList.find((item) => item.type === "union")?.value || null,
            services_search_text: filteredMusicList.find((item) => item.type === "serviceBeingRecord")?.label || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: 100,
            page_no: 1,
        }
        this.props.updateFilteredList(filteredMusicList, musicEditorDetails)
    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisodes: true });
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
                this.props.userContext?.active_tenant?.tenant_id,
                1
            )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item.show_season_episode_id,
                        label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "seasonBasedEpisodeList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'filterList')
                    }
                },
                (err) => {
                    console.log("Error in fetching Details:", err);
                }
            );
    };

    render() {
        return (
            <div className='MusicEditorContainer filterFieldsContainer'>
                <MDBRow>
                    <MDBCol md={9}>
                        <h6>Details</h6>
                        <MDBRow>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <SelectField
                                            id="type"
                                            placeHolderText="- Type -"
                                            value={this.props?.musicEditorFilter?.type || null}
                                            options={this.state.filterList?.typeOptions || []}
                                            onChange={(e) => {
                                                let selectedValue = this.state.filterList?.typeOptions?.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'type', 'musicEditorFilter')
                                                this.handleFilterListSelection('type', selectedValue?.value, selectedValue?.label, 'selectedValue')
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <BasicTextField
                                        id="fullName"
                                        placeholder="Full Name"
                                        value={this.props.musicEditorFilter?.fullName || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('fullName', 'fullName', e.target.value, 'fullName');
                                            this.props.updateListState(e.target.value, 'fullName', 'musicEditorFilter');
                                        }
                                        }
                                    />
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <BasicTextField
                                        id="email"
                                        placeholder="Email"
                                        value={this.props?.musicEditorFilter?.email || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('email', 'email', e.target.value, 'email');
                                            this.props.updateListState(e.target.value, 'email', 'musicEditorFilter');
                                        }
                                        }
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="workPhone"
                                            placeholder="WorkPhone"
                                            value={this.props?.musicEditorFilter?.workPhone || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('workPhone', 'workPhone', e.target.value, 'workPhone');
                                                this.props.updateListState(e.target.value, 'workPhone', 'musicEditorFilter');
                                            }
                                            }

                                        />
                                    </MDBCol>
                                    <MDBCol>
                                        <BasicTextField
                                            id="cellPhone"
                                            placeholder="Cell phone"
                                            value={this.props?.musicEditorFilter?.cellPhone || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('cellPhone', 'cellPhone', e.target.value, 'cellPhone');
                                                this.props.updateListState(e.target.value, 'cellPhone', 'musicEditorFilter');
                                            }
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="fedaralIdNo"
                                            placeholder="Fedaral Id No"
                                            value={this.props?.musicEditorFilter?.fedaralIdNo || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('fedaralIdNo', 'fedaralIdNo', e.target.value, 'fedaralIdNo');
                                                this.props.updateListState(e.target.value, 'fedaralIdNo', 'musicEditorFilter');
                                            }
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <BasicTextField
                                        id="addressName"
                                        placeholder="Address Name"
                                        value={this.props?.musicEditorFilter?.addressName || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('addressName', 'addressName', e.target.value, 'addressName');
                                            this.props.updateListState(e.target.value, 'addressName', 'musicEditorFilter');
                                        }
                                        }
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <BasicTextField
                                        id="streetAddress"
                                        placeholder="Street Address"
                                        value={this.props?.musicEditorFilter?.streetAddress || ''}
                                        onChange={(e) => {
                                            this.handleFilterListSelection('streetAddress', 'streetAddress', e.target.value, 'streetAddress');
                                            this.props.updateListState(e.target.value, 'streetAddress', 'musicEditorFilter');
                                        }
                                        }
                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="city"
                                            placeholder="City"
                                            value={this.props?.musicEditorFilter?.city || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('city', 'city', e.target.value, 'city');
                                                this.props.updateListState(e.target.value, 'city', 'musicEditorFilter');
                                            }
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={3}>
                                <MDBRow>
                                    <MDBCol md={8}>
                                        {/* <SelectField
                                            id="state"
                                            value={this.props.musicEditorFilter?.selectedState || ""}
                                            options={this.state.filterList?.stateList || []}
                                            placeHolderText="State"
                                            onChange={(e) => {
                                                let selectedValue = this.state.filterList?.stateList?.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'selectedState', 'musicEditorFilter')
                                                this.handleFilterListSelection('selectedState', selectedValue?.value, selectedValue?.label, 'selectedState')
                                            }}
                                        /> */}
                                        <BasicTextField
                                            placeholder="State"
                                            id="state"
                                            value={this.props.musicEditorFilter?.state || ""}
                                            onChange={(e) => {
                                                this.props.updateListState(e.target.value, 'state', 'musicEditorFilter')
                                                this.handleFilterListSelection('state', "state", e.target.value, 'state')
                                            }}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <SelectField
                                        id="country"
                                        placeHolderText="-Country-"
                                        value={this.props.musicEditorFilter?.selectedCountry || ""}
                                        options={this.state.filterList?.countrytList || []}
                                        onChange={(e) => {
                                            let selectedValue = this.state.filterList?.countrytList?.find(item => item.value === e.target.value);
                                            this.props.updateListState(e.target.value, 'selectedCountry', 'musicEditorFilter')
                                            this.handleFilterListSelection('selectedCountry', selectedValue?.value, selectedValue?.label, 'selectedCountry')
                                        }}

                                    />
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            id="zipcode"
                                            placeholder="zipcode"
                                            value={this.props?.musicEditorFilter?.zipcode || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('zipcode', 'zipcode', e.target.value, 'zipcode');
                                                this.props.updateListState(e.target.value, 'zipcode', 'musicEditorFilter');
                                            }
                                            }
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <h6>Details</h6>
                            <MDBCol md={3}>
                                <div className="display-input">
                                    <MDBCol id="zipcode-Field" md={4}>
                                        <SelectField
                                            id="gt"
                                            placeHolderText="gt"
                                            value={this.props.musicEditorFilter?.selectedgt || ""}
                                            options={this.state.filterList?.gtOptions || []}
                                            onChange={(e) => {
                                                let selectedValue = this.state.filterList?.gtOptions?.find(item => item.value === e.target.value);
                                                this.props.updateListState(e.target.value, 'selectedgt', 'musicEditorFilter')
                                                this.handleFilterListSelection('selectedgt', selectedValue?.value, selectedValue?.label, 'selectedgt')
                                            }}
                                        />
                                    </MDBCol>
                                    <MDBCol id="city-Field" md={8}>
                                        <BasicTextField
                                            id="feePerEpisode"
                                            placeholder="Fee Per Episode"
                                            value={this.props?.musicEditorFilter?.feePerEpisode || ''}
                                            onChange={(e) => {
                                                this.handleFilterListSelection('feePerEpisode', 'feePerEpisode', e.target.value, 'feePerEpisode');
                                                this.props.updateListState(e.target.value, 'feePerEpisode', 'musicEditorFilter');
                                            }
                                            }
                                            InputProps={
                                                {
                                                    startAdornment: (
                                                        <InputAdornment position="start" size="small">
                                                            {'$'}
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                        />
                                    </MDBCol>
                                </div>
                            </MDBCol>
                            <MDBCol md={2}>
                                <SelectField
                                    id="union"
                                    placeHolderText="-Union-"
                                    value={this.props?.musicEditorFilter?.union || null}
                                    options={this.state.filterList?.unionOptions || null}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.unionOptions?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'union', 'musicEditorFilter')
                                        this.handleFilterListSelection('union', selectedValue?.value, selectedValue?.label, 'union')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicTextField
                                    id="serviceBeingRecord"
                                    placeholder="Service Being Record"
                                    value={this.props?.musicEditorFilter?.serviceBeingRecord || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('serviceBeingRecord', 'serviceBeingRecord', e.target.value, 'serviceBeingRecord');
                                        this.props.updateListState(e.target.value, 'serviceBeingRecord', 'musicEditorFilter');
                                    }
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={3}>
                        <MDBRow>
                            <MDBCol md={12}>
                                <h6>Show</h6>
                            </MDBCol>
                            <MDBCol md={12}>
                                <SearchSelectField
                                    id={"program"}
                                    placeholder={'- Show -'}
                                    options={this.state.filterList?.showOptions || []}
                                    searchSelect={true}

                                    width={'100%'}
                                    searchText={ev => {
                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                            this.getShows("SHOW", filterConfig.show, "showOptions", ev.target.value);
                                        }
                                    }}
                                    detail_selected={this.props?.musicEditorFilter?.show ? this.state.selectedShowValue : null}
                                    valueSelected={(e, newValue) => {
                                        if (newValue) {
                                            this.setState({ selectedShowValue: newValue })
                                            this.selectedShowBasedSeason(newValue)
                                            //let selectedShow = this.state.filterList?.showOptions?.find(item => item.value === newValue.value);
                                            this.props.updateListState(newValue ? newValue?.value : null, 'show', 'musicEditorFilter')
                                            this.handleFilterListSelection("show", newValue ? newValue?.value : null, newValue ? newValue?.text : null, "show")
                                        } else {
                                            this.setState({ selectedShowValue: null }, () => {
                                                if (!this.state.selectedShowValue) {
                                                    this.props.updateListState(null, 'season', 'musicEditorFilter')
                                                    this.handleFilterListSelection('season', null, null, 'season')
                                                }
                                            })
                                            this.props.updateListState(null, 'show', 'musicEditorFilter')
                                            this.handleFilterListSelection("show", null, null, "show")
                                        }
                                    }
                                    }
                                />
                                {/* <SelectField
                                    id="Show"
                                    value={this.props.musicEditorFilter?.show || ""}
                                    options={this.state.filterList?.showOptions || []}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('show', 'show', e.target.value, 'show');
                                        this.props.updateListState(e.target.value, 'show', 'musicEditorFilter');
                                    }
                                    }
                                /> */}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="lh-1">
                            <MDBCol md={6}>
                                <SelectField
                                    id="Season"
                                    placeHolderText="-Season-"
                                    value={this.props.musicEditorFilter?.season || ""}
                                    options={this.props?.musicEditorFilter?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList || []}
                                    onChange={(e) => {
                                        let selectedSeason = (this.props?.musicEditorFilter?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList)?.find(item => item.value === e.target.value);
                                        this.handleFilterListSelection('season', selectedSeason?.value, selectedSeason?.label, 'season');
                                        this.props.updateListState(e.target.value, 'season', 'musicEditorFilter');
                                        let selectedShowSeason =
                                            this.state.seasonBasedEpisodes?.find(
                                                (item) => item.season_id === e.target.value
                                            )?.show_season_id;
                                        this.setState(
                                            { show_season_id: selectedShowSeason },
                                            () => {
                                                this.getShowBasedEpisodes(this.state.show_season_id);
                                            }
                                        );
                                    }
                                    }
                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicTextField
                                    id="Showyr"
                                    placeholder="Show Yr."
                                    value={this.props?.musicEditorFilter?.Showyr || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('Showyr', 'Showyr', e.target.value, 'Showyr');
                                        this.props.updateListState(e.target.value, 'Showyr', 'musicEditorFilter');
                                    }
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={12}>
                                <SelectField
                                    id="episode"
                                    // label={"Seasons"}
                                    placeHolderText="- Episode Name -"
                                    options={this.props?.musicEditorFilter?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption || []}
                                    width="80%"
                                    value={this.props?.musicEditorFilter?.episodeName || null}
                                    onChange={(e) => {
                                        let selectedValue = (this.props?.musicEditorFilter?.season ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'episodeName', 'musicEditorFilter')
                                        this.handleFilterListSelection('episodeName', selectedValue?.value, selectedValue?.label, 'episodeName')
                                    }}
                                />
                                {/* <BasicTextField
                                    id="Episodename"
                                    placeholder="Episode Name"
                                    value={this.props?.musicEditorFilter?.episodeName || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('episodeName', 'episodeName', e.target.value, 'episodeName');
                                        this.props.updateListState(e.target.value, 'episodeName', 'musicEditorFilter');
                                    }
                                    }
                                /> */}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicTextField
                                    id="Episode"
                                    placeholder="Episode"
                                    value={this.props?.musicEditorFilter?.episode || ''}
                                    onChange={(e) => {
                                        this.handleFilterListSelection('episode', 'episode', e.target.value, 'episode');
                                        this.props.updateListState(e.target.value, 'episode', 'musicEditorFilter');
                                    }
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}
)