export const sendersConfig = {
    headings: [
        {
            headingLabel: "Revision",
            inputType: "text",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "Send Date",
            inputType: "text",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Sender",
            inputType: "text",
            icon: "",
            width: "15%"
        }
    ],
    dataNodes: ["revision", "send_date", "sender"],
    primaryKey: "cue_sheet_distribution_revision_id",
    highlightCell:["revision"]
}


export const initialCueSheetData = {
    show_id: null,
    selected_show: null,
    show_name:null,
    season_id: null,
    show_season_id: null,
    show_season_episode_id: null,
    cueSheet_group: null,
    episodes_list: [],
    show_season_episode_ids: "",
    is_include_licensors: 0,
    Initial: 0,
    alt1: 0,
    alt2:0
    
}
export const airingOptions = [
    { label: "Initial", value: 1 },
    { label: "Alternate 1", value: 2 },
    { label: "Alternate 2", value: 3 },
]