
import React from 'react';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.scss';
import EditorToolbar, {  formats } from "./EditorToolbar";
import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import BasicButton from '../BasicButton/BasicButton';
Quill.register(Quill.import("attributors/style/align"), true);
var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);
class RichTextEditor extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

 

  render(){
      // modules.toolbar.container = `#toolbar${this.props?.id}`
      return (
        <div className="text-editor">
          <div>
            {this.props.alphabetVariantMessage ? <div>
              <span>Please Select Variant</span>
              <div className="variant-button">{this.props.alphaVariants.map((item, index) =>( <><BasicButton text={item} key={"variant" + index}
                onClick={() => { this.props.handleRichTextEditor(null, null, null, this.props.value, item) }} />
              </>
               ) )}
                
              </div>
               
            </div> : null}
          </div>
          <EditorToolbar id={this.props?.id}/>
          <ReactQuill 
            {...(this.props?.value ? {value:this.props?.value} :{})}
            {...(this.props?.defaultValue ? {defaultValue :this.props?.defaultValue } :{})}
            {...(this.props?.onChange ? {onChange:this.props?.onChange} : {})}
            theme="snow"
            formats={formats}
            modules={{toolbar: {
              container: `#toolbar${this.props?.id}`}}}
             />
      </div>
  )}

}
export default RichTextEditor;
      

