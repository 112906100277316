import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialIntlTVDistributionOption,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";

class IntlTVDistributionOption extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      intlTVDistributionOption: initialIntlTVDistributionOption,
      seasonIdValues: [],
      showIdValues: [],
      seasonOptions: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
    };
  }
  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }

  handleSubmitintlTVDistributionOption = () => {
    this.setState({ postInitiated: true, isPosting: true });
    let { intlTVDistributionOption, filterValues, seasonIdValues, showIdValues } = this.state;
    let payload = {
      is_include_inactive_shows: intlTVDistributionOption.is_include_inactive_shows,
      is_with_details: intlTVDistributionOption.is_with_details,
      is_no_notes: intlTVDistributionOption.is_no_notes,
      season_ids: seasonIdValues,
      show_ids: showIdValues
    }
    this.props.generateReport(this.props?.reportName, payload).then(response => {
      if (response.data.error) {
        // this.setState({ isPosting: false })
        // this.props.fieldChanged(true, "reportNotFound");
      }
      else {
        let url = response.data;
        let obj = {
          reportName: this.props?.reportDownloadName,
          production: "",
          season: "",
          episode: "",
          performer: "",
        }
        // this.setState({ isPosting: false })
        let reportNameFinal = genReportName(obj)
        this.props?.downloadUrl(url, reportNameFinal)
      }
    }, (err) => {
      this.setState({ isPosting: false })
      console.log(err)
      // this.props.fieldChanged(true, "reportFail");
    })
  }


  //handleSubmit = () => {
    //console.log("submit", this.state.intlTVDistributionOption);
  //};

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      intlTVDistributionOption: {
        ...prevState.intlTVDistributionOption,
        [field]: value,
      },
    }));
  };
  setSeasonId = (seasonIdValues) => {
    let seasonIdValue = this.state.seasonIdValues;
    seasonIdValue = seasonIdValues;
    this.setState({
      seasonIdValues: seasonIdValue
    })
  }

  setShowId = (showIdValues) => {
    let showIdValue = this.state.showIdValues;
    showIdValue = showIdValues;
    this.setState({
      showIdValues: showIdValue
    })
  }
  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode });
  }

    render() {
    return (
      <>
        {" "}
        <p>
          <b>Intl. TV Distribution - 3 Year All TV Option</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
                // filterValues={this.state.filterValues}
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
            <MDBCol md={7}>
            <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                setSeasonId={this.setSeasonId}
                setShowId={this.setShowId} />
            </MDBCol>
             {/* <BasicLabel text="Seasons" />
              <SearchSelectField
                id={"All Seasons"}
                placeHolderText={"All Seasons"}
                value={{ 'value': this.state.intlTVDistributionOption.selected_season?.value, 'text': this.state.intlTVDistributionOption.selected_season?.text } || {}}
                options={this.state.seasonOption || []}
                onChange={(e, value) => this.handleChange("selected_season", value)}
              />
            </MDBCol>
            <MDBCol md={3}>
              <BasicLabel text="Shows" />
              <SearchSelectField
                id={"All Shows in selected season(s)"}
                placeHolderText={"All Shows in selected season(s)"}
                value={{ 'value': this.state.intlTVDistributionOption.selected_shows?.value, 'text': this.state.intlTVDistributionOption.selected_shows?.text } || {}}
                options={this.state.showOptions || []}
                onChange={(e, value) => this.handleChange("selected_shows", value)}
                  />*/}
            
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Include Inactive Shows"
                label={"Include Inactive Shows"}
                checked={
                  this.state.intlTVDistributionOption
                    ?.is_include_inactive_shows || false
                }
                onChange={(e) =>
                  this.handleChange(
                    "is_include_inactive_shows",
                    e.target.checked
                  )
                }
              />
            </MDBCol>
            <MDBCol md={1}>
              <br />
              <BasicCheckbox
                id="With Details"
                label={"With Details"}
                checked={
                  this.state.intlTVDistributionOption?.is_with_details || false
                }
                onChange={(e) =>
                  this.handleChange("is_with_details", e.target.checked)
                }
              />
            </MDBCol>
            <MDBCol md={1}>
              <br />
              <BasicCheckbox
                id="No Notes"
                label={"No Notes"}
                checked={
                  this.state.intlTVDistributionOption?.is_no_notes || false
                }
                onChange={(e) =>
                  this.handleChange("is_no_notes", e.target.checked)
                }
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={"Generate PDF"} onClick={this.handleSubmitintlTVDistributionOption} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>

    );
  }
}

export default withUserContext(IntlTVDistributionOption);
