import { clearanceRecordsTblConfig, clearanceRecordsFilter } from './ClearanceRecords/Config'
import { showSeasonsTblConfig, showSeasonsFilter } from './ShowSeasons/config'
import { licensorsTblConfig, licensorsFilter, licensorsDataConfig } from './Licensors/config'
import { licenseTrackingTblConfig, licenseTrackingFilter, licenseTrackingDataConfig } from './LicenseTracking/config'
import { payeesTblConfig, payeesFilter, payeesDataConfig } from './Payees/config'
import { rightsTblConfig, rightsFilter, rightsDataConfig } from './Rights/config'
import { songLibraryTblConfig, songLibraryFilter,  songLibraryDataConfig } from './SongLibrary/Config';
import { cueSheetsFilter, cueSheetsTblConfig, cueSheetsDataConfig } from './CueSheets/Config';
import { musicEditorFilter, musicEditorTblConfig, musicEditorDataConfig } from './MusicEditor/Config';
import { budgetFilter, budgetTblConfig, budgetDataConfig } from './Budget/Config';
import { peopleFilter, peopleTblConfig } from './People/config'

export const InitialCardData = [
    {
        title: "Shamless",
        season: "2020-2021",
        clearanceRecords: "108",
        prodEntity: "WB Television"
    },
    {
        title: "Studio 60 on the sunset strip",
        season: "2020-2021",
        clearanceRecords: "100",
        prodEntity: "WB Television"
    },
    {
        title: "Queen Sugar",
        season: "2020-2021",
        clearanceRecords: "10",
        prodEntity: "WB Television"
    },
    {
        title: "[Program Name]",
        season: "[Season]",
        clearanceRecords: "[x]",
        prodEntity: "[Prod Entity]"
    }
]
export const tableConfig = {
    showSeasonsTblConfig: showSeasonsTblConfig,
    clearanceRecordsTblConfig: clearanceRecordsTblConfig,
    licensorsTblConfig: licensorsTblConfig,
    licenseTrackingTblConfig: licenseTrackingTblConfig,
    payeesTblConfig: payeesTblConfig,
    rightsTblConfig: rightsTblConfig,
    songLibraryTblConfig: songLibraryTblConfig,
    cueSheetsTblConfig: cueSheetsTblConfig,
    musicEditorTblConfig: musicEditorTblConfig,
    budgetTblConfig: budgetTblConfig,
    peopleTblConfig:peopleTblConfig
}
export const filterObj = {
    clearanceRecordsFilter: clearanceRecordsFilter,
    showSeasonsFilter: showSeasonsFilter,
    licensorsFilter: licensorsFilter,
    licenseTrackingFilter: licenseTrackingFilter,
    payeesFilter: payeesFilter,
    rightsFilter: rightsFilter,
    songLibraryFilter: songLibraryFilter,
    cueSheeetsFilter: cueSheetsFilter,
    musicEditorFilter: musicEditorFilter,
    budgetFilter: budgetFilter,
    peopleFilter:peopleFilter
}
export const tableData = {
    showSeasonsDataConfig: [],
    clearanceRecordsDataConfig: [],
    licensorsDataConfig: [],
    licenseTrackingDataConfig: [],
    payeesDataConfig: [],
    rightsDataConfig: [],
    songLibraryDataConfig: [],
    cueSheetsDataConfig: [],
    musicEditorDataConfig: [],
    budgetDataConfig: []
}

export const dashboardCreateEntityPermissions =[
    {
        buttonName: "Import ADDR",
        headerName: "Show Seasons",
        permissions: ["New Show Season, Import ADDR, Show Season pop-up"]
    },
    {
        buttonName: "Create New",
        headerName: "Show Seasons",
        permissions: ["New Show Season, Import ADDR, Show Season pop-up"]
    },
    {
        buttonName: "New MS",
        headerName: ["Music Supervisors/Show Composers","People", "People (SC's, MS's, and ME's)"],
        permissions: ["Show Composers/Music Supervisorss (BA)","People (SC's, MS's, and ME's)"]
    },
    {
        buttonName: "Create New",
        headerName: ["Clearance Records"],
        permissions: ["Create a Clearance Record"]
    },
    {
        buttonName: "Create New",
        headerName: ["Songs"],
        permissions:  ["Songs (Reg + PD)"]
    },
    {
        buttonName: "Create New",
        headerName: ["Licensors"],
        permissions: ["Licensors"]
    },
    {
        buttonName: "Create New",
        headerName: ["Payees"],
        permissions: ["Clearance Payees","Music Supervisor/Show Composer Payees (BA)"]
    }   

]

