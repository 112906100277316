import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { businessAffairsDetails } from './Config';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import { withUserContext } from '../../../contexts/UserContext';
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
class BusinessAffairsDeals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditDealModal: false,
            businessAffairsDealsData: this.props?.businessAffairsDealsData,
            show_season_id: this.props?.show_season_id
        }
    }
    handleEditDeal = () => {
        this.setState({ showEditDealModal: true });
    }
    handleHideEditDeal = () => {
        this.setState({ showEditDealModal: false });
    }
    downloadFile = (urlToDownload) => {
        window.open(urlToDownload);
    }
    render() {
        return (
            <MDBContainer className="Details-Container deals">
                {this.props.isSecondaryLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (<>
                    <MDBRow>
                        <MDBCol md={2}>
                            <BasicLabel text={"Status"} />
                            <p className='content-text'>{this.props?.businessAffairsDealsData?.deal_status_name ? this.props?.businessAffairsDealsData?.deal_status_name : "None"}</p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Type"} />
                            <p className='content-text'>{this.props?.businessAffairsDealsData?.deal_type_name ? this.props?.businessAffairsDealsData?.deal_type_name : "None"}</p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Union"} />
                            <p className='content-text'>{this.props?.businessAffairsDealsData?.union_name ? this.props?.businessAffairsDealsData?.union_name : "None"}</p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Fee per Episode"} />
                            <p className='content-text'>{this.props?.businessAffairsDealsData?.fee_per_episode ? this.props?.businessAffairsDealsData?.fee_per_episode : "None"}</p>
                        </MDBCol>
                        <MDBCol md={4}>
                            <BasicLabel text={"Additional Notes"} />
                            <p className='content-text'>{this.props?.businessAffairsDealsData?.additional_notes ? this.props?.businessAffairsDealsData?.additional_notes : "None"}</p>
                        </MDBCol>
                        <div className="editDealBtnContainer">
                            <BasicButton icon="pen" text="Edit Deal" disabled={this.props?.businessAffairsDealsData?.show_season_people_deal_id ? false : true}
                                variant="contained" onClick={this.handleEditDeal} />
                        </div>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={2}>
                            {this.props.viewDealMemoPDF && <>
                                <BasicLabel text={"Deal Memos"} />
                                {this.props.isFileLoading ? (
                                    <div>
                                        <CircularProgress size={24} />
                                    </div>
                                ) : (<>
                                    <MDBRow>
                                        {this.props.filesArr?.length > 0 && this.props.filesArr?.map((item, index) => (
                                            <MDBCol md={12} key={index}>
                                                <div className="files-display">
                                                    <MDBRow>
                                                        <MDBCol md={12}>
                                                            <p className="file-text" onClick={() => this.downloadFile(item.url)}>{item.Key} </p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </div>
                                            </MDBCol>
                                        ))}
                                        {this.props.filesArr.length === 0 && <p className='content-text'> No file present</p>}
                                    </MDBRow>
                                </>)}
                            </>}
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Services Being Rendered"} />
                            <p className='content-text'>{this.props?.businessAffairsDealsData?.service_being_rendered ? this.props?.businessAffairsDealsData?.service_being_rendered : "None"}</p>
                        </MDBCol>
                        <MDBCol md={1}>
                        </MDBCol>
                        <MDBCol md={4}>
                            <BasicLabel text={"Credit"} />
                            <p className='content-text'>{this.props?.businessAffairsDealsData?.credit ? this.props?.businessAffairsDealsData?.credit : "None"}</p>
                        </MDBCol>
                    </MDBRow>
                    {this.props?.detailsToDisplay?.people_type === "SHOW_COMPOSER" && <>
                        <p style={{ fontSize: "16px", fontFamily: "Warner-Bros-Sans", color: "black", marginBottom: "0" }}>Royalties</p>
                        <MDBRow>
                            <MDBCol md={3}>
                                <BasicLabel text={"Record Royalties (Artist)"} />
                                <p className='content-text'>{this.props?.businessAffairsDealsData?.record_royaltie_artist ? this.props?.businessAffairsDealsData?.record_royaltie_artist : "None"}</p>
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicLabel text={"Performing Rights Society"} />
                                <p className='content-text'>{this.props?.businessAffairsDealsData?.performing_rights_society ? this.props?.businessAffairsDealsData?.performing_rights_society : "None"}</p>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={3}>
                                <BasicLabel text={"Record Royalties(Producer)"} />
                                <p className='content-text'>{this.props?.businessAffairsDealsData?.record_royaltie_producer ? this.props?.businessAffairsDealsData?.record_royaltie_producer : "None"}</p>
                            </MDBCol>
                            <MDBCol md={3}>
                                <BasicLabel text={"Federal ID No."} />
                                <p className='content-text'>{this.props?.businessAffairsDealsData?.fedral_id_number ? this.props?.businessAffairsDealsData?.fedral_id_number : "None"}</p>
                            </MDBCol>
                        </MDBRow>
                    </>}
                </>)}
                {
                    this.state.showEditDealModal && (
                        <MainViewModalComponent
                            open={this.state.showEditDealModal}
                            handleClose={this.handleHideEditDeal}
                            headerText={"Edit Deal Details"}
                            mode={""}
                            modalName={"Edit Deal"}
                            show_season_id={this.props?.show_season_id}
                            businessAffairsDealsData={this.props?.businessAffairsDealsData}
                            fetchDealData={this.props.fetchDealData}
                            permissionArr={this.props.permissionArr}
                            people_type={this.props?.detailsToDisplay?.people_type}
                            division_id={this.props.division_id}
                            detailsToDisplay={this.props?.detailsToDisplay}
                        />
                    )
                }
            </MDBContainer >

        )
    }
}

export default withUserContext(BusinessAffairsDeals);