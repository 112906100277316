import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import './EditShowDetails.scss';
import 'rc-time-picker/assets/index.css';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import { withUserContext } from '../../../contexts/UserContext';
import { validateEmail } from '../../../Common/Helper';
import messages from '../../../Common/Messages.json';
var postObj = [], sum, is_initial_rights_updated = 0, is_optional_rights_updated = 0, is_alt_initial_rights_updated = 0, is_cost_center_updated = 0, canEditDefaultRights, currentSeasonOptions = [], initialRecordData = {};
class EditContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPosting: false,
            isLoading: false,
            initialRecordData: this.props?.initialRecordData,
            currentSeasonOptions: [],
            productionEntityOptions: [],
            typeOptions: [],
            networkOptions: [],
            durationOptions: [],
            costCenterOptions: [],
            termOptions: [],
            territoryOptions: [],
            initialRightsOptions: [],
            optionalRightsOptions: [],
            show_season_id: this.props.show_season_id
        };
    }
    // handleSubmit = () => {
    //     this.setState({ isPosting: true })
    //     postObj = JSON.parse(JSON.stringify(this.state.initialRecordData));
    //     delete postObj["company"];
    //     delete postObj["network_name"];
    //     delete postObj["duration_name"];
    //     delete postObj["duration_name"];
    //     delete postObj["seasons"];
    //     if (typeof postObj["initial_term"] === "string") {
    //         postObj["initial_term"] = postObj["initial_term_id"];
    //     }
    //     if (typeof postObj["initial_exp_term"] === "string") {
    //         postObj["initial_exp_term"] = postObj["initial_exp_term_id"];
    //     }
    //     if (typeof postObj["initial_territory"] === "string") {
    //         postObj["initial_territory"] = postObj["initial_territory_id"];
    //     }
    //     if (typeof postObj["alt_initial_term"] === "string") {
    //         postObj["alt_initial_term"] = postObj["alt_initial_term_id"];
    //     }
    //     if (typeof postObj["alt_initial_exp_term"] === "string") {
    //         postObj["alt_initial_exp_term"] = postObj["alt_initial_exp_term_id"];
    //     }
    //     if (typeof postObj["alt_initial_territory"] === "string") {
    //         postObj["alt_initial_territory"] = postObj["alt_initial_territory_id"];
    //     }
    //     let durationTemp = postObj["show_duration_id"];
    //     postObj["duration_id"] = durationTemp;
    //     postObj["is_initial_rights_updated"] = is_initial_rights_updated;
    //     postObj["is_optional_rights_updated"] = is_optional_rights_updated;
    //     postObj["is_alt_initial_rights_updated"] = is_alt_initial_rights_updated;
    //     postObj["production_exc"] = postObj["production_exc_id"];
    //     postObj["clearance_rep"] = postObj["clearance_rep_id"];
    //     postObj["clearance_rep_asst"] = postObj["clearance_rep_asst_id"];
    //     postObj.optional_rights.map((item, index) => {
    //         if (item.right_id == "" || item.right_id == null) {
    //             item.right_id = null;
    //         }
    //         if (item.term == "" || item.term == null) {
    //             item.term = null;
    //         }
    //         if (item.exp_term == "" || item.exp_term == null) {
    //             item.term = null;
    //         }
    //         if (item.territory == "" | item.territory == null) {
    //             item.territory = null;
    //         }
    //         if ((item.right_id == "" || item.right_id == null) && (item.term == "" || item.term == null) && (item.territory == "" | item.territory == null)) {
    //             postObj.optional_rights.splice(index, 1);
    //         }

    //         delete item["right_abbr"];
    //         if (typeof item["term"] === "string") {
    //             item["term"] = item["term_id"];
    //         }
    //         if (typeof item["exp_term"] === "string") {
    //             item["exp_term"] = item["exp_term_id"];
    //         }
    //         if (typeof item["territory"] === "string") {
    //             item["territory"] = item["territory_id"];
    //         }
    //     })
    //     postObj.clearance_budgets.map((item, index) => {
    //         if (item.cost_center_id == "") {
    //             item.cost_center_id = null;
    //         }
    //         if (item.budget == "") {
    //             item.budget = null;
    //         }
    //         if ((item.budget == "" || item.budget == null) && (item.cost_center_id == "" || item.cost_center_id == null)) {
    //             postObj.clearance_budgets.splice(index, 1);
    //         }
    //         delete item["cost_center_name"];
    //         delete item["internal_show_season_clearance_budgets_id"];
    //     })
    //     if (postObj.defaultFromDuration === false) {
    //         let from_duration = String(postObj.from_duration.hour) + ":" + String(postObj.from_duration.min) + ":00";
    //         postObj.from_duration = from_duration;
    //     }
    //     if (postObj.defaultToDuration === false) {
    //         let to_duration = String(postObj.to_duration.hour) + ":" + String(postObj.to_duration.min);
    //         postObj.to_duration = to_duration;
    //     }
    //     if (postObj.defaultFromDuration === true) {
    //         postObj.from_duration = null;
    //     }
    //     if (postObj.defaultToDuration === true) {
    //         postObj.to_duration = null;
    //     }

    //     if (postObj?.mpm?.trim().length == 0) { postObj.mpm = null; }
    //     if (postObj?.proj_eps?.length == 0) { postObj.proj_eps = null; }
    //     if (postObj?.notes?.trim().length == 0) { postObj.notes = null; }
    //     if (postObj.estimating_manager?.trim()?.length == 0) { postObj.estimating_manager = null; }
    //     if (postObj?.estimator?.trim()?.length == 0) { postObj.estimator = null; }
    //     if (postObj?.music_amort_code?.trim()?.length == 0) { postObj.music_amort_code = null; }
    //     if (postObj?.series_amort_code?.trim()?.length == 0) { postObj.series_amort_code = null; }
    //     this.postSeasonData(postObj);
    // };
    // postSeasonData = (postJson) => {
    //     this.setState({ isPosting: true });
    //     let extensionUrl = '/showSeason';
    //     clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.state.initialRecordData.division_id)
    //         .then((response) => {
    //             if (response.data.error) {
    //                 this.setState({ isPosting: false });
    //                 this.props?.notificationComponent(true, "fail");
    //             } else {
    //                 this.setState({ isPosting: false });
    //                 this.props.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
    //                 this.props?.notificationComponent(true, "success");
    //                 console.log("post response", response);
    //             }
    //         },
    //             (err) => {
    //                 this.setState({ isPosting: false });
    //                 this.props?.notificationComponent(true, "fail");
    //                 console.log("Post agency details error: " + err);
    //             });
    // }
    // for text fields change event
    handleChange = (field, value, index, objPassed) => {
        initialRecordData[field] = value;
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                [field]: value
            },
        }));
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }
    onDropdownChange(whatChanged, parentKey, index, keyToModify, valPassed, objPassed) {
        initialRecordData[keyToModify] = valPassed;
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                [keyToModify]: valPassed
            },
        }));
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    componentDidMount() {
        initialRecordData = { ...this.state.initialRecordData };
    }
    render() {
        return (
            <div>
                <MDBContainer fluid className="EditShowDetailsModalContainer">
                    <p className='content'>Budget vs. EFC, EFC, and Cue Sheet Recipients, and Other Contacts can edited in the ‘People’ tab.</p>
                    <MDBRow>
                        <MDBCol md={6} className="secondaryLabelContainer">
                            <SelectField
                                label={"Production Exec"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                //mandatoryWarning={messages.mandatoryWarning} 
                                value={this.state.initialRecordData.production_exc_id || ""}
                                options={this.props.prodExecOptions || []}
                                onChange={(e) => this.onDropdownChange("prodExecChanged", null, null, "production_exc_id", e.target.value, null)}
                            />
                        </MDBCol>
                        <MDBCol md={6} className="secondaryLabelContainer">
                            <BasicTextField
                                label={"Production Executive Email"}
                                placeholder="Production Executive Email"
                                value={this.state.initialRecordData?.production_executive_email || null}
                                fieldValid={this.state.initialRecordData?.production_executive_email ? (!validateEmail(this.state.initialRecordData?.production_executive_email) ? true : false) : false}
                                onChange={(e) =>
                                    this.handleChange(
                                        "production_executive_email",
                                        e.target.value.trim().length === 0 ? null : e.target.value
                                    )
                                }
                                //onChange={(e) => validateEmail(e.target.value) ? this.handleChange("production_executive_email", e.target.value) : null}
                                inValidInput={messages.invalidInput}
                            />
                        </MDBCol>
                    </MDBRow>
                    {/* {Estimating Manager row start} */}
                    <MDBRow>
                        <MDBCol md={4} className="secondaryLabelContainer">
                            <BasicLabel text={"Estimating Manager"} />
                            <BasicTextField
                                value={this.state.initialRecordData.estimating_manager || ""}
                                onChange={(e) => this.handleChange('estimating_manager', e.target.value.trim().length === 0 ? null : e.target.value)} />
                        </MDBCol>
                        <MDBCol md={4} className="secondaryLabelContainer">
                            <BasicTextField
                                label={"Estimator"}
                                value={this.state.initialRecordData?.estimator || ""}
                                // isMandatory={true}
                                // showMandatory={this.state.postInitiated || false}
                                onChange={(e) => this.handleChange('estimator', e.target.value.trim().length === 0 ? null : e.target.value)} />
                        </MDBCol>
                        <MDBCol md={4} className="secondaryLabelContainer">
                            <BasicTextField
                                label={"Estimator Email"}
                                value={this.state.initialRecordData?.estimator_email || null}
                                fieldValid={this.state.initialRecordData?.estimator_email ? (!validateEmail(this.state.initialRecordData?.estimator_email) ? true : false) : false}
                                placeholder="Estimator Email"
                                onChange={(e) =>
                                    this.handleChange(
                                        "estimator_email",
                                        e.target.value.trim().length === 0 ? null : e.target.value
                                    )
                                }
                                inValidInput={messages.invalidInput}
                            />
                        </MDBCol>
                    </MDBRow>
                    {/* {Estimating Manager row end} */}
                    {/* cl rep row startts */}
                    <MDBRow>
                        <MDBCol md={6} className="secondaryLabelContainer">
                            <SelectField
                                label={"Clearence Rep"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                value={this.state.initialRecordData.clearance_rep_id || null}
                                options={this.props.clearanceRepOptions || []}
                                onChange={(e) => this.onDropdownChange("clearanceRepChanged", null, null, "clearance_rep_id", e.target.value, null)}
                            />
                        </MDBCol>

                        <MDBCol md={6} className="secondaryLabelContainer">
                            <BasicLabel text={"Cl. Rep Assistant"} />
                            <SelectField
                                value={this.state.initialRecordData.clearance_rep_asst_id || null}
                                options={this.props.clrRepAsstOptions || []}
                                onChange={(e) => this.onDropdownChange("clRepAssistantChanged", null, null, "clearance_rep_asst_id", e.target.value, null)}
                            />
                        </MDBCol>

                    </MDBRow>
                    <MDBRow>
                    <MDBCol md={6} className="secondaryLabelContainer">
                            <BasicLabel text={"Creative Rep"} />
                            <SelectField
                                value={this.state.initialRecordData.creative_rep_id || null}
                                options={this.props.creativeRepOptions || []}
                                onChange={(e) => this.onDropdownChange("creativeRepChanged", null, null, "creative_rep_id", e.target.value, null)}
                            />
                        </MDBCol>
                        {this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === this.state.initialRecordData.division_id)?.division_name?.toLowerCase() === 'animation' && <MDBCol md={6} className="secondaryLabelContainer">
                            <BasicLabel text={"Line Producer"} />
                            <SelectField
                                value={this.state.initialRecordData.line_producer_id || null}
                                options={this.props.lineProducerOptions || []}
                                onChange={(e) => this.onDropdownChange("lineProducerChanged", null, null, "line_producer_id", e.target.value, null)}
                            />
                        </MDBCol>}
                    </MDBRow>
                    {/* cl rep row ends */}
                    {/* <MDBRow>
                                <MDBCol md={10}></MDBCol>
                                <MDBCol md={2} className="saveCancelWrapper">
                                    <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"} onClick={this.handleSubmit} />
                                    <BasicButton
                                        onClick={() => this.props.handleClose()}
                                        variant="outlined"
                                        text={"Cancel"}
                                    />
                                </MDBCol>
                            </MDBRow> */}
                </MDBContainer>
            </div>
        );
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            canEditDefaultRights = permissions.filter((obj, index) => obj.permission_name === "Can edit Default Rights");
            canEditDefaultRights = canEditDefaultRights.length > 0 ? true : false;
        }
    }

}
export default withUserContext(EditContacts);
