export const navTopMenus  = [{
    label:"Dashboard",
    type:"navDropdown",
    permissionGroupName: "Dashboards",
    menuItems:[
        {
            id: 1,
            icon: "film",
            label: "Show Seasons",
            sectionName: "showSeasons",
            permissionNames: ["Show Seasons"]
        },
        {
            id: 2,
            icon: "music",
            label: "Clearance Records",
            sectionName: "clearanceRecords",
            permissionNames: ["Clearance Records"]
        },
        {
            id: 3,
            icon: "tasks",
            label: "License Tracking",
            sectionName: "licenseTracking",
            permissionNames: ["License Tracking Dashboard"]
        },
        {
            id: 4,
            icon: "file-contract",
            label: "Rights",
            sectionName: "rights",
            permissionNames: ["Rights"]
        },
        {
            id: 5,
            icon: "music",
            label: "Cue Sheets",
            sectionName: "cueSheets",
            permissionNames: ["Cue Sheets"]
        },
        {
            id: 6,
            icon: "money-bill",
            label: "Budgets",
            sectionName: "budget",
            permissionNames: ["TV Cr. Mus. Bdgt"]
        }
    ]
},
{
    label:"Data Lookups",
    type:"navDropdown",
    permissionGroupName: "Data Lookups",
    menuItems: [
        {
            id: 7,
            icon: "file-audio",
            label: "Songs",
            sectionName: "songLibrary",
            permissionNames: ["Songs"]
        },
        {
            id: 8,
            icon: "user-tie",
            label: "Licensors",
            sectionName: "licensors",
            permissionNames: ["Licensors"]
        },
        {
            id: 9,
            icon: "money-check-alt",
            label: "Payees",
            sectionName: "payees",
            permissionNames: ["Payees"]
        },
        {
            id: 10,
            icon: "microphone-alt",
            label: "Music Supervisors/Show Composers",
            sectionName: "musicEditor",
            permissionNames: ["Music Supervisors/Show Composers"]
        },
        {
            id: 11,
            icon: "microphone-alt",
            label: "People",
            sectionName: "people",
            permissionNames: ["People (SC's, MS's, ME's)"]
        },
        {
            id: 12,
            icon: "server",
            label: "Lookup Maintenance",
            permissionNames: [
                "PD Composers tab",
                "Cue Sheet Publishers tab",
                "Writers tab",
                "Performers tab",
                "Unions tab",
                "Cue Sheet Recipients tab",
                "Budget vs EFC Recipients tab",
                "EFC Recipients tab"                
            ]
        }
    ]
},
{
    label:"View",
    type:"navDropdown",
    permissionGroupName: "views",
    menuItems: [
        {
            id: 13,
            icon: "file-export",
            label: "Reports",
            permissionNames: ["Can navigate to Reports tab"]
        },
        {
            id: 14,
            icon: "calculator",
            label: "Cost Allocation Calculator",
            permissionNames: ["Cost Allocation Calculator"]
        },
        {
            id: 15,
            icon: "file-audio",
            label: "Cue Sheet Acknowledgements",
            permissionNames: ["Cue Sheet Acknowledgements pop-up (from side nav)"]
        }
    ]
},
{
    label:"Admin Tools",
    type:"navDropdown",
    permissionGroupName: "Admin Setup",
    menuItems:[
        {
            id: 16,
            icon: "sliders-h",
            label: "TV Cr. Music Budgeting Template",
            permissionNames: ["TV Creative Music Budget Template"]
        },
        {
            id: 17,
            icon: "mail-bulk",
            label: "Letter Setup",
            permissionNames: ["Letter Setup"]
        },
        {
            id: 18,
            icon: "users-cog",
            label: "Admin Setup",
            permissionNames: [
                "Admin Setup - All Values",
                "Admin Setup - 'Fringe Detail' value"
            ]
        }
    ]
}]

