export const AdminSetupSelectOptions = [
    {
        id: 1,
        label: "Cost Allocation Table",
        value: "Cost Allocation Table",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 2,
        label: "Budget Detail Codes",
        value: "Budget Detail Codes",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 3,
        label: "Clearance Options",
        value: "Clearance Options",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 4,
        label: "Cost Center",
        value: "Cost Center",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 5,
        label: "Existing Rights",
        value: "Existing Rights",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 6,
        label: "Fringe Detail",
        value: "Fringe Detail",
        permissionName: "Admin Setup - 'Fringe Detail' value"
    },
    {
        id: 7,
        label: "License Cover Letter Types",
        value: "License Cover Letter Types",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 8,
        label: "License Tracking Actions",
        value: "License Tracking Actions",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 9,
        label: "License Types",
        value: "License Types",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 10,
        label: "Music Uses",
        value: "Music Uses",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 11,
        label: "Networks",
        value: "Networks",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 12,
        label: "Production Entities",
        value: "Production Entities",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 13,
        label: "Production Executives",
        value: "Production Executives",
        permissionName: "Admin Setup - All Values"
    }, {
        id: 14,
        label: "Production Groups",
        value: "Production Groups",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 15,
        label: "Programs",
        value: "Programs",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 16,
        label: "Public Domain Genres",
        value: "Public Domain Genres",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 17,
        label: "RapidCue Music Uses",
        value: "RapidCue Music Uses",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 18,
        label: "Restricted Promo Email Lists",
        value: "Restricted Promo Email Lists",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 19,
        label: "Seasons",
        value: "Seasons",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 19,
        label: "Set Current Season",
        value: "Set Current Season",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 20,
        label: "Show Durations",
        value: "Show Durations",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 21,
        label: "Show Season Options",
        value: "Show Season Options",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 22,
        label: "Territories",
        value: "Territories",
        permissionName: "Admin Setup - All Values"
    },
    {
        id: 23,
        label: "Terms",
        value: "Terms",
        permissionName: "Admin Setup - All Values"
    },
]

export const budgetDetailCodeConfig = {
    headings: [
        {
            headingLabel: "Detail",
            inputType: "text",
            //selectOptions: [],
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            icon: "",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Secondary Description",
            inputType: "text",
            icon: "",
            border: true,
            width: "24%"
        },
        {
            headingLabel: "Clearance?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Clearance MKT?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Clearance MT?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "4%"
        },
    ],

    dataNodes: ["detail_code", "description", "secondary_description", "is_clearance", "is_clearance_MKT", "is_clearance_MT"],
    primaryKey: "budget_detail_code_id",
    nodeFields: [
        {
            column: "is_clearance",
            icon: "check"
        },
        {
            column: "is_clearance_MKT",
            icon: "check"
        },
        {
            column: "is_clearance_MT",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "detail_code",
            width: "20%",
            placeholder: "Detail",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "description",
            width: "24%",
            placeholder: "Description"
        },
        {
            dataNode: "secondary_description",
            width: "25%"
        },
        {
            dataNode: "is_clearance",
            width: "8%"
        },
        {
            dataNode: "is_clearance_MKT",
            width: "9%"
        },
        {
            dataNode: "is_clearance_MT",
            width: "9%"
        },
    ]
};

export const clearanceOptionsConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Option",
            inputType: "text",
            icon: "",
            border: true,
            width: "27%"
        },
        {
            headingLabel: "H/D/B",
            inputType: "select",
            selectOptions: [],
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Def. Fee",
            inputType: "text",
            icon: "",
            border: true,
            width: "27%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            icon: "",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "4%"
        },
    ],

    dataNodes: ["is_active", "option", "H_D_B", "deff_fee", "notes"],
    primaryKey: "clearance_option_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "6%"
        },
        {
            dataNode: "option",
            width: "27%"
        },
        {
            dataNode: "H_D_B",
            width: "8%"
        },
        {
            dataNode: "deff_fee",
            width: "27%",
            inlineIcon: "$",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "notes",
            width: "27%"
        },
    ]
};

export const costCenterConfig = {
    headings: [
        {
            headingLabel: "Season",
            icon: "",
            border: true,
            width: "40%"
        },
        {
            headingLabel: "Display Name",
            icon: "",
            border: true,
            width: "60%"
        },
    ],

    dataNodes: ["season_name", "display_name"],
    primaryKey: "cost_center_season_id",
};

export const fringeDetailConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Detail",
            inputType: "text",
            icon: "",
            border: true,
            width: "75%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],
    dataNodes: ["is_active", "detail"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "20%"
        },
        {
            dataNode: "detail",
            width: "80%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
    ]
};

export const licenseCoverConfig = {
    headings: [
        {
            headingLabel: "Description",
            inputType: "text",
            icon: "",
            border: true,
            width: "75%"
        },
        {
            headingLabel: "Sort Order",
            inputType: "text",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["description", "sort_order"],
    primaryKey: "license_cover_letter_id",
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "description",
            width: "80%"
        },
        {
            dataNode: "sort_order",
            width: "20%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
    ]
};

export const licenseTrackingConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Action",
            inputType: "text",
            icon: "",
            border: true,
            width: "75%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "action"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "20%"
        },
        {
            dataNode: "action",
            width: "80%"
        },
    ]
};

export const licenseTypesConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "License Type",
            inputType: "text",
            icon: "",
            border: true,
            width: "75%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "license_type"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "20%"
        },
        {
            dataNode: "license_type",
            width: "80%"
        },
    ]
};

export const musicUsesConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Music Uses",
            inputType: "text",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Abbreviation",
            inputType: "text",
            icon: "",
            border: true,
            width: "18%"
        },
        {
            headingLabel: "Full Display",
            inputType: "text",
            icon: "",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "RapidCue Music Use",
            inputType: "text",
            icon: "",
            border: true,
            width: "19%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "4%"
        },
    ],

    dataNodes: ["is_active", "existing_uses", "abbreviation", "full_description", "rapid_cue_music_use_id"],
    primaryKey: "music_uses_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "7%"
        },
        {
            dataNode: "existing_uses",
            width: "20%"
        },
        {
            dataNode: "abbreviation",
            width: "18%"
        },
        {
            dataNode: "full_description",
            width: "30%"
        },
        {
            dataNode: "rapid_cue_music_use_id",
            width: "20%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
    ]
};

export const networkConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Network",
            inputType: "text",
            icon: "",
            border: true,
            width: "75%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "network"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "20%"
        },
        {
            dataNode: "network",
            width: "80%",
            placeholder: "Network"
        },
    ]
};

export const termConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Term Name",
            inputType: "label",
            icon: "",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Duration",
            inputType: "text",
            icon: "",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "Duration Type",
            inputType: "text",
            icon: "",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "term_name", "duration", "duration_type"],
    primaryKey: "term_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "30%"
        },
        {
            dataNode: "term_name",
            width: "5%"
        },
        {
            dataNode: "duration",
            width: "30%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter valid duration',
        },
        {
            dataNode: "duration_type",
            width: "35%"
        },
    ]
};

export const prodExecutiveConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Name",
            inputType: "text",
            icon: "",
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Display Name",
            inputType: "text",
            icon: "",
            border: true,
            width: "16%"
        },
        {
            headingLabel: "Email",
            inputType: "text",
            icon: "",
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Work Phone",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Cell Phone",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Assistant",
            inputType: "text",
            icon: "",
            border: true,
            width: "13%"
        },
        {
            headingLabel: "Assistant Phone",
            inputType: "text",
            icon: "",
            border: true,
            width: "9%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "4%"
        },
    ],

    dataNodes: ["is_active", "production_executive_name", "display_name", "email", "work_phone", "cell_phone", "assistant", "assistant_phone"],
    primaryKey: "production_executive_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "7%",
        },
        {
            dataNode: "production_executive_name",
            width: "15%"
        },
        {
            dataNode: "display_name",
            width: "16%"
        },
        {
            dataNode: "email",
            width: "15%",
            validateMethod: 'validateEmail',
            validateMessage: 'Please enter valid Email'
        },
        {
            dataNode: "work_phone",
            width: "10%",
            // validateMethod: 'validateAllPhone',
            // validateMessage: 'Please enter valid Phone',
            placeholder: "(___) ___-____"
        },
        {
            dataNode: "cell_phone",
            width: "10%",
            // validateMethod: 'validateAllPhone',
            // validateMessage: 'Please enter valid Phone',
            placeholder: "(___) ___-____"
        },
        {
            dataNode: "assistant",
            width: "14%"
        },
        {
            dataNode: "assistant_phone",
            width: "10%",
            // validateMethod: 'validateAllPhone',
            // validateMessage: 'Please enter valid Phone',
            placeholder: "(___) ___-____"
        },
    ]
};

export const productionGroupConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Production Group",
            inputType: "text",
            icon: "",
            border: true,
            width: "50%"
        },
        {
            headingLabel: "Source Company Code",
            inputType: "text",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "production_group_name", "source_company_code", ""],
    primaryKey: "production_group_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "18%"
        },
        {
            dataNode: "production_group_name",
            width: "50%"
        },
        {
            dataNode: "source_company_code",
            width: "30%"
        },
    ]
};

export const programsConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Internet Show",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "12%"
        },
        {
            headingLabel: "Program Name",
            inputType: "text",
            icon: "",
            border: true,
            width: "21%"
        },
        {
            headingLabel: "Show Type",
            inputType: "select",
            selectOptions: [],
            icon: "",
            border: true,
            width: "13%"
        },
        {
            headingLabel: "Synopsis",
            inputType: "text",
            icon: "",
            border: true,
            width: "43%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "3%"
        },
    ],

    dataNodes: ["is_active", "is_internet_show", "show_name", "division_id", "synopsis"],
    primaryKey: "show_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
        {
            column: "is_internet_show",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "7%"
        },
        {
            dataNode: "is_internet_show",
            width: "12%"
        },
        {
            dataNode: "show_name",
            width: "21%"
        },
        {
            dataNode: "division_id",
            width: "13%"
        },
        {
            dataNode: "synopsis",
            width: "47%"
        },
    ]
};

export const publicDomainGenreConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Public Domain Genre",
            inputType: "text",
            icon: "",
            border: true,
            width: "75%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "public_domain_genre"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "20%"
        },
        {
            dataNode: "public_domain_genre",
            width: "80%"
        },
    ]
};

export const rapidCueMusicUseConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Music Use",
            inputType: "text",
            icon: "",
            border: true,
            width: "75%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "music_use"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "20%"
        },
        {
            dataNode: "music_use",
            width: "80%"
        },
    ]
};

export const emailConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "17%"
        },
        {
            headingLabel: "Email",
            inputType: "text",
            icon: "",
            border: true,
            width: "63%"
        },
    ],

    dataNodes: ["is_active", "email"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "15%"
        },
        {
            dataNode: "email",
            width: "70%",
            validateMethod: 'validateEmail',
            validateMessage: 'Please enter valid Email'
        },
    ]
};

export const ccConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "17%"
        },
        {
            headingLabel: "Email",
            inputType: "text",
            icon: "",
            border: true,
            width: "63%"
        },
    ],

    dataNodes: ["is_cc_active", "email"],
    primaryKey: "cc_list_id",
    nodeFields: [
        {
            column: "is_cc_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_cc_active",
            width: "15%"
        },
        {
            dataNode: "email",
            width: "70%",
            validateMethod: 'validateEmail',
            validateMessage: 'Please enter valid Email'
        },
    ]
};

export const existingRightConfig = {
    headings: [
        {
            headingLabel: "",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "3%",
        },
        {
            headingLabel: "Cost Centers",
            inputType: "label",
            icon: "",
            border: true,
            width: "20%"
        },
    ],

    dataNodes: ["check", "cost_center_name"],
    primaryKey: "cost_center_id",
    inlineEdits: [
        {
            dataNode: "cost_center_id",
            width: "3%"
        },
        {
            dataNode: "cost_center_name",
            width: "20%"
        },
    ]
};

export const showDurationConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Show Duration",
            inputType: "text",
            icon: "",
            border: true,
            width: "60%"
        },
        {
            headingLabel: "Minutes",
            inputType: "text",
            icon: "",
            border: true,
            width: "14%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "show_duration", "minutes"],
    primaryKey: "show_duration_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "15%"
        },
        {
            dataNode: "show_duration",
            width: "60%"
        },
        {
            dataNode: "minutes",
            width: "14%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        }

    ]
};

export const showSeasonConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Option",
            inputType: "text",
            icon: "",
            border: true,
            width: "27%"
        },
        {
            headingLabel: "H/E/B",
            inputType: "select",
            selectOptions: [],
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Default",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            icon: "",
            border: true,
            width: "44%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "5%"
        },
    ],

    dataNodes: ["is_active", "option", "H_E_B", "is_default", "notes"],
    primaryKey: "show_season_option_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
        {
            column: "is_default",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "8%"
        },
        {
            dataNode: "option",
            width: "27%"
        },
        {
            dataNode: "H_E_B",
            width: "8%"
        },
        {
            dataNode: "is_default",
            width: "8%"
        },
        {
            dataNode: "notes",
            width: "44%"
        },
    ]
};

export const territoryConfig = {
    headings: [
        {
            headingLabel: "Active",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Territory Abbreviation",
            inputType: "text",
            icon: "",
            border: true,
            width: "24%"
        },
        {
            headingLabel: "Full Description",
            inputType: "text",
            icon: "",
            border: true,
            width: "50%"
        },
        {
            headingLabel: "One-off Territory",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "",
            icon: "",
            border: true,
            width: "8%"
        },
    ],

    dataNodes: ["is_active", "territory_abbr", "full_description", "is_one_off_territory"],
    primaryKey: "territory_id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
        {
            column: "is_one_off_territory",
            icon: "check"
        },
    ],
    actions: ["pen"],
    inlineEdits: [
        {
            dataNode: "is_active",
            width: "7%"
        },
        {
            dataNode: "territory_abbr",
            width: "24%"
        },
        {
            dataNode: "full_description",
            width: "50%"
        },
        {
            dataNode: "is_one_off_territory",
            width: "10%"
        },
    ]
};
export const initialRightsDetails = {
    "right_id": null,
    "right_abbr": null,
    "description": null,
    "is_default": 0,
    "default_term_id": null,
    "default_territory_id": null,
    "default_exp_term_id": null,
    "is_optional": null,
    "is_main_title_buy_out": 0,
    "is_initial_right": 0,
    "is_include_home_video": 0,
    "dont_include_yearly_cost_increase": 0,
    "is_default_for_library_song": 0,
    "is_default_rights_for_COA": 0,
    "is_applicable_to_internet_shows": 0,
    "can_be_included_with_init_right": 0,
    "can_be_alt_init_right": 0,
    "is_acquired_by_intl_dept": 0,
    "is_active": 1,
    "cost_centers_list": []
}

export const seasonsConfig = {
    headings: [
        {
            headingLabel: "Start Year",
            inputType: "text",
            icon: "",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "End Year",
            inputType: "text",
            icon: "",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "Cost Splits",
            icon: "",
            border: true,
            width: "30%"
        }
    ],

    dataNodes: ["start_year", "end_year", "cost_splits"],
    primaryKey: "season_id",
    actions: ["pen"],
    buttonFields: [
        {
            column: "cost_splits",
            icon: "pen",
            hideBtnText: true
        }
    ],
    inlineEdits: [
        {
            dataNode: "start_year",
            width: "28%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "end_year",
            width: "28%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "",
            width: "29%"
        },
    ]
}

export const intialShowprogramDetails = [{
    date_created: null,
    division_id: null,
    is_active: null,
    show_id: null,
    show_name: null,
    synopsis: null,
    tenant_id: null,
    selected_show: null
}]

// export const setCurrentSeasonDetails = [
//     {
//         season_id: null,
//         start_year: null,
//         end_year: null,
//         season_name: null,
//         is_visible_online: null,
//         is_current:null,
//         is_active: null,
//         is_delete: null
//     },
// ]
