import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import TableComponent from "../../../SharedComponents/Table";
import * as Constants from "../../../../constants/constants";
import ClearTrackService from "../../../../services/service";
import SearchSelectField from "../../../SharedComponents/SearchSelectField";
import SelectField from "../../../SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../SharedComponents/BasicLabel/BasicLabel";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../../contexts/UserContext';
import { validateEmail } from '../../../../Common/Helper';
import { budgetRecipientRecordConfig, budgetRecipientConfig } from './ConfigBudget'
import axios from "axios";
import { updateTableSortConfigObject, tableSortList } from '../../../../Common/TableHelper';
class BudgetRecipient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            config: { ...budgetRecipientRecordConfig },
            tableOptions: [],
            isSubmit: false,
            tableDetails: { ...budgetRecipientConfig },
            mandatoryNotExist: false,
            isLoading: false,
        }
    }
    componentDidMount() {
        this.getTableData();
    }
    getTableData = (loader = true) => {
        this.setState({ isLoading: loader });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            '/budgetEmailRecipients', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data
                this.setState({ tableOptions: formattedList, isLoading: false, isSubmit: false })

            })
    }
    deleteBudgetVsEFCRecipient = (dataItem) => {
        console.log("loggg",dataItem)
        this.setState({ isSubmit: true })
        let recipientData = {
            is_delete: 1,
            is_active: dataItem?.is_active ? 1 : 0,
            full_name: dataItem?.full_name || null,
            email: dataItem?.email || null,
            is_cc: dataItem?.is_cc ? 1 : 0,
            is_clearance_budget_recipient: dataItem?.is_clearance_budget_recipient ? 1 : 0,
            is_proposed_budget_recipient: dataItem?.is_proposed_budget_recipient ? 1 : 0,
            budget_email_recipients_id:dataItem?.budget_email_recipients_id 
        }
        ClearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/budgetEmailRecipients`, recipientData,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then(
                (response) => {
                    if (response.data.error) {
                        this.setState({
                            isSubmit: false,
                        });
                        this.props.toastMessageNotification(true, "fail");
                    } else {
                        this.props.toastMessageNotification(true, "success");
                        this.getTableData();

                    }
                },
                (err) => {
                    this.setState({ isSubmit: false });
                    this.props.toastMessageNotification(true, "fail");
                }
            );
    }


        postBudgetEmailRecipientData = (recipientDetails, postFlag) => {
            this.setState({ isSubmit: postFlag });
            ClearTrackService.postDataParams(
                Constants.ClearTrackServiceBaseUrl + `/budgetEmailRecipients`,
                recipientDetails,
                this.props?.userContext?.active_tenant?.tenant_id
            ).then(
                (response) => {
                    if (response.data.error) {
                        this.setState({
                            isSubmit: false,
                        });
                        this.props.toastMessageNotification(true, "fail");
                    } else {
                        this.props.toastMessageNotification(true, "success");
                        if (recipientDetails?.editing) {
                            recipientDetails.editing = false;
                        }
                        this.getTableData();
                    }
                },
                (err) => {
                    this.setState({ isSubmit: false });
                    this.props.toastMessageNotification(true, "fail");
                }
            );
        };
        handleAddNewItem = (newDataItem) => {
            this.setState({ mandatoryNotExist: false })
            if (newDataItem?.full_name && newDataItem?.full_name?.length > 0 && newDataItem?.email && newDataItem?.email.length > 0 && validateEmail(newDataItem?.email)) {
                let newBudgetRecipientData = {

                    is_active: newDataItem?.is_active ? 1 : 0,
                    full_name: newDataItem.full_name || null,
                    email: newDataItem.email || null,
                    is_cc: newDataItem.is_cc ? 1 : 0,
                    is_clearance_budget_recipient: newDataItem?.is_clearance_budget_recipient ? 1 : 0,
                    is_proposed_budget_recipient: newDataItem?.is_proposed_budget_recipient ? 1 : 0
                };
                this.postBudgetEmailRecipientData(newBudgetRecipientData, "newItemPost");
            } else {
                this.setState({ mandatoryNotExist: true })
            }
        };
        handleInlineEdits = (id, dataItem) => {
            let allDetails = [...this.state.tableOptions];
            this.setState({
                mandatoryNotExist: false,
                tableOptions: allDetails.map((item) => {
                    let newItem = {...item}
                    if (item.budget_email_recipients_id
                        == id) newItem["editing"] = true;
                    return newItem;
                }),
            });
        };
        saveInlineEdits = (data, index, pkValue, dataItem) => {
            this.setState({ mandatoryNotExist: false })
            if (data[index]?.full_name && data[index]?.full_name?.length > 0 && data[index]?.email && data[index]?.email.length > 0 && validateEmail(data[index]?.email)) {
                let dataList = [...data];
                let allDetails = [...this.state.tableOptions]
                allDetails[index].budget_email_recipients_id = dataList[index]?.budget_email_recipients_id,
                    allDetails[index].full_name = dataList[index]?.full_name || null,
                    allDetails[index].email = dataList[index]?.email || null,
                    allDetails[index].is_cc = dataList[index]?.is_cc ? 1 : 0,
                    allDetails[index].is_active = dataList[index]?.is_active ? 1 : 0,
                    allDetails[index].is_clearance_budget_recipient = dataList[index]?.is_clearance_budget_recipient ? 1 : 0,
                    allDetails[index].is_proposed_budget_recipient = dataList[index]?.is_proposed_budget_recipient ? 1 : 0,
                    this.postBudgetEmailRecipientData(allDetails[index], allDetails[index]?.budget_email_recipients_id);
            } else {
                this.setState({ mandatoryNotExist: true })
            }

        }

        render() {
            let permissionValue = this.props?.permissionArray[0]
            console.log("value",permissionValue)
            return (

                <MDBRow >
                    {this.state.isLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>) : (
                        <MDBCol md={12} id="table-bg-sync-cue" >
                            <TableComponent 
                                stickyHeader={true}
                                className={"clearencRecord"}
                                config={this.state.config}
                                addItemToList={true}
                                postInitiated={this.state.isSubmit}
                                list={this.state.tableOptions || []}
                                handleAddNewItem={permissionValue?.edit === 1 ? this.handleAddNewItem.bind(this) : null}
                                editCallback={permissionValue?.edit === 1 ? this.handleInlineEdits.bind(this) : null}
                                saveCallback={permissionValue?.edit === 1 ? this.saveInlineEdits.bind(this) : null}
                                handleDelCallBack={(id, item) => permissionValue?.delete === 1 ?
                                    this.deleteBudgetVsEFCRecipient(item) :null
                                }
                                readCallback={(index) => {
                                    let budgetReceipientData = [...this.state.tableOptions];
                                    budgetReceipientData[index].editing = false
                                    this.setState({ tableOptions: budgetReceipientData, mandatoryNotExist: false });
                                }}

                            />
                        </MDBCol>
                    )}
                </MDBRow>
            )
        }
    }

export default withUserContext(BudgetRecipient);