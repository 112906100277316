const modalStyles = {
    position: 'absolute',
    // overflowY: 'auto',
    // overflowY: 'hidden',
    overflowX: 'hidden',
    border: '2px solid grey',
    borderRadius: '8px',

}

export const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        ...modalStyles,
        boxShadow: theme.shadows[5],
        backgroundMDBColor: theme.palette.background.paper,
        width: '60%',
        height: '80%',
    },
    // statusDate: {
    //     maxWidth: '440px !important',
    // },
    addLocation: {
        maxWidth: '55% !important'
    },
    NewProductionDetails: {
        maxWidth: '55% !important',
    },
    addWorkweek: {
        maxWidth: '440px !important',
    },
    newEpisode: {
        maxWidth: '720px !important',
    },
    newSeason: {
        maxWidth: '680px !important',
    },
    newShow: {
        maxWidth: '770px !important',
    },
    staffMember: {
        maxWidth: '50% !important'
    },
    ChangeLocation: {
        maxWidth: '440px !important'
    },
    roles: {
        maxWidth: '900px !important'
    },
    AddPerformer: {
        maxWidth: '900px !important'
    },
    ManageLookup: {
        maxWidth: '450px !important'
    },
    label: {
        fontSize: '0.75rem'
    },
    AddPublisherContainer: {
        maxWidth: '45% !important'
    },
    AddComposerContainer: {
        maxWidth: '50% !important'
    },
    PersonContainer: {
        maxWidth: '65% !important'
    },
    NewMainTitleContainer: {
        maxWidth: '50% !important'
    },
    EditCueSheetPublisherInfoContainer: {
        maxWidth: '65% !important'
    },
    PayeeIncreaseContainer: {
        maxWidth: '50% !important'
    },
    CostAllocationCalculatorContainer: {
        maxWidth: '80% !important'
    },
    CueSheetAcknowledgementWarningsContainer: {
        maxWidth: '75% !important'
    },
    EditCostSplitsContainer: {
        maxWidth: '75% !important'
    },
    EpisodeContainer: {
        maxWidth: '70% !important'
    },
    CueSheetDistributionContainer: {
        maxWidth: '45% !important'
    },
    SongContainer: {
        maxWidth: '75% !important'
    },
    PayeeContainer: {
        maxWidth: '80% !important'
    },
    RecurringCueContainer: {
        maxWidth: '75% !important'
    },
    mainTitleBatchesContainer: {
        maxWidth: '85% !important'
    }
});

export const modalPermissions = [
    {
        modalName: "Song",
        permissions: ["Songs (Reg + PD)"],
        permissionGroupName: ["Songs"]
    }
]