
export const LicenseTrackingHeading = {
    headings: [
        {
            headingLabel: "Description",
            icon: "",
            width: "15%",
            inputType: "text",
        },
        {
            headingLabel: "Action",
            icon: "",
            width: "24%",
            inputType: "select",
            dataNode: "action",
            selectOptions:[]
        },
        {
            headingLabel: "Date",
            icon: "sort-alpha-down",
            width: "13%",
            inputType: "datefield"
        },
        {
            headingLabel: "Comment",
            icon: "",
            width: "14%",
            inputType: "text"
        },
        {
            headingLabel: "Submittedby",
            icon: "",
            width: "12%",
            inputType: "select",
            selectOptions:[]
        },
        {
            headingLabel: "File",
            icon: "",
            width: "15%",
        },

    ],
    isClearFields:true,
    dataNodes: ["description", "action", "date", "comment", "submitted_by", "files"],
    primaryKey: "clearance_license_tracking_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "description",
            width: "14%"
        },
        {
            dataNode: "action",
            width: "23%"
        },
        {
            dataNode: "date",
            width: "12%"
        },
        {
            dataNode: "comment",
            width: "13%"
        },
        {
            dataNode: "submitted_by",
            width: "16%"
        }
    ],

};
