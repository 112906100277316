import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { CircularProgress } from "@material-ui/core";
import {withUserContext} from '../../contexts/UserContext'
import {validateAllPhone, validateEmail} from '../../Common/Helper'
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';



class ProductionExecutives extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productionExecutivesList: [],
            isLoading: false,
            isSubmit: false,
            nameNotExist: false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config: JSON.parse(JSON.stringify({ ...this.props?.config}))
        }
    }

    componentDidMount() {
      this.getProductionExecutives("productionExecutivesList", "PRODUCTION_EXECUTIVE", "");
      
    }

    getProductionExecutives = (entity, entitytype, searchString,loader = true ) => {
        this.setState({ isLoading: loader,nameNotExist: false, });
          ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
              `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then((res) => {
              let configRecord = {...this.state.config}
              this.props?.permissionArray?.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
              this.setState({ [entity]: res.data, isLoading: false, isSubmit: false,sortBy: null,
                isSortByAsc: true, config: configRecord,
                sortCount: 0, }, () => {this.handleTableColumnClick("Name");});
            })
            .catch((err) => {
              this.setState({ isLoading: false });
        });
    };

    postProductionExecutive = (productionExecutiveDetails, postFlag) => {
        this.setState({ isSubmit: postFlag,nameNotExist: false, });
        ClearTrackService.postDataParams(
          Constants.ClearTrackServiceBaseUrl + `/productionExecutive`,
          productionExecutiveDetails,
          this.props?.userContext?.active_tenant?.tenant_id
        ).then(
          (response) => {
            if (response.data.error) {
              this.setState({ isSubmit: false });
              this.props?.toastMessageNotification(true, "fail");
              this.getProductionExecutives("productionExecutivesList", "PRODUCTION_EXECUTIVE", "");
            } else {
             // this.setState({
               // isSubmit: false,
              //});
              if (productionExecutiveDetails?.editing) {
                productionExecutiveDetails.editing = false;
              }
              this.props?.toastMessageNotification(true, "success");
              this.getProductionExecutives("productionExecutivesList", "PRODUCTION_EXECUTIVE", "",false);
            }
          },
          (err) => {
            this.setState({ isSubmit: false });
            this.props?.toastMessageNotification(true, "fail");
            console.log("Post productionExecutive details error: " + err);
          }
        );
    };

    validateErrors = (postData) => {
      let canSubmit = postData?.production_executive_name && 
                      (postData?.assitant_phone ? validateAllPhone(postData?.assitant_phone) : true) &&
                      (postData?.cell_phone ? validateAllPhone(postData?.cell_phone) : true) &&
                      (postData?.work_phone ? validateAllPhone(postData?.work_phone) : true) &&
                      (postData?.email ? validateEmail(postData?.email) : true) 
      return canSubmit
    }

    handleInlineEdits = (id, dataItem) => {
        let productionExecutives = [...this.state.productionExecutivesList]
        this.setState({
            nameNotExist: false,
            productionExecutivesList: productionExecutives.map((item) => {
                let newItem = {...item}
                if (item.production_executive_id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({nameNotExist: false})
        if(data[index]?.production_executive_name && data[index]?.production_executive_name?.length > 0 && 
          data[index]?.email && data[index]?.email?.length > 0){
          let productionExecutive = [...data];
          let productionExecutiveDetails = [...this.state.productionExecutivesList];
          productionExecutiveDetails[index].production_executive_id = productionExecutive[index]?.production_executive_id;
          productionExecutiveDetails[index].production_executive_name = productionExecutive[index]?.production_executive_name || null;
          productionExecutiveDetails[index].display_name = productionExecutive[index]?.display_name || null;
          productionExecutiveDetails[index].email = productionExecutive[index]?.email || null;
          productionExecutiveDetails[index].work_phone = productionExecutive[index]?.work_phone || null;
          productionExecutiveDetails[index].cell_phone = productionExecutive[index]?.cell_phone || null;
          productionExecutiveDetails[index].assistant = productionExecutive[index]?.assistant || null;
          productionExecutiveDetails[index].assistant_phone = productionExecutive[index]?.assistant_phone || null;
          productionExecutiveDetails[index].is_active = productionExecutive[index]
            ?.is_active
            ? 1
            : 0;
          this.setState({ productionExecutivesList: productionExecutiveDetails });
          if(this.validateErrors(productionExecutiveDetails[index])) {
            this.postProductionExecutive(productionExecutiveDetails[index],productionExecutiveDetails[index]?.production_executive_id);
          }
        }  else {
            this.setState({nameNotExist : true})
        }
    };

    handleAddNewItem = (newDataItem) => {
      this.setState({nameNotExist: false})
      if(newDataItem?.production_executive_name && newDataItem?.production_executive_name?.length > 0 &&
        newDataItem?.email && newDataItem?.email?.length > 0){
        let newProductionExecutiveData = {
            production_executive_name: newDataItem?.production_executive_name || null,
            display_name: newDataItem?.display_name || null,
            production_executive_id: null,
            is_active: 1,
            email: newDataItem?.email || null,
            work_phone: newDataItem?.work_phone || null,
            cell_phone: newDataItem?.cell_phone || null,
            assistant: newDataItem?.assistant || null,
            assistant_phone:newDataItem?.assistant_phone || null
        };
        //let productionExecutiveDetails = [...this.state.productionExecutivesList];
      if(this.validateErrors(newProductionExecutiveData)) {
        this.postProductionExecutive(newProductionExecutiveData,'newItemPost');
        // productionExecutiveDetails.push(newProductionExecutiveData);
        // this.setState({ productionExecutivesList: productionExecutiveDetails });
      }
    }  else {
      this.setState({nameNotExist : true})
    }
    };
    handleTableColumnClick = (column) => {
      if (column === this.state.sortBy) {
        this.setState({ sortCount: this.state.sortCount + 1 }, () => {
          this.handleTableSort(column);
        })
      }
      else this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      })
  }
  
  handleTableSort = (colName) => {
      this.setState({
        isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      }, () => {
        this.setState({
          sortBy: colName,
          config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
          productionExecutivesList: tableSortList((colName === "Work Phone" || colName === "Cell Phone" || colName === "Assistant Phone") ? "Number" : "Name", this.getSortNode(colName), this.state.productionExecutivesList, this.state.isSortByAsc)
        });
      })
  }
  
  getSortNode = (col) => {
      switch (col) {
        case "Name": return "production_executive_name";
        case "Display Name": return "display_name";
        case "Email": return "email";
        case "Work Phone": return "work_phone";
        case "Cell Phone": return "cell_phone";
        case "Assistant Phone": return "assistant_phone";
        case "Assistant": return "assistant";
        case "Active": return "is_active";
      }
  }

    render() {
      let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="ProductionExecutivesContainer">
                <MDBRow>
                    <span className="description">Selectable Production Executives for a Show/Season.</span>
                </MDBRow>
                <MDBRow className='pd'>
                <span className="errorText">{this.state.nameNotExist && 'Please enter Name and Email fields value'}</span>
                </MDBRow>
                <MDBRow className="ProductionExecutivesTable">
                    {this.state.isLoading ? (
                        <CircularProgress />
                    ) : (
                            <TableComponent
                                newIsActive={true}
                                list={this.state.productionExecutivesList || []}
                                config={this.state.config}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1}
                                postInitiated={this.state.isSubmit}
                               // isLoading={this.state.isSubmit}
                                readCallback={(index) => {
                                  let productionOptions = [...this.state.productionExecutivesList];
                                  productionOptions[index].editing = false
                                  this.setState({ productionExecutivesList: productionOptions, nameNotExist:false });
                                }}
                                stickyHeader={true}
                                sortCallback={this.handleTableColumnClick}
                            />
                        )
                    }
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withUserContext(ProductionExecutives);