import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import TabsComponent from '../../../components/SharedComponents/Tabs/Tabs';
import { budgetList } from './Config';
import CreativeBudget from './CreativeBudget';
import PilotBudget from './PilotBudget';
import './Budget.scss';
import ClearanceBudget from './ClearanceBudget'
import { withUserContext } from '../../../contexts/UserContext';
let canViewClearanceBudgetTab = false, canViewPilotBudgetTab = false, canViewTvCrMusicBudgetTab = false;
class Budget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: null,
            pilotBudgetList: [],
            budgetParentTabs: []
        };
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };
    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <PilotBudget division_id={this.props.division_id} show_season_id={this.props?.show_season_id} initialRecordData={this.props?.initialRecordData} pilotBudgetList={this.state.pilotBudgetList} permissionArr={this.props.permissionArr} {...this.props} />;
                break
            case 2:
                return <ClearanceBudget division_id={this.props.division_id} show_season_id={this.props.show_season_id} show_id={this.props.show_id} season_id={this.props.season_id} initialRecordData={this.props?.initialRecordData} permissionArr={this.props.permissionArr} {...this.props}
                />;
                break
            case 3:
                return <CreativeBudget division_id={this.props.division_id} permissionArr={this.props.permissionArr} show_season_id={this.props.show_season_id} season_id={this.props.season_id} initialRecordData={this.props?.initialRecordData} handleEpisodesArray={this.props.handleEpisodesArray} {...this.props} />;
                break
        }
    };
    render() {
        return (
            <div className="ShowSeason-Budget-Container">
                <div>
                    <MDBRow className="tab-content">
                        <MDBCol md={10}>
                            <TabsComponent
                                tabList={this.state.budgetParentTabs}
                                value={this.state.tabValue}
                                onChange={(e, v) => this.handleTabChange(v)}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
                {this.getTabComponent()}
            </div>
        )
    }
    getDynamicTabs = () => {
        let pilotBudgetList = [], budgetParentTabs = [];
        if (canViewPilotBudgetTab === true && canViewClearanceBudgetTab === true && (this.props?.initialRecordData?.is_pilot === 1 && (this.props?.initialRecordData?.pilot_maintance[0]?.is_this_not_pilot == 0 || this.props?.initialRecordData?.pilot_maintance[0]?.is_this_not_pilot == null))) {
            budgetParentTabs = [
                {
                    label: "Pilot Budget",
                    value: 1
                },
                {
                    label: "Clearance Budget",
                    value: 2
                }
            ];
            pilotBudgetList = [
                {
                    label: "Pilot",
                    value: 1
                },
                {
                    label: "Series Budget",
                    value: 2
                },
                {
                    label: "Amort",
                    value: 3
                }
            ]
        }

        if (canViewPilotBudgetTab === true && canViewClearanceBudgetTab === true && (this.props?.initialRecordData?.is_pilot === 1) && this.props?.initialRecordData?.pilot_maintance[0]?.is_this_not_pilot == 1) {
            budgetParentTabs = [
                {
                    label: "Pilot Budget",
                    value: 1
                },
                {
                    label: "Clearance Budget",
                    value: 2
                }
            ];
            pilotBudgetList = [
                {
                    label: this.props?.initialRecordData?.pilot_maintance[0]?.customize_title ? this.props?.initialRecordData?.pilot_maintance[0]?.customize_title : 'Pilot',
                    value: 1
                },
                {
                    label: "Series Budget",
                    value: 2
                },
                {
                    label: "Amort",
                    value: 3
                }
            ]
        }
        if (canViewTvCrMusicBudgetTab === true) {
            budgetParentTabs.push({
                label: "TV Creative Music Budget",
                value: 3
            });
        }
        if (this.props?.window_name === 're-route creative budget' && this.props?.is_pilot === 1) {
            console.log("window name in budget", this.props?.window_name, this.props?.is_pilot)
            this.setState({ pilotBudgetList, budgetParentTabs, tabValue: 3 })
        } else {
            this.setState({ pilotBudgetList, budgetParentTabs, tabValue: budgetParentTabs[0]?.value })
        }
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            canViewClearanceBudgetTab = permissions.filter((obj) => obj.permission_name === "Clearance Budget" && obj.view === 1);
            canViewClearanceBudgetTab = canViewClearanceBudgetTab.length > 0 ? true : false;

            canViewPilotBudgetTab = permissions.filter((obj) => obj.permission_name === "Pilot Budget" && obj.view === 1);
            canViewPilotBudgetTab = canViewPilotBudgetTab.length > 0 ? true : false;

            canViewTvCrMusicBudgetTab = permissions.filter((obj) => obj.permission_name === "TV Cr. Music Budget" && obj.view === 1);
            canViewTvCrMusicBudgetTab = canViewTvCrMusicBudgetTab.length > 0 ? true : false;
            this.getDynamicTabs();
        }
    }
    componentDidMount() {
        this.getPermissions(this.props.permissionArr);
    }

}

export default withUserContext(Budget);
