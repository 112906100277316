import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class RapidCueMusicUses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            rapidNotExist:false,
            rapidDuplicate:false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config: JSON.parse(JSON.stringify({ ...this.props?.config})),
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps?.list !== this.props?.list && this.props?.list?.length > 0) {
            let configRecord = {...this.state.config}
            this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
            this.setState({renderList:this.props.list,sortCount:0,sortBy:null,isSortByAsc: true,config: configRecord}, () => {
                this.handleTableColumnClick('Music Use')
            })
        }
    }


    handleInlineEdits = (id, dataItem) => {
        let rapidCueMusicUses = [...this.state.renderList]
        this.setState({
            rapidNotExist:false,
            renderList: rapidCueMusicUses.map((item) => {
                let newItem = {...item}
                if (item.id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({rapidNotExist:false,rapidDuplicate:false}) 
        if(!data[index]?.music_use || data[index]?.music_use?.length == 0){
            this.setState({rapidNotExist : true})
        } else if(data[index]?.music_use && this.state?.renderList?.some((item,i)=>item?.music_use?.toLowerCase() ===data[index]?.music_use?.toLowerCase() && i != index)){
            this.setState({rapidDuplicate:true})
        }else{
        
            let rapidCueMusicUses = [...this.state.renderList]
            rapidCueMusicUses[index].id = data[index].id,
                rapidCueMusicUses[index].music_use = data[index].music_use || null,
                rapidCueMusicUses[index].is_active = data[index].is_active?1:0
            this.setState({
                renderList: rapidCueMusicUses.map((item) => {
                    item['editing'] = false
                    return item
                })
            })
            let editedList = this.state.renderList.filter(item => item.id === pkValue);
            let list = {
                id: editedList[0].id,
                name: editedList[0].music_use || null,
                is_active: editedList[0].is_active?1:0
            }
            this.props.handleSave(list, "lu_rapid_cue_music", list?.id);
        }
    }

    handleAddNewItem = (newDataItem) => {
        this.setState({rapidNotExist:false,rapidDuplicate:false}) 
        
        if(!newDataItem?.music_use || newDataItem?.music_use?.length == 0){
            this.setState({rapidNotExist : true})
        } else if(newDataItem?.music_use && this.state?.renderList?.some(item=>item?.music_use?.toLowerCase() === newDataItem?.music_use?.toLowerCase())){
            this.setState({rapidDuplicate:true})
        }else{
            let addList = {
                id: null,
                name: newDataItem.music_use || null,
                is_active: 1
            }
            this.props.handleSave(addList, "lu_rapid_cue_music",'newItemPost');
        }
            
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
          this.setState({ sortCount: this.state.sortCount + 1 }, () => {
            this.handleTableSort(column);
          })
        }
        else this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        })
    }
    
    handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            sortBy: colName,
            config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, this.state.sortBy, colName),
            renderList: tableSortList(colName === 'Active' ? 'Number' :"Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
          });
        })
    }
    
    getSortNode = (col) => {
        switch (col) {
            case "Music Use": return "music_use";
            case "Active": return "is_active";
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="detailContainer">
                <MDBRow>
                    <span className="description">
                        Define Music Uses from RapidCue to be available as system Music Uses.
                    </span>
                </MDBRow>
                <MDBRow className='pd'>
                <span className="errorText">{this.state.rapidNotExist && 'Please enter Music use field value'}</span>
                <span className='errorText'>{this.state?.rapidDuplicate && 'Music use is already exist'}</span>
                </MDBRow>
                <MDBRow className="tableComponent">
                    {this.props?.loadingOptions ? (
                        <div className="ContentLoader">
                            <CircularProgress />
                        </div>
                    ) : (
                            <TableComponent
                                newIsActive={true}
                                list={this.state.renderList || []}
                                config={this.state?.config}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1}
                                stickyHeader={true}
                                postInitiated={this.props?.isSaveData}
                               // isLoading={this.props?.isSaveData}
                                readCallback={(index) => {
                                    let rapidOptions = [...this.state.renderList];
                                    rapidOptions[index].editing = false
                                    this.setState({ renderList: rapidOptions, rapidNotExist:false });
                                }}
                                sortCallback={this.handleTableColumnClick.bind(this)}
                            />
                        )
                    }
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default RapidCueMusicUses;