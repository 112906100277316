export const filesList = [
    {
        value: 1,
        label: "File_Name.pdf",
        icon: "times"
    },
    {
        value: 2,
        label: "File_Name.msg",
        icon: "times"
    },
    {
        value: 3,
        label: "File_Name.jpg",
        icon: "times"
    },
    {
        value: 4,
        label: "File_Name.png",
        icon: "times"
    },
    {
        value: 5,
        label: "File_Name.xlsx",
        icon: "times"
    },
    {
        value: 6,
        label: "File_Name.doc",
        icon: "times"
    },
    {
        value: 7,
        label: "File_Name.doc",
        icon: "times"
    },
    {
        value: 8,
        label: "File_Name.jpg",
        icon: "times"
    },
    {
        value: 9,
        label: "File_Name.png",
        icon: "times"
    }

]