import React from 'react';
import { MDBCol,MDBRow } from 'mdbreact';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableComponent from '../../SharedComponents/Table';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import './TvMusicBudget.scss';
import { onCameraPerformersConfig, fringeConfig ,tvMusicBudgetDetails, recordingConfig, orchestralConfig } from './Config';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import {validateNumDotOnly } from '../../../Common/Helper';
import { CircularProgress } from "@material-ui/core";
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import { withUserContext } from '../../../contexts/UserContext';


class TvMusicBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            onCameraPerformersList: [],
            recordingList: [],
            orchestralList:[],
            config: {...onCameraPerformersConfig},
            fringeConfig: {...fringeConfig},
            recordingConfig: {...recordingConfig},
            orchestralConfig: {...orchestralConfig},
            fringeList: [],
            unionList:[],
            tvMusicBudgetDetails: tvMusicBudgetDetails,
            showDetailsOptions: [],
            fringDetailsOptions: [],
            budgetList:[],
            errorFlag: false,
            recordings: false,
            fringe: false,
            orchestral: false,
            payIncreaseModal:false,
            isCameraPerformerLoading: false,
            isRecordingsLoading: false,
            isFringeLoading: false,
            isOrchestralLoading: false,
            isSubmit: false
           
        };
    }

    componentDidMount() {
        document.title = 'Tv Creative Music Budget Template'
        this.getUnionOptions()
        this.getFringDetails()
        this.getBudgetDetailCodes("BUDGET_DETAIL_CODE", null)
        this.getFringeDetailCodes("FRINGE_DETAIL", null)
    }

    getFringeDetailCodes = (fringeDetailCode, searchString) => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(
          Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${fringeDetailCode}&searchString=${searchString}`,
          this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                console.log("res", res.data)
                let formattedList = res?.data?.map(item => ({ value: item.fringe_detail_id, label: item.detail }));
              console.log("formattedList",formattedList)
              let fringeConfig = {...this.state.fringeConfig}
                
                fringeConfig.headings[0].selectOptions = formattedList
                
            this.setState({ fringDetailsOptions: formattedList});
            this.setState({
              budgetList: res.data,
              isLoading: false
            });
          })
          .catch((err) => {
            this.setState({  isLoading: false });
          });
      };

    getBudgetDetailCodes = (bugetDetailCode, searchString) => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(
          Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${bugetDetailCode}&searchString=${searchString}`,
          this.props?.userContext?.active_tenant?.tenant_id
        )
            .then((res) => {
                console.log("res", res.data)
            let detailOptions = res.data?.filter(
              (item, index, data) =>
                index === data.findIndex((t) => t.detail_id === item.detail_id && item.detail !== null)
              );
              console.log("detailOptions",detailOptions)
                let formattedList = res.data.map((item) => ({
                    value: item?.budget_detail_code_id,
                    label:  `${item?.detail_code}_${item?.description}`,
            }));
              console.log("formattedList",formattedList)
                let config = { ...this.state.config }
                let recordingConfig = { ...this.state.recordingConfig }
                let orchestralConfig ={...this.state.orchestralConfig}
                config.headings[0].selectOptions = formattedList
                // fringeConfig.headings[0].selectOptions = formattedList
                recordingConfig.headings[0].selectOptions = formattedList
                orchestralConfig.headings[0].selectOptions = formattedList
            this.setState({ showDetailsOptions: formattedList});
            this.setState({
              budgetList: res.data,
              isLoading: false
            });
          })
          .catch((err) => {
            this.setState({  isLoading: false });
          });
      };

    

    getUnionOptions = (loader=true) => {
        this.setState({isLoading :loader})
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            // `/entitySearch?entity=${entitytype}&searchString=${searchString}`
            "/staticData?staticTable=lu_union",this.props.userContext?.active_tenant?.tenant_id)
            .then((res)=> {
                let formattedList = res?.data?.map((item) => ({
                    label: item.name,
                    value: item.id,
                    is_active: item.is_active,
                  }));
          
                    // ({ value: item.union_id, label: item.union_name }));
                this.setState({ unionList: formattedList, isLoading: false });
        })
        .catch ((err)=> {
            console.log("error in getting options")
        })
    }

    getFringDetails = () => {
        this.setState({ isLoading: true});
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + "/staticData?staticTable=lu_fringe_detail",
      this.props?.userContext?.active_tenant?.tenant_id
    )
        .then((res) => {
            let formattedList = res?.data?.map(item => ({ value: item.id, label: item.name }));
            let config = { ...this.state.config }
            let recordingConfig = { ...this.state.recordingConfig }
            let orchestralConfig = { ...this.state.orchestralConfig }
            let fringeConfig = {...this.state.fringeConfig}
            config.headings[4].selectOptions = formattedList
            recordingConfig.headings[4].selectOptions = formattedList
            orchestralConfig.headings[4].selectOptions = formattedList
            // fringeConfig.headings[0].selectOptions = formattedList
            this.setState({ fringDetailsOptions: formattedList});
        })
        .catch((err) => {
            console.log("error")
        });
    }

    getTvMusicBudgetDetails = (union_id, loader = true) => {
        this.setState({ isLoading: loader });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/tvMusicBudgetingTemplate?union_id=${union_id}`,
            this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let permission_name = this.props?.userContext.active_tenant?.common_permissions?.find(item =>item?.permission_group_name === 'TV Cr. Music Budget Template')
                let config = {...this.state.config}
                let fringeConfig = {...this.state.fringeConfig}
                let recordingConfig = {...this.state.recordingConfig}
                let orchestralConfig = {...this.state.orchestralConfig}
                if(permission_name?.edit === 1) {
                    config.actions = ["pen", "times"]
                    fringeConfig.actions = ["pen", "times"]
                    recordingConfig.actions = ["pen", "times"]
                    orchestralConfig.actions = ["pen", "times"] 
                } else {
                    config.actions = []
                    fringeConfig.actions = []
                    recordingConfig.actions = []
                    orchestralConfig.actions = []
                }
                this.setState({
                    onCameraPerformersList: response.data[0].on_camera_performers,
                    recordingList: response.data[0].recordings,
                    orchestralList: response.data[0].orchestral_sessions,
                    fringeList:response.data[0].fringe,
                    isFringeLoading: false,
                    isOrchestralLoading: false,
                    isLoading: false,
                    isSubmit: false,
                    isRecordingsLoading: false,
                    permissionName : permission_name ? permission_name : null,
                    config : config,
                    fringeConfig : fringeConfig,
                    recordingConfig : recordingConfig,
                    orchestralConfig : orchestralConfig
                })
            }),
            ((err) => {
                this.setState({  isFringeLoading: false,
                    isOrchestralLoading: false,
                    isLoading: false,
                    isSubmit: false,
                    isRecordingsLoading: false });
            });

    }

    handleOnChange = (field, value) => {
        console.log("value", value)
        this.getTvMusicBudgetDetails(value)
        if (value != "") {
            this.setState({
                tvMusicBudgetDetails: {
                    ...this.state.tvMusicBudgetDetails,
                    [field]: value,
                },
            });
        }
        else {
            this.setState({
                tvMusicBudgetDetails: {
                    ...this.state.tvMusicBudgetDetails,
                    [field]: null,
                },
                postInitiated: false
            });
        }
    };

    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded });
    };
    handleInlineEdits = (tableName, id, dataItem) => {
        switch (tableName) {
            case 'On_Camera_Performers_Recordings': {
                this.setState({
                    onCameraPerformersList: this.state.onCameraPerformersList.map((item) => {
                        let newItem = {...item}
                        if (item.tv_music_budgeting_template_on_camera_performers_id == id)
                            newItem['editing'] = true
                        return newItem
                    })
                })
            }
                break;
                case 'recordings': {
                    this.setState({
                        recordingList: this.state.recordingList.map((item) => {
                            let newItem = {...item}
                            if (item.tv_music_budgeting_template_recordings_id == id)
                                newItem['editing'] = true
                            return newItem
                        })
                    })
                }
                break;
                case 'orchestral_Sessions': {
                    this.setState({
                        orchestralList: this.state.orchestralList.map((item) => {
                            let newItem = {...item}
                            if (item.tv_music_budgeting_template_orchestral_sessions_id == id)
                                newItem['editing'] = true
                            return newItem
                        })
                    })
                }
                break;
                case 'fringe': {
                    this.setState({
                        fringeList: this.state.fringeList.map((item) => {
                            let newItem = {...item}
                            if (item.tv_music_budgeting_template_fringe_id == id)
                                newItem['editing'] = true
                            return newItem
                        })
                    })
                }
                    break;
        }
        
    }

    postTvMusicBudgetDetails = (showPilotBudgetDetails, tableName, path, postFlag) => {
        tableName === "On_Camera_Performers_Recordings" ? this.setState({ isSubmit: postFlag }) :  tableName === "recordings" ?  this.setState({isRecordingsLoading: postFlag}) :  tableName === "orchestral_Sessions" ? this.setState({isOrchestralLoading: postFlag}) :  tableName === "fringe" ? this.setState({isFringeLoading : postFlag}) : null
        console.log("showPilotBudgetDetails", showPilotBudgetDetails,tableName)
        showPilotBudgetDetails.union_id = this.state.tvMusicBudgetDetails?.union_id
        let Data = { ...showPilotBudgetDetails }
                 ClearTrackService.postDataParams(
                    Constants.ClearTrackServiceBaseUrl + `/${path}`,
                    Data,
                    this.props.userContext?.active_tenant?.tenant_id
                ).then(
                    (response) => {
                        if (response.data.error) {
                            tableName === "On_Camera_Performers_Recordings" ? this.setState({ isSubmit: false }) :  tableName === "recordings" ?  this.setState({isRecordingsLoading: false}) :  tableName === "orchestral_Sessions" ? this.setState({isOrchestralLoading: true}) :  tableName === "fringe" ? this.setState({isFringeLoading : true}) : null
                            this.props?.notificationComponent(true, "fail")
                        } else {
                            this.props?.notificationComponent(true, "success")
                            this.getTvMusicBudgetDetails(showPilotBudgetDetails.union_id, false)
                        }
                    },
                    (err) => {
                        tableName === "On_Camera_Performers_Recordings" ? this.setState({ isSubmit: false }) :  tableName === "recordings" ?  this.setState({isRecordingsLoading: false}) :  tableName === "orchestral_Sessions" ? this.setState({isOrchestralLoading: true}) :  tableName === "fringe" ? this.setState({isFringeLoading : true}) : null
                        this.props?.notificationComponent(true, "fail")
                        console.log("Post showProgramDetails Details error: " + err);
                    }
                );
           
        
    }

    saveInlineEdits = (tableName,data, index, pkValue, dataItem) => {
        console.log("savedata", tableName, data, index, pkValue, dataItem)
        switch (tableName) {
            case 'On_Camera_Performers_Recordings': {
                this.setState({ errorFlag: false })
                if(data[index].budget_detail_code_id && validateNumDotOnly(data[index].rate) && data[index].rate.toString().length > 0 && 
                validateNumDotOnly(data[index].min_hours) && data[index].min_hours.toString().length > 0 && data[index].fringe_detail_id ){
                    let onCameraPerformers = [...data];
                    let onCameraPerformersDetails = [...this.state.onCameraPerformersList];
                    onCameraPerformersDetails[index].detail = onCameraPerformers[index]?.detail_id;
                    onCameraPerformersDetails[index].budget_detail_code_id = onCameraPerformers[index]?.budget_detail_code_id || nu;;;
                    onCameraPerformersDetails[index].description = onCameraPerformers[index]?.description || null;
                    onCameraPerformersDetails[index].rate = onCameraPerformers[index]?.rate|| null;
                    onCameraPerformersDetails[index].min_hours = onCameraPerformers[index]?.min_hours || null;
                    onCameraPerformersDetails[index].fringe_detail_id = onCameraPerformers[index]?.fringe_detail_id || null;
                    onCameraPerformersDetails[index].fringe_detail = onCameraPerformers[index]?.fringe_detail || null;
                    onCameraPerformersDetails[index].notes = onCameraPerformers[index]?.notes || null;
                    onCameraPerformersDetails[index].editing = false;
                    this.setState({ onCameraPerformersList: onCameraPerformersDetails })
                    this.postTvMusicBudgetDetails(onCameraPerformersDetails[index],"On_Camera_Performers_Recordings","tvMusicBudgetingTemplateOnCameraPerformers",onCameraPerformersDetails[index].tv_music_budgeting_template_on_camera_performers_id);
                }else{
                    this.setState({ errorFlag: true })
                }
            }
                break;
                case 'recordings': {
                    this.setState({ recordings: false })
                    if(data[index].budget_detail_code_id && validateNumDotOnly(data[index].rate) && data[index].rate.toString().length > 0 && 
                    validateNumDotOnly(data[index].min_hours) && data[index].min_hours.toString().length > 0 && data[index].fringe_detail_id ){
                        let recordings = [...data];
                        let recordingsDetails = [...this.state.recordingList];
                        recordingsDetails[index].detail = recordings[index]?.detail_id;
                        recordingsDetails[index].budget_detail_code_id = recordings[index]?.budget_detail_code_id;
                        recordingsDetails[index].description = recordings[index]?.description || null;
                        recordingsDetails[index].rate = recordings[index]?.rate || null;
                        recordingsDetails[index].min_hours = recordings[index]?.min_hours || null;
                        recordingsDetails[index].fringe_detail_id = recordings[index]?.fringe_detail_id || null;
                        recordingsDetails[index].fringe_detail = recordings[index]?.fringe_detail || null;
                        recordingsDetails[index].notes = recordings[index]?.notes || null;
                        recordingsDetails[index].editing = false;
                        this.setState({recordingList: recordingsDetails })
                        this.postTvMusicBudgetDetails(recordingsDetails[index],"recordings","tvMusicBudgetingTemplateRecordings",recordingsDetails[index].tv_music_budgeting_template_recordings_id);
                    }
                    else{
                        this.setState({ recordings: true })
                        recordingsDetails[index].editing = true;
                    }
                }
                break;
                case 'orchestral_Sessions': {
                    this.setState({ orchestral: false })
                    if(data[index].budget_detail_code_id && validateNumDotOnly(data[index].rate) && data[index].rate.toString().length > 0 && 
                    validateNumDotOnly(data[index].min_hours) && data[index].min_hours.toString().length > 0 && data[index].fringe_detail_id ){
                        let orchestralSessions = [...data];
                        let orchestralSessionsDetails = [...this.state.orchestralList];
                        orchestralSessionsDetails[index].detail = orchestralSessions[index]?.detail_id;
                        orchestralSessionsDetails[index].budget_detail_code_id = orchestralSessions[index]?.budget_detail_code_id;
                        orchestralSessionsDetails[index].description = orchestralSessions[index]?.description || null;
                        orchestralSessionsDetails[index].rate = orchestralSessions[index]?.rate || null;
                        orchestralSessionsDetails[index].min_hours = orchestralSessions[index]?.min_hours || null;
                        orchestralSessionsDetails[index].fringe_detail_id = orchestralSessions[index]?.fringe_detail_id || null;
                        orchestralSessionsDetails[index].fringe_detail = orchestralSessions[index]?.fringe_detail || null;
                        orchestralSessionsDetails[index].notes = orchestralSessions[index]?.notes || null;
                        orchestralSessionsDetails[index].editing = false;
                        this.setState({ orchestralList: orchestralSessionsDetails  })
                        this.postTvMusicBudgetDetails(orchestralSessionsDetails[index],"orchestral_Sessions","tvMusicBudgetingTemplateOrchestralSessions",orchestralSessionsDetails[index].tv_music_budgeting_template_orchestral_sessions_id);
                    }else{
                        this.setState({ orchestral: true })
                    }
                }
                break;
                case 'fringe': {
                    this.setState({ fringe: false })
                    if(data[index].fringe_detail_id && validateNumDotOnly(data[index].fringe) && data[index].fringe.toString().length > 0 ){
                        let fringeRecord = [...data];
                        let fringeDetails = [...this.state.fringeList];
                        fringeDetails[index].detail = fringeRecord[index]?.detail_id;
                        fringeDetails[index].fringe_detail_id = fringeRecord[index]?.fringe_detail_id;
                        fringeDetails[index].description = fringeRecord[index]?.description || null;
                        fringeDetails[index].fringe = fringeRecord[index]?.fringe || null;
                        fringeDetails[index].notes = fringeRecord[index]?.notes || null;
                        fringeDetails[index].editing = false;
                        this.setState({ frldingeList: fringeDetails  })
                        this.postTvMusicBudgetDetails(fringeDetails[index],"fringe","tvMusicBudgetingTemplateFringe",fringeDetails[index].tv_music_budgeting_template_fringe_id);
                    }else{
                        this.setState({ fringe: true })
                        fringeDetails[index].editing = true;
                    }
                }
                break;
        }
    }

    handleAddNewItem = (tableName,newDataItem) => {
        console.log("newDataItem", newDataItem)
        switch (tableName) {
            case 'On_Camera_Performers_Recordings': {
                this.setState({ errorFlag: false })
                if(newDataItem?.budget_detail_code_id && validateNumDotOnly(newDataItem?.rate) && newDataItem?.rate.length > 0 && validateNumDotOnly(newDataItem?.min_hours) && newDataItem?.min_hours.length > 0 && newDataItem?.fringe_detail_id ){
                    let newOnCameraPerformerData = {
                        detail: newDataItem?.budget_detail_code_id || null ,
                        budget_detail_code_id:newDataItem?.budget_detail_code_id || null,
                        description: newDataItem?.description || null,
                        rate: newDataItem?.rate || null,
                        min_hours: newDataItem?.min_hours || null,
                        fringe_detail_id: newDataItem?.fringe_detail_id || null,
                        fringe_detail: newDataItem?.fringe_detail || null,
                        notes: newDataItem?.notes || null,
                    };
                    let onCameraPerformersDetails = [...this.state.onCameraPerformersList];
                    this.postTvMusicBudgetDetails(newOnCameraPerformerData, "On_Camera_Performers_Recordings", "tvMusicBudgetingTemplateOnCameraPerformers",'newItemPost');
                    this.setState({ onCameraPerformersList: onCameraPerformersDetails });
                }else{
                    this.setState({ errorFlag:true })
                }
              
            }
                break;
                case 'recordings': {
                    this.setState({ recordings: false })
                    if(newDataItem?.budget_detail_code_id &&  validateNumDotOnly(newDataItem?.rate) &&  newDataItem?.rate.length>0 && validateNumDotOnly(newDataItem?.min_hours) && newDataItem?.min_hours.length >0 && newDataItem?.fringe_detail_id ){
                        let newRecordingsData = {
                            detail: newDataItem?.detail_id || null,
                            budget_detail_code_id:newDataItem?.budget_detail_code_id || null,
                            description: newDataItem?.description || null,
                            rate: newDataItem?.rate || null,
                            min_hours: newDataItem?.min_hours || null,
                            fringe_detail_id: newDataItem?.fringe_detail_id || null,
                            fringe_detail: newDataItem?.fringe_detail || null,
                            notes: newDataItem?.notes || null,
                        };
                        let recordingDetails = [...this.state.recordingList];
                        recordingDetails.push(newRecordingsData);
                        this.postTvMusicBudgetDetails(newRecordingsData,"recordings","tvMusicBudgetingTemplateRecordings","newItemPost");
                        this.setState({ recordingList: recordingDetails });
                    }
                   else{
                    this.setState({ recordings:true })
                   }
                }
                break;
                case 'orchestral_Sessions': {
                    this.setState({ orchestral: false })
                    if( newDataItem?.budget_detail_code_id && validateNumDotOnly(newDataItem?.rate) && newDataItem?.rate.length >0 &&  validateNumDotOnly(newDataItem?.min_hours) && newDataItem?.min_hours.length > 0 && newDataItem?.fringe_detail_id){
                        let newOrchestralData = {
                            detail: newDataItem?.detail_id || null,
                            budget_detail_code_id:newDataItem?.budget_detail_code_id || null,
                            description: newDataItem?.description || null,
                            rate: newDataItem?.rate || null,
                            min_hours: newDataItem?.min_hours || null,
                            fringe_detail_id: newDataItem?.fringe_detail_id || null,
                            fringe_detail: newDataItem?.fringe_detail || null,
                            notes: newDataItem?.notes || null,
                        };
                        let orchestralDetails = [...this.state.orchestralList];
                        orchestralDetails.push(newOrchestralData);
                        this.postTvMusicBudgetDetails(newOrchestralData,"orchestral_Sessions","tvMusicBudgetingTemplateOrchestralSessions","newItemPost");
                        this.setState({ orchestralList: orchestralDetails });
                    }else{
                        this.setState({ orchestral:true })
                    }
                }
                break;
                case 'fringe': {
                    this.setState({ fringe: false })
                    if(newDataItem?.fringe_detail_id &&  validateNumDotOnly(newDataItem?.fringe) && newDataItem?.fringe.length > 0){
                        let newfringeData = {
                            detail: newDataItem?.detail_id || null,
                            fringe_detail_id:newDataItem?.fringe_detail_id || null,
                            description: newDataItem?.description || null,
                            fringe: newDataItem?.fringe || null,
                            notes: newDataItem?.notes || null,
                        };
                        let fringeDetails = [...this.state.fringeList];
                        fringeDetails.push(newfringeData);
                        this.postTvMusicBudgetDetails(newfringeData,"fringe","tvMusicBudgetingTemplateFringe","newItemPost");
                        this.setState({ fringeList: fringeDetails });
                    }else{
                        this.setState({ fringe:true })
                    }
                }
                break;
        }
        
    };

    handleReadCallback = (tableName, index) => {
        if (tableName === "On_Camera_Performers_Recordings") {
            let allDetails = [...this.state.onCameraPerformersList]
            allDetails[index].editing = false
            this.setState({ onCameraPerformersList: allDetails });
        }
        else if (tableName === "recordings") {
            let allDetails = [...this.state.recordingList]
            allDetails[index].editing = false
            this.setState({ recordingList: allDetails });
        }
        else if (tableName === "orchestral_Sessions") {
            let allDetails = [...this.state.orchestralList]
            allDetails[index].editing = false
            this.setState({ orchestralList: allDetails });
        }
        else if (tableName === "fringe") {
            let allDetails = [...this.state.fringeList]
            allDetails[index].editing = false
            this.setState({ fringeList  : allDetails });
        }
        
    }
    handleshowPayIncreaseModal = () => {
        this.setState({ payIncreaseModal: true });
    }
    handleHidePayIncreaseModal = () => {
        this.setState({ payIncreaseModal: false });
    }

    handleCheckRelation = (tableName,id, flag, payload, uiTable, path) => {
        this.setState({ [flag]: id });
        ClearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl +
            `/checkRelationalTables?tableName=${tableName}&tableId=${id}`,
            this.props?.userContext?.active_tenant?.tenant_id
           )
            .then((res) => {
                if (res?.data[0]?.status === 'SUCCESS') {
                    payload.is_delete = 1
                    this.postTvMusicBudgetDetails(payload,uiTable,path,id);
                } else {
                    this.props?.notificationComponent(true, "fail", 'This record can not be deleted as it is used at other places')
                    this.setState({ [flag]: false });
                }
            }),
            ((err) => {
                this.props?.notificationComponent(true, "fail")
                this.setState({[flag]: false });
            });
    }


    render() {
        return (
           <div className="TvMusicBudget-Container">
                <h4>TV Creative Music Budgeting Template</h4>
                
                <MDBRow>
                     <MDBCol md={2}>
                            <SelectField id={"Union"}
                                placeholder="Unoin"
                                value={this.state.tvMusicBudgetDetails?.union_id}
                                options={this.state.unionList || []}
                                onChange={(e) => this.handleOnChange("union_id", e.target.value)}
                                width={"100%"}
                            />
                        </MDBCol>
                        <MDBCol>
                        <BasicButton
                                className="right"
                                variant="contained"
                                text="Pay Increase"
                                disabled={!this.state.tvMusicBudgetDetails?.union_id}
                                onClick={this.handleshowPayIncreaseModal}
                            />
                            </MDBCol>
                           
                        </MDBRow>
                   
                        <div>
                            <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                <div className="cueSheet-header">
                                    <div className="cuesheet-btn">
                                        <Typography className="header-cue">
                                            On Camera Performer
                                    </Typography>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                        </AccordionSummary>
                                    </div>
                                </div>
                        <AccordionDetails className="cuesheet-table">
                           
                            {this.state.isLoading ? (
                                <div style={{ textAlign: "center" }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) : (
                                    <div className="cueSheetTable table-scroll" id="people-table-bg">
                                        {this.state.errorFlag ? <p className="f-color">Please Enter Detail,Rate,Min.Hour and FringeDetails</p> : this.state.isLoading = false}
                                        {this.state.config.headings[0].selectOptions.length > 0 && this.state.config.headings[4].selectOptions.length > 0 &&
                                            <TableComponent
                                                list={this.state.onCameraPerformersList || []}
                                                className={"clearencRecord"}
                                                config={this.state.config}
                                                editCallback={this.handleInlineEdits.bind(this, "On_Camera_Performers_Recordings")}
                                                saveCallback={this.saveInlineEdits.bind(this, "On_Camera_Performers_Recordings")}
                                                handleAddNewItem={this.handleAddNewItem.bind(this, "On_Camera_Performers_Recordings")}
                                                readCallback={this.handleReadCallback.bind(this, "On_Camera_Performers_Recordings")}
                                                addItemToList={this.state.tvMusicBudgetDetails?.union_id ?  this.state.permissionName?.edit === 1 :  false}
                                                postInitiated={this.state.isSubmit}
                                                handleDelCallBack={(id,dataItem) => {this.handleCheckRelation('tv_music_budgeting_template_on_camera_performers',id,'isSubmit', dataItem, "On_Camera_Performers_Recordings","tvMusicBudgetingTemplateOnCameraPerformers" )}}
                                                stickyHeader={true}
                                            />
                                        }
                                    </div>
                                )}
                            </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                <div className="cueSheet-header">
                                    <div className="cuesheet-btn">
                                        <Typography className="header-cue">
                                            Recordings
                                    </Typography>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                        </AccordionSummary>
                                    </div>
                                </div>
                        <AccordionDetails className="cuesheet-table">
                            {this.state.isLoading ? (
                                <div style={{ textAlign: "center" }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) : (
                                    <div className="cueSheetTable table-scroll" id="people-table-bg">
                                        {this.state.recordings ? <p className="f-color">Please Enter Detail,Rate,Min.Hour and FringeDetails</p> : this.state.isLoading = false}
                                        <TableComponent
                                            list={this.state.recordingList || []}
                                            className={"clearencRecord"}
                                            config={this.state.recordingConfig}
                                            editCallback={this.handleInlineEdits.bind(this, "recordings")}
                                            saveCallback={this.saveInlineEdits.bind(this, "recordings")}
                                            handleAddNewItem={this.handleAddNewItem.bind(this, "recordings")}
                                            readCallback={this.handleReadCallback.bind(this, "recordings")}
                                            addItemToList={this.state.tvMusicBudgetDetails?.union_id ?  this.state.permissionName?.edit === 1 :  false}
                                            postInitiated={this.state.isRecordingsLoading}
                                            handleDelCallBack={(id, dataItem) => {this.handleCheckRelation('tv_music_budgeting_template_recordings',id,'isRecordingsLoading', dataItem, "recordings","tvMusicBudgetingTemplateRecordings" )}}
                                            stickyHeader={true}
                                        />
                                    </div>
                                )}
                            </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                <div className="cueSheet-header">
                                    <div className="cuesheet-btn">
                                        <Typography className="header-cue">
                                            Orchestral Sessions
                                    </Typography>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                        </AccordionSummary>
                                    </div>
                                </div>
                        <AccordionDetails className="cuesheet-table">
                            {this.state.isLoading ? (
                                <div style={{ textAlign: "center" }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) : (
                                    <div className="cueSheetTable table-scroll" id="people-table-bg">
                                        {this.state.orchestral ? <p className="f-color">Please Enter Detail,Rate,Min.Hour and FringeDetails</p> : this.state.isLoading = false}
                                        <TableComponent
                                            list={this.state.orchestralList || []}
                                            className={"clearencRecord"}
                                            config={this.state.orchestralConfig}
                                            editCallback={this.handleInlineEdits.bind(this, "orchestral_Sessions")}
                                            saveCallback={this.saveInlineEdits.bind(this, "orchestral_Sessions")}
                                            handleAddNewItem={this.handleAddNewItem.bind(this, "orchestral_Sessions")}
                                            readCallback={this.handleReadCallback.bind(this, "orchestral_Sessions")}
                                            addItemToList={this.state.tvMusicBudgetDetails?.union_id ?  this.state.permissionName?.edit === 1 :  false}
                                            postInitiated={this.state.isOrchestralLoading}
                                            handleDelCallBack={(id,dataItem) => {this.handleCheckRelation('tv_music_budgeting_template_orchestral_sessions',id,'isOrchestralLoading',dataItem, "orchestral_Sessions","tvMusicBudgetingTemplateOrchestralSessions" )}}
                                            stickyHeader={true}
                                        />
                                    </div>
                                )}
                        </AccordionDetails>
                            </Accordion>
                        </div>
                        <div>
                            <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                <div className="cueSheet-header">
                                    <div className="cuesheet-btn">
                                        <Typography className="header-cue">
                                            Fringe
                                    </Typography>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                        </AccordionSummary>
                                    </div>
                                </div>
                        <AccordionDetails className="cuesheet-table">
                            {this.state.isLoading ? (
                                <div style={{ textAlign: "center" }}>
                                    <CircularProgress size={24} />
                                </div>
                            ) : (
                                    <div className="cueSheetTable table-scroll" id="people-table-bg">
                                        {this.state.fringe ? <p className="f-color">Please Enter Detail and FringeDetails</p> : this.state.isLoading = false}
                                        <TableComponent
                                            list={this.state.fringeList || []}
                                            className={"clearencRecord"}
                                            config={this.state.fringeConfig}
                                            editCallback={this.handleInlineEdits.bind(this, "fringe")}
                                            saveCallback={this.saveInlineEdits.bind(this, "fringe")}
                                            handleAddNewItem={this.handleAddNewItem.bind(this, "fringe")}
                                            readCallback={this.handleReadCallback.bind(this, "fringe")}
                                            addItemToList={this.state.tvMusicBudgetDetails?.union_id ?  this.state.permissionName?.edit === 1 :  false}
                                            postInitiated={this.state.isFringeLoading}
                                            handleDelCallBack={(id,dataItem) => {this.handleCheckRelation('tv_music_budgeting_template_fringe',id,'isFringeLoading', dataItem, "fringe","tvMusicBudgetingTemplateFringe" )}}
                                            stickyHeader={true}
                                        />
                                    </div>
                                )}
                                </AccordionDetails>
                            </Accordion>
                            {
                                    this.state.payIncreaseModal &&
                                    <MainViewModalComponent
                                        open={this.state.payIncreaseModal}
                                        handleClose={this.handleHidePayIncreaseModal}
                                        headerText={"Pay Increase"}
                                        mode={""}
                                        modalName={"Pay Increase"}
                                        unionId = {this.state.tvMusicBudgetDetails?.union_id}
                                       getTvMusicBudgetDetails={this.getTvMusicBudgetDetails.bind(this)} 
                                       onCameraList={this.state.onCameraPerformersList}   
                                       onOrchestralList={this.state.orchestralList} 
                                       recordingsList={this.state.recordingList}              
                                    />
                                }
                        </div>
                    </div>
               
        )
    }
}

export default withUserContext(TvMusicBudget);