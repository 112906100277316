import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import CommonAddressComponent from "../CommonAddressComponent";
import { addressConfig, remitsAddressHistory } from "./config";
import messages from "../../../Common/Messages.json";
import '../EditPayee/EditPayee.scss';
import clearTrackService from "../../../services/service";
import { withUserContext } from '../../../contexts/UserContext';
import { CircularProgress } from "@material-ui/core";
import { validateNumbersOnly, validateEmail, validateAllPhone, formatPhoneNumbers } from "../../../Common/Helper";
import TableComponent from "../../SharedComponents/Table";
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";

export default withUserContext(class PayeeRemitDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payeeDetails: this.props?.payeeDetails,
            isExcelPosting: false,
            payeeId: this.props?.payeeId,
            postInitiated: false,
            showExcelAndDocModal: false,
            isOpenExisting: false
        }
    }

    formatAddress(value) {
        if (value) {
            return value
        } else {
            return ''
        }
    }
    isOpenExisting=(data)=>{
        this.setState({isOpenExisting: data})
    }
    handleShowExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: true ,isOpenExisting: false});
      };
      handleHideExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: false });
      };
    downloadReport = (reportName, searchPayload, fileName) => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let load = {
            'payload': 'exportExcel',
            'reportName': reportName,
            'fileName': "Payee Request Form",
            'searchJson': encryptTedStr,
            'showHtml': 0,
            'encryptionType': 1,
            'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
            'divisionId': this.props?.division_id,
        }
        return clearTrackService.getDataWS(load)
    }
    // downloadReport = (reportName, searchPayload,reportType) => {

    //     let encryptTedStr = btoa(JSON.stringify(searchPayload));
    //      if (reportType === "isExcel") {
    //       return clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/exportExcel?reportName=${reportName}&searchJson=${encryptTedStr}`, 
    //       this.props.userContext?.active_tenant?.tenant_id, 1, 1)

    //     }
    // }
    downloadExcel = () => {
        this.setState({ postInitiated: true, isExcelPosting: true });
        let reportName = "rptpayeerequestformexcel";
        let searchPayload = { payee_id: this.state.payeeId ,isOpenExisting : this.state.isOpenExisting ? 1 : 0}
        this.downloadReport(reportName, searchPayload, "isExcel").then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if (response?.statusCode == 200) {
                    this.setState({ isExcelPosting: false })
                    this.downloadUrl(response?.body);
                    this.notificationComponent(true, 'success')
                } else {
                    this.notificationComponent(true, 'fail')
                }
            }
        })
    }
    downloadUrl = (url) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                // link.href = window.URL.createObjectURL(blob);
                link.href = url;
                // link.setAttribute('download', reportName);
                document.body.appendChild(link);
                this.handleHideExcelAndDocModal()
                link.click();
                link.remove();
            })
        }
    }
    //   downloadUrl = (url, reportName) => {
    //     if (url) {
    //       fetch(url).then(response => response.blob()).then(blob => {
    //         const link = document.createElement('a');
    //         // link.href = window.URL.createObjectURL(blob);
    //         // link.setAttribute('download', reportName);
    //         link.href = url
    //         document.body.appendChild(link);
    //         link.click();
    //         link.remove();
    //         this.notificationComponent(true, "success");
    //       })
    //     }
    //   }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccessMessage: value });
            case "fail": return this.setState({ showFailMessage: value });
        }
    }
    handleResetNotify = () => {
        this.setState({ showFailMessage: false, showSuccessMessage: false });
        if (this.state.saveFlag) {
            this.closeToastMessage();
        }
    }
    closeToastMessage = () => {
        this.setState({ showErrorMessage: false, showFailMessage: false, showSuccessMessage: false });

    }
    render() {
        let historyData = this.props?.payeeDetails[this.props?.index]?.addres_history?.map((item, index) => {
            let newItem = {...item}
            newItem.address = this.formatAddress(item?.address_1) + " " + this.formatAddress(item?.address_2) + " " + this.formatAddress(item?.city) + " " + this.formatAddress(item?.state) + ",  " + this.formatAddress(item?.country) + " " + this.formatAddress(item?.zip);
            // if (item?.changed_by !== null && payeeData.addres_history[index].address !== null ) {
            //     isAddress = true
            // } else {
            //     isAddress = false
            // }
            return newItem
        })
        return (
            <MDBContainer>
                <div>
                    <MDBRow>
                        <MDBCol md={2}>
                            <BasicTextField
                                label="Remit To #"
                                // isMandatory={true}
                                // showMandatory={this.props?.postInitiated || false}
                                mandatoryWarning={messages.mandatoryWarning}
                                value={this.props?.payeeDetails[this.props?.index]?.remit_to || ""}
                                onChange={(e) => validateNumbersOnly(e.target.value) ? this.props?.handleChangeRemitFields("remits_to", "remit_to", e.target.value.length > 0 ? e.target.value : null, this.props?.index) : null}
                            />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicTextField
                                label="DBA"
                                value={this.props?.payeeDetails[this.props?.index]?.DBA || ""}
                                onChange={(e) => this.props?.handleChangeRemitFields("remits_to", "DBA", e.target.value.length > 0 ? e.target.value : null, this.props?.index)}
                            />
                        </MDBCol>
                        <MDBCol md={1} className="SelfEnd">
                            <BasicCheckbox
                                label="Inactive"
                                align="end"
                                id={"is_active"}
                                checked={this.props?.payeeDetails[this.props?.index]?.is_active === 0}
                                onChange={(e) => this.props?.handleChangeRemitFields("remits_to", "is_active", e.target.checked ? 0 : 1, this.props?.index)}
                            />
                        </MDBCol>
                        <MDBCol md={2} className="SelfEnd">
                            <BasicCheckbox
                                label="Default Remit To"
                                align="end"
                                id={"default_remit_to"}
                                checked={this.props?.payeeDetails[this.props?.index]?.default_remit_to === 1}
                                onChange={(e) => this.props?.handleChangeRemitFields("remits_to", "default_remit_to", e.target.checked ? 1 : 0, this.props?.index)}
                            />
                        </MDBCol>
                        <MDBCol md={2} className="delete-font mt-4">
                            {this.props?.index === 0?null :this.props?.isDelete ? <CircularProgress size={18} /> :
                                <span className="delete-btn"
                                    onClick={() => this.props?.deleteTabItem(this.props?.payeeDetails[this.props?.index])}
                                >Delete Remit To</span>}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={4}>
                            <BasicTextField
                                label="Contact Name"
                                value={this.props?.payeeDetails[this.props?.index]?.contact_name || ""}
                                onChange={(e) => this.props?.handleChangeRemitFields("remits_to", "contact_name", e.target.value.length > 0 ? e.target.value : null, this.props?.index)}
                            />
                            <BasicTextField
                                label="Email"
                                value={this.props?.payeeDetails[this.props?.index]?.email || ""}
                                onChange={(e) => this.props?.handleChangeRemitFields("remits_to", "email", e.target.value.length > 0 ? e.target.value : null, this.props?.index)}
                                inValidInput={messages?.invalidInput}
                                fieldValid={!validateEmail(this.props?.payeeDetails[this.props?.index]?.email) && this.props?.payeeDetails[this.props?.index]?.email}
                            />
                        </MDBCol>
                        <MDBCol md={4} className="mt3">
                            <MDBCol md={6}>
                                <BasicTextField
                                    label="Phone"
                                    placeholder="(___) ___-____"
                                    // fieldValid={this.props?.payeeDetails[this.props?.index]?.phone ? !validateAllPhone(this.props?.payeeDetails[this.props?.index]?.phone) : false}
                                    // inValidInput={"Please enter valid phone"}
                                    value={this.props?.payeeDetails[this.props?.index]?.phone || ""}
                                    onChange={(e) => this.props?.handleChangeRemitFields("remits_to", "phone", e.target.value?.length > 0 ? formatPhoneNumbers(e.target.value) : null, this.props?.index)}
                                />
                                <MDBCol md={6}></MDBCol>
                            </MDBCol>
                        </MDBCol>
                        <MDBCol md={2} className="SelfCenter">
                            {this.props?.mode === 'Edit' ?
                                <BasicButton
                                    className="btnwidth"
                                    variant="contained"
                                    type="inline"
                                    text={this.state.isExcelPosting ? (
                                        <CircularProgress color="inherit" size={18} />
                                    ) : ("Payee Request Form")}
                                    onClick={this.handleShowExcelAndDocModal}
                                    postInitiated={this.state.isExcelPosting}
                                /> : " "}
                        </MDBCol>
                        {this.props?.mode === 'Edit' && this.props?.index < this.props?.existingPayeeDataLength && <MDBCol md={2} className="SelfCenter">
                            <BasicButton
                                className="btnwidth"
                                variant="contained"
                                type="inline"
                                text={"Past Address"}
                                onClick={() => this.props?.handleShowAddressTable()}
                            />
                        </MDBCol>}
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <CommonAddressComponent
                                addressDetails={this.props?.payeeDetails[this.props?.index]}
                                addressConfig={addressConfig}
                                editCallback={(field, value) => this.props?.handleChangeRemitFields("remits_to", field, value, this.props?.index)} />
                        </MDBCol>
                        <MDBCol md={4}>
                            <BasicTextArea
                                label="Notes"
                                rows={2}
                                // isMandatory={this.props?.payeeDetails[this.props?.index]?.is_address_updated === 1 ? true : false}
                                // showMandatory={this.props?.payeeDetails[this.props?.index]?.is_address_updated === 1 && this.props?.postInitiated}
                                value={this.props?.payeeDetails[this.props?.index]?.payee_notes || null}
                                onChange={(e) => this.props?.handleChangeRemitFields("remits_to", "payee_notes", e.target.value.length > 0 ? e.target.value : null, this.props?.index)}
                            />
                        </MDBCol>
                    </MDBRow>
                    {this.props?.showPastAddress ? <MDBRow>
                        <div className="mt-2 mb-2" id={"past-address-table"}>
                            <TableComponent
                                list={historyData || []}
                                config={remitsAddressHistory}
                                isLoading={false}
                            />
                        </div>
                    </MDBRow> : null}
                </div>
                {this.state.showExcelAndDocModal && (
                <>
                <MainViewModalComponent
                open={this.state.showExcelAndDocModal}
                handleClose={this.handleHideExcelAndDocModal}
                headerText={"Generate Excel/DOC"}
                mode={''}
                modalName={"Excel And Doc Modal"}
                downloadExcel={this.downloadExcel}
                isOpenExisting = {this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isExcelPosting}

              />
            </>
          )}
            </MDBContainer >
        )
    }
}
)