export const clearRecordConfig = {
    headings: [
        {
            headingLabel: "Program",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Season",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Episode",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Composition",
            icon: "",
            width: "30%"
        },
        {
            headingLabel: "Rights",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Fee",
            icon: "",
            width: "10%"
        },

        {
            headingLabel: "Status",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "",
            icon: "",
            width: "10%"
        },

    ],
    dataNodes: ["program", "season", "episode", "composition", "rights", "fee", "status"],
    actions: ["share"],
    // actions: ["pen","trash-alt"],

    hyperlinks: ["program", "season", "episode", "share"],
    // changeColourToGreen: ["program", "season", "episode","share"],
    highLightedColumns: ["program", "season", "episode"],
}
export const payeeDetails = {
    "payee_company": null,
    "payee_group": "tax",
    "tax": null,
    "ssn": null,
    "other": null,
    "is_NA": null,
    "vendor": null,
    "is_ACH": 0,
    "is_intl_wire_transfer": 0,
    "is_check": 0,
    "is_foreign_vendor": 0,
    "notes": null,
    "is_active": 1,
    "tenant_id": null,
    "remits_to": [
        {
            "remit_to_no": null,
            "contact_name": null,
            "email": null,
            "phone": null,
            "address_1": null,
            "address_2": null,
            "city": null,
            "state": null,
            "country": null,
            "country_id": null,
            "zip": null,
            "payee_notes": null,
            "remit_to": null,
            "default_remit_to": 0,
            "DBA": null,
            "is_active": 1,
            "is_address_updated": 0,
            "history_address_1": null,
            "history_address_2": null,
            "history_city": null,
            "history_state": null,
            "history_country": null,
            "history_country_id": null,
            "history_zip": null
        }
    ]
}
export const remitsToAddress = {
    "remit_to_no": null,
    "contact_name": null,
    "email": null,
    "phone": null,
    "address_1": null,
    "address_2": null,
    "city": null,
    "state": null,
    "country": null,
    "country_id": null,
    "zip": null,
    "payee_notes": null,
    "remit_to": null,
    "default_remit_to": 0,
    "DBA": null,
    "is_active": 1,
    "is_address_updated": 0
}

export const addressConfig = {
    "address1": "address_1",
    "address2": "address_2",
    "country": "country_id",
    "state": "state",
    "city": "city",
    "zipcode": "zip",
}
export const remitsAddressHistory = {
    headings: [
        {
            headingLabel: "Change Date",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Changed By",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Address",
            icon: "",
            width: "30%"
        },
        {
            headingLabel: "Notes",
            icon: "",
            width: "20%"
        }

    ],
    dataNodes: ["change_date", "changed_by", "address", "notes"],
}