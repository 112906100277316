import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../../../SharedComponents/BasicCheckbox/BasicCheckbox";
import "../MusicEditorDetails.scss";
import AgentDetails from "./AgentDetails";
import { initialComposerDetails } from "../config";
import ClearTrackServices from "../../../../services/service";
import * as Constants from "../../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../../contexts/UserContext';
import { validateCharNumOnly, validateAllPhone, formatPhoneNumbers } from '../../../../Common/Helper';
import Radio from "@mui/material/Radio";
import MainViewModalComponent from '../../../../Common/MainViewModalLayout/Modal/Modal';
import MessageModal from '../../../SharedComponents/MessageModal';
let invalidValuesField = [], composerAndSupervisorDetails = {}, division_id, people_id, show_season_people_id, existingPayeeId = null;
class ShowComposerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: initialComposerDetails,
      isPosting: false,
      fieldValid: true,
      postInitiated: false,
      isLoading: true,
      showCreatePayeeModal: false,
      showCreatePayeeConfirmationModal: false,
      old_payee_id: null
    };
  }
  componentDidMount() {
    if (this.props?.mode === "Edit") {
      this.setState({ isLoading: true });
      let division_id, show_season_people_id, people_id;
      if (this.props.origin === "profile" || this.props.origin === "dashboard") {
        division_id = null;
        people_id = this.props?.people_id ? this.props.people_id : null;
        show_season_people_id = null;
      }
      else {
        division_id = this.props?.division_id ? this.props?.division_id : null;
        people_id = null;
        show_season_people_id = this.props?.show_season_people_id ? this.props?.show_season_people_id : null;
      }

      ClearTrackServices.getData(
        Constants.ClearTrackServiceBaseUrl + `/people?show_season_people_id=${show_season_people_id}&people_id=${people_id}`,
        this.props.userContext?.active_tenant?.tenant_id, division_id
      )
        .then((res) => {
          this.setState({ details: res?.data[0], old_payee_id: res?.data[0]?.payee_id, isLoading: false })
        })
        .catch((err) => {
          this.setState({ isLoading: false })
          console.log("err--");
        });
    } else {
      this.setState({ isLoading: false, details: { ...initialComposerDetails } });
    }

  }
  validateErrors = (composerAndSupervisorDetails) => {
    let canSubmit = composerAndSupervisorDetails?.full_name && (composerAndSupervisorDetails?.cell_phone ? validateAllPhone(composerAndSupervisorDetails?.cell_phone) : true) &&
      (composerAndSupervisorDetails?.work_phone ? validateAllPhone(composerAndSupervisorDetails?.work_phone) : true) &&
      (composerAndSupervisorDetails?.agent_cell_phone ? validateAllPhone(composerAndSupervisorDetails?.agent_cell_phone) : true) &&
      (composerAndSupervisorDetails?.agent_work_phone ? validateAllPhone(composerAndSupervisorDetails?.agent_work_phone) : true)
      //  &&
      // (composerAndSupervisorDetails?.zip_code ? validateCharNumOnly(composerAndSupervisorDetails?.zip_code) &&
      //   (composerAndSupervisorDetails?.country === 'United States' ?
      //     composerAndSupervisorDetails?.zip_code?.length <= 5 : composerAndSupervisorDetails?.zip_code?.length <= 10) : true) &&
      // (composerAndSupervisorDetails?.agent_zip_code ? validateCharNumOnly(composerAndSupervisorDetails?.agent_zip_code) &&
      //   (composerAndSupervisorDetails?.country === 'United States' ?
      //     composerAndSupervisorDetails?.agent_zip_code?.length <= 5 : composerAndSupervisorDetails?.agent_zip_code?.length <= 10) : true)
    return canSubmit
  }
  preHandleSubmit = () => {
    this.setState({ postInitiated: true });
    composerAndSupervisorDetails = { ...this.state.details }
    if (this.validateErrors(composerAndSupervisorDetails)) {
      this.setState({ postInitiated: false, isPosting: true });
      if (this.props?.modeType === "Music Supervisor" || this.props?.modeType === "MUSIC_SUPERVISOR") {
        composerAndSupervisorDetails.people_type = "MUSIC_SUPERVISOR";
      }
      if (this.props?.modeType === "Show Composer" || this.props?.modeType === "SHOW_COMPOSER") {
        composerAndSupervisorDetails.people_type = "SHOW_COMPOSER";
      }
      if (this.props?.mode === "Edit") {
        if (this.props.origin === "profile" || this.props.origin === "dashboard") {
          division_id = null;
          composerAndSupervisorDetails.people_id = this.props?.people_id ? this.props.people_id : null;
          composerAndSupervisorDetails.show_season_people_id = null;
          composerAndSupervisorDetails.is_profile = 1;
          composerAndSupervisorDetails.division_id = null;
        } else {
          division_id = this.props?.division_id ? this.props.division_id : null;
          composerAndSupervisorDetails.people_id = null;
          composerAndSupervisorDetails.show_season_people_id = this.props?.show_season_people_id ? this.props.show_season_people_id : null;
          composerAndSupervisorDetails.division_id = this.props?.division_id ? this.props.division_id : null;
        }
      }
    }
  }
  postPeopleData = (payeeId = null) => {
    composerAndSupervisorDetails.payee_id = payeeId;
    ClearTrackServices.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/people`,
      composerAndSupervisorDetails,
      this.props.userContext?.active_tenant?.tenant_id, division_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
        } else {
          this.setState({ isPosting: false });
          if (this.props?.origin !== "dashboard") {
            console.log("called after successful post");
            if (this.props?.mode === "New") {
              this.props?.notificationComponent(true, "success");
              this.props.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.props.show_season_id}`);
              this.props.handleHideShowComposer();
              //this.addPayeeConfirmationModal();

            }
            if (this.props?.mode === "Edit") {
              this.props?.notificationComponent(true, "success");
              this.props.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.props.show_season_id}`, "editing");
              this.props.handleHideShowComposer();
            }
          }
          if (this.props?.origin === "dashboard") {
            this.props?.notificationComponent(true, "success");
            this.props.handleHideShowComposer();
           // this.props?.handleSubmit();
          }
          //this.props?.notificationComponent(true, "success");
        }
      },
      (err) => {
        this.setState({ postInitiated: false, isPosting: false });
        this.props?.notificationComponent(true, "fail");
        console.log("Post details error: " + err);
      }
    );
  }
  handleSubmit = () => {
    this.preHandleSubmit();
    if (this.props?.mode === "New") {
      division_id = this.props?.division_id ? this.props.division_id : null;
      if (this.props.origin === "dashboard") {
        composerAndSupervisorDetails.people_id = this.props?.people_id ? this.props.people_id : null;
        composerAndSupervisorDetails.show_season_people_id = this.props?.show_season_people_id ? this.props.show_season_people_id : null;
        composerAndSupervisorDetails.is_dashboard = 1;
        composerAndSupervisorDetails.division_id = this.props?.division_id ? this.props.division_id : null;
      } else {
        composerAndSupervisorDetails.people_id = null;
        composerAndSupervisorDetails.show_season_id = this.props?.show_season_id ? this.props.show_season_id : null;
        composerAndSupervisorDetails.show_id = this.props?.show_id ? this.props.show_id : null;
        composerAndSupervisorDetails.season_id = this.props?.season_id ? this.props.season_id : null;
        composerAndSupervisorDetails.division_id = this.props?.division_id ? this.props.division_id : null;
      }

    }
    if (this.props?.mode !== "New") {
      this.checkPayeeEditValidation();
      // if (this.state.details?.payee_id !== null || (this.state.details?.payee_id === null && this.props?.checkReqData?.length === 0)) {
      //   this.checkPayeeEditValidation();
      // }
      // if (this.state.details?.payee_id === null && this.props?.checkReqData?.length > 0) {
      //   console.log("this.state.details?.payee_id === null && this.props?.checkReqData?.length > 0");
      //   this.props?.notificationComponent(true, "checkRelationalFailure");
      //   this.setState({ isPosting: false });
      // }
    } else {
      this.addPayeeConfirmationModal();
    }
  }

  handleChange = (field, value) => {
    if (field === "payee_company") {
      if (value !== null) {
        this.setState(prevState => ({
          details: {
            ...prevState.details,
            ["payee_id"]: value.value,
            ["payee_company"]: value.text,
          }
        }))
        console.log("checkReqData", this.props?.checkReqData);
      } else {
        this.setState(prevState => ({
          details: {
            ...prevState.details,
            ["payee_id"]: null,
            ["payee_company"]: null
          }
        }))
      }

      if (this.props?.checkReqData?.length > 0) {
        existingPayeeId = this.props?.checkReqData[0]?.payee_id;
        //existingPayeeId = this.props?.checkReqData.filter(item => (item.payee_id === value.value) ? item : null);
        console.log("existingPayeeId", existingPayeeId);
      }

    } else {
      this.setState(prevState => ({
        details: {
          ...prevState.details,
          [field]: value
        },
        postInitiated: false
      }))
    }

  };
  handleCheckboxChange = (field, value) => {
    console.log("field", field, "value", value)
    this.setState(prevState => ({
      details: {
        ...prevState.addShowDetails,
        [field]: value ? 1 : 0
      }
    }))
  }
  handleRadioChange(e, field) {
    console.log("e.target.value", e.target.value, field);
    this.setState((prevState) => ({
      details: {
        ...prevState.details,
        [field]: e.target.value
      }
    }))
  }
  handleADDPayeeModal = () => {
    this.setState({ showCreatePayeeConfirmationModal: false, showCreatePayeeModal: true })
  }
  addPayeeConfirmationModal = () => {
    this.setState({ showCreatePayeeConfirmationModal: true })
  }
  closePayeeConfirmationModal = (mode) => {
    this.setState({ showCreatePayeeConfirmationModal: false });
    this.postPeopleData(null)
    //this.props.handleHideShowComposer();
  }
  checkPayeeEditValidation = () => {
    let payeePeopleDetails = {
      "show_season_id": this.props?.show_season_id,
      "show_season_people_id": composerAndSupervisorDetails.show_season_people_id,
      "payee_id": this.state.details?.payee_id,
      "people_id": this.props?.people_id,
      "old_payee_id": this.state.old_payee_id
    };
    ClearTrackServices.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/payeePeople`,
      payeePeopleDetails,
      this.props.userContext?.active_tenant?.tenant_id, division_id
    ).then(res => {
      if (res.data[0].status === "SUCCESS") {
        this.postPeopleData(this.state.details?.payee_id);
      }
      if (res.data[0].status === "FAILURE") {
        this.props?.notificationComponent(true, "fail");
        this.setState({ isPosting: false });
      }
    }),
      ((err) => {
        //this.props?.fieldChanged(true)
        this.props?.notificationComponent(true, "fail");
        this.setState({ isPosting: false });
      });
  }
  render() {
    console.log("this.state.details", this.state.details);
    return (
      <MDBContainer fluid className="ComposerContainer">
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (<>
          <div className="radioBtnWrapper">
            <div className="radioBtnContainer">
              <Radio name="radio-buttons"
                checked={this.state.details?.is_individual == Number("1")}
                onChange={(e) => this.handleRadioChange(e, "is_individual")} value={Number("1")}
              />
              <span className="popover-content-value">Individual</span>
            </div>
            <div className="radioBtnContainer">
              <Radio name="radio-buttons"
                checked={this.state.details?.is_individual == Number("0")}
                onChange={(e) => this.handleRadioChange(e, "is_individual")} value={Number("0")}
              />
              <span className="popover-content-value">Loanout</span>
            </div>
          </div>
          <MDBRow>
            <AgentDetails origin="BusinessAffairs"
              details={this.state.details}
              handleChange={this.handleChange} postInitiated={this.state.postInitiated} modeType={this.props?.modeType}
              newOrEdit={this.props?.mode}
            />
          </MDBRow>
          <MDBRow id="compodetails">
            <div className="agent">Agent</div>
          </MDBRow>
          <MDBRow>
            <AgentDetails
              view={true}
              details={this.state.details}
              handleChange={this.handleChange} postInitiated={this.state.postInitiated} modeType={this.props?.modeType}
              newOrEdit={this.props?.mode}
            />
          </MDBRow>
          {/* {this.props.origin === "BusinessAffairs" &&
            <MDBRow>
              <BasicCheckbox label={"Create Payee"}
                id={"create_payee"}
                checked={this.state.details?.create_payee_flag}
                onChange={(e) => this.handleCheckboxChange("create_payee_flag", e.target.checked)}
              />
            </MDBRow>
          } */}
          <MDBRow>
            <MDBCol md={10}></MDBCol>
            <MDBCol md={2} className="saveCancelWrapper">
              <BasicButton
                className="btnwidth"
                variant="contained"
                type="inline"
                text={
                  this.state.isPosting ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : ("Save")
                }
                onClick={this.handleSubmit}
              />
              <BasicButton
                onClick={() => this.props.handleClose()}
                variant="outlined"
                text={"Cancel"}
              />
            </MDBCol>
          </MDBRow>
        </>)}
        {this.state.showCreatePayeeModal &&
          <MainViewModalComponent
            open={this.state.showCreatePayeeModal}
            handleClose={() => this.setState({ showCreatePayeeModal: false })}
            headerText={"Payee"}
            mode={"New"}
            modalName={"Payee"}
            commonPermissions={this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_name === "Show Composers/Music Supervisors (BA)")}
            modeType={this.props?.modeType}
            linkedMsScName={this.state.details?.full_name}
            handleSubmit={this.props?.handleSubmit}
            fetchData={this.props?.fetchData}
            show_season_id={this.props?.show_season_id}
            postPeopleData={this.postPeopleData.bind(this)}
          />
        }
        {this.state.showCreatePayeeConfirmationModal &&
          <MessageModal
            title={"Create Payee"}
            open={this.state.showCreatePayeeConfirmationModal}
            message={"Do you want to create Payee?"}
            primaryButtonText={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : "Yes"}
            secondaryButtonText={"Cancel"}
            onConfirm={this.handleADDPayeeModal}
            handleClose={this.closePayeeConfirmationModal} />
        }
      </MDBContainer>
    );
  }
}
export default withUserContext(ShowComposerDetails);