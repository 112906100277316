import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import RichTextEditor from "../SharedComponents/RichTextEditor/RichTextEditor";
import clearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { withUserContext } from "../../contexts/UserContext";
import { postPayload } from "./config";
import alphabetVariantsJson from "../SharedComponents/RichTextEditor/AlphabetVariant.json";
import CircularProgress from "@material-ui/core/CircularProgress";
let postPayloadData = JSON.parse(JSON.stringify(postPayload))
class ExecCardLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      letterRequestData: {},
      isPosting: false,
      isLoading: false,
      letterLanguageId: null,
      letterTypeId: null,
      alphabetVariantMessage: false,
      alphaVariants: [],
      richTextEditor: null,
      indexToModify: null,
      editorText: null,
      characterToModify: null,
      letterLanguageData: null,
      isFetchingLetterLoading: false,
      isTextError: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchData(
      "letterType",
      `/entitySearch?entity=LETTER_TYPE&searchString=null`
    );
  }

  getLetterSetupData = () => {
    this.setState({ isFetchingLetterLoading: true });
    let { letterTypeId } = this.state;
    let data = {
      letter_type_id: letterTypeId,
      text_type: "Exec Card Language",
    };
    console.log("data", data);
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl +
          `/letterLanguage?input=${btoa(JSON.stringify(data))}`,
        this.props.userContext?.active_tenant?.tenant_id,
        null,
        true
      )
      .then(
        (response) => {
          if (response.data.error) {
            (err) => {
              this.setState({
                isLoading: false,
                isFetchingLetterLoading: false,
              });
              console.log("Error in fetching details:", err);
            };
          } else {
            console.log(
              "this.props.userContext?.active_tenant?.tenant_id",
              this.props.userContext
            );
            this.setState({ isLoading: false, isFetchingLetterLoading: false });
            let index = response.data.length;
            if (response.data.length > 0) {
              this.setState({
                letterLanguageId: response.data[index - 1]?.letter_language_id,
                richTextEditor: response.data[index - 1]?.description,
              });
            } else {
              this.setState({ letterLanguageId: null, richTextEditor: " " });
            }
          }
        },
        (err) => {
          this.setState({ isLoading: false, isFetchingLetterLoading: false });
          console.log("Error in fetching details:", err);
        }
      );
  };

  fetchData = (field, url) => {
    clearTrackService
      .getData(
        Constants.ClearTrackServiceBaseUrl + url,
        this.props.userContext?.active_tenant?.tenant_id
      )
      .then(
        (response) => {
          console.log(
            "this.props.userContext?.active_tenant?.tenant_id",
            this.props.userContext
          );
          this.setState({ isLoading: false });
          let formattedList = [];
          let letterTypeValue = null;
          switch (field) {
            case "letterType":
              letterTypeValue = response.data?.find(
                (item) => item.description === this.props.description
              ).letter_type_id;
              this.setState({ letterTypeId: letterTypeValue }, () => {
                this.getLetterSetupData();
              });
              break;
          }
        },
        (err) => {
          this.setState({ isLoading: false });
          console.log("Error in fetching details:", err);
        }
      );
  };

  handleSubmit = () => {
    if (
      document
        .getElementById("issueToLanguageText")
        ?.getElementsByClassName("ql-editor")[0]?.innerHTML ===
        "<div> </div>" ||
      this.state.editorText === null ||
      this.state.editorText.trim() === ""
    ) {
      this.setState({ isTextError: true });
    } else {
      this.setState({ isPosting: true, isTextError: false });
      let postObj = JSON.parse(JSON.stringify(this.state.letterRequestData));
      console.log("postObj", postObj);
      postPayloadData["description"] = document
        .getElementById("execcardlanguage")
        ?.getElementsByClassName("ql-editor")[0]?.innerHTML
        ? document
            .getElementById("execcardlanguage")
            ?.getElementsByClassName("ql-editor")[0]?.innerHTML
        : null;
      postPayloadData["text_type"] = "Exec Card Language";
      if (this.state.letterLanguageId != null) {
        postPayloadData["letter_language_id"] = this.state.letterLanguageId;
      } else {
        postPayloadData["letter_language_id"] = null;
      }

      postPayloadData["production_entity_id"] = postObj?.selected_production?.value
        ? postObj?.selected_production?.value
        : null;
      postPayloadData["is_active"] = postObj?.is_active ? postObj?.is_active : 0;
      postPayloadData["letter_type_id"] = this.state.letterTypeId;
      clearTrackService
        .postDataParams(
          Constants.ClearTrackServiceBaseUrl + "/letterLanguage",
          postPayloadData,
          this.props.userContext?.active_tenant?.tenant_id
        )
        .then(
          (response) => {
            if (response.data.error) {
              this.setState({ isPosting: false });
              this.props?.notificationComponent(true, "fail");
            } else {
              this.setState({ isPosting: false });
              console.log("letterTypeId", response.data[0]);
              this.getLetterSetupData();
              this.props?.notificationComponent(true, "success");
            }
          },
          (err) => {
            this.setState({ isPosting: false });
            this.props?.notificationComponent(true, "fail");
            console.log("Post agency details error: " + err);
          }
        );
    }
  };

  handleRichTextEditor = (
    content = null,
    delta = null,
    source = null,
    editor,
    variantToAdd = null
  ) => {
    if (variantToAdd === null) {
      let text = editor.getText();
      let characterToModify = text.charAt(text.length - 2);
      let indexToModify = text.length - 2;
      let alphaVariants = [];
      if (alphabetVariantsJson[characterToModify] != undefined) {
        alphaVariants = alphabetVariantsJson[characterToModify];
      }
      this.setState(
        {
          characterToModify,
          indexToModify,
          alphaVariants,
          richTextEditor: editor.getHTML(),
          editorText: editor.getText(),
        });
      if (alphaVariants.length > 0) {
        this.setState({ alphabetVariantMessage: true });
      } else {
        this.setState({ alphabetVariantMessage: false });
      }
    } else {
      let text = this.state.editorText;
      let result = editor.indexOf(this.state.characterToModify + "</");
      String.prototype.replaceAt = function (index, replacement) {
        return (
          this.substring(0, index) +
          replacement +
          this.substring(index + replacement.length)
        );
      };
      let text1 = text.replaceAt(this.state.indexToModify, variantToAdd);
      let richTextEditorValue = editor.replaceAt(result, variantToAdd);
      this.setState({ richTextEditor: richTextEditorValue }, () => {
        console.log("richTextEditor", this.state.richTextEditor);
      });
      document.getElementById("execcardlanguage")?.getElementsByClassName('ql-editor')[0]?.focus()
    }
  };

  render() {
    return (
      <div disabled={!this.props?.editRecord}>
        {this.state.isFetchingLetterLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <MDBRow>
            <span className="error-text">
              {this.state.isTextError
                ? `Please write the comment in textEditor`
                : " "}
            </span>
            <MDBCol md={10} id={`execcardlanguage`}>
              <RichTextEditor
                id={`execcardlanguage`}
                value={this.state.richTextEditor}
                handleRichTextEditor={this.handleRichTextEditor.bind(this)}
                onChange={this.handleRichTextEditor}
                alphabetVariantMessage={this.state.alphabetVariantMessage}
                alphaVariants={this.state.alphaVariants}
              />
            </MDBCol>
          </MDBRow>
        )}
        {this.props?.editRecord &&
        <MDBRow>
          <MDBCol md={10}></MDBCol>
          <MDBCol md={2} className="saveCancelWrapper">
            <BasicButton
              variant="outlined"
              text={"Revert"}
              onClick={() => this.getLetterSetupData()}
            />
            <BasicButton
              text={
                this.state.isPosting ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Save"
                )
              }
              onClick={() => this.handleSubmit()}
            />
          </MDBCol>
        </MDBRow>
      }
      </div>
    );
  }
}
export default withUserContext(ExecCardLanguage);
