import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MainTitleReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialMainTitleDetails,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import { s2ab, genReportName } from '../../../Common/Helper';
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";

class MainTitleReport extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      mainTitleDetails: initialMainTitleDetails,
      seasonOptions: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
      seasonIdValues: [],
      showIdValues: [],
      isShowTitle: false,
      isPosting: false
    };
  }

  componentDidMount() {
    //  this.fetchSeasonsData();
    //  this.fetchShowsData();
    if (this.props?.userContext?.reportTabData?.length > 0) {
      this.setState({
        filterValues: [this.props?.userContext?.reportTabData[1], this.props?.userContext?.reportTabData[2]],
        showIdValues: [this.props?.userContext?.reportTabData[3]],
        seasonIdValues: [this.props?.userContext?.reportTabData[4]]
      })
    }
  }

  // fetchSeasonsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SEASON&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id, 1)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.season_id, text: item.season_name }));
  //       console.log("Formattedlist",formattedList)
  //       this.setState({ seasonOptions: formattedList }, () => { console.log(this.state.seasonOptions) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  // fetchShowsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id, 1)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name }));
  //       this.setState({ showOptions: formattedList }, () => { console.log(this.state.showOptions) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  handleSubmit = () => {
    console.log("submit", this.state.mainTitleDetails);
  };

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      mainTitleDetails: {
        ...prevState.mainTitleDetails,
        [field]: value,
      },
    }));
  };
  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode }, () => {
      let splitVal = this.state.removedItem.split(":");
      let name = splitVal[0].trim();
      let valueId = splitVal[1].trim();
      if (name === "Season") {
        let filterSeasonIdValues = this.state.found.find(item => item.label === valueId).value
        let index2 = this.state.seasonIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.seasonIdValues
        id.splice(index2, 1);
        this.setState({seasonIdValues: id})
      }
      else if (name === 'Show') {
        let filterSeasonIdValues = this.state.showOptions.find(item => item.text === valueId).value
        let index2 = this.state.showIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.showIdValues
        id.splice(index2, 1);
        this.setState({showIdValues: id})
      }
    });
  }
  seasonValues = (seasonValue) => {
    this.setState({found:seasonValue})
  }

  showValues = (showValue) => {
    let backup = this.state.showOptions
    backup = backup.concat(showValue)
    this.setState({showOptions:backup})
  }
  handleClearFilters = () => {
    let values = this.state.filterValues;
    values = []
    this.setState({ filterValues: values, mainTitleDetails: [],seasonIdValues: [], showIdValues: []});
  }

  handleSubmitMainTitleReport = () => {
    this.setState({ postInitiated: true });
    let { mainTitleDetails, filterValues, seasonIdValues, showIdValues } = this.state;
    let rptName = mainTitleDetails.selectedValue === "listview" ? "rptMainTitleList" : "rptMainTitleSummary";
    // if (!mixDateDetails.mix_date_range_form || !mixDateDetails.mix_date_range_to) {
    //   return;
    // }
    this.setState({ isPosting: true })
    // let payload = {
    //   mix_date_start: mixDateDetails.mix_date_range_form,
    //   mix_date_end: mixDateDetails.mix_date_range_to,
    //   season_ids: seasonIdValues,
    //   show_ids: showIdValues
    // }
    let payload = {
      "season_ids": seasonIdValues,
      "show_ids": showIdValues,
      "exclude_shows": [],
      "list_view": mainTitleDetails.selectedValue === "listview" ? 1 : null,
      "summary_view": mainTitleDetails.selectedValue === "summary" ? 1 : null,
      "include_inactive_shows": null,
      "display_criteria": 1,
      "is_wfh": mainTitleDetails?.is_wfh_list_view || null,
      "is_licensed": mainTitleDetails?.is_licensed || null,
      "is_no_main_title": mainTitleDetails?.is_no_main_title || null,
      "is_studio_owned": mainTitleDetails?.is_studio_owned || null,
      "is_include_inactive": null,
      "field_fee": mainTitleDetails?.is_fee || null,
      "field_licensor": mainTitleDetails?.is_licensor || null,
      "field_artist": mainTitleDetails?.is_artist || null,
      "field_initial_rights": mainTitleDetails?.is_intial_rights || null,
      "field_right_type_sm": mainTitleDetails?.is_right_type || null,
      "field_season": mainTitleDetails?.is_season || null,
      "field_options": mainTitleDetails?.is_options || null,
      "is_grand_total": mainTitleDetails?.is_show_grand_total || null,
      "is_show_episode_total": mainTitleDetails?.is_show_episode || null,
    }
    this.props.generateReport(rptName, payload, "Main Title").then(webSocketConn => {
      webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data)
        if (response?.statusCode == 200) {
          this.setState({ isPosting: false })
          this.props?.downloadUrl(response?.body);
          // this.props?.notificationComponent(true, 'success')
        } else {
          // this.props?.notificationComponent(true, 'fail')
        }
      }
    })
  }

  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }
 
  GenerateCleranceDetails = () => { };
  render() {
    return (
      <>
        {" "}
        <p>
          <b>Main Title Report</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
                // filterValues={this.state.filterValues}
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="ModalContainer"
        >
          <MDBRow>
            <MDBCol md={3}>
              {/* <br /> */}
              <BasicLabel text="Fields" />
              <MDBRow>
                <MDBCol md={7} className="radio-font">
                  <Radio
                    name="radio-buttons"
                    label="name"
                    checked={this.state.mainTitleDetails.selectedValue === "summary"}
                    onChange={(e) =>
                      this.handleChange("selectedValue", e.target.value)
                    }
                    value="summary"
                  />
                  <span className="radio-font">Summary </span>

                  <BasicCheckbox
                    className="buttoncolor"
                    id="Show Title"
                    label={"Show Title"}
                    disabled={true}
                    checked={
                      true
                    }
                    onChange={(e) =>
                      this.handleChange("is_show_title", e.target.checked)
                    }
                  />
                  <BasicCheckbox
                    id="Fee"
                    label={"Fee"}
                    checked={this.state.mainTitleDetails?.is_fee || false}
                    onChange={(e) =>
                      this.handleChange("is_fee", e.target.checked)
                    }
                  />
                  <BasicCheckbox
                    id="Season"
                    label={"Season"}
                    checked={this.state.mainTitleDetails?.selectedValue === "listview" ? true :
                    this.state.mainTitleDetails?.is_season || false}
                    disabled ={this.state.mainTitleDetails?.selectedValue === "listview" ? true : false}
                    onChange={(e) =>
                      this.handleChange("is_season", e.target.checked)
                    }
                  />
                  {this.state.mainTitleDetails?.selectedValue === "listview" ? null :
                  <BasicCheckbox
                    id="Show Grand Total"
                    label={"Show Grand Total"}
                    checked={this.state.mainTitleDetails?.is_show_grand_total || false}
                    onChange={(e) =>
                      this.handleChange(
                        "is_show_grand_total",
                        e.target.checked
                      )
                    }
                  />}
                  {this.state.mainTitleDetails?.selectedValue === "listview" ? null :
                  <BasicCheckbox
                    id="show Episodes"
                    label={"Show Episodes"}
                    checked={
                      this.state.mainTitleDetails?.is_show_episode || false
                    }
                    onChange={(e) =>
                      this.handleChange("is_show_episode", e.target.checked)
                    }
                  />}
                </MDBCol>
                <MDBCol md={5}>
                  <MDBRow>
                    <MDBCol md={1}>
                      <Radio
                        name="radio-buttons"
                        label="name"
                        checked={this.state.mainTitleDetails.selectedValue === "listview"}
                        onChange={(e) =>
                          this.handleChange("selectedValue", e.target.value)
                        }
                        value="listview"
                      />
                    </MDBCol>
                    <MDBCol className="label">
                      <BasicLabel text="List View" />
                      {/* </MDBCol>
                  </MDBRow> */}
                      {/* <Radio
                    name="radio-buttons"
                    label="name"
                    checked={this.state.mainTitleDetails.selectedValue === "listview"}
                    onChange={(e) =>
                      this.handleChange("selectedValue", e.target.value)
                    }
                    value="listview"
                  />
                  <span style={{ fontSize: "10px" }}>List View </span> */}
                    </MDBCol>

                    {/* <MDBCol md={4}> */}
                    <br />
                    {this.state.mainTitleDetails?.is_season ?
                      <BasicCheckbox
                        id="Licensor"
                        label={"Licensor"}
                        checked={this.state.mainTitleDetails?.is_licensor || false}
                        onChange={(e) =>
                          this.handleChange("is_licensor", e.target.checked)
                        }
                      /> : null}
                    {this.state.mainTitleDetails?.is_season ?
                      <BasicCheckbox
                        id="Artist"
                        label={"Artist"}
                        checked={this.state.mainTitleDetails?.is_artist || false}
                        onChange={(e) =>
                          this.handleChange("is_artist", e.target.checked)
                        }
                      /> : null}
                    {this.state.mainTitleDetails?.is_season ?
                      <BasicCheckbox
                        id="Initial Rights"
                        label={"Initial Rights"}
                        checked={
                          this.state.mainTitleDetails?.is_intial_rights || false
                        }
                        onChange={(e) =>
                          this.handleChange("is_intial_rights", e.target.checked)
                        }
                      /> : null}
                    {this.state.mainTitleDetails?.is_season ?
                      <BasicCheckbox
                        id="Options"
                        label={"Options"}
                        checked={this.state.mainTitleDetails?.is_options || false}
                        disabled ={this.state.mainTitleDetails?.is_licensor ? false : true}
                        onChange={(e) =>
                          this.handleChange("is_options", e.target.checked)
                        }
                      /> : null}
                    {this.state.mainTitleDetails?.selectedValue === "listview" ?
                      <BasicCheckbox
                        id="Rights Type"
                        label={"Rights Type"}
                        checked={
                          this.state.mainTitleDetails?.is_right_type || false
                        }
                        onChange={(e) =>
                          this.handleChange("is_right_type", e.target.checked)
                        }
                      /> : null}

                    {/* </MDBCol>
                </MDBRow> */}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol md={6}>
              <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                // setSeasonId={this.setSeasonId}
                // setShowId={this.setShowId}
                filterSeasonIdValues={this.state.seasonIdValues}
                filterShowIdValues={this.state.showIdValues}
                filterValues={this.state.filterValues} 
                seasonValues={this.seasonValues}
                showValues={this.showValues}/>
            </MDBCol>
            {/* <br />
              <BasicLabel text="Seasons" />
              <SearchSelectField
                id={"All Seasons"}
                placeHolderText={"All Seasons"}
                value={{ 'value': this.state.mainTitleDetails.selected_season?.value, 'text': this.state.mainTitleDetails.selected_season?.text } || {}}
                options={this.state.seasonOptions || []}
                onChange={(e, value) => this.handleChange("selected_season", value)}
              />
            </MDBCol> */}

            {/* <MDBCol md={1}> */}
              
              {/* <BasicCheckbox
                id="Shows"
                label={"Internet Shows"}
                checked={
                  this.state.mainTitleDetails?.is_internet_shows || false
                }
                onChange={(e) =>
                  this.handleChange("is_internet_shows", e.target.checked)
                } 
              />*/}
              {/* <BasicLabel text="Shows" /> */}
              {/* <SearchSelectField
                id={"All Shows"}
                placeHolderText={"All Shows in selected season(s)"}
                value={{ 'value': this.state.mainTitleDetails.selected_shows?.value, 'text': this.state.mainTitleDetails.selected_shows?.text } || {}}
                options={this.state.showOptions || []}
                onChange={(e, value) => this.handleChange("selected_shows", value)}
              /> */}
            {/* </MDBCol> */}
            <MDBCol md={2}>
              <br />
              <br />
              <BasicCheckbox
                id="WFH LIST VIEW"
                label={"WFH LIST VIEW"}
                checked={this.state.mainTitleDetails?.is_wfh_list_view || false}
                onChange={(e) =>
                  this.handleChange("is_wfh_list_view", e.target.checked)
                }
              />
              <BasicCheckbox
                id="No Main Title"
                label={"No Main Title"}
                checked={this.state.mainTitleDetails?.is_no_main_title || false}
                onChange={(e) =>
                  this.handleChange("is_no_main_title", e.target.checked)
                }
              />

              <BasicCheckbox
                id="Licensed"
                label={"Licensed"}
                checked={this.state.mainTitleDetails?.is_licensed || false}
                onChange={(e) =>
                  this.handleChange("is_licensed", e.target.checked)
                }
              />

              <BasicCheckbox
                id="Studio Owned"
                label={"Studio Owned"}
                checked={this.state.mainTitleDetails?.is_studio_owned || false}
                onChange={(e) =>
                  this.handleChange("is_studio_owned", e.target.checked)
                }
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate PDF"} onClick={this.handleSubmitMainTitleReport} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} onClick={this.handleClearFilters} />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default withUserContext(MainTitleReport);
