export const InitialCostCenterDetails = {
    "cost_center_id": null,
    "cost_center_name": null,
    "cost_center_code": null,
    "gl_code": null,
    "receiving_co": null,
    "sap_code": null,
    "loc_code": null,
    "notes": null,
    "is_available_for_selection": 0,
    "is_music_clearance_cc": 0,
    "is_all_media_charge_off": 0,
    "is_home_video_cost_center": 0,
    "is_budgeting_cost_center": 0,
    "is_application_budget": 0,
    "is_active": 1
}