import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import * as Constants from "../../../constants/constants";
import ClearTrackService from "../../../services/service";
// import "./ExcelAndDoc.scss";
import { withUserContext } from "../../../contexts/UserContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import { valueToNode } from "@babel/types";

class LettersModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenExisting: false,
            lettersArray: [],
            renderList: []
        };
    }

    handleChange = (obj, value) => {
        if (value === "recreate") {
            this.setState({ isOpenExisting: false });
        } else {
            this.setState({ isOpenExisting: true });
        }
        let neWobj = { letter: obj.report_name, value: value === "recreate" ? "Recreate" : "OpenExisting", licensor_id: obj.licensor_id }
        let lettersArray = this.state.lettersArray
        let find = lettersArray.find(item => item.letter === obj.report_name)
        if (find) {
            lettersArray.map((item) => {
                if (item.letter === obj.report_name) {
                    item['value'] = value
                }
                return item;
            })

        } else {
            lettersArray.push(neWobj)
        }
        this.setState({ lettersArray }, () => {
            console.log("arr", this.state.lettersArray)
        })

        // this.props?.callBack(obj, this.state.isOpenExisting)
    };

    handleSubmit = () => {
            this.props?.getDataToOpenExisting(this.state.lettersArray)
            this.props?.handleClose()
    }


    componentDidMount() {
        console.log("lettersTabList", this.props?.lettersTabList)
        console.log("response in letter modal", this.props?.response)
        let response = [...this.props?.response]
         let fileExists = response?.filter(item => item.fileExists === 1)
         let replaceLetterName = fileExists.map(item => {
             let newObj = {...item}
             newObj.report_name = this.props?.getReportName(this.props?.isMainTitle, null, item.report_name, true)
             return newObj;
            }
         )
        let lettersArray = this.state.lettersArray
        replaceLetterName.map(item => {
            let neWobj = { letter: item.report_name, value: "Recreate" }
            lettersArray.push(neWobj);
        })
        this.setState({ lettersArray, renderList: replaceLetterName }, () => {
            console.log("onmount", this.state.lettersArray)
        })
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.response !== this.props.response) {
    //     this.setState({ response: this.props?.response}, () => {
    //         console.log("response in update", this.state.response)
    //     })
    //     }
    //   }


    render() {
        return (
            <MDBContainer>
                {this.state.renderList.map((obj, index) => (
                    <MDBRow id={"scAndMSdetailss" + index}>
                        <BasicLabel className="fieldlabel" text={obj?.report_name + "-" + obj?.licensor_name} />
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={"recreate"}
                            onChange={(e) => this.handleChange(obj, e.target.value)}
                        >
                            <FormControlLabel
                                value="recreate"
                                control={<Radio />}
                                label="ReCreate"
                            />
                            <FormControlLabel
                                value="openExisting"
                                control={<Radio />}
                                label="Open Existing"
                            />
                        </RadioGroup>
                    </MDBRow>
                )
                )}
                <MDBRow>
                    <MDBCol md={6}></MDBCol>
                    <MDBCol md={1}>
                        <BasicButton
                            className="btnwidth"
                            variant="contained"
                            type="inline"
                            text={"Ok"}
                            // text={
                            //   this.state.isSubmit ? (
                            //     <CircularProgress color="inherit" size={18} />
                            //   ) : (
                            //     "Save"
                            //   )
                            // }
                            onClick={this.handleSubmit}
                        />
                    </MDBCol>
                    <MDBCol md={1}></MDBCol>
                    <MDBCol md={1}>
                        <BasicButton
                            type="inline"
                            variant="outlined"
                            text={"Close"}
                            onClick={this.props?.handleClose}
                            disabled={false}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}
export default withUserContext(LettersModal);
