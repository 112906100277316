import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../SharedComponents/SelectField/SelectField';
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from '../../contexts/UserContext'
import { validateEmail } from '../../Common/Helper'
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';


class RestrictedPromoEmailList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNetworkOption: null,
            networkOptions: [],
            loadingOptions: false,
            ccList: [],
            emailList: [],
            emailConfig: { ...this.props.emailConfig },
            ccConfig: { ...this.props.ccConfig },
            fullData: [],
            isEmailListLoading: false,
            isCCListLoading: false,
            emailMandatory: false,
            emailDuplicate: false,
            ccMandatory: false,
            ccDuplicate: false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            sortByCC: null,
            isSortByAscCC: true,
            sortCountCC: 0
        }
    }

    componentDidMount() {
        this.getList()
        this.getNewtowrkList()

    }

    handleInlineEdits = (tableName, id, dataItem) => {
        let allEmails = tableName === "email" ? [...this?.state?.emailList] : [...this.state?.ccList];
        if (tableName === "email") {
            this.setState({
                emailMandatory: false,
                ccMandatory: false,
                emailList: allEmails.map((item) => {
                    let newItem = { ...item }
                    if (item.id === id){
                        newItem['editing'] = true;
                    } else {
                        newItem.editing = false
                    }
                    return newItem
                })
            })
        } else {
            this.setState({
                emailMandatory: false,
                ccMandatory: false,
                ccList: allEmails.map((item) => {
                    let newItem = {...item}
                    if (item.cc_list_id === id){
                        newItem['editing'] = true;
                    } else {
                        newItem.editing = false
                    }
                    return newItem
                })
            })
        }
    }

    saveInlineEdits = (tableName, data, index, pkValue, dataItem) => {
        this.setState({ emailMandatory: false, ccMandatory: false, emailDuplicate: false, ccDuplicate: false })
        let renderList = tableName === 'email' ? [...this.state?.emailList] : [...this.state?.ccList]
        if (!data[index]?.email || data[index]?.email?.length == 0 || !validateEmail(data[index]?.email)) {
            if (tableName === 'email') {
                this.setState({ emailMandatory: true })
            } else {
                this.setState({ ccMandatory: true })
            }
        } else if (data[index]?.email && renderList?.some((item, i) => item?.email == data[index]?.email && i != index)) {
            if (tableName === 'email') {
                this.setState({ emailDuplicate: true })
            } else {
                this.setState({ ccDuplicate: true })
            }
        }
        else {

            let currentEmailList = tableName === 'email' ? [...this?.state?.emailList] : [...this?.state?.ccList]
            let promoList = [...data]
            currentEmailList[index].restricted_promo_email_id = tableName === 'email' ? promoList[index]?.id : promoList[index]?.cc_list_id,
                currentEmailList[index].email = promoList[index].email || null,
                currentEmailList[index].is_active = tableName === 'email' ? promoList[index].is_active ? 1 : 0 : promoList[index].is_cc_active ? 1 : 0,
                currentEmailList[index].network_id = promoList[0].network_id || null
            currentEmailList[index].is_cc = tableName !== 'email' ? 1 : 0
            if (tableName === 'email') {
                this.setState({
                    emailList: currentEmailList.map((item) => {
                        item['editing'] = false
                        return item
                    })
                })
            } else {
                this.setState({
                    ccList: currentEmailList.map((item) => {
                        item['editing'] = false
                        return item
                    })
                })
            }

            this.handleSave(currentEmailList[index]);
            if (tableName === 'email') {
                this.setState({ isEmailListLoading: currentEmailList[index]?.id })
            } else {
                this.setState({ isCCListLoading: currentEmailList[index]?.cc_list_id })
            }
        }
    }

    handleAddNewItem = (type, newDataItem) => {
        this.setState({ emailMandatory: false, ccMandatory: false, emailDuplicate: false, ccDuplicate: false })
        let renderList = type === 'email' ? [...this.state?.emailList] : [...this.state?.ccList]
        if (!newDataItem?.email || newDataItem?.email?.length == 0 || !validateEmail(newDataItem?.email)) {
            if (type === 'email') {
                this.setState({ emailMandatory: true })
            } else {
                this.setState({ ccMandatory: true })
            }
        } else if (newDataItem?.email && renderList?.some(item => item?.email == newDataItem?.email)) {
            if (type === 'email') {
                this.setState({ emailDuplicate: true })
            } else {
                this.setState({ ccDuplicate: true })
            }
        }
        else {
            let addList = {
                restricted_promo_email_id: null,
                email: newDataItem.email || null,
                is_active: 1,
                is_cc: type === 'email' ? 0 : 1,
                network_id: this.state.selectedNetworkOption ? this.state.selectedNetworkOption : null
            }
            this.handleSave(addList);
            if (type === 'email') {
                this.setState({ isEmailListLoading: 'newItemPost' })
            } else {
                this.setState({ isCCListLoading: 'newItemPost' })
            }
        }
    }

    onSelectChange = (value) => {
        this.setState({ selectedNetworkOption: value })
        this.handleNetworkEmails(value);
    }

    getList = (networkId = null, loader = true) => {
        this.setState({ loadingOptions: loader })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=RESTRICTED_PROMO_EMAILS&searchString=null`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let fullData = response?.data?.map(item => ({ email: item.email, id: item.restricted_promo_email_id, is_active: item.is_active, network_id: item.network_id, is_cc: item.is_cc }));
                let ccList = networkId ? response?.data?.filter(item => item.is_cc === 1 && item?.network_id === networkId) :
                    response?.data?.filter(item => item.is_cc === 1 && item?.network_id === null)
                let emailList = networkId ? response?.data?.filter(item => item.is_cc === 0 && item?.network_id === networkId) : []
                let formattedList = ccList?.map(item => ({ email: item.email, cc_list_id: item.restricted_promo_email_id, is_cc_active: item.is_active, network_id: item.network_id, is_cc: item.is_cc }));
                let list2 = emailList?.map(item => ({ email: item.email, id: item.restricted_promo_email_id, is_active: item.is_active, network_id: item.network_id, is_cc: item.is_cc }));
                let configRecord = { ...this.state.ccConfig }
                this.props?.permissionArray.edit === 0 ? configRecord.actions = [] : configRecord.actions = ["pen"]
                let configEmailRecord = { ...this.state.emailConfig }
                this.props?.permissionArray.edit === 0 ? configRecord.actions = [] : configRecord.actions = ["pen"]
                this.setState({
                    ccList: formattedList, emailList: list2, loadingOptions: false, fullData: fullData,
                    isEmailListLoading: false, isCCListLoading: false, sortByCC: null,
                    isSortByAscCC: true, sortCountCC: 0, sortBy: null, ccConfig: configRecord, emailConfig: configEmailRecord,
                    isSortByAsc: true, sortCount: 0
                }, () => {
                    this.handleTableColumnClick("email", "Email");
                    this.handleTableColumnClick("cc", "Email");
                })
            }),
            (err) => {
                console.log("Error in fetching Static Data:", err)
                this.setState({ loadingOptions: false, ccList: [], emailList: [], fullData: [] })
            }
    }

    getNewtowrkList = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/staticData?staticTable=lu_network`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let networkOptions = response?.data?.map(item => ({ label: item.name, value: item.id }));
                networkOptions.unshift({ label: 'select', value: null })
                this.setState({ networkOptions: networkOptions }, () => { this.handleTableColumnClick("email", "Email") })
            }),
            (err) => {
                console.log("Error in fetching Static Data:", err)
                this.setState({ networkOptions: [] })
            }
    }

    handleNetworkEmails = (selectedNetworkId) => {
        let filteredEmailData = selectedNetworkId ? this.state.fullData.filter(item => item.network_id === selectedNetworkId && item.is_cc === 0) : []
        let filteredCCData = selectedNetworkId ? this.state.fullData.filter(item => item.network_id === selectedNetworkId && item.is_cc === 1) : this.state.fullData.filter(item => item.network_id === null && item.is_cc === 1)
        this.setState({
            emailList: filteredEmailData?.map(item => ({ email: item.email, id: item.id, is_active: item.is_active, network_id: item.network_id, is_cc: item.is_cc })),
            ccList: filteredCCData?.map(item => ({ email: item.email, cc_list_id: item.id, is_cc_active: item.is_active, network_id: item.network_id, is_cc: item.is_cc }))
        })
    }


    handleSave = (list) => {
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/restrictedPromoEmails`, list, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isEmailListLoading: false, isCCListLoading: false })
                    console.log("Error in saving Static Data:", response.data.error)
                    this.props?.toastMessageNotification(true, "fail")
                }
                else {
                    // this.setState({isEmailListLoading:false,isCCListLoading:false })
                    this.props?.toastMessageNotification(true, "success")
                    this.getList(this.state.selectedNetworkOption, false)
                }
            },
                (err) => {
                    this.setState({ isEmailListLoading: false, isCCListLoading: false })
                    this.props?.toastMessageNotification(true, "fail")
                    console.log("Post details error: " + err);
                }
            )
    }

    handleTableColumnClick = (tableName, column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column, tableName);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column, tableName);
        })
    }

    handleTableSort = (colName, tableName) => {
        if (tableName === 'email') {
            this.setState({
                isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            }, () => {
                this.setState({
                    sortBy: colName,
                    config: updateTableSortConfigObject(this.state?.emailConfig, this.state.isSortByAsc, this.state.sortBy, colName),
                    emailList: tableSortList("Name", this.getSortNode(colName, tableName), this?.state?.emailList, this.state.isSortByAsc)
                });
            })
        }
        else if (tableName === 'cc') {
            this.setState({
                isSortByAscCC: this.state.sortCountCC % 2 === 0 ? true : false,
            }, () => {
                this.setState({
                    config: updateTableSortConfigObject(this.state?.ccConfig, this.state.isSortByAscCC, this.state.sortByCC, colName),
                    sortByCC: colName,
                    ccList: tableSortList("Name", this.getSortNode(colName, tableName), this?.state?.ccList, this.state.isSortByAscCC)
                });
            })
        }
    }

    getSortNode = (col, tableName) => {
        if (tableName === "email") {
            switch (col) {
                case "Email": return "email";
            }
        }
        else if (tableName === "cc") {
            switch (col) {
                case "Email": return "email";
            }
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="restrictedPromoContainer">
                <MDBRow>
                    <span className="description">
                        Defines emails to send Restricted Email Promo notices to when triggered for a song from the Clearance Screen.
                    </span>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={3} className="pb-2 select-width">
                        <SelectField
                            options={this.state?.networkOptions}
                            placeHolderText="- Select Network -"
                            onChange={(e) => this.onSelectChange(e.target.value)}
                            value={this.state?.selectedNetworkOption}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={6} className="promoListTable">
                        <BasicLabel text="Email List" type="fieldHeading" />
                        <MDBRow className='pd'>
                            <span className="errorText">{this.state.emailMandatory && 'Please enter email field value'}</span>
                            <span className='errorText'>{this.state?.emailDuplicate && 'Email already exist'}</span>
                        </MDBRow>
                        {this.state?.loadingOptions ? (
                            <div className="ContentLoader">
                                <CircularProgress />
                            </div>
                        ) : (
                            <TableComponent
                                list={this?.state?.emailList || []}
                                newIsActive={true}
                                config={this.state?.emailConfig}
                                handleAddNewItem={this.handleAddNewItem.bind(this, 'email')}
                                editCallback={this.handleInlineEdits.bind(this, 'email')}
                                saveCallback={this.saveInlineEdits.bind(this, 'email')}
                                addItemToList={permissionValue?.edit === 1 ? this.state?.selectedNetworkOption : false}
                                stickyHeader={true}
                                postInitiated={this.state?.isEmailListLoading}
                                // isLoading={this.state?.isEmailListLoading}
                                readCallback={(index) => {
                                    let emailList = [...this.state.emailList];
                                    emailList[index].editing = false
                                    this.setState({ emailList: emailList, emailMandatory: false });
                                }}
                                sortCallback={this.handleTableColumnClick.bind(this, 'email')}
                            />)}
                    </MDBCol>
                    <MDBCol md={6} className="promoListTable">
                        <BasicLabel text="CC List" type="fieldHeading" />
                        <MDBRow className='pd'>
                            <span className="errorText">{this.state.ccMandatory && 'Please enter email field value'}</span>
                            <span className="errorText">{this.state.ccDuplicate && 'Email already exist'}</span>
                        </MDBRow>
                        {this.state?.loadingOptions ? (
                            <div className="ContentLoader">
                                <CircularProgress />
                            </div>
                        ) : (
                            <TableComponent
                                list={this?.state?.ccList || []}
                                newIsActive={true}
                                config={this.state?.ccConfig}
                                handleAddNewItem={this.handleAddNewItem.bind(this, 'cc')}
                                editCallback={this.handleInlineEdits.bind(this, "cc")}
                                saveCallback={this.saveInlineEdits.bind(this, "cc")}
                                addItemToList={permissionValue?.edit === 1}
                                stickyHeader={true}
                                postInitiated={this.state?.isCCListLoading}
                                readCallback={(index) => {
                                    let ccList = [...this.state.ccList];
                                    ccList[index].editing = false
                                    this.setState({ ccList: ccList, ccMandatory: false });
                                }}
                                sortCallback={this.handleTableColumnClick.bind(this, "cc")}
                            />)}
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default withUserContext(RestrictedPromoEmailList);