import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import './ImportSeason.scss';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import SelectField from "../SharedComponents/SelectField/SelectField";
import { withUserContext } from "../../contexts/UserContext";
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { CircularProgress } from "@material-ui/core";

export default withUserContext(class ImportSeason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileData: null,
            fileName: null,
            isSubmit: false,
            showError: false,
            divisionId: null,
            divisionOptions: []
        };
    }


    postImportSeason = () => {
        this.setState({ isSubmit: true });
        ClearTrackService.postDataBuffer(
            Constants.ClearTrackServiceBaseUrl + `/importSeason`, this.state.fileData,
            this.props.userContext?.active_tenant?.tenant_id, this.state.divisionId
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.props?.handleSubmit()
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent(true, "success");
                }
            },
            (err) => {
                this.setState({ isSubmit: false });
                this.props?.notificationComponent(true, "fail");
            }
        );
    };

    uploadFile(file) {
        file=file[0]
        this.setState({ showError: false })
        try {
            const reader = new FileReader()
            if (file?.size > 5242880 || !file?.name?.includes('.xlsx')) {
                this.setState({ showError: true })
                return
            }
            reader.onload = () => {
                if (reader.readyState === 2) {
                    let result = reader.result.split(",")?.[1];
                    this.setState({ fileData: result, fileName: file.name });
                    this.props?.fieldChanged(true, "change")
                }
            };
            reader.readAsDataURL(file);
        } catch (error) {
            console.log(error)
            this.setState({ fileData: null, fileName: null });
        }

    }

    componentDidMount() {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/staticData?staticTable=division', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                let allowedDivsions = this.props?.userContext?.active_tenant?.division_role?.map(item => item?.division_id)
                this.setState({ divisionOptions: formattedList?.filter(ele => allowedDivsions?.includes(ele?.value)) }, () => {
                    this.setState({ divisionId: this.state.divisionOptions[0]?.value })
                });
            },
                (err) => {
                    console.log("Error in fetching Tenant Details:", err)
                })
    }

    render() {
        return (
            <MDBContainer className={'importSeasonContainer'}>
                <div className="content">
                    <div>The excel sheet must contain the following specifications:</div>
                    <div>1) First row must contain headings.</div>
                    <div>2) There must be no empty rows.</div>
                    <div>3) Empty columns are okay as long as there is at least a Season/Series Title.</div>
                    <div>4) Columns must be in the following order:</div>
                    <div>&emsp;&emsp;a) Season</div>
                    <div>&emsp;&emsp;b) Series Title</div>
                    <div>&emsp;&emsp;c) Production # as text</div>
                    <div>&emsp;&emsp;d) Episode Title</div>
                    <div>&emsp;&emsp;e) Air Order</div>
                    <div>&emsp;&emsp;f) air date display as date <i>in MM/DD/YYYY format</i></div>
                    {this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id ===this.state?.divisionId)?.division_name !== 'Animation' && <div>&emsp;&emsp;g) Pilot <i>"Yes" or "No"</i></div>}
                    <br />
                </div>
                <BasicLabel text={"Select File"} />
                <MDBRow>
                    <MDBCol md={1}>
                        <BasicButton
                            variant="outlined"
                            icon="paperclip"
                            type="inline"
                            inputType="file"
                            id={'imporstseaonfile'}
                            uploadFile={(file) => this.uploadFile(file)}
                        />
                    </MDBCol>
                    <MDBCol md={3}><BasicLabel text={"(upto 5mb)"} /></MDBCol>
                    <MDBCol md={3}>
                        <SelectField
                            id="Season"
                            label='Division'
                            value={this.state.divisionId}
                            placeHolderText="-division-"
                            options={this.state.divisionOptions}
                            onChange={(e) => this.setState({ divisionId: e.target.value })}
                        />
                    </MDBCol>
                </MDBRow>
                <span className="filename">{this.state.fileName}</span>
                <span>{this.state.showError && 'Please upload excel file having size less than 5MB'}</span>
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton className="importButton"
                            text={this.state.isSubmit ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                "Import"
                            )}
                            variant="contained"
                            type="inline"
                            disabled={!this.state.fileName}
                            onClick={this.postImportSeason.bind(this)}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            text={"Cancel"}
                            variant="outlined"
                            onClick={this.props.handleClose}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
})