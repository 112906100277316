import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class Networks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            networkNotExist:false,
            networkDuplicate:false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config: JSON.parse(JSON.stringify({ ...this.props?.config}))
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps?.list !== this.props?.list && this.props?.list?.length > 0) {
            let configRecord = {...this.state.config}
            this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
            this.setState({renderList:this.props.list,sortCount:0,sortBy:null,isSortByAsc: true,config: configRecord}, () => {
                this.handleTableColumnClick('Network')
            })
        }
    }

    handleInlineEdits = (id, dataItem) => {
        let networks = [...this.state?.renderList]
        this.setState({
            networkNotExist:false,
            renderList: networks.map((item) => {
                let newItem = {...item}
                if (item.id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({networkNotExist:false,networkDuplicate:false}) 
        if(!data[index]?.network || data[index]?.network?.length == 0){
            this.setState({networkNotExist : true})
        }
        else if(data[index]?.network && this.state?.renderList?.some((item,i)=>item?.network == data[index]?.network && index != i)){
            this.setState({networkDuplicate:true})
        }
        else{
            let networks = [...this.state?.renderList]
            networks[index].id = data[index].id,
                networks[index].network = data[index].network || null,
                networks[index].is_active = data[index].is_active?1:0
            this.setState({
                renderList: networks.map((item) => {
                    item['editing'] = false
                    return item
                })
            })
            let editedList = this.state.renderList.filter(item => item.id === pkValue);
            let list = {
                id: editedList[0].id,
                name: editedList[0].network || null,
                is_active: editedList[0].is_active?1:0
            }
            this.props.handleSave(list, "lu_network",list?.id);
        }
    }

    handleAddNewItem = (newDataItem) => {
        this.setState({networkNotExist:false,networkDuplicate:false}) 
        if(!newDataItem?.network || newDataItem?.network?.length == 0){
            this.setState({networkNotExist : true})
        }
        else if(newDataItem?.network && this.state?.renderList?.some((item,i)=>item?.network == newDataItem?.network)){
            this.setState({networkDuplicate:true})
        }
        else{
        
            let addList = {
                id: null,
                name: newDataItem.network || null,
                is_active: 1
            }
            this.props.handleSave(addList, "lu_network",'newItemPost');
        } 
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
          this.setState({ sortCount: this.state.sortCount + 1 }, () => {
            this.handleTableSort(column);
          })
        }
        else this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        })
    }
    
    handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            sortBy: colName,
            config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, this.state.sortBy, colName),
            renderList: tableSortList(colName === 'Active' ? 'Number':"Name", this.getSortNode(colName), this.props?.list, this.state.isSortByAsc)
          });
        })
    }
    
    getSortNode = (col) => {
        switch (col) {
            case "Network": return "network";
            case "Active": return "is_active";
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="detailContainer" >
                <MDBRow>
                    <span className="description">Selectable Networks for a Show/Season.</span>
                </MDBRow>
                <MDBRow className='pd'>
                <span className="errorText">{this.state.networkNotExist && 'Please enter Network field value'}</span>
                <span className="errorText">{this.state.networkDuplicate && 'Network already exist'}</span>
                
                </MDBRow>
                {this.props?.loadingOptions ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBRow className="tableComponent">
                            <TableComponent
                                newIsActive={true}
                                list={this.state?.renderList || []}
                                config={this.state?.config}
                                handleAddNewItem={permissionValue?.edit === 1 ? this.handleAddNewItem.bind(this) : null}
                                editCallback={permissionValue?.edit === 1 ? this.handleInlineEdits.bind(this) : null}
                                saveCallback={permissionValue?.edit === 1 ? this.saveInlineEdits.bind(this) : null}
                                addItemToList={permissionValue?.edit === 1 ? true : false}
                                stickyHeader={true}
                                postInitiated={this.props?.isSaveData}
                               // isLoading={this.props?.isSaveData}
                                readCallback={(index) => {
                                    let networkOptions = [...this.state.renderList];
                                    networkOptions[index].editing = false
                                    this.setState({ renderList: networkOptions, networkNotExist:false });
                                }}
                                sortCallback={this.handleTableColumnClick}
                            />
                        </MDBRow>)}
            </MDBContainer>
        )
    }
}

export default Networks;