export const clearanceOptionsData = [
    {
        id: 1,
        inactive: true,
        option: "Ad Card",
        h_d_b: "h",
        def_fee: "[Def. Fee]",
        notes: "[Notes]"
    },
    {
        id: 2,
        inactive: true,
        option: "Ad Card",
        h_d_b: "h",
        def_fee: "[Def. Fee]",
        notes: "[Notes]"
    },
    {
        id: 3,
        inactive: true,
        option: "Ad Card",
        h_d_b: "h",
        def_fee: "[Def. Fee]",
        notes: "[Notes]"
    }
]



export const seasonData = [
    {
        id: 1,
        season: "2000-2001",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "2001-2002",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "2003-2004",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    },
    {
        id: 1,
        season: "[Season]",
        display_name: "Display Name"
    }
]

export const fringeDetailList = [
    {
        id: 1,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 2,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 3,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 4,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 5,
        inactive: true,
        detail: "[Detail]"
    }
]

export const licenseCoverData = [
    {
        id: 1,
        description: "[Description]",
        sort_order: "1"
    },
    {
        id: 2,
        description: "[Description]",
        sort_order: "2"
    },
    {
        id: 3,
        description: "[Description]",
        sort_order: "1"
    },
    {
        id: 4,
        description: "[Description]",
        sort_order: "3"
    },
    {
        id: 5,
        description: "[Description]",
        sort_order: "4"
    }
]

export const licenseTrackingData = [
    {
        id: 1,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 2,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 3,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 4,
        inactive: true,
        detail: "[Detail]"
    },
    {
        id: 5,
        inactive: true,
        detail: "[Detail]"
    }
]

export const licenseTypes = [
    {
        id: 1,
        inactive: true,
        license_type: "[License Type]"
    },
    {
        id: 2,
        inactive: true,
        license_type: "[License Type]"
    },
    {
        id: 3,
        inactive: true,
        license_type: "[License Type]"
    },
    {
        id: 4,
        inactive: true,
        license_type: "[License Type]"
    },
    {
        id: 5,
        inactive: true,
        license_type: "[License Type]"
    }
]

export const musicUsesList = [
    {
        id: 1,
        inactive: true,
        existing_uses: "[Existing Uses]",
        abbreviation: "[Abbreviation]",
        full_description: "[Full Description]",
        rapidcue_music_use: "[RapidCue Music Use]"
    },
    {
        id: 2,
        inactive: true,
        existing_uses: "[Existing Uses]",
        abbreviation: "[Abbreviation]",
        full_description: "[Full Description]",
        rapidcue_music_use: "[RapidCue Music Use]"
    },
    {
        id: 3,
        inactive: true,
        existing_uses: "[Existing Uses]",
        abbreviation: "[Abbreviation]",
        full_description: "[Full Description]",
        rapidcue_music_use: "[RapidCue Music Use]"
    },
]

export const networksList = [
    {
        id: 1,
        inactive: true,
        network: "[Network]"
    },
    {
        id: 2,
        inactive: true,
        network: "[Network]"
    },
    {
        id: 3,
        inactive: true,
        network: "[Network]"
    },
    {
        id: 4,
        inactive: true,
        network: "[Network]"
    },
    {
        id: 5,
        inactive: true,
        network: "[Network]"
    }
]
export const prodExecutives = [
    {
        id: 1,
        inactive: true,
        name: "[Name]",
        display_name: "[Display Name]",
        email: "[Email]",
        work_phone: "[Work Phone]",
        cell_phone: "[Cell Phone]",
        assistant: "[Assistant]",
        assistant_phone: "[Assistant Phone]"
    },
    {
        id: 2,
        inactive: true,
        name: "[Name]",
        display_name: "[Display Name]",
        email: "[Email]",
        work_phone: "[Work Phone]",
        cell_phone: "[Cell Phone]",
        assistant: "[Assistant]",
        assistant_phone: "[Assistant Phone]"
    },
    {
        id: 3,
        inactive: true,
        name: "[Name]",
        display_name: "[Display Name]",
        email: "[Email]",
        work_phone: "[Work Phone]",
        cell_phone: "[Cell Phone]",
        assistant: "[Assistant]",
        assistant_phone: "[Assistant Phone]"
    }
]

export const productionGroupList = [
    {
        id: 1,
        inactive: true,
        production_group: "[Production Group]"
    },
    {
        id: 2,
        inactive: true,
        production_group: "[Production Group]"
    },
    {
        id: 3,
        inactive: true,
        production_group: "[Production Group]"
    },
    {
        id: 4,
        inactive: true,
        production_group: "[Production Group]"
    },
    {
        id: 5,
        inactive: true,
        production_group: "[Production Group]"
    }
]

export const programsList = [
    {
        id: 1,
        inactive: true,
        program_name: "[Program Name]",
        show_type: "[Show Type]",
        synopsis: "[Synopsis]"
    },
    {
        id: 2,
        inactive: true,
        program_name: "[Program Name]",
        show_type: "[Show Type]",
        synopsis: "[Synopsis]"
    },
]

export const publicDomainGenreList = [
    {
        id: 1,
        inactive: true,
        public_domain_genre: "[Public Domain Genre]",
    },
    {
        id: 2,
        inactive: true,
        public_domain_genre: "[Public Domain Genre]",
    },
]

export const rapidCueMusicUseList = [
    {
        id: 1,
        inactive: true,
        music_use: "[Music Use]",
    },
    {
        id: 2,
        inactive: true,
        music_use: "[Music Use]",
    },
]

export const emailList = [
    {
        id: 1,
        inactive: true,
        email: "[Email]",
    },
    {
        id: 2,
        inactive: true,
        email: "[Email]",
    },
]

export const ccList = [
    {
        id: 1,
        inactive: true,
        email: "[Email]",
    },
    {
        id: 2,
        inactive: true,
        email: "[Email]",
    },
]
export const showDurations = [
    {
        id: 1,
        inactive: true,
        show_duration: "[Detail]",
        minutes: "[Mins]"
    },
    {
        id: 2,
        inactive: false,
        show_duration: "[Detail]",
        minutes: "[Mins]"
    },
    {
        id: 3,
        inactive: true,
        show_duration: "[Detail]",
        minutes: "[Mins]"
    },
]
export const seasonsListData = [
    { id: 1, start_year: "02/04/2002", end_year: "05/03/2020",cost_splits:" " }
]