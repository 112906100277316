import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import TabsComponent from "../SharedComponents/Tabs/Tabs";
import { checkTransmittalTabList } from "./config";
import UserDefined from "./UserDefined";

class CheckTransmittals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      checkTransmittalData: {},
    };
  }
  handleChange = (field, value) => {
    this.setState((prevState) => ({
      checkTransmittalData: {
        ...prevState.checkTransmittalData,
        [field]: value,
      },
    }));
  };
  handleTabChange = (newTabValue) => {
    this.setState({
      tabValue: newTabValue,
    });
  };
  getTitleData = () => {
    console.log("get title entity");
  };
  selectTitle = (newValue) => {
    console.log("selected title--", newValue);
  };
  getProgramsDetails = () => {
    console.log("shows");
  };
  selectedShow = (newValue) => {
    console.log("select");
  };
  handleSeasonDetails = (value) => {
    console.log("season");
  };

  getTabComponent = () => {
    switch (this.state.tabValue) {
      case 1:
        return <UserDefined notificationComponent={this.props.notificationComponent} description={"Check Transmittal (Initial Fee)"} isCheck={true} {...this.props}/>
    }
  };
  render() {
    return (
      <div className="letterSetup-Container">
        <div>
          <MDBRow className="tab-content">
            <MDBCol md={12}>
              <TabsComponent
                tabList={checkTransmittalTabList}
                value={this.state.tabValue}
                onChange={(e, v) => this.handleTabChange(v)}
              />
            </MDBCol>
          </MDBRow>
        </div>
        {this.getTabComponent()}
      </div>
    );
  }
}

export default CheckTransmittals;
