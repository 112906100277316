import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import BasicTextField from '../../components/SharedComponents/BasicTextField/BasicTextField';
import SelectField from '../../components/SharedComponents/SelectField/SelectField';
import ClearTrackService from '../../services/service';
import messages from '../../Common/Messages.json';
import sizeLimits from '../../Common/SizeLimits.json'
import * as Constants from '../../constants/constants';
import { withUserContext } from '../../contexts/UserContext';
import { validateCharNumOnly } from '../../Common/Helper';

class CommonAddressComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: []
        }
    }

    componentDidMount() {
        this.getCountryList();
        this.getStateList();
    }

    getCountryList = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_country`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                let defaultCountry = formattedList.filter(item => {
                    return item.label === "United States"
                })

                this.setState({
                    countryList: formattedList,
                    //selectedCountryValue: defaultCountry[0].value
                });

                if (!this.props?.addressDetails?.[this.props.addressConfig?.country]) {
                    this.props.editCallback(this.props.addressConfig?.country?.slice(0, -3), defaultCountry[0].label);
                    this.props.editCallback(this.props.addressConfig?.country, defaultCountry[0].value);
                }
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getStateList = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_state`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                this.setState({ stateList: formattedList });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err)
                })
    }

    onChangeCallback = (field, value) => {
        this.props.editCallback(field, value);
        if (field.includes('country_id')) {
            this.props.editCallback(this.props.addressConfig?.state, null);
            this.props.editCallback(field.slice(0, -3), this.state.countryList?.find(item => item?.value === value)?.label);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props?.addressDetails?.[this.props.addressConfig?.country] && this.state.countryList.length > 0) {
            let defaultCountry = this.state.countryList.find(item => item.label === "United States");
            this.props.editCallback(this.props.addressConfig?.country?.slice(0, -3), defaultCountry.label);
            this.props.editCallback(this.props.addressConfig?.country, defaultCountry.value);
        }
    }

    render() {
        const { addressConfig } = this.props;
        return (
            <div>
                <MDBRow>
                    <MDBCol md={6}>
                        <MDBRow>
                            <BasicTextField
                                id="address_line1"
                                label={"Address Line"}
                                placeholder={"Address Line 1"}
                                value={(this.props?.addressDetails && addressConfig?.address1) ? this.props?.addressDetails?.[addressConfig?.address1] : ""}
                                showAsLabel={this.props?.disabled || false}
                                onChange={(e) => this.onChangeCallback(addressConfig?.address1, e.target.value.length > 0 ? e.target.value : null)}
                            />
                        </MDBRow>
                        <MDBRow className="mt-1">
                            <BasicTextField
                                id="address_line2"
                                placeholder={"Address Line 2"}
                                value={(this.props?.addressDetails && addressConfig?.address2) ? this.props?.addressDetails?.[addressConfig?.address2] : ""}
                                showAsLabel={this.props?.disabled || false}
                                onChange={(e) => this.onChangeCallback(addressConfig?.address2, e.target.value.length > 0 ? e.target.value : null)}
                            />
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={6}>
                        <MDBRow>
                            <BasicTextField
                                id="city"
                                label={"City"}
                                placeholder={"City"}
                                value={(this.props?.addressDetails && addressConfig?.city) ? this.props?.addressDetails?.[addressConfig?.city] : ""}
                                showAsLabel={this.props?.disabled || false}
                                onChange={(e) => this.onChangeCallback(addressConfig?.city, e.target.value.length > 0 ? e.target.value : null)}
                            />
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                {this.state.countryList?.find(item => item?.value == this.props?.addressDetails?.[addressConfig?.country])?.label == 'United States' ?
                                    <SelectField
                                        id={"state"}
                                        label={"State"}
                                        disabled={this.props?.disabled || false}
                                        value={this.state.stateList?.find(item => item?.label === this.props?.addressDetails?.[addressConfig?.state])?.value || null}
                                        options={this.state.stateList || []}
                                        onChange={(e) => this.onChangeCallback(addressConfig?.state, this.state.stateList?.find(item => item?.value === e.target.value)?.label)}
                                        showAsLabel={this.props?.disabled || false}
                                        stateLabel={this.props?.disabled ? true : false}
                                    /> :
                                    <BasicTextField
                                        id="state"
                                        //disabled={this.props?.disabled || false}
                                        label={"State"}
                                        value={this.props?.addressDetails?.[addressConfig?.state]}
                                        onChange={(e) => this.onChangeCallback(addressConfig?.state, e.target.value.length > 0 ? e.target.value : null)}
                                        disabled={this.props?.addressDetails?.[addressConfig?.country] ? false : true}
                                        showAsLabel={this.props?.disabled || false}
                                    />
                                }
                            </MDBCol>
                            <MDBCol>
                                <BasicTextField
                                    id="zip"
                                    label={"Zip"}
                                    placeholder={"Zip"}
                                    // limit={this.state.countryList?.find(item => item?.value == this.props?.addressDetails?.[addressConfig?.country])?.label !== 'United States' ? 10 : 5}
                                    // limitWarning={this.state.countryList?.find(item => item?.value == this.props?.addressDetails?.[addressConfig?.country])?.label !== 'United States' ? messages.addressLimitIntl : messages.addressLimitUs}
                                    value={(this.props?.addressDetails && addressConfig?.zipcode) ? this.props?.addressDetails?.[addressConfig?.zipcode] : ""}
                                    showAsLabel={this.props?.disabled || false}
                                    onChange={(e) => this.onChangeCallback(addressConfig?.zipcode, e.target.value.length > 0 ? e.target.value : null)}
                                    // fieldValid={(this.props?.addressDetails && addressConfig?.zipcode) ? (!validateCharNumOnly(this.props?.addressDetails?.[addressConfig?.zipcode]) ? true : false) : false}
                                    // inValidInput={messages.invalidInput}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <SelectField
                                id={"country"}
                                label={"Country"}
                                disabled={this.props.disabled || false}
                                value={this.props?.addressDetails?.[addressConfig?.country]}
                                options={this.state.countryList || []}
                                onChange={(e) => this.onChangeCallback(addressConfig?.country, e.target.value)}
                                showAsLabel={this.props.disabled || false}
                            />
                        </MDBRow>

                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}

export default withUserContext(CommonAddressComponent);
