export const payeesFilter = {
    payeeCompany: null,
    tax: null,
    vendor: null,
    firstName: null,
    lastName: null,
    dba: null,
    remitTo: null,
    addressName: null,
    streetAddress: null,
    city: null,
    email: null,
    state: null,
    country: null,
    zipCode: null,
    phone: null,
    notes: null
}

export const filterConfig = {
    payeeCompany: {id: 'payee_id', name: 'payee_company'},
}

export const payeesSelectedFilters = {
    taxSSN: null,
    vendor: null,
    contactName: null,
    dBA: null,
    remitTo: null,
    addressName: null,
    streetAddress: null,
    city: null,
    email: null,
    phone: null,
    notes: null,
    zipcode:null,
    is_active_flag: null,
    payeecompany: null,
    selectedState: null,
    selectedCountry: null
}

export const payeesTblConfig = {
    headings: [{
        headingLabel: "Payee Company",
        icon: "",
        width: "32%"
    },
    {
        headingLabel: "Tax ID/SSN",
        icon: "",
        width: "8%"
    },
    {
        headingLabel: "Vendor #",
        icon: "",
        width: "8%"
    },
    
    {
        headingLabel: "Contact",
        icon: "",
        width: "15%"
    },
    {
        headingLabel: "Address",
        icon: "",
        width: "20%"
    },
    {
        headingLabel: "Email",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Phone",
        icon: "",
        width: "7%"
    },
    {
        headingLabel: "Active",
        icon: "",
        width:"5%"
    },
],
    dataNodes: ["payee_company", "tax_ssn", "vendor",  "contact_name", "address", "email", "phone","is_active"],
    actions: [],
    hyperlinks:["payee_company"],
    primaryKey: "payee_id",
}

export const payeesDataConfig = [
    {
        id: 1,
        payee_company: "[Payee Name]",
        tax: "[Tax ID/SSN]",
        vendor: "[Vendor #]",
        contact: "[Contact Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        email: "[Contact Email]",
        phone: "[Contact Phone]"
    },
    {
        id: 2,
        payee_company: "[Payee Name]",
        tax: "[Tax ID/SSN]",
        vendor: "[Vendor #]",
        contact: "[Contact Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        email: "[Contact Email]",
        phone: "[Contact Phone]"
    },
    {
        id: 3,
        payee: "[Payee Name]",
        tax: "[Tax ID/SSN]",
        vendor: "[Vendor #]",
        company: "[Company Name]",
        contact: "[Contact Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        email: "[Contact Email]",
        phone: "[Contact Phone]"
    },
    {
        id: 4,
        payee_company: "[Payee Name]",
        tax: "[Tax ID/SSN]",
        vendor: "[Vendor #]",
        contact: "[Contact Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        email: "[Contact Email]",
        phone: "[Contact Phone]"
    },
    {
        id: 5,
        payee_company: "[Payee Name]",
        tax: "[Tax ID/SSN]",
        vendor: "[Vendor #]",
        contact: "[Contact Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        email: "[Contact Email]",
        phone: "[Contact Phone]"
    },
    
]
export const payeeRecordJSON = {
    payee_search_ids:null,
    tax_ssn_search_text:null,
    vendor_search_text:null,
    contact_fname_search_text:null,
    contact_lname_search_text:null,
    dba_search_text:null,
    remit_search_text:null,
    address_1_search_text:null,
    address_2_search_text:null,
    city_search_text:null,
    email_search_text:null,
    state_search_ids:null,
    country_search_ids:null,
    zip_search_text:null,
    phone_search_text:null,
    notes_search_text:null,
    order_by_clause: "Contact",
    order_by: null,
    page_count: 25,
    page_no: 1
}
