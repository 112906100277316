import React from 'react';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBDropdown, MDBCollapse,MDBNavbarToggler,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBContainer, MDBDropdownLink
} from 'mdb-react-ui-kit';
import { withOktaAuth } from '@okta/okta-react';
import AsynchronousSearch from '../../src/components/SharedComponents/AsynchronousSerach/AsynchronousSearch'
import { withUserContext } from '../contexts/UserContext';
import SideNav from '../components/SideNav/SideNav';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import MessageModal from '../components/SharedComponents/MessageModal';
import MainViewModalComponent from "../Common/MainViewModalLayout/Modal/Modal";
import { BrowserRouter as Router, Link as RouterLink,  withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import clearTrackService from '../services/service';
import * as Constants from '../constants/constants';
import logo from '../assets/WBDLogo.png';


var checkInterval = null;
export default //withRouter(
    withRouter(withUserContext(
        withOktaAuth(class HeaderContent extends React.Component {
            constructor(props) {
                super(props);
                this.logout = this.logout.bind(this);
                this.login = this.login.bind(this);
                this.state = {
                    confirmSaveChanges: false,
                    switch_tenant_id: 0,
                    switch_tenant_name: "",
                    isManageUser: false,
                    sideNavOpen: false,
                    showNotification: false,
                    currentTime: null,
                    tenant_id: null,
                    getScheduledTime: () => this.getScheduledTime(),
                    showIcon: false,
                    seasons: [],
                    scheduled_time:null,
                    production_group_name: null,
                    budget_vs_efc_scheduling_id: null
                }
            }

            async logout() {
                var len = localStorage.length;
                var keys = Object.keys(localStorage);
                for (var i = 0; i < len; i++) {
                    var key = keys[i];
                    if (key.indexOf("okta-") == -1)
                        localStorage.removeItem(key);
                }

                this.props.oktaAuth.signOut('/');
            }


            async login() {
                // Redirect to '/' after logout
                this.props.authService.login('/');
            }
            handleSideNavActive = (value, routePath) => {
                this.props.history.push(routePath, this.state);
            }

            switchTenant = (tenant_id, tenant_name) => {
                this.setState({ switch_tenant_id: tenant_id, switch_tenant_name: tenant_name, confirmSaveChanges: !this.state.confirmSaveChanges });
            }

            handleOpenScheduledNotification = () => {
                this.setState({ showNotification: true })
            }

            confirmCancelChanges = (isCancel) => {
                //if (isCancel) {
                this.props.userContext.switchTenant(this.state.switch_tenant_id);
                //}
                //else {
                this.setState({ confirmSaveChanges: !this.state.confirmSaveChanges });
                //}
            }
            componentWillReceiveProps(nextProps) {
                this.setState({
                    isManageUser: nextProps.userContext?.active_tenant?.permissions?.isManageUser
                });
            }

            componentDidMount() {
                // this.getScheduledTime();
                //this.getTime();
                checkInterval = setInterval(this.getScheduledTime,300000)
            }

            static getDerivedStateFromProps(props, state) {
                if (props?.userContext?.active_tenant?.tenant_id !== state.tenant_id) {
                    state.getScheduledTime();
                    return {
                        tenant_id: props?.userContext?.active_tenant?.tenant_id
                    }
                }
                return null;
            }

            hanldeDrawerOpen = () => {
                this.setState({ sideNavOpen: true }, () => {
                    this.props?.userContext?.updateSideNavOpen(true)
                })
            }
            handleDrawerClose = () => {
                this.setState({ sideNavOpen: false }, () => {
                    this.props?.userContext?.updateSideNavOpen(false)
                })
            }
            routeToDashboard = () => {
                if(this.props.location.pathname?.toLowerCase() !== '/cuesheet' && this.props.location.pathname?.toLowerCase() !== '/userrolemanagement') {
                let default_home_page = this.props.userContext.active_tenant.default_home_page;
                let dashboardName = default_home_page === "Show Seasons"  ? "Show Seasons" : default_home_page === "Clearance Records" ? "Clearance Records" : default_home_page === "Songs" ? "songLibrary" : default_home_page === "Licensors" ? "Licensors" : default_home_page === "Payees" ? "payees" : default_home_page === "Cue Sheets" ? "cueSheets" : default_home_page === "Music Editors Show Composers" ? "musicEditor" : default_home_page === "TV Creative Music Budget" ? "budget" : default_home_page === "Reports" ? "reports" : null;
                let sectionName = default_home_page === "Show Seasons"  ? "showSeasons" : default_home_page === "Clearance Records" ? "clearanceRecords" : default_home_page === "Songs" ? "songLibrary" : default_home_page === "Licensors" ? "licensors" : default_home_page === "Payees" ? "payees" : default_home_page === "Cue Sheets" ? "cueSheets" : default_home_page === "Music Editors Show Composers" ? "musicEditor" : default_home_page === "TV Creative Music Budget" ? "budget" : default_home_page === "Reports" ? "reports" : null;
                let dashboardId = default_home_page === "Show Seasons"  ? 1 : default_home_page === "Clearance Records" ? 2 : default_home_page === "Songs" ? 3 : default_home_page === "Licensors" ? 4 : default_home_page === "Payees" ? 6 : default_home_page === "Cue Sheets" ? 7 : default_home_page === "Music Editors Show Composers" ? 8 : default_home_page === "TV Creative Music Budget" ? 9 : default_home_page === "Reports" ? 12 : null;
                this.props.history.push('/');
                this.props.userContext.handleSideNavActiveMain(dashboardId, dashboardName, sectionName)
                }
            }

            handleNavigation = () => {
                let dashboardName = "Reports";
                let sectionName = "reports";
                let dashboardId = 13;
                let reportTabData = ["budgetvsEFCbyEpisode"]
                this.props.userContext.handleSideNavActiveMain(dashboardId, dashboardName, sectionName, reportTabData)
                this.setState({ showNotification: false })
            }

            getScheduledTime = () => {
                this.setState({ tenant_id: this.props?.userContext?.active_tenant?.tenant_id })
                if (this.props?.userContext?.active_tenant?.tenant_id) {
                    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/budgetVsEfcScheduleCheck`, this.props.userContext?.active_tenant?.tenant_id, null)
                        .then(response => {
                            let scheduledItem = response?.data?.find(ele => ele?.message?.toLowerCase() === 'scheduled')
                            if (scheduledItem) {
                                this.setState({ scheduled_time: scheduledItem?.schedule_time|| null, seasons: scheduledItem?.seasons || null, 
                                    production_group_name: scheduledItem?.production_group_name || null, budget_vs_efc_scheduling_id: scheduledItem?.budget_vs_efc_scheduling_id || null}, () => {
                                    var to_day = new Date();
                                    let time = to_day.getHours() + ':' + to_day.getMinutes() + ':' + to_day.getSeconds();
                                    this.setState({ currentTime: time }, () => {
                                        if (this.state.currentTime >= this.state.scheduled_time) {
                                            this.setState({ showIcon: true })
                                        }
                                    });

                                })
                            }
                        })
                }
            }


            getTime = () => {
                checkInterval = setInterval(() => {
                    var today = new Date();
                    let time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
                    this.setState({ currentTime: time }, () => {
                        if (this.state.currentTime >= this.state.scheduled_time) {
                            this.setState({ showIcon: true })
                        }
                    });
                }, 60000)
            }

            handleRemoveIcon = () => {
                this.setState({ showIcon: false, seasons: [],
                    scheduled_time:null,
                    production_group_name: null,
                    budget_vs_efc_scheduling_id: null}, () => {
                    this.getScheduledTime()
                    //clearInterval(checkInterval)
                })
            }

            render() {
                const bgColorBlue = { backgroundColor: '#0062AF' }
                const adminTitle = { fontSize: '13px', color: '#ffffff', padding: '2px' }
                const ptli = { paddingTop: '0.1rem' }

                return (
                    <div id="headerContent">
                        {/* <Router fixed="top"> */}
                        <header  >
                            <div className="top">
                                <MDBNavbar bgColor="primary" dark expand='lg' className="header">
                                    <MDBContainer fluid>
                                    <MDBNavbarToggler
                                        type='button'
                                        aria-expanded='false'
                                        aria-label='Toggle navigation'
                                       // onClick={() => setShowNavNoTogglerThird(!showNavNoTogglerThird)}
                                    >
                                        {/* <MDBIcon icon='bars' fas /> */}
                                    </MDBNavbarToggler>
                                            <MDBNavbarBrand className='mb-0 h1'>
                                            <Link component={RouterLink} className="action-icons">
                                                <img src={logo} alt='Logo' className="imageWidth" id="imageWidth1" onClick={this.routeToDashboard} />
                                            </Link>
                                             <Link component={RouterLink} className="action-icon">
                                                <h1 className="app-title" onClick={this.routeToDashboard} >
                                                    CLEaRTRaCK
                                                </h1>
                                            </Link>
                                            
                                            </MDBNavbarBrand>
                                            {this.props?.isLoggedIn  ? <>
                                                {this.props.location.pathname?.toLowerCase() !== '/cuesheet' && this.props.location.pathname?.toLowerCase() !== '/userrolemanagement' && <SideNav openCueSheetModal={() => this.setState({ cueSheetModal: true })} openCostAllocationModal={() => this.setState({ costAllocationModal: true })} />} 
                                                {(this.state.showIcon && this.props.location.pathname?.toLowerCase() !== '/cuesheet' && this.props.location.pathname?.toLowerCase() !== '/userrolemanagement') ?
                                            <div className="icon-bell">
                                                <MDBIcon
                                                    icon="bell"
                                                    onClick={this.handleOpenScheduledNotification}
                                                ></MDBIcon>
                                            </div>
                                            : null}
                                                <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>                                            {/* <MDBNavbarItem className="d-none d-lg-block">
                                            <span style={adminTitle}>Signed in as: &nbsp;
                                            <b> {this.props.user_full_name} </b>
                                                <br />
                                                <i> {this.props.active_tenant_role ? this.props.active_tenant_role + ", " + this.props.active_tenant_name : ""} </i>
                                            </span>
                                        </MDBNavbarItem> */}

                                            <MDBNavbarItem className="padding-hor-25" >
                                                <MDBDropdown >
                                                    <MDBDropdownToggle tag="a" className="bgColorBlue shadow-none text-white" >

                                                        <MDBIcon fas size='3x' icon="user-circle" className="mr-2" />
                                                    </MDBDropdownToggle>
                                                    <MDBDropdownMenu className="text-end border border-1-quinary">
                                                        {this.props.userContext?.user_profile?.tenants?.map((tenant) => {
                                                            if (tenant.tenant_id != this.props.userContext?.active_tenant?.tenant_id)
                                                                return <MDBDropdownItem data-active={false} key={tenant.tenant_id}
                                                                    onClick={this.switchTenant.bind(this, tenant.tenant_id, tenant.tenant_name)}>
                                                                    <MDBDropdownLink className="text-tertiary" active={false} to='/'>
                                                                        <small>{tenant.tenant_name}</small>
                                                                    </MDBDropdownLink>
                                                                </MDBDropdownItem>
                                                            else
                                                                return <MDBDropdownItem data-active={false} key={tenant.tenant_id}>
                                                                    <MDBDropdownLink className="text-dark" active={false} >
                                                                        <small>{tenant.tenant_name}</small>
                                                                    </MDBDropdownLink>
                                                                    <div>{tenant?.division_role?.map(divisionRole => <div key={divisionRole?.division_id + divisionRole?.division_name}>{divisionRole?.division_name +'-'+ divisionRole?.role_name}</div>)}</div>
                                                                </MDBDropdownItem>
                                                        }
                                                        )}
                                                        <MDBDropdownItem active={false} className={!this.props.isLoggedIn ? "hide" : "show"}
                                                            disabled={!this.props.isLoggedIn} onClick={this.logout} >
                                                            <MDBDropdownLink className="fw-bold" active={false} to='/'>Logout</MDBDropdownLink >
                                                        </MDBDropdownItem>

                                                    </MDBDropdownMenu>
                                                </MDBDropdown>
                                            </MDBNavbarItem>

                                        </MDBNavbarNav>
                                          
                                            </> 
                                            
                                            : null}
                                            
                                            
                                        
                                        {/* {this.props.location.pathname !== '/userRoleManagement' && <AsynchronousSearch props={this.props}></AsynchronousSearch>} */}
                                       
                                      
                                    </MDBContainer>
                                </MDBNavbar>
                                <MessageModal
                                    open={this.state.confirmSaveChanges}
                                    title={"Unsaved Changes"}
                                    message={"Switching tenant will lose any unsaved changes. Do you want to proceed?"}
                                    primaryButtonText={"Yes"}
                                    secondaryButtonText={"No"}
                                    onConfirm={() => this.confirmCancelChanges()}
                                    handleClose={(e) => this.setState({ confirmSaveChanges: false })} />
                            </div>
                        </header>
                        {this.state.cueSheetModal && (
                            <MainViewModalComponent
                                open={this.state.cueSheetModal}
                                handleClose={() => this.setState({ cueSheetModal: false })}
                                headerText={"Cue Sheet Acknowledgement Warnings"}
                                mode={""}
                                modalName={"Cue Sheet Acknowledgement Warnings"}
                            />
                        )}
                        {this.state.costAllocationModal && (
                            <MainViewModalComponent
                                open={this.state.costAllocationModal}
                                handleClose={() => this.setState({ costAllocationModal: false })}
                                headerText={"Cost Allocation Calculator"}
                                mode={""}
                                modalName={"Cost Allocation Calculator"}
                            />
                        )}
                        {
                            this.state.showNotification &&
                            <MainViewModalComponent
                                open={this.state.showNotification}
                                handleClose={() => this.setState({ showNotification: false })}
                                headerText={`Scheduled Budget vs. EFC Report - ${this.state.production_group_name}`}
                                mode={"Both"}
                                modalName={"Scheduled Budget EFCReport"}
                                handleNavigation={this.handleNavigation}
                                // id={this.state.selectedLicensor}
                                commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
                                seasons={this.state.seasons}
                                budget_vs_efc_scheduling_id={this.state.budget_vs_efc_scheduling_id}
                                handleRemoveIcon={this.handleRemoveIcon}
                            // production_group_name={this.state.production_group_name}
                            />
                        }
                        {/* </Router> */}
                    </div>
                );
            }
        }
        )
    ));