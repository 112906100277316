import React from 'react';
import BasicButton from "./BasicButton/BasicButton";
import { withUserContext } from '../../contexts/UserContext';
let subjectToDisplay = "dummy Subject";
let recipients = ["abc@gmail.com", "xyz@gmail.com"];
let ccRecipiensList = ["dummy@gmail.com", "qwerty@gmail.com"]
let emailbody = `This is dummy email`;
let recipientsList = recipients.toString();
class EmailButton extends React.Component {

    sendEmail = () => {
        // window.open(`mailto:${recipientsList}?subject=${subjectToDisplay}&body=${emailbody}&cc=${ccRecipiensList}`);
        window.open(`https://outlook.office.com/mail/deeplink/compose?subject=${subjectToDisplay}&body=${emailbody}&to=${recipientsList}`)
    };
    render() {
        return (
            <div>
                <BasicButton text={"Send Email"} onClick={this.sendEmail} />
            </div >
        )
    }
}
export default withUserContext(EmailButton);