import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RichTextEditor from "../SharedComponents/RichTextEditor/RichTextEditor";
import "./LetterSetup.scss";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import clearTrackService from '../../services/service';
import * as Constants from '../../constants/constants';
import { withUserContext } from '../../contexts/UserContext';
import { postPayload } from "./config";
import axios from 'axios';
import SelectField from "../SharedComponents/SelectField/SelectField";
import alphabetVariantsJson from '../SharedComponents/RichTextEditor/AlphabetVariant.json';
import CircularProgress from '@material-ui/core/CircularProgress';
let postPayloadData = JSON.parse(JSON.stringify(postPayload))

class AdCardLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      adCardLanguageData: {},
      loadingLetters: false,
      loadingTerms: false,
      loadingTypes: false,
      isLoading: false,
      letterTypeId: null,
      termId: null,
      licensorTypeId:null,
      letterTypeList: [],
      typeList: [{value: "S", label:"S"},{value: "M", label:"M"},{value: "S/M",label:"S/M"}],
      isShowFetching: null,
      adCardLanguageId: null,
      adCardData: {},
      termList: [],
      filterList: {
        letterTypeList: [],
      },
      alphabetVariantMessage: false,
      alphaVariants: [],
      richTextEditor: null,
      indexToModify: null,
      editorText: null,
      characterToModify: null,
      include_video: false,
      is_alt_ad_card: false,
      selectedLetterValue: {},
      isFetchingLetterLoading: false,
      isTextError: false
    };
  }

  componentDidMount() {
    this.getEntity("LETTER_TYPE", { id: 'letter_type_id', name: 'description' }, "letterTypeList","")
    this.getTermList("TERM","");
  }

  getAdCardLanguage = () => {
    this.setState({isFetchingLetterLoading : true})
    let encryptionType = 1;
    let {letterTypeId, termId ,licensorTypeId, include_video, is_alt_ad_card} = this.state;
    let data = {
      letter_type_id: letterTypeId, term_id:termId, is_alt_ad_card: is_alt_ad_card ? 1 : 0, include_video: include_video ? 1 : 0, licensor_type: licensorTypeId
    }
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +`/adCardLanguage?input=${btoa(JSON.stringify(data))}`, this.props.userContext?.active_tenant?.tenant_id,null,true)
      .then(response => {
        this.setState({ isLoading: false });
        if (response.data.length > 0) {
          let adCardLanguageData = { ... this.state.adCardLanguageData }
          if (adCardLanguageData?.selectedLetterType) {
            adCardLanguageData.selectedLetterType = { value: response.data[0]?.letter_type_id, text: response.data[0]?.letter }
          } if (adCardLanguageData?.term_id) {
            adCardLanguageData.term_id = response.data[0]?.term_id
          }if (adCardLanguageData?.licensor_type) {
            adCardLanguageData.licensor_type = { value: response.data[0]?.licensor_type, label: response.data[0]?.licensor_type }
          }
          adCardLanguageData.is_alt_ad_card = (response.data[0]?.is_alt_ad_card === 0) ? false : true;
          adCardLanguageData.rights = (response.data[0]?.include_video === 1) ? "include_video" : "exclude_video";
          this.setState({ adCardLanguageData, richTextEditor: response.data[0]?.letter_text })
        } else {
          let adCardLanguageData = { ... this.state.adCardLanguageData }
          this.setState({ richTextEditor: " ", adCardLanguageData })
        }
        this.setState({isFetchingLetterLoading: false})
      },
        (err) => {
          this.setState({ isLoading: false })
          console.log("Error in fetching details:", err)
        })
  }

  getTermList = (entity,searchString) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
    .then(response => {
      let formattedList = response.data?.map((item) => ({
        value: item.term_id,
        label: item.term_name
      }));
      this.setState({termList:formattedList})
    },
    (err) => {
      console.log("Error in fetching show data:", err)
    })
  }

  getEntity = (entity, config, node, searchString) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (this.props?.userContext?.active_tenant?.tenant_id) {
      this.setState({ isShowFetching: true })
      clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
          let formattedList = response.data?.map((item) => ({
            value: item[config.id],
            text: item[config.name]
          }));
          let letterValue = response.data?.find((item, index) => index === 0)
          let adCardLanguageData = { ...this.state.adCardLanguageData }
            this.setState({adCardLanguageData})
            this.updateListState(formattedList, node, "filterList");
            this.setState({ isShowFetching: false })
        },
          (err) => {
            console.log("Error in fetching show data:", err)
          })
    }
  }
  updateListState = (value, node, list) => {
    this.setState(prevState => ({
      [list]: {
        ...prevState[list],
        [node]: value
      }
    }))
  }

  handleSubmit = () => {
    if(document.getElementById("issueToLanguageText")?.getElementsByClassName('ql-editor')[0]?.innerHTML === '<div> </div>' || this.state.editorText === null || this.state.editorText.trim() === ''){
      this.setState({isTextError: true})
      }
      else{
      this.setState({isTextError: false});
    this.setState({ isPosting: true });
    let postObj = JSON.parse(JSON.stringify(this.state.adCardLanguageData));
    postPayloadData["letter_text"] = document.getElementById("adcardTextArea")?.getElementsByClassName('ql-editor')[0]?.innerHTML ? document.getElementById("adcardTextArea")?.getElementsByClassName('ql-editor')[0]?.innerHTML : null;
    if (this.state.adCardLanguageId != null) {
      postPayloadData["ad_card_language_id"] = this.state.adCardLanguageId
    } else {
      postPayloadData["ad_card_language_id"] = null
    }
    postPayloadData["letter_type_id"] = postObj?.selectedLetterType?.value ? postObj?.selectedLetterType?.value : null;
    postPayloadData["term_id"] = postObj?.term_id ? postObj?.term_id : null;
    postPayloadData["licensor_type"] = postObj?.letterType
    postPayloadData["include_video"] = (postObj?.rights === "exclude_video") ? 0 : 1
    postPayloadData["is_alt_ad_card"] = postObj?.is_adcard ? 1 : 0
    clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + "/adCardLanguage", postPayloadData, this.props.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        if (response.data.error) {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
        } else {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "success");
          this.getAdCardLanguage();
        }
      },
        (err) => {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
          console.log("Post agency details error: " + err);
        });
      }
  }
 
 
  onChangeCallBack = (field, val) => {
    let selectedValue = val;
    if (field === "selectedLetterType") {
      let letterTypeId = val.value
      this.setState({ letterTypeId })
    } else if (field === "term_id") {
      let termId = val
      this.setState({termId})
    } else if (field === "letterType") {
      let licensorTypeId = val
      this.setState({licensorTypeId})
    } else if (field === "rights") {
      let include_video = (val === "include_video") ? 1 : 0
      this.setState({include_video})
    }else if (field === "is_adcard") {
      let is_alt_ad_card = val ? 1 : 0
      this.setState({is_alt_ad_card})
    }
    this.handleChange(field, selectedValue);
  
  };
  handleChange = (field, value) => {
    this.setState((prevState) => ({
      adCardLanguageData: {
        ...this.state.adCardLanguageData,
        [field]: value,
      },
    }), () => {
        if (this.state.adCardLanguageData?.selectedLetterType != null && this.state.adCardLanguageData?.term_id != null && this.state.adCardLanguageData?.letterType && this.state.adCardLanguageData?.rights){
          this.getAdCardLanguage()
        }
     
    });
  };

  handleRichTextEditor = (content=null, delta=null, source=null,editor,variantToAdd=null) => {
    if (variantToAdd === null) {
      let text = editor.getText()
      let characterToModify = text.charAt(text.length - 2)
      let indexToModify = text.length-2
      let alphaVariants = []
      if (alphabetVariantsJson[characterToModify] != undefined) {
        alphaVariants = alphabetVariantsJson[characterToModify]
      }
      this.setState({characterToModify,indexToModify, alphaVariants, richTextEditor: editor.getHTML(),editorText:editor.getText() })
      if (alphaVariants.length > 0) {
        this.setState({ alphabetVariantMessage: true })
      } else {
        this.setState({ alphabetVariantMessage: false })
      }
    } else {
      let text = this.state.editorText
      let result = editor.indexOf(this.state.characterToModify + "</");
      String.prototype.replaceAt = function(index, replacement) {
        return this.substring(0, index) + replacement + '</div>';
      //this.substring(index + replacement.length);
      }
      let text1 = text.replaceAt(this.state.indexToModify, variantToAdd)
      let richTextEditorValue = editor.replaceAt(result, variantToAdd)
      this.setState({richTextEditor: richTextEditorValue })
      document.getElementById("adcardTextArea")?.getElementsByClassName('ql-editor')[0]?.focus()
    }
  }
  render() {
    return (
     
        <div >
          <MDBRow className="AdContainer">
            {this.state.adCardLanguageData?.selectedLetterType === undefined || this.state.adCardLanguageData?.term_id === undefined || this.state.adCardLanguageData?.letterType === undefined || 
            this.state.adCardLanguageData?.rights === undefined?
            <span className="ad-text">Please select a letter, Term Letter type, and check any rights</span> : " "}
            <MDBCol md={7}>
              <MDBRow>
              <MDBCol md={5} id="letter-field">
              <BasicLabel text={"Choose a Letter: "} />
                <SearchSelectField
                     options={this.state.filterList?.letterTypeList || []}
                     loading={this.state.isShowFetching}
                     width="80%"
                     multiple={false}
                  detail_selected={this.state.adCardLanguageData?.selectedLetterType}
                     searchSelect={true}
                     valueSelected={(e, newValue) => {
                       this.onChangeCallBack("selectedLetterType", newValue)
                     }}
                     searchText={ev => {
                       // dont fire API if the user delete or not entered anything
                       if (ev.target.value !== "" && ev.target.value !== null) {
                         this.getEntity("LETTER_TYPE", { id: 'letter_type_id', name: 'description' }, "letterTypeList", ev.target.value);
                       } else {
                         this.setState({ isShowFetching: false })
                       }
                     }}
                  />
                </MDBCol>
                <MDBCol md={4} id="term-field">
                <BasicLabel text={"Choose a Term: "} />
                <SelectField
                  id="letter_type"
                  // label={"Letter Type:"}
                  options={this.state.termList || []}
                  // placeHolderText="- gt -"
                  value={this.state.adCardLanguageData?.term_id || null}
                  onChange={(e)=>{this.onChangeCallBack("term_id",e.target.value)}}
                />
      
                </MDBCol>
                <MDBCol md={3} id="type-field">
                <BasicLabel text={"Letter Type: "} />
                <SelectField
                  id="letter_type"
                  // label={"Letter Type:"}
                  options={this.state.typeList || []}
                  // placeHolderText="- gt -"
                  value={this.state.adCardLanguageData?.letterType || null}
                  onChange={(e)=>{this.onChangeCallBack("letterType",e.target.value)}}
                />
                </MDBCol>
              </MDBRow>
          </MDBCol>
          <MDBCol md={5}>
            <MDBRow>
              <MDBCol md={6} className={"radio-font"}>
              <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  id="radio-group-height"
                  // defaultValue="exclude_video"
                    // value={this.state.adCardLanguageData.rights || null}
                    onChange={(e) => this.onChangeCallBack("rights", e.target.value)}
                  >
                    <FormControlLabel
                      className="LabelSize"
                      value="include_video"
                      control={<Radio size="small" />}
                      label="Rights Include Video"
                    />
                    <FormControlLabel
                      className="LabelSize"
                      value="exclude_video"
                      control={<Radio size="small" />}
                      label="Rights Exclude Video"
                    />
                  </RadioGroup>
              </MDBCol>
              <MDBCol md={6}>
              <BasicCheckbox id="is_active" label="Alternate Ad Card"
                  checked={this.state.adCardLanguageData?.is_adcard || false}
                  onChange={(e) => this.onChangeCallBack("is_adcard", e.target.checked)}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
            <MDBCol md={1}></MDBCol>
          </MDBRow>
          {this.state.isFetchingLetterLoading ?
            <div className="ContentLoader">
              <CircularProgress />
                </div> :
              <MDBRow>
                 <span className="error-text">
                  {this.state.isTextError ? `Please write the comment in textEditor` : " "}</span>
                <MDBCol md={10} id={`adcardTextArea`} disabled={this.state.adCardLanguageData?.selectedLetterType === undefined || this.state.adCardLanguageData?.term_id === undefined || this.state.adCardLanguageData?.letterType === undefined || this.state.adCardLanguageData?.rights === undefined || !this.props?.editRecord}>
                  <RichTextEditor id={`adcardTextArea`} value = {this.state.richTextEditor} handleRichTextEditor={this.handleRichTextEditor.bind(this)}
                   onChange={this.handleRichTextEditor} alphabetVariantMessage={this.state.alphabetVariantMessage} alphaVariants={this.state.alphaVariants} />
                </MDBCol>
             </MDBRow>
          }
          {this.props?.editRecord &&
          <MDBRow>
              <MDBCol md={10}></MDBCol>
              <MDBCol md={2} className="saveCancelWrapper" >
                <BasicButton
                  
                  variant="outlined"
                  text={"Revert"}
                  disabled={this.state.adCardLanguageData?.selectedLetterType === undefined || this.state.adCardLanguageData?.term_id === undefined || this.state.adCardLanguageData?.letterType === undefined || this.state.adCardLanguageData?.rights === undefined
                  || this.state.adCardLanguageData?.selectedLetterType === undefined || this.state.adCardLanguageData?.term_id === null || this.state.adCardLanguageData?.letterType === null || this.state.adCardLanguageData?.rights === null}
                  onClick={this.getAdCardLanguage}
                />
                <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                  disabled={this.state.adCardLanguageData?.selectedLetterType === undefined || this.state.adCardLanguageData?.term_id === undefined || this.state.adCardLanguageData?.letterType === undefined || this.state.adCardLanguageData?.rights === undefined
                    || this.state.adCardLanguageData?.selectedLetterType === undefined || this.state.adCardLanguageData?.term_id === null || this.state.adCardLanguageData?.letterType === null || this.state.adCardLanguageData?.rights === null
                  }
                  onClick={() => this.handleSubmit()}
                />
              </MDBCol>
            </MDBRow>
          }
        </div>
      
    );
  }
}

export default withUserContext(AdCardLanguage);
