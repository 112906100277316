export const newDetailObject = {
    "show_season_id": null,
    "season_id": null,
    "show_id": null,
    // harshit changes
    // "music_supervisor_id": null,
    "show_season_people_id": null,
    // "payee": null,
    "payee_remit_to_id": null,
    "show_season_episode_id": null,
    "episode": null,
    "check_amount": null,
    "category_id": null,
    "cost_center_code": null,
    "req_date": null,
    "inv_number": null,
    "date_paid": null,
    "inv_notes": null,
    "notes": null,
    "tenant_id": 1,
    "division_id": 1,
    "is_active": 1
}
export const businessAffairsDetails = {
    businessAffairsList: [{
        "Agent Name": "John Smith (Agent)",
        "Company": "Hanz Zimmerman Compositions LLC. ",
        "Work Phone": "999-999-9999",
        "Cell Phone": "999-999-9999",
        "Email": "HanzZimmerman@gmail.com",
        "Notes": "none Added",
        Address: ["12345 Dreary Lane, Suite 4000", "Los Angeles, CA 90210, United States"]

    },],
    // showComposers: ["none Added"],
    musicSupervisors: [{ value: 1, label: ["Hans Zimmerman", "Carly VanderBeek"], icon: ["pen", "times"], },
    ],
    producersName: [{ "name": "John Smith", "email": "John.Smith@email.com" },
    { "name": "Frank Josephson", "email": "Frank.Josephson@email.com" }],
    publicst: [{ "name": "David Johnson", "email": "David.Johnson@alias.com" }],
    marketingContact: [{ "name": "Amy Smith", "email": "Amy.Smith@alias.com" }],
    deals: [{
        "Status": "Session Report Received",
        "Fee per Episode": "$10,000.00",
        "Services Being Rendered": "[Services Being Rendered]",
        "Additional Notes": "None",
        "Type": "Episode",
        "Union": "SAG",
        "Credit": "[Credit]",
        "Agentname": "Royalties",
        "Record Royalties (Artist)": "3%",
        "Cue Sheet Splits": " [Cue Sheet Splits]",
        "Record Royalties(Producer)": "2%",
        "Federal ID No.": "[Federal ID No.]",
        "Performing Rights Society": "[Performing Rights Society]"
    }]

}

export const musicSupervisorConfig = {
    headings: [
        {
            headingLabel: " ",
            inputType: "",
            border: true,
            width: "2%"
        },
        {
            headingLabel: "Payee",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "10%",
        },
        {
            headingLabel: "Episode",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Check Amt",
            inputType: "text",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Category",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Cost Center",
            inputType: "label",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Req.Dt",
            inputType: "datefield",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Inv#",
            inputType: "text",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Check Req",
            inputType: "",
            border: true,
            width: "6%"
        },
        {
            headingLabel: "Dt.Paid",
            inputType: "datefield",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Packet?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Inv.notes",
            inputType: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Notes",
            inputType: "",
            border: true,
            width: "4%"
        },
    ],
    dataNodes: ["show_season_music_supervisor_check_requests_id", "payee_remit_to_id", "show_season_episode_id", "check_amount", "category_id", "cost_center_code", "req_date", "inv_number", "bch", "date_paid", "is_packet", "inv_notes", "notes"],
    searchFields: [{
        label: "payee",
        hyperlink: false
    },
    {
        label: "episode",
        hyperlink: false
    }],
    primaryKey: "show_season_music_supervisor_check_requests_id",
    actions: ["pen", "times"],
    textWithbuttonFields: [],
    tooltips: ["inv_notes", "notes"],
    toggleFields: ["is_packet"],
    nodeFields: [
        {
            column: "show_season_music_supervisor_check_requests_id",
            icon: "pen-square"
        },
        {
            column: "is_packet",
            icon: "check"
        },
        {
            column: "notes",
            icon: "file"
        },
        {
            column: "bch",
            icon: "money-check"
        },
        {
            column: "inv_notes",
            icon: "file"
        }
    ],
    inlineEdits: [
        {
            dataNode: "payee_remit_to_id",
            width: "10%"
        },
        {
            dataNode: "show_season_episode_id",
            width: "11%"
        },
        {
            dataNode: "check_amount",
            width: "7.5%",
            inlineIcon:"$",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "category_id",
            width: "12%"
        },
        {
            dataNode: "cost_center_code",
            width: "4%"
        },
        {
            dataNode: "req_date",
            width: "8%"
        },
        {
            dataNode: "inv_number",
            width: "6%"
        },
        {
            dataNode: "CheckReq",
            width: "5%"
        },
        {
            dataNode: "date_paid",
            width: "8%"
        },
        {
            dataNode: "is_packet",
            width: "5%"
        },
        {
            dataNode: "inv_notes",
            width: "5%"
        },
        {
            dataNode: "notes",
            width: "5%"
        },

    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    buttonFields: [{ column: "id", icon: "pen-square" }]
};
export const showComposerConfig = {
    headings: [
        {
            headingLabel: " ",
            inputType: "",
            border: true,
            width: "2%"
        },
        {
            headingLabel: "Payee",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "10%",
        },
        {
            headingLabel: "Episode",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Check Amt",
            inputType: "text",
            border: true,
            width: "8%"        },
        {
            headingLabel: "Category",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Cost Center",
            inputType: "label",
            width: "10%"
        },
        {
            headingLabel: "Req.Dt",
            inputType: "datefield",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Inv#",
            inputType: "text",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Check Req",
            inputType: "",
            border: true,
            width: "6%"
        },
        {
            headingLabel: "Dt.Paid",
            inputType: "datefield",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "SR?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Score?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Inv.notes",
            inputType: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Notes",
            inputType: "",
            border: true,
            width: "3%"
        },
    ],
    dataNodes: ["show_season_music_supervisor_check_requests_id", "payee_remit_to_id", "show_season_episode_id", "check_amount", "category_id", "cost_center_code", "req_date", "inv_number", "bch", "date_paid", "is_sr", "is_score", "inv_notes", "notes"],
    searchFields: [
    // {
    //     label: "payee",
    //     hyperlink: false
    // },
    {
        label: "episode",
        hyperlink: false
    }],
    primaryKey: "show_season_music_supervisor_check_requests_id",
    actions: ["pen", "times"],
    textWithbuttonFields: [],
    toggleFields: ["is_sr", "is_score"],
    nodeFields: [
        {
            column: "show_season_music_supervisor_check_requests_id",
            icon: "pen-square"
        },
        {
            column: "is_sr",
            icon: "check"
        },
        {
            column: "is_score",
            icon: "check"
        },
        {
            column: "notes",
            icon: "file"
        },
        {
            column: "bch",
            icon: "money-check"
        },
        {
            column: "inv_notes",
            icon: "file"
        }
    ],
    inlineEdits: [
        {
            dataNode: "payee_remit_to_id",
            width: "10%"
        },
        {
            dataNode: "show_season_episode_id",
            width: "11%"
        },
        {
            dataNode: "check_amount",
            width: "7.5%",
            inlineIcon:"$",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "category_id",
            width: "12%"
        },
        {
            dataNode: "cost_center_code",
            width: "4%"
        },
        {
            dataNode: "req_date",
            width: "8%"
        },
        {
            dataNode: "inv_number",
            width: "6%"
        },
        {
            dataNode: "CheckReq",
            width: "5%"
        },
        {
            dataNode: "date_paid",
            width: "8%"
        },
        {
            dataNode: "is_sr",
            width: "5%"
        },
        {
            dataNode: "is_score",
            width: "5%"
        },
        {
            dataNode: "inv_notes",
            width: "5%"
        },
        {
            dataNode: "notes",
            width: "5%"
        },

    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    buttonFields: [{ column: "id", icon: "pen-square" }]
};


export const BusinessTabList = [
    {
        label: "Details",
        value: 1
    },
    {
        label: "Deal",
        value: 2
    }
]
export const ProducerContacts = {
    "show_season_producers": [],
    "publicist_name": null,
    "publicist_email": null,
    "marketing_contact_name": null,
    "marketing_contact_email": null,
}
export const ContactItem = {
    "producer_name": null,
    "producer_email": null,
    "is_primary": 0,
    "season_id": 1,
    "show_id": 1
}
export const BudgetingSetupConfig = {
    headings: [
        {
            headingLabel: "Episode",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "SAP Code",
            inputType: "text",
            border: true,
            width: "15%",
        },
        {
            headingLabel: "Music Supervisor",
            inputType: "text",
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Show Composer",
            inputType: "text",
            border: true,
            width: "15%"
        },
    ],
    dataNodes: ["episode_number", "code", "ms_budget_cost", "sc_budget_cost"],
    inlineEdits: [
        // {
        //     dataNode: "code",
        //     width: "15%",
        // },
        {
            dataNode: "ms_budget_cost",
            width: "15%",
            inlineIcon: "$",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "sc_budget_cost",
            width: "15%",
            inlineIcon: "$",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        }
    ]
}