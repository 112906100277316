import React from "react";
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import { MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import Radio from "@material-ui/core/Radio";
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import "./AcquireOptionMainTitle.scss";
import { initialAcquireMainTitleRecord } from "./config";
import moment from 'moment'

export default withUserContext(class AcquireOptionMainTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingAcquireOptionStatus: false,
            postInitiated: false,
            isPosting: false,
            acquireDetails: { ...initialAcquireMainTitleRecord },
            isPosting:false,
            openEmailModal:false
        };
    }
    componentDidMount() {
        let data = {...this.props?.selectedOptionalRight}
        data.acquire_for_option = "2"
        data.option_acquired_date = data?.option_acquired_date ? moment(data?.option_acquired_date)?.format("YYYY-MM-DD"):moment(new Date())?.format("YYYY-MM-DD")
        this.setState({acquireDetails:data})
    }
    handleChange = (field, value) => {
        this.setState((prevState) => ({
            acquireDetails: {
                ...prevState.acquireDetails,
                [field]: value,
            },
        }));
    }



    onSave = () => {
        this.setState({postInitiated: true,})
        if (this.props?.selectedOptionalRight?.option_acquired_date ? true : this.state.acquireDetails?.option_acquired_date) {
            this.setState({ postInitiated: false,isPosting: true })
            let acquireD = {...this.state.acquireDetails}
            acquireD.cl_mt_licensor_optional_rights_id = this.props?.selectedOptionalRight?.main_title_licensor_optional_rights_id;
            acquireD.song_id = this.props?.song_id
            acquireD["opening_from_mt"] = !this.props.isMainTitle ? 0 : 1
            delete acquireD.main_title_licensor_optional_check_requests
            let endPointUrl = '/acquireOption'
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + endPointUrl, acquireD, this.props.userContext?.active_tenant?.tenant_id, this.props?.divisionId)
                .then((response) => {
                    if (!response.data.error) {
                        this.setState({ isPosting: false })
                        this.props?.notificationComponent(true, "success")
                        console.log("post response", response);
                        this.props?.toggleRefreshAcquisition(this.state.acquireDetails?.option_acquired_date)
                        if (!this.props?.isEditing) {
                                    this.props?.toggleRefreshPage('EpisodeClearance')
                            }
                    }
                    else {
                        this.setState({ isPosting: false })
                        this.props?.notificationComponent(true, "fail")
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false })
                        this.notificationComponent(true, "fail")
                        console.log("Post agency details error: " + err);
                    });
        } else {
            this.setState({ isPosting: false })
        }
    }
    render() {
        return (
            <>
                <div className="AcquireOptionMainTitle">
                    <MDBRow>
                        <MDBCol md={3}>
                            <BasicLabel text="Option Acquisition Date:" />
                        </MDBCol>
                        <MDBCol className="dateSelect">
                            <MDBCol md={5} className="dateField">
                                <DateField
                                    id="Acquisition_date"
                                    isMandatory={true}
                                    // label={"Option Acquisition Date"}
                                    showMandatory={this.props?.selectedOptionalRight?.option_acquired_date ? false : this.state.postInitiated}
                                    value={this.state.acquireDetails?.option_acquired_date || null}
                                    onChange={(e) => { this.handleChange('option_acquired_date', e.target.value.length > 0 ? e.target.value : null) }}
                                />                            </MDBCol>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={1}></MDBCol>
                        {this.props?.selectedOptionalRight?.option_acquired_date && <MDBCol>
                            <BasicLabel text="(Blank out the date to un-acquire option)" />
                        </MDBCol>}
                    </MDBRow>
                    <MDBRow className="line">
                        <MDBCol md={4}>
                            <hr className="hr" />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBRow>
                            <MDBCol md={1}>
                                <Radio
                                    name="radio-buttons"
                                    id="Acquire For 1"
                                    checked={this.state.acquireDetails.acquire_for_option === "1"}
                                    onChange={(e) => this.handleChange('acquire_for_option', e.target.value)}
                                    value={"1"}
                                />
                            </MDBCol>
                            <MDBCol className="label">
                                <BasicLabel text="For this licensor and this song only" />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={1}>
                                <Radio
                                    name="radio-buttons"
                                    id="Acquire For 2"
                                    checked={this.state.acquireDetails.acquire_for_option === "2"}
                                    onChange={(e) => this.handleChange('acquire_for_option', e.target.value)}
                                    value={"2"}
                                />
                            </MDBCol>
                            <MDBCol className="label">
                                <BasicLabel text="For all licensors, this song only" />
                            </MDBCol>
                        </MDBRow>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}></MDBCol>
                        <MDBCol md={4}>
                            <MDBRow>
                                <MDBCol md={5}>
                                    <BasicButton onClick={() => this.setState({postInitiated:true}, () => {
                                        if(this.props?.selectedOptionalRight?.option_acquired_date ? true : this.state.acquireDetails?.option_acquired_date) {
                                            if((this.props?.selectedOptionalRight?.option_acquired_date && !this.state.acquireDetails?.option_acquired_date)) {
                                                this.onSave()
                                            } else {
                                                this.setState({postInitiated:false, openEmailModal:true})
                                            }
                                        }
                                    })} text={this.state.isPosting ? <CircularProgress color="inherit" size={18}/>:"Save"}
                                    disabled={this.props?.viewAcquireOptionRights?.edit == 1 ? false : true}
                                     />
                                </MDBCol>
                                <MDBCol>
                                    <BasicButton onClick={() => this.props?.handleClose()} variant="outlined" text={"Cancel"} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div>
                {
          this.state.openEmailModal &&
          <MainViewModalComponent
            open={this.state.openEmailModal}
            handleClose={() => this.setState({ openEmailModal: false})}
            headerText={"Send Option Acquisition Email"}
            handleSubmit={(emailInfo) => {
              let acquireInfo = {...this.state.acquireDetails}
              acquireInfo.subject = emailInfo?.sub
              acquireInfo.to_address = emailInfo?.toMails
              acquireInfo.cc_address = emailInfo?.ccMails
              acquireInfo.message = emailInfo?.body
              acquireInfo.has_attachment = false
              acquireInfo.is_draft = false
              acquireInfo.attachments = null
              this.setState({acquireDetails:acquireInfo,openEmailModal:false}, this.onSave)
            }}
            mode={""}
            subject={'Main title Option Pickup Notification'}
            body={`The MAIN TITLE ${this.state.acquireDetails?.right_abbr} option has been acquired for the season ${this.props?.seasonName}  of ${this.props?.showName}`}
            modalName={"Acquire Option Email"}
            modalClass=''
            isMainTitle={this.props?.isMainTitle}
          />
        }
            </>
        );
    }
}
);