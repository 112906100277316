export const costAllocationList = [
    {
        label: "Initial Rights",
        value: 1
    },
    {
        label: "Home Video Option",
        value: 2
    }
]
export const homeVideoOptionConfig = {
    headings: [
        {
            headingLabel: "Term",
            selectOptions: [],
            inputType: "select",
            icon: "",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Cost Center",
            selectOptions: [],
            inputType: "select",
            icon: "",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "std %",
            inputType: "text",
            icon: "",
            border: true,
            width: "20%"
        }
    ],

    dataNodes: ["term_id", "cost_center_id", "standard_pct"],
    primaryKey: "ca_hv_option_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "term_id",
            width: "24%",

        },
        {
            dataNode: "cost_center_id",
            width: "24%",

        },
        {
            dataNode: "standard_pct",
            width: "20%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
    ]
}
export const initialRightsConfig = {
    headings: [
        {
            headingLabel: "Term",
            selectOptions: [],
            inputType: "select",
            icon: "",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "Incl HV",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Multiplier",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            icon: "",
            border: true,
            width: "40%"
        }
    ],

    dataNodes: ["term_id", "include_HV", "multiplier", "description",],
    primaryKey: "ca_header_id",
    actions: ["pen", "times"],
    nodeFields: [
        {
            column: "include_HV",
            icon: "check"
        }
    ],
    inlineEdits: [
        {
            dataNode: "term_id",
            width: "29%",
        },
        {
            dataNode: "include_HV",
            width: "4%"
        },
        {
            dataNode: "multiplier",
            width: "9%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter decimal numbers'
        },
        {
            dataNode: "description",
            width: "38%"
        },
    ]
}
export const initialRightsListConfig = [
    {
        "ca_header_id": null,
        "term_id": null,
        "include_HV": null,
        "multiplier": null,
        "description": null,
        "is_active": null,
        "is_delete": null
    }
]
export const homeVideoOptionList = [
    {
        "ca_hv_option_id": null,
        "cost_center_id": null,
        "term_id": null,
        "standard_pct": 0,
        "is_active": null,
        "is_delete": 0
    }
]
export const initialRightsCostcenterConfig = {
    headings: [
        {
            headingLabel: "Cost Center",
            selectOptions: [],
            inputType: "select",
            icon: "",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Std %",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Lib %",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Ad Card %",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "MT %",
            inputType: "text",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Incl Multiplier for Standard?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Incl Multiplier for Library?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Incl Multiplier for Ad Card?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Incl Multiplier for MT?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "8%"
        }
    ],

    dataNodes: ["cost_center_id", "standard_pct", "library_pct", "ad_card_pct", "MT_pct", "is_standard_use_multiplier", "is_library_use_multiplier", "is_ad_card_use_multiplier", "is_MT_use_multiplier",],
    primaryKey: "ca_detail_id",
    nodeFields: [
        {
            column: "is_standard_use_multiplier",
            icon: "check"
        },
        {
            column: "is_library_use_multiplier",
            icon: "check"
        },
        {
            column: "is_ad_card_use_multiplier",
            icon: "check"
        },
        {
            column: "is_MT_use_multiplier",
            icon: "check"
        },
    ],
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "cost_center_id",
            width: "19%",
        },
        {
            dataNode: "standard_pct",
            width: "9%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "library_pct",
            width: "9%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "ad_card_pct",
            width: "9%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "MT_pct",
            width: "9%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers'
        },
        {
            dataNode: "is_standard_use_multiplier",
            width: "8.5%",
        },{
            dataNode: "is_library_use_multiplier",
            width: "8.5%",
        },{
            dataNode: "is_ad_card_use_multiplier",
            width: "8.5%",
        },
        {
            dataNode: "is_MT_use_multiplier",
            width: "8.5%"
        },
    ]
}