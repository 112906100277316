import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import "../MusicEditorDetails.scss";
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicCheckbox from "../../../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import SearchSelectField from '../../../SharedComponents/SearchSelectField';
import { addShowRecordData } from '../config';
import { withUserContext } from '../../../../contexts/UserContext';
import { CircularProgress } from "@material-ui/core";
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import clearTrackService from '../../../../services/service';
import * as Constants from '../../../../constants/constants';
import messages from '../../../../Common/Messages.json';
class AddShowComposer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addShowDetails: addShowRecordData,
            nameOptions: [],
            isLoading: false,
            postInitiated: false,
            nameSelected: false,
            peopleDetailsData: this.props.peopleDetailsData
        }
    }
    handleCheckboxChange = (field, value) => {
        console.log("field", field, "value", value)
        this.setState(prevState => ({
            addShowDetails: {
                ...prevState.addShowDetails,
                [field]: value ? 1 : 0
            }
        }))
    }
    handleChange = (objPassed) => {
        if (objPassed) {
            let selected_obj = this.state.nameOptions.filter(item => item.value === objPassed?.value);
            this.setState(prevState => ({
                addShowDetails: {
                    ...prevState.addShowDetails,
                    ["people_id"]: objPassed.value,
                    ["company"]: selected_obj[0].company,
                    ["agent_full_name"]: selected_obj[0].agent_full_name,
                    ["full_name"]: selected_obj[0].text,
                    ["user_id"]: selected_obj[0].user_id
                }, nameSelected: true
            }))
        }
        else {
            this.setState(prevState => ({
                addShowDetails: {
                    ...prevState.addShowDetails,
                    ["people_id"]: null,
                    ["company"]: null,
                    ["agent_full_name"]: null,
                    ["full_name"]: null,
                    ["user_id"]: null
                }, nameSelected: false,
                postInitiated: false
            }))
        }

    }
    handleSubmit = () => {
        this.setState({ postInitiated: true, isPosting: true })
        let composerAndSupervisorDetails = { ...this.state.addShowDetails }
        composerAndSupervisorDetails.show_season_id = this.props?.show_season_id;
        composerAndSupervisorDetails.show_id = this.props?.show_id;
        composerAndSupervisorDetails.season_id = this.props?.season_id;
        if (this.props?.modeType === "Music Supervisor") {
            composerAndSupervisorDetails.people_type = "MUSIC_SUPERVISOR";
        }
        if (this.props?.modeType === "Show Composer") {
            composerAndSupervisorDetails.people_type = "SHOW_COMPOSER";
        }
        if (this.props?.modeType === "Music Editor") {
            composerAndSupervisorDetails.people_type = "MUSIC_EDITOR";
        }
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/people`,
            composerAndSupervisorDetails,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isPosting: false });
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({ isPosting: false });
                    this.props.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.props.show_season_id}`);
                    this.props?.notificationComponent(true, "success");
                }
            },
            (err) => {
                this.setState({ postInitiated: false, isPosting: false });
                this.props?.notificationComponent(true, "fail");
                console.log("Post details error: " + err);
            }
        );
    };
    fetchSearchDetails = (event) => {
        let entity = null;
        switch (this.props.modeType) {
            case 'Show Composer':
                entity = "SHOW_COMPOSER";
                break;
            case 'Music Supervisor':
                entity = "MUSIC_SUPERVISOR";
                break;
            case 'Music Editor':
                entity = "MUSIC_EDITOR";
                break;
        }
        if (event.target?.value) {
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
                `/entitySearch?entity=${entity}&searchString=${event.target.value}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
                .then(response => {
                    let allexistingPeopleId = this.state.peopleDetailsData.map(item => item.people_id);
                    let formattedList = response.data.map(item => ({ value: item.people_id, text: item.full_name, is_active: item.is_active, company: item.company, agent_full_name: item.agent_full_name, user_id: item.user_id }));
                    let newFormattedList = formattedList.filter(item => !allexistingPeopleId.includes(item.value));
                    this.setState({ nameOptions: newFormattedList })
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err)
                    })
        } else {
            this.setState({ nameOptions: [] });
        }
    }
    componentDidMount() {
        this.setState({ nameSelected: false });
    }
    render() {
        return (
            <MDBContainer fluid className="ComposerContainer addShowComposer">
                <MDBRow>
                    <SearchSelectField
                        id={"name"}
                        label={"Name"}
                        isMandatory={true}
                        showMandatory={this.state.postInitiated || false}
                        mandatoryWarning={messages.mandatoryWarning}
                        placeholder={`- Search ${this.props.modeType} by Name -`}
                        options={this.state.nameOptions || []}
                        width={'100%'}
                        detail_selected={this.state?.addShowDetails?.people_id ? {
                            value: this.state?.addShowDetails?.people_id,
                            text: this.state?.addShowDetails?.full_name
                        } : null}
                        valueSelected={(e, newValue) => this.handleChange(newValue)}
                        searchText={this.fetchSearchDetails}
                        multiple={false}
                        searchSelect={true}
                    />
                </MDBRow>
                {this.state.nameSelected && <>
                    <MDBRow>
                        <BasicTextField
                            id="Company"
                            label={"Company"}
                            value={this.state.addShowDetails.company}
                            showAsLabel={true}
                        />
                    </MDBRow>
                    <MDBRow>
                        <BasicTextField
                            id="Agent"
                            label={"Agent"}
                            value={this.state.addShowDetails.agent_full_name}
                            showAsLabel={true}
                        />
                    </MDBRow>
                </>}
                {/* {this.props.origin === "BusinessAffairs" &&
                    <MDBRow>
                        <BasicCheckbox label={"Create Payee"}
                            id={"create_payee"}
                            checked={this.state.addShowDetails?.create_payee_flag}
                            onChange={(e) => this.handleCheckboxChange("create_payee_flag", e.target.checked)}
                        />
                    </MDBRow>
                } */}
                <div className="saveCancelRow">
                    <BasicButton
                        className="basic-btn"
                        type="inline"
                        variant="contained"
                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Add"}
                        onClick={this.handleSubmit}
                    />
                    <BasicButton
                        variant="outlined"
                        type="inline"
                        text={"Cancel"}
                        onClick={this.props?.handleClose}
                    />
                </div>
            </MDBContainer>
        )
    }
}
export default withUserContext(AddShowComposer);