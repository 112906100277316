import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import { initialScheduleDetails, dayOptions } from "./config";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import DateField from "../../../components/SharedComponents/DateField/DateField"
import "./BudgetEFCReport.scss";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import TimePicker from 'rc-time-picker';
import moment from "moment";
import { getFormattedDate } from "../../../Common/Helper";
import CircularProgress from "@material-ui/core/CircularProgress";

class BudgetEFCReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productionGroupOptions: [],
      scheduleDetails: {...initialScheduleDetails},
      userOptions: [],
      dayOptions: dayOptions,
      isPosting: false,
      isLoading: false,
      isUserFetching:false,
    };
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getProductionGroupDetails = () => {
    this.setState({ isLoading: true})
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      '/staticData?staticTable=lu_production_group', this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
        let filteredList = formattedList.filter(item => item.label === "Warner Bros." || item.label === "Warner Horizon")
        this.setState({ productionGroupOptions: filteredList }, () => {
          let initialProductionId = filteredList.filter(item => item.label === "Warner Bros.")[0]?.value
          this.setState(prevState => ({
            scheduleDetails: {
              ...prevState.scheduleDetails,
              ["production_group_id"]: initialProductionId ? initialProductionId : filteredList[0]?.value
            },
          }), () => {
            this.getReportDetails(initialProductionId ? initialProductionId : filteredList[0]?.value)
          })
        })
      })
  }

  getUserDetails = () => {
    this.setState({isUserFetching : true})
    let allUsers  = []
    clearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + '/userAll', this.props.userContext?.active_tenant?.tenant_id, null).then((response) => {
        let validRoles = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Regular User)']
        let validReportUsers = []
        allUsers = [...response?.data]
        for (let i = 0; i < response?.data?.length; i++) {
          for (let j = 0; j < response?.data[i]?.tenants?.length; j++) {
            if (response?.data[i]?.tenants[j]?.tenant_id === this.props.userContext?.active_tenant?.tenant_id) {
              for (let k = 0; k < response?.data[i]?.tenants[j]?.division_role?.length; k++) {
                  if (validRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                    validReportUsers.push({ value: response?.data[i].user_id, label: response?.data[i].full_name, is_active: response?.data[i].is_active })
                  }
              }
            }
          }
        }
        let uniqueArr = validReportUsers.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.value === value.value
            ))
            )
        this.setState({ userOptions: uniqueArr, isUserFetching:false},this.getProductionGroupDetails);
      });
  };

  getReportDetails = (id) => {
    clearTrackService.getData(`${Constants.ClearTrackServiceBaseUrl}/budgetVsEfcSchedule?production_group_id=${id}`, this.props.userContext?.active_tenant?.tenant_id, 1)
      .then((response) => {
        if (response.data?.length !== 0) {
          let results = response.data?.map(item => {
            let time = moment(item.schedule_time, "HH:mm:ss");
            item.schedule_time = time;
            item.schedule_day_id = this.state.dayOptions.filter(items => items?.label === item?.schedule_day)[0]?.value
            return item;
          });
          this.setState({ scheduleDetails: results[0] , isLoading: false})
        } else {
          let day = this.state.dayOptions?.filter(item => item?.value === 6)[0]?.label
          let user_id = this.state.userOptions?.filter(item => item?.label === this.props.userContext?.user_profile?.full_name)[0]?.value
          var to_day = new Date();
          let time = to_day.getHours() + ':' + to_day.getMinutes() + ':' + to_day.getSeconds();
          let current_time = moment(time, "HH:mm:ss")
          let scheduleDetails = {...initialScheduleDetails}
          scheduleDetails.frequency = 7,
          scheduleDetails.schedule_day_id = 6
          scheduleDetails.schedule_day = day
          scheduleDetails.notified_user_id = user_id ? user_id : this.state.userOptions[0]?.value,
          scheduleDetails.schedule_time = current_time
          scheduleDetails.production_group_id = id
          this.setState({scheduleDetails:scheduleDetails, isLoading: false})
        }
      }, (err) => {
        console.log("error in fetching details", err)
      })
  }

  generateCleranceDetails = (field, value) => {
    this.setState(prevState => ({
      scheduleDetails: {
        ...prevState.scheduleDetails,
        [field]: value
      },
    }), () => {
      if (field === "production_group_id") {
        this.getReportDetails(value)
      } else if (field === "schedule_day_id") {
        let day = this.state.dayOptions.filter(item => item.value === value)[0].label
        this.setState(prevState => ({
          scheduleDetails: {
            ...prevState.scheduleDetails,
            ["schedule_day"]: day
          }
        }))
      }
    })
  };

  handleTime = (time) => {
    return moment(time).format("HH:mm:ss");
  }
  handleSubmit = () => {
    this.setState({ isPosting: true })
    let { scheduleDetails } = this.state
    // if(!scheduleDetails?.notified_user_id){
    //    return;
    // }
    let time = scheduleDetails?.schedule_time;
    let schedule_time = undefined;
    if (time) {
      schedule_time = this.handleTime(time)
    }
    clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/budgetVsEfcSchedule`,
      { ...scheduleDetails, schedule_time },
      this.props?.userContext?.active_tenant?.tenant_id, 1)
      .then(
        (response) => {
          this.setState({ isPosting: false })
          this.props?.notificationComponent(true, 'success')
        },
        (err) => {
          this.props?.notificationComponent(true, 'fail')
          this.setState({ isPosting: false })
        }
      )
  };

  render() {
    return (
      <MDBContainer className="BudgetVSEFC-Container">
        {this.state.isLoading || this.state.isUserFetching? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <MDBRow>
            <MDBRow className="mt-2 mainRow-one">
              <MDBCol md={5}>
                <BasicLabel text="Schedule For" />
                <SelectField
                  placeHolderText={"-Select-"}
                  value={this.state.scheduleDetails?.production_group_id}
                  options={this.state.productionGroupOptions}
                  onChange={(e) => this.generateCleranceDetails('production_group_id', e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={2} className="secRow-column">
                <MDBRow>
                  <MDBCol md={6} className="secRow-frstColumn">
                    <BasicLabel text="Every" />
                  </MDBCol>
                  <MDBCol md={6} className="secRow-secColumn">
                    <BasicTextField
                      // placeholder="7"
                      id="additionalMaterial"
                      isMandatory={true}
                      value={this.state.scheduleDetails?.frequency}
                      onChange={(e) => this.generateCleranceDetails('frequency', e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>

              <MDBCol md={3}>
                <MDBRow>
                  <MDBCol md={4} className="secRow-daysOn">
                    <BasicLabel text="days on" />
                  </MDBCol>
                  <MDBCol md={8} className="secRow-daysOnField">
                    <SelectField
                      placeHolderText={"-Select-"}
                      options={this.state.dayOptions}
                      value={this.state.scheduleDetails?.schedule_day_id}
                      onChange={(e) => this.generateCleranceDetails('schedule_day_id', e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md={6}>
                <MDBRow>
                  <MDBCol md={4} className="secRow-thirdColumn">
                    <BasicLabel text="at the time of" />
                  </MDBCol>
                  <MDBCol md={4} className="secRow-fourthColumn">
                    <TimePicker
                      className={"timePickerSchedule"}
                      value={this.state.scheduleDetails?.schedule_time || undefined}
                      onChange={(e) => this.generateCleranceDetails("schedule_time", e)}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-2">
              <MDBCol md={12}>
                <MDBRow>
                  <MDBCol md={1} className="thirdRow-frstColumn">
                    <BasicLabel text="the user" />
                  </MDBCol>
                  <MDBCol md={4}>
                    <SelectField
                      placeHolderText={"-user-"}
                      options={this.state.userOptions}
                      value={this.state.scheduleDetails?.notified_user_id}
                      onChange={(e) => this.generateCleranceDetails('notified_user_id', e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol md={4}>
                    <BasicLabel text="will be notified to send the emails." />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-2">
              <MDBCol md={12}>
                <MDBRow>
                  <MDBCol md={3} className="fourthRow-frstColumn">
                    <BasicLabel text="Last email was sent on" />
                  </MDBCol>
                  <MDBCol md={4} className="fourthRow-secColumn">
                    <DateField
                      id="Date"
                      value={getFormattedDate(this.state.scheduleDetails?.last_email_sent, true) || ""}
                      onChange={(e) => this.generateCleranceDetails('last_email_sent', e.target.value.length > 0 ? e.target.value : null)}
                    />
                  </MDBCol>

                </MDBRow>

              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4">
              <MDBCol md={8}></MDBCol>
              <MDBCol md={4}>
                <MDBRow>
                  <MDBCol>
                    <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"} onClick={this.handleSubmit} />
                  </MDBCol>
                  <MDBCol>
                    <BasicButton
                      onClick={() => this.props.handleClose()}
                      variant="outlined"
                      text={"Cancel"}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBRow>
        )}
      </MDBContainer>
    );
  }
}
export default withUserContext(BudgetEFCReport);
