import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TableComponent from "../../SharedComponents/Table";
import ClearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { validateNumbersOnly } from '../../../Common/Helper'
import { withUserContext } from '../../../contexts/UserContext';
import { updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import { homeVideoOptionConfig, homeVideoOptionList } from "./config";
import axios from 'axios';
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent";


class HomeVideoOptionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: homeVideoOptionList,
      isLoading: false,
      homeVideoOptionConfig: homeVideoOptionConfig,
      mandatoryNotExist: false,
      homeVideoOptionListData: [],
      isDataSave: false,
      mandatoryNotExist: false,
      sortBy: null,
      isSortByAsc: true,
      sortCount: 0,
    };
  }
  componentDidMount() {
    this.getEntity('costCenters', 'COST_CENTER', '');
    this.getEntity('terms', 'TERM', '');
    this.getHomeVideoTableData()
  }
  getHomeVideoTableData = () => {

    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=COST_ALLOCATION_HV_OPTIONS&searchString=null`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((response) => {
      let formattedList = response.data;
      this.setState({ renderList: formattedList, isDataSave: false, isLoading: false ,sortBy: null,
        isSortByAsc: true, sortCount: 0,}, () => {this.handleTableColumnClick("Term")})
    })
      .catch((err) => {
        this.setState({ isLoading: false, isDataSave: false });
      });
  }
  getEntity = (entity, entityType, searchString) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${entityType}&searchString=${searchString}`, cancelToken,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        console.log("res----", response)
        if (entityType === "COST_CENTER") {
          let costCenters = response?.data?.map(item => ({
            label: item.cost_center_name,
            value: item.cost_center_id,
          }));
          this.setState({ [entity]: costCenters })
          console.log("check---", costCenters)
          let costCenterConfig = { ...this.state.homeVideoOptionConfig }
          costCenterConfig.headings[1].selectOptions = costCenters
          this.props?.permission?.edit === 1 ? null : costCenterConfig.actions = [ ]
          this.setState({ homeVideoOptionConfig: costCenterConfig })
        } else if (entityType === "TERM") {
          let terms = response?.data?.map(item => ({
            label: item.term_name,
            value: item.term_id,
          }));
          this.setState({ [entity]: terms })
          let termsConfig = { ...this.state.homeVideoOptionConfig }
          termsConfig.headings[0].selectOptions = terms
          this.props?.permission?.edit === 1 ? null : termsConfig.actions = [ ]
          this.setState({ homeVideoOptionConfig: termsConfig })
        }
      })
  }
  postHomeVideoDetails = (homeVideoOptionList, postFlag) => {
    this.setState({ isDataSave: postFlag, mandatoryNotExist: false })
    ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/costAllocationHVOptions`,
      homeVideoOptionList,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(
        (response) => {
          if (response.data.error) {
            this.setState({ isDataSave: false })
            this.notificationComponent(true, "fail");
          } else {
            if (homeVideoOptionList?.editing) {
              homeVideoOptionList.editing = false;
            }
            this.notificationComponent(true, "success")
            this.getHomeVideoTableData();

          }
          
        },
        (err) => {
          this.setState({ isDataSave: false });
          this.notificationComponent(true, "fail");
        }
      )
  }
  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.homeVideoOptionConfig, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: tableSortList(colName === "std %" ? "Number" : "Name", this.getSortNode(colName), this.state?.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
        case "Term": return "term_id";
        case "Cost Center": return "cost_center_id";
        case "std %": return "standard_pct";
    }
  }
  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success": return this.setState({ showSuccessMessage: value });
      case "fail": return this.setState({ showFailMessage: value });
    }
  }
  handleResetNotify = () => {
    this.setState({ showFailMessage: false, showSuccessMessage: false });
    if (this.state.saveFlag) {
      this.closeToastMessage();
    }
  }
  closeToastMessage = () => {
    this.setState({ showErrorMessage: false, showFailMessage: false, showSuccessMessage: false });

  }
  handleInlineEdits = (id, dataItem,index) => {
    let homeVideoList = [...this.state.renderList];
    homeVideoList[index]['editing'] = true
    this.setState({
      mandatoryNotExist: false,
      renderList:homeVideoList
    });
  };
  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ mandatoryNotExist: false })
    if (data[index]?.term_id && validateNumbersOnly(data[index]?.standard_pct) && data[index]?.cost_center_id) {
      let homeVideoList = [...data];
      let homeVideoOptionList = [...this.state.renderList];
      homeVideoOptionList[index].ca_hv_option_id = homeVideoList[index]?.ca_hv_option_id || null;
      homeVideoOptionList[index].term_id = homeVideoList[index]?.term_id || null;
      homeVideoOptionList[index].cost_center_id = homeVideoList[index]?.cost_center_id || null;
      homeVideoOptionList[index].standard_pct = homeVideoList[index]?.standard_pct || 0;
      this.setState({ homeVideoList: homeVideoOptionList });
      this.postHomeVideoDetails(homeVideoOptionList[index], homeVideoOptionList[index]?.ca_hv_option_id);
    } else {
      this.setState({ mandatoryNotExist: true })
    }
  };
  handleAddNewItem = (newDataItem) => {
    this.setState({ mandatoryNotExist: false })
    if (newDataItem?.term_id && validateNumbersOnly(newDataItem?.standard_pct) && newDataItem?.cost_center_id) {
      let newData = {
        term_id: newDataItem?.term_id,
        cost_center_id: newDataItem?.cost_center_id || null,
        standard_pct: newDataItem?.standard_pct || 0
      };
      this.state.renderList.push(newData)
      this.postHomeVideoDetails(newData, "newItemPost")
    } else {
      this.setState({ mandatoryNotExist: true })
    }
  };
  deleteHomeVideoOptionItem = (data) => {
    let removeNewData = {
      ca_hv_option_id:data?.ca_hv_option_id,
      cost_center_name:data?.cost_center_name,
      term_id: data?.term_id || null,
      cost_center_id: data?.cost_center_id || null,
      tenant_id:data?.tenant_id,
      term_name:data?.term_name,
      standard_pct: data?.standard_pct ,
      is_delete:1
    };
    
     this.postHomeVideoDetails(removeNewData,data?.ca_hv_option_id)
  }

  render() {
    let severity = this.state.showFailMessage ? "error" : this.state.showSuccessMessage ? "success" : "";
    let messageNotification = this.state.showSuccessMessage ? "Saved Changes Successfully" : this.state.showFailMessage ? "Saved Changes Failed" : "";
    //success modal popup
    let message = this.state.showFail ? this.state.failMessage :
      this.state.showSuccess ? this.state.saveEditMessage : null
    return (
      <MDBContainer fluid className="homeVideoContainer">
        <div>
          <NotificationComponent open={this.state.showSuccessMessage || this.state.showFailMessage} message={messageNotification} severity={severity}
            handleResetNotify={this.handleResetNotify.bind(this)} />
        </div>
        <MDBRow className="pd">
          <span className="errorText">{this.state.mandatoryNotExist && `Please enter terms, cost center and std field's value`}</span>
        </MDBRow>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          (this.state.homeVideoOptionConfig?.headings[0]?.selectOptions?.length > 0 && this.state.homeVideoOptionConfig?.headings[1]?.selectOptions?.length > 0) && <MDBRow className="homeVideoTable">
            <TableComponent
              list={this.state.renderList || []}
              config={this.state.homeVideoOptionConfig}
              addItemToList={this.props?.permission?.edit === 1}
              notificationComponent={this.notificationComponent}
              handleAddNewItem={this.handleAddNewItem.bind(this)}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              postInitiated={this.state.isDataSave}
              stickyHeader={true}
              readCallback={(index) => {
                let homeVideoList = [...this.state.renderList];
                homeVideoList[index].editing = false
                this.setState({ renderList: homeVideoList, mandatoryNotExist: false });
              }}
              handleDelCallBack={(id, item) =>
                this.deleteHomeVideoOptionItem(item)
              }
              sortCallback={this.handleTableColumnClick}
            />
          </MDBRow>)}
      </MDBContainer>
    );
  }
}

export default withUserContext(HomeVideoOptionTable);
