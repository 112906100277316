import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import { withUserContext } from '../../../contexts/UserContext';
import Radio from "@material-ui/core/Radio";
import './EFCModal.scss';

class EFCModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEFCValue: "Open_existing"
        }
    }
    render() {
        return (
            <MDBContainer fluid className="EFCModalContainer">
                <MDBRow>
                    <MDBCol md={6}>
                        <Radio name="radio-buttons" checked={this.state.selectedEFCValue === 'Open_existing'}
                            onChange={this.handleRadioChange}
                            value="Open_existing" />
                        <span className='content-font'>Open Existing</span>
                    </MDBCol>
                    <MDBCol md={6}>
                        <Radio name="radio-buttons" checked={this.state.selectedEFCValue === 'Overwrite'}
                            onChange={this.handleRadioChange}
                            value="Overwrite" />
                        <span className='content-font'>Overwrite</span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton text={"Confirm"} />
                    </MDBCol>
                    <MDBCol md={2}><BasicButton text={"Cancel"} onClick={this.props?.handleClose} /></MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}
export default withUserContext(EFCModal);
