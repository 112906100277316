import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import SelectField from "../../../components/SharedComponents/SelectField/SelectField"
import './EditUnionTabModal.scss';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import { mockUnionsRecord } from './config';
import { CircularProgress } from "@material-ui/core";

class EditUnionTabModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            budgetTabModalDetails: this.props.initialRecordData?.unions,
            unionList: [],
            isLoading: false,
            isPosting: false,
            pilotType: null,
            seriesType: null
        }
    }
    componentDidMount() {
        //this.getBudgetTabDetails();
        this.fetchStaticData("lu_union", "unionList");
        //this.fetchData(`/showSeason?showSeasonId=${this.props.show_season_id}`);
        if (this.props.initialRecordData?.unions?.length === 0) {
            let emptyUnion = [{ "union_id": null, "union_name": null }];
            this.setState({ budgetTabModalDetails: emptyUnion });
        }
        if (this.props.initialRecordData?.unions?.length > 0) {
            this.setState({ budgetTabModalDetails: this.props.initialRecordData?.unions });
        }
    }


    fetchStaticData = (entity, node) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                formattedList.unshift({ label: "Select", value: null })
                this.setState({ unionList: formattedList })
                // this.getDistinctArray(this.state.unionList)
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }

    getDistinctArray = (arr) => {

        const distincitUserRoles = arr.filter((ele, index, arr) =>

            index === arr.findIndex((item) => (

                item.union_id == ele.union_id

            )))
        console.log("distincitUserRoles", distincitUserRoles)
        return distincitUserRoles

    }
    handleChange = (field, value, index) => {
        if (field === "union_id") {
            let selectedUnion = [], selectedDetails = [];
            selectedUnion = this.state.unionList.filter(item => item.value === value);
            let objToInsert = { union_id: selectedUnion[0].value, union_name: selectedUnion[0].label };
            selectedDetails = this.state.budgetTabModalDetails;
            selectedDetails[index] = objToInsert;
            this.setState({ budgetTabModalDetails: selectedDetails });
        }
    };

    removeCallback = (Index) => {
        let allDetails = [...this.state.budgetTabModalDetails];
        allDetails.splice(Index, 1)
        this.setState({ budgetTabModalDetails: allDetails })
    }

    addCallback = (Index) => {
        let allDetails = [...this.state.budgetTabModalDetails];
        allDetails.push(JSON.parse(JSON.stringify({ ...mockUnionsRecord })))
        this.setState({ budgetTabModalDetails: allDetails })
    }
    handleSubmit = () => {
        this.setState({ isPosting: true });
        let postObj = {};
        postObj.unions = [...this.state.budgetTabModalDetails];
        postObj.type = this.props.is_pilot === 1 ? "PILOT" : "SERIES";
        postObj.show_season_id = this.props.show_season_id;
        postObj.season_id = this.props.season_id;
        postObj.show_id = this.props.show_id;
        console.log("postObj", postObj);
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonUnion`,
            postObj,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
            .then(
                (response) => {
                    console.log("response", response)
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props?.notificationComponent(true, "fail");

                    } else {
                        this.setState({ isPosting: false });
                        this.props.getUnionList(`/showSeason?showSeasonId=${this.props.show_season_id}`);
                        this.props?.notificationComponent(true, "success");
                    }
                },
                (err) => {
                    this.setState({ isPosting: false, });
                    this.props?.notificationComponent(true, "fail")
                }
            )
    }

    render() {
        return (
            <>
                {this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                        <MDBContainer className="EditBudgetTabModal-container">
                            <>
                                <p className="informationText">The below Unions will apply to all budgets created for this Show Season.</p>
                                {this.state.budgetTabModalDetails?.map((item, index) => (
                                    <div className="union-field">
                                        <MDBCol md={7} className="pb-3">
                                            <SelectField
                                                id="Type"
                                                label={`${index === 0 ? "Season Union(s)" : ""}`}
                                                value={item.union_id || null}
                                                options={this.state.unionList || []}
                                                onChange={(e) => this.handleChange("union_id", e.target.value, index)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={1} className="icons-align">
                                            <div className="plus-icon">
                                                <MDBCol  disabled={!item?.union_id} >
                                                    {this.state.budgetTabModalDetails?.length - 1 === index ?
                                                        <MDBIcon icon="plus-circle" className={index === 0 ? "plus-height" : "float-left"} onClick={() => this.addCallback(index)} /> : ""
                                                    }
                                                </MDBCol>
                                                <MDBCol className="p2">
                                                    {this.state.budgetTabModalDetails?.length > 1 ?
                                                        <MDBIcon icon="minus-circle" className={index === 0 ? "plus-height " : "float-left"} onClick={() => this.removeCallback(index)} /> : ""
                                                    }
                                                </MDBCol>
                                            </div>
                                        </MDBCol>
                                    </div>
                                ))}
                                {this.getDistinctArray(this.state?.budgetTabModalDetails).length !== this.state?.budgetTabModalDetails.length ?
                                    <span className="errorText">This union already existed Please select other union</span> : null}
                                <MDBCol md={8}></MDBCol>
                            </>
                            <MDBRow>
                                <MDBCol md={8}></MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        className="basic-btn"
                                        type="inline"
                                        variant="contained"
                                        text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                        onClick={this.handleSubmit}
                                        disabled={this.getDistinctArray(this.state?.budgetTabModalDetails)?.length !== this.state?.budgetTabModalDetails?.length}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicButton
                                        variant="outlined"
                                        type="inline"
                                        text={"Cancel"}
                                        onClick={this.props?.handleClose}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer >
                    )}
            </>
        );
    }
}

export default withUserContext(EditUnionTabModal);
