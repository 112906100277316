import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from "../SharedComponents/Table";
import TablePagination from '@material-ui/core/TablePagination';
import { CircularProgress } from '@material-ui/core';
import './DashboardContainer.scss';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { withUserContext } from '../../contexts/UserContext';
export default withUserContext(class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditSongModal: false,
      selectedSong: null,
      showLicensorModal: false,
      selectedLicensor: null,
      openPayeeModal: false,
      ShowPersonModal: false,
      showShowComposer: false,
      selectedPersonDetail: {},
      openPersonModal:false
    }
  }

  navigationCallback = (obj, node) => {
    if(this.props?.userContext?.dashboardName === 'Cue Sheets' && this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === obj?.division_id)?.role_name ==='Music Editor') {
      window.open(`/cueSheet`, obj);
    } else if (node === "show_name" || node === "season_name" || node === "show_season_name") {
      window.open(obj.show_season_id ? `/showseason/${obj.show_season_id}` : `/showseason`, '_blank');
    } else if (node === "episode" || node === "episode_number") {
      this.setState({ show_season_episode_id: obj.show_season_episode_id})
      window.open(`/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`, '_blank');
    } else if (node === "song_title") {
      this.setState({ showEditSongModal: true, selectedSong: obj.song_id })
    } else if (node === "licensor_name") {
      this.setState({ showLicensorModal: true, selectedLicensor: obj.licensor_id })
    } else if (node === "payee_company") {
      this.setState({ openPayeeModal: true, selectedPayee: obj.payee_id })
    }  else if (this.props?.sectionName === "people" && node === "full_name") {
      this.setState({ openPersonModal: true, selectedPersonDetail: obj,showShowComposer:false })
    }else if (node === "full_name") {
      this.setState({ showShowComposer: true, selectedMusicSupervisor: obj })
    }
  }

  handleHidePersonModal = () => {
    this.setState({ ShowPersonModal: false });
  }

  handleHideShowEditSongModal = () => {
    this.setState({ showEditSongModal: false })
  }

  handleHideShowComposer = () => {
    this.setState({ showShowComposer: false });
}

  render() {
    return (
      <>
        <MDBRow>
          <MDBCol md={12} id="dashboardTable">
            <TableComponent
              list={this.props?.listData || []}
              config={this.props?.config}
              hyperLinkNavigationCallback={this.props?.sectionName === "songLibrary" && this.props?.songModalPermissions?.view === 1 ? this.navigationCallback : this.navigationCallback}
              isLoading={this.props?.isLoading || false}
              sortCallback={this.props?.handleSortColumn}
              stickyHeader={true}
              handleOpenPopUp={(event, dataItem) => this.props.handleShareClick(event, dataItem, this)}
            // filterList={this.props.filterList || []}
            //handleChipClose={this.props.handleChipClose}
            />
            <div className="Pagination">
              <TablePagination
                component="div"
                count={this.props?.currentList ? this.props?.currentList : 0}
                rowsPerPageOptions={[25, 50,75, 100]}
                page={this.props?.page - 1}
                onChangePage={this.props?.handleChangePage}
                rowsPerPage={this.props?.rowsPerPage}
                onChangeRowsPerPage={this.props?.handleChangeRowsPerPage}
              />
            </div>
          </MDBCol>
        </MDBRow>
        {
          this.state.showEditSongModal &&
          <MainViewModalComponent
            open={this.state.showEditSongModal}
            handleClose={this.handleHideShowEditSongModal}
            headerText={"Song"}
            mode={"Edit"}
            modalName={"Song"}
            songId={this.state.selectedSong}
            // isMainTitle={this.props?.isMainTitle}
            commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
            toggleRefreshPage={this.props?.toggleRefreshPage}
            division_id={this.props?.division_id}
            toggleRefreshMainTitleLicensor={this.props?.toggleRefreshMainTitleLicensor}
            handleFilterSearch = {this.props.handleFilterSearch}
          />
        }
        {
          this.state.showLicensorModal &&
          <MainViewModalComponent
            open={this.state.showLicensorModal}
            handleClose={() => this.setState({ showLicensorModal: false })}
            headerText={"Licensor"}
            mode={"Edit"}
            modalName={"Edit Licensor"}
            id={this.state.selectedLicensor}
            commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
          />
        }
        {
          this.state.openPayeeModal &&
          <MainViewModalComponent
            open={this.state.openPayeeModal}
            handleClose={() => this.setState({ openPayeeModal: null })}
            headerText={"Payee"}
            mode={"Edit"}
            modalName={"Payee"}
            id={this.state.selectedPayee}
            commonPermissions={this.props?.commonPermissions?.filter(item => item.permission_name === "Clearance Payees")}
          />
        }
        {this.state.openPersonModal && this.state.selectedPersonDetail && (
          <MainViewModalComponent
            open={this.state.openPersonModal}
            handleClose={()=>this.setState({openPersonModal:false})}
            headerText={`Person- ${this.state.selectedPersonDetail?.people_type === "SHOW_COMPOSER" && "Show Composer" || this.state.selectedPersonDetail?.people_type === "MUSIC_SUPERVISOR" && "Music Supervisor" || this.state.selectedPersonDetail?.people_type === "MUSIC_EDITOR" && "Music Editor"}`}
            mode={"Edit"}
            modeType={`${this.state.selectedPersonDetail?.people_type === "SHOW_COMPOSER" && "Show Composer" || this.state.selectedPersonDetail?.people_type === "MUSIC_SUPERVISOR" && "Music Supervisor" || this.state.selectedPersonDetail?.people_type === "MUSIC_EDITOR" && "Music Editor"}`}
            modalName={"Person"}
            show_id={null}
            season_id={null}
            people_id={this.state.selectedPersonDetail?.people_id}
            show_season_people_id={this.state.selectedPersonDetail?.show_season_people_id}
            division_id={this.props?.division_id}
            origin={"dashboard"}
            peopleModalPopupPermissions={this.props?.peopleModalPopupPermissions}
          />
        )}
        {this.state.ShowPersonModal && this.state.selectedMusicSupervisor && (
          <MainViewModalComponent
            open={this.state.ShowPersonModal}
            handleClose={this.handleHidePersonModal}
            headerText={`Person- ${this.state.selectedMusicSupervisor?.people_type}`}
            mode={"Edit"}
            modeType={this.state.selectedMusicSupervisor?.people_type}
            modalName={"Person"}
            // show_season_id={this.props?.show_season_id}
            // show_id={this.props?.show_id}
            // season_id={this.props?.season_id}
            people_id={this.state.selectedMusicSupervisor?.people_id}
            show_season_people_id={this.state.selectedMusicSupervisor?.show_season_people_id}
            division_id={this.props?.division_id}
            // fetchData={this.fetchData.bind(this)}
            origin={"profile"}
            peopleModalPopupPermissions={this.props?.peopleModalPopupPermissions}
          />
        )}
        {
          this.state.showShowComposer && this.state.selectedMusicSupervisor && (
            <MainViewModalComponent
              open={this.state.showShowComposer}
              handleClose={this.handleHideShowComposer}
              headerText={this.state.selectedMusicSupervisor?.people_type}
              mode={"Edit"}
              modeType={this.state.selectedMusicSupervisor?.people_type}
              modalName={this.state.selectedMusicSupervisor?.people_type === "SHOW_COMPOSER" ? "Show Composer" : "Music Supervisor"}
              // show_season_id={this.state.show_season_id}
              // show_id={this.props.show_id}
              // season_id={this.props.season_id}
              people_id={this.state.selectedMusicSupervisor?.people_id}
              show_season_people_id={this.state.selectedMusicSupervisor?.show_season_people_id}
              division_id={this.props?.division_id}
              // fetchData={this.fetchData.bind(this)}
              showSecondtab={true}
              origin={"dashboard"}
              commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
              // commonPermissions={this.props.permissionArr}
              // handleShareClick={(event, dataItem) => this.handleShareClick(event, dataItem, this)}
              peopleModalPopupPermissions={this.props?.peopleModalPopupPermissions}
            />
          )
        }
      </>
    )
  }
})