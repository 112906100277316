import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import {withUserContext} from '../../contexts/UserContext';
import { validateNumDotOnly } from '../../Common/Helper';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            termNotExist:false,
            isLoading:false,
            isSubmit:false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config: JSON.parse(JSON.stringify({ ...this.props?.config }))
        }
    }

    componentDidMount() {
        this.getTerms()
    }

    getTerms = (loader = true) => {
        this.setState({ isLoading: loader });
        ClearTrackService.getData(
          Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=TERM&searchString=null`,this.props?.userContext?.active_tenant?.tenant_id)
          .then((res) => {
            let configRecord = {...this.state.config}
            this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
            this.setState({renderList:res?.data, isLoading: false,isSubmit: false,  sortBy: null,
              isSortByAsc: true, config: configRecord,
              sortCount: 0,}, () => {this.handleTableColumnClick("Term Name")})
          })
          .catch((err) => {
            this.setState({ isLoading: false });
          });
      };

    handleInlineEdits = (id, dataItem) => {
        let terms = [...this.state?.renderList]
        this.setState({
            termNotExist:false,
            renderList: terms.map((item) => {
                let newItem = {...item}
                if (item.term_id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({termNotExist:false}) 
        if( validateNumDotOnly(data[index]?.duration) && data[index]?.duration?.length > 0 &&
            data[index]?.duration_type && data[index]?.duration_type?.length > 0){
            let terms = [...this.state?.renderList]
            terms[index].term_id = data[index].term_id || null,
            terms[index].term_name = `${data[index].duration} ${data[index].duration_type}`,
            terms[index].duration = data[index].duration || null,
            terms[index].duration_type = data[index].duration_type || null,
            terms[index].is_active = data[index].is_active?1:0
            this.setState({
                renderList: terms.map((item) => {
                    item['editing'] = false
                    return item
                })
            })
            this.handleSubmit(terms[index],terms[index]?.term_id);
        } else {
            this.setState({termNotExist : true})
        }
    }



    handleAddNewItem = (newDataItem) => {
        this.setState({termNotExist:false}) 
        if(validateNumDotOnly(newDataItem?.duration) && newDataItem?.duration?.length > 0 && 
            newDataItem?.duration_type && newDataItem?.duration_type?.length > 0){
            let addList = {
                term_id: null,
                term_name: `${newDataItem.duration} ${newDataItem.duration_type}`,
                duration: newDataItem.duration || null,
                duration_type: newDataItem.duration_type || null,
                is_active: 1
            }
            this.handleSubmit(addList, 'newItemPost');
        } else {
            this.setState({termNotExist : true})
        }
    }


    handleSubmit = (postdata,postFlag) => {
        this.setState({ isSubmit: postFlag });
        ClearTrackService.postDataParams(
          Constants.ClearTrackServiceBaseUrl + `/term`,
          postdata,
          this.props?.userContext?.active_tenant?.tenant_id
        ).then(
          (response) => {
            if (response.data.error) {
              this.setState({ isSubmit: false });
              this.props?.toastMessageNotification(true, "fail");
            } else {
              this.setState({isSubmit: false});
              this.props?.toastMessageNotification(true, "success");
              this.getTerms()  
            }
          },
          (err) => {
            this.setState({ isSubmit: false });
            this.props?.toastMessageNotification(true, "fail");
          }
        );
    };

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
          this.setState({ sortCount: this.state.sortCount + 1 }, () => {
            this.handleTableSort(column);
          })
        }
        else this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        })
    }
    
    handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            sortBy: colName,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
            renderList: tableSortList(colName === "Duration" ? "Number" : "Name", this.getSortNode(colName), this.state?.renderList, this.state.isSortByAsc)
          });
        })
    }
    
    getSortNode = (col) => {
        switch (col) {
            case "Term Name": return "term_name";
            case "Duration": return "duration";
            case "Duration Type": return "duration_type";
            case "Active": return "is_active";
        }
    }

    render() {
      let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="detailContainer">
                <MDBRow>
                    <span className="description">Selectable Terms for a Show/Season.</span>
                </MDBRow>
                <MDBRow className='pd'>
                <span className="errorText">{this.state.termNotExist && `Please enter  Duration and Duration type field's value`}</span>
                </MDBRow>
                {this.state?.isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBRow className="tableComponent">
                            <TableComponent
                                newIsActive={true}
                                list={this.state?.renderList || []}
                                config={this.state.config}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1}
                                stickyHeader={true}
                                postInitiated={this.state.isSubmit}
                               // isLoading={this.state?.isSubmit}
                                readCallback={(index) => {
                                    let termOptions = [...this.state.renderList];
                                    termOptions[index].editing = false
                                    this.setState({ renderList: termOptions, termNotExist:false });
                                }}
                                sortCallback={this.handleTableColumnClick}
                            />
                        </MDBRow>)}
            </MDBContainer>
        )
    }
}

export default withUserContext(Terms);