import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import { withUserContext } from '../../../contexts/UserContext';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {allowRestrictConfig} from './Config';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
export default withUserContext(class AllowRestrictUse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowRestrictDetails : allowRestrictConfig,
            isSubmit: false
        }
    }

    postEmailAllow=()=>{
        this.setState({isSubmit: true})
       let postObj = {
        "show_season_episode_id": this.props.show_season_episode_id,
        "song_id": this.props.song_id,
        "show_id": this.props.show_id,
        "letter_type": this.state.allowRestrictDetails?.Restrict_All_Promo_Use === "All_Promo_In_Episode_Only" ? "allPromoInEpisodeOnly" : this.state.allowRestrictDetails?.Restrict_All_Promo_Use === "Allow_all_Promo_Use" ? "allowAllPromoUse" : "restrictAllPromoUse"
        }
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/sendEmail`,
            postObj,
            this.props?.userContext?.active_tenant?.tenant_id,this.props?.division_id
          ).then(
            (response) => {
              if (response.data.error) {
                this.setState({ isSubmit: false });
                this.props?.notificationComponent(true, "fail");
              } else {
               
                this.props?.notificationComponent(true, "success");
                this.setState({
                  isSubmit: false,
                });
              }
            },
            (err) => {
              this.setState({ isSubmit: false });
              this.props?.notificationComponent(true, "fail");;
            }
          );

    }

    handleOnChange = (field,value)=>{
        console.log("value-------",value)
        this.setState(prevState=>({
            allowRestrictDetails:{...prevState.allowRestrictDetails,[field]: value}
        }))
    }

    render(){
        return(
            <MDBContainer>
                {/* <MDBRow>
                    <MDBCol md={5}>
                        <Radio
                        id="Restrict All Promo Use"
                        label="Restrict All Promo Use"
                        value={this.state.allowRestrictDetails.Restrict_All_Promo_Use}
                        onChange={(e, value) => this.handleOnChange('Restrict_All_Promo_Use', e.target.value)}
                        />
                        <span style={{ fontSize: "10px" }}>Restrict All Promo Use</span>
                    </MDBCol>
                </MDBRow> */}
                {/* <MDBRow>
                    <MDBCol>
                        <Radio
                        id="All Promo In Episode Only"
                        label="All Promo In Episode Only"
                        value={this.state.allowRestrictDetails.All_Promo_In_Episode_Only}
                        onChange={(e, value) => this.handleOnChange('All_Promo_In_Episode_Only', e.target.value)}
                        />
                        <span style={{ fontSize: "10px" }}>All Promo In Episode Only</span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <Radio
                        id="Allow all Promo Use"
                        label="Allow all Promo Use"
                        value={this.state.allowRestrictDetails.Allow_all_Promo_Use}
                        onChange={(e, value) => this.handleOnChange('Allow_all_Promo_Use', e.target.value)}
                        />
                        <span style={{ fontSize: "10px" }}>Allow all Promo Use</span>
                    </MDBCol>
                </MDBRow> */}
                <MDBRow>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    id="radio-group-height"
                    value={this.state.allowRestrictDetails?.Restrict_All_Promo_Use || "Restrict_All_Promo_Use"}
                    onChange={(e) => this.handleOnChange("Restrict_All_Promo_Use", e.target.value)}
                  >
                    <FormControlLabel
                      className="LabelSize"
                      value="Restrict_All_Promo_Use"
                      control={<Radio size="small" />}
                      label="Restrict All Promo Use"
                    />
                    <FormControlLabel
                      className="LabelSize"
                      value="All_Promo_In_Episode_Only"
                      control={<Radio size="small" />}
                      label="All Promo In Episode Only"
                    />
                    <FormControlLabel
                      className="LabelSize"
                      value="Allow_all_Promo_Use"
                      control={<Radio size="small" />}
                      label="Allow all Promo Use"
                    />
                  </RadioGroup>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={6}></MDBCol>
                    <MDBCol md={3}>
                    <BasicButton
                    label="Send Email"
                    text={this.state.isSubmit ? <CircularProgress size={20} color="inherit"/> : "Send Email"}
                     onClick={ this.postEmailAllow}/>
                    </MDBCol>
                   
                        <MDBCol md={3}>
                    <BasicButton
                    label = "close"
                    text="close"
                    onClick={this.props?.handleClose}
                    />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }

})