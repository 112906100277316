export const initialAcquireRecord = {
    "acquisition_date": null,
    "acquire_for_option":null,
    "acq_for_main_title":null,
    "inc_init":null,
    "option_start":null,
    "option_effective":null,
    "option_effective_date":null,
    "clearance_licensor_optional_rights_id":null,
    "song_id":null

}

export const initialAcquireMainTitleRecord = {
    "acquisition_date": null,
    "acqusition_value":null,
    "acquire_for_option":null,
}

export const acquireOptionConfig = {
    headings: [
        {
            headingLabel: "Acquire",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Disallow Acq",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Ep",
            inputType: "label",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Song",
            inputType: "label",
            icon: "",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Licensor",
            inputType: "label",
            icon: "",
            border: true,
            width: "10%"
        }
    ],

dataNodes: [ "is_option_acquired", "is_disallow_acquire", "episode_number", "song_title", "licensor_name"],
    primaryKey: "",
    nodeFields: [
        {
            column: "is_option_acquired",
            icon: "check",
            inputType:"checkbox"
        },
        {
            column: "is_disallow_acquire",
            icon: "check",
            inputType:"checkbox"
        }
    ], 
    
    inlineEdits: [
        {
            dataNode: "is_option_acquired",
            width: "7%"
        },
        {
            dataNode: "is_disallow_acquire",
            width: "7%"
        }
    ]       
}