import React from 'react';
import { withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { MDBIcon} from "mdbreact";
import TableComponent from '../../SharedComponents/Table';
import { searchResultsConfig } from './Asynchronous';
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList } from '../../../Common/TableHelper';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import clearTrackService from '../../../services/service';;
import { selectOptions } from "./Asynchronous";
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import './GlobalSearchResult.scss';


class GlobalSearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResults: [],
            loading: false,
            searchCategory: props?.match?.params?.category,
            searchType: selectOptions.filter(item => item.value == props?.match?.params?.category)[0].searchType,
            resultsCount:0,
            searchText: props?.match?.params?.keyWord,
            page: 0,
            rowsPerPage: 10,
            list : [],
            openModalName:null,
            itemId: null,
            headerText : null
        }
    }

    componentDidMount() {
        if(this.props?.userContext?.active_tenant?.tenant_id) {
            this.onChangeHandle()
        }
        // if(this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
        //     this.props.history.push('/userRoleManagement');
        // }
        if(this.props?.userContext?.user_profile?.is_user_provisioner === 1){
            this.props.history.push('/userRoleManagement');
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match?.params?.keyWord !== prevState.searchText || nextProps.match?.params?.category !== prevState.searchCategory ) {
            return { searchText: nextProps?.match?.params?.keyWord,
                     searchCategory: nextProps?.match?.params?.category,
                     page:0,
                     rowsPerPage: 10,
                     searchType: selectOptions.filter(item => item.value == nextProps?.match?.params?.category)[0].searchType,
                    }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match?.params?.keyWord !== this.state.searchText || prevProps.match?.params?.category !== this.state.searchCategory || prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id) {
            this.onChangeHandle()
        }  
        // if((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
        //         && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
        //     this.props.history.push('/userRoleManagement');
        // }  
        if(this.props?.userContext?.user_profile?.is_user_provisioner === 1 && this.props?.userContext?.user_profile?.is_user_provisioner){
            this.props.history.push('/userRoleManagement');
        }
    }

    onChangeHandle = () => {
        this.setState({loading:true})
        let searchObj = {
            'data_type': this.state.searchType,
            'search_string':this.state.searchText,
            'page_count':this.state.rowsPerPage,
            'page_no':this.state.page+1
        }
        clearTrackService.getDataParams(Constants.ClearTrackServiceBaseUrl + '/globalSearch', searchObj, this.props.userContext?.active_tenant?.tenant_id) 
            .then(response => {
                this.setState({ 
                    searchResults : response.data.data, loading: false, resultsCount: response.data.total_count,
                    list: handlePaginationList(0, this.state.rowsPerPage, response.data.data)
                });
            },
                (err) => {
                    this.setState({ searchResults: [], loading: false, page:0, rowsPerPage:0 })
                })
            }            
    
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage},this.onChangeHandle);
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0}, this.onChangeHandle)
    }
  
    // handleTableSort = (column) => { 
    //     alert(column)
    // }
 
    handleItemSelected = (newValue, node) => {
        if(node == 'name') {
            if(newValue.data_type == 'SHOW_SEASONS') {
                window.open(`/showseason/${newValue.id.split('_')[1]}`)
            } else if (newValue.data_type == 'CLEARANCE_RECORDS') {
                this.setState({openModalName: 'Clearance Record', headerText: 'Clearance Record', itemId : newValue.id.slice(18) })
            } else if (newValue.data_type == 'SONGS'){
                this.setState({openModalName: 'Song', headerText: 'Song',  itemId : newValue.id.split('_')[1] })
            } else if (newValue.data_type == 'LICENSORS'){
                this.setState({openModalName: 'Edit Licensor', headerText: 'Licensor', itemId : newValue.id.split('_')[1] })
            } else if (newValue.data_type == 'PAYEES'){
                this.setState({openModalName: 'Payee', headerText: 'Payee',  itemId : newValue.id.split('_')[1] })
            } else if (newValue.data_type == 'MUSIC_EDITORS') {
                this.setState({openModalName: 'Person', headerText: 'Person- Music Editor',  itemId : newValue.id.split('_')[1] })
            } else if(newValue.data_type == 'SHOW_COMPOSERS') {
                this.setState({openModalName: 'Show Composer', headerText: 'Show Composer',  itemId : newValue.id.split('_')[1] })
            }
    } }


    render() {
        return (
            <div className='globalSearchResult'>
                <div class='row'>
                    <div class="col-6">
                        <Link href="#"
                            onClick={(event, newValue) => {
                                event.preventDefault();
                            }}
                            color="primary" >
                            <MDBIcon icon="arrow-left" className="ml-1" style={{ fontSize: 20 }} onClick={(event, newValue) => {
                                event.preventDefault();
                                this.props.history.goBack()
                            }} />
                        </Link>
                        <label className='mainLabel'> &nbsp;&nbsp;Back</label>
                    </div>
                </div>
                <div class='row'>
                    <div class='col-xs-12' id='tblSearchResults'>
                        <TableComponent 
                            searchText={this.state.searchText}
                            resultsCount={this.state.resultsCount} 
                            searchType={this.state.searchType}
                            list={this.state.list}
                            config={searchResultsConfig}
                            isLoading={this.state.loading || false}
                            sortCallback={this.handleTableSort}
                            editCallback={() => console.log("edit callback")}
                            hyperLinkNavigationCallback={this.handleItemSelected} />
                        <TablePagination
                            component="div"
                            count={this.state.resultsCount}
                            rowsPerPageOptions={[10, 20, 50, 100, 200, 500, 1000]}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                </div>
                {
                    this.state.openModalName &&
                    <MainViewModalComponent
                        open={this.state.openModalName ? true : false}
                        handleClose={() => this.setState({ openModalName: null, itemId:null, headerText:null,})}
                        headerText={this.state.headerText}
                        mode={'Edit'}
                        modalName={this.state.openModalName}
                        {...(this.state?.openModalName === 'Person' ? {modeType:'Music Editor'} :{})}
                        {...(this.state?.openModalName === 'Person' ? {people_id:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Person' ? {origin:'profile'} :{})}
                        {...(this.state?.openModalName === 'Song' ? {songId:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Edit Licensor' ? {id:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Clearance Record' ? {clearenceId:this.state.itemId.split('_')[0]} :{})}
                        {...(this.state?.openModalName === 'Clearance Record' ? {from:false} :{})}
                        {...(this.state?.openModalName === 'Clearance Record' ? {clearance_divisionId:this.state.itemId.split('_')[1]} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {modeType:'SHOW_COMPOSER'} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {people_id:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {origin:'dashboard'} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {showSecondtab:true} :{})}
                        {...(this.state?.openModalName === 'Payee' ? {id:this.state.itemId} :{})}
                    />
                }
            </div>
        );
    }
}
export default withRouter(withUserContext(GlobalSearchResult))