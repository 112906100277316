import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialAcquisitionReportsDetails,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import { withUserContext } from "../../../contexts/UserContext";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { s2ab, genReportName } from "../../../Common/Helper";
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";

class OptionAcquisitionReport extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      optionAcquisitionReport: initialAcquisitionReportsDetails,
      seasonOptions: [],
      showOptions: [],
      options: [],
      filterValues: [],
      removedItem: null,
      seasonIdValues: [],
      showIdValues: [],
      optionIdValues: [],
      isPosting: false,
      isClearFlag: false,
      found: [],
      rightOption: [],
      showOption: [],
    };
  }

  handleSubmitOptionAcquisitionReport = () => {
    this.setState({ postInitiated: true, isPosting: true });
    let {
      optionAcquisitionReport,
      filterValues,
      seasonIdValues,
      showIdValues,
      optionIdValues,
    } = this.state;
    let payload = {
      is_acquired: optionAcquisitionReport.is_acquired ? 1 : 0,
      is_not_acquired: optionAcquisitionReport.is_not_acquired ? 1 : 0,
      is_page_break_option: optionAcquisitionReport.is_page_break_option
        ? 1
        : 0,
      is_season_option_report: optionAcquisitionReport.is_season_option_report
        ? 1
        : 0,
      season_ids: seasonIdValues,
      show_ids: showIdValues,
      option_ids: optionIdValues,
      is_summary: optionAcquisitionReport.is_summary ? 1 : 0,
      is_display_criteria_report:
        optionAcquisitionReport.is_display_criteria_report ? 1 : 0,
      is_init_rights: optionAcquisitionReport.is_init_rights ? 1 : 0,
    };
    let reportName = optionAcquisitionReport.is_season_option_report
      ? "rptShowSeasonRightsOptionsReport"
      : this.props?.reportName;
    this.props.generateReport(reportName, payload, "Option Acquisition Report")
      .then((webSocketConn) => {
        webSocketConn.onmessage = (e) => {
          let response = JSON.parse(e.data);
          if (response?.statusCode == 200) {
            this.setState({ isPosting: false });
            this.props?.downloadUrl(response?.body);
            // this.props?.notificationComponent(true, "success");
          } else {
            // this.props?.notificationComponent(true, "fail");
          }
        };
      });
    // .then(response => {
    //   if (response.data.error) {
    //     this.setState({ isPosting: false })
    //     // this.props.fieldChanged(true, "reportNotFound");
    //   }
    //   else {
    //     let url = response.data;
    //     let obj = {
    //       reportName: this.props?.reportDownloadName,
    //       production: "",
    //       season: "",
    //       episode: "",
    //       performer: "",
    //     }
    //     this.setState({ postInitiated: false, isPosting: false })
    //     let reportNameFinal = genReportName(obj)
    //     this.props?.downloadUrl(url, reportNameFinal)
    //   }
    // }, (err) => {
    //    this.setState({ isPosting: false })
    //   console.log(err)
    //   // this.props.fieldChanged(true, "reportFail");
    // })
  };

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      optionAcquisitionReport: {
        ...prevState.optionAcquisitionReport,
        [field]: value,
      },
    }));
  };

  componentDidMount() {
    // this.fetchSeasonsData();
    // this.fetchShowsData();
    // this.fetchOptionsData();
  }

  // fetchSeasonsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SEASON&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.season_id, text: item.season_name }));
  //       this.setState({ seasonOptions: formattedList }, () => { console.log(this.state.seasonOptions) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  // fetchShowsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name }));
  //       this.setState({ showOptions: formattedList }, () => { console.log(this.state.showOptions) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  // fetchOptionsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW_SEASON_OPTIONS&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.show_season_option_id, text: item.option }));
  //       this.setState({ options: formattedList }, () => { console.log(this.state.options) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues;
    this.setState({
      filterValues: values,
    });
  };

  // handleClearValue = () => {
  //   let values = this.state.filterValues;
  //   values = []
  //   this.setState({
  //     isClearFlag: true,
  //     filterValues: values
  //   },()=>{console.log("filterValues",this.state.filterValues)})
  // }

  // setSeasonId = (seasonIdValues) => {
  //   let seasonIdValue = this.state.seasonIdValues;
  //   seasonIdValue = seasonIdValues;
  //   this.setState({
  //     seasonIdValues: seasonIdValue
  //   })
  // }

  // setShowId = (showIdValues) => {
  //   let showIdValue = this.state.showIdValues;
  //   showIdValue = showIdValues;
  //   this.setState({
  //     showIdValues: showIdValue
  //   })
  // }

  // setOptionId = (optionIdValues) => {
  //   let optionIdValue = this.state.optionIdValues;
  //   optionIdValue = optionIdValues;
  //   this.setState({
  //     optionIdValues: optionIdValue
  //   })
  // }

  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState(
      { filterValues: filterValues, removedItem: removeNode },
      () => {
        let splitVal = this.state.removedItem.split(":");
        let name = splitVal[0].trim();
        let valueId = splitVal[1].trim();
        if (name === "Season") {
          let filterSeasonIdValues = this.state.found.find(
            (item) => item.label === valueId
          ).value;
          let index2 = this.state.seasonIdValues.indexOf(filterSeasonIdValues);
          let id = this.state.seasonIdValues;
          id.splice(index2, 1);
          this.setState({ seasonIdValues: id });
        } else if (name === "Show") {
          let filterSeasonIdValues = this.state.showOption.find(
            (item) => item.text === valueId
          ).value;
          let index2 = this.state.showIdValues.indexOf(filterSeasonIdValues);
          let id = this.state.showIdValues;
          id.splice(index2, 1);
          this.setState({ showIdValues: id });
        } else if (name === "Options") {
          let filterSeasonIdValues = this.state.rightOption.find(
            (item) => item.text === valueId
          ).value;
          let index2 = this.state.optionIdValues.indexOf(filterSeasonIdValues);
          let id = this.state.optionIdValues;
          id.splice(index2, 1);
          this.setState({ optionIdValues: id });
        }
      }
    );
  };

  seasonValues = (seasonValue) => {
    this.setState({ found: seasonValue });
  };

  showValues = (showValue) => {
    let backup = this.state.showOption;
    backup = backup.concat(showValue);
    this.setState({ showOption: backup });
  };

  rightOptionValues = (rightOptionValue) => {
    this.setState({ rightOption: rightOptionValue });
  };

  handleClearFilters = () => {
    let values = this.state.filterValues;
    values = [];
    this.setState({ filterValues: values, optionAcquisitionReport: {...initialAcquisitionReportsDetails}, seasonIdValues:[],
      showIdValues:[] });
  };

  GenerateCleranceDetails = () => {};
  render() {
    return (
      <>
        {" "}
        <p>
          <b>Option Acquisition Report</b>
        </p>
        <MDBRow
          className={
            this.state.filterValues?.length > 0
              ? "columnFilters d-block"
              : "d-none"
          }
        >
          <div style={{ display: "flex" }}>
            {console.log("filterValuesRender", this.state.filterValues)}
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(this, item, index)}
                  // filterValues={this.state.filterValues}
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Acquired"
                label={"Acquired"}
                checked={
                  this.state.optionAcquisitionReport?.is_acquired || false
                }
                onChange={(e) =>
                  this.handleChange("is_acquired", e.target.checked)
                }
              />
              <BasicCheckbox
                id="Not Acquired"
                label={"Not Acquired"}
                checked={
                  this.state.optionAcquisitionReport?.is_not_acquired || false
                }
                onChange={(e) =>
                  this.handleChange("is_not_acquired", e.target.checked)
                }
              />
              <BasicCheckbox
                id="Page Break"
                label={"Page Break by Option"}
                checked={
                  this.state.optionAcquisitionReport?.is_page_break_option ||
                  false
                }
                onChange={(e) =>
                  this.handleChange("is_page_break_option", e.target.checked)
                }
              />
            </MDBCol>
            <MDBCol md={7}>
              <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                setSeasonId={this.setSeasonId}
                setShowId={this.setShowId}
                isOptionAcquisition={true}
                setOptionId={this.setOptionId}
                filterSeasonIdValues={this.state.seasonIdValues}
                filterShowIdValues={this.state.showIdValues}
                filterOptionIdValues={this.state.optionIdValues}
                isClearFlag={this.state.isClearFlag}
                seasonValues={this.seasonValues}
                rightOptionValues={this.rightOptionValues}
                showValues={this.showValues}
                filterValues={this.state.filterValues}
              />
            </MDBCol>
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Rights/Options"
                label={"Show Season Rights/Options Report"}
                checked={
                  this.state.optionAcquisitionReport?.is_season_option_report
                }
                onChange={(e) =>
                  this.handleChange("is_season_option_report", e.target.checked)
                }
              />
              {this.state.optionAcquisitionReport?.is_season_option_report ? (
                <BasicCheckbox
                  id="Intial"
                  label={"Also Display Init Rights"}
                  checked={this.state.optionAcquisitionReport?.is_init_rights}
                  onChange={(e) =>
                    this.handleChange("is_init_rights", e.target.checked)
                  }
                />
              ) : (
                <BasicCheckbox
                  id="Summary"
                  label={"Summary"}
                  checked={this.state.optionAcquisitionReport?.is_summary}
                  onChange={(e) =>
                    this.handleChange("is_summary", e.target.checked)
                  }
                />
              )}
              <BasicCheckbox
                id="Display Criteria"
                label={"Display Criteria on Report"}
                checked={
                  this.state.optionAcquisitionReport
                    ?.is_display_criteria_report || false
                }
                onChange={(e) =>
                  this.handleChange(
                    "is_display_criteria_report",
                    e.target.checked
                  )
                }
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genexl-btn">
                <br />
                {/* <BasicButton className="genBtn" text={"Generate Excel"} /> */}
              </MDBCol>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton
                  className="efcBtn"
                  text={
                    this.state.isPosting ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      "Generate PDF"
                    )
                  }
                  onClick={this.handleSubmitOptionAcquisitionReport}
                />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton
                  variant="outlined"
                  text={"Clear Filters"}
                  onClick={this.handleClearFilters}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default withUserContext(OptionAcquisitionReport);
