import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { MDBCard, MDBCardBody, MDBCardText } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import {getFormattedDate} from '../../../Common/Helper'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from "@material-ui/core/CircularProgress";
import { EpisodeListConfig, EpisodeCostConfig } from "./config"
import { Cancel } from '@material-ui/icons';
import TableComponent from '../../SharedComponents/Table';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import Popover from '@mui/material/Popover';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import { applyFilterTable } from '../../../Common/TableHelper';
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import MessageModal from '../../SharedComponents/MessageModal';
import "./ShowSeasonDetails.scss";
import { withUserContext } from '../../../contexts/UserContext';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import moment from 'moment';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
let displayDetailsPermissionFound = false, displayEpisodeGridPermissionFound = false, currentObj = [], displayNgtdPermissionFound = false, displayEpCostBudgetDiffPermissionFound = false, displayMainTitleNoMtNgtdEpCost = false, displayColGlTitleAirMixNoteCmplt = false, tempConfig, displayCSPermissionFound = false, filtersNone = true, filterValues = [], episodeToFilter = '', titleToFilter = '', canCreateEpisodes, displayEFCPermissionFound = false, displayBatchPermissionFound = false, dataToDelete, popoverNotes = "", isDeletable = false, episodeCostArr = [];
class ShowSeasonTabDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            permissionArr: [],
            config: EpisodeListConfig,
            episodeData: [],
            isSubmit: false,
            setPopoverInfo: null,
            setPopoverFile: null,
            setPopoverFileEpCost: null,
            setEFCModal: null,
            setBCHModal: false,
            showCueSheetDistribution: false,
            selectedEFCValue: "Open_existing",
            initialRecordData: this.props.initialRecordData,
            newEpisodeNumber: null,
            seasonOptions: [],
            productionEntityOptions: [],
            typeOptions: [],
            networkOptions: [],
            durationOptions: [],
            prodExecOptions: [],
            costCenterOptions: [],
            termOptions: [],
            territoryOptions: [],
            isPosting: false,
            clearanceRepOptions: [],
            episodeCost: EpisodeCostConfig,
            initialRightsOptions: [],
            optionalRightsOptions: [],
            isLoading: false,
            postInitiated: false,
            showEpisodeModal: false,
            showDeleteModal: false,
            mode: null,
            setFlagPopover: false,
            selectedEpisodeId: null,
            show_season_id: this.props.show_season_id,
            main_title_id: this.props.main_title_id,
            isCueSheetDistribution: false,
            showSeasonEpisodeId: null,
            isExcelPosting: false,
            episodeCostData: [],
            setPopoverFileEpisodeCost: null,
            setEFCPopover: null,
            efcDataObj: {},
            setPopoverNegotiatedCost: null,
            episodeNegotiatedCost: [],
            setCSPopover:null,
            showExcelAndDocModal: false,
            isOpenExisting: false,
            commonPermissions:[],
            openNotification:null,
            cueSheetPopOverLoad:false,
            anyErrorMessage:null,
        }
    }
    isOpenExisting=(data)=>{
        this.setState({isOpenExisting: data})
    }
    handleShowExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: true ,isOpenExisting: false,setEFCPopover: null});
    };
    handleHideExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: false });
    };
    handleClickInfo = (e) => {
        this.setState({
            setPopoverInfo: e.currentTarget
        })
    }
    handleCloseInfo = () => {
        this.setState({
            setPopoverInfo: null
        })
    };
    handleSubmit = () => {
        this.setState({
            postInitiated: true
        }, () => {
            let allDetails = [...this.state.episodeData]
            let index = allDetails.findIndex(item => item.show_season_episode_id === currentObj.show_season_episode_id);
            if (allDetails[index]?.air_date) {
                let isoDate = moment(allDetails[index]?.air_date).toISOString();
                allDetails[index].air_date = isoDate;
            }
            if (allDetails[index]?.mixed_date) {
                let isoDate = moment(allDetails[index]?.mixed_date).toISOString();
                allDetails[index].mixed_date = isoDate;
            }
            if (!this.state.episodeCostData.every(x => x.description === "")) {
                this.postUpdatedData(allDetails[index]);
            }
            else {
                console.log("submitfail")
            }
        })
    }
    handleChange = (field, value, index) => {
        let newValueOfCostDesc = [...this.state.episodeCostData];
        newValueOfCostDesc[index][field] = value;
        currentObj.episode_cost = newValueOfCostDesc;
        this.setState({
            episodeCostData: newValueOfCostDesc

        })
    }
    handleClickFile = (obj, column) => (e) => {
        if (column === "episode_cost") {
            episodeCostArr = obj.episode_cost;
            currentObj = obj;
            this.setState({
                episodeCostData: episodeCostArr,
                setPopoverFileEpisodeCost: e.currentTarget
            })
        } else if (column === "episode_negotiated_costs") {
            this.setState({
                episodeNegotiatedCost: obj.episode_negotiated_costs,
                setPopoverNegotiatedCost:e.currentTarget
            })
        } else {
            popoverNotes = obj.notes;
            this.setState({
                setPopoverFile: e.currentTarget
            })
        }

    }
    handleCloseEpCost = () => {
        this.setState({
            setPopoverFileEpisodeCost: null,
            postInitiated: false
        })
    }
    // handleEfcModal = (e) => {
    //     this.setState({
    //         setEFCModal: e.currentTarget
    //     })
    // }
    // downloadReport = (reportName, searchPayload, reportType) => {
    //     let encryptTedStr = btoa(JSON.stringify(searchPayload));
    //     if (reportType === "isExcel") {
    //         return clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/exportExcel?reportName=${reportName}&searchJson=${encryptTedStr}`,
    //             this.props.userContext?.active_tenant?.tenant_id, 1, 1)
    //     }
    // }
    downloadReport = (reportName, searchPayload, fileName) => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let load = {
            'payload': 'exportExcel',
            'reportName': reportName,
            'fileName': "isExcel",
            'searchJson': encryptTedStr,
            'showHtml': 0,
            'encryptionType': 1,
            'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
            'divisionId': this.props.division_id,
        }
        return clearTrackService.getDataWS(load)
    }
    downloadExcel = (identifier) => {
        this.setState({ setEFCPopover: null, postInitiated: true, isExcelPosting: this.state.efcDataObj?.show_season_episode_id });
        let reportName = "rptclearanceefc";
        let dataId = this.state.efcDataObj?.show_season_episode_id;
        let searchPayload = {
            "show_season_episode_id": dataId,
            "is_efc": 1,
            "is_costsheet": null,
            "is_show_notes": null
        }
        if(identifier === "email"){
            searchPayload["send_email"] = 1
            searchPayload["isOpenExisting"] =  0
        }
        if(identifier === "excel"){
            searchPayload["isOpenExisting"] = this.state.isOpenExisting ? 1 : 0
        }
        this.downloadReport(reportName, searchPayload, "isExcel").then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                this.setState({ isExcelPosting: false })
                if (response?.statusCode == 200) {
                    this.setState({ isExcelPosting: false, showExcelAndDocModal:false })
                    if(identifier === "excel"){
                        this.downloadUrl(response?.body);
                    }
                    this.notificationComponent(true, 'success')
                } 
                else {
                    this.setState({ isExcelPosting: false })
                    this.notificationComponent(true, 'success')
                }
            }
        })
            .catch(err => {
                this.setState({ isExcelPosting: false })
                this.notificationComponent(true, 'fail')
                this.setState({ isExcelPosting: false })
            })
    }
    downloadUrl = (url) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                // link.href = window.URL.createObjectURL(blob);
                link.href = url;
                // link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }
    //     then(response => {
    //         if (response.data.error) {
    //             this.setState({ isExcelPosting: false });
    //             this.notificationComponent(true, "fail");
    //             // this.props.fieldChanged(true, "reportNotFound");
    //         }
    //         else {
    //             let excelDownloadUrl = response.data;
    //             this.downloadUrl(excelDownloadUrl, "rptclearanceefc");
    //             this.setState({ isExcelPosting: false });
    //         }
    //     }, (err) => {
    //         this.setState({ isExcelPosting: false });
    //         this.notificationComponent(true, "fail");
    //         console.log(err);
    //         // this.props.fieldChanged(true, "reportFail");
    //     })
    // }
    // downloadUrl = (url, reportName) => {
    //     if (url) {
    //         fetch(url).then(response => response.blob()).then(blob => {
    //             const link = document.createElement('a');
    //             // link.href = window.URL.createObjectURL(blob);
    //             // link.setAttribute('download', reportName);
    //             link.href = url
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //             this.notificationComponent(true, "success");
    //         })
    //     }
    // }

    handleBCHModal = (obj) => {
        this.setState({
            setBCHModal: true, showSeasonEpisodeId: obj.show_season_episode_id
        })
    }
    handleShowCueSheetPopup = (obj, nodeName, rowIndex, event) => {
        this.setState({showSeasonEpisodeId: obj.show_season_episode_id,setCSPopover:event.currentTarget });
    }
    handleCloseCSPopup = () => {
        this.setState({setCSPopover:null})
    }
    handleShowCueSheetDistribution = () => {
        this.setState({ showCueSheetDistribution:true,setCSPopover:null});
    }
    handleHideCueSheetDistribution = () => {
        this.setState({ showCueSheetDistribution: false}, () => {
            this.fetchData("episodeData", `/showSeasonEpisodes?show_season_id=${this.state.show_season_id}`);
        });
    }
    postUpdatedData = (dataToUpdate) => {
        this.setState({ isPosting: true, postInitiated: true });
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes`,
            dataToUpdate,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(
            (response) => {
                this.setState({ isPosting: false, })
                if (response.data.error) {
                    this.setState({ isPosting: false });
                    //this.props?.fieldChanged(true, "fail");
                    this.fetchData("episodeData", `/showSeasonEpisodes?show_season_id=${this.state.show_season_id}&main_title_id=${this.state.main_title_id}`);
                    this.notificationComponent(true, "fail");
                } else {
                    this.setState({
                        isPosting: false,
                    });
                    if (dataToUpdate?.editing) {
                        dataToUpdate.editing = false;
                    }
                    //this.props?.fieldChanged(true, "success");
                    this.fetchData("episodeData", `/showSeasonEpisodes?show_season_id=${this.state.show_season_id}&main_title_id=${this.state.main_title_id}`);
                    this.setState({ showDeleteModal: false });
                    this.notificationComponent(true, "success");
                    this.handleCloseEpCost();
                }
            },
            (err) => {
                this.setState({ isSubmit: false, postInitiated: false });
                //this.props?.fieldChanged(true, "fail");
                this.notificationComponent(true, "fail");
                console.log("Post budget details error: " + err);
            }
        );
    };
    saveInlineEdits = (data, index, pkValue, dataItem) => {
        let allDetails = [...this.state.episodeData]
        let tempInlineSavedData = [...data];
        if (tempInlineSavedData[index]?.air_date) {
            let isoDate = moment(tempInlineSavedData[index]?.air_date).toISOString();
            allDetails[index].air_date = isoDate;
        }
        if (tempInlineSavedData[index]?.mixed_date) {
            let isoDate = moment(tempInlineSavedData[index]?.mixed_date).toISOString();
            allDetails[index].mixed_date = isoDate;
        }
        allDetails[index].show_season_id = this.state.show_season_id;
        allDetails[index].main_title_id = this.state.main_title_id;
        allDetails[index].id = tempInlineSavedData[index]?.id;
        allDetails[index].episode_title = tempInline / SavedData[index]?.episode_title;
        allDetails[index].song_title = tempInlineSavedData[index]?.song_title;
        allDetails[index].ngtd_4646 = tempInlineSavedData[index]?.ngtd_4646;
        allDetails[index].ngtd_4675 = tempInlineSavedData[index]?.ngtd_4675;
        allDetails[index].is_ngtd_new = tempInlineSavedData[index]?.is_ngtd_new;
        allDetails[index].episode_cost = tempInlineSavedData[index]?.episode_cost;
        allDetails[index].notes = tempInlineSavedData[index]?.notes;
        allDetails[index].is_completed = tempInlineSavedData[index]?.is_completed;

        this.setState({
            episodeData: allDetails.map((item) => {
                item['editing'] = false
                return item
            })
        })
        this.postUpdatedData(allDetails[index]);

    }
    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.episodeData]
        this.setState({
            episodeData: allDetails.map((item) => {
                let newItem = {...item}
                if (item.id == id)
                    newItem['editing'] = true
                return newItem
            })
        })
    }
    preRequisiteCheckForDelete = (item) => {
        dataToDelete = { ...item };
    }
    openDeleteModal = () => {
        this.setState({ showDeleteModal: true })
    }
    closeDeleteModal = (mode) => {
        this.setState({ showDeleteModal: false })
    }
    deleteTableRow = (deleteObj) => {
        dataToDelete = deleteObj;
        if (deleteObj.is_completed === 1) {
            isDeletable = false;
            this.openDeleteModal();
        }
        else {
            clearTrackService.getData(
                Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=show_season_episodes&tableId=${dataToDelete?.show_season_episode_id}`,
                this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id
            ).then(res => {
                if (res.data[0].status === "SUCCESS") {
                    isDeletable = true;
                    if (dataToDelete?.air_date) {
                        let air_date = moment(dataToDelete.air_date).toISOString();
                        dataToDelete.air_date = air_date;
                    }
                    if (dataToDelete?.mixed_date) {
                        let mixed_date = moment(dataToDelete.mixed_date).toISOString();
                        dataToDelete.mixed_date = mixed_date;
                    }
                    let tempEpisodeCost = [...dataToDelete.episode_cost];
                    tempEpisodeCost.map((item, index) => {
                        if (item.description?.trim()?.length === 0) {
                            tempEpisodeCost.splice(index, 1);
                        }
                    })
                    dataToDelete.episode_cost = tempEpisodeCost;
                    dataToDelete["is_delete"] = 1;
                    this.openDeleteModal();
                } else {
                    isDeletable = false;
                    this.openDeleteModal();
                    //this.props?.fieldChanged(true, 'fail')
                    this.setState({ isSubmit: false });
                }
            }),
                ((err) => {
                    //this.props?.fieldChanged(true)
                    this.notificationComponent(true, "fail");
                    this.setState({ isSubmit: false });
                });
        }
    };
    handleCloseFile = () => {
        this.setState({
            setPopoverFile: null
        })
    };
    closeEfcModal = () => {
        this.setState({
            setEFCModal: null
        })
    };
    closeBCHModal = () => {
        this.setState({
            setBCHModal: false
        })
    }
    handleShowEpisodeModal = (mode) => {
        this.setState({ showEpisodeModal: true, mode: mode })
    }
    handleHideEpisodeModal = () => {
        this.setState({ showEpisodeModal: false })
    }

    handleInlineEdits = (mode, id) => {
        let allDetails = [...this.state.episodeData];
        this.setState({ showEpisodeModal: true, mode: mode, selectedEpisodeId: id });
    }

    fetchData = (field, url) => {
        this.setState({ isLoading: true })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isLoading: false })
                let formattedList = [];
                switch (field) {
                    case 'seasonOptions':
                        formattedList = response.data?.map(item => ({ value: item.season_id, label: item.season_name }));
                        break;
                    case 'productionEntityOptions':
                        formattedList = response.data?.map(item => ({ value: item.production_entity_id, label: item.company, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        this.props.getProductionEntityOptions(formattedList);
                        break;
                    case 'typeOptions':
                        formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                        break;
                    case 'networkOptions':
                        formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        break;
                    case 'durationOptions':
                        formattedList = response.data?.map(item => ({ value: item.show_duration_id, label: item.show_duration, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        break;
                    case 'prodExecOptions':
                        formattedList = response.data?.map(item => ({ value: item.production_executive_id, label: item.production_executive_name, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        break;
                    case 'rightsOptions':
                        //for initial and alt initial rights options
                        let initialRightsOptions = [];
                        response.data?.map((item, index) => {
                            if (item.is_optional == 0) { initialRightsOptions.push({ value: item.right_id, label: item.right_abbr, is_active: item.is_active }) }
                        });

                        if (initialRightsOptions.length == 0) {
                            initialRightsOptions.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        //for optional rights options
                        let optionalRightsOptions = [];
                        response.data?.map((item, index) => {
                            if (item.is_optional == 1) { optionalRightsOptions.push({ value: item.right_id, label: item.right_abbr, is_active: item.is_active }) }
                        });

                        if (optionalRightsOptions.length == 0) {
                            optionalRightsOptions.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        this.setState((prevState) => ({
                            initialRightsOptions: initialRightsOptions,
                            optionalRightsOptions: optionalRightsOptions
                        }));
                        break;
                    case 'costCenterOptions':
                        formattedList = response.data?.map(item => ({ value: item.cost_center_id, label: item.cost_center_name, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        break;
                    case 'clearanceRepOptions':
                        formattedList = response.data?.map(item => ({ value: item.user_id, label: item.full_name, is_active: item.is_active }));
                        break;
                    case 'termOptions':
                        formattedList = response.data?.map(item => ({ value: item.term_id, label: item.term_name, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        break;
                    case 'territoryOptions':
                        formattedList = response.data?.map(item => ({ value: item.territory_id, label: item.territory_abbr, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        break;
                    case 'episodeData':
                        let episodeDataFormattedList = [];
                        episodeDataFormattedList = response.data?.map((item, index) => {
                            item["air_date"] = item?.air_date ? item.air_date?.split('T')[0] : null;
                            item["mixed_date"] = item?.mixed_date ? item.mixed_date?.split('T')[0] : null;
                            item["cue_sheet_revision_send_date"] = item?.cue_sheet_revision_send_date ? getFormattedDate(item.cue_sheet_revision_send_date?.split('T')[0]) : 'None';
                            // if (item?.is_completed === null) {
                            //     item["is_completed"] = 1;
                            // }
                            // if (item?.is_budget_difference === null) {
                            //     item["is_budget_difference"] = 1;
                            // }
                            //     if (item?.cue_sheet_revision_send_date === null) {
                            //         let config = { ...this.state.config }
                            //         config.buttonFields = []
                            //         item["cue_sheet_revision_send_date"] = " ";
                            //         this.setState({ config })
                            //     } else {
                            //         item["cue_sheet_revision_send_date"] = moment(item.air_date).format("MM/DD/YYYY");
                            //     }
                            if (item?.is_no_mt === 1) {
                                item["main_title"] = "No MT"
                            }
                            if (item?.is_ngtd_new === null) {
                                item["is_ngtd_new"] = 1;
                            }
                            if (item?.notes === null || item?.notes === undefined) {
                                item["notes"] = "";
                            }
                            if (item?.episode_cost === null || item?.episode_cost === undefined || item?.episode_cost?.length === 0) {
                                item["episode_cost"] =
                                    [{
                                        description: "",
                                        cost: 0,
                                        show_season_episode_cost_id: 0
                                    }];
                            }
                            item['efc'] = 1
                            item['bch'] = 1
                            item["index"] = index + 1;
                            item["id"] = item.show_season_episode_id;
                            if (displayColGlTitleAirMixNoteCmplt == false) {
                                delete item["index"];
                                delete item["episode_number"];
                                delete item["episode_title"];
                                delete item["air_date"];
                                delete item["mixed_date"];
                                delete item["notes"];
                                delete item["is_completed"];
                            }
                            if (displayMainTitleNoMtNgtdEpCost == false) {
                                delete item["main_title"];
                                delete item["is_no_mt"];
                                delete item["ngtd_4646"];
                                delete item["ngtd_4675"];
                                delete item["is_ngtd_new"];
                                delete item["episode_cost"];
                            }
                            if (displayNgtdPermissionFound == false) {
                                delete item["ngtd_4646"];
                                delete item["ngtd_4675"];
                                delete item["is_ngtd_new"];
                            }
                            if (displayEpCostBudgetDiffPermissionFound === false) {
                                delete item["is_budget_difference"];
                                delete item["episode_cost"];
                            }
                            if (displayCSPermissionFound == false) {
                                delete item["cue_sheet_revision_send_date"];
                            }
                            if (displayEFCPermissionFound == false) {
                                delete item["efc"]
                            }
                            if (displayBatchPermissionFound == false) {
                                delete item["bch"]
                            }
                            formattedList.push(item);
                        });
                        episodeToFilter = '';
                        titleToFilter = '';
                        this.setState({
                            episodeData: formattedList,
                            episodeDataBackup: formattedList,
                        }, () => {
                            this.props.episodeLenght(this.state.episodeData.length)
                        })
                        break;
                }
                if (field != 'rightsOptions' && field != 'episodeData') {
                    this.setState((prevState) => ({
                        [field]: formattedList
                    }));
                }
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.initialRecordData !== prevProps.initialRecordData) {
            this.setState({ initialRecordData: this.props.initialRecordData });
            this.getPermissions(this.props.permissionArr);
        }
    }
    removeCallback = (Index) => {
        let allCostData = [...this.state.episodeCostData];
        allCostData.splice(Index, 1)
        currentObj.episode_cost = allCostData;
        this.setState({ episodeCostData: allCostData })
    }

    addCallback = (index) => {
        let allCostData = [...this.state.episodeCostData];

        allCostData.push(JSON.parse(JSON.stringify({ ...EpisodeCostConfig })))
        currentObj.episode_cost = allCostData;
        this.setState({ episodeCostData: allCostData })
    }
    handleFilterText(field, value) {
        this.setState({ [field]: value });
        if (field === "episodeToFilter") { episodeToFilter = value };
        if (field === "titleToFilter") { titleToFilter = value };
        if (episodeToFilter.length > 0 && titleToFilter.length == 0) {
            filtersNone = false;
            filterValues = [`Episode #:${episodeToFilter}`]
            var finalResult = applyFilterTable(this.state.config, this.state.episodeData, filterValues, filtersNone);
            this.setState({ episodeData: finalResult });
        }
        if (titleToFilter.length > 0 && episodeToFilter.length == 0) {
            filtersNone = false;
            filterValues = [`Title :${titleToFilter}`]
            var finalResult = applyFilterTable(this.state.config, this.state.episodeData, filterValues, filtersNone);
            this.setState({ episodeData: finalResult });
        }
        if (episodeToFilter.length > 0 && titleToFilter.length > 0) {
            filtersNone = false;
            filterValues = [`Episode #:${episodeToFilter}`, `Title :${titleToFilter}`]
            var finalResult = applyFilterTable(this.state.config, this.state.episodeData, filterValues, filtersNone);
            this.setState({ episodeData: finalResult });
        }
        if (episodeToFilter.length == 0 && titleToFilter.length == 0) {
            filtersNone = true;
            this.setState({ episodeData: this.state.episodeDataBackup });
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        // if (this.state.saveFlag) {

        // }
    }
    handleOpenFlagPopover = (e) => {
        this.setState({
            setFlagPopover: !this.state.setFlagPopover
        })
    };

    handleCloseFlagPopup = () => {
        this.setState({
            setFlagPopover: false
        })
    };
    handleOpenEFCPopup = (node, value) => (e) => {
        this.setState({
            setEFCPopover: e.currentTarget, efcDataObj: value
        })
    };
    handleCloseEFCPopup = () => {
        this.setState({
            setEFCPopover: false
        })
    };
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false, openNotification: null ,anyErrorMessage : null, setCSPopover:null, cueSheetPopOverLoad: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    handleRadioChange = (e) => {
        if (e.target.value === "Open_existing") {
            this.setState({ selectedEFCValue: "Open_existing" });
        }
        else {
            this.setState({ selectedEFCValue: "Overwrite" });
        }
    }
    episodeIncrement = () => {
        let episodeList = this.state.episodeData;
        let episodeLastIndex = this.state.episodeData.length - 1;
        let isIncrement = episodeList[episodeLastIndex].episode_number.includes(".")
        if (isIncrement) {
            let episodeStart = episodeList[episodeLastIndex].episode_number.split('.')[0];
            let number = episodeList[episodeLastIndex].episode_number.split('.').pop();
            number = (parseInt(number) + 1).toString()
            let newEpisodeNumber = episodeStart.concat(".", number);
            this.setState({ newEpisodeNumber: newEpisodeNumber });
        }
        this.handleShowEpisodeModal("New")

    }
    navigationCallback = (obj, node) => {
        if (node === "episode_number") {
            window.open(`/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`, '_blank');
        }
    }

    postCueSheetRevision = (type, cueSheetData, licensorsFlag = 0) => {
        let postJson = {
            "episodes_list":[{"show_season_episode_id":cueSheetData?.show_season_episode_id,"airing":"Initial","cue_sheet_header_id":cueSheetData?.cue_sheet_header_id}],
            "type": type,
            "show_season_episode_ids":cueSheetData?.show_season_episode_id?.toString(),
            "show_season_id": this.state.show_season_id,
            "email_to":[],
            "is_include_licensors": licensorsFlag
        }
        // if(rivision !== null) {
        //     postJson.rivision_no = rivision
        // }
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/sendMailToCueSheetDistributors`,postJson,
            this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id
          )
            .then((response) => {
              if (!response.data.error) {
                this.setState({
                  cueSheetPopOverLoad:false,
                  openNotification: response?.data?.success ? 'success' : 'error',
                  anyErrorMessage:response?.data?.msg,
                  setCSPopover:null
                }, () => {
                    this.fetchData("episodeData", `/showSeasonEpisodes?show_season_id=${this.state.show_season_id}`);
                });
              } else {
                this.setState({
                  setCSPopover:null,
                  cueSheetPopOverLoad:false,
                  openNotification:'error',
                });
              }
            }),
            ((err) => {
                this.setState({
                    setCSPopover:null,
                    cueSheetPopOverLoad:false,
                    openNotification:'error',
                });
            });
    };

    getCuesheetEpisodes = (value, licensorsFlag = 0) => {
        this.setState({ cueSheetPopOverLoad: true });
        let entityType;
        if (value === "send") {
          entityType = "SEND_CUE_SHEET_EPISODES";
        } else {
          entityType = "RESEND_CUE_SHEET_EPISODES";
        }
        clearTrackService.getData(
          Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${this.state.show_season_id}`,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props.division_id
        )
          .then((response) => {
             let cueSheetData = response?.data?.find(item => item?.show_season_episode_id === this.state.showSeasonEpisodeId && item?.airing ==="Initial")
             if(cueSheetData) {
                if(value === 'sendRevision') {
                    this.postCueSheetRevision("New Revision", cueSheetData,licensorsFlag)
                } else if(value === 'send') {
                    this.postCueSheetRevision("Send", cueSheetData,licensorsFlag)
                } else {
                    this.postCueSheetRevision('Resend', cueSheetData,licensorsFlag)
                }
             } else {
                this.setState({cueSheetPopOverLoad: false, openNotification : 'error', anyErrorMessage : 'There is no Initial cue sheet CS PDF for selected Episode'})
             } 
          })
          .catch((err) => {
            this.setState({cueSheetPopOverLoad: false, openNotification : 'error'})
          });
      };

    //   getRevisionCueSheets = (cueSheetData) => {
    //     clearTrackService.getData(
    //       Constants.ClearTrackServiceBaseUrl +
    //         `/cueSheetDistributionRevision?show_season_id=${this.state.show_season_id}&show_season_episode_id=${cueSheetData?.show_season_episode_id}&airing=${cueSheetData?.airing}`,
    //       this.props?.userContext?.active_tenant?.tenant_id,
    //       this.props?.division_id
    //     )
    //       .then((response) => {
    //          this.postCueSheetRevision("New Revision", cueSheetData, response?.data[response?.data?.length - 1]?.rivision)
    //       })
    //       .catch((err) => {
    //         this.setState({cueSheetPopOverLoad: false, openNotification : 'error'})
    //       });
    //   };

    render() {
        const { isLoading } = this.state.isLoading;
        let openInfo = Boolean(this.state.setPopoverInfo);
        let openFile = Boolean(this.state.setPopoverFile);
        let openExpandModal = Boolean(this.state.setFlagPopover);
        let openFileEpCost = Boolean(this.state.setPopoverFileEpisodeCost);
        let openNegotiatedCost=Boolean(this.state.setPopoverNegotiatedCost)
        let openEFCPopover = Boolean(this.state.setEFCPopover);
        let openCSPopover = Boolean(this.state.setCSPopover);
        let sum;
        let severity = this.state.openNotification ? this.state.openNotification : this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = this.state.anyErrorMessage ? this.state.anyErrorMessage : (this.state.showSuccess || this.state.openNotification === 'success')
                                 ? "The changes are saved successfully!" : (this.state.showFail || this.state.openNotification === 'error') ? "Saving changes failed!" : "";
        return (
            <MDBContainer fluid className="ProjectDetailsContainer p-0">
                {isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div>
                            <NotificationComponent open={this.state.showSuccess || this.state.showFail || this.state.openNotification?.length > 0} message={messageNotification} severity={severity}
                                handleResetNotify={this.handleResetNotify.bind(this)} />
                            {displayDetailsPermissionFound && (<MDBRow>
                                <MDBCol md={5} className="groupingLabel">
                                    <MDBCard className='general-card'>
                                        <MDBCardBody>
                                            <MDBCardText>
                                                <BasicLabel text={"General"} />
                                                <MDBRow>
                                                    <MDBCol md={9} className="secondaryLabelContainer">
                                                        <BasicLabel text={"Production Entity"} />
                                                        <p className='content'>{this.state.initialRecordData?.company || "-"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={3} className="secondaryLabelContainer">
                                                        <BasicLabel text={"MPM #"} />
                                                        <p className='content'>{this.state.initialRecordData?.mpm || "-"}</p>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={3} className="secondaryLabelContainer">
                                                        <BasicLabel text={"Show Type"} />
                                                        <p className='content'>{this.state.initialRecordData?.show_type || "-"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={4} className="secondaryLabelContainer">
                                                        <BasicLabel text={"Network"} />
                                                        <p className='content'>{this.state.initialRecordData?.network_name || "-"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={5} className="secondaryLabelContainer">
                                                        <BasicLabel text={"Time"} />
                                                        <p className='content'>{this.state.initialRecordData?.timeToDisplay === "- " ? "- - " : this.state.initialRecordData?.timeToDisplay} {this.state.initialRecordData?.show_duration ? `(${this.state.initialRecordData?.show_duration})` : '-'}</p>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={6} className="secondaryLabelContainer">
                                                        <BasicLabel text={"Notes"} />
                                                        <p className='content'>{this.state.initialRecordData?.notes || "-"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={6} className="secondaryLabelContainer">
                                                        <BasicLabel text={"Show Year"} />
                                                        <p className='content'>{this.state.initialRecordData?.show_yr || "-"}</p>
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md={12} className="secondaryLabelContainer">
                                                        <BasicLabel text={"Options"} />
                                                        <MDBRow>
                                                            <MDBCol md={6}><p className='content'>Music Replaced
                                                            {this.state.initialRecordData.is_music_has_replaced == 1 ? <CheckCircleIcon fontSize="small" style={{ fill: 'green' }} /> :
                                                                    <Cancel fontSize='small' style={{ fill: 'red' }} />}</p>
                                                            </MDBCol>
                                                            <MDBCol md={6}><p className='content'>Create Excel w/batch
                                                            {this.state.initialRecordData.can_create_excel_batch == 1 ? <CheckCircleIcon fontSize="small" style={{ fill: 'green' }} /> :
                                                                    <Cancel fontSize='small' style={{ fill: 'red' }} />}</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow>
                                                            <MDBCol md={6}><p className='content'>Override CostSheet-MS
                                                            {this.state.initialRecordData.override_cost_sheet_ms == 1 ? <CheckCircleIcon fontSize="small" style={{ fill: 'green' }} /> :
                                                                    <Cancel fontSize='small' style={{ fill: 'red' }} />}</p>
                                                            </MDBCol>
                                                            <MDBCol md={6}><p className='content'>Seperate HV Checks/Batch
                                                            {this.state.initialRecordData.is_separate_hv_checks_batch == 1 ? <CheckCircleIcon fontSize="small" style={{ fill: 'green' }} /> :
                                                                    <Cancel fontSize='small' style={{ fill: 'red' }} />}</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>


                                <MDBCol md={7} className="groupingLabel">
                                    <MDBRow style={{ marginRight: "2px" }}>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardText>
                                                    <BasicLabel text={"Contacts"} />
                                                    <MDBRow>
                                                        <MDBCol md={4} className="secondaryLabelContainer">
                                                            <BasicLabel text={"Production Exec"} />
                                                            <p className='content'>{this.state.initialRecordData?.production_exc || "-"}</p>
                                                        </MDBCol>
                                                        <MDBCol md={4} className="secondaryLabelContainer">
                                                            <BasicLabel text={"Clearance Rep"} />
                                                            <p className='content'>{this.state.initialRecordData?.clearance_rep || "-"}</p>
                                                        </MDBCol>
                                                        <MDBCol md={4} className="secondaryLabelContainer">
                                                            <BasicLabel text={"Estimating Manager"} />
                                                            <p className='content'>{this.state.initialRecordData?.estimating_manager || "-"}</p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <MDBRow>
                                                        <MDBCol md={4} className="secondaryLabelContainer">
                                                            <BasicLabel text={"Line Producer"} />
                                                            <p className='content'>{this.state.initialRecordData?.line_producer || "-"}</p>
                                                        </MDBCol>
                                                        <MDBCol md={4} className="secondaryLabelContainer">
                                                            <BasicLabel text={"Cl. Rep Assitant"} />
                                                            <p className='content'>{this.state.initialRecordData?.clearance_rep_asst || "-"}</p>
                                                        </MDBCol>
                                                        <MDBCol md={4} className="secondaryLabelContainer">
                                                            <BasicLabel text={"Estimater"} />
                                                            <p className='content'>{this.state.initialRecordData?.estimator || "-"}</p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBRow>
                                    <MDBRow className='mt-2' style={{ marginRight: "2px" }}>
                                        <MDBCard>
                                            <MDBCardBody>
                                                <MDBCardText>
                                                    <div className="groupingLabel">
                                                        <BasicLabel text={"Default Rights"} />
                                                        <MDBRow className="DefaultRightHeadingWrapper">
                                                            <MDBCol md={1}><MDBIcon icon={"info-circle"} className="info-icon" onClick={this.handleClickInfo} /></MDBCol>
                                                            <MDBCol md={4} className="secondaryLabelContainer"><BasicLabel text={"Initial Rights"} /></MDBCol>
                                                            <Popover
                                                                id={"rightsPopup"}
                                                                open={openInfo}
                                                                anchorEl={this.state.setPopoverInfo}
                                                                onClose={this.handleCloseInfo}                                    
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }} >
                                                                <MDBRow className="popoverRightsWrapper">
                                                                <MDBCol md={12}>
                                                                    <p className="popoverHeading"> Default Alt Initial </p>
                                                                    <p className="popover-content-title popover-content-parent">
                                                                        <span className='popover-content-child'>{this.state.initialRecordData?.alt_initial_right_abbr || "-"}</span>,
                                                                        <span className="popover-content-title popover-content-child">{this.state.initialRecordData?.alt_initial_term || "-"} </span>,
                                                                        <span className="popover-content-child">{this.state.initialRecordData?.alt_initial_territory || "-"} </span>
                                                                    </p>

                                                                    </MDBCol>
                                                                </MDBRow>
                                                                 <MDBRow className="popoverRightsWrapper">
                                                                 <MDBCol md={12}>
                                                                 <p className="popoverHeading"> Default Option Right</p>
                                                                            
                                                                {this.state.initialRecordData.optional_rights.map((item, index) => {
                                                                    return (                                                                       
                                                                        <p className="popover-content-title  popover-content-parent">
                                                                            <span className="popover-content-title popover-content-child">{item?.right_abbr || "-"}</span>
                                                                            <span className="popover-content-title popover-content-child">{item?.term || "-"}</span> 
                                                                            <span className='popover-content-child'>{item?.term ? ", " : ""} {item.territory || "-"} </span>
                                                                        </p>
                                                                   )
                                                                })}
                                                                 </MDBCol>
                                                                 </MDBRow>  
                                                            </Popover>
                                                            <MDBCol md={3} className="secondaryLabelContainer">
                                                                <BasicLabel text={"Term"} />
                                                            </MDBCol>
                                                            <MDBCol md={4} className="secondaryLabelContainer">
                                                                <BasicLabel text={"Territory"} />
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow className="DefaultRightContentWrapper">
                                                            <MDBCol md={1}></MDBCol>
                                                            <MDBCol md={4}>
                                                                <p className='content'>{this.state.initialRecordData?.initial_right_abbr || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol md={3}>
                                                                <p className='content'>{this.state.initialRecordData?.initial_term || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol md={4}>
                                                                <p className='content'>{this.state.initialRecordData?.initial_territory || "-"}</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>
                                                    <div className="groupingLabel">
                                                        <BasicLabel text={"Clearance Budget"} />
                                                        <MDBRow>
                                                            <MDBCol md={3} className="secondaryLabelContainer">
                                                                <BasicLabel text={"Cost Center"} />
                                                            </MDBCol>
                                                            <MDBCol md={2} className="secondaryLabelContainer">
                                                                <BasicLabel text={"Budget"} />
                                                            </MDBCol>
                                                            <MDBCol md={2} className="secondaryLabelContainer">
                                                                <BasicLabel text={"Total Budget"} />
                                                            </MDBCol>
                                                            <MDBCol md={2} className="secondaryLabelContainer">
                                                                <BasicLabel text={"Music Amort Code"} />
                                                            </MDBCol>
                                                            <MDBCol md={3} className="secondaryLabelContainer">
                                                                <BasicLabel text={"Series Amort(for MT)"} />
                                                            </MDBCol>
                                                        </MDBRow>
                                                        <MDBRow>
                                                            <MDBCol md={3}>
                                                                {this.state.initialRecordData?.clearance_budgets && this.state.initialRecordData.clearance_budgets.map((item, index) => (<p key={"costCenter" + index} className='content'>{item?.cost_center_name || "-"}</p>))}

                                                            </MDBCol>
                                                            <MDBCol md={2}>
                                                                {this.state.initialRecordData?.clearance_budgets && this.state.initialRecordData.clearance_budgets.map((item, index) => (<p key={"costCenter" + index} className='content'>{item?.budget || "-"}<span>{item.sum == 1 ? "(Sum)" : null}</span></p>))}
                                                            </MDBCol>
                                                            <MDBCol md={2}>
                                                                <p className='content'><span className="sum">$</span>
                                                                    {this.state.initialRecordData?.clearance_budgets &&
                                                                        <>
                                                                            <span className="sum">{sum = this.state.initialRecordData?.clearance_budgets?.reduce((accum, item) => (item.sum == 1) ? accum + item.budget : accum + 0, 0)}</span>
                                                                        </>
                                                                    }</p>
                                                            </MDBCol>
                                                            <MDBCol md={2}>
                                                                <p className='content'>{this.state.initialRecordData?.music_amort_code || "-"}</p>
                                                            </MDBCol>
                                                            <MDBCol md={3}>
                                                                <p className='content'>{this.state.initialRecordData?.series_amort_code || "-"}</p>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>
                                                </MDBCardText>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>)}
                            {displayEpisodeGridPermissionFound && <>
                                <MDBRow className="EpisodesHeadingRow">
                                    <MDBCol md={9}><p className="headingContainer">Episodes</p></MDBCol>
                                    <MDBCol md={3} className="completedCountWrapper">
                                        <span className="content">{this.state.episodeData.length} Episodes ( {this.state.episodeData.length > 0 && this.state.episodeData.filter((obj) => (obj?.is_completed == 1)).length} completed, {this.state.initialRecordData?.proj_eps} Projected)</span>
                                        {canCreateEpisodes &&
                                            <div className="plus-icon" style={{ display: "inline-block" }}>
                                                <BasicButton className="plus-icon-Details"
                                                    icon={"plus-circle"} text=" "
                                                    variant="contained"
                                                    onClick={() => this.state.episodeData?.length > 0 ? this.episodeIncrement() : this.handleShowEpisodeModal("New")} />
                                            </div>
                                        }
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="episodeTableFilters">
                                    <MDBCol md={2} className="episodeFilter">
                                        <BasicTextField placeholder="Search Episode"
                                            value={episodeToFilter || ""}
                                            onChange={(e) => this.handleFilterText('episodeToFilter', e.target.value)} />
                                    </MDBCol>
                                    <MDBCol md={2} className="titleFilter">
                                        <BasicTextField placeholder="Search Title"
                                            value={titleToFilter || ""}
                                            onChange={(e) => this.handleFilterText('titleToFilter', e.target.value)} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={12}>
                                        <div className='showSeasonDetailTable' id="table-bg-details">
                                            <TableComponent
                                                list={this.state.episodeData || []}
                                                stickyHeader={true}
                                                className={"showseason"}
                                                config={this.state.config}
                                                editCallback={this.handleInlineEdits.bind(this, "Edit")}
                                                saveCallback={this.saveInlineEdits.bind(this)}
                                                postInitiated={this.state.isExcelPosting}
                                                handlePopoverClick={this.handleClickFile.bind(this)}
                                                handleEfcModal={this.handleOpenEFCPopup.bind(this)}
                                                handleBCHModal={(e, value) => this.handleBCHModal.bind(this, value)}
                                                handleOpenInlineModal={this.handleShowCueSheetPopup.bind(this)}
                                                handlepopupCallback={this.handleOpenFlagPopover}
                                                handleDelCallBack={(id, item) =>
                                                    this.deleteTableRow(item)
                                                }
                                                hyperLinkNavigationCallback={this.navigationCallback}
                                            />
                                            <Popover
                                                 open={openCSPopover}
                                                 anchorEl={this.state.setCSPopover}
                                                 onClose={this.handleCloseCSPopup}
                                                 anchorReference="anchorOrigin"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left'
                                                    }}

                                                anchorPosition={{ top: 100, left: 100 }}
                                            >
                                                {this.state.cueSheetPopOverLoad ?  <CircularProgress size={15}/> : <MDBRow>
                                                    <p className="popover-content-title" onClick={() => this.handleShowCueSheetDistribution(null)}>Cue Sheet Distribution</p>
                                                    {this.state.episodeData?.find(item => item?.show_season_episode_id === this.state.showSeasonEpisodeId)?.cue_sheet_revision_send_date === 'None' ?
                                                    <><p className="popover-content-title" onClick={() => this.getCuesheetEpisodes('send', 0)}>New(Exclude Licensors)</p>
                                                    <p className="popover-content-title" onClick={() => this.getCuesheetEpisodes('send', 1)}>New(Include Licensors)</p></> :
                                                    <><p className="popover-content-title" onClick={() =>this.getCuesheetEpisodes('sendRevision')}>Send Cue Sheet Revision</p>
                                                    <p className="popover-content-title" onClick={() =>this.getCuesheetEpisodes('resend')}>ReSend Cue Sheet</p></>
                                                    }
                                                </MDBRow>}
                                            </Popover>
                                            <Popover
                                                    open={openNegotiatedCost}
                                                    anchorEl={this.state.setPopoverNegotiatedCost}
                                                    onClose={()=>this.setState({setPopoverNegotiatedCost:null})}
                                                    anchorReference="anchorOrigin"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left'
                                                    }}

                                                    anchorPosition={{ top: 100, left: 300 }}
                                            >
                                                 <MDBRow key={"episodeCost"}>
                                                            <MDBCol md={6} id="Dec-p">
                                                       <BasicLabel text={"Negotiated Cost Center"} />
                                                    </MDBCol>
                                                    <MDBCol md={4} id="cost-p">
                                                        <BasicLabel text={"Actual Cost"} />
                                                    </MDBCol>
                                                    </MDBRow>
                                                    {this.state.episodeNegotiatedCost?.map((item, index) => (
                                                         item?.is_visible === 1 ? <MDBRow key={"episodeCost" + index}>
                                                            <MDBCol md={6} id="Dec-p">
                                                                <p className="content">{item?.cost_center_name || ""}</p>
                                                            </MDBCol>
                                                            <MDBCol md={4} id="cost-p">
                                                                <p className="content">{item?.actual_cost || 0}</p>
                                                            </MDBCol>
                                                        </MDBRow>:null
                                                    ))}
                                                </Popover>
                                        <Popover
                                                 open={openEFCPopover}
                                                 anchorEl={this.state?.setEFCPopover}
                                                 onClose={this.handleCloseEFCPopup}
                                                 anchorReference="anchorOrigin"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left'
                                                    }}

                                                anchorPosition={{ top: 100, left: 100 }}
                                            >
                                                <MDBRow>
                                                    <p className="popover-content-title" onClick={this.handleShowExcelAndDocModal}>Display</p>
                                                    <p className="popover-content-title" onClick={()=>this.downloadExcel("email")}>Email</p>
                                                </MDBRow>
                                            </Popover>
                                            {/* <Popover
                                                open={openExpandModal}
                                                anchorEl={this.state.setFlagPopover}
                                                onClose={() => { this.handleCloseFlagPopup() }}
                                                anchorReference="anchorOrigin"
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                anchorPosition={{ top: 100, left: 300 }}
                                            >
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="Music Replaced"
                                                        label="Music Replaced"
                                                    />
                                                    <BasicCheckbox
                                                        id="Exclude"
                                                        label="Exclude From Budget/EFC Report"
                                                    />
                                                    <BasicTextArea
                                                        label="EFC Notes"
                                                        type="text"
                                                        rows={1}
                                                    />
                                                    <br /> 
                                                    <MDBCol md={4}></MDBCol>
                                                    <MDBCol md={4}>
                                                        <BasicButton icon={"save"} text=" " />
                                                    </MDBCol>
                                                    <MDBCol md={4}>
                                                        <BasicButton icon={"times"} text=" " variant="outlined" onClick={this.handleCloseFlagPopup} />
                                                    </MDBCol>
                                                </MDBRow>
                                            </Popover>*/}
                                            <MDBCol md={15}>
                                                <Popover
                                                    open={openFileEpCost}
                                                    anchorEl={this.state.setPopoverFileEpisodeCost}
                                                    onClose={this.handleCloseEpCost}
                                                    anchorReference="anchorOrigin"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left'
                                                    }}

                                                    anchorPosition={{ top: 100, left: 300 }}
                                                >
                                                    {this.state.episodeCostData?.map((item, index) => (
                                                        <MDBRow key={"episodeCost" + index}>
                                                            <MDBCol md={6} id="Dec-p">
                                                                {index === 0 && <BasicLabel text={"Description"} />}
                                                                <p className="content">{item?.description || "-"}</p>
                                                                {/* <BasicTextField
                                                                        label={"Description"}
                                                                        className={"dec-field"}
                                                                        isMandatory={true}
                                                                        showMandatory={((this.state.episodeCostData?.length - 1) === index && this.state.postInitiated) || false}
                                                                        mandatoryWarning={messages.mandatoryWarning}
                                                                        value={item?.description || null}
                                                                        onChange={(e) => this.handleChange('description', e.target.value?.length > 0 ? e.target.value : null, index)}
                                                                    /> */}
                                                            </MDBCol>
                                                            <MDBCol md={4} id="cost-p">
                                                                {index === 0 && <BasicLabel text={"Cost"} />}
                                                                <p className="content">{item?.cost || "-"}</p>
                                                                {/* <BasicTextField
                                                                        label={"Cost"}
                                                                        className={"cost-field"}
                                                                        isMandatory={true}
                                                                        // showMandatory={this.state.postInitiated || false}
                                                                        mandatoryWarning={messages.mandatoryWarning}
                                                                        value={item?.cost || null}
                                                                        onChange={(e) => this.handleChange('cost', e.target.value?.length > 0 ? e.target.value : null, index)}
                                                                    /> */}
                                                            </MDBCol>
                                                            {/* <MDBCol md={2} className="icons-align">
                                                                <div >
                                                                    <MDBCol>
                                                                        {this.state.episodeCostData?.length - 1 === index ?
                                                                            <MDBIcon icon="plus-circle" className={index === 0 ? "plus-height" : "float-left"} onClick={() => this.addCallback(index)} /> : ""
                                                                        }
                                                                    </MDBCol>
                                                                    <MDBCol className="p2">
                                                                        {this.state.episodeCostData?.length > 1 ?
                                                                            <MDBIcon icon="minus-circle" className={index === 0 ? "plus-height " : "float-left"} onClick={() => this.removeCallback(index)} /> : ""
                                                                        }
                                                                    </MDBCol>
                                                                </div>
                                                            </MDBCol> */}
                                                        </MDBRow>
                                                    ))}
                                                    {/* <MDBRow>
                                                        <br />
                                                        <MDBCol md={4}></MDBCol>
                                                        <MDBCol md={4}>
                                                            <BasicButton icon={"save"} text=" " onClick={this.handleSubmit} />
                                                        </MDBCol>
                                                        <MDBCol md={4}>
                                                            <BasicButton icon={"times"} text=" " variant="outlined" onClick={this.handleCloseEpCost} />
                                                        </MDBCol>
                                                    </MDBRow>
 */}

                                                </Popover>
                                            </MDBCol>
                                            <Popover
                                                open={openFile}
                                                anchorEl={this.state?.setPopoverFile}
                                                onClose={this.handleCloseFile}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <p className='content' style={{ wordBreak: "break-all" }}>{popoverNotes}</p>
                                                {/* {FilePopoverOptions.map((item) => (<BasicCheckbox label={item.label} checked={item.checked} />))}
                                                <DateField
                                                    id="date"
                                                    label={"Mix Date"}
                                                />
                                                <MDBRow>
                                                    <MDBCol md={4}></MDBCol>
                                                    <MDBCol md={2}><BasicButton className="save-icon-Details"
                                                        icon={"save"} text=" "
                                                        variant="contained" /></MDBCol>
                                                    <MDBCol md={2}></MDBCol>
                                                    <MDBCol md={1}><BasicButton className="show-season-icons"
                                                        icon={"times"} text=" "
                                                        variant="contained" /></MDBCol>
                                                    <MDBCol md={2}></MDBCol>
                                                </MDBRow> */}
                                            </Popover>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </>}
                        </div>
                    )
                }
                {
                    this.state.showDeleteModal &&
                    <MessageModal
                        title={isDeletable ? "Confirm Episode Deletion" : "Delete Episode"}
                        open={this.state.showDeleteModal}
                        message={isDeletable && `Are you sure you would like to delete episode "${dataToDelete?.episode_title}"` || (!isDeletable && dataToDelete.is_completed === 1) && "This is a completed episode and connot be deleted." || (!isDeletable && dataToDelete.is_completed === 0) && "This episode can not be deleted since Clearance Records/ Cue Sheet Records have been added"}

                        primaryButtonText={isDeletable ? (this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : "Yes") : "Ok"}
                        hideCancel={isDeletable ? false : true}
                        secondaryButtonText={isDeletable ? "Cancel" : null}
                        onConfirm={isDeletable ? () => this.postUpdatedData(dataToDelete) : this.closeDeleteModal}
                        handleClose={isDeletable ? this.closeDeleteModal : null} />
                }
                {
                    this.state.showEpisodeModal &&
                    <MainViewModalComponent
                        open={this.state.showEpisodeModal}
                        handleClose={this.handleHideEpisodeModal}
                        headerText={this.state.mode === "Edit" ? "Episode" : "Episode for ".concat(this.props?.initialRecordData?.show_name)}
                        mode={this.state.mode}
                        indexNumber={this.state.mode === "Edit" ? this.state.episodeData?.find(item => item?.show_season_episode_id === this.state.selectedEpisodeId)?.index : this.state.episodeData?.length}
                        modalName={"Episode"}
                        initialRecordData={this.props?.initialRecordData}
                        selectedEpisodeId={this.state.selectedEpisodeId}
                        fetchData={this.fetchData}
                        show_season_id={this.state.show_season_id}
                        main_title_id={this.state.main_title_id}
                        permissionArr={this.props?.permissionArr}
                        newEpisodeNumber={this.state.newEpisodeNumber}
                    />
                }
                {/* {
                    this.state.setEFCModal &&
                    <MainViewModalComponent
                        open={this.state.setEFCModal}
                        handleClose={this.closeEfcModal}
                        headerText={""}
                        mode={""}
                        modalName={"EFCModal"}
                    />
                } */}
                {
                    this.state.setBCHModal &&
                    <MainViewModalComponent
                        open={this.state.setBCHModal}
                        handleClose={this.closeBCHModal}
                        headerText={"Batches"}
                        mode={""}
                        modalName={"Batches"}
                        show_season_episode_id={this.state.showSeasonEpisodeId}
                        isMainTitle={false}
                        division_id={this.props.division_id}
                        clearanceBatchesPermissions={this.state.commonPermissions.find((item) => item.permission_name === "Columns: BCH")}
                    />
                }
                {
                    this.state.showCueSheetDistribution && (
                        <MainViewModalComponent
                            open={this.state.showCueSheetDistribution}
                            handleClose={this.handleHideCueSheetDistribution}
                            headerText={"Cue Sheet Distribution"}
                            mode={""}
                            modalName={"Cue Sheet Distribution"}
                            showSeasonData={this.state.initialRecordData}
                            showSeasonEpisodeId={this.state.showSeasonEpisodeId}
                            isCueSheetDistribution={true}
                        />)
                }
                {this.state.showExcelAndDocModal && (
            <>
              <MainViewModalComponent
                open={this.state.showExcelAndDocModal}
                handleClose={this.handleHideExcelAndDocModal}
                headerText={"Generate Excel/DOC"}
                mode={' '}
                modalName={"Excel And Doc Modal"}
                downloadExcel={()=>this.downloadExcel("excel")}
                isOpenExisting = {this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isExcelPosting}

              />
            </>
          )}
            </MDBContainer >
        )
    }
    getPermissions(permissions) {
        if(this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === this.props?.division_id)?.division_name === 'Animation' && !(this.state.config?.headings?.find(i => i.headingLabel === "MPM No."))) {
            EpisodeListConfig.headings[0].width = "2%"
            EpisodeListConfig.headings[12].width = "4%"
            EpisodeListConfig.headings[7].width = "6%"
            EpisodeListConfig.headings?.splice(1,0,{headingLabel: "MPM No.",icon: "",width: "5%", inputType: "text"},)
            EpisodeListConfig.dataNodes?.splice(1,0,'mpm_no')
            this.setState({config:EpisodeListConfig})
        }
        if (permissions.length > 0) {
            displayDetailsPermissionFound = permissions.filter((obj, index) => obj.permission_name === "Show Season - Details section" && obj.view === 1);
            displayDetailsPermissionFound = displayDetailsPermissionFound.length > 0 ? true : false;
            displayColGlTitleAirMixNoteCmplt = permissions.filter((obj, index) => (obj.permission_name === "Columns: #, GL No., Title, Air Date, Mix Date, Notes, Cmplt" && obj.view === 1));
            displayColGlTitleAirMixNoteCmplt = displayColGlTitleAirMixNoteCmplt.length > 0 ? true : false;
            if (displayColGlTitleAirMixNoteCmplt === false) {
                tempConfig = EpisodeListConfig;
                let headingToRemove = ["#", "Episode #", "Title", "Air Date", "Mix Date", "Notes", "Cmplt"];
                let dataNodesToRemove = ["index", "episode_number", "episode_title", "air_date", "mixed_date", "notes", "is_completed"];

                let tempHeading = tempConfig?.headings?.filter(item => !headingToRemove.includes(item?.headingLabel));
                let tempDataNodes = tempConfig?.dataNodes?.filter(item => !dataNodesToRemove.includes(item));
                EpisodeListConfig.headings = tempHeading;
                EpisodeListConfig.dataNodes = tempDataNodes;
                this.setState({ config: EpisodeListConfig })
            }
            displayMainTitleNoMtNgtdEpCost = permissions.filter((obj, index) => obj.permission_name === "Columns: Main Title, No MT?, Ngtd, Ep. Costs columns" && obj.view === 1);
            displayMainTitleNoMtNgtdEpCost = displayMainTitleNoMtNgtdEpCost.length > 0 ? true : false;
            if (displayMainTitleNoMtNgtdEpCost === false) {
                tempConfig = EpisodeListConfig;
                let headingToRemove = ["Main Title", "NoMT?", "Ngtd.4646", "Ngtd.4675", "Ngtd New", "Ep.Costs"];
                let dataNodesToRemove = ["main_title", "is_no_mt", "ngtd_4646", "ngtd_4675", "is_ngtd_new", "episode_cost"];
                let NodeFieldsToRemove = ["is_no_mt", "is_ngtd_new"];

                let tempHeading = tempConfig?.headings?.filter(item => !headingToRemove.includes(item?.headingLabel));
                let tempDataNodes = tempConfig?.dataNodes?.filter(item => !dataNodesToRemove.includes(item));
                let tempNodeFields = tempConfig?.nodeFields?.filter(item => !NodeFieldsToRemove.includes(item.column));

                EpisodeListConfig.headings = tempHeading;
                EpisodeListConfig.dataNodes = tempDataNodes;
                EpisodeListConfig.nodeFields = tempNodeFields;
                this.setState({ config: EpisodeListConfig })
            }
            displayNgtdPermissionFound = permissions.filter((obj, index) => obj.permission_name === "Columns: 'Ngtd.' columns" && obj.view === 1);
            displayNgtdPermissionFound = displayNgtdPermissionFound.length > 0 ? true : false;
            if (displayNgtdPermissionFound === false) {
                tempConfig = EpisodeListConfig;
                let headingToRemove = ["Ngtd.4646", "Ngtd.4675", "Ngtd New"];
                let dataNodesToRemove = ["ngtd_4646", "ngtd_4675", "is_ngtd_new"];
                let NodeFieldsToRemove = ["is_ngtd_new"];

                let tempHeading = tempConfig?.headings?.filter(item => !headingToRemove.includes(item?.headingLabel));
                let tempDataNodes = tempConfig?.dataNodes?.filter(item => !dataNodesToRemove.includes(item));
                let tempNodeFields = tempConfig?.nodeFields?.filter(item => !NodeFieldsToRemove.includes(item.column));

                EpisodeListConfig.headings = tempHeading;
                EpisodeListConfig.dataNodes = tempDataNodes;
                EpisodeListConfig.nodeFields = tempNodeFields;
                this.setState({ config: EpisodeListConfig })
            }
            displayCSPermissionFound = permissions.filter((obj, index) => obj.permission_name === "Columns: Cue Sheets button" && obj.view === 1);
            displayCSPermissionFound = displayCSPermissionFound.length > 0 ? true : false;
            if (displayCSPermissionFound === false) {
                tempConfig = EpisodeListConfig;
                let headingToRemove = ["CS"];
                let dataNodesToRemove = ["cue_sheet_revision_send_date"];
                let buttonFieldsToRemove = ["cue_sheet_revision_send_date"];

                let tempHeading = tempConfig?.headings?.filter(item => !headingToRemove.includes(item.headingLabel));
                let tempDataNodes = tempConfig?.dataNodes?.filter(item => !dataNodesToRemove.includes(item));
                let tempButtonFields = tempConfig?.buttonFields?.filter(item => !buttonFieldsToRemove.includes(item.column));
                EpisodeListConfig.headings = tempHeading;
                EpisodeListConfig.dataNodes = tempDataNodes;
                EpisodeListConfig.buttonFields = tempButtonFields;
                this.setState({ config: EpisodeListConfig });
            }
            displayEpCostBudgetDiffPermissionFound = permissions.filter((obj, index) => obj.permission_name === "Columns: Ep. Costs / Bgdt. Difference" && obj.view === 1);
            displayEpCostBudgetDiffPermissionFound = displayEpCostBudgetDiffPermissionFound.length > 0 ? true : false;
            if (displayEpCostBudgetDiffPermissionFound === false) {
                tempConfig = EpisodeListConfig;
                let headingToRemove = ["BdgtDiff?", "Ep.Costs"];
                let dataNodesToRemove = ["is_budget_difference", "episode_cost"];
                let NodeFieldsToRemove = ["is_budget_difference"];

                let tempHeading = tempConfig?.headings?.filter(item => !headingToRemove.includes(item.headingLabel));
                let tempDataNodes = tempConfig?.dataNodes?.filter(item => !dataNodesToRemove.includes(item));
                let tempNodeFields = tempConfig?.nodeFields?.filter(item => !NodeFieldsToRemove.includes(item.column));
                EpisodeListConfig.headings = tempHeading;
                EpisodeListConfig.dataNodes = tempDataNodes;
                EpisodeListConfig.nodeFields = tempNodeFields;
                this.setState({ config: EpisodeListConfig });
            }

            displayEFCPermissionFound = permissions.filter((obj, index) => obj.permission_name === "Columns: EFC button" && obj.view === 1);
            displayEFCPermissionFound = displayEFCPermissionFound.length > 0 ? true : false;

            if (displayEFCPermissionFound === false) {
                tempConfig = EpisodeListConfig;
                let headingToRemove = ["EFC"];
                let dataNodesToRemove = ["efc"];
                let nodeFieldsToRemove = ["efc"];
                let inlineEditsToRemove = ["efc"];

                let tempHeading = tempConfig?.headings?.filter(item => !headingToRemove.includes(item.headingLabel));
                let tempDataNodes = tempConfig?.dataNodes?.filter(item => !dataNodesToRemove.includes(item));
                let tempNodeFields = tempConfig?.nodeFields?.filter(item => !nodeFieldsToRemove.includes(item.column));
                let tempInlineEdits = tempConfig?.inlineEdits?.filter(item => !inlineEditsToRemove.includes(item.dataNode));
                EpisodeListConfig.headings = tempHeading;
                EpisodeListConfig.dataNodes = tempDataNodes;
                EpisodeListConfig.nodeFields = tempNodeFields;
                EpisodeListConfig.inlineEdits = tempInlineEdits;
                this.setState({ config: EpisodeListConfig })
            }
            //Can see Batches button in Episode table
            displayBatchPermissionFound = permissions.filter((obj, index) => (obj.permission_name === "Columns: BCH" && obj.view === 1));
            displayBatchPermissionFound = displayBatchPermissionFound.length > 0 ? true : false;

            if (displayBatchPermissionFound === false) {
                tempConfig = EpisodeListConfig;
                let headingToRemove = ["BCH"];
                let dataNodesToRemove = ["bch"];
                let nodeFieldsToRemove = ["bch"];

                let tempHeading = tempConfig?.headings?.filter(item => !headingToRemove.includes(item.headingLabel));
                let tempDataNodes = tempConfig?.dataNodes?.filter(item => !dataNodesToRemove.includes(item));
                let tempNodeFields = tempConfig?.nodeFields?.filter(item => !nodeFieldsToRemove.includes(item.column));

                EpisodeListConfig.headings = tempHeading;
                EpisodeListConfig.dataNodes = tempDataNodes;
                EpisodeListConfig.nodeFields = tempNodeFields;
                this.setState({ config: EpisodeListConfig })
            }
            canCreateEpisodes = permissions.filter((obj, index) => (obj.permission_name === "Create Episode (button+modal)" && obj.view === 1));
            canCreateEpisodes = canCreateEpisodes.length > 0 ? true : false;
            if (canCreateEpisodes === false) {
                tempConfig = EpisodeListConfig;
                let actionsToRemove = ["pen", "times"];
                let tempActions = tempConfig?.actions?.filter(item => !actionsToRemove.includes(item));
                EpisodeListConfig.actions = tempActions;
                this.setState({ config: EpisodeListConfig })
            }

            displayEpisodeGridPermissionFound = (displayColGlTitleAirMixNoteCmplt || displayBatchPermissionFound || displayMainTitleNoMtNgtdEpCost || displayNgtdPermissionFound || displayCSPermissionFound || displayEpCostBudgetDiffPermissionFound);
        }
        this.setState({commonPermissions:permissions})
    }

    componentDidMount() {
        this.getPermissions(this.props.permissionArr);
        // this.fetchData("showSeasonPilot", `/showSeasonPilot?show_season_id=${this.state.show_season_id}`);
        this.fetchData("seasonOptions", `/entitySearch?entity=SEASON&searchString=null`);
        this.fetchData("productionEntityOptions", `/entitySearch?entity=PRODUCTION_ENTITY&searchString=null`);
        this.fetchData("typeOptions", `/staticData?staticTable=division`);
        this.fetchData("networkOptions", `/staticData?staticTable=lu_network`);
        this.fetchData("durationOptions", `/entitySearch?entity=SHOW_DURATIONS&searchString=null`);
        this.fetchData("prodExecOptions", `/entitySearch?entity=PRODUCTION_EXECUTIVE&searchString=null`);
        this.fetchData("rightsOptions", `/entitySearch?entity=RIGHTS&searchString=null`);
        this.fetchData("clearanceRepOptions", `/entitySearch?entity=USER&searchString=null`);
        this.fetchData("costCenterOptions", `/entitySearch?entity=COST_CENTER&searchString=null`);
        this.fetchData("termOptions", `/entitySearch?entity=TERM&searchString=null`);
        this.fetchData("territoryOptions", `/entitySearch?entity=TERRITORY&searchString=null`);
        this.fetchData("episodeData", `/showSeasonEpisodes?show_season_id=${this.state.show_season_id}`);

    }
}

export default withUserContext(ShowSeasonTabDetails);
