export const publisherConfig = {
	"publisher_id": null,
	"publisher_name": null,
	"affiliation_id": null,
	"affiliation_name": null,
	"percentage": null,
	"index": 1,
    "is_delete": 0,
    "selected_publisher": null,
    "selected_affiliation": null
}

export const initialPublishers = {
    publishers: [{
        "publisher_name": "ShowTime Songs Inc.",
        "publisher_id": 1,
        "selected_publisher": { value: 1, text: "ShowTime Songs Inc." },
        "affiliation_name": "ASCAP",
        "selected_affiliation": { value: 1, text: "ASCAP" },
        "affiliation_id": 1,
        "pct_value": '30.00',
    }, {
        "publisher_name": "ShowTime Songs Inc.",
        "publisher_id": 2,
        "selected_publisher": { value: 2, text: "BossHouse Music LLC." },
        "affiliation_name": "BMI",
        "selected_affiliation": { value: 2, text: "BMI" },
        "affiliation_id": 2,
        "pct_value": '50.00'
    },
    {
        "publisher_name": null,
        "publisher_id": null,
        "selected_publisher": {},
        "affiliation_name": "ASCAP",
        "selected_affiliation": {},
        "affiliation_id": null,
        "pct_value": '',
    }]
}
