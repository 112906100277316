export const cueSheetComposerCueConfig = {
    headings: [
        {
            headingLabel: "Composer",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "42%"
        },
        {
            headingLabel: "Publisher",
            inputType: "textarea",
            icon: "",
            border: true,
            width: "42%"
        },
        {
            headingLabel: "Default",
            inputType: "checkbox",
            border: true,
            icon: "",
            width: "7%"
        }
    ],
    dataNodes: ["composer", "publisher", "is_default"],
    primaryKey: "show_season_composer_id",
    actions: ["pen", "times"],
    isClearFields: true,
    nodeFields: [
        {
            column: "is_default",
            icon: "check"
        },
    ],
    inlineEdits: [
        {
            dataNode: "composer",
            icon: "arrows-alt-v",
            width: "42%"
        },
        {
            dataNode: "publisher",
            icon: "arrows-alt-v",
            width: "40%"
        },
        {
            dataNode: "is_default",
            width: "7%"
        },
    ],

};

export const initialShowSeasonComposerConfig = {
    "is_delete": 0,
    "show_season_composer_id": null,
    "show_season_id": null,
    "show_id": null,
    "season_id": null,
    "composer": null,
    "publisher": null,
    "is_default" : 1,
    "selected_season":null
}

