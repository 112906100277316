import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialIntlTVDistributionPerp,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";

class IntlTVDistributionPerp extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      intlTVDistributionPerp: initialIntlTVDistributionPerp,
      seasonOption: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
      seasonIdValues: [],
      showIdValues: []
    };
  }


  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues
    values = filterValues
    this.setState({
      filterValues: values
    })
  }

  seasonIdValues = (seasonIdValues) => {
    let values = this.state.seasonIdValues
    values = seasonIdValues
    this.setState({
      seasonIdValues: values
    })
  }

  setShowId = (showIdValues) => {
    let values = this.state.showIdValues
    values = showIdValues
    this.setState({
      showIdValues: values
    })
  }

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      homeVideoSummary: {
        ...prevState.homeVideoSummary,
        [field]: value,
      },
    }));
  };

  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode });
  }

  setSeasonId = (seasonIdValues) => {
    let seasonIdValue = this.state.seasonIdValues;
    seasonIdValue = seasonIdValues;
    this.setState({
      seasonIdValues: seasonIdValue
    })
  }

  handleTvDistributionPrepReport = () => {
    this.setState({ postInitiated: true, isPosting: true });
    let { seasonIdValues, showIdValues, intlTVDistributionPerp } = this.state;
    let payload = {
      season_ids: seasonIdValues,
      show_ids: showIdValues,
      is_include_inactive_shows : intlTVDistributionPerp?.is_include_inactive_shows,
      is_with_details: intlTVDistributionPerp?.is_with_details,
      is_no_notes : intlTVDistributionPerp?.is_no_notes
    }
    this.props.generateReport(this.props?.reportName, payload).then(response => {
      if (response.data.error) {
        // this.setState({ isPosting: false })
        // this.props.fieldChanged(true, "reportNotFound");
      }
      else {
        let url = response.data;
        let obj = {
          reportName: this.props?.rptOptionAcquisition,
          season: "",
          episode: "",
        }
        // this.setState({ isPosting: false })
        let reportNameFinal = genReportName(obj)
        this.props?.downloadUrl(url, reportNameFinal)
      }
    }, (err) => {
      this.setState({ isPosting: false })
      console.log(err)
      // this.props.fieldChanged(true, "reportFail");
    })
  }

  componentDidMount() {
    this.fetchSeasonsData();
    this.fetchShowsData();
  }

  fetchSeasonsData = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SEASON&searchString=null`,
      this.props?.userContext?.active_tenant?.tenant_id, 1)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.season_id, text: item.season_name }));
        this.setState({ seasonOption: formattedList }, () => { console.log(this.state.seasonOption) });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err);
          this.setState({ isFetchingClearanceDetails: false });
        })
  }

  fetchShowsData = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW&searchString=A`,
      this.props?.userContext?.active_tenant?.tenant_id, 1)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name }));
        this.setState({ showOptions: formattedList }, () => { console.log(this.state.showOptions) });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err);
          this.setState({ isFetchingClearanceDetails: false });
        })
  }

  handleSubmit = () => {
    console.log("submit", this.state.intlTVDistributionPerp);
  };

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      intlTVDistributionPerp: {
        ...prevState.intlTVDistributionPerp,
        [field]: value,
      },
    }));
  };

  GenerateCleranceDetails = () => { };
  render() {
    return (
      <>
        {" "}
        <p>
          <b>Intl. TV Distribution - Extend Initial Into Perp</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
                // filterValues={this.state.filterValues}
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
          <MDBCol md={7}>
              <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                setSeasonId={this.setSeasonId}
                setShowId={this.setShowId} />
            </MDBCol>
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Include Inactive Shows"
                label={"Include Inactive Shows"}
                checked={
                  this.state.intlTVDistributionPerp
                    ?.is_include_inactive_shows || false
                }
                onChange={(e) =>
                  this.handleChange(
                    "is_include_inactive_shows",
                    e.target.checked
                  )
                }
              />
            </MDBCol>
            <MDBCol md={1}>
              <br />
              <BasicCheckbox
                id="With Details"
                label={"With Details"}
                checked={
                  this.state.intlTVDistributionPerp?.is_with_details || false
                }
                onChange={(e) =>
                  this.handleChange("is_with_details", e.target.checked)
                }
              />
            </MDBCol>
            <MDBCol md={1}>
              <br />
              <BasicCheckbox
                id="No Notes"
                label={"No Notes"}
                checked={
                  this.state.intlTVDistributionPerp?.is_no_notes || false
                }
                onChange={(e) =>
                  this.handleChange("is_no_notes", e.target.checked)
                }
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={"Generate PDF"} onClick={this.handleTvDistributionPrepReport} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default withUserContext(IntlTVDistributionPerp);
