export const initialContactConfig = {
    "is_default": null,
    "licensor_id": null,
    "full_name": null,
    "licensor_contact_id": null,
    "licensor_contact_name": null,
    "selected_contact": null
}

export const contactConfig = {
    full_name: null,
    licensor_contact_id: null
}
