import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import { intialShowSeasonRecords } from './config';
import CircularProgress from "@material-ui/core/CircularProgress";
import "./NewSeason.scss";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
import messages from '../../../Common/Messages.json';
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import axios from 'axios';
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";

class NewShowSeasonModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postInitiated: false,
      showSeasonRecordData: JSON.parse(JSON.stringify({ ...intialShowSeasonRecords })),
      showsOptions: [],
      seasonOptions: [],
      isLoading: false,
      fieldChanged: false,
      showSuccess: false,
      showFail: false,
      searchCancelToken: null,
      isPosting: false,
      postInitiated:false,
      divisionOptions: [],
      allSeasonOption: [],
      divisionId: null
    };
  }

  componentDidMount() {
    this.getAllSeasonList();
    this.getDivisions();
    this.getShowLists('');
  }

  getShowLists = (value) => {
    this.setState({ loadingShows: true });
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request.");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=SHOW&searchString=${value}`, cancelToken,
      this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response?.data?.map(item => ({ value: item.show_id, text: item.show_name, synopsis: item.synopsis, division_id: item.division_id }));
        this.setState({ showsOptions: formattedList ,loadingShows: false})
      },
        (err) => {
          console.log("Error in fetching entities:", err)
        })
  }

  getDivisions = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + '/staticData?staticTable=division', this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
        this.setState({ divisionOptions: formattedList, });
      },
        (err) => {
          console.log("Error in fetching Tenant Details:", err)
        })

  }

  getAllSeasonList = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SEASON&searchString=${null}`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response?.data?.map(item => ({ value: item.season_id, label: item.season_name }));
        this.setState({ allSeasonOption: formattedList })
      },
        (err) => {
          console.log("Error in fetching entities:", err)
        })
  }

  getSeasons = (showId) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/seasonsBasedOnShows?show_id=${showId}`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data[0]?.seasons?.map(item => item?.season_id);
        let showList = this.state.allSeasonOption?.filter(obj1 => !formattedList?.includes(obj1?.value))
        this.setState({ seasonOptions: showList });
      },
        (err) => {
          console.log("Error in fetching Tenant Details:", err)
        })


  }

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      showSeasonRecordData: {
        ...prevState.showSeasonRecordData,
        [field]: value,
      },
      postInitiated: false,
    }));
    if (field === 'show_id') {
      if (value) {
        this.getSeasons(value)
      } else {
        this.setState({ seasonOptions: [], divisionId: null, showSeasonRecordData: JSON.parse(JSON.stringify({ ...intialShowSeasonRecords })) })
      }
    }
    if (field === 'is_pilot') {
      this.setState((prevState) => ({
        showSeasonRecordData: {
          ...prevState.showSeasonRecordData,
          [field]: value ? 1 : 0,
        }
      }));
    }
    this.props?.fieldChanged(true, "change")
  }
  validatePostJson(){    
    let isValid = this.state.showSeasonRecordData?.show_id && this.state.showSeasonRecordData?.new_season_id;
    if(isValid){
      return this.state.showSeasonRecordData;
    }
    else {
      return false;
    }
  }
  handleSubmit = () => {
    
    this.setState({ isPosting: true,postInitiated:true })
    let postJson = this.validatePostJson();
    if(!postJson){
      this.setState({ isPosting: false })
      return;
    }
    // postJson.show_season_id = this.state.showSeasonRecordData.show_season_id
    // postJson.division_id = this.state.divisionOptions.find((obj1) => this.state.showsOptions.find((obj2) => obj1.value === obj2.division_id)).label
    console.log("dId", postJson.division_id)
    postJson.copy_main_title_flag = 0;
    postJson.copy_business_affairs_flag = 0;
    postJson.pay_increase = 0;
    postJson.mpm = null;
    let extensionUrl = '/copySeason'
    clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.state.divisionId)
      .then((response) => {
        this.setState({ isPosting: false,postInitiated:false })
        if (!response.data.error) {
          // this.props?.fieldChanged(false, "error");
          window.open(`/showseason/${response.data[0].var_new_show_season_id}`, '_blank')
          this.props?.notificationComponent(true, "success")
          console.log("post response", response);
        }
        else {
        
          this.props?.notificationComponent(true, "fail")
        }
      },
        (err) => {
          this.setState({ isPosting: false,postInitiated:true  })
          this.props?.notificationComponent(true, "fail")
          console.log("Post agency details error: " + err);
        });
  }

  render() {
    return (
      <>
        <div className='copyShowSeasonContainer'>
          <MDBContainer className="copyShowSeason">
            {this.state.isLoading ? (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            ) : (<>
             
                <MDBRow>
                  <MDBCol md={8}>
                    <BasicLabel text="Program" isMandatory={true} />
                    <SearchSelectField
                      id={"program"}
                      loading={this.state.loadingShows}
                      placeholder={'- Search -'}
                      options={this.state.showsOptions || []}
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      mandatoryWarning={messages.mandatoryWarning}
                      searchSelect={true}
                      detail_selected={this.state.showSeasonRecordData.show_id ?
                        { value: this.state.showSeasonRecordData.show_id, text: this.state.showSeasonRecordData.show_name } : null}
                      width={'100%'}
                      valueSelected={(e, newValue) => {
                        this.handleChange('show_id', newValue?.value)
                        this.handleChange('show_name', newValue?.text)
                        this.setState({ divisionId: newValue?.division_id })
                      }}
                      searchText={ev => {
                        if (ev.target.value !== "" && ev.target.value !== null) {
                          this.getShowLists(ev.target.value)
                        } else {
                          this.setState({loadingShows:false})
                        }
                      }}
                    />
                  </MDBCol>
                  <MDBCol md={3}>
                    <BasicLabel text={" "} />
                    <BasicCheckbox
                      id={"is_pilot"}
                      label={"Pilot"}
                      checked={this.state.showSeasonRecordData?.is_pilot || false}
                      onChange={(e) => this.handleChange("is_pilot", e.target.checked)}
                    />
                  </MDBCol>
                </MDBRow>
                <br/>
                <MDBRow>
                  <MDBCol md={4}>
                    <BasicLabel text="Type" />
                    <span>{this.state.divisionOptions?.find((item) => item.value === this.state.divisionId)?.label || "-"}</span>
                  </MDBCol>
                  <MDBCol md={8}>
                    <BasicLabel text="Synopsis" />
                    <span>{this.state.showsOptions?.find(item => item?.value === this.state.showSeasonRecordData?.show_id)?.synopsis || "-"}</span>
                  </MDBCol>
                </MDBRow>
                <br/>
                <MDBRow>
                  <MDBCol md={4}>
                    <BasicLabel text="Season" isMandatory={true} />
                    <SelectField
                      width={"100%"}
                      placeHolderText="- Select -"
                      isMandatory={true}
                      showMandatory={this.state.postInitiated}
                      mandatoryWarning={messages.mandatoryWarning}
                      value={this.state.showSeasonRecordData.new_season_id}
                      options={this.state.seasonOptions || []}
                      onChange={(e) => this.handleChange("new_season_id", e.target.value)}
                    />
                  </MDBCol>
                
                </MDBRow>
                <br/>
                <MDBRow>
                  <MDBCol md={8}></MDBCol>
                  <MDBCol md={4}>
                    <MDBRow>
                      <MDBCol>
                        <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Create"} onClick={this.handleSubmit} />
                      </MDBCol>
                      <MDBCol>
                        <BasicButton
                          variant="outlined" text={"Cancel"}
                          onClick={() => this.props.handleClose()}
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>

             
            </>)}
          </MDBContainer>
        </div>

      </>
    );
  }
}
export default withUserContext(NewShowSeasonModal);
