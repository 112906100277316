export const selectOptions = [
    {
        label: "All",
        value: 1,
        searchType : "ALL",
        placeHolder: "All"
    },
    {
        label: "Show Season",
        value: 2,
        searchType : "SHOW_SEASONS",
        permissionName: "Can see Show/Season tab",
        placeHolder: "Search by Name"
    },
    {
        label: "Clearance Records",
        value: 3,
        searchType : "CLEARANCE_RECORDS",
        permissionName: "Can see Clearance Records tab",
        placeHolder: "Search by ID"
    },
    {
        label: "Songs",
        value: 4,
        searchType : "SONGS",
        permissionName: "Can see Songs tab",
        placeHolder: "Search by Song or Performer Name"
    },
    {
        label: "Licensors",
        value: 5,
        searchType : "LICENSORS",
        permissionName: "Can see Licensors tab",
        placeHolder: "Search by Name"
    },
    {
        label: "Payees",
        value: 6,
        searchType : "PAYEES",
        permissionName: "Can see Payees Tab",
        placeHolder: "Search by Name"
    },
    {
        label: "Music Editors",
        value: 7,
        searchType : "MUSIC_EDITORS",
        permissionName: "Can see Music Editors/Show Composers tab",
        placeHolder: "Search by Name"
    },
    {
        label: "Show Composers",
        value: 8,
        searchType : "SHOW_COMPOSERS",
        permissionName: "Can see Music Editors/Show Composers tab",
        placeHolder: "Search by Name"
    }
]


export const searchResultsConfig = {
    headings: [
        {
            headingLabel: "Name",
            icon: ""
        },
        {
            headingLabel: "SubGroup",
            icon: ""
        },
        {
            headingLabel: "Data Type",
            icon: ""
        }
    ],
    dataNodes: ["name", "sub_group", "data_type"],
    primaryKey: "id",
    hyperlinks: ["name"],
    isSearchBar: true
}
