import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import "rc-time-picker/assets/index.css";
import "./Contact.scss";
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import { initialContactConfig } from "./Config";
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import messages from '../../../Common/Messages.json';


class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusOptions: [],
      isFetchingContactStatus: false,
      contactDetails: JSON.parse(JSON.stringify({ ...initialContactConfig })),
      postInitiated: false,
      isPosting: false,
      contactValue: null,
      postData: null
    };
  }

  componentDidMount = () => { 
    this.getLicensorData();
  }
  
  getLicensorData = () => {
    this.setState({isFetchingContactStatus: true})
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      `/showLevelDefaultContact?show_season_id=${this.props.showSeasonId}&licensor_id=${this.props?.licensorId}`,
      this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
      .then(response => {
        if (response.data.error) {
          this.setState({isFetchingContactStatus: false})
        } else {
          this.setState({isFetchingContactStatus: false})
          let formattedList = response.data?.map(item => ({ value: item.licensor_contact_id, text: item.full_name, is_default: item.is_default, is_primary : item?.is_primary}));
          let contactDetails = this.state.contactDetails
          this.setState({ statusOptions: formattedList }, () => {
            let defaultData = this.state.statusOptions.find((item) => item.is_default === 1) ? this.state.statusOptions.find((item) => item.is_default === 1) : this.state.statusOptions.find((item) => item.is_primary === 1)
            contactDetails.licensor_contact_id = defaultData ? defaultData?.value : this.state.statusOptions[0]?.value
            contactDetails.licensor_contact_name = defaultData ? defaultData?.text : this.state.statusOptions[0]?.text
            contactDetails.selected_contact = {value:contactDetails.licensor_contact_id, text: contactDetails.licensor_contact_name}
          })
          this.setState({contactDetails})
        }
       
      })
  }

  
  
  handleChange = (field, value) => {
    let contactData = { ...this.state.contactDetails }
    contactData[field + '_id'] = value?.value || null;
    contactData[field + '_name'] = value?.text || null;
    contactData["selected_contact"] = value || null;
    this.setState({contactDetails: contactData})
    let postValue = {
      [field + '_id']: value?.value || null,
      [field + '_name']:value?.text || null,
      // show_id: this.props.showId,
      show_season_id:this.props.showSeasonId,
      is_active: 1,
      licensor_id: this.props.licensorId,
      is_default: 1,
    }
    this.setState({ postData: postValue })
    
    
  };
  
  ContactDetails = () => {
    this.setState({ postInitiated: true , isPosting: true });
    if (this.validateErrorFlags(this.state.postData)) {
      ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showLevelDefaultContact`,
        this.state.postData,
        this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(
          (response) => {
            console.log("response", response)
            if (response.data.error) {
              this.setState({ isPosting: false })
              this.props?.notificationComponent(true, "fail");

            } else {
              this.setState({ isPosting: false });
              //this.props.fetchPilotMainDetails(this.props.show_season_id);
              //this.props.fetchPilotHeaderData(this.props.show_season_id);
              this.props?.notificationComponent(true, "success"); 
              this.props?.toggleRefreshPage('EpisodeClearance');
              // console.log("this.state.pilotMaintanace?.customize_title", this.state.pilotMaintanace?.customize_title);
              // this.props.handleNonPilotTabValue(this.state.pilotMaintanace?.customize_title, this.state.pilotMaintanace?.is_this_not_pilot);
              // this.props.handleClose();
              // this.getLicensorData(re)
            }
          },
          (err) => {
            this.setState({ postInitiated: false, isPosting: false });
            this.props?.notificationComponent(true, "fail")
          }
        )
    }
  };

  validateErrorFlags = (postEpisodeJson) => {
    let isValid = false;
    let canSubmit = (postEpisodeJson?.licensor_contact_id) && (!isValid) ? true : false;
    console.log("canSubmit", canSubmit)
    return canSubmit;
}


  render() {
    return (
      <>
        {this.state.isFetchingContactStatus ?
          <div className="ContentLoader">
            <CircularProgress />
          </div> :
          <>
            <MDBContainer className="NewGenerateReportContainer">
              <MDBRow className="font-size">
                Change your default contact for {this.props?.licensor_name} and this {this.props?.show_name}
              </MDBRow>
              <MDBRow>
                <MDBCol md={10}>
                  <SearchSelectField
                    label={"Default Contact"}
                    showMandatory={this.state.postInitiated || false}
                    mandatoryWarning={messages.mandatoryWarning}
                    value={this.state.contactDetails.selected_contact}
                    options={this.state.statusOptions || []}
                    onChange={(e,value) => {
                      this.handleChange("licensor_contact",value)
                    }}
                  />
            
                </MDBCol>
              </MDBRow>
              <MDBRow className="p-2">
                <MDBCol md={8}></MDBCol>

                <MDBCol md={2}>
                  <BasicButton
                    className="btnwidth"
                    variant="contained"
                    type="inline"
                    text={
                      this.state.isPosting ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                          "Save"
                        )
                    }
                    disabled={false}
                    onClick={this.ContactDetails}
                  />
                </MDBCol>
                <MDBCol md={2}>
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text={"Cancel"}
                    onClick={this.props?.handleClose}
                    disabled={false}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </>
        }
      </>
    );
  }
}

export default withUserContext(Contact);