import React, { Component } from 'react';
import { MDBContainer, MDBRow } from "mdbreact";
import TableComponent from '../../SharedComponents/Table'
import { LicensorPayeeConfig } from '../Licensor/Config'
import TablePagination from '@material-ui/core/TablePagination';
import { handlePaginationList, updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import './Licensor.scss';

class LicensorPayee extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 20,
            page: 0,
            licensorData: {...this.props?.licensorData},
            payees_options: [...this.props?.payees_options],
            renderList: handlePaginationList(0, 20,this.props?.licensorData?.licensor_payees),
            config: LicensorPayeeConfig,
            isSortByAsc: true,
            sortCount: 0,
            sortBy: "Value"
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.props?.licensorData?.licensor_payees) });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10), page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.props?.licensorData?.licensor_payees)
        });
    }

    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                if (item.licensor_payee_id == dataItem.licensor_payee_id)
                    newItem['editing'] = true
                return newItem
            })
        })
        this.props?.fieldChanged(true, "error")
    }

    handleReadCallback = (index) => {
        let allDetails = [...this.state.renderList]
        allDetails[index].editing = false
        this.setState({ renderList: allDetails })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        let allDetails = [...this.state.renderList]
        let licensorData = {...this.state.licensorData}
        //let payeeName = this.props?.payees_options?.find(item => item.licensor_payee_id == dataItem.licensor_payee_id)?.value
        allDetails[index].editing = false 
        allDetails[index].payee_name = data[index]?.payee_remit_to?.text,
        allDetails[index].is_auto_pop = data[index].is_auto_pop ? 1 : 0
        allDetails[index].is_default = data[index].is_default ? 1 : 0
        allDetails[index].tax_ssn = data[index].tax_ssn
        allDetails[index].licensor_payee_id =  data[index].licensor_payee_id
        allDetails[index].payee_id = data[index]?.payee_remit_to?.payee_id
        allDetails[index].payee_remit_to = data[index]?.payee_remit_to
        allDetails[index].payee_remit_to_name = data[index]?.payee_remit_to?.text,
        allDetails[index].payee_remit_to_id = data[index]?.payee_remit_to?.value   
        allDetails[index].vendor = data[index].vendor
        
        licensorData.licensor_payees = allDetails
        licensorData.is_payees_updated = 1  
        this.props.changeParentValue(licensorData)
        this.setState({ renderList: allDetails, licensorData: licensorData })
        this.props?.fieldChanged(true, "error")
    }

    handleDeleteItem = (dataItem) => {
        let allDetails = [...this.state.renderList]
        let licensorData = {...this.state.licensorData}
        licensorData.is_payees_updated = 1
        let selectedIndex = allDetails.findIndex(item => item.licensor_payee_id === dataItem)
        allDetails.splice(selectedIndex, 1)
        licensorData.licensor_payees = allDetails
        this.props.changeParentValue(licensorData)
        this.setState({ renderList: allDetails, licensorData: licensorData })
        this.props?.fieldChanged(true, "error")
    }

    handleAddNewItem = (newDataItem) => {
        let allDetails = [...this.state.renderList]
        let licensorData = {...this.state.licensorData}
        let addList = {
            payee_name :  newDataItem?.payee_remit_to?.text,
            is_auto_pop : newDataItem.is_auto_pop ? 1 : 0 ,
            is_default : newDataItem.is_default ? 1 : 0,
            tax_ssn : newDataItem.tax_ssn,
            vendor : newDataItem.vendor,
            licensor_payee_id: 'new'+Date.now(),
            payee_id: newDataItem?.payee_remit_to?.payee_id,
            payee_remit_to : newDataItem?.payee_remit_to,
            payee_remit_to_name : newDataItem?.payee_remit_to?.text,    
            payee_remit_to_id : newDataItem?.payee_remit_to?.value       
        }
        allDetails.push(addList)
        licensorData.licensor_payees = allDetails
        this.props.changeParentValue(licensorData)
        licensorData.is_payees_updated = 1
        this.setState({ renderList: allDetails, licensorData: licensorData })
        this.props?.fieldChanged(true, "error")
    }
    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(LicensorPayeeConfig, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: handlePaginationList(this.state.page, this.state.rowsPerPage,
                    tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc))
            });
        })
    }
    getSortNode = (col) => {
        switch (col) {
            case "Payee Name": return "payee_remit_to";
        }
    }


    render() {
        LicensorPayeeConfig.headings[0].selectOptions = this.props?.payees_options?.filter(item => !this.state.renderList?.map(ele => ele?.payee_remit_to_id)?.includes(item?.value))
        return (
            <MDBContainer fluid className="ModalTableContainer" disabled={this.props?.disabled}>
                <MDBRow className='g-0 licensorCLRecords'>
                    {LicensorPayeeConfig.headings[0].selectOptions.length > 0 && 
                    <TableComponent
                        list={this.state.renderList || []}
                        className={"agentList"}
                        config={this.state.config}
                        editCallback={this.handleInlineEdits.bind(this)}
                        saveCallback={this.saveInlineEdits.bind(this)}
                        handleAddNewItem={this.handleAddNewItem.bind(this)}
                        handleDelCallBack={this.handleDeleteItem.bind(this)}
                        isLoading={this.props.isLoading || false}
                        addItemToList={true}
                        readCallback={this.handleReadCallback.bind(this)}
                    // hyperLinkNavigationCallback={this.navigationCallback}
                        sortCallback={this.handleTableColumnClick} 
                        updateTaxSSNVendor={true}
                    />}
                </MDBRow>
                <MDBRow className="Pagination">
                    <TablePagination
                        component="div"
                        count={this.state.renderList ? this.state.licensorData?.licensor_payees.length : 0}
                        rowsPerPageOptions={[20,30,50,100]}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        rowsPerPage={this.state.rowsPerPage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </MDBRow>
            </MDBContainer>
        );
    }
}


export default LicensorPayee;