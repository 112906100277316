export const newDetailObject = {
    "season_id": null,
    "name": null,
    "email": null,
    "company": null,
    "type": null,
}
export const newEFCDetailObject = {
    "season_id": null,
    "efc_recipient_name": null,
    "efc_recipient_email": null,
    "type": null,
}
export const newCueDetailObject = {
    "season_id": null,
    "name": null,
    "email": null,
    "type": null,
}
export const peopleTabs = [
    {
        label: "Budget vs. EFC Recipients",
        value: 1
    },
    {
        label: "EFC Recipients",
        value: 2
    },
    {
        label: "Cue Sheet Recipients",
        value: 3
    }
]
export const budgetVsEFCConfig = {
    headings: [
        {
            headingLabel: "Name",
            inputType: "text",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Email",
            inputType: "text",
            border: true,
            width: "20%",
        },
        {
            headingLabel: "Company",
            inputType: "text",
            border: true,
            width: "50%",
        }
    ],
    dataNodes: ["name", "email", "company"],
    primaryKey: "id",
    actions: ["pen", "times"],
    textWithbuttonFields: [],
    nodeFields: [],
    inlineEdits: [
        {
            dataNode: "name",
            width: "20%"
        },
        {
            dataNode: "email",
            width: "20%",
            validateMethod: 'validateEmail',
            validateMessage: 'Please enter valid email.'
        },
        {
            dataNode: "company",
            width: "50%"
        },
    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    buttonFields: []
};
export const efcConfig = {
    headings: [
        {
            headingLabel: "Name",
            inputType: "text",
            border: true,
            width: "42%"
        },
        {
            headingLabel: "Email",
            inputType: "text",
            border: true,
            width: "42%",
        },
    ],
    dataNodes: ["efc_recipient_name", "efc_recipient_email"],
    primaryKey: "id",
    actions: ["pen", "times"],
    textWithbuttonFields: [],
    nodeFields: [],
    inlineEdits: [
        {
            dataNode: "efc_recipient_name",
            width: "42%"
        },
        {
            dataNode: "efc_recipient_email",
            width: "42%",
            validateMethod: 'validateEmail',
            validateMessage: 'Please enter valid email.'
        },
    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    buttonFields: []
};
export const cueConfig = {
    headings: [
        {
            headingLabel: "Name",
            inputType: "text",
            border: true,
            width: "30%"
        },
        {
            headingLabel: "Email",
            inputType: "text",
            border: true,
            width: "35%",
        },
        {
            headingLabel: "Comapny",
            inputType: "text",
            border: true,
            width: "30%",
        },    ],
    dataNodes: ["name", "email", "company"],
    primaryKey: "id",
    actions: ["pen", "times"],
    textWithbuttonFields: [],
    nodeFields: [],
    inlineEdits: [
        {
            dataNode: "name",
            width: "30%"
        },
        {
            dataNode: "email",
            width: "35%",
            validateMethod: 'validateEmail',
            validateMessage: 'Please enter valid email.'
        },
        {
            dataNode: "company",
            width: "30%"
        },
    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
    buttonFields: []
};

export const budgetefcRecipientRecordConfig = {
    headings: [
        {
            headingLabel: "Name",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "Email",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "Company",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "WB?",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Horizon?",
            width: "5%",
            inputType: "checkbox"
        },

    ],
    dataNodes: ["name", "email","company","is_wb","is_horizon"],
    primaryKey: "id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "name",
            width: "25%"
        },
        {
            dataNode: "email",
            width: "25%",
            validateMethod : 'validateEmail',
            validateMessage : 'Please enter valid Email'
        },
        {
            dataNode: "company",
            width: "25%"
        },
        {
            dataNode: "is_wb",
            width: "5%"
        },
        {
            dataNode: "is_horizon",
            width: "5%"
        },
    ],
    nodeFields: [
        
        {
            column: "is_wb",
            icon: "check"
        },
        {
            column: "is_horizon",
            icon: "check"
        },
        
    ],
}

