import React from 'react';
import { MDBModal, MDBModalBody, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from '../../components/SharedComponents/BasicButton/BasicButton';
import { withUserContext } from '../../contexts/UserContext';
import './UsersReport.scss';
import exportFromJSON from 'export-from-json';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default withUserContext(class UsersReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usersData: props.usersData,
            filterInputs: props?.filterInputs
        }
    }
    generatePDF = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "landscape"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        //doc.setFontSize(15);
        const title = "Users Report";
        const headers = [["Name", "Display Name","Tenant", "Division", "Role", "Email", "Phone", "Status"]];
        const data = this.state.usersData.map(item => [item.name, item.displayName, item.tenant?.toString().replace(/,+/g,','), item.division?.toString().replace(/,+/g,','), item.role?.toString().replace(/,+/g,','), item.email, item.phone, item.status]);
        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("UsersReport.pdf")
    }
    generateExcel = () => {
        let excelData = this.state.usersData.map(item => {
            return {
                "Name": item.name ? item.name : " ",
                "Display Name": item.displayName ? item.displayName : " ",
                "Tenant": item.tenant?.length ? item.tenant.toString().replace(/,+/g,',') : " ",
                "Division": item.division?.length ? item.division.toString().replace(/,+/g,',') : " ",
                "Role": item.role?.length ? item.role.toString().replace(/,+/g,',') : " ",
                "Email": item.email ? item.email : " ",
                "Phone": item.phone ? "'"+ item.phone : " ",
                "Status": item.status ? item.status : " "
            }
        })
        exportFromJSON({ data: excelData, fileName: 'UsersReport', exportType: exportFromJSON.types.xls })
    }
    render() {
        return (
            <div className="MainViewModalContainer1">
                <MDBModal staticBackdrop closeOnEsc={false} show={this.props?.open}>
                    <MDBModalDialog size="sm">
                        <MDBModalBody>
                            <MDBModalContent>
                                <MDBContainer fluid className="padding-hor-12">
                                    <MDBRow className="ModalContent">
                                        <MDBCol>
                                            <MDBRow className="ModalHeader" id={"modal_header"}>
                                                <MDBCol md={5} className="HeaderText">Users Report</MDBCol>
                                                <MDBCol md={7} className="HeaderText" ></MDBCol>
                                            </MDBRow>
                                            <MDBRow className="ModalBody">
                                                <MDBCol md={12} className="Content">
                                                    <MDBRow>Report will run based on the filters added to the users table.</MDBRow>
                                                    <MDBRow className="boldHeader">Added Filters</MDBRow>
                                                    {this.state.filterInputs?.map(item => <MDBRow>{item}</MDBRow>)}
                                                    <MDBRow>
                                                        <MDBCol md={6}></MDBCol>
                                                        <MDBCol md={2}>
                                                            <BasicButton
                                                                variant="contained"
                                                                text="PDF"
                                                                onClick={() => this.generatePDF()}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={2} className="button2">
                                                            <BasicButton
                                                                variant="contained"
                                                                text="Excel"
                                                                onClick={() => this.generateExcel()}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={2} className="button3">
                                                            <BasicButton
                                                                variant="Outline"
                                                                text="Close"
                                                                onClick={() => this.props?.handleClose()}
                                                            />
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBModalContent>
                        </MDBModalBody>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        )
    }
});