import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import TableComponent from '../../SharedComponents/Table';
import { notAcknowledeHeading } from './config';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';


import './CueSheetAcknowledgementWarnings.scss';

class NotAcknowledged extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: JSON.parse(JSON.stringify({ ...notAcknowledeHeading })),
            renderList: [],
            isLoading:false,
        }
    }

    componentDidMount = () => {
        this.getData()
     } 
 
     getData = (show_all_reps = 0) => {
        this.setState({isLoading:true})
         ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
             `/cueSheetAck?type=INITIATED&show_all_reps=${show_all_reps}`,
             this.props.userContext?.active_tenant?.tenant_id, null)
             .then(response => {
                 this.setState({ renderList : response.data,isLoading:false })
             })
     }

    render() {
        return (
            <div id="warnigs">
                <div className='between mt-2'>
                    <div>
                        <span className='ml5'>The Cue Sheets below were submitted to rapid cue but have not been acknowledged yet</span>
                    </div>
                    <div className='p5 p-r2'>
                        <div>
                            <BasicCheckbox
                                id={"custom"}
                                label='Show All Clearance Reps'
                                checked={this.state.showAllReps}
                                onChange={(e) =>
                                   this.setState({showAllReps : e.target.checked}, () => {
                                        this.getData(this.state.showAllReps ?  1 : 0)
                                   })
                                }
                            />
                        </div>
                    </div>
                </div>
                <div id="bg-header" className='table-component mt-2'>
                    <TableComponent
                        list={this.state.renderList || []}
                        config={this.state.config}
                        isLoading={this.state.isLoading}
                    />
                </div>
                <MDBRow className='mt-2'>
                    <MDBCol sm={10}>

                    </MDBCol>
                    <MDBCol sm={2}>
                        <BasicButton
                            onClick={() => this.props.handleClose()}
                            variant="outlined"
                            text={"Close"}
                        />
                    </MDBCol>
                </MDBRow>
            </div>

        )
    }
}

export default withUserContext(NotAcknowledged);
