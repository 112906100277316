export const rightsFilter = {
    rightsType: null,
    term: null,
    expireDate: null,
    licensor: null,
    territory: null,
    acquired: null,
    expiring: null,
    optExpire: null,
    show: null,
    episodeName: null,
    season: null,
    showYear: null,
    episode: null
}

export const rightsRecordJson = { 
    right_type_search_ids:null,
    licensor_search_ids:null,
    expiring_search_ids:null,
    term_search_ids:null,
    territory_search_ids:null,
    opt_expire_search_ids:null,
    expire_date_search_text:null,
    acquired_search_flag:null,
    show_search_ids:null,
    season_search_ids: null,
    show_year_search_text:null,
    episode_title_search_text:null,
    episode_no_search_text:null,
    order_by_clause: "Show",
    order_by: null,
    page_count: 25,
    page_no:1
}

export const filterConfig = {
    show: {id: 'show_id', name: 'show_name'},
    season: {id: 'season_id', name: 'season_name'},
    term: {id: 'term_id', name: 'term_name'},
    territory: {id: 'territory_id', name: 'territory_abbr'},
    licensor: { id: 'licensor_id', name: 'licensor_name'}
}

export const rightsTblConfig = {
    headings: [{
        headingLabel: "Show",
        icon: "",
        width: "15%",
        dataNode:"show_name"
    },
    {
        headingLabel: "Season",
        icon: "",
        width: "10%",
        dataNode:"season_name"
    },
    {
        headingLabel: "Episode",
        icon: "",
        width: "10%",
        dataNode:"episode_number"
    },
    {
        headingLabel: "Acq. Date",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Rights",
        icon: "",
        width: "17%"
    },
    {
        headingLabel: "Term/Territory",
        icon: "",
        width: "13%"
    },
    {
        headingLabel: "Exp. Date",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Composition",
        icon: "",
        width: "20%"
    },
    {
        headingLabel: "S/M",
        icon: "",
        width: "5%"
    },
    {
        headingLabel: "Fee",
        icon: "",
        width: "7%"
    },
    {
        headingLabel: "",
        icon: "",
        width: "5%"
    }
    ], dataNodes: ["show_name", "season_name", "episode_number", "option_acquired_date", "right_name", "term_territory", "exp_date", "composition", "licensor_type", "fee"],
    actions: ["share"],
    primaryKey: "right_id",
    hyperlinks: ["season_name", "episode_number"]
}

export const rightsDataConfig = [
    {
        id: 1,
        show: "Shameless",
        season: "2017-2018",
        episode: "U13.14552",
        acqDate: "MM/DD/YYYY",
        rights: "Initial-ALL MEDIA 06/07 X-THEA",
        term: "Perp, WW",
        expDate: "Perp",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "S",
        fee: "$17,000"
    },
    {
        id: 2,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "Option - 1 YR DOWNLOAD",
        term: "1year, WW",
        expDate: "4/30/2022",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "M",
        fee: "$5,000"
    },
    {
        id: 3,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "Alt Init - ALL MEDIA 06/07 X-HV",
        term: "Prep, WW",
        expDate: "Prep",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "M",
        fee: "[Fee]"
    },
    {
        id: 4,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "[Type]-[Option]",
        term: "[Term], [Territory]",
        expDate: "[Prep/Date]",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "[S/M]",
        fee: "[Fee]"
    },
    {
        id: 5,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "[Type]-[Option]",
        term: "[Term], [Territory]",
        expDate: "[Prep/Date]",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "[S/M]",
        fee: "[Fee]"
    },
    {
        id: 6,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "[Type]-[Option]",
        term: "[Term], [Territory]",
        expDate: "[Prep/Date]",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "[S/M]",
        fee: "[Fee]"
    },
    {
        id: 7,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "[Type]-[Option]",
        term: "[Term], [Territory]",
        expDate: "[Prep/Date]",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "[S/M]",
        fee: "[Fee]"
    },
    {
        id: 8,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "[Type]-[Option]",
        term: "[Term], [Territory]",
        expDate: "[Prep/Date]",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "[S/M]",
        fee: "[Fee]"
    },
    {
        id: 9,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "[Type]-[Option]",
        term: "[Term], [Territory]",
        expDate: "[Prep/Date]",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "[S/M]",
        fee: "[Fee]"
    },
    {
        id: 10,
        show: "[Show Name]",
        season: "[Show Years]",
        episode: "[Episode #]",
        acqDate: "MM/DD/YYYY",
        rights: "[Type]-[Option]",
        term: "[Term], [Territory]",
        expDate: "[Prep/Date]",
        composition: "[Song Name] by [Performer(s)]",
        sOrM: "[S/M]",
        fee: "[Fee]"
    }
]
