export const ProducerContacts = {
    "show_season_producers": [],
    "publicist_name": null,
    "publicist_email": null,
    "marketing_contact_name": null,
    "marketing_contact_email": null,
}


export const contactItem = {
    "producer_name": null,
    "producer_email": null,
    "is_primary": 0,
    "season_id": 1,
    "show_id": 1
}

