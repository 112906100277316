import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
} from "mdb-react-ui-kit";
import {withUserContext} from '../../contexts/UserContext';
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import TableComponent from "../SharedComponents/Table";
import BasicTextArea from "../SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../SharedComponents/SelectField/SelectField";
import ClearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { initialRightsDetails } from "./config";
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class ExistingRights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      existingRights: [],
      rightsDetails: {...initialRightsDetails},
      isLoading: false,
      termOptionDetails: [],
      expirationTermOptions: [],
      territoryOptionDetails: [],
      existingCostCenters: [],
      mode: "default",
      selectedRights: null,
      allExistingRights:[],
      showInactive: 0,
      sortBy: "Value",
      isSortByAsc: true,
      sortCount: 0,
      postInitialted:false,
    };
  }

  componentDidMount() {
    this.getExistingRights("RIGHTS", ""),
    this.getExistingCostCenter("COST_CENTER", ""),
    this.getTermOptions("TERM", "");
    this.getTerritoryOptions("TERRITORY", "");
    this.handleTableColumnClick("Cost Centers");
  }
  getExistingRights = (entityType, searchString, currentItem =null) => {
    this.setState({ isExistRightsLoading: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${entityType}&searchString=${searchString}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let activeRights=[]
        res.data.map((item) => {
          if (this.state.showInactive === 0 && item.is_active === 1) {
            activeRights.push(item)
          }
          else if (this.state.showInactive === 1 && item.is_active === 0) {
            activeRights.push(item)
          }
        })
        this.setState({
          existingRights: activeRights,
          isExistRightsLoading: false,
          allExistingRights: res.data,
          showInactive: 0,
        },() => {
          this.handleUpdateExistingRights(currentItem ? currentItem : activeRights[0]?.right_id)
        });   
      })
      .catch((err) => {
        this.setState({ isExistRightsLoading: false });
      });
  };
  getExistingCostCenter = (entityType, searchString) => {
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${entityType}&searchString=${searchString}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        this.setState({ existingCostCenters: res.data});
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  getTermOptions = (termData, searchString) => {
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${termData}&searchString=${searchString}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let termDetails = res.data?.map((item) => ({
          value: item?.term_id,
          label: item?.term_name,
        }));
        this.setState({
          termOptionDetails: termDetails,
          expirationTermOptions: termDetails,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  getTerritoryOptions = (territoryData, searchString) => {
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${territoryData}&searchString=${searchString}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let territoryDetails = res.data?.map((item) => ({
          value: item?.territory_id,
          label: item?.territory_abbr,
        }));
        this.setState({ territoryOptionDetails: territoryDetails });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleUpdateExistingRights = (id) => {
      this.setState({ isLoading: true, rightsDetails: { ...initialRightsDetails },selectedRights:id });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + `/rights?right_id=${id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        this.setState({
          rightsDetails: res.data[0],
          isLoading: false,
          postInitialted:false,
          mode: "edit",
        });
        this.handleShowInlineCheckBox(res.data[0]);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  handleShowInlineCheckBox = (CostCenterData) => {
    let costCenterList = [...CostCenterData?.cost_centers_list];
    let updateCostCenterList = []
    if (costCenterList?.length > 0) {
      updateCostCenterList = this.state.existingCostCenters?.map(item => {
        let newItem = {...item}
        if(costCenterList?.find(ele => ele?.cost_center_id === item?.cost_center_id)){
          newItem.check = true;
        } else {
          newItem.check = false;
        }
        return newItem
      })
    } else {
      updateCostCenterList = this.state.existingCostCenters?.map((item) => {
        let newItem = {...item}
        newItem.check = false;
        return newItem
      });
    }
    let rightsDetailData = { ...this.state.rightsDetails };
    rightsDetailData.cost_centers_list = updateCostCenterList;
    this.setState({ rightsDetails: rightsDetailData });
  };

  arrayCheckUncheck = (id, dataItem) => {
    let updatedcost_centers_list = this.state.rightsDetails?.cost_centers_list?.map(item => {
      let newItem = {...item} 
      if(item?.cost_center_id === dataItem?.cost_centers_id) {
        newItem.check = id
      }
      return newItem
    });
    let updatedRightsDetailData = {...this.state.rightsDetails}
    updatedRightsDetailData.is_cost_centers_updated = 1;
    updatedRightsDetailData.cost_centers_list = updatedcost_centers_list
    this.setState({
      rightsDetails: updatedRightsDetailData,
    });
  };

  handleGeneralEdit = (field, value) => {
    this.setState(prevState => ({
      rightsDetails: {
          ...prevState.rightsDetails,
          [field]: value
      },
    }))
  };

  handleSubmit = () => {
    this.setState({postInitialted:true})
    if(this.state.rightsDetails?.right_abbr && this.state.rightsDetails?.default_territory_id 
      && this.state.rightsDetails?.default_term_id && this.state.rightsDetails?.default_exp_term_id){
    let rightsDetailData = { ...this.state.rightsDetails };
    rightsDetailData.cost_centers_list = this.state.rightsDetails?.cost_centers_list?.filter(item => item?.check);
    rightsDetailData.is_optional = rightsDetailData?.is_initial_right ? 0 : 1;
    this.setState({ isSubmit: true });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/rights`,
      rightsDetailData,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false });
          this.props?.toastMessageNotification(true, "fail");
        } else {
          this.setState({
            isSubmit: false,
            mode: "default"
          });
          this.props?.toastMessageNotification(true, "success");
          if(!rightsDetailData?.right_id) {
            this.getExistingRights("RIGHTS", "",response?.data[0]?.right_id)
          } else {
              let editedItem = this.state?.allExistingRights?.find(item => item?.right_id === rightsDetailData?.right_id)
              if((editedItem?.is_default === rightsDetailData?.is_default) && (editedItem?.right_abbr === rightsDetailData?.right_abbr))
                { this.handleUpdateExistingRights(response?.data[0]?.right_id) }
              else  {
                this.getExistingRights("RIGHTS", "",response?.data[0]?.right_id)
              }
          }  
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props?.toastMessageNotification(true, "fail");
      }
    )};
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      config: updateTableSortConfigObject(this.props?.config, this.state.isSortByAsc, this.state.sortBy, colName)
    }, () => {
      this.setState({
        sortBy: colName,
        costCenterList: tableSortList("Name", this.getSortNode(colName), this.state.rightsDetails?.cost_centers_list, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
        case "Cost Centers": return "cost_center_name";
    }
  }
  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="existingRightsContainer">
        <MDBRow>
          <span className="description">
          Define selectable Rights for Clearance and MT records
          </span>
        </MDBRow>
        <MDBRow>
          <MDBCol md={3} className="existing-records-heading">
              <MDBRow>
                <MDBCol md={5} lg={5} xl={5}>
                  <BasicLabel type={"darkFieldHeading"} text="Existing Rights" />
                </MDBCol>
                <MDBCol md={5} lg={5} xl={5}>
                  <BasicCheckbox
                    id={"show-inactive"}
                    label="Show Inactive"
                    checked={this.state.showInactive === 1}
                    onChange={(e) => {
                      if(e.target.checked) {
                        this.setState({showInactive : 1, existingRights: this.state.allExistingRights?.filter(ele => ele?.is_active === 0)}, () => {
                          this.handleUpdateExistingRights(this.state.existingRights[0]?.right_id)
                        })
                      } else {
                        this.setState({showInactive : 0, existingRights: this.state.allExistingRights?.filter(ele => ele?.is_active === 1)}, () => {
                          this.handleUpdateExistingRights(this.state.existingRights[0]?.right_id)
                        })
                      }
                    }}
                  />
                </MDBCol>
                <MDBCol className="add-existing-right-btn" md={2}>
                  {permissionValue.edit === 1 &&
                  <BasicButton
                    type="inline"
                    color="primary"
                    text={" "}
                    icon={"plus-circle"}
                    onClick={() => {
                      let initialRightsDetail = { ...initialRightsDetails }
                      initialRightsDetail.cost_centers_list = this.state.existingCostCenters?.map(item => {
                        let newItem = {...item} 
                        newItem.check = false
                        return newItem
                      })
                      this.setState({ rightsDetails: initialRightsDetail ,selectedRights:null }) 
                    }}
                  />}
                </MDBCol>
              </MDBRow>
            {this.state.isExistRightsLoading ? (
              <CircularProgress size={24} />
            ) : (
              <div className="existing-records">
                  {this.state.existingRights?.map((item, index) => (
                  <div
                    key={index}
                    className="existing-right-item"
                    onClick={() =>
                      this.handleUpdateExistingRights(item.right_id)
                    }
                  >
                    <span>
                      <span style={{color:this.state.selectedRights === item.right_id?"#164f9f":""}}>{item?.right_abbr}</span>
                      {item?.is_default ? (
                        <span className="default-rights">Default</span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </MDBCol>
            <MDBCol className="existing-right-details">
              <div className="display-flex">
                <BasicLabel type={"darkFieldHeading"} text={this.state.rightsDetails?.right_abbr || "Rights"} />
                {permissionValue.edit === 1 &&
                <BasicButton
                  type="inline"
                  color="primary"
                  // disabled={!this.state.rightsDetails?.right_abbr ||!this.state.rightsDetails?.description}
                  text={
                    this.state.isSubmit ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Save"
                    )
                  }
                  icon={"save"}
                  onClick={this.handleSubmit}
                />}
              </div>
              {this.state.isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress size={24} />
                </div>
              ) : (
                <MDBCard className="existing-right-card">
                  <MDBCardBody>
                    <MDBCardText>
                      <MDBRow>
                        <MDBCol md={3}>
                            <BasicTextField
                              id="rightsAbbr"
                              label={"Rights Abbr."}
                              isMandatory={true}
                              showMandatory={this.state.postInitialted}
                              value={this.state.rightsDetails?.right_abbr || ""}
                              onChange={(e) =>
                                this.handleGeneralEdit(
                                  "right_abbr",
                                  e.target.value.length > 0 ? e.target.value :  null
                                )
                              }
                          />
                        </MDBCol>
                        <MDBCol md={2}>
                        <BasicCheckbox
                              id={"is_default"}
                              label="Default"
                              className="default-checkbox"
                              checked={this.state.rightsDetails?.is_default}
                              onChange={(e) =>
                                this.handleGeneralEdit(
                                  "is_default",
                                  e.target.checked ? 1 : 0
                                )
                              }
                            />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicCheckbox
                              id={"initial-rights"}
                              label="Initial (Not Option) Rights"
                              className="default-checkbox"
                              checked={this.state.rightsDetails?.is_initial_right}
                              onChange={(e) => {
                                this.handleGeneralEdit("is_initial_right",e.target.checked ? 1 : 0)
                                this.handleGeneralEdit("can_be_alt_init_right",0)
                                this.handleGeneralEdit("can_be_included_with_init_right",0)
                              }}
                          />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicCheckbox
                              id={"mark-inactive"}
                              label="Mark Inactive"
                              className="default-checkbox"
                              checked={this.state.rightsDetails?.is_active === 0}
                              onChange={(e) =>
                                this.handleGeneralEdit(
                                  "is_active",
                                  e.target.checked ? 0 : 1
                                )
                              }
                          />
                        </MDBCol>
                        </MDBRow>
                          <MDBRow>
                          <MDBCol md={5}>
                          <div className="costCenterTable">
                            <TableComponent
                              list={
                                this.state.rightsDetails?.cost_centers_list ||
                                []
                              }
                              config={this.props?.config}
                              arrayCheckUncheck={this.arrayCheckUncheck}
                              stickyHeader={true}
                              sortCallback={this.handleTableColumnClick}
                            />
                          </div>
                        </MDBCol>
                        <MDBCol md={6}>
                          <BasicTextArea
                            className={this.state.postInitialted ? "mt-1" : null}
                            label="Full Descrition (to appear on letters)"
                            isMandatory={true}
                              showMandatory={this.state.postInitialted}
                            value={this.state.rightsDetails?.description || ""}
                            onChange={(e) =>
                              this.handleGeneralEdit(
                                "description",
                                e.target.value.length > 0 ? e.target.value :  null
                              )
                            }
                          />
                          <MDBRow>
                            <MDBCol  md={6} className="defaultTerm">
                              <SelectField
                                className={this.state.postInitialted ? "mt-1" : null}
                                id="defaultTerm"
                                label="Default Term"
                                options={this.state.termOptionDetails}
                                placeHolderText="- Select -"
                                isMandatory={true}
                                showMandatory={this.state.postInitialted}
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "default_term_id",
                                    e.target.value
                                  )
                                }
                                value={
                                  this.state?.rightsDetails?.default_term_id
                                }
                              />
                            </MDBCol>
                            </MDBRow>
                            <MDBRow>
                            <MDBCol  md={6} className="defaultTerritory">
                              <SelectField
                                className={this.state.postInitialted ? "mt-1" : null}
                                id="defaultTerritory"
                                label="Default Territory"
                                options={this.state.territoryOptionDetails}
                                placeHolderText="- Select -"
                                isMandatory={true}
                                showMandatory={this.state.postInitialted}
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "default_territory_id",
                                    e.target.value
                                  )
                                }
                                value={
                                  this.state.rightsDetails?.default_territory_id
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol md={6} className="pr-0 expirationTerm">
                              <SelectField
                                className={this.state.postInitialted ? "mt-1" : null}
                                id="expTerm"
                                label="Default Expiration Term"
                                options={this.state.expirationTermOptions}
                                placeHolderText="- Select -"
                                isMandatory={true}
                                showMandatory={this.state.postInitialted}
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "default_exp_term_id",
                                    e.target.value
                                  )
                                }
                                value={
                                  this.state.rightsDetails?.default_exp_term_id
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="mt-1 customCheckbox">
                            <MDBCol md={6}>
                              <BasicCheckbox
                                id={"is_main_title_buy_out"}
                                label="These are Main Title buyout rights"
                                checked={
                                  this.state.rightsDetails
                                    ?.is_main_title_buy_out
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "is_main_title_buy_out",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                              <BasicCheckbox
                                id={"is_include_home_video"}
                                label="These rights include Home Video"
                                checked={
                                  this.state.rightsDetails
                                    ?.is_include_home_video
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "is_include_home_video",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                              <BasicCheckbox
                                id={"dont_include_yearly_cost_increase"}
                                label="Don’t include in yearly Main Title cost increase"
                                checked={
                                  this.state.rightsDetails
                                    ?.dont_include_yearly_cost_increase
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "dont_include_yearly_cost_increase",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                              {this.state.rightsDetails?.is_initial_right ===1 && <BasicCheckbox
                                id={"can_be_alt_init_right"}
                                label="These rights can be used as Alt Initial Rights"
                                checked={
                                  this.state.rightsDetails
                                    ?.can_be_alt_init_right
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "can_be_alt_init_right",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />}
                              {this.state.rightsDetails?.is_initial_right === 0 && <BasicCheckbox
                                id={"can_be_included_with_init_right"}
                                label="This Option can be included with Initial Rights"
                                checked={
                                  this.state.rightsDetails?.can_be_included_with_init_right
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "can_be_included_with_init_right",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />}
                            </MDBCol>
                            <MDBCol>
                              <BasicCheckbox
                                id={"is_default_for_library_song"}
                                label="Default rights for Library songs"
                                checked={
                                  this.state.rightsDetails
                                    ?.is_default_for_library_song
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "is_default_for_library_song",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                              <BasicCheckbox
                                id={"is_default_rights_for_COA"}
                                label="Default rights for COA records"
                                checked={
                                  this.state.rightsDetails
                                    ?.is_default_rights_for_COA
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "is_default_rights_for_COA",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                              <BasicCheckbox
                                id={"is_applicable_to_internet_shows"}
                                label="Only applicable to Internet Shows"
                                checked={
                                  this.state.rightsDetails
                                    ?.is_applicable_to_internet_shows
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "is_applicable_to_internet_shows",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                              <BasicCheckbox
                                id={"is_acquired_by_intl_dept"}
                                label="This option can be acquired by International Dpt."
                                checked={
                                  this.state.rightsDetails
                                    ?.is_acquired_by_intl_dept
                                }
                                onChange={(e) =>
                                  this.handleGeneralEdit(
                                    "is_acquired_by_intl_dept",
                                    e.target.checked ? 1 : 0
                                  )
                                }
                              />
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              )}
            </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(ExistingRights);
