import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { withUserContext } from "../../../contexts/UserContext";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { filterConfig } from './config';
import axios from 'axios';
import Chip from "@material-ui/core/Chip";
import "./ShowSeason.scss";

class ShowSeason extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seasonOptions: "",
            showOptions: "",
            rightsOptions: "",
            filterValues: [],
            filterSeasonIdValues: [],
            filterShowIdValues: [],
            filterOptionIdValues: [],
            backUpSeasonOptions: "",
            backUpShowOptions: "",
            backUpRightsOptions: "",
            seasonValues: [],
            showErrorMessage: false,
            showErrorValidation: false,
            showSelectedShowError: false,
            showSeasonValidation: false,
            isShowFetching: false,
            searchCancelToken: null,
            seasonListByShow:[]
            // removedItem: null
        }
    }

    componentDidMount() {
        if (this.props?.reportName === "BudgetVSEfc") {
            this.fetchSeasonsData("BUDGET_VS_EFC_SEASON");
        } else {
            this.fetchSeasonsData("SEASON");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.removedItem !== this.props.removedItem) {
            this.updateDropDowns(this.props?.removedItem);
            this.props.filterSeasonIdValues?.length <= 1 && this.props.filterShowIdValues?.length <=1 && setTimeout(() => {
                this.setState({ seasonOptions: this.state.backUpSeasonOptions,seasonListByShow:[]});
            }, 2000);
        }
        if (prevProps.filterValues !== this.props.filterValues) {
            this.setState({ filterValues: this.props.filterValues }, () => {
                console.log("updated values", this.state.filterValues)
            })
        }
        if (prevProps.is_warner_horizon !== this.props.is_warner_horizon) {
            console.log("updated horizon", this.props.is_warner_horizon)
            this.updateShowOptions(this.props?.is_warner_horizon)
        }
        if (prevProps.is_all_shows !== this.props.is_all_shows) {
            console.log("updated all", this.props.is_all_shows)
            this.updateShowOptions(this.props?.is_all_shows)
        }
        if (prevProps.showErrorValidation !== this.props.showErrorValidation) {
            console.log("updated all", this.props.is_all_shows)
            this.setState({ showErrorValidation: this.props.showErrorValidation })
        }
        if (prevProps.showSelectedShowError !== this.props.showSelectedShowError) {
            console.log("updated all", this.props.is_all_shows)
            this.setState({ showSelectedShowError: this.props.showSelectedShowError })
        }
        if (prevProps.showSeasonValidation !== this.props.showSeasonValidation) {
            console.log("updated all", this.props.is_all_shows)
            this.setState({ showSeasonValidation: this.props.showSeasonValidation },()=>{
                // setTimeout(() => {
                //     this.setState({ showSeasonValidation: false});
                // }, 2000);
            });
         
        }      
    }

    updateShowOptions = () => {
        let warnerHorizonShows = this.state.warnerHorizonShows;
        let allShows = this.state.allShows;
        let warnerHorizonAllShows = this.state.warnerHorizonAllShows;
        let initialShows = this.state.initialShows;
        if (this.props?.reportName === "BudgetVSEfc") {
            if (this.props?.is_warner_horizon === 1) {
                this.setState({ showOptions:  warnerHorizonShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: warnerHorizonShows }, () => {
                    this.props?.showValues(this.state.showOptions)
                });
            } else if (this.props?.is_all_shows === 1) {
                this.setState({ showOptions:  allShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: allShows }, () => {
                    this.props?.showValues(this.state.showOptions)
                });
            } else if (this.props?.is_warner_horizon === 1 && this.props?.is_all_shows === 1) {
                this.setState({ showOptions: warnerHorizonAllShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: warnerHorizonAllShows }, () => {
                    this.props?.showValues(this.state.showOptions)
                });
            } else {
                this.setState({ showOptions: initialShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: initialShows }, () => {
                    this.props?.showValues(this.state.showOptions)
                });
            }
        }
    }

    updateDropDowns = (val) => {
        let splitVal = val.split(":");
        let name = splitVal[0].trim();
        let value = splitVal[1].trim();
        if (name === "Season") {
            let seasonOptions = this.state.seasonOptions;
            let found = this.state.backUpSeasonOptions.find(item => item.label === value);
            let checkSeason = seasonOptions.find(item => item === found);
            if(!checkSeason){
               seasonOptions.push(found);
            }           
            seasonOptions.sort((a, b) => a.value - b.value)
            let filterSeasonIdValues = this.props?.filterSeasonIdValues;
            filterSeasonIdValues = filterSeasonIdValues.filter(item => item !== found?.value);
            this.setState({ seasonOptions: seasonOptions, seasonValues: filterSeasonIdValues });
        } else if (name === "Show") {
            let showOptions = this.state.showOptions
            let found = this.state.backUpShowOptions.find(item => item?.text === value);
            showOptions.push(found);
            showOptions.sort((a, b) => a.value - b.value)
            let filterShowIdValues = this.props?.filterShowIdValues;
            filterShowIdValues = filterShowIdValues.filter(item => item !== found?.value)
            // let filteredShowIdValues = filterShowIdValues.filter(item => item !== found?.value)
            this.setState({ showOptions: showOptions })
            //  filterShowIdValues: filteredShowIdValues }, () => { this.props?.setShowId(this.state.filterShowIdValues) })
        } else if (name === "Options") {
            let rightsOptions = this.state.rightsOptions
            let found = this.state.backUpRightsOptions.find(item => item.text === value);
            rightsOptions.push(found);
            rightsOptions.sort((a, b) => a.value - b.value)
            let filterOptionIdValues = this.props?.filterOptionIdValues;
            filterOptionIdValues = filterOptionIdValues.filter(item => item !== found?.value)
            // let filteredOptionIdValues = filterOptionIdValues.filter(item => item !== found?.value)
            this.setState({ rightsOptions: rightsOptions })
            // filterOptionIdValues: filteredOptionIdValues }, () => { this.props?.setOptionId(this.state.filterOptionIdValues) })
        }
    }

    fetchSeasonsData = (entity) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`,
            this.props?.userContext?.active_tenant?.tenant_id, 1)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.season_id, label: item.season_name, is_current: item?.is_current }));
                this.setState({ seasonOptions: formattedList, backUpSeasonOptions: formattedList }, () => {
                    console.log(this.state.seasonOptions),
                        this.props.seasonValues(this.state.seasonOptions);
                    if (this.props?.reportName === "BudgetVSEfc") {
                        let current_year = formattedList.find(item => item.is_current === 1)
                        if (current_year) {
                            this.onSeasonChange(null, current_year?.value, current_year?.label)
                        } else {
                            this.onSeasonChange(null, formattedList[0].value, formattedList[0].label)
                        }
                    }
                    if (this.props?.reportName === "LicenseTracking") {
                        let current_year_license = formattedList.filter(item => (item?.label?.substring(0, 4) >= '2018' && item?.label?.substring(0, 4) !='9999')).reverse()               
                        console.log(current_year_license)
                        if (current_year_license) {
                            for (let i of current_year_license){
                                console.log(i)
                                this.onSeasonChange( null,i?.value, i?.label)
                            }
                        } else {
                            this.onSeasonChange(null, formattedList[0].value, formattedList[0].label)
                        }
                    }
                });
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }

    fetchShowsData = (entity, config, node, searchString) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            this.setState({ isShowFetching: true })
            //  let filteredSeasonIdValues =  this.state.filterSeasonIdValues?.length > 1 ? null : this.state.filterSeasonIdValues;
            let filteredSeasonIdValues =  null;
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}&all_seasons=${filteredSeasonIdValues}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name,season_id:item.season_id }));
                this.setState({ isShowFetching: false, showOptions: formattedList, backUpShowOptions: formattedList }, () => {
                    this.props?.showValues(this.state.showOptions)

                }
                );
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }
    }

    updateValues = (name, val) => {
        if (name === "Season") {
            let seasonOptions = this.state.seasonOptions
            let updatedOptions = seasonOptions.filter(item => item?.value !==val?.target?.value)
            this.setState({
                seasonOptions: updatedOptions
            })
        } else if (name === "Show") {
            let showOptions = this.state.showOptions
            let updatedOptions = showOptions.filter(item => item?.value !== val?.value)
            this.setState({
                showOptions: updatedOptions
            })
        } else if (name === "Options") {
            let rightsOptions = this.state.rightsOptions
            let updatedOptions = rightsOptions.filter(item => item.value !== val?.value)
            this.setState({
                rightsOptions: updatedOptions
            })
        }
    }

    onSeasonChange = (val, frstVal = null, label = null) => {
        let filterValues = this.state.filterValues;
        let filterSeasonIdValues = this.props?.filterSeasonIdValues;
        // if (this.props?.reportName === "BudgetVSEfc" && filterValues.length >= 1 && filterSeasonIdValues.length >= 1 && this.state.disable_searchShowBySeason==false) {
        //     this.setState({ showErrorMessage: true }, () => {
        //         setTimeout(() => { this.setState({ showErrorMessage: false }) }, 5000)
        //     })
        //     return;
        // }
       // else {
            const seasonYear="Season" + ": " + (label !== null ? label : val?.currentTarget.innerText);
            const filterValueId=filterValues.find(val=>val===seasonYear);
            if(!filterValueId){
            filterValues.push("Season" + ": " + (label !== null ? label : val?.currentTarget.innerText));
            filterSeasonIdValues.push(frstVal ? frstVal : val?.target.value)
            this.setState({
                filterValues: filterValues,
                filterSeasonIdValues: filterSeasonIdValues
            }, () => {
                if (this.props?.reportName === "LicenseTracking" ){
                this.props?.onChangeCallBack(this.state.filterValues); 
                // this.updateValues("Season", frstVal ? frstVal : val?.target?.value);
                }
                else{
                this.props?.onChangeCallBack(this.state.filterValues); this.updateValues("Season", val);
                }
                // this.props?.setSeasonId(this.state.filterSeasonIdValues)
            });
            let entity = "SEASON-SHOW"
            // if (this.props?.reportName === "BudgetVSEfc") {
            //     entity = "BUDGET-VS-EFC-SEASON-SHOW"
            // }
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entityHierarchical?entity=${entity}&entity_id=` + (frstVal ? frstVal : val?.target.value),
                this.props?.userContext?.active_tenant?.tenant_id, 1)
                .then(response => {
                    let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name, show_season_id: item.show_season_id, is_warner_horizon: item.is_warner_horizon, is_visible: item.is_visible,season_id:item?.season_id }));
                    let warnerHorizonShows = formattedList.filter(item => 
                       // item.is_warner_horizon === 1 && 
                        item.is_visible === 1)
                    let allShows = formattedList.filter(item => item.is_warner_horizon === 0)
                    let warnerHorizonAllShows = formattedList.filter(item => item.is_warner_horizon === 1)
                    let initialShows = formattedList.filter(item => item.is_visible === 1 && item.is_warner_horizon === 0)
                    this.setState({ warnerHorizonShows: warnerHorizonShows, allShows: allShows, warnerHorizonAllShows: warnerHorizonAllShows, initialShows: initialShows })
                    if (this.props?.reportName === "BudgetVSEfc") {
                        if (this.props?.is_warner_horizon === 1) {
                            this.setState({ showOptions: warnerHorizonShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: warnerHorizonShows }, () => {
                                this.props?.showValues(this.state.showOptions)
                            });
                             
                        } else if (this.props?.is_all_shows === 1) {
                            this.setState({ showOptions: allShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: allShows }, () => {
                                this.props?.showValues(this.state.showOptions)
                            });
                        } else if (this.props?.is_warner_horizon === 1 && this.props?.is_all_shows === 1) {
                            this.setState({ showOptions: warnerHorizonAllShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: warnerHorizonAllShows }, () => {
                                this.props?.showValues(this.state.showOptions)
                            });
                        } else {
                            this.setState({ showOptions: initialShows?.sort((a, b) => (a?.text.toString().split(' ').join('').toLowerCase() > b?.text.toString().split(' ').join('').toLowerCase()) ? 1 : -1), backUpShowOptions: initialShows }, () => {
                                this.props?.showValues(this.state.showOptions)
                            });
                        }
                    } else {
                        this.setState({ showOptions: formattedList, backUpShowOptions: formattedList }, () => {
                            this.props?.showValues(this.state.showOptions)
                        });
                    }
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err);
                        this.setState({ isFetchingClearanceDetails: false });
                    })
        }
    //}
}

selectSeasonByShow = (show) => {
    clearTrackService
        .getData(
            Constants.ClearTrackServiceBaseUrl +
            `/seasonsBasedOnShows?show_id=${show.value}`,
            this.props?.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                    (item, index, data) =>
                        index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                ) : [];
                let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                    value: item.season_id,
                    label: (item?.season_name).replace(/\s+/g, ''),
                })) : [];

                let filterSeasonIdValues = this.state?.filterSeasonIdValues;
                if (filterSeasonIdValues?.length > 0) {
                    formattedList = formattedList.filter(item => !filterSeasonIdValues.includes(item?.value));
                }
                this.addSeasonsBasedOnShow(formattedList);
             //   console.log(seasonslist,this.state.seasonListByShow);
              //  this.setState({ seasonOptions: formattedList});
                // let seasonBasedEpisodes = response?.data[0]?.seasons;
                // this.setState({ seasonBasedEpisodes })
                // formattedList.unshift({ label: "Select", value: null })
                // if (typeof (selectedShow) !== 'undefined') {
                //     this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                // } else {
                //     this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                // }
            })
        .catch(error => {
            console.log("error--", error)
        })
}

addSeasonsBasedOnShow = (newSeasons) => {
    if(this.state.seasonListByShow.length > 0){
    this.setState(prevState => {
      const existingSeasonIds = new Set(prevState.seasonOptions.map(season => season.value));

      const updatedSeasons = [...prevState.seasonOptions, ...newSeasons.filter(season => !existingSeasonIds.has(season.value))];
      updatedSeasons.sort((a, b) => a.season_id - b.season_id);
      this.setState({ seasonListByShow: updatedSeasons} ,()=>{
        this.setState({ seasonOptions: this.state.seasonListByShow});
      });
    
      return  updatedSeasons ;
    });
}else{
    this.setState({ seasonListByShow: newSeasons} ,()=>{
        this.setState({ seasonOptions: this.state.seasonListByShow});
      });
    //this.setState({ seasonOptions: newSeasons});
}
  };

    selectSeasonByShow2 = (value) => {
        console.log(value);
        if (value?.season_id == null) {
            return false;
        } else {
            let { filterSeasonIdValues } = this.props;
            if (filterSeasonIdValues.includes(value?.season_id)) {
                return false;
            } else {
                filterSeasonIdValues.push(value?.season_id);
                let filterValues = this.state.filterValues;
                let seasonFound = this.state.backUpSeasonOptions.find(item => item.value === value?.season_id);
                const seasonYear = "Season" + ": " + seasonFound?.label;
                if (seasonFound) {
                    filterValues.push(seasonYear);
                    this.setState({
                        filterValues: filterValues,
                        filterSeasonIdValues: filterSeasonIdValues
                    });
                }
            }
        }
    }
    

    onShowChange = (val) => {
        let filterValues = this.state.filterValues;
        let filterShowIdValues = this.props?.filterShowIdValues;
        filterValues.push("Show" + ": " + val?.text);
        filterShowIdValues.push(val?.value)
        this.setState({
            filterValues: filterValues,
            filterShowIdValues: filterShowIdValues,
            // disable_searchShowBySeason: (this.state.disable_searchShowBySeason == true || this.props.filterSeasonIdValues.length == 0)

        }, () => {
            this.props?.onChangeCallBack(this.state.filterValues); this.updateValues("Show", val);
            // this.props?.setShowId(this.state.filterShowIdValues) 
        });
        if (this.props?.isOptionAcquisition) {
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entityHierarchical?entity=SHOWSEASON-RIGHTS&entity_id=` + val?.show_season_id,
                this.props?.userContext?.active_tenant?.tenant_id, 1)
                this.setState({isShowFetching : true})
                .then(response => {
                    let formattedList = response.data?.map(item => ({ value: item.rights_id, text: item.right_abbr }));
                    this.setState({ rightsOptions: formattedList, backUpRightsOptions: formattedList, isShowFetching : false }, () => {
                        console.log(this.state.rightsOptions),
                            this.props.rightOptionValues(this.state.rightsOptions)
                    });
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err);
                        this.setState({ isFetchingClearanceDetails: false });
                    })
        }
    }

    onOptionChange = (val) => {
        let filterValues = this.state.filterValues;
        let filterOptionIdValues = this.props?.filterOptionIdValues;
        filterValues.push("Options" + ": " + val?.text);
        filterOptionIdValues.push(val?.value)
        this.setState({
            filterValues: filterValues,
            filterOptionIdValues: filterOptionIdValues
        }, () => {
            this.props?.onChangeCallBack(this.state.filterValues); this.updateValues("Options", val);
            //  this.props?.setOptionId(this.state.filterOptionIdValues)
        });

    }

    render() {
        return (
            <div className="ProductionSeasonEpisode">
                <MDBRow className='searchSelectBorder'>
                    <MDBCol md={5}>
                        <SelectField
                            options={this.state.seasonOptions || []}
                            placeHolderText="All Seasons"
                            onChange={(e) => this.onSeasonChange(e)}
                            label={"Seasons"}
                        // value={this.state?.selectedOption}
                        />
                        <span className="errorText">{this.state.showErrorMessage && 'Cannot choose more than one season' || this.state.showSeasonValidation && 'Choose atleast one season'}</span>
                    </MDBCol>
                    <MDBCol md={5}>
                        <SearchSelectField
                            id={"All Shows"}
                            placeHolderText={"All Shows in selected season(s)"}
                            // value={ this.state.showOptions?.text }
                            options={this.state.showOptions || []}
                           // onChange={(e, value) => this.onShowChangee(value)}
                            label={"Shows"}
                            searchSelect={true}
                            loading={this.state.isShowFetching}
                            valueSelected={(e, value) => {
                                this.onShowChange(value);
                               // this.selectSeasonByShow(value);
                            }}
                            searchText={ev => {
                                // dont fire API if the user delete or not entered anything
                                if (ev.target.value !== "" && ev.target.value !== null) {
                                    this.fetchShowsData("SHOW", filterConfig.show, "showList", ev.target.value);
                                } else {
                                    this.setState({ isShowFetching: false })
                                }
                            }}
                        />
                        <span className="errorText">{this.state.showSelectedShowError && 'Choose atleast one show' || this.state.showErrorValidation && 'There are no shows for this season'}</span>
                        <br />
                    </MDBCol>
                    {this.props?.isOptionAcquisition ?
                        <MDBCol md={5}>
                            <SearchSelectField
                                id={"All Rights"}
                                placeHolderText={"All Shows in selected season(s)"}
                                // value={{ 'value': this.state.selected_show?.value, 'text': this.state.selected_show?.text } || {}}
                                options={this.state.rightsOptions || []}
                                onChange={(e, value) => this.onOptionChange(value)}
                                label={"Options"}
                            />
                            {/* <br /> */}
                        </MDBCol>
                        : null
                    }
                </MDBRow>
            </div>
        );
    }
}

export default withUserContext(ShowSeason);