export const onCameraPerformersConfig = {
    headings: [
        {
            headingLabel: "Detail",
            inputType: "select",
            selectOptions:[],
            width: "20%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            width: "20%"
        },
        {
            headingLabel: "Rate",
            inputType: "text",
            width: "8%"
        },
        {
            headingLabel: "Min. Hours",
            inputType: "text",
            width: "8%"
        },
        {
            headingLabel: "Fringe Detail",
            inputType: "select",
            selectOptions:[],
            width: "14%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            width: "25%"
        },

    ],

    dataNodes: ["budget_detail_code_id", "description", "rate", "min_hours", "fringe_detail_id", "notes"],
    primaryKey: "tv_music_budgeting_template_on_camera_performers_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "budget_detail_code_id",
            width: "19%"
        },
        {
            dataNode: "description",
            width: "19%"
        },
        {
            dataNode: "rate",
            width: "7%",
            inlineIcon:"$",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers',
        },
        {
            dataNode: "min_hours",
            width: "7%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers',
        },
        {
            dataNode: "fringe_detail_id",
            width: "13%"
        },
        {
            dataNode: "notes",
            width: "25%"
        }
    ],
}

export const recordingConfig = {
    headings: [
        {
            headingLabel: "Detail",
            inputType: "select",
            selectOptions:[],
            width: "20%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            width: "20%"
        },
        {
            headingLabel: "Rate",
            inputType: "text",
            width: "8%"
        },
        {
            headingLabel: "Min. Hours",
            inputType: "text",
            width: "8%"
        },
        {
            headingLabel: "Fringe Detail",
            inputType: "select",
            selectOptions:[],
            width: "14%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            width: "25%"
        },

    ],

    dataNodes: ["budget_detail_code_id", "description", "rate", "min_hours", "fringe_detail_id", "notes"],
    primaryKey: "tv_music_budgeting_template_recordings_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "budget_detail_code_id",
            width: "19%"
        },
        {
            dataNode: "description",
            width: "19%"
        },
        {
            dataNode: "rate",
            width: "7%",
            inlineIcon:"$",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers',
        },
        {
            dataNode: "min_hours",
            width: "7%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers',
        },
        {
            dataNode: "fringe_detail_id",
            width: "13%"
        },
        {
            dataNode: "notes",
            width: "25%"
        }
    ],
}
export const orchestralConfig = {
    headings: [
        {
            headingLabel: "Detail",
            inputType: "select",
            selectOptions:[],
            width: "20%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            width: "20%"
        },
        {
            headingLabel: "Rate",
            inputType: "text",
            width: "8%"
        },
        {
            headingLabel: "Min. Hours",
            inputType: "text",
            width: "8%"
        },
        {
            headingLabel: "Fringe Detail",
            inputType: "select",
            selectOptions:[],
            width: "14%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            width: "25%"
        },

    ],

    dataNodes: ["budget_detail_code_id", "description", "rate", "min_hours", "fringe_detail_id", "notes"],
    primaryKey: "tv_music_budgeting_template_orchestral_sessions_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "budget_detail_code_id",
            width: "19%"
        },
        {
            dataNode: "description",
            width: "19%"
        },
        {
            dataNode: "rate",
            width: "7%",
            inlineIcon:"$",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers',
        },
        {
            dataNode: "min_hours",
            width: "7%",
            validateMethod : 'validateNumDotOnly',
            validateMessage : 'Please enter numbers',
        },
        {
            dataNode: "fringe_detail_id",
            width: "13%"
        },
        {
            dataNode: "notes",
            width: "25%"
        }
    ],
}

export const fringeConfig = {
    headings: [
        {
            headingLabel: "Detail",
            inputType: "select",
            selectOptions:[],
            width: "20%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            width: "20%"
        },
        {
            headingLabel: "Fringe%",
            inputType: "text",
            width: "15%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            width: "39%"
        },

    ],

    dataNodes: ["fringe_detail_id", "description", "fringe", "notes"],
    primaryKey: "tv_music_budgeting_template_fringe_id",
    actions: ["pen", "times"],
    inlineEdits: [{ dataNode: "fringe_detail_id", width: "19%" }, { dataNode: "description", width: "19%" }, { dataNode: "fringe", width: "14%",validateMethod : 'validateNumDotOnly',
    validateMessage : 'Please enter numbers' },
    { dataNode: "notes", width: "39%" }],
}
export const fringeList = [
    {
        id: 1,
        Detail: "[Detail]",
        Description: "[Description]",
        Fringe: "[Fringe]",
        Notes: "[Notes]"
    },
    {
        id: 2,
        Detail: "[Detail]",
        Description: "[Description]",
        Fringe: "[Fringe]",
        Notes: "[Notes]"
    },
    {
        id: 3,
        Detail: "[Detail]",
        Description: "[Description]",
        Fringe: "[Fringe]",
        Notes: "[Notes]"
    },
    {
        id: 4,
        Detail: "[Detail]",
        Description: "[Description]",
        Fringe: "[Fringe]",
        Notes: "[Notes]"
    },
    {
        id: 5,
        Detail: "[Detail]",
        Description: "[Description]",
        Fringe: "[Fringe]",
        Notes: "[Notes]"
    },
]

export const tvMusicBudgetDetails = {
    union_id : null
}
export  const PayIncreaseDetails ={
    union_id : null,
    on_camera_performers_pay_increase : null,
    orchestral_sessions_pay_increase : null,
    recordings_pay_increase: null
}