export const budgetefcRecipientConfig = {
    headings: [
        // {
        //     headingLabel: "Active",
        //     width: "10%",
        //     inputType: "checkbox"
        // },
        {
            headingLabel: "Name",
            width: "30%",
            inputType: "text"
        },
        {
            headingLabel: "Email",
            width: "30%",
            inputType: "text"
        },
        {
            headingLabel: "Company",
            width: "25%",
            inputType: "text"
        }

    ],
    dataNodes: ["name", "email","company"],
    primaryKey: "id",
    // nodeFields: [
    //     {
    //         column: "is_active",
    //         icon: "check"
    //     },
    // ],
    actions: ["pen", "times"],
    inlineEdits: [
        // {
        //     dataNode: "is_active",
        //     width: "10%"
        // },
        {
            dataNode: "name",
            width: "30%"
        },
        {
            dataNode: "email",
            width: "30%",
            validateMethod : 'validateEmail',
            validateMessage : 'Please enter valid Email'
        },
        {
            dataNode: "company",
            width: "25%"
        },
    ],
}

export const budgetefcRecipientRecordConfig = {
    headings: [
        {
            headingLabel: "Name",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "Email",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "Company",
            width: "25%",
            inputType: "text"
        },
        {
            headingLabel: "WB?",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Horizon?",
            width: "5%",
            inputType: "checkbox"
        },

    ],
    dataNodes: ["name", "email","company","is_wb","is_horizon"],
    primaryKey: "id",
    actions: ["pen", "times"],
   // toggleFields:["is_wb","is_horizon"],
    inlineEdits: [
        
        {
            dataNode: "name",
            width: "25%",
            
        },
        {
            dataNode: "email",
            width: "25%",
            validateMethod : 'validateEmail',
            validateMessage : 'Please enter valid Email',
            
        },
        {
            dataNode: "company",
            width: "25%",
           
        },
        {
            dataNode: "is_wb",
            width: "5%",
           
        },
        {
            dataNode: "is_horizon",
            width: "5%",
           
        },
    ],
    nodeFields: [
        
        {
            column: "is_wb",
            icon: "check"
        },
        {
            column: "is_horizon",
            icon: "check"
        },
        
    ],

   
}




