import React, { Component } from 'react'
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel'
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField"
import SelectField from "../../SharedComponents/SelectField/SelectField"
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { filterConfig, showSeasonsDetailsConfig, showSeasonSelectedFilters } from './config';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import { withUserContext } from '../../../contexts/UserContext';
import axios from 'axios';

export default withUserContext(class ShowSeasons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filterSearchList: [],
            showSeasonsDetailsConfig: { ...showSeasonsDetailsConfig },
            showSeasonSelectedFilters: this.props?.showSeasonSelectedFilters,
            clearFilters: this.props?.clearFilters,
            selectedFilterValues: {},
            clearFilters: this.props?.clearFilters,
            sortBy: "Show",
            isShowFetching: false,
            isUserListFetching: false,
            selectedUserForSearch: null,
            selectedShowForSearch: null,
            filterList: {
                showList: [],
                seasonList: [],
                networkList: [],
                showDurationList: [],
                internetShowList: [],
                productionEntityList: [],
                userList: [],
                termsList: [],
                territoryList: [],
                rightsList: [],
                initialRightsList: [],
                altInitialRightsList: [],
                currentRightsList: [],
                finalRightsList: [],
                showBasedSeasonList: []
            },
            searchCancelToken: null
        }
    }

    componentDidMount() {
        document.title = 'Show Season'
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            this.fetchEntityList('PRODUCTION_ENTITY', filterConfig.production_entity, 'productionEntityList')
            this.fetchEntityList('SEASON', filterConfig.season, 'seasonList')
            this.fetchEntityList('SHOW_DURATIONS', filterConfig.show_durations, 'showDurationList')
            this.fetchEntityList('TERRITORY', filterConfig.territory, 'territoryList')
            this.fetchEntityList('TERM', filterConfig.term, 'termsList')
            this.fetchEntityList('RIGHTS', filterConfig.rights, 'rightsList')
            // this.getUserList('USER', filterConfig.user, "userList", null)
            this.fetchStaticData('lu_network', 'networkList')
            this.getShowDetails("SHOW", filterConfig.show, "showList", "");
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.fetchEntityList('PRODUCTION_ENTITY', filterConfig.production_entity, 'productionEntityList')
            this.fetchEntityList('SEASON', filterConfig.season, 'seasonList')
            this.fetchEntityList('SHOW_DURATIONS', filterConfig.show_durations, 'showDurationList')
            this.fetchEntityList('TERRITORY', filterConfig.territory, 'territoryList')
            this.fetchEntityList('TERM', filterConfig.term, 'termsList')
            this.fetchEntityList('RIGHTS', filterConfig.rights, 'rightsList')
            // this.getUserList('USER', filterConfig.user, "userList", null)
            this.fetchStaticData('lu_network', 'networkList')
            this.getShowDetails("SHOW", filterConfig.show, "showList", "");
        }
        if (this.props?.clearFilters != this.state.clearFilters) {
            let mockClear = this.props?.clearFilters
            this.setState({ clearFilters: mockClear, filterSearchList: [], sortBy: "Show" })
        }
        if (this.props?.userContext.dashboardName === "Show Seasons" && this.state.sortBy != this.props?.sortBy) {
            this.setState({ sortBy: this.props?.sortBy })
        }
    }

    fetchStaticData = (entity, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }

    fetchEntityList = (entity, config, node) => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=null`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item[config.id], label: item[config.name] }));
                formattedList.unshift({ label: "Select", value: null })
                this.updateListState(formattedList, node, 'filterList')
                if (entity === 'RIGHTS') {
                    let initialRights = response.data?.filter(right => right?.is_initial_right === 1 || right?.can_be_included_with_init_right === 1);
                    let altInitialRights = response.data?.filter(right => right?.can_be_alt_init_right === 1);
                    let initlist = initialRights?.map(item => ({ value: item[config.id], label: item[config.name] }));
                    let altlist = altInitialRights?.map(item => ({ value: item[config.id], label: item[config.name] }));
                    initlist.unshift({ label: "Select", value: null })
                    altlist.unshift({ label: "Select", value: null })
                    this.updateListState(initlist, 'initialRightsList', 'filterList')
                    this.updateListState(altlist, 'altInitialRightsList', 'filterList')
                }
            },
                (err) => {
                    console.log("Error in fetching entity list:", err)
                })
    }

    getShowDetails = (entity, config, node, searchString) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            this.setState({ isShowFetching: true })
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedList = response.data?.map((item) => ({
                        value: item[config.id],
                        text: item[config.name]
                    }));
                    this.updateListState(formattedList, node, "filterList");
                    this.setState({ isShowFetching: false })
                },
                    (err) => {
                        console.log("Error in fetching license data:", err)
                    })
        }
    }

    getShowBasedEpisodes = (showSeasonId) => {
        this.setState({ isFetchingEpisodes: true });
        clearTrackService
            .getData(
                Constants.ClearTrackServiceBaseUrl +
                `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
                this.props.userContext?.active_tenant?.tenant_id,
                1
            )
            .then(
                (response) => {
                    let formattedList = response.data?.map((item) => ({
                        value: item.show_season_episode_id,
                        label: `${item.episode_number ? item.episode_number : ''}${item.episode_title ? '-' + item.episode_title : ''}`,
                    }));
                    formattedList.unshift({ label: "Select", value: null })
                    if (typeof (selectedShow) !== 'undefined') {
                        this.updateListState(formattedList, "seasonBasedEpisodeList", 'filterList')
                    } else {
                        this.updateListState(formattedList.length > 0 ? formattedList : [], 'seasonBasedEpisodeList', 'filterList')
                    }
                },
                (err) => {
                    console.log("Error in fetching Details:", err);
                }
            );
    };

    selectedShow = (newValue) => {
        this.props.updateListState(null, 'selectedSeason', 'showSeasonSelectedFilters')
        this.handleFilterSelection('season', null, null, 'selectedSeason')
        clearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl +
            `/seasonsBasedOnShows?show_id=${newValue.value}`,
            this.props?.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                let selectedShow = response?.data[0]?.seasons?.find(
                    (item) => response?.data[0]?.show_id === newValue.value
                )?.show_season_id;
                let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
                    (item, index, data) =>
                        index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
                ) : [];
                let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
                    value: item.season_id,
                    label: item.season_name,
                })) : [];
                let seasonBasedEpisodes = response?.data[0]?.seasons;
                this.setState({ seasonBasedEpisodes })
                formattedList.unshift({ label: "Select", value: null })
                if (typeof (selectedShow) !== 'undefined') {
                    this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
                } else {
                    this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
                }
            })
            .catch(error => {
                console.log("error--", error)
            })

    }

    getUserList = (entity, config, node, searchString) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken, isUserListFetching: true });
        clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext.active_tenant.tenant_id)
            .then(response => {
                let formattedList = response.data?.map((item) => ({
                    value: item[config.id],
                    text: item[config.name]
                }));
                this.setState({ isUserListFetching: false })
                this.updateListState(formattedList, node, "filterList");
            },
                (err) => {
                    console.log("Error in fetching license data:", err)
                })

    }

    updateListState = (value, node, list) => {
        this.setState(prevState => ({
            [list]: {
                ...prevState[list],
                [node]: value
            }
        }))
    }
    handleFilterSelection = (type, val, name, node) => {
        this.updateFilteredShowSeasonData(type, val, name, node)
    }
    updateFilteredShowSeasonData = (type, val, name, node) => {
        let filtereredShowSeason = [...this.state.filterSearchList]
        let newFilterValue = {
            type: type,
            label: name,
            value: val,
            node: node
        }
        let check = filtereredShowSeason.filter((item, index) => {
            if (item.type === type) {
                return filtereredShowSeason[index] = newFilterValue

            }
        })
        if (check.length === 0) {
            filtereredShowSeason.push(newFilterValue)
        }
        this.setState({ filterSearchList: filtereredShowSeason })
        let showSeasonDetails = {
            show_search_ids: filtereredShowSeason.find((item) => item.type === "show")?.value || null,
            season_search_ids: filtereredShowSeason.find((item) => item.type === "season")?.value || null,
            show_year_search_text: filtereredShowSeason.find((item) => item.type === "showYear")?.label || null,
            episode_title_search_text: filtereredShowSeason.find((item) => item.type === "episode name")?.value || null,
            episode_no_search_text: filtereredShowSeason.find((item) => item.type === "episode")?.label || null,
            projected_eps_search_no: filtereredShowSeason.find((item) => item.type === "project eps")?.label || null,
            completed_eps_search_no: filtereredShowSeason.find((item) => item.type === "comp episode")?.label || null,
            network_search_ids: filtereredShowSeason.find((item) => item.type === "network")?.value || null,
            show_duration_search_ids: filtereredShowSeason.find((item) => item.type === "show duration")?.value || null,
            is_internet_show_flag: filtereredShowSeason.find((item) => item.type === "internet show")?.label || null,
            production_entity_search_ids: filtereredShowSeason.find((item) => item.type === "production entity")?.value || null,
            production_exec_search_text: filtereredShowSeason.find((item) => item.type === "Prod Exec")?.label || null,
            estimator_search_text: filtereredShowSeason.find((item) => item.type === "estimator")?.label || null,
            clearance_rep_search_ids: filtereredShowSeason.find((item) => item.type === "user")?.value || null,
            composer_search_text: filtereredShowSeason.find((item) => item.type === "composers")?.label || null,
            init_right_search_ids: filtereredShowSeason.find((item) => item.type === "initial right")?.value || null,
            init_term_search_ids: filtereredShowSeason.find((item) => item.type === "initial-right term")?.value || null,
            init_territory_search_ids: filtereredShowSeason.find((item) => item.type === "initial-right territory")?.value || null,
            alt_init_right_search_ids: filtereredShowSeason.find((item) => item.type === "alt-initial right")?.value || null,
            alt_init_term_search_ids: filtereredShowSeason.find((item) => item.type === "alt-right term")?.value || null,
            alt_init_territory_search_ids: filtereredShowSeason.find((item) => item.type === "alt-right territory")?.value || null,
            order_by: null,
            order_by_clause: this.state.sortBy,
            page_count: this.props?.rowsPerPage,
            page_no: this.props?.page > 0 ? this.props?.page : 1
        }
        this.props.updateFilteredList(filtereredShowSeason, showSeasonDetails)
    }

    render() {
        return (
            <div className="filterFieldsContainer">
                <MDBRow>
                    <MDBCol md={2}>
                        <MDBRow>
                            <h6>Show</h6>
                        </MDBRow>
                        <MDBRow >
                            <SearchSelectField
                                id="show"
                                placeholder="- Show -"
                                width="100%"
                                searchSelect={true}
                                loading={this.state.isShowFetching}
                                multiple={false}
                                detail_selected={this.props?.showSeasonSelectedFilters.show ? this.state.selectedShowForSearch : null}
                                // detail_selected={this.props?.showSeasonSelectedFilters?.show ? {
                                //     value: this.props?.showSeasonSelectedFilters?.show,
                                //     text: this.state.filterList?.showList.find(item => item.value === this.props?.showSeasonSelectedFilters?.show)?.text
                                // } : null}
                                options={this.state.filterList?.showList || []}
                                valueSelected={(e, newValue) => {
                                    if (newValue) {
                                        this.setState({ selectedShowForSearch: newValue })
                                        this.selectedShow(newValue)
                                        this.props.updateListState(newValue != null ? newValue.value : null, 'show', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection("show", (newValue != null ? newValue?.value : null), newValue != null ? newValue?.text : null, "show")
                                    } else {
                                        this.setState({ selectedShowForSearch: null }, () => {
                                            if (!this.state.selectedShowForSearch) {
                                                this.props.updateListState(null, 'selectedSeason', 'showSeasonSelectedFilters')
                                                this.handleFilterSelection('season', null, null, 'selectedSeason')
                                                this.props.updateListState(null, 'episodeName', 'showSeasonSelectedFilters')
                                                this.handleFilterSelection('episode name', null, null, 'episodeName')
                                            }
                                        })
                                        this.props.updateListState(null, 'show', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection("show", null, null, "show")

                                    }
                                }}
                                searchText={ev => {
                                    // dont fire API if the user delete or not entered anything
                                    if (ev.target.value !== "" && ev.target.value !== null) {
                                        this.getShowDetails("SHOW", filterConfig.show, "showList", ev.target.value);
                                    } else {
                                        this.setState({ isShowFetching: false })
                                    }
                                }}

                            />
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <SelectField
                                    id="Season"
                                    value={this.props.showSeasonSelectedFilters?.selectedSeason}
                                    placeHolderText="- Season -"
                                    options={this.props?.showSeasonSelectedFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList || []}
                                    onChange={(e) => {
                                        let selectedValue = (this.props?.showSeasonSelectedFilters?.show ? this.state.filterList?.showBasedSeasonList : this.state.filterList?.seasonList)?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'selectedSeason', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('season', selectedValue?.value, selectedValue?.label, 'selectedSeason')
                                        let selectedShowSeason =
                                            this.state.seasonBasedEpisodes?.find(
                                                (item) => item.season_id === e.target.value
                                            )?.show_season_id;
                                        this.setState(
                                            { show_season_id: selectedShowSeason },
                                            () => {
                                                this.getShowBasedEpisodes(this.state.show_season_id);
                                            }
                                        );
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={6} >
                                <BasicTextField
                                    id="show-year"
                                    placeholder="- Show Yr. -"
                                    value={this.props.showSeasonSelectedFilters?.showYear || ''}
                                    onChange={(e) => {
                                        this.props.updateListState(e.target.value, 'showYear', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('showYear', "showYear", e.target.value, 'showYear')
                                    }}

                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <SelectField
                                id="episode"
                                // label={"Seasons"}
                                placeHolderText="- Episode Name -"
                                options={this.props?.showSeasonSelectedFilters?.selectedSeason ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption || []}
                                width="80%"
                                value={this.props?.showSeasonSelectedFilters?.episodeName || null}
                                onChange={(e) => {
                                    let selectedValue = (this.props?.showSeasonSelectedFilters?.selectedSeason ? this.state.filterList?.seasonBasedEpisodeList : this.state.filterList?.episodeOption)?.find(item => item.value === e.target.value);
                                    this.props.updateListState(e.target.value, 'episodeName', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('episode name', selectedValue?.value, selectedValue?.label, 'episodeName')
                                }}
                            />
                            {/* <BasicTextField
                                id="episodeName"
                                placeholder="- Episode Name -"
                                value={this.props.showSeasonSelectedFilters?.episodeName || ""}
                                onChange={(e) => {
                                    this.props.updateListState(e.target.value, 'episodeName', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('episode name', 'episodeName', e.target.value, 'episodeName')
                                }}
                            /> */}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    id="episode"
                                    placeholder="- Episode # -"
                                    value={this.props.showSeasonSelectedFilters?.episode || ""}
                                    onChange={(e) => {
                                        this.props.updateListState(e.target.value, 'episode', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('episode', 'episode', e.target.value, 'episode')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicTextField
                                    id="projectEps"
                                    placeholder="- Proj. Eps. -"
                                    value={this.props.showSeasonSelectedFilters?.projEpisode || ""}
                                    onChange={(e) => {
                                        this.props.updateListState(e.target.value, 'projEpisode', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('project eps', 'projEps', e.target.value, 'projEpisode')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={6}>
                                <BasicTextField
                                    id="compEps"
                                    placeholder="- Comp. Eps. -"
                                    value={this.props.showSeasonSelectedFilters?.compEpisode || ""}
                                    onChange={(e) => {
                                        this.props.updateListState(e.target.value, 'compEpisode', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('comp episode', 'compEpisode', e.target.value, 'compEpisode')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={2}>
                        <MDBRow>
                            <h6>Other</h6>
                        </MDBRow>
                        <MDBRow>
                            <SelectField
                                id="Network"
                                value={this.props.showSeasonSelectedFilters?.selectedNetwork || ""}
                                placeHolderText="- Network -"
                                options={this.state.filterList?.networkList || []}
                                onChange={(e) => {
                                    let selectedValue = this.state.filterList?.networkList?.find(item => item.value === e.target.value);
                                    this.props.updateListState(e.target.value, 'selectedNetwork', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('network', selectedValue?.value, selectedValue?.label, 'selectedNetwork')
                                }}
                            />
                        </MDBRow>
                        <MDBRow>
                            <SelectField
                                id="Show Duration"
                                value={this.props.showSeasonSelectedFilters?.selectedShowDuration || ""}
                                placeHolderText="- Show Duration -"
                                options={this.state.filterList?.showDurationList || []}
                                onChange={(e) => {
                                    let selectedValue = this.state.filterList?.showDurationList?.find(item => item.value === e.target.value);
                                    this.props.updateListState(e.target.value, 'selectedShowDuration', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('show duration', selectedValue?.value, selectedValue?.label, 'selectedShowDuration')
                                }}
                            />
                        </MDBRow>
                        <MDBRow>
                            <SelectField
                                id="Internet Show"
                                value={this.props.showSeasonSelectedFilters?.selectedNetworkShow || ""}
                                placeHolderText="Internet Show"
                                options={[{ label: "Select", value: null }, { label: "Yes", value: 1 }, { label: "No", value: 2 }] || []}
                                onChange={(e, newValue) => {
                                    this.props.updateListState(e.target.value, 'selectedNetworkShow', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('internet show', "selectedNetworkShow", e.target.value, 'selectedNetworkShow')
                                }}
                            />
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={3}>
                        <MDBRow>
                            <h6>People</h6>
                        </MDBRow>
                        <MDBRow>
                            <SelectField
                                id="productionEntity"
                                value={this.props.showSeasonSelectedFilters?.selectedProductionEntity || ""}
                                placeHolderText="- Production Entity -"
                                options={this.state.filterList?.productionEntityList || []}
                                onChange={(e) => {
                                    let selectedValue = this.state.filterList?.productionEntityList?.find(item => item.value === e.target.value);
                                    this.props.updateListState(e.target.value, 'selectedProductionEntity', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('production entity', selectedValue?.value, selectedValue?.label, 'selectedProductionEntity')
                                }}
                            />
                        </MDBRow>
                        <MDBRow id="mh-2">
                            <BasicTextField
                                id="productionExec"
                                placeholder="- Production Exec -"
                                value={this.props.showSeasonSelectedFilters?.productionExec || ""}
                                onChange={(e) => {
                                    this.props.updateListState(e.target.value, 'productionExec', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('Prod Exec', 'prodExec', e.target.value, 'productionExec')
                                }}
                            />
                        </MDBRow>
                        <MDBRow id="mh-2" className="lh-5">
                            <BasicTextField
                                id="estimator"
                                placeholder="- Estimator -"
                                value={this.props.showSeasonSelectedFilters?.estimator || ""}
                                onChange={(e) => {
                                    this.props.updateListState(e.target.value, 'estimator', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection('estimator', 'estimator', e.target.value, 'estimator')
                                }}
                            />
                        </MDBRow>
                        <MDBRow className="lh-5 m-t2">
                            <SearchSelectField
                                id="clearence rep"
                                placeholder="- Clearance Rep -"
                                width="100%"
                                searchSelect={true}
                                multiple={false}
                                loading={this.state.isUserListFetching}
                                detail_selected={this.props?.showSeasonSelectedFilters?.user ? this.state.selectedUserForSearch : null}
                                // detail_selected={this.props?.showSeasonSelectedFilters?.user ? {
                                //      value: this.props?.showSeasonSelectedFilters?.user,
                                //      text: this.state.filterList?.userList.find(item => item.value === this.props?.showSeasonSelectedFilters?.user)?.text
                                // } : null}
                                options={this.state.filterList?.userList || []}
                                valueSelected={(e, newValue) => {
                                    this.setState({ selectedUserForSearch: newValue })
                                    let selectedShow = this.state.filterList?.userList?.find(item => item.value === (newValue != null ? newValue.value : null));
                                    this.props.updateListState(newValue != null ? newValue.value : null, 'user', 'showSeasonSelectedFilters')
                                    this.handleFilterSelection("user", (newValue != null ? selectedShow?.value : null), newValue != null ? selectedShow?.text : null, "user")
                                }}
                                searchText={ev => {
                                    if (ev.target.value !== "" && ev.target.value !== null) {
                                        this.getUserList("USER", filterConfig.user, "userList", ev.target.value);
                                    } else {
                                        this.setState({ isUserListFetching: false })
                                    }
                                }}

                            />
                        </MDBRow>
                        <MDBRow className="lh-5">
                            <BasicTextField
                                id="composers"
                                placeholder="- Composers -"
                                value={this.props.showSeasonSelectedFilters?.composers || ""}
                                onChange={(e) => {
                                    this.handleFilterSelection('composers', 'composers', e.target.value, 'composers');
                                    this.props.updateListState(e.target.value, 'composers', 'showSeasonSelectedFilters')
                                }}
                            />
                        </MDBRow>
                    </MDBCol>
                    <MDBCol md={5}>
                        <MDBRow>
                            <h6>Rights</h6>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <SelectField
                                    id="initialRights"
                                    value={this.props.showSeasonSelectedFilters?.selectedInitialRight || ''}
                                    placeHolderText="- Initial Rights -"
                                    options={this.state.filterList?.initialRightsList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.initialRightsList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'selectedInitialRight', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('initial right', selectedValue?.value, selectedValue?.label, 'selectedInitialRight')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <SelectField
                                    id="initialRights-term"
                                    value={this.props.showSeasonSelectedFilters?.initialRightsTerm || ''}
                                    placeHolderText="- Term -"
                                    options={this.state.filterList?.termsList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.termsList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'initialRightsTerm', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('initial-right term', selectedValue?.value, selectedValue?.label, 'initialRightsTerm')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <SelectField
                                    id="initialRights-territory"
                                    value={this.props.showSeasonSelectedFilters?.initialRightsTerritory || ''}
                                    placeHolderText="- Territory -"
                                    options={this.state.filterList?.territoryList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.territoryList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'initialRightsTerritory', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('initial-right territory', selectedValue?.value, selectedValue?.label, 'initialRightsTerritory')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <SelectField
                                    id="AltRights"
                                    value={this.props.showSeasonSelectedFilters?.selectedAltInitialRight || ''}
                                    placeHolderText="- Alt Initial Rights -"
                                    options={this.state.filterList?.altInitialRightsList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.altInitialRightsList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'selectedAltInitialRight', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('alt-initial right', selectedValue?.value, selectedValue?.label, 'selectedAltInitialRight')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <SelectField
                                    id="AltRights-term"
                                    value={this.props.showSeasonSelectedFilters?.altRightsTerm || ''}
                                    placeHolderText="- Term -"
                                    options={this.state.filterList?.termsList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.termsList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'altRightsTerm', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('alt-right term', selectedValue?.value, selectedValue?.label, 'altRightsTerm')
                                    }}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <SelectField
                                    id="AltRights-territory"
                                    value={this.props.showSeasonSelectedFilters?.altRightsTerritory || ''}
                                    placeHolderText="- Territory -"
                                    options={this.state.filterList?.territoryList || []}
                                    onChange={(e) => {
                                        let selectedValue = this.state.filterList?.territoryList?.find(item => item.value === e.target.value);
                                        this.props.updateListState(e.target.value, 'altRightsTerritory', 'showSeasonSelectedFilters')
                                        this.handleFilterSelection('alt-right territory', selectedValue?.value, selectedValue?.label, 'altRightsTerritory')
                                    }}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
});