import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SearchResultsLayout from './SearchResultsLayout';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import './AutoCompleteSearch.scss';
import SearchIcon from "@material-ui/icons/Search";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import axios from 'axios';
import { MDBIcon } from 'mdbreact';
import { withUserContext } from '../../../contexts/UserContext';

export default withUserContext(class AutoCompleteSearchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            options: [],
            loading: false,
            isOpen: false,
            parentDropDownSelection: 1,
            childDropDownSelection: [],
            defautSelection: [],
            searchKeyWord: '',
            searchCancelToken: null,
            aesKey: ''
        }
    }
    componentDidMount() {
        if (this.props.encryptionType == 2) {
            this.getAes();
        }
    }
    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });
    sleep = (delay = 0) => {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }
    onChangeHandle = async value => {
        let option = {
            "searchString": value,
        }
        let active = true;
        this.setState({ searchKeyWord: value })
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });

        if (!this.state.loading) {
            return undefined;
        }
        if (value == '') {
            this.setState({ loading: false, options: [] })
            return undefined;
        }
        let path = '';
        if (this.props.dataUrl == 'CUE_SHEET_PUBLISHER' || this.props.dataUrl == 'PD_COMPOSER' || this.props.dataUrl == 'PERFORMER' || this.props.dataUrl == 'WRITER' || this.props.dataUrl == 'SHOW') {
            path = Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${this.props.dataUrl}&searchString=${value}`
        } else if(this.props.dataUrl == 'SHOW&SEASON') {
            path = Constants.ClearTrackServiceBaseUrl + '/' + 'entitySearch?entity=SHOW' + `&searchString=${value}&all_seasons=1`
        }else {
            path = Constants.ClearTrackServiceBaseUrl + '/' + this.props.dataUrl + `?searchString=${value}`
        }
        (async () => {
            clearTrackService.getDataWithCancel(path,
                cancelToken, this.props.userContext?.active_tenant?.tenant_id, null, (this.props.encryptionType || 1), this.state.aesKey)
                .then(response => {
                    if (active) {
                        if (this.props.dataUrl === "CUE_SHEET_PUBLISHER") {
                            let formattedList = response.data?.map(item => ({ name: item.cue_sheet_publisher_name, id: item.cue_sheet_publisher_id }));
                            this.setState({ options: formattedList, loading: false });
                        } else if (this.props.dataUrl === "PD_COMPOSER") {
                            let formattedList = response.data?.map(item => ({ name: item.pd_composer_name, id: item.pd_composer_id, year_died: item.year_died, comment:item.comment }));
                            this.setState({ options: formattedList, loading: false });
                        } else if (this.props.dataUrl === "PERFORMER") {
                            let formattedList = response.data?.map(item => ({ name: item.performer_name, id: item.performer_id }));
                            this.setState({ options: formattedList, loading: false });
                        } else if (this.props.dataUrl === "WRITER") {
                            let formattedList = response.data?.map(item => ({ name: item.writer_name, id: item.writer_id,affiliation_id:item?.affiliation_id,affiliation_name:item?.affiliation_name,share : item?.share }));
                            this.setState({ options: formattedList, loading: false });
                        }else if (this.props.dataUrl === "SHOW") {
                            let formattedList = response.data?.map(item => ({ name: item.show_name, id: item.show_id }));
                            this.setState({ options: formattedList, loading: false });
                        }else if (this.props.dataUrl === "SHOW&SEASON") {
                        let formattedList = response.data?.map(item => ({ name: item.show_name, id: item.show_season_id }));
                        this.setState({ options: formattedList, loading: false });
                        }
                        else {
                            this.setState({ options: response.data, loading: false });
                        }
                    }
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err)
                    })
        })();
        return () => {
            active = false;
        };
    };
    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });
    handleParentSearchChange = (event) => {
        this.setState({ parentDropDownSelection: event.target.value, defautSelection: [] })
    };
    getAes = () => {
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/aesSecretKey`)
            .then(response => {
                let secretKey = response?.data;
                this.setState({ aesKey: secretKey })
            },
                (err) => {
                    console.log("Error in AES:", err)
                })
    }
    render() {
        return (
            <div id='searchAutoComplete'>
                <Autocomplete
                    id="asynchronous-demo" className='searchautoCompleteStyle'
                    open={this.state.open}
                    onOpen={() => {
                        this.setState({ open: true })
                    }}
                    onClose={() => {
                        this.setState({ open: false })
                    }}
                    renderOption={(props, option) => (
                        <>
                            <li {...props}>
                                <SearchResultsLayout searchResult={props}
                                    headerText={this.props.headerText}></SearchResultsLayout>
                            </li>
                        </>
                    )}
                    onChange={(event, newValue) => {
                        this.props.handleSearchSelect(newValue);
                        this.setState({options:[]})
                    }}
                    getOptionLabel={(option) => (option?.name)}
                    options={this.state.options}
                    value={null}
                    loading={this.state.loading}
                    renderTags={() => null}
                    noOptionsText="No labels"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={this.props?.placeholder}
                            variant="outlined"
                            onChange={ev => {
                                if (ev.target.value !== "" || ev.target.value !== null) {
                                    this.setState({ loading: true }, () => { this.onChangeHandle(ev.target.value) })
                                } else { this.setState({ loading: true, options: [] }) }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {this.state.loading ?
                                            <CircularProgress color="inherit" size={20} /> : null}
                                        {this.props.headerText === "User Management Group" ? <MDBIcon icon="chevron-right" /> : <SearchIcon />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </div>
        );
    }
}
);