import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import TableComponent from "../../../SharedComponents/Table";
import { efcRecipientConfig, efcRecipientList } from "./Config";
import * as Constants from "../../../../constants/constants";
import ClearTrackService from "../../../../services/service";
import BasicLabel from "../../../SharedComponents/BasicLabel/BasicLabel";
import SearchSelectField from "../../../SharedComponents/SearchSelectField";
import SelectField from "../../../SharedComponents/SelectField/SelectField";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../../contexts/UserContext';
import { validateEmail } from '../../../../Common/Helper'
import axios from 'axios';
import { updateTableSortConfigObject, tableSortList } from '../../../../Common/TableHelper';




class CueSheetRecipient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      config: { ...efcRecipientConfig },
      showOptions: [],
      divisionOptions: [],
      cueSheetRecipient: { selected_program: null, season_id: null, division_id: null },
      loadingShows: false,
      isLoading: false,
      searchCancelToken: null,
      mandatoryNotExist: false,
      isFetchingSeason: false,
      sortBy: "Value",
      isSortByAsc: true,
      sortCount: 0,
      isSubmit: false,
      allSHowOption:{text: 'All', value : 'ALL', division_id : null}
    };
  }

  componentDidMount() {
    this.setState({showOptions: [this.state.allSHowOption]})
    this.handleShowEdit("selected_program", this.state.allSHowOption);
    this.getDivisions()
    this.getProgramsDetails(' ')
  }


  getProgramsDetails = (value) => {
    // if (event.target.value !== "") {
      this.setState({ loadingShows: true });
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request.");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      ClearTrackService.getDataWithCancel(
        Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=SHOW&searchString=${value}`, cancelToken,
        this.props?.userContext?.active_tenant?.tenant_id, null,
      )
        .then((res) => {
          let formattedList = res.data.map((item) => ({
            value: item.show_id,
            text: item.show_name,
            division_id: item?.division_id
          }));
          formattedList.unshift(this.state.allSHowOption)
          this.setState({ showOptions: formattedList, loadingShows: false, mandatoryNotExist: false, });
        })
        ,((err) => {
          this.setState({ showOptions: [], loadingShows: false });
        });
    //}
  };

  selectedShow = (newValue) => {
    if (newValue && newValue?.value !== 'ALL') {
      this.handleShowEdit("selected_program", newValue);
      this.handleShowEdit("season_id", null);
      this.setState({ renderList: [] })
      this.setState({ isFetchingSeason: true })
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
        `/seasonsBasedOnShows?show_id=${newValue.value}`,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
            (item, index, data) =>
              index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
          ) : [];
          let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
            value: item.show_season_id,
            label: item.season_name,
          })) : [];
          if(formattedList.length > 0) {
            this.handleShowEdit("season_id", formattedList[0].value)
          }
          this.setState({ mandatoryNotExist: false, isFetchingSeason: false ,renderList: [], seasonOptions: formattedList.length > 0 ? formattedList : [] },this.getProgramsRenderList)
        },
        (err) => {
          console.log("Error in fetching Details:", err);
        }
      );
    } else {
      this.handleShowEdit("selected_program", newValue ? newValue : null);
      this.handleShowEdit("season_id", null);
      this.setState({ renderList: [] , seasonOptions:[]}, () => {
        if(newValue) {
          this.getProgramsRenderList()
        }
      })
    }
  };

  getProgramsRenderList = (loader=true) => {
    this.setState({ isLoading: loader });
    let path  = this.state.cueSheetRecipient?.selected_program?.value  !== 'ALL' ? 
                `show_season_id=${this.state.cueSheetRecipient?.season_id}&season_division_id=null` : `show_season_id=null&season_division_id=${this.state.cueSheetRecipient?.division_id}`
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/showSeasonCueSheetRecipients?${path}`,
      this.props?.userContext?.active_tenant?.tenant_id,
      this.state.cueSheetRecipient?.selected_program?.division_id ? this.state.cueSheetRecipient?.selected_program?.division_id : this.state.cueSheetRecipient.division_id
    )
      .then((response) => {
        let list = response?.data?.map((item) => ({
          name: item.name,
          email: item.email,
          company: item.company,
          id: this.state.cueSheetRecipient?.selected_program?.value !== 'ALL'  ? item?.show_season_cue_sheet_recipients_id : item?.season_cue_sheet_recipients_id,
          is_active: item.is_active,
        }));
        let configRecord = {...this.state.config}
        this.props?.permissionArray[0].edit === 0 ? configRecord.actions = ["times"] :
        this.props?.permissionArray[0].delete === 0 ? configRecord.actions = ["pen"] :  configRecord.actions = ["pen","times"],
        this.setState({
          renderList: list?.length > 0 ? list : [],
          config: configRecord,
          isLoading: false,
          isSubmit: false,
          mandatoryNotExist: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("Error in fetching  Details:", err);
      });
  };

  handleInlineEdits = (id, dataItem) => {
    let allDetails = [...this.state.renderList];
    this.setState({
      mandatoryNotExist: false,
      renderList: allDetails.map((item) => {
        let newItem = {...item}
        if (item.id == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ mandatoryNotExist: false })
    if (data[index]?.name && data[index]?.name?.length > 0 && data[index]?.email && data[index]?.email.length > 0 && validateEmail(data[index]?.email)) {
      let recipientData = [...data];
      if (this.state.cueSheetRecipient?.selected_program?.value !== "ALL") {
        recipientData[index].show_season_cue_sheet_recipients_id = recipientData[index]?.id;
      } else {
        recipientData[index].season_cue_sheet_recipients_id = recipientData[index]?.id;
      }
      recipientData[index].show_id = this.state.cueSheetRecipient?.selected_program?.value !== 'ALL' ? this.state.cueSheetRecipient?.selected_program?.value : null,
      recipientData[index].type = this.state.cueSheetRecipient?.selected_program?.value !== 'ALL' ? "SHOW" : "SEASON",
      recipientData[index].show_season_id = this.state.cueSheetRecipient?.selected_program?.value === 'ALL' ? null : this.state.cueSheetRecipient?.season_id;
      recipientData[index].division_id = this.state.cueSheetRecipient?.selected_program?.value === 'ALL' ? this.state.cueSheetRecipient?.division_id : this.state.cueSheetRecipient?.selected_program?.division_id
      this.postCueSheetRecipientData(recipientData[index], recipientData[index]?.id );
    } else {
      this.setState({ mandatoryNotExist: true })
    }
  };


  postCueSheetRecipientData = (recipientDetails, postFlag) => {
    this.setState({ isSubmit: postFlag });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/showSeasonCueSheetRecipients`,
      recipientDetails,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({
            isSubmit: false,
          });
          this.props.toastMessageNotification(true, "fail");
        } else {
          // this.setState({
          //   isSubmit: false,
          // });
          this.props.toastMessageNotification(true, "success");
          if (recipientDetails?.editing) {
            recipientDetails.editing = false;
          }
          this.getProgramsRenderList(false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props.toastMessageNotification(true, "fail");
      }
    );
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ mandatoryNotExist: false })
    if (newDataItem?.name && newDataItem?.name?.length > 0 && newDataItem?.email && newDataItem?.email.length > 0 && validateEmail(newDataItem?.email)) {
      let newCueSheetRecipientData = {
        is_active: 1,
        name: newDataItem.name || null,
        email: newDataItem.email || null,
        company: newDataItem.company || null,
        show_season_id: this.state.cueSheetRecipient?.selected_program?.value === 'ALL' ? null : this.state.cueSheetRecipient?.season_id,
        type: this.state.cueSheetRecipient?.selected_program?.value !== 'ALL' ? "SHOW" : "SEASON",
        show_id : this.state.cueSheetRecipient?.selected_program?.value !== 'ALL' ? this.state.cueSheetRecipient?.selected_program?.value : null,
        division_id: this.state.cueSheetRecipient?.selected_program?.value === 'ALL' ? this.state.cueSheetRecipient?.division_id : this.state.cueSheetRecipient?.selected_program?.division_id
      };
      this.postCueSheetRecipientData(newCueSheetRecipientData, 'newItemPost');
    } else {
      this.setState({ mandatoryNotExist: true })
    }
  };

  handleDivisionId=(field, value) => {
    this.setState((prevState) => ({
      cueSheetRecipient: {
        ...prevState.cueSheetRecipient,
        [field]: value,
      },
    }), () => {
      if(this.state.cueSheetRecipient?.selected_program) {
        this.getProgramsRenderList()
      }
    });
  };


  getDivisions = () => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      '/staticData?staticTable=division', this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let divisions = response.data?.map(item => ({
          label: item.name,
          value: item.id,
        }));
          this.setState({ divisionOptions: divisions}, () => {
            this.handleDivisionId('division_id',divisions[0]?.value)
          })
      }
      )
  }

  handleShowEdit = (field, value, callList = false) => {
    this.setState((prevState) => ({
      cueSheetRecipient: {
        ...prevState.cueSheetRecipient,
        [field]: value,
      },
    }), () => {
      if(callList) {
        this.getProgramsRenderList()
      }
    });
  };

  deleteRecipient = (recipientDetails) => {
    this.setState({ isSubmit: recipientDetails?.id })
    let tableName = this.state.cueSheetRecipient?.selected_program?.value !== 'ALL' ? 'show_season_cue_sheet_recipients' : 'season_cue_sheet_recipients'
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=${tableName}&tableId=${recipientDetails?.id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(res => {
      if (res.data[0].status === "SUCCESS") {
        let recipientData = {};
        if (this.state.cueSheetRecipient?.selected_program?.value !== 'ALL' ) {
          recipientData = {
            ...recipientDetails,
            is_delete: 1,
            show_season_id: this.state.cueSheetRecipient?.season_id,
            show_id: this.state.cueSheetRecipient?.selected_program?.value || null,
            show_season_cue_sheet_recipients_id: recipientDetails?.id,
            division_id: this.state.cueSheetRecipient?.selected_program?.division_id || null,
            type: 'SHOW'
          };
        } else {
          recipientData = {
            ...recipientDetails,
            is_delete: 1,
            type: 'SEASON',
            season_cue_sheet_recipients_id: recipientDetails?.id,
            division_id: this.state.cueSheetRecipient?.division_id,
          };
        }
        this.postCueSheetRecipientData(recipientData, recipientDetails?.id )
      } else {
        this.props?.fieldChanged(true, 'fail')
        this.setState({ isSubmit: false });
      }
    }),
      ((err) => {
        this.props?.fieldChanged(true)
        this.setState({ isSubmit: false });
      });
  };
  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
    }, () => {
      this.setState({
        sortBy: colName,
        renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Name": return "name";
      case "Email": return "email";
      case "Company": return "company";
    }
  }


  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <div className="Efc-Container">
        <MDBRow>
          
            <MDBCol md={2} className="inline-block">
              {this.state.isFetchingSeason ? <CircularProgress /> : <SelectField
                id={"key27"}
                label="Seasons"
                // isMandatory={true}
                value={this.state.cueSheetRecipient?.season_id}
                options={this.state.seasonOptions}
                width="80%"
                disabled={this.state.cueSheetRecipient?.selected_program?.value === 'ALL'}
                multiple={false}
                onChange={(e) => this.handleShowEdit('season_id',e.target.value, true)}
              />}
            </MDBCol>
            <MDBCol md={3} className="inline-block">
              <BasicLabel text="Program" />
              <SearchSelectField
                id={"key27"}
                //detail_selected={this.state.cueSheetRecipient?.selected_program?.text==="All" ? { value: null, text: "All" } :this.state.cueSheetRecipient?.selected_program ? { value: item.show_id, text: item.show_name } : null}
                detail_selected={this.state.cueSheetRecipient?.selected_program }
                options={this.state.showOptions}
                loading={this.state.loadingShows}
                placeholder={'- Search -'}
                width="80%"
                multiple={false}
                className="margint"
                searchSelect={true}
                searchText={(e) => {this.getProgramsDetails(e.target.value)}}
                valueSelected={(e, newValue) => this.selectedShow(newValue)}
              />
            </MDBCol>
            {this.state.cueSheetRecipient?.selected_program?.text==="All" ?
            <MDBCol md={2} className="inline-block marginl">
              {this.state.cueSheetRecipient?.selected_program?.value === 'ALL' && <SelectField
                id={"key27"}
                label="Division"
                options={this.state.divisionOptions}
                value={this.state.cueSheetRecipient?.division_id}
                detail_selected={{value:1,label:"WB TV"}?this.state.cueSheetRecipient?.division_id:null}
                onChange={(e) => this.handleDivisionId("division_id", e.target.value)}
                width="80%"
                multiple={false}               
              />}
            </MDBCol> : null}
          
          <span className="errorText">{this.state.mandatoryNotExist && "Please enter name and email fields value"}</span>
          {/* <span className="errorText"> Please enter name and email fields value</span> */}
          {this.state.isLoading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
              <MDBCol md={12} id="table-bg-sync-efc">
                <TableComponent
                  list={this.state.renderList || []}
                  className={"clearencRecord"}
                  stickyHeader={true}
                  config={this.state.config}
                  handleAddNewItem={this.handleAddNewItem.bind(this)}
                  editCallback={this.handleInlineEdits.bind(this)}
                  saveCallback={this.saveInlineEdits.bind(this)}
                  addItemToList={this.state.cueSheetRecipient?.selected_program?.value}
                  // isLoading={this.state.isSubmit}
                  handleDelCallBack={(id, item) => this.deleteRecipient(item)}
                  readCallback={(index) => {
                    let cueSheetData = [...this.state.renderList];
                    cueSheetData[index].editing = false
                    this.setState({ renderList: cueSheetData, mandatoryNotExist: false });
                  }}
                  sortCallback={this.handleTableColumnClick}
                  postInitiated={this.state.isSubmit}
                />
              </MDBCol>
            )}
        </MDBRow>
      </div>
    );
  }
}

export default withUserContext(CueSheetRecipient);
