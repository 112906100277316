import React from "react";
import { MDBRow, MDBContainer, MDBCol, MDBIcon } from "mdbreact";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import { ProducerContacts, ContactItem } from "./Config";
import DateField from '../../SharedComponents/DateField/DateField';
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import { validateEmail,validateNumDotOnly } from '../../../Common/Helper'
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from '../../../contexts/UserContext';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import './BusinessAffairs.scss';
let postObj = [], canUploadEditViewDealMemo = false, queryParam = {}, show_season_people_deal_id = null, fileNameArr = [], allFiles = [], oldFileNameCombined = "", fileListReplace = [], fileToDeleteCombined = [], tempUploadfileNameArr = [], fileList = [];
class EditDealModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            postInitiated: false,
            isPosting: false,
            dealTypeOptions: [],
            dealStatusOptions: [],
            unionOptions: [],
            initialRecordData: this.props?.businessAffairsDealsData,
            show_season_id: this.props?.show_season_id,
            filesArr: [],
            filesArrBackup: [],
            isFileLoading: false,
            isDeleteFile: false,
            openNotification: false,
            notification: null,
            fileData: null,
            fileName: null,
            formData: null,
            fileNameArr: [],
            fileList: [],
            fileListReplaceArr: [],
            isFilePosting: false
        };
    }
    handleChange(field, value) {
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                [field]: value
            },
        }));
    }
    fetchData = (field, url) => {
        this.setState({ isLoading: true })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ isLoading: false });
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({ isLoading: false });
                    let formattedList = [];
                    switch (field) {
                        case 'dealTypeOptions':
                            formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                            this.setState({
                                [field]: formattedList
                            })
                        case 'dealStatusOptions':
                            formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                            this.setState({
                                [field]: formattedList
                            })
                        case 'unionOptions':
                            formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
                            this.setState({
                                [field]: formattedList
                            })
                            break;
                    }
                }
            },
                (err) => {
                    this.setState({ isLoading: false });
                    this.props?.notificationComponent(true, "fail")
                    console.log("Error in fetching Address Types:", err)
                })
    }
    postData = (postJson) => {
        let showError = false, totalFileSize = 0;
        if (this.state.fileList?.length > 0) {
            this.state.fileList.map((item) => { totalFileSize += item.size; })
        };
        if (this.state.fileListReplaceArr?.length > 0) {
            this.state.fileListReplaceArr.map((item) => { totalFileSize += item.size; })
        };
        console.log("totalFileSize", totalFileSize);
        totalFileSize = totalFileSize / (1024 * 1024);
        console.log("totalFileSize", totalFileSize);
        if ((this.state.fileList.length > 0 && totalFileSize > 4) || (this.state.fileListReplaceArr?.length > 0 && totalFileSize > 4)) {
            showError = true;
            this.setState({ showError: true, isPosting: false })
        }
        if ((this.state.fileList.length > 0 && totalFileSize < 4) || (this.state.fileListReplaceArr.length > 0 && totalFileSize < 4)) {
            show_season_people_deal_id = postJson.show_season_people_deal_id;
            showError = false;
            this.setState({ showError: false })
        }
        if (this.state.fileList.length === 0 && this.state.fileListReplaceArr.length === 0) {
            show_season_people_deal_id = postJson.show_season_people_deal_id;
            showError = false;
            this.setState({ showError: false })
        }

        if (showError === false) {
            this.setState({ isPosting: true });
            let extensionUrl = '/dealDetails';
            clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                .then((response) => {
                    if (response.data.error) {
                        this.setState({ isPosting: false })
                        this.props?.notificationComponent(true, "fail");

                    } else {
                        if ((this.state.fileList.length > 0 && totalFileSize < 4) || (this.state.fileListReplaceArr.length > 0 && totalFileSize < 4)) {
                            this.handleUploadSubmit();
                        }
                        if (fileToDeleteCombined.length > 0) {
                            this.handleDeleteFiles();
                        }
                        if (this.state.fileList.length == 0 && this.state.fileListReplaceArr.length == 0) {
                            //this.setState({ isPosting: false })
                            oldFileNameCombined = "";
                            this.props?.fetchDealData(show_season_people_deal_id);
                        }
                        this.setState({ isPosting: false });
                        this.props?.notificationComponent(true, "success");
                    }
                },
                    (err) => {
                        this.setState({ isPosting: false })
                        this.props?.notificationComponent(true, "fail")
                        console.log("Post agency details error: " + err);
                    });
        }


    }
    handleSubmit() {
        this.setState({ isPosting: true });
        postObj = this.state.initialRecordData;
        delete postObj["deal_status_name"];
        delete postObj["deal_type_name"];
        delete postObj["union_name"];
        postObj["show_season_id"] = this.props?.show_season_id;
        this.postData(postObj);
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            canUploadEditViewDealMemo = permissions.filter((obj, index) => obj.permission_name === "Deal Details: Deal Memo PDF(s)" && obj.view === 1 && obj.edit === 1);
            canUploadEditViewDealMemo = canUploadEditViewDealMemo.length > 0 ? true : false;
        }
    }
    onLoadFetchData = (url, queryParamObj, loader = true) => {
        this.setState({ isFileLoading: loader });
        clearTrackService.getDataParams(Constants.ClearTrackServiceBaseUrl + url, queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isFileLoading: false, isDeleteFile: false });
                if (response?.data?.data?.length > 0) {
                    this.setState({ filesArr: response.data.data, filesArrBackup: response.data.data });
                } else {
                    this.setState({ filesArr: [], filesArrBackup: [] });
                }
                if (!loader) {
                    this.setState({ openNotification: true, notification: 'File deleted successfully' });
                }
            },
                (err) => {
                    this.setState({ isFileLoading: false });
                    console.log("Error in fetching data", err)
                })
    }
    componentDidMount() {
        this.getPermissions(this.props.permissionArr);
        queryParam = {
            "showName": this.props?.detailsToDisplay?.show_name,
            "season": this.props?.detailsToDisplay?.season_name?.replace(/ /g, ""),
            "module": "showseasondeal",
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            "dealId": this.props?.detailsToDisplay?.show_season_people_deal_id,
            "peopleName": this.props?.detailsToDisplay?.full_name,
            "peopleId": this.props?.detailsToDisplay?.show_season_people_id,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }
        this.onLoadFetchData(`/getFiles`, queryParam);
        this.fetchData("dealTypeOptions", `/staticData?staticTable=lu_deal_type`);
        this.fetchData("dealStatusOptions", `/staticData?staticTable=lu_deal_status`);
        this.fetchData("unionOptions", `/staticData?staticTable=lu_union`);
    }
    replaceFile(file, oldFileName, index) {
        oldFileNameCombined = oldFileNameCombined + oldFileName + ",";
        this.setState({ showError: false })
        try {
            let formData = new FormData();
            let fileObject = file;
            //let fileList = fileObject.splice(fileObject.length - 1, 1);
            let fileListReplaceArr = [...this.state.fileListReplaceArr]
            let fileListReplace = Array.from(fileObject);
            fileListReplaceArr.push(...fileListReplace);
            formData.append("showName", this.props?.detailsToDisplay?.show_name);
            formData.append("season", this.props?.detailsToDisplay?.season_name?.replace(/ /g, ""));
            formData.append("dealId", this.props?.detailsToDisplay?.show_season_people_deal_id);
            formData.append("episodeName", null);
            formData.append("module", "showseasondeal");
            formData.append("peopleName", this.props?.detailsToDisplay?.full_name);
            formData.append("peopleId", this.props?.detailsToDisplay?.show_season_people_id);
            formData.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
            fileListReplaceArr.map((item) => {
                formData.append("files", item);
            })
            formData.append("subModule", "");
            formData.append("songName", null);
            formData.append("songId", null);
            formData.append("replaceFileName", oldFileNameCombined.length > 0 ? oldFileNameCombined.slice(0, -1) : oldFileNameCombined);
            formData.append("isReplaceFile", 1);
            formData.append("divisionId", this.props.division_id);
            formData.append("divisionName", this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name);
            allFiles = [...this.state.filesArr];
            if (fileListReplace.length > 0) {
                allFiles.splice(index, 1)
                fileListReplace.map((item) => { allFiles.splice(index, 0, item.name) });
            }
            console.log("allFiles in replace file", allFiles);
            this.setState({ fileData: formData, formData, filesArr: allFiles, fileListReplaceArr });
        } catch (error) {
            console.log(error)
            this.setState({ fileData: null, formData: null, filesArr: [], fileListReplaceArr: [] });
        }
    }
    formDataHandler = (fileList) => {
        let formData = new FormData();
        formData.append("showName", this.props?.detailsToDisplay?.show_name);
        formData.append("season", this.props?.detailsToDisplay?.season_name?.replace(/ /g, ""));
        formData.append("dealId", this.props?.detailsToDisplay?.show_season_people_deal_id);
        formData.append("episodeName", null);
        formData.append("module", "showseasondeal");
        formData.append("peopleName", this.props?.detailsToDisplay?.full_name);
        formData.append("peopleId", this.props?.detailsToDisplay?.show_season_people_id);
        formData.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
        fileList.map((item) => {
            formData.append("files", item);
        })
        formData.append("subModule", "");
        formData.append("songName", null);
        formData.append("songId", null);
        formData.append("replaceFileName", "");
        formData.append("isReplaceFile", null);
        formData.append("divisionId", this.props.division_id);
        formData.append("divisionName", this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name);
        this.setState({ fileData: formData, formData })
    }
    uploadFile(file) {
        this.setState({ showError: false })
        try {
            fileList = Array.from(file);
            this.formDataHandler(fileList);
            let fileNameArr = []
            if (fileList.length > 0) {
                fileList.map((item) => { fileNameArr.push(item.name) });
            }
            this.setState({ fileNameArr, fileList });
        } catch (error) {
            console.log(error)
            this.setState({ fileData: null, formData: null, fileNameArr: [], fileList: [] });
        }
    }
    handleUploadSubmit = () => {
        this.setState({ isFilePosting: true });
        let extensionUrl = '/uploadFile';
        clearTrackService.uploadFileFormData(Constants.ClearTrackServiceBaseUrl + extensionUrl, this.state.formData, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isFilePosting: false, openNotification: true, notification: 'File upload failed!' });

                } else {
                    this.setState({ isFilePosting: false, fileName: null, openNotification: true, notification: 'File upload success!', isPosting: false });
                    oldFileNameCombined = "";
                    fileList = [];
                    queryParam.divisionId = this.props.division_id
                    queryParam.divisionName = this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
                    this.onLoadFetchData(`/getFiles`, queryParam);
                    this.props?.fetchDealData(show_season_people_deal_id);
                }
            },
                (err) => {

                    this.setState({ isFilePosting: false, openNotification: true, notification: 'File upload failed!' });
                    console.log("Post agency details error: " + err);
                });
    }
    deleteFiles = (fileName, index, action = null) => {
        tempUploadfileNameArr = [...this.state.fileNameArr];
        if (action === "sendToApi") {
            fileToDeleteCombined.splice(fileToDeleteCombined.length, 0, fileName);
        }
        if (action === "sendToApi" || action === "deletelocally") {
            let tempFilesArr = [...this.state.filesArr];
            tempFilesArr.splice(index, 1);
            this.setState({ filesArr: tempFilesArr });
        }
        if (action === null) {
            tempUploadfileNameArr.splice(index, 1);
            let tempFilesList = [];
            this.state.fileList.map((item, index) => {
                tempUploadfileNameArr.map((item2, index2) => {
                    if (item.name === item2) {
                        tempFilesList.push(item);
                    }
                })
            })
            this.formDataHandler(tempFilesList);
            this.setState({ fileNameArr: tempUploadfileNameArr, fileList: tempFilesList });
        }
    }
    handleDeleteFiles = () => {
        let queryParamObj = {
            "showName": this.props?.detailsToDisplay?.show_name,
            "season": this.props?.detailsToDisplay?.season_name?.replace(/ /g, ""),
            "dealId": this.props?.detailsToDisplay?.show_season_people_deal_id,
            "episodeName": null,
            "module": "showseasondeal",
            "fileName": fileToDeleteCombined,
            "subModule": "",
            "peopleName": this.props?.detailsToDisplay?.full_name,
            "peopleId": this.props?.detailsToDisplay?.show_season_people_id,
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "songName": null,
            "songId": null,
            "isBoxUpload": 1,
            "isDeleteAll": 0,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }
        clearTrackService.deleteDataParams(Constants.ClearTrackServiceBaseUrl + '/deleteFiles', queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ openNotification: true, notification: 'File deletion failed' });

                } else {

                    //this.setState({ openNotification: true, notification: 'File deleted successfully' });
                    this.onLoadFetchData(`/getFiles`, queryParam);
                    this.props?.fetchDealData(show_season_people_deal_id);
                    fileToDeleteCombined = [];
                }
            },
                (err) => {

                    this.setState({ isDeleteFile: false, openNotification: true, notification: 'File deletion failed' });
                    console.log("Error in fetching data", err)
                })
    }
    downloadFile = (urlToDownload) => {
        window.open(urlToDownload);
    }
    render() {

        return (
            <>
                <MDBContainer className="editDealContainer">
                    {this.state.isLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (<>
                        <NotificationComponent
                            open={this.state.openNotification}
                            message={this.state.notification}
                            severity={this.state.notification === 'File upload success!' || this.state.notification === 'File deleted successfully' ? 'success' : 'fail'}
                            handleResetNotify={() => {
                                this.setState({ openNotification: false, notification: null })
                            }} />
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicLabel text={"Type"} />
                                <SelectField value={this.state.initialRecordData?.deal_type_id || ""} options={this.state.dealTypeOptions || []} onChange={(e) => this.handleChange("deal_type_id", (e.target.value === null || e.target.value === "") ? null : e.target.value)} />
                            </MDBCol>
                            <MDBCol md={6}>
                                <BasicLabel text={"Memo Date"} />
                                <DateField value={this.state.initialRecordData?.memo_date || null}
                                    onChange={(e) => this.handleChange("memo_date", e.target.value.length > 0 ? e.target.value : null)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicLabel text={"Status"} />
                                <SelectField value={this.state.initialRecordData?.deal_status_id || ""} options={this.state.dealStatusOptions || []} onChange={(e) => this.handleChange("deal_status_id", e.target.value)} />
                            </MDBCol>
                            <MDBCol md={6}>
                                <>
                                    <BasicLabel text={"Deal Memos"} />
                                    {this.state.isFileLoading ? (
                                        <div style={{ textAlign: "center" }}>
                                            <CircularProgress size={24} />
                                        </div>
                                    ) : (<>
                                        <MDBRow>
                                            {this.state.filesArr.length > 0 && this.state.filesArr.map((item, index) => (
                                                <MDBCol md={12} key={"fileRow" + index}>
                                                    <div className="files-display">
                                                        <MDBRow style={{ margin: "0" }} >
                                                            <MDBCol md={10}>
                                                                {item?.Key ? <p className="file-text" onClick={() => this.downloadFile(item.url)}>{item.Key}  </p> : <p className="file-text" >{item}  </p>}
                                                            </MDBCol>
                                                            <>
                                                                <MDBCol md={2} className="fileIconContainer">
                                                                    {item?.Key ? <BasicButton
                                                                        icon="exchange-alt"
                                                                        text=""
                                                                        type="inline"
                                                                        inputType="file"
                                                                        multiple={true}
                                                                        id={'uploadseaonfilereplace' + index}
                                                                        uploadFile={(file) => this.replaceFile(file, item.Key, index)}
                                                                    /> : null}
                                                                    <span style={{ cursor: "pointer" }}>
                                                                        <MDBIcon
                                                                            icon={"times"}
                                                                            onClick={() => this.deleteFiles(item?.Key ? item.Key : item, index, item?.Key ? "sendToApi" : "deletelocally")}
                                                                        />
                                                                    </span>
                                                                </MDBCol>
                                                            </>
                                                        </MDBRow>
                                                    </div>
                                                </MDBCol>
                                            ))}
                                            {this.state.filesArr.length === 0 && <p> No file present</p>}
                                        </MDBRow>
                                        {canUploadEditViewDealMemo &&
                                            <MDBRow>
                                                <MDBCol md={8}>
                                                    <BasicButton
                                                        variant="text"
                                                        icon="upload"
                                                        text="Select File"
                                                        type="inline"
                                                        inputType="file"
                                                        multiple={true}
                                                        id={'uploadseaonfile'}
                                                        uploadFile={(file) => this.uploadFile(file)}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={4}><BasicLabel text={"(upto 4mb)"} /></MDBCol>
                                            </MDBRow>
                                        }
                                        {canUploadEditViewDealMemo && this.state.fileNameArr.map((item, index) => <MDBRow style={{ margin: "0" }} key={"uploadFileName" + index} >
                                            <MDBCol md={10}>
                                                <p className="filename" >{item}</p>
                                            </MDBCol>
                                            <MDBCol md={2} className="fileIconContainer">
                                                <MDBIcon
                                                    icon={"times"}
                                                    onClick={() => this.deleteFiles(item, index, null)}
                                                />
                                            </MDBCol>
                                        </MDBRow>)}
                                        {canUploadEditViewDealMemo && <p className="errorMessage">{this.state.showError && 'Please upload file having size less than 4MB'}</p>}
                                    </>)}
                                </>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicLabel text={"Union"} />
                                <SelectField value={this.state.initialRecordData?.union_id || ""} options={this.state.unionOptions || []} onChange={(e) => this.handleChange("union_id", e.target.value)} />
                            </MDBCol>
                            <MDBCol md={6}>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={6}>
                                <BasicLabel text={"Fee per episode"} />
                                <BasicTextField
                                    value={this.state.initialRecordData?.fee_per_episode}
                                    onChange={(e) => validateNumDotOnly(e.target.value)?this.handleChange('fee_per_episode', e.target.value.length > 0 ? e.target.value : null):null} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={12}>
                                <BasicLabel text={"Services Being Rendered"} />
                                <BasicTextField
                                    value={this.state.initialRecordData?.service_being_rendered}
                                    onChange={(e) => this.handleChange('service_being_rendered', e.target.value.length > 0 ? e.target.value : null)} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={12}>
                                <BasicLabel text={"Credit"} />
                                <BasicTextField
                                    value={this.state.initialRecordData?.credit}
                                    onChange={(e) => this.handleChange('credit', e.target.value.length > 0 ? e.target.value : null)} />
                            </MDBCol>
                        </MDBRow>
                        {this.props.people_type === "SHOW_COMPOSER" &&
                            <>
                                <MDBRow>
                                    <MDBCol md={4}>
                                        <BasicLabel text={"Record Royalties (artist)"} />
                                        <BasicTextField placeholder={"%"}
                                            value={this.state.initialRecordData?.record_royaltie_artist}
                                            onChange={(e) => validateNumDotOnly(e.target.value)?this.handleChange('record_royaltie_artist', e.target.value.length > 0 ? e.target.value : null):null} />
                                    </MDBCol>
                                    <MDBCol md={4}>
                                        <BasicLabel text={"Record Royalties (producer)"} />
                                        <BasicTextField placeholder={"%"}
                                            value={this.state.initialRecordData?.record_royaltie_producer}
                                            onChange={(e) => validateNumDotOnly(e.target.value)?this.handleChange('record_royaltie_producer', e.target.value.length > 0 ? e.target.value : null):null} />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={4}>
                                        <BasicLabel text={"Federal ID Number"} />
                                        <BasicTextField
                                            value={this.state.initialRecordData?.fedral_id_number}
                                            onChange={(e) => this.handleChange('fedral_id_number', e.target.value.length > 0 ? e.target.value : null)} />
                                    </MDBCol>
                                    <MDBCol md={4}>
                                        <BasicLabel text={"Performing Rights Society"} />
                                        <BasicTextField
                                            value={this.state.initialRecordData?.performing_rights_society}
                                            onChange={(e) => this.handleChange('performing_rights_society', e.target.value.length > 0 ? e.target.value : null)} />
                                    </MDBCol>

                                </MDBRow>
                            </>
                        }
                        <MDBRow>
                            <MDBCol md={12}>
                                <BasicLabel text={"Additional Notes"} />
                                <BasicTextArea
                                    value={this.state.initialRecordData?.additional_notes}
                                    onChange={(e) => this.handleChange('additional_notes', e.target.value.length > 0 ? e.target.value : null)} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={8}>
                            </MDBCol>
                            <MDBCol md={1}>
                                <BasicButton text={(this.state.isPosting || this.state.isFilePosting) ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                    onClick={this.handleSubmit.bind(this)}
                                />
                            </MDBCol>
                            <MDBCol md={1}>
                            </MDBCol>
                            <MDBCol md={1}>
                                <BasicButton variant="outlined"
                                    text={"Cancel"} onClick={() => this.props.handleClose()} />
                            </MDBCol>
                        </MDBRow>
                    </>)}
                </MDBContainer>
            </>
        );
    }
}

export default withUserContext(EditDealModal);
