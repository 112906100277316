import React from "react";
import {MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import "rc-time-picker/assets/index.css";
import Radio from "@material-ui/core/Radio";
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import TableComponent from '../../SharedComponents/Table';
import "./AcquireOption.scss";
import { initialAcquireRecord, acquireOptionConfig } from "./config";
import moment from "moment";
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal'
import MessageModal from "../../SharedComponents/MessageModal";

export default withUserContext(class AcquireOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingAcquireOptionStatus: false,
      postInitiated: false,
      isPosting: false,
      acquireDetails: { ...initialAcquireRecord },
      acquirable_songs_div_display: false,
      episodeList: [],
      acquireOption: { ...acquireOptionConfig },
      acquirableEpisodes: [],
      episodeAcquisitionList: [],
      openEmailModal:false,
      openMessageModal:false,
    };
  }
  componentDidMount() {
    this.getEpisodeList();
   // this.getEpisodeAcquisitionDetails();
   let acquireDetails = {...this.props?.selectedOptionalRight}
   acquireDetails.acquire_for_option = "1"
   acquireDetails.option_start = '1'
   acquireDetails.option_effective = '1'
   acquireDetails.option_effective_date = moment(new Date())?.format("YYYY-MM-DD")
   acquireDetails.option_acquired_date = acquireDetails?.option_acquired_date ? moment(acquireDetails?.option_acquired_date)?.format("YYYY-MM-DD"):moment(new Date())?.format("YYYY-MM-DD")
   this.setState({acquireDetails:acquireDetails})
  }
  handleChange = (field, value) => {
    this.setState((prevState) => ({
      acquireDetails: {
        ...prevState.acquireDetails,
        [field]: value,
      },
    }));
  }

  getEpisodeAcquisitionDetails = (id) => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=ACQUIRE_OPTION&searchString=${id}`,
      this.props?.userContext?.active_tenant?.tenant_id, this.props?.divisionId)
      .then((res) => {
        let acquisitionRows = this.state.episodeAcquisitionList?.filter(item => item?.show_season_episode_id !== id)
        let formattedList = res?.data?.map(item => {
          let newItem = {...item}
          if(item?.flag === 0) {
            newItem['editing'] = false 
          } else {
            newItem['editing'] = true  
          }
          return newItem
        })
        this.setState({ episodeAcquisitionList: [...acquisitionRows , ...formattedList ] });
      })
  }

  getEpisodeList = () => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      `/showSeasonEpisodes?show_season_id=${this.props?.showSeasonId}`,
      this.props?.userContext?.active_tenant?.tenant_id,this.props?.divisionId)
      .then((res) => {
        let episodeListfromAPI = res?.data?.map(item => ({ value: item.show_season_episode_id, label: item.episode_number }));
        this.setState({ episodeList: episodeListfromAPI });
      });
  }

  onRadioButtonChange = (e, field, value) => {
    let acquireDetails = this.state.acquireDetails
    if (e.target.id === "Acquire For") {
      acquireDetails.acquire_for_option = value;
      field === "5" ? this.getEntityDetails : null;
    }
    if (e.target.id === "Option Start Date") {
      acquireDetails.option_start = value;
    }
    if (e.target.id === "Option Effective") {
      acquireDetails.option_effective = value;
    }
    this.setState({ acquireDetails: acquireDetails })
    if (value === "5") {
      this.setState({ acquirable_songs_div_display: true })
    } else { this.setState({ acquirable_songs_div_display: false }) }
  }

  onEpisodeCheckBoxChange = (e) => {
    let isChecked = e.target.checked
    let selectedEpisodes = [...this.state.acquirableEpisodes]
    if (isChecked) {
      selectedEpisodes.push(e.target.id)
      this.getEpisodeAcquisitionDetails(e.target.id)
    }
    else {
      selectedEpisodes.slice(e.target.id)
    } this.setState({ acquirableEpisodes: selectedEpisodes })
  }

  selectall = () => {
    let selectedEpisodes = []
    selectedEpisodes = this.state.episodeList.map(item => item.value)
    this.setState({ acquirableEpisodes: selectedEpisodes })
    for(let i = 0; i< selectedEpisodes?.length ; i++) {
       this.getEpisodeAcquisitionDetails(selectedEpisodes[i])
    }
  }

  deselectall = () => {
    this.setState({ acquirableEpisodes: [], episodeAcquisitionList: [] })
  }

  onSave = () => {
    this.setState({postInitiated: true })
    if (this.props?.selectedOptionalRight?.option_acquired_date ? true : this.state.acquireDetails?.option_acquired_date && this.props?.selectedOptionalRight?.clearance_licensor_optional_rights_id) {
      this.setState({ postInitiated: false,isPosting: true })
      let acquireD = {...this.state.acquireDetails}
      acquireD.cl_mt_licensor_optional_rights_id = this.props?.selectedOptionalRight?.clearance_licensor_optional_rights_id;
      acquireD.song_id = this.props?.song_id;
      acquireD["opening_from_mt"] = !this.props.isMainTitle ? 0 : 1
      let endPointUrl = '/acquireOption'
      if(acquireD?.acquire_for_option == 5) {
        acquireD.acquire_list = this.state.episodeAcquisitionList?.map(item => {
          let newItem = {
            acquire: item?.is_option_acquired || 0,
            disallow_acquire: item?.is_disallow_acquire || 1,
            song_id: item?.song_id || null,
            licensor_id:  item?.licensor_id|| null,
            show_season_episode_id: item?.show_season_episode_id|| null
          }
          return newItem
        })
      }
      delete acquireD.clearance_licensor_optional_check_requests
      ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + endPointUrl, acquireD, this.props.userContext?.active_tenant?.tenant_id,this.props?.divisionId)
        .then((response) => {
          if (!response.data.error) {
            this.setState({ isPosting: false })
            this.props?.notificationComponent(true, "success")
            this.props?.toggleRefresh(this.state.acquireDetails?.option_acquired_date)
            if (!this.props?.isEditing) {
              this.props?.toggleRefreshPage('EpisodeClearance')
            }
          }
          else {
            this.setState({ isPosting: false })
            this.props?.notificationComponent(true, "fail")
          }
        },
          (err) => {
            this.setState({ isPosting: false })
            this.props?.notificationComponent(true, "fail")
            console.log("Post agency details error: " + err);
          });
    }
    else {
      this.setState({ isPosting: false })
    }
  }

  updateCheckBoxValues = (list, index, nodeName) => {
      if(nodeName === 'is_option_acquired') {
         if(list[index]?.is_disallow_acquire  === 1) {
          list[index].is_option_acquired  = 0
          this.setState({openMessageModal : true, episodeAcquisitionList : list})
         } else {
          list[index].is_option_acquired  = list[index]?.is_option_acquired ? 1 : 0
          this.setState({episodeAcquisitionList : list})
         }
      } else if(nodeName === 'is_disallow_acquire' ) {
        list[index].is_option_acquired  = 0
        list[index].is_disallow_acquire  =  list[index]?.is_disallow_acquire  ? 1 : 0 
        this.setState({episodeAcquisitionList : list})
      }
  }

  render() {
    return (
      <>
        <div className="ManageDealContainer">
        <MessageModal
          open={this.state.openMessageModal || false}
          title={"Acquisition Warning"}
          message={"Acquisition can't happend as Disallow acq. is checked"}
          hideCancel={true}
          primaryButtonText={"OK"}
          onConfirm={() => this.setState({openMessageModal:false })}/>
          <MDBRow>
            <MDBCol md={2} className="datefieldstyles">
              <DateField
                id="Acquisition_date"
                label={"Acquisition Date"}
                isMandatory={true}
                showMandatory={this.props?.selectedOptionalRight?.option_acquired_date ? false : this.state.postInitiated}
                //value={this.state.acquireDetails?.option_acquired_date?moment(this.state.acquireDetails?.option_acquired_date).format("YYYY-MM-DD"):null || null}
                value= {this.state.acquireDetails?.option_acquired_date || null}
                onChange={(e) => {
                  this.handleChange('option_acquired_date', e.target.value.length > 0 ? e.target.value :null)
                }}
              />
            </MDBCol>
            {this.props?.selectedOptionalRight?.option_acquired_date && <MDBCol md={8}>
                            <BasicLabel text="(Blank out the date to un-acquire option)" />
            </MDBCol>}
          </MDBRow>
          <MDBRow></MDBRow>
          <MDBRow className="mt-2">
            <MDBCol md={3} >
              <BasicCheckbox
                className="checkbox-font"
                id="Acquire_Main_Title"
                label="Acquire For Main Title also"
                checked={this.state.acquireDetails?.acq_for_main_title === 1}
                onChange={(e) => this.handleChange("acq_for_main_title", e.target.checked ? 1 : 0)}
              />
            </MDBCol>
            {/* <MDBCol md={3} >
              <BasicCheckbox
                className="checkbox-font"
                id="Inc_Int_Rights"
                label="Include in Initial Rights"
                checked={this.state.acquireDetails?.inc_init === 1}
                onChange={(e) => this.handleChange("inc_init", e.target.checked ? 1 : 0)}
              />
            </MDBCol> */}
          </MDBRow>
          <div>
            <MDBRow>
              <MDBCol md={5}>
                <p className="para-text">Acquire For</p>
                <Radio
                  name="radio-buttons"
                  id="Acquire For"
                  checked={this.state.acquireDetails.acquire_for_option === "1"}
                  onChange={(e) => this.onRadioButtonChange(e, "1", e.target.value)}
                  value={"1"} />
                {/* <span className="textStyleforradio">For {this.props.clearneceLicenserInfo.map((item) => item.licensors_list[0].licensor_name)}; this song ({this.props.licensorsInfo.map((licensor)=>licensor.songs_list[0]?.song_title)}) only</span> */}
                <span className="textStyleforradio">For {this.props.lic.licensor_name}; this song ({this.props.licensorsInfo.map((licensor)=>licensor.songs_list[0]?.song_title)}) only</span>
                <br />
                <Radio
                  name="radio-buttons"
                  id="Acquire For"
                  checked={this.state.acquireDetails.acquire_for_option === "2"}
                  onChange={(e) => this.onRadioButtonChange(e, "2", e.target.value)}
                  value={"2"} />
                <span className="textStyleforradio">For all licensors; this song ({this.props.licensorsInfo.map((licensor)=>licensor.songs_list[0]?.song_title)}) in {!this.props.isMainTitle ? this.props?.episodeNumber : "Main Title" } only{" "}</span>
                <br />
                <Radio
                  name="radio-buttons"
                  id="Acquire For"
                  label="For all songs in episode [Episode #]"
                  checked={this.state.acquireDetails?.acquire_for_option === "3"}
                  onChange={(e) => this.onRadioButtonChange(e, "3", e.target.value)}
                  value={"3"} />
                <span className="textStyleforradio">For all songs in episode {!this.props.isMainTitle ? this.props?.episodeNumber : "Main Title" }</span>
                <br />
                <Radio
                  name="radio-buttons"
                  id="Acquire For" label=" For all songs used in [Show Name] during the [Season] season."
                  checked={this.state.acquireDetails?.acquire_for_option === "4"}
                  onChange={(e) => this.onRadioButtonChange(e, "4", e.target.value)}
                  value="4" />
                <span className="textStyleforradio">For all songs used in {this.props.licensorsInfo.map((licensor) => licensor.show_name)} during the {this.props?.seasonName} season.</span>
                <br />
                <Radio
                  name="radio-buttons"
                  id="Acquire For"
                  label=" Acquire by Song/Licensor"
                  checked={this.state.acquireDetails?.acquire_for_option === "5"}
                  onChange={(e) => this.onRadioButtonChange(e, "5", e.target.value)}
                  value={"5"} />
                <span className="textStyleforradio"> Acquire by Song/Licensor</span>
                <br />
              </MDBCol>
              <MDBCol md={5}>
                <p className="para-text">Option Start Date</p>
                <Radio
                  name="radio-buttons"
                  id="Option Start Date"
                  checked={this.state.acquireDetails?.option_start === "1"}
                  onChange={(e) => this.onRadioButtonChange(e, "1", e.target.value)}
                  value={"1"} />
                <span className="textStyleforradio">First Episode Air Date</span>
                <br />
                <Radio
                  name="radio-buttons"
                  id="Option Start Date"
                  checked={this.state.acquireDetails?.option_start === "2"}
                  onChange={(e) => this.onRadioButtonChange(e, "2", e.target.value)}
                  value={"2"} />
                <span className="textStyleforradio">This Episode Air Date</span>
                <br />
                <Radio
                  name="radio-buttons"
                  id="Option Start Date"
                  checked={this.state.acquireDetails?.option_start === "3"}
                  onChange={(e) => this.onRadioButtonChange(e, "3", e.target.value)}
                  value={"3"} />
                <span className="textStyleforradio">Option Acq Date</span>
                <br />
              </MDBCol>
              <MDBCol md={2} id="option-effective" className="datefieldstyles">
                <p className="para-text">Option Effective Date</p>
                <Radio
                  name="radio-buttons"
                  id="Option Effective"
                  checked={this.state.acquireDetails?.option_effective === "1"}
                  onChange={(e) => this.onRadioButtonChange(e, "1", e.target.value)}
                  value={"1"}
                  className="radio-btn" />
                <span className="textStyleforradio">Option Acquired Date</span>
                <MDBRow>
                  <MDBCol>
                    <DateField
                      className="date-width"
                      id="option_effective_date"
                      //showMandatory={this.state.postInitiated}
                      //isMandatory={true}
                      value={this.state.acquireDetails?.option_effective_date || ""}
                      onChange={(e) => { this.handleChange('option_effective_date', e.target.value?.length > 0 ? e.target.value : null) }}
                    />
                  </MDBCol>
                </MDBRow>

              </MDBCol>
            </MDBRow>
          </div>
          {this.state.acquirable_songs_div_display === true ?

            <div>
             <p className="para-text">Acquirable Songs</p> 
              <MDBRow>
                <MDBCol md="2" className="mt-1">
                  <MDBRow>
                    <MDBCol className="text-color" onClick={this.selectall}>Select all</MDBCol>
                    <MDBCol className="text-color" onClick={this.deselectall}>Deselect all</MDBCol>
                  </MDBRow>
                  <div className="checkboxstyles">
                    {this.state.episodeList.map((item) => (
                      <BasicCheckbox id={item.value} label={item.label} value={item.value}
                        checked={this.state.acquirableEpisodes === [] ? false : this.state.acquirableEpisodes.find(ep => ep === item.value)}
                        onChange={(e) => this.onEpisodeCheckBoxChange(e)} />))}
                  </div>
                </MDBCol>
                <MDBCol md="10">
                  <div id="acquireoptiontablecolor">
                    <TableComponent
                      list={this.state.episodeAcquisitionList || []}
                      config={this.state.acquireOption}
                      updateOptCheckRequest={this.updateCheckBoxValues.bind(this)}
                      stickyHeader={true}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
            : null}
          <MDBRow>
            <MDBCol md={9}></MDBCol>
            <MDBCol md={3}>
              <MDBRow>
                <MDBCol>
                  <BasicButton 
                  onClick={() => this.setState({postInitiated:true}, () => {
                    if(this.props?.selectedOptionalRight?.option_acquired_date ? true : this.state.acquireDetails?.option_acquired_date) {
                      if((this.props?.selectedOptionalRight?.option_acquired_date && !this.state.acquireDetails?.option_acquired_date) || this.state.acquireDetails?.acquire_for_option === 5) {
                        this.onSave()
                      } else {
                          this.setState({postInitiated:false, openEmailModal:true})
                      }
                    }
                })}
                  //onClick={() => this.onSave()}
                  disabled={this.props?.optionAcquisitionModalPermissions?.edit == 1 ? false : true}
                  text={this.state.isPosting?<CircularProgress color="inherit" size={18} />:"Save"} />
                </MDBCol>
                <MDBCol>
                  <BasicButton onClick={() => this.props?.handleClose()} variant="outlined" text={"Cancel"} />
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </div>
        {
          this.state.openEmailModal &&
          <MainViewModalComponent
            open={this.state.openEmailModal}
            handleClose={() => this.setState({ openEmailModal: false})}
            headerText={"Send Option Acquisition Email"}
            handleSubmit={(emailInfo) => {
              let acquireInfo = {...this.state.acquireDetails}
              acquireInfo.subject = emailInfo?.sub
              acquireInfo.to_address = emailInfo?.toMails
              acquireInfo.cc_address = emailInfo?.ccMails
              acquireInfo.message = emailInfo?.body
              acquireInfo.has_attachment = false
              acquireInfo.is_draft = false
              acquireInfo.attachments = null
              this.setState({acquireDetails:acquireInfo,openEmailModal:false}, this.onSave)
            }}
            mode={""}
            subject={'Option Pickup Notification'}
            body={`The ${this.state.acquireDetails?.right_abbr} option has been acquired for the season ${this.props?.seasonName} of ${this.props?.showName}`}
            modalName={"Acquire Option Email"}
            modalClass={'acquireOptionEmailModal'}
            isMainTitle={this.props?.isMainTitle}
          />
        }
      </>
    );
  }
}
);