import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { withUserContext } from '../../../../contexts/UserContext';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableComponent from '../../../SharedComponents/Table';
import { cueSheetComposerCueConfig, initialShowSeasonComposerConfig } from './Config';
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import NotificationComponent from '../../../../Common/NotificationComponent/NotificationComponent';
import SelectField from '../../../SharedComponents/SelectField/SelectField';
import * as Constants from '../../../../constants/constants';
import ClearTrackService from '../../../../services/service';
import "./ShowSeasonComposer.scss";

export default withUserContext(class ShowSeasonComposer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSeasonComposerList: [],
            config: JSON.parse(JSON.stringify({ ...cueSheetComposerCueConfig })),
            isErrorFlag: false,
            composerDetails: JSON.parse(JSON.stringify({ ...initialShowSeasonComposerConfig })),
            seasonsOptions: [],
            isFetchingComposer: false,
            isFethchingRestSeasons: false,
            openNotification: null,
        }
    };

    componentDidMount() {
        this.getShowSeasonComposer();
        this.getShowComposers(`/entitySearch?entity=${"COMPOSERS_SHOW_SEASONS"}&searchString=${this.props?.showId}`);
    }


    formatShowSeasonComposerCue = (dataItem, mode = null, copyId = null) => {
        this.setState({ mode: mode })
        let composerCueDetails = { ...this.state.composerDetails }
        composerCueDetails.show_season_id = this.props?.showSeasonId || null;
        composerCueDetails.show_id = this.props?.showId || null;
        composerCueDetails.season_id = this.props.seasonId || null;
        composerCueDetails.old_show_season_id = copyId || null;
        composerCueDetails.composer = dataItem?.composer || null;
        composerCueDetails.publisher = dataItem?.publisher || null;
        composerCueDetails.is_default = dataItem?.is_default ? 1 : 0 || null;
        composerCueDetails.show_season_composer_id = dataItem?.show_season_composer_id || null;
        if (mode === "delete") {
            composerCueDetails.is_delete = 1;
        }
        return composerCueDetails;
    }



    getShowSeasonComposer = (loader = true) => {
        this.setState({ isFetchingComposer: loader })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${"SHOW_SEASON_COMPOSERS"}&searchString=${this.props?.showSeasonId}`, this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                this.setState({ showSeasonComposerList: response?.data, isFetchingComposer: false, isSubmit: false })
            },
                (err) => {
                    this.setState({ isFetchingComposer: false, showSeasonComposerList: [], isSubmit: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }

    getShowComposers = (url) => {
        this.setState({ isFethchingRestSeasons: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({isFethchingRestSeasons: false})
                if (response.data.length > 0) {
                    let seasons = response.data.filter((item) => item.season_id !== this.props?.seasonId)
                    let formattedList = seasons.map(item => ({
                        label: item.season_name,
                        value: item.show_season_id,
                    }))
                    this.setState({
                        seasonsOptions: formattedList,
                        selectedSeason:formattedList[0]?.value
                    })
                }

            }, (err) => {
                this.setState({ isFethchingRestSeasons: false })
                console.log("Error in fetching Address Types:", err)
            })
    }


    postShowSeasonComposer = (payload, postFlag) => {
        this.setState({ isSubmit: postFlag })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonComposers`, payload, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                if (response) {
                    if (response.data.error) {
                        this.setState({ isSubmit: false, openNotification: 'error' })
                    } else {
                        this.getShowSeasonComposer(false);
                        this.props?.toggleRefresh();
                        this.setState({ openNotification: 'success' })
                    }
                }
            }),
            (err) => {
                this.setState({ isSubmit: false, openNotification: 'error' })
            }
    }

    copyDataFromAnotherSeasons = () => {
        let newDataItem = {
            "composer": null,
            "publisher": null
        }
        let payload = { ...this.formatShowSeasonComposerCue(newDataItem, null, this.state.selectedSeason) };
        this.postShowSeasonComposer(payload, 'newItemPost')
    }


    handleAddNewItem = (newDataItem) => {
        this.setState({ isErrorFlag: false })
        if (newDataItem?.composer && newDataItem?.publisher) {
            let payload = { ...this.formatShowSeasonComposerCue(newDataItem) }
            this.postShowSeasonComposer(payload, 'newItemPost');
        } else {
            this.setState({ isErrorFlag: true })
        }
    }

    handleInlineEdits = (id, dataItem) => {
        let cueSheetDetails = [...this.state.showSeasonComposerList]
        this.setState({
            showSeasonComposerList: cueSheetDetails.map((item) => {
                let newItem = {...item}
                if (item.show_season_composer_id == id) {
                    newItem ['editing'] = true
                } else {
                    newItem .editing = false
                }
                return newItem 
            })
        })
    }

    saveInlineEdits = (list, index, id, dataItem) => {
        this.setState({ isErrorFlaf: false })
        let composerDetails = [...list];
        if (composerDetails[index]?.composer && composerDetails[index]?.publisher) {
            let payload = { ...this.formatShowSeasonComposerCue(composerDetails[index]) };
            this.postShowSeasonComposer(payload, composerDetails[index].show_season_composer_id);
        } else {
            this.setState({ isErrorFlag: true })
        }

    }

    handleInlineDelete = (id) => {
        let composerDetails = [...this.state.showSeasonComposerList];
        let deletedItem = composerDetails?.find(item => item?.show_season_composer_id === id);
        let payload = { ...this.formatShowSeasonComposerCue(deletedItem, "delete") };
        this.postShowSeasonComposer(payload, payload.show_season_composer_id);
    }


    handleReadCallback = (index) => {
        let cueSheetDetails = [...this.state.showSeasonComposerList];
        cueSheetDetails[index].editing = false;
        this.setState({ showSeasonComposerList: cueSheetDetails, isErrorFlag: false })
    }

    handleInlineIconClick = (nodeName, index) => {
        this.setState({
            mode: !index ? "Add" : "Edit",
            nodeName: nodeName,
            cueSheetItemIndex: index
        })
        if (nodeName === "composer") {
            this.setState({ showComposerModal: true })
        } else {
            this.setState({ showPublisherModal: true })
        }
    }



    render() {
        return (
            <>{(this.state.isFetchingComposer || this.state.isFethchingRestSeasons) ? (
                <div className="ContentLoader">
                    <CircularProgress />
                </div>
            ) : (<div>
                <NotificationComponent
                    open={this.state.openNotification?.length > 0 ? true : false}
                    message={this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                    severity={this.state.openNotification || ''}
                    handleResetNotify={() => this.setState({ openNotification: null })}
                />
                <MDBContainer>
                    {this.state.showSeasonComposerList.length === 0 && this.state.seasonsOptions.length > 0 &&
                        <MDBRow className='mt-2'>
                            <MDBCol md={5}>
                                <BasicButton
                                    text={"Copy from Another Season"}
                                    onClick={this.copyDataFromAnotherSeasons}
                                    disabled={!this.state.selectedSeason}
                                />
                            </MDBCol>
                            <MDBCol md={3}>
                                <SelectField
                                    id={"seasons"}
                                    placeHolderText={'-select season-'}
                                    options={this.state.seasonsOptions || []}
                                    value={this.state.selectedSeason}
                                    onChange={(e) => this.setState({ selectedSeason: e.target.value })}
                                />
                            </MDBCol>
                        </MDBRow>
                    }
                </MDBContainer>
                <div className="cueSheet row composer_table" id='mainGrid'>
                    {this.state.isErrorFlag ? <p className="f-color">Please Enter All the Fields</p> : this.state.isFetchingCueSheetDetails = false}
                    <TableComponent
                        list={this.state.showSeasonComposerList || []}
                        className={"clearencRecord"}
                        stickyHeader={true}
                        config={this.state.config}
                        handleAddNewItem={this.handleAddNewItem.bind(this)}
                        editCallback={this.handleInlineEdits.bind(this)}
                        saveCallback={this.saveInlineEdits.bind(this)}
                        handleDelCallBack={this.handleInlineDelete.bind(this)}
                        readCallback={this.handleReadCallback.bind(this)}
                        inlineIconClick={this.handleInlineIconClick.bind(this)}
                        addItemToList={true}
                        postInitiated={this.state.isSubmit}
                        updatedItemNodeValue={this.state.nodeName === "publisher" ?
                            this.state.publisherName : this.state.composerName
                        } />
                    {this.state.showComposerModal &&
                        <MainViewModalComponent
                            open={this.state.showComposerModal}
                            handleClose={() => this.setState({ showComposerModal: false })}
                            headerText={"Composer"}
                            mode={this.state.mode}
                            modalName={"Add Composer"}
                            handleNodeValue={(value) => this.setState({
                                composerName: {
                                    value: value,
                                    node: this.state.nodeName,
                                    index: this.state.cueSheetItemIndex
                                }
                            })}
                        />
                    }
                    {this.state.showPublisherModal &&
                        <MainViewModalComponent
                            open={this.state.showPublisherModal}
                            handleClose={() => this.setState({ showPublisherModal: false })}
                            headerText={"Publisher"}
                            mode={this.state.mode}
                            modalName={"Add Publisher"}
                            handleNodeValue={(value) => this.setState({
                                publisherName: {
                                    value: value,
                                    node: this.state.nodeName,
                                    index: this.state.cueSheetItemIndex
                                }
                            })}
                        />
                    }
                </div>
            </div>)}
            </>
        );
    }
});