import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import BasicTextField from "../../../SharedComponents/BasicTextField/BasicTextField";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import { ProducerContacts, contactItem } from "./config";
import BasicCheckbox from "../../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../SharedComponents/BasicLabel/BasicLabel";
import { validateEmail } from '../../../../Common/Helper'
import "./ProducerContactsModal.scss";
import ClearTrackService from "../../../../services/service";
import * as Constants from "../../../../constants/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withUserContext } from '../../../../contexts/UserContext';

class ProducerContactsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            postInitiated: false,
            producerContacts: JSON.parse(JSON.stringify(ProducerContacts)),
            checkedMultiPrimary: {
                show_season_producers: false,
            },
            isPosting: false
        };
    }
    handleContactList = (arrayList, listItem, value, index) => {
        let contactArray = [...this.state.producerContacts.show_season_producers]
        contactArray[index][listItem] = value
        this.handleOnChange(arrayList, contactArray)
    }

    handleOnChange = (field, value) => {
        this.setState(prevState => ({
            producerContacts: {
                ...prevState.producerContacts,
                [field]: value
            },
            postInitiated: false
        }))
        if (field === "show_season_producers") {
            this.setState(prevState => ({
                checkedMultiPrimary: {
                    ...prevState.checkedMultiPrimary,
                    [field]: this.checkMultiplePrimary(field, value)
                }
            }))
        }
    }

    checkMultiplePrimary = (listName, list) => {
        let multiplePrimary = list?.filter(item => item.is_primary === 1);
        return multiplePrimary.length > 1
    }

    handleAdd = () => {
        let contactArray = [...this.state.producerContacts.show_season_producers]
        contactArray.push(JSON.parse(JSON.stringify(contactItem)))
        this.handleOnChange('show_season_producers', contactArray)
    }

    handleRemove = (deleteIndex) => {
        let contactArray = [...this.state.producerContacts.show_season_producers]
        contactArray.splice(deleteIndex, 1)
        this.handleOnChange('show_season_producers', contactArray)
    }

    componentDidMount() {
        this.getDetails();
    }

    getDetails = () => {
        this.setState({ isLoading: true });
        let producerContacts = {}
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonProducers?show_season_id=${this.props?.show_season_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id).then(res => {
            this.setState({ isLoading: false });
            console.log("res.data", res.data);
            producerContacts = res.data[0];
            if (res.data[0]?.show_season_producers?.length === 0) {
                producerContacts.show_season_producers.push({ "producer_name": null, producer_email: null, is_primary: 0,season_id:this.props.season_id,show_id:this.props.show_id });
            }
            this.setState({ producerContacts: producerContacts, isLoading: false, mode: "edit" })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }

    checkIfPrimaryIsChecked = (postJson) => {
        if (postJson.show_season_producers.length !== 0 && !postJson.show_season_producers.some(item => item.is_primary == 1)) {
            postJson.show_season_producers[0].is_primary = 1;
        }
        return postJson;
    }

    setRepresentativeDetailsUniqueEntries = () => {
        let producerContacts = { ...this.state.producerContacts };
        producerContacts.show_season_producers = [...producerContacts.show_season_producers].filter((tag, index, array) =>
            (array.findIndex(t => t.producer_name == tag.producer_name && t.producer_email == tag.producer_email) == index) &&
            tag.producer_email && tag.producer_name);
        return producerContacts;
    }

    validateErrorFlags = (postJson) => {
        let canSubmit = (
            (postJson.publicist_name &&
                postJson.publicist_email &&
                postJson.show_season_producers.every(item =>
                    !(item.producer_name) ? true :
                        (item.producer_name.length <= 50) && validateEmail(item.producer_email) && (!this.state.checkedMultiPrimary.show_season_producers)
                ))) ? true : false;
        return canSubmit;
    }

    postDetails = (postJson) => {
        this.setState({ isPosting: true });
        let StringValueKeysArray = Object.keys(postJson).filter(item => typeof item === "string");
        StringValueKeysArray.map(item => postJson[item] === "" ? postJson[item] = null : null);
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + '/showSeasonProducers', postJson, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isPosting: false })
                    this.props.notificationComponent(true, "fail");
                } else {
                    console.log("postJson", response)
                    this.setState({ isPosting: false })
                    this.props.fetchData("seasonProducersDetails", `/showSeasonProducers?show_season_id=${this.props.show_season_id}`, "calledOnLoad");
                    this.props.notificationComponent(true, "success");
                }
            },
                (err) => {
                    this.setState({ isPosting: false })
                    this.props.notificationComponent(true, "fail");
                    console.log("Post error: " + err);
                })
    }

    handleSubmit = () => {
        let postJson = this.checkIfPrimaryIsChecked(this.setRepresentativeDetailsUniqueEntries());
        console.log("postJson", postJson);
        this.setState({ postInitiated: true }, () => {
            if (this.validateErrorFlags(postJson)) {
                this.postDetails(postJson);
            } else {
                console.log("Post JSON", postJson)
            }
        })
    }

    render() {
        return (
            <>
                <MDBContainer className="Contacts-Container">
                    {this.state.isLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (<>
                        <p className="title">Producers</p>
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicLabel text={"Name"} />
                            </MDBCol>
                            <MDBCol md={5} className="marginLeft">
                                <BasicLabel text={"Email"} />
                            </MDBCol>
                            <MDBCol md={1} align="start">
                                <BasicLabel text={"Prim."} />
                            </MDBCol>
                        </MDBRow>
                        {this.state.producerContacts?.show_season_producers?.map((item, index) => (
                            <MDBRow>
                                <MDBCol md={5} className="mt-1">
                                    <BasicTextField
                                        id={"contact_name"}
                                        className=" "
                                        width={"100%"}
                                        value={item?.producer_name || null}
                                        onChange={(e) =>
                                            this.handleContactList(
                                                "show_season_producers",
                                                "producer_name",
                                                e.target.value.length > 0 ? e.target.value : null,
                                                index
                                            )
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={5} className="mt-1 marginLeft">
                                    <BasicTextField
                                        id={"contact_email"}
                                        className=" "
                                        width={"100%"}
                                        value={item.producer_email}
                                        inValidInput={"Invalid Email"}
                                        fieldValid={!validateEmail(item.producer_email) && item.producer_email}
                                        onChange={(e) =>
                                            this.handleContactList(
                                                "show_season_producers",
                                                "producer_email",
                                                e.target.value.length > 0 ? e.target.value : null,
                                                index
                                            )
                                        }
                                    />
                                </MDBCol>
                                <MDBCol md={1} align="end">
                                    <BasicCheckbox
                                        id={`produceCheckBox${index}`}
                                        checked={item.is_primary === 1}
                                        onChange={(e) =>
                                            this.handleContactList(
                                                "show_season_producers",
                                                "is_primary",
                                                e.target.checked ? 1 : 0,
                                                index
                                            )} />
                                </MDBCol>
                                <MDBCol md={1} className='flex justify-content-between'>
                                    {this.state.producerContacts.show_season_producers.length > 1 ?
                                        <RemoveCircleIcon fontSize="small" className="actions"
                                            onClick={() => this.handleRemove(index)}
                                        />
                                        : null}
                                    {this.state.producerContacts.show_season_producers.length - 1 === index ?
                                        <AddCircleIcon fontSize="small" className="actions"
                                            onClick={this.handleAdd}
                                        />
                                        : null
                                    }
                                </MDBCol>
                            </MDBRow>
                        ))}
                        <MDBRow>
                            {
                                this.state.checkedMultiPrimary.show_season_producers ? (
                                    <MDBRow>
                                        <div className="error">{"Only one producer can be a primary. Multiple selection is not allowed"}</div>
                                    </MDBRow>
                                ) : null
                            }
                        </MDBRow>
                        <p className="title">Publicist</p>
                        <MDBRow className="mt-2">
                            <MDBCol md={5}>
                                <BasicLabel text={"Name"} />
                            </MDBCol>
                            <MDBCol md={5} className="marginLeft">
                                <BasicLabel text={"Email"} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicTextField
                                    id={"publicist_name"}
                                    width={"100%"}
                                    value={this.state.producerContacts.publicist_name}
                                    onChange={(e) => this.handleOnChange("publicist_name", e.target.value.length > 0 ? e.target.value : null)}
                                />
                            </MDBCol>
                            <MDBCol md={5} className="marginLeft">
                                <BasicTextField
                                    id={"publicist_email"}
                                    inValidInput={"Invalid Email"}
                                    fieldValid={!validateEmail(this.state.producerContacts.publicist_email) && this.state.producerContacts.publicist_email}
                                    width={"100%"}
                                    value={this.state.producerContacts.publicist_email}
                                    onChange={(e) => this.handleOnChange("publicist_email", e.target.value.length > 0 ? e.target.value : null)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <p className="title">Marketing Contact</p>
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicLabel text={"Name"} />
                            </MDBCol>
                            <MDBCol md={5} className="marginLeft">
                                <BasicLabel text={"Email"} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={5}>
                                <BasicTextField
                                    id={"marketing_contact_name"}
                                    width={"100%"}
                                    value={this.state.producerContacts.marketing_contact_name}
                                    onChange={(e) => this.handleOnChange("marketing_contact_name", e.target.value.length > 0 ? e.target.value : null)}
                                />
                            </MDBCol>
                            <MDBCol md={5} className="marginLeft">
                                <BasicTextField
                                    id={"marketing_contact_email"}
                                    inValidInput={"Invalid Email"}
                                    fieldValid={!validateEmail(this.state.producerContacts.publicist_email) && this.state.producerContacts.publicist_email}
                                    width={"100%"}
                                    value={this.state.producerContacts.marketing_contact_email}
                                    onChange={(e) => this.handleOnChange("marketing_contact_email", e.target.value.length > 0 ? e.target.value : null)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md={8}></MDBCol>
                            <MDBCol md={4} className="saveCancelWrapper">
                                <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"} onClick={this.handleSubmit} />
                                <BasicButton
                                    onClick={() => this.props.handleClose()}
                                    variant="outlined"
                                    text={"Cancel"}
                                />
                            </MDBCol>
                        </MDBRow>
                    </>)}
                </MDBContainer>
            </>
        );
    }
}

export default withUserContext(ProducerContactsModal);
