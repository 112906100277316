import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TableComponent from "../SharedComponents/Table";
import ClearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../contexts/UserContext';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import { validateNumbersOnly, validateNumDotOnly } from "../../Common/Helper";

class ClearanceOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      isSubmit: false,
      clearanceConfig: JSON.parse(JSON.stringify({ ...this.props?.config})),
      clearanceSelectDetails: [],
      isLoading: false,
      optionFieldNotExist: false,
      optionFieldDuplicate:false,
      sortBy: null,
      isSortByAsc: true,
      sortCount: 0
    };
  }
  componentDidMount() {
    this.getHDBoptions();
    this.getClearanceOptions("CLEARANCE_OPTIONS", "");
  }
  getClearanceOptions = (clearanceOptions, searchString, loader = true) => {
    this.setState({ isLoading: loader })
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${clearanceOptions}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let clearanceDeta = [...res.data];
        clearanceDeta?.map((data) => {
          return (data.H_D_B = this.state.clearanceSelectDetails?.find(
            (item) => item?.label === data?.H_D_B
          )?.value);
        });
        // clearanceDeta?.map((data) => {
        //   return data.deff_fee= '$'+parseFloat(data.deff_fee).toFixed(2)
        // });
        let configRecord = {...this.state.clearanceConfig}
        this.props?.permissionArray?.edit === 1 ? configRecord.actions = ["pen"] : configRecord.actions = [ ]
        this.setState({ renderList: clearanceDeta, isLoading: false, isSubmit: false,sortBy: null,
          isSortByAsc: true, clearanceConfig: configRecord,
          sortCount: 0 }, () => this.handleTableColumnClick("Option"));
      })
      .catch((err) => {
        console.log("err", err)
        this.setState({ isLoading: false })
      });
  };
  getHDBoptions = () => {
    let clearanceConfigValue = { ...this.state.clearanceConfig };
    let clearanceSelect = [
      { label: "H", value: 1 },
      { label: "D", value: 2 },
      { label: "B", value: 3 },
    ];
    clearanceConfigValue.headings[2].selectOptions = clearanceSelect;

    this.setState({
      clearanceConfig: clearanceConfigValue,
      clearanceSelectDetails: clearanceSelect,
    });
  };
  handleInlineEdits = (id, dataItem) => {
    let clearanceOptions = [...this.state.renderList];
    this.setState({
      optionFieldNotExist: false,
      renderList: clearanceOptions.map((item) => {
        let newItem = {...item}
        if (item.clearance_option_id == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ optionFieldNotExist: false,optionFieldDuplicate:false })
    if (!data[index]?.option || !data[index]?.H_D_B || !data[index]?.deff_fee || !validateNumDotOnly(data[index]?.deff_fee)) {
      this.setState({ optionFieldNotExist: true })
    }
    else if(this.state?.renderList?.some((item,i)=>item?.option === data[index]?.option && index != i)){
      this.setState({optionFieldDuplicate:true})
    }
    else{    
    if (data[index]?.option && data[index]?.option?.length > 0 && data[index]?.H_D_B && data[index]?.deff_fee && validateNumDotOnly(data[index]?.deff_fee) && data[index]?.deff_fee?.toString().length > 0) {
      let clearanceOptions = [...data];
      let clearanceOptionDetails = [...this.state.renderList];
      let hdbValue = this.state.clearanceSelectDetails?.find(
        (item) => item?.value === clearanceOptions[index]?.H_D_B
      )?.label;
      clearanceOptionDetails[index].is_active = clearanceOptions[index]?.is_active
        ? 1
        : 0;
      clearanceOptionDetails[index].option = clearanceOptions[index]?.option;
      clearanceOptionDetails[index].H_D_B =
        typeof clearanceOptions[index]?.H_D_B === "string"
          ? clearanceOptions[index]?.H_D_B
          : hdbValue;
      clearanceOptionDetails[index].deff_fee = clearanceOptions[index]?.deff_fee || null;
      clearanceOptionDetails[index].notes = clearanceOptions[index]?.notes || null;
      this.setState({ renderList: clearanceOptionDetails });
      this.postClearanceOptions(clearanceOptionDetails[index], clearanceOptionDetails[index]?.clearance_option_id);
    } else {
      this.setState({ optionFieldNotExist: true })
    }
  }
  };
  postClearanceOptions = (clearanceOptionDetails, postFlag) => {
    this.setState({ isSubmit: postFlag });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/clearanceOption`,
      clearanceOptionDetails,
      this.props?.userContext?.active_tenant?.tenant_id,
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false });
          this.props?.toastMessageNotification(true, "fail");
          this.getClearanceOptions("CLEARANCE_OPTIONS", "");

        } else {
          if (clearanceOptionDetails?.editing) {
            clearanceOptionDetails.editing = false;
          }
          this.props?.toastMessageNotification(true, "success");
          this.getClearanceOptions("CLEARANCE_OPTIONS", "", false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props?.toastMessageNotification(true, "fail");
        console.log("Post budget details error: " + err);
      }
    );
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ optionFieldNotExist: false,optionFieldDuplicate:false })
    if (!newDataItem?.option || !newDataItem?.H_D_B || !newDataItem?.deff_fee || !validateNumDotOnly(newDataItem?.deff_fee)) {
      this.setState({ optionFieldNotExist: true })
    }
    else if(this.state?.renderList?.some(item=>item?.option === newDataItem?.option)){
      this.setState({optionFieldDuplicate:true})
    }
    else{

      if (newDataItem?.option && newDataItem?.option?.length > 0 && newDataItem?.H_D_B && newDataItem?.deff_fee && validateNumDotOnly(newDataItem?.deff_fee) && newDataItem?.deff_fee?.length > 0) {
        let hdbValue = this.state.clearanceSelectDetails?.find(
          (item) => item?.value === newDataItem?.H_D_B
          )?.label;
          
          let newClearanceData = {
            is_active: newDataItem?.is_active ? 1 : 0,
            option: newDataItem?.option || null,
            H_D_B: hdbValue ? hdbValue : null,
        deff_fee: newDataItem?.deff_fee || null,
        notes: newDataItem?.notes || null,
      };
      // let clearanceOptionDetails = [...this.state.renderList];
      // clearanceOptionDetails.push(newClearanceData);
      this.postClearanceOptions(newClearanceData, 'newItemPost');
      //this.setState({ renderList: clearanceOptionDetails });
    } else {
      this.setState({ optionFieldNotExist: true })
    }
  };
}

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.clearanceConfig, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: tableSortList(colName === "Def. Fee" ? "Number":"Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Option": return "option";
      case "H/D/B": return "H_D_B";
      case "Def. Fee": return "deff_fee";
      case "Notes": return "notes";
      case "Active": return "is_active";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="ClearanceOptionsContainer" >
        <MDBRow>
          <span className="description">
            Ad Card Options for Clearance Records
          </span>
        </MDBRow>
        <MDBRow className="pd">
        <span className="errorText">{this.state.optionFieldNotExist && 'Please enter Option, H/D/B and Def Fee fields value'}</span>
        <span className="errorText">{this.state?.optionFieldDuplicate && 'Option value already exist'}</span>
        </MDBRow>
        {this.state.isLoading ? (<div className="ContentLoader">
          <CircularProgress />
        </div>) : (<MDBRow>
          <div className="ClearanceOptionsTable">
            <TableComponent
              list={this.state.renderList || []}
              config={this.state.clearanceConfig}
              handleAddNewItem={this.handleAddNewItem.bind(this)}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              addItemToList={permissionValue?.edit === 1 ? true : false}
              postInitiated={this.state.isSubmit}
              newIsActive={true}
              readCallback={(index) => {
                let clearanceOptions = [...this.state.renderList];
                clearanceOptions[index].editing = false
                this.setState({ renderList: clearanceOptions, optionFieldNotExist: false });
              }}
              stickyHeader={true}
              sortCallback={this.handleTableColumnClick}
            /></div>
        </MDBRow>
        )}
      </MDBContainer>
    );
  }
}

export default withUserContext(ClearanceOptions);
