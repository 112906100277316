import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from 'mdbreact';
import '../../ShowSeason/Modals/EditShowDetails.scss';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import DateField from "../../SharedComponents/DateField/DateField"
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import { pilotMaintananceJSON, mockExecutiveProducer, networkList, productionEntityList } from './config';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';
import { withUserContext } from '../../../contexts/UserContext';
import SearchSelectField from '../../SharedComponents/SearchSelectField';
import { validateEmail, validateNumbersOnly } from '../../../Common/Helper';
import messages from '../../../Common/Messages.json';
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
let initialRecordData = {};
class ShowPilotMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialRecordData: this.props.initialRecordData1,
            pilotMaintanace: { ...this.props.initialRecordData1?.pilot_maintance[0] },
            executiveOptions: [],
            isPosting: false,
            postInitiated: false
        };
    }
    componentDidMount() {
        initialRecordData = { ...this.state.initialRecordData };
        this.getPilotMaintenanceDetails();
    }

    getPilotMaintenanceDetails = () => {
        let pilotMaintenanceResponse = { ...this.props.initialRecordData1?.pilot_maintance[0] };
        if (pilotMaintenanceResponse?.script_date) {
            pilotMaintenanceResponse.script_date = moment(pilotMaintenanceResponse.script_date).format('YYYY-MM-DD')
        }
        if (!pilotMaintenanceResponse?.is_this_not_pilot) {
            pilotMaintenanceResponse.is_this_not_pilot = 0;
        }
        if (pilotMaintenanceResponse?.executive_producers?.length === 0) {
            pilotMaintenanceResponse?.executive_producers.push(JSON.parse(JSON.stringify({ ...mockExecutiveProducer })));
        }
        this.setState({ pilotMaintanace: pilotMaintenanceResponse });
    }
    onCheckboxChange = (field, value) => {
        if (field === "is_this_not_pilot" && value === false) {
            initialRecordData["pilot_maintance"][0][field] = value ? 1 : 0;
            initialRecordData["pilot_maintance"][0]["customize_title"] = null;
            initialRecordData["pilot_maintance"][0]["apply_to"] = 'PILOT';
            this.setState({
                pilotMaintanace: {
                    ...this.state.pilotMaintanace,
                    [field]: value ? 1 : 0,
                    ["customize_title"]: null,
                    ["apply_to"]: 'PILOT'
                },
            });
        }
        else {
            initialRecordData["pilot_maintance"][0][field] = value ? 1 : 0;
            this.setState({
                pilotMaintanace: {
                    ...this.state.pilotMaintanace,
                    [field]: value ? 1 : 0
                },
            });
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    }
    handleChange = (field, value) => {
        initialRecordData["pilot_maintance"][0][field] = value;
        if (field === "customize_title") {
            initialRecordData["pilot_maintance"][0]["apply_to"] = value;
            this.setState(prevState => ({
                pilotMaintanace: {
                    ...prevState.pilotMaintanace,
                    [field]: value,
                    ["apply_to"]: value
                },
                postInitiated: false
            }))
        }
        else {
            this.setState(prevState => ({
                pilotMaintanace: {
                    ...prevState.pilotMaintanace,
                    [field]: value
                },
                postInitiated: false
            }))
        }
        this.props.getChildDetails(initialRecordData, this.props.origin);
    };

    handleListEdit = (fieldName, field, value, index) => {
        let pilotMaintanaceDetails = { ...this.state.pilotMaintanace };
        switch (fieldName) {
            case "executive_producers":
                let pilotList = pilotMaintanaceDetails[fieldName][index];
                let pilot_item = {
                    ...pilotList,
                    [field]: value,
                };
                pilotMaintanaceDetails[fieldName][index] = pilot_item;
                break;
        }
        this.setState({
            pilotMaintanace: pilotMaintanaceDetails
        });
    };



    formatPilotDetail = (response1) => {
        let response = { ...response1 }
        if (response?.executive_producers?.length === 0) {
            response?.executive_producers.push(JSON.parse(JSON.stringify({ ...mockExecutiveProducer })));
        }
        console.log("response", response)
        this.setState({ pilotMaintanace: response })
        return response;
    }

    handleExecutiveChange = (Index, value) => {
        let data = this.state.pilotMaintanace;
        data.executive_producers[Index].executive_producer = value;
        data.is_executive_producer_updated = 1;
        this.setState({ pilotMaintanace: data });
        this.fieldChanged(true, "error");
    }
    removeCallback = (Index) => {
        let allDetails = this.state.pilotMaintanace
        allDetails.executive_producers.splice(Index, 1)
        this.setState({ pilotMaintanace: allDetails })
    }

    addCallback = (Index) => {
        let allDetails = { ...this.state.pilotMaintanace };
        allDetails.executive_producers.push(JSON.parse(JSON.stringify({ ...mockExecutiveProducer })))
        this.setState({ pilotMaintanace: allDetails })
    }

    render() {
        let selectedSeasonObj = this.props.seasonOptions?.filter((item) => item.value === this.state.initialRecordData.season_id);
        selectedSeasonObj = selectedSeasonObj.length > 0 ? selectedSeasonObj[0] : null;
        let selectedStartYear = selectedSeasonObj.label.split("-")[0];
        return (
            <>
                {this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                        <MDBContainer fluid className="pilotMaintenanceModal">
                            <MDBRow>
                                <MDBCol md={6}>
                                    <BasicCheckbox id="is_this_not_pilot" label="This is not a pilot"
                                        checked={this.state.pilotMaintanace?.is_this_not_pilot || false}
                                        onChange={(e) => this.onCheckboxChange("is_this_not_pilot", e.target.checked)}
                                    />
                                </MDBCol>
                                <MDBCol md={6}>
                                    {this.state.pilotMaintanace?.is_this_not_pilot === 1 &&
                                        <BasicTextField
                                            label={"Type of Telecast"}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated || false}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            value={this.state.pilotMaintanace?.customize_title || null}
                                            placeholder="Type of Telecast"
                                            onChange={(e) => this.handleChange("customize_title", e.target.value.trim().length === 0 ? null : e.target.value)}
                                        />}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>

                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={4}>
                                    <BasicLabel
                                        text={"Format"}
                                    />
                                    <BasicTextField
                                        value={this.state.pilotMaintanace?.format || null}
                                        placeholder="Format"
                                        onChange={(e) => this.handleChange("format", e.target.value.trim().length === 0 ? null : e.target.value)}
                                    />
                                </MDBCol>
                                {/* <MDBCol md={4}>
                                    <SelectField
                                        id={"Network"}
                                        label={"Network"}
                                        value={this.state.pilotMaintanace?.network_id || null}
                                        options={this.props.networkOptions || []}
                                        onChange={(e) => this.handleChange("network_id", e.target.value)}
                                    />
                                </MDBCol> */}
                                <MDBCol md={4}>
                                    <DateField
                                        label={"Script Date"}
                                        placeholder={"MM/DD/YYYY"}
                                        value={this.state.pilotMaintanace?.script_date || null}
                                        onChange={(e) => this.handleChange("script_date", e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                {/* <MDBCol md={6}>
                                    <BasicLabel
                                        text={"Production Executive"}
                                    />
                                    <BasicTextField
                                        value={this.state.pilotMaintanace?.production_executive_name || null}
                                        placeholder="Production Executive"
                                        onChange={(e) => this.handleChange("production_executive_name", e.target.value)}
                                    />
                                </MDBCol> */}
                                {/* <MDBCol md={6}>
                                    <BasicLabel
                                        text={"Estimator"}
                                    />
                                    <BasicTextField
                                        value={this.state.pilotMaintanace?.estimator_name || null}
                                        placeholder="Estimator"
                                        onChange={(e) => this.handleChange("estimator_name", e.target.value)}
                                    />
                                </MDBCol> */}
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={4}>
                                    <BasicLabel text={"Executive Producers"} />
                                    {this.state.pilotMaintanace?.executive_producers?.map((item, index) => (
                                        <MDBRow>
                                            <MDBCol md={10} className="pb-3">
                                                <BasicTextField
                                                    value={item?.executive_producer || ""}
                                                    placeholder="Executive Producers"
                                                    onChange={(e) => this.handleExecutiveChange(index, e.target.value.trim().length === 0 ? null : e.target.value)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={2}>
                                                <div className="plus-icon">
                                                    {this.state.pilotMaintanace?.executive_producers?.length - 1 === index ?
                                                        <MDBIcon icon="plus-circle" className={index === 0 ? "plus-height" : "float-right"} onClick={() => this.addCallback(index)} /> : ""
                                                    }
                                                    {this.state.pilotMaintanace?.executive_producers?.length > 1 ?
                                                        <MDBIcon icon="minus-circle" className={index === 0 ? "plus-height " : "float-right"} onClick={() => this.removeCallback(index)} /> : ""
                                                    }
                                                </div>
                                            </MDBCol>


                                        </MDBRow>
                                    ))}
                                </MDBCol>
                                <MDBCol md={6}>
                                    <BasicLabel
                                        text={"Dev. Exec"}
                                    />
                                    <BasicTextField
                                        value={this.state.pilotMaintanace?.developer_executive_name || null}
                                        placeholder="Dev. Exec Name"
                                        onChange={(e) => this.handleChange("developer_executive_name", e.target.value.trim().length === 0 ? null : e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <BasicLabel
                                    text={"Notes"}
                                />
                                <BasicTextArea
                                    rows={4}
                                    placeholder={"Notes go here"}
                                    value={this.state.pilotMaintanace?.notes || null}
                                    onChange={(e) => this.handleChange("notes", e.target.value.trim().length === 0 ? null : e.target.value)}
                                />
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={3}>
                                    <BasicLabel text={"Bundling of Rights Discount"} />
                                    {selectedStartYear >= "2016" && <BasicTextField
                                        // fieldValid={this.state.details?.work_phone ? (!validateNumbersOnly(this.state.details?.work_phone) ? true : false) : false}
                                        fieldValid={selectedSeasonObj.value === this.state.pilotMaintanace.season_id ? !validateNumbersOnly(this.state.pilotMaintanace?.rights_discount) : !validateNumbersOnly(selectedSeasonObj.rights_discount) ? true : false}
                                        value={selectedSeasonObj.value === this.state.pilotMaintanace.season_id ? this.state.pilotMaintanace?.rights_discount : selectedSeasonObj.rights_discount}
                                        onChange={(e) => this.handleChange("rights_discount", e.target.value.trim().length === 0 ? null : e.target.value)}
                                        inValidInput={messages.invalidInput}
                                    />}
                                    {selectedStartYear < "2016" && <BasicTextField
                                        value={"0"} disabled={true}
                                    />}
                                </MDBCol>
                            </MDBRow>

                        </MDBContainer>
                    )}
            </>
        )
    }
}

export default withUserContext(ShowPilotMaintenance);
