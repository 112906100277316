import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import './MusicEditorDetails.scss';

export default class MusicEditorDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: null,
            company: null,
            federalId: null,
            email: null
        };
    }
    render() {
        return (
            <MDBContainer fluid className="ModalContainer MusicEditorDetailsContainer">
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Full Name"}
                            className="title"
                            placeholder={"Hans Zimmerman"}
                            value={this.state.fullName}
                            onChange={(e) => { this.setState({ fullName: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Company"}
                            className="title"
                            placeholder={"Hans Zimmerman Compositions LLC"}
                            value={this.state.company}
                            onChange={(e) => { this.setState({ company: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={2}></MDBCol>
                    <MDBCol md={2}>
                        <BasicTextField
                            label={"Federal ID No."}
                            className="federal"
                            placeholder={"99999-99"}
                            value={this.state.federalId}
                            onChange={(e) => { this.setState({ federalId: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Email"}
                            className="title"
                            placeholder={"Hans.Zimmerman@alias.com"}
                            value={this.state.email}
                            onChange={(e) => { this.setState({ email: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Address"}
                            className="title"
                            placeholder={"Sommerville Publishing P.O Box 12345"}
                            value={this.state.address}
                            onChange={(e) => { this.setState({ address: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"City"}
                            className="title"
                            placeholder={"Los Angeles"}
                            value={this.state.city}
                            onChange={(e) => { this.setState({ city: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={2}>
                        <BasicTextField
                            label={"Work Phone"}
                            className="title"
                            placeholder={"123-456-7890"}
                            value={this.state.workPhone}
                            onChange={(e) => { this.setState({ workPhone: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicTextField
                            label={"Cell Phone"}
                            className="title"
                            placeholder={"123-456-7890"}
                            value={this.state.cellPhone}
                            onChange={(e) => { this.setState({ cellPhone: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            className="title"
                            placeholder={"12345 Cherry Lane"}
                            value={this.state.address}
                            onChange={(e) => { this.setState({ address: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <SelectField
                            label={"State"}
                            className="title"
                            placeholder={"CA"}
                            value={this.state.state}
                            onChange={(e) => { this.setState({ state: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicTextField
                            label={"Zip Code"}
                            className="title"
                            placeholder={"90120-9999"}
                            value={this.state.zipCode}
                            onChange={(e) => { this.setState({ zipCode: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={4}>
                        <SelectField
                            label={"Country"}
                            className="title"
                            placeholder={"United States"}
                            value={this.state.country}
                            onChange={(e) => { this.setState({ country: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    Agent
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Full Name"}
                            className="title"
                            placeholder={"Scott Wheeler"}
                            value={this.state.agentFullName}
                            onChange={(e) => { this.setState({ agentFullName: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Company"}
                            className="title"
                            placeholder={"WME Entertainment"}
                            value={this.state.agentCompany}
                            onChange={(e) => { this.setState({ agentCompany: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Email"}
                            className="title"
                            placeholder={"Scott.Wheeler@wme.com"}
                            value={this.state.agentEmail}
                            onChange={(e) => { this.setState({ agentEmail: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"Address"}
                            className="title"
                            placeholder={"Sommerville Publishing P.O Box 12345"}
                            value={this.state.agentAddress}
                            onChange={(e) => { this.setState({ agentAddress: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"City"}
                            className="title"
                            placeholder={"Los Angeles"}
                            value={this.state.agentCity}
                            onChange={(e) => { this.setState({ agentCity: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={2}>
                        <BasicTextField
                            label={"Work Phone"}
                            className="title"
                            placeholder={"111-222-3333"}
                            value={this.state.agentWorkPhone}
                            onChange={(e) => { this.setState({ agentWorkPhone: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicTextField
                            label={"Cell Phone"}
                            className="title"
                            placeholder={"444-666-4444"}
                            value={this.state.agentCellPhone}
                            onChange={(e) => { this.setState({ agentCellPhone: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                            className="title"
                            placeholder={"12345 Cherry Lane"}
                            value={this.state.agentAddress}
                            onChange={(e) => { this.setState({ agentAddress: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <SelectField
                            label={"State"}
                            className="title"
                            placeholder={"CA"}
                            value={this.state.agentState}
                            onChange={(e) => { this.setState({ agentState: e.target.value }) }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicTextField
                            label={"Zip Code"}
                            className="title"
                            placeholder={"90120-9999"}
                            value={this.state.agentZipCode}
                            onChange={(e) => { this.setState({ agentZipCode: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={4}>
                        <SelectField
                            label={"Country"}
                            className="title"
                            placeholder={"United States"}
                            value={this.state.agentCountry}
                            onChange={(e) => { this.setState({ agentCountry: e.target.value }) }}
                        />
                    </MDBCol>
                </MDBRow>
                <br/>
            </MDBContainer>
        )
    }
}
