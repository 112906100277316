import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import TabsComponent from '../../../components/SharedComponents/Tabs/Tabs';
import { lookupMaintenanceList } from './Config';
import CueSheetPublisher from './CueSheetPublisher/CueSheetPublisher';
import CueSheetRecipient from './CueSheetRecipient/CueSheetRecipient';
import BudgetvsEFCRecipient from './BudgetvsEFCRecipient/BudgetvsEFCRecipient';
import { efcRecipientList } from './EFCRecipients/Config';
import EFCRecipients from './EFCRecipients/EFCRecipients';
import PDComposer from './PDComposer/PDComposer';
import Performers from './Performers/Performers';
import Unions from './Unions/Unions';
import Writers from './Writers/Writers';
import BudgetRecipient from './BudgetRecipient/BudgetRecipient';
import MessageModal from '../../SharedComponents/MessageModal';
import './LookupMaintenance.scss'
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent"
import Affiliations from './Affiliations/Affiliations';


let tabValues = [];
class LookupMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            showError: false,
            showSuccess: false,
            showFail: false,
            unsavedTitle: "something went wrong",
            fieldChanged: false,
            isExistCostCenterLoading: false,
            failMessage: "You can not delete this lookup record because it is being used",
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            showSuccessMessage: false,
            showFailMessage: false,
            showErrorMessage: false,
            showFailDeleteMessage: false,
            showNoAccess: false,
            lookupTabsConfig: lookupMaintenanceList,
            permissionList:[]
        };
    }
    componentDidMount(){
        document.title = 'Lookup Maintenance'
        this.setState({tabValue:tabValues.length > 0 ? tabValues[0].value : 1})
    }
    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "error": return this.setState({ showError: value });
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };
    closePopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
    }

    

    getTabComponent = () => {
        let permissionArrayList = this.props?.lookupMaintenancePermissions?.filter((item)=>item.permission_name === this.state.lookupTabsConfig.find((item)=>item.value === this.state.tabValue).permissionName)
        switch (tabValues.length > 0 && this.state.tabValue) {
            case 1:
                return <CueSheetPublisher fieldChanged={this.fieldChanged} toastMessageNotification={this.notificationComponent} permissionArray = {permissionArrayList}/>
                break;
            case 2:
                return <CueSheetRecipient toastMessageNotification={this.notificationComponent} fieldChanged={this.fieldChanged} permissionArray = {permissionArrayList}/>
                break;
            case 3:
                return <EFCRecipients toastMessageNotification={this.notificationComponent} fieldChanged={this.fieldChanged} permissionArray = {permissionArrayList}/>
                break;
            case 4:
                return <BudgetvsEFCRecipient toastMessageNotification={this.notificationComponent} fieldChanged={this.fieldChanged}  permissionArray = {permissionArrayList}/>
                break;
            case 5:
                return <PDComposer fieldChanged={this.fieldChanged} toastMessageNotification={this.notificationComponent}  permissionArray = {permissionArrayList}/>
                break;
            case 6:
                return <Performers fieldChanged={this.fieldChanged} toastMessageNotification={this.notificationComponent}  permissionArray = {permissionArrayList}/>
                break;
            case 7:
                return <Unions fieldChanged={this.fieldChanged} toastMessageNotification={this.notificationComponent}  permissionArray = {permissionArrayList}/>
                break;
            case 8:
                return <Writers toastMessageNotification={this.notificationComponent} fieldChanged={this.fieldChanged}  permissionArray = {permissionArrayList}/>
                break;
            case 9:
                return <BudgetRecipient toastMessageNotification={this.notificationComponent} fieldChanged={this.fieldChanged}  permissionArray = {permissionArrayList}/>
                break;
            case 10:
                return <Affiliations toastMessageNotification={this.notificationComponent} fieldChanged={this.fieldChanged}  permissionArray = {permissionArrayList}/>
                break;    

        }
    };
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccessMessage: value });
            case "fail": return this.setState({ showFailMessage: value });
            case "delete": return this.setState({ showFailDeleteMessage: value });
            case "noaccess": return this.setState({ showNoAccess: value })
        }
    }
    handleResetNotify = () => {
        this.setState({ showFailMessage: false, showSuccessMessage: false, showFailDeleteMessage: false, showNoAccess: false });
        if (this.state.saveFlag) {
            this.closeToastMessage();
        }
    }
    closeToastMessage = () => {
        this.setState({ showErrorMessage: false, showFailMessage: false, showSuccessMessage: false, showFailDeleteMessage: false, showNoAccess: false });
        if (this.state.saveFlag) {
            this.props?.handleClose();
        }
    }

    render() {
         tabValues = this.state.lookupTabsConfig.filter(item => this.props?.lookupMaintenancePermissions?.find(obj => obj.permission_name === item.permissionName && obj.view === 1))
        let severity = (this.state.showFailMessage || this.state.showFailDeleteMessage || this.state.showNoAccess) ? "error" : this.state.showSuccessMessage ? "success" : "";
        let messageNotification = this.state.showSuccessMessage ? "Saved Changes Successfully" : this.state.showFailMessage ? "Saved Changes Failed" : this.state.showFailDeleteMessage ? "Cannot Delete this Record" : this.state.showNoAccess ? 'Do not have edit access for this show season' : ""

        let message = this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveEditMessage : 'something went wrong'
        let comfirmModalTitle = 'Message';
        return (
            <div className="Lookup-Container">
                <div>
                    <NotificationComponent open={this.state.showSuccessMessage || this.state.showFailMessage || this.state.showFailDeleteMessage || this.state.showNoAccess} message={messageNotification} severity={severity}
                        handleResetNotify={this.handleResetNotify.bind(this)} />
                </div>
                <MessageModal
                    open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                    title={comfirmModalTitle}
                    message={message}
                    hideCancel={true}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => this.closePopup()}
                    handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                <div>
                    <MDBRow className="tab-content">
                        <MDBCol md={12}>
                            <TabsComponent
                                tabList={tabValues}
                                value={this.state.tabValue}
                                onChange={(e, v) => this.handleTabChange(v)}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
                {this.getTabComponent()}
            </div>
        )
    }
}

export default LookupMaintenance;
