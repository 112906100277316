import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import {MDBContainer,} from "mdb-react-ui-kit";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../route/Routes";
import ErrorBoundary from "../error-boundary/ErrorBoundary";;
import { withUserContext } from "../contexts/UserContext";
import "./MainContent.scss"

export default withUserContext(
    class MainContent extends React.Component {
        _isMounted = false;
        constructor(props) {
            super(props);
            this.state = {
                active_route: "",
                dashboardId: 1,
                dashboardName: "Show Seasons",
                refreshPage: { "modal": '', "value": false },
            };
            this.mainContent = React.createRef();
        }

        
        setActiveTab = (value) => {
            this.setState({ activeTab: value });
        };

        handleSideNavActive = (id, dashboardName) => {
            this.setState({ dashboardId: id, dashboardName: dashboardName })
        };

        toggleRefreshPage = (modalName) => {
            let refreshPage = {
                "modal": modalName,
                "value": !this.state.refreshPage?.value              
            }
            this.setState({ refreshPage: refreshPage })
          }

        render() {
            return (
                <>
                    <div>
                        {/* <HeaderContent {...this.props} {...this.state} 
                  authState={this.props.authState} 
                  switchTenant = {this.switchTenant.bind(this)}
                  setActiveTab = {this.setActiveTab.bind(this)} /> */}

                        <MDBContainer fluid>
                            <MDBRow className="mainContent">
                                {/* <DashboardContainer {...this.props} /> */}
                                <MDBCol className="w-auto main-content" id="main-content">
                                    <div ref={this.mainContent}>
                                        <ErrorBoundary>
                                            <Routes
                                                authState={this.props.authState}
                                                {...this.props}
                                                {...this.state}
                                                functions={this.functions}
                                                toggleRefreshPage={this.toggleRefreshPage.bind(this)}
                                                refreshPage={this.state?.refreshPage}
                                                handleSideNavActive={this.handleSideNavActive.bind(
                                                    this
                                                )}
                                                setActiveTab={this.setActiveTab.bind(this)}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                </MDBCol>
                                {/* <MDBCol className="col-qtr"></MDBCol> */}
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </>
            );
        }
    }
);