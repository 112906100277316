import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasicCheckbox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import DateField from '../../../SharedComponents/DateField/DateField';
import MessageModal from '../../../SharedComponents/MessageModal';
import Popover from '@material-ui/core/Popover';
import { initialCheckRequestConfig, initialOptionalRightsConfig } from './Config';
import {getFormattedDate} from "../../../../Common/Helper"

class CheckRequestPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkRequestDetails: {...this.props?.selectedCheckRequest},
            optionalRightDetails: {...this.props?.selectedOptionalRight},
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedCheckRequest !== this.props.selectedCheckRequest) {
            this.setState({ checkRequestDetails: {...this.props?.selectedCheckRequest}});
        }
        if (prevProps.selectedOptionalRight !== this.props.selectedOptionalRight) {
            this.setState({ optionalRightDetails: {...this.props?.selectedOptionalRight} });
        }
    }



    handleChange = (field, value) => {
        if (field === "check" || field === "date_paid" || field === "is_exclude_batch" || field === "is_amort") {
            this.setState(prevState => ({
                checkRequestDetails: {
                    ...prevState.checkRequestDetails,
                    [field]: value
                }
            }))
        } else if (field === "is_void") {
            if (this.state.checkRequestDetails?.check && this.state.checkRequestDetails?.date_paid) {
                let newNotes = `\n Voided on ${getFormattedDate(new Date())} by ${this.props?.userName}`
                console.log(this.state.checkRequestDetails?.notes,"prevState?.notes");
                this.setState(prevState => ({
                    checkRequestDetails: {
                        ...prevState.checkRequestDetails,
                        [field]: value,
                        notes: value ? prevState?.notes ? `${prevState?.notes + newNotes}` : newNotes : prevState?.notes
                    }                     
                }))
            } else {
                this.props?.handleVoidCheck();
                this.setState(prevState => ({
                    checkRequestDetails: {
                        ...prevState.checkRequestDetails,
                        [field]: false
                    }
                }))
            }
        } else if (field === "is_exclude_letters") {
            if (this.state.optionalRightDetails?.option_acquired_date) {
                this.setState({
                    showCannotExcludeAlert: true,
                    cannotExcludeAlertMessage: `You cannot exclude this option because it has already been acquired or you do not have sufficient rights.`
                })
            } else {
                this.setState(prevState => ({
                    optionalRightDetails: {
                        ...prevState.optionalRightDetails,
                        [field]: value
                    }
                }))
            }
        } else {
            if (field === "is_disallow_acquire" && !this.state.optionalRightDetails?.option_acquired_date) {
                if (!this.state.optionalRightDetails?.is_disallow_acquire) {
                    this.setState({
                        showDisallowAcquireAlert: true,
                        disallowAcquireAlertMessage: `Would you like to disallow the acquisition of the 
                            ${this.props?.selectedOptionalRight?.right_abbr} option for this song/licensor? ${'episode name'}?`
                    })
                } else {
                    this.setState({
                        showAllowAcquireAlert: true,
                        allowAcquireAlertMessage: "Allow Acquisition?"
                    })
                }
            } else if (field === "is_disallow_acquire" && this.state.optionalRightDetails?.option_acquired_date) {
                if (!this.state.optionalRightDetails?.is_disallow_acquire) {
                    this.setState({
                        showCannotDisallowAcquireAlert: true,
                        cannotDisallowAcquireAlertMessage: `You cannot disallow acquisition of this option because it has already been acquired or you do not have sufficient rights.`
                    })
                }
            } else {
                this.setState(prevState => ({
                    optionalRightDetails: {
                        ...prevState.optionalRightDetails,
                        [field]: value
                    }
                }))
            }
        }
    }

    handleClick = (listName) => {
        let details = listName === "check-request" ? this.state.checkRequestDetails : this.state.optionalRightDetails
        this.props?.onChangeCallback(details, this.props?.listName)
        this.props.handleOnClose()
    }

    handleOnConfirm = () => {
        if (this.state.showCannotDisallowAcquireAlert) {
            this.setState(prevState => ({
                optionalRightDetails: {
                    ...prevState.optionalRightDetails,
                    is_disallow_acquire: this.state.optionalRightDetails?.is_disallow_acquire,
                },
                showCannotDisallowAcquireAlert: false,
            }))
        } else if(this.state.showCannotExcludeAlert) {
            this.setState(prevState => ({
                optionalRightDetails: {
                    ...prevState.optionalRightDetails,
                    is_exclude_letters: this.state.optionalRightDetails?.is_exclude_letters,
                },
                showCannotExcludeAlert: false,
            }))
        } else {
            this.setState(prevState => ({
                optionalRightDetails: {
                    ...prevState.optionalRightDetails,
                    is_disallow_acquire: !this.state.optionalRightDetails?.is_disallow_acquire
                },
                showDisallowAcquireAlert: false,
                showAllowAcquireAlert: false
            }))
        }
    }

    handleOnCloseMessageModal = () => {
        this.setState(prevState => ({
            optionalRightDetails: {
                ...prevState.optionalRightDetails,
                is_disallow_acquire: this.state.optionalRightDetails?.is_disallow_acquire
            },
            showDisallowAcquireAlert: false,
            showAllowAcquireAlert: false
        }))
    }

    render() {
        let open = this.props.open;
        let openOpPopUp = this.props?.openOpPopUp;
        let id = open ? 'simple-popover' : undefined;
        let idModal = open ? 'modal-popover' : undefined;
        let messageModalTitle = this.state.showDisallowAcquireAlert ? 'Disallow Acquisition!' :
            this.state.showAllowAcquireAlert ? 'Allow Acquisition!' : 
                this.state.showCannotDisallowAcquireAlert ? 'Acquire Validation' :
                this.state.showCannotExcludeAlert ? 'Exclude Alert' : 'Saving Changes Failed!';
        let message = this.state.showDisallowAcquireAlert ? this.state.disallowAcquireAlertMessage :
            this.state.showAllowAcquireAlert ? this.state.allowAcquireAlertMessage : 
                this.state.showCannotDisallowAcquireAlert ? this.state.cannotDisallowAcquireAlertMessage : 
                    this.state.showCannotExcludeAlert ? this.state.cannotExcludeAlertMessage : null;
        return (
            <MDBContainer className="EditSyncLicensor-Container">
                <MessageModal
                    open={(this.state.showDisallowAcquireAlert || 
                        this.state.showAllowAcquireAlert || this.state.showCannotDisallowAcquireAlert ||
                        this.state.showCannotExcludeAlert
                    ) || false}
                    title={messageModalTitle}
                    message={message}
                    hideCancel={(this.state.showCannotDisallowAcquireAlert || this.state.showCannotExcludeAlert) || false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={this.handleOnConfirm}
                    handleClose={this.handleOnCloseMessageModal}
                />
                {/* <div className="popover-text-2" id="pop-text"> */}
                    <Popover
                        id={id}
                        open={open}
                        //className={"popover-text"}
                        anchorEl={this.props.checkRequestAnchorEl}
                        getContentAnchorEl={null}
                        onClose={this.props.handleOnClose}
                        //anchorReference="anchorPosition"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        //anchorPosition={{ top: 0, left: 0 }}
                        PaperProps={{ style: { width: '18%',backgroundColor:'#F2F2F2',borderRadius:'1px' } }}
                    >
                        <div className="popover-padding">
                            <div>
                                <BasicTextField
                                    id={"check-id"}
                                    label="Check #"
                                    value={this.state?.checkRequestDetails?.check || null}
                                    disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                    onChange={(e) => this.handleChange('check', e.target.value)}
                                />
                                <DateField
                                    id={"check-id"}
                                    label="Date Paid"
                                    disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                    value={getFormattedDate(this.state.checkRequestDetails?.date_paid,true) || ""}
                                    onChange={(e) =>
                                        this.handleChange(
                                            "date_paid",
                                            e.target.value?.length > 0 ? e.target.value : null
                                        )
                                    }
                                />
                                <BasicCheckbox
                                    id="exclude-batch"
                                    label={"Exclude Batch"}
                                    disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                    checked={this.state?.checkRequestDetails?.is_exclude_batch}
                                    onChange={(e) => this.handleChange('is_exclude_batch', e.target.checked)}
                                />
                                <BasicCheckbox
                                    id="void"
                                    label={"Void"}
                                    disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                    checked={this.state?.checkRequestDetails?.is_void}
                                    onChange={(e) => this.handleChange('is_void', e.target.checked)}
                                />
                                <BasicCheckbox
                                    id="amort"
                                    label={"Amort"}
                                    disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                    checked={this.state?.checkRequestDetails?.is_amort}
                                    onChange={(e) => this.handleChange('is_amort', e.target.checked)}
                                />
                            </div>
                            <MDBRow>
                                <MDBCol md={3}></MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={9}></MDBCol>
                                <MDBCol md={3}>
                                    <MDBRow >
                                        <MDBCol className="display-modal-2">
                                            {this.props?.isEditing &&
                                                <BasicButton
                                                    className={"modal-btn-save"}
                                                    variant="contained"
                                                    text=" "
                                                    icon={"save"}
                                                    onClick={() => this.handleClick('check-request')}
                                                    disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? false : true}
                                                />
                                            }
                                            <BasicButton
                                                className={"modal-btn-save"}
                                                variant="outlined"
                                                text=" "
                                                icon={"times"}
                                                onClick={this.props.handleOnClose}
                                            />
                                        </MDBCol>

                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </Popover>
                {/* </div>
                <div className="popover-text popover-padding" id="popover-text"> */}
                    <Popover
                        id={id}
                        open={openOpPopUp}
                        className={"popover-text"}
                        anchorEl={this.props?.opCheckRequestAnchorEl}
                        getContentAnchorEl={null}
                        onClose={this.props?.handleOnClose}
                        //anchorReference="anchorPosition"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        // anchorPosition={{ top: 420, left: 1100 }}
                        PaperProps={{ style: { width: '15%',backgroundColor:'#F2F2F2',borderRadius:'1px' } }}

                    >
                        <div>
                            <BasicCheckbox
                                id="disallow-acquire"
                                label={"Disallow Acquire"}
                                disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                checked={this.state?.optionalRightDetails?.is_disallow_acquire}
                                onChange={(e) => this.handleChange('is_disallow_acquire', e.target.checked)}
                            />
                            <BasicCheckbox
                                id="exclude-letter"
                                label={"Exclude Letter"}
                                disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                checked={this.state?.optionalRightDetails?.is_exclude_letters}
                                onChange={(e) => this.handleChange('is_exclude_letters', e.target.checked)}
                            />
                            <BasicCheckbox
                                id="exclude-license"
                                label={"Exclude License"}
                                disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? !this.props?.isEditing : true}
                                checked={this.state?.optionalRightDetails?.is_exclude_license}
                                onChange={(e) => this.handleChange('is_exclude_license', e.target.checked)}
                            />
                            <MDBRow>
                                <MDBCol md={3}></MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={9}></MDBCol>
                                <MDBCol md={3}>
                                    <MDBRow >
                                        <MDBCol className="display-modal-2">
                                            {this.props?.isEditing &&
                                                <BasicButton
                                                    className={"modal-btn-save"}
                                                    variant="contained"
                                                    text=" "
                                                    icon={"save"}
                                                    onClick={() => this.handleClick('optional-right')}
                                                    disabled={this.props?.initialRightsCheckRequestsPermission?.edit === 1 ? false : true}
                                                />
                                            }
                                            <BasicButton
                                                className={"modal-btn-save"}
                                                variant="outlined"
                                                text=" "
                                                icon={"times"}
                                                onClick={this.props?.handleOnClose}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </Popover>
                {/* </div> */}
            </MDBContainer>
        )
    }
}

export default CheckRequestPopUp;
