import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TableComponent from "../SharedComponents/Table";
import ClearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { validateNumbersOnly } from '../../Common/Helper'
import { withUserContext } from '../../contexts/UserContext';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';


class BudgetDetailCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      isLoading: false,
      isSubmit: false,
      budgetDetailCodeConfig: JSON.parse(JSON.stringify({ ...this.props?.config })),
      mandatoryNotExist: false,
      sortBy: null,
      isSortByAsc: true,
      sortCount: 0,
      isDetailDublicate: false
    };
  }

  componentDidMount() {
    this.getBudgetDetailCodes("BUDGET_DETAIL_CODE", null);
  }

  getBudgetDetailCodes = (bugetDetailCode, searchString, loader = true) => {
    this.setState({ isLoading: loader });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${bugetDetailCode}&searchString=${searchString}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let detailOptions = res.data?.filter(
          (item, index, data) =>
            index === data.findIndex((t) => t.detail_code === item.detail_code && item.detail !== null)
        );
        let formmatedList = detailOptions?.map((item) => ({
          value: item?.detail_code,
          label: item?.detail,
        }));
        let configRecord = {...this.state.budgetDetailCodeConfig}
        this.props?.permissionArray?.edit === 1 ? configRecord.actions = ["pen"] : configRecord.actions = [ ]
        this.setState({
          renderList: res.data,
          budgetDetailCodeConfig: configRecord,
          isLoading: false,
          isSubmit: false,
          sortBy: null,
          isSortByAsc: true,
          sortCount: 0
        }, () => {
          this.handleTableColumnClick("Detail");
          this.props?.reportData(this.state.renderList)
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };


  handleInlineEdits = (id, dataItem) => {
    let budgetDetailCodes = [...this.state.renderList];
    this.setState({
      mandatoryNotExist: false,
      renderList: budgetDetailCodes?.map((item) => {
        let newItem = {...item}
        if (item.budget_detail_code_id === id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ mandatoryNotExist: false,isDetailDublicate: false })
    if(this.state.renderList.some(item=>item.detail_code ===  parseInt(data[index]?.detail_code))){
      this.setState({isDetailDublicate: true})
    }else{
      if (data[index]?.detail_code && validateNumbersOnly(data[index]?.detail_code) && data[index]?.description && data[index]?.description.length > 0) {
        let budgetDetailCodes = [...data];
        let budgetDetails = [...this.state.renderList];
        budgetDetails[index].detail_code = budgetDetailCodes[index]?.detail_code;
        budgetDetails[index].description = budgetDetailCodes[index]?.description || null;
        budgetDetails[index].secondary_description =
          budgetDetailCodes[index]?.secondary_description || null;
        budgetDetails[index].is_clearance_MKT = budgetDetailCodes[index]
          ?.is_clearance_MKT
          ? 1
          : 0;
        budgetDetails[index].is_clearance_MT = budgetDetailCodes[index]
          ?.is_clearance_MT
          ? 1
          : 0;
        budgetDetails[index].is_clearance = budgetDetailCodes[index]?.is_clearance
          ? 1
          : 0;
        this.postBudgetDetailsCode(budgetDetails[index], budgetDetails[index]?.budget_detail_code_id)
      } else {
        this.setState({ mandatoryNotExist: true })
      }
    }
    
  };

  postBudgetDetailsCode = (budgetDetails, postFlag) => {
    this.setState({ isSubmit: postFlag });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/budgetDetailCode`,
      budgetDetails,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false });
          this.props?.toastMessageNotification(true, "fail");
        } else {
          // this.setState({
          //   isSubmit: false,
          // });
          if (budgetDetails?.editing) {
            budgetDetails.editing = false;
          }
          this.props?.toastMessageNotification(true, "success");
          this.getBudgetDetailCodes("BUDGET_DETAIL_CODE", "", false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props?.toastMessageNotification(true, "fail");
      }
    );
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ mandatoryNotExist: false ,isDetailDublicate: false})
    if(this.state.renderList.some(item=>item.detail_code ===  parseInt(newDataItem.detail_code))){
      this.setState({isDetailDublicate: true})
    }else{
      if (newDataItem?.detail_code && validateNumbersOnly(newDataItem?.detail_code) && newDataItem?.description && newDataItem?.description.length > 0) {
        let newBudgetData = {
          detail_code: newDataItem?.detail_code,
          description: newDataItem?.description || null,
          secondary_description: newDataItem?.secondary_description || null,
          is_clearance_MKT: newDataItem?.is_clearance_MKT ? 1 : 0,
          is_clearance_MT: newDataItem?.is_clearance_MT ? 1 : 0,
          is_clearance: newDataItem?.is_clearance ? 1 : 0,
        };
        this.postBudgetDetailsCode(newBudgetData, 'newItemPost');
      } else {
        this.setState({ mandatoryNotExist: true })
      }
    }
   
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.budgetDetailCodeConfig, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: tableSortList(colName === "Detail" ? "Number" : "Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Detail": return "detail_code";
      case "Description": return "description";
      case "Secondary Description": return "secondary_description";
      case "Clearance?": return "is_clearance";
      case "Clearance MKT?": return "is_clearance_MKT";
      case "Clearance MT?": return "is_clearance_MT";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="BudgetDetailCodeContainer">
        <MDBRow>
          <span className="description">Detail Codes for TV Music Creative Music Budgets</span>
        </MDBRow>
        <MDBRow className="pd">
        <span className="errorText pd">{this.state.mandatoryNotExist && `Please enter Detail and description field's value`}</span>
        </MDBRow>
        <MDBRow className="pd">
        <span className="errorText pd">{this.state.isDetailDublicate && `Detail value is  already existed Please Add New Value`}</span>
        </MDBRow>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <MDBRow className="budgetDetailCodeTable">
            <TableComponent
              list={this.state.renderList || []}
              config={this.state.budgetDetailCodeConfig}
              handleAddNewItem={this.handleAddNewItem.bind(this)}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              addItemToList={permissionValue?.edit === 1 ? true : false}
              postInitiated={this.state.isSubmit}
              stickyHeader={true}
              readCallback={(index) => {
                let budgetDetailCodes = [...this.state.renderList];
                budgetDetailCodes[index].editing = false
                this.setState({ renderList: budgetDetailCodes, mandatoryNotExist: false });
              }}
              sortCallback={this.handleTableColumnClick}
            />
          </MDBRow>
          
        )}
      </MDBContainer>
    );
  }
}

export default withUserContext(BudgetDetailCodes);
