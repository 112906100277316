import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import "./NewEpisode.scss";
import DateField from '../../SharedComponents/DateField/DateField';
import { intialEpisodeDetails, EpisodeCostConfig, EpisodeCostCenterConfig,episodeEFCNotesJSON } from './config';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { validateNumbersOnly, validateNumDotOnly } from '../../../Common/Helper';
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../contexts/UserContext';
import clearTrackService from '../../../services/service';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import messages from '../../../Common/Messages.json';
import MessageModal from '../../SharedComponents/MessageModal'
import InputAdornment from "@material-ui/core/InputAdornment"
let editEpisodesGLNum = false, canEditEpisodeAllValues = false;
let currentObj = [], is_cost_center_budgets = 0;
class NewEpisode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            episodeDetails: { ...intialEpisodeDetails },
            episodeCost: EpisodeCostConfig,
            episodeCostCenterConfig: EpisodeCostCenterConfig,
            postInitiated: false,
            mainTitleOptions: [],
            episodeCostDescription: [],
            costCenterOptions: [],
            episodeCostCenter: [],
            isLoading: false,
            isSubmit: false,
            isPosting: false,
            isValid: false,
            isPosting: false,
            episodeData: [],
            isdisable: false,
            efcIndex:2,
            active_episode_efc_notes: [],
            showBudgetError: false,
            efcNotesList: [],
            deletedEFCNotes: [],
            initialCostCenterBudgets:[]
        };
    };

    componentDidMount() {
        this.fetchData("costCenterOptions", `/entitySearch?entity=EPISODE_COST_CENTER&searchString=${this.props?.show_season_id}`);
        // this.fetchData("episodeData", `/showSeasonEpisodes?show_season_id=${this.props.show_season_id}`);
        this.getPermissions(this.props?.permissionArr);
        this.getMainTitleOptions("mainTitleOptions", "MAIN_TITLE_SONGS", this.props?.show_season_id);
        if (this.props?.mode === "Edit") {
            this.getEpisodeDetails();
        } else {
            this.fetchData("initialCostCenterBudgets", `/showSeason?showSeasonId=${this.props.show_season_id}`);
            let newEpisode = { ...intialEpisodeDetails };
            if (newEpisode?.episode_cost?.length === 0) {
                newEpisode["episode_cost"] =
                    [{
                        description: null,
                        cost: null,
                        show_season_episode_cost_id: 0
                    }];

                //     newEpisode["episode_cost"] = [];
                //    this.setState({episodeDetails : newEpisode}) 
            } else if (newEpisode?.episode_cost?.length != 0) {
                newEpisode["episode_cost"] =
                    [{
                        description: null,
                        cost: null,
                        show_season_episode_cost_id: 0
                    }];
            }
            if (newEpisode?.episode_cost_centers?.length === 0) {
                newEpisode["episode_cost_centers"] =
                    [{
                        cost_center_id: null,
                        budget: null,
                        show_season_episode_cost_center_budget_id: null
                    }];

            } else if (newEpisode?.episode_cost_centers?.length != 0) {
                newEpisode["episode_cost_centers"] =
                    [{
                        cost_center_id: null,
                        budget: null,
                        show_season_episode_cost_center_budget_id: null
                    }];
            } 
            if (newEpisode?.episode_efc_notes?.length === 0) {
                newEpisode["episode_efc_notes"]=[{...episodeEFCNotesJSON}]
            }
            newEpisode.episode_number = this.props?.newEpisodeNumber ? this.props?.newEpisodeNumber : null;
            this.setState({ episodeDetails: newEpisode })

        }
    }

    getEpisodeDetails = () => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodes?show_season_id=${this.props?.show_season_id}`,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
            .then(response => {
                if (this.props.mode === "Edit") {
                    let formattedList = response?.data.filter((item) => item.show_season_episode_id === this.props?.selectedEpisodeId);
                    formattedList[0].air_date = formattedList[0]?.air_date ? formattedList[0]?.air_date?.split('T')[0] : null;
                    formattedList[0].mixed_date = formattedList[0]?.mixed_date ? formattedList[0]?.mixed_date?.split('T')[0] : null;
                    if (formattedList[0]?.is_budget_difference === null) {
                        formattedList[0]["is_budget_difference"] = 1;
                    }
                    if (formattedList[0]?.is_completed === null) {
                        formattedList[0]["is_completed"] = 1;
                    }
                    if (formattedList[0]?.is_ngtd_new === null) {
                        formattedList[0]["is_ngtd_new"] = 1;
                    }
                    if (formattedList[0]?.is_pilot === null) {
                        formattedList[0]["is_pilot"] = 0;
                    }
                    if (formattedList[0]?.is_ms_complete === null) {
                        formattedList[0]["is_ms_complete"] = 0;
                    }
                    if (formattedList[0]?.is_no_lincensed_music === null) {
                        formattedList[0]["is_no_lincensed_music"] = 0;
                    }
                    if (formattedList[0].episode_cost === null || formattedList[0].episode_cost === undefined || formattedList[0].episode_cost?.length === 0) {
                        formattedList[0]["episode_cost"] =
                            [{
                                description: null,
                                cost: null,
                                show_season_episode_cost_id: 0
                            }];
                    }
                    if (formattedList[0].episode_cost_centers === null || formattedList[0].episode_cost_centers === undefined || formattedList[0].episode_cost_centers?.length === 0) {
                        formattedList[0]["episode_cost_centers"] =
                            [{
                                cost_center_id: null,
                                budget: null,
                                show_season_episode_cost_center_budget_id: null
                            }];
                    }
                    if (formattedList[0].episode_efc_notes?.length === 0) {
                        formattedList[0]["episode_efc_notes"] =
                            [{
                                show_season_episode_efc_notes_id: null,
                                notes: null,
                                is_new: 0
                            }];
                    }

                    this.setState({ episodeDetails: formattedList[0], isLoading: false,efcNotesList: formattedList[0]?.episode_efc_notes})
                }

            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });

    }

    getMainTitleOptions = (entity, entitytype, searchString) => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
            .then((res) => {
                let formattedList = res?.data?.map(item => ({ value: item.main_title_song_id, label: item.song_title }));
                this.setState({ mainTitleOptions: formattedList, isLoading: false });

            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    }
    handleChangeValue = (field, value, index) => {
        switch (field) {
            case "budget":
                let newValueOfCostcenter = [...this.state.episodeDetails.episode_cost_centers]
                is_cost_center_budgets = 1;
                newValueOfCostcenter[index][field] = value;
                currentObj.episode_cost_centers = newValueOfCostcenter;
                this.setState((prevState) => ({
                    episodeDetails: {
                        ...prevState.episodeDetails,
                        ["episode_cost_centers"]: newValueOfCostcenter
                    },showBudgetError:false
                }));
                break;
            default:
                let newValueOfCostDesc = [...this.state.episodeDetails.episode_cost];
                newValueOfCostDesc[index][field] = value;
                currentObj.episode_cost = newValueOfCostDesc;
                this.setState((prevState) => ({
                    episodeDetails: {
                        ...prevState.episodeDetails,
                        ["episode_cost"]: newValueOfCostDesc
                    },
                }));
                break;
        }
    }
    onDropdownChange(whatChanged, parentKey, index, keyToModify, valPassed, objPassed) {
        switch (whatChanged) {
            case 'costCenterChanged':
                let CostCenterArr = this.state.episodeDetails.episode_cost_centers;
                objPassed["cost_center_id"] = valPassed;
                CostCenterArr.splice(index, 1, objPassed);
                is_cost_center_budgets = 1;
                this.state.episodeDetails["episode_cost_centers"] = CostCenterArr;
                this.setState((prevState) => ({
                    episodeDetails: {
                        ...prevState.episodeDetails,
                        ["episode_cost_centers"]: CostCenterArr
                    },
                }));
                break;
            default:
                episodeDetails[keyToModify] = valPassed;
                this.setState((prevState) => ({
                    episodeDetails: {
                        ...prevState.episodeDetails,
                        [keyToModify]: valPassed
                    },
                }));

        }


    }
    handleEfcNotesChange = (key, value, index) => {
        let tempEFCNotes = [...this.state.episodeDetails.episode_efc_notes];


        if (key === "is_new") {
            tempEFCNotes[index][key] = value ? 1 : 0;
            this.setState({
                episodeDetails: {
                    ...this.state.episodeDetails,
                    ["episode_efc_notes"]: tempEFCNotes,

                },
                postInitiated: false
            })
        }
        if (key === "notes") {
            tempEFCNotes[index][key] = value;
            this.setState({
                episodeDetails: {
                    ...this.state.episodeDetails,
                    ["episode_efc_notes"]: tempEFCNotes,

                },
                postInitiated: false
            })
        }
    }
    handleChange = (field, value) => {
        if (field === "is_no_mt" || field === "is_music_replaced" || field === "is_completed" || field === "is_budget_difference" || field === "is_excl_from_budget_to_efc_report" || field === "is_mixed" || field === "is_ngtd_new" || field === "is_no_lincensed_music" || field === "is_ms_complete" || field === "is_pilot") {
            this.setState({
                episodeDetails: {
                    ...this.state.episodeDetails,
                    [field]: value ? 1 : 0,

                },
                postInitiated: false
            }, () => {
                if (field === "is_budget_difference" && this.state.episodeDetails.is_budget_difference === 0) {
                    this.setState({ openBudgetDiffModal:true });
                }
                if (this.props?.mode !== "Edit" && field === "is_budget_difference" && this.state.episodeDetails.is_budget_difference === 1) {
                    this.setState((prevState) => ({
                        episodeDetails: {
                        ...prevState.episodeDetails,
                        'episode_cost_centers': this.state.initialCostCenterBudgets
                    }}))
                }
                if (field === 'is_mixed') {
                    this.setState(prevState => ({
                        episodeDetails: {
                            ...prevState.episodeDetails,
                            'mixed_date': null
                        },
                    }))
                }
            });
        } else if (field === "air_date" && value === "") {
            this.setState({
                episodeDetails: {
                    ...this.state.episodeDetails,
                    [field]: null
                },
                postInitiated: false
            });
        } else {
            this.setState({
                episodeDetails: {
                    ...this.state.episodeDetails,
                    [field]: isNaN(value) ? value.trim().length === 0 ? null : value : value,
                },
                postInitiated: false
            });
        }

    };

    postEpisodeDetails = (postJson) => {
        //if (this.validateErrorFlags(postJson)) {
            this.setState({ postInitiated: true, isPosting: true });
            postJson.show_season_id = this.props?.initialRecordData?.show_season_id
            postJson.show_id = this.props?.initialRecordData?.show_id
            postJson.season_id = this.props?.initialRecordData?.season_id
            if (typeof postJson["episode_cost_centers"] === 0 || null) {
                postJson.is_cost_center_budgets = 0;
            } else {
                postJson.is_cost_center_budgets = 1;
            }      
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodes`,
                postJson,
                this.props.userContext?.active_tenant?.tenant_id, this.props?.initialRecordData?.division_id)
                .then(
                    (response) => {
                        this.setState({ isPosting: false });
                        if (response.data.error) {
                            this.props?.notificationComponent(true, "fail")
                            this.setState({ isPosting: false, });
                        } else {
                            this.setState({ isPosting: false, });
                            this.props?.notificationComponent(true, "success")
                            this.props?.fetchData("episodeData", `/showSeasonEpisodes?show_season_id=${this.props?.show_season_id}&main_title_id=${this.props.main_title_id}`);
                        }
                    },
                    (err) => {
                        this.setState({ postInitiated: false, isLoading: false });
                        this.props?.notificationComponent(true, "fail")
                    }
                )
        //}
    }
    fetchData = (field, url, id = "default", passedObj = null) => {
        this.setState({ isLoading: true })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let formattedList = [];
                switch (field) {
                    case 'costCenterOptions':
                        formattedList = response.data?.map(item => ({ value: item.cost_center_id, label: item.cost_center_name, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        this.setState({ costCenterOptions: formattedList })
                        break;
                    case "initialCostCenterBudgets":
                        formattedList=response?.data[0]?.clearance_budgets?.map((item)=>({
                            "show_season_clearance_budgets_id": item?.show_season_clearance_budgets_id,
                            "cost_center_id": item?.cost_center_id,
                            "cost_center_name": item?.cost_center_name,
                            "budget": null,
                            "default_budget":item?.budget
                        }))
                        this.setState({ initialCostCenterBudgets: formattedList })
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching data:", err)
                })
    }

    removeCallback = (index) => {
        let allCostData = [...this.state.episodeDetails.episode_cost];
        allCostData.splice(index, 1);
        this.setState((prevState) => ({
            episodeDetails: {
                ...prevState.episodeDetails,
                ["episode_cost"]: allCostData
            }
        }));
    }
    addEfcNotesCallback = (index) => {
        let tempEfcCost = this.state.episodeDetails;
       // tempEfcCost.push(JSON.parse(JSON.stringify({ ...intialEpisodeDetails.episode_efc_notes[0] })));
        if (tempEfcCost.episode_efc_notes[index].notes) {
            let efcArray = JSON.parse(JSON.stringify({ ...episodeEFCNotesJSON }));
            let efc_list = [...tempEfcCost.episode_efc_notes];
            efcArray.index = this.state.efcIndex;
            efc_list.push(efcArray);
            tempEfcCost.episode_efc_notes = efc_list;
            this.setState({
                efcIndex: this.state.efcIndex + 1, episodeDetails: tempEfcCost
            });
        }
    }
    removeEfcNotesCallback(index) {
        let data = JSON.parse(JSON.stringify(this.state.episodeDetails))
        let existingEFCList=[...this.state.efcNotesList]
        let deletedItem = data?.episode_efc_notes[index]
        if (deletedItem?.show_season_episode_efc_notes_id) {
            deletedItem.is_delete = 1
            let deletedEFCNotes = [...this.state.deletedEFCNotes, deletedItem]
            this.setState({ deletedEFCNotes: deletedEFCNotes })
            //data.is_songs_updated = 1
        }
        data.episode_efc_notes.splice(index, 1)
        let deleteExistingItem = existingEFCList.filter(item =>item.show_season_episode_efc_notes_id !== deletedItem.show_season_episode_efc_notes_id)
        this.setState({ episodeDetails: data, efcNotesList:deleteExistingItem})



        // let tempEfcCost =this.state.episodeDetails;
        // tempEfcCost.episode_efc_notes[index].is_delete = 1;
        // tempEfcCost.episode_efc_notes.splice(index, 1);
        // this.setState((prevState) => ({
        //     episodeDetails: tempEfcCost
        // }));
    }
    addCallback = (index) => {
        let allCostData = [...this.state.episodeDetails.episode_cost];
        allCostData.push(JSON.parse(JSON.stringify({ ...intialEpisodeDetails.episode_cost })))
        currentObj.episode_cost = allCostData;
        this.setState((prevState) => ({
            episodeDetails: {
                ...prevState.episodeDetails,
                ["episode_cost"]: allCostData
            }

        }));

    }
    addCostCenterRow = (index) => {
        let objToInsert = { ...intialEpisodeDetails.episode_cost_centers[0] };
        let CostCenter_Arr = this.state.episodeDetails.episode_cost_centers;
        CostCenter_Arr.push(objToInsert)
           // splice(index + 1, 0, objToInsert);
            this.setState((prevState) => ({
                episodeDetails: {
                    ...prevState.episodeDetails,
                    ["episode_cost_centers"]: CostCenter_Arr
                }
            }));
    }
    // addCostCenterRow() {
    //     let CostCenter_Arr = this.state.episodeDetails.episode_cost_centers;
    //    CostCenter_Arr.splice(this.state.episodeDetails.episode_cost_centers.length, 0, {
    //         "episode_cost_centers":this.state.episodeDetails.episode_cost_centers.length,
    //         "show_season_episode_cost_center_budget_id" : 0,
    //         "cost_center_name" :null,
    //         "cost_center_id": 0,
    //         "budget": 0,

    //     });

    //     this.setState((prevState) => ({
    //         episodeDetails: {
    //             ...prevState.episodeDetails,
    //             ["episode_cost_centers"]: CostCenter_Arr
    //         }

    //     }));
    // }
    removeCostCenterRow(index) {
        let CostCenter_Arr = this.state.episodeDetails?.episode_cost_centers;
        CostCenter_Arr.splice(index, 1);
        this.setState((prevState) => ({
            episodeDetails: {
                ...prevState.episodeDetails,
                ["episode_cost_centers"]: CostCenter_Arr
            }
        }));
    }

    handleSubmit = () => {
        this.setState({
            postInitiated: true
        }, () => {
            let finalArray = JSON.parse(JSON.stringify(this.state.episodeDetails));
            if (finalArray?.is_budget_difference === 0) {
                finalArray.episode_cost_centers = []
                finalArray.is_cost_center_budgets = 1
            }
            else {
                finalArray.episode_cost_centers?.map((item) => {
                    return item.budget = item.budget === null ? item.default_budget : item.budget
                })
            }
            finalArray.episode_cost?.map((item, index) => {
                if (item.description == "") {
                    item.description = null;
                }
                if (item.cost == "") {
                    item.cost = null;
                }
                if ((item.description == "" || item.description == null) || (item.cost == "" || item.cost == null)) {
                    finalArray.episode_cost.splice(index, 1);
                }

            })

            finalArray.tenant_id = this.props.userContext?.active_tenant?.tenant_id;
            finalArray.division_id = this.props?.initialRecordData?.division_id;
            if (this.state.deletedEFCNotes.length > 0) {
                finalArray.episode_efc_notes = [...finalArray.episode_efc_notes, ...this.state.deletedEFCNotes]
            }
            // let validateEpisodeCost = false;
            // finalArray.episode_cost.map((item, index) => {
            //     if (item?.description && item?.description?.trim()?.length !== 0 && item?.cost && item?.cost?.toString()?.trim()?.length !== 0) {
            //         validateEpisodeCost = true;
            //     } else {
            //         validateEpisodeCost = false;
            //         return validateEpisodeCost;
            //     }

            // })
            if (finalArray?.episode_number // && finalArray?.episode_title 
                // && validateEpisodeCost === true
            ) {
                this.postEpisodeDetails(finalArray);
            }
        })

    };

    validateErrorFlags = (postEpisodeJson) => {
        let isValid = false;

        // let canSubmit = (postEpisodeJson?.episode_number && postEpisodeJson?.code && postEpisodeJson?.episode_title && postEpisodeJson?.main_title_song_id) && (!isValid) ? true : false;
        //let canSubmit = (postEpisodeJson?.episode_number) && (!isValid) ? true : false;
        if (postEpisodeJson?.episode_cost_centers?.length > 0) {
            let canSubmit = false;

            postEpisodeJson?.episode_cost_centers?.find((item) => {
                canSubmit = item.budget !== null ? true : false
            })
            if (!canSubmit) {
                this.setState({ showBudgetError: true })
            }
            return canSubmit;
        } else {
            return true
        }
    }
    getPermissions(permissions) {
        if (permissions?.length > 0) {
            canEditEpisodeAllValues = permissions.filter((obj, index) => obj.permission_name === "Edit Episode (button+modal) - All Values minus GL number" && obj.view === 1 && obj.edit === 1);
            canEditEpisodeAllValues = canEditEpisodeAllValues.length > 0 ? true : false;
            editEpisodesGLNum = permissions.filter((obj, index) => obj.permission_name === "Edit Episode - GL number field" && obj.view === 1 && obj.edit === 1);
            editEpisodesGLNum = editEpisodesGLNum.length > 0 ? true : false;
        }
    }
    render() {
        return (
            <>
                {(this.state.isLoading) ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                        <MDBContainer fluid className="EpisodeContainer" disabled={canEditEpisodeAllValues === false ? true : false}>
                            <h6>Details</h6>
                            <MDBRow>
                                <MDBCol md={2}>
                                    <BasicTextField
                                        label={"#"}
                                        value={this.props?.indexNumber}
                                        showAsLabel={true}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                {<BasicTextField disabled={editEpisodesGLNum === false ? true : false}
                                        label={"Episode #"}
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated || false}
                                        value={this.state.episodeDetails?.episode_number}
                                        onChange={(e) => this.handleChange("episode_number", e.target.value)}
                                    />}
                                </MDBCol>
                                {this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id ===this.props?.initialRecordData?.division_id)?.division_name === 'Animation' && <MDBCol md={3}>
                                    <BasicTextField
                                        label={"MPM No."}
                                        value={this.state.episodeDetails?.mpm_no}
                                        onChange={(e) => this.handleChange("mpm_no", e.target.value)}
                                    />
                                </MDBCol>}
                                <MDBCol md={this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id ===this.props?.initialRecordData?.division_id)?.division_name === 'Animation' ? 3 : 6}>
                                    <BasicTextField
                                        label={"Title"}
                                        // isMandatory={true}
                                        // showMandatory={this.state.postInitiated || false}
                                        // mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.episodeDetails?.episode_title}
                                        onChange={(e) => this.handleChange("episode_title", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={1} className="PilotCheckboxContainer">
                                    <BasicCheckbox label={"Pilot"}
                                        id={"is_pilot"}
                                        checked={this.state.episodeDetails?.is_pilot}
                                        onChange={(e) => this.handleChange("is_pilot", e.target.checked)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-2">
                                {this.props?.mode === "Edit" ?
                                    (<>
                                        <MDBCol md={3}><BasicCheckbox label={"No Licensed Music"}
                                            id={"is_no_lincensed_music"}
                                            checked={this.state.episodeDetails?.is_no_lincensed_music}
                                            onChange={(e) => this.handleChange("is_no_lincensed_music", e.target.checked)}
                                        /></MDBCol>
                                        <MDBCol md={3}><BasicCheckbox label={"MS Complete"}
                                            id={"is_ms_complete"}
                                            className="disabled-cb"
                                            checked={this.state.episodeDetails?.is_ms_complete}
                                            onChange={(e) => this.handleChange("is_ms_complete", e.target.checked)}
                                            disabled={true}
                                        /></MDBCol>
                                        <MDBCol md={3}><BasicCheckbox label={"Mark as Complete"}
                                            id={"is_completed"}
                                            checked={this.state.episodeDetails?.is_completed}
                                            onChange={(e) => this.handleChange("is_completed", e.target.checked)}
                                        /></MDBCol>
                                    </>)
                                    :
                                    <MDBCol md={3}><BasicCheckbox label={"No Licensed Music"}
                                        id={"no_licensd_music"}
                                        checked={this.state.episodeDetails?.no_licensd_music}
                                        onChange={(e) => this.handleChange("no_licensd_music", e.target.checked)}
                                    /></MDBCol>
                                }
                            </MDBRow>
                            <MDBRow className="mt-2">
                                <MDBCol md={3}>
                                    <DateField
                                        label={"Air Date"}
                                        // isMandatory={true}
                                        // showMandatory={this.state.postInitiated || false}
                                        // mandatoryWarning={messages.mandatoryWarning}
                                        value={this.state.episodeDetails?.air_date || ""}
                                        onChange={(e) => this.handleChange("air_date", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={6} >
                                    {/* <BasicLabel text={"MainTitle"}/> */}
                                    <SelectField className="maintitle-mb"
                                        // isMandatory={true}
                                        // showMandatory={this.state.postInitiated || false}
                                        disabled={this.state.episodeDetails?.is_no_mt === 1 ? true : false}
                                        placeHolderText={"-Select-"}
                                        label={"Main Title"}
                                        value={this.state.episodeDetails?.is_no_mt === 1 ? null : this.state.episodeDetails?.main_title_song_id}
                                        options={this.state.mainTitleOptions || []}
                                        onChange={(e) => this.handleChange("main_title_song_id", e.target.value)}
                                    />
                                    {/* <BasicLabel text="select an existing MT record" className="text-color" /> */}
                                </MDBCol>
                                <MDBCol md={3} className="No-Mt">
                                    <BasicCheckbox
                                        id={"is_no_mt"}
                                        label={"No MT"}
                                        checked={this.state.episodeDetails?.is_no_mt === 1}
                                        onChange={(e) => {
                                            this.handleChange("main_title_song_id", null)
                                            this.handleChange("is_no_mt", e.target.checked)
                                        }
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={12}>
                                    <BasicTextArea
                                        label={"Notes"}
                                        // isMandatory={true}
                                        // showMandatory={this.state.postInitiated || false}
                                        // mandatoryWarning={messages.mandatoryWarning}
                                        rows={2}
                                        value={this.state.episodeDetails?.notes}
                                        onChange={(e) =>
                                            this.handleChange("notes", e.target.value)
                                        }
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow  className="mt-2">
                                <MDBCol>
                                    <BasicLabel text={"EFC Notes"} />
                                </MDBCol>
                            </MDBRow>
                            {this.state.episodeDetails?.episode_efc_notes?.map((item, index) => (
                                <MDBRow>
                                    <MDBCol md={10}>
                                        <BasicTextField
                                            rows={2}
                                            value={item?.notes}
                                            onChange={(e) =>
                                                this.handleEfcNotesChange("notes", e.target.value, index)
                                            }
                                        />
                                    </MDBCol>
                                    <MDBCol md={1}>
                                        <BasicCheckbox
                                            id={"is_new" + index}
                                            label={"New ?"}
                                            checked={item?.is_new}
                                            onChange={(e) => this.handleEfcNotesChange("is_new", e.target.checked, index)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={1}>
                                        {this.state.episodeDetails?.episode_efc_notes?.length - 1 === index  &&
                                            <AddCircleIcon fontSize="small"
                                                onClick={() => this.addEfcNotesCallback(index)}
                                            />
                                        }
                                        {this.state.episodeDetails?.episode_efc_notes?.length > 1 &&
                                            <RemoveCircleIcon fontSize="small"
                                                onClick={() => this.removeEfcNotesCallback(index)}
                                            />
                                        }
                                    </MDBCol>
                                </MDBRow>
                            ))}

                            {this.props.mode === "New" ?
                                <h6 style={{ marginTop: "5px" }}>Show Season Budget</h6> :
                                <h6 style={{ marginTop: "5px" }}>Budgets for Episode {this.state.episodeDetails?.episode_number}</h6>
                            }
                            <MDBRow className="mt-2">
                                <MDBCol md={2}>
                                    <BasicCheckbox
                                        id={"is_budget_difference"}
                                        label={"Budget Difference"}
                                        checked={this.state.episodeDetails?.is_budget_difference}
                                        onChange={(e) => this.handleChange("is_budget_difference", e.target.checked)}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicCheckbox label={"Negotiated NEW"}
                                        //disabled
                                        id={"is_ngtd_new"}
                                        checked={this.state.episodeDetails?.is_ngtd_new}
                                        onChange={(e) => this.handleChange("is_ngtd_new", e.target.checked)}
                                    />

                                </MDBCol>
                            </MDBRow>
                            {/* <MDBCol md={2}>
                                    {this.props.mode === "New" ?
                                        <BasicTextField
                                            label={"Negotiated 4646"}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated || false}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            placeHolderText={"$"}
                                            value={this.state.episodeDetails?.ngtd_4646}
                                            fieldValid={this.state?.episodeDetails?.ngtd_4646 ? (!validateNumbersOnly(this.state?.episodeDetails?.ngtd_4646) ? true : false) : false}
                                            onChange={(e) => this.handleChange('ngtd_4646', e.target.value.trim().length === 0 ? null : e.target.value)}
                                            inValidInput={messages.invalidInput}
                                        />
                                        :
                                        <>
                                            <BasicLabel text={"Negotiated 4646"} />
                                            <BasicLabel text={this.state.episodeDetails?.ngtd_4646} />
                                        </>
                                    }
                                </MDBCol>
                                <MDBCol md={2}>
                                    {this.props.mode === "New" ?
                                        <BasicTextField
                                            label={"Negotiated 4675"}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated || false}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            placeHolderText={"$"}
                                            fieldValid={this.state?.episodeDetails?.ngtd_4675 ? (!validateNumbersOnly(this.state?.episodeDetails?.ngtd_4675) ? true : false) : false}
                                            value={this.state.episodeDetails?.ngtd_4675}
                                            onChange={(e) => this.handleChange('ngtd_4675', e.target.value.trim().length === 0 ? null : e.target.value)}
                                            inValidInput={messages.invalidInput}
                                        /> :
                                        <>
                                            <BasicLabel text={"Negotiated 4675"} />
                                            <BasicLabel text={this.state.episodeDetails?.ngtd_4675} />
                                        </>
                                    }
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicCheckbox label={"Negotiated NEW"}
                                        id={"is_ngtd_new"}
                                        checked={this.state.episodeDetails?.is_ngtd_new}
                                        onChange={(e) => this.handleChange("is_ngtd_new", e.target.checked)}
                                    />
                                    
                                </MDBCol>
                             */}

                            {/* <MDBRow>
                                <MDBCol md={4}>
                                    <BasicCheckbox label={"Negotiated NEW"}
                                        id={"is_ngtd_new"}
                                        checked={this.state.episodeDetails?.is_ngtd_new}
                                        onChange={(e) => this.handleChange("is_ngtd_new", e.target.checked)}
                                    />
                                </MDBCol>
                            </MDBRow> */}
                            <MDBRow>
                                <MDBCol md={6}>
                                    {this.state.episodeDetails?.is_budget_difference === 1 && this.state.episodeDetails?.episode_cost_centers?.map((item, index) => (
                                        <MDBRow className="costCenterRow secondaryLabelContainer" key={"costCenterRow" + index}>
                                            <MDBCol md={5} id="Dec-p">
                                                <SelectField
                                                    disabled
                                                    label={index === 0 ? "Cost Center" : null}
                                                    className={"dec-field"}
                                                    options={this.state.costCenterOptions || []}
                                                    value={item?.cost_center_id || null}
                                                    onChange={(e) => this.onDropdownChange("costCenterChanged", null, index, "cost_center_id", e.target.value, item)}
                                                />
                                            </MDBCol>
                                            <MDBCol md={4} id="cost-p">
                                                <BasicTextField
                                                    label={index === 0 ? "Budget" : null}
                                                    className={"cost-field mt"}
                                                    value={item?.budget === null?item?.default_budget:item?.budget}
                                                    InputProps={ 
                                                        {
                                                          startAdornment: (
                                                            <InputAdornment position="start" size="small">
                                                                {'$'}
                                                            </InputAdornment>
                                                          ),
                                                        }
                                                      }
                                                    onChange={(e) => validateNumDotOnly(e.target.value)?this.handleChangeValue('budget', e.target.value , index):null}
                                                />
                                                {/* {(item?.budget === null && this.state.showBudgetError) && <span className="error">Please enter Budget</span>} */}
                                            </MDBCol>
                                            {/* <MDBCol md={2} style={{ paddingLeft: "0" }} className={index === 0 ? "firstRecordMargin" : null}>
                                                {index === this.state.episodeDetails?.episode_cost_centers?.length - 1 && <AddCircleIcon fontSize="small" onClick={() => this.addCostCenterRow(index)} />
                                                }
                                                {this.state.episodeDetails?.episode_cost_centers?.length > 1 && <RemoveCircleIcon fontSize="small"
                                                    onClick={() => this.removeCostCenterRow(index)}
                                                />}
                                            </MDBCol> */}
                                        </MDBRow>))
                                    }
                                </MDBCol>
                                <MDBCol md={6}>
                                    <MDBRow className="costCenterRow secondaryLabelContainer">
                                        {/* <MDBCol md={2}>
                                            <BasicLabel text={"Description"} />
                                        </MDBCol>
                                        <MDBCol md={4} className="margin-l" >
                                            <BasicLabel
                                                className="margin-l"
                                                text={"Cost"} />
                                        </MDBCol> */}
                                        {this.state.episodeDetails?.episode_cost?.map((item, index) => (
                                            <MDBRow className="costCenterRow secondaryLabelContainer" key={"ep_cost" + index}>
                                                <MDBCol md={5} id="Dec-p">
                                                    <BasicTextField
                                                        label={index === 0 ? "Description" : null}
                                                        // isMandatory={true}
                                                        // showMandatory={this.state.postInitiated || false}
                                                        // mandatoryWarning={messages.mandatoryWarning}
                                                        className={"dec-field"}
                                                        value={item?.description || null}
                                                        onChange={(e) => this.handleChangeValue('description', e.target.value?.length > 0 ? e.target.value : null, index)}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={3} id="cost-p">
                                                    <BasicTextField
                                                        className={"cost-field"}
                                                        label={index === 0 ? "Cost" : null}
                                                        // isMandatory={true}
                                                        // showMandatory={this.state.postInitiated || false}
                                                        // mandatoryWarning={messages.mandatoryWarning}
                                                        value={item?.cost || null}
                                                        InputProps={ 
                                                            {
                                                              startAdornment: (
                                                                <InputAdornment position="start" size="small">
                                                                    {'$'}
                                                                </InputAdornment>
                                                              ),
                                                            }
                                                          }
                                                        onChange={(e) => validateNumDotOnly(e.target.value)?this.handleChangeValue('cost', e.target.value?.length > 0 ? e.target.value : null, index):null}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={2} style={{ paddingLeft: "0" }} className={index === 0 ? "firstRecordMargin" : null}>
                                                    {index === this.state.episodeDetails?.episode_cost?.length - 1 && <AddCircleIcon fontSize="small" onClick={() => this.addCallback(index)} />
                                                    }
                                                    {this.state.episodeDetails?.episode_cost?.length > 1 && <RemoveCircleIcon fontSize="small"
                                                        onClick={() => this.removeCallback(index)}
                                                    />}
                                                </MDBCol>
                                                {/* <MDBCol md={2} className="icons-align">
                                                <div >
                                                    <MDBCol>
                                                        {this.state.episodeDetails?.episode_cost?.length - 1 === index ?
                                                            <MDBIcon icon="plus-circle" className={index === 0 ? "plus-height" : "floatleft"} onClick={() => this.addCallback(index)} /> : ""
                                                        }
                                                    </MDBCol>
                                                    <MDBCol className="p2">
                                                        {this.state.episodeDetails?.episode_cost?.length > 1 ?
                                                            <MDBIcon icon="minus-circle" className={index === 0 ? "plus-height " : "float-left"} onClick={() => this.removeCallback(index)} /> : ""
                                                        }
                                                    </MDBCol>
                                                </div>
                                            </MDBCol> */}
                                            </MDBRow>


                                        ))}
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            <h6 style={{ marginTop: "5px" }}>Options</h6>
                            <MDBRow>
                                <MDBCol md={2}>
                                    <BasicCheckbox
                                        id={"is_music_replaced"}
                                        label={"Music Replaced"}
                                        checked={this.state.episodeDetails?.is_music_replaced}
                                        onChange={(e) => { this.handleChange("is_music_replaced", e.target.checked) }}
                                    />
                                </MDBCol>
                                <MDBCol md={2}>
                                    <BasicCheckbox
                                        id={"is_excl_from_budget_to_efc_report"}
                                        label={"Excl. from Bdgt/EFC Report"}
                                        checked={this.state.episodeDetails?.is_excl_from_budget_to_efc_report}
                                        onChange={(e) => this.handleChange("is_excl_from_budget_to_efc_report", e.target.checked)}
                                    />
                                </MDBCol>

                                <MDBCol md={1}>
                                    <BasicCheckbox
                                        id={"is_mixed"}
                                        label={"Mixed"}
                                        checked={this.state.episodeDetails?.is_mixed}
                                        onChange={(e) => {
                                            this.handleChange("is_mixed", e.target.checked)
                                        }}
                                    />
                                </MDBCol>
                                <MDBCol md={3}>
                                    {this.state.episodeDetails?.is_mixed === 1 &&
                                        <DateField label={"Mix Date"}
                                            value={this.state.episodeDetails?.mixed_date || ""}
                                            onChange={(e) => this.handleChange("mixed_date", e.target.value?.length > 0 ? e.target.value : null)}
                                        />
                                    }
                                </MDBCol>
                            </MDBRow>


                            <MDBRow>

                                <MDBCol md={10}></MDBCol>
                                <MDBCol md={2} className="saveCancelWrapper">
                                    <>
                                        {this.props.mode === "New" ?
                                            <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Create"} onClick={this.handleSubmit} />
                                            :
                                            <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"} onClick={this.handleSubmit} />
                                        }
                                        <BasicButton
                                            variant="outlined" text={"Cancel"}
                                            onClick={() => this.props.handleClose()}
                                        />
                                    </>
                                </MDBCol>
                            </MDBRow>
                            <MessageModal
                    open={this.state.openBudgetDiffModal}
                    title={"Confirm"}
                    message={`You have selected to use the show season budgets for this episode.If any budgets exist for the episode they will be deleted.`}
                    hideCancel={false}
                    primaryButtonText={"OK"}
                    secondaryButtonText={"Cancel"}
                    onConfirm={() => {
                        this.setState({ openBudgetDiffModal: false })
                    }}
                    handleClose={() => {
                        this.setState({ openBudgetDiffModal: false })
                    }} />
                        </MDBContainer>
                    )
                }
            </>
        );
    }
}

export default withUserContext(NewEpisode);
