import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import './RapidCueAcknowledgements.scss';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
import { CircularProgress } from "@material-ui/core";
import ClearTrackService from '../../../services/service';

export default withUserContext(class RapidCueAcknowledgements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ackResponse: null,
            isFetchingData:true,
            isAckXmlLoader: false,
            isOrginalXmlLoader: false
        };
    }

    componentDidMount () {
            let showSeasonEpisodeCueSheetRecords =  this.props?.showSeasonEpisodeCueSheetRecords
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/RCXMLCueAck?show_season_episode_id=${showSeasonEpisodeCueSheetRecords.show_season_episode_id}&rc_xml_cue_id=${showSeasonEpisodeCueSheetRecords.rc_xml_cue_id}&cue_sheet_header_id=${showSeasonEpisodeCueSheetRecords.cue_sheet_header_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
                .then(response => {
                    this.setState({ ackResponse: response.data[0] ,isFetchingData:false});
                },
                    (err) => {
                        console.log("Error in fetching Tenant Details:", err)
                    })

    }

    downloadXML = (ackReport) => {
        if (ackReport) {
            this.setState({isAckXmlLoader: true})
            let json = {
                "platform": "BOX",
                "filename": `${this.state.ackResponse?.file_name}_Ack`,
                'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props?.division_id)?.division_name,
                'divisionId' : this.props?.division_id
                } 
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/downloadFiles?input=${btoa(JSON.stringify(json))}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id, true)
                .then(response => {
                    this.downloadUrl(response.data.data[0].url)
                    this.setState({isAckXmlLoader: false})
                },
                    (err) => {
                        console.log("Error in fetching Tenant Details:", err)
                    })
        } else {
           this.setState({ isOrginalXmlLoader: true})
            let json = {
                "platform": "BOX",
                "filename": `${this.state.ackResponse?.file_name}`,
                'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props?.division_id)?.division_name,
                'divisionId' : this.props?.division_id
                }
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/downloadFiles?input=${btoa(JSON.stringify(json))}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id, true)
                .then(response => {
                    this.downloadUrl(response.data.data[0].url)
                    this.setState({ isOrginalXmlLoader: false})
                },
                    (err) => {
                        console.log("Error in fetching Tenant Details:", err)
                    })
        }
    }


    downloadUrl = (url) => {
        if (url) {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const link = document.createElement("a");
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
        }
    };

    render() {
        return (
            <>
                {this.state.isFetchingData ? (
            <div className="ContentLoader">
                <CircularProgress />
            </div>
            ) : (
            <div id="Rapid-Cue-Acknowledgements">
                <MDBRow>
                    <MDBCol md={3} xl={3}>
                        <span className=' center fgreen f7'>{this.state.ackResponse?.file_name + ".xml"}</span>

                        <div className="center ack-xml-btn mt-2">
                            <BasicButton
                                text={this.state.isAckXmlLoader ? <CircularProgress color="inherit" size={20}/>: "View Ack. XML"}
                                variant="contained"
                                onClick={() => this.downloadXML(true)} />
                        </div>
                        <div className="center mt-2">
                            <BasicButton
                                text={this.state.isOrginalXmlLoader ? <CircularProgress color="inherit" size={20}/> : "View Original XML"}
                                variant="contained"
                                onClick={() => this.downloadXML(false)} />
                        </div>
                    </MDBCol>
                    <MDBCol md={9} className="border-left">
                        <span className='f-b'> Acknowledgement </span>
                        <MDBRow>
                            <MDBCol md={2}>
                                <span className='f-b'>Status</span><br />
                                <span className='f7'>{this.state.ackResponse?.ack_status}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Description</span><br />
                                <span className='f7'>{this.state.ackResponse?.ack_description}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Errors Exist</span><br />
                                <span className='f7'>{this.state.ackResponse?.ack_error_exists}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Warnings Exist</span><br />
                                <span className='f7'>{this.state.ackResponse?.ack_warning_exists}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Society Confirmed</span><br />
                                <span className='f7'>{this.state.ackResponse?.ack_society_confirmed}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Submitter</span><br />
                                <span className='f7'>{this.state.ackResponse?.ack_submitter}</span><br />
                            </MDBCol>
                        </MDBRow>
                        <span className='f-b'> Series </span>
                        <MDBRow>
                            <MDBCol md={2}>
                                <span className='f-b'>Status</span><br />
                                <span className='f7'>{this.state.ackResponse?.series_status}</span><br />
                            </MDBCol>
                            <MDBCol md={4}>
                                <span className='f-b'>Name</span><br />
                                <span className='f7'>{this.state.ackResponse?.series_name}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Submitter #</span><br />
                                <span className='f7'>{this.state.ackResponse?.series_submitter_number}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Creator ID</span><br />
                                <span className='f7'>{this.state.ackResponse?.series_creator_id}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'></span><br />
                                <span className='f7'></span><br />
                            </MDBCol>

                        </MDBRow>
                        <span className='f-b'> Episode </span>
                        <MDBRow>
                            <MDBCol md={2}>
                                <span className='f-b'>Status</span><br />
                                <span className='f7'>{this.state.ackResponse?.episode_status}</span><br />
                            </MDBCol>
                            <MDBCol md={4}>
                                <span className='f-b'>Name</span><br />
                                <span className='f7'>{this.state.ackResponse?.episode_name}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Submitter #</span><br />
                                <span className='f7'>{this.state.ackResponse?.episode_submitter_number}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'>Series Submitter #</span><br />
                                <span className='f7'>{this.state.ackResponse?.series_submitter_number}</span><br />
                            </MDBCol>
                            <MDBCol md={2}>
                                <span className='f-b'></span><br />
                                <span className='f7'></span><br />
                            </MDBCol>

                        </MDBRow>
                        <span className='f-b'> Errors/Warnings </span>
                        <MDBRow>
                            <MDBCol>
                    <span className='f7'>{this.state.ackResponse?.errors?.map((item,i) => <div>{i+1}. {item?.description}</div>)}</span><br />
                            </MDBCol>

                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={10}></MDBCol>
                    <MDBCol>
                        <BasicButton
                            text="Close"
                            variant="outlined"
                            onClick={this.props?.handleClose}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
            )}
            </>
        )
    }
}

)
