import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Tabs from '../../SharedComponents/Tabs/Tabs';
import { EditPayeeTabList } from '../../../constants/TabList';
import EditPayeeDetails from './EditPayeeDetails';
import ClearanceRecord from './ClearanceRecords';
import { withUserContext } from '../../../contexts/UserContext';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox'
import './EditPayee.scss';

export default withUserContext(class EditPayee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            usingOnly:false
        }
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    getTabComponent = (canEditPayeeInformation) => {
            switch (this.state.tabValue) {
                case 1: return <EditPayeeDetails disabled={canEditPayeeInformation ? canEditPayeeInformation?.edit === 0 : true} handleSubmit={this.props?.handleSubmit} fieldChanged={this.props?.fieldChanged} mode={this.props?.mode} getPayeeRemitTo={this.props?.getPayeeRemitTo} openFromDashboard={this.props?.openFromDashboard}
                    setPostFlag={this.props?.setPostFlag} handleClose={this.props?.handleClose} id={this.props?.id} notificationComponent={this.props?.notificationComponent} {...this.props} division_id={this.props?.division_id}/>
                case 2: return <ClearanceRecord id={this.props?.id} usingOnly={this.state.usingOnly} {...this.props}/>
            }
    }

    handleText = () => {
        switch (this.state.tabValue) {
            case 1: return "EditPayeeDetails"
            case 2: return "ClearanceRecords"
        }
    }

    handleSubmit = () => {
        switch (this.state.tabValue) {
            case 1: return this.props.EditPayeeDetails
            case 2: return this.props.ClearanceRecord
        }
    }

    render() {
        let canEditPayeeInformation = this.props?.commonPermissions?.find(item => item.permission_name === "Clearance Payees" || item.permission_name === "Show Composers/Music Supervisors (BA)")
        return (
            <MDBContainer fluid className="Project-detail-container">
                 {/* <div disabled = {!canEditLicensorInformation}> */}
                <MDBRow className="tab-content">
                    <MDBCol md={10}>
                        <Tabs
                            tabList={this.props?.mode === 'New' ? [{label: "Details",value: 1}] :EditPayeeTabList}
                            value={this.state.tabValue}
                            onChange={(e, v) => this.handleTabChange(v)}
                        />
                    </MDBCol>
                    <MDBCol md={2} className="edit-btn">
                        { this.state.tabValue === 2 &&
                            // this.state.tabValue === 1
                            //     ? <BasicButton
                            //         variant="contained"
                            //         type="inline"
                            //         color="primary"
                            //         text={"Get Info from Licensor"}
                            //     // icon={this.handleIcon()}
                            //     // onClick={this.handleSubmit()}
                            //     />
                            //     : 
                                    <BasicCheckbox
                                        label="Using Only"
                                        align="end"
                                        checked={this.state?.usingOnly}
                                        onChange={(e) => this.setState({usingOnly:e.target.checked})}
                                        id={'usingonlypayeeCLRecords'}
                                    />
                        }
                    </MDBCol>
                </MDBRow>
                <div >
                    {this.getTabComponent(canEditPayeeInformation)}
                    </div>
                    {/* </div> */}
            </MDBContainer>

        );
    }
})