export const builtLettersOptions = [
    {
        value: 1,
        label: "New Quote Request"
    },
    {
        value: 2,
        label: "New Confirmation",
    },
    {
        value:3,
        label: "New License Request"
    },
    {
        value: 4,
        label: "New License"
    },
    {
        value: 5,
        label: "New License Cover Letter"
    },
    {
        value: 6,
        label: "New Check Request"
    },
    {
        value: 7,
        label: "New Check Request AP"
    },
    {
        value: 8,
        label: "New Producer Memo"
    },
    {
        value: 9,
        label: "Edit Quote Request for Universal - S"
    },

]

export const licensorOptions = [
    {
        value: 1,
        label: "Universal - S"
    },
    {
        value: 2,
        label: "Universal - F"
    },
    {
        value: 3,
        label: "Universal - N"
    },
    {
        value: 4,
        label: "Universal - K"
    }
]
export const initialLettersData = {
    licensor_list:[]
}