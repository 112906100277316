import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';

class CueSheetEditDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false,
            cueSheetHeaderDetails: { ...this.props?.cueSheetHeaderDetails }
        }
    }

    handleOnChange = (field, value) => {
        this.setState(prevState => ({
            cueSheetHeaderDetails: {
                ...prevState.cueSheetHeaderDetails,
                [field]: value,
            },
        }));
    }

    handleSubmit = () => {
        let postJson = JSON.parse(JSON.stringify(this.state.cueSheetHeaderDetails));
        postJson.show_season_id = this.props?.show_season_id
        postJson.show_season_episode_id = this.props?.show_season_episode_id
        postJson.show_id = this.props?.show_id
        postJson.season_id = this.props?.season_id
        postJson.cue_sheet_header_id = this.props?.cue_sheet_header_id != "null" ? this.props.cue_sheet_header_id : null;
        postJson.airing = this.props?.cueSheetHeaderDetails?.airing  ? this.props?.cueSheetHeaderDetails.airing : "Initial"
        this.setState({ isSubmit: true })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodeCueSheetHeader`,
            postJson, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(
                (response) => {
                    if (response.data.error) {
                        this.setState({ isSubmit: false });
                        this.props?.notificationComponent('error')
                        this.props?.handleClose();
                    } else {
                        this.setState({ isSubmit: false });
                        this.props?.notificationComponent('success')
                        this.props?.handleClose();
                        this.props?.getCueSheetHeader(response.data[0].cue_sheet_header_id)
                    }
                },
                (err) => {
                    this.props?.notificationComponent('error')
                    this.setState({ isSubmit: false });
                }
            )
    }

    render() {
        return (
            <div>
                {this.props?.musicEditorView ?
                    <MDBContainer fluid className="cue-sheet-edit-detail-container" disabled={this.props?.disabledData}>
                        <MDBRow>
                            <MDBCol md={8} className="p-0 from-duration">
                                <BasicTextField
                                    id="time"
                                    label={"Time"}
                                    placeholder={"HH:MM XM - HH:MM XM"}
                                    value={this.state?.cueSheetHeaderDetails?.cue_sheet_time || ""}
                                    onChange={(e) =>
                                        this.handleOnChange("cue_sheet_time", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id={"showDateTime"}
                                label="Show Episode Date and Time on Cue Sheet"
                                checked={this.state?.cueSheetHeaderDetails?.is_show_episode_date}
                                onChange={(e) =>
                                    this.handleOnChange(
                                        "is_show_episode_date",
                                        e.target.checked ? 1 : 0
                                    )
                                }
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicTextArea
                                label="Notes"
                                value={this.state?.cueSheetHeaderDetails?.cue_sheet_notes || ""}
                                onChange={(e) => this?.handleOnChange('cue_sheet_notes', e.target.value)}
                            />
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={8}></MDBCol>
                            <MDBCol md={`${this.state.isSubmit ? 2 : 1}`} className="mr-1">
                                <BasicButton
                                    className={"modal-btn-save"}
                                    variant="contained"
                                    text={!this.state.isSubmit ? " " : <CircularProgress color="inherit" size={18} />}
                                    icon={!this.state.isSubmit ? "save" : " "}
                                    onClick={this.handleSubmit}
                                />
                            </MDBCol>
                            <MDBCol md={1}>
                                <BasicButton
                                    className={"modal-btn"}
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.props?.handleClose}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                    :
                    <MDBContainer fluid className="cue-sheet-edit-detail-container" disabled={this.props?.disabledData}>
                        <MDBRow>
                            <MDBCol md={8} className="p-0 from-duration">
                                <BasicTextField
                                    id="time"
                                    label={"Time"}
                                    placeholder={"HH:MM XM - HH:MM XM"}
                                    value={this.state?.cueSheetHeaderDetails?.cue_sheet_time || ""}
                                    onChange={(e) =>
                                        this.handleOnChange("cue_sheet_time", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id={"showDateTime"}
                                label="Show Episode Date and Time on Cue Sheet"
                                checked={this.state?.cueSheetHeaderDetails?.is_show_episode_date}
                                onChange={(e) =>
                                    this.handleOnChange(
                                        "is_show_episode_date",
                                        e.target.checked ? 1 : 0
                                    )
                                }
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id={"sendThrough"}
                                label="Send Through Rapid Cue"
                                checked={this.state?.cueSheetHeaderDetails?.is_send_through_rapid_cue}
                                onChange={(e) =>
                                    this.handleOnChange(
                                        "is_send_through_rapid_cue",
                                        e.target.checked ? 1 : 0
                                    )
                                }
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicTextArea
                                label="Notes"
                                value={this.state?.cueSheetHeaderDetails?.cue_sheet_notes || ""}
                                onChange={(e) => this?.handleOnChange('cue_sheet_notes', e.target.value)}
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicCheckbox
                                id={"override"}
                                label="Override Cue Sheet Episode/Heading"
                                checked={this.state.cueSheetHeaderDetails?.is_override_cue_sheet_episode}
                                onChange={(e) =>
                                    this?.handleOnChange(
                                        "is_override_cue_sheet_episode",
                                        e.target.checked ? 1 : 0
                                    )
                                }
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicTextField
                                id="episode"
                                label={"Episode"}
                                value={this.state?.cueSheetHeaderDetails?.cue_sheet_episodes || ""}
                                onChange={(e) =>
                                    this.handleOnChange("cue_sheet_episodes", e.target.value)
                                }
                                disabled={!this.state?.cueSheetHeaderDetails?.is_override_cue_sheet_episode || false}
                            />
                        </MDBRow>
                        <MDBRow>
                            <BasicTextField
                                id="heading"
                                label={"Heading"}
                                value={this.state?.cueSheetHeaderDetails?.cue_sheet_heading || ""}
                                onChange={(e) =>
                                    this.handleOnChange("cue_sheet_heading", e.target.value)
                                }
                                disabled={!this.state?.cueSheetHeaderDetails?.is_override_cue_sheet_episode || false}
                            />
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={8}></MDBCol>
                            <MDBCol md={`${this.state.isSubmit ? 2 : 1}`} className="mr-1">
                                <BasicButton
                                    className={"modal-btn-save"}
                                    variant="contained"
                                    text={!this.state.isSubmit ? " " : <CircularProgress color="inherit" size={18} />}
                                    icon={!this.state.isSubmit ? "save" : " "}
                                    onClick={this.handleSubmit}
                                />
                            </MDBCol>
                            <MDBCol md={1}>
                                <BasicButton
                                    className={"modal-btn"}
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.props?.handleClose}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>}
            </div>
        )
    }
}

export default withUserContext(CueSheetEditDetails);