import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import './SideNav.scss';
import '../../../src/index.scss';
import {MDBNavbarNav, MDBNavbarLink, MDBNavbarItem  } from "mdb-react-ui-kit";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle,MDBDropdownItem, MDBDropdownLink } from 'mdb-react-ui-kit';
import { withUserContext } from '../../contexts/UserContext';
import { navTopMenus} from './config';
import {  withRouter } from 'react-router-dom';

let commonPermissions = [], selectedItems = new Set();
export default withRouter(withOktaAuth(withUserContext(class SideNav extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isDashboard: false,
            isDataLookups: false,
            isViews: false,
            isAdminSetup: false
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.fetchPermissions();
        this.menuPermissionExists();
    }

    componentWillMount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {
        this.fetchPermissions();
        this.menuPermissionExists();
    }

    gotoMainContent = (selection) => {
        if (selection.id === 15) {
            this.props?.openCueSheetModal()
        }else if (selection.id === 14) {
            this.props?.openCostAllocationModal()
        }
        else {
            this.props.history.push('/');
            this.props.userContext.handleSideNavActiveMain(selection.id, selection.label, selection.sectionName)
        }
    }

    fetchPermissions = () => {
        if (this.props?.userContext?.active_tenant?.common_permissions?.length > 0) {
            commonPermissions = this.props?.userContext?.active_tenant?.common_permissions;
        }
    }
    
    
    routeToDashboard = () => {
        let default_home_page = this.props.userContext.active_tenant.default_home_page;

        let dashboardName = this.props.userContext.active_tenant.default_home_page;//default_home_page === "Show/Season tab" ? "Show Seasons" : default_home_page === "Clearance Records" ? "Clearance Records" : default_home_page === "Songs" ? "songLibrary" : default_home_page === "Licensors" ? "Licensors" : default_home_page === "Payees" ? "payees" : default_home_page === "Cue Sheets" ? "cueSheets" : default_home_page === "Music Editors Show Composers" ? "musicEditor" : default_home_page === "TV Creative Music Budget" ? "budget" : default_home_page === "Reports" ? "reports" :default_home_page === "People" ? "people": null;
        let sectionName = default_home_page === "Show Seasons"  ? "showSeasons" : default_home_page === "Clearance Records" ? "clearanceRecords" : default_home_page === "Songs" ? "songLibrary" : default_home_page === "Licensors" ? "licensors" : default_home_page === "Payees" ? "payees" : default_home_page === "Cue Sheets" ? "cueSheets" : default_home_page === "Music Editors Show Composers" ? "musicEditor" : default_home_page === "TV Creative Music Budget" ? "budget" : default_home_page === "Reports" ? "reports" :default_home_page === "People" ? "people": null;
        let dashboardId = default_home_page === "Show Seasons"  ? 1 : default_home_page === "Clearance Records" ? 2 : default_home_page === "Songs" ? 3 : default_home_page === "Licensors" ? 4 : default_home_page === "Payees" ? 6 : default_home_page === "Cue Sheets" ? 7 : default_home_page === "Music Editors Show Composers" ? 8 : default_home_page === "TV Creative Music Budget" ? 9 : default_home_page === "Reports" ? 12 : default_home_page ==="People" ?11 :null;
        this.props.userContext.handleSideNavActiveMain(dashboardId, dashboardName, sectionName)
        this.props.handleDrawerClose()
    }
    renderDropDownItems = (itemSelected) => {
        selectedItems = new Set();
        itemSelected.menuItems.map((mItem) => {
            commonPermissions.map((lItem) => {
                if((mItem.permissionNames.includes(lItem.permission_name) && lItem.view === 1) ){
                    selectedItems.add(mItem)
                }
            })
        })
       return selectedItems.size > 0? true: false;
    }

    menuPermissionExists = () => {
        navTopMenus.map((item) => {
                commonPermissions?.map((per) => {
                    item.menuItems?.some((itemPerm) => {
                        if((itemPerm.permissionNames?.includes(per.permission_name) && per.view === 1)){
                            (item.permissionGroupName === "Dashboards")?
                            this.setState({ isDashboard: true}):(item.permissionGroupName === "Data Lookups")? this.setState({isDataLookups: true}): (item.permissionGroupName === "Views")? this.setState({isViews:true}):(item.permissionGroupName === "Admin Setup")? this.setState({isAdminSetup :true}):null;
                            return true;
                        }else{
                            return false;
                        }
                    })
                })
        })
    }

    render() {
        return (
             <div className='ms-auto'>
                 <MDBNavbarNav className='mb-2 mb-lg-0'>
                        {navTopMenus.map((item) =>                                                    
                            <MDBNavbarItem >
                                {(item.type==="navDropdown"  && (this.state.isAdminSetup || this.state.isDataLookups || this.state.isViews ||this.state.isDashboard) )?
                                    <MDBDropdown>
                                        <MDBDropdownToggle tag='a' className='nav-link' >
                                            {item.label}
                                        {/* {this.state.isAdminSetup && item.label === "Admin Setup" ? item.label : this.state.isDataLookups && item.label === "Data Lookups" ? item.label : this.state.isViews && item.label === "Views" ? item.label : this.state.isDashboard && item.label === "Dashboard"? item.label : null} */}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                        {this.renderDropDownItems(item) ? 
                                        Array.from(selectedItems).map((value) => 
                                            <MDBDropdownItem><MDBDropdownLink  className={"onHover"} onClick={() => this.gotoMainContent(value)}> {value.label}</MDBDropdownLink></MDBDropdownItem>
                                        ) 
                                        :null
                                        }   
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                : <MDBNavbarLink className={"onHover"} onClick={() => this.gotoMainContent(item)}>{item.label}</MDBNavbarLink>
                                }
                            </MDBNavbarItem>
                            
                        )};
                   </MDBNavbarNav>                
             </div>
        );
    }
}
)));