export const initialCheckRequestConfig = {
    "clearance_check_request_id": null,
    "payee_remit_to_id": null,
    "payee_contact_name": null,
    "lic_per": null,
    "share": null,
    "init_fee": null,
    "requested_date": null,
    "inv": null,
    "notes": null,
    "in_batch": null,
    "check": null,
    "date_paid": null,
    "is_exclude_batch": 0,
    "is_void": 0,
    "is_amort": 0,
    "is_active": 1,
    "is_delete": 0
}

export const initialOptionalRightsConfig = {
    "clearance_licensor_optional_rights_id": null,
    "right_id": null,
    "fee": null,
    "term_id": null,
    "territory_id": null,
    "opt_exp_term_id": null,
    "is_option_acquired": null,
    "option_acquired_date": null,
    "incl_init": false,
    "is_disallow_acquire": 0,
    "is_exclude_letters": 0,
    "is_exclude_license": 0,
    "is_active": 1,
    "is_delete": 0,
    "is_licensor_optional_check_requests_updated": 0,
    "clearance_licensor_optional_check_requests": []
}

export const initialOptionalCheckRequestConfig = {
    "clearance_licensor_optional_check_requests_id": null,
    "clearance_licensor_optional_rights_id": null,
    "payee_remit_to_id": null,
    "lic_per": null,
    "share": null,
    "fee": null,
    "requested_date": null,
    "inv": null,
    "notes": null,
    "in_batch": null,
    "check": null,
    "date_paid": null,
    "is_exclude_batch": 0,
    "is_void": 0,
    "is_amort": 0,
    "is_active": 1,
    "is_delete": 0
}

export const optionalRightsConfig = {
    headings: [
        {
            headingLabel: "Option",
            inputType: "select",
            icon: "",
            border: true,
            width: "20%",
            selectOptions: []
        },
        {
            headingLabel: "Fee",
            inputType: "text",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "Term",
            inputType: "select",
            border: true,
            width: "10%",
            selectOptions: []
        },
        {
            headingLabel: "Territory",
            inputType: "select",
            border: true,
            width: "15%",
            selectOptions: [],
        },
        {
            headingLabel: "Opt Exp",
            inputType: "select",
            border: true,
            width: "10%",
            selectOptions: []
        },
        {
            headingLabel: "Acquired",
            inputType: "",
            border: true,
            width: "9%"
        },
        {
            headingLabel: "Init.",
            inputType: "checkbox",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "CR",
            inputType: "checkbox",
            border: true,
            width: "13%"
        },
    ],
    dataNodes: ["right_id", "fee", "term_id", "territory_id", "opt_exp_term_id", "option_acquired_date", "incl_init", "bch"],
    primaryKey: ["clearance_licensor_optional_rights_id"],
    nodeFields: [
        {
            column: "incl_init",
            icon: "check"
        },
        // {
        //     column: "is_cr",
        //     icon: "check"
        // },
        {
            column: "bch",
            icon: "money-check"
        },
    ],
    buttonFields: [
        {
            column: "option_acquired_date",
            icon: "",
            showDataNodeValue: true
        }
    ],
    // commented by nehal for hiding cross icon on view mode and adding dynamically when mode changed
    // actions: ["arrows-alt-v", "times"],
    actions: ["arrows-alt-v"],
    isClearFields: true,
    inlineEdits: [
        {
            dataNode: "right_id",
            width: "18.9%",
            fieldDisabled: true
        },
        {
            dataNode: "fee",
            width: "6.4%",
            inlineIcon: "$",
            validateMethod:"validateNumDotWith3Decimals",
            validateMessage : 'Please enter numbers',
            fieldDisabled: true
        },
        {
            dataNode: "term_id",
            width: "9.3%",
            fieldDisabled: true
        },
        {
            dataNode: "territory_id",
            width: "14.1%",
            fieldDisabled: true
        },
        {
            dataNode: "opt_exp_term_id",
            width: "9.2%",
            fieldDisabled: true
        },
        {
            dataNode: "",
            width: "8.8%"
        },
        {
            dataNode: "incl_init",
            width: "6.4%",
            fieldDisabled: true
        },
        {
            dataNode: "is_cr",
            width: "13.8%",
            fieldDisabled: true
        }
    ],
};
export const optionalRightsList = [
    {
        id: 1,
        Option: "6 YRS HOME VIDEO 06/07",
        Fee: "$1500.00",
        Term: "1 year",
        Territory: "WW",
        OptExp: "1 year",
        Acquired: "MM/DD/YYYY ",
        Init: " ",
        CR: " "
    },
    {
        id: 2,
        Option: "6 YRS HOME VIDEO 06/07",
        Fee: "$1500.00",
        Term: "1 year",
        Territory: "WW",
        OptExp: "1 year",
        Acquired: "MM/DD/YYYY ",
        Init: " ",
        CR: " "
    },
]

export const editCueSheetPublisherInfo = {
    "editCueSheetPublisherInfo_id": 1,
    "publisher_options": null,
    "affiliation_options": null,
    "share": null,
    "publisher_name": 0,
    "inactive": 0,
}

export const publisherOptions = [
    { "label": "publisher 1", "value": 1 },
    { "label": "publisher 2", "value": 2 },
    { "label": "publisher 3", "value": 3 },
    { "label": "publisher 4", "value": 4 }
]

export const affiliationOptions = [
    { "label": "affiliation 1", "value": 1 },
    { "label": "affiliation 2", "value": 2 },
    { "label": "affiliation 3", "value": 3 },
    { "label": "affiliation 4", "value": 4 }
]

export const initialCueSheetConfig = {
    
    "song_id": null,
    "licensor_id": null,
    "cue_sheet_publishers": []
}

export const publisherConfig = {
	song_cue_sheet_publisher_id: null,
    cue_sheet_publisher_id: null,
    affiliation_id: null,
    share: null,
    is_active: 0,
}

export const cueSheetModalConfig = {
    "cue_sheet_publisher_name":null
}
