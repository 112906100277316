import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TableComponent from "../SharedComponents/Table";
import ClearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import {withUserContext} from '../../contexts/UserContext'
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class Territories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      isSubmit: false,
      isLoading:false,
      mandatoryNotExist:false,
      sortBy:null,
      isSortByAsc: true,
      sortCount: 0,
      config: JSON.parse(JSON.stringify({ ...this.props?.config }))
    };
  }
  componentDidMount() {
    this.getTerritoryDetails("TERRITORY", "");
  }
  getTerritoryDetails = (territoryData, searchString, loader = true) => {
    this.setState({isLoading:loader})
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${territoryData}&searchString=${searchString}`,
        this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let territoryDetails = [...res.data];
        let configRecord = {...this.state.config}
        this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
        this.setState({ renderList: territoryDetails,isLoading:false,isSubmit:false ,sortBy:null,
          isSortByAsc: true, config: configRecord,
          sortCount: 0,}, () => {this.handleTableColumnClick("Territory Abbreviation")});
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({isLoading:false})
      });
  };

  handleInlineEdits = (id, dataItem) => {
    let territories = [...this.state.renderList];
    this.setState({
      mandatoryNotExist:false,
      renderList: territories.map((item) => {
        let newItem = {...item}
        if (item.territory_id === id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({mandatoryNotExist : false })
    if(data[index]?.territory_abbr && data[index]?.territory_abbr?.length > 0  && data[index]?.full_description && data[index]?.full_description?.length > 0 ) {
        let territoryData = [...data];
        let territoryDetails = [...this.state.renderList];
        territoryDetails[index].territory_abbr =
          territoryData[index]?.territory_abbr || null;
        territoryDetails[index].full_description =
          territoryData[index]?.full_description || null;
        territoryDetails[index].is_active = territoryData[index]?.is_active ? 1 : 0;
        territoryDetails[index].is_one_off_territory = territoryData[index]
          ?.is_one_off_territory
          ? 1
          : 0;
        this.setState({ renderList: territoryDetails });
        this.postTerritoryDetails(territoryDetails[index],territoryDetails[index]?.territory_id);
    } else {
        this.setState({mandatoryNotExist : true })
    }
  };


  postTerritoryDetails = (territoryDetails,postFlag) => {
    this.setState({ isSubmit: postFlag });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/territory`,
      territoryDetails,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false });
          this.props?.toastMessageNotification(true, "fail");
          this.getTerritoryDetails("TERRITORY", "");
        } else {
         // this.setState({
           // isSubmit: false,
         // });
          if (territoryDetails?.editing) {
            territoryDetails.editing = false;
          }
          this.props?.toastMessageNotification(true, "success");
          this.getTerritoryDetails("TERRITORY", "",false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props?.toastMessageNotification(true, "fail");
        console.log("Post Territory details error: " + err);
      }
    );
  };
  handleAddNewItem = (newDataItem) => {
    this.setState({mandatoryNotExist : false })
    if(newDataItem?.territory_abbr && newDataItem?.territory_abbr?.length > 0 ) {
    let newTerritoryData = {
      territory_abbr: newDataItem?.territory_abbr || null,
      full_description: newDataItem?.full_description || null,
      is_active: 1,
      is_one_off_territory: newDataItem?.is_one_off_territory ? 1 : 0,
    };
    // let territoryDetails = [...this.state.renderList];
    // territoryDetails.push(newTerritoryData);
    this.postTerritoryDetails(newTerritoryData,'newItemPost');
    //this.setState({ renderList: territoryDetails });
  } else {
    this.setState({mandatoryNotExist : true })
    }
  };
  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Territory Abbreviation": return "territory_abbr";
      case "Full Description": return "full_description";
      case "Active": return "is_active";
      case "One-off Territory": return "is_one_off_territory";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="TerritoriesContainer">
        <MDBRow>
          <span className="description">
            Define selectable territories for Clearance and MT Rights.
          </span>
        </MDBRow>
        <MDBRow className="pd">
        <span className="errorText">{this.state.mandatoryNotExist && 'Please enter Territory Abbreviation field value'}</span>
        </MDBRow>
        {this.state.isLoading ? <div className="ContentLoader">
          <CircularProgress />
      </div> : <MDBRow className="TerritoriesTable">
          <TableComponent
            newIsActive={true}
            list={this.state.renderList || []}
            config={this.state.config}
            handleAddNewItem={this.handleAddNewItem.bind(this)}
            editCallback={this.handleInlineEdits.bind(this)}
            saveCallback={this.saveInlineEdits.bind(this)}
            addItemToList={permissionValue?.edit === 1}
            postInitiated={this.state.isSubmit}
           // isLoading={this.state.isSubmit}
            readCallback={(index) => {
              let territoryOptions = [...this.state.renderList];
              territoryOptions[index].editing = false
              this.setState({ renderList: territoryOptions, mandatoryNotExist:false });
            }}
            stickyHeader={true}
            sortCallback={this.handleTableColumnClick}
          />
        </MDBRow>}
      </MDBContainer>
    );
  }
}

export default withUserContext(Territories);