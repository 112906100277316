import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasicCheckbox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import BasicTextArea from '../../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../../../SharedComponents/SelectField/SelectField';

class NotesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lic: props?.lic
        }
    }

    componentDidMount() {
        if (this.props?.notesValue) {
            let lic = {
                notes: this.props?.notesValue
            }
            this.setState({ lic: lic })
        }
    }

    handleChange(field, value) {
        this.setState(prevState => ({
            lic: {
                ...prevState.lic,
                [field]: value
            }
        }))
    }

    saveData() {
        this.props?.onChangeCallback(this.props?.field || 'notes', this.state?.lic?.notes)
        this.props.onClose()
    }
    
    render() {
        return (
            <MDBContainer>
                {!this.props.epsCountFlag ?
                    <div>
                        <MDBRow>
                            <BasicLabel text={"Notes"}></BasicLabel>
                        </MDBRow>
                        <MDBRow>
                            {this.props?.isEditMode ?
                                <BasicTextArea
                                    value={this.state?.lic?.notes}
                                    onChange={((this.props.field == "inv_notes" || this.props.field == "cr_notes") && this.props.initialRightsCheckRequestsPermission?.edit == 1) || ((this.props.field == "op_inv_notes" || this.props.field == "op_notes")  && this.props.optionalRightsCRPermissions?.edit == 1) || (this.props.field == "notes" && this.props?.licensorNotesField?.edit == 1 ) ? (e) => this.handleChange('notes', e?.target?.value) : null }
                                /> :
                                <p className="notes-text">{this.state?.lic?.notes}</p>
                            }
                        </MDBRow>
                        {this.props?.isEditMode &&
                            <MDBRow>
                                <MDBCol className="display-modal-2">
                                    <BasicButton
                                        className={"modal-btn-save"}
                                        variant="contained"
                                        text=" "
                                        icon={"save"}
                                        onClick={this.saveData.bind(this)}
                                    />
                                    <BasicButton
                                        className={"modal-btn"}
                                        variant="outlined"
                                        text=" "
                                        icon={"times"}
                                        onClick={this.props.onClose}
                                    />
                                </MDBCol>
                            </MDBRow>
                        }
                    </div>
                    : <div>
                        <SelectField
                            label={"Select the Episodes this check is paying for"}
                            // value={}
                            // onChange={}
                        />
                         <MDBRow>
                                <MDBCol md={3}></MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={9}></MDBCol>
                                <MDBCol md={3}>
                                    <MDBRow >
                                        <MDBCol className="display-modal-2">
                                            {this.props?.isEditing &&
                                                <BasicButton
                                                    className={"modal-btn-save"}
                                                    variant="contained"
                                                    text=" "
                                                    icon={"save"}
                                                    // onClick={() => this.handleClick('check-request')}
                                                />
                                            }
                                            <BasicButton
                                                className={"modal-btn-save"}
                                                variant="outlined"
                                                text=" "
                                                icon={"times"}
                                                // onClick={this.props.handleOnClose}
                                            />
                                        </MDBCol>

                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                    </div>
                }
               
            </MDBContainer >
        )
    }
}

export default NotesModal;
