import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { CircularProgress } from "@material-ui/core";
import { programsConfig } from './config';
import { withUserContext } from "../../contexts/UserContext";
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { updateTableSortConfigObject, tableSortList, handlePaginationList } from '../../Common/TableHelper';
import TablePagination from '@material-ui/core/TablePagination';
import axios from 'axios';

class Programs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOptions: [],
            optionsName: [],
            results: [],
            showType: [],
            programsConfig: JSON.parse(JSON.stringify({ ...programsConfig })),
            isLoading: false,
            selectedShow:null,
            isSubmit: false,
            mandatoryNotExist: false,
            openImportSeason: false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            rowsPerPage: 10,
            page: 0,
            searchCancelToken : null,
        }
    }
    componentDidMount() {
        this.getShowList("showOptions", "SHOW", "");
        this.geShowTypeDetails();
    }

    getShowList = (entity, entitytype, searchString, loader = true) => {
        this.setState({ isLoading: loader });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id)
            .then((res) => {
                let configRecord = {...this.state.programsConfig}
                this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
                this.setState({
                    showOptions: res.data, isLoading: false, isSubmit: false, sortBy: null,
                    currentList: res.data,
                    programsConfig: configRecord,
                    isSortByAsc: true,
                    selectedShow:null,
                    page:0,
                    sortCount: 0,
                    optionsName:[],
                }, () => { this.handleTableColumnClick("Program Name"); });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            showOptions: handlePaginationList(newPage, this.state.rowsPerPage, this.state.currentList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            showOptions: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }

    handleSelectedShow = (newValue) => {
        this.setState({selectedShow : newValue})
            if(newValue) {
                let selectedShow = this.state.currentList?.find(item => item?.show_id === newValue?.value)
                this.setState({
                    showOptions : [selectedShow],
                    page:0,
                })
            } else {
                this.getShowList("showOptions", "SHOW", "");
            }
    };

    fetchSearchDetails = (event) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        if (event.target.value !== "") {
            ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
                `/entitySearch?entity=${"SHOW"}&searchString=${event.target.value}`,cancelToken ,this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let formattedList = response?.data?.map(item => ({ value: item.show_id, text: item.show_name }));
                    this.setState({ optionsName: formattedList });
                },
                    (err) => {

                    })
        } else {
            this.setState({ optionsName: [] });
        }
    }

    geShowTypeDetails = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            '/staticData?staticTable=division', this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let allowedDivisions = this.props?.userContext?.active_tenant?.division_role?.map(item => item?.division_id)
                let useList = response?.data?.filter(ele => allowedDivisions?.includes(ele?.id))
                let programConfig = { ...this.state.programsConfig };
                programConfig.headings[3].selectOptions = useList?.map(item => ({
                    label: item.name,
                    value: item.id,
                }));
                this.setState({ programsConfig: programConfig })
            }
            )
    }

    postShowProgramsDetails = (showProgramDetails, postFlag) => {
        this.setState({ isSubmit: postFlag });
        ClearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/show`,
            showProgramDetails,
            this.props.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false });
                    this.props?.toastMessageNotification(true, "fail");
                    this.getShowList("showOptions", "SHOW", "");
                } else {
                    //this.setState({
                    //isSubmit: false,
                    //});
                    if (showProgramDetails?.editing) {
                        showProgramDetails.editing = false;
                    }
                    this.props?.toastMessageNotification(true, "success");
                    this.getShowList("showOptions", "SHOW", "", false);
                    this.geShowTypeDetails();
                }
            },
            (err) => {
                this.setState({ isSubmit: false });
                this.props?.toastMessageNotification(true, "fail");
                console.log("Post showProgramDetails Details error: " + err);
            }
        );
    };

    handleInlineEdits = (id, dataItem) => {
        let programs = [...this.state.showOptions]
        this.setState({
            mandatoryNotExist: false,
            showOptions: programs.map((item) => {
                let newItem = {...item}
                if (item.show_id == id) {
                    newItem['editing'] = true;
                }
                else {
                    newItem['editing'] = false;
                }
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({ mandatoryNotExist: false })
        if (data[index]?.show_name && data[index]?.show_name?.length > 0 && data[index]?.division_id) {
            let showPrograms = [...data];
            let showProgramsDetails = [...this.state.showOptions];
            showProgramsDetails[index].show_id = showPrograms[index]?.show_id;
            showProgramsDetails[index].show_name = showPrograms[index]?.show_name || null;
            showProgramsDetails[index].division_id = showPrograms[index]?.division_id;
            showProgramsDetails[index].synopsis = showPrograms[index]?.synopsis || null;
            showProgramsDetails[index].is_active = showPrograms[index]
                ?.is_active ? 1 : 0;
            showProgramsDetails[index].is_internet_show = showPrograms[index]
                ?.is_internet_show ? 1 : 0;
            showProgramsDetails[index].is_pilot = showPrograms[index]
                ?.is_pilot ? 1 : 0;
            this.setState({ showOptions: showProgramsDetails, programsConfig: { ...programsConfig }, });
            this.postShowProgramsDetails(showProgramsDetails[index], showProgramsDetails[index]?.show_id);
        } else {
            this.setState({ mandatoryNotExist: true })
        }
    };

    handleAddNewItem = (newDataItem) => {
        this.setState({ mandatoryNotExist: false })
        if (newDataItem?.show_name && newDataItem?.show_name?.length > 0 && newDataItem?.division_id) {
            let newshowProgramData = {
                show_id: newDataItem?.show_id,
                show_name: newDataItem?.show_name || null,
                division_id: newDataItem?.division_id,
                is_active: 1,
                is_internet_show: newDataItem?.is_internet_show ? 1 : 0,
                is_pilot: newDataItem?.is_pilot ? 1 : 0,
                synopsis: newDataItem?.synopsis || null
            };
            // let showProgramDetails = [...this.state.showOptions ];
            // showProgramDetails.push(newshowProgramData);
            this.postShowProgramsDetails(newshowProgramData, 'newItemPost');
            //this.setState({ showOptions: showProgramDetails });
        } else {
            this.setState({ mandatoryNotExist: true })
        }
    };

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            sortBy: colName
        }, () => {
            this.setState({
                config: updateTableSortConfigObject(this.state.programsConfig, this.state.isSortByAsc, this.state.sortBy, colName),
                showOptions: tableSortList("Name", this.getSortNode(colName), this.state.currentList, this.state.isSortByAsc)
            }, () => {
                this.setState({
                    showOptions: handlePaginationList(this.state.page, this.state.rowsPerPage, this.state.showOptions)
                })
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Program Name": return "show_name";
            case "Show Type": return "division_id";
            case "Synopsis": return "synopsis";
            case "Active": return "is_active";
            case "Internet Show": return "is_internet_show";
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="ProgramsContainer">
                <MDBRow>
                    <span className="description">
                        Define all Programs, including their Division, Synopsis, and visibility in the system.
                    </span>
                </MDBRow>
                    <MDBRow>
                        <div className="search-program display-flex field-style">
                            <SearchSelectField
                                id={"program"}
                                placeholder={'- Search Program by Name -'}
                                options={this.state.optionsName || []}
                                width={'100%'}
                                detail_selected={this.state.selectedShow}
                                valueSelected={(e,value) => this.handleSelectedShow(value)}
                                searchText={this.fetchSearchDetails}
                                multiple={false}
                                searchSelect={true}
                            />
                            {permissionValue?.edit === 1 &&
                            <BasicButton
                                variant="contained"
                                text="Import from Excel"
                                onClick={(e) => {
                                    this.setState({ openImportSeason: true })
                                }}
                            />}
                        </div>
                    </MDBRow>
                <MDBRow className='pd'>
                    <span className="errorText">{this.state.mandatoryNotExist && 'Please enter Show name, showType & Synopsis fields value'}</span>
                </MDBRow>
                {this.state.isLoading ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                    <MDBRow >
                        <div className="ProgramsTable">
                            <TableComponent
                                newIsActive={true}
                                list={this.state.showOptions || []}
                                config={this.state.programsConfig}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1}
                                postInitiated={this.state.isSubmit}
                                // isLoading={this.state.isSubmit}
                                readCallback={(index) => {
                                    let showOptions = [...this.state.showOptions];
                                    showOptions[index].editing = false
                                    this.setState({ showOptions: showOptions, mandatoryNotExist: false });
                                }}
                                stickyHeader={true}
                                sortCallback={this.handleTableColumnClick}
                            />
                            {!this.state.selectedShow && <TablePagination
                                component="div"
                                count={this.state.currentList ? this.state.currentList?.length : 0}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />}
                        </div>
                    </MDBRow>
                )}
                {
                    this.state.openImportSeason &&
                    <MainViewModalComponent
                        open={this.state.openImportSeason}
                        handleClose={() => this.setState({ openImportSeason: false })}
                        headerText={"Import Season"}
                        mode={""}
                        modalName={"Import Season"}
                        handleSubmit={() => this.getShowList("showOptions", "SHOW", "")}
                    />
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(Programs);