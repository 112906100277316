import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./PublicDomainReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import ClearTrackService from '../../../services/service';
import { s2ab, genReportName } from '../../../Common/Helper';
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialPublicDomainDetails,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";

class PublicDomainReport extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      selectedValue: null,
      publicDomainDetails: initialPublicDomainDetails,
      allGenresOptions: [],
      composerOptions: [],
      filterValues: [],
      filterGenreIdValues: [],
      filterComposerIdValues: [],
      backUpComposerOptions: []
    };
  }

  componentDidMount() {
    // this.fetchPerformerData();
    this.getGenreList();
  }

  getGenreList = () => {
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/staticData?staticTable=lu_public_domain_genre`,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response?.data?.map((item) =>
        ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }));
        this.setState({ allGenresOptions: formattedList, backUpGenreOptions: formattedList });

      },
        (err) => {
          console.log("Error in fetching Static Data:", err)
          this.setState({ isFetchingClearanceDetails: false });
        }
      );
  }

  // fetchShowsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id, 1)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name }));
  //       this.setState({ allGenresOptions: formattedList }, () => { console.log(this.state.showOptions) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  handleClearFilters = () => {
    let values = this.state.filterValues;
    values = [];
    this.setState({ filterValues: values,  publicDomainDetails: {...initialPublicDomainDetails}, filterGenreIdValues: [], filterComposerIdValues: []  });
  }

  handleSubmitPublicDomainReport = () => {
    this.setState({ postInitiated: true });
    let { publicDomainDetails, filterGenreIdValues, filterComposerIdValues } = this.state;
    this.setState({ isPosting: true })
    let payload = {
      "genres_ids": filterGenreIdValues || null,
      "include_composer_ids": publicDomainDetails.selectedIncludedValue === "include_composer_ids" ? filterComposerIdValues : null,
      "exclude_composer_ids": publicDomainDetails.selectedIncludedValue === "exclude_composer_ids" ? filterComposerIdValues : null,
      "selected_composers": filterComposerIdValues,
      "group_by_genre": publicDomainDetails.group_by_genre ? 1 : 0,
      "show_display_criteria": publicDomainDetails.show_display_criteria ? 1 : 0,
      "song_title_only": publicDomainDetails.song_title_only ? 1 : 0,
      "is_all_genre":  publicDomainDetails.selectedValue === "all_of_the_selected_genres"  ? 1 : 0,
      "is_any_genre": publicDomainDetails.selectedValue === "any_of_the_selected_genres" ? 1 : 0
    }
    this.props.generateReport(this.props?.reportName, payload, "PublicDomain").then(webSocketConn => {
      webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data)
        if (response?.statusCode == 200) {
          this.setState({ isPosting: false })
          this.props?.downloadUrl(response?.body);
          this.props?.notificationComponent(true, 'success')
        } else {
          this.props?.notificationComponent(true, 'fail')
        }
      }
    })
  }

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      publicDomainDetails: {
        ...prevState.publicDomainDetails,
        [field]: value,
      },
    }));
  };

  onGenreChange = (val, frstVal = null, label = null) => {
    let filterValues = this.state.filterValues;
    let filterGenreIdValues = this.state.filterGenreIdValues;
    filterValues.push("Genre" + ": " + (label !== null ? label : val?.currentTarget.innerText));
    filterGenreIdValues.push(frstVal ? frstVal : val?.target.value)
    let allGenresOptions = [...this.state.allGenresOptions];
    let updatedOptions = allGenresOptions.filter(item => item.value !== val?.target.value)
    this.setState({
      filterValues: filterValues,
      filterGenreIdValues: filterGenreIdValues,
      allGenresOptions: updatedOptions
    }, () => {
      console.log("filtervalues", this.state.filterValues, this.state.filterGenreIdValues, this.state.allGenresOptions)
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=COMPOSERS_BY_GENRES&searchString=` + this.state.filterGenreIdValues,
        this.props?.userContext?.active_tenant?.tenant_id, 1)
        .then(response => {
          let formattedList = response.data?.map(item => ({ value: item.pd_composer_id, label: item.pd_composer_name }));
          this.setState({ composerOptions: formattedList, backUpComposerOptions: formattedList });
        },

      (err) => {
        console.log("Error in fetching Address Types:", err);
        this.setState({ isFetchingClearanceDetails: false });
      })
  })
}

onComposerChange = (val, frstVal = null, label = null) => {
  console.log("val", val, val?.target.value)
  let filterValues = this.state.filterValues;
  filterValues.push("Composer" + ": " + val?.currentTarget.innerText)
  let filterComposerIdValues = this.state.filterComposerIdValues;
  filterComposerIdValues.push(val?.target.value)
  let composerOptions = [...this.state.composerOptions];
  let filteredComposerOptions = composerOptions.filter(item => item.value !== val?.target.value)
  this.setState({ filterValues, filterComposerIdValues, composerOptions: filteredComposerOptions })
}

onChangeCallBack = (field, val, eventValue, data) => {
  let newValue = val.filter(
    (value, index, arr) =>
      index === arr.findIndex((item) => item.value === value.value)
  );
  let modifiedValue = newValue?.map((item) => ({
    value: item.value,
    text: item.text,
  }));
  if (field === "composerList") {
    this.handleChange("composerList", modifiedValue);
  }
}



handleFilterDelete = (removeNode, index) => {
  let filterValues = this.state.filterValues;
  let removedItem = filterValues.splice(index, 1);
  this.setState({ filterValues: filterValues, removedItem: removeNode }, () => {
    let splitVal = this.state.removedItem.split(":");
    let name = splitVal[0].trim();
    let valueId = splitVal[1].trim();
    if (name === "Genre") {
      let valueToBeRemoved = this.state.backUpGenreOptions.find(item => item.label === valueId)?.value
      let valueToBeAdded = this.state.backUpGenreOptions.find(item => item.label === valueId)
      let filterGenreIdValues = [...this.state.filterGenreIdValues];
      let index = filterGenreIdValues.indexOf(valueToBeRemoved)
      filterGenreIdValues.splice(index, 1)
      let allGenresOptions = [...this.state.allGenresOptions];
      allGenresOptions.push(valueToBeAdded)
      this.setState({ filterGenreIdValues, allGenresOptions }, () => {
        console.log("filtervaluesDelete", this.state.filterValues, this.state.filterGenreIdValues)
      })
    }
    else if (name === "Composer") {
      let valueToBeRemoved = this.state.backUpComposerOptions.find(item => item.label === valueId)?.value
      let valueToBeAdded = this.state.backUpComposerOptions.find(item => item.label === valueId)
      let filterComposerIdValues = [...this.state.filterComposerIdValues];
      let index = filterComposerIdValues.indexOf(valueToBeRemoved)
      filterComposerIdValues.splice(index, 1)
      let composerOptions = [...this.state.composerOptions];
      composerOptions.push(valueToBeAdded)
      this.setState({ filterComposerIdValues, composerOptions }, () => {
        console.log("filtervaluesDelete", this.state.filterValues, this.state.filterComposerIdValues)
      })
    }
  })
}

handleSubmit = () => {
  console.log("submit", this.state.publicDomainDetails);
};

GenerateCleranceDetails = () => { };
render() {
  return (
    <>
      {" "}
      <p>
        <b>Public Domain Report</b>
      </p>
      <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
        <div style={{ display: "flex" }}>
          {this.state.filterValues &&
            this.state.filterValues?.map((item, index) => (
              <Chip
                label={item}
                onDelete={this.handleFilterDelete.bind(
                  this,
                  item,
                  index
                )}
              // filterValues={this.state.filterValues}
              />
            ))}
        </div>
      </MDBRow>
      <MDBContainer
        style={{ background: "white" }}
        fluid
        className="ModalContainer"
      >
        <MDBRow>
          <MDBCol md={3}>
            <br />
            <Radio name="radio-buttons" label="name"
              checked={this.state.publicDomainDetails.selectedValue === 'any_of_the_selected_genres'}
              onChange={(e) =>
                this.handleChange("selectedValue", e.target.value)
              }
              value="any_of_the_selected_genres"
            />
            <span>
              Music Matching any of the selected genres{" "}
            </span>
            <br />
            <Radio name="radio-buttons" label="name"
              checked={this.state.publicDomainDetails.selectedValue === 'all_of_the_selected_genres'}
              onChange={(e) =>
                this.handleChange("selectedValue", e.target.value)
              }
              value="all_of_the_selected_genres"
            />
            <span>
              Music Matching all of the selected genres{" "}
            </span>
          </MDBCol>
          <MDBCol md={3}>
            <BasicLabel text="Genre" />
            <SelectField
              placeHolderText={"All Genres"}
              // value={{ 'value': this.state.publicDomainDetails.genres_ids?.value, 'text': this.state.publicDomainDetails.genres_ids?.text } || []}
              //  value={this.state.publicDomainDetails.genres_ids}
              options={this.state.allGenresOptions || []}
              // onChange={(e, value) =>
              //   this.handleChange("genres_ids",e.target.value)
              // }
              onChange={(e) => this.onGenreChange(e)}
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicLabel text="Composers" />
            <SelectField
              placeHolderText={"All Composers in selected genre(s)"}
              // value={{ 'value': this.state.publicDomainDetails.genres_ids?.value, 'text': this.state.publicDomainDetails.genres_ids?.text } || []}
              //  value={this.state.publicDomainDetails.genres_ids}
              options={this.state.composerOptions || []}
              // onChange={(e, value) =>
              //   this.handleChange("genres_ids",e.target.value)
              // }
              onChange={(e) => this.onComposerChange(e)}
              // onChange={(e, newValue) => {
              //   this.onChangeCallBack(
              //     "composerList",
              //     newValue,
              //     e.target.value
              //   );
              // }}
            />
          </MDBCol>
          <MDBCol md={1}>
            <br />
            <Radio
              name="radio-buttons"
              label="include"
              checked={this.state.publicDomainDetails.selectedIncludedValue === "include_composer_ids"}
              onChange={(e) =>
                this.handleChange("selectedIncludedValue", e.target.value)
              }
              value="include_composer_ids"
            />
            <span>Include </span>
            <br />
            <Radio
              name="radio-buttons"
              label="exclude"
              checked={this.state.publicDomainDetails.selectedIncludedValue === "exclude_composer_ids"}
              onChange={(e) =>
                this.handleChange("selectedIncludedValue", e.target.value)
              }
              value="exclude_composer_ids"
            />
            <span>Exclude </span>
          </MDBCol>

          <MDBCol md={2}>
            <br />
            <BasicCheckbox
              id="Subtitle"
              label={"Song Titles Only"}
              checked={this.state.publicDomainDetails?.song_title_only || false}
              onChange={(e) =>
                this.handleChange("song_title_only", +e.target.checked)
              }
            />

            <BasicCheckbox
              id="genre"
              label={"Group by Genre"}
              checked={this.state.publicDomainDetails?.group_by_genre || false}
              onChange={(e) =>
                this.handleChange("group_by_genre", +e.target.checked)
              }
            />
            <BasicCheckbox
              id="criteria"
              label={"Display Criteria on Report"}
              checked={this.state.publicDomainDetails?.show_display_criteria || false}
              onChange={(e) =>
                this.handleChange("show_display_criteria", +e.target.checked)
              }
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBRow>
        <MDBCol md={9}></MDBCol>
        <MDBCol md={3}>
          <MDBRow>
            <MDBCol id="genpdf-btn">
              <br />
              <BasicButton className="efcBtn" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate PDF"} onClick={this.handleSubmitPublicDomainReport} />
            </MDBCol>
            <MDBCol>
              <br />
              <BasicButton variant="outlined" text={"Clear Filters"} onClick={this.handleClearFilters} />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  );
}
  }

export default withUserContext(PublicDomainReport);
