import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TableComponent from "../../SharedComponents/Table";
import ClearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import {
  validateNumDotOnly,
} from "../../../Common/Helper";
import { withUserContext } from "../../../contexts/UserContext";
import { initialRightsConfig, initialRightsCostcenterConfig, initialRightsListConfig } from "./config";
import axios from "axios";
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent";
import { updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';

class InitialRightsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRightsList: initialRightsListConfig,
      isLoading: false,
      initialRightsConfig: initialRightsConfig,
      mandatoryNotExist: false,
      mandatoryNotExistSub :false,
      costCenterList: [],
      showCostCenterTable: false,
      isSubmit: false,
      selectedIndex: null,
      isSaveData: false,
      isDataSave: false,
      showFailMessage: false,
      showErrorMessage: false,
      showSuccessMessage: false,
      initialRightsCostcenterConfig: initialRightsCostcenterConfig,
      isCostCenterLoading: false,
      isLoading: false,
      caHeaderId:null,
      sortBy: null,
      isSortByAsc: true,
      sortCount: 0,
      sortByCostCenter: null,
      isSortByAscCostCenter: true,
      sortCountCostCenter: 0,
    };
  }
  componentDidMount() {
    this.getEntity("terms", "TERM", "");
    this.getEntity('costCenters', 'COST_CENTER', '');
    this.getTableData();

  }
  getCostCenterTableData = (ca_header_id) => {
    this.setState({ isCostCenterLoading: true })
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=COST_ALLOCATION_DETAIL&searchString=` + ca_header_id,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((response) => {
      let formattedList = response.data;
      this.setState({ costCenterList: formattedList, isDataSave: false, isCostCenterLoading: false, sortBy: null,
        isSortByAscCostCenter: true, sortCountCostCenter: 0,}, () => {this.handleCostCenterTableColumnClick("Cost Center")})
    })
      .catch((err) => {
        this.setState({ isCostCenterLoading: false, isDataSave: false });
      });
  }

  getTableData = () => {
    this.setState({ isLoading: true })
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=COST_ALLOCATION_HEADER&searchString=null`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((response) => {
      let formattedList = response.data;
      this.setState({ initialRightsList: formattedList, isSaveData: false, isLoading: false, sortBy: null,
        isSortByAsc: true, sortCount: 0,}, () => {this.handleTableColumnClick("Term")})
    })
      .catch((err) => {
        this.setState({ isLoading: false, isSaveData: false });
      });
  }
  getEntity = (entity, entityType, searchString) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    ClearTrackService.getDataWithCancel(
      Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${entityType}&searchString=${searchString}`,
      cancelToken,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((response) => {
      if (entityType === "TERM") {
        let terms = response?.data?.map((item) => ({
          label: item.term_name,
          value: item.term_id,
        }));
        this.setState({ [entity]: terms });
        let termsConfig = { ...this.state.initialRightsConfig };
        termsConfig.headings[0].selectOptions = terms;
        this.props?.permission?.edit === 1 ? null : termsConfig.actions = [ ]
        this.setState({ initialRightsConfig: termsConfig });
      } else if (entityType === "COST_CENTER") {
        let costCenters = response?.data?.map(item => ({
          label: item.cost_center_name,
          value: item.cost_center_id,
        }));
        this.setState({ [entity]: costCenters })
        let costCenterHeader = { ...this.state.initialRightsCostcenterConfig }
        costCenterHeader.headings[0].selectOptions = costCenters
        this.setState({ initialRightsCostcenterConfig: costCenterHeader })
      }
    });
  };
  postDetails = (initialRightsListDetails, postFlag) => {
    this.setState({ isSaveData: postFlag, mandatoryNotExist: false , mandatoryNotExistSub:false})
    ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/costAllocationHeader`,
      initialRightsListDetails,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(
        (response) => {
          if (response.data.error) {
            this.setState({ isSaveData: false })
            this.notificationComponent(true, "fail");
          } else {
            if (initialRightsListDetails?.editing) {
              initialRightsListDetails.editing = false;
            }
            this.notificationComponent(true, "success")
            this.getTableData();
          }
          //this.setState({ isSubmit: false });
        },
        (err) => {
          this.setState({ isSaveData: false });
          this.notificationComponent(true, "fail");
        }
      )
  }
  postCostCenterDetails = (costCenterListDetails, postFlag) => {
    this.setState({ isDataSave: postFlag, mandatoryNotExist: false , mandatoryNotExistSub:false})
    ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/costAllocationDetail`,
      costCenterListDetails,
      this.props?.userContext?.active_tenant?.tenant_id)
      .then(
        (response) => {
          if (response.data.error) {
            this.setState({ isDataSave: false })
            this.notificationComponent(true, "fail");
          } else {
            if (costCenterListDetails?.editing) {
              costCenterListDetails.editing = false;
            }
            this.notificationComponent(true, "success")
            this.getCostCenterTableData(costCenterListDetails?.ca_header_id);
          }
          //this.setState({ isSubmit: false });
        },
        (err) => {
          this.setState({ isDataSave: false });
          this.notificationComponent(true, "fail");
        }
      )
  }
  handleCostCenterTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCountCostCenter: this.state.sortCountCostCenter + 1 }, () => {
        this.handleCostCenterTableSort(column);
      })
    }
    else this.setState({ sortCountCostCenter: 0 }, () => {
      this.handleCostCenterTableSort(column);
    })
  }

  handleCostCenterTableSort = (colName) => {
    this.setState({
      isSortByAscCostCenter: this.state.sortCountCostCenter % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.initialRightsCostcenterConfig, this.state.isSortByAscCostCenter, this.state.sort, colName),
        renderList: tableSortList(colName === "Cost Center" ?  "Name":"Number", this.getCostCenterSortNode(colName), this.state?.costCenterList, this.state.isSortByAscCostCenter)
      });
    })
  }

  getCostCenterSortNode = (col) => {
    switch (col) {
        case "Cost Center": return "cost_center_id";
        case "Std %": return "standard_pct";
        case "Lib %": return "library_pct";
        case "Ad Card %": return "ad_card_pct";
        case "MT %": return "MT_pct";
        case "Incl Multiplier for Standard?": return "is_standard_use_multiplier";
        case "Incl Multiplier for Library?": return "is_library_use_multiplier";
        case "Incl Multiplier for Ad Card?": return "is_ad_card_use_multiplier";
        case "Incl Multiplier for MT?": return "is_MT_use_multiplier";
    }
  }

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.initialRightsConfig, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: tableSortList(colName === ("Multiplier" || "Incl HV") ? "Number" : "Name", this.getSortNode(colName), this.state?.initialRightsList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
        case "Term": return "term_id";
        case "Incl HV": return "include_HV";
        case "Multiplier": return "multiplier";
        case "Description": return "description";
    }
  }


  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success": return this.setState({ showSuccessMessage: value });
      case "fail": return this.setState({ showFailMessage: value });
    }
  }
  handleResetNotify = () => {
    this.setState({ showFailMessage: false, showSuccessMessage: false });
    if (this.state.saveFlag) {
      this.closeToastMessage();
    }
  }
  closeToastMessage = () => {
    this.setState({ showErrorMessage: false, showFailMessage: false, showSuccessMessage: false });

  }
  handleInlineEdits = (tableName, id, dataItem, index) => {
    switch (tableName) {
      case "initialRightsList": {
        let initialRights = [...this.state.initialRightsList];
        initialRights[index]['editing'] = true
        this.setState({
          mandatoryNotExist: false,
          initialRightsList: initialRights
        });
      }
        break;
      case "costCenterList": {
        let costList = [...this.state.costCenterList];
        costList[index]['editing'] = true
        this.setState({
          mandatoryNotExistSub: false,
          costCenterList: costList
        });
      }

        break;
    }
  }
  saveInlineEdits = (tableName, data, index, pkValue, dataItem) => {
    switch (tableName) {
      case "initialRightsList": {
        this.setState({ mandatoryNotExist: false });

        if (data[index]?.term_id  && data[index]?.description && data[index]?.multiplier && validateNumDotOnly(data[index]?.multiplier)) {
          let initialDetails = [...data];
          let initialRightsListDetails = [...this.state?.initialRightsList]
          initialRightsListDetails[index].ca_header_id = initialDetails[index]?.ca_header_id;
          initialRightsListDetails[index].term_id = initialDetails[index]?.term_id;
          initialRightsListDetails[index].multiplier = initialDetails[index]?.multiplier || 0;
          initialRightsListDetails[index].description = initialDetails[index]?.description;
          initialRightsListDetails[index].include_HV = initialDetails[index]?.include_HV
            ? 1
            : 0;
          initialRightsListDetails[index].is_active = initialDetails[index]?.is_active;
          //initialRightsListDetails[index].is_delete = initialDetails[index]?.is_delete;
          this.setState({
            initialRightsList: initialRightsListDetails
          });
          this.postDetails(initialRightsListDetails[index], initialRightsListDetails[index]?.ca_header_id);
        } else {
          this.setState({ mandatoryNotExist: true })
        }
      }
        break;

      case "costCenterList": {
        this.setState({mandatoryNotExistSub:false})
        if (
          costCenterList[index].cost_center_id && costCenterList[index].standard_pct && costCenterList[index].library_pct && costCenterList[index].ad_card_pct && costCenterList[index].MT_pct
        ) {
        let costCenterList = [...data];
        let costCenterListDetails = [...this.state.costCenterList];
        costCenterListDetails[index].ca_detail_id = costCenterList[index]?.ca_detail_id;
        costCenterListDetails[index].ca_header_id = costCenterList[index]?.ca_header_id;
        costCenterListDetails[index].cost_center_id = costCenterList[index]?.cost_center_id;
        costCenterListDetails[index].standard_pct = costCenterList[index]?.standard_pct || 0;
        costCenterListDetails[index].library_pct = costCenterList[index]?.library_pct || 0;
        costCenterListDetails[index].ad_card_pct = costCenterList[index]?.ad_card_pct || 0;
        costCenterListDetails[index].MT_pct = costCenterList[index]?.MT_pct || 0;
        costCenterListDetails[index].is_standard_use_multiplier = costCenterList[index]?.is_standard_use_multiplier ? 1 : 0,
          costCenterListDetails[index].is_library_use_multiplier = costCenterList[index]?.is_library_use_multiplier ? 1 : 0,
          costCenterListDetails[index].is_ad_card_use_multiplier = costCenterList[index]?.incl_multiplier_ad_card ? 1 : 0,
          costCenterListDetails[index].is_MT_use_multiplier = costCenterList[index]?.is_MT_use_multiplier ? 1 : 0,
          // costCenterListDetails[index].is_active = costCenterList[index]?.is_active;
          // costCenterListDetails[index].is_delete = costCenterList[index]?.is_delete;
          this.setState({ costCenterList: costCenterListDetails })
        this.postCostCenterDetails(costCenterListDetails[index], costCenterListDetails[index]?.ca_detail_id)
        } else {
          this.setState({mandatoryNotExistSub:true})
        }
      }
        break;
    }
  }

  handleAddNewItem = (tableName, newDataItem) => {
    switch (tableName) {
      case "initialRightsList": {
        this.setState({ mandatoryNotExist: false });
        if (
          newDataItem?.term_id && newDataItem?.description && newDataItem?.multiplier && validateNumDotOnly(newDataItem?.multiplier)) {
          let newData = {
            ca_header_id: newDataItem?.ca_header_id,
            term_id: newDataItem?.term_id,
            multiplier: newDataItem?.multiplier || 0,
            description: newDataItem?.description || null,
            include_HV: newDataItem?.include_HV ? 1 : 0,
            is_active: newDataItem?.is_active ? 1 : 0,
            //is_delete: newDataItem?.is_delete ? 1 : 0,
          };
          this.state.initialRightsList.push(newData);
          this.postDetails(newData, 'newItemPost');
        } else {
          this.setState({ mandatoryNotExist: true });
        }
      }
        break;
      case "costCenterList": {
        this.setState({ mandatoryNotExistSub: false });
        if (
          newDataItem?.cost_center_id && newDataItem?.standard_pct && newDataItem?.library_pct && newDataItem?.ad_card_pct && newDataItem?.MT_pct
        ) {
          let newDataCostCanter = {
            ca_header_id: this.state.caHeaderId,
            cost_center_id: newDataItem?.cost_center_id,
            standard_pct: newDataItem?.standard_pct || 0,
            library_pct: newDataItem?.library_pct || 0,
            ad_card_pct: newDataItem?.ad_card_pct || 0,
            MT_pct: newDataItem?.MT_pct || 0,
            is_standard_use_multiplier: newDataItem?.is_standard_use_multiplier ? 1 : 0,
            is_library_use_multiplier: newDataItem?.is_library_use_multiplier ? 1 : 0,
            is_ad_card_use_multiplier: newDataItem?.incl_multiplier_ad_card ? 1 : 0,
            is_MT_use_multiplier: newDataItem?.is_MT_use_multiplier ? 1 : 0,
          };
          this.state.costCenterList.push(newDataCostCanter);
          this.postCostCenterDetails(newDataCostCanter, 'newItemPost');
        } else {
          this.setState({ mandatoryNotExistSub: true });
        }
      }
        break;
    };
  }
  deleteInitialRightsItem = (newDataItem) => {
    let removeData = {
      ca_header_id: newDataItem?.ca_header_id,
      term_id: newDataItem?.term_id,
      multiplier: newDataItem?.multiplier || 0,
      description: newDataItem?.description || null,
      include_HV: newDataItem?.include_HV ? 1 : 0,
      is_active: newDataItem?.is_active ? 1 : 0,
      is_delete: 1,
    };
    this.postDetails(removeData, newDataItem?.ca_header_id)
    //this.getCostCenterTableData(newDataItem?.ca_header_id)
  };
  deleteCostCenterItem = (data) => {
    let removeDataCostCanter = {
      cost_center_id: data?.cost_center_id,
      standard_pct: data?.standard_pct,
      library_pct: data?.library_pct,
      ad_card_pct: data?.ad_card_pct,
      MT_pct: data?.MT_pct,
      is_standard_use_multiplier: data?.is_standard_use_multiplier ? 1 : 0,
      is_library_use_multiplier: data?.is_library_use_multiplier ? 1 : 0,
      is_ad_card_use_multiplier: data?.incl_multiplier_ad_card ? 1 : 0,
      is_MT_use_multiplier: data?.is_MT_use_multiplier ? 1 : 0,
      ca_header_id: data?.ca_header_id,
      ca_detail_id:data?.ca_detail_id,
     is_delete:1
    };
    this.postCostCenterDetails(removeDataCostCanter,data?.ca_detail_id)
  }
  initialRightsRowClick = (value, dataItem, index) => {
    if (!dataItem?.editing) {
      this.getCostCenterTableData(dataItem?.ca_header_id)
      this.setState({ showCostCenterTable: true, selectedIndex: index,caHeaderId:dataItem?.ca_header_id })
    }
  }

  render() {
    let severity = this.state.showFailMessage ? "error" : this.state.showSuccessMessage ? "success" : "";
    let messageNotification = this.state.showSuccessMessage ? "Saved Changes Successfully" : this.state.showFailMessage ? "Saved Changes Failed" : "";
    let message = this.state.showFail ? this.state.failMessage :
      this.state.showSuccess ? this.state.saveEditMessage : null
    return (
      <MDBContainer fluid className="initialRightsContainer">
        <MDBRow>
          <span className="description">Selet any Initial Rights records to show Cost Center table</span>
        </MDBRow>
        <div>
          <NotificationComponent open={this.state.showSuccessMessage || this.state.showFailMessage} message={messageNotification} severity={severity}
            handleResetNotify={this.handleResetNotify.bind(this)} />
        </div>
        <MDBRow className="pd">
          <span className="errorText">
            {this.state.mandatoryNotExist &&
              `Please enter Term, Multiplier and Description field's value`}
          </span>
        </MDBRow>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          this.state.initialRightsConfig?.headings[0]?.selectOptions?.length >
          0 && (
            <MDBRow id="initialRightsTable" className="mt-2">
              <TableComponent
                list={this.state.initialRightsList || []}
                config={this.state.initialRightsConfig}
                addItemToList={this.props?.permission?.edit === 1}
                notificationComponent={this.notificationComponent}
                handleAddNewItem={this.handleAddNewItem.bind(this, "initialRightsList")}
                editCallback={this.handleInlineEdits.bind(this, "initialRightsList")}
                saveCallback={this.saveInlineEdits.bind(this, "initialRightsList")}
                postInitiated={this.state.isSaveData}
                stickyHeader={true}
                readCallback={(index) => {
                  let initialRightsList = [...this.state.initialRightsList];
                  initialRightsList[index].editing = false;
                  this.setState({
                    initialRightsList: initialRightsList,
                    mandatoryNotExist: false,
                  });
                  }}
                  isLoading={this.state.isLoading}
                handleDelCallBack={(id, item) =>
                  this.deleteInitialRightsItem(item, "initialRightsList")
                }
                initialRightsFlag={true}
                initialRightsRowClick={this.initialRightsRowClick.bind(this)}
                selectedIndex={this.state.selectedIndex}
                sortCallback={this.handleTableColumnClick}
              />
            </MDBRow>
          )
        )}
        <MDBRow className="pd">
          <span className="errorText">
            {this.state.mandatoryNotExistSub &&
              `Please enter CostCenter, Std% , Lib%, MT%  & Adcard% field's value`}
          </span>
        </MDBRow>
        {this.state.showCostCenterTable ? <MDBRow id="initialRightsInnerTable" className="mt-2">
          <TableComponent
            list={this.state.costCenterList || []}
            config={initialRightsCostcenterConfig}
            addItemToList={this.props?.permission?.edit === 1}
            handleAddNewItem={this.handleAddNewItem.bind(this, "costCenterList")}
            editCallback={this.handleInlineEdits.bind(this, "costCenterList")}
            saveCallback={this.saveInlineEdits.bind(this, "costCenterList")}
            postInitiated={this.state.isDataSave}
            stickyHeader={true}
            readCallback={(index) => {
              let initialRightsCostCenterList = [...this.state.costCenterList];
              initialRightsCostCenterList[index].editing = false;
              this.setState({
                costCenterList: initialRightsCostCenterList,
                mandatoryNotExistSub: false,
              });
            }}
            isLoading={this.state.isCostCenterLoading}
            handleDelCallBack={(id, item) =>
              this.deleteCostCenterItem(item, "costCenterList")
            }
            sortCallback={this.handleCostCenterTableColumnClick}
          />

        </MDBRow> : null}
      </MDBContainer>
    );
  }
}

export default withUserContext(InitialRightsTable);
