export const budgetList = [
    {
        label: "Pilot Budget",
        value: 1
    },
    {
        label: "Clearance Budget",
        value: 2
    },
    {
        label: "TV Creative Music Budget",
        value: 3
    }
]
export const newVersion = {
    "show_season_episode_id": null,
    "version_description": null,
    "type_of_use": null,
    "shoot_date": null,
    "recording_date": null,
    "location": null,
    "scene_description": null,
    "version_notes": null,
    "is_final_budget": null
}

export const creativeBudgetOnCameraConfig = {
    headings: [
        {
            headingLabel: "Include?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "4%"
        },
        {
            headingLabel: "From Ver",
            inputType: "label",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Union",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Detail",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "4%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            width: "12%"
        },
        {
            headingLabel: "Rate",
            inputType: "text",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Hours",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Quantity",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Add%",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Cost",
            inputType: "label",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "+FR",
            inputType: "button",
            border: true,
            width: "9%"
        },
        {
            headingLabel: "Fringe",
            inputType: "label",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "TotalCost",
            inputType: "label",
            border: true,
            width: "6%"
        },
    ],

    dataNodes: ["is_include", "from_version", "union_id", "budget_detail_code_id", "description", "notes", "rate", "hours", "qty", "add_percentage", "cost", "is_fr", "fringe", "total_cost"],
    primaryKey: "show_season_tvmb_on_camera_performers_id",
    actions: ["pen"],
    buttonFields: [
        {
            column: "is_fr",
            icon: "plus-circle",
            hideBtnText: true
        }
    ],
    inlineEdits: [
        {
            dataNode: "is_include",
            width: "5%"
        },
        {
            dataNode: "union_id",
            width: "6%"
        },
        {
            dataNode: "budget_detail_code_id",
            width: "6%"
        },
        {
            dataNode: "description",
            width: "13%"
        },
        {
            dataNode: "notes",
            width: "11%"
        },
        {
            dataNode: "rate",
            width: "8%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        },
        {
            dataNode: "hours",
            width: "5%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "qty",
            width: "4%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "add_percentage",
            width: "4%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "cost",
            width: "8%",
        },
        {
            dataNode: "is_fr",
            width: "18%",
            icon: "plus-circle"
        },
        {
            dataNode: "total_cost",
            width: "12%",
        }
    ],
    nodeFields: [
        {
            column: "is_include",
            icon: "check"
        },
    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};
export const creativeBudgetRecordingConfig = {
    headings: [
        {
            headingLabel: "Include?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "4%"
        },
        {
            headingLabel: "From Ver",
            inputType: "label",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Union",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Detail",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "4%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            width: "12%"
        },
        {
            headingLabel: "Rate",
            inputType: "text",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Hours",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Quantity",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Add%",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Cost",
            inputType: "label",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "+FR",
            inputType: "button",
            border: true,
            width: "9%"
        },
        {
            headingLabel: "Fringe",
            inputType: "label",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "TotalCost",
            inputType: "label",
            border: true,
            width: "6%"
        },
    ],

    dataNodes: ["is_include", "from_version", "union_id", "budget_detail_code_id", "description", "notes", "rate", "hours", "qty", "add_percentage", "cost", "is_fr", "fringe", "total_cost"],
    primaryKey: "show_season_tvmb_recordings_id",
    actions: ["pen"],
    buttonFields: [
        {
            column: "is_fr",
            icon: "plus-circle",
            hideBtnText: true
        }
    ],
    inlineEdits: [
        {
            dataNode: "is_include",
            width: "5%"
        },
        {
            dataNode: "union_id",
            width: "6%"
        },
        {
            dataNode: "budget_detail_code_id",
            width: "6%"
        },
        {
            dataNode: "description",
            width: "13%"
        },
        {
            dataNode: "notes",
            width: "11%"
        },
        {
            dataNode: "rate",
            width: "8%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        },
        {
            dataNode: "hours",
            width: "5%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "qty",
            width: "4%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "add_percentage",
            width: "4%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "cost",
            width: "8%"
        },
        {
            dataNode: "is_fr",
            width: "18%",
            icon: "plus-circle"
        },
        {
            dataNode: "total_cost",
            width: "12%"
        }
    ],
    nodeFields: [
        {
            column: "is_include",
            icon: "check"
        },
    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};
export const creativeBudgetOrchestralConfig = {
    headings: [
        {
            headingLabel: "Include?",
            inputType: "checkbox",
            icon: "",
            border: true,
            width: "4%"
        },
        {
            headingLabel: "From Ver",
            inputType: "label",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Union",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Detail",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "4%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            width: "12%"
        },
        {
            headingLabel: "Rate",
            inputType: "text",
            border: true,
            width: "8%"
        },
        {
            headingLabel: "Hours",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Quantity",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Add%",
            inputType: "text",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "Cost",
            inputType: "label",
            border: true,
            width: "7%"
        },
        {
            headingLabel: "+FR",
            inputType: "button",
            border: true,
            width: "9%"
        },
        {
            headingLabel: "Fringe",
            inputType: "label",
            border: true,
            width: "5%"
        },
        {
            headingLabel: "TotalCost",
            inputType: "label",
            border: true,
            width: "6%"
        },
    ],

    dataNodes: ["is_include", "from_version", "union_id", "budget_detail_code_id", "description", "notes", "rate", "hours", "qty", "add_percentage", "cost", "is_fr", "fringe", "total_cost"],
    primaryKey: "show_season_tvmb_orchestral_sessions_id",
    actions: ["pen"],
    buttonFields: [
        {
            column: "is_fr",
            icon: "plus-circle",
            hideBtnText: true
        }
    ],
    inlineEdits: [
        {
            dataNode: "is_include",
            width: "5%"
        },
        {
            dataNode: "union_id",
            width: "6%"
        },
        {
            dataNode: "budget_detail_code_id",
            width: "6%"
        },
        {
            dataNode: "description",
            width: "13%"
        },
        {
            dataNode: "notes",
            width: "11%"
        },
        {
            dataNode: "rate",
            width: "8%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        },
        {
            dataNode: "hours",
            width: "5%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "qty",
            width: "4%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "add_percentage",
            width: "4%",
            validateMethod: 'validateNumDotOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "cost",
            width: "8%"
        },
        {
            dataNode: "is_fr",
            width: "18%",
            icon: "plus-circle"
        },
        {
            dataNode: "total_cost",
            width: "12%"
        }
    ],
    nodeFields: [
        {
            column: "is_include",
            icon: "check"
        },
    ],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"],
};
export const creativeBudgetList = [
    {
        id: 1,
        Include: "X",
        Union: "[Union]",
        Detail: "[Details]",
        Description: "[Description]",
        Notes: "[Notes]",
        Rate: "$[Rate]",
        Hours: "[Hours]",
        Add: "[Add%]",
        FR: " ",
        Cost: "$[Cost]",
        Fringe: "[Fring]",
        TotalCost: "[TotalCost]"

    },
    {
        id: 2,
        Include: "X",
        Union: "[Union]",
        Detail: "[Details]",
        Description: "[Description]",
        Notes: "[Notes]",
        Rate: "$[Rate]",
        Hours: "[Hours]",
        Add: "[Add%]",
        FR: " ",
        Cost: "$[Cost]",
        Fringe: "[Fring]",
        TotalCost: "[TotalCost]"

    },
    {
        id: 3,
        Include: "X",
        Union: "[Union]",
        Detail: "[Details]",
        Description: "[Description]",
        Notes: "[Notes]",
        Rate: "$[Rate]",
        Hours: "[Hours]",
        Add: "[Add%]",
        FR: " ",
        Cost: "$[Cost]",
        Fringe: "[Fring]",
        TotalCost: "[TotalCost]"

    },
    {
        id: 4,
        Include: "X",
        Union: "[Union]",
        Detail: "[Details]",
        Description: "[Description]",
        Notes: "[Notes]",
        Rate: "$[Rate]",
        Hours: "[Hours]",
        Add: "[Add%]",
        FR: " ",
        Cost: "$[Cost]",
        Fringe: "[Fring]",
        TotalCost: "[TotalCost]"

    },
    {
        id: 5,
        Include: "X",
        Union: "[Union]",
        Detail: "[Details]",
        Description: "[Description]",
        Notes: "[Notes]",
        Rate: "$[Rate]",
        Hours: "[Hours]",
        Add: "[Add%]",
        FR: " ",
        Cost: "$[Cost]",
        Fringe: "[Fring]",
        TotalCost: "[TotalCost]"

    },
]

export const pilotBudgetList = [
    {
        label: "Pilot",
        value: 1
    },
    {
        label: "Series Budget",
        value: 2
    },
    {
        label: "Amort",
        value: 3
    }
]

export const intialPilotBudgetDetails = {
    "script_date": null,
    "preparation_date": null,
    "comments": "Comments go here",
    CostBeforeMusicLicense: [],
    PilotCost: [],
    PilotCostPerpBudget: [],
}

export const pilotBudgetConfig = {
    headings: [
        {
            headingLabel: "Details",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Description 1(bolded)",
            inputType: "text",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Description 2(normal)",
            inputType: "text",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Est.Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Locked Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "EFC",
            inputType: "text",
            border: true,
            width: "10%"
        }
    ],
    dataNodes: ["budget_detail_code_id", "description", "secondary_description", "est_cost", "locked_cost", "efc"],
    primaryKey: "show_season_pilot_budget_details_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "budget_detail_code_id",
            width: "15%"
        },
        {
            dataNode: "description",
            width: "22%"
        },
        {
            dataNode: "secondary_description",
            width: "23%"
        },
        {
            dataNode: "est_cost",
            width: "10%",
            inlineIcon: "$",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "locked_cost",
            width: "10%",
            inlineIcon: "$",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "efc",
            width: "10%",
            inlineIcon: "$",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        }
    ]
}
export const seriesBudgetConfig = {
    headings: [
        {
            headingLabel: "Details",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Description 1(bolded)",
            inputType: "text",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Description 2(normal)",
            inputType: "text",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Est.Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Locked Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "EFC",
            inputType: "text",
            border: true,
            width: "10%"
        }
    ],
    dataNodes: ["budget_detail_code_id", "description", "secondary_description", "est_cost", "locked_cost", "efc"],
    primaryKey: "show_season_pilot_budget_details_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "budget_detail_code_id",
            width: "15%"
        },
        {
            dataNode: "description",
            width: "22%"
        },
        {
            dataNode: "secondary_description",
            width: "23%"
        },
        {
            dataNode: "est_cost",
            width: "10%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        },
        {
            dataNode: "locked_cost",
            width: "10%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        },
        {
            dataNode: "efc",
            width: "10%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        }
    ]
}
export const amortBudgetConfig = {
    headings: [
        {
            headingLabel: "Details",
            inputType: "select",
            selectOptions: [],
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Description 1(bolded)",
            inputType: "text",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Description 2(normal)",
            inputType: "text",
            border: true,
            width: "25%"
        },
        {
            headingLabel: "Est.Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Locked Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "EFC",
            inputType: "text",
            border: true,
            width: "10%"
        }
    ],
    dataNodes: ["budget_detail_code_id", "description", "secondary_description", "est_cost", "locked_cost", "efc"],
    primaryKey: "show_season_pilot_budget_details_id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "budget_detail_code_id",
            width: "15%"
        },
        {
            dataNode: "description",
            width: "22%"
        },
        {
            dataNode: "secondary_description",
            width: "23%"
        },
        {
            dataNode: "est_cost",
            width: "10%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        },
        {
            dataNode: "locked_cost",
            width: "10%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        },
        {
            dataNode: "efc",
            width: "10%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers',
            inlineIcon: "$",
        }
    ]
}
export const pilotList = [
    {
        id: 1,
        detail_s: "[details]",
        description_bolded: "[description_bolded]",
        description_normal: "[description_normal]",
        est_cost: "[est_cost]",
        locked_cost: "[locked_cost]",
        efc: "[efc]"
    },
    {
        id: 2,
        detail_s: "[details]",
        description_bolded: "[description_bolded]",
        description_normal: "[description_normal]",
        est_cost: "[est_cost]",
        locked_cost: "[locked_cost]",
        efc: "[efc]"
    },
    {
        id: 3,
        detail_s: "[details]",
        description_bolded: "[description_bolded]",
        description_normal: "[description_normal]",
        est_cost: "[est_cost]",
        locked_cost: "[locked_cost]",
        efc: "[efc]"
    },
    {
        id: 4,
        detail_s: "[details]",
        description_bolded: "[description_bolded]",
        description_normal: "[description_normal]",
        est_cost: "[est_cost]",
        locked_cost: "[locked_cost]",
        efc: "[efc]"
    },
    {
        id: 5,
        detail_s: "[details]",
        description_bolded: "[description_bolded]",
        description_normal: "[description_normal]",
        est_cost: "[est_cost]",
        locked_cost: "[locked_cost]",
        efc: "[efc]"
    },
]

export const pilotBudgetConfig2 = {
    headings: [
        {
            headingLabel: "Details",
            inputType: "label",
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Description ",
            inputType: "text",
            border: true,
            width: "50%"
        },
        {
            headingLabel: "Est.Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Locked Cost",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "EFC",
            inputType: "text",
            border: true,
            width: "10%"
        }
    ],
    dataNodes: ["cost_center_name", "description", "est_cost", "locked_cost", "efc"],
    inlineEdits: ["cost_center_name", "description", "est_cost", "locked_cost", "efc"],
}

export const pilotList2 = [
    {
        id: 1,
        detail_s: "[details]",
        description: "[description]",
        est_cost: "[est_cost]",
        locked_cost: "[locked_cost]",
        efc: "[efc]"
    },
    {
        id: 2,
        detail_s: "[details]",
        description: "[description]",
        est_cost: "[est_cost]",
        locked_cost: "[locked_cost]",
        efc: "[efc]"
    },

]

export const intialClearanceBudgetDetails = {
    "apply_to": "Pilot",
    "script_date": "01/25/2015",
    "marketing_cost": "$3000.00",
    "budget_description": "All Media excluding EST and DVD for 6 years",
    "series_pattern": "$25,000.00",
    "prep_date": "01/ 21 / 2015",
    SubTotals: ["$1,150.00", "$0.00", "$0.00"],

}


export const ClearanceBudgetConfig = {
    headings: [
        {
            headingLabel: "Pg #",
            inputType: "text",
            border: true,
            width: "3%"
        },
        {
            headingLabel: "Description",
            inputType: "text",
            border: true,
            width: "20%"
        },
        {
            headingLabel: "Song Title",
            inputType: "text",
            //selectOptions: [],
            border: true,
            width: "15%"
        },
        {
            headingLabel: "Artist",
            inputType: "text",
            //selectOptions: [],
            border: true,
            width: "15%"
        },

        {
            headingLabel: "Est. Synch Fees",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Est. Master Fees",
            inputType: "text",
            border: true,
            width: "10%"
        },
        {
            headingLabel: "Notes",
            inputType: "text",
            border: true,
            width: "20%"
        }
    ],
    dataNodes: ["pg_no", "description", "song_title", "artist_name", "est_synch_fees", "est_master_fees", "notes"],
    // searchFields: [{
    //     label: "song_title",
    //     hyperlink: false
    // },
    // {
    //     label: "artist_name",
    //     hyperlink: false
    // }],
    primaryKey: "id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "pg_no",
            width: "3%",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "description",
            width: "18%"
        },
        {
            dataNode: "song_title",
            width: "15%"
        },
        {
            dataNode: "artist_name",
            width: "15%"
        },
        {
            dataNode: "est_synch_fees",
            width: "9%",
            inlineIcon: "$",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "est_master_fees",
            width: "10%",
            inlineIcon: "$",
            validateMethod: 'validateNumbersOnly',
            validateMessage: 'Please enter numbers'
        },
        {
            dataNode: "notes",
            width: "20%"
        }
    ]
}



export const intialPilotHeaderDetails = {
    "show_season_pilot_budget_header_id": null,
    "script_date": null,
    "preparation_date": null,
    "comments": null,
    "show_season_id": null,
    "season_id": null,
    "show_id": null
}

