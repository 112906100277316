import React from 'react';
import { MDBCol,  MDBRow, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText, MDBTooltip } from 'mdb-react-ui-kit';
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableComponent from '../../SharedComponents/Table';
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import { creativeBudgetOnCameraConfig, creativeBudgetRecordingConfig, creativeBudgetOrchestralConfig, creativeBudgetList, newVersion } from './Config';
import "./Budget.scss";
import ClearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
import { validateNumDotOnly, validateNumbersOnly } from '../../../Common/Helper';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import { CircularProgress, Popover } from '@material-ui/core';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import messages from '../../../Common/Messages.json';

let canEditBudgetDetails = false, budgetDetailsPermission, versionArr = [{ "version_no.": 1, "version_name": "dummy version 1" }, { "version_no.": 2, "version_name": "dummy version 2" }], musicBudgetRecordList = [], combineVersionArray = [], is_final_array = [], can_edit_final_budget = false, load = {}, exportPDForExcel, selectedSeason, episodeNumber, seasonOptions, seasonSelectedObj = {};
class CreativeBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            renderList: creativeBudgetList,
            show_season_id: this.props?.show_season_id,
            main_title_id: this.props?.main_title_id,
            episodeOptions: [],
            musicBudgetRecordList: [],
            versionOptions: [],
            episode_id: null,
            version: null,
            headerData: [],
            allVersionsList: [],
            cameraPerformersData: [],
            creativeBudgetOnCameraConfig: creativeBudgetOnCameraConfig,
            creativeBudgetRecordingConfig: creativeBudgetRecordingConfig,
            creativeBudgetOrchestralConfig: creativeBudgetOrchestralConfig,
            unionOptions: [],
            detailCodeOptions: [],
            recordingsData: [],
            orchestralData: [],
            fieldChanged: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            isPosting: false,
            isPostingCombineVersion: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            loadingCameraPerformers: false,
            loadingRecordingData: false,
            loadingOrchestralData: false,
            showNotesOfOnCameraPerformers: false,
            onCameraPerformersNotes: "Camera",
            showNotesOfRecordings: false,
            recordingsNotes: "Recordings",
            showOrchestralSessions: false,
            orchestralNotes: "",
            isPostingRecordings: false,
            isPostingOrchestral: false,
            setPopoverVersion: null,
            setCopyVersionPopup: null,
            setCopyVersionPopupPilot: null,
            showEditBudgetTabModal: null,
            showExcelAndDocModal: false,
            origin: null,
            isLoading: false,
            prodEntityOfShow: "",
            clickedFrom: null,
            seasonUnionList: [],
            is_final_array: [],
            can_edit_final_budget: false,
            postNotesLoader: null,
            isPdfPosting: false,
            isExcelPosting: false,
            isFinalBudgetLoading: false,
            copyPayload: {
                "from_show_season_id": this.props.show_season_id
            },
            copyEpisodeOptions:[],
            isEmailLoader: false,
            excelData: {},
            copyVersionError:null,
            isOpenExisting: false
        };
    }
    componentDidMount() {
        this.setState({ isLoading: true });
        this.getPermissions(this.props.permissionArr);
        selectedSeason = this.props?.initialRecordData?.seasons?.filter(item => (item.show_season_id == this.props.initialRecordData?.show_season_id) ? item : null);
        seasonOptions = this.props?.initialRecordData?.seasons.map((item, index) => ({ value: item.season_id, label: item.season_name }));
        if (selectedSeason.length > 0) {
            selectedSeason = selectedSeason[0];
        }
        if (this.props.is_pilot === 1) {
            this.getVersions(this.state.show_season_id, null);
        }
        if (this.props.is_pilot === 0 || this.props.is_pilot === null) {
            this.getEpisodesData(this.state.show_season_id);
        }
        this.getUnion();
        this.getBudgetCodeDetail();
        if (this.props.initialRecordData?.production_entity_id) {
            let prodEntityOfShow = this.props.productionEntityOptions.filter(item => item.value === this.props.initialRecordData?.production_entity_id);
            this.setState({ prodEntityOfShow: prodEntityOfShow[0]?.label })
        }
        if (this.props.initialRecordData?.unions) {
            let seasonUnionList = [];
            this.props.initialRecordData?.unions?.map(item => seasonUnionList.push({ "value": item?.union_id, "label": item?.union_name }));
            this.setState({ seasonUnionList });
        }

        // this.getRecordingsData(this.state.show_season_id)
        // if(this.state.cameraPerformersData.length > 0) {
        //     this.formatTableData(this.state.cameraPerformersData);
        // }
    }
    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded });
    };
    handleInlineEdits = (tableName, id, dataItem) => {
        if (tableName === "cameraPerformersData") {
            let allDetails = [...this.state.cameraPerformersData]
            this.setState({
                cameraPerformersData: allDetails.map((item) => {
                    let newItem = {...item}
                    if (item.show_season_tvmb_on_camera_performers_id == id)
                        newItem['editing'] = true;
                    return newItem
                })
            })
        }
        else if (tableName === "recordingsData") {
            let allDetails = [...this.state.recordingsData]
            this.setState({
                recordingsData: allDetails.map((item) => {
                    if (item.show_season_tvmb_recordings_id == dataItem.show_season_tvmb_recordings_id)
                        item['editing'] = true;
                    return item
                })
            })
        }
        else if (tableName === "orchestralData") {
            let allDetails = [...this.state.orchestralData]
            this.setState({
                orchestralData: allDetails.map((item) => {
                    if (item.show_season_tvmb_orchestral_sessions_id == dataItem.show_season_tvmb_orchestral_sessions_id)
                        item['editing'] = true;
                    return item
                })
            })
        }
    }
    saveInlineEdits = (tableName, data, index, pkValue, dataItem) => {
        if (tableName === "cameraPerformersData") {
            let allDetails = [...this.state.cameraPerformersData]
            // let selectedUnion = data[index].unions
            // data[index].unions = this.state.unionOptions.find(data => data.value === selectedUnion)?.label
            let rateHourQty = Number(data[index].rate) * Number(data[index].hours) * Number(data[index].qty);
            data[index].cost = rateHourQty + ((Number(data[index].add_percentage) / 100) * rateHourQty);
            data[index].is_include = data[index].is_include ? 1 : 0;
            delete data[index].fringe;
            delete data[index].total_cost;
            delete data[index].cost;
            // allDetails[index].editing = false;
            // allDetails = data
            // this.setState({ cameraPerformersData: allDetails });
            if (validateNumDotOnly(data[index].rate ? data[index].rate : 0) && validateNumDotOnly(data[index].hours ? data[index].hours : 0) && validateNumbersOnly(data[index].qty ? data[index].qty : 0) && validateNumDotOnly(data[index].add_percentage ? data[index].add_percentage : 0)) {
                this.postCameraPerformers(data[index], data[index].show_season_tvmb_on_camera_performers_id);
            }
        }
        else if (tableName === "recordingsData") {
            let allDetails = [...this.state.recordingsData]
            // let selectedUnion = data[index].unions
            // data[index].unions = this.state.unionOptions.find(data => data.value === selectedUnion)?.label
            let rateHourQty = Number(data[index].rate) * Number(data[index].hours) * Number(data[index].qty);
            data[index].cost = rateHourQty + ((Number(data[index].add_percentage) / 100) * rateHourQty);
            data[index].is_include = data[index].is_include ? 1 : 0
            // allDetails[index].editing = false;
            // allDetails = data
            // this.setState({ recordingsData: allDetails });
            delete data[index].fringe;
            delete data[index].total_cost;
            delete data[index].cost;
            if (validateNumDotOnly(data[index].rate ? data[index].rate : 0) && validateNumDotOnly(data[index].hours ? data[index].hours : 0) && validateNumbersOnly(data[index].qty ? data[index].qty : 0) && validateNumDotOnly(data[index].add_percentage ? data[index].add_percentage : 0)) {
                this.postRecordingsData(data[index], data[index].show_season_tvmb_recordings_id);
            }
        }
        else if (tableName === "orchestralData") {
            let allDetails = [...this.state.orchestralData]
            // let selectedUnion = data[index].unions
            // data[index].unions = this.state.unionOptions.find(data => data.value === selectedUnion)?.label
            let rateHourQty = Number(data[index].rate) * Number(data[index].hours) * Number(data[index].qty);
            data[index].cost = rateHourQty + ((Number(data[index].add_percentage) / 100) * rateHourQty);
            data[index].is_include = data[index].is_include ? 1 : 0
            // allDetails[index].editing = false
            // allDetails = data
            // this.setState({ orchestralData: allDetails });
            delete data[index].fringe;
            delete data[index].total_cost;
            delete data[index].cost;
            if (validateNumDotOnly(data[index].rate ? data[index].rate : 0) && validateNumDotOnly(data[index].hours ? data[index].hours : 0) && validateNumbersOnly(data[index].qty ? data[index].qty : 0) && validateNumDotOnly(data[index].add_percentage ? data[index].add_percentage : 0)) {
                this.postOrchestralData(data[index], data[index].show_season_tvmb_orchestral_sessions_id);
            }
        }
    }

    handleReadCallback = (tableName, index) => {
        if (tableName === "cameraPerformersData") {
            let allDetails = [...this.state.cameraPerformersData]
            allDetails[index].editing = false
            this.setState({ cameraPerformersData: allDetails });
        }
        else if (tableName === "recordingsData") {
            let allDetails = [...this.state.recordingsData]
            allDetails[index].editing = false
            this.setState({ recordingsData: allDetails });
        }
        else if (tableName === "orchestralData") {
            let allDetails = [...this.state.orchestralData]
            allDetails[index].editing = false
            this.setState({ orchestralData: allDetails });
        }
    }

    handleAddNewItem = (tableName, newDataItem) => {
        if (tableName === "cameraPerformersData") {
            console.log("newDataItem in handleAddNewItem", newDataItem);
            let allDetails = [...this.state.cameraPerformersData];
            let index = allDetails.length;
            let rateHourQty = (newDataItem?.rate ? Number(newDataItem?.rate) : 0) * (newDataItem?.hours ? Number(newDataItem?.hours) : 0) * (newDataItem?.qty ? Number(newDataItem?.qty) : 0);
            let cost = rateHourQty + (((newDataItem?.add_percentage ? Number(newDataItem?.add_percentage) : 0) / 100) * rateHourQty);
            let newData = {
                "add_percentage": newDataItem.add_percentage,
                "budget_detail_code_id": newDataItem.budget_detail_code_id,
                "description": newDataItem.description,
                "hours": Number(newDataItem.hours),
                "is_include": newDataItem?.is_include ? 1 : 0,
                "notes": newDataItem.notes,
                "qty": Number(newDataItem.qty),
                "rate": Number(newDataItem.rate),
                "union_id": newDataItem.union_id,
                "show_season_id": this.state.show_season_id,
                "show_season_tvmb_on_camera_performers_id": null,
                "show_id": this.props?.show_id,
                "season_id": this.props?.season_id,
                "show_season_tv_creative_music_budget_id": this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id
                // "unions": this.state.unionOptions.find(data => data.value === newDataItem.unions)?.label
            }
            allDetails[index] = newData;
            this.setState({ cameraPerformersData: allDetails })
            if (validateNumDotOnly(allDetails[index].rate ? allDetails[index].rate : 0) && validateNumDotOnly(allDetails[index].hours ? allDetails[index].hours : 0) && validateNumbersOnly(allDetails[index].qty ? allDetails[index].qty : 0) && validateNumDotOnly(allDetails[index].add_percentage ? allDetails[index].add_percentage : 0)) {
                this.postCameraPerformers(allDetails[index]);
            }
        }
        else if (tableName === "recordingsData") {
            let allDetails = [...this.state.recordingsData]
            let index = allDetails.length
            let newData = {
                "add_percentage": newDataItem.add_percentage,
                "budget_detail_code_id": newDataItem.budget_detail_code_id,
                "description": newDataItem.description,
                "hours": Number(newDataItem.hours),
                "is_include": newDataItem?.is_include ? 1 : 0,
                "notes": newDataItem.notes,
                "qty": Number(newDataItem.qty),
                "rate": Number(newDataItem.rate),
                "union_id": newDataItem.union_id,
                "show_season_id": this.state.show_season_id,
                "show_season_tvmb_on_camera_performers_id": null,
                "show_id": this.props?.show_id,
                "season_id": this.props?.season_id,
                "show_season_tv_creative_music_budget_id": this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id
            }
            allDetails[index] = newData;
            this.setState({ recordingsData: allDetails });
            if (validateNumDotOnly(allDetails[index].rate ? allDetails[index].rate : 0) && validateNumDotOnly(allDetails[index].hours ? allDetails[index].hours : 0) && validateNumbersOnly(allDetails[index].qty ? allDetails[index].qty : 0) && validateNumDotOnly(allDetails[index].add_percentage ? allDetails[index].add_percentage : 0)) {
                this.postRecordingsData(allDetails[index]);
            }
        }
        else if (tableName === "orchestralData") {
            let allDetails = [...this.state.orchestralData]
            let index = allDetails.length
            let newData = {
                "add_percentage": newDataItem.add_percentage,
                "budget_detail_code_id": newDataItem.budget_detail_code_id,
                "description": newDataItem.description,
                "hours": Number(newDataItem.hours),
                "is_include": newDataItem?.is_include ? 1 : 0,
                "notes": newDataItem.notes,
                "qty": Number(newDataItem.qty),
                "rate": Number(newDataItem.rate),
                "union_id": newDataItem.union_id,
                "show_season_id": this.state.show_season_id,
                "show_season_tvmb_on_camera_performers_id": null,
                "show_id": this.props?.show_id,
                "season_id": this.props?.season_id,
                "show_season_tv_creative_music_budget_id": this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id
            }
            allDetails[index] = newData;
            this.setState({ orchestralData: allDetails });
            if (validateNumDotOnly(allDetails[index].rate ? allDetails[index].rate : 0) && validateNumDotOnly(allDetails[index].hours ? allDetails[index].hours : 0) && validateNumbersOnly(allDetails[index].qty ? allDetails[index].qty : 0) && validateNumDotOnly(allDetails[index].add_percentage ? allDetails[index].add_percentage : 0)) {
                this.postOrchestralData(allDetails[index]);
            }
        }
    }

    getEpisodesData = (show_season_id) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodes?show_season_id=${show_season_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let formattedList = response?.data?.map(item => ({ value: item.show_season_episode_id, label: item.episode_number }));
                episodeNumber = formattedList.length > 0 ? formattedList[0].label : null;
                //this.props.handleEpisodesArray(formattedList);
                if (formattedList.length > 0) {
                    this.getVersions(show_season_id, formattedList[0].value);
                }
                else {
                    this.setState({ isLoading: false });
                }
                this.setState({ episodeOptions: formattedList, episode_id: formattedList.length > 0 ? formattedList[0].value : null });
            },
                (err) => {
                    console.log("Error in fetching Episodes:", err)
                })
    }

    getVersions = (show_season_id, episode_id) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonTvCreativeMusicBudget?show_season_id=${show_season_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isLoading: false });
                musicBudgetRecordList = response.data;
                if (musicBudgetRecordList.length > 0) {
                    let formattedList = [], initial_version_value = null;
                    if (this.props.is_pilot === 1) {
                        is_final_array = [];
                        response?.data?.length > 0 && response?.data?.map((item, index) => {
                            is_final_array.push(item.is_final_budget);
                        });
                        let can_edit_final_budget = is_final_array.includes(Number("1")) ? false : true;
                        formattedList = response.data.map(item => ({ value: item.version, label: item.version_description, version_episode_id: item.show_season_episode_id, version_description: item.version_description }));
                        if (budgetDetailsPermission?.edit === 1) {
                            formattedList.push({
                                value: 'newbtn', label: <BasicButton
                                    variant="contained" type="inline" text={"Create New Version"} disabled={false} />
                            });
                        }
                        initial_version_value = formattedList[0].value;
                        this.getHeaderData(this.state.show_season_id, null, initial_version_value);
                        this.setState({ musicBudgetRecordList, allVersionsList: formattedList, version: initial_version_value, is_final_array, can_edit_final_budget }, () => {
                            //this.getRecordingOrchestralCameraPerformersData(this.state.show_season_id, musicBudgetRecordList)
                            //if (this.state.allVersionsList.length > 0) {

                            // }
                        })
                    }
                    if (this.props.is_pilot === 0) {
                        let filteredVersionList = [];
                        formattedList = response.data.map(item => ({ value: item?.version, label: item?.version_description, version_episode_id: item?.show_season_episode_id, is_final_budget: item?.is_final_budget, version_description: item?.version_description }));
                        filteredVersionList = formattedList.filter(item => item.version_episode_id === episode_id);
                        if (budgetDetailsPermission?.edit === 1) {
                            filteredVersionList.push({
                                value: 'newbtn', label: <BasicButton
                                    variant="contained" type="inline" text={"Create New Version"} disabled={false} />
                            });
                        }
                        initial_version_value = filteredVersionList.length > 0 ? filteredVersionList[0].value : null;
                        is_final_array = [];
                        filteredVersionList?.length > 0 && filteredVersionList?.map((item, index) => {
                            is_final_array.push(item?.is_final_budget);
                        });
                        let can_edit_final_budget = is_final_array.includes(Number("1")) ? false : true;
                        if (initial_version_value !== null && initial_version_value !== "newbtn") {
                            this.getHeaderData(this.state.show_season_id, episode_id, initial_version_value);
                            this.setState({ musicBudgetRecordList, allVersionsList: formattedList, versionOptions: filteredVersionList, version: initial_version_value, is_final_array, can_edit_final_budget }, () => {
                                //this.getRecordingOrchestralCameraPerformersData(this.state.show_season_id, musicBudgetRecordList)
                                // this.getRecordingOrchestralCameraPerformersData(this.state.show_season_id, this.state.musicBudgetRecordList[0].show_season_tv_creative_music_budget_id);

                            })
                        } else {
                            let budgetData =  response.data?.find(item => item?.show_season_episode_id === this.state.episode_id)
                            this.setState({ musicBudgetRecordList, allVersionsList: formattedList, versionOptions: [], version: null, selectedmusicBudgetRecord:budgetData ? budgetData : {}, is_final_array, can_edit_final_budget });
                        }
                    }
                } else {
                    this.setState({ musicBudgetRecordList: [], allVersionsList: [], versionOptions: [], version: null, selectedmusicBudgetRecord: {} });
                }
            },
                (err) => {
                    console.log("Error in fetching Version Options:", err)
                })
    }

    postNotes = (tableName) => {
        this.setState({ postNotesLoader: true });

        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonTvCreativeMusicBudget`, this.state.headerData, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ postNotesLoader: false });
                if (response.data.error) {
                    cosole.log("Error in Post call", response.data.error)
                    this.setState({ openNotification: 'error'})
                }
                else {
                    this.handleNotes(tableName);
                    if (this.props.is_pilot === 1) {
                        this.getHeaderData(this.state.show_season_id, null, this.state.selectedmusicBudgetRecord?.version);
                    } else {
                        this.getHeaderData(this.state.show_season_id, this.state.selectedmusicBudgetRecord?.show_season_episode_id, this.state.selectedmusicBudgetRecord?.version);
                    }
                    this.setState({ openNotification: 'success'}) 
                }
            },
                (err) => {
                    this.setState({ openNotification: 'error'})
                    console.log("Error in posting Notes:", err)
                })
    }

    onChange = (field, value) => {
        let selectedmusicBudgetRecord;
        if (field === "episode") {
            let filteredVersionList = this.state.allVersionsList.filter(item => item.version_episode_id === value);
            selectedmusicBudgetRecord = this.state.musicBudgetRecordList.filter(item => item.show_season_episode_id === value);
            if (filteredVersionList.length > 0) {
                let episodeObj = this.state.episodeOptions?.filter(item => item.value === value);
                episodeNumber = episodeObj.length > 0 ? episodeObj[0].label : null;
                this.getVersions(this.state.show_season_id, value);
                this.setState({ episode_id: value, version: filteredVersionList[0]?.value, versionOptions: filteredVersionList, selectedmusicBudgetRecord: selectedmusicBudgetRecord?.length > 0 ? selectedmusicBudgetRecord[0] : {} });
                //this.getHeaderData(this.state.show_season_id, value, selectedmusicBudgetRecord[0].version);
                //this.getRecordingOrchestralCameraPerformersData(this.state.show_season_id, selectedmusicBudgetRecord[0].show_season_tv_creative_music_budget_id);
            } else {
                this.setState({ episode_id: value, version: null, versionOptions: [], selectedmusicBudgetRecord: {} })
            }

        }
        if (field === "version") {
            if (value === "newbtn") {
                this.handleShowBudgetTabModal("New", "version", "fromdropdown");
            } else {
                selectedmusicBudgetRecord = this.state.musicBudgetRecordList.filter(item => item.version === value);
                this.setState({ version: value, selectedmusicBudgetRecord: selectedmusicBudgetRecord.length > 0 ? selectedmusicBudgetRecord[0] : {}})
                this.getHeaderData(this.state.show_season_id, this.state.episode_id, value);
                //this.getRecordingOrchestralCameraPerformersData(this.state.show_season_id, selectedmusicBudgetRecord[0].show_season_tv_creative_music_budget_id);
                //this.getCameraPerformersData(this.state.show_season_id);
            }
        }
    }

    getHeaderData = (show_season_id, show_season_episode_id, version) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonTvCreativeMusicBudgetBasedOnVersion?show_season_id=${show_season_id}&show_season_episode_id=${show_season_episode_id}&version=${version}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let formattedList = response?.data[0];
                this.setState({ headerData: formattedList, selectedmusicBudgetRecord: formattedList }, () => {
                    this.getRecordingOrchestralCameraPerformersData(this.state.show_season_id, this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id);
                })
            },
                (err) => {
                    console.log("Error in fetching Header Data:", err)
                })
    }

    getCameraPerformersData = (show_season_id, show_season_tv_creative_music_budget_id, loader = true) => {
        this.setState({ loadingCameraPerformers: loader })
        let tempCameraConfigData = { ...this.state.creativeBudgetOnCameraConfig };
        tempCameraConfigData.headings[2].selectOptions = [...this.state.seasonUnionList];
        tempCameraConfigData.headings[3].selectOptions = [...this.state.detailCodeOptions];
        console.log("tempUnionData in getCameraPerformersData", tempCameraConfigData);
        this.setState({ creativeBudgetOnCameraConfig: tempCameraConfigData }, () => {
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
                `/showSeasonOnCameraPerformers?show_season_id=${show_season_id}&show_season_tv_creative_music_budget_id=${show_season_tv_creative_music_budget_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                .then(response => {
                    let formattedList = response?.data;
                    let cameraPerformersData = formattedList.map(item => {
                        let rateHourQty = (item?.rate ? Number(item?.rate) : 0) * (item?.hours ? Number(item?.hours) : 0) * (item?.qty ? Number(item?.qty) : 0);
                        let cost = rateHourQty + ((item?.add_percentage ? (Number(item?.add_percentage) / 100) : 0) * rateHourQty);
                        let fringe = cost * (item?.fringe_percentage ? (item?.fringe_percentage / 100) : 0);
                        return {
                            "show_season_tvmb_on_camera_performers_id": item.show_season_tvmb_on_camera_performers_id,
                            "is_include": item?.is_include,
                            "from_version": item?.from_version ? item.from_version : null,
                            "union_id": item?.union_id ? item.union_id : null,
                            // "union_name": item?.union_name ? item.union_name : null,
                            "budget_detail_code_id": item?.budget_detail_code_id ? item.budget_detail_code_id : null,
                            // "detail_id": item?.detail_id ? item.detail_id : null,
                            "description": item?.description ? item.description : null,
                            "notes": item?.notes ? item.notes : null,
                            "rate": item?.rate ? parseFloat(item.rate).toFixed(2) : 0,
                            "hours": item?.hours ? item.hours : 0,
                            "qty": item?.qty ? item.qty : 0,
                            "add_percentage": item?.add_percentage ? item.add_percentage : 0,
                            "cost": "$" + (parseFloat(cost).toFixed(2)).toString(),
                            "is_fr": item?.is_fr ? item.is_fr : 0,
                            "fringe": item?.is_fr === 1 ? "$" + (parseFloat(0).toFixed(2)).toString() : (item?.fringe_percentage ? "$" + (parseFloat(fringe).toFixed(2)).toString() : "$" + (parseFloat(0).toFixed(2)).toString()),
                            "fringe_percentage": item?.fringe_percentage ? item.fringe_percentage : 0,
                            "total_cost": item.is_fr === 1 ? "$" + (parseFloat(cost).toFixed(2)).toString() : "$" + (parseFloat(fringe + cost).toFixed(2)).toString(),
                            "division_id": item?.division_id ? item.division_id : null,
                            "is_active": item?.is_active ? item.is_active : null,
                            "season_id": item?.season_id ? item.season_id : null,
                            "show_id": item?.show_id ? item.show_id : null,
                            "show_season_id": item?.show_season_id ? item.show_season_id : null,
                            "tenant_id": item?.tenant_id ? item.tenant_id : null
                        }
                    })
                    this.setState({ cameraPerformersData: cameraPerformersData, loadingCameraPerformers: false, isPosting: false })
                    // this.formatTableData("cameraPerformersData", cameraPerformersData, this.state.unionOptions)
                    // this.getUnion();
                },
                    (err) => {
                        this.setState({ loadingCameraPerformers: false })
                        console.log("Error in fetching Camera Performers Data Data:", err)
                    })
        });

    }

    postCameraPerformers = (data, postFlag) => {
        data["show_season_tv_creative_music_budget_id"] = this.state.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id;
        this.setState({ isPosting: postFlag })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonOnCameraPerformers`, data, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (!response.data.error) {
                    this.setState({ openNotification: 'success'})
                    this.getCameraPerformersData(this.state.show_season_id, this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id, false);
                }
                else {
                    this.setState({ isPosting: false })
                    this.setState({ openNotification: 'error'})
                }
            },
                (err) => {
                    this.setState({ isPosting: false })
                    this.setState({ openNotification: 'error'})
                    console.log("Post agency details error: " + err);
                });
    }

    postRecordingsData = (data, postFlag) => {
        data["show_season_tv_creative_music_budget_id"] = this.state.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id;
        this.setState({ isPostingRecordings: postFlag })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonRecordings`, data, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (!response.data.error) {
                    this.setState({ openNotification: 'success'})
                    this.getRecordingsData(this.state.show_season_id, this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id, false);
                }
                else {
                    this.setState({openNotification: 'error', isPostingRecordings: false })
                }
            },
                (err) => {
                    this.setState({openNotification: 'error', isPostingRecordings: false })
                    console.log("Post agency details error: " + err);
                });
    }

    formatTableData = (tableName, tableData, unionOptions) => {
        if (tableName === "cameraPerformersData") {
            let renderData = tableData.map(item => (item.unions = unionOptions.find(data => data.label === item.unions)?.value));
            this.setState({ cameraPerformersData: tableData })
        }
        else if (tableName === "recordingsData") {
            let renderData = tableData.map(item => (item.unions = unionOptions.find(data => data.label === item.unions)?.value));
            this.setState({ recordingsData: tableData })
        }
        else if (tableName === "orchestralData") {
            let renderData = tableData.map(item => (item.unions = unionOptions.find(data => data.label === item.unions)?.value));
            this.setState({ orchestralData: tableData })
        }
    }
    getBudgetCodeDetail = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=BUDGET_DETAIL_CODE&searchString=null`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let formattedList = response?.data.map(item => ({ value: item.budget_detail_code_id, label: item.detail_code, is_active: item.is_active }));
                this.setState({ detailCodeOptions: formattedList });
            },
                (err) => {
                    console.log("Error in fetching budget detail Data:", err)
                })
    }
    getUnion = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=UNION&searchString=null`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let formattedList = response?.data.map(item => ({ value: item.union_id, label: item.union_name, is_active: item.is_active }))
                this.setState({ unionOptions: formattedList })

            },
                (err) => {
                    console.log("Error in fetching Header Data:", err)
                })
    }

    getRecordingsData = (show_season_id, show_season_tv_creative_music_budget_id, loader = true) => {
        this.setState({ loadingRecordingData: loader });
        let tempRecordingConfigData = { ...this.state.creativeBudgetRecordingConfig };
        tempRecordingConfigData.headings[2].selectOptions = [...this.state.seasonUnionList];
        tempRecordingConfigData.headings[3].selectOptions = [...this.state.detailCodeOptions];
        console.log("tempUnionData in getCameraPerformersData", tempRecordingConfigData);
        this.setState({ creativeBudgetRecordingConfig: tempRecordingConfigData }, () => {
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
                `/showSeasonRecordings?show_season_id=${show_season_id}&show_season_tv_creative_music_budget_id=${show_season_tv_creative_music_budget_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                .then(response => {
                    let formattedList = response?.data
                    let recordingsData = formattedList.map(item => {
                        let rateHourQty = (item?.rate ? Number(item?.rate) : 0) * (item?.hours ? Number(item?.hours) : 0) * (item?.qty ? Number(item?.qty) : 0);
                        let cost = rateHourQty + ((item?.add_percentage ? (Number(item?.add_percentage) / 100) : 0) * rateHourQty);
                        let fringe = cost * (item?.fringe_percentage ? (item?.fringe_percentage / 100) : 0);
                        return {
                            "show_season_tvmb_recordings_id": item?.show_season_tvmb_recordings_id ? item.show_season_tvmb_recordings_id : null,
                            "is_include": item?.is_include,
                            "from_version": item?.from_version ? item.from_version : null,
                            "union_id": item?.union_id ? item.union_id : null,
                            "budget_detail_code_id": item?.budget_detail_code_id ? item.budget_detail_code_id : null,
                            // "detail_id": item?.detail_id ? item.detail_id : null,
                            "description": item?.description ? item.description : null,
                            "notes": item?.notes ? item.notes : null,
                            "rate": item?.rate ? parseFloat(item.rate).toFixed(2) : 0,
                            "hours": item?.hours ? item.hours : 0,
                            "qty": item?.qty ? item.qty : 0,
                            "add_percentage": item?.add_percentage ? item.add_percentage : 0,
                            "cost": "$" + (parseFloat(cost).toFixed(2)).toString(),
                            "is_fr": item?.is_fr ? item.is_fr : 0,
                            "fringe": item?.is_fr === 1 ? "$" + (parseFloat(0).toFixed(2)).toString() : (item?.fringe_percentage ? "$" + (parseFloat(fringe).toFixed(2)).toString() : "$" + (parseFloat(0).toFixed(2)).toString()),
                            "fringe_percentage": item?.fringe_percentage ? item.fringe_percentage : 0,
                            "total_cost": item.is_fr === 1 ? "$" + (parseFloat(cost).toFixed(2)).toString() : "$" + (parseFloat(fringe + cost).toFixed(2)).toString(),
                            "division_id": item?.division_id ? item.division_id : null,
                            "is_active": item?.is_active ? item.is_active : null,
                            "season_id": item?.season_id ? item.season_id : null,
                            "show_id": item?.show_id ? item.show_id : null,
                            "show_season_id": item?.show_season_id ? item.show_season_id : null,
                            "tenant_id": item?.tenant_id ? item.tenant_id : null
                        }
                    })
                    this.setState({ recordingsData: recordingsData, loadingRecordingData: false, isPostingRecordings: false })
                    // this.formatTableData("recordingsData", recordingsData, this.state.unionOptions)
                },
                    (err) => {
                        this.setState({ loadingRecordingData: false })
                        console.log("Error in fetching Recordings Data:", err)
                    })
        })
    }

    getOrchestralSessions = (show_season_id, show_season_tv_creative_music_budget_id, loader = true) => {
        this.setState({ loadingOrchestralData: loader });
        let tempOrchestralConfigData = { ...this.state.creativeBudgetOrchestralConfig };
        tempOrchestralConfigData.headings[2].selectOptions = [...this.state.seasonUnionList];
        tempOrchestralConfigData.headings[3].selectOptions = [...this.state.detailCodeOptions];
        console.log("tempUnionData in getCameraPerformersData", tempOrchestralConfigData);
        this.setState({ creativeBudgetOrchestralConfig: tempOrchestralConfigData }, () => {
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
                `/showSeasonOrchestralSessions?show_season_id=${show_season_id}&show_season_tv_creative_music_budget_id=${show_season_tv_creative_music_budget_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                .then(response => {
                    let formattedList = response?.data
                    let orchestralData = formattedList.map(item => {
                        let rateHourQty = (item?.rate ? Number(item?.rate) : 0) * (item?.hours ? Number(item?.hours) : 0) * (item?.qty ? Number(item?.qty) : 0);
                        let cost = rateHourQty + ((item?.add_percentage ? (Number(item?.add_percentage) / 100) : 0) * rateHourQty);
                        let fringe = cost * (item?.fringe_percentage ? (item?.fringe_percentage / 100) : 0);
                        return {
                            "show_season_tvmb_orchestral_sessions_id": item?.show_season_tvmb_orchestral_sessions_id ? item.show_season_tvmb_orchestral_sessions_id : null,
                            "is_include": item?.is_include,
                            "from_version": item?.from_version ? item.from_version : null,
                            "union_id": item?.union_id ? item.union_id : null,
                            "budget_detail_code_id": item?.budget_detail_code_id ? item.budget_detail_code_id : null,
                            // "detail_id": item?.detail_id ? item.detail_id : null,
                            "description": item?.description ? item.description : null,
                            "notes": item?.notes ? item.notes : null,
                            "rate": item?.rate ? parseFloat(item.rate).toFixed(2) : 0,
                            "hours": item?.hours ? item.hours : 0,
                            "qty": item?.qty ? item.qty : 0,
                            "add_percentage": item?.add_percentage ? item.add_percentage : 0,
                            "cost": "$" + (parseFloat(cost).toFixed(2)).toString(),
                            "is_fr": item?.is_fr ? item.is_fr : 0,
                            "fringe": item?.is_fr === 1 ? "$" + (parseFloat(0).toFixed(2)).toString() : (item?.fringe_percentage ? "$" + (parseFloat(fringe).toFixed(2)).toString() : "$" + (parseFloat(0).toFixed(2)).toString()),
                            "fringe_percentage": item?.fringe_percentage ? item.fringe_percentage : 0,
                            "total_cost": item.is_fr === 1 ? "$" + (parseFloat(cost).toFixed(2)).toString() : "$" + (parseFloat(fringe + cost).toFixed(2)).toString(),
                            "division_id": item?.division_id ? item.division_id : null,
                            "is_active": item?.is_active ? item.is_active : null,
                            "season_id": item?.season_id ? item.season_id : null,
                            "show_id": item?.show_id ? item.show_id : null,
                            "show_season_id": item?.show_season_id ? item.show_season_id : null,
                            "tenant_id": item?.tenant_id ? item.tenant_id : null
                        }
                    })
                    this.setState({ orchestralData: orchestralData, loadingOrchestralData: false, isPostingOrchestral: false })
                    this.formatTableData("orchestralData", orchestralData, this.state.unionOptions)
                },
                    (err) => {
                        this.setState({ loadingOrchestralData: false })
                        console.log("Error in fetching orchestral Data:", err)
                    })
        })

    }

    postOrchestralData = (data, postFlag) => {
        data["show_season_tv_creative_music_budget_id"] = this.state.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id;
        this.setState({ isPostingOrchestral: postFlag })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonOrchestralSessions`, data, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (!response.data.error) {
                    this.setState({openNotification: "success"})
                    this.getOrchestralSessions(this.state.show_season_id, this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id, false);
                    // this.setState({ isPostingOrchestral: false })
                    console.log("post response", response);
                }
                else {
                    this.setState({ openNotification:"error",isPostingOrchestral: false })
                }
            },
                (err) => {
                    this.setState({openNotification:"error", isPostingOrchestral: false })
                    console.log("Post agency details error: " + err);
                });
    }
    // notificationComponent = (value, mode = "fail") => {
    //     switch (mode) {
    //         case "success": return this.setState({ showSuccess: value });
    //         case "fail": return this.setState({ showFail: value });
    //     }
    // // }
    // handleResetNotify = () => {
    //     this.setState({ showFail: false, showSuccess: false , copyVersionError : null});
    // }

    handleClickFR = (tableName, data) => {
        let rateHourQty = (data?.rate ? Number(data?.rate) : 0) * (data?.hours ? Number(data?.hours) : 0) * (data?.qty ? Number(data?.qty) : 0);
        let cost = rateHourQty + ((data?.add_percentage ? (Number(data?.add_percentage) / 100) : 0) * rateHourQty);
        let fringe = cost * (data?.fringe_percentage ? (data?.fringe_percentage / 100) : 0);
        if (data.is_fr === 1) {
            data.cost = cost;
            data.fringe = fringe;
            data.total_cost = fringe + cost;
            data.is_fr = 0;
        } else {
            data.cost = cost;
            data.fringe = 0;
            data.total_cost = cost;
            data.is_fr = 1;
        }
        // data.unions = this.state.unionOptions.find(item => item.value === data.unions)?.label
        data.is_include = data.is_include;
        if (tableName === "cameraPerformersData") {
            this.postCameraPerformers(data, data?.show_season_tvmb_on_camera_performers_id)
        }
        else if (tableName === "recordingsData") {
            this.postRecordingsData(data, data?.show_season_tvmb_recordings_id)
        }
        else if (tableName === "orchestralData") {
            this.postOrchestralData(data, data?.show_season_tvmb_orchestral_sessions_id)
        }
    }

    handleNotes = (tableName) => {
        if (tableName === "onCameraPerformers") {
            this.setState({ showNotesOfOnCameraPerformers: !this.state.showNotesOfOnCameraPerformers })
        } else if (tableName === "recordingsData") {
            this.setState({ showNotesOfRecordings: !this.state.showNotesOfRecordings })
        } else if (tableName === "orchestralSessions") {
            this.setState({ showOrchestralSessions: !this.state.showOrchestralSessions })
        }
    }

    handleNotesChange = (value, tableName) => {
        let headerData = { ...this.state.headerData };
        switch (tableName) {
            case "onCameraPerformers":
                headerData.on_camera_performers_notes = value
                break;
            case "recordingsData":
                headerData.recordings_notes = value
                break;
            case "orchestralSessions":
                headerData.orchestral_sessions_notes = value
                break;
        }
        this.setState({ headerData: headerData })
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            // canGeneratePDF = permissions.filter((obj, index) => obj.permission_name === "Can generate a PDF of a budget");
            // canGeneratePDF = canGeneratePDF.length > 0 ? true : false;
            budgetDetailsPermission = permissions.find((item) => item.permission_name === "TV Cr. Music Budget");
            canEditBudgetDetails = permissions.filter((obj) => obj.permission_name === "TV Cr. Music Budget" && obj.view === 1 && obj.edit === 1);
            canEditBudgetDetails = canEditBudgetDetails.length > 0 ? true : false;
            if (canEditBudgetDetails === false) {
                let tempConfig = creativeBudgetOnCameraConfig;
                let actionsToRemove = ["pen"];
                let tempActions = tempConfig.actions.filter(item => !actionsToRemove.includes(item));
                creativeBudgetOnCameraConfig.actions = tempActions;
                let tempConfig1 = creativeBudgetRecordingConfig;
                let actionsToRemove1 = ["pen"];
                let tempActions1 = tempConfig1.actions.filter(item => !actionsToRemove1.includes(item));
                creativeBudgetRecordingConfig.actions = tempActions1;
                let tempConfig2 = creativeBudgetOrchestralConfig;
                let actionsToRemove2 = ["pen"];
                let tempActions2 = tempConfig2.actions.filter(item => !actionsToRemove2.includes(item));
                creativeBudgetOrchestralConfig.actions = tempActions2;
                this.setState({ creativeBudgetOnCameraConfig, creativeBudgetRecordingConfig, creativeBudgetOrchestralConfig });
            }
        }
    }
    handleCombineVersionPopup = (e) => {
        this.setState({
            setPopoverVersion: e.currentTarget
        })
    }
    handleCopyVersionPopup = (e) => {
        this.setState({
            setCopyVersionPopup: e.currentTarget
        })
    }
    handleCloseCombineVersionPopup = () => {
        this.setState({
            setPopoverVersion: null
        })
    };
    handleCloseCopyVersionPopup = () => {
        this.setState({
            setCopyVersionPopup: null
        })
    }
    handleEditUnionTabModal = (mode) => {
        if (mode === "New") {
            this.props.handleEditUnionTabModal(mode);
        }
    }
    handleShowBudgetTabModal = (mode, origin, clickedFrom) => {
        this.setState({ showEditBudgetTabModal: true, mode, origin, clickedFrom });
    }
    handleHideBudgetTabModal = () => {
        this.setState({ showEditBudgetTabModal: false });
    }
    handleShowExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: true });
    }
    handleHideExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: false });
    }
    getRecordingOrchestralCameraPerformersData = (show_season_id, show_season_tv_creative_music_budget_id) => {
        this.getRecordingsData(show_season_id, show_season_tv_creative_music_budget_id);
        this.getOrchestralSessions(show_season_id, show_season_tv_creative_music_budget_id);
        this.getCameraPerformersData(show_season_id, show_season_tv_creative_music_budget_id);
    }
    componentDidUpdate(prevProps, prevState) {
        // if (this.state.seasonUnionList !== prevState.seasonUnionList) {
        //     this.getRecordingOrchestralCameraPerformersData(this.state.show_season_id, this.state.selectedmusicBudgetRecord.show_season_tv_creative_music_budget_id);
        // }
        if (this.props.initialRecordData.unions !== prevProps?.initialRecordData.unions) {
            console.log("this.props.initialRecordData.unions", this.props.initialRecordData.unions);
            console.log("this.state.seasonUnionList", this.state.seasonUnionList);
            let seasonUnionList = [];
            this.props.initialRecordData?.unions?.map(item => seasonUnionList.push({ "value": item?.union_id, "label": item?.union_name }));
            this.setState({ seasonUnionList });
            if (this.props.initialRecordData.is_pilot === 1) {
                this.getVersions(this.state.show_season_id, null);
            }
            if (this.props.initialRecordData.is_pilot === 0 || this.props.initialRecordData.is_pilot === null) {
                this.getEpisodesData(this.state.show_season_id);
            }
        }
    }
    onCheckboxChangeCombineVersion = (versionValue, checkedValue) => {
        if (checkedValue === true) {
            combineVersionArray.push(versionValue);
        }
        if (checkedValue === false) {
            let index = combineVersionArray.indexOf(versionValue)
            combineVersionArray.splice(index, 1);
        }
    }
    onCheckBoxChange = (field, value) => {
        let is_final = value === true ? 1 : 0;
        let headerData = { ...this.state.headerData }
        headerData["is_final_budget"] = is_final;
        this.setState((prevState) => ({
            headerData: {
                ...prevState.headerData,
                ["is_final_budget"]: is_final,
                ["is_final_budget_update "]: is_final
            },
            isFinalBudgetLoading: true
        }));
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonTvCreativeMusicBudget`,
            headerData,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(
                (response) => {
                    console.log("response", response)
                    if (response.data.error) {
                        this.setState({openNotification: "error", isFinalBudgetLoading: false })
                    } else {
                        this.setState({openNotification:"success", isFinalBudgetLoading: false });
                        console.log("post response", response.data);
                        this.getVersions(headerData.show_season_id, headerData.show_season_episode_id)
                    }
                },
                (err) => {
                    this.setState({ openNotification:"error",isFinalBudgetLoading: false, });
                }
            )
    }
    handleCombine = (pilotType) => {
        this.setState({ isPostingCombineVersion: true });
        let combineVersionPostObj = {};
        let tempCombineVersion = [];
        let selected_versions = "INCLUDES VERSION ";
        combineVersionArray.map((item, index) => {
            tempCombineVersion.push({ "version": item });
            selected_versions = selected_versions.concat(item);
            selected_versions = selected_versions.concat(index !== combineVersionArray.length - 1 ? "," : "")
        })
        combineVersionPostObj = {
            show_season_id: this.state.show_season_id,
            season_id: this.props?.initialRecordData?.season_id,
            show_id: this.props?.initialRecordData?.show_id,
            combine_versions: [...tempCombineVersion],
            version_notes: selected_versions
        }
        if (pilotType === "non-pilot") {
            combineVersionPostObj["show_season_episode_id"] = this.state.episode_id;
        }
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonCombineTvCreativeMusicBudgetVersion`, combineVersionPostObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isPostingCombineVersion: false });
                if (!response.data.error) {
                    this.setState({openNotification:"success"})
                    this.getVersions(this.state.headerData.show_season_id, this.state.headerData.show_season_episode_id);
                    console.log("post response", response);
                    combineVersionArray = [];
                    this.handleCloseCombineVersionPopup();
                }
                else {
                    this.setState({ openNotification:"error",isPostingOrchestral: false });
                    combineVersionArray = [];
                }
            },
                (err) => {
                    this.setState({ openNotification:"error",isPostingOrchestral: false })
                    combineVersionArray = [];
                    console.log("Post agency details error: " + err);
                });
    }
    downloadUrl = (url, identifier = null) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                //link.href = window.URL.createObjectURL(blob);
                link.href = url
                //link.setAttribute('download', load?.fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                if (identifier === "pdf") {
                    this.setState({ isPdfPosting: false });
                }
                 else {
                    this.setState({ isExcelPosting: false });
                    this.handleHideExcelAndDocModal()
                }
                this.setState({openNotification:"success"})
            })
        }
    }
    generateReport = (reportName, searchPayload) => {
        if (!searchPayload || !reportName) {
            return;
        }
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        load = {
            'payload': exportPDForExcel,
            'reportName': reportName,
            'fileName': "Creative Budget Report",
            'searchJson': encryptTedStr,
            'showHtml': 0,
            'encryptionType': 1,
            'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
            'divisionId': this.props?.division_id,
        }
        return ClearTrackService.getDataWS(load)
        // let queryParams = `reportName=${reportName}&searchJson=${encryptTedStr}&fileName=TV Creative Music Budget Report`;
        // return ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/exportPdf?${queryParams}`, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id, true)
    }
    isOpenExisting=(data)=>{
        this.setState({isOpenExisting: data})
      }
    generatePdf = (identifier) => {
        exportPDForExcel = null;
        let reportName;
        let pdfPayload = {
            "show_season_tv_creative_music_budget_id": this.state?.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id,
            "is_pilot": this.props.is_pilot,
            "is_box_upload": 1,
            "report_type": "TVMB_BUDGET" 
        };
        if (identifier === "pdf") {
            this.setState({ postInitiated: true, isPdfPosting: true });
            reportName = "rpttvcreativemusicbudget";
            exportPDForExcel = "exportPdf";
        }else if (identifier === "email") {
            this.setState({ postInitiated: true, isEmailLoader: true });
            pdfPayload["send_email"] = true,
            pdfPayload["is_draft"] = true, 
            pdfPayload["has_attachment"] = true,
            pdfPayload["report_type"] = "TVMB_BUDGET",
            reportName = "rpttvcreativemusicbudget";
            exportPDForExcel = "exportPdf";
            pdfPayload["subject"] = "Tv Creative music budegt draft"
            pdfPayload["to_address"] = ["madhuri.kota@wbconsultant.com"]
            pdfPayload["cc_address"] = []
            pdfPayload["message"] = "Draft Example with attachment"
            pdfPayload["has_attachment"] = true
            pdfPayload["is_draft"] = true
            pdfPayload["attachments"]= null
            Object.keys(pdfPayload).map(obj => {
                if (pdfPayload[obj] === undefined) {
                    pdfPayload[obj] = null;
                }
            })
        }
        else {
            this.setState({ postInitiated: true, isExcelPosting: true });
            reportName = "rpttvcreativemusicbudgetexcel";
            exportPDForExcel = "exportExcel";
            pdfPayload["isOpenExisting"] = this.state.isOpenExisting ? 1 : 0
        }
       
        this.generateReport(reportName, pdfPayload).then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                    if (identifier === "pdf") {
                        this.setState({ isPdfPosting: false });
                    } else {
                        this.setState({ isExcelPosting: false });
                    }
                    if (response?.body && identifier != "email") {
                        this.downloadUrl(response.body, identifier);
                    } else {
                       this.setState({openNotification:"success",anyErrorMessage:"Emails have been saved to your Drafts"})
                    }
                } else{
                    this.setState({isPdfPosting: false, isExcelPosting: false, isEmailLoader: false})
                }
            }
        })
    }
    // .then(response => {
    //     if (response.data.error) {
    //         this.setState({ isPdfPosting: false });
    //         this.notificationComponent(true, "fail");
    //         // this.props.fieldChanged(true, "reportNotFound");
    //     }
    //     else {
    //         let url = response.data;
    //         this.setState({ isPdfPosting: false });
    //         this.downloadUrl(url, "TV Creative Music Budget Report")
    //     }
    // }, (err) => {
    //     this.setState({ isPdfPosting: false });
    //     this.notificationComponent(true, "fail");
    //     console.log(err)
    //     // this.props.fieldChanged(true, "reportFail");
    // })
    copyVersion = () => {
        console.log("copyVersion");
    }
    onDropdownChange(fieldChanged, keyToModify, valPassed) {
        switch (fieldChanged) {
            case 'seasonChanged':
                console.log("keyToModify", keyToModify, "valPassed", valPassed);
                seasonSelectedObj = this.props?.initialRecordData?.seasons?.filter(item => item.season_id === valPassed);
                if (seasonSelectedObj.length > 0) {
                    seasonSelectedObj = seasonSelectedObj[0];
                    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
                        `/showSeasonEpisodes?show_season_id=${seasonSelectedObj?.show_season_id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                        .then(response => {
                            let formattedList = response?.data?.map(item => ({ value: item.show_season_episode_id, label: item.episode_number }));
                            episodeNumber = formattedList.length > 0 ? formattedList[0].label : null;
                            //this.props.handleEpisodesArray(formattedList);
                            this.setState((prevState) => ({
                                copyPayload: {
                                    ...prevState.copyPayload,
                                    "from_show_season_id": this.props.show_season_id,
                                    "from_show_season_tv_creative_music_budget_id": this.state?.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id,
                                    "to_show_season_id": seasonSelectedObj?.show_season_id ? seasonSelectedObj?.show_season_id : null,
                                    "to_season_id": valPassed,
                                    "to_show_id": seasonSelectedObj?.show_id ? seasonSelectedObj?.show_id : null,
                                }, copyEpisodeOptions: formattedList
                            }));
                            // this.setState({ copyEpisodeOptions: formattedList });
                        },
                            (err) => {
                                console.log("Error in fetching Episodes:", err)
                            })

                } else {
                    seasonSelectedObj = null;
                }


                break;
            case 'episodeChanged':

                this.setState((prevState) => ({
                    copyPayload: {
                        ...prevState.copyPayload,
                        "to_show_season_episode_id": valPassed
                    },
                }));
                break;
        }

    }
    handleCopySubmit = () => {
        this.setState({ isCopyPosting: true });
        console.log("this.state.copyPayload", this.state.copyPayload);
        let postData = null
        if (this.props?.is_pilot === 0) {
            postData = { ...this.state.copyPayload }
        } else {
            postData = {
                "from_show_season_id": this.props.show_season_id,
                "from_show_season_tv_creative_music_budget_id": this.state?.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id,
                "to_show_season_id": this.props.show_season_id,
                "to_season_id": this.props?.season_id,
                "to_show_id": this.props?.show_id,
                "to_show_season_episode_id": null
            }
        }
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/copyShowSeasonTvCreativeMusicBudget`, postData, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isCopyPosting: false, setCopyVersionPopup: null, setCopyVersionPopupPilot: null });
                if (response.data.error) {
                    this.setState({copyVersionError : response.data?.message, openNotification:"error"})
                }
                else {
                    // if (this.props.is_pilot === 1) {
                    //     this.getHeaderData(this.state.show_season_id, null, this.state.selectedmusicBudgetRecord?.version);
                    // } else {
                    //     this.getHeaderData(popover,this.state.show_season_id, this.state.selectedmusicBudgetRecord?.show_season_episode_id, this.state.selectedmusicBudgetRecord?.version);
                    // }
                    if (this.props.is_pilot === 1) {
                        this.getVersions(this.state.show_season_id, null);
                    }
                    if (this.props.is_pilot === 0 || this.props.is_pilot === null) {
                        this.getEpisodesData(this.state.show_season_id, this.state.episode_id);
                    }
                    this.setState({openNotification:"success"})
                }
            },
                (err) => {
                    this.setState({openNotification:"error"})
                })
    }
    render() {
        console.log("this.props.initialRecordData in tvcr", this.props.initialRecordData);
        // let severity = this.state.showSuccess ? "success" : this.state.showFail ? "error" : "";
        // let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
        let openCamera = Boolean(this.state.showNotesOfOnCameraPerformers);
        let openRecordings = Boolean(this.state.showNotesOfRecordings);
        let openOrchestral = Boolean(this.state.showOrchestralSessions);
        let id = openCamera ? 'simple-popover' : openRecordings ? 'simple-popover' : undefined;
        creativeBudgetOnCameraConfig.headings[2].selectOptions = this.state.seasonUnionList;
        // let messageNotification = this.state.showSuccess ? "Successfull" : this.state.showFail ? "Fail" : "";
        // let severity = this.state.showFail ? "fail" : this.state.showSuccess ? "success" : "";
        return (
            <div>
                <NotificationComponent
                    open={this.state.openNotification?.length > 0 ? true : false}
                    message={this.state.anyErrorMessage ? this.state.anyErrorMessage  : this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                    severity={this.state.openNotification || ''}
                    handleResetNotify={() => this.setState({ openNotification: null ,anyErrorMessage : null})}
                />
                {this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                        <MDBCol className="CreativeBudget-Container">
                            <MDBRow className="buget-card-p">
                                <MDBCard>
                                    <MDBCardBody>
                                        <MDBCardText>
                                            {(this.props.initialRecordData?.unions?.length === 0) ? <>
                                                <p>Please add union by clicking "Actions" button present on top and select "Add Unions".</p>
                                            </> : <>
                                                    {/* for pilot season code starts */}
                                                    {this.props.is_pilot === 1 && <>
                                                        {(this.state?.allVersionsList?.length === 0) ?
                                                            <>
                                                                <BasicButton text="Create New Version" onClick={() => this.handleShowBudgetTabModal("New", "version", "fromEmptyVersion")} />
                                                            </>
                                                            :
                                                            <>
                                                                <MDBRow>
                                                                    {this.state?.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id && <div className="pdfFileContainer">
                                                                        <BasicButton
                                                                            icon={"copy"}
                                                                            variant={"outlined"}
                                                                            text={" "}
                                                                            onClick={(e) => this.setState({
                                                                                setCopyVersionPopupPilot: e.currentTarget
                                                                            })}
                                                                        />
                                                                        <Popover
                                                                            open={this.state.setCopyVersionPopupPilot}
                                                                            anchorEl={this.state.setCopyVersionPopupPilot}
                                                                            onClose={() => {
                                                                                this.setState({
                                                                                    setCopyVersionPopupPilot: null
                                                                                })
                                                                            }}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            }} >
                                                                            <MDBRow className="popoverRightsWrapper">
                                                                                <div className="combineVersionHeading"> Create new version from version {this.state.version} </div>
                                                                            </MDBRow>
                                                                            <MDBRow className='mt-2'>
                                                                                <MDBCol md={10}></MDBCol>
                                                                                <MDBCol md={2} className="saveCancelWrapper">
                                                                                    <BasicButton text={this.state.isCopyPosting ?
                                                                                        <CircularProgress color="inherit" size={20} /> : "Save"}
                                                                                        onClick={this.handleCopySubmit}/>
                                                                                    <BasicButton
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                setCopyVersionPopupPilot: null
                                                                                            })
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        text={"Cancel"}
                                                                                    />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                        </Popover>
                                                                        {/* {canGeneratePDF &&
                                                                            <> */}
                                                                                <BasicButton
                                                                                    icon={this.state.isEmailLoader ? " " : "envelope"}
                                                                                    text={this.state.isEmailLoader ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                    variant={"outlined"}
                                                                                    onClick={() => this.generatePdf("email")}
                                                                                />
                                                                                <BasicButton
                                                                                    icon={this.state.isExcelPosting ? " " : "file-excel"}
                                                                                    text={this.state.isExcelPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                    variant={"outlined"}
                                                                                    onClick={ this.handleShowExcelAndDocModal}
                                                                                />
                                                                                <BasicButton
                                                                                    icon={this.state.isPdfPosting ? " " : "file-pdf"} variant={"outlined"}
                                                                                    text={this.state.isPdfPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                    onClick={() => this.generatePdf("pdf")}
                                                                                />
                                                                            {/* </>
                                                                        } */}
                                                                        </div>}
                                                                </MDBRow>
                                                                <MDBRow>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Season Union(s)</div>
                                                                        {/* {seasonUnionList.map(item => (
                                                                        <div className="sub-text">{`${item},`}</div>
                                                                    ))} */}
                                                                        <div className="sub-text">{this.state.seasonUnionList?.length > 0 ? `${this.state.seasonUnionList.map(item => item?.label).join()}` : "None"}</div>

                                                                    </MDBCol>
                                                                    <MDBCol md={1} className="budget-text">
                                                                        <SelectField id={"Version"}
                                                                            label="Version"
                                                                            options={this.state.allVersionsList}
                                                                            value={this.state.version}
                                                                            onChange={(e) => this.onChange("version", e.target.value)}
                                                                            disabled={false}
                                                                        />
                                                                    </MDBCol>
                                                                    <MDBCol md={4} className="budget-text combineVersionContainer">
                                                                    {budgetDetailsPermission?.edit === 1 &&
                                                                        <>
                                                                        <div>
                                                                            <BasicLabel text={" "} />
                                                                            <BasicButton onClick={this.handleCombineVersionPopup} text={"Combine Versions"} />
                                                                            <Popover
                                                                                open={this.state.setPopoverVersion}
                                                                                anchorEl={this.state.setPopoverVersion}
                                                                                onClose={this.handleCloseCombineVersionPopup}
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'left',
                                                                                }} >
                                                                                <MDBRow className="popoverRightsWrapper">
                                                                                    <div className="combineVersionHeading"> Select versions to combine into a new version. </div>
                                                                                    {this.state.allVersionsList.map((item, index) => (
                                                                                        <>
                                                                                            {index !== this.state.allVersionsList.length - 1 && <BasicCheckbox id={item.value} label={`Version ${item?.value}${item?.label ? " - " + item?.label : ""}`}
                                                                                                onChange={(e) => this.onCheckboxChangeCombineVersion(item.value, e.target.checked)}
                                                                                            />
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                                    <MDBRow className="combineButtonRow">
                                                                                        <MDBCol md={3} ></MDBCol>
                                                                                        <MDBCol md={9} className="saveCancelWrapper">
                                                                                            <div>
                                                                                                <BasicButton
                                                                                                    icon={this.state.isPostingCombineVersion ? " " : "compress-arrows-alt"}
                                                                                                    text={this.state.isPostingCombineVersion ? <CircularProgress color="inherit" size={20} /> : "Combine"}
                                                                                                    variant="contained"
                                                                                                    onClick={() => this.handleCombine("pilot")}
                                                                                                />
                                                                                            </div>
                                                                                            <div>
                                                                                                <BasicButton
                                                                                                    icon={"times"} text=" "
                                                                                                    variant="outlined"
                                                                                                    onClick={this.handleCloseCombineVersionPopup}
                                                                                                />
                                                                                            </div>
                                                                                        </MDBCol>
                                                                                    </MDBRow>
                                                                                </MDBRow>
                                                                            </Popover>
                                                                        </div>
                                                                        <div>
                                                                            <BasicLabel text={" "} />
                                                                            <BasicButton text="Edit Budget Details" onClick={() => this.handleShowBudgetTabModal("Edit", "budget", null)} />
                                                                        </div>
                                                                        <div>
                                                                            {canEditBudgetDetails === false &&
                                                                                <>
                                                                                    <BasicLabel text={"Final Budget"} />
                                                                                    <span>{this.state.detailsToDisplay?.is_final_budget === 1 ? "Yes" : "No"}</span>
                                                                                </>
                                                                            }
                                                                            {canEditBudgetDetails === true &&
                                                                                <>
                                                                                    <BasicLabel text={" "} />
                                                                                    <BasicCheckbox
                                                                                        id={"is_final_budget"}
                                                                                        label={this.state.isFinalBudgetLoading ? <CircularProgress size={18} /> : "Final Budget?"}
                                                                                        className={this.state.can_edit_final_budget === false && this.state.headerData?.is_final_budget === 0 ? "disabled" : null}
                                                                                        checked={this.state.headerData?.is_final_budget}
                                                                                        onChange={this.state.can_edit_final_budget === false && this.state.headerData?.is_final_budget === 0 ? null : (e) => this.onCheckBoxChange("is_final_budget", e.target.checked)}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        </>
                                                                    }
                                                                    </MDBCol>
                                                                    <MDBCol md={2}></MDBCol>
                                                                    <MDBCol md={3} className="budget-text final-budget-text">
                                                                        <BasicLabel text={this.state.prodEntityOfShow} />
                                                                        <span className="error-budget-text">{this.state.allVersionsList?.find(item => item?.value === this.state.headerData?.version && this.state.headerData?.is_final_budget === 1) ? "Budget finalized" : ""} </span>
                                                                        {/* <span className="error-budget-text">{this.state.allVersionsList?.find(item => item?.value === this.state.headerData?.version && this.state.headerData?.is_final_budget === 1) ? "Budget finalized" : "Budget is not finalized, changes are pending."} </span> */}
                                                                    </MDBCol>
                                                                </MDBRow>
                                                                <MDBRow>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Version Description</div>
                                                                        <div className="sub-text">{this.state.headerData?.version_description || "None"}</div>
                                                                    </MDBCol>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Types of Using/Timing</div>
                                                                        <div className="sub-text">{this.state.headerData?.type_of_use || "None"}</div>
                                                                    </MDBCol>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Shoot Dates</div>
                                                                        <div className="sub-text">{this.state.headerData?.shoot_date || "None"}
                                                                            {/* {getFormattedDate(this.state.headerData?.shoot_date, true) || ""} */}
                                                                        </div>
                                                                    </MDBCol>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Scene Description(s)</div>
                                                                        <div className="sub-text">{this.state.headerData.scene_description || "None"}</div>
                                                                    </MDBCol>
                                                                </MDBRow>
                                                                <MDBRow>
                                                                    <MDBCol md={2} className="budget-text">
                                                                    </MDBCol>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Location</div>
                                                                        <div className="sub-text">{this.state.headerData.location || "None"}</div>
                                                                    </MDBCol>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Recording Date(s)</div>
                                                                        <div className="sub-text">{this.state.headerData?.recording_date || "None"}
                                                                            {/* {getFormattedDate(this.state.headerData?.recording_date, true) || ""} */}
                                                                        </div>
                                                                    </MDBCol>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Version Note(s)</div>
                                                                        <div className="sub-text">{this.state.headerData.version_notes || "None"}</div>
                                                                    </MDBCol>
                                                                </MDBRow>
                                                            </>}
                                                    </>}
                                                    {/* for pilot season code end */}
                                                    {/* for non pilot season code starts */}
                                                    {this.props.is_pilot === 0 && <>
                                                        {(this.state?.episodeOptions?.length === 0) ?
                                                            <>
                                                                <p>Please add an episode.</p>
                                                            </>
                                                            :
                                                            <>
                                                                <MDBRow>
                                                                    {this.state?.selectedmusicBudgetRecord?.show_season_tv_creative_music_budget_id && <div className="pdfFileContainer">
                                                                        <BasicButton
                                                                            icon={"copy"}
                                                                            text={" "}
                                                                            variant={"outlined"}
                                                                            onClick={this.handleCopyVersionPopup}
                                                                        />
                                                                        <Popover
                                                                            open={this.state.setCopyVersionPopup}
                                                                            anchorEl={this.state.setCopyVersionPopup}
                                                                            onClose={this.handleCloseCopyVersionPopup}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            }} >
                                                                            <MDBRow className="popoverRightsWrapper">
                                                                                <div className="combineVersionHeading"> Copy items from season {selectedSeason.season_name} Episode {episodeNumber} Version {this.state.version} to: </div>
                                                                                <MDBCol md={6}>
                                                                                    <SelectField
                                                                                        label={"Season"}
                                                                                        isMandatory={true}
                                                                                        showMandatory={this.state.postInitiated || false}
                                                                                        mandatoryWarning={messages.mandatoryWarning}
                                                                                        placeHolderText='-Select-'
                                                                                        value={this.state.copyPayload?.to_season_id ? this.state.copyPayload?.to_season_id : null}
                                                                                        options={seasonOptions || []}
                                                                                        onChange={(e) => this.onDropdownChange("seasonChanged", "to_season_id", e.target.value)}
                                                                                    />
                                                                                </MDBCol>
                                                                                <MDBCol md={6}>
                                                                                    <SelectField disabled={!seasonSelectedObj?.show_season_id} id={"EpisodePilot"}
                                                                                        label={"Episode"}
                                                                                        isMandatory={true}
                                                                                        showMandatory={this.state.postInitiated || false}
                                                                                        mandatoryWarning={messages.mandatoryWarning}
                                                                                        placeHolderText='-Select-'
                                                                                        options={this.state.copyEpisodeOptions}
                                                                                        value={this.state.copyPayload?.to_show_season_episode_id ? this.state.copyPayload?.to_show_season_episode_id : null}
                                                                                        onChange={(e) => this.onDropdownChange("episodeChanged", "to_show_season_episode_id", e.target.value)}
                                                                                    />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                            <MDBRow className='mt-2'>
                                                                                <MDBCol md={10}></MDBCol>
                                                                                <MDBCol md={2} className="saveCancelWrapper">
                                                                                    <BasicButton text={this.state.isCopyPosting ? <CircularProgress color="inherit" size={20} /> : "Save"} onClick={this.handleCopySubmit} disabled={!this.state.copyPayload?.to_season_id || !this.state.copyPayload?.to_show_season_episode_id} />
                                                                                    <BasicButton
                                                                                        onClick={this.handleCloseCopyVersionPopup}
                                                                                        variant="outlined"
                                                                                        text={"Cancel"}
                                                                                    />
                                                                                </MDBCol>
                                                                            </MDBRow>
                                                                        </Popover>
                                                                        {/* {canGeneratePDF &&
                                                                            <> */}
                                                                        <BasicButton
                                                                            icon={this.state.isEmailLoader ? " " : "envelope"}
                                                                            text={this.state.isEmailLoader ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                            variant={"outlined"}
                                                                            onClick={() => this.generatePdf("email")}
                                                                        />
                                                                        <BasicButton
                                                                            icon={this.state.isExcelPosting ? " " : "file-excel"}
                                                                            text={this.state.isExcelPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                            variant={"outlined"}
                                                                            onClick={this.handleShowExcelAndDocModal}
                                                                        />
                                                                        <BasicButton
                                                                            icon={this.state.isPdfPosting ? " " : "file-pdf"}
                                                                            text={this.state.isPdfPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                            variant={"outlined"}
                                                                            onClick={() => this.generatePdf("pdf")}
                                                                        />
                                                                        {/* </>} */}
                                                                    </div>}
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div className="heading-text">Season Union(s)</div>
                                                                        {/* {seasonUnionList.map(item => (
                                                                        <div className="sub-text">{`${item},`}</div>
                                                                    ))} */}
                                                                        <div className="sub-text">{this.state.seasonUnionList?.length > 0 ? `${this.state.seasonUnionList.map(item => item?.label).join()}` : "None"}</div>
                                                                    </MDBCol>
                                                                    <MDBCol md={2} className="budget-text">
                                                                        <div>
                                                                            <SelectField id={"Episode"}
                                                                                label={"Episode"}
                                                                                placeHolderText='-select-'
                                                                                options={this.state.episodeOptions}
                                                                                value={this.state.episode_id}
                                                                                onChange={(e) => this.onChange("episode", e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </MDBCol>
                                                                    {this.state.versionOptions.length > 0 && this.state.version !== null &&
                                                                        <MDBCol md={1} className="budget-text">
                                                                            <SelectField id={"Version"}
                                                                                label="Version"
                                                                                options={this.state.versionOptions}
                                                                                value={this.state.version}
                                                                                onChange={(e) => this.onChange("version", e.target.value)}
                                                                            />
                                                                        </MDBCol>}
                                                                    {this.state.versionOptions.length == 0 && this.state.version === null && budgetDetailsPermission?.edit === 1 &&
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <BasicLabel text={" "} />
                                                                            <BasicButton text="Create New Version" onClick={() => this.handleShowBudgetTabModal("New", "version", "fromEmptyVersion")} />
                                                                        </MDBCol>
                                                                    }
                                                                    {this.state.versionOptions.length > 0 && this.state.version !== null && budgetDetailsPermission?.edit === 1 &&
                                                                        <>
                                                                            <MDBCol md={4} className="budget-text combineVersionContainer">
                                                                                <div>
                                                                                    <BasicLabel text={" "} />
                                                                                    <BasicButton onClick={this.handleCombineVersionPopup} text={"Combine Versions"} />
                                                                                    <Popover
                                                                                        open={this.state.setPopoverVersion}
                                                                                        anchorEl={this.state.setPopoverVersion}
                                                                                        onClose={this.handleCloseCombineVersionPopup}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'left',
                                                                                        }} >
                                                                                        <MDBRow className="popoverRightsWrapper">
                                                                                            <div className="combineVersionHeading"> Select versions to combine into a new version. </div>
                                                                                            {
                                                                                                this.state.versionOptions.map((item, index) => (
                                                                                                    <>
                                                                                                        {index !== this.state.versionOptions.length - 1 && <BasicCheckbox id={item.value} label={`Version ${item?.value}${item?.label ? " - " + item?.label : ""}`}
                                                                                                            onChange={(e) => this.onCheckboxChangeCombineVersion(item.value, e.target.checked)}
                                                                                                        />
                                                                                                        }
                                                                                                    </>
                                                                                                ))
                                                                                            }
                                                                                            <MDBRow className="combineButtonRow">
                                                                                                <MDBCol md={3} ></MDBCol>
                                                                                                <MDBCol md={9} className="saveCancelWrapper">
                                                                                                    <div>
                                                                                                        <BasicButton
                                                                                                            icon={this.state.isPostingCombineVersion ? " " : "compress-arrows-alt"}
                                                                                                            text={this.state.isPostingCombineVersion ? <CircularProgress color="inherit" size={20} /> : "Combine"}
                                                                                                            variant="contained"
                                                                                                            onClick={() => this.handleCombine("non-pilot")}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <BasicButton
                                                                                                            icon={"times"} text=" "
                                                                                                            variant="outlined"
                                                                                                            onClick={this.handleCloseCombineVersionPopup}
                                                                                                        />
                                                                                                    </div>
                                                                                                </MDBCol>
                                                                                            </MDBRow>

                                                                                        </MDBRow>
                                                                                    </Popover>
                                                                                </div>
                                                                                <div>
                                                                                    <BasicLabel text={" "} />
                                                                                    <BasicButton text="Edit Budget Details" onClick={() => this.handleShowBudgetTabModal("Edit", "budget", null)} />
                                                                                </div>
                                                                                <div>
                                                                                    {canEditBudgetDetails === false &&
                                                                                        <>
                                                                                            <BasicLabel text={"Final Budget"} />
                                                                                            <span>{this.state.detailsToDisplay?.is_final_budget === 1 ? "Yes" : "No"}</span>
                                                                                        </>
                                                                                    }
                                                                                    {canEditBudgetDetails === true && <>
                                                                                        <BasicLabel text={" "} />
                                                                                        <BasicCheckbox
                                                                                            id={"is_final_budget"}
                                                                                            label={this.state.isFinalBudgetLoading ? <CircularProgress size={18} /> : "Final Budget?"}
                                                                                            className={this.state.can_edit_final_budget === false && this.state.headerData?.is_final_budget === 0 ? "disabled" : null}
                                                                                            checked={this.state.headerData?.is_final_budget}
                                                                                            onChange={this.state.can_edit_final_budget === false && this.state.headerData?.is_final_budget === 0 ? null : (e) => this.onCheckBoxChange("is_final_budget", e.target.checked)}
                                                                                        />
                                                                                    </>}
                                                                                </div>
                                                                            </MDBCol>
                                                                            {/* <MDBCol md={1}></MDBCol> */}
                                                                            <MDBCol md={3} className="budget-text final-budget-text">
                                                                                <BasicLabel text={this.state.prodEntityOfShow} />
                                                                                {/* <span className="error-budget-text">{this.state.allVersionsList?.find(item => item?.value === this.state.headerData?.version && this.state.headerData?.is_final_budget === 1) ? "Budget finalized" : "Budget is not finalized, changes are pending."} </span> */}
                                                                                <span className="error-budget-text">{this.state.allVersionsList?.find(item => item?.value === this.state.headerData?.version && this.state.headerData?.is_final_budget === 1) ? "Budget finalized" : ""} </span>
                                                                            </MDBCol>
                                                                        </>
                                                                    }
                                                                </MDBRow>
                                                                {this.state.versionOptions.length > 0 && this.state.version !== null && <>
                                                                    <MDBRow>
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <div className="heading-text">Version Description</div>
                                                                            <div className="sub-text">{this.state.headerData?.version_description || "None"}</div>
                                                                        </MDBCol>
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <div className="heading-text">Types of Using/Timing</div>
                                                                            <div className="sub-text">{this.state.headerData?.type_of_use || "None"}</div>
                                                                        </MDBCol>
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <div className="heading-text">Shoot Dates</div>
                                                                            <div className="sub-text">{this.state.headerData?.shoot_date || "None"}
                                                                                {/* {getFormattedDate(this.state.headerData?.shoot_date, true) || ""} */}
                                                                            </div>
                                                                        </MDBCol>
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <div className="heading-text">Scene Description(s)</div>
                                                                            <div className="sub-text">{this.state.headerData?.scene_description || "None"}</div>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    <MDBRow>
                                                                        <MDBCol md={2} className="budget-text">
                                                                        </MDBCol>
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <div className="heading-text">Location</div>
                                                                            <div className="sub-text">{this.state.headerData?.location || "None"}</div>
                                                                        </MDBCol>
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <div className="heading-text">Recording Date(s)</div>
                                                                            <div className="sub-text">{this.state.headerData?.recording_date || "None"}
                                                                                {/* {getFormattedDate(this.state.headerData?.recording_date, true) || ""} */}
                                                                            </div>
                                                                        </MDBCol>
                                                                        <MDBCol md={2} className="budget-text">
                                                                            <div className="heading-text">Version Note(s)</div>
                                                                            <div className="sub-text">{this.state.headerData?.version_notes || "None"}</div>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                </>}
                                                            </>}
                                                    </>}
                                                    {/* for non pilot season code end */}
                                                </>}
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBRow>
                            {(this.props.initialRecordData?.unions?.length > 0) && ((this.state?.allVersionsList?.length > 0 && this.props.is_pilot === 1) || (this.state?.episodeOptions?.length > 0 && this.state.versionOptions.length > 0 && this.props.is_pilot === 0 && this.state.version !== null)) && <>
                                {/* Camera performers accordion start */}
                                <MDBRow className="buget-card-p">
                                    <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                        <div className="cueSheet-header">
                                            <div className="cuesheet-btn">
                                                <Typography className="header-cue">
                                                    On Camera Performers
                                                </Typography>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                </AccordionSummary>
                                            </div>
                                            <div className="budget-fileIcon">
                                                <MDBIcon icon="file" onClick={() => this.handleNotes("onCameraPerformers")} />
                                                <Popover
                                                    id={id}
                                                    open={openCamera}
                                                    className={"pop-over"}
                                                    anchorEl={this.state.showNotesOfOnCameraPerformers}
                                                    onClose={() => this.handleNotes("onCameraPerformers")}
                                                    anchorReference="anchorPosition"
                                                    anchorOrigin={{ horizontal: "right", vertical: "bottom " }}
                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                    PaperProps={{ style: { width: '16%', backgroundColor: '#e6e6e6' } }}
                                                    anchorPosition={{ top: 250, left: 1350 }}
                                                >
                                                    <BasicTextArea
                                                        label="Notes"
                                                        type="text"
                                                        rows={1}
                                                        value={this.state.headerData?.on_camera_performers_notes}
                                                        onChange={e => this.handleNotesChange(e.target.value, "onCameraPerformers")}
                                                    />
                                                    <br />
                                                    <MDBRow>
                                                        <MDBCol md={8}></MDBCol>
                                                        <MDBCol md={4} className="saveCancelWrapper">
                                                            <div>
                                                                <BasicButton text={this.state.postNotesLoader ? <CircularProgress color="inherit" size={20} /> : " "} icon={this.state.postNotesLoader ? " " : "save"} onClick={() => this.postNotes("onCameraPerformers")} />
                                                            </div>
                                                            <div>
                                                                <BasicButton icon={"times"} text=" " variant="outlined" onClick={() => this.handleNotes("onCameraPerformers")} />
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>

                                                </Popover>
                                            </div>
                                        </div>
                                        <AccordionDetails id="cuesheet-table-creative">
                                            <div className="cueSheetTable" id="table-bg-creative">
                                                {this.state.loadingCameraPerformers ? (
                                                    <div style={{ textAlign: "center" }}>
                                                        <CircularProgress size={10} />
                                                    </div>
                                                ) :
                                                    <TableComponent
                                                        list={this.state.cameraPerformersData || []}
                                                        className={"clearencRecord"}
                                                        stickyHeader={true}
                                                        config={this.state.creativeBudgetOnCameraConfig}
                                                        editCallback={canEditBudgetDetails ? this.handleInlineEdits.bind(this, "cameraPerformersData") : false}
                                                        saveCallback={canEditBudgetDetails ? this.saveInlineEdits.bind(this, "cameraPerformersData") : false}
                                                        addItemToList={canEditBudgetDetails ? true : false}
                                                        handleAddNewItem={canEditBudgetDetails ? this.handleAddNewItem.bind(this, "cameraPerformersData") : false}
                                                        handleOpenInlineModal={canEditBudgetDetails ? this.handleClickFR.bind(this, "cameraPerformersData") : false}
                                                        postInitiated={this.state.isPosting || this.state.loadingCameraPerformers}
                                                        readCallback={canEditBudgetDetails ? this.handleReadCallback.bind(this, "cameraPerformersData") : false}
                                                    // tableMode={"light"}
                                                    // isLoading={this.props.isLoading || false}
                                                    // hyperLinkNavigationCallback={this.navigationCallback}
                                                    // episodeCallback={this.handleChangePage}
                                                    // sortCallback={this.handleTableColumnClick} 
                                                    />
                                                }


                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </MDBRow>
                                <MDBRow className="buget-card-p">
                                    <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                        <div className="cueSheet-header">
                                            <div className="cuesheet-btn">
                                                <Typography className="header-cue">
                                                    Recordings
                                </Typography>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                </AccordionSummary>
                                            </div>
                                            <div className="budget-fileIcon">
                                                <MDBIcon icon="file" onClick={() => this.handleNotes("recordingsData")} />
                                                <Popover
                                                    id={id}
                                                    open={openRecordings}
                                                    className={"pop-over"}
                                                    anchorEl={this.state.showNotesOfRecordings}
                                                    onClose={() => this.handleNotes("recordingsData")}
                                                    anchorReference="anchorPosition"
                                                    anchorOrigin={{ horizontal: "right", vertical: "bottom " }}
                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                    PaperProps={{ style: { width: '16%', backgroundColor: '#e6e6e6' } }}
                                                    anchorPosition={{ top: 300, left: 1350 }}
                                                >
                                                    <BasicTextArea
                                                        label="Notes"
                                                        type="text"
                                                        rows={1}
                                                        value={this.state.headerData?.recordings_notes}
                                                        onChange={e => this.handleNotesChange(e.target.value, "recordingsData")}
                                                    />
                                                    <br />
                                                    <MDBRow>
                                                        <MDBCol md={8}></MDBCol>
                                                        <MDBCol md={4} className="saveCancelWrapper">
                                                            <div>
                                                                <BasicButton text={this.state.postNotesLoader ? <CircularProgress color="inherit" size={20} /> : " "} icon={this.state.postNotesLoader ? " " : "save"} onClick={() => this.postNotes("recordingsData")} />
                                                            </div>
                                                            <div>
                                                                <BasicButton icon={"times"} text=" " variant="outlined" onClick={() => this.handleNotes("recordingsData")} />
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </Popover>
                                            </div>
                                        </div>
                                        <AccordionDetails id="cuesheet-table-creative">
                                            <div className="cueSheetTable" id="table-bg-creative">
                                                {this.state.loadingRecordingData ? (
                                                    <div style={{ textAlign: "center" }}>
                                                        <CircularProgress size={10} />
                                                    </div>
                                                ) :
                                                    <TableComponent
                                                        stickyHeader={true}
                                                        list={this.state.recordingsData || []}
                                                        className={"RecordingRecord"}
                                                        config={this.state.creativeBudgetRecordingConfig}
                                                        editCallback={canEditBudgetDetails ? this.handleInlineEdits.bind(this, "recordingsData") : false}
                                                        saveCallback={canEditBudgetDetails ? this.saveInlineEdits.bind(this, "recordingsData") : false}
                                                        addItemToList={canEditBudgetDetails ? true : false}
                                                        handleAddNewItem={canEditBudgetDetails ? this.handleAddNewItem.bind(this, "recordingsData") : false}
                                                        handleOpenInlineModal={canEditBudgetDetails ? this.handleClickFR.bind(this, "recordingsData") : false}
                                                        postInitiated={this.state.isPostingRecordings || this.state.loadingRecordingData}
                                                        readCallback={canEditBudgetDetails ? this.handleReadCallback.bind(this, "recordingsData") : false}
                                                    // tableMode={"light"}
                                                    // isLoading={this.props.isLoading || false}
                                                    // hyperLinkNavigationCallback={this.navigationCallback}
                                                    // episodeCallback={this.handleChangePage}
                                                    // sortCallback={this.handleTableColumnClick} 
                                                    />
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </MDBRow>
                                <MDBRow className="buget-card-p">
                                    <Accordion onChange={this.handleChange('panel1')} className="cue-boxshadow">
                                        <div className="cueSheet-header">
                                            <div className="cuesheet-btn">
                                                <Typography className="header-cue">
                                                    Orchestral Sessions
                                </Typography>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                </AccordionSummary>
                                            </div>
                                            <div className="budget-fileIcon">
                                                <MDBIcon icon="file" onClick={() => this.handleNotes("orchestralSessions")} />
                                                <Popover
                                                    id={id}
                                                    open={openOrchestral}
                                                    className={"pop-over"}
                                                    anchorEl={this.state.showOrchestralSessions}
                                                    onClose={() => this.handleNotes("orchestralSessions")}
                                                    anchorReference="anchorPosition"
                                                    anchorOrigin={{ horizontal: "right", vertical: "bottom " }}
                                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                    PaperProps={{ style: { width: '16%', backgroundColor: '#e6e6e6' } }}
                                                    anchorPosition={{ top: 350, left: 1350 }}
                                                >
                                                    <BasicTextArea
                                                        label="Notes"
                                                        type="text"
                                                        rows={1}
                                                        value={this.state.headerData?.orchestral_sessions_notes}
                                                        onChange={e => this.handleNotesChange(e.target.value, "orchestralSessions")}
                                                    />
                                                    <br />
                                                    <MDBRow>
                                                        <MDBCol md={8}></MDBCol>
                                                        <MDBCol md={4} className="saveCancelWrapper">
                                                            <div>
                                                                <BasicButton text={this.state.postNotesLoader ? <CircularProgress color="inherit" size={20} /> : " "} icon={this.state.postNotesLoader ? " " : "save"} onClick={() => this.postNotes("orchestralSessions")} />
                                                            </div>
                                                            <div>
                                                                <BasicButton icon={"times"} text=" " variant="outlined" onClick={() => this.handleNotes("orchestralSessions")} />
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </Popover>
                                            </div>
                                        </div>
                                        <AccordionDetails id="cuesheet-table-creative">
                                            <div className="cueSheetTable" id="table-bg-creative">
                                                {this.state.loadingOrchestralData ? (
                                                    <div style={{ textAlign: "center" }}>
                                                        <CircularProgress size={10} />
                                                    </div>
                                                ) :
                                                    <TableComponent
                                                        stickyHeader={true}
                                                        list={this.state.orchestralData || []}
                                                        className={"orchestralRecord"}
                                                        config={this.state.creativeBudgetOrchestralConfig}
                                                        editCallback={canEditBudgetDetails ? this.handleInlineEdits.bind(this, "orchestralData") : false}
                                                        saveCallback={canEditBudgetDetails ? this.saveInlineEdits.bind(this, "orchestralData") : false}
                                                        addItemToList={canEditBudgetDetails ? true : false}
                                                        handleAddNewItem={canEditBudgetDetails ? this.handleAddNewItem.bind(this, "orchestralData") : false}
                                                        handleOpenInlineModal={canEditBudgetDetails ? this.handleClickFR.bind(this, "orchestralData") : false}
                                                        postInitiated={this.state.isPostingOrchestral || this.state.loadingOrchestralData}
                                                        readCallback={canEditBudgetDetails ? this.handleReadCallback.bind(this, "orchestralData") : false}
                                                    // tableMode={"light"}
                                                    // isLoading={this.props.isLoading || false}
                                                    // hyperLinkNavigationCallback={this.navigationCallback}
                                                    // episodeCallback={this.handleChangePage}
                                                    // sortCallback={this.handleTableColumnClick} 
                                                    />
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </MDBRow>
                            </>}
                            {
                                this.state.showEditBudgetTabModal &&
                                <>
                                    <MainViewModalComponent
                                        open={this.state.showEditBudgetTabModal}
                                        handleClose={this.handleHideBudgetTabModal}
                                        headerText={this.state.origin === "budget" ? `${this.props.initialRecordData?.show_name} Tv Budget Details` : `Version - Version ${this.state.clickedFrom === "fromdropdown" ? (this.props.is_pilot === 1 ? this.state.allVersionsList.length : this.state.versionOptions.length) : (this.props.is_pilot === 1 ? this.state.allVersionsList.length + 1 : this.state.versionOptions.length + 1)} (${this.props.initialRecordData?.show_name} - Tv Budget Details)`}
                                        //New Version - Version 1(Show name - TV Budget Deatils)
                                        mode={this.state.mode}
                                        modalName={"Edit Budget Tab Modal"}
                                        initialRecordData={this.props.initialRecordData}
                                        is_pilot={this.props.initialRecordData?.is_pilot}
                                        show_season_id={this.props.show_season_id}
                                        season_id={this.props?.initialRecordData?.season_id}
                                        show_id={this.props?.initialRecordData?.show_id}
                                        episodeOptions={this.state.episodeOptions}
                                        getVersions={this.getVersions.bind(this)}
                                        getRecordingOrchestralCameraPerformersData={this.getRecordingOrchestralCameraPerformersData.bind(this)}
                                        getHeaderData={this.getHeaderData.bind(this)}
                                        versionNum={this.state.clickedFrom === "fromdropdown" ? (this.props.is_pilot === 1 ? this.state.allVersionsList.length : this.state.versionOptions.length) : (this.props.is_pilot === 1 ? this.state.allVersionsList.length + 1 : this.state.versionOptions.length + 1)}
                                        selectedmusicBudgetRecord={this.state.mode === "Edit" ? this.state.selectedmusicBudgetRecord : newVersion}
                                        episode_id={this.props.is_pilot === 0 ? this.state.episode_id : null}
                                        seasonUnionList={this.state.seasonUnionList}
                                        division_id={this.props.division_id}
                                    />
                                </>
                            }
                             {
                                this.state.showExcelAndDocModal &&
                                <>
                                    <MainViewModalComponent
                                        open={this.state.showExcelAndDocModal}
                                        handleClose={this.handleHideExcelAndDocModal}
                                        headerText={"Generate Excel/DOC"}
                                        mode={''}
                                        modalName={"Excel And Doc Modal"}
                                        downloadExcel={this.generatePdf}
                                        isOpenExisting = {this.isOpenExisting.bind(this)}
                                        isExcelPosting = {this.state.isExcelPosting}
                                    />
                                </>
                            }
                        </MDBCol>
                    )
                }
            </div>

        )
    }
}

export default withUserContext(CreativeBudget);