export const warningHeading = {
    headings: [
        {
            headingLabel: "Program",
            icon: "",
            width: "15%",
        },
        {
            headingLabel: "Episode #",
            icon: "",
            width: "8%"
        },

        {
            headingLabel: "Episode Title",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Airing",
            icon: "",
            width: "8%"
        },
        {
            headingLabel: "Submit Date",
            icon: "",
            width: "10%"
        },
        {
            headingLabel: "Closed",
            icon: "",
            width: "7%",
            inputType: "checkbox",
        },
        {
            headingLabel: "Filename",
            icon: "",
            width: "12%"
        },
        {
            headingLabel: "Ack",
            icon: "",
            width: "7%"
        },
        {
            headingLabel: "XML",
            icon: "",
            width: "7%"
        },
        {
            headingLabel: "CS",
            icon: "",
            width: "7%"
        }

    ],
    dataNodes: ["show_name", "episode_number", "episode_title", "airing", "submitted_date", 'check', 'file_name',"Ack", "XML", "CS"],
    primaryKey: "rc_xml_cue_id",
    hyperlinks:["airing"],
    buttonFields: [
        {
            column: "Ack",
            icon: "",
            disabled:true
        },
        {
            column: "XML",
            icon: "",
            disabled:true
        },
        {
            column: "CS",
            icon: "",
            disabled:true
        },
        
    ],
}


export const rejectedHeading = {
    headings: [
        {
            headingLabel: "Program",
            icon: "",
            width: "15%",
        },
        {
            headingLabel: "Episode #",
            icon: "",
            width: "15%"
        },

        {
            headingLabel: "Episode Title",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Airing",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Submit Date",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Ack",
            icon: "",
            width: "7%"
        },
        {
            headingLabel: "XML",
            icon: "",
            width: "7%"
        },
        {
            headingLabel: "CS",
            icon: "",
            width: "7%"
        }

    ],
    dataNodes: ["show_name", "episode_number", "episode_title", "airing", "submitted_date","Ack", "XML", "CS"],
    primaryKey: "rc_xml_cue_id",
    hyperlinks:["airing"],
    buttonFields: [
        {
            column: "Ack",
            icon: "",
            disabled:true
        },
        {
            column: "XML",
            icon: "",
            disabled:true
        },
        {
            column: "CS",
            icon: "",
            disabled:true
        },
        
    ],
}


export const notAcknowledeHeading = {
    headings: [
        {
            headingLabel: "Program",
            icon: "",
            width: "20%",
        },
        {
            headingLabel: "Episode #",
            icon: "",
            width: "15%"
        },

        {
            headingLabel: "Episode Title",
            icon: "",
            width: "20%"
        },
        {
            headingLabel: "Airing",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "Submit Date",
            icon: "",
            width: "15%"
        },
        {
            headingLabel: "File Name",
            icon: "",
            width: "15%"
        },

    ],
    dataNodes: ["show_name", "episode_number", "episode_title", "airing", "submitted_date", "file_name"],
    primaryKey: "rc_xml_cue_id",
}