import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText, MDBTooltip } from 'mdb-react-ui-kit';
import { filesList } from './FileConfig';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import EmailButton from '../../SharedComponents/EmailButton';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { CircularProgress } from "@material-ui/core";
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import './File.scss';
let CanOpenUploadFile = false, queryParam = {}, selectedSeason;
class Files extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: filesList,
            filesArr: [],
            filesArrBackup: [],
            isLoading: false,
            fileToFilter: '',
            isDeleteFile: false,
            openNotification: false,
            notification: null,
        }
    };
    getPermissions(permissions) {
        if (permissions.length > 0) {
            CanOpenUploadFile = permissions.filter((obj, index) => obj.permission_name === "Can open/upload files");
            CanOpenUploadFile = CanOpenUploadFile.length > 0 ? true : false;
        }
    }
    onLoadFetchData = (url, queryParamObj, loader = true) => {
        this.setState({ isLoading: loader });
        clearTrackService.getDataParams(Constants.ClearTrackServiceBaseUrl + url, queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isLoading: false, isDeleteFile: false });
                //if (response?.data?.data?.length > 0) {
                this.setState({ filesArr: response.data.data, filesArrBackup: response.data.data });
                this.props.disabletriggerGetFileData(null);
                //}
                if (!loader) {
                    this.setState({ openNotification: true, notification: 'File deleted successfully' })
                }
            },
                (err) => {
                    this.setState({ isLoading: false });
                    console.log("Error in fetching data", err)
                })
    }
    downloadFile = (url) => {
        if (url) {
            fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                const link = document.createElement("a");
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
          }
    }
    deleteFiles = (fileName, index) => {
        let queryParamObj = {
            "showName": this.props?.initialRecordData?.show_name,
            "season": selectedSeason,
            "module": "showseasonfiles",
            "fileName": fileName,
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }
        this.setState({ isDeleteFile: index })
        clearTrackService.deleteDataParams(Constants.ClearTrackServiceBaseUrl + '/deleteFiles', queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ openNotification: true, notification: 'File deletion failed' })
                } else {
                    queryParam.divisionId = this.props.division_id
                    queryParam.divisionName = this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
                    this.onLoadFetchData(`/getFiles`, queryParam, false);
                }
            },
                (err) => {
                    this.setState({ isDeleteFile: false, openNotification: true, notification: 'File deletion failed' });
                    console.log("Error in fetching data", err)
                })
    }
    componentDidUpdate(prevProps) {
        if (this.props.triggerGetFileData === true && prevProps.triggerGetFileData !== this.props.triggerGetFileData) {
            queryParam.divisionId = this.props.division_id
            queryParam.divisionName = this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
            this.onLoadFetchData(`/getFiles`, queryParam);
        }
    }
    componentDidMount() {
        this.getPermissions(this.props.permissionArr);
        selectedSeason = this.props?.initialRecordData?.seasons?.filter(item => (item.season_id == this.props?.initialRecordData?.season_id) ? item : null
        );
        selectedSeason = selectedSeason.length > 0 && selectedSeason[0].season_name;
        queryParam = {
            "showName": this.props?.initialRecordData?.show_name,
            "season": selectedSeason,
            "module": "showseasonfiles",
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }
        this.onLoadFetchData(`/getFiles`, queryParam);

    }
    handleFilterText(value) {
        if (value.length > 0) {
            var finalResult = this.state.filesArrBackup.filter((item) => item.Key.toLowerCase().includes(value.toLowerCase()) ? item : null);
            this.setState({ filesArr: finalResult, fileToFilter: value });
        }
        if (value.length == 0) {
            this.setState({ filesArr: this.state.filesArrBackup, fileToFilter: "" });
        }
    }
    render() {
        return (
            <>
                {
                    this.state.isLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (<>
                        <NotificationComponent
                            open={this.state.openNotification}
                            message={this.state.notification}
                            severity={this.state.notification === 'File deleted successfully' ? 'success' : 'fail'}
                            handleResetNotify={() => {
                                this.setState({ openNotification: false, notification: null })
                            }} />
                        <MDBCard className="ShowSeason-Files-Container">
                            <MDBCardBody>
                                <MDBCardText>
                                    <MDBRow style={{ marginBottom: "10px" }}>
                                        <MDBCol md={3}>
                                            <BasicTextField
                                                id="searchfile"
                                                placeholder={"Search Files by Name"} value={this.state.fileToFilter}
                                                onChange={(e) => this.handleFilterText(e.target.value)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={7}>
                                        </MDBCol>
                                        {/* <MDBCol md={2}>
                                            <EmailButton />
                                        </MDBCol> */}
                                    </MDBRow>
                                    <MDBRow>
                                        {this.state?.filesArr?.length > 0 && this.state?.filesArr?.map((item, index) => (
                                            <MDBCol md={2} key={index}>
                                                <div className="files-display">
                                                    <p style={{ cursor: "pointer" }} className="file-text" onClick={() => this.downloadFile(item.url)}>{item.Key} </p>
                                                    {this.state.isDeleteFile === index ?
                                                        <div style={{ textAlign: "center" }}>
                                                            <CircularProgress size={10} />
                                                        </div> :
                                                        <span className="icon-float">                                                            <MDBIcon
                                                                icon={"times"}
                                                                onClick={() => this.deleteFiles(item.Key, index)}
                                                            />
                                                        </span>
                                                    }
                                                </div>
                                            </MDBCol>
                                        ))}
                                        {this.state?.filesArr?.length === 0 && <p> No files present. Please add using "Add File" button"</p>}
                                    </MDBRow>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </>)
                }
            </>
        )
    }
}

export default Files;