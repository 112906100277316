import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import TabsComponent from "../SharedComponents/Tabs/Tabs";
import { quoteRequestTabList } from "./config";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../SharedComponents/SelectField/SelectField";
import RichTextEditor from "../SharedComponents/RichTextEditor/RichTextEditor";
import PilotLanguage from './PilotLanguage';
import PreAirMarketing from "./PreAirMarketing";
import QuoteExtension from "./QuoteExtension";
import UserDefined from './UserDefined';
import ExecCardLanguage from "./ExecCardLanguage";
import IncludeProposedFees from "./IncludeProposedFees";

class QuoteRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      quoteRequestData: {},
      loadingShows: false,
    };
  }
  handleTabChange = (newTabValue) => {
    this.setState({
      tabValue: newTabValue,
    });
  };
  
  getTabComponent = () => {
    switch (this.state.tabValue) {
      case 1:
        return <ExecCardLanguage notificationComponent={this.props.notificationComponent} description={this.props.description} {...this.props}/>
      case 2:
        return <PilotLanguage notificationComponent={this.props.notificationComponent} description={this.props.description} {...this.props}/>
      case 3:
       return <PreAirMarketing notificationComponent={this.props.notificationComponent} description={this.props.description} {...this.props}/>
      case 4:
       return <QuoteExtension notificationComponent={this.props.notificationComponent} description={this.props.description} {...this.props}/>
      case 5:
        return <IncludeProposedFees notificationComponent={this.props.notificationComponent} description={this.props.description} {...this.props}/>
      case 6:
        return <UserDefined notificationComponent={this.props.notificationComponent} description={this.props.description} {...this.props}/>
    }
  };
  render() {
    return (
      <div className="letterSetup-Container">
        <div>
          <MDBRow className="tab-content">
            <MDBCol md={12}>
              <TabsComponent
                tabList={quoteRequestTabList}
                value={this.state.tabValue}
                onChange={(e, v) => this.handleTabChange(v)}
              />
            </MDBCol>
          </MDBRow>
        </div>
        {this.getTabComponent()}
      </div>
    );
  }
}

export default QuoteRequests;
