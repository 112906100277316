import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { CircularProgress } from "@material-ui/core";
import {withUserContext} from '../../contexts/UserContext';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import { validateNumbersOnly } from '../../Common/Helper';
import { applyFilterTable } from '../../Common/TableHelper';
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";

let abbreviationFilter = '',filterValues =[], filtersNone = true,activeFilter = '';
class MusicUses extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        renderList: this.props?.list,
        isLoading: false,
        isSubmit: false,
        musicUseOptions:[],
        usesFieldNotExist : false,
        sortBy:null,
        isSortByAsc: true,
        usesDuplicate:false,
        sortCount: 0,
        showInactive:0,
        musicUsesConfig:  JSON.parse(JSON.stringify({ ...this.props?.config}))
      }
    }

    componentDidMount() {
      this.getMusicUses("musicUseOptions", "MUSIC_USES", "");
    }

    getMusicUses = (entity, entitytype, searchString , loader = true) => {
      this.setState({ isLoading: loader,usesFieldNotExist : false });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`,this.props?.userContext?.active_tenant?.tenant_id)
          .then((res) => {
            abbreviationFilter = '';
            let existingUses = []
            res.data.map((item) => {
              if (this.state.showInactive === 0 && item.is_active === 1) {
                existingUses.push(item)
              }
            })
            let configRecord = {...this.state.musicUsesConfig}
            this.props?.permissionArray?.edit === 1 ? configRecord.actions = ["pen"] : configRecord.actions = [ ]
            this.setState({ [entity]: res.data,musicUsesBackup : res.data, isLoading: false,isSubmit: false,sortBy:null,
              isSortByAsc: true, musicUsesConfig: configRecord,
              sortCount: 0, }, () => { this.handleTableColumnClick("Music Uses")});
          })
          .catch((err) => {
            this.setState({ isLoading: false });
        });
    };

    postMusicUseDetails = (musicUseDetails,postFlag) => {
        this.setState({ isSubmit: postFlag,usesFieldNotExist : false });
        ClearTrackService.postDataParams(
          Constants.ClearTrackServiceBaseUrl + `/musicUse`,
          musicUseDetails,
          this.props?.userContext?.active_tenant?.tenant_id
        ).then(
          (response) => {
            if (response.data.error) {
              this.setState({ isSubmit: false });
              this.props?.toastMessageNotification(true, "fail");
              this.getMusicUses("musicUseOptions", "MUSIC_USES", "");
            } else {
             // this.setState({
               // isSubmit: false,
              //});
              if (musicUseDetails?.editing) {
                musicUseDetails.editing = false;
              }
              this.props?.toastMessageNotification(true, "success");
              this.getMusicUses("musicUseOptions", "MUSIC_USES", "", false);
            }
          },
          (err) => {
            this.setState({ isSubmit: false });
            this.props?.toastMessageNotification(true, "fail");
            console.log("Post budget details error: " + err);
          }
        );
    };

    handleInlineEdits = (id, dataItem) => {
        let musicUses = [...this.state.musicUseOptions]
        this.setState({
            usesFieldNotExist : false,
            musicUseOptions: musicUses.map((item) => {
                let newItem = {...item}
                if (item.music_uses_id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({usesFieldNotExist : false,usesDuplicate:false})
        if(!data[index]?.existing_uses || !data[index]?.abbreviation || data[index]?.existing_uses?.length == 0 || data[index]?.rapid_cue_music_use_id?.length == 0  || !validateNumbersOnly(data[index]?.rapid_cue_music_use_id)) {
          this.setState({usesFieldNotExist : true})
        }
        else if(data[index]?.abbreviation && this.state?.musicUseOptions?.some((item,i)=>item?.abbreviation == data[index]?.abbreviation && i!=index)){
          this.setState({usesDuplicate: true})
        }
        else{
        
          let musicUses = [...data];
          let musicUsesDetails = [...this.state.musicUseOptions];
          musicUsesDetails[index].music_uses_id = musicUses[index]?.music_uses_id;
          musicUsesDetails[index].existing_uses = musicUses[index]?.existing_uses || null;
          musicUsesDetails[index].abbreviation = musicUses[index]?.abbreviation || null;
          musicUsesDetails[index].full_description = musicUses[index]?.full_description || null;
          musicUsesDetails[index].rapid_cue_music_use_id = musicUses[index]?.rapid_cue_music_use_id ? musicUses[index]?.rapid_cue_music_use_id: 0;
          musicUsesDetails[index].is_active = musicUses[index]
            ?.is_active
            ? 1
            : 0;
          this.setState({ musicUseOptions: musicUsesDetails });
          this.postMusicUseDetails(musicUsesDetails[index], musicUsesDetails[index]?.music_uses_id);
        } 
      };

    handleAddNewItem = (newDataItem) => {
      this.setState({usesFieldNotExist : false,usesDuplicate: false})

      if(!newDataItem?.existing_uses || !newDataItem?.abbreviation || newDataItem?.existing_uses?.length == 0 || !newDataItem?.full_description || newDataItem?.rapid_cue_music_use_id?.length == 0 || !validateNumbersOnly(newDataItem?.rapid_cue_music_use_id)) {
        this.setState({usesFieldNotExist : true})
      }
      else if(newDataItem?.abbreviation && this.state?.musicUseOptions?.some(item=>item?.abbreviation == newDataItem?.abbreviation)){
        this.setState({usesDuplicate: true})
      }
      else{
        let newMusicData = {
          existing_uses: newDataItem?.existing_uses || null,
          abbreviation: newDataItem?.abbreviation || null,
          full_description: newDataItem?.full_description || null,
          is_active: 1,
          rapid_cue_music_use_id:newDataItem?.rapid_cue_music_use_id ? newDataItem?.rapid_cue_music_use_id : 0
        };
        //let musicUsesDetails = [...this.state.musicUseOptions];
        //musicUsesDetails.push(newMusicData);
        this.postMusicUseDetails(newMusicData,'newItemPost');
        //this.setState({ musicUseOptions: musicUsesDetails });
      }
        
      
    };
    handleFilterText(field, value) {
      this.setState({ [field]: value });
      if (field === "abbreviationFilter") { abbreviationFilter = value };
      if (abbreviationFilter.length > 0 ) {
          filtersNone = false;
          filterValues = [`Abbreviation:${abbreviationFilter}`]
          var finalResult = applyFilterTable(this.state.musicUsesConfig, this.state.musicUseOptions, filterValues, filtersNone);
          this.setState({ musicUseOptions: finalResult });
      }
      if (abbreviationFilter.length == 0 ) {
        filtersNone = true;
        if(this.state.showInactive === 1) {
          this.setState({ musicUseOptions: this.state.musicUsesBackup?.filter(ele => ele?.is_active === 0)}); 
            
         
        } else{
          this.setState({ musicUseOptions: this.state.musicUsesBackup?.filter(ele => ele?.is_active === 1)}); 
            
        
        // this.setState({ musicUseOptions: this.state.musicUsesBackup,});
    }
  }
}
  handleGeneralEdit = (field, value) => {
    this.setState(prevState => ({
      rightsDetails: {
          ...prevState.rightsDetails,
          [field]: value
      },
    }))
  };
    handleTableColumnClick = (column) => {
      if (column === this.state.sortBy) {
        this.setState({ sortCount: this.state.sortCount + 1 }, () => {
          this.handleTableSort(column);
        })
      }
      else this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      })
  }
  
  handleTableSort = (colName) => {
      this.setState({
        isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      }, () => {
        this.setState({
          sortBy: colName,
          config: updateTableSortConfigObject(this.state.musicUsesConfig, this.state.isSortByAsc, this.state.sortBy, colName),
          musicUseOptions: tableSortList(colName === "RapidCue Music Use" ? "Number" : "Name", this.getSortNode(colName), this.state.musicUseOptions, this.state.isSortByAsc)
        });
      })
  }
  
  getSortNode = (col) => {
    switch (col) {
      case "Music Uses": return "existing_uses";
      case "Abbreviation": return "abbreviation";
      case "Full Display": return "full_description";
      case "RapidCue Music Use": return "rapid_cue_music_use_id";
      case "Active" :  return "is_active";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="MusicUsesContainer">
                <MDBRow>
                    <span className="description">Different uses for songs for use on Cue Sheets</span>
                </MDBRow>
                <MDBRow className='pd'>
                <span className="errorText">{this.state.usesFieldNotExist && 'Please enter Music Uses ,Full display , Abbreviation, RapidCueMusicuse field value'}</span>
                <span className="errorText">{this.state.usesDuplicate && 'Abbreviation already exist'}</span>
                </MDBRow>
                {this.state.isLoading ? (
                    <div className="ContentLoader">
                    <CircularProgress />
                </div>
                ) : (
                  <MDBRow>
                  <MDBCol md={2}>
                  <BasicTextField
                   placeholder="Abbreviation"
                      value={abbreviationFilter || ""}
                      onChange={(e) => this.handleFilterText('abbreviationFilter', e.target.value)} />
                    </MDBCol>
                    <MDBCol md={5} lg={5} xl={5}>
                  <BasicCheckbox
                    id={"show-inactive"}
                    label="Show Inactive"
                    checked={this.state.showInactive === 1}
                    onChange={(e) => {
                      if(e.target.checked) {
                        this.setState({showInactive : 1, musicUseOptions: this.state.musicUsesBackup?.filter(ele => ele?.is_active === 0)}, () => {
                          
                        })
                      } else {
                        this.setState({showInactive : 0, musicUseOptions: this.state.musicUsesBackup?.filter(ele => ele?.is_active === 1)}, () => {
                          
                        })
                      }
                    }}
                  />
                </MDBCol>
                          <MDBRow className="MusicUsesTable">
                            <TableComponent
                              newIsActive={true}
                                list={this.state.musicUseOptions || []}
                                config={this.state.musicUsesConfig}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1 ? true : false}
                                postInitiated={this.state.isSubmit}
                               // isLoading={this.state.isSubmit}
                                readCallback={(index) => {
                                  let musicUses = [...this.state.musicUseOptions];
                                  musicUses[index].editing = false
                                  this.setState({ musicUseOptions: musicUses, usesFieldNotExist:false });
                                }}
                                stickyHeader={true}
                                sortCallback={this.handleTableColumnClick}
                            />
                        </MDBRow>
                        </MDBRow>
                    )
                }
            </MDBContainer>
       
        )
    }
}

export default withUserContext(MusicUses);