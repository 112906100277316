import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from 'mdbreact';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import SelectField from "../../SharedComponents/SelectField/SelectField";
import DateField from '../../SharedComponents/DateField/DateField';
import './Budget.scss';
import { CircularProgress } from "@material-ui/core";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
import moment from 'moment';
import messages from '../../../Common/Messages.json';
import { validateNumbersOnly } from '../../../Common/Helper';
let postObj = [], invalidValuesField = [], errorPercent = false, errorDuplicate = false, costSplitOptions = [];
class YearsBudgetModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialRecordData: this.props.mode === "Edit" ? this.props?.detailsToDisplay : {},
            isPosting: false,
            fieldValid: true,
            cost_splits: this.props.mode === "Edit" ? this.props?.detailsToDisplay?.cost_splits : [],
            errorFlag: false,
            errorText: null
        }
    }
    handleChange(field, value, fieldValid) {
        if (fieldValid == false) {
            if (invalidValuesField.indexOf(field) === -1) {
                invalidValuesField.push(field);
            }
        }
        else {
            if (invalidValuesField.indexOf(field) !== -1) {
                invalidValuesField.splice(invalidValuesField.indexOf(field), 1);
            }
        }
        if (field === "series_pattern") {
            if (value.trim().length === 0) {
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["series_pattern"]: "$0"
                    },
                }));
            } else {
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["series_pattern"]: value
                    },
                }));
            }
        } else {
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    [field]: value
                },
            }));
        }
    }
    onChangeCallback = (field, value) => {
        switch (field) {
            case 'budget_name':
                if (value.trim().length > 0) {
                    this.handleChange(field, value, true);
                } else {
                    this.handleChange(field, value, false);
                }
                break;
        }
    }
    postData = (postJson) => {
        console.log("postJson", postJson);
        this.setState({ isPosting: true })
        let extensionUrl = '/showSeasonPilotClearanceBudget'
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then((response) => {
                if (response.data.errorPercent) {
                    this.setState({ isPosting: false })
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({ isPosting: false });
                    this.props?.fetchPilotHeaderData(this.props.show_season_id, this.props.mode === "Edit" ? this.state.initialRecordData.show_season_pilot_clearance_budget_id : this.props.mode === "New" ? "New" : null);
                    this.props?.notificationComponent(true, "success");
                    this.props.mode == "New" ? this.props.handleClose() : null;

                }
            },
                (err) => {
                    this.setState({ isPosting: false });
                    this.props?.notificationComponent(true, "fail");
                    console.log("Post agency details errorPercent: " + err);
                });
    }
    handleSubmit = () => {
        postObj = { ...this.state.initialRecordData };
        if (this.props.mode === "New") {
            postObj["show_season_id"] = this.props.show_season_id;
            postObj["season_id"] = this.props.season_id;
            postObj["show_id"] = this.props.show_id;
            if (!this.state.initialRecordData?.budget_name) {
                postObj["budget_name"] = "New Budget";
            }
        }
        if (postObj?.series_pattern?.length > 0 && validateNumbersOnly(this.state?.initialRecordData?.series_pattern?.slice(1)) && postObj?.budget_name) {
            this.setState({ errorFlag: false, errorText: null });
            let tempSeriesPattern = postObj?.series_pattern?.slice(1);
            postObj.series_pattern = Number(tempSeriesPattern);
            this.postData(postObj);
        } else {
            this.setState({ errorFlag: true, errorText: "Please enter mandatory fields" });
        }

    }
    addCallback = (index) => {
        let objToInsert = { "value": "", "label": "", "percentage": 0 };
        let cost_splits = this.state.cost_splits;
        cost_splits.splice(index + 1, 0, objToInsert);
        this.setState({ cost_splits })
    }
    removeCallback = (index) => {
        let cost_splits = this.state.cost_splits;
        cost_splits.splice(index, 1);
        this.setState({ cost_splits })
    }
    getDistinctArray = (arr) => {

        const distincitCostCode = arr.filter((ele, index, arr) =>

            index === arr.findIndex((item) => (

                item.cost_center_id == ele.cost_center_id

            )))
        return distincitCostCode

    }
    onDropdownChange = (fieldToChange, index, value) => {
        errorDuplicate = false;
        let cost_splits = this.state.cost_splits;
        cost_splits[index][fieldToChange] = value;
        errorDuplicate = this.getDistinctArray(this.state?.cost_splits).length !== this.state?.cost_splits.length ? true : false;
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["cost_splits"]: cost_splits
            },
            cost_splits
        }))
    }
    handleCostSplitChange = (fieldToChange, index, value) => {
        let cost_splits = this.state.cost_splits;
        cost_splits[index]["percentage"] = value;
        errorPercent = false;
        let percentSum = 0;
        percentSum = cost_splits.reduce(function (previousValue, currentValue) {
            return previousValue + Number(currentValue.percentage);
        }, 0);
        errorPercent = (percentSum <= 100) ? false : true;
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["cost_splits"]: cost_splits
            },
            cost_splits
        }))
    }
    componentDidMount() {
        errorDuplicate = false;
        errorPercent = false;
        let cost_splits = [];
        if (this.props.mode === "Edit") {
            if (this.state.cost_splits?.length > 0) {
                cost_splits = [...this.state.cost_splits]
            }
            if (this.state.initialRecordData?.series_pattern === null || this.state.initialRecordData?.series_pattern === undefined) {
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["series_pattern"]: "$"
                    },
                }));
            } else {
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["series_pattern"]: "$" + this.state.initialRecordData?.series_pattern
                    },
                }));
            }
        }
        // if (cost_splits.length > 0) {
        //     if (cost_splits[0].cost_center_id !== this.props.defaultCostCenter.cost_center_id) {
        //         cost_splits.splice(0, 0, { ...this.props.defaultCostCenter, percentage: 100 });
        //     }
        // }
        if (cost_splits.length == 0) {
            cost_splits.push({ ...this.props.defaultCostCenter, percentage: 100 });
        }
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["cost_splits"]: cost_splits
            },
            cost_splits
        }))
        this.props.costCenterOptions?.map((item, index) => {
            item["percentage"] = 0;
            costSplitOptions.push(item);
        });
        if (this.props.mode === "New") {
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    ["budget_name"]: "New Budget"
                }
            }))
        }
    }
    render() {
        let percentSum = 0, errorPercent = false, errorDuplicate = false;
        if (this.state.cost_splits?.length > 0) {
            percentSum = this.state.cost_splits.reduce(function (previousValue, currentValue) {
                return previousValue + Number(currentValue.percentage);
            }, 0);
            errorDuplicate = this.getDistinctArray(this.state?.cost_splits).length !== this.state?.cost_splits.length ? true : false;
            errorPercent = (percentSum <= 100) ? false : true;
        }
        return (
            <MDBContainer fluid className="subTabEditModal">
                <div className="subTabEditModal">
                    {this.state.errorFlag && <p className="errorMessage">{this.state.errorText}</p>}
                    <MDBRow style={{ marginTop: "2%" }}>
                        <MDBCol md={10}>
                            {
                                // this.props.mode === "New" &&
                                <BasicTextField
                                    label={"Budget Name"}
                                    isMandatory={true}
                                    showMandatory={this.state.postInitiated || false}
                                    value={this.state.initialRecordData?.budget_name}
                                    mandatoryWarning={messages.mandatoryWarning}
                                    onChange={(e) => this.onChangeCallback('budget_name', e.target.value)} />}
                            {/* {this.props.mode === "Edit" && <BasicTextField value={this.state.initialRecordData?.budget_name} onChange={(e) => this.onChangeCallback('budget_name', e.target.value)} />} */}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <BasicLabel text={"Budget Description"} />
                        <BasicTextArea value={this.state.initialRecordData?.budget_desc} onChange={(e) => this.handleChange('budget_desc', e.target.value)} />
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <BasicTextField label={"Series pattern"}
                                //placeHolderText={"$"}
                                isMandatory={true}
                                showMandatory={this.state.postInitiated || false}
                                mandatoryWarning={messages.mandatoryWarning}
                                value={this.state.initialRecordData?.series_pattern ? this.state.initialRecordData?.series_pattern : "$0"}
                                onChange={(e) => this.handleChange('series_pattern', e.target.value.trim().length === 0 ? null : e.target.value)}
                            // fieldValid={this.state?.initialRecordData?.series_pattern ? (!validateNumbersOnly(this.state?.initialRecordData?.series_pattern?.slice(1)) ? true : false) : false}
                            // inValidInput={messages.invalidInput} 
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={8}>
                            <BasicLabel text={"Prep Date"} />
                            <DateField value={this.state.initialRecordData?.prep_date?.split("T")[0] || null} onChange={(e) => this.handleChange('prep_date', e.target.value)} />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={12}>
                            <BasicLabel text={"Cost Splits"} />
                        </MDBCol>
                    </MDBRow>
                    {errorDuplicate && <p className="errorPercent">Cost Code should be unique.</p>}
                    {errorPercent && <p className="errorPercent">Total Percent should be less than or equal to 100.</p>}
                    {/* <div className="costSplitContainer"> */}
                    {this.state.cost_splits?.map((item, index) => (
                        <MDBRow id={"cost_split" + index}>
                            <MDBCol md={5}>
                                <SelectField
                                    value={item.cost_center_id}
                                    options={costSplitOptions || []}
                                    onChange={(e) => this.onDropdownChange('cost_center_id', index, e.target.value)}

                                />
                            </MDBCol>
                            <MDBCol md={4}>
                                <BasicTextField value={item.percentage} onChange={(e) => this.handleCostSplitChange('percentage', index, e.target.value)} />
                            </MDBCol>
                            <MDBCol md={3}>
                                <div className="plus-icon">
                                    {this.state.cost_splits?.length - 1 === index ?
                                        <MDBIcon icon="plus-circle" className={index === 0 ? "plus-height" : "float-right"} onClick={() => this.addCallback(index)} /> : ""
                                    }
                                    {this.state.cost_splits?.length > 1 && index !== 0 ?
                                        <MDBIcon icon="minus-circle" className={index === 0 ? "plus-height " : "float-right"} onClick={() => this.removeCallback(index)} /> : ""
                                    }
                                </div>
                            </MDBCol>
                        </MDBRow>

                    ))}
                    {/* </div> */}

                    <MDBRow>
                        <MDBCol md={8}></MDBCol>
                        <MDBCol md={4} className="saveCancelWrapper">
                            <div>
                                <BasicButton disabled={(errorDuplicate === true ? true : false) || (errorPercent === true ? true : false) || (invalidValuesField.length > 0 ? true : false)} variant="contained" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : " "} icon={this.state.isPosting ? " " : "save"} onClick={this.handleSubmit} />
                            </div>
                            <div>
                                <BasicButton
                                    variant="outlined"
                                    icon={"times"}
                                    text=" " onClick={this.props.handleClose}
                                />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>

            </MDBContainer>
        )
    }
}
export default withUserContext(YearsBudgetModal);