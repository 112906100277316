import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from '../../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../../components/SharedComponents/BasicTextArea/BasicTextArea';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import Radio from "@material-ui/core/Radio";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField"
import './EditBudgetTabModal.scss';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import { budgetTabJSON, mockUnionsRecord } from './config';
import { getFormattedDate, getDateAndTime } from '../../../Common/Helper';
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import messages from '../../../Common/Messages.json';

class EditBudgetTabModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            budgetTabModalDetails: this.props.selectedmusicBudgetRecord,
            unionList: [],
            isLoading: false,
            isPosting: false,
            type: null,
            postInitiated: true
        }
    }
    componentDidMount() {
        // this.getBudgetTabDetails();
        this.fetchStaticData("lu_union", "unionList");
    }

    getBudgetTabDetails = () => {
        this.setState({ isLoading: true });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonTvCreativeMusicBudget?show_season_id=${this.props.show_season_id}`,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                this.setState({ budgetTabModalDetails: this.formatBudgetDetails(response.data[0]), isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    }

    fetchStaticData = (entity, node) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=${entity}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let formattedList = response.data?.map(item => ({ value: item.id, label: item.name }));
                formattedList.unshift({ label: "Select", value: null })
                this.setState({ unionList: formattedList })
                // this.getDistinctArray(this.state.unionList)
            },
                (err) => {
                    console.log("Error in fetching static data:", err)
                })
    }

    getDistinctArray = (arr) => {

        const distincitUserRoles = arr.filter((ele, index, arr) =>

            index === arr.findIndex((item) => (

                item.union_id == ele.union_id

            )))
        return distincitUserRoles

    }
    handleChange = (field, value, index) => {
        if (field === "unions") {
            let selectedDetails = { ...this.state.budgetTabModalDetails };
            selectedDetails[field][index].union_id = value
            this.setState({ budgetTabModalDetails: selectedDetails })
        }
        else if (value != "") {
            this.setState({
                budgetTabModalDetails: {
                    ...this.state.budgetTabModalDetails,
                    [field]: value,
                },
            });
        }
        else {
            this.setState({
                budgetTabModalDetails: {
                    ...this.state.budgetTabModalDetails,
                    [field]: null,
                },
                postInitiated: false
            });
        }
    };

    formatBudgetDetails = (response) => {
        if (response.unions.length === 0) {
            response?.unions?.push(JSON.parse(JSON.stringify({ ...mockUnionsRecord })));
            return response;
        }
        else {
            return response;
        }

    }

    removeCallback = (Index) => {
        let allDetails = this.state.budgetTabModalDetails
        allDetails.unions.splice(Index, 1)
        this.setState({ budgetTabModalDetails: allDetails })
    }

    addCallback = (Index) => {
        let allDetails = this.state.budgetTabModalDetails
        allDetails.unions.push(JSON.parse(JSON.stringify({ ...mockUnionsRecord })))
        this.setState({ budgetTabModalDetails: allDetails })
    }
    handleSubmit = () => {
        let postObj = { ...this.state.budgetTabModalDetails }
        postObj.type = this.props.is_pilot === 1 ? "PILOT" : "SERIES";
        postObj.show_season_id = this.props.show_season_id;
        postObj.season_id = this.props.season_id;
        postObj.show_id = this.props.show_id;
        postObj.show_season_episode_id = this.props.episode_id;
        postObj.version = this.props.mode === "Edit" ? this.state.budgetTabModalDetails.version : this.props.versionNum;
        //this.state.budgetTabModalDetails.show_season_episode_id = this.props.show_id;
        if (postObj?.version_description && postObj?.version_description?.trim()?.length > 0) {
            this.setState({ isPosting: true })
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonTvCreativeMusicBudget`,
                postObj,
                this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                .then(
                    (response) => {
                        console.log("response", response)
                        if (response.data.error) {
                            this.setState({ isPosting: false })
                            this.props?.notificationComponent(true, "fail");

                        } else {
                            this.setState({ isPosting: false });
                            if (this.props.mode === "Edit") {
                                this.props.getVersions(this.props.show_season_id, this.props.episode_id);
                                this.props.getHeaderData(this.props.show_season_id, this.props.episode_id, this.state.budgetTabModalDetails?.version);
                                this.props.getRecordingOrchestralCameraPerformersData(this.props.show_season_id, this.state.budgetTabModalDetails?.show_season_tv_creative_music_budget_id);
                            } else {
                                console.log("post response", response.data);
                                this.props.getVersions(this.props.show_season_id, this.props.episode_id)
                                //this.props.getHeaderData(this.state.show_season_id, this.props.episode_id, null);
                                // this.props.getRecordingOrchestralCameraPerformersData(this.props.show_season_id, null);
                            }

                            this.props?.notificationComponent(true, "success");
                        }
                    },
                    (err) => {
                        this.setState({ isPosting: false, });
                        this.props?.notificationComponent(true, "fail")
                    }
                )
        }
    }


    render() {
        return (
            <>
                {this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (
                        <MDBContainer className="EditBudgetTabModal-container">
                            {(this.props.is_pilot === 0 && this.props.episodeOptions.length === 0) && <p className="addEpisodeNotification">Please add an episode</p>}
                            <div className={this.props.is_pilot === 0 && this.props.episodeOptions.length === 0 ? "disabled" : null}>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            // className="percentfield"
                                            label={"Version Description"}
                                            isMandatory={true}
                                            showMandatory={this.state.postInitiated || false}
                                            mandatoryWarning={messages.mandatoryWarning}
                                            placeholder={this.props.mode === "Edit" ? `This is Version ${this.state.budgetTabModalDetails?.version}` : `This is version ${this.props.versionNum}`}
                                            value={this.state.budgetTabModalDetails?.version_description}
                                            onChange={(e) => this.handleChange("version_description", e.target.value.trim().length > 0 ? e.target.value : null)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6} className="radioBtnContainer">
                                        <Radio name="radio-buttons" id="Pilot" disabled checked={this.props.is_pilot === 1}
                                            value={this.props.is_pilot} />
                                        <span className='content-font'>Pilot/Others</span>
                                        <Radio name="radio-buttons" id="Series" disabled checked={this.props.is_pilot === 0 || this.props.is_pilot === null}
                                            value={this.props.is_pilot} />
                                        <span className='content-font'>Series</span>
                                    </MDBCol>
                                    {/* <MDBRow>
                                        <MDBCol md={8}>
                                            <BasicTextField
                                                // className="percentfield"
                                                label={"Version Description"}
                                                placeholder={"This is Version 1"}
                                                value={this.state.budgetTabModalDetails?.version_description}
                                                onChange={(e) => this.handleChange("version_description", e.target.value)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={2} className="selfEnd">
                                            <BasicCheckbox
                                                id={"pilot_others"}
                                                label="Pilot/Others"
                                                align="end"
                                                checked={this.state.pilotType}
                                                onChange={(e) => this.handleChange("pilotType", e.target.checked)}
                                            />
                                        </MDBCol>
                                    </MDBRow> */}
                                    {/* <MDBCol md={6}>
                                    <MDBRow>
                                        <MDBCol md={2} className="m-t">
                                            <BasicCheckbox
                                                id={"pilot_others"}
                                                label="Series"
                                                align="end"
                                                checked={this.state.seriesType}
                                                onChange={(e) => this.handleChange("seriesType", e.target.checked)}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol> */}
                                </MDBRow>
                                {/*                 
                            <div >
                            
                                {this.state.budgetTabModalDetails?.unions.map((item, index) => (
                                    <div className="union-field">
                                        <MDBCol md={3} className="pb-3">
                                            <SelectField
                                                id="Type"
                                                label={`${index === 0 ? "Unions" : ""}`}
                                                value={item.union_id || null}
                                                options={this.state.unionList || []}
                                                onChange={(e) => this.handleChange("unions", e.target.value, index)}
                                            />
                                        </MDBCol>
                                        <MDBCol md={1} className="icons-align">
                                            <div className="plus-icon">
                                                <MDBCol>
                                                    {this.state.budgetTabModalDetails?.unions?.length - 1 === index ?
                                                        <MDBIcon icon="plus-circle" className={index === 0 ? "plus-height" : "float-left"} onClick={() => this.addCallback(index)} /> : ""
                                                    }
                                                </MDBCol>
                                                <MDBCol className="p2">
                                                    {this.state.budgetTabModalDetails?.unions?.length > 1 ?
                                                        <MDBIcon icon="minus-circle" className={index === 0 ? "plus-height " : "float-left"} onClick={() => this.removeCallback(index)} /> : ""
                                                    }
                                                </MDBCol>
                                            </div>
                                        </MDBCol>
                                    </div>
                                ))}
                                {this.getDistinctArray(this.state?.budgetTabModalDetails?.unions).length !== this.state?.budgetTabModalDetails?.unions.length ? 
                                <span className="errorText">This union already existed Please select other union</span> : null}
                                <MDBCol md={8}></MDBCol>
                            </div>
                 */}
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            label={"Type of Use/Timing"}
                                            //placeholder={"[Type of Use/Timing]"}
                                            value={this.state.budgetTabModalDetails?.type_of_use}
                                            onChange={(e) => this.handleChange("type_of_use", e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            label={"Shoot Date(s)"}
                                            //placeholder={"The month of February 2018-2019"}
                                            value={this.state.budgetTabModalDetails?.shoot_date}
                                            onChange={(e) => this.handleChange("shoot_date", e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            label={"Recording Date(s)"}
                                            //placeholder={"February 2018"}
                                            value={this.state.budgetTabModalDetails?.recording_date}
                                            onChange={(e) => this.handleChange("recording_date", e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        <BasicTextField
                                            label={"Location"}
                                            //placeholder={"Chicago"}
                                            value={this.state.budgetTabModalDetails?.location}
                                            onChange={(e) => this.handleChange("location", e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicTextArea
                                            label={"Scene Description(s)"}
                                            rows={3}
                                            //placeholder={"This is a scene description"}
                                            value={this.state.budgetTabModalDetails?.scene_description}
                                            onChange={(e) => this.handleChange("scene_description", e.target.value)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        <BasicTextArea
                                            label={"Version Notes"}
                                            rows={3}
                                            //placeholder={"These are version notes"}
                                            value={this.state.budgetTabModalDetails?.version_notes}
                                            onChange={(e) => this.handleChange("version_notes", e.target.value)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={8}></MDBCol>
                                    <MDBCol md={4} className="saveCancelContainer">
                                        <BasicButton
                                            className="basic-btn"
                                            type="inline"
                                            variant="contained"
                                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                                            onClick={this.handleSubmit}
                                        />
                                        <BasicButton
                                            variant="outlined"
                                            type="inline"
                                            text={"Cancel"}
                                            onClick={this.props?.handleClose}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </div>

                        </MDBContainer >
                    )
                }
            </>
        );
    }
}

export default withUserContext(EditBudgetTabModal);
