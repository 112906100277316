import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";;
import TableComponent from "../../SharedComponents/Table";
import {
  sendersConfig,
  airingOptions,
  initialCueSheetData,
} from "./config";
import "./CueSheetDistribution.scss";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import ClearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SearchSelectField from "../../SharedComponents/SearchSelectField";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from "../../../contexts/UserContext";
import axios from "axios";
import {getFormattedDate} from'../../../Common/Helper';
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent";
import MessageModal from "../../SharedComponents/MessageModal";

export default withUserContext(
  class CueSheetDistribution extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        headings: JSON.parse(JSON.stringify({ ...sendersConfig })),
        revisionList: [],
        cueSheetRecordData: JSON.parse(
          JSON.stringify({ ...initialCueSheetData })
        ),
        airingOptions: [...airingOptions],
        multipleEpisodeOptions: [],
        selectedCellId: null,
        multipleCueSheetEpisodes: [],
        isEpisodeLoading: false,
        isDataLoading: false,
        showsOptions: [],
        seasonOptions: [],
        episodeOptions: [],
        searchCancelToken: null,
        isFetchingShow: false,
        isFetchingCuesheetDistribution: false,
        episodeList: [],
        resendLoading: false,
        filteredCuesheet: [],
        isOpenEmailModal: false,
        emailTextMsg: null,
        newRevisionLoading: false,
        sendCuesheetLoading: false,
        filteredEpisodeList: [],
        isOpenEpisodeErrMsg:false,
        openNotification:null,
        anyErrorMessage:null,
      };
    }
    componentDidMount() {
      this.getSeasonBasedShowList(this.props?.showSeasonData?.season_id, true)
      let data = { ...this.state.cueSheetRecordData };
      // this.getShowBasedEpisodes(this.props?.showSeasonData?.show_season_id);
      if (this.props?.isCueSheetDistribution) {
        data.show_id = this.props?.showSeasonData?.show_id;
        data.show_name = this.props?.showSeasonData?.show_name;
        data.season_id = this.props?.showSeasonData?.season_id;
        data.show_season_id = this.props?.showSeasonData?.show_season_id;
        data.selected_show = {
          value: this.props?.showSeasonData?.show_id,
          text: this.props?.showSeasonData?.show_name,
        };
        data.cueSheet_type = 'send'
      } else {
        data.show_id = this.props?.showSeasonData?.show_id;
        data.show_name = this.props?.showSeasonData?.show_name;
        data.season_id = this.props?.showSeasonData?.season_id;
        data.show_season_id = this.props?.showSeasonData?.show_season_id;
        data.selected_show = {
          value: this.props?.showSeasonData?.show_id,
          text: this.props?.showSeasonData?.show_name,
        };
        data.cueSheet_type = 'send'
      }
      this.setState({ cueSheetRecordData: data },() => {
        this.getSeasons(true);
        this.getCuesheetEpisodes('send', true);
      });
    }

    getShowLists = (value) => {
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel(
          "Operation canceled due to new request"
        );
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      if (value) {
        this.setState({ isFetchingShow: true });
        ClearTrackService.getDataWithCancel(
          Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=SHOW&searchString=${value}`,
          cancelToken,
          this.props.userContext?.active_tenant?.tenant_id
        ).then(
          (response) => {
            let formattedList = response?.data?.map((item) => ({
              value: item.show_id,
              text: item.show_name,
              division_id: item.division_id,
            }));
            this.setState({
              showsOptions: formattedList,
              isFetchingShow: false,
            });
          },
          (err) => {
            console.log("Error in fetching entities:", err);
          }
        );
      }
    };

    getSeasons = (initialLoad = false) => {
      if(!initialLoad) {
        this.handleChange("cueSheet_type", null);
        this.handleChange("Initial", 0);
        this.handleChange("alt1", 0);
        this.handleChange("alt2", 0);
      }
      // this.setState({ isFetchingCuesheetDistribution: true });
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=SEASON&searchString=null`,
        this.props.userContext?.active_tenant?.tenant_id,
        this.props.showSeasonData?.division_id
      ).then(
        (response) => {
          let formattedList = response.data?.map((item) => ({
            value: item.season_id,
            label: item.season_name,
          }));
          this.setState({
            seasonOptions: formattedList,
            // seasonBasedEpisodes: response.data[0]?.seasons,
            isFetchingCuesheetDistribution: false,
          });
        },
        (err) => {
          console.log("Error in fetching Tenant Details:", err);
        }
      );
    };

    getSeasonBasedShowList = (value, firstTime=false) => {
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel(
          "Operation canceled due to new request"
        );
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      if (value) {
        this.setState({ isFetchingShow: true });
        ClearTrackService.getDataWithCancel(
          Constants.ClearTrackServiceBaseUrl +
            `/entityHierarchical?entity=SEASON-SHOW&entity_id=${value}`,
          cancelToken,
          this.props.userContext?.active_tenant?.tenant_id,
          this.props.showSeasonData?.division_id
        ).then(
          (response) => {
            let formattedList = response?.data?.map((item) => ({
              value: item.show_id,
              text: item.show_name,
              show_season_id: item?.show_season_id,
              season_id: item?.season_id,
            }));
            this.setState({
              filteredShowOptions: formattedList,
              isFetchingShow: false,
            },() => {
              if(!firstTime ) {
                let data = {...this.state.cueSheetRecordData}
                data.show_id = formattedList?.length > 0 ? formattedList[0]?.value : null;
                data.show_name =  formattedList?.length > 0 ? formattedList[0]?.text :null;
                data.show_season_id =  formattedList?.length > 0 ? formattedList[0]?.show_season_id :null;
                data.selected_show =  formattedList?.length > 0 ?  {
                  value: formattedList[0]?.value,
                  text: formattedList[0]?.text,
                } : null;
                this.setState({cueSheetRecordData:data})
              }
            });
          },
          (err) => {
            console.log("Error in fetching entities:", err);
          }
        );
      }
    };

    // getShowBasedEpisodes = (showSeasonId) => {
    //   ClearTrackService.getData(
    //     Constants.ClearTrackServiceBaseUrl +
    //       `/showSeasonEpisodes?show_season_id=${showSeasonId}`,
    //     this.props.userContext?.active_tenant?.tenant_id,
    //     this.props.showSeasonData?.division_id
    //   ).then(
    //     (response) => {
    //       let formatData = response.data?.map((item) => ({
    //         show_season_episode_id: item?.show_season_episode_id,
    //         episode_number: item?.episode_number,
    //         episode_title: item?.episode_title,
    //         is_checked: 0,
    //         cue_sheet_header_id: item?.cue_sheet_header_id,
    //         airing:item?.airing
    //       }));
    //       this.setState({ episodeList: formatData });
    //     },
    //     (err) => {
    //       console.log("Error in fetching Details:", err);
    //     }
    //   );
    // };

    handleChange = (field, value) => {
      this.setState((prevState) => ({
        cueSheetRecordData: {
          ...prevState.cueSheetRecordData,
          [field]: value,
        },
      }));
    };
    onChangeCallBack = (field, val, eventValue) => {
      let data = { ...this.state.cueSheetRecordData };
      if (field === "show") {
        if (val) {
          let season_id = this.state.cueSheetRecordData?.season_id
          data = JSON.parse(
            JSON.stringify({ ...initialCueSheetData })
          )
          data.season_id = season_id
          data[field + "_id"] = val.value || null;
          data[field + "_name"] = val.text || null;
          data["selected_show"] = val;
          let showSeasonId = this.state.filteredShowOptions.find(
            (item) => item.value === val.value
          )?.show_season_id;

          data["show_season_id"] = showSeasonId;
        }
      } else if (field === "season_id") {
        data = JSON.parse(
          JSON.stringify({ ...initialCueSheetData })
        )
        data[field] = val;
        this.getSeasonBasedShowList(val);
      }
      this.setState({ cueSheetRecordData: data , filteredEpisodeList:[], revisionList:[], episodeList:[]});
    };
    getRevisionCueSheets = (episodeId, cueSheetType) => {
      this.setState({ isDataLoading: true });
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
          `/cueSheetDistributionRevision?show_season_id=${
            // this.state.cueSheetRecordData?.show_season_id
            this.state.cueSheetRecordData?.show_season_id
            //   : this.props.showSeasonData?.show_season_id
          }&show_season_episode_id=${episodeId}&airing=${cueSheetType}`,
        this.props?.userContext?.active_tenant?.tenant_id,
        this.props.showSeasonData?.division_id
      )
        .then((response) => {
          let formattedList = response?.data?.map(item => {
            let newItem = {...item}
            newItem.send_date = item?.send_date ? getFormattedDate(item?.send_date?.split('T')[0]) : null
            return newItem
          })
          this.setState({ revisionList: formattedList, isDataLoading: false });
        })
        .catch((err) => {
          this.setState({ isDataLoading: false });
        });
    };
    handleSelectedCell = (obj, node) => {
      this.setState({ selectedCellId: obj.revision });
      let cueSheetDistribution = { ...this.state.cueSheetRecordData };
      cueSheetDistribution.revision = obj.revision;
      cueSheetDistribution.show_season_episode_id = obj.show_season_episode_id;
      this.setState({ cueSheetRecordData: cueSheetDistribution });
    };
    postCueSheetRevision = (type) => {
      let revisionValue = { ...this.state.cueSheetRecordData };
      revisionValue.show_season_episode_ids = [];
      revisionValue.episodes_list = [];
      this.state.filteredEpisodeList?.filter((data) => {
        if (data?.is_checked === 1) {
          revisionValue?.show_season_episode_ids.push(
            data.show_season_episode_id
          );
          revisionValue?.episodes_list?.push({
            show_season_episode_id: data.show_season_episode_id,
            airing: data.airing,
            cue_sheet_header_id: data?.cue_sheet_header_id,
          });
        }
      });
      let showSeasonEpisodeIds =
        revisionValue?.show_season_episode_ids?.toString();

      let postJson = {
        episodes_list: revisionValue?.episodes_list,
        type: type,
        show_season_episode_ids: showSeasonEpisodeIds,
        show_season_id: this.state.cueSheetRecordData?.show_season_id || null,
        //  this.state.cueSheetRecordData?.show_season_id
        //   ? this.state.cueSheetRecordData?.show_season_id
        //   : this.props.showSeasonData?.show_season_id,
        // revision_no:
        //   this.state.revisionList?.length > 0 ? revisionValue.revision : 0,
        email_to: [],
        is_include_licensors: revisionValue?.is_include_licensors,
      };
      // if(type === 'Send') {
      //   postJson.revision_no = 0
      // }
      if (revisionValue?.show_season_episode_ids?.length > 0) {
        this.setState({
          //isLoading: true,
          resendLoading: type === "Resend" ? true : false,
          newRevisionLoading: type === "New Revision" ? true : false,
          sendCuesheetLoading: type === "Send" ? true : false,
        });
        ClearTrackService.postDataParams(
          Constants.ClearTrackServiceBaseUrl + `/sendMailToCueSheetDistributors`,
          postJson,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props.showSeasonData?.division_id
        )
          .then((response) => {
            if (!response.data.error) {
              this.setState({
                isLoading: false,
                resendLoading: false,
                // emailTextMsg: response?.data?.msg,
                // isOpenEmailModal: true,
                openNotification: response?.data?.success ? 'success' : 'error',
                anyErrorMessage:response?.data?.msg,
                sendCuesheetLoading: false,
                newRevisionLoading: false,
              });
              //this.props?.fieldChanged(false, "error");
              //this.props?.notificationComponent(true, "success");
            } else {
              this.setState({
                isLoading: false,
                resendLoading: false,
                sendCuesheetLoading: false,
                newRevisionLoading: false,
                isOpenEmailModal: false,
                openNotification:'error',
              });
              //this.props?.notificationComponent(true, "fail");
            }
          }),
          ((err) => {
            this.setState({
              isLoading: false,
              resendLoading: false,
              sendCuesheetLoading: false,
              newRevisionLoading: false,
              isOpenEmailModal: false,
              openNotification:'error',
            });
            //this.props?.notificationComponent(true, "fail");
          });
      } else {
        this.setState({isOpenEpisodeErrMsg:true})
      }
    };
    handleCuesheetGroupChange = (field, value) => {
      let episodeDataList = [...this.state.episodeList];
      if (field === "Initial") {
        this.handleChange("Initial", value);
      } else if (field === "Alternate 1") {
        this.handleChange("alt1", value);
      } else if (field === "Alternate 2") {
        this.handleChange("alt2", value);
      }

      let filteredCueSheets = [...this.state.filteredEpisodeList];
      episodeDataList.filter((item) => {
        if (item.airing === "Initial" && field === "Initial" && value === 1) {
          filteredCueSheets.push(item);
        } else if (
          item.airing === "Initial" &&
          field === "Initial" &&
          value === 0
        ) {
          filteredCueSheets = filteredCueSheets.filter(
            (item1) => item1.airing !== "Initial"
          );
        }
        if (
          item.airing === "Alternate 1" &&
          field === "Alternate 1" &&
          value === 1
        ) {
          filteredCueSheets.push(item);
        } else if (
          item.airing === "Alternate 1" &&
          field === "Alternate 1" &&
          value === 0
        ) {
          filteredCueSheets = filteredCueSheets.filter(
            (item1) => item1.airing !== "Alternate 1"
          );
        }
        if (
          item.airing === "Alternate 2" &&
          field === "Alternate 2" &&
          value === 1
        ) {
          filteredCueSheets.push(item);
        } else if (
          item.airing === "Alternate 2" &&
          field === "Alternate 2" &&
          value === 0
        ) {
          filteredCueSheets = filteredCueSheets.filter(
            (item1) => item1.airing !== "Alternate 2"
          );
        }

        this.setState((prevState) => ({
          filteredEpisodeList: [...filteredCueSheets],
        }));
      });
    };
    handleCuesheetTypeChange = (value) => {
      this.setState({filteredEpisodeList:[], revisionList:[]})
      this.handleChange("cueSheet_type", value);
      this.handleChange("Initial", 1);
      this.handleChange("alt1", 0);
      this.handleChange("alt2", 0);
      this.handleChange("is_include_licensors",0)
      this.getCuesheetEpisodes(value, true);
    };
    getCuesheetEpisodes = (value, intialLoad = false) => {
      this.setState({ isEpisodeLoading: true });
      let entityType;
      if (value === "send") {
        entityType = "SEND_CUE_SHEET_EPISODES";
      } else {
        entityType = "RESEND_CUE_SHEET_EPISODES";
      }
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
          `/entitySearch?entity=${entityType}&searchString=${
            // this.state.cueSheetRecordData?.show_season_id
             this.state.cueSheetRecordData?.show_season_id
            //   : this.props.showSeasonData?.show_season_id
          }`,
        this.props?.userContext?.active_tenant?.tenant_id,
        this.props.showSeasonData?.division_id
      )
        .then((response) => {
          let formatData = response.data?.map((item) => ({
            show_season_episode_id: item?.show_season_episode_id,
            episode_number: item?.episode_number,
            episode_title: item?.episode_title,
            is_checked: 0,
            cue_sheet_header_id: item?.cue_sheet_header_id,
            airing: item?.airing,
          }));
          this.setState({
            episodeList: formatData,
            isEpisodeLoading: false,
          }, () => {
            if(intialLoad) {
              this.handleCuesheetGroupChange("Initial",1)
            }
          });
        })
        .catch((err) => {
          this.setState({ isEpisodeLoading: false });
        });
    };

    onChangeEpisodeList = (field, dataItem, val) => {
      let updated_episode_list = this.state.filteredEpisodeList?.map((item) => {
        let newItem = { ...item };
        if (item?.show_season_episode_id === dataItem?.show_season_episode_id) {
          newItem.is_checked = val;
        }
        return newItem;
      });
      let updatedEpisodeData = { ...this.state.filteredEpisodeList };
      updatedEpisodeData = updated_episode_list;
      let filteredCuesheet = updated_episode_list?.filter(
        (item) => item.is_checked === 1
      );
      if (filteredCuesheet?.length === 1) {
        this.setState({
          // cueSheetRecordData: updateCueSheetData,
          cueSheetRecordData: {
            ...this.state.cueSheetRecordData,
            show_season_episode_id: filteredCuesheet[0]?.show_season_episode_id,
          },
        });

        // this.getRevisionCueSheets(
        //   filteredCuesheet[0]?.show_season_episode_id,
        //   filteredCuesheet[0]?.airing
        // );
      } else {
        this.setState({ revisionList: [] });
      }
      this.setState({
        filteredEpisodeList: updatedEpisodeData,
        filteredCuesheet: filteredCuesheet,
      });
    };
    render() {
      let canEditCueSheets = this.props?.commonPermissions?.find(item => item?.permission_name === "Cue SheDistribution pop-up");
      let disabledCuesheetButtons = canEditCueSheets ? canEditCueSheets?.edit === 1 ? false:true:false
      return (
        <>
          {this.state.isFetchingCuesheetDistribution ? (
            <div className="ContentLoader">
              <CircularProgress />
            </div>
          ) : (
            <MDBContainer fluid className="cuesheetDistributionContainer">
              <NotificationComponent
                  open={this.state.openNotification?.length > 0 ? true : false} 
                  message={this.state.anyErrorMessage ? this.state.anyErrorMessage  : this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                  severity={this.state.openNotification || ''}
                  handleResetNotify={() => this.setState({ openNotification: null ,anyErrorMessage : null})}
              />
              <p className="heading">
                Cue Sheets will be sent to Cue sheet Recipients denoted under
                the 'People' tab
              </p>
              {/* <BasicLabel text={newMode} /> */}
              {/* {this.state.cueSheetRecordData?.multipleCueSheets ? ( */}
              <MDBRow>
                <MDBCol md={4}>
                  <SelectField
                    width={"100%"}
                    label="Season"
                    placeHolderText="- Select -"
                    // isMandatory={true}
                    // showMandatory={this.state.postInitiated}
                    // mandatoryWarning={messages.mandatoryWarning}
                    value={this.state.cueSheetRecordData?.season_id}
                    options={this.state.seasonOptions || []}
                    onChange={(e) => {
                      this.onChangeCallBack("season_id", e.target.value);
                      //this.getSeasons();
                    }}
                  />
                </MDBCol>
                <MDBCol md={6}>
                  <SearchSelectField
                    id={"clearence-show"}
                    label={"Show"}
                    placeholder={"- Search -"}
                    options={this.state.filteredShowOptions || []}
                    //loading={this.state.isFetchingShow}
                    searchSelect={false}
                    value={
                      this.state.cueSheetRecordData?.selected_show
                    }
                    onChange={(e, newValue) =>
                      this.onChangeCallBack("show", newValue, e.target.value)
                    }
                    // searchText={(ev, val) => {
                    //   if (ev.target.value !== "" && ev.target.value !== null) {
                    //     this.getShowLists(ev.target.value);
                    //   }
                    // }}
                  />
                </MDBCol>
              </MDBRow>

              <div>
                <MDBRow>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    id="radio-group-height"
                    value={this.state.cueSheetRecordData?.cueSheet_type || null}
                    onChange={(e) =>
                      this.handleCuesheetTypeChange(e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="send"
                      control={<Radio size="small" />}
                      label="Send Cue Sheets"
                    />

                    <FormControlLabel
                      value="resend"
                      control={<Radio size="small" />}
                      label="ReSend Cue Sheets"
                    />
                    <FormControlLabel
                      value="sendRevision"
                      control={<Radio size="small" />}
                      label="Send Revisions"
                    />
                  </RadioGroup>
                </MDBRow>
                <MDBRow>
                  <BasicLabel text="Select Cue Sheet(s)" />
                  <MDBCol md={4}>
                    <BasicCheckbox
                      label={"Initial Cue Sheets"}
                      id={"initialcuesheet"}
                      checked={this.state.cueSheetRecordData?.Initial}
                      onChange={(e) =>
                        this.handleCuesheetGroupChange(
                          "Initial",
                          e.target.checked ? 1 : 0
                        )
                      }
                    />
                  </MDBCol>
                  <MDBCol md={4}>
                    <BasicCheckbox
                      label={"Alt 1 Cue Sheets"}
                      id={"alt1cuecheet"}
                      checked={this.state.cueSheetRecordData?.alt1}
                      onChange={(e) =>
                        this.handleCuesheetGroupChange(
                          "Alternate 1",
                          e.target.checked ? 1 : 0
                        )
                      }
                    />
                  </MDBCol>
                  <MDBCol md={4}>
                    <BasicCheckbox
                      label={"Alt 2 Cue Sheets"}
                      id={"alt2cuesheet"}
                      checked={this.state.cueSheetRecordData?.alt2}
                      onChange={(e) =>
                        this.handleCuesheetGroupChange(
                          "Alternate 2",
                          e.target.checked ? 1 : 0
                        )
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md={12}>
                    {/* <BasicLabel text="Episodes" /> */}
                    <div className="episodesList">
                      {this.state.filteredEpisodeList?.map((item) => (
                        <BasicCheckbox
                          id={`${item?.show_season_episode_id}-episode-${item?.airing}-${item?.episode_number ? item?.episode_number : ''}-${item?.episode_title ? item?.episode_title: ''}`}
                          checked={item?.is_checked || 0}
                          label={`${item?.episode_number ? item?.episode_number : ''}-${item?.episode_title ? item?.episode_title: ''}(${item.airing})`}
                          onChange={(e) =>
                            this.onChangeEpisodeList(
                              "is_checked",
                              item,
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                      ))}
                    </div>
                    {((this.state.cueSheetRecordData?.Initial || this.state.cueSheetRecordData?.alt1 || this.state.cueSheetRecordData?.alt2) 
                    && this.state.cueSheetRecordData?.cueSheet_type && !this.state.filteredEpisodeList.length > 0 ) ? <div>No Episodes Available</div> : <div></div>}
                  </MDBCol>
                </MDBRow>
              </div>

              {/* <MDBRow className="mt-2">
                {this.state.filteredCuesheet?.length < 2 && (
                  <div id={"sendersTable"}>
                    <TableComponent
                      list={this.state.revisionList || []}
                      config={this.state.headings}
                      handleSelectedCell={this.handleSelectedCell}
                      selectedCellId={this.state.selectedCellId}
                      isLoading={this.state.isDataLoading}
                      stickyHeader={true}
                    />
                  </div>
                )}
              </MDBRow> */}
              <MDBRow>
                <BasicLabel text="Options" />
                <BasicCheckbox
                  label={"Include Licensors in Distribution"}
                  id={"licensorsDistribution"}
                  checked={this.state.cueSheetRecordData?.is_include_licensors}
                  onChange={(e) =>
                    this.handleChange(
                      "is_include_licensors",
                      e.target.checked ? 1 : 0
                    )
                  }
                />
              </MDBRow>
              <MDBRow disabled={disabledCuesheetButtons}>
                <MDBCol md={3}>
                  {this.state.cueSheetRecordData?.cueSheet_type === "send" && (
                    <BasicButton
                      text={
                        this.state.sendCuesheetLoading ? (
                          <CircularProgress color="inherit" size={18} />
                        ) : (
                          "Send Cue Sheet"
                        )
                      }
                      variant="contained"
                      type="inline"
                      onClick={() => this.postCueSheetRevision("Send")}
                    />
                  )}
                </MDBCol>
                <MDBCol md={3}>
                  {this.state.cueSheetRecordData?.cueSheet_type ===
                    "sendRevision" && (
                    <BasicButton
                      text={
                        this.state.newRevisionLoading ? (
                          <CircularProgress color="inherit" size={18} />
                        ) : (
                          "Send Revisions"
                        )
                      }
                      variant="contained"
                      type="inline"
                      onClick={() => this.postCueSheetRevision("New Revision")}
                    />
                  )}
                </MDBCol>
                <MDBCol md={4}>
                  {this.state.cueSheetRecordData?.cueSheet_type ===
                    "resend" && (
                    <BasicButton
                      text={
                        this.state.resendLoading ? (
                          <CircularProgress color="inherit" size={18} />
                        ) : (
                          "ReSend Cue Sheet"
                        )
                      }
                      variant="contained"
                      type="inline"
                      onClick={() => this.postCueSheetRevision("Resend")}
                    />
                  )}
                </MDBCol>
                <MDBCol md={2}>
                  <BasicButton
                    text={"Cancel"}
                    variant="outlined"
                    onClick={() => this.props?.handleClose()}
                  />
                </MDBCol>
              </MDBRow>
              {this.state.isOpenEmailModal && (
                <MessageModal
                  open={this.state.isOpenEmailModal}
                  title={"Message"}
                  message={this.state.emailTextMsg}
                  hideCancel={true}
                  primaryButtonText={"OK"}
                  onConfirm={() => this.setState({ isOpenEmailModal: false })}
                  handleClose={(e) =>
                    this.setState({ isOpenEmailModal: false })
                  }
                />
                )}
                {this.state.isOpenEpisodeErrMsg &&
                  <MessageModal
                    open={this.state.isOpenEpisodeErrMsg}
                    title={"Message"}
                    message={"Please select atleast one Episode"}
                    hideCancel={true}
                    primaryButtonText={"OK"}
                    onConfirm={() =>  this.setState({ isOpenEpisodeErrMsg: false })}
                    handleClose={(e) => this.setState({ isOpenEpisodeErrMsg: false })}
                  />}
            </MDBContainer>
          )}
        </>
      );
    }
  }
);
