export const mainTitleDetails = {
    "title": "The Luck You Got by Josh Maleman",
    "titleWarning": "Do Not Use This Song",
    "totalFee": " $16,000.00",
    "options": [
        {
            value: 1,
            label: "COA",
            icon: "times-circle"
        },
        {
            value: 2,
            label: "All Promo Use Restricted",
            icon: "times-circle"
        },
        {
            value: 3,
            label: "Limited Promo Use",
            icon: "check-circle"
        },
        {
            value: 4,
            label: "Contains Sample(s)",
            icon: "check-circle"
        },
        {
            value: 5,
            label: "Library",
            icon: "check-circle"
        },
        {
            value: 6,
            label: "PD",
            icon: "times-circle"
        },
        {
            value: 5,
            label: "Library",
            icon: "check-circle"
        },


    ],
    "masterFee": " $8,000.00",
    "duration": "2.48",
    "use": "Bckg. Vocal",
    "clearence_rep": "Cindy Rodriguez",
    "Main_title_credits": "None",
    "guaranteed_eps": "4",
    "apply": "Apply to HV",
    "notes": " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupi- datat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "music_group": "All Media 06/07 X-HV / Perp / WW",
    "fee": "$8,000.00",
    "share": "100%",
    "status": "",
    "prvd": "",
    "licensee_rep": "",
    "s_m_licensor": false
}

export const Licensor = {
    "main_title_licensor_id": null,
    "licensor_id": null,
    "licensor_name": "",
    "licensor_type": "",
    "share": 0,
    "status_id": 0,
    "is_they_provide_license": 0,
    "licensee_rep_id": 0,
    "notes": "",
    "is_WFH": 0,
    "is_apply_dollar_to_episodic_budget": 0,
    "is_broad_use_license": 0,
    "is_flat_fee_buyout": 0,
    "is_allow_zero_cost": 0,
    "contract_date": null,
    "addendum_date": null,
    "is_active": 1,
    "is_rights_updated": 0,
    "is_check_request_updated": 0,
    "is_licensor_optional_rights_updated": 0,
    "rights": [],
    "cue_sheet_publisher_info": "",
    "main_title_check_request": [],
    "main_title_licensor_optional_rights": []
}

export const licensorRightsConfig = [
    {
        "clearance_licensor_rights_id": null,
        "right_id": null,
        "right_abbr": null,
        "is_initial": 1,
        "is_alt_initial": 0,
        "territory_id": null,
        "term_id": null,
        "fee": null
    },
    {
        "clearance_licensor_rights_id": null,
        "right_id": null,
        "right_abbr": null,
        "is_initial": 0,
        "is_alt_initial": 1,
        "territory_id": null,
        "term_id": null,
        "fee": null
    }
]
