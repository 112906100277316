import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import TableComponent from "../../../SharedComponents/Table";
import { unionConfig } from "./Config";
import * as Constants from "../../../../constants/constants";
import ClearTrackService from "../../../../services/service";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../../contexts/UserContext';
import '../LookupMaintenance.scss';
import { updateTableSortConfigObject, tableSortList } from '../../../../Common/TableHelper';

class Unions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      config: { ...unionConfig },
      unionNotExist: false,
      sortBy: "Value",
      isSortByAsc: true,
      sortCount: 0,
      duplicateValue:false,
    };
  }

  componentDidMount() {
    this.getUnionDetails();
  }

  getUnionDetails = (loader=true) => {
    this.setState({ isLoading: loader });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + "/staticData?staticTable=lu_union",
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        let useList = response?.data?.map((item) => ({
          name: item.name,
          id: item.id,
          is_active: item.is_active,
        }));
        let configRecord = {...this.state.config}
        this.props?.permissionArray[0].edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
        this.setState({ renderList: useList, config: configRecord, isLoading: false,isSubmit: false });
        this.handleTableColumnClick("Union");
      })
      .catch((err) => {
        console.log("error", err);
        this.setState({ isLoading: false, isSubmit: false });
      });
  };

  handleInlineEdits = (id, dataItem) => {
    let allDetails = [...this.state.renderList];
    this.setState({
      isLoading : false,
      isSubmit:false,
      unionNotExist: false,
      renderList: allDetails.map((item) => {
        let newItem = {...item}
        if (item.id == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ unionNotExist: false,isSubmit:false,isLoading : false ,duplicateValue: false}, () => {
      if (data[index]?.name && data[index]?.name?.length > 0) {
        if(!this.state?.renderList?.some((item,i)=>item?.name?.toLowerCase() ==  data[index]?.name?.toLowerCase() && index != i)) {
        let allDetails = [...this.state.renderList];
        let dataList = [...data];
        allDetails[index].is_active = dataList[index].is_active ? 1 : 0;
        allDetails[index].name = dataList[index].name;
        // this.setState({ renderList: allDetails });
        this.postUnionDetails(allDetails[index], allDetails[index]?.id);
        } else {
          this.setState({ duplicateValue: true })
        }
      } else {
        this.setState({ unionNotExist: true })
      }
    })
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ unionNotExist: false,isSubmit:false , duplicateValue: false}, () => {
      if (newDataItem?.name && newDataItem?.name?.length > 0) {
        if(!this.state?.renderList?.some((item,i)=>item?.name?.toLowerCase() == newDataItem?.name?.toLowerCase())) {
          let unionData = {
            ...newDataItem,
            is_active: 1,
            name: newDataItem?.name,
          };
          this.postUnionDetails(unionData,"newItemPost");
        } else {
          this.setState({ duplicateValue: true })
        }
      } else {
        this.setState({ unionNotExist: true })
      }
    })
    
  };

  postUnionDetails = (unionDetails, postFlag) => {
    unionDetails.table_name = "lu_union";
    this.setState({ isSubmit: postFlag, unionNotExist: false });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/staticData`,
      unionDetails,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.props.toastMessageNotification(true, "fail");
          this.getUnionDetails();
        } else {
          if (unionDetails?.editing === true) {
            unionDetails.editing = false;
          }
           this.setState({ isSubmit: false });
          this.props.toastMessageNotification(true, "success");
          this.getUnionDetails(false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props.toastMessageNotification(true, "fail");
      }
    );
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
    }, () => {
      this.setState({
        sortBy: colName,
        renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Union": return "name";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray
    console.log("value",permissionValue)
    return (
      <div className="union-Container">
        <MDBRow className="pd">
        <span className="errorText">{this.state.unionNotExist && 'Please enter Union field value'}</span>
        <span className="errorText">{this.state.duplicateValue && 'Union value already exist'}</span>
        </MDBRow>
        {this.state.isLoading ? (
          <CircularProgress size={24} />
        ) : (
            <MDBRow>
              <MDBCol md={4} id="union-table-bg-sync-efc">
                <TableComponent
                  list={this.state.renderList || []}
                  className={"clearencRecord"}
                  handleAddNewItem={this.handleAddNewItem.bind(this)}
                  config={this.state.config}
                  editCallback={this.handleInlineEdits.bind(this)}
                  saveCallback={this.saveInlineEdits.bind(this)}
                  addItemToList={true}
                  stickyHeader={true}
                  newIsActive={true}
                  // isLoading={this.state.isSubmit}
                  postInitiated={this.state.isSubmit}
                  readCallback={(index) => {
                    let uinonOptions = [...this.state.renderList];
                    uinonOptions[index].editing = false
                    this.setState({ renderList: uinonOptions, unionNotExist: false });
                  }}
                  sortCallback={this.handleTableColumnClick}
                />
              </MDBCol>
            </MDBRow>
          )}
      </div>
    );
  }
}

export default withUserContext(Unions);
