import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import TableComponent from '../../SharedComponents/Table';
import Radio from "@material-ui/core/Radio";
import "./Budget.scss";
import Popover from '@material-ui/core/Popover';
import PilotModal from './PilotModal';
import MessageModal from '../../SharedComponents/MessageModal';
import { getFormattedDate, validateNumbersOnly } from '../../../Common/Helper';
import CircularProgress from "@material-ui/core/CircularProgress";
import TabComponent from '../../SharedComponents/Tabs/Tabs';
import YearsBudgetModal from './YearsBudgetModal';
import { ClearanceBudgetConfig } from './Config';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import moment from 'moment';
import { withUserContext } from '../../../contexts/UserContext';
let newDetailObject = {}, est_master_fees_total = 0, est_synch_fees_total = 0, canEditBudgetDetails = false, dataToDelete, postObj = {}, load = {};
class YearsBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            subTabValue: 1,
            setModal: null,
            renderList: [],
            config: ClearanceBudgetConfig,
            artistOptions: [],
            songOptions: [],
            pilotCBDetailsHeader: this.props?.pilotCBDetailsHeader,
            detailsToDisplay: {},
            isLoading: false,
            isPostingTableContent: false,
            showDeleteModal: false,
            costCenterOptions: [],
            defaultCostCenter: null,
            setGeneratePdfPopup: null,
            setGenerateExcelPopup: null,
            isPdfPosting: null,
            isExcelPosting: null,
            pdfPayload: {
                "show_season_id": this.props?.show_season_id,
                "show_season_pilot_clearance_budget_id": this.props?.tabValue,
                "is_budget_only": "1",
                "is_hide_series_pattern": 0,
                "is_hide_marketting_cost": 0,
                "is_hide_diclaimer": 0
            },
            excelPayload: {
                "show_season_id": this.props?.show_season_id,
                "show_season_pilot_clearance_budget_id": this.props?.tabValue,
                "is_budget_only": "1",
                "is_hide_series_pattern": 0,
                "is_hide_marketting_cost": 0,
                "is_hide_diclaimer": 0
            },
            errorFlag: false,
            errorMsg: null,
            isEmailLoader: false
        };
    }
    postData = (postJson) => {
        console.log("postJson", postJson);
        this.setState({ isPosting: true })
        let extensionUrl = '/showSeasonPilotClearanceBudget'
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then((response) => {
                if (response.data.errorPercent) {
                    this.setState({ isPosting: false })
                    this.props?.notificationComponent("error");
                } else {
                    this.setState({ isPosting: false });
                    this.props.fetchPilotHeaderData(this.state.detailsToDisplay.show_season_id, "finalBudget", postJson);
                    // this.props?.fetchPilotHeaderData(this.props.show_season_id, this.props.mode === "Edit" ? this.state.initialRecordData.show_season_pilot_clearance_budget_id : this.props.mode === "New" ? "New" : null);
                    this.props?.notificationComponent("success");
                }
            },
                (err) => {
                    this.setState({ isPosting: false });
                    this.props?.notificationComponent("error");
                    console.log("Post agency details errorPercent: " + err);
                });
    }
    postUpdatedData = (dataToUpdate, postFlag) => {
        this.setState({ isSubmit: postFlag });
        // this.setState({ isPostingTableContent: true });
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonPilotClearanceBudgetDetail`,
            dataToUpdate,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ showDeleteModal: false, isSubmit: false });
                    this.props?.notificationComponent("error");
                } else {
                    this.setState({ errorFlag: false, errorMsg: null, showDeleteModal: false });
                    this.props?.notificationComponent("success");
                    if (dataToUpdate?.editing) {
                        dataToUpdate.editing = false;
                    }
                    if (this.props?.tabValue !== undefined) {
                        this.fetchData("pilotCBDetails", `/showSeasonPilotClearanceBudgetDetail?show_season_id=${this.props?.show_season_id}&show_season_pilot_clearance_budget_id=${this.props?.tabValue}`, false);
                    }
                }
            },
            (err) => {
                this.setState({ isPostingTableContent: false, showDeleteModal: false, isSubmit: false });
                this.props?.notificationComponent("error");
                console.log("Post budget details error: " + err);
            }
        );
    };
    handleReadCallback = (index) => {
        let allDetails = [...this.state.renderList]
        allDetails[index].editing = false
        this.setState({ renderList: allDetails })
    }
    handleAddNewItem = (newDataItem) => {
        this.setState({ errorFlag: false, errorMsg: null });
        newDetailObject["show_season_pilot_clearance_budget_id"] = this.props.tabValue;
        newDetailObject["show_season_id"] = this.props.show_season_id;
        newDetailObject["season_id"] = this.props.season_id;
        newDetailObject["show_id"] = this.props.show_id;
        newDetailObject["pg_no"] = newDataItem?.pg_no;
        newDetailObject["description"] = newDataItem?.description;
       // newDetailObject["song_id"] = newDataItem?.song?.value;
        newDetailObject["song_title"] = newDataItem?.song_title;
        //newDetailObject["song"] = newDataItem?.song?.value;
        //newDetailObject["song_name"] = newDataItem?.song?.text;
       // newDetailObject["artist"] = newDataItem?.artist?.value;
       // newDetailObject["artist_id"] = newDataItem?.artist?.value;
        newDetailObject["artist_name"] = newDataItem?.artist_name;
        newDetailObject["est_synch_fees"] = newDataItem?.est_synch_fees;
        newDetailObject["est_master_fees"] = newDataItem?.est_master_fees;
        newDetailObject["notes"] = newDataItem?.notes;
        newDetailObject["is_active"] = newDataItem?.is_active;
        newDetailObject["tenant_id"] = newDataItem?.tenant_id;
        newDetailObject["division_id"] = newDataItem?.division_id;
        let renderedCRItems = [...this.state.renderList];
        if (validateNumbersOnly(newDetailObject["pg_no"]) && newDetailObject["pg_no"] !== null && newDetailObject["pg_no"] !== undefined) {
            if (newDetailObject["est_synch_fees"] === null || newDetailObject["est_synch_fees"] === undefined || (newDetailObject["est_synch_fees"] !== null && newDetailObject["est_synch_fees"] !== undefined && validateNumbersOnly(newDetailObject["est_synch_fees"]))) {
                if (newDetailObject["est_master_fees"] === null || newDetailObject["est_master_fees"] === undefined || (newDetailObject["est_master_fees"] !== null && newDetailObject["est_master_fees"] !== undefined && validateNumbersOnly(newDetailObject["est_master_fees"]))) {
                    this.postUpdatedData(newDetailObject, 'newItemPost');
                }
            }
        }
        else {
            this.setState({ errorFlag: true, errorMsg: "Pg# is required" });
        }

        // renderedCRItems.push(newDetailObject);
        // this.setState({ renderList: renderedCRItems });
    };
    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({ errorFlag: false, errorMsg: null });
        let allDetails = JSON.parse(JSON.stringify(this.state.renderList));
        let tempInlineSavedData = [...data];
        allDetails[index].id = tempInlineSavedData[index]?.show_season_pilot_clearance_budget_detail_id;
        allDetails[index].show_season_id = tempInlineSavedData[index]?.show_season_id;
        allDetails[index].season_id = tempInlineSavedData[index]?.season_id;
        allDetails[index].show_id = tempInlineSavedData[index]?.show_id;
        allDetails[index].pg_no = tempInlineSavedData[index]?.pg_no;
        allDetails[index].description = tempInlineSavedData[index]?.description;
       // if (typeof tempInlineSavedData[index].song == "object") {
            //allDetails[index].song_id = tempInlineSavedData[index]?.song?.value;
            allDetails[index].song_title = tempInlineSavedData[index]?.song_title;
        //} else {
            //allDetails[index].song_id = tempInlineSavedData[index]?.song_id;
           // allDetails[index].song_title = tempInlineSavedData[index]?.song_title;
        //}
        //if (typeof tempInlineSavedData[index].artist == "object") {
           // allDetails[index].artist_id = tempInlineSavedData[index]?.artist?.value;
           
        
           //else {
            allDetails[index].artist_name = tempInlineSavedData[index]?.artist_name;
        // } else {
        //     allDetails[index].artist_id = tempInlineSavedData[index]?.artist_id;
        //     allDetails[index].artist_name = tempInlineSavedData[index]?.artist_name;
        // }
        allDetails[index].est_synch_fees = tempInlineSavedData[index]?.est_synch_fees;
        allDetails[index].est_master_fees = tempInlineSavedData[index]?.est_master_fees;
        allDetails[index].notes = tempInlineSavedData[index]?.notes;
        allDetails[index].is_active = tempInlineSavedData[index]?.is_active;
        allDetails[index].tenant_id = tempInlineSavedData[index]?.tenant_id;
        allDetails[index].division_id = tempInlineSavedData[index]?.division_id;
        if (allDetails[index]["pg_no"] !== null && allDetails[index]["pg_no"] !== undefined && validateNumbersOnly(allDetails[index]["pg_no"])) {
            if (allDetails[index]["est_synch_fees"] === null || allDetails[index]["est_synch_fees"] === undefined || (allDetails[index]["est_synch_fees"] !== null && allDetails[index]["est_synch_fees"] !== undefined && validateNumbersOnly(allDetails[index]["est_synch_fees"]))) {
                if (allDetails[index]["est_master_fees"] === null || allDetails[index]["est_master_fees"] === undefined || (allDetails[index]["est_master_fees"] !== null && allDetails[index]["est_master_fees"] !== undefined && validateNumbersOnly(allDetails[index]["est_master_fees"]))) {
                    this.setState({
                        renderList: allDetails.map((item) => {
                            item['editing'] = false
                            return item
                        })
                    });
                    this.postUpdatedData(allDetails[index], allDetails[index].id);
                }
            }
        }
        else if (!validateNumbersOnly(allDetails[index]["pg_no"])) {
            this.setState({ errorFlag: true });
        }
        else {
            this.setState({ errorFlag: true, errorMsg: "Pg# is required" });
        }
    }
    openDeleteModal = (item) => {
        dataToDelete = { ...item };
        this.setState({ showDeleteModal: true })
    }
    closeDeleteModal = (mode) => {
        this.setState({ showDeleteModal: false })
    }
    deleteTableRow = () => {
        // this.setState({ isSubmit: true });
        clearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=show_season_pilot_clearance_budget_detail&tableId=${dataToDelete?.show_season_pilot_clearance_budget_detail_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(res => {

            if (res.data[0].status === "SUCCESS") {
                dataToDelete["is_delete"] = 1;
                dataToDelete.show_season_id = this.props?.show_season_id;
                dataToDelete.season_id = this.props?.season_id;
                dataToDelete.show_id = this.props?.show_id;
                this.postUpdatedData(dataToDelete, dataToDelete.id);
            } else {
                //this.props?.fieldChanged(true, 'fail')
                this.notificationComponent("error");
                this.setState({ isSubmit: false, showDeleteModal: false, isPostingTableContent: false });
            }




            // dataToDelete = { ...item };
            // console.log("resp", res)

            // if (res.data.error) {
            //     //this.props?.fieldChanged(true, 'fail')
            //     this.notificationComponent(true, "fail");
            //     this.setState({ isSubmit: false, showDeleteModal: false, isPostingTableContent: false });
            // } else {
            //     dataToDelete["is_delete"] = 1;
            //     dataToDelete.show_season_id = this.props?.show_season_id;
            //     dataToDelete.season_id = this.props?.season_id;
            //     dataToDelete.show_id = this.props?.show_id;
            //     this.postUpdatedData(dataToDelete,dataToDelete.id);

            // }
        }),
            ((err) => {
                //this.props?.fieldChanged(true)
                this.notificationComponent("error");
                this.setState({ isSubmit: false });
            });
    };
    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList];
        this.setState({
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                if (item.show_season_pilot_clearance_budget_detail_id == id ) {
                    newItem['editing'] = true;
                }

                return newItem
            })
        })
    }
    handleClickModal = (event) => {
        this.setState({
            setModal: event.currentTarget
        })
    };
    handleCloseModal = () => {
        this.setState({
            setModal: null
        })
    };

    fetchData = (field, url, loader = true) => {
        this.setState({ isLoading: loader, isPostingTableContent: loader })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isLoading: false, isPostingTableContent: false })
                let formattedList = [];
                switch (field) {
                    case 'pilotCBDetailsHeader':
                        //clearanceTabList
                        let clearanceTabList = [];
                        formattedList = response.data;
                        this.setState({ pilotCBDetailsHeader: formattedList, isSubmit: false });
                        break;
                    case 'pilotCBDetails':
                        let tempList = [];
                        response?.data?.length > 0 && response?.data?.map((item, index) => {
                            item["id"] = item.show_season_pilot_clearance_budget_detail_id;
                           // item["song"] = item?.song_id;
                            item["song_title"] = item?.song_title;
                            //item["artist"] = item?.artist_id;
                            item["artist_name"] = item?.artist_name;
                            tempList.push(item);
                        });
                        est_synch_fees_total = tempList.reduce((accum, item) => accum + item.est_synch_fees, 0);
                        est_master_fees_total = tempList.reduce((accum, item) => accum + item.est_master_fees, 0);
                        this.setState({ pilotCBDetails: tempList, renderList: tempList, isSubmit: false });
                        break;
                    case 'costCenterOptions':
                        formattedList = response.data?.map(item => ({ value: item.cost_center_id, label: item.cost_center_name, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        let defaultCostCenter = formattedList?.filter((item) => item.label.includes("4646"));
                        if (defaultCostCenter.length > 0) {
                            defaultCostCenter[0]["cost_center_id"] = defaultCostCenter[0].value;
                            defaultCostCenter[0]["percentage"] = 100;
                        }
                        this.setState({ costCenterOptions: formattedList, defaultCostCenter: defaultCostCenter.length > 0 ? defaultCostCenter[0] : null, isSubmit: false })
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            canEditBudgetDetails = permissions.filter((obj) => obj.permission_name === "Clearance Budget" && obj.view === 1 && obj.edit === 1);
            canEditBudgetDetails = canEditBudgetDetails.length > 0 ? true : false;
            if (canEditBudgetDetails === false) {
                let tempConfig = ClearanceBudgetConfig;
                let actionsToRemove = ["pen", "times"];
                let tempActions = tempConfig.actions.filter(item => !actionsToRemove.includes(item));
                ClearanceBudgetConfig.actions = tempActions;
                this.setState({ config: ClearanceBudgetConfig })
            }
            // canGeneratePDF = permissions.filter((obj, index) => obj.permission_name === "Can generate a PDF of a budget");
            // canGeneratePDF = canGeneratePDF.length > 0 ? true : false;

        }
    }
    getEntity = (searchString, nodeName) => {
        let entitytype;
        switch (nodeName) {
            case 'artist':
                entitytype = "PERFORMER";
                break;
            case 'song':
                entitytype = "SONGS";
                break;
        }
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (entitytype === "PERFORMER") {
                    let performers = response?.data?.map(item => ({
                        text: item.performer_name,
                        value: item.performer_id,
                        is_active: item.is_active
                    }));
                    let tempPerformerData = { ...this.state.config }
                    tempPerformerData.headings[3].selectOptions = performers;
                    this.setState({
                        artistOptions: performers,
                        config: tempPerformerData
                    })
                }
                if (entitytype === "SONGS") {
                    let formattedListsongOptions = response?.data?.map(item => ({
                        text: item.song_title,
                        value: item.song_id,
                        is_active: item.is_active
                    }));
                    let tempSongData = { ...this.state.config }
                    tempSongData.headings[2].selectOptions = formattedListsongOptions;
                    this.setState({
                        songOptions: formattedListsongOptions,
                        config: tempSongData
                    })
                }
            })
    }
    downloadUrl = (url, identifier) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = url
                //link.setAttribute('download', load?.fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                if (identifier === "pdf") {
                    this.setState({ isPdfPosting: false });
                    this.closeGeneratePdfPopup();
                } else {
                    this.setState({ isExcelPosting: false });
                    this.closeGenerateExcelPopup();
                }
                this.props?.notificationComponent('success')
            })
            // clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            //     `/${url}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            //     .then(response => response.blob()).then(blob => {
            //         const link = document.createElement('a');
            //         link.href = window.URL.createObjectURL(blob);
            //         link.setAttribute('download', reportName);
            //         document.body.appendChild(link);
            //         link.click();
            //         link.remove();
            //     })
        }
    }
    generateReport = (identifier, reportName, searchPayload) => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        load = {
            'reportName': reportName,
            'fileName': "Clearance Budget Report",
            'searchJson': encryptTedStr,
            'showHtml': 0,
            'encryptionType': 1,
            'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
            'divisionId': this.props.division_id,
        }
        if (identifier === "pdf" || identifier === "email") {
            load.payload = "exportPdf";
        } else {
            load.payload = "exportExcel";
        }
        return clearTrackService.getDataWS(load);
    }

    generatePdf = (identifier) => {
        let payloadToPass, reportApi;
        if (identifier === "pdf") {
            this.setState({ postInitiated: true, isPdfPosting: true });
            payloadToPass = { ...this.state.pdfPayload };
            payloadToPass["is_budget_only"] = Number(payloadToPass["is_budget_only"]);
            reportApi = "rptshowseasonmusicclearancepilotbudget";
        } else if(identifier === "email"){
            this.setState({isEmailLoader: true})
            payloadToPass = { ...this.state.pdfPayload };
            payloadToPass["send_email"] = 1,
            payloadToPass["is_draft"] = 1, 
            payloadToPass["has_attachment"] = 1,
            payloadToPass["report_type"] = "CLEARANCE_BUDGET",
            reportApi = "rptshowseasonmusicclearancepilotbudget";
        }
        else {
            this.setState({ postInitiated: true, isExcelPosting: true });
            payloadToPass = { ...this.state.excelPayload };
            payloadToPass["is_budget_only"] = Number(payloadToPass["is_budget_only"]);
            reportApi = "rptshowseasonmusicclearancepilotbudgetexcel";
            payloadToPass["isOpenExisting"] = 0;
        }
        this.generateReport(identifier, reportApi, payloadToPass).then(webSocketConn => {
            webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                    if (identifier === "pdf") {
                        this.setState({ isPdfPosting: false });
                    } else if(identifier === "email"){
                        this.setState({isEmailLoader: false})
                    }
                    else {
                        this.setState({ isExcelPosting: false });
                    }
                    if(identifier != "email") {
                        this.downloadUrl(response?.body, identifier);
                    } else {
                        this.props?.notificationComponent('success', "Emails have been saved to your Drafts")
                    }
                } else {
                    this.setState({ isPdfPosting: false, isEmailLoader: false, isExcelPosting: false })
                }
            }
        })

    }
    openGeneratePdfPopup = (e) => {
        this.setState({
            setGeneratePdfPopup: e.currentTarget
        })
    }
    openGenerateExcelPopup = (e) => {
        this.setState({
            setGenerateExcelPopup: e.currentTarget
        })
    }
    closeGeneratePdfPopup = () => {
        this.setState({
            setGeneratePdfPopup: null,
            pdfPayload: {
                "show_season_id": this.props?.show_season_id,
                "show_season_pilot_clearance_budget_id": this.props?.tabValue,
                "is_budget_only": "1",
                "is_hide_series_pattern": 0,
                "is_hide_marketting_cost": 0,
                "is_hide_diclaimer": 0
            }
        })
    };
    closeGenerateExcelPopup = () => {
        this.setState({
            setGenerateExcelPopup: null,
            excelPayload: {
                "show_season_id": this.props?.show_season_id,
                "show_season_pilot_clearance_budget_id": this.props?.tabValue,
                "is_budget_only": "1",
                "is_hide_series_pattern": 0,
                "is_hide_marketting_cost": 0,
                "is_hide_diclaimer": 0
            }
        })
    };
    handleRadioChange(e, identifier) {
        console.log("e.target.value", e.target.value, typeof e.target.value, identifier);
        if (identifier === "pdf") {
            this.setState((prevState) => ({
                pdfPayload: {
                    ...prevState.pdfPayload,
                    ["is_budget_only"]: e.target.value
                }
            }))
        } else {
            this.setState((prevState) => ({
                excelPayload: {
                    ...prevState.excelPayload,
                    ["is_budget_only"]: e.target.value
                }
            }))
        }
    }
    onCheckboxChange = (field, value, identifier = null) => {
        if (field === "is_final_budget") {
            let objToPass = this.state.detailsToDisplay;
            objToPass[field] = value ? 1 : 0;
            this.setState((prevState) => ({
                detailsToDisplay: {
                    ...prevState.detailsToDisplay,
                    [field]: value ? 1 : 0
                }
            }));
            console.log("objToPass in checkbox change", objToPass);
            postObj = objToPass;
            this.postData(postObj);
        } else {
            if (identifier === "pdf") {
                this.setState({
                    pdfPayload: {
                        ...this.state.pdfPayload,
                        [field]: value ? 1 : 0
                    },
                });
            } else {
                this.setState({
                    excelPayload: {
                        ...this.state.excelPayload,
                        [field]: value ? 1 : 0
                    },
                });
            }

        }
    }
   
    render() {
        let openModal = Boolean(this.state.setModal);
        let idModal = open ? 'modal-popover' : undefined;
        let openBusinessAffairsActions = Boolean(this.state.setGeneratePdfPopup);
        let openBusinessAffairsActionsExcel = Boolean(this.state.setGenerateExcelPopup);
        return (
            <>
                {this.state.isLoading ? (
                    <div className="ContentLoader" >
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="Years-Container">
                            {this.props?.tabValue !== undefined &&
                                <MDBCard>
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <MDBRow>
                                                <MDBCol md={4}>
                                                    <MDBRow>
                                                        <MDBCol md={1} id="btn-w">
                                                            {/* {canGeneratePDF && */}
                                                            <>
                                                                <BasicButton
                                                                    icon={"file-pdf"}
                                                                    text=" "
                                                                    variant={"outlined"}
                                                                    onClick={this.openGeneratePdfPopup}
                                                                />
                                                                <Popover
                                                                    open={openBusinessAffairsActions}
                                                                    anchorEl={this.state.setGeneratePdfPopup}
                                                                    onClose={this.closeGeneratePdfPopup}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    PaperProps={{ style: { width: '30%' } }} >
                                                                    <MDBRow>
                                                                        <BasicLabel text={"Report Type"} />
                                                                        <MDBCol md={5}>
                                                                            <Radio name="radio-buttons-pdf" checked={this.state.pdfPayload?.is_budget_only == "1"}
                                                                                onChange={(e) => this.handleRadioChange(e, "pdf")} value="1" />
                                                                            <span className="popover-content-value">Selected Budget Only</span>
                                                                        </MDBCol>
                                                                        <MDBCol md={7}>
                                                                            <Radio name="radio-buttons-pdf" checked={this.state.pdfPayload?.is_budget_only == "0"}
                                                                                onChange={(e) => this.handleRadioChange(e, "pdf")} value="0" />
                                                                            <span className="popover-content-value">Summary (All budgets for this pilot)</span>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    <div>
                                                                        <BasicCheckbox
                                                                            id={"is_hide_series_pattern"}
                                                                            label={"Hide series pattern"}
                                                                            checked={this.state.pdfPayload?.is_hide_series_pattern}
                                                                            onChange={(e) => this.onCheckboxChange("is_hide_series_pattern", e.target.checked, "pdf")}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <BasicCheckbox
                                                                            id={"is_hide_marketting_cost"}
                                                                            label={"Hide marketing cost"}
                                                                            checked={this.state.pdfPayload?.is_hide_marketting_cost}
                                                                            onChange={(e) => this.onCheckboxChange("is_hide_marketting_cost", e.target.checked, "pdf")}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <BasicCheckbox
                                                                            id={"is_hide_diclaimer"}
                                                                            label={"Hide disclaimer"}
                                                                            checked={this.state.pdfPayload?.is_hide_diclaimer}
                                                                            onChange={(e) => this.onCheckboxChange("is_hide_diclaimer", e.target.checked, "pdf")}
                                                                        />
                                                                    </div>
                                                                    <MDBRow>
                                                                        <MDBCol md={8}>
                                                                        </MDBCol>
                                                                        <MDBCol md={4} className="saveCancelWrapper">
                                                                            <BasicButton
                                                                                icon={this.state.isPdfPosting ? " " : "save"}
                                                                                text={this.state.isPdfPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                variant={"contained"}
                                                                                onClick={() => this.generatePdf("pdf")}
                                                                            />
                                                                            <BasicButton
                                                                                icon={"times"}
                                                                                text={" "}
                                                                                variant={"outlined"}
                                                                                onClick={this.closeGeneratePdfPopup}
                                                                            />
                                                                        </MDBCol>
                                                                    </MDBRow>

                                                                </Popover>

                                                            </>
                                                            {/* } */}
                                                        </MDBCol>
                                                        <MDBCol md={1} id="btn-w">
                                                            {/* {canGeneratePDF && */}
                                                            <>
                                                                <BasicButton
                                                                    icon={"file-excel"}
                                                                    text=" "
                                                                    variant={"outlined"}
                                                                    onClick={this.openGenerateExcelPopup}
                                                                />
                                                                <Popover
                                                                    open={openBusinessAffairsActionsExcel}
                                                                    anchorEl={this.state.setGenerateExcelPopup}
                                                                    onClose={this.closeGenerateExcelPopup}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    PaperProps={{ style: { width: '30%' } }} >
                                                                    <MDBRow>
                                                                        <BasicLabel text={"Report Type"} />
                                                                        <MDBCol md={5}>
                                                                            <Radio name="radio-buttons-pdf" checked={this.state.excelPayload?.is_budget_only == "1"}
                                                                                onChange={(e) => this.handleRadioChange(e, "excel")} value="1" />
                                                                            <span className="popover-content-value">Selected Budget Only</span>
                                                                        </MDBCol>
                                                                        <MDBCol md={7}>
                                                                            <Radio name="radio-buttons-pdf" checked={this.state.excelPayload?.is_budget_only == "0"}
                                                                                onChange={(e) => this.handleRadioChange(e, "excel")} value="0" />
                                                                            <span className="popover-content-value">Summary (All budgets for this pilot)</span>
                                                                        </MDBCol>
                                                                    </MDBRow>
                                                                    <div>
                                                                        <BasicCheckbox
                                                                            id={"is_hide_series_pattern_excel"}
                                                                            label={"Hide series pattern"}
                                                                            checked={this.state.excelPayload?.is_hide_series_pattern}
                                                                            onChange={(e) => this.onCheckboxChange("is_hide_series_pattern", e.target.checked, "excel")}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <BasicCheckbox
                                                                            id={"is_hide_marketting_cost_excel"}
                                                                            label={"Hide marketing cost"}
                                                                            checked={this.state.excelPayload?.is_hide_marketting_cost}
                                                                            onChange={(e) => this.onCheckboxChange("is_hide_marketting_cost", e.target.checked, "excel")}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <BasicCheckbox
                                                                            id={"is_hide_diclaimer_excel"}
                                                                            label={"Hide disclaimer"}
                                                                            checked={this.state.excelPayload?.is_hide_diclaimer}
                                                                            onChange={(e) => this.onCheckboxChange("is_hide_diclaimer", e.target.checked, "excel")}
                                                                        />
                                                                    </div>
                                                                    <MDBRow>
                                                                        <MDBCol md={8}>
                                                                        </MDBCol>
                                                                        <MDBCol md={4} className="saveCancelWrapper">
                                                                            <BasicButton
                                                                                icon={this.state.isExcelPosting ? " " : "save"}
                                                                                text={this.state.isExcelPosting ? <CircularProgress color="inherit" size={20} /> : " "}
                                                                                variant={"contained"}
                                                                                onClick={() => this.generatePdf("excel")}
                                                                            />
                                                                            <BasicButton
                                                                                icon={"times"}
                                                                                text={" "}
                                                                                variant={"outlined"}
                                                                                onClick={this.closeGenerateExcelPopup}
                                                                            />
                                                                        </MDBCol>
                                                                    </MDBRow>

                                                                </Popover>

                                                            </>
                                                            {/* } */}
                                                        </MDBCol>
                                                        <MDBCol md={2} id="btn-w">
                                                            <BasicButton
                                                                icon={this.state.isEmailLoader ? <CircularProgress size={20} color={"primary"}/>: "envelope"}
                                                                text={this.state.isEmailLoader ? <CircularProgress size={20} color={"primary"}/>: " "}
                                                                variant={"outlined"}
                                                                onClick={() => this.generatePdf("email")}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={6}>
                                                            <BasicLabel text={"Budget Description"} />
                                                            <p>{this.state.detailsToDisplay?.budget_desc}</p>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                                <MDBCol md={2}>
                                                    <BasicLabel text={"Cost Splits"} />
                                                    <div className="costSplitsContainer">
                                                        {this.state.detailsToDisplay?.cost_splits?.length > 0 && this.state.detailsToDisplay?.cost_splits?.map((item) => (<p>{`${item?.cost_center_name ? item?.cost_center_name : null} - ${item?.percentage ? item?.percentage : null} ${item?.percentage ? `%` : null} `}</p>))}
                                                    </div>
                                                </MDBCol>
                                                <MDBCol md={6}>
                                                    <MDBRow>
                                                        <MDBCol md={2}>
                                                            <BasicLabel text={"Series Pattern"} />
                                                            <p>{this.state.detailsToDisplay?.series_pattern}</p>
                                                        </MDBCol>
                                                        <MDBCol md={2}>
                                                            <BasicLabel text={"Prep Date"} />
                                                            {this.state.detailsToDisplay?.prep_date !== null ? <p>{getFormattedDate(this.state.detailsToDisplay?.prep_date)}</p> : null}
                                                        </MDBCol>
                                                        <MDBCol md={2} >
                                                            <BasicLabel text={"Total Est.Cost"} />
                                                            {this.state.isPostingTableContent ? (
                                                                <div style={{ textAlign: "center" }}>
                                                                    <CircularProgress size={24} />
                                                                </div>
                                                            ) : (<><p>$ {est_master_fees_total + est_synch_fees_total}</p>
                                                            </>)}

                                                        </MDBCol>
                                                        <MDBCol md={3} >
                                                            {canEditBudgetDetails === false &&
                                                                <>
                                                                    <BasicLabel text={"Final Budget"} />
                                                                    <span>{this.state.detailsToDisplay?.is_final_budget === 1 ? "Yes" : "No"}</span>
                                                                </>
                                                            }
                                                            {canEditBudgetDetails === true &&
                                                                <BasicCheckbox
                                                                    id={"is_final_budget"}
                                                                    label={"Final Budget?"}
                                                                    className={this.props.can_edit_final_budget === false && this.state.detailsToDisplay?.is_final_budget === 0 ? "disabled" : null}
                                                                    checked={this.state.detailsToDisplay?.is_final_budget}
                                                                    onChange={this.props.can_edit_final_budget === false && this.state.detailsToDisplay?.is_final_budget === 0 ? null : (e) => this.onCheckboxChange("is_final_budget", e.target.checked)}
                                                                />
                                                            }
                                                        </MDBCol>
                                                        <MDBCol md={1} id="btn-w">
                                                            {canEditBudgetDetails && this.state.defaultCostCenter !== null && <>
                                                                <BasicButton variant="contained"
                                                                    type="inline"
                                                                    text={"Edit"}
                                                                    onClick={this.handleClickModal}
                                                                />
                                                                <MainViewModalComponent
                                                                    open={this.state.setModal}
                                                                    handleClose={this.handleCloseModal.bind(this)}
                                                                    headerText={"Clearance Budget Details"}
                                                                    mode={"Edit"}
                                                                    modalName={"Edit clearance subtab details"}
                                                                    show_season_id={this.props.show_season_id}
                                                                    fetchData={this.fetchData.bind(this)}
                                                                    division_id={this.props.division_id}
                                                                    detailsToDisplay={this.state.detailsToDisplay}
                                                                    fetchPilotHeaderData={this.props.fetchPilotHeaderData}
                                                                    costCenterOptions={this.props.costCenterOptions}
                                                                    defaultCostCenter={this.props.defaultCostCenter}
                                                                />
                                                                {/* <Popover
                                                                id={idModal}
                                                                open={openModal}
                                                                className={"pop-over"}
                                                                anchorEl={this.state.setModal}
                                                                onClose={this.handleCloseModal}
                                                                anchorReference="anchorOrigin"
                                                                anchorOrigin={{
                                                                    horizontal: "right",
                                                                    vertical: "bottom "
                                                                }}
                                                                transformOrigin={{
                                                                    horizontal: "left",
                                                                    vertical: "top"
                                                                }}
                                                                PaperProps={{ style: { width: '20%' } }}
                                                                anchorPosition={{ top: 400, left: 300 }}
                                                            >
                                                                <YearsBudgetModal detailsToDisplay={this.state.detailsToDisplay} show_season_id={this.props.show_season_id} fetchPilotHeaderData={this.props.fetchPilotHeaderData} handleClose={this.handleCloseModal} type="Edit" notificationComponent={this.props?.notificationComponent} costCenterOptions={this.state.costCenterOptions} defaultCostCenter={this.state.defaultCostCenter} />
                                                            </Popover>
                                                         */}
                                                            </>}
                                                        </MDBCol>
                                                        <MDBCol md={1}>
                                                            {canEditBudgetDetails &&
                                                            <BasicButton
                                                                className="trashBtnContainer"
                                                                variant="contained"
                                                                text={this.props.isDelete ? <CircularProgress color="inherit" size={20} /> : "Delete"}
                                                                icon={"trash"}
                                                                onClick={this.props?.handleDeleteClick}
                                                            />}
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                            {this.state.isPostingTableContent ? (
                                                <div style={{ textAlign: "center" }}>
                                                    <CircularProgress size={24} />
                                                </div>
                                            ) : (<>
                                                <MDBRow>
                                                    <MDBRow className="cueSheetTable" id="table-bg-creative">
                                                        {/* {this.state.config.headings[2].selectOptions.length > 0 &&  */}
                                                        {/* {this.state.config.headings[3].selectOptions.length > 0 && */}
                                                        {this.state.errorFlag && <p className="errorMessage">{this.state.errorMsg}</p>}
                                                        <TableComponent
                                                            list={this.state.renderList || []}
                                                            config={this.state.config}
                                                            editCallback={canEditBudgetDetails ? this.handleInlineEdits.bind(this) : false}
                                                            saveCallback={canEditBudgetDetails ? this.saveInlineEdits.bind(this) : false}
                                                            handleAddNewItem={canEditBudgetDetails ? this.handleAddNewItem.bind(this) : false}
                                                            handleDelCallBack={canEditBudgetDetails ? ((id, item) =>
                                                                this.openDeleteModal(item)) : false}
                                                            readCallback={canEditBudgetDetails ? this.handleReadCallback.bind(this) : false}
                                                            //searchString={(value, nodeName) => this.getEntity(value, nodeName)}
                                                            postInitiated={this.state.isSubmit}
                                                            addItemToList={canEditBudgetDetails ? true : false}
                                                        />
                                                    </MDBRow>
                                                </MDBRow>

                                                <div className="subTotalWrapper">
                                                    <div className="labels">SubTotals</div>
                                                    <div className="estSyncFees">
                                                        <>{this.state.renderList.length > 0 ? <div className="lgText">
                                                            $ {est_synch_fees_total = this.state.renderList.reduce((accum, item) => accum + item.est_synch_fees, 0)}
                                                        </div> : <div className="lgText">$ 0</div>
                                                        }</>
                                                    </div>
                                                    <div className="est_master_fees">
                                                        <>{this.state.renderList.length > 0 ? <div className="lgText">
                                                            $ {est_master_fees_total = this.state.renderList.reduce((accum, item) => accum + item.est_master_fees, 0)}
                                                        </div> : <div className="lgText">$ 0</div>
                                                        }</>

                                                    </div>
                                                </div>
                                                <div className="subTotalWrapper">
                                                    {Number(this.props.rightsDiscount) > 0 && <>
                                                        <div className="left">
                                                            <div className="labels">Music license subtotal</div>
                                                            <div className="estSyncFees">
                                                                {this.state.renderList.length > 0 ? <p className="lgText">$ {est_master_fees_total + est_synch_fees_total}</p> : <p className="lgText">$ 0</p>}
                                                            </div>
                                                        </div>
                                                        <div className="right">
                                                            <div className="labels">Series Pattern</div>
                                                            <div className="estSyncFees">
                                                                {this.state.detailsToDisplay?.series_pattern ? <p className="lgText">{"$" + this.state.detailsToDisplay?.series_pattern}</p> : <p>None</p>}
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                                <div className="subTotalWrapper">
                                                    {Number(this.props.rightsDiscount) > 0 && <>
                                                        <div className="left">
                                                            <div className="labels">Music license bundling of rights discount</div>
                                                            <div className="estSyncFees">
                                                                {this.state.renderList.length > 0 ? <p className="lgText">$ {((est_master_fees_total + est_synch_fees_total) * (Number(this.props.rightsDiscount) / 100)).toFixed(2)}</p> : <p className="lgText">$ 0</p>}
                                                            </div>
                                                        </div>
                                                        <div className="right">
                                                            {console.log("this.state.detailsToDisplay?.series_pattern", this.state.detailsToDisplay?.series_pattern, typeof this.state.detailsToDisplay?.series_pattern)}
                                                            <div className="labels">Bundling of rights discount</div>
                                                            <div className="estSyncFees">
                                                                <p className="lgText">$ {(Number(this.state.detailsToDisplay?.series_pattern) * (this.props.rightsDiscount / 100)).toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                                <div className="subTotalWrapper">
                                                    {Number(this.props.rightsDiscount) > 0 && <>
                                                        <div className="left">
                                                            <div className="labels">Music license grand total</div>
                                                            <div className="estSyncFees">
                                                                {this.state.renderList.length > 0 ? <p className="lgText">$ {((est_master_fees_total + est_synch_fees_total) - ((est_master_fees_total + est_synch_fees_total) * (Number(this.props.rightsDiscount) / 100))).toFixed(2)}</p> : <p className="lgText">$ 0</p>}
                                                            </div>
                                                        </div>
                                                        <div className="right">
                                                            <div className="labels">Adjusted Pattern</div>
                                                            <div className="estSyncFees">
                                                                <p className="lgText">$ {(this.state.detailsToDisplay?.series_pattern ? this.state.detailsToDisplay?.series_pattern : 0) - ((this.state.detailsToDisplay?.series_pattern ? this.state.detailsToDisplay?.series_pattern : 0) * ((this.props?.rightsDiscount ? this.props.rightsDiscount : 0) / 100)).toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </>)}
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            }
                        </div>
                    )
                }
                {/* delete Confirmation modal */}
                {
                    this.state.showDeleteModal &&
                    <MessageModal
                        title={"Delete Budget Record"}
                        open={this.state.showDeleteModal}
                        message={"Are You Sure To Delete?"}
                        primaryButtonText={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : "Yes"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.deleteTableRow}
                        handleClose={this.closeDeleteModal} />
                }
            </>
        )
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.tabValue !== prevProps.tabValue || this.props.pilotCBDetailsHeader !== prevProps.pilotCBDetailsHeader) {
            if (this.props?.tabValue !== undefined) {
                this.fetchData("pilotCBDetails", `/showSeasonPilotClearanceBudgetDetail?show_season_id=${this.props?.show_season_id}&show_season_pilot_clearance_budget_id=${this.props?.tabValue}`);
            }
            let detailsToDisplay = this.props.pilotCBDetailsHeader.filter((item, index) => (
                item?.show_season_pilot_clearance_budget_id == this.props?.tabValue ? item : null
            ))
            this.getPermissions(this.props.permissionArr);
            this.setState({
                detailsToDisplay: detailsToDisplay?.length > 0 ? detailsToDisplay[0] : null, pdfPayload: {
                    "show_season_id": this.props?.show_season_id,
                    "show_season_pilot_clearance_budget_id": this.props?.tabValue,
                    "is_budget_only": "1",
                    "is_hide_series_pattern": 0,
                    "is_hide_marketting_cost": 0,
                    "is_hide_diclaimer": 0
                },
                excelPayload: {
                    "show_season_id": this.props?.show_season_id,
                    "show_season_pilot_clearance_budget_id": this.props?.tabValue,
                    "is_budget_only": "1",
                    "is_hide_series_pattern": 0,
                    "is_hide_marketting_cost": 0,
                    "is_hide_diclaimer": 0
                },
            })
        }
    }
    componentDidMount() {
        this.fetchData("costCenterOptions", `/entitySearch?entity=COST_CENTER&searchString=null`);
        this.getPermissions(this.props.permissionArr);
        if (this.props?.tabValue !== undefined) {
            this.fetchData("pilotCBDetails", `/showSeasonPilotClearanceBudgetDetail?show_season_id=${this.props?.show_season_id}&show_season_pilot_clearance_budget_id=${this.props?.tabValue}`);
        }
        let detailsToDisplay = this.props.pilotCBDetailsHeader.filter((item, index) => (
            item?.show_season_pilot_clearance_budget_id == this.props?.tabValue ? item : null
        ));
        this.setState({
            detailsToDisplay: detailsToDisplay?.length > 0 ? detailsToDisplay[0] : null
        });

        // this.fetchData("artistOptions", `/entitySearch?entity=PERFORMER&searchString=null`);
        // this.fetchData("songOptions", `/entitySearch?entity=SONGS&searchString=null`);
    }
}

export default withUserContext(YearsBudget);
