export const efcRecipientConfig = {
    headings: [
        {
            headingLabel: "Name",
            width: "38%",
            inputType: "text"
        },
        {
            headingLabel: "Email",
            width: "38%",
            inputType: "text"
        },
        {
            headingLabel: "Company",
            width: "20%",
            inputType: "text"
        },
        {
            headingLabel: "",
            width: "8%",
}

    ],
    dataNodes: ["name", "email","company"],
    primaryKey: "id",
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "name",
            width: "38%",

        },
        {
            dataNode: "email",
            width: "38%",
            validateMethod : 'validateEmail',
            validateMessage : 'Please enter valid Email'
        },
        {
            dataNode: "company",
            width: "20%"
        },
        {
            dataNode: "",
            width: "5%"
        }
    ],
}

export const efcRecipientList = [
    {

        id: 1,
        is_active: 0,
        efc_recipient_name: "[name]",
        email: "efc@email.com",
    },
    {
        id: 2,
        is_active: 0,
        efc_recipient_name: "[name]",
        email: "[email]",
    },
    {
        id: 3,
        is_active: 0,
        efc_recipient_name: "[name]",
        email: "[email]",
    },
    {
        id: 4,
        is_active: 1,
        efc_recipient_name: "[name]",
        email: "[email]",
    },
    {
        id: 5,
        is_active: 0,
        efc_recipient_name: "[name]",
        email: "[email]",
    },
    {
        id: 6,
        is_active: 1,
        efc_recipient_name: "[name]",
        email: "[email]",
    }
]