import { MDBCol, MDBIcon, MDBRow, MDBContainer } from 'mdbreact';
import React from 'react';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import "../ShowSeasonDetails/ShowSeasonDetails.scss";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';

export default class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainTitleDetails: this.props?.mainTitleDetails, showClearanceRecordModal: false
        }
    }
    handleshowClearanceRecordModal = () => {
        this.setState({ showClearanceRecordModal: true });
    }
    handleHideClearanceRecordModal = () => {
        this.setState({ showClearanceRecordModal: false });
    }
    render() {
        return (
            <MDBContainer fluid className="Main-Title-Container">
                <div>
                    <MDBRow style={{ marginTop: '10px' }}>
                        <MDBCol md={1} className="heder-text">Details</MDBCol>
                        <MDBCol md={1}><BasicButton text="Edit" variant="contained" onClick={this.handleshowClearanceRecordModal} /></MDBCol>

                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={1}>
                            <BasicLabel text={"Duration"} />
                            <p className='header-text'>{this.state.mainTitleDetails?.duration}</p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Use"} />
                            <p className='header-text'>{this.state.mainTitleDetails?.use}</p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Clearance Rep"} />
                            <p className='header-text'>{this.state.mainTitleDetails?.clearence_rep}</p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Main Title Credits"} />
                            <p className='header-text'>{this.state.mainTitleDetails?.Main_title_credits}</p>
                        </MDBCol>
                        <MDBCol md={6}></MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md={1}>
                            <BasicLabel text={"Guaranteed Eps"} />
                            <p className='header-text'>{this.state.mainTitleDetails?.guaranteed_eps}</p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <p className='header-text'>{this.state.mainTitleDetails?.apply}{" "}<span className='cancle-icon'><MDBIcon icon={"times-circle"} className="icon-space" /></span></p>
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Main Title Credits"} />
                            <p className='header-text'>{this.state.mainTitleDetails?.Main_title_credits}</p>
                        </MDBCol>
                        <MDBCol md={7}>
                            <BasicLabel text={"Notes"} />
                            <p className='header-text'>{this.state.mainTitleDetails?.notes}</p>
                        </MDBCol>
                    </MDBRow>
                    {this.state.showClearanceRecordModal &&
                        <MainViewModalComponent
                            open={this.state.showClearanceRecordModal}
                            handleClose={this.handleHideClearanceRecordModal}
                            headerText={"Edit Clearance Record Details"}
                            mode={""}
                            modalName={"Clearance Record"} />}
                </div>
            </MDBContainer>
        )
    }
}