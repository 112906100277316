import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import { withUserContext } from "../../../contexts/UserContext";
import BasicLabel from "../../SharedComponents/BasicLabel/BasicLabel";
import "./BudgetingSetupModal.scss";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import ClearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import "./BudgetingReportModal.scss";
class BudgetingReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      budgetingReportDetails: {
        is_ms: 0,
        is_sc: 0,
        is_select_all: 0,
        is_display_each_check: 0,
        show_season_id: this.props?.showSeasonId,
        show_season_episode_ids: [],
      },
      isGenerate: false,
      episodeList: [],
    };
  }
  componentDidMount = () => {
    this.getReportEpisodeList();
  };
  getReportEpisodeList = () => {
    this.setState({ isEpisodeLoading: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/showSeasonEpisodes?show_season_id=${this.props?.showSeasonId}`,
      this.props.userContext?.active_tenant?.tenant_id,
      this.props?.divisionId
    ).then(
      (response) => {
        let formatData = response.data?.map((item) => ({
          show_season_episode_id: item?.show_season_episode_id,
          episode_number: item?.episode_number,
          episode_title: item?.episode_title,
          is_checked: 0,
        }));
        this.setState({
          isEpisodeLoading: false,
          episodeList: formatData,
        });
      },
      (err) => {
        console.log("Error in fetching Details:", err);
      }
    );
  };
  onChangeCallback = (field, dataItem, val) => {
    let updated_episode_list = this.state.episodeList?.map((item) => {
      let newItem = { ...item };
      if (item?.show_season_episode_id === dataItem?.show_season_episode_id) {
        newItem.is_checked = val;
      }
      return newItem;
    });
    let updatedEpisodeData = { ...this.state.episodeList };
    updatedEpisodeData = updated_episode_list;
    this.setState({
      episodeList: updatedEpisodeData,
    });
  };
  handleChange = (field, value) => {
    this.setState({
      budgetingReportDetails: {
        ...this.state.budgetingReportDetails,
        [field]: value,
      },
    });
  };
  generateReport = () => {
    this.setState({
      isGenerate: true,
      budgetingReportDetails: {
        ...this.state.budgetingReportDetails,
        show_season_episode_ids: [],
      },
    });
    let reportName = "rptshowseasonscmsbudget";
    let searchPayload = {};
    this.state.episodeList?.filter((data) => {
      if (data?.is_checked === 1) {
        this.state.budgetingReportDetails?.show_season_episode_ids.push(
          data.show_season_episode_id
        );
      }
    });
    searchPayload = this.state.budgetingReportDetails;
    this.downloadReport(reportName, searchPayload)
      .then((webSocketConn) => {
        webSocketConn.onmessage = (e) => {
          let response = JSON.parse(e.data);
          this.setState({ isGenerate: false });
          if (response?.statusCode == 200) {
            this.setState({ isGenerate: false });
            this.downloadUrl(response?.body, "Show Season SCMS Budget");
            this.props?.notificationComponent(true, "success");
          } else {
            //this.notificationComponent(true, 'success')
          }
        };
      })
      .catch((err) => {
        this.setState({ isGenerate: false });
        this.props?.notificationComponent(true, "fail");
      });
  };
  downloadReport = (reportName, searchPayload) => {
    if (!searchPayload || !reportName) {
      return;
    }
    Object.keys(searchPayload).map((obj) => {
      if (searchPayload[obj] === undefined) {
        searchPayload[obj] = null;
      }
    });
    let encryptTedStr = btoa(JSON.stringify(searchPayload));
    let load = {};

    load = {
      payload: "exportPdf",
      reportName: reportName,
      fileName: "Show Season SCMS Budget",
      searchJson: encryptTedStr,
      showHtml: 0,
      encryptionType: 1,
      tenantId: this.props?.userContext?.active_tenant?.tenant_id,
      divisionId: this.props?.divisionId,
    };
    return ClearTrackService.getDataWS(load);
  };
  downloadUrl = (url, reportName) => {
    if (url) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }
  };
  handleSelectAll = (field, val) => {
    let updated_episode_list = this.state.episodeList?.map((item) => {
      let newItem = { ...item };
      newItem.is_checked = val;
      return newItem;
    });
    let updatedEpisodeData = { ...this.state.episodeList };
    updatedEpisodeData = updated_episode_list;
    this.setState({
      episodeList: updatedEpisodeData,
    });
    this.handleChange("is_select_all", val);
  };
  render() {
    return (
      <MDBContainer fluid className="BudgetingReportContainer">
        <MDBRow>
          <MDBCol md={4}>
            <BasicCheckbox
              id="musicSupervisor1"
              label="Music Supervisors"
              checked={this.state.budgetingReportDetails?.is_ms || 0}
              onChange={(e) =>
                this.handleChange("is_ms", e.target.checked ? 1 : 0)
              }
            />
          </MDBCol>
          <MDBCol md={4}>
            <BasicCheckbox
              id="showComposers1"
              label="Show Composers"
              checked={this.state.budgetingReportDetails?.is_sc || 0}
              onChange={(e) =>
                this.handleChange("is_sc", e.target.checked ? 1 : 0)
              }
            />
          </MDBCol>
          <MDBCol md={4}>
            <BasicCheckbox
              id={`option-episode`}
              checked={
                this.state.budgetingReportDetails?.is_display_each_check || 0
              }
              label={`Display Each Check`}
              onChange={(e) =>
                this.handleChange(
                  "is_display_each_check",
                  e.target.checked ? 1 : 0
                )
              }
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={4}>
            <BasicCheckbox
              id="selectAll"
              label="Select/De-Select All"
              checked={this.state.budgetingReportDetails?.is_select_all || 0}
              onChange={(e) =>
                this.handleSelectAll("is_select_all", e.target.checked ? 1 : 0)
              }
            />
          </MDBCol>
          <MDBCol md={8}>
            <BasicLabel text="Episodes" />
            <div className="report-episode-list">
              {this.state.episodeList?.map((item) => (
                <BasicCheckbox
                  id={`${item?.show_season_episode_id}-episode`}
                  checked={item?.is_checked || 0}
                  label={`${item?.episode_number}-${item?.episode_title}`}
                  onChange={(e) =>
                    this.onChangeCallback(
                      "is_checked",
                      item,
                      e.target.checked ? 1 : 0
                    )
                  }
                />
              ))}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md={6}></MDBCol>
          <MDBCol md={3}>
            <BasicButton
              text={
                this.state.isGenerate ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  "Generate"
                )
              }
              onClick={this.generateReport}
            />
          </MDBCol>
          <MDBCol md={3}>
            <BasicButton
              variant="outlined"
              text={"Cancel"}
              onClick={this.props?.handleClose}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
export default withUserContext(BudgetingReportModal);
