// export const ClearTrackServiceBaseUrl  = 'https://d8xu2tu49b.execute-api.us-west-2.amazonaws.com/Dev';
// export const clearTrackServiceWSBaseUrl = 'wss://cx7658vg91.execute-api.us-west-2.amazonaws.com/dev';  
export const ClearTrackServiceBaseUrl  = 'https://wzd2jemo1f.execute-api.us-west-2.amazonaws.com/dev';
export const clearTrackServiceWSBaseUrl = 'wss://0i8oazfzy7.execute-api.us-west-2.amazonaws.com/dev';  
export const clearTrackSharedServiceBaseUrl = '';
export const OKTA_CLIENT_ID = "0oa17y8nxkdhaOKuz0h8";
export const OKTA_ISSUER_URL = "https://wmbiz.oktapreview.com/oauth2/default";
export const OKTA_REDIRECT_URL = window.location.origin + '/callback';
export const OKTA_URL = "https://wmbiz.oktapreview.com";
export const IDLE_TIMEOUT = "60";
export const IDLE_WARN_TIMEOUT = "30";