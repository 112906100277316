import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialcableDistributionProjections,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";

class CableDistributionProjections extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      cableDistributionProjections: initialcableDistributionProjections,
      seasonIdValues: [],
      showIdValues: [],
      seasonOptions: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
    };
  }
  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }
  handlesubmitcableDistributionPojections= () => {
    this.setState({ postInitiated: true, isPosting: true });
    let { cableDistributionProjections, filterValues, seasonIdValues, showIdValues } = this.state;
    let payload = {
      is_inactive_shows: cableDistributionProjections.is_inactive_shows,
      is_with_details: cableDistributionProjections.is_with_details,
      is_no_notes: cableDistributionProjections.is_no_notes,
      season_ids: seasonIdValues,
      show_ids: showIdValues
    }
    this.props.generateReport(this.props?.reportName, payload).then(response => {
      if (response.data.error) {
        // this.setState({ isPosting: false })
        // this.props.fieldChanged(true, "reportNotFound");
      }
      else {
        let url = response.data;
        let obj = {
          reportName: this.props?.reportDownloadName,
          production: "",
          season: "",
          episode: "",
          performer: "",
        }
        // this.setState({ isPosting: false })
        let reportNameFinal = genReportName(obj)
        this.props?.downloadUrl(url, reportNameFinal)
      }
    }, (err) => {
      this.setState({ isPosting: false })
      console.log(err)
      // this.props.fieldChanged(true, "reportFail");
    })
  }


  //  handleSubmit = () => {
  //  console.log("submit", this.state.cableDistributionProjections);
  //};

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      cableDistributionProjections: {
        ...prevState.cableDistributionProjections,
        [field]: value,
      },
    }));
  };
  setSeasonId = (seasonIdValues) => {
    let seasonIdValue = this.state.seasonIdValues;
    seasonIdValue = seasonIdValues;
    this.setState({
      seasonIdValues: seasonIdValue
    })
  }

  setShowId = (showIdValues) => {
    let showIdValue = this.state.showIdValues;
    showIdValue = showIdValues;
    this.setState({
      showIdValues: showIdValue
    })
  }


  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode });
  }


  render() {
    return (
      <>
        {" "}
        <p>
          <b>Cable Distribution - Summary Projections</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
               
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
            <MDBCol md={6}>
            <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                setSeasonId={this.setSeasonId}
                setShowId={this.setShowId} />
              {/*<BasicLabel text="Seasons" />
              <SearchSelectField
                placeHolderText={"All Genres"}
                value={this.state.cableDistributionProjections.selected_season}
                options={this.state.seasonOption || []}
                onChange={(e) => this.handleChange("season_id", e.target.value)}
              />
            </MDBCol>
            <MDBCol md={3}>
              <BasicLabel text="Shows" />
              <SearchSelectField
                placeHolderText={"All Shows in selected season(s)"}
                value={this.state.cableDistributionProjections.selected_shows}
                options={this.state.showOptions || []}
                onChange={(e) => this.handleChange("show_id", e.target.value)}
                  />*/}
                  </MDBCol>
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Subtitle"
                label={"Include Inactive Shows"}
                checked={
                  this.state.cableDistributionProjections
                    ?.is_include_inactive_shows || false
                }
                onChange={(e) =>
                  this.handleChange(
                    "is_include_inactive_shows",
                    +e.target.checked
                  )
                }
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBContainer>
        <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={"Generate PDF"} onClick={this.handlesubmitcableDistributionPojections} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} />
              </MDBCol>
            </MDBRow>
        
</>
    );
  }
}

export default CableDistributionProjections;
