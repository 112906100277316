import React from 'react';
import { withUserContext } from '../../../contexts/UserContext';
import TableComponent from '../../SharedComponents/Table';
import { handlePaginationList } from '../../../Common/TableHelper';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import { MDBCol, MDBRow } from 'mdbreact';
import {validateDuration, validateNumbersOnly} from '../../../Common/Helper'
import { payeeDetails } from '../EditPayee/config';
export default withUserContext(class CuesheetTableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: JSON.parse(JSON.stringify(this.props?.renderList || [])),
            composerName: {},
            publisherName: {},
            compositionName: {},
            isErrorFlag: false,
            mode: null,
            nodeName: null,
            cueSheetItemIndex: null,
            showComposerModal: false,
            showPublisherModal: false,
            isDurationValid: false
        }
    };


    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevProps.renderList) !== JSON.stringify(this.props.renderList)) {
            this.setState({
                renderList: JSON.parse(JSON.stringify(this.props?.renderList || [])),
            });
        }
    }



    handleAddNewItem = (newDataItem) => {
        let payload = { ...this.props?.formatCueSheetDetails(newDataItem) };
        this.setState({ isErrorFlag: false, isDurationValid: false }, () => {
            if(payload?.cue_no && (payload?.min ? validateNumbersOnly(payload?.min) : true)
             && (payload?.sec ?  validateNumbersOnly(payload?.sec)  && payload.sec < 60 :true)) {
                    this.props?.postCueSheetDetails(payload, 'newItemPost')
                    this.setState({
                    composerName: {},
                    publisherName: {},
                    compositionName: {}
                    })
            } else {
                this.setState({ isErrorFlag: true })
            }
        })
    }

    handleInlineEdits = (id, dataItem) => {
        let cueSheetDetails = [...this.state.renderList]
        this.setState({
            renderList: cueSheetDetails.map((item) => {
                if (item.cue_sheet_record_id == id) {
                    item['editing'] = true
                } else {
                    item.editing = false
                }
                return item
            })
        })
    }

    saveInlineEdits = (list, index, id, dataItem) => {
        let cueSheetDetails = [...list];
        let payload = { ...this.props?.formatCueSheetDetails(cueSheetDetails[index]) };
        this.setState({ isErrorFlag: false })
        if (cueSheetDetails[index]?.cue_no && (cueSheetDetails[index]?.min ? validateNumbersOnly(cueSheetDetails[index]?.min) : true) 
        && (cueSheetDetails[index]?.sec ?  validateNumbersOnly(cueSheetDetails[index]?.sec)  && cueSheetDetails[index].sec < 60 :true)){
            this.props?.postCueSheetDetails(payload, cueSheetDetails[index]?.cue_sheet_record_id)
        } else {
            this.setState({ isErrorFlag: true })
        }

    }

    handleInlineDelete = (id) => {
        let cueSheetDetails = [...this.state?.renderList];
        let deletedItem = cueSheetDetails?.find(item => item?.cue_sheet_record_id === id);
        let payload = this.props?.formatCueSheetDetails(deletedItem, "delete");
        this.props?.postCueSheetDetails(payload, payload.cue_sheet_record_id);
        this.setState({ renderList: cueSheetDetails })
    }

    handleInlineCopy = (id) => {
        let cueSheetDetails = [...this.state?.renderList];
        let copiedItem = cueSheetDetails?.find(item => item?.cue_sheet_record_id === id);
        let payload = { ...this.props?.formatCueSheetDetails(copiedItem, "copy") };
        this.props?.postCueSheetDetails(payload, id);
    }

    handleReadCallback = (index) => {
        let cueSheetDetails = [...this.state.renderList];
        cueSheetDetails[index].editing = false;
        this.setState({ renderList: cueSheetDetails, isErrorFlag: false })
    }

    handleInlineIconClick = (nodeName, index) => {
        this.setState({
            mode: !index ? "Add" : "Edit",
            nodeName: nodeName,
            cueSheetItemIndex: index
        })
        if (nodeName === "composer") {
            this.setState({ showComposerModal: true })
        } else {
            this.setState({ showPublisherModal: true })
        }
    }


    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }

    handleSelectCheck = (value) => {
        let list = [...this.state.renderList]
        this.setState({
            renderList: list?.map(item => {
                let newItem = { ...item }
                newItem.check = value
                return newItem
            })
        })
    }

    handleCopyDelete = (type) => {
        let payload = { ...this.props?.formatCueSheetDetails(null, null) };
        if (type === 'copy') {
            payload.is_multiple_copy = 1
        } else {
            payload.is_multiple_delete = 1
        }
        payload.multiple_ids = ''
        for (let i = 0; i < this.state.renderList?.length; i++) {
            if (this.state.renderList[i]?.check) {
                payload.multiple_ids = payload.multiple_ids + this.state.renderList[i]?.cue_sheet_record_id + ','
            }
        }
        payload.multiple_ids = payload.multiple_ids.slice(0, -1);
        this.props?.postCueSheetDetails(payload, 'newItemPost');
    }

    render() {
        return (
            <>
                {this.state.renderList?.length > 0 && this.props?.addItemToList  && <MDBRow className='mt-2'>
                    <MDBCol md={2}>
                        <span  className="label-with-cursor" onClick={() => { this.handleSelectCheck(true) }}>Select All</span>
                    </MDBCol>
                    <MDBCol md={2}>
                        <span  className="label-with-cursor" onClick={() => { this.handleSelectCheck(false) }}>Deselect All</span>
                    </MDBCol>
                    {this.state.renderList?.some(item => item?.check) &&<MDBCol md={2}>
                        <span className="label-with-cursor" onClick={() => { this.handleCopyDelete('copy') }}>Copy Selected Items</span>
                    </MDBCol>}
                    {this.state.renderList?.some(item => item?.check) &&<MDBCol md={2}>
                        <span className="label-with-cursor-red"onClick={() => { this.handleCopyDelete('delete') }}>Delete Selected Items</span>
                    </MDBCol>}
                </MDBRow>}
                <div className="cueSheet row" id='mainGrid'>
                    {<p className="f-color">{this.state.isErrorFlag ? `Please enter Cue no` : null}</p>}
                    {this.props?.config?.headings[6]?.selectOptions?.length > 0  &&
                        <TableComponent
                            list={this.state.renderList || []}
                            className={"clearencRecord"}
                            stickyHeader={true}
                            config={this.props?.config}
                            handleAddNewItem={this.handleAddNewItem.bind(this)}
                            editCallback={this.handleInlineEdits.bind(this)}
                            saveCallback={this.saveInlineEdits.bind(this)}
                            handleDelCallBack={this.handleInlineDelete.bind(this)}
                            handleInlineCopy={this.handleInlineCopy.bind(this)}
                            readCallback={this.handleReadCallback.bind(this)}
                            inlineIconClick={this.handleInlineIconClick.bind(this)}
                            arrayCheckUncheck={(value, dataItem) => {
                                let list = [...this.state.renderList]
                                this.setState({
                                    renderList: list?.map(item => {
                                        let newItem = { ...item }
                                        if (newItem?.cue_sheet_record_id === dataItem?.cue_sheet_record_id) {
                                            newItem.check = value
                                        }
                                        return newItem
                                    })
                                })
                            }}
                            addItemToList={this.props?.addItemToList}
                            showClear={true}
                            postInitiated={this.props.isSubmit}
                            updatedItemNodeValue={this.state.nodeName === "composition" ? this.state.compositionName : this.state.nodeName === "composer" && this.state.publisherName?.value?.trim()?.length !== 0 ?
                                [this.state.composerName, this.state.publisherName] : this.state.publisherName
                            }
                            defaultValuePopulation={this.props?.currentUser || null}
                            validateNewItem={(newDataItem) => {
                                let payload = this.props?.formatCueSheetDetails(newDataItem);
                                // if (payload.cue_no != null && payload.min != null && payload.sec !==null && payload.sec < 60)
                               if (payload?.cue_no && (payload?.min ? validateNumbersOnly(payload?.min) : true)
                                   && (payload?.sec ?  validateNumbersOnly(payload?.sec)  && payload.sec < 60 :true)) {
                                    this.setState({ isErrorFlag: false })
                                    return true
                                    
                                } else {
                                    this.setState({ isErrorFlag: true })
                                    return false
                                }
                            }}
                        />
                    }
                    {this.state.showComposerModal &&
                        <MainViewModalComponent
                            open={this.state.showComposerModal}
                            handleClose={() => this.setState({ showComposerModal: false })}
                            headerText={"Composer"}
                            mode={this.state.mode}
                            modalName={"Add Composer"}
                            handleNodeValue={(value) => this.setState({
                                composerName: {
                                    value: value,
                                    node: this.state.nodeName,
                                    index: this.state.cueSheetItemIndex
                                }
                            })}
                        />
                    }
                    {this.state.showPublisherModal &&
                        <MainViewModalComponent
                            open={this.state.showPublisherModal}
                            handleClose={() => this.setState({ showPublisherModal: false })}
                            headerText={"Publisher"}
                            mode={this.state.mode}
                            modalName={"Add Publisher"}
                            handleNodeValue={(value) => this.setState({
                                publisherName: {
                                    value: value,
                                    node: this.state.nodeName,
                                    index: this.state.cueSheetItemIndex
                                }
                            })}
                        />
                    }
                </div>
            </>
        );
    }
});