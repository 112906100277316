import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import Chip from "@material-ui/core/Chip";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialCableDistributionPrep,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import ShowSeason from "./ShowSeason";
class CableDistributionPerp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      cableDistributionPerp: initialCableDistributionPrep,
      seasonIdValues: [],
      showIdValues: [],
      seasonOptions: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
    };
  }
  
  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }
  handlesubmitcableDistributionPerp = () => {
    this.setState({ postInitiated: true, isPosting: true });
    let { cableDistributionPerp, filterValues, seasonIdValues, showIdValues } = this.state;
    let payload = {
      is_inactive_shows: cableDistributionPerp.is_inactive_shows,
      is_with_details: cableDistributionPerp.is_with_details,
      is_no_notes: cableDistributionPerp.is_no_notes,
      season_ids: seasonIdValues,
      show_ids: showIdValues
    }
    this.props.generateReport(this.props?.reportName, payload).then(response => {
      if (response.data.error) {
        // this.setState({ isPosting: false })
        // this.props.fieldChanged(true, "reportNotFound");
      }
      else {
        let url = response.data;
        let obj = {
          reportName: this.props?.reportDownloadName,
          production: "",
          season: "",
          episode: "",
          performer: "",
        }
        // this.setState({ isPosting: false })
        let reportNameFinal = genReportName(obj)
        this.props?.downloadUrl(url, reportNameFinal)
      }
    }, (err) => {
      this.setState({ isPosting: false })
      console.log(err)
      // this.props.fieldChanged(true, "reportFail");
    })
  }

  // handleSubmit = () => {
  //   console.log("submit", this.state.maixDataDetails);
  // };

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      cableDistributionPerp: {
        ...prevState.cableDistributionPerp,
        [field]: value,
      },
    }));
  };

  setSeasonId = (seasonIdValues) => {
    let seasonIdValue = this.state.seasonIdValues;
    seasonIdValue = seasonIdValues;
    this.setState({
      seasonIdValues: seasonIdValue
    })
  }

  setShowId = (showIdValues) => {
    let showIdValue = this.state.showIdValues;
    showIdValue = showIdValues;
    this.setState({
      showIdValues: showIdValue
    })
  }


  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode });
  }
  
  render() {
    return (
      <>
        {" "}
        <p>
          <b>Cable Distribution - Extend Initial Into Perp</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
               
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
            <MDBCol md={6}>
            <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                setSeasonId={this.setSeasonId}
                setShowId={this.setShowId} />
             {/* <BasicLabel text="Seasons" />
              <SearchSelectField
                placeHolderText={"All Genres"}
                value={this.state.cableDistributionPerp.selected_season}
                options={this.state.seasonOption || []}
                onChange={(e) => this.handleChange("season_id", e.target.value)}
              />
            </MDBCol>
            <MDBCol md={3}>
              <BasicLabel text="Shows" />
              <SearchSelectField
                placeHolderText={"All Shows in selected season(s)"}
                value={this.state.cableDistributionPerp.selected_shows}
                options={this.state.showOptions || []}
                onChange={(e) => this.handleChange("show_id", e.target.value)}
                  />*/}
            </MDBCol>
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Include Inactive Shows"
                label={"Include Inactive Shows"}
                checked={
                  this.state.cableDistributionPerp?.is_inactive_shows || false
                }
                onChange={(e) =>
                  this.handleChange("is_inactive_shows", e.target.checked)
                }
              />
            </MDBCol>
            <MDBCol md={1}>
              <br />
              <BasicCheckbox
                id="With Details"
                label={"With Details"}
                checked={
                  this.state.cableDistributionPerp?.is_with_details || false}
                onChange={(e) =>
                  this.handleChange("is_with_details", e.target.checked)
                }
              />
            </MDBCol>
            <MDBCol md={1}>
              <br />
              <BasicCheckbox
                id="No Notes"
                label={"No Notes"}
                checked={this.state.cableDistributionPerp?.is_no_notes || false}
                onChange={(e) =>
                  this.handleChange("is_no_notes", e.target.checked)
                }
              />
              

            </MDBCol>
          </MDBRow>
          <br />
        </MDBContainer>
        
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={"Generate PDF"} onClick={this.handlesubmitcableDistributionPerp} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
};

export default CableDistributionPerp;
