import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import TabsComponent from "../SharedComponents/Tabs/Tabs";
import { letterSetupTabList } from "./config";
import LetterRequests from "./LetterRequests";
import QuoteRequests from "./QuoteRequest";
import CheckTransmittals from "./CheckTransmittals";
import AdCardLanguage from "./AdCardLanguage";
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent';
import "./LetterSetup.scss";

class LetterSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      showError: false,
      showSuccess: false,
      showFail: false,
      letterSetupPermissions:[]
    };
  }

  componentDidMount(){
    document.title = 'Letter Set up'
    let letterSetupPermissionsRecord = this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_group_name === "Letter Setup")
    this.setState({ letterSetupPermissions: letterSetupPermissionsRecord})
  }

  handleTabChange = (newTabValue) => {
    this.setState({
      tabValue: newTabValue,
    });
  };
  getTabComponent = () => {
    let letterSetupPermissionsRecord = this.props?.userContext?.active_tenant?.common_permissions?.filter(item => item.permission_group_name === "Letter Setup")
    let editValue = letterSetupPermissionsRecord.length > 0 ? letterSetupPermissionsRecord[0].edit === 1 : false
    switch (this.state.tabValue) {
      case 1:
        return <LetterRequests notificationComponent={this.notificationComponent.bind(this)} description="License Request" editRecord={editValue}/>;
        break;
      case 2:
        return <QuoteRequests notificationComponent={this.notificationComponent.bind(this)} description={"Quote Request"}  editRecord={editValue}/>;
        break;
      case 3:
        return <CheckTransmittals notificationComponent={this.notificationComponent.bind(this)}  editRecord={editValue}/>;
        break;
      case 4:
        return <AdCardLanguage notificationComponent={this.notificationComponent.bind(this)}  editRecord={editValue}/>;
        break;
    }
  };
  notificationComponent = (value, mode = "fail") => {
    switch (mode) {
      case "success": return this.setState({ showSuccess: value });
      case "fail": return this.setState({ showFail: value });
    }
  }
  closPopup = () => {
    this.setState({ showFail: false, showSuccess: false });
  }
  handleResetNotify = () => {
    this.setState({ showFail: false, showSuccess: false });
    if (this.state.saveFlag) {
      this.closPopup();
    }
  }
  render() {
    let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
    let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";
    return (
      <div className="letterSetup-Container">
        <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
          handleResetNotify={this.handleResetNotify.bind(this)} />
        <MDBRow>
          <h5><b>Letter Language</b></h5>
        </MDBRow>
        <MDBRow className="tab-content">
          <MDBCol md={12}>
            <TabsComponent
              tabList={letterSetupTabList}
              value={this.state.tabValue}
              onChange={(e, v) => this.handleTabChange(v)}
            />
          </MDBCol>
        </MDBRow>
        {this.getTabComponent()}
      </div>
    );
  }
}

export default LetterSetup;
