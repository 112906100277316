import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { showSeasonsConfig, seasonsList } from './config';
import TableComponent from '../../SharedComponents/Table';
import TablePagination from '@material-ui/core/TablePagination';
import './MusicEditorDetails.scss';

export default class ShowSeasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headings: JSON.parse(JSON.stringify({...showSeasonsConfig})),
            data: seasonsList,
            isLoading: false,
            rowsPerPage: 10,
            page: 0,
            isSortByAsc: true,
            sortCount: 0
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage, renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.renderList) });
    }
    render() {
        return (
            <>
                {this.state.isFetchingEpisodeAndClearenceDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="showSeasonTable" id="table-bg">
                            <TableComponent
                                list={this.state.data || []}
                                className={"showSeasonTable"}
                                config={this.state.headings}
                                hyperLinkNavigationCallback={this.navigationCallback}
                                //episodeCallback={this.handleChangePage}
                            />
                            <div className="Pagination" >
                                <TablePagination
                                    component="div"
                                    count={this.state.agentList ? this.state.agentList?.length : 0}
                                    rowsPerPageOptions={[]}
                                    page={this.state.page}
                                    onChangePage={this.handleChangePage}
                                    rowsPerPage={this.state.rowsPerPage}
                                />
                            </div>
                        </div>

                    )
                }
            </>
        )
    }
}