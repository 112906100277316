const configObjectAllUsers = {
    headings: [{
        headingLabel: "Name",
        width: "15%",
        icon: ""
    },
    {
        headingLabel: "Display Name",
        width: "10%",
        icon: ""
    },
    {
        headingLabel: "Tenant",
        width: "8%",
        icon: ""
    },
    {
        headingLabel: "Division",
        width: "8%",
        icon: ""
    },
    {
        headingLabel: "Role",
        width: "10%",
        icon: ""
    },
    // {
    //     headingLabel: "Level",
    //     width: "8%",
    //     icon: ""
    // },
    {
        headingLabel: "Email",
        width: "18%",
        icon: ""
    },
    {
        headingLabel: "Phone",
        width: "8%",
        icon: ""
    },
    {
        headingLabel: "Status",
        width: "5%",
        icon: ""
    }],
    dataNodes: ["name", "displayName", "tenant", "division", "role", "email", "phone", "status"],
    primaryKey: "id",
    hyperlinks: ["name"],
    changeColourToRed: ["Inactive"],
    changeColourToGreen: ["Active"]
};

const allUsersList = [
    {
        id: 1,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "TV",
        role: "COA",
        level: "Regular User",
        email: "UserAlias@warnerbros.com",
        phone: "111-222-3333",
        status: "Active"
    },
    {
        id: 2,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "Animation",
        role: "Corporate Legal",
        level: "Regular User",
        email: "UserAlias@warnerbros.com",
        phone: "222-333-4444",
        status: "Active"
    },
    {
        id: 3,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "Cue Sheets",
        level: "Admin",
        email: "UserAlias@warnerbros.com",
        phone: "555-444-3333",
        status: "Active"
    },
    {
        id: 4,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "Music Clearance",
        level: "Manager",
        email: "UserAlias@warnerbros.com",
        phone: "+13216540987",
        status: "Inactive"
    },
    {
        id: 5,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "[Role]",
        level: "[Level]",
        email: "UserAlias@warnerbros.com",
        phone: "000-000-0000",
        status: "Active"
    },
    {
        id: 6,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "[Role]",
        level: "[Level]",
        email: "UserAlias@warnerbros.com",
        phone: "999-999-9999",
        status: "Inactive"
    },
    {
        id: 7,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "[Role]",
        level: "[Level]",
        email: "[Email]",
        phone: "[Phone Number]",
        status: "[Status]"
    },
    {
        id: 8,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "[Role]",
        level: "[Level]",
        email: "[Email]",
        phone: "[Phone Number]",
        status: "[Status]"
    },
    {
        id: 9,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "[Role]",
        level: "[Level]",
        email: "[Email]",
        phone: "[Phone Number]",
        status: "[Status]"
    },
    {
        id: 10,
        name: "[First Name][Last Name]",
        displayName: "[Display Name]",
        tenant: "WBTV",
        division: "[Division]",
        role: "[Role]",
        level: "[Level]",
        email: "[Email]",
        phone: "[Phone Number]",
        status: "[Status]"
    }
]

const configObjectAllUsersModal = {
    dataNodes: ["usersname"],
    primaryKey: "id",
    actions: ["view","trash-alt"],
};

const selectOptions = [
    {
        label: "Active",
        value: 1
    },
    {
        label: "Inactive",
        value: 2
    },
]

const userFilters = {
    firstName: null,
    lastName: null,
    displayName: null,
    title: null,
    company: null,
    supervisor_id: null,
    assistant: null,
    status: null,
    shows: null,
    division: null,
    role: null,
    user_level: null
}

const filterUsersConfig = {
    headings: [{
        headingLabel: "Name"
    },
    {
        headingLabel: "Display Name"
    },
    {
        headingLabel: "Title"
    },
    {
        headingLabel: "Company"
    },
    {
        headingLabel: "Division"
    },
    {
        headingLabel: "Role"
    },
    {
        headingLabel: "Level"
    },
    {
        headingLabel: "Supervisor"
    },
    {
        headingLabel: "Assistant"
    },
    {
        headingLabel: "Show"
    },
    {
        headingLabel: "All Shows"
    },
    {
        headingLabel: "Status"
    }],
    dataNodes: ["name", "displayName", "title" ,"company" ,"division" , "role" , "level" , "supervisor", "assistant", "show", "all_shows", "status"],
    primaryKey: "id"
};

export { configObjectAllUsers, allUsersList, configObjectAllUsersModal, selectOptions, userFilters, filterUsersConfig }