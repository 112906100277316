import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../SharedComponents/SelectField/SelectField";
import RichTextEditor from "../SharedComponents/RichTextEditor/RichTextEditor";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import clearTrackService from '../../services/service';
import * as Constants from '../../constants/constants';
import { withUserContext } from '../../contexts/UserContext';
import { postPayload } from "./config";
import axios from 'axios';
import alphabetVariantsJson from '../SharedComponents/RichTextEditor/AlphabetVariant.json';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./LetterSetup.scss";
let postPayloadData = JSON.parse(JSON.stringify(postPayload))
class QuoteExtension extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      productionEntityOptions: [],
      letterRequestData: {},
      isPosting: false,
      titleOptions: [],
      isLoading: false,
      searchCancelToken: null,
      isShowFetching: null,
      selectedShowForSearch: null,
      selectedSeason: null,
      filterList: {
        showList: [],
        showBasedSeasonList: []
      },
      showSeasonId: null,
      letterTypeId: null,
      alphabetVariantMessage: false,
      alphaVariants: [],
      richTextEditor: null,
      indexToModify: null,
      editorText: null,
      characterToModify: null,
      letterLanguageId: null,
      isFetchingLetterLoading: false,
      isFetchingSeason: false,
      isTextError: false,
      division_id: null
      // idTitle: []
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.setState((prevState) => ({
      letterRequestData: {
        ...prevState.letterRequestData,
        ["is_default_user_defined"]: 1
      }
    }));
    if (this.props.isLetterFlag) {
      this.fetchData("letterTitle", `/userDefinedLanguage?letter_title=License%20Request`);
    } else if (this.props.isCheck) {
      this.fetchData("letterTitle", `/userDefinedLanguage?letter_title=Check%20Transmittal`);
    } else {
      this.fetchData("letterTitle", `/userDefinedLanguage?letter_title=Quote%20Request`);
    }
    this.fetchData("letterType", `/entitySearch?entity=LETTER_TYPE&searchString=null`);
    this.getShowDetails("SHOW", { id: 'show_id', name: 'show_name', division_id: 'division_id' }, "showList", "");
  }


  getLetterSetupData = () => {
    this.setState({isFetchingLetterLoading : true})
    let { letterTypeId, showSeasonId, letterRequestData } = this.state;
    let data = {
      letter_type_id: letterTypeId, production_entity_id: null,
      show_season_id: showSeasonId,
      text_type : "QuoteExtension"

    }
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/letterLanguage?input=${btoa(JSON.stringify(data))}`, this.props.userContext?.active_tenant?.tenant_id, this.state.division_id, true)
      .then(response => {
        this.setState({ isLoading: false });
        if (response.data.length > 0) {
          let formattedId = response.data[0].letter_language_id;
          let richTextEditorValue = response.data[0].description;
          let letterRequestDataRecord = { ... this.state.letterRequestData }
          this.setState({ letterLanguageId: formattedId, richTextEditor: richTextEditorValue, letterRequestData: letterRequestDataRecord })
        }
        else {
          let letterRequestDataRecord = { ... this.state.letterRequestData }
          this.setState({ letterLanguageId: null, richTextEditor: " ", letterRequestData: letterRequestDataRecord })
        }
        this.setState({isFetchingLetterLoading: false})
      },
        (err) => {
          this.setState({ isLoading: false })
          console.log("Error in fetching details:", err)
        })
  }
  fetchData = (field, url) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        this.setState({ isLoading: false });
        let formattedList = [];
        let letterTypeValue = null;
        switch (field) {
          case 'letterType':
            if (response?.data?.length > 0) {
              letterTypeValue = response.data?.find(item => item.description === this.props.description)?.letter_type_id;
            }
            this.setState({ letterTypeId: letterTypeValue },()=>{
              this.getLetterSetupData()
            })
            break;
          case 'letterTitle':
            if (response?.data?.length > 0) {
              formattedList = response.data?.map(item => ({ value: item.letter_language_title_id, text: item.text_type, description: item.description, titleOptions: item.letter_language_title_id}));
            }
            this.setState({ titleOptions: formattedList })
            break;

        }

      },
        (err) => {
          this.setState({ isLoading: false })
          console.log("Error in fetching details:", err)
        })
  }

  handleChange = (field, value) => {
    switch (this.props.description) {
      case this.props.description:
        this.setState((prevState) => ({
          letterRequestData: {
            ...prevState.letterRequestData,
            [field]: value,
          },
        }), () => {
          if(this.state.letterRequestData?.selectedSeason){
            this.getLetterSetupData()
          }
        });
        break;
    }
  };

  onCheckboxChange = (field, value) => {
    switch (this.props.description) {
      case this.props.description:
        this.setState((prevState) => ({
          letterRequestData: {
            ...prevState.letterRequestData,
            [field]: value ? 1 : 0,
          },
        }), () => {
          if (this.state.letterRequestData.is_default_user_defined === 1) {
            let letterRequestDataTemp = { ...this.state.letterRequestData };
            delete letterRequestDataTemp["selectedShowForSearch"];
            delete letterRequestDataTemp["selectedSeason"];
            this.setState({ letterRequestData: letterRequestDataTemp, showSeasonId: null }, () => this.getLetterSetupData());
          }else{
            this.setState({richTextEditor: " "})
          }

        });
        break;
    }
  }

  onChangeCallBack = (field, val) => {
    let selectedValue = val;
    this.handleChange(field, selectedValue);
    this.selectedShow(val);
  };

  selectedShow = (newValue) => {
    this.setState({isFetchingSeason: true})
    clearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/seasonsBasedOnShows?show_id=${newValue.value}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        let selectedShow = response?.data[0]?.seasons?.find(
          (item) => response?.data[0]?.show_id === newValue.value
        )?.show_season_id;
        this.setState({ showSeasonId: selectedShow }, () => {
          // this.getLetterSetupData()
        })
        let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
          (item, index, data) =>
            index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
        ) : [];
        let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
          value: item.season_id,
          label: item.season_name,
        })) : [];
        // formattedList.unshift({ label: "Select", value: null })
        if (typeof (selectedShow) !== 'undefined') {
          this.updateListState(formattedList, "showBasedSeasonList", 'filterList')
        } else {
          this.updateListState(formattedList.length > 0 ? formattedList : [], 'showBasedSeasonList', 'filterList')
        }
        this.setState({isFetchingSeason: false})
      })
      .catch(error => {
        console.log("error--", error)
      })

  }

  getShowDetails = (entity, config, node, searchString) => {
    this.setState({isFetchingSeason: true})
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (this.props?.userContext?.active_tenant?.tenant_id) {
      this.setState({ isShowFetching: true })
      clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
          let formattedList = response.data?.map((item) => ({
            value: item[config.id],
            text: item[config.name],
            division_id: item[config.division_id]
          }));
          this.updateListState(formattedList, node, "filterList");
          this.setState({ isShowFetching: false, isFetchingSeason: false })
        },
          (err) => {
            console.log("Error in fetching show data:", err)
          })
    }
  }
  updateListState = (value, node, list) => {
    this.setState(prevState => ({
      [list]: {
        ...prevState[list],
        [node]: value
      }
    }))
  }

  handleSubmit = () => {
    if(document.getElementById("issueToLanguageText")?.getElementsByClassName('ql-editor')[0]?.innerHTML === '<div> </div>' || this.state.editorText === null || this.state.editorText.trim() === ''){
      this.setState({isTextError: true})
      }
      else{
      this.setState({isTextError: false});
    switch (this.props.description) {
      case this.props.description:
        this.setState({ isPosting: true });
        let postObj = JSON.parse(JSON.stringify(this.state.letterRequestData));
        postPayloadData["description"] = document.getElementById("quoteExtensionText")?.getElementsByClassName('ql-editor')[0]?.innerHTML ? document.getElementById("quoteExtensionText")?.getElementsByClassName('ql-editor')[0]?.innerHTML : null;
        postPayloadData["text_type"] = "QuoteExtension"
        if (this.state.letterLanguageId != null) {
          postPayloadData["letter_language_id"] = this.state.letterLanguageId
        } else {
          postPayloadData["letter_language_id"] = null
        }

        // postPayloadData["user_defined"] = 1;
        postPayloadData["show_season_id"] = this.state.showSeasonId;
        postPayloadData["letter_type_id"] = this.state.letterTypeId;
        console.log("postPayloadData in handleSubmit", postPayloadData);
        break;
    }
    clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + "/letterLanguage", postPayloadData, this.props.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        if (response.data.error) {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
        } else {
          this.setState({ isPosting: false });
          this.getLetterSetupData()
          this.props?.notificationComponent(true, "success");
          console.log("post response", response);
        }
      },
        (err) => {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
          console.log("Post agency details error: " + err);
        });
      }
  }
  handleRichTextEditor = (content = null, delta = null, source = null, editor, variantToAdd = null) => {
    if (variantToAdd === null) {
      let text = editor.getText()
      let characterToModify = text.charAt(text.length - 2)
      let indexToModify = text.length - 2
      let alphaVariants = []
      if (alphabetVariantsJson[characterToModify] != undefined) {
        alphaVariants = alphabetVariantsJson[characterToModify]
      }
      this.setState({ characterToModify, indexToModify, alphaVariants, richTextEditor: editor.getHTML(), editorText: editor.getText() })
      if (alphaVariants.length > 0) {
        this.setState({ alphabetVariantMessage: true })
      } else {
        this.setState({ alphabetVariantMessage: false })
      }
    } else {
      let text = this.state.editorText
      let result = editor.indexOf(this.state.characterToModify + "</");
      String.prototype.replaceAt = function (index, replacement) {
        return this.substring(0, index) + replacement + '</div>'; //this.substring(index + replacement.length);
      }
      let text1 = text.replaceAt(this.state.indexToModify, variantToAdd)
      let richTextEditorValue = editor.replaceAt(result, variantToAdd)
      this.setState({ richTextEditor: richTextEditorValue })
      document.getElementById("quoteExtensionText")?.getElementsByClassName('ql-editor')[0]?.focus()

    }
  }

  onChangeIDPrefix = (item) => {
    let data = {...this.state.letterRequestData}
    if(item === null) {
        this.handleChange('selected_title', null)
        data.selected_title = null
        this.setState({letterRequestData : data})
    } else {
        if(!item.hasOwnProperty('titleOptions')) {
            let id_prefix = [...this.state.titleOptions]
            id_prefix.push({'value': item.value, 'text': item.value, 'titleOptions': item.value})
            this.handleChange('selected_title', item.value)
            data.selected_title = item.value
            this.setState({letterRequestData : data, titleOptions : id_prefix})
        } else {
            this.handleChange('selected_title', item.value)
            data.selected_title = item.value
            this.setState({letterRequestData : data})
        }
    }
}

  render() {
    return (
      <div>
          <MDBRow>
          <BasicCheckbox
            id="is_default_user_defined"
            label="Click to enter default quote extension language for all show seasons or enter a program and season below to override the default for a show season (default is 'Checked')"
            checked={this.state.letterRequestData?.is_default_user_defined}
            onChange={(e) =>
              this.onCheckboxChange("is_default_user_defined", e.target.checked)
            }
          />
        </MDBRow>
        <MDBRow>
          <MDBCol md={3} className="inline-block">
            <SearchSelectField
              label="Program"
              disabled={this.state.letterRequestData?.is_default_user_defined === 1 ? true : false}
              options={this.state.filterList?.showList}
              loading={this.state.isShowFetching}
              width="80%"
              multiple={false}
              detail_selected={this.state.letterRequestData.selectedShowForSearch}
              searchSelect={true}
              placeholder={'- Search -'}
              valueSelected={(e, newValue) => {
                if(newValue){
                  this.setState({division_id: newValue.division_id})
                  this.onChangeCallBack("selectedShowForSearch", newValue)
                }else{
                  this.setState({division_id: null})
                }
               
              }}
              searchText={ev => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== "" && ev.target.value !== null) {
                  this.getShowDetails("SHOW", { id: 'show_id', name: 'show_name', division_id: 'division_id' }, "showList", ev.target.value);
                } else {
                  this.setState({ isShowFetching: false })
                }
              }}
            />
          </MDBCol>
          <MDBCol md={3} className="inline-block">
          {this.state.isFetchingSeason ? <CircularProgress /> : 
            <SelectField
              id="Season"
              label="Season"
              disabled={this.state.letterRequestData?.is_default_user_defined === 1 ? true : false}
              value={this.state.letterRequestData?.selectedSeason ? typeof this.state.letterRequestData?.selectedSeason === "number" ? this.state.letterRequestData.selectedSeason : this.state.letterRequestData?.selectedSeason?.value : null}
              placeHolderText="- Season -"
              options={this.state.letterRequestData?.selectedShowForSearch ? (this.state.filterList?.showBasedSeasonList ? this.state.filterList?.showBasedSeasonList : []) : []}
              onChange={(e) => {
                this.handleChange("selectedSeason", e.target.value);
              }}
            />
          }
          </MDBCol>
        </MDBRow>
        {this.state.isFetchingLetterLoading ?
            <div className="ContentLoader">
              <CircularProgress />
                </div> :
        <MDBRow>
          <span className="error-text">
          {this.state.isTextError ? `Please write the comment in textEditor` : " "}</span>
          <MDBCol md={10} id={`quoteExtensionText`} disabled={(this.state.letterRequestData?.is_default_user_defined === 0 && this.state.letterRequestData?.selectedSeason === undefined)  || !this.props?.editRecord}>
            <RichTextEditor id={`quoteExtensionText`} value={this.state.richTextEditor} handleRichTextEditor={this.handleRichTextEditor.bind(this)}
              onChange={this.handleRichTextEditor} alphabetVariantMessage={this.state.alphabetVariantMessage} alphaVariants={this.state.alphaVariants} />
          </MDBCol>
        </MDBRow>
        }
        {this.props?.editRecord &&
        <MDBRow>
          <MDBCol md={10}></MDBCol>
          <MDBCol md={2} className="saveCancelWrapper" >
            <BasicButton
              disabled={(this.state.letterRequestData?.is_default_user_defined === 0 && ( this.state.letterRequestData?.selectedShowForSearch === undefined || this.state.letterRequestData?.selectedSeason === null || this.state.letterRequestData?.selectedShowForSearch === null)) }
              variant="outlined"
              text={"Revert"}
              onClick={this.getLetterSetupData}
            />
            <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
              disabled={(this.state.letterRequestData?.is_default_user_defined === 0 && ( this.state.letterRequestData?.selectedShowForSearch === undefined || this.state.letterRequestData?.selectedSeason === null || this.state.letterRequestData?.selectedShowForSearch === null)) }
              onClick={() => this.handleSubmit()}
            />
          </MDBCol>
        </MDBRow>
      }
      </div>
    );
  }
}
export default withUserContext(QuoteExtension);