export const lookupMaintenanceList = [
    {
        label: "CueSheet Publisher",
        value: 1,
        permissionName: "Cue Sheet Publishers tab"
    },
    {
        label: "CueSheet Recipients",
        value: 2,
        permissionName: "Cue Sheet Recipients tab"
    },
    {
        label: "EFC Recipients",
        value: 3,
        permissionName: "EFC Recipients tab"
    },
    {
        label: "Budget vs EFC Recipients",
        value: 4,
        permissionName: "Budget vs EFC Recipients tab"
    },
    {
        label: "PD Composers",
        value: 5,
        permissionName: "PD Composers tab"
    },
    {
        label: "Performers",
        value: 6,
        permissionName: "Performers tab"
    },
    {
        label: "Unions",
        value: 7,
        permissionName: "Unions tab"
    },
    {
        label: "Writers",
        value: 8,
        permissionName:"Writers tab"
    },
    {
        label: "Budget Recipients",
        value: 9,
        permissionName: "Budget Recipients (Pilot/Clearance)"
    },
    {
        label: "Affiliations",
        value: 10,
        permissionName: "Affiliations tab"
    }
]