export const efcRecipientConfig = {
    headings: [
        {
            headingLabel: "Name",
            width: "43%",
            inputType: "text"
        },
        {
            headingLabel: "Email",
            width: "43%",
            inputType: "text"
        },

    ],
    dataNodes: ["efc_recipient_name", "efc_recipient_email"],
    primaryKey: "id",
    nodeFields: [
        {
            column: "is_active",
            icon: "check"
        },
    ],
    actions: ["pen", "times"],
    inlineEdits: [
        {
            dataNode: "efc_recipient_name",
            width: "43%"
        },
        {
            dataNode: "efc_recipient_email",
            width: "43%",
            validateMethod : 'validateEmail',
            validateMessage : 'Please enter valid Email'
        },
    ],
}


