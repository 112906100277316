import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import "../LookupMaintenance.scss";
import BasicTextField from "../../../SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import TableComponent from "../../../SharedComponents/Table";
import {
  prformerGridConfig,
  performerIntialDetails,
} from "../CueSheetPublisher/Config";
import * as Constants from "../../../../constants/constants";
import ClearTrackService from "../../../../services/service";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from "../../../../contexts/UserContext";
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import {
  updateTableSortConfigObject,
  tableSortList,
} from "../../../../Common/TableHelper";
import axios from "axios";

class Performers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      performerDetails: { ...performerIntialDetails },
      renderList: [],
      // rowsPerPage: 10,
      page: 1,
      isSortByAsc: true,
      sortCount: 0,
      isSubmit: false,
      isDelete: false,
      postInitiated: false,
      showSeasonId: null,
      openEditSeasonModal: false,
      division_id: null,
      showSeasonPermission: [],
      sortBy: "Value",
      isSortByAsc: true,
      sortCount: 0,
      existingPerformer: [],
      allEntities: [],
      selectedEntity: null,
      allPerformers: [],
      isFetchingPerformer: false,
      searchCancelToken: null,
      totalRecord: 0,
    };
  }

  componentDidMount() {
    this.getPerformers();
  }

  getPermormerDetails = (id) => {
    this.setState({ isLoading: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/clearanceRecordList?entity=PERFORMER&entity_id=${id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        // let data = { ...this.state.performerDetails };
        // data.name = this.state.existingPerformer.find(
        //   (item) => item.performer_id === id
        // ).performer_name;
        let performerDetailList = response?.data?.map((item, index) => {
          let newitem = {...item}
          newitem.initial_fee = item.initial_fee
            ? "$" + parseFloat(item.initial_fee).toFixed(2)
            : null
          newitem.show_season =
            item?.show_name && item?.season_name
              ? `${item?.show_name} / ${item?.season_name}`
              : !item?.show_name && item?.season_name
              ? `${item?.season_name}`
              : item?.show_name && !item?.season_name
              ? `${item?.show_name}`
              : "";
            return newitem
        });
        this.setState({
          renderList: performerDetailList,
          isLoading: false,
          //performerDetails: data,
        });
        this.handleTableColumnClick("Show/Season");
      })
      ,(err) => {
        console.log("Error in fetching Details:", err);
        this.setState({ isLoading: false });
      };
  };

  handleEdit = (field, value) => {
    this.setState((prevState) => ({
      performerDetails: { ...prevState.performerDetails, [field]: value },
      postInitiated: false,
    }));
  };

  handleSubmit = (deleteFlag = 0) => {
    this.setState({ postInitiated: true });
    if (this.state.performerDetails?.name) {
      this.setState({ postInitiated: false });
      let performerData = {
        ...this.state.performerDetails,
        is_active: 1,
        table_name: "lu_performer",
      };
      if (deleteFlag === 1) {
        performerData.is_delete = 1;
      } else {
        this.setState({ isSubmit: true });
      }
      ClearTrackService.postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/staticData`,
        performerData,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.props.toastMessageNotification(true, "fail");
            this.setState({ isSubmit: false });
          } else {
            this.setState({
              performerDetails: {
                ...performerIntialDetails,
              },
              isSubmit: false,
              renderList: [],
              page: 1
            },()=>{this.getPerformers(false)});
            this.props.toastMessageNotification(true, "success");
          }
          this.setState({ isDelete: false });
        },
        (err) => {
          this.setState({ isSubmit: false, isDelete: false });
          this.props.toastMessageNotification(true, "fail");
        }
      );
    }
  };

  handleDeletePerformer = () => {
    this.setState({ isDelete: true });
    let performer_id = this.state.allPerformers.find(
      (item) => item.performer_name === this.state.performerDetails?.name
    )?.performer_id;
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/checkRelationalTables?tableName=lu_performer&tableId=${performer_id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((res) => {
      if (res?.data[0]?.status === "SUCCESS") {
        this.handleSubmit(1);
      } else {
        this.props?.fieldChanged(true, "fail");
        this.setState({ isDelete: false });
      }
    }),
      (err) => {
        this.props?.fieldChanged(true);
        this.setState({ isLoading: false, isDelete: false });
      };
  };

  navigationCallback = (obj, node) => {
    if (obj?.show_season_id && node === "show_season") {
      window.open(`/showseason/${obj.show_season_id}`, "_blank");
    } else if (obj?.show_season_episode_id && node === "episode_title") {
      window.open(
        `/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`,
        "_blank"
      );
    }
  };

  handleShareClick = (Id, dataItem) => {
    let cr = this.state.renderList?.find((item) => item?.clearance_id === Id);
    if (cr?.show_season_episode_id) {
      window.open(
        `/episodeAndClearance/ss/${cr.show_season_id}/ep/${cr.show_season_episode_id}`,
        "_blank"
      );
    } else {
      window.open(`/showseason/${cr.show_season_id}`, "_blank");
    }
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      });
    } else
      this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      });
  };

  handleTableSort = (colName) => {
    this.setState(
      {
        isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        config: updateTableSortConfigObject(
          prformerGridConfig,
          this.state.isSortByAsc,
          this.state.sortBy,
          colName
        ),
      },
      () => {
        this.setState({
          sortBy: colName,
          renderList: tableSortList(
            "Name",
            this.getSortNode(colName),
            this.state.renderList,
            this.state.isSortByAsc
          ),
        });
      }
    );
  };

  getSortNode = (col) => {
    switch (col) {
      case "Show/Season":
        return "show_season";
      case "Episode#":
        return "episode_title";
      case "Song":
        return "song_title";
      case "Use":
        return "licensor_name";
      case "Status":
        return "status";
      case "Intial Fee":
        return "initial_fee";
    }
  };

  getPerformers = (scrollLoad = false) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    this.setState({ isFetchingPerformer: true });
    let postData = {
      entity_text: "PERFORMER",
      entity_search_text: this.state.searchString,
      page_count: 100,
      page_no: this.state.page,
    };
    let encryptTedStr = btoa(JSON.stringify(postData));
    ClearTrackService.getDataWithCancel(
      Constants.ClearTrackServiceBaseUrl +
        `/dashboardLookups?input==${encryptTedStr}`,
      cancelToken,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        this.setState({ totalRecord: response.data?.query_record_count });
        if (response.data?.records?.length > 0) {
          let allList = [];
          if (scrollLoad) {
            allList = [...this.state.allPerformers, ...response.data?.records];
          } else {
            allList = response.data?.records;
          }
          this.setState({
            allPerformers: allList,
            isFetchingPerformer: false,
            renderList: [],
            performerDetails: JSON.parse(
              JSON.stringify({ ...performerIntialDetails })
            ),
          });
        } else {
          this.setState({
            performerDetails: JSON.parse(
              JSON.stringify({ ...performerIntialDetails })
            ),
            isFetchingPerformer: false,
            renderList: [],
            allPerformers: [],
          });
        }
      })
      .catch((err) => {
        this.setState({ isFetchingPerformer: false });
      });
  };

  render() {
    return (
      <MDBContainer fluid className="CueSheetContainer">
        <MDBRow className="tab-content">
          <MDBCol md={3}>
            <div>
              <BasicTextField
                id="performer"
                placeholder={"- Search by Performer Name -"}
                value={this.state.searchString}
                onChange={(e) => {
                  this.setState(
                    {
                      searchString:
                        e.target.value.length > 0 ? e.target.value : null,
                      page: 1,
                    },
                    this.getPerformers
                  );
                }}
              />
            </div>

            <div
              className="existing-records"
              onScroll={(e) => {
                let totalScrollHeight = Math.floor(e.currentTarget.clientHeight)
                if (
                  [totalScrollHeight-1, totalScrollHeight, totalScrollHeight +1]?.includes(Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop)) &&
                  this.state.totalRecord != this.state.allPerformers.length
                ) {
                  this.setState(
                    (prevState) => ({
                      page: prevState.page + 1,
                    }),
                    () => {
                      this.getPerformers(true);
                    }
                  );
                }
              }}
            >
              <>
                {this.state.allPerformers.length > 0 ? (
                  <>
                    {this.state.allPerformers?.map((item, index) => (
                      <div
                        key={index}
                        className="entity-item"
                        onClick={() => {
                          this.setState({
                            performerDetails: {
                              id: item?.performer_id,
                              name: item?.performer_name,
                            },
                          });
                          this.getPermormerDetails(item.performer_id);
                        }}
                      >
                        <span
                          className={`${
                            item.performer_id === this.state.performerDetails.id
                              ? "highlight-entity"
                              : ""
                          }`}
                        >
                          {item.performer_name}
                        </span>
                      </div>
                    ))}
                    {this.state.isFetchingPerformer && (
                      <CircularProgress color="white" size={18} />
                    )}
                  </>
                ) : (
                  <span className="no-content">
                    {!this.state.isFetchingPerformer && "No Data Found"}{" "}
                  </span>
                )}
              </>
            </div>
          </MDBCol>
          <MDBCol md={9}>
            <MDBRow className="performer-mt">
              {/* <BasicLabel text="Selected Performer" /> */}
              <MDBCol md={4} className="text-btn">
                <BasicTextField
                  className="CueSheetPublisherName"
                  label="Selected Performer"
                  placeholder={"- Selected Performer Name -"}
                  isMandatory={true}
                  showMandatory={this.state.postInitiated || false}
                  value={this.state.performerDetails?.name}
                  onChange={(e) =>
                    this.handleEdit(
                      "name",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
                <div className="mt-4">
               
                  <BasicButton
                    className={"save-btn"}
                    variant="contained"
                    text={
                      this.state.isSubmit ? (
                        <CircularProgress color="white" size={18} />
                      ) : (
                        " "
                      )
                    }
                    icon={"save"}
                    onClick={this.handleSubmit}
                  />
  
                </div>
              </MDBCol>
              <MDBCol md={2}></MDBCol>
              {this.state.performerDetails?.name ? (
                <MDBCol md={1} className="mt-4">
                  <span className="add-prod-entity-btn">
                    <BasicButton
                      type="inline"
                      color="primary"
                      text={" "}
                      icon={"plus-circle"}
                      onClick={() =>
                        this.setState({
                          performerDetails: JSON.parse(
                            JSON.stringify({ ...performerIntialDetails })
                          ),
                          selectedEntity: null,
                          renderList: [],
                        })
                      }
                    />
                  </span>
                </MDBCol>
              ) : null}
             
              <>
              {this.state.performerDetails?.name ? (
                <MDBCol md={2} className="delete-font mt-4">
                  {this.state.isDelete ? (
                    <CircularProgress size={18} />
                  ) : (
                    <span
                      className="delete-btn"
                      onClick={this.handleDeletePerformer}
                    >
                      Delete
                    </span>
                  )}
                </MDBCol>
              ) : null}
              </>
            
              <MDBCol md={6}></MDBCol>
            </MDBRow>
            {this.state.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <MDBRow className="mt-2">
                <div id="table-bg-sync-cue">
                  <TableComponent
                    list={this.state.renderList || []}
                    className={"clearencRecord"}
                    config={prformerGridConfig}
                    stickyHeader={true}
                    hyperLinkNavigationCallback={this.navigationCallback}
                    sortCallback={this.handleTableColumnClick}
                  />
                </div>
                {this.state.openEditSeasonModal && (
                  <MainViewModalComponent
                    open={this.state.openEditSeasonModal}
                    handleClose={() =>
                      this.setState({
                        showSeasonPermission: [],
                        openEditSeasonModal: false,
                        showSeasonId: null,
                        division_id: null,
                      })
                    }
                    headerText={"Show Details"}
                    mode={"Edit"}
                    modalName={"Edit ShowDetails"}
                    show_season_id={this.state.showSeasonId}
                    fetchData={() =>
                      this.setState({
                        showSeasonPermission: [],
                        openEditSeasonModal: false,
                        showSeasonId: null,
                        division_id: null,
                      })
                    }
                    division_id={this.state.division_id}
                    permissionArr={this.state.showSeasonPermission}
                  />
                )}
              </MDBRow>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(Performers);
