import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBIcon } from "mdbreact";
import { withUserContext } from '../../../contexts/UserContext';
import './AcquireOption.scss';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import Chip from "@material-ui/core/Chip";
import { validateEmail } from '../../../Common/Helper'
class AcquireOptionEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentMail: null,
            ccMail: null,
            toMails: [],
            ccMails: [],
            sub: this.props?.subject,
            body: this.props?.body,
            postInitiated: false,
        }
    }

    render() {
        return (
            <MDBContainer className='AcquireOptionEmail'>
                <MDBRow className="mt-2">
                    <BasicLabel text={"From"} />
                    <div>{this.props?.userContext?.user_profile?.login_email}</div>
                    <div>
                        {this.state.toMails.length > 0 &&
                            this.state.toMails?.map((item, index) => (
                                <Chip
                                    label={item}
                                    onDelete={() => {
                                        let toMails = [...this.state.toMails]
                                        toMails.splice(index, 1)
                                        this.setState({ postInitiated: false, toMails: toMails })
                                    }}
                                />
                            ))}
                    </div>
                    <MDBCol md={11}>
                        <BasicTextField
                            id="To"
                            label="To"
                            fieldValid={!validateEmail(this.state.currentMail) && this.state.currentMail}
                            inValidInput={"Invalid Email"}
                            placeholder={'Insert valid email and click on + button'}
                            value={this.state.currentMail}
                            onChange={(e) => this.setState({ postInitiated: false, currentMail: e.target.value })} />
                    </MDBCol>
                    <MDBCol md={1} className="plusCircleMargin" disabled={this.state.currentMail ? !validateEmail(this.state.currentMail) : true}>
                        <MDBIcon
                            icon="plus-circle"
                            onClick={() => {
                                let toMails = [...this.state.toMails]
                                toMails.push(this.state.currentMail)
                                this.setState({ postInitiated: false, toMails: toMails, currentMail: null })
                            }}
                        ></MDBIcon>
                    </MDBCol>
                </MDBRow>
                <div>
                    {this.state.ccMails.length > 0 &&
                        this.state.ccMails?.map((item, index) => (
                            <Chip
                                label={item}
                                onDelete={() => {
                                    let ccMails = [...this.state.ccMails]
                                    ccMails.splice(index, 1)
                                    this.setState({ postInitiated: false, ccMails: ccMails })
                                }}
                            />
                        ))}
                </div>
                <MDBRow className="mt-2">
                    <MDBCol md={11}>
                        <BasicTextField
                            id="cc"
                            label="Cc"
                            fieldValid={!validateEmail(this.state.ccMail) && this.state.ccMail}
                            inValidInput={"Invalid Email"}
                            value={this.state.ccMail}
                            placeholder={'Insert valid email and click on + button'}
                            onChange={(e) => this.setState({ postInitiated: false, ccMail: e.target.value })} />
                    </MDBCol>
                    <MDBCol md={1} className="plusCircleMargin" disabled={this.state.ccMail ? !validateEmail(this.state.ccMail) : true}>
                        <MDBIcon
                            icon="plus-circle"
                            onClick={() => {
                                let ccMails = [...this.state.ccMails]
                                ccMails.push(this.state.ccMail)
                                this.setState({ ccMails: ccMails, ccMail: null, postInitiated: false })
                            }}
                        ></MDBIcon>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol md={12}>
                        <BasicTextField
                            id="Subject"
                            label="Subject"
                            showMandatory={this.state.postInitiated || false}
                            isMandatory={true}
                            value={this.state.sub || null}
                            onChange={(e) => this.setState({ postInitiated: false, sub: e.target.value.length > 0 ? e.target.value : null })} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol md={12}>
                        <BasicTextArea
                            id="Message Body"
                            label="Message Body"
                            value={this.state.body || null}
                            onChange={(e) => this.setState({ postInitiated: false, body: e.target.value.length > 0 ? e.target.value : null })}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow  className="errorText mt-2">
                        <span>{this.state.postInitiated && this.state.toMails.length === 0 && 'Please enter atleast 1 mail id in To'}</span>
                </MDBRow>
                <MDBRow className="mt-2">
                    <MDBCol md={7}>
                    </MDBCol>
                    <MDBCol md={3}>
                        <BasicButton
                            onClick={() => {
                                this.setState({ postInitiated: true }, () => {
                                    if (this.state.sub && this.state.toMails.length > 0) {
                                        let emailInfo = {}
                                        emailInfo.sub = this.state.sub
                                        emailInfo.toMails = this.state.toMails
                                        emailInfo.ccMails = this.state.ccMails
                                        emailInfo.body = this.state.body
                                        this.props.handleSubmit(emailInfo)
                                    }
                                })
                            }}
                            text={"Send Email"} />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton onClick={() => this.props?.handleClose()} variant="outlined" text={"Cancel"} />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }

}
export default withUserContext(AcquireOptionEmail);