import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import SearchSelectField from "../SharedComponents/SearchSelectField";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import RichTextEditor from "../SharedComponents/RichTextEditor/RichTextEditor";
import clearTrackService from '../../services/service';
import * as Constants from '../../constants/constants';
import { withUserContext } from '../../contexts/UserContext';
import { postPayload } from "./config";
import alphabetVariantsJson from '../SharedComponents/RichTextEditor/AlphabetVariant.json';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
let postPayloadData = JSON.parse(JSON.stringify(postPayload))

class IssueToLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productionEntityOptions: [],
      letterRequestData: {},
      isPosting: false,
      titleOptions: [],
      isLoading: false,
      searchCancelToken: null,
      isShowFetching: null,
      selectedShowForSearch: null,
      selectedSeason: null,
      letterLanguageId: null,
      letterTypeId: null,
      filterList: {
        showList: [],
        showBasedSeasonList: [],
        productionEntityOptions: []
      },
      alphabetVariantMessage: false,
      alphaVariants: [],
      richTextEditor: null,
      indexToModify: null,
      editorText: null,
      characterToModify: null,
      isFetchingLetterLoading: false,
      isTextError: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchData("production_entity", `/entitySearch?entity=PRODUCTION_ENTITY&searchString=null`);
    this.fetchData("letterType", `/entitySearch?entity=LETTER_TYPE&searchString=null`);
  }

  getLetterSetupData = () => {
    this.setState({isFetchingLetterLoading : true})
    let encryptionType = 1;
    let { letterRequestData, letterTypeId } = this.state;
    let data = {
      letter_type_id: letterTypeId, text_type: "Issue To Language", production_entity_id: letterRequestData.selected_production?.value,
      show_season_id: null
    }
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/letterLanguage?input=${btoa(JSON.stringify(data))}`, this.props.userContext?.active_tenant?.tenant_id, null, true)
      .then(response => {
        this.setState({ isLoading: false });
        if (response.data.length > 0) {
          let selected_production = { ... this.state.letterRequestData }
          selected_production.selected_production = { value: response.data[0]?.production_entity_id, text: response.data[0]?.company }
          selected_production.is_active = response.data[0]?.is_active;
          this.setState({ letterLanguageId: response.data[0]?.letter_language_id, richTextEditor: response.data[0]?.description, letterRequestData: selected_production })
        } else {
          let selected_production = { ... this.state.letterRequestData }
          selected_production.is_active = 0;
          this.setState({ letterLanguageId: null, richTextEditor: " ", letterRequestData: selected_production })
        }
        this.setState({isFetchingLetterLoading: false})
      },
        (err) => {
          this.setState({ isLoading: false })
          console.log("Error in fetching details:", err)
        })
  }

  fetchData = (field, url) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        this.setState({ isLoading: false });
        let formattedList = [];
        let letterTypeValue = null;
        switch (field) {
          case 'letterType':
            letterTypeValue = response.data?.find(item => item.description === this.props.description).letter_type_id;
            this.setState({ letterTypeId: letterTypeValue })
            break;
          case 'production_entity':
            formattedList = response.data?.map(item => ({ value: item.production_entity_id, text: item.company }));
            this.setState({ productionEntityOptions: formattedList })
            break;
        }
      },
        (err) => {
          this.setState({ isLoading: false })
          console.log("Error in fetching details:", err)
        })
  }

  onCheckboxChange = (field, value) => {
    this.setState((prevState) => ({
      letterRequestData: {
        ...prevState.letterRequestData,
        [field]: value ? 1 : 0,
      },
    }));
  }

  onChangeCallBack = (field, val) => {
    let selectedValue = val;
    if(selectedValue === null){
      this.setState({richTextEditor: " "})
    }
    this.handleChange(field, selectedValue);
  };

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      letterRequestData: {
        ...prevState.letterRequestData,
        [field]: value,
      },
    }), () => {
      if (this.state.letterRequestData.selected_production != null) {
        this.getLetterSetupData()
      }

    });
  };

  getEntity = (entity, config, node, searchString) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel("Operation canceled due to new request");
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (this.props?.userContext?.active_tenant?.tenant_id) {
      this.setState({ isShowFetching: true })
      clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=${entity}&searchString=${searchString}`, cancelToken, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
          let formattedList = response.data?.map((item) => ({
            value: item[config.id],
            text: item[config.name]
          }));
          this.updateListState(formattedList, node, "filterList");
          this.setState({ isShowFetching: false })
        },
          (err) => {
            console.log("Error in fetching show data:", err)
          })
    }
  }
  updateListState = (value, node, list) => {
    this.setState(prevState => ({
      [list]: {
        ...prevState[list],
        [node]: value
      }
    }))
  }

  handleSubmit = () => {
    if(document.getElementById("issueToLanguageText")?.getElementsByClassName('ql-editor')[0]?.innerHTML === '<div><br></div>'){
    this.setState({isTextError: true})
    }
    else{
    this.setState({ isPosting: true ,isTextError: false});
    let postObj = JSON.parse(JSON.stringify(this.state.letterRequestData));
    postPayloadData["description"] = document.getElementById("issueToLanguageText")?.getElementsByClassName('ql-editor')[0]?.innerHTML ? document.getElementById("issueToLanguageText")?.getElementsByClassName('ql-editor')[0]?.innerHTML : null;
    postPayloadData["text_type"] = "Issue To Language";
    if (this.state.letterLanguageId != null) {
      postPayloadData["letter_language_id"] = this.state.letterLanguageId
    } else {
      postPayloadData["letter_language_id"] = null
    }
    postPayloadData["production_entity_id"] = postObj?.selected_production?.value ? postObj?.selected_production?.value : null;
    postPayloadData["is_active"] = postObj?.is_active ? postObj?.is_active : 0;
    postPayloadData["letter_type_id"] = this.state.letterTypeId;
    clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + "/letterLanguage", postPayloadData, this.props.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        this.setState({ isPosting: false });
        if (response.data.error) {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
        } else {
          this.setState({ isPosting: false });
          this.getLetterSetupData()
          this.props?.notificationComponent(true, "success");
        }
      },
        (err) => {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
          console.log("Post agency details error: " + err);
        });
      }
  }

  handleRichTextEditor = (content = null, delta = null, source = null, editor, variantToAdd = null) => {
    if (variantToAdd === null) {
      let text = editor.getText()
      let characterToModify = text.charAt(text.length - 2)
      let indexToModify = text.length - 2
      let alphaVariants = []
      if (alphabetVariantsJson[characterToModify] != undefined) {
        alphaVariants = alphabetVariantsJson[characterToModify]
      }
      this.setState({ characterToModify, indexToModify, alphaVariants, richTextEditor: editor.getHTML(), editorText: editor.getText(), isTextError: false })
      if (alphaVariants.length > 0) {
        this.setState({ alphabetVariantMessage: true })
      } else {
        this.setState({ alphabetVariantMessage: false })
      }
    } else {
      let text = this.state.editorText
      let result = editor.indexOf(this.state.characterToModify + "</");
      String.prototype.replaceAt = function (index, replacement) {
        return this.substring(0, index) + replacement + '</div>';
        //this.substring(index + replacement.length);
      }
      let text1 = text.replaceAt(this.state.indexToModify, variantToAdd)
      let richTextEditorValue = editor.replaceAt(result, variantToAdd)
      this.setState({ richTextEditor: richTextEditorValue })
      document.getElementById("issueToLanguageText")?.getElementsByClassName('ql-editor')[0]?.focus()
    }
  
  }

  render() {
    return (
      <div>
        <MDBRow>
          <MDBCol md={3} className="productionEntityWrapper">
            <SearchSelectField
              placeholder={"Production_Entity"}
              label={"Choose a Production Entity:"}
              options={this.state.filterList?.productionEntityOptions}
              detail_selected={this.state.letterRequestData?.selected_production ? this.state.letterRequestData.selected_production : null}
              loading={this.state.isShowFetching}
              width="80%"
              multiple={false}
              searchSelect={true}
              valueSelected={(e, newValue) => {
                this.onChangeCallBack("selected_production", newValue)
              }}
              searchText={ev => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== "" && ev.target.value !== null) {
                  this.getEntity("production_entity", { id: 'production_entity_id', name: 'company' }, "productionEntityOptions", ev.target.value);
                } else {
                  this.setState({ isShowFetching: false })
                }
              }}
            />
          </MDBCol>
          <MDBCol md={2} className="productionEntityWrapper">
            <BasicCheckbox id="is_active" label="Active"
              checked={this.state.letterRequestData?.is_active || false}
              onChange={(e) => this.onCheckboxChange("is_active", e.target.checked)}
            />
          </MDBCol>
        </MDBRow>
        {this.state.isFetchingLetterLoading ?
            <div className="ContentLoader">
              <CircularProgress />
                </div> :
        <MDBRow>
          <span className="error-text">
          {this.state.isTextError ? `Please write the comment in textEditor` : " "}</span>
          <MDBCol md={10} id={`issueToLanguageText`} disabled={this.state.letterRequestData?.selected_production === undefined || !this.props?.editRecord }>
            <RichTextEditor style ={{cursor:"pointer"} }id={`issueToLanguageText`} value={this.state.richTextEditor} handleRichTextEditor={this.handleRichTextEditor.bind(this)} 
              onChange={this.handleRichTextEditor} alphabetVariantMessage={this.state.alphabetVariantMessage} alphaVariants={this.state.alphaVariants} />
          </MDBCol>
        </MDBRow>
        }
        {this.props?.editRecord &&
        <MDBRow>
          <MDBCol md={10}></MDBCol>
          <MDBCol md={2} className="saveCancelWrapper" >
            <BasicButton
              disabled={this.state.letterRequestData?.selected_production === undefined || this.state.letterRequestData?.selected_production === null}
              variant="outlined"
              text={"Revert"}
              onClick={() => this.getLetterSetupData()}
            />
            <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
              disabled={this.state.letterRequestData?.selected_production === undefined || this.state.letterRequestData?.selected_production === null}
              onClick={() => this.handleSubmit()}
            />
          </MDBCol>
        </MDBRow>
        }
      </div>
    );
  }
}
export default withUserContext(IssueToLanguage);