import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ClearTrackServices from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { payeeDetails, remitsToAddress } from "./config";
import { RemitTabListInitial } from "../../../constants/TabList";
import { CircularProgress } from "@material-ui/core";
import '../EditPayee/EditPayee.scss';
import Tabs from "../../SharedComponents/Tabs/Tabs";
import PayeeRemitDetails from "./PayeeRemitDetails";
import { withUserContext } from '../../../contexts/UserContext';
import { validateEmail, validateNumbersOnly, validateAllPhone } from "../../../Common/Helper";
import MessageModal from "../../SharedComponents/MessageModal";

class EditPayeeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payeeDetails: { ...payeeDetails },
      isSubmit: false,
      tabValue: 1,
      existingPayeeDetails: null,
      showPastAddress: false,
      isLoading: true,
      postInitiated: false,
      RemitTabList: [...RemitTabListInitial],
      showDeletePayeeMsg: false,
      showError: false,
      errMsg: null,
      isDelete: false
    };
  }

  componentDidMount() {
    if (this.props?.mode === "Edit") {
      ClearTrackServices.getData(
        Constants.ClearTrackServiceBaseUrl +
        `/payee?payee_id=${this.props?.id}`,
        this.props?.userContext?.active_tenant?.tenant_id
      )
        .then((res) => {
          if (res.data[0] !== null) {
            let RemitTabList = [...this.state.RemitTabList]
            for (let i = 1; i < res?.data[0]?.remits_to?.length; i++) {
              if (RemitTabList.length < res?.data[0]?.remits_to?.length) {
                RemitTabList.push({
                  label: `Remit To ${i + 1}`,
                  value: i + 1,
                });
              }
            }
            this.setState({ RemitTabList: RemitTabList })
          }

          let payeeDetailsData = JSON.parse(JSON.stringify({ ...res.data[0] }));
          if (payeeDetailsData?.tax) {
            payeeDetailsData.payee_group = "tax";
          } else if (payeeDetailsData?.ssn) {
            payeeDetailsData.payee_group = "ssn";
          } else if (payeeDetailsData?.other) {
            payeeDetailsData.payee_group = "other";
          } else if (payeeDetailsData?.is_NA === 1) {
            payeeDetailsData.payee_group = "is_NA";
          } else {
            payeeDetailsData.payee_group = "tax";
          }
          payeeDetailsData?.remits_to?.map((item) => {
            return (item.is_address_updated = 0);
          });
          payeeDetailsData?.remits_to?.map((item) => {
            return (item.is_delete = 0);
          });
          this.setState({
            payeeDetails: payeeDetailsData,
            existingPayeeDetails: res.data[0],
            isLoading: false
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let newPayeeDetails = {...payeeDetails}
      newPayeeDetails.payee_type = this.props?.modeType == "CLEARANCE_PAYEE" ? 'CLEARANCE_PAYEE' 
                                  : (this.props?.modeType == "Music Supervisor" || this.props?.modeType == "MUSIC_SUPERVISOR") ? 'MS_PAYEE' :
                                  (this.props?.modeType == "Show Composer" || this.props?.modeType == "SHOW_COMPOSER") ? 'SC_PAYEE' :null;
      this.setState({ payeeDetails: newPayeeDetails, isLoading: false, });
    }
  }

  handleChange = (field, value, updateValue = null) => {
    this.setState((prevState) => ({
      payeeDetails: {
        ...prevState.payeeDetails,
        [field]: value,
      },
      postInitiated: false,
    }), () => {
      if ( field === 'payee_group') {
        this.setState((prevState) => ({
          payeeDetails: {
            ...prevState.payeeDetails,
            'tax' : value === 'tax'  ?  updateValue: null ,
            'ssn' : value === 'ssn'  ?  updateValue: null ,
            'other' : value === 'other'  ?  updateValue: null ,
            'is_NA' : value === 'is_NA'  ?  1 : 0
          }
        }));
      }
    });
    this.props?.fieldChanged(true, 'change');
  };

  handleChangeRemitFields = (listName, field, value, index) => {
    this.setState((prevState) => ({
      payeeDetails: {
        ...prevState.payeeDetails,
        'remits_to': prevState.payeeDetails?.remits_to?.map((item, itemIndex) => {
          let newItem = { ...item }
          if (itemIndex !== index && field === 'default_remit_to') {
            newItem.default_remit_to = 0
          } else {
            if (itemIndex === index) {
              newItem[field] = value
            }
          }
          return newItem
        })
      },
      postInitiated: false,
    }));
    if (field !== 'country' && field !== 'country_id') {
      this.props?.fieldChanged(true, 'change');
    }
  };

  validateBeforeSubmit = (payeeData) => {
    let canSubmit = false
    if (this.props?.mode === 'Edit') {
      canSubmit = payeeData?.payee_company &&payeeData?.remits_to?.every(item =>
        // (item?.remit_to) &&
        (item?.email ? validateEmail(item?.email) : true) &&
        (item?.phone ? validateAllPhone(item?.phone) : true) 
        //&& (item?.zip ? item?.country === 'United States' ? item?.zip?.length <= 5 : item?.zip?.length <= 10 : true) 
        //&& (item?.is_address_updated === 1 ? item?.payee_notes : true)
      )
    } else {
      canSubmit = payeeData?.payee_company &&  payeeData?.remits_to?.every(item =>
        // (item?.remit_to) &&
        (item?.email ? validateEmail(item?.email) : true) &&
        (item?.phone ? validateAllPhone(item?.phone) : true) 
        //&& (item?.zip ? item?.country === 'United States' ? item?.zip?.length <= 5 : item?.zip?.length <= 10 : true)
        )
    }
    return canSubmit
  }


  checkAddressUpdate = () => {
    let existingPayeeData = { ...this.state.existingPayeeDetails };
    let payeeDetailsData = { ...this.state.payeeDetails };
    for (let i = 0; i < payeeDetailsData?.remits_to?.length; i++) {
      if (i < existingPayeeData?.remits_to?.length &&
        (payeeDetailsData?.remits_to[i].address_1 !== existingPayeeData?.remits_to[i]?.address_1 ||
          payeeDetailsData?.remits_to[i].address_2 !== existingPayeeData?.remits_to[i]?.address_2 ||
          payeeDetailsData?.remits_to[i].city !== existingPayeeData?.remits_to[i]?.city ||
          payeeDetailsData?.remits_to[i].state !== existingPayeeData?.remits_to[i]?.state ||
          payeeDetailsData?.remits_to[i].country !== existingPayeeData?.remits_to[i]?.country ||
          payeeDetailsData?.remits_to[i].country_id !== existingPayeeData?.remits_to[i]?.country_id ||
          payeeDetailsData?.remits_to[i].zip !== existingPayeeData?.remits_to[i]?.zip)
      ) {
        payeeDetailsData.remits_to[i].is_address_updated = 1;
        payeeDetailsData.remits_to[i].history_address_1 = existingPayeeData?.remits_to[i]?.address_1;
        payeeDetailsData.remits_to[i].history_address_2 = existingPayeeData?.remits_to[i]?.address_2;
        payeeDetailsData.remits_to[i].history_city = existingPayeeData?.remits_to[i]?.city;
        payeeDetailsData.remits_to[i].history_state = existingPayeeData?.remits_to[i]?.state;
        payeeDetailsData.remits_to[i].history_country = existingPayeeData?.remits_to[i]?.country;
        payeeDetailsData.remits_to[i].history_country_id = existingPayeeData?.remits_to[i]?.country_id;
        payeeDetailsData.remits_to[i].history_zip = existingPayeeData?.remits_to[i]?.zip;
      }
    }
    this.setState({ payeeDetails: payeeDetailsData })
  }

  setDefault = () => {
    let payeeData = { ...this.state.payeeDetails }
    if (payeeData?.remits_to?.every(item => item?.default_remit_to === 0)) {
      payeeData.remits_to[0].default_remit_to = 1
      this.setState({ payeeDetails: payeeData })
    }
  }

  handleSubmit = () => {
    if (this.props?.mode === 'Edit') {
      this.checkAddressUpdate()
    }
    this.setState({ postInitiated: true })
    this.setDefault();
    let postObj = { ...this.state.payeeDetails }
    if (this.props?.openFromDashboard && this.state.payeeDetails?.payee_type) {
      postObj["payee_type"] = this.state.payeeDetails?.payee_type
    } else {
      if (this.props?.modeType == "Music Supervisor" || this.props?.modeType == "MUSIC_SUPERVISOR") {
        postObj["payee_type"] = "MS_PAYEE";
      }
      if (this.props?.modeType == "Show Composer" || this.props?.modeType == "SHOW_COMPOSER") {
        postObj["payee_type"] = "SC_PAYEE";
      }
      if (this.props?.modeType == "CLEARANCE_PAYEE") {
        postObj["payee_type"] = "CLEARANCE_PAYEE";
      }
    }
    if (this.validateBeforeSubmit(postObj)) {
      this.setState({ isSubmit: true, postInitiated: false, showError: false, errMsg: false });
      this.props?.setPostFlag(true);
      ClearTrackServices.postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/payee`,
        postObj,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.setState({ isSubmit: false });
            this.props?.notificationComponent(true, "fail");
          } else {
            if (this.props?.getPayeeRemitTo) {
              ClearTrackServices.getData(
                Constants.ClearTrackServiceBaseUrl +
                `/payee?payee_id=${response.data[0]?.payee_id}`,
                this.props?.userContext?.active_tenant?.tenant_id
              )
                .then((res) => {
                  this.props?.getPayeeRemitTo(res.data[0])
                })
                .catch((err) => {
                  this.props?.getPayeeRemitTo(null)

                });
            }
            if ((this.props?.modeType == "Music Supervisor" || this.props?.modeType == "MUSIC_SUPERVISOR" || this.props?.modeType == "Show Composer" || this.props?.modeType == "SHOW_COMPOSER") && this.props?.mode === "New") {
              this.props?.postPeopleData(response?.data[0]?.payee_id);
              //this.props?.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.props.show_season_id}`);
            }
            if (this.props?.handleSubmit) {
              this.props?.handleSubmit();
            }
            if (this.props?.mode === "Edit" && this.props?.origin === "BusinessAffairs") {
              this.props?.getPayee(this.props?.peopleId);
              this.props?.fetchTableData("musicSupervisorCheckRequest", `/showSeasonMusicSupervisorCheckRequests?show_season_id=${this.props?.show_season_id}&show_season_people_id=${this.props?.show_season_people_id}`);
              //this.props?.handleClose();
            }
            // if (this.props?.mode === "Edit") {
            //   this.props?.handleClose();
            // }
            this.props?.notificationComponent(true, "success");
            this.setState({
              isSubmit: false,
            });
          }
        },
        (err) => {
          this.setState({ isSubmit: false });
          this.props?.notificationComponent(true, "fail");;
        }
      );
    } else {
      this.setState({ showError: true, errMsg: `Please fill all the mandatory fields.` });
    }
  };

  handleTabChange = (newTabValue) => {
    this.setState({
      tabValue: newTabValue
    });
  };

  addRemitComponent = () => {
    this.setState({ tabValue: this.state.tabValue + 1 });
    let RemitTabList = [...this.state.RemitTabList]
    let newTab = {
      label: `Remit To ${RemitTabList.length + 1}`,
      value: RemitTabList.length + 1,
    }
    RemitTabList.push(newTab);
    this.setState({ RemitTabList: RemitTabList })
    this.handleTabChange(newTab.value)
    let payeeData = { ...this.state.payeeDetails }
    payeeData?.remits_to.push({ ...remitsToAddress })
    this.setState({ payeeDetails: payeeData })
  };
  handleShowAddressTable = () => {
    let remitsIndex = this.state.tabValue - 1;
    let addressIndex = this.state.payeeDetails?.remits_to?.findIndex((item, itemIndex) => remitsIndex === itemIndex)
    if (remitsIndex === addressIndex) {
      this.setState({ showPastAddress: !this.state.showPastAddress })
    }
  }

  getTabComponent = () => {
    switch (this.state.tabValue) {
      case this.state.tabValue:
        return (
          <PayeeRemitDetails
            payeeDetails={this.state.payeeDetails?.remits_to}
            payeeId={this.props?.id}
            index={this.state.tabValue - 1}
            handleChangeRemitFields={this.handleChangeRemitFields}
            handleChange={this.handleChange}
            handleShowAddressTable={this.handleShowAddressTable}
            showPastAddress={this.state.showPastAddress}
            mode={this.props?.mode}
            postInitiated={this.state.postInitiated}
            existingPayeeDataLength={this.state.existingPayeeDetails?.remits_to?.length}
            deleteTabItem={this.deleteTabItem}
            isDelete={this.state.isDelete}
            division_id={this.props?.division_id}
          />
        );
    }
  };
  deleteTabItem = (deletedItem) => {
    let RemitTabList = [...this.state.RemitTabList]
    let data = JSON.parse(JSON.stringify(this.state.payeeDetails))
    let remitData = RemitTabList.find((item) => item.value === this.state.tabValue)
    let remitIndex = RemitTabList?.indexOf(remitData)
    this.setState({ isDelete: true })
    if (deletedItem?.payee_remit_to_id) {
      ClearTrackServices.getData(
        Constants.ClearTrackServiceBaseUrl +
        `/checkRelationalTables?tableName=payee_remit_to&tableId=${deletedItem?.payee_remit_to_id}`,
        this.props?.userContext?.active_tenant?.tenant_id
      )
        .then((res) => {
          if (res?.data[0]?.status === 'SUCCESS') {
            this.setState({ showDeletePayeeMsg: false, isDelete: false })

            deletedItem.is_delete = 1
            data.remits_to?.map((item) => {
              if (item.payee_remit_to_id === deletedItem?.payee_remit_to_id) {
                return item.is_delete = 1;
              }
            })
            RemitTabList.splice(remitIndex, 1)

            this.setState({
              payeeDetails: data,
              RemitTabList: RemitTabList
            })
            if (this.state.RemitTabList?.length > 1) {
              this.handleTabChange(1)
            }
          } else {
            this.setState({ showDeletePayeeMsg: true, isDelete: false })
          }
        }),
        ((err) => {
          this.setState({ showDeletePayeeMsg: false, isDelete: false })
        });
    } else {
      let remitpayeeIndex = data.remits_to?.indexOf(deletedItem)
      data.remits_to.splice(remitpayeeIndex, 1)
      RemitTabList.splice(remitIndex, 1)
      this.setState({
        payeeDetails: data,
        RemitTabList: RemitTabList,
        isDelete: false
      })
      if (this.state.RemitTabList?.length > 1) {
        this.handleTabChange(1)
      }
    }


  }
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <div className="EditPayeeDetailsContainer" disabled={this.props?.disabled}>
            {this.state.showError && <p className="errorMessage">{this.state.errMsg}</p>}
            <MessageModal
              open={this.state.showDeletePayeeMsg}
              title={"Message"}
              message={"You can not delete this Payee because it is being used"}
              hideCancel={true}
              primaryButtonText={"OK"}
              secondaryButtonText={"Cancel"}
              onConfirm={() => this.setState({ showDeletePayeeMsg: false })}
              handleClose={(e) => this.setState({ showDeletePayeeMsg: false })} />
            <div className="radioBtnWrapper">
              <div className="radioBtnContainer">
                <Radio name="radio-buttons"
                  checked={this.state.payeeDetails?.payee_type == "CLEARANCE_PAYEE"}
                  value="CLEARANCE_PAYEE"
                  {...(this.props?.openFromDashboard ? { onChange: () => { this.handleChange("payee_type", 'CLEARANCE_PAYEE') } } : {})}

                />
                <span className="popover-content-value">Clearance Payee</span>
              </div>
              <div className="radioBtnContainer">
                <Radio name="radio-buttons"
                  checked={this.state.payeeDetails?.payee_type == "MS_PAYEE"}
                  value="MS_PAYEE"
                  {...(this.props?.openFromDashboard ? { onChange: () => { this.handleChange("payee_type", 'MS_PAYEE') } } : {})}
                />
                <span className="popover-content-value">Music Spvsr. Payee</span>
              </div>
              <div className="radioBtnContainer">
                <Radio name="radio-buttons"
                  checked={this.state.payeeDetails?.payee_type == "SC_PAYEE"}
                  value="SC_PAYEE"
                  {...(this.props?.openFromDashboard ? { onChange: () => { this.handleChange("payee_type", 'SC_PAYEE') } } : {})}
                />
                <span className="popover-content-value">Show Comp. Payee</span>
              </div>
            </div>
            <MDBRow>
              <MDBCol md={5}>
                <MDBRow>
                  <MDBCol md={6}>
                    <BasicTextField
                      label="Payee Company"
                      isMandatory={true}
                      showMandatory={this.state?.postInitiated || false}
                      value={this.state.payeeDetails?.payee_company}
                      onChange={(e) =>
                        this.handleChange("payee_company", e.target.value.length > 0 ? e.target.value : null)
                      }
                    />
                  </MDBCol>
                  <MDBCol md={6}>
                    <BasicTextField
                      label="Payee Tax ID/SSN"
                      value={
                        this.state.payeeDetails?.payee_group === "tax"
                          ? this.state.payeeDetails?.tax
                          : this.state.payeeDetails?.payee_group === "ssn"
                            ? this.state.payeeDetails?.ssn
                            : this.state.payeeDetails?.payee_group === "other"
                              ? this.state.payeeDetails?.other
                              : this.state.payeeDetails?.payee_group === "is_NA"
                                ? null
                                : null
                      }
                      onChange={(e) =>
                        this.state.payeeDetails?.payee_group ===
                          "is_NA" ? null : this.handleChange(this.state.payeeDetails?.payee_group, e.target.value?.length ? e.target?.value : null)
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  {this.props?.linkedMsScName &&  <MDBCol md={3}>
                    <BasicLabel text={(this.props?.modeType == "Music Supervisor" || this.props?.modeType == "MUSIC_SUPERVISOR") ? "Linked Music Supervisor" : "Linked Show Composer"} />
                    <BasicLabel text={this.props?.linkedMsScName} />
                  </MDBCol>}
                  <MDBCol md={6}>
                    <BasicTextField
                      label="Vendor #"
                      value={this.state.payeeDetails?.vendor}
                      // isMandatory={true}
                      // showMandatory={this.state?.postInitiated || false}
                      onChange={(e) =>
                        validateNumbersOnly(e.target.value)
                          ? this.handleChange("vendor", e.target.value?.length ? e.target?.value : null)
                          : null
                      }
                    />
                  </MDBCol>
                  <MDBCol md={3} className='inactivePayeeCheckbox'>
                    <BasicCheckbox
                      id="payeeInactiveCheckbox"
                      label={"Inactive"}
                      checked={this.state.payeeDetails?.is_active === 1 ? false : true}
                      onChange={(e) => this.handleChange("is_active", e.target.checked ? 0 : 1)}
                    /> 
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md={1}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  id="radio-group-height"
                  value={this.state.payeeDetails?.payee_group || null}
                  onChange={(e) => this.handleChange("payee_group", e.target.value, (this.state.payeeDetails?.payee_group === 'is_NA' && e.target.value != 'is_NA') ? null : this.state.payeeDetails[this.state.payeeDetails?.payee_group] )}
                >
                  <FormControlLabel
                    className="LabelSize"
                    value="tax"
                    control={<Radio size="small" />}
                    label="TaxId"
                  />
                  <FormControlLabel
                    className="LabelSize"
                    value="ssn"
                    control={<Radio size="small" />}
                    label="SSN"
                  />
                  <FormControlLabel
                    className="LabelSize"
                    value="other"
                    control={<Radio size="small" />}
                    label="Other"
                  />
                  <FormControlLabel
                    className="LabelSize"
                    value="is_NA"
                    control={<Radio size="small" />}
                    label="N/A"
                  />
                </RadioGroup>
              </MDBCol>
              <MDBCol md={2} className="SelfEnd">
                <BasicCheckbox
                  label="ACH"
                  align="end"
                  id={"is_ACH"}
                  checked={this.state.payeeDetails?.is_ACH === 1}
                  onChange={(e) => this.handleChange("is_ACH", e.target.checked ? 1 : 0)}
                />
                <BasicCheckbox
                  className="SelfEnd"
                  label="Intl. Wire Transfer"
                  align="end"
                  id={"is_intl_wire_transfer"}
                  checked={this.state.payeeDetails?.is_intl_wire_transfer === 1}
                  onChange={(e) =>
                    this.handleChange("is_intl_wire_transfer", e.target.checked ? 1 : 0)
                  }
                />
                <BasicCheckbox
                  className="SelfEnd"
                  label="Check"
                  align="end"
                  checked={this.state.payeeDetails?.is_check === 1}
                  id={"is_check"}
                  onChange={(e) => this.handleChange("is_check", e.target.checked ? 1 : 0)}
                />
                <BasicCheckbox
                  label="Foreign Vendor"
                  className="SelfEnd"
                  align="end"
                  id={"is_foreign_vendor"}
                  checked={this.state.payeeDetails?.is_foreign_vendor === 1}
                  onChange={(e) =>
                    this.handleChange("is_foreign_vendor", e.target.checked ? 1 : 0)
                  }
                />
              </MDBCol>
              <MDBCol md={4}>
                <BasicTextArea
                  label={"Notes"}
                  rows={3}
                  value={this.state.payeeDetails?.notes}
                  onChange={(e) => this.handleChange("notes", e.target.value?.length > 0 ? e.target?.value : null)}
                />
              </MDBCol>

            </MDBRow>
            <MDBRow className="IconSize">
              <MDBCol style={{ display: "flex" }}>
                <div className="remit">
                  <div className="tabContainer">
                    <Tabs
                      tabList={this.state.RemitTabList}
                      value={this.state.tabValue}
                      onChange={(e, v) => this.handleTabChange(v)}
                    />
                  </div>
                  <div className="remitFont">
                    <i
                      class="fas fa-plus-circle"
                      onClick={this.addRemitComponent}
                    ></i>
                  </div>
                </div>
              </MDBCol>
              {this.getTabComponent()}
            </MDBRow>

            <MDBRow>
              <MDBRow className="p-2">
                <MDBCol md={10}></MDBCol>
                <MDBCol md={1}>
                  <BasicButton
                    className="btnwidth"
                    variant="contained"
                    type="inline"
                    disabled={!this.state.payeeDetails?.payee_company}
                    text={
                      this.state.isSubmit ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        "Save"
                      )
                    }
                    onClick={this.handleSubmit}
                  />
                </MDBCol>
                <MDBCol md={1}>
                  <BasicButton
                    type="inline"
                    variant="outlined"
                    text={"Close"}
                    onClick={this.props?.handleClose}
                    disabled={false}
                  />
                </MDBCol>
              </MDBRow>
            </MDBRow>
          </div>
        )}</>
    );
  }
}

export default withUserContext(EditPayeeDetails);
