export const personalDetails={
        "show_season_id":null,
        "people_id": null,
        "people_type": null,
        "user_id": null,
        "full_name": null,
        "email": null,
        "work_phone": null,
        "cell_phone": null,
        "address_1": null,
        "address_2": null,
        "city": null,
        "state": null,
        "country": null,
        "country_id":null,
        //"country_name": null,
        "zip_code": null,
        "tenant_id": null,
        "is_active": null,
}
export const addressConfig = {
    "address1": "address_1",
    "address2": "address_2",
    "country": "country_id",
    "state": "state",
    "city": "city",
    "zipcode": "zip_code",
}