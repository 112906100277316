import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import AutoCompleteSearch from "../../../SharedComponents/AutoCompleteSearch/AutoCompleteSearch";
import "../LookupMaintenance.scss";
import BasicLabel from "../../../SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../../../SharedComponents/BasicTextField/BasicTextField";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import TableComponent from "../../../SharedComponents/Table";
import {
  pdComposerConfig,
  pdComposerIntialDetails,
} from "../CueSheetPublisher/Config";
import { TablePagination } from "@mui/material";
import DateField from "../../../SharedComponents/DateField/DateField";
import * as Constants from "../../../../constants/constants";
import ClearTrackService from "../../../../services/service";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from "../../../../contexts/UserContext";
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import { getFormattedDate } from "../../../../Common/Helper";
import {
  updateTableSortConfigObject,
  tableSortList,
} from "../../../../Common/TableHelper";
import axios from "axios";

class PDComposer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdComposerDetails: { ...pdComposerIntialDetails },
      renderList: [],
      isSubmit: false,
      isDelete: false,
      searchString: null,
      isSortByAsc: true,
      sortCount: 0,
      postInitiated: false,
      showSeasonId: null,
      openEditSeasonModal: false,
      division_id: null,
      showSeasonPermission: [],
      sortBy: "Value",
      isSortByAsc: true,
      sortCount: 0,
      existingPDComper: [],
      allEntities: [],
      allPDComposers: [],
      selectedEntity: null,
      isLoading: false,
      searchCancelToken: null,
      totalRecord: 0,
      page: 1,
      isFechingComposer: false,
      pdComposerRecord: [],
      totalRecord: 0,
    };
  }

  componentDidMount() {
    this.getCuesheetPublisher();
  }

  getPDComposerDetails = (id) => {
    this.setState({ isLoading: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/clearanceRecordList?entity=PD_COMPOSER&entity_id=${id.pd_composer_id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        this.handlePdData(id);
        let data = { ...this.state.pdComposerDetails };
        data.pd_composer_name = this.state.allPDComposers.find(
          (item) => item.pd_composer_id === id.pd_composer_id
        ).pd_composer_name;
        let composerList = [...response?.data];
        composerList?.map((item, index) => {
          composerList[index].show_season =
            item?.show_name && item?.season_name
              ? `${item?.show_name} / ${item?.season_name}`
              : !item?.show_name && item?.season_name
              ? `${item?.season_name}`
              : item?.show_name && !item?.season_name
              ? `${item?.show_name}`
              : "";
        });
        composerList?.map((item) => {
          return (item.initial_fee = item.initial_fee
            ? "$" + parseFloat(item.initial_fee).toFixed(2)
            : null);
        });

        this.setState({
          renderList: composerList,
          pdComposerDetails: data,
          isLoading: false,
          isFechingComposer: false,
        });
        this.handleTableColumnClick("Show/Season");
      })
      .catch((err) => {
        console.log("Error in fetching Details:", err);
      });
  };

  handlePdData = (value) => {
    console.log("value..................", value);
    let pdDetails = { ...this.state.pdComposerDetails };
    pdDetails.pd_composer_id = value.pd_composer_id;
    pdDetails.pd_composer_name = value.pd_composer_name;
    pdDetails.year_died = value?.year_died
      ? value?.year_died?.split("T")[0]
      : null;
    pdDetails.comment = value.comment;
    this.setState({
      pdComposerDetails: pdDetails,
    });
  };

  handleEdit = (field, value) => {
    this.setState((prevState) => ({
      pdComposerDetails: {
        ...prevState.pdComposerDetails,
        [field]: value,
      },
      postInitiated: false,
    }));
  };

  handleSubmit = (deleteFlag = 0) => {
    this.setState({ postInitiated: true });
    if (this.state.pdComposerDetails?.pd_composer_name) {
      this.setState({ postInitiated: false });
      let pdComposerDetails = { ...this.state.pdComposerDetails, is_active: 1 };
      if (deleteFlag === 1) {
        pdComposerDetails.is_delete = 1;
      } else {
        this.setState({ isSubmit: true });
      }
      ClearTrackService.postDataParams(
        Constants.ClearTrackServiceBaseUrl + `/PDComposer`,
        pdComposerDetails,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          if (response.data.error) {
            this.props.toastMessageNotification(true, "fail");
            this.setState({ isSubmit: false });
          } else {
            this.setState(
              {
                pdComposerDetails: {
                  ...pdComposerIntialDetails,
                },
                isSubmit: false,
                renderList: [],
                page: 1
              },
              () => {
                this.getCuesheetPublisher(false);
              }
            );
            this.props.toastMessageNotification(true, "success");
          }
          this.setState({ isDelete: false });
        },
        (err) => {
          this.setState({ isSubmit: false, isDelete: false });
          this.props.toastMessageNotification(true, "fail");
        }
      );
    }
  };

  handleDeleteComposer = () => {
    this.setState({ isDelete: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/checkRelationalTables?tableName=lu_pd_composer&tableId=${this.state.pdComposerDetails?.pd_composer_id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((res) => {
      if (res?.data[0]?.status === "SUCCESS") {
        this.handleSubmit(1);
      } else {
        this.props?.fieldChanged(true, "fail");
        this.setState({ isDelete: false });
      }
    }),
      (err) => {
        this.props?.fieldChanged(true);
        this.setState({ isLoading: false, isDelete: false });
      };
  };

  navigationCallback = (obj, node) => {
    if (obj?.show_season_id && node === "show_season") {
      window.open(`/showseason/${obj.show_season_id}`, "_blank");
    } else if (obj?.show_season_episode_id && node === "episode_title") {
      window.open(
        `/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`,
        "_blank"
      );
    }
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      });
    } else
      this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      });
  };

  handleTableSort = (colName) => {
    this.setState(
      {
        isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        config: updateTableSortConfigObject(
          pdComposerConfig,
          this.state.isSortByAsc,
          this.state.sortBy,
          colName
        ),
      },
      () => {
        this.setState({
          sortBy: colName,
          renderList: tableSortList(
            "Name",
            this.getSortNode(colName),
            this.state.renderList,
            this.state.isSortByAsc
          ),
        });
      }
    );
  };

  getSortNode = (col) => {
    switch (col) {
      case "Show/Season":
        return "show_season";
      case "Episode#":
        return "episode_title";
      case "Song":
        return "song_title";
      case "Use":
        return "licensor_name";
      case "Status":
        return "status";
      case "Intial Fee":
        return "initial_fee";
    }
  };

  getCuesheetPublisher = (scrollLoad = false) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    this.setState({ isFechingComposer: true });
    let postData = {
      entity_text: "pd_composer",
      entity_search_text: this.state.searchString,
      page_count: 100,
      page_no: this.state.page,
    };
    let encryptTedStr = btoa(JSON.stringify(postData));
    ClearTrackService.getDataWithCancel(
      Constants.ClearTrackServiceBaseUrl +
        `/dashboardLookups?input==${encryptTedStr}`,
      cancelToken,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((response) => {
        this.setState({ totalRecord: response.data?.query_record_count });
        if (response.data?.records?.length > 0) {
          let allList = [];
          if (scrollLoad) {
            allList = [...this.state.allPDComposers, ...response.data?.records];
          } else {
            allList = response.data?.records;
          }
          this.setState({
            allPDComposers: allList,
            isFechingComposer: false,
            renderList: [],
            pdComposerDetails: JSON.parse(
              JSON.stringify({ ...pdComposerIntialDetails })
            ),
          });
        } else {
          this.setState({
            pdComposerDetails: JSON.parse(
              JSON.stringify({ ...pdComposerIntialDetails })
            ),
            isFechingComposer: false,
            allPDComposers: [],
            renderList: [],
          });
        }
      })
      .catch((err) => {
        this.setState({ isFechingComposer: false });
      });
  };

  render() {
    let permissionValue = this.props?.permissionArray
    console.log("value",permissionValue)
    return (
      <MDBContainer fluid className="CueSheetContainer">
        <MDBRow className="tab-content" >
          <MDBCol md={3}>
            <div>
              <BasicTextField
                id="pdcomposer"
                placeholder={"- Search by PDComposer Name -"}
                value={this.state.searchString}
                onChange={(e) => {
                  this.setState(
                    {
                      searchString:
                        e.target.value.length > 0 ? e.target.value : null,
                      page: 1,
                    },
                    this.getCuesheetPublisher
                  );
                }}
              />
            </div>
            <div
              className="existing-records"
              onScroll={(e) => {
                let totalScrollHeight = Math.floor(e.currentTarget.clientHeight)
                if (
                  [totalScrollHeight-1, totalScrollHeight, totalScrollHeight +1]?.includes(Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop)) &&
                  this.state.totalRecord != this.state.allPDComposers.length
                ) {
                  this.setState(
                    (prevState) => ({
                      page: prevState.page + 1,
                    }),
                    () => {
                      this.getCuesheetPublisher(true);
                    }
                  );
                }
              }}
            >
              <>
                {this.state.allPDComposers.length > 0 ? (
                  <>
                    {this.state.allPDComposers?.map((item, index) => (
                      <div
                        key={index}
                        className="entity-item"
                        onClick={() => {
                          this.setState({
                            pdComposerDetails: {
                              id: item?.pd_composer_id,
                              name: item?.pd_composer_name,
                            },
                          });
                          this.getPDComposerDetails(item);
                        }}
                      >
                        <span
                          className={`${
                            item.pd_composer_id ===
                            this.state.pdComposerDetails.id
                              ? "highlight-entity"
                              : ""
                          }`}
                        >
                          {item.pd_composer_name}
                        </span>
                      </div>
                    ))}
                    {this.state.isFechingComposer && (
                      <CircularProgress color="white" size={18} />
                    )}
                  </>
                ) : (
                  <span className="no-content">
                    {!this.state.isFechingComposer && "No Data Found"}{" "}
                  </span>
                )}
              </>
            </div>
          </MDBCol>
          <MDBCol md={9}>
            <MDBRow className="mt-pd">
              <div className="text-btn">
                <MDBCol md={3}>
                  {/* <BasicLabel text="Selected PD Composer" /> */}
                  <BasicTextField
                    label="Selected PD Composer"
                    className="composer-name"
                    isMandatory={true}
                    showMandatory={this.state.postInitiated || false}
                    placeholder={"- Selected Public Domain composers Name -"}
                    value={this.state.pdComposerDetails?.pd_composer_name}
                    onChange={(e) =>
                      this.handleEdit(
                        "pd_composer_name",
                        e.target.value?.length > 0 ? e.target.value : null
                      )
                    }
                  />
                </MDBCol>
                <MDBCol md={2}>
                  <BasicLabel text="Year Died" />
                  <div id="Year-Died">
                    <DateField
                      id="Year-died"
                      value={this.state.pdComposerDetails.year_died || ""}
                      onChange={(e) =>
                        this.handleEdit(
                          "year_died",
                          e.target.value?.length > 0 ? e.target.value : null
                        )
                      }
                    />
                  </div>
                </MDBCol>
                <MDBCol md={4}>
                  <BasicLabel text="Comment" />
                  <BasicTextField
                    className="Comment"
                    placeholder={"- Comment Text -"}
                    value={this.state.pdComposerDetails?.comment || ""}
                    onChange={(e) =>
                      this.handleEdit(
                        "comment",
                        e.target.value?.length > 0 ? e.target.value : null
                      )
                    }
                  />
                </MDBCol>
                <MDBCol md={1}>
                {permissionValue[0].edit === 1 &&
                  <BasicButton
                    className={"save-btn-3"}
                    variant="contained"
                    text={
                      this.state.isSubmit ? (
                        <CircularProgress color="white" size={18} />
                      ) : (
                        " "
                      )
                    }
                    icon={"save"}
                    onClick={this.handleSubmit}
                  />
                }
                </MDBCol>
                {this.state.pdComposerDetails?.pd_composer_name ? (
                  <MDBCol md={1} className="mt-4">
                    <span className="add-prod-entity-btn">
                      <BasicButton
                        type="inline"
                        color="primary"
                        text={" "}
                        icon={"plus-circle"}
                        onClick={() =>
                          this.setState({
                            pdComposerDetails: JSON.parse(
                              JSON.stringify({ ...pdComposerIntialDetails })
                            ),
                            selectedEntity: null,
                            renderList: [],
                          })
                        }
                      />
                    </span>
                  </MDBCol>
                ) : null}
                {permissionValue[0].delete === 1 &&
                <>
                {this.state.pdComposerDetails?.pd_composer_name ? (
                  <MDBCol md={1} className="delete-font mt-4">
                    {this.state.isDelete ? (
                      <CircularProgress size={18} />
                    ) : (
                      <span
                        className="delete-btn"
                        onClick={this.handleDeleteComposer}
                      >
                        Delete
                      </span>
                    )}
                  </MDBCol>
                ) : null}
                </>
                }
              </div>
            </MDBRow>
            {this.state.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <MDBRow className="mt-2">
                <h6>Clearance Record</h6>
                <div id="table-bg-sync-cue">
                  <TableComponent
                    list={this.state.renderList || []}
                    className={"clearencRecord"}
                    config={pdComposerConfig}
                    stickyHeader={true}
                    hyperLinkNavigationCallback={this.navigationCallback}
                    sortCallback={this.handleTableColumnClick}
                  />
                </div>
                {this.state.openEditSeasonModal && (
                  <MainViewModalComponent
                    open={this.state.openEditSeasonModal}
                    handleClose={() =>
                      this.setState({
                        openEditSeasonModal: false,
                        showSeasonId: null,
                        division_id: null,
                        showSeasonPermission: [],
                      })
                    }
                    headerText={"Show Details"}
                    mode={"Edit"}
                    modalName={"Edit ShowDetails"}
                    show_season_id={this.state.showSeasonId}
                    fetchData={() =>
                      this.setState({
                        openEditSeasonModal: false,
                        showSeasonId: null,
                        division_id: null,
                        showSeasonPermission: [],
                      })
                    }
                    division_id={this.state.division_id}
                    permissionArr={this.state.showSeasonPermission}
                  />
                )}
              </MDBRow>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withUserContext(PDComposer);
