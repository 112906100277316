import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React from 'react';
import TableComponent from '../../SharedComponents/Table';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { clearReacordList, clearReacordHeading } from './config';
import { updateTableSortConfigObject, handlePaginationList, tableSortList } from '../../../Common/TableHelper';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal"
import './EditSong.scss';

class EditSongClearanceRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            songId: this.props?.songId,
            config: JSON.parse(JSON.stringify({ ...clearReacordHeading })),
            renderList: [],
            usingClearanceList: [],
            page: 0,
            rowsPerPage: 20,
            isFetchingClearanceList: false,
            isUsingOnly: false,
            sortBy: "Value",
            isSortByAsc: true,
            sortCount: 0,
            showClearanceModal: false,
            clearanceId: null,
            showId: null,
            showSeasonId: null,
            showName: null,
            seasonId: null,
            seasonName: null,
            episodeId: null,
            episodeName: null
        }
    }

    componentDidMount = () => {
        this.getSongClearanceRecords();
    }

    getSongClearanceRecords = () => {
        this.setState({ isFetchingClearanceList: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/clearanceRecordList?entity=SONG&entity_id=${this.props?.songId}`, this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                this.setState({
                    renderList: handlePaginationList(this.state.page, this.state.rowsPerPage, response.data),
                    currentList: response.data,
                    isFetchingClearanceList: false
                })
                this.handleTableColumnClick("Program");
            })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.currentList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }

    navigationCallback = (obj, node) => {
        if (node === 'show_name' || node === "season_name") {
            window.open(`/showSeason/${obj.show_season_id}`, '_blank');
        } else if (node === 'episode_title') {
            window.open(`/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`);
        }
    }

    handleChange = (field, value) => {
        if (field === "isUsingOnly") {
            let clearanceList = [...this.state.renderList];
            if (value) {
                let filteredUsingClearanceList = clearanceList?.filter(item => item?.status === "Using");
                this.setState({
                    [field]: value,
                    usingClearanceList: handlePaginationList(this.state.page, this.state.rowsPerPage, filteredUsingClearanceList),
                    currentList: filteredUsingClearanceList,
                })
            } else {
                this.setState({
                    [field]: value,
                    renderList: handlePaginationList(this.state.page, this.state.rowsPerPage, clearanceList),
                    currentList: clearanceList,
                    usingClearanceList: []
                })
            }
        }
    }
    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }
    handleTableSort = (colName) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
        }, () => {
            this.setState({
                sortBy: colName,
                renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Program": return "show_name";
            case "Season": return "season_name";
            case "Episode": return "episode_title";
            case "Compisition": return "song_title";
            case "Rights": return "rights";
            case "Fee": return "fee";
            case "Status": return "status";
        }
    }
    handleShareClick = (Id, dataItem, dataItem2) => {
        if(dataItem?.show_season_episode_id) {
            window.open(`/episodeAndClearance/ss/${dataItem.show_season_id}/ep/${dataItem.show_season_episode_id}`, dataItem?.clearance_id)
        } else {
            window.open(`/showseason/${dataItem.show_season_id}`, '_blank')  
        }
        // this.setState({
        //     showClearanceModal: true,
        //     clearanceId: dataItem?.clearance_id, showName: dataItem?.show_name, showId: dataItem?.show_id,
        //     episodeId: dataItem?.episode_id, episodeName: dataItem?.episode_name, seasonId: dataItem?.season_id,
        //     showSeasonId: dataItem?.show_season_id
        // })
    }
    render() {
        return (
            <>
                {this.state.isFetchingClearanceList ?
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div> :
                    <MDBContainer fluid id="TableClearanceRecord">
                        <MDBRow className="using-only-check">
                            <MDBCol md={11}></MDBCol>
                            <MDBCol >
                                <BasicCheckbox
                                    id="using_only"
                                    label="Using Only"
                                    align="end"
                                    checked={this.state.isUsingOnly}
                                    onChange={(e) => this.handleChange("isUsingOnly", e.target.checked)}
                                />
                            </MDBCol>
                        </MDBRow>
                        <TableComponent
                            list={this.state.isUsingOnly === true ? this.state.usingClearanceList : this.state.renderList}
                            config={this.state.config}
                            isLoading={false}
                            hyperLinkNavigationCallback={this.navigationCallback}
                            sortCallback={this.handleTableColumnClick}
                            handleOpenPopUp={(event, dataItem) => this.handleShareClick(event, dataItem, this)}
                        />
                        <TablePagination
                            component="div"
                            count={this.state.currentList ? this.state.currentList?.length : 0}
                            page={this.state.page}
                            rowsPerPageOptions={[20,30, 50, 100]}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        {/* {
                            this.state.showClearanceModal &&
                            <MainViewModalComponent
                                open={this.state.showClearanceModal}
                                handleClose={() => this.setState({ showClearanceModal: false })}
                                headerText={"Clearance Record"}
                                mode={"Edit"}
                                modalName={"Clearance Record"}
                                clearenceId={this.state.clearanceId}
                                show_season_id={this.props?.showSeasonId}
                                show_name={this.state.showName}
                                season_name={this.state.seasonName}
                                episode_name={this.state.episodeName}
                                episodeId={this.state.episodeId}
                                seasonId={this.state.seasonId}
                                showId={this.state.showId}
                                from={false}
                                clearance_divisionId={this.props.division_id}
                                // commonPermissions={this.props?.commonPermissions}

                            />
                        } */}
                    </MDBContainer>
                }
            </>
        )
    }
}

export default withUserContext(EditSongClearanceRecord);
