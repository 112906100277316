import React from 'react';
import TextField from '@material-ui/core/TextField';
import { getFormattedDate } from '../../../Common/Helper';
import BasicLabel from '../BasicLabel/BasicLabel';
import './DateField.scss';

export default class DateField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mandatoryWarning: "Please complete this mandatory field",
            dateError:"Cannot be less than Start Date"
        }
    }
    render() {
        // var dateValue = this.props?.value ? getFormattedDate(this.props?.value, true) : null;
        let error = (this.props.showMandatory && !this.props?.value) || this.props.showErrorBorder || false;
        return (
            <div 
                className={`${error ? "DateFieldContainer error" : "DateFieldContainer"}`}
                style={{width: this.props?.width ? this.props?.width : ""}}
            >
                {this.props?.label && <BasicLabel text={this.props?.label || ""} type={"text"}
                    isMandatory={this.props?.isMandatory || false} />}
                {this.props.showAsLabel ? <div className="showAsLabel">{this.props?.value && this.props?.value !== '-'? getFormattedDate(this.props?.value) : ""} </div> :
                <TextField
                    type="date"
                    title={this.props?.onHoverDateText || null}
                   // InputProps={{inputProps: { max: getFormattedDate(new Date, true)} }}
                    id={this.props?.id}
                    placeholder={this.props?.placeholder || "MM/DD/YYYY"}
                    format="MM/DD/YYYY"
                    variant="outlined"
                    disabled={this.props?.disabled ? this.props?.disabled : false}
                    value={this.props?.value || ""}
                    onChange={(e) => {e.target.value?.length <= 10 ? this.props?.onChange(e) : null}}
                />}
                {error && <div className="errorText">{(this.props.showMandatory && !this.props?.value) ? this.state.mandatoryWarning : 
                this.props.showErrorBorder ? this.state.dateError : ""}</div>}
            </div>
        );
    }
}
