export const musicEditorFilter = {
    type: null,
    fullName: null,
    lname: null,
    email: null,
    workPhone: null,
    cellPhnoe: null,
    fedaralIdNo: null,
    addressName: null,
    streetAddress: null,
    city: null,
    selectedgt: null,
    union: null,
    serviceBeingRendered: null,
    status: null,
    country: null,
    zipcode: null,
    show: null,
    season: null,
    showYr: null,
    episodeName: null,
    episode: null,
    feePerEpisode:null
}

export const typeOptons = {
    "value": "Music Editors",
    "label": "Show Composers"
}

export const musicEditorTblConfig = {
    headings: [{
        headingLabel: "Name",
        icon: "",
        width: "15%"
    },
    {
        headingLabel: "Email",
        icon: "",
        width: "15%"
    },
    {
        headingLabel: "Address",
        icon: "",
        width: "15%"
    },
    {
        headingLabel: "AgentName",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Agency",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Airing",
        icon: "",
        width: "17%"
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "7%"
    },
    {
        headingLabel: "",
        icon: "",
        width: "1%"
    },
    ],
    dataNodes: ["full_name", "email", "address", "agent_name", "agency", "airing", "status", ""],
    hyperlinks: ["full_name"],
    primaryKey: "people_id",
    actions: ["share"]
}

export const musicEditorDataConfig = [
    {
        id: 1,
        Name: "[Name]",
        Email: "[Email]",
        Address: "[Address]",
        AgentName: "[AgentName]",
        Agency: "[Agency]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 2,
        Name: "[Name]",
        Email: "[Email]",
        Address: "[Address]",
        AgentName: "[AgentName]",
        Agency: "[Agency]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 3,
        Name: "[Name]",
        Email: "[Email]",
        Address: "[Address]",
        AgentName: "[AgentName]",
        Agency: "[Agency]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 4,
        Name: "[Name]",
        Email: "[Email]",
        Address: "[Address]",
        AgentName: "[AgentName]",
        Agency: "[Agency]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 5,
        Name: "[Name]",
        Email: "[Email]",
        Address: "[Address]",
        AgentName: "[AgentName]",
        Agency: "[Agency]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
]

export const typeOptions = [
    { label: "Select", value: null },
    { value: 1, label: "Music Supervisor" },
    { value: 2, label: "Show Composer" }
]

export const gtOptions = [
    { label: "Select", value: null },
    { label: "gt", value: ">" },
    { label: "lt", value: "<" },
    { label: "gt/eq", value: ">=" }, 
    { label: "lt/eq", value: "<=" }
]


export const filterConfig = {
    show: { id: 'show_id', name: 'show_name' },
    season: { id: 'season_id', name: 'season_name'},
}

export const musicEditorConfig = {
    type_search_ids: null,
    full_name_search_text: null,
    email_search_text: null,
    work_phone_search_text: null,
    cell_phone_search_text: null,
    federal_id_no_search_text: null,
    address_1_search_text: null,
    address_2_search_text: null,
    city_search_text: null,
    state_search_ids: null,
    country_search_ids: null,
    zip_search_text: null,
    show_search_ids: null,
    season_search_ids: null,
    episode_title_search_text: null,
    episode_no_search_text: null,
    gt_search_ids: null,
    fee_per_ep_search_text: null,
    union_search_ids: null,
    services_search_text: null,
    order_by_clause: "Name",
    order_by: null,
    page_count: 25,
    page_no: 1
}