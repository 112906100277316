export const budgetTabJSON = {
    "version_description": null,
    "is_pilot_others": null,
    "is_series": null,
    "unions": [],
    "type_of_use": null,
    "shoot_date": null,
    "recording_date": null,
    "location": null,
    "scene_description": null,
    "version_notes": null,
    "type":null
}
export const mockUnionsRecord = {
    "union_id": null,
    "union_name": null,
    
}