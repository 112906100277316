import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import TabsComponent from "../../SharedComponents/Tabs/Tabs";
import './EditShowDetails.scss';
import 'rc-time-picker/assets/index.css';
import clearTrackService from '../../../services/service';
import EditShowDetailsTabModal from './EditShowDetailsTabModal';
import EditDefaultRights from './EditDefaultRights';
import EditContacts from './EditContacts';
import ShowPilotMaintenance from './ShowPilotMaintenance';
import * as Constants from '../../../constants/constants';
import CircularProgress from "@material-ui/core/CircularProgress";
import MessageModal from "../../SharedComponents/MessageModal"
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicButton from "../../SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../SharedComponents/SelectField/SelectField";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import { withUserContext } from '../../../contexts/UserContext';
import { validateNumbersOnly } from '../../../Common/Helper';
var postObj = [], sum, is_initial_rights_updated = 0, is_optional_rights_updated = 0, is_alt_initial_rights_updated = 0, is_cost_center_updated = 0, canEditDefaultRights, canEditContacts, canEditPilotMaintenance, currentSeasonOptions = [], tempInitialRecord = null;
class EditShowDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPosting: false,
      isLoading: false,
      initialRecordData: {},
      currentSeasonOptions: [],
      productionEntityOptions: [],
      tabList: [],
      tabValue: 1,
      networkOptions: [],
      durationOptions: [],
      prodExecOptions: [],
      costCenterOptions: [],
      termOptions: [],
      territoryOptions: [],
      clearanceRepOptions: [],
      clrRepAsstOptions: [],
      initialRightsOptions: [],
      altInitialRightsOptions: [],
      optionalRightsOptions: [],
      show_season_id: this.props.show_season_id,
      showError: false,
      errMsg: null,
      showMainTiltePopup: false,
      previouslyMtChecked: false,
      creativeRepOptions: [],
      lineProducerOptions:[]
    };
  }
  onCancel = () => {
    this.setState({
      showMainTiltePopup: false
    })
  }
  onNoMtConfirmation = () => {
    this.setState({ showError: false, errMsg: null, isPosting: true, showMainTiltePopup: false });
    this.postSeasonData(postObj);
  }
  handleSubmit = () => {

    postObj = JSON.parse(JSON.stringify(this.state.initialRecordData));
    delete postObj["company"];
    delete postObj["network_name"];
    delete postObj["duration_name"];
    delete postObj["duration_name"];
    delete postObj["seasons"];
    if (postObj?.pilot_maintance?.length > 0) {
      postObj.pilot_maintance[0].show_season_id = this.state?.initialRecordData?.show_season_id;
      postObj.pilot_maintance[0].show_id = this.state?.initialRecordData?.show_id;
      postObj.pilot_maintance[0].season_id = this.state?.initialRecordData?.season_id;
      postObj.pilot_maintance[0].pilot_title = this.state?.initialRecordData?.show_name;
    }
    //if (typeof postObj["initial_term"] === "string") {
    postObj["initial_term"] = postObj["initial_term_id"];
    //}
    //if (typeof postObj["initial_exp_term"] === "string") {
    postObj["initial_exp_term"] = postObj["initial_exp_term_id"];
    //}
    //if (typeof postObj["initial_territory"] === "string") {
    postObj["initial_territory"] = postObj["initial_territory_id"];
    //}
    //if (typeof postObj["alt_initial_term"] === "string") {
    postObj["alt_initial_term"] = postObj["alt_initial_term_id"];
    //}
    if (typeof postObj["alt_initial_exp_term"] === "string") {
      postObj["alt_initial_exp_term"] = postObj["alt_initial_exp_term_id"];
    }
    //if (typeof postObj["alt_initial_territory"] === "string") {
    postObj["alt_initial_territory"] = postObj["alt_initial_territory_id"];
    //}
    let durationTemp = postObj["show_duration_id"];
    postObj["duration_id"] = durationTemp;
    postObj["is_initial_rights_updated"] = this.state.initialRecordData?.is_initial_rights_updated ? this.state.initialRecordData.is_initial_rights_updated : 0;
    postObj["is_optional_rights_updated"] = this.state.initialRecordData?.is_optional_rights_updated ? this.state.initialRecordData.is_optional_rights_updated : 0;
    postObj["is_alt_initial_rights_updated"] = this.state.initialRecordData?.is_alt_initial_rights_updated ? this.state.initialRecordData.is_alt_initial_rights_updated : 0;
    postObj["production_exc"] = postObj["production_exc_id"];
    postObj["clearance_rep"] = postObj["clearance_rep_id"];
    postObj["clearance_rep_asst"] = postObj["clearance_rep_asst_id"];
    let validateOptionalRightsSuccessful = null;
    postObj.optional_rights.map((item, index) => {
      if (item.right_id == "" || item.right_id == null) {
        item.right_id = null;
      }
      if (item.term == "" || item.term == null) {
        item.term = null;
      }
      if (item.exp_term == "" || item.exp_term == null) {
        item.term = null;
      }
      if (item.territory == "" | item.territory == null) {
        item.territory = null;
      }
      delete item["right_abbr"];
      //if (typeof item["term"] === "string") {
      item["term"] = item["term_id"];
      //}
      //if (typeof item["exp_term"] === "string") {
      item["exp_term"] = item["exp_term_id"];
      //}
      //if (typeof item["territory"] === "string") {
      item["territory"] = item["territory_id"];
      //}
      if (item.right_id === null) {
        postObj.optional_rights.splice(index, 1);
      }
    })
    if (postObj.optional_rights.length > 0) {
      postObj.optional_rights.map((item, index) => {
        if (item.right_id !== null && (item.term === null || item.term === undefined || item.territory === null || item.territory === undefined || item.exp_term_id === null || item.exp_term_id === undefined)) {
          validateOptionalRightsSuccessful = false;
        }
      })
    }

    postObj.clearance_budgets.map((item, index) => {
      if (item.cost_center_id == "") {
        item.cost_center_id = null;
      }
      if (item.budget == "") {
        item.budget = null;
      }
      if ((item.budget == "" || item.budget == null) && (item.cost_center_id == "" || item.cost_center_id == null)) {
        postObj.clearance_budgets.splice(index, 1);
      }
      delete item["cost_center_name"];
      delete item["internal_show_season_clearance_budgets_id"];
    })
    if (postObj.defaultFromDuration === false) {
      let from_duration = String(postObj.from_duration.hour) + ":" + String(postObj.from_duration.min) + ":00";
      postObj.from_duration = from_duration;
    }
    if (postObj.defaultToDuration === false) {
      let to_duration = String(postObj.to_duration.hour) + ":" + String(postObj.to_duration.min);
      postObj.to_duration = to_duration;
    }
    if (postObj.defaultFromDuration === true) {
      postObj.from_duration = null;
    }
    if (postObj.defaultToDuration === true) {
      postObj.to_duration = null;
    }

    if (postObj?.mpm?.trim().length == 0) { postObj.mpm = null; }
    if (postObj?.proj_eps?.length == 0) { postObj.proj_eps = null; }
    if (postObj?.notes?.trim().length == 0) { postObj.notes = null; }
    if (postObj.estimating_manager?.trim()?.length == 0) { postObj.estimating_manager = null; }
    if (postObj?.estimator?.trim()?.length == 0) { postObj.estimator = null; }
    if (postObj?.music_amort_code?.trim()?.length == 0) { postObj.music_amort_code = null; }
    if (postObj?.series_amort_code?.trim()?.length == 0) { postObj.series_amort_code = null; }
    if (postObj?.pilot_maintance[0]?.is_this_not_pilot === null) {
      postObj.pilot_maintance[0].is_this_not_pilot = 0;
    }
    let isValidJson = false;
    isValidJson = postObj?.show_id && postObj?.show_name && postObj?.show_season_id && postObj?.show_type && postObj?.season_id &&
      postObj?.production_entity_id &&
      (postObj?.mpm ? validateNumbersOnly(postObj?.mpm) : true) &&
      //(postObj?.clearance_budgets?.length > 0 ? postObj?.clearance_budgets?.every(item => item?.cost_center_id && item?.budget) : true) &&
      (canEditDefaultRights ? (postObj?.initial_right_id && postObj?.initial_term_id && postObj?.initial_territory_id) : true) &&
      (canEditContacts ? (postObj?.production_exc_id && postObj?.clearance_rep_id) : true) &&
      ((canEditPilotMaintenance && postObj?.is_pilot === 1) ? (postObj?.pilot_maintance[0]?.is_this_not_pilot === 1 ? postObj?.pilot_maintance[0]?.customize_title : true) : true)
    // isValidJson = postObj?.show_id && postObj?.show_name && postObj?.show_season_id && postObj?.show_type && postObj?.season_id &&
    //   postObj?.production_entity_id &&
    //   (postObj?.mpm ? validateNumbersOnly(postObj?.mpm) : true) &&
    //   (postObj?.clearance_budgets?.length > 0 ? postObj?.clearance_budgets?.every(item => item?.cost_center_id && item?.budget) : true) &&
    //   (canEditDefaultRights && (postObj?.initial_right_id && postObj?.initial_term_id && postObj?.initial_territory_id) || true) &&
    //   (canEditContacts && (postObj?.production_exc_id && postObj?.clearance_rep_id) || true) &&
    //   (canEditPilotMaintenance && (postObj?.is_this_not_pilot === 1 && postObj?.customize_title) || true);

    if (isValidJson) {
      if (postObj?.no_mt == 1 && !this.state.previouslyMtChecked) {
        this.setState({ showMainTiltePopup: true })
      } else {
        this.setState({ showError: false, errMsg: null, isPosting: true });
        this.postSeasonData(postObj);
      }
    }
    else {
      this.setState({ showError: true, errMsg: `Please fill all the mandatory fields.`, isPosting: false }, () => {
        if(canEditContacts ?  !(postObj?.initial_right_id && postObj?.initial_term_id && postObj?.initial_territory_id) : false) {
          this.setState({tabValue:2})
        } else if( canEditContacts ? !(postObj?.production_exc_id && postObj?.clearance_rep_id) : false) {
          this.setState({tabValue:3})
        } else if((canEditPilotMaintenance && postObj?.is_pilot === 1) ? (postObj?.pilot_maintance[0]?.is_this_not_pilot ? !postObj?.pilot_maintance[0]?.customize_title :false) : false) {
          this.setState({tabValue:4}) 
        } else {
          this.setState({tabValue:1}) 
        }
      })
    }
    // if ((canEditDefaultRights === false || (((postObj.initial_right_id !== null && postObj.initial_term_id !== null && postObj.initial_territory_id !== null) || (postObj.initial_right_id === null && postObj.initial_term_id === null && postObj.initial_territory_id === null)) &&
    //   ((postObj.alt_initial_right_id !== null && postObj.alt_initial_term_id !== null && postObj.alt_initial_territory_id !== null) || (postObj.alt_initial_right_id === null && postObj.alt_initial_term_id === null && postObj.alt_initial_territory_id === null)) &&
    //   validateOptionalRightsSuccessful === null)) &&
    //   (canEditContacts === false || (postObj?.production_exc_id !== null && postObj?.estimator !== null && postObj?.clearance_rep_id !== null)) && 
    //   (canEditPilotMaintenance === false || postObj?.is_pilot === 0 || (postObj?.pilot_maintance[0]?.is_this_not_pilot === 1 && postObj?.pilot_maintance[0]?.customize_title) || (postObj?.pilot_maintance[0]?.is_this_not_pilot === 0)) &&
    //   (postObj?.mpm && validateNumbersOnly(postObj?.mpm)) && postObj?.season_id) {
    //   this.setState({ showError: false, errMsg: null, isPosting: true });
    //   this.postSeasonData(postObj);
    // } else {
    //   this.setState({ showError: true, errMsg: `Please fill all the mandatory fields.`, isPosting: false });
    // }
  };
  getInitialData(initialData) {
    this.setState({ isLoading: false });
    // for time values in timepicker
    if (initialData.from_duration !== null) {
      // let from_duration = initialData?.from_duration?.split(":");
      // initialData["from_duration"] = { "hour": Number(from_duration[0]), "min": Number(from_duration[1]) }
      initialData["defaultFromDuration"] = false;
    }
    if (initialData.to_duration !== null) {
      // let to_duration = initialData?.to_duration?.split(":");
      // initialData["to_duration"] = { "hour": Number(to_duration[0]), "min": Number(to_duration[1]) }
      initialData["defaultToDuration"] = false;
    }
    if (initialData.from_duration === null) {
      // const d = new Date();
      // let from_hour = d.getHours();
      // let from_min = d.getMinutes();
      initialData["from_duration"] = { "hour": null, "min": null }
      initialData["defaultFromDuration"] = true;
    }
    if (initialData.to_duration === null) {
      // const d1 = new Date();
      // let to_hour = d1.getHours();
      // let to_min = d1.getMinutes();
      initialData["to_duration"] = { "hour": null, "min": null }
      initialData["defaultToDuration"] = true;
    }

    if (initialData.optional_rights.length == 0) {
      initialData.optional_rights.push({
        "show_season_rights_id": null,
        "right_id": "",
        "term": "",
        "exp_term": "",
        "territory": "",
        "incl_init": 0,
        "is_initial_right": null,
        "is_optional": 1,
        "is_alt_initial_right": null
      })
    }
    if (initialData.clearance_budgets.length == 0) {
      initialData.clearance_budgets.push({
        "internal_show_season_clearance_budgets_id": null,
        "show_season_clearance_budgets_id": null,
        "cost_center_id": null,
        "cost_center_name": null,
        "budget": null,
        "sum": 0
      })
    }
    initialData.clearance_budgets.length > 0 && initialData.clearance_budgets.map((item, index) => initialData.clearance_budgets.internal_show_season_clearance_budgets_id = item?.show_season_clearance_budgets_id ? item?.show_season_clearance_budgets_id : Date.now());
    currentSeasonOptions = initialData?.seasons.map((item, index) => ({ value: item.season_id, label: item.season_name }));
    this.setState({
      initialRecordData: initialData
    });
  }
  fetchData = (field, url) => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
      .then(response => {
        let formattedList = [];
        switch (field) {
          case 'getInitialValues':

            formattedList = JSON.parse(JSON.stringify(response.data[0]));
            tempInitialRecord = JSON.parse(JSON.stringify(response.data[0]));
            // for time values in timepicker
            if (formattedList.from_duration !== null) {
              let from_duration = formattedList?.from_duration?.split(":");
              formattedList["from_duration"] = { "hour": Number(from_duration[0]), "min": Number(from_duration[1]) }
              formattedList["defaultFromDuration"] = false;
            }
            if (formattedList.to_duration !== null) {
              let to_duration = formattedList?.to_duration?.split(":");
              formattedList["to_duration"] = { "hour": Number(to_duration[0]), "min": Number(to_duration[1]) }
              formattedList["defaultToDuration"] = false;
            }
            if (formattedList.from_duration === null) {
              // const d = new Date();
              // let from_hour = d.getHours();
              // let from_min = d.getMinutes();
              formattedList["from_duration"] = { "hour": null, "min": null }
              formattedList["defaultFromDuration"] = true;
            }
            if (formattedList.to_duration === null) {
              // const d1 = new Date();
              // let to_hour = d1.getHours();
              // let to_min = d1.getMinutes();
              formattedList["to_duration"] = { "hour": null, "min": null }
              formattedList["defaultToDuration"] = true;
            }

            if (formattedList.optional_rights.length == 0) {
              formattedList.optional_rights.push({
                "show_season_rights_id": null,
                "right_id": "",
                "term": "",
                "exp_term": "",
                "territory": "",
                "incl_init": 0,
                "is_initial_right": null,
                "is_optional": 1,
                "is_alt_initial_right": null
              })
            }
            if (formattedList.clearance_budgets.length === 0) {

              formattedList.clearance_budgets.push({
                "internal_show_season_clearance_budgets_id": null,
                "show_season_clearance_budgets_id": null,
                "cost_center_id":  null,
                "cost_center_name":  null,
                "budget": null,
                "sum": 0
              })
            }
            formattedList.clearance_budgets.length > 0 && formattedList.clearance_budgets.map((item, index) => formattedList.clearance_budgets[index].internal_show_season_clearance_budgets_id = item?.show_season_clearance_budgets_id ? item?.show_season_clearance_budgets_id : Date.now());

            currentSeasonOptions = formattedList?.seasons.map((item, index) => ({ value: item.season_id, label: item.season_name, rights_discount: item.rights_discount }));
            this.setState({
              initialRecordData: JSON.parse(JSON.stringify(formattedList)),
              seasonOptions: currentSeasonOptions,
              previouslyMtChecked: formattedList?.no_mt == 1 ? true : false
            }, () => {
              this.getPermissions(this.props.permissionArr);
              this.fetchData("costCenterOptions", `/entitySearch?entity=COST_CENTER&searchString=null`);
            });
            break;
          case 'allSeasonOptions':
            let allSeasonOptions = response.data?.map(item => ({ value: item.season_id, label: item.season_name, is_active: item.is_active }));
            let deltaOptions = allSeasonOptions.filter(object1 => {
              return !currentSeasonOptions.some(object2 => {
                return object1.value === object2.value;
              });
            });
            let selectedSeason = allSeasonOptions.filter(obj => obj.value === this.state.initialRecordData?.season_id);
            if (selectedSeason.length > 0) {
              deltaOptions.push(selectedSeason[0]);
            }
            this.setState({
              seasonOptions: deltaOptions
            });
            break;
          case 'productionEntityOptions':
            formattedList = response.data?.map(item => ({ value: item.production_entity_id, label: item.company, is_active: item.is_active }));
            if (formattedList.length == 0) {
              formattedList.push({ value: "", label: "no records found", is_active: 0 })
            }
            break;
          case 'networkOptions':
            formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
            if (formattedList.length == 0) {
              formattedList.push({ value: "", label: "no records found", is_active: 0 })
            }
            break;
          case 'durationOptions':
            formattedList = response.data?.map(item => ({ value: item.show_duration_id, label: item.show_duration, is_active: item.is_active, minutes: item.minutes }));
            if (formattedList.length == 0) {
              formattedList.push({ value: "", label: "no records found", is_active: 0 })
            }
            break;
          case 'prodExecOptions':
            formattedList = response.data?.map(item => ({ value: item.production_executive_id, label: item.production_executive_name, is_active: item.is_active }));
            if (formattedList.length == 0) {
              formattedList.push({ value: "", label: "no records found", is_active: 0 })
            }
            break;
          case 'rightsOptions':
            //for initial and alt initial rights options
            let initialRightsOptions = [];
            let initialObject = {}
            initialObject.initial_right_id = this.state.initialRecordData?.initial_right_id
            initialObject.initial_right_abbr =  this.state.initialRecordData?.initial_right_abbr
            initialObject.initial_term_id = this.state.initialRecordData?.initial_term_id
            initialObject.initial_term = this.state.initialRecordData?.initial_term_name
            initialObject.initial_territory_id =  this.state.initialRecordData?.initial_territory_id
            initialObject.initial_territory = this.state.initialRecordData?.initial_territory_abbr

            let initialRights = response.data?.filter(
              (right) => (right?.is_initial_right === 1 || right?.can_be_included_with_init_right === 1)
            );

            initialRights?.map((item, index) => {
              // if (item.is_initial_right == 1)
              //  { 
              initialRightsOptions.push({ value: item.right_id, label: item.right_abbr, is_active: item.is_active, default_term_id: item.default_term_id, default_term_name: item.default_term_name, default_territory_id: item.default_territory_id, default_territory_abbr: item.default_territory_abbr })


              if(item?.is_default === 1 && !this.state.initialRecordData?.initial_right_id){
                initialObject.initial_right_id = item.right_id
                initialObject.initial_right_abbr =  item.right_abbr
                initialObject.initial_term_id = item.default_term_id
                initialObject.initial_term = item.default_term_name
                initialObject.initial_territory_id =  item.default_territory_id
                initialObject.initial_territory = item.default_territory_abbr
              }
              //}
            });
            if (initialRightsOptions.length == 0) {
              initialRightsOptions.push({ value: "", label: "no records found", is_active: 0 })
            }
            //for optional rights options
            let optionalRightsOptions = [];
            let optRights = response.data?.filter(
              (right) => (right?.is_optional === 1)
            );
            optRights?.map((item, index) => {
              // if (index === 1) {
              //   console.log("item optionalRightsOptions", item)
              // }
              // if (item.is_optional == 1) {
              optionalRightsOptions.push({ value: item.right_id, label: item.right_abbr, is_active: item.is_active, default_term_id: item.default_term_id, default_term_name: item.default_term_name, default_territory_id: item.default_territory_id, default_territory_abbr: item.default_territory_abbr, default_exp_term_id: item.default_exp_term_id, default_exp_term_name: item.default_exp_term_name })
              //}
            });

            if (optionalRightsOptions.length == 0) {
              optionalRightsOptions.push({ value: "", label: "no records found", is_active: 0 })
            }
            let altinitialRights = response.data?.filter(
              (right) => (right?.can_be_alt_init_right === 1)
            );
            let altInitialRightsOptions=[]
            altinitialRights?.map((item, index) => {
              // if (index === 1) {
              //   console.log("item optionalRightsOptions", item)
              // }
              // if (item.is_optional == 1) {
              altInitialRightsOptions.push({ value: item.right_id, label: item.right_abbr, is_active: item.is_active, default_term_id: item.default_term_id, default_term_name: item.default_term_name, default_territory_id: item.default_territory_id, default_territory_abbr: item.default_territory_abbr, default_exp_term_id: item.default_exp_term_id, default_exp_term_name: item.default_exp_term_name })
              //}
            });
            this.setState((prevState) => ({
              initialRecordData : {
                ...prevState.initialRecordData,
                ...initialObject
              },
              initialRightsOptions: initialRightsOptions,
              optionalRightsOptions: optionalRightsOptions,
              altInitialRightsOptions:altInitialRightsOptions

            }));
            break;
          case 'costCenterOptions':
            let defaultCostCenterId, defaultCostCenterName = "4646-PROD";
            formattedList = response.data?.map(item => {
              if (item.cost_center_name === defaultCostCenterName) defaultCostCenterId = item?.cost_center_id;
              return { value: item.cost_center_id, label: item.cost_center_name, is_active: item.is_active }
            });
            if (defaultCostCenterId && this.state?.initialRecordData?.clearance_budgets?.length == 1 && this.state?.initialRecordData?.clearance_budgets?.[0]?.cost_center_id == null) {
              let clearanceBudgets = [...this.state.initialRecordData?.clearance_budgets];
              clearanceBudgets[0].cost_center_id = defaultCostCenterId;
              clearanceBudgets[0].cost_center_name = defaultCostCenterName;
              clearanceBudgets[0].sum = 1;
              this.setState(prevState => ({
                initialRecordData: {
                  ...prevState.initialRecordData,
                  clearance_budgets: clearanceBudgets
                }
              }))
            }
            if (formattedList.length == 0) {
              formattedList.push({ value: "", label: "no records found", is_active: 0 })
            }
           
            break;
          // case 'clearanceRepOptions':
          //   formattedList = response.data?.map(item => ({ value: item.user_id, label: item.full_name, is_active: item.is_active }));
          //   break;
          case 'termOptions':
            formattedList = response.data?.map(item => ({ value: item.term_id, label: item.term_name, is_active: item.is_active }));
            if (formattedList.length == 0) {
              formattedList.push({ value: "", label: "no records found", is_active: 0 })
            }
            break;
          case 'territoryOptions':
            formattedList = response.data?.map(item => ({ value: item.territory_id, label: item.territory_abbr, is_active: item.is_active }));
            if (formattedList.length == 0) {
              formattedList.push({ value: "", label: "no records found", is_active: 0 })
            }
            break;
        }
        if (field != "getInitialValues" && field != 'rightsOptions') {
          this.setState((prevState) => ({
            [field]: formattedList
          }));
        }
      },
        (err) => {
          this.setState({ isLoading: false })
          console.log("Error in fetching Address Types:", err)
        })
  }
  postSeasonData = (postJson) => {
    this.setState({ isPosting: true });
    let extensionUrl = '/showSeason';
    clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, this.props.userContext?.active_tenant?.tenant_id, this.state.initialRecordData.division_id)
      .then((response) => {
        if (response.data.error) {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
        } else {
          this.setState({ isPosting: false });
          this.props.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
          this.props?.notificationComponent(true, "success");
        }
      },
        (err) => {
          this.setState({ isPosting: false });
          this.props?.notificationComponent(true, "fail");
        });
  }

  handleTimeChange = (field, value, keyToModify) => {
    if (value !== null) {
      let flagToChange;
      if (keyToModify === "from_duration") {
        flagToChange = "defaultFromDuration";
      }
      if (keyToModify === "to_duration") {
        flagToChange = "defaultToDuration";
      }
      this.setState((prevState) => ({
        initialRecordData: {
          ...prevState.initialRecordData,
          [keyToModify]: { "hour": value._d.getHours(), "min": value._d.getMinutes() },
          [flagToChange]: false
        }
      }));
    } else {
      if (keyToModify === "from_duration") {
        // const d = new Date();
        // let from_hour = d.getHours();
        // let from_min = d.getMinutes();
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [keyToModify]: { "hour": null, "min": null },
            ["defaultFromDuration"]: true
          }
        }))
      }
      if (keyToModify === "to_duration") {
        // const d = new Date();
        // let from_hour = d.getHours();
        // let from_min = d.getMinutes();
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [keyToModify]: { "hour": null, "min": null },
            ["defaultFromDuration"]: true
          }
        }))
      }
    }
  }
  // for text fields change event
  handleChange = (field, value, index, objPassed) => {
    switch (field) {
      case 'budget':
        let budgetArr = this.state.initialRecordData.clearance_budgets;
        var budget_obj = budgetArr.filter((obj) => (obj.internal_show_season_clearance_budgets_id === objPassed.internal_show_season_clearance_budgets_id) ? obj : null);
        budget_obj[0][field] = Number(value);
        budgetArr.splice(index, 1, budget_obj[0]);
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            ["clearance_budgets"]: budgetArr
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [field]: value
          },
        }));
        break;
    }
  }

  onCheckboxChange = (checkboxKey, checkedValue, index, objPassed, parentKey) => {
    var val = (checkedValue == true) ? 1 : 0;
    switch (checkboxKey) {
      case 'incl_init':
        let optionRightsArr = this.state.initialRecordData.optional_rights;
        optionRightsArr[index]['incl_init'] = val;
        is_optional_rights_updated = 1;
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [parentKey]: optionRightsArr
          }
        }));
        break;
      case 'sum':
        let budgetArr = this.state.initialRecordData.clearance_budgets;
        var budget_obj = budgetArr.filter((obj) => (obj.internal_show_season_clearance_budgets_id === objPassed.internal_show_season_clearance_budgets_id) ? obj : null);
        budget_obj[0]["sum"] = val;
        budgetArr.splice(index, 1, budget_obj[0]);
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            ["clearance_budgets"]: budgetArr
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [checkboxKey]: val
          },
        }));
        break;
    }
  }


  addBudgetRow() {
    let budget_arr = this.state.initialRecordData.clearance_budgets;
    budget_arr.splice(this.state.initialRecordData.clearance_budgets.length, 0, {
      "internal_show_season_clearance_budgets_id": this.state.initialRecordData.clearance_budgets.length,
      "show_season_clearance_budgets_id": null,
      "cost_center_id": "",
      "cost_center_name": "",
      "budget": 0,
      "sum": 0
    });

    this.setState((prevState) => ({
      initialRecordData: {
        ...prevState.initialRecordData,
        ["clearance_budgets"]: budget_arr
      }

    }));
  }
  removeBudgetRow(index) {
    let budget_arr = this.state.initialRecordData.clearance_budgets;
    budget_arr.splice(index, 1);
    this.setState((prevState) => ({
      initialRecordData: {
        ...prevState.initialRecordData,
        ["clearance_budgets"]: budget_arr
      }
    }));
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.state) {
      return true;
    }
    else { return false; }
  }
  onDropdownChange(whatChanged, parentKey, index, keyToModify, valPassed, objPassed) {
    switch (whatChanged) {
      case 'optionalRightsChanged':
        let rightsArr = this.state.initialRecordData.optional_rights;
        objPassed[keyToModify] = valPassed;
        rightsArr.splice(index, 1, objPassed);
        is_optional_rights_updated = 1;
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [parentKey]: rightsArr
          }
        }));
        break;
      case 'initialRightsChanged':
        is_initial_rights_updated = 1;
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [keyToModify]: valPassed
          },
        }));
        break;
      case 'altInitialRightsChanged':
        is_alt_initial_rights_updated = 1;
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [keyToModify]: valPassed
          },
        }));
        break;
      case 'costCenterChanged':
        let budgetArr = this.state.initialRecordData.clearance_budgets;
        objPassed["cost_center_id"] = valPassed;
        budgetArr.splice(index, 1, objPassed);
        is_cost_center_updated = 1;
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            ["clearance_budgets"]: budgetArr
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          initialRecordData: {
            ...prevState.initialRecordData,
            [keyToModify]: valPassed
          },
        }));
        break;
    }
  }

  getTabComponent = () => {
    switch (this.state.tabValue) {
      case 1:
        return <EditShowDetailsTabModal showError={this.state?.showError} permissionArr={this.props.permissionArr} initialRecordData1={this.state?.initialRecordData} productionEntityOptions={this.state.productionEntityOptions} networkOptions={this.state.networkOptions} durationOptions={this.state.durationOptions} costCenterOptions={this.state.costCenterOptions} seasonOptions={this.props.origin === "dashboard" ? this.state.seasonOptions : this.props.seasonOptions} getChildDetails={this.getChildDetails.bind(this)} {...this.props} />;
      case 2:
        return <EditDefaultRights initialRecordData1={this.state?.initialRecordData} initialRightsOptions={this.state.initialRightsOptions} optionalRightsOptions={this.state.optionalRightsOptions} termOptions={this.state.termOptions} territoryOptions={this.state.territoryOptions} getChildDetails={this.getChildDetails.bind(this)} {...this.props} altInitialRightsOptions={this.state.altInitialRightsOptions} />;
      case 3:
        return <EditContacts initialRecordData={this.state?.initialRecordData} prodExecOptions={this.state.prodExecOptions} clearanceRepOptions={this.state.clearanceRepOptions} clrRepAsstOptions={this.state.clrRepAsstOptions} lineProducerOptions={this.state.lineProducerOptions} creativeRepOptions={this.state.creativeRepOptions} getChildDetails={this.getChildDetails.bind(this)} {...this.props} />;
      case 4:
        return <ShowPilotMaintenance initialRecordData1={this.state?.initialRecordData} show_season_id={this.props?.show_season_id} networkOptions={this.state.networkOptions} getChildDetails={this.getChildDetails.bind(this)} seasonOptions={this.props.origin === "dashboard" ? this.state.seasonOptions : this.props.seasonOptions} {...this.props} />;
    }
  };
  handleTabChange = (newTabValue, type) => {
    this.setState({
      [type]: newTabValue,
    });
  };
  getDashboardData = (initialRecordData) => {
    this.setState({ initialRecordData: initialRecordData });
  }
  getChildDetails = (initialRecordData) => {
    if (this.props.origin === "dashboard") {
      this.getDashboardData(initialRecordData);
    }
    if (this.props.origin === "showSeasonDetails") {
      this.props.getChildDetails(initialRecordData);
    }
  }
  render() {
    let message = "All the clerance record related to this main title will be deleted permanently!!";
    let comfirmModalTitle = "Confirmation";
    return (
      <div>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <MDBContainer fluid className="EditShowDetailsModalContainer">
            {this.state.initialRecordData?.show_season_id !== undefined && <>
              <div>
                {this.state.showError && <p className="errorMessage">{this.state.errMsg}</p>}
                <MessageModal
                  open={this.state.showMainTiltePopup || false}
                  title={comfirmModalTitle}
                  message={message}
                  hideCancel={false}
                  primaryButtonText={"OK"}
                  secondaryButtonText={"Cancel"}
                  onConfirm={() => this.onNoMtConfirmation()}
                  handleClose={() => this.onCancel()} />
                <MDBRow className="tab-content">
                  <MDBCol md={10}>
                    <TabsComponent
                      tabList={this.state.tabList}
                      value={this.state.tabValue}
                      onChange={(e, v) => this.handleTabChange(v, "tabValue")}
                    />
                  </MDBCol>
                </MDBRow>
              </div>
              <div>
                {this.getTabComponent()}
                <MDBRow className="mt-4">
                  <MDBCol md={10}></MDBCol>
                  <MDBCol md={2} className="saveCancelWrapper" >
                    <BasicButton text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                      onClick={this.handleSubmit}
                    />
                    <BasicButton
                      onClick={() => this.props.handleClose()}
                      variant="outlined"
                      text={"Cancel"}
                    />
                  </MDBCol>
                </MDBRow>
              </div>
            </>}
          </MDBContainer>
        )}
      </div>
    );
  }
  getPermissions(permissions) {
    let tabList = [];
    if (permissions.length > 0) {
      canEditDefaultRights = permissions.filter((obj, index) => (obj.permission_name === "Edit Show Details - Default Rights tab" && (obj.view === 1 && obj.edit === 1)));
      canEditDefaultRights = canEditDefaultRights.length > 0 ? true : false;
      canEditContacts = permissions.filter((obj, index) => (obj.permission_name === "Edit Show Details - Contacts tab" && (obj.view === 1 && obj.edit === 1)));
      canEditContacts = canEditContacts.length > 0 ? true : false;
      canEditPilotMaintenance = permissions.filter((obj, index) => (obj.permission_name === "Edit Show Details - Pilot Maintenance" && (obj.view === 1 && obj.edit === 1)));
      canEditPilotMaintenance = canEditPilotMaintenance.length > 0 ? true : false;

      if (this.state.initialRecordData?.is_pilot !== undefined && this.state.initialRecordData?.is_pilot === 1) {
        tabList = [
          {
            label: "Show Season Details",
            value: 1,
          }
        ]
        canEditDefaultRights === true ? tabList.splice(1, 0, { label: "Default Rights", value: 2 }) : null;
        canEditContacts === true ? tabList.splice(2, 0, { label: "Contacts", value: 3 }) : null;
        (canEditPilotMaintenance === true ? tabList.splice(3, 0, { label: "Pilot Maintenance", value: 4 }) : null);
        this.setState({ tabList: tabList, isLoading: false });
      }
      if (this.state.initialRecordData?.is_pilot === undefined && tempInitialRecord?.is_pilot === 1) {
        tabList = [
          {
            label: "Show Season Details",
            value: 1,
          }
        ];
        (canEditDefaultRights === true ? tabList.splice(1, 0, { label: "Default Rights", value: 2 }) : null);
        (canEditContacts === true ? tabList.splice(2, 0, { label: "Contacts", value: 3 }) : null);
        (canEditPilotMaintenance === true ? tabList.splice(3, 0, { label: "Pilot Maintenance", value: 4 }) : null);
        this.setState({ tabList: tabList, isLoading: false });
      }
      if ((this.state.initialRecordData?.is_pilot === undefined && tempInitialRecord?.is_pilot === 0) || (this.state.initialRecordData?.is_pilot !== undefined && this.state.initialRecordData?.is_pilot === 0)) {
        tabList = [
          {
            label: "Show Season Details",
            value: 1,
          },
        ];
        (canEditDefaultRights === true ? tabList.splice(1, 0, { label: "Default Rights", value: 2 }) : null);
        (canEditContacts === true ? tabList.splice(2, 0, { label: "Contacts", value: 3 }) : null);
        this.setState({ tabList: tabList, isLoading: false });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.origin !== "dashboard") {
      if (this.props.initialRecordData !== prevProps.initialRecordData) {
        this.setState({ initialRecordData: this.props.initialRecordData });
        this.getPermissions(this.props.permissionArr);
        this.getTabComponent();
      }
    }
    if (this.props.origin === "dashboard") {
      if (this.props.initialRecordData !== prevProps.initialRecordData) {
        this.getPermissions(this.props.permissionArr);
        this.getTabComponent();
      }
    }
  }
  getEntity = () => {
    let allUsers  = []
    clearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + '/userAll', this.props.userContext?.active_tenant?.tenant_id, null).then((response) => {
        let validClearanceRepRoles = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Regular User)', 'Music Clearance (Basic)']
        let lineProducerRoles = ['Line Producer']
        let creativeRepRoles = ['Music Creative (Manager)', 'Music Creative (Regular User)']
        let validClearanceRepUsers = []
        let clearanceRepAsstRoles = []
        let validClrRepAsstUsers = []
        let validCreativeRepUsers = []
        let validLineProducerUsers = []
        allUsers = [...response?.data]
        for (let i = 0; i < response?.data?.length; i++) {
          for (let j = 0; j < response?.data[i]?.tenants?.length; j++) {
            if (response?.data[i]?.tenants[j]?.tenant_id === this.props.userContext?.active_tenant?.tenant_id) {
              for (let k = 0; k < response?.data[i]?.tenants[j]?.division_role?.length; k++) {
                if (response?.data[i]?.tenants[j]?.division_role[k]?.division_id === this.props.division_id) {
                  if (validClearanceRepRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                    validClearanceRepUsers.push({ value: response?.data[i].user_id, label: response?.data[i].full_name, is_active: response?.data[i].is_active })
                    if (response?.data[i]?.assistant_id) {
                      clearanceRepAsstRoles.push(response?.data[i]?.assistant_id)
                    }
                  }
                  if (lineProducerRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                    validLineProducerUsers.push({ value: response?.data[i].user_id, label: response?.data[i].full_name, is_active: response?.data[i].is_active })
                  }
                  if (creativeRepRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                    validCreativeRepUsers.push({ value: response?.data[i].user_id, label: response?.data[i].full_name, is_active: response?.data[i].is_active })
                  }
                }
              }
            }
          }
        }
        validClrRepAsstUsers = response?.data?.filter?.(item => clearanceRepAsstRoles?.includes(item?.user_id))
        let formatvalidClrRepAsstUsers = validClrRepAsstUsers?.map(item => ({ value: item.user_id, label: item.full_name, is_active: item.is_active }))
        if(this.state.initialRecordData?.clearance_rep_id && !validClearanceRepUsers?.find(item => item?.value === this.state.initialRecordData?.clearance_rep_id)) {
          validClearanceRepUsers.push({value: this.state.initialRecordData?.clearance_rep_id, label: this.state.initialRecordData?.clearance_rep, is_active: allUsers?.find(item => item?.user_id === this.state.initialRecordData?.clearance_rep_id )?.is_active})
        }
        if(this.state.initialRecordData?.clearance_rep_asst_id && !formatvalidClrRepAsstUsers?.find(item => item?.value === this.state.initialRecordData?.clearance_rep_asst_id)) {
          formatvalidClrRepAsstUsers.push({value: this.state.initialRecordData?.clearance_rep_asst_id, label: this.state.initialRecordData?.clearance_rep_asst, is_active: allUsers?.find(item => item?.user_id === this.state.initialRecordData?.clearance_rep_asst_id )?.is_active})
        }
        if(this.state.initialRecordData?.creative_rep_id && !validCreativeRepUsers?.find(item => item?.value === this.state.initialRecordData?.creative_rep_id)) {
          validCreativeRepUsers.push({value: this.state.initialRecordData?.creative_rep_id, label: this.state.initialRecordData?.creative_rep, is_active: allUsers?.find(item => item?.user_id === this.state.initialRecordData?.creative_rep_id )?.is_active})
        }
        if(this.state.initialRecordData?.line_producer_id && !validLineProducerUsers?.find(item => item?.value === this.state.initialRecordData?.line_producer_id)) {
          validLineProducerUsers.push({value: this.state.initialRecordData?.line_producer_id, label: this.state.initialRecordData?.line_producer, is_active: allUsers?.find(item => item?.user_id === this.state.initialRecordData?.line_producer_id )?.is_active})
        }
        this.setState({ clearanceRepOptions: validClearanceRepUsers, clrRepAsstOptions: formatvalidClrRepAsstUsers, creativeRepOptions: validCreativeRepUsers, lineProducerOptions: validLineProducerUsers });
      });
  };
  componentDidMount() {
    this.setState({ isLoading: true });

    // if (this.props.origin === "dashboard") {
    this.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
    // } else {
    //   this.getInitialData(this.props.initialRecordData);
    // }
    this.fetchData("productionEntityOptions", `/entitySearch?entity=PRODUCTION_ENTITY&searchString=null`);
    this.fetchData("networkOptions", `/staticData?staticTable=lu_network`);
    this.fetchData("durationOptions", `/entitySearch?entity=SHOW_DURATIONS&searchString=null`);
    this.fetchData("prodExecOptions", `/entitySearch?entity=PRODUCTION_EXECUTIVE&searchString=null`);
    this.fetchData("rightsOptions", `/entitySearch?entity=RIGHTS&searchString=null`);
    //this.fetchData("clearanceRepOptions", `/entitySearch?entity=USER&searchString=null`);
    this.getEntity();
    this.fetchData("termOptions", `/entitySearch?entity=TERM&searchString=null`);
    this.fetchData("territoryOptions", `/entitySearch?entity=TERRITORY&searchString=null`);



    // this.fetchData("allSeasonOptions", `/entitySearch?entity=SEASON&searchString=null`);
  }
}
export default withUserContext(EditShowDetails);
