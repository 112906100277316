import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import TabsComponent from "../SharedComponents/Tabs/Tabs";
import { letterRequestTabList } from "./config";
import { withUserContext } from '../../contexts/UserContext';
import IssueToLanguage from './IssueToLanguage';
import UserDefined from "./UserDefined";

class LetterRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      isLetterFlag: true,
    };
  }

  handleTabChange = (newTabValue) => {
    this.setState({
      tabValue: newTabValue,
    });
  };

  getTabComponent = () => {
    switch (this.state.tabValue) {
      case 1:
        return <IssueToLanguage notificationComponent={this.props.notificationComponent} description={this.props.description} {...this.props}/>
        break;
      case 2:
        return <UserDefined notificationComponent={this.props.notificationComponent} isLetterFlag={this.state.isLetterFlag} description={this.props.description} {...this.props} />
    }
  };
 
  render() {
    return (
      <div className="letterSetup-Container">
        <div>
          <MDBRow className="tab-content">
            <MDBCol md={12}>
              <TabsComponent
                tabList={letterRequestTabList}
                value={this.state.tabValue}
                onChange={(e, v) => this.handleTabChange(v)}
              />
            </MDBCol>
          </MDBRow>
        </div>
        {this.getTabComponent()}
      </div>
    );
  }
}

export default withUserContext(LetterRequests);
