import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import DateField from '../../SharedComponents/DateField/DateField';
import CircularProgress from "@material-ui/core/CircularProgress";
import './Budget.scss';
import Radio from "@material-ui/core/Radio";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import moment from 'moment';
import messages from '../../../Common/Messages.json';
let postObj = [];
class ClearanceBudgetModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initialRecordData: this.props.initialRecordData,
            isPosting: false,
            errorFlag: false,
            errorText: null
        }
    }
    handleRadioChange(e) {
        this.setState((prevState) => ({
            initialRecordData: {
                ...prevState.initialRecordData,
                ["apply_to"]: e.target.value
            }
        }))
    }
    handleChange(field, value) {
        if (field === "marketing_cost") {
            if (value.trim().length === 0) {
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["marketing_cost"]: "$0.00"
                    },
                }));
            } else {
                this.setState((prevState) => ({
                    initialRecordData: {
                        ...prevState.initialRecordData,
                        ["marketing_cost"]: value
                    },
                }));
            }
        } else {
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    [field]: value
                },
            }));
        }

    }
    postData = (postJson) => {
        console.log("postJson", postJson);
        this.setState({ isPosting: true })
        let extensionUrl = '/showSeasonPilotHeader'
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + extensionUrl, postJson, 1, 1)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isPosting: false })
                    this.props?.notificationComponent("error");
                } else {
                    this.setState({ isPosting: false })
                    this.props?.notificationComponent("success");
                    // this.props.fetchPilotMainDetails(postJson);
                    this.props.fetchModalDetails("getInitialValues", `/showSeason?showSeasonId=${this.props?.show_season_id}`)
                    this.props?.handleClose();
                }
            },
                (err) => {
                    this.setState({ isPosting: false })
                    this.props?.notificationComponent("error");
                    console.log("Post agency details error: " + err);
                });
    }
    handleSubmit = () => {
        postObj = { ...this.state.initialRecordData };
        // postObj.script_date = this.state.initialRecordData.script_date.length
        if (postObj?.marketing_cost?.length > 0 && postObj?.script_date) {
            this.setState({ errorFlag: false, errorText: null });
            let tempMarketingCost = postObj?.marketing_cost?.slice(1);
            postObj.marketing_cost = Number(tempMarketingCost);
            this.postData(postObj);
        } else {
            this.setState({ errorFlag: true, errorText: "Please enter mandatory fields" });
        }
    }
    render() {
        return (
            <div className="pilotModal">
                <MDBRow>
                    <div>
                        {this.state.errorFlag && <p className="errorMessage">{this.state.errorText}</p>}
                        <BasicLabel text={"Apply To"} />
                    </div>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={6} className="radioBtnWrapper">
                        {this.state.initialRecordData?.customize_title !== null ?
                            <><Radio name="radio-buttons" checked={this.state.initialRecordData?.apply_to === this.state.initialRecordData?.customize_title || this.state.initialRecordData?.apply_to === 'PILOT'} onChange={this.handleRadioChange.bind(this)} value={this.state.initialRecordData?.customize_title ? this.state.initialRecordData?.customize_title : 'PILOT'} />
                                <span className='radio-pilot-font'>{this.state.initialRecordData?.customize_title ? this.state.initialRecordData?.customize_title : 'Pilot'}</span></> :
                            <><Radio name="radio-buttons" checked={this.state.initialRecordData?.apply_to === 'PILOT'} onChange={this.handleRadioChange.bind(this)} value={'PILOT'} />
                        <span className='radio-pilot-font'>Pilot</span></>}
                    </MDBCol>
                    <MDBCol md={10} className="radioBtnWrapper">
                        <Radio name="radio-buttons" checked={this.state.initialRecordData?.apply_to === 'AMORT'}
                            onChange={this.handleRadioChange.bind(this)} value="AMORT" />
                        <span className='radio-pilot-font'>Amort</span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={8} className="script-mt">
                        <DateField
                            label={"Script Date"}
                            isMandatory={true}
                            showMandatory={this.state.postInitiated || false}
                            mandatoryWarning={messages.mandatoryWarning}
                            value={this.state.initialRecordData?.script_date?.split("T")[0] || null}
                            onChange={(e) => this.handleChange('script_date', e.target.value.length > 0 ? e.target.value : null)} />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md={8}>
                        <BasicLabel text={"Marketing Cost"} />
                        <BasicTextField
                            placeholder={"$0.00"}
                            value={this.state.initialRecordData?.marketing_cost ? this.state.initialRecordData?.marketing_cost : "$0.00"}
                            onChange={(e) => this.handleChange('marketing_cost', e.target.value)} />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <BasicTextArea label={"Notes"}
                        placeholder={"Notes go here"}
                        value={this.state.initialRecordData?.notes}
                        onChange={(e) => this.handleChange('notes', e.target.value.length > 0 ? e.target.value : null)} />
                </MDBRow>
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={4} className={"saveCancelWrapper"}>
                        <div>
                            <BasicButton variant="contained" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : " "} icon={this.state.isPosting ? " " : "save"} onClick={this.handleSubmit} />
                        </div>
                        <div>
                            <BasicButton variant="outlined" icon={"times"} text=" " onClick={this.props.handleClose} />
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
    componentDidMount() {
        if (this.state.initialRecordData?.marketing_cost === null || this.state.initialRecordData?.marketing_cost === undefined) {
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    ["marketing_cost"]: "$0.00"
                },
            }));
        } else {
            this.setState((prevState) => ({
                initialRecordData: {
                    ...prevState.initialRecordData,
                    ["marketing_cost"]: "$" + this.state.initialRecordData?.marketing_cost
                },
            }));
        }
    }
}
export default ClearanceBudgetModal;