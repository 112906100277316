import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../contexts/UserContext';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';
import { validateNumbersOnly } from '../../Common/Helper';

class LicenseCoverLetterTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      licenseCoverLetterOptions: [],
      isLoading: false,
      isSubmit: false,
      sortNotExist: false,
      sortDuplicate:false,
      sortBy:null,
      isSortByAsc: true,
      sortCount: 0,
      config: JSON.parse(JSON.stringify({ ...this.props?.config}))
    }
  }

  componentDidMount() {
    this.getCoverLetterTypes("licenseCoverLetterOptions", "LICENSE_COVER_LETTER", "");
  }

  getCoverLetterTypes = (entity, entitytype, searchString, loader = true) => {
    this.setState({ isLoading: loader, sortNotExist: false });
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id)
      .then((res) => {
        let configRecord = {...this.state.config}
        this.props?.permissionArray?.edit === 1 ? configRecord.actions = ["pen"] : configRecord.actions = [ ]
        this.setState({ [entity]: res.data, isLoading: false, isSubmit: false, sortBy:null,
          isSortByAsc: true, config: configRecord,
          sortCount: 0, }, this.handleTableColumnClick("Description"));
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  handleInlineEdits = (id, dataItem) => {
    let licenseCoverLetterTypes = [...this.state.licenseCoverLetterOptions]
    this.setState({
      sortNotExist: false,
      licenseCoverLetterOptions: licenseCoverLetterTypes.map((item) => {
        let newItem = {...item}
        if (item.license_cover_letter_id == id)
          newItem['editing'] = true;
        return newItem
      })
    })
  }

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ sortNotExist: false,sortDuplicate:false })
    if(!data[index]?.sort_order || !data[index]?.description || isNaN(parseInt(data[index]?.sort_order))){
      this.setState({ sortNotExist: true })
    }
    else if(data[index]?.sort_order && this.state?.licenseCoverLetterOptions?.some((item,i)=>(data[index]?.sort_order == item?.sort_order || item?.description == data[index]?.description) && i != index)){
      this.setState({ sortDuplicate: true })
    }
    else {
    if (data[index]?.sort_order &&  validateNumbersOnly(data[index]?.sort_order) && parseInt(data[index]?.sort_order) !== null) {
      let licenseCoverLetter = [...data];
      let licenseCoverLetterDetails = [...this.state.licenseCoverLetterOptions];
      licenseCoverLetterDetails[index].description = licenseCoverLetter[index]?.description || null;
      licenseCoverLetterDetails[index].sort_order = parseInt(licenseCoverLetter[index]?.sort_order) || null;
      this.setState({ licenseCoverLetterOptions: licenseCoverLetterDetails });
      this.postLicenseCoverLetterTypes(licenseCoverLetterDetails[index], licenseCoverLetterDetails[index]?.license_cover_letter_id);
    } else {
      this.setState({ sortNotExist: true })
    }
  }
  };
  postLicenseCoverLetterTypes = (licenseCoverLetter, postFlag) => {
    this.setState({ isSubmit: postFlag, sortNotExist: false });
    ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/licenseCoverLetter`,
      licenseCoverLetter,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false });
          this.props?.toastMessageNotification(true, "fail");
          this.getCoverLetterTypes("licenseCoverLetterOptions", "LICENSE_COVER_LETTER", "");
        } else {
          // this.setState({
          //   : false,
          // });
          if (licenseCoverLetter?.editing) {
            licenseCoverLetter.editing = false;
          }
          this.props?.toastMessageNotification(true, "success");
          this.getCoverLetterTypes("licenseCoverLetterOptions", "LICENSE_COVER_LETTER", "", false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props?.toastMessageNotification(true, "fail");
        console.log("Post license details error: " + err);
      }
    );
  };


  handleAddNewItem = (newDataItem) => {
    console.log("newData----", newDataItem)
    this.setState({ sortNotExist: false,sortDuplicate:false })
    if(!newDataItem?.sort_order || !newDataItem?.description || isNaN(parseInt(newDataItem?.sort_order))){
      this.setState({ sortNotExist: true })
    }
    else if(newDataItem?.sort_order && this.state?.licenseCoverLetterOptions?.some(item=>newDataItem?.sort_order == item?.sort_order || item?.description == newDataItem?.description)){
      this.setState({ sortDuplicate: true })
    }
    else {

    
    if (newDataItem?.sort_order && !isNaN(parseInt(newDataItem?.sort_order))) {
      let newLicenseCoverLetterData = {
        description: newDataItem?.description || null,
        sort_order: parseInt(newDataItem?.sort_order) || null,
        license_cover_letter_id: null,
        is_active: 1,
      };
      // let licenseCoverLetterDetails = [...this.state.licenseCoverLetterOptions];
      // licenseCoverLetterDetails.push(newLicenseCoverLetterData);
      this.postLicenseCoverLetterTypes(newLicenseCoverLetterData, 'newItemPost');
      //this.setState({ licenseCoverLetterOptions: licenseCoverLetterDetails });
    } else {
      this.setState({ sortNotExist: true })
    }
  }
  };
  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
        licenseCoverLetterOptions: tableSortList(colName === "Sort Order" ? "Number": "Name", this.getSortNode(colName), this.state.licenseCoverLetterOptions, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Description": return "description";
      case "Sort Order": return "sort_order";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="LicenseCoverLetterContainer">
        <MDBRow>
          <span className="description">Selectable statuses to go on License Cover Letters</span>
        </MDBRow>
        <MDBRow className='pd'>
        <span className="errorText">{this.state.sortNotExist && 'Please enter correct - Description , Sort Order field value'}</span>
        <span className="errorText">{this.state.sortDuplicate && 'Sort Order or Description already exist'}</span>
        </MDBRow>
        {this.state.isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <MDBRow className="LicenseCoverLetterTable">
            <TableComponent
              list={this.state.licenseCoverLetterOptions || []}
              config={this.state.config}
              handleAddNewItem={this.handleAddNewItem.bind(this)}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              addItemToList={permissionValue?.edit === 1 ? true : false}
              postInitiated={this.state.isSubmit}
              // isLoading={this.state.}
              readCallback={(index) => {
                let licenseCoverLetterOptions = [...this.state.licenseCoverLetterOptions];
                licenseCoverLetterOptions[index].editing = false
                this.setState({ licenseCoverLetterOptions: licenseCoverLetterOptions, sortNotExist: false });
              }}
              sortCallback={this.handleTableColumnClick}
              stickyHeader={true}
            />
          </MDBRow>
        )
        }
      </MDBContainer>
    )
  }
}

export default withUserContext(LicenseCoverLetterTypes);