import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardText, MDBIcon } from 'mdbreact';
import { ClearanceBudgetConfig } from './Config';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import MessageModal from '../../SharedComponents/MessageModal';
import YearsBudget from './YearsBudget';
import { getFormattedDate } from '../../../Common/Helper'
import "./Budget.scss";
import Popover from '@material-ui/core/Popover';
import PilotModal from './PilotModal';
import TabComponent from '../../SharedComponents/Tabs/Tabs';
import ClearanceBudgetModal from './ClearanceBudgetModal';
import CircularProgress from "@material-ui/core/CircularProgress";
import YearsBudgetModal from './YearsBudgetModal';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import moment from 'moment';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import { withUserContext } from '../../../contexts/UserContext';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import { fromUnixTime } from 'date-fns';
let show_season_pilot_clearance_budget_id = null, tabValue = null, canEditBudget = false, is_final_array = [], can_edit_final_budget = false;
class ClearanceBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            tabValue: null,
            saveFlag: true,
            setModal: null,
            setModalApplyTo: null,
            mainPilotDetails: {},
            pilotCBDetailsHeader: [],
            showError: false,
            showSuccess: false,
            showFail: false,
            show_season_pilot_clearance_budget_id: null,
            pilotCBDetails: [],
            clearanceTabList: [],
            renderList: [],
            config: ClearanceBudgetConfig,
            showPilotMaintenance: false,
            is_pilot: null,
            defaultCostCenter: null,
            costCenterOptions: [],
            rightsDiscount: null,
            is_final_array: [],
            can_edit_final_budget: false,
            isLoadingTabs: false,
            isDelete: false
        };
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue
        })
    }

    getTabComponent = () => {
        return <YearsBudget division_id={this.props.division_id} show_season_id={this.props.show_season_id} tabValue={this.state.tabValue} pilotCBDetailsHeader={this.state.pilotCBDetailsHeader} fetchPilotHeaderData={this.fetchPilotHeaderData.bind(this)} show_id={this.props.show_id} season_id={this.props.season_id} mainPilotDetails={this.state.mainPilotDetails} permissionArr={this.props.permissionArr} costCenterOptions={this.state.costCenterOptions} defaultCostCenter={this.state.defaultCostCenter} rightsDiscount={this.state.rightsDiscount} can_edit_final_budget={this.state.can_edit_final_budget} notificationComponent={(value, msg=null) => this.setState({ openNotification: value, anyErrorMessage: msg })}
        handleDeleteClick={this.handleDeleteClick} isDelete={this.state.isDelete}
        />;
    }

    handleClickModal = (event) => {
        this.setState({
            setModal: event.currentTarget
        })
    };
    handleClickModalApplyTo = (event) => {
        this.setState({
            setModalApplyTo: event.currentTarget
        })
    };
    handleCloseModal = () => {
        this.setState({
            setModal: null
        })
    };
    handleCloseModalApplyTo = () => {
        this.setState({
            setModalApplyTo: null
        })
    };
    fetchData = (field, url, id = "default", passedObj = null) => {
        this.setState({ isLoading: true });
        if (field === "pilotCBDetailsHeader") {
            this.setState({ isLoadingTabs: true });
        }
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let formattedList = [];
                switch (field) {
                    case 'pilotCBDetailsHeader':
                        //clearanceTabList
                        this.setState({ isLoading: false })
                        let clearanceTabList = [];
                        formattedList = response.data;
                        let index = null;
                        if ((id != null && typeof id === "number")) {
                            index = response.data.findIndex(object => {
                                return object.show_season_pilot_clearance_budget_id === id;
                            });
                            tabValue = response.data[index]?.show_season_pilot_clearance_budget_id;

                        }
                        if (id === "finalBudget") {
                            index = response.data.findIndex(object => {
                                return object.show_season_pilot_clearance_budget_id === passedObj.show_season_pilot_clearance_budget_id;
                            });

                            formattedList[index].is_final_budget = passedObj.is_final_budget;
                            tabValue = response.data[index]?.show_season_pilot_clearance_budget_id;
                        }

                        if (id === "New") {
                            tabValue = response.data[response.data?.length - 1]?.show_season_pilot_clearance_budget_id;
                        }
                        if (id === "default") {
                            tabValue = response.data[0]?.show_season_pilot_clearance_budget_id;
                        }
                        is_final_array = [];

                        response?.data?.length > 0 && response?.data?.map((item, index) => {
                            clearanceTabList.push({ label: item.budget_name, value: item.show_season_pilot_clearance_budget_id });
                            is_final_array.push(item.is_final_budget);
                        });
                        let can_edit_final_budget = is_final_array.includes(Number("1")) ? false : true;
                        this.setState({ pilotCBDetailsHeader: formattedList, show_season_pilot_clearance_budget_id: formattedList.show_season_pilot_clearance_budget_id, tabValue: tabValue, clearanceTabList: clearanceTabList, is_final_array, can_edit_final_budget, isLoadingTabs: false });
                        break;
                    case 'pilotCBDetails':
                        let tempList = [];
                        response?.data?.length > 0 && response?.data?.map((item, index) => {
                            item["id"] = item.show_season_pilot_clearance_budget_id;
                            tempList.push(item);
                        });
                        this.setState({ pilotCBDetails: tempList, renderList: tempList });
                        break;
                    case 'getInitialValues':
                        if (response.data.length > 0) {
                            this.setState({ mainPilotDetails: response.data[0], rightsDiscount: response.data[0]?.pilot_maintance[0]?.rights_discount, isLoading: false, is_pilot: response.data[0]?.is_pilot });
                        }

                        break;
                    case 'costCenterOptions':
                        formattedList = response.data?.map(item => ({ value: item.cost_center_id, label: item.cost_center_name, is_active: item.is_active }));
                        if (formattedList.length == 0) {
                            formattedList.push({ value: "", label: "no records found", is_active: 0 })
                        }
                        let defaultCostCenter = formattedList?.filter((item) => item.label.includes("4646"));
                        if (defaultCostCenter.length > 0) {
                            defaultCostCenter[0]["cost_center_id"] = defaultCostCenter[0].value;
                            defaultCostCenter[0]["percentage"] = 100;
                        }
                        this.setState({ costCenterOptions: formattedList, defaultCostCenter: defaultCostCenter.length > 0 ? defaultCostCenter[0] : null })
                        break;

                }
            },
                (err) => {
                    this.setState({ isLoading: false, isLoadingTabs: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }
    fetchPilotHeaderData(show_season_id, id = "default", passedObj = null) {
        this.fetchData("pilotCBDetailsHeader", `/showSeasonPilotClearanceBudget?show_season_id=${show_season_id}`, id, passedObj);
    }
    // fetchPilotMainDetails(objPassed) {
    //     let mainPilotDetails = { ...objPassed }
    //     mainPilotDetails["script_date"] = mainPilotDetails?.script_date !== null ? moment(mainPilotDetails?.script_date).format('MM/DD/YYYY') : null;
    //     this.setState({ mainPilotDetails })
    // }
    componentDidMount() {
        this.getPermissions(this.props.permissionArr);
        //this.fetchPilotMainDetails(this.props.initialRecordData?.pilot_maintance[0]);
        this.fetchPilotHeaderData(this.props.show_season_id);
        this.fetchData("costCenterOptions", `/entitySearch?entity=COST_CENTER&searchString=null`);
        this.fetchData("getInitialValues", `/showSeason?showSeasonId=${this.props.show_season_id}`);
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("this.state.mainPilotDetails in compdidupdate", this.state.mainPilotDetails);
        console.log("prevState.mainPilotDetails in compdidupdate", prevState.mainPilotDetails);
        console.log("this.state.clearanceTabList", this.state.clearanceTabList);
        if (this.state.clearanceTabList !== prevState.clearanceTabList) {
            this.getTabComponent();
        }
    }

    handleShowPilotMaintenanceModal = () => {
        this.setState({ showPilotMaintenance: true })
    }
    handleHidePilotMaintenanceModal = () => {
        this.setState({ showPilotMaintenance: false })
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        // if (this.state.saveFlag) {

        // }
    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            canEditBudget = permissions.filter((obj, index) => obj.permission_name === "Clearance Budget" && obj.view === 1 && obj.edit === 1);
            canEditBudget = canEditBudget.length > 0 ? true : false;
        }
    }
    handleDeleteClick = () => {
        this.setState({ isDelete: true })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=show_season_pilot_clearance_budget&tableId=${this.state.tabValue}`,
        this.props?.userContext?.active_tenant?.tenant_id).then(res => {
            if(res.data[0].status === 'SUCCESS') {
                this.postDeleteClearanceBudget()
            }
            else {
                this.notificationComponent(true, "fail")
                this.setState({ isDelete: false });
            }
        }),
        ((err) => {
            this.notificationComponent(true, "fail")
            this.setState({ isDelete: false });
        });
    }
    postDeleteClearanceBudget = () => {
        let currentCB = this.state.pilotCBDetailsHeader?.find(item => item.show_season_pilot_clearance_budget_id === this.state.tabValue)
        currentCB["is_delete"] = 1;
        clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl+'/showSeasonPilotClearanceBudget', currentCB, this.props?.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then((response) => {
                if (!response.data.error) {
                    this.setState({ isDelete: false },() => {
                        this.fetchPilotHeaderData(this.props.show_season_id);
                        this.notificationComponent(true, "success");
                    }) 
                }
                else {
                    this.notificationComponent(true, "fail")
                    this.setState({ isDelete: false });
                }
            }),
            ((err) => {
              this.notificationComponent(true, "fail")
              this.setState({ isDelete: false });
        });
    }
    render() {
        console.log("fetchModalDetails in render", this.state.mainPilotDetails);
        let openModalApplyTo = Boolean(this.state.setModalApplyTo);
        let idModalApplyTo = open ? 'modal-popover' : undefined;
        let message = this.state.showFail ? this.state.failMessage : this.state.saveEditMessage;
        let openModal = Boolean(this.state.setModal);
        let idModal = open ? 'modal-popover' : undefined;
        let severity = this.state.showSuccess ? "success" : this.state.showFail ? "error" : "";
        let messageNotification = this.state.showSuccess ? "Deleted successfully!" : this.state.showFail ? "Cannot be deleted" : "";

        return (
            <>
                {this.state.isLoading ? (
                    <div className="ContentLoader" >
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="Years-Container">
                            <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                                handleResetNotify={this.handleResetNotify.bind(this)} />
                                <NotificationComponent
                            open={this.state.openNotification?.length > 0 ? true : false}
                    message={this.state.anyErrorMessage ? this.state.anyErrorMessage  : this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                    severity={this.state.openNotification || ''}
                    handleResetNotify={() => this.setState({ openNotification: null ,anyErrorMessage : null})}
                />
                            <MDBCard>
                                <MDBCardBody>
                                    <MDBCardText>
                                        <MDBRow>
                                            <MDBCol md={6}>
                                                <MDBRow>
                                                    <MDBCol md={2}>
                                                        <BasicLabel text={"Apply To"} />
                                                        {<p>{this.state.mainPilotDetails?.pilot_maintance?.[0]?.apply_to ? this.state.mainPilotDetails?.pilot_maintance?.[0]?.apply_to : 'None'}</p>}
                                                    </MDBCol>
                                                    <MDBCol md={2}>
                                                        <BasicLabel text={"Script Date"} />
                                                        {console.log("this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date", this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date)}
                                                        {(this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date !== null && this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date !== undefined && this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date !== `"Invalid date"`) && <p>{getFormattedDate(this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date)}</p>}
                                                        {(this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date === undefined || this.state.mainPilotDetails?.pilot_maintance?.[0]?.script_date === null) && <p>None</p>}
                                                    </MDBCol>
                                                    <MDBCol md={2}>
                                                        <BasicLabel text={"Marketing Cost"} />
                                                        <p>{this.state.mainPilotDetails?.pilot_maintance?.[0]?.marketing_cost ? "$" + this.state.mainPilotDetails?.pilot_maintance?.[0]?.marketing_cost : "$0.00"}</p>
                                                    </MDBCol>
                                                    <MDBCol md={1} id={"btn-w"}>
                                                        {canEditBudget && <>
                                                            <BasicButton variant="contained"
                                                                type="inline"
                                                                text={"Edit"}
                                                                onClick={this.handleClickModalApplyTo}
                                                            />
                                                            <Popover
                                                                id={idModalApplyTo}
                                                                open={openModalApplyTo}
                                                                className={"pop-over"}
                                                                anchorEl={this.state.setModalApplyTo}
                                                                onClose={this.handleCloseModalApplyTo}
                                                                anchorReference="anchorOrigin"
                                                                anchorOrigin={{
                                                                    horizontal: "right",
                                                                    vertical: "bottom "
                                                                }}
                                                                transformOrigin={{
                                                                    horizontal: "left",
                                                                    vertical: "top"
                                                                }}
                                                                PaperProps={{ style: { width: '16%' } }}
                                                                anchorPosition={{ top: 700, left: 300 }}
                                                            >

                                                                <ClearanceBudgetModal
                                                                    initialRecordData={this.state.mainPilotDetails?.pilot_maintance?.[0]}
                                                                    // fetchPilotMainDetails={this.fetchPilotMainDetails.bind(this)} 
                                                                    show_season_id={this.props.show_season_id}
                                                                    handleClose={this.handleCloseModalApplyTo.bind(this)}
                                                                    notificationComponent={(value, msg=null) => this.setState({ openNotification: value, anyErrorMessage: msg })}
                                                                    is_pilot={this.state.is_pilot}
                                                                    apply_to={this.state.mainPilotDetails?.pilot_maintance?.[0]?.apply_to}
                                                                    fetchModalDetails={this.fetchData.bind(this)}
                                                                />
                                                            </Popover>
                                                        </>}
                                                    </MDBCol>
                                                </MDBRow>
                                                {/* } */}
                                            </MDBCol>
                                            <MDBCol md={6}>
                                                <MDBRow>
                                                    <MDBCol md={8}></MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>
                                        {this.state.mainPilotDetails?.pilot_maintance?.[0]?.show_season_pilot_id && this.props.is_pilot === 1 && <>
                                            <MDBRow className="dynamicTabsContainer">
                                                <div className="tabContainer">
                                                    <TabComponent
                                                        tabList={this.state.clearanceTabList}
                                                        value={this.state.tabValue}
                                                        onChange={(e, v) => this.handleTabChange(v)}
                                                    />
                                                </div>
                                                {canEditBudget &&
                                                    <div className="addBtnContainer">
                                                        <BasicButton variant="text"
                                                            type="inline"
                                                            text={" "}
                                                            icon={"plus-circle"}
                                                            onClick={this.handleClickModal}
                                                        />
                                                    </div>
                                                }

                                            </MDBRow>
                                            {this.state.isLoadingTabs ? (
                                                <div className="ContentLoader">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                    this.getTabComponent()
                                                )
                                            }
                                        </>}

                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                            {this.state.setModal && <MainViewModalComponent
                                open={this.state.setModal}
                                handleClose={this.handleCloseModal.bind(this)}
                                headerText={"Clearance Budget Details"}
                                mode={"New"}
                                modalName={"Edit clearance subtab details"}
                                show_season_id={this.props.show_season_id}
                                season_id={this.props.season_id}
                                show_id={this.props.show_id}
                                fetchData={this.fetchData.bind(this)}
                                fetchPilotHeaderData={this.fetchPilotHeaderData.bind(this)}
                                division_id={this.props.division_id}
                                detailsToDisplay={this.state.detailsToDisplay}
                                costCenterOptions={this.state.costCenterOptions}
                                defaultCostCenter={this.state.defaultCostCenter}
                            />}
                        </div>
                    )
                }
            </>
        )
    }
}

export default withUserContext(ClearanceBudget);
