import React from 'react';
import {MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { withUserContext } from '../../../contexts/UserContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { filesList } from './Config';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import './EpisodeAndClearence.scss';

let permissionOpenAndUploadFiles, queryParam = {};
export default withUserContext(class EpisodeFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingCueSheetDetails: false,
            expanded: false,
            setExpanded: false,
            config: filesList,
            generalFilesArr: [],
            mailHistoryFilesArr: [],
            isGeneralFileLoading: false,
            isMailHistoryLoading: false,
            setGeneralFileModal: null,
            setMailHistoryFileModal: null,
            triggerGetGeneralFileData: null,
            triggerGetMailFileData: null,
            isDeleteFileGeneral: false,
            isDeleteFileMail: false,
            openNotification: false,
            notification: null,
        }
    };
    // const[expanded, setExpanded] = React.useState(false);

    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded }, () => {
            if(this.state.expanded) {
                this.fileExtract()
            }
        });
        // this.getPermissions(this.props?.permissionFilesTab)
    };

    // getPermissions(permissions) {
    //     if (permissions.length > 0) {
    //         permissionOpenAndUploadFiles = permissions.filter((obj, index) => obj.permission_name === "Can open/upload files");
    //         permissionOpenAndUploadFiles = permissionOpenAndUploadFiles.length > 0 ? true : false;
    //     }
    // }

    onLoadFetchData = (url, queryParamObj, isGeneral = null) => {
        if (isGeneral === true) {
            this.setState({ isGeneralFileLoading: true });
            queryParamObj["module"] = "clearancegeneral";
        }
        if (isGeneral === false) {
            this.setState({ isMailHistoryLoading: true });
            queryParamObj["module"] = "clearanceemailhistory";
        }
        clearTrackService.getDataParams(Constants.ClearTrackServiceBaseUrl + url, queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (isGeneral === true) {
                    this.setState({ isGeneralFileLoading: false, isDeleteFileGeneral: false });
                    queryParamObj["module"] = "clearancegeneral";
                    if (response?.data?.data?.length > 0) {
                        this.setState({ generalFilesArr: response.data.data });
                        //this.disabletriggerGetFileData("GeneralFileModal");
                    }
                    else {
                        this.setState({ generalFilesArr: [] });
                    }
                }
                if (isGeneral === false) {
                    this.setState({ isMailHistoryLoading: false, isDeleteFileMail: false });
                    queryParamObj["module"] = "clearanceemailhistory";
                    if (response?.data?.data?.length > 0) {
                        this.setState({ mailHistoryFilesArr: response.data.data });
                        //this.disabletriggerGetFileData("MailHistoryFileModal");
                    } else {
                        this.setState({ mailHistoryFilesArr: [] });
                    }
                }
            },
                (err) => {
                    if (isGeneral === true) {
                        this.setState({ isDeleteFileGeneral: false, isGeneralFileLoading: false });
                        queryParamObj["module"] = "clearancegeneral";
                    }
                    if (isGeneral === false) {
                        this.setState({ isDeleteFileMail: false, isMailHistoryLoading: false });
                        queryParamObj["module"] = "clearanceemailhistory";
                    }
                    console.log("Error in fetching data", err)
                })
    }
    downloadFile = (url) => {
        if (url) {
            fetch(url)
              .then((response) => response.blob())
              .then((blob) => {
                const link = document.createElement("a");
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
          }
    }
    handleUploadFile = (e, isGeneral) => {
        if (isGeneral === true) {
            this.setState({
                setGeneralFileModal: e.currentTarget
            })
        }
        if (isGeneral === false) {
            this.setState({
                setMailHistoryFileModal: e.currentTarget
            })
        }
    }
    closeUploadFile = (isGeneral) => {
        if (isGeneral === true) {
            this.setState({
                setGeneralFileModal: null
            })
        }
        if (isGeneral === false) {
            this.setState({
                setMailHistoryFileModal: null
            })
        }
    }
    deleteFiles = (fileName, index, isGeneral) => {
        let queryParamObj = {
            "showName": this.props?.show_name,
            "season": this.props?.season_name,
            "fileName": fileName,
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            "episodeName": this.props?.episode_name,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }
        if (isGeneral === true) {
            queryParamObj["module"] = "clearancegeneral";
            this.setState({ isDeleteFileGeneral: index });
        } else {
            queryParamObj["module"] = "clearanceemailhistory";
            this.setState({ isDeleteFileMail: index });
        }
        clearTrackService.deleteDataParams(Constants.ClearTrackServiceBaseUrl + '/deleteFiles', queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ openNotification: true, notification: 'File deletion failed' })
                } else {
                    if (isGeneral === true) {
                        this.onLoadFetchData(`/getFiles`, {
                            "showName": this.props?.show_name,
                            "season": this.props?.season_name,
                            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                            "isBoxUpload": 1,
                            "module": "clearancegeneral",
                            "episodeName": this.props?.episode_name,
                            'divisionId':this.props.division_id,
                            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
                        }, true);
                    } else {
                        this.onLoadFetchData(`/getFiles`, {
                            "showName": this.props?.show_name,
                            "season": this.props?.season_name,
                            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                            "isBoxUpload": 1,
                            "module": "clearanceemailhistory",
                            "episodeName": this.props?.episode_name,
                            'divisionId':this.props.division_id,
                            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
                        }, false);
                    }

                }
            },
                (err) => {
                    this.setState({ isDeleteFileGeneral: false, isDeleteFileMail: false, openNotification: true, notification: 'File deletion failed' });
                    console.log("Error in fetching data", err)
                })
    }

    // componentDidMount() {
    //     if(this.props.userContext?.active_tenant?.tenant_name) {
    //         this.fileExtract()
    //     }
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if(prevProps?.userContext?.active_tenant?.tenant_name !== this.props?.userContext?.active_tenant?.tenant_name) {
    //         this.fileExtract()
    //     }
    // }


    fileExtract () {
        queryParam = {
            "showName": this.props?.show_name,
            "season": this.props?.season_name,
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            "episodeName": this.props?.episode_name,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }
        this.onLoadFetchData(`/getFiles`, {
            "showName": this.props?.show_name,
            "season": this.props?.season_name,
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            "module": "clearancegeneral",
            "episodeName": this.props?.episode_name,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }, true);
        this.onLoadFetchData(`/getFiles`, {
            "showName": this.props?.show_name,
            "season": this.props?.season_name,
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            "module": "clearanceemailhistory",
            "episodeName": this.props?.episode_name,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }, false);    
    }
    
    render() {
        return (
            <>
                {this.state.isFetchingCueSheetDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="cueSheet files-container" class='row' id='mainGrid'>

                            <Accordion onChange={this.handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className="header-cue" >
                                        Files
                                    </Typography>

                                </AccordionSummary>

                                <AccordionDetails id="cuesheet-table">
                                    <MDBRow className="file-width">
                                        {/* General file section code start */}
                                        <MDBCol className="header-cue" md={6}>
                                            General
                                            {
                                                this.state.isGeneralFileLoading ? (
                                                    <div style={{ textAlign: "center" }}>
                                                        <CircularProgress size={24} />
                                                    </div>
                                                ) : (<>
                                                    {/* {permissionOpenAndUploadFiles && */}
                                                        <div className="cuesheet-files-btn">
                                                            <BasicButton
                                                                className="cuesheet-btn-2"
                                                                variant="outlined"
                                                                type="inline"
                                                                color="primary"
                                                                text={"Add File"}
                                                                onClick={(e) => { this.handleUploadFile(e, true) }}
                                                            />
                                                        </div>
                                                    {/* } */}
                                                    <MDBRow>
                                                        <>
                                                            {this.state.generalFilesArr.length > 0 && this.state.generalFilesArr.map((item, index) => (
                                                                <MDBCol md={6} key={index}>
                                                                    <div className="display-pdf">
                                                                        <p className="file-text" onClick={() => this.downloadFile(item.url)}>{item.Key} </p>
                                                                        {this.state.isDeleteFileGeneral === index ?
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <CircularProgress size={10} />
                                                                            </div> :
                                                                            <span className="icon-float">
                                                                                <MDBIcon
                                                                                    icon={"times"}
                                                                                    onClick={() => this.deleteFiles(item.Key, index, true)}
                                                                                />
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </MDBCol>
                                                            ))}
                                                            {this.state.generalFilesArr.length === 0 && <p> No files present. Please add using "Add File" button"</p>}
                                                        </>
                                                    </MDBRow>
                                                </>)}
                                        </MDBCol>
                                        {/* General file section code end */}
                                        {/* Mail History file section code start */}
                                        <MDBCol className="header-cue vartical-line" md={6}>
                                            Mail History
                                                {
                                                this.state.isMailHistoryLoading ? (
                                                    <div style={{ textAlign: "center" }}>
                                                        <CircularProgress size={24} />
                                                    </div>
                                                ) : (<>
                                                    {/* {permissionOpenAndUploadFiles && */}
                                                        <div className="cuesheet-files-btn">
                                                            <BasicButton
                                                                className="cuesheet-btn-2"
                                                                variant="outlined"
                                                                type="inline"
                                                                color="primary"
                                                                text={"Add File"}
                                                                onClick={(e) => { this.handleUploadFile(e, false) }}
                                                            />
                                                        </div>
                                                    {/* } */}
                                                    <MDBRow>
                                                        <>
                                                            {this.state.mailHistoryFilesArr.length > 0 && this.state.mailHistoryFilesArr.map((item, index) => (
                                                                <MDBCol md={6} key={index}>
                                                                    <div className="display-pdf">
                                                                        <p className="file-text" onClick={() => this.downloadFile(item.url)}>{item.Key} </p>
                                                                        {this.state.isDeleteFileMail === index ?
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <CircularProgress size={10} />
                                                                            </div> :
                                                                            <span className="icon-float">
                                                                                <MDBIcon
                                                                                    icon={"times"}
                                                                                    onClick={() => this.deleteFiles(item.Key, index, false)}
                                                                                />
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </MDBCol>
                                                            ))}
                                                            {this.state.mailHistoryFilesArr.length === 0 && <p> No files present. Please add using "Add File" button"</p>}
                                                        </>
                                                    </MDBRow>
                                                </>)}
                                        </MDBCol>
                                        {/* Mail History file section code end */}
                                    </MDBRow>
                                </AccordionDetails>

                            </Accordion>

                        </div>
                    )
                }
                {this.state.setGeneralFileModal &&
                    <MainViewModalComponent
                        open={this.state.setGeneralFileModal}
                        division_id={this.props.division_id}
                        handleClose={() => this.closeUploadFile(true)}
                        headerText={"Upload File"}
                        mode={""}
                        modalName={"UploadFile"}
                        initialRecordData={this.state.initialRecordData}
                        onLoadFetchData={this.onLoadFetchData.bind(this)}
                        origin={"GeneralFileModal"}
                        show_name={this.props?.show_name} season_name={this.props?.season_name}
                        episode_name={this.props.episode_name}
                    />}
                {this.state.setMailHistoryFileModal &&
                    <MainViewModalComponent
                        open={this.state.setMailHistoryFileModal}
                        division_id={this.props.division_id}
                        handleClose={() => this.closeUploadFile(false)}
                        headerText={"Upload File"}
                        mode={""}
                        modalName={"UploadFile"}
                        initialRecordData={this.state.initialRecordData}
                        onLoadFetchData={this.onLoadFetchData.bind(this)}
                        origin={"MailHistoryFileModal"}
                        show_name={this.props?.show_name} season_name={this.props?.season_name}
                        episode_name={this.props.episode_name}
                    />}
            </>
        );
    }

});