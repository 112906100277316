export const initialClearanceRecord = {
    "clearance_id": null,
    "show_season_id": null,
    "show_id": null,
    "selected_show": null,
    "selected_song": null,
    "season_id": null,
    "show_season_episode_id": null,
    "show_name": null,
    "episode_title": null,
    "scene": null,
    // "duration": null,
    // "music_uses_id": null,
    "existing_uses": null,
    "clearance_option_id": null,
    "clearance_option": null,
    "is_subtitle_allowed": 0,
    "is_film_tv_clip": 0,
    "is_override_cost_center": 0,
    "is_ms_complete": 0,
    "is_no_lic_music": 0,
    "is_apply_dollar_to_HV": 0,
    "is_translation_allowed": 0,
    "is_active": 0,
    "songs_list": [],
    "is_songs_updated": null,
    "performer_id":null
}
export const mockSong = {
    song_id: null,
    is_do_not_use: null,
    performer_list: [],
    selected_song: null,
    is_delete:0,
    min:null,
    sec:null,
}

export const mockSong2 = {
    main_title_song_id:null,
    song_id: null,
    is_do_not_use: null,
    performer_list:[],
    min:null,
    sec:null,
}


export const mockPerformer = {
    performer_id: null,
    performer_name: null
}

export const checkBoxOptionsLeft = [{ "id": "is_subtitle_allowed", "label": "Subtitle Allowed" }, { "id": "is_film_tv_clip", "label": "Film TV Clip" }, { "id": "is_override_cost_center", "label": "Override Cost Center" }]
export const checkBoxOptionsRight = [{ "id": "is_no_lic_music", "label": "No Lic. Music" }, { "id": "is_apply_dollar_to_HV", "label": "Apply $ to HV" },{ "id": "is_translation_allowed", "label": "Translation Allowed" }]
export const checkBoxOptionsRightEdit = [{ "id": "is_translation_allowed", "label": "Translation Allowed" },{"id": "is_ms_complete","label":"MS Episode Complete"}]
