import React from 'react';
import {  MDBRow, MDBCol } from 'mdbreact';
import TableComponent from '../../SharedComponents/Table';
import { warningHeading } from './config';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import './CueSheetAcknowledgementWarnings.scss';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';

class Warnings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: JSON.parse(JSON.stringify({ ...warningHeading })),
            renderList: [],
            showAllReps: false,
            backUpList:[],
            isLoading:false,
            showIsClosed:false,
            selectedItem:null,
        }
    }

    componentDidMount = () => {
       this.getData()
    } 

    getData = (show_all_reps = 0) => {
        this.setState({isLoading:true})
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/cueSheetAck?type=WARNING&show_all_reps=${show_all_reps}`,
            this.props.userContext?.active_tenant?.tenant_id, null)
            .then(response => {
                this.props?.setDownLoadRow(null)
                let formattedList = response.data?.map(item => {
                    let newItem = {...item}
                    newItem.check = item?.is_closed===1 ? true:false
                    newItem.CS = false
                    if(item?.file_name) {
                        newItem.Ack = false
                        newItem.XML = false
                    } else {
                        newItem.Ack = true
                        newItem.XML = true
                    }
                    delete newItem.is_closed
                    return newItem
                })
                this.setState({ renderList : formattedList?.filter(item => item?.check === this.state.showIsClosed) , backUpList :formattedList, isLoading:false, selectedItem:null})
            })
    }

    handleClosdeCheckBox = (value, dataItem) => {
        this.props?.setDownLoadRow(dataItem?.rc_xml_cue_id)
        let postJson = {
            "rc_xml_cue_id": dataItem?.rc_xml_cue_id,
            "is_closed": value ? 1: 0
        }
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/cueSheetAck`, postJson , this.props.userContext?.active_tenant?.tenant_id, dataItem?.division_id)
            .then(response => {
                if (response) {
                    if (response.data.error) {
                        this.props?.setDownLoadRow(null)
                        this.props.notificationComponent('error')
                    } else {
                        this.getData(this.state.showAllReps ?  1 : 0)
                        this.props.notificationComponent('success')
                    }
                }
            })
    }


    cueSheetDownload = (dataItem, node) => {
        this.props?.setDownLoadRow(dataItem?.rc_xml_cue_id)
        this.props?.cueSheetDownload(dataItem, node, this.props?.userContext?.active_tenant?.tenant_id)
    }

    render() {
        let errorList = this.state.renderList?.find(item => item?.rc_xml_cue_id === this.state.selectedItem )
        return (
            <div id="warnigs">
                <div className='between mt-2'>
                    <div>
                        <span className='ml5'>The below table have RapidCue Warnings. Click on a airing to view its warnings</span>
                    </div>
                    <div className='p5 p-r2'>
                        <div className='p-r2'>
                            <BasicCheckbox
                                id={"warningCueSheetACkClosed"}
                                label='Show Closed'
                                checked={this.state.showIsClosed}
                                onChange={(e) =>
                                    this.setState({showIsClosed : e.target.checked}, () => {
                                        this.setState({renderList : this.state.backUpList?.filter(item => item?.check === this.state.showIsClosed)})
                                    })
                                 }
                            />
                        </div>
                        <div>
                            <BasicCheckbox
                            id={"warningCueSheetACkShowAllRep"}
                            label='Show All Clearance Reps'
                            checked={this.state.showAllReps}
                            onChange={(e) =>
                               this.setState({showAllReps : e.target.checked}, () => {
                                    this.getData(this.state.showAllReps ?  1 : 0)
                               })
                            }
                        />
                        </div>
                    </div>
                </div>
                <div id="bg-header" className='table-component mt-2'>
                    <TableComponent
                        list={this.state.renderList ? this.state.renderList : ""}
                        config={this.state.config}
                        arrayCheckUncheck={(value, dataItem) => {this.handleClosdeCheckBox(value, dataItem)}}
                        hyperLinkNavigationCallback={(dataItem, node) => {this.setState({selectedItem : dataItem?.rc_xml_cue_id})}}
                        handleOpenInlineModal={this.cueSheetDownload}
                        postInitiated={this.props.downLoadRow}
                        isLoading={this.state.isLoading}
                    />
                </div>
                {this.state.selectedItem && <div className='mt-2 warning-error-list'>
                        <span className="label-with-cursor"  onClick={() => {this.setState({selectedItem:null})}}>Close Warning List</span>
                        <div  className="file-selected-header">Warnings for {errorList?.file_name}</div>
                        {errorList?.errors?.length > 0 ? errorList?.errors?.map((item,index) => <div key={item?.rc_xml_cue_error_id}>{index + 1}. {item?.description}</div>) :
                        <div>No Warnings</div>}
                </div>}
                <MDBRow className='mt-2'>
                    <MDBCol sm={10}>

                    </MDBCol>
                    <MDBCol sm={2}>
                        <BasicButton
                            onClick={this.props.handleClose}
                            variant="outlined"
                            text={"Close"}
                        />
                    </MDBCol>
                </MDBRow>
            </div>

        )
    }
}

export default withUserContext(Warnings);
