import React from 'react';
class SearchResultsLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: props.searchResult ? props.searchResult : null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.searchResult !== state.searchResult) {
            return {
                searchResult: props.searchResult
            }
        }
        return null
    }

    render() {
        return (<>
            <div class='row'>
                <div class='col-12'>
                    {this.state.searchResult?.name}
                </div>
            </div>
        </>
        )
    }
}
export default SearchResultsLayout;
