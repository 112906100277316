import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from "../../../SharedComponents/BasicButton/BasicButton";
import SelectField from "../../../SharedComponents/SelectField/SelectField";
import DateField from "../../../SharedComponents/DateField/DateField";
import BasicTextArea from "../../../SharedComponents/BasicTextArea/BasicTextArea";
import TableComponent from "../../../SharedComponents/Table";
import { withUserContext } from "../../../../contexts/UserContext";
import {
  AllBatchHeading,
  batchesHeading,
  AllMTBatchHeading,
  MTBatchesHeading,
} from "./config";
import "./MainTitleBatches.scss";
import moment from "moment";
import clearTrackService from "../../../../services/service";
import * as Constants from "../../../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import MessageModal from "../../../SharedComponents/MessageModal";
import { getFormattedDate } from "../../../../Common/Helper";
import NotificationComponent from "../../../../Common/NotificationComponent/NotificationComponent";
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
export default withUserContext(
  class MainTitleBatches extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        BatchOption: [],
        selectedOption: "ALL",
        batchesData: [],
        batchesRecordData: {},
        selectedBatches: [],
        isLoading: false,
        isSubmit: false,
        isDelete: false,
        isUpdate: false,
        isRemove: false,
        isCreateBatch: false,
        isOpen: false,
        postInitiated: false,
        isExcelPosting: false,
        isOpenPaidModal: false,
        showSuccess: false,
        showFail: false,
        saveFlag: false,
        selectedField: null,
        isCheckRequest: false,
        isPreview: false,
        checkReqList: [],
        isFieldChanged: false,
        isSelectedRemoveBatch: false,
        paidSongDetailText: null,
        paidBatchList: [],
        isOpenEmailModal: false,
        isEmailBatch: false,
        emailTextMsg: null,
        callFromExcelBatch: false,
        isCheckTransmit: false,
        isCheckTransmitAP: false,
        checkTransmitList: [],
        isCheckReqButton: false,
        isCheckTransmitButton: false,
        showExcelAndDocModal: false,
        isOpenExisting: false,
        showDocBatchModal: false,
        showDocNewModal: false,
        showDocCheckTransmitModal: false,
        showDocCheckTransmitApModal: false,
      };
    }
    componentDidMount() {
      this.getBatchesList("ALL");
    }
    isOpenExisting = (data) => {
      this.setState({ isOpenExisting: data });
    };
    handleShowExcelAndDocModal = () => {
      this.setState({ showExcelAndDocModal: true, isOpenExisting: false });
    };
    handleHideExcelAndDocModal = () => {
      this.setState({ showExcelAndDocModal: false });
    };
    handleShowDocBatchModal = () => {
      this.setState({ showDocBatchModal: true, isOpenExisting: false });
    };
    handleHideDocBatchModal = () => {
      this.setState({ showDocBatchModal: false });
    };
    handleShowDocNewModal = () => {
      this.setState({ showDocNewModal: true, isOpenExisting: false });
    };
    handleHideDocNewModal = () => {
      this.setState({ showDocNewModal: false });
    };
    handleShowDocCheckTransmitModal = () => {
      this.setState({ showDocCheckTransmitModal: true, isOpenExisting: false });
    };
    handleHideDocCheckTransmitModal = () => {
      this.setState({ showDocCheckTransmitModal: false });
    };
    handleShowDocCheckTransmitApModal = () => {
      this.setState({
        showDocCheckTransmitApModal: true,
        isOpenExisting: false,
      });
    };
    handleHideDocCheckTransmitApModal = () => {
      this.setState({ showDocCheckTransmitApModal: false });
    };
    handleGeneratePreviewBatch = (previewFlag, batchType) => {
      this.setState({ postInitiated: true, isPreview: previewFlag });
      let reportName = this.props?.isMainTitle
        ? "rptMainTitleOptExcelBatchPdf"
        : "rptClearanceOptExcelBatchPdf";
      let searchPayload = {};
      if (this.props?.isMainTitle) {
        searchPayload = {
          main_title_id: this.props?.main_title_id,
          right_id: this.props?.right_id,
          batch_no: this.state.selectedOption,
        };
      } else {
        searchPayload = {
          show_season_id: this.props?.show_season_id,
          right_id: this.props?.right_id,
          batch_no: this.state.selectedOption,
        };
      }
      this.downloadReport(reportName, searchPayload, "isPdf")
        .then((webSocketConn) => {
          webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            this.setState({ isPreview: false });
            if (batchType === "isPdf") {
              if (response?.statusCode == 200) {
                this.setState({ isPreview: false });
                this.downloadUrlPdf(response?.body);
                //this.notificationComponent(true, "success");
              } else {
                this.setState({ isPreview: false });
                //this.notificationComponent(true, 'success')
              }
            }
          };
        })
        .catch((err) => {
          this.setState({ isPreview: false });
          this.notificationComponent(true, "fail");
        });
    };
    downloadUrlPdf = (url, reportName) => {
      if (url) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            //link.href = window.URL.createObjectURL(blob);
            //link.setAttribute('download', reportName);
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
            // this.notificationComponent(true, "success");
          });
      }
    };

    handleCreateCheckRequest = (batchTypeMode) => {
      this.setState({ postInitiated: true, isCheckRequest: true });
      let reportName = this.props?.isMainTitle
        ? this.props?.isHVOptionalRight === true
          ? "rptMainTitleHVOptCheckRequestLetter"
          : "rptMainTitleOptionalCheckRequestLetter"
        : this.props?.isHVOptionalRight === true
        ? "rptClearanceHVOptCheckRequestLetter"
        : "rptClearanceOptionalCheckRequestLetter";
      let searchPayload = {};
      if (this.props?.isMainTitle) {
        this.state.checkReqList?.map((item) => {
          searchPayload = {
            main_title_id: item?.main_title_id,
            main_title_licensor_ids: this.props?.licOptionalId,
            main_title_licensor_optional_check_requests_ids:
              item?.main_title_licensor_optional_check_requests_id,
            generate_letter: 1,
            isOpenExisting: this.state.isOpenExisting ? 1 : 0,
          };
          this.generateCheckRequest(
            reportName,
            searchPayload,
            "isDocx",
            batchTypeMode
          );
        });
      } else {
        this.state.checkReqList?.map((item) => {
          searchPayload = {
            clearance_id: item?.clearance_id,
            clearance_licensor_ids: this.props?.licOptionalId,
            clearance_licensor_optional_check_requests_ids:
              item?.clearance_licensor_optional_check_requests_id,
            generate_letter: 1,
            isOpenExisting: this.state.isOpenExisting ? 1 : 0,
          };
          this.generateCheckRequest(
            reportName,
            searchPayload,
            "isDocx",
            batchTypeMode
          );
        });
      }
    };
    generateCheckRequest = (
      reportName,
      searchPayload,
      reportType,
      batchTypeMode
    ) => {
      this.downloadReport(reportName, searchPayload, reportType)
        .then((webSocketConn) => {
          webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (response?.statusCode == 200) {
              this.downloadUrl(response?.body?.url, response?.body?.filename);
              //this.notificationComponent(true, "success");
              this.setState({
                isCheckRequest: false,
                postInitiated: false,
                isCheckTransmit: false,
                isCheckTransmitAP: false,
              });
              if (batchTypeMode === "new") {
                this.getBatchesList("NEW");
              } else if (
                batchTypeMode === "isTransmit" ||
                batchTypeMode === "batch"
              ) {
                this.selectedBatchList(this.state.selectedOption);
              }
            }
            //  else {
            //   //this.notificationComponent(true, 'fail')
            //   this.setState({
            //     isCheckRequest: false,
            //     postInitiated: false,
            //     isCheckTransmit: false,
            //     isCheckTransmitAP: false,
            //   });
            //   if (batchTypeMode === "new") {
            //     this.getBatchesList("NEW");
            //   } else if (
            //     batchTypeMode === "isTransmit" ||
            //     batchTypeMode === "batch"
            //   ) {
            //     this.selectedBatchList(this.state.selectedOption);
            //   }
            // }
          };
        }),
        ((err) => {
          this.setState({ isCheckRequest: false, postInitiated: false });
          this.notificationComponent(true, "fail");
        });
    };

    downloadReport = (reportName, searchPayload, reportType) => {
      if (!searchPayload || !reportName) {
        return;
      }
      Object.keys(searchPayload).map((obj) => {
        if (searchPayload[obj] === undefined) {
          searchPayload[obj] = null;
        }
      });
      let encryptTedStr = btoa(JSON.stringify(searchPayload));
      let load = {};
      if (reportType === "isPdf") {
        load = {
          payload: "exportPdf",
          reportName: reportName,
          fileName: this.state.callFromExcelBatch
            ? this.state.batchesRecordData?.fileName
            : this.state.batchesRecordData?.previewFileName,
          searchJson: encryptTedStr,
          showHtml: 0,
          encryptionType: 1,
          tenantId: this.props?.userContext?.active_tenant?.tenant_id,
          divisionId: this.props?.divisionId,
        };
        return clearTrackService.getDataWS(load);
      } else if (reportType === "isExcel") {
        load = {
          payload: "exportExcel",
          reportName: reportName,
          fileName: null, // this.props?.isMainTitle?"MainTitleOptExcelBatch":"ClearanceOptExcelBatch",
          searchJson: encryptTedStr,
          showHtml: 0,
          encryptionType: 1,
          tenantId: this.props?.userContext?.active_tenant?.tenant_id,
          divisionId: this.props?.divisionId,
        };
        return clearTrackService.getDataWS(load);
      } else if (reportType === "isDocx") {
        load = {
          payload: "exportDocx",
          reportName: reportName,
          fileName: null, // this.props?.isMainTitle?"MainTitleOptExcelBatch":"ClearanceOptExcelBatch",
          searchJson: encryptTedStr,
          showHtml: 0,
          encryptionType: 1,
          tenantId: this.props?.userContext?.active_tenant?.tenant_id,
          divisionId: this.props?.divisionId,
        };
        return clearTrackService.getDataWS(load);
      }
    };

    downLoadExcelForBox = () => {
      if(this.props?.isMainTitle) {
        this.downloadExcel('rptMainTitleOptExcelBatch')
        // this.downloadExcel('rptMainTitleOptExcelBatchFiori', true)
      } else {
        this.downloadExcel('rptClearanceOptExcelBatch')
        // this.downloadExcel('rptClearanceOptExcelBatchFiori', true)
      }
  }
  
    downloadExcel = (reportNameDownload, showNotification=false,) => {
      this.setState({
        postInitiated: true,
        isExcelPosting: true,
        emailTextMsg: null,
      });
      let reportName = reportNameDownload;
      let searchPayload = {};
      if (this.props?.isMainTitle) {
        searchPayload = {
          main_title_id: this.props?.main_title_id,
          right_id: this.props?.right_id,
          batch_no: this.state.selectedOption,
        };
      } else {
        searchPayload = {
          show_season_id: this.props?.show_season_id,
          right_id: this.props?.right_id,
          batch_no: this.state.selectedOption,
        };
      }
      searchPayload["isOpenExisting"] = this.state.isOpenExisting ? 1 : 0;
      this.downloadReport(reportName, searchPayload, "isExcel")
        .then((webSocketConn) => {
          webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            console.log('all responses', response)
            if (response?.statusCode == 200) {
              console.log('report url is here for', reportName, response?.body)
            let responseBodyData = response?.body;
            // if (responseBodyData?.length > 0) {
            //   responseBodyData?.forEach((item) => {
                if (this.props?.isMainTitle) {
                  if (reportName === 'rptMainTitleOptExcelBatch') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_${this.state.selectedOption}`
                    );
                    this.downloadExcel('rptMainTitleOptExcelBatchFiori', true)
                  }
                  if (reportName === 'rptMainTitleOptExcelBatchFiori') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_FIORI_${this.state.selectedOption}`
                    );
                  }
                } else {
                  if (reportName === 'rptClearanceOptExcelBatch') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_${this.state.selectedOption}`
                    );
                    this.downloadExcel('rptClearanceOptExcelBatchFiori', true)
                  }
                  if (reportName === 'rptClearanceOptExcelBatchFiori') {
                    this.downloadUrl(
                      responseBodyData,
                      `EXCELBATCH_FIORI_${this.state.selectedOption}`
                    );
                  }
               }
              // });
              if(showNotification) {
                this.setState({ isExcelPosting: false , showExcelAndDocModal:false});
                this.handleHideExcelAndDocModal();
                this.notificationComponent(true, "success");
              }
              //this.setState({ isExcelPosting: false });
             // this.notificationComponent(true, "success");
            }
          };
        })
        // .catch((err) => {
        //   this.setState({ isExcelPosting: false });
        //   this.notificationComponent(true, "fail");
        // });
    };
    downloadUrl = (url, reportName) => {
      console.log('check the report url', url)
      if (url) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", reportName);
            document.body.appendChild(link);
            this.handleHideDocBatchModal();
            this.handleHideDocNewModal();
            this.handleHideDocCheckTransmitModal();
            this.handleHideDocCheckTransmitApModal();
            //this.handleHideExcelAndDocModal();
            link.click();
            link.remove();
            // this.notificationComponent(true, "success");
          });
      }
    };
    downloadCheckTransmitLetter = (transmitType) => {
      let reportName = null;
      if (transmitType === "checkTransmitAP") {
        this.setState({ isCheckTransmitAP: true, isCheckTransmit: false });
        reportName = this.props?.isMainTitle
          ? this.props?.isHVOptionalRight === true
            ? "rptMainTitleHVOptCheckTransmittalAPLetter"
            : "rptMainTitleOptionalCheckTransmittalAPLetter"
          : this.props?.isHVOptionalRight === true
          ? "rptClearanceHVOptCheckTransmittalAPLetter"
          : "rptClearanceOptionalCheckTransmittalAPLetter";
      } else {
        this.setState({ isCheckTransmit: true, isCheckTransmitAP: false });
        reportName = this.props?.isMainTitle
          ? this.props?.isHVOptionalRight === true
            ? "rptMainTitleHVOptCheckTransmittalLetter"
            : "rptMainTitleOptionalCheckTransmittalLetter"
          : this.props?.isHVOptionalRight === true
          ? "rptClearanceHVOptCheckTransmittalLetter"
          : "rptClearanceOptionalCheckTransmittalLetter";
      }
      let searchPayload = {};
      if (this.props?.isMainTitle) {
        this.state.checkTransmitList?.map((item) => {
          searchPayload = {
            main_title_id: item.main_title_id,
            main_title_licensor_id: this.props?.licOptionalId,
            main_title_licensor_optional_check_requests_id:
              item?.main_title_licensor_optional_check_requests_id,
            isOpenExisting: this.state.isOpenExisting ? 1 : 0,
          };
          this.generateCheckRequest(
            reportName,
            searchPayload,
            "isDocx",
            "isTransmit"
          );
        });
      } else {
        this.state.checkTransmitList?.map((item) => {
          searchPayload = {
            clearance_id: item?.clearance_id,
            clearance_licensor_id: this.props?.licOptionalId,
            clearance_licensor_optional_check_requests_id:
              item?.clearance_licensor_optional_check_requests_id,
            isOpenExisting: this.state.isOpenExisting ? 1 : 0,
          };
          this.generateCheckRequest(
            reportName,
            searchPayload,
            "isDocx",
            "isTransmit"
          );
        });
      }
    };

    getBatchesList = (batch_option) => {
      this.setState({ isLoading: true, BatchOption: [] });
      let url = this.props?.isMainTitle
        ? `/mainTitleOptCRQBatches?main_title_id=${this.props?.main_title_id}&right_id=${this.props?.right_id}&batch_option=${batch_option}`
        : `/clearanceOptCRQBatches?show_season_id=${this.props?.show_season_id}&right_id=${this.props?.right_id}&batch_option=${batch_option}`;
      clearTrackService
        .getData(
          Constants.ClearTrackServiceBaseUrl + url,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props?.divisionId
        )
        .then((response) => {
          let filteredBatchList = [];
          response.data?.map((item) => {
            if (item?.batch_no) {
              filteredBatchList.push({
                value: item.batch_no,
                label: item.batch_no,
              });
            }
          });
          //  filteredBatchList.sort(function (a, b) {  return a - b;  });
          filteredBatchList.unshift({ value: "NEW", label: "NEW" });
          filteredBatchList.unshift({ value: "ALL", label: "ALL" });
          let dataList = [];
          response.data?.map((data) => {
            data?.batch_list?.map((item) => {
              dataList.push({ ...item, batch_no: data.batch_no });
            });
          });
          dataList?.map((batchItem) => {
            if (batchItem.batch_no === null) {
              return (batchItem.batch_no = "None");
            }
          });
          this.setState({
            BatchOption: filteredBatchList,
            batchesData: dataList,
            isLoading: false,
          });
        })

        .catch((error) => {
          this.setState({ isLoading: false });
          console.log("error");
        });
    };
    selectedBatchList = (selectedBatch) => {
      this.setState({
        batchesData: [],
        isLoading: true,
        isFieldChanged: false,
        isSelectedRemoveBatch: false,
        checkTransmitList: [],
        isCheckTransmitButton: false,
        checkReqList: [],
        isCheckReqButton: false,
      });
      let url = this.props?.isMainTitle
        ? `/mainTitleOptCRQBatches?main_title_id=${this.props?.main_title_id}&right_id=${this.props?.right_id}&batch_option=${selectedBatch}`
        : `/clearanceOptCRQBatches?show_season_id=${this.props?.show_season_id}&right_id=${this.props?.right_id}&batch_option=${selectedBatch}`;
      clearTrackService
        .getData(
          Constants.ClearTrackServiceBaseUrl + url,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props?.divisionId
        )
        .then((response) => {
          let batchDataList = [];
          let deleteBatchList = [];
          response.data?.map((data) => {
            data?.batch_list?.map((item) => {
              if (!(selectedBatch === "ALL" || selectedBatch === "NEW")) {
                deleteBatchList.push({ ...item, batch_no: data.batch_no });
              }
              batchDataList.push({ ...item, batch_no: data.batch_no });
            });
          });
          batchDataList?.map((batchItem) => {
            if (batchItem.batch_no === null) {
              return (batchItem.batch_no = "None");
            }
          });
          if (this.state.selectedField === "is_create") {
            let filteredBatchList = [...this.state.BatchOption];
            response.data?.map((item) => {
              if (item?.batch_no) {
                filteredBatchList.push({
                  value: item.batch_no,
                  label: item.batch_no,
                });
              }
            });
            this.setState({ BatchOption: filteredBatchList });
          }

          this.setState({
            batchesData: batchDataList,
            selectedBatches: deleteBatchList,
            batchesRecordData: {
              ...this.state.batchesRecordData,
              batchesNotes: response.data[0]?.batch_notes,
              releaseDate: response.data[0]?.release_date ? getFormattedDate(
                response.data[0]?.release_date,
                true
              ) : moment(new Date())?.format("YYYY-MM-DD"),
              batchNo: response.data[0]?.batch_no,
              fileName: response.data[0]?.file_name,
              previewFileName: response.data[0]?.preview_file_name,
            },
            isLoading: false,
          });
        })

        .catch((error) => {
          this.setState({ isLoading: false });
          console.log("error");
        });
    };
    arrayCheckUncheck = (id, dataItem) => {
      let updateBatchList = this.state.batchesData?.map((item) => {
        let newItem = { ...item };
        if (this.props?.isMainTitle) {
          if (
            item?.main_title_licensor_optional_check_requests_id ===
            dataItem?.main_title_licensor_optional_check_requests_id
          ) {
            newItem.check = id;
          }
        } else if (
          item?.clearance_licensor_optional_check_requests_id ===
          dataItem?.clearance_licensor_optional_check_requests_id
        ) {
          newItem.check = id;
        }
        return newItem;
      });
      let filteredBatch = updateBatchList?.filter(
        (item) => item.check === true
      );
      if (filteredBatch?.length > 0) {
        filteredBatch?.find((item) => {
          if (item?.check === true) {
            return this.setState({ isSelectedRemoveBatch: true });
          } else {
            return this.setState({ isSelectedRemoveBatch: false });
          }
        });
      } else {
        return this.setState({
          isSelectedRemoveBatch: false,
          isCheckReqButton: false,
          isCheckTransmitButton: false,
        });
      }
      let checkReqList = [];
      filteredBatch?.find((selectedItem) => {
        if (this.state.selectedOption === "NEW") {
          if (
            selectedItem?.check === true &&
            selectedItem?.generated_date === null
          ) {
            checkReqList.push(selectedItem);
            return this.setState({ isCreateBatch: true });
          } else {
            return this.setState({ isCreateBatch: false });
          }
        } else {
          if (selectedItem?.check === true) {
            checkReqList.push(selectedItem);
            return this.setState({ isCheckReqButton: true });
          } else {
            return this.setState({ isCheckReqButton: false });
          }
        }
      });
      let checkTransmitList = [];
      filteredBatch?.find((item) => {
        if (item?.check === true) {
          checkTransmitList.push(item);
          return this.setState({ isCheckTransmitButton: true });
        } else {
          return this.setState({ isCheckTransmitButton: false });
        }
      });
      let paidBatchList = filteredBatch?.filter(
        (item2) => item2?.check === true && item2?.paid_flag === 1
      );

      this.setState({
        batchesData: updateBatchList,
        selectedBatches: filteredBatch,
        checkReqList: checkReqList,
        paidBatchList: paidBatchList,
        batchesRecordData: {
          ...this.state.batchesRecordData,
          composition: dataItem?.composition,
          payee_company: dataItem?.payee_company,
          check: dataItem?.check,
          paid_flag: dataItem?.paid_flag,
          generated_date: dataItem?.generated_date,
        },
        checkTransmitList: checkTransmitList,
      });
    };
    handleChange = (field, value) => {
      this.setState((prevState) => ({
        batchesRecordData: {
          ...prevState.batchesRecordData,
          [field]: value,
        },
        isFieldChanged: true,
      }));
    };
    handleCreateBatch = (field) => {
      if (this.state.selectedBatches?.length > 0) {
        this.setState({
          isSubmit: true,
          isDelete:
            field === "is_update" || field === "is_remove" ? false : true,
          isUpdate:
            field === "is_delete" || field === "is_remove" ? false : true,
          isRemove:
            field === "is_update" || field === "is_delete" ? false : true,
        });
        let selectedBatchData = this.state.selectedBatches?.map((item) => {
          if (this.props?.isMainTitle) {
            return {
              is_delete: field === "is_remove" ? 1 : 0,
              main_title_optional_check_request_batches_id:
                item?.main_title_optional_check_request_batches_id,
              main_title_id: item?.main_title_id,
              main_title_licensor_optional_check_requests_id:
                item?.main_title_licensor_optional_check_requests_id,
            };
          } else {
            return {
              is_delete: field === "is_remove" ? 1 : 0,
              clearance_optional_check_request_batches_id:
                item?.clearance_optional_check_request_batches_id,
              clearance_id: item?.clearance_id,
              clearance_licensor_optional_check_requests_id:
                item?.clearance_licensor_optional_check_requests_id,
            };
          }
        });
        let batchPayload = {
          batch_no:
            field === "is_delete" ||
            field === "is_update" ||
            field === "is_remove"
              ? this.state.selectedOption
              : null,
          delete_batch: field === "is_delete" ? 1 : 0,
          update_batch: field === "is_update" ? 1 : 0,
          release_date: this.state.batchesRecordData?.releaseDate,
          batch_notes: this.state.batchesRecordData?.batchesNotes,
          batch_list: selectedBatchData?.length > 0 ? selectedBatchData : [],
        };
        let url = this.props?.isMainTitle
          ? `/mainTitleOptCRQBatches`
          : `/clearanceOptCRQBatches`;
        clearTrackService
          .postDataParams(
            Constants.ClearTrackServiceBaseUrl + url,
            batchPayload,
            this.props?.userContext?.active_tenant?.tenant_id,
            this.props?.divisionId
          )
          .then((response) => {
            if (response.data.error) {
              this.notificationComponent(true, "fail");
              this.setState({
                isSubmit: false,
                isDelete: false,
                isUpdate: false,
                isRemove: false,
                isSelectedRemoveBatch: false,
                emailTextMsg: null,
                isCheckReqButton: false,
                isCheckTransmitButton: false,
              });
            } else {
              this.notificationComponent(true, "success");
              if (field === "is_create") {
                this.setState({ selectedOption: response?.data[0]?.batch_no });
                this.downLoadExcelForBox();
                this.setState(
                  {
                    //isExcelPosting: false,
                    isDelete: false,
                    isUpdate: false,
                    isRemove: false,
                    isOpen: false,
                    isFieldChanged: false,
                    isSelectedRemoveBatch: false,
                    emailTextMsg: null,
                    isCheckReqButton: false,
                    isCheckTransmitButton: false,
                    selectedField: field === "is_create" ? "is_create" : null,
                  },
                  () => {
                    this.setState({
                      isSubmit: false,
                      selectedOption: response?.data[0]?.batch_no,
                    });
                    this.selectedBatchList(response?.data[0]?.batch_no);
                  }
                );
              } else if (field === "is_delete" || field === "is_remove") {
                this.setState({
                  selectedOption: "ALL",
                  isSubmit: false,
                  isDelete: false,
                  isUpdate: false,
                  isRemove: false,
                  isOpen: false,
                  isFieldChanged: false,
                  isSelectedRemoveBatch: false,
                  emailTextMsg: null,
                  isCheckReqButton: false,
                  isCheckTransmitButton: false,
                });
                this.getBatchesList("ALL");
              } else {
                this.setState({
                  selectedOption: response?.data[0]?.batch_no,
                  isSubmit: false,
                  isDelete: false,
                  isUpdate: false,
                  isRemove: false,
                  isOpen: false,
                  isFieldChanged: false,
                  isSelectedRemoveBatch: false,
                  emailTextMsg: null,
                  isCheckReqButton: false,
                  isCheckTransmitButton: false,
                });
                this.selectedBatchList(response?.data[0]?.batch_no);
              }
            }
          })
          .catch((error) => {
            console.log("error");
            this.setState({
              isSubmit: false,
              isDelete: false,
              isUpdate: false,
              isRemove: false,
              isFieldChanged: false,
              isSelectedRemoveBatch: false,
              emailTextMsg: null,
              isCheckReqButton: false,
              isCheckTransmitButton: false,
            });
            this.notificationComponent(true, "fail");
          });
      }
    };

    allBatches = () => {
      return (
        <div>
          <MDBRow id={"MainTitleBatch-table"}>
            <TableComponent
              list={this.state.batchesData || []}
              config={
                this.props?.isMainTitle
                  ? this.state.selectedOption === "ALL"
                    ? AllMTBatchHeading
                    : MTBatchesHeading
                  : this.state.selectedOption === "ALL"
                  ? AllBatchHeading
                  : batchesHeading
              }
              isLoading={this.state.isLoading}
              arrayCheckUncheck={this.arrayCheckUncheck}
              stickyHeader={true}
            />
          </MDBRow>
          {this.state.selectedOption !== "ALL" ? (
            <MDBRow>
              <MDBCol md={2}>
                <DateField
                  label={"Release Date"}
                  id="release Date"
                  placeholder="- Release Date -"
                  value={
                    getFormattedDate(
                      this.state.batchesRecordData?.releaseDate,
                      true
                    ) || null
                  }
                  onChange={(e) =>
                    this.handleChange("releaseDate", e.target.value)
                  }
                />
              </MDBCol>
              <MDBCol md={10}>
                <BasicTextArea
                  id={"textarea-performer"}
                  rows={1}
                  label={"Batches Notes"}
                  value={this.state.batchesRecordData?.batchesNotes}
                  onChange={(e) =>
                    this.handleChange("batchesNotes", e.target.value)
                  }
                />
              </MDBCol>
            </MDBRow>
          ) : null}
          <MDBRow>
            <MDBCol md={11}></MDBCol>
            <MDBCol md={1} className="mt-2">
              <BasicButton
                type="inline"
                variant="outlined"
                text={"Close"}
                onClick={this.props?.handleClose}
              />
            </MDBCol>
          </MDBRow>
        </div>
      );
    };
    onChange = (label, value) => {
      this.setState({
        selectedOption: value,
        selectedField: null,
      });
      this.selectedBatchList(value);
    };
    handleSelectAllBatches = () => {
      let batchesList = [...this.state.batchesData];
      let batchListChecked = batchesList?.map((item) => {
        return (item = { ...item, check: true });
      });
      batchListChecked?.find((item) => {
        if (item?.generated_date === null) {
          return this.setState({ isCreateBatch: true });
        }
      });
      let paidList = batchListChecked?.filter(
        (pd) => pd?.check === true && pd?.paid_flag === 1
      );
      let checkTransmitList = [];
      let checkReqList = [];
      if (batchListChecked?.length > 0) {
        batchListChecked?.find((data) => {
          if (data?.check === true) {
            checkReqList.push(data);
            checkTransmitList.push(data);
            return this.setState({
              isSelectedRemoveBatch: true,
              isCheckTransmitButton: true,
              isCheckReqButton: true,
            });
          } else {
            return this.setState({
              isSelectedRemoveBatch: false,
              isCheckTransmitButton: false,
              isCheckReqButton: false,
            });
          }
        });
      } else {
        this.setState({
          isSelectedRemoveBatch: false,
          isCheckTransmitButton: false,
          isCheckReqButton: false,
        });
      }
      this.setState({
        batchesData: batchListChecked,
        selectedBatches: batchListChecked,
        paidBatchList: paidList,
        checkTransmitList: checkTransmitList,
        checkReqList: checkReqList,
      });
    };
    handleDeSelectAllBatches = () => {
      let batchesList = [...this.state.batchesData];
      let batchListUnChecked = batchesList?.map((item) => {
        if (item?.generated_date === null) {
          this.setState({ isCreateBatch: true });
        } else {
          this.setState({ isCreateBatch: false });
        }
        return (item = { ...item, check: false });
      });
      if (batchListUnChecked?.length > 0) {
        batchListUnChecked?.find((data) => {
          if (data?.check === true) {
            return this.setState({ isSelectedRemoveBatch: true });
          } else {
            return this.setState({
              isSelectedRemoveBatch: false,
              isCheckTransmitButton: false,
              isCheckReqButton: false,
            });
          }
        });
      } else {
        this.setState({
          isSelectedRemoveBatch: false,
          isCheckTransmitButton: false,
          isCheckReqButton: false,
        });
      }
      this.setState({
        batchesData: batchListUnChecked,
        selectedBatches: [],
        paidBatchList: [],
        checkTransmitList: [],
        checkReqList: [],
      });
    };
    closePopup = () => {
      this.setState({ isOpenPaidModal: false });
    };
    closeEmailPopup = () => {
      this.setState({ isOpenEmailModal: false });
    };
    notificationComponent = (value, mode = "fail") => {
      switch (mode) {
        case "success":
          return this.setState({ showSuccess: value });
        case "fail":
          return this.setState({ showFail: value });
      }
    };

    handleResetNotify = () => {
      this.setState({ showFail: false, showSuccess: false });
      if (this.state.saveFlag) {
        this.setState({ saveFlag: false });
      }
    };
    handleEmailBatch = () => {
      this.setState({ isEmailBatch: true, callFromExcelBatch: true });
      // let reportName = this.props?.isBusinessAffairs?"rptSCMSExcelBatchPdf":this.props?.isMainTitle?"rptMainTitleExcelBatchPdf":"rptClearanceExcelBatchPdf";
      let searchPayload = {};
      searchPayload = {
        opening_dialog_from: this.props?.isMainTitle ? "MT_OPT" : "CL_OPT",
        batch_no: this.state.selectedOption,
      };
      let encryptTedStr = btoa(JSON.stringify(searchPayload));
      this.handleGeneratePreviewBatch(false, "isEmail");
      clearTrackService
        .getData(
          Constants.ClearTrackServiceBaseUrl +
            `/emailBatches?searchJson=${encryptTedStr}`,
          this.props?.userContext?.active_tenant?.tenant_id,
          this.props?.divisionId,
          true
        )
        .then((res) => {
          this.setState({
            isEmailBatch: false,
            emailTextMsg: res?.data?.msg,
            isOpenEmailModal: true,
            callFromExcelBatch: false,
          });
        })
        .catch((err) => {
          this.setState({
            isEmailBatch: false,
            emailTextMsg: null,
            isOpenEmailModal: false,
            callFromExcelBatch: false,
          });
          this.notificationComponent(true, "fail");
          console.log(err);
        });
    };
    render() {
      let messageNotification = this.state.showSuccess
        ? "The changes are saved successfully!"
        : this.state.showFail
        ? "Saving changes failed!"
        : "";
      let severity = this.state.showFail
        ? "error"
        : this.state.showSuccess
        ? "success"
        : "";
      return (
        <MDBContainer fluid className="Batches-container">
          <NotificationComponent
            open={this.state.showSuccess || this.state.showFail}
            message={messageNotification}
            severity={severity}
            handleResetNotify={this.handleResetNotify.bind(this)}
          />
          <MDBRow className="mt-2">
            <MDBCol md={2}>
              <SelectField
                id={"batch-type"}
                size="small"
                //label={"Status"}
                value={this.state.selectedOption}
                options={this.state.BatchOption || []}
                onChange={(e) =>
                  this.onChange("selectedOption", e.target.value)
                }
              />
            </MDBCol>
            {this.state.selectedOption === "NEW" ? (
              <MDBCol md={10}>
                <MDBRow>
                  <MDBCol md={3}>
                    <div className="self-check-request">
                      <BasicButton
                        variant="contained"
                        type="inline"
                        color="primary"
                        disabled={this.state.isCreateBatch ? false : true}
                        text={
                          this.state.isCheckRequest ? (
                            <CircularProgress color="inherit" size={18} />
                          ) : (
                            "Check Request"
                          )
                        }
                        onClick={this.handleShowDocNewModal}
                        postInitiated={this.state.isCheckRequest}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md={2}>
                    <div className="create-batch">
                      <BasicButton
                        variant="contained"
                        type="inline"
                        color="primary"
                        disabled={this.state.isCreateBatch ? true : false}
                        onClick={() => {
                          let paidSongDetail = "";
                          if (this.state.paidBatchList?.length > 0) {
                            this.state.selectedBatches?.find((item) => {
                              if (
                                this.state.selectedOption === "NEW" &&
                                item?.check === true &&
                                item?.paid_flag === 1 &&
                                item?.generated_date !== null
                              ) {
                                paidSongDetail =
                                  paidSongDetail +
                                  item?.composition +
                                  " Payee " +
                                  item?.payee_company +
                                  ",";
                                this.setState({
                                  isOpenPaidModal: true,
                                  paidSongDetailText: paidSongDetail,
                                });
                              }
                            });
                          } else {
                            this.handleCreateBatch("is_create");
                          }
                        }}
                        text={
                          this.state.isSubmit ? (
                            <CircularProgress color="inherit" size={18} />
                          ) : (
                            "Create Batch"
                          )
                        }
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md={7}>
                    <span className="message">
                     Highlighted rows indicate a Check Request has not yet been
                     saved to the network and cannot be added to a batch.
                    </span>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            ) : null}
            {this.state.selectedOption === "ALL" ? (
              <MDBCol md={10}>
                <span className="message">
                  Highlighted rows indicate a Check Request has not yet been
                  saved to the network and cannot be added to a batch
                </span>
              </MDBCol>
            ) : null}
            {!(
              this.state.selectedOption === "NEW" ||
              this.state.selectedOption === "ALL"
            ) ? (
              <MDBCol md={8}>
                  <MDBRow>
                    {this.props?.clearanceBatchesPermissions?.edit === 1 && (
                      <MDBCol md={3}>
                        <div className="self-check-request">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            disabled={
                              this.state.isCheckReqButton ? false : true
                            }
                            text={
                              this.state.isCheckRequest ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Check Request"
                              )
                            }
                            onClick={this.handleShowDocBatchModal}
                            postInitiated={this.state.isCheckRequest}
                          />
                        </div>
                      </MDBCol>
                    )}

                    {this.props?.clearanceBatchesPermissions?.edit === 1 && (
                      <MDBCol md={2}>
                        <div className="self-update">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            disabled={!this.state.isFieldChanged ? true : false}
                            text={
                              this.state.isUpdate ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Update Batch"
                              )
                            }
                            onClick={() => {
                              this.setState({ selectedField: null });
                              this.handleCreateBatch("is_update");
                            }}
                          />
                        </div>
                      </MDBCol>
                    )}

                    {this.props?.clearanceBatchesPermissions?.view === 1 && (
                      <MDBCol md={3}>
                        <div className="self-preview">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            text={
                              this.state.isPreview ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Preview Batch"
                              )
                            }
                            onClick={() =>
                              this.handleGeneratePreviewBatch(true, "isPdf")
                            }
                          />
                        </div>
                      </MDBCol>
                    )}

                    {this.props?.clearanceBatchesPermissions?.edit === 1 && (
                      <MDBCol md={2}>
                        <div className="self-email">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            text={
                              this.state.isEmailBatch ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Email Batch"
                              )
                            }
                            onClick={this.handleEmailBatch}
                          />
                        </div>
                      </MDBCol>
                    )}
                    {this.props?.clearanceBatchesPermissions?.edit == 1 && (
                      <MDBCol md={2}>
                        <div className="self-excel">
                          <BasicButton
                            variant="contained"
                            type="inline"
                            color="primary"
                            text={
                              //this.state.selectedField !== "is_create" &&
                              this.state.isExcelPosting ? (
                                <CircularProgress color="inherit" size={18} />
                              ) : (
                                "Excel Batch"
                              )
                            }
                            onClick={this.handleShowExcelAndDocModal}
                            postInitiated={this.state.isExcelPosting}
                          />
                        </div>
                      </MDBCol>
                    )}
                  </MDBRow>
              </MDBCol>
            ) : null}

            {!(
              this.state.selectedOption === "NEW" ||
              this.state.selectedOption === "ALL"
            ) ? (
              <MDBCol md={2} className="alignEnd2">
                <div className="dlt-btnn">
                  {this.props?.clearanceBatchesPermissions?.edit == 1 && (
                    <BasicButton
                      variant="contained"
                      type="inline"
                      color="primary"
                      text={"Delete Batch"}
                      onClick={() => this.setState({ isOpen: true })}
                    />
                  )}
                </div>
              </MDBCol>
            ) : null}
          </MDBRow>
          {!(
            this.state.selectedOption === "NEW" ||
            this.state.selectedOption === "ALL"
          ) ? (
            this.props?.clearanceBatchesPermissions?.edit === 1 ? (
              <MDBRow className="mt-2">
                <MDBCol md={2}></MDBCol>
                <MDBCol md={2}>
                  <div className="self-transmit">
                    <BasicButton
                      disabled={this.state.isCheckTransmitButton ? false : true}
                      variant="contained"
                      type="inline"
                      color="primary"
                      text={
                        this.state.isCheckTransmit ? (
                          <CircularProgress color="inherit" size={18} />
                        ) : (
                          "Check Transmit"
                        )
                      }
                      onClick={this.handleShowDocCheckTransmitModal}
                      postInitiated={this.state.isCheckTransmit}
                    />
                  </div>
                </MDBCol>
                <MDBCol md={2}>
                  <div className="self-transmit">
                    <BasicButton
                      disabled={this.state.isCheckTransmitButton ? false : true}
                      variant="contained"
                      type="inline"
                      color="primary"
                      text={
                        this.state.isCheckTransmitAP ? (
                          <CircularProgress color="inherit" size={18} />
                        ) : (
                          "Check Transmit AP"
                        )
                      }
                      onClick={this.handleShowDocCheckTransmitApModal}
                      postInitiated={this.state.isCheckTransmitAP}
                    />
                  </div>
                </MDBCol>
                <MDBCol md={6}></MDBCol>
              </MDBRow>
            ) : null
          ) : null}
          <MDBRow className="mt-2">
            <MDBCol md={3}>
              {this.state.selectedOption !== "ALL" ? (
                <div className="main-Bactch">
                  <div>
                    <span
                      className="selectAll"
                      onClick={this.handleSelectAllBatches}
                    >
                      Select All
                    </span>
                  </div>
                  <div>
                    <span
                      className="deSelect"
                      onClick={this.handleDeSelectAllBatches}
                    >
                      DeSelect All
                    </span>
                  </div>
                </div>
              ) : null}
            </MDBCol>
            <MDBCol md={7}></MDBCol>
            <MDBCol md={2} xl={2}>
              {!(
                this.state.selectedOption === "NEW" ||
                this.state.selectedOption === "ALL"
              ) ? (
                this.props?.clearanceBatchesPermissions?.edit == 1 && <div
                  className="sub-Batch"
                  disabled={!this.state.isSelectedRemoveBatch ? true : false}
                >
                  <span
                    className="del-batch"
                    onClick={() => this.handleCreateBatch("is_remove")}
                  >
                    {this.state.isRemove ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : (
                      "Delete Sel. From Batch"
                    )}
                  </span>
                </div>
              ) : null}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <div>{this.allBatches()}</div>
          </MDBRow>
          <MessageModal
            open={this.state.isOpen}
            title={"Confirm"}
            message={`Delete Batch ${this.state.batchesRecordData?.batchNo}?`}
            primaryButtonText={
              this.state.isDelete ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                "confirm"
              )
            }
            secondaryButtonText={"Cancel"}
            onConfirm={() => this.handleCreateBatch("is_delete")}
            handleClose={(e) => this.setState({ isOpen: false })}
          />
          {this.state.isOpenPaidModal && (
            <MessageModal
              open={this.state.isOpenPaidModal}
              title={"Message"}
              message={`Check Request for song ${this.state.paidSongDetailText}. cannot be added to the batch because it has already been Paid.`}
              hideCancel={true}
              primaryButtonText={"OK"}
              onConfirm={() => this.closePopup()}
              handleClose={(e) => this.setState({ isOpenPaidModal: false })}
            />
          )}
          {this.state.isOpenEmailModal && (
            <MessageModal
              open={this.state.isOpenEmailModal}
              title={"Message"}
              message={this.state.emailTextMsg}
              hideCancel={true}
              primaryButtonText={"OK"}
              onConfirm={() => this.closeEmailPopup()}
              handleClose={(e) => this.setState({ isOpenEmailModal: false })}
            />
          )}
          {this.state.showExcelAndDocModal && (
            <>
              <MainViewModalComponent
                open={this.state.showExcelAndDocModal}
                handleClose={this.handleHideExcelAndDocModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={() => this.downLoadExcelForBox()}
                isOpenExisting={this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isExcelPosting}
              />
            </>
          )}
          {this.state.showDocBatchModal && (
            <>
              <MainViewModalComponent
                open={this.state.showDocBatchModal}
                handleClose={this.handleHideDocBatchModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={() => this.handleCreateCheckRequest("batch")}
                isOpenExisting={this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isCheckRequest}
              />
            </>
          )}
          {this.state.showDocNewModal && (
            <>
              <MainViewModalComponent
                open={this.state.showDocNewModal}
                handleClose={this.handleHideDocNewModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={() => this.handleCreateCheckRequest("new")}
                isOpenExisting={this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isCheckRequest}
              />
            </>
          )}
          {this.state.showDocCheckTransmitModal && (
            <>
              <MainViewModalComponent
                open={this.state.showDocCheckTransmitModal}
                handleClose={this.handleHideDocCheckTransmitModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={() =>
                  this.downloadCheckTransmitLetter("checkTransmit")
                }
                isOpenExisting={this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isCheckTransmit}
              />
            </>
          )}
          {this.state.showDocCheckTransmitApModal && (
            <>
              <MainViewModalComponent
                open={this.state.showDocCheckTransmitApModal}
                handleClose={this.handleHideDocCheckTransmitApModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={() =>
                  this.downloadCheckTransmitLetter("checkTransmitAP")
                }
                isOpenExisting={this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isCheckTransmitAP}
              />
            </>
          )}
        </MDBContainer>
      );
    }
  }
);
