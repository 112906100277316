import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialMixDetails,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import { s2ab, genReportName } from '../../../Common/Helper';
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";
class MixDateReport extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      mixDateDetails: initialMixDetails,
      seasonOptions: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
      seasonIdValues: [],
      showIdValues: [],
      found: [],
      isPosting: false
    };
  }

  componentDidMount() {
    // this.fetchSeasonsData();
    // this.fetchShowsData();
    if (this.props?.userContext?.reportTabData?.length > 0) {
      this.setState({
        filterValues: [this.props?.userContext?.reportTabData[1], this.props?.userContext?.reportTabData[2]],
        showIdValues: [this.props?.userContext?.reportTabData[3]],
        seasonIdValues: [this.props?.userContext?.reportTabData[4]]
      })
    }
  }

  // fetchSeasonsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SEASON&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id, 1)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.season_id, text: item.season_name }));
  //       this.setState({ seasonOptions: formattedList }, () => { console.log(this.state.seasonOptions) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  // fetchShowsData = () => {
  //   clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=SHOW&searchString=A`,
  //     this.props?.userContext?.active_tenant?.tenant_id, 1)
  //     .then(response => {
  //       let formattedList = response.data?.map(item => ({ value: item.show_id, text: item.show_name }));
  //       this.setState({ showOptions: formattedList }, () => { console.log(this.state.showOptions) });
  //     },
  //       (err) => {
  //         console.log("Error in fetching Address Types:", err);
  //         this.setState({ isFetchingClearanceDetails: false });
  //       })
  // }

  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }
  handleClearFilters = () => {
    let values = this.state.filterValues;
    values = []
    this.setState({ filterValues : values , mixDateDetails : []});
}
  handleSubmitMixDateReport = () => {
    this.setState({ postInitiated: true });
    let { mixDateDetails, filterValues, seasonIdValues, showIdValues } = this.state;
    if (!mixDateDetails.mix_date_range_form || !mixDateDetails.mix_date_range_to) {
      return;
    }
    this.setState({ isPosting: true })
    let payload = {
    //   mix_date_start: "2022-07-15",
    //    mix_date_end: "2022-08-18",
    //   show_ids: [707],
    //   show_season_ids: [1107]
    // }
      mix_date_start: mixDateDetails.mix_date_range_form,
      mix_date_end: mixDateDetails.mix_date_range_to,
      season_ids: seasonIdValues,
      show_ids: showIdValues
    }
    this.props.generateReport(this.props?.reportName, payload, "MixDate").then(webSocketConn => {
      webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data)
        if (response?.statusCode == 200) {
          this.setState({ isPosting: false })
          this.props?.downloadUrl(response?.body);
          this.props?.notificationComponent(true, 'success')
        } else {
          this.props?.notificationComponent(true, 'fail')
        }
      }
    })
  }

  // handleSubmit = () => {
  //   console.log("submit", this.state.maixDataDetails);
  // };

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      mixDateDetails: {
        ...prevState.mixDateDetails,
        [field]: value,
      },
    }));
  };

  // setSeasonId = (seasonIdValues) => {
  //   let seasonIdValue = this.state.seasonIdValues;
  //   seasonIdValue = seasonIdValues;
  //   this.setState({
  //     seasonIdValues: seasonIdValue
  //   })
  // }

  // setShowId = (showIdValues) => {
  //   let showIdValue = this.state.showIdValues;
  //   showIdValue = showIdValues;
  //   this.setState({
  //     showIdValues: showIdValue
  //   })
  // }


  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode }, () => {
      let splitVal = this.state.removedItem.split(":");
      let name = splitVal[0].trim();
      let valueId = splitVal[1].trim();
      if (name === "Season") {
        let filterSeasonIdValues = this.state.found.find(item => item.label === valueId).value
        let index2 = this.state.seasonIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.seasonIdValues
        id.splice(index2, 1);
        this.setState({seasonIdValues: id})
      }
      else if (name === 'Show') {
        let filterSeasonIdValues = this.state.showOptions.find(item => item.text === valueId).value
        let index2 = this.state.showIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.showIdValues
        id.splice(index2, 1);
        this.setState({showIdValues: id})
      }
    });
  }
  seasonValues = (seasonValue) => {
    this.setState({found:seasonValue})
  }

  showValues = (showValue) => {
    let backup = this.state.showOptions
    backup = backup.concat(showValue)
    this.setState({showOptions:backup})
  }

  GenerateCleranceDetails = () => { };
  render() {
    return (
      <>
        {" "}
        <p>
          <b>Mix Date Report</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
                // filterValues={this.state.filterValues}
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
            <MDBCol md={5} className="dateRange">
              <BasicLabel text="Mix Date Range" />
              <MDBRow className="g-0 mt-2">
                <MDBCol md={4} id="DateField-1">
                  <DateField
                    id="date"
                    showMandatory={this.state.postInitiated || false}
                    value={this.state.mixDateDetails?.mix_date_range_form || ""}
                    onChange={(e) =>
                      this.handleChange("mix_date_range_form", e.target.value.length > 0 ? e.target.value : null)
                    }
                  />
                </MDBCol>
                <MDBCol md={4} id="DateField-2">
                  <DateField
                    id="MixDate"
                    showMandatory={this.state.postInitiated || false}
                    value={this.state.mixDateDetails?.mix_date_range_to || ""}
                    onChange={(e) =>
                      this.handleChange("mix_date_range_to", e.target.value.length > 0 ? e.target.value : null)
                    }
                    showErrorBorder={this.state.mixDateDetails?.mix_date_range_form > this.state.mixDateDetails?.mix_date_range_to ? true : false}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol md={7}>
              <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                // setSeasonId={this.setSeasonId}
                // setShowId={this.setShowId}
                filterSeasonIdValues={this.state.seasonIdValues}
                filterShowIdValues={this.state.showIdValues}
                filterValues ={this.state.filterValues}
                seasonValues={this.seasonValues}
                showValues={this.showValues}
                />
            </MDBCol>
            {/* <MDBCol md={3}>
              <BasicLabel text="Seasons" />
              <SearchSelectField
                id={"All Seasons"}
                placeHolderText={"All Seasons"}
                value={{ 'value': this.state.mixDateDetails.selected_season?.value, 'text': this.state.mixDateDetails.selected_season?.text } || {}}
                options={this.state.seasonOptions || []}
                onChange={(e, value) => this.handleChange("selected_season", value)}
              />
            </MDBCol>
            <MDBCol md={3}>
              <BasicLabel text="Shows" />
              <SearchSelectField
                id={"All Shows"}
                placeHolderText={"All Shows in selected season(s)"}
                value={{ 'value': this.state.mixDateDetails.selected_show?.value, 'text': this.state.mixDateDetails.selected_show?.text } || {}}
                options={this.state.showOptions || []}
                onChange={(e, value) => this.handleChange("selected_show", value)}
              />
              <br />
            </MDBCol> */}
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate PDF"} onClick={this.handleSubmitMixDateReport} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"}  onClick ={this.handleClearFilters}/>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default withUserContext(MixDateReport);
