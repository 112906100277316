import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { CircularProgress } from "@material-ui/core";

export default withUserContext(class ImportCueSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileData: null,
            fileName: null,
            isSubmit: false,
            showError: false,
            showDataError: null,
        };
    }


    postImportCueSheet = (is_excel) => {
        this.setState({ isSubmit: true });
        let url = is_excel === 1 ?   `/importCueSheets?show_season_episode_id=${this.props.EpId}&airing=${this.props.airingType}&is_excel=${is_excel}` :
                                `/importCueSheets?show_season_episode_id=${this.props.EpId}&airing=${this.props.airingType}`
        ClearTrackService.postDataBuffer(
            Constants.ClearTrackServiceBaseUrl + url, this.state.fileData,
            this.props.userContext?.active_tenant?.tenant_id, this.props.divisionId
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isSubmit: false, showDataError : response?.data?.message });
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.props?.handleSubmit()
                    this.setState({ isSubmit: false });
                    this.props?.notificationComponent(true, "success");
                }
            },
            (err) => {
                this.setState({ isSubmit: false });
                this.props?.notificationComponent(true, "fail");
            }
        );
    };

    uploadFile(file) {
        file=file[0]
        this.setState({ showError: false, showDataError:null })
        try {
            const reader = new FileReader()
            if (file?.size > 5242880 || !(file?.name?.includes('.xlsx') || file?.name?.includes('.txt'))) {
                this.setState({ showError: true })
                return
            }
            reader.onload = () => {
                if (reader.readyState === 2) {
                    let result = reader.result.split(",")?.[1];
                    this.setState({ fileData: result, fileName: file.name });
                    this.props?.fieldChanged(true, "change")
                }
            };
            reader.readAsDataURL(file);
        } catch (error) {
            console.log(error)
            this.setState({ fileData: null, fileName: null });
        }

    }

    render() {
        return (
            <MDBContainer className={'importCueSheetData'}>
                <MDBRow className='mt-1'>
                    <span>Please upload file in .txt or .xlsx format</span>
                </MDBRow>
                <MDBRow className='mt-1'>
                    <div>Format for .txt file</div>
                    <div>Please enter data for columns 'Cue no, Composition, Composer, Publisher and Duration' tab separated without any headers</div>
                </MDBRow>
                <MDBRow className='mt-1'>
                    <div>Format for .xlsx file</div>
                    <div>Please keep following headers for each columns 'Cue No, Composition, Composer,	Publisher, Duration'</div>
                </MDBRow>
                <BasicLabel text={"Select File"} />
                <MDBRow>
                    <MDBCol md={1}>
                        <BasicButton
                            variant="outlined"
                            icon="paperclip"
                            type="inline"
                            inputType="file"
                            id={'imporstseaonfile'}
                            uploadFile={(file) => this.uploadFile(file)}
                        />
                    </MDBCol>
                    <MDBCol md={3}><BasicLabel text={"(upto 5mb)"} /></MDBCol>
                </MDBRow>
                <span className="filename">{this.state.fileName}</span>
                <div className='errorText'>{this.state.showError && 'Please upload excel/txt file having size less than 5MB'}</div>
                <div className='errorText'>{this.state.showDataError && 'Please upload file in correct format of data'}</div>
                <MDBRow>
                    <MDBCol md={8}></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton className="importButton"
                            text={this.state.isSubmit ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                "Import"
                            )}
                            variant="contained"
                            type="inline"
                            disabled={!this.state.fileName}
                            onClick={() => {
                                this.postImportCueSheet(this.state.fileName?.includes('.xlsx') ? 1 : 0)
                            }}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            text={"Cancel"}
                            variant="outlined"
                            onClick={this.props.handleClose}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
})