export const tempJSON  = [
    {
        "payload": {
            "show_search_ids": "421,648",
            "season_search_ids": null,
            "show_year_search_text": null,
            "episode_title_search_text": null,
            "episode_no_search_ids": null,
            "episode_r1_search_text": "1990",
            "episode_r2_search_text": "2020",
            "composition_search_text": null,
            "writer_search_ids": null,
            "production_entity_search_ids": null,
            "clearance_rep_search_ids": null,
            "licensor_search_text": null,
            "licensor_type_search_ids": null,
            "artist_search_text": null,
            "use_search_text": null,
            "init_right_search_ids": null,
            "option_right_search_ids": null,
            "term_search_ids": null,
            "territory_search_ids": null,
            "status_search_ids": null,
            "order_by_clause": null,
            "group_by_clause": null,
            "order_by": null,
            "show_search_comparison_op": "EQUAL",
            "show_season_comparison_op": "EQUAL",
            "show_episode_comparison_op": "EQUAL",
            "show_episode_title_comparison_op": "NOT_EQUAL",
            "show_year_search_comparison_op": null,
            "composition_search_comparison_op": null,
            "writer_search_comparison_op": null,
            "artist_search_comparison_op": null,
            "production_entity_search_comparison_op": null,
            "clearance_rep_search_comparison_op": null,
            "licensor_search_comparison_op": null,
            "licensor_type_search_comparison_op": null,
            "use_search_comparison_op": null,
            "init_right_search_comparison_op": null,
            "option_right_search_comparison_op": null,
            "term_search_comparison_op": null,
            "territory_search_comparison_op": null,
            "status_search_comparison_op": null,
            "air_date_start": null,
            "air_date_end": null,
            "fee_range_start": null,
            "fee_range_end": null,
            "batch": null,
            "selectedFields": [],
            "selectedGroupFields": [],
            "filters": [
                "Episode Range: 1990- 2020",
                "Show EQ: Main Title Test",
                "Season EQ: 2025-2026",
                "Episode Title NOTLIKE: qwerty",
                "Show EQ: A Bronx Life"
            ]
        },
        "value": 1,
        "label": "filter 1"
    },
    {
        "payload": {
            "prior_season_search_flag": 1,
            "show_search_ids": "458,1132",
            "season_search_ids": "64,18",
            "show_year_search_text": "6,7,13",
            "episode_title_search_text": "qwerty",
            "episode_no_search_ids": null,
            "episode_r1_search_text": "1990",
            "episode_r2_search_text": "2020",
            "composition_search_text": "asdfhj",
            "writer_search_ids": "67676",
            "production_entity_search_ids": "38",
            "clearance_rep_search_ids": "579",
            "licensor_search_text": "hjljhlj",
            "licensor_type_search_ids": "3",
            "artist_search_text": "20011",
            "use_search_text": "bnbmb",
            "init_right_search_ids": "58",
            "option_right_search_ids": "4",
            "term_search_ids": "9",
            "territory_search_ids": "141",
            "status_search_ids": "6",
            "order_by_clause": "Territory,Composition,Use/Timing",
            "group_by_clause": "Licensor Type,Use/Timing,Season",
            "order_by": null,
            "show_search_comparison_op": "NOT_EQUAL",
            "show_season_comparison_op": "EQUAL",
            "show_episode_comparison_op": "EQUAL",
            "show_episode_title_comparison_op": "NOT_EQUAL",
            "show_year_search_comparison_op": "NOT_EQUAL",
            "composition_search_comparison_op": "NOT_LIKE",
            "writer_search_comparison_op": "NOT_EQUAL",
            "artist_search_comparison_op": "EQUAL",
            "production_entity_search_comparison_op": "NOT_EQUAL",
            "clearance_rep_search_comparison_op": "EQUAL",
            "licensor_search_comparison_op": "NOT_LIKE",
            "licensor_type_search_comparison_op": "EQUAL",
            "use_search_comparison_op": "NOT_LIKE",
            "init_right_search_comparison_op": "NOT_EQUAL",
            "option_right_search_comparison_op": "EQUAL",
            "term_search_comparison_op": "EQUAL",
            "territory_search_comparison_op": "NOT_EQUAL",
            "status_search_comparison_op": "NOT_EQUAL",
            "air_date_start": "2024-11-01",
            "air_date_end": "2024-12-03",
            "fee_range_start": "1000",
            "fee_range_end": "2000",
            "batch": "NO",
            "selectedFields": [
                {
                    "value": 15,
                    "label": "Territory",
                    "icon": "check-circle"
                },
                {
                    "value": 7,
                    "label": "Composition",
                    "icon": "check-circle"
                },
                {
                    "value": 17,
                    "label": "Use/Timing",
                    "icon": "check-circle"
                }
            ],
            "selectedGroupFields": [
                {
                    "value": 12,
                    "label": "Licensor Type",
                    "icon": "check-circle"
                },
                {
                    "value": 17,
                    "label": "Use/Timing",
                    "icon": "check-circle"
                },
                {
                    "value": 2,
                    "label": "Season",
                    "icon": "check-circle"
                }
            ],
            "filters": [
                "Show NOTEQ: Vladimir Caamano Project",
                "Season EQ: 2016 - 2017",
                "Episode Title NOTLIKE: qwerty",
                "Episode Range: 1990- 2020",
                "Show Year NOTEQ: 6",
                "Show Year NOTEQ: 12",
                "Composition NOTLIKE: asdfhj",
                "Writer NOTEQ: Raystag",
                "Performer EQ:  Afrika Bambaataa and The Soul Sonic Force",
                "Production Entity NOTEQ: Delta Blues Productions LLC (WBTV)",
                "Licensor NOTLIKE: hjljhlj",
                "Licensor Type EQ: SM",
                "Initial Rights NOTEQ: All Media",
                "Optional Rights EQ: HOME VIDEO 06-07 6-YEAR BUYOUT 14 EPISODES OR MORE",
                "Territory NOTEQ: 20% BRTs",
                "Term EQ: 1 week",
                "Use Timing NOTLIKE: bnbmb",
                "Status NOTEQ: Hidden",
                "Clearance Rep EQ: Alicia Staffhorst",
                "Air Date: 2024-11-01 To 2024-12-03",
                "Fee: 1000 To 2000",
                "Batch: NO"
            ]
        },
        "value": 3,
        "label": "test filter"
    }

]

