import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import BasicCheckbox from '../../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextField from '../../../SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import DateField from '../../../SharedComponents/DateField/DateField';
import {getFormattedDate} from "../../../../Common/Helper"

class MasterLicensorModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            lic: props?.lic,
        }
    }
    handleChange(field, value) {
       
            this.setState(prevState => ({
                lic: {
                    ...prevState.lic,
                    [field]: value
                }
            }))
        
    }
    saveData() {
        let lic = this.state?.lic;
        let updatedFields = null;
        if (!this.props?.isMainTitle) {
            updatedFields = {
                is_mfn_with_S_M: lic?.is_mfn_with_S_M,
                is_license_for_sample: lic?.is_license_for_sample,
                is_apply_dollar_to_HV: lic?.is_apply_dollar_to_HV,
                recording_share: lic?.recording_share
            }
        } else {
            updatedFields = {
                is_mfn_with_S_M: lic?.is_mfn_with_S_M,
                is_flat_fee_buyout:lic?.is_flat_fee_buyout,
                is_WFH:lic?.is_WFH,
                is_allow_zero_cost:lic?.is_allow_zero_cost,
                is_apply_dollar_to_episodic_budget:lic?.is_apply_dollar_to_episodic_budget,
                is_broad_use_license:lic?.is_broad_use_license,
                contract_date:lic?.contract_date,
                addendum_date:lic?.addendum_date
            }
        }
       
        this.props?.onChangeCallback('MasterLicensorData', updatedFields)
        this.props.onClose();
    }
    render() {
        return (
            <MDBContainer fluid className="Licensor-Modal-Container">
                {!this.props.isMainTitle ?
                    <>
                        <div className="display-modal">
                            {/* <BasicCheckbox
                                id="mfnCheck"
                                label={"MFN w/[master/sync]"}
                                disabled={!this.props?.isEditing || false}
                                checked={!!this.state?.lic?.is_mfn_with_S_M || null}
                                onChange={(e) => this.handleChange('is_mfn_with_S_M', +e.target.checked)}
                            /> */}
                            <div className="sample-check">
                                <BasicCheckbox
                                    id="licenceForSampleCheck"
                                    label={"Licence for sample"}
                                    disabled={!this.props?.isEditing || false}
                                    checked={!!this.state?.lic?.is_license_for_sample || null}
                                    onChange={(e) => this.handleChange('is_license_for_sample', +e.target.checked)}
                                />
                            </div>
                        </div>
                        <BasicCheckbox
                            id="applyToHvCheck"
                            className="mt-1"
                            label={"Apply $ to Hv"}
                            disabled={!this.props?.isEditing || false}
                            checked={!!this.state?.lic?.is_apply_dollar_to_HV || null}
                            onChange={(e) => this.handleChange('is_apply_dollar_to_HV', +e.target.checked)}
                        />
                        {this.props?.songContainsSample && 
                        <MDBRow className="mt-2">
                            <MDBCol md={10} className="recording-share">
                                <div className="modal-text">Recording Share(song contains samples)</div>
                                <BasicTextField
                                    id={"next step"}
                                    value={this.state?.lic?.recording_share || null}
                                    disabled={!this.props?.isEditing || false}
                                    onChange={(e) => this.handleChange('recording_share', e.target.value)}
                                    />
                            </MDBCol>
                        </MDBRow>
                    }
                    </> : <div>
                        <div className="display-modal">
                            {/* <BasicCheckbox
                                id="mfnCheck-main"
                                label={"MFN w/[master/sync]"}
                                disabled={!this.props?.isEditing || false}
                                checked={this.state.lic?.is_mfn_with_S_M || null}
                                onChange={(e) => this.handleChange('is_mfn_with_S_M', +e.target.checked)}
                            /> */}
                            <div className="sample-check">
                                <BasicCheckbox
                                    id="is_flat_fee_buyout"
                                    label={"Flat Fee Buyout"}
                                    disabled={!this.props?.isEditing || false}
                                    checked={this.state.lic?.is_flat_fee_buyout || null}
                                    onChange={(e) => this.handleChange('is_flat_fee_buyout', +e.target.checked)}
                                />
                            </div>
                        </div>
                        <div className="display-modal mt-1">
                            <BasicCheckbox
                                id="is_WFH"
                                label={"WFH"}
                                disabled={!this.props?.isEditing || false}
                                checked={this.state.lic?.is_WFH || null}
                                onChange={(e) => this.handleChange('is_WFH', +e.target.checked)}
                            />
                            <div className="sample-check ml-5">
                                <BasicCheckbox
                                    id="is_allow_zero_cost"
                                    label={"Allow Zero Cost"}
                                    disabled={!this.props?.isEditing || false}
                                    checked={this.state.lic?.is_allow_zero_cost || null}
                                    onChange={(e) => this.handleChange('is_allow_zero_cost', +e.target.checked)}
                                />
                            </div>
                        </div>
                        <BasicCheckbox
                            id="is_apply_dollar_to_episodic_budget"
                            className="mt-1"
                            label={"Apply $ to Episodic Budget"}
                            disabled={!this.props?.isEditing || false}
                            checked={this.state.lic?.is_apply_dollar_to_episodic_budget || null}
                            onChange={(e) => this.handleChange('is_apply_dollar_to_episodic_budget', +e.target.checked)}
                        />
                        <BasicCheckbox
                            id="is_broad_use_license"
                            className="mt-1"
                            label={"Broad Use License"}
                            disabled={!this.props?.isEditing || false}
                            checked={this.state.lic?.is_broad_use_license || null}
                            onChange={(e) => this.handleChange('is_broad_use_license', +e.target.checked)}
                        />
                        <MDBRow className="mt-2">
                            <MDBCol md={6}>
                                <DateField
                                    label={"Contract Date"}
                                    value={getFormattedDate(this.state.lic?.contract_date,true) || null}
                                    onChange={(e) => this.handleChange('contract_date', e.target.value)}
                                />
                            </MDBCol>
                            <MDBCol md={6}>
                                <DateField
                                    label={"Addendum Date"}
                                    value={getFormattedDate(this.state.lic?.addendum_date,true) || null}
                                    onChange={(e) => this.handleChange('addendum_date',  e.target.value)}
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                }
                <MDBRow>
                    <MDBCol md={9}></MDBCol>
                    <MDBCol md={3}>
                        <MDBRow >
                            <MDBCol className="display-modal-2">
                                {this.props?.isEditing &&
                                    <BasicButton
                                        className={"modal-btn-save"}
                                        variant="contained"
                                        text=" "
                                        icon={"save"}
                                        onClick={this.saveData.bind(this)}
                                    />
                                }
                                <BasicButton
                                    className={"modal-btn"}
                                    variant="outlined"
                                    text=" "
                                    icon={"times"}
                                    onClick={this.props.onClose}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default MasterLicensorModal;
