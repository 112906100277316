import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import TabsComponent from '../../../components/SharedComponents/Tabs/Tabs';
import './Budget.scss';
import Pilot from './Pilot';
import SeriesPattern from './SeriesPattern';
import Amort from './Amort';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';

class PilotBudget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            subTabValue: 1,
            showError: false,
            showSuccess: false,
            showFail: false,
            fieldChanged: false,
        };
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <Pilot division_id={this.props.division_id} show_season_id={this.props?.show_season_id} initialRecordData={this.props?.initialRecordData}   notificationComponent={(value, msg=null) => this.setState({ openNotification: value, anyErrorMessage: msg })}
                 permissionArr={this.props?.permissionArr} {...this.props} />
                break
            case 2:
                return <SeriesPattern division_id={this.props.division_id} show_season_id={this.props?.show_season_id} initialRecordData={this.props?.initialRecordData}  notificationComponent={(value, msg=null) => this.setState({ openNotification: value, anyErrorMessage: msg })}
                permissionArr={this.props?.permissionArr} {...this.props} />
                break
            case 3:
                return <Amort division_id={this.props.division_id}  notificationComponent={(value, msg=null) => this.setState({ openNotification: value, anyErrorMessage: msg })}
                show_season_id={this.props?.show_season_id} initialRecordData={this.props?.initialRecordData} permissionArr={this.props?.permissionArr} {...this.props} />
                break
        }
    };
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        // if (this.state.saveFlag) {

        // }
    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    render() {
        // let severity = this.state.showSuccess ? "success" : this.state.showFail ? "error" : "";
        // let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : "";

        return (
            <div className="ShowSeason-Budget-Container">
                {/* <div>
                    <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                        handleResetNotify={this.handleResetNotify.bind(this)} />
                </div> */}
                <NotificationComponent
                    open={this.state.openNotification?.length > 0 ? true : false}
                    message={this.state.anyErrorMessage ? this.state.anyErrorMessage  : this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                    severity={this.state.openNotification || ''}
                    handleResetNotify={() => this.setState({ openNotification: null ,anyErrorMessage : null})}
                />
                <div>
                    <MDBRow className="tab-content">
                        <MDBCol md={10}>
                            <TabsComponent
                                tabList={this.props.pilotBudgetList}
                                value={this.state.tabValue}
                                onChange={(e, v) => this.handleTabChange(v)}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
                {this.getTabComponent()}
            </div>
        )
    }

}

export default PilotBudget;
