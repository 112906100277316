import React from "react";
import TableComponent from "../../SharedComponents/Table";
import { CueSheetsTabConfig } from "./config";
import "./CueSheetsTab.scss";
import * as Constants from "../../../constants/constants";
import ClearTrackService from "../../../services/service";
import { withUserContext } from "../../../contexts/UserContext";
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent";

let cueSheetTablePermissions;
class CueSheetsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      config: CueSheetsTabConfig,
      isFetchingCueSheets: false,
      downLoadRow:null,
      openNotification:null,
      anyErrorMessage:null,
    };
  }
  getShowSeasonCueSheets = () => {
    this.setState({ isFetchingCueSheets: true });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/showSeasonCueSheets?show_season_id=${this.props?.show_season_id}`,
      this.props?.userContext?.active_tenant?.tenant_id,
      this.props.division_id
    ).then((response) => {
      let cueSheets = response?.data?.map((item) => {
        if (item?.status) {
          item.status =
            item?.status === "unsubmitted"
              ? "Not Submitted"
              : item?.status?.charAt(0)?.toUpperCase() + item?.status?.slice(1);
        }
        return item;
      });
      let cueSheetConfig = { ...this.state.config };

      cueSheets.map((item) => {
        if (item.rc_xml_file_name) {
          item.ack = false;
          item.xml = false;
        } else {
          item.ack = true;
          item.xml = true;
        }

        return item;
      });
      let activeTenantArray = this.props?.userContext?.user_profile?.tenants?.find(item => item.tenant_id == this.props?.userContext?.active_tenant?.tenant_id)
      let activeDivisionArray = activeTenantArray?.division_role?.find(item => item.division_id == this.props.division_id)
      let cueSheetTablePermissions = activeDivisionArray?.permissions?.find(item => item.permission_name == "View Cue Sheet table: Cue Sheet Acknowledgement, XML, and CS buttons")
      cueSheetConfig?.buttonFields.map(item => item.viewPermission = cueSheetTablePermissions?.view)
      this.setState({
        renderList: cueSheets,
        isFetchingCueSheets: false,
        config: cueSheetConfig
      });
      
    });
  };

  componentDidMount() {;
    this.getShowSeasonCueSheets();
  }

  downloadUrl = (url) => {
    if (url) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    }
  };

  cueSheetDownload = (dataItem, nodeItem, rowIndex, event) => {
    this.setState({downLoadRow:dataItem?.cue_sheet_header_id})
    if (nodeItem === "cs") {
      let searchPayload = {
        show_season_id: dataItem.show_season_id,
        show_season_episode_id: dataItem.show_season_episode_id,
        cue_sheet_header_id: dataItem.cue_sheet_header_id,
        is_box_upload: 1,
      };
      Object.keys(searchPayload).map((obj) => {
        if (searchPayload[obj] === undefined) {
          searchPayload[obj] = null;
        }
      });
      let encryptTedStr = btoa(JSON.stringify(searchPayload));
      let load = {
        payload: "exportPdf",
        reportName: "rptCueSheetsReport",
        fileName: "cueSheetsReport",
        searchJson: encryptTedStr,
        showHtml: 0,
        encryptionType: 1,
        tenantId: this.props?.userContext?.active_tenant?.tenant_id,
        divisionId: this.props.division_id,
      };
      let queryParams = `reportName=rptCueSheetsReport&searchJson=${encryptTedStr}&fileName=cueSheetsReport`;
      ClearTrackService.getDataWS(load).then(
        (webSocketConn) =>
          (webSocketConn.onmessage = (e) => {
            let response = JSON.parse(e.data);
            if (response?.statusCode == 200) {
              this.setState({ downLoadRow: null });
              this.downloadUrl(response?.body);
            } 
          })
      );
    } else {
      if (nodeItem === "ack") {
        let json = {
          platform: "BOX",
          filename: `${dataItem?.rc_xml_file_name}_Ack`,
          divisionName: this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== dataItem?.division_id)?.division_name,
          divisionId: this.props?.division_id
        };
        ClearTrackService.getData(
          Constants.ClearTrackServiceBaseUrl +
            `/downloadFiles?input=${btoa(JSON.stringify(json))}`,
          this.props.userContext?.active_tenant?.tenant_id,
          this.props?.division_id,
          true
        ).then(
          (response) => {
            this.setState({ downLoadRow: null });
            if(response?.data?.error) {
              this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
            } else {
              this.downloadUrl(response.data.data[0].url);
            }
          },
          (err) => {
            this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
          }
        );
      } else if (nodeItem === "xml") {
        let json = {
          platform: "BOX",
          filename: `${dataItem?.rc_xml_file_name}`,
          divisionName: this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== dataItem?.division_id)?.division_name,
          divisionId:this.props?.division_id,
        };
        ClearTrackService.getData(
          Constants.ClearTrackServiceBaseUrl +
            `/downloadFiles?input=${btoa(JSON.stringify(json))}`,
          this.props.userContext?.active_tenant?.tenant_id,
          this.props?.division_id,
          true
        ).then(
          (response) => {
            this.setState({ downLoadRow: null });
            if(response?.data?.error) {
              this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
            } else {
              this.downloadUrl(response.data.data[0].url);
            }
          },
          (err) => {
            this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
          }
        );
      }
    }
  };

  navigationCallback = (obj, node) => {
    if (node === "episode") {
        window.open(`/episodeAndClearance/ss/${obj.show_season_id}/ep/${obj.show_season_episode_id}`, '_blank');
    }
}

  render() {
    return (
      <div id="cuesheet-tab" className="tableContainer">
        <NotificationComponent
          open={this.state.openNotification?.length > 0 ? true : false}
          message={this.state.anyErrorMessage ? this.state.anyErrorMessage  : this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
          severity={this.state.openNotification || ''}
          handleResetNotify={() => this.setState({ openNotification: null ,anyErrorMessage : null})}
        />
        <TableComponent
          list={this.state.renderList || []}
          config={this.state.config}
          isLoading={this.state.isFetchingCueSheets}
          handleOpenInlineModal={this.cueSheetDownload}
          hyperLinkNavigationCallback={this.navigationCallback}
          postInitiated={this.state.downLoadRow}
        />
      </div>
    );
  }
}

export default withUserContext(CueSheetsTab);
