import React from 'react';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import { businessAffairsDetails } from './Config';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import { withUserContext } from '../../../contexts/UserContext';
import { CircularProgress } from "@material-ui/core";

class BusinessAffairsDetails extends React.Component {
    render() {
        let personAddressDetail = [];
        this.props.detailsToDisplay?.address_1 ? personAddressDetail.push(this.props.detailsToDisplay.address_1) : null;
        this.props.detailsToDisplay?.address_2 ? personAddressDetail.push(this.props.detailsToDisplay?.address_2) : null;
        this.props.detailsToDisplay?.city ? personAddressDetail.push(this.props.detailsToDisplay?.city) : null;
        this.props.detailsToDisplay?.state ? personAddressDetail.push(this.props.detailsToDisplay?.state) : null;
        this.props.detailsToDisplay?.zip_code ? personAddressDetail.push(this.props.detailsToDisplay?.zip_code) : null;
        this.props.detailsToDisplay?.country_name ? personAddressDetail.push(this.props.detailsToDisplay?.country_name) : null;

        let agentAddressDetails = [];
        this.props.detailsToDisplay?.agent_address_1 ? agentAddressDetails.push(this.props.detailsToDisplay.agent_address_1) : null;
        this.props.detailsToDisplay?.agent_address_2 ? agentAddressDetails.push(this.props.detailsToDisplay?.agent_address_2) : null;
        this.props.detailsToDisplay?.agent_city ? agentAddressDetails.push(this.props.detailsToDisplay?.agent_city) : null;
        this.props.detailsToDisplay?.agent_state ? agentAddressDetails.push(this.props.detailsToDisplay?.agent_state) : null;
        this.props.detailsToDisplay?.agent_zip_code ? agentAddressDetails.push(this.props.detailsToDisplay?.agent_zip_code) : null;
        this.props.detailsToDisplay?.agent_country_name ? agentAddressDetails.push(this.props.detailsToDisplay?.agent_country_name) : null;
        return (
            <MDBContainer className="Details-Container">
                {this.props.isSecondaryLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (<>
                    <MDBRow>
                        <MDBCol md={3}>
                            <BasicLabel text={"Company"} />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"WorkPhone"} />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Cell Phone"} />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Email"} />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicLabel text={"Address"} />
                        </MDBCol>
                    </MDBRow>
                    {this.props.detailsToDisplay != undefined &&
                        <MDBRow>
                            <MDBCol md={3}>
                                <p className='content-text'>{this.props.detailsToDisplay?.company ? this.props.detailsToDisplay.company : "None"}</p>
                            </MDBCol>
                            <MDBCol md={2}>
                                <p className='content-text'>{this.props.detailsToDisplay?.work_phone ? this.props.detailsToDisplay?.work_phone : "None"}</p>
                            </MDBCol>
                            <MDBCol md={2}>
                                <p className='content-text'>{this.props.detailsToDisplay?.cell_phone ? this.props.detailsToDisplay.cell_phone : "None"}</p>
                            </MDBCol>
                            <MDBCol md={2}>
                                <p className='content-text'>{this.props.detailsToDisplay?.email ? this.props.detailsToDisplay.email : "None"}</p>
                            </MDBCol>
                            <MDBCol md={3}>
                                <p className='content-text'>{personAddressDetail.length > 0 ? personAddressDetail.map((item, index) => <span>{item}{index !== personAddressDetail.length - 1 && ", "}</span>) : "None"}</p>
                            </MDBCol>
                        </MDBRow>
                    }
                    {(this.props.detailsToDisplay?.agent_full_name) ? <BasicLabel text={this.props.detailsToDisplay.agent_full_name + "(Agent)"} /> : <BasicLabel text={"(Agent)"} />}
                    <MDBRow>
                        <MDBCol md={3}>
                            <BasicLabel text={"Company"} />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"WorkPhone"} />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Cell Phone"} />
                        </MDBCol>
                        <MDBCol md={2}>
                            <BasicLabel text={"Email"} />
                        </MDBCol>
                        <MDBCol md={3}>
                            <BasicLabel text={"Address"} />
                        </MDBCol>
                    </MDBRow>
                    {this.props.detailsToDisplay != undefined &&
                        <MDBRow>
                            <MDBCol md={3}>
                                <p className='content-text'>{this.props.detailsToDisplay?.agent_company ? this.props.detailsToDisplay?.agent_company : "None"}</p>
                            </MDBCol>
                            <MDBCol md={2}>
                                <p className='content-text'>{this.props.detailsToDisplay?.agent_work_phone ? this.props.detailsToDisplay?.agent_work_phone : "None"}</p>
                            </MDBCol>
                            <MDBCol md={2}>
                                <p className='content-text'>{this.props.detailsToDisplay?.agent_cell_phone ? this.props.detailsToDisplay?.agent_cell_phone : "None"}</p>
                            </MDBCol>
                            <MDBCol md={2}>
                                <p className='content-text'>{this.props.detailsToDisplay?.agent_email ? this.props.detailsToDisplay?.agent_email : "None"}</p>
                            </MDBCol>
                            <MDBCol md={3}>
                                <p className='content-text'>{agentAddressDetails.length > 0 ? agentAddressDetails.map((item, index) => <span>{item}{index !== agentAddressDetails.length - 1 && ", "}</span>) : "None"}</p>
                            </MDBCol>
                        </MDBRow>}
                </>)}

            </MDBContainer>
        )
    }
}

export default withUserContext(BusinessAffairsDetails);