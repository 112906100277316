import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MixDateReport.scss";
import sizeLimits from "../../../Common/Messages.json";
import TableComponent from "../../../components/SharedComponents/Table";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialpayeeCheckReportsDetails,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import { s2ab, genReportName } from '../../../Common/Helper';
class PayeeCheckReport extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      payeeCheckReports: initialpayeeCheckReportsDetails,
      showPayeeOptions: [],
      isPosting: false
    };
  }

  componentDidMount() {
    this.fetchPayeeData();
  }

  fetchPayeeData = () => {
    clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=LICENSOR_PAYEES&searchString=null`,
      this.props?.userContext?.active_tenant?.tenant_id, 1)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.payee_id, text: item.payee_company }));
        this.setState({ showPayeeOptions: formattedList, backUpShowPayeeOptions: formattedList }, () => { console.log(this.state.showPayeeOptions) });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err);
          this.setState({ isFetchingClearanceDetails: false });
        })
  }

  // handleSubmit = () => {
  //   console.log("submit", this.state.payeeCheckReports);
  // };
  clearFilters = () => {
    let values = this.state.payeeCheckReports;
    values = {}
    this.setState({
      payeeCheckReports: values
    })
  }

  handleChange = (field, value) => {
    this.setState((prevState) => ({
      payeeCheckReports: {
        ...prevState.payeeCheckReports,
        [field]: value,
      },
    }), () => {
        if (field === "check_pay_date_range_to" || field === "is_foreign_only") {
          let is_foreign_only = this.state.payeeCheckReports?.is_foreign_only ? 1 : 0
          console.log("is_foreign_only",is_foreign_only)
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/payeeList?start_date=${this.state.payeeCheckReports?.check_pay_date_range_from}&end_date=${this.state.payeeCheckReports?.check_pay_date_range_to}&is_foreign_only=${is_foreign_only}`,
          this.props?.userContext?.active_tenant?.tenant_id, 1)
          .then(response => {
            let formattedList = response.data?.map(item => ({ value: item.payee_id, text: item.payee_company }));
            this.setState({ showPayeeOptions: formattedList, backUpShowPayeeOptions: formattedList }, () => { console.log(this.state.showPayeeOptions) });
          },
            (err) => {
              console.log("Error in fetching Address Types:", err);
              this.setState({ isFetchingClearanceDetails: false });
            })
      }
    });
  };

  handleSubmitPayeeCheckReport = () => {
    this.setState({ postInitiated: true });
    let { payeeCheckReports, filterValues, seasonIdValues, showIdValues } = this.state;
    if (!payeeCheckReports.check_pay_date_range_from || !payeeCheckReports.check_pay_date_range_to) {
      return;
    }
    this.setState({ isPosting: true, postInitiated: false })
    let arr = [];
    // arr.push(payeeCheckReports?.selected_payees?.value)
    console.log("payeeCheckReports?.selected_payees", Object.keys(payeeCheckReports?.selected_payees).length)
    if (Object.keys(payeeCheckReports?.selected_payees).length > 0) {
      arr.push(payeeCheckReports?.selected_payees.map((item)=>item.value))
    }
    let payload = {
      start_date: payeeCheckReports.check_pay_date_range_from,
      end_date: payeeCheckReports.check_pay_date_range_to,
      is_foreign_only: payeeCheckReports?.is_foreign_only ? 1 : 0,
      report_title: payeeCheckReports?.notes,
      payee_id: arr
    }
    this.props.generateReport(this.props?.reportName, payload, "Payee Check")
    .then(webSocketConn => {                
      webSocketConn.onmessage = (e) => {                    
        let response = JSON.parse(e.data)                   
         if (response?.statusCode == 200) {    
            this.setState({isPosting: false})                                      
            this.props?.downloadUrl(response?.body);     
            // this.props?.notificationComponent(true, 'success')        
          } else {                    
            // this.props?.notificationComponent(true, 'fail')
          }             
      }         
    })
    // .then(response => {
    //   if (response.data.error) {
    //     this.setState({ isPosting: false, postInitiated: false })
    //     // this.props.fieldChanged(true, "reportNotFound");
    //   }
    //   else {
    //     let url = response.data;
    //     let obj = {
    //       reportName: this.props?.reportDownloadName,
    //       production: "",
    //       season: "",
    //       episode: "",
    //       performer: "",
    //     }
    //     this.setState({ isPosting: false, postInitiated: false })
    //     let reportNameFinal = genReportName(obj)
    //     this.props?.downloadUrl(url, reportNameFinal)
    //   }
    // }, (err) => {
    //   this.setState({ isPosting: false, postInitiated: false })
    //   console.log(err)
    //   // this.props.fieldChanged(true, "reportFail");
    // })
  }

  GenerateCleranceDetails = () => { };
  render() {
    return (
      <>
        {" "}
        <p>
          <b>Payee Check Report</b>
        </p>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MixDate-Container"
        >
          <MDBRow>
            <MDBCol md={3}>
              <BasicLabel text="Report Title" />
              <BasicTextArea
                value={this.state.payeeCheckReports?.notes}
                // label={"Report Title"}
                // isMandatory={true}
                limit={sizeLimits.exceed200CharacterWarning}
                onChange={(e) => this.handleChange("notes", e.target.value)}
              />
              <BasicCheckbox
                id="Subtitle"
                label={"Foreign Only"}
                checked={this.state.payeeCheckReports?.is_foreign_only || false}
                onChange={(e) =>
                  this.handleChange("is_foreign_only", e.target.checked)
                }
              />
            </MDBCol>
            <MDBCol md={3}>
              <BasicLabel text="Check Pay Date Range" />
              <MDBRow className="g-0 mt-2">
                <MDBCol md={4} id="DateField-1">
                  <DateField
                    id="date"
                    showMandatory={this.state.postInitiated || false}
                    value={
                      this.state.payeeCheckReports?.check_pay_date_range_from ||
                      ""
                    }
                    onChange={(e) =>
                      this.handleChange(
                        "check_pay_date_range_from",
                        e.target.value
                      )
                    }
                  />
                </MDBCol>
                <MDBCol md={4} id="DateField-2">
                  <DateField
                    id="MixDate"
                    showMandatory={this.state.postInitiated || false}
                    value={
                      this.state.payeeCheckReports?.check_pay_date_range_to ||
                      ""
                    }
                    onChange={(e) =>
                      this.handleChange(
                        "check_pay_date_range_to",
                        e.target.value
                      )
                    }
                    showErrorBorder={this.state.payeeCheckReports?.check_pay_date_range_from > this.state.payeeCheckReports?.check_pay_date_range_to ? true : false}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol md={2} className="payee-field">
              <BasicLabel text="Payees" />
              <SearchSelectField
                id={"Payees"}
                placeHolderText={"All Payees in Date Range"}
                multiple={true}
                value={
                  this.state.payeeCheckReports?.selected_payees?.length > 0
                    ? this.state.payeeCheckReports?.selected_payees.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                }
                // value={{ 'value': this.state.payeeCheckReports?.selected_payees?.value, 'text': this.state.payeeCheckReports?.selected_payees?.text } || {}}
                options={this.state.showPayeeOptions || []}
                onChange={(e, value) => this.handleChange("selected_payees", value)}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Generate PDF"} onClick={this.handleSubmitPayeeCheckReport} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"} onClick={this.clearFilters}/>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default withUserContext(PayeeCheckReport);
