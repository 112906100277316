import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import TableComponent from "../SharedComponents/Table";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  updateTableSortConfigObject,
  tableSortList,
} from "../../Common/TableHelper";
import { productionGroupConfig } from "./config";
import ClearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";

class ProductionGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      groupNotExist: false,
      groupDuplicate:false,
      sortBy: null,
      config: { ...productionGroupConfig },
      isSortByAsc: true,
      sortCount: 0,
      isProductionLoader: false,
      loadingOptions: false,
      productionList: [],
    };
  }

  componentDidMount() {
    this.getProductionGroups();
  }

  getProductionGroups = (loader = true) => {
    this.setState({ loadingOptions: loader });
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${"PRODUCTION_GROUP"}&searchString=${" "}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({ renderList: response?.data });
        let configRecord = { ...this.state.config };
        let formattedList = response.data?.map((item) => ({
          value: item.production_group_id,
          text: item.production_group_name,
        }));
        this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
        this.setState({
          config: configRecord,
          loadingOptions: false,
          isProductionLoader: false,
          productionList: formattedList,
        });
      },
      (err) => {
        console.log("Error in fetching production groups:", err);
      }
    );
  };

  handleInlineEdits = (id, dataItem) => {
    let productionGroups = [...this.state?.renderList];
    this.setState({
      groupNotExist: false,
      renderList: productionGroups.map((item) => {
        let newItem = { ...item };
        if (item.production_group_id == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ groupNotExist: false,groupDuplicate:false });
    if(!data[index]?.production_group_name || !data[index]?.production_group_id || !data[index]?.source_company_code){
      this.setState({groupNotExist:true})
    }
    else if(data[index]?.production_group_name && this.state?.renderList?.some((item,i)=>item?.production_group_name?.toLowerCase() === data[index]?.production_group_name?.toLowerCase() && i != index)){
      this.setState({groupDuplicate:true})
    }
    else {

   
      let productionGroups = [...this.state?.renderList];
      (productionGroups[index].production_group_id =
        data[index].production_group_id),
        (productionGroups[index].production_group_name =
          data[index].production_group_name || null),
        (productionGroups[index].source_company_code =
          data[index].source_company_code || null),
        (productionGroups[index].is_active = data[index].is_active ? 1 : 0);
      this.setState({
        renderList: productionGroups.map((item) => {
          item["editing"] = false;
          return item;
        }),
      });
      let editedList = this.state.renderList.filter(
        (item) => item.production_group_id === pkValue
      );
      let list = {
        production_group_id: editedList[0].production_group_id,
        production_group_name: editedList[0].production_group_name || null,
        source_company_code: editedList[0].source_company_code || null,
        is_active: editedList[0].is_active ? 1 : 0,
      };
      this.postProductionGroup(list, list?.production_group_id);
    } 
  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ groupNotExist: false,groupDuplicate:false });
    if(!newDataItem?.production_group_name || !newDataItem?.source_company_code){
      this.setState({groupNotExist:true})
    }
    else if(newDataItem?.production_group_name && this.state?.renderList?.some(item=>item?.production_group_name?.toLowerCase() === newDataItem?.production_group_name?.toLowerCase())){
      this.setState({groupDuplicate:true})
    }
    else {
      let addList = {
        production_group_name: newDataItem?.production_group_name,
        production_group_id: null,
        is_active: newDataItem?.is_active === true ? 1 : 0,
        source_company_code: newDataItem?.source_company_code || null,
      };
      this.postProductionGroup(addList, "newItemPost");
    } 
  };

  postProductionGroup = (payload, postFlag) => {
    this.setState({ isProductionLoader: postFlag });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/productionGroup`,
      payload,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then((response) => {
      if (response) {
        if (response.data.error) {
          this.setState({ isProductionLoader: false });
          this.props?.toastMessageNotification(true, "fail");
        } else {
          this.getProductionGroups(false);
          this.setState({ isProductionLoader: false });
          this.props?.toastMessageNotification(true, "success");
        }
      }
    });
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      });
    } else
      this.setState({ sortCount: 0 }, () => {
        this.handleTableSort(column);
      });
  };

  handleTableSort = (colName) => {
    this.setState(
      {
        isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      },
      () => {
        this.setState({
          sortBy: colName,
          config: updateTableSortConfigObject(
            this.state?.config,
            this.state.isSortByAsc,
            this.state.sortBy,
            colName
          ),
          renderList: tableSortList(
            colName === "Active" ? "Number" : "Name",
            this.getSortNode(colName),
            this.state.renderList,
            this.state.isSortByAsc
          ),
        });
      }
    );
  };

  getSortNode = (col) => {
    switch (col) {
      case "Production Group":
        return "production_group";
      case "Active":
        return "is_active";
    }
  };

  render() {
    let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="detailContainer">
        <br />
        {/* <MDBRow>
                    <span className="description">[Description]</span>
                </MDBRow> */}
        <MDBRow className="pd">
          <span className="errorText">
            {this.state.groupNotExist &&
              "Please enter Production Group  And Source Company code values"}
          </span>
          <span className="errorText">
            {this.state.groupDuplicate &&
              "Production Group already exist"}
          </span>
        </MDBRow>
        <MDBRow className="tableComponent">
          {this.state?.loadingOptions ? (
            <div className="ContentLoader">
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              // newIsActive={false}
              list={this.state.renderList || []}
              config={this.state?.config}
              handleAddNewItem={this.handleAddNewItem.bind(this)}
              editCallback={this.handleInlineEdits.bind(this)}
              saveCallback={this.saveInlineEdits.bind(this)}
              addItemToList={permissionValue?.edit === 1}
              stickyHeader={true}
              postInitiated={this.state.isProductionLoader}
              // isLoading={this.props?.isSaveData}
              newIsActive={true}
              readCallback={(index) => {
                let groupOptions = [...this.state.renderList];
                groupOptions[index].editing = false;
                this.setState({
                  renderList: groupOptions,
                  groupNotExist: false,
                });
              }}
              sortCallback={this.handleTableColumnClick}
            />
          )}
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default ProductionGroups;
