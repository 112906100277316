import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import TableComponent from "../../../SharedComponents/Table";
import { budgetefcRecipientConfig, budgetefcRecipientRecordConfig } from "./Config";
import * as Constants from "../../../../constants/constants";
import ClearTrackService from "../../../../services/service";
import SearchSelectField from "../../../SharedComponents/SearchSelectField";
import SelectField from "../../../SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../SharedComponents/BasicLabel/BasicLabel";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../../contexts/UserContext';
import { validateEmail } from '../../../../Common/Helper';
import axios from "axios";
import { updateTableSortConfigObject, tableSortList } from '../../../../Common/TableHelper';


class BudgetvsEFCRecipient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      config: { ...budgetefcRecipientConfig },
      selectedSeason: null,
      budgetEfcRecipientDetails: {
        selected_program: null,
        season_id: null,
        division_id: null,
      },
      showOptions: [],
      divisionOptions: [],
      loadingShows: false,
      isLoading: false,
      searchCancelToken: null,
      mandatoryNotExist: false,
      isFetchingSeason: false,
      sortBy: "Value",
      isSortByAsc: true,
      sortCount: 0,
      allShowOption: { text: 'All', value: 'ALL', division_id: null }
    };
  }

  componentDidMount() {
    this.setState({ showOptions: [this.state.allShowOption] })
    this.handleShowEdit("selected_program", this.state.allShowOption);
    this.getDivisions();
    this.getProgramsDetails(' ')
  }
  getDivisions = () => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
      '/staticData?staticTable=division', this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let divisions = response.data?.map(item => ({
          label: item.name,
          value: item.id,
        }));
        this.setState({ divisionOptions: divisions }, () => {
          this.handleDivisionId('division_id', divisions[0]?.value)
        })
      }
      )
  }

  // getSeasonDetails = (entityType, searchString) => {
  //   this.setState({ isFetchingSeason: true })
  //   ClearTrackService.getData(
  //     Constants.ClearTrackServiceBaseUrl +
  //     `/entitySearch?entity=${entityType}&searchString=${searchString}`,
  //     this.props?.userContext?.active_tenant?.tenant_id
  //   )
  //     .then((response) => {
  //       let budgetRecipientDetails = response.data?.filter(
  //         (item, index, data) =>
  //           index === data.findIndex((t) => t.season_name === item.season_name && item.season_id !== null)
  //       );
  //      let formattedList = budgetRecipientDetails?.map((item) => ({
  //         value: item.season_id,
  //         label: item.season_name,
  //       }));
  //       formattedList.unshift(this.state.allShowOption)
  //         this.setState({ showOptions: formattedList, loadingShows: false,mandatoryNotExist: false, });
  //     })
  //     .catch((err) => {
  //       console.log("Error in fetching Details:", err);
  //     });
  // };

  getProgramsDetails = (value) => {
    // if (event.target.value !== "") {
      this.setState({ loadingShows: true });
      if (this.state.searchCancelToken != null)
        this.state.searchCancelToken.cancel("Operation canceled due to new request.");
      var cancelToken = axios.CancelToken.source();
      this.setState({ searchCancelToken: cancelToken });
      ClearTrackService.getDataWithCancel(
        Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=SHOW&searchString=${value}`, cancelToken,
        this.props?.userContext?.active_tenant?.tenant_id, null
      )
        .then((res) => {
          let formattedList = res.data.map((item) => ({
            value: item.show_id,
            text: item.show_name,
            division_id: item?.division_id
          }));
          formattedList.unshift(this.state.allShowOption)
          this.setState({ showOptions: formattedList, loadingShows: false, mandatoryNotExist: false, });
        }),
        ((err) => {
          this.setState({ showOptions: [], loadingShows: false });
        });
    //}
  };

  selectedShow = (newValue) => {
    if (newValue && newValue?.value !== 'ALL') {
      this.handleShowEdit("selected_program", newValue);
      this.handleShowEdit("season_id", null);
      this.setState({ isFetchingSeason: true })
      ClearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
        `/seasonsBasedOnShows?show_id=${newValue.value}`,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {

          let selectedShowSeasons = response?.data[0]?.seasons?.length > 0 ? response?.data[0]?.seasons?.filter(
            (item, index, data) =>
              index === data.findIndex((t) => t.season_name === item.season_name && item.show_season_id !== null)
          ) : [];
          let formattedList = selectedShowSeasons?.length > 0 ? selectedShowSeasons?.map((item) => ({
            value: item.show_season_id,
            label: item.season_name,
          })) : [];
          if (formattedList.length > 0) {
            this.handleShowEdit("season_id", formattedList[0].value)
          }
          this.setState({ mandatoryNotExist: false, isFetchingSeason: false, renderList: [], seasonOptions: formattedList.length > 0 ? formattedList : [] }, this.getProgramsRenderList)

        },
        (err) => {
          console.log("Error in fetching Details:", err);
        }
      );
    } else {
      this.handleShowEdit("selected_program", newValue ? newValue : null);
      this.handleShowEdit("season_id", null);
      this.setState({ renderList: [], seasonOptions: [] }, () => {
        if (newValue) {
          this.getProgramsRenderList()
        }
      })
    }
  };
  handleDivisionId = (field, value) => {
    this.setState((prevState) => ({
      budgetEfcRecipientDetails: {
        ...prevState.budgetEfcRecipientDetails,
        [field]: value,
      },
    }), () => {
      let configRecord = JSON.parse(JSON.stringify({...this.state.config}))
      if(this.state.budgetEfcRecipientDetails.division_id === 1){
        configRecord = {...budgetefcRecipientRecordConfig}
      }else{
        configRecord = {...budgetefcRecipientConfig}
      }
      this.setState({config: configRecord})
      if (this.state.budgetEfcRecipientDetails?.selected_program) {
        this.getProgramsRenderList()
      }
    });
  };


  getProgramsRenderList = (loader = true) => {
    this.setState({ isLoading: loader });
    let path = this.state.budgetEfcRecipientDetails?.selected_program?.value !== 'ALL' ?
      `show_season_id=${this.state.budgetEfcRecipientDetails?.season_id}&season_division_id=null` : `show_season_id=null&season_division_id=${this.state.budgetEfcRecipientDetails?.division_id}`
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/showSeasonBudgetVsEfcRecipients?${path}`,
      this.props?.userContext?.active_tenant?.tenant_id,
      this.state.budgetEfcRecipientDetails?.selected_program?.division_id ? this.state.budgetEfcRecipientDetails?.selected_program?.division_id : null
    )
      .then((response) => {
        let list = response?.data?.map((item) => ({
          name: item.name,
          email: item.email,
          company: item.company,
          is_wb: item.is_wb ? 1:0,
          is_horizon: item.is_horizon? 1:0,
          id: this.state.budgetEfcRecipientDetails?.selected_program?.value !== "ALL" ? item.show_season_budget_vs_efc_recipients_id : item?.season_budget_vs_efc_recipients_id,
          is_active: item.is_active,

        }));
        let configRecord = {...this.state.config}
        this.props?.permissionArray[0].edit === 0 ? configRecord.actions = ["times"] :
        this.props?.permissionArray[0].delete === 0 ? configRecord.actions = ["pen"] :  configRecord.actions = ["pen","times"],
        this.setState({
          renderList: list?.length > 0 ? list : [],
          config: configRecord,
          isLoading: false,
          isSubmit: false
        });
        this.handleTableColumnClick("Name");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("Error in fetching  Details:", err);
      });
  };

  // handleSeasonDetails = (newValue) => {
  //   this.handleShowEdit("season_id", newValue);
  //   this.setState({ isLoading: true });
  //   let path = this.state.budgetEfcRecipientDetails?.selected_program ? `show_season_id=${newValue}&season_id=null` : `show_season_id=null&season_id=${newValue}`
  //   this.getProgramsRenderList(path);
  // };

  handleInlineEdits = (id, dataItem) => {
    let allDetails = [...this.state.renderList];
    this.setState({
      mandatoryNotExist: false,
      renderList: allDetails.map((item) => {
        let newItem  = {...item}
        if (item.id == id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index, pkValue, dataItem) => {
    this.setState({ mandatoryNotExist: false })
    if (data[index]?.name && data[index]?.name?.length > 0 && data[index]?.email && data[index]?.email.length > 0 && validateEmail(data[index]?.email)) {
      //let dataList = [...data];
      let allDetails=[...this.state.renderList]
      if (this.state.budgetEfcRecipientDetails?.selected_program?.value !== "ALL") {
        allDetails[index].show_season_budget_vs_efc_recipients_id = data[index]?.id;
      } else if(this.state.budgetEfcRecipientDetails?.selected_program?.value === "ALL") {
        allDetails[index].season_budget_vs_efc_recipients_id = data[index]?.id;
        allDetails[index].is_wb =  data[index].is_wb ? 1:0 ;
        allDetails[index].is_horizon =data[index].is_horizon ? 1:0;
      }
      allDetails[index].name=data[index].name;
      allDetails[index].email=data[index].email;
      allDetails[index].company=data[index].company;
      allDetails[index].show_id = this.state.budgetEfcRecipientDetails?.selected_program?.value !== 'ALL' ? this.state.budgetEfcRecipientDetails?.selected_program?.value : null,
      allDetails[index].type = this.state.budgetEfcRecipientDetails?.selected_program?.value !== 'ALL' ? "SHOW" : "SEASON",
      allDetails[index].show_season_id = this.state.budgetEfcRecipientDetails?.selected_program?.value === 'ALL' ? null: this.state.budgetEfcRecipientDetails?.season_id ;
      allDetails[index].division_id = this.state.budgetEfcRecipientDetails?.selected_program?.value === 'ALL' ? this.state.budgetEfcRecipientDetails?.division_id : this.state.budgetEfcRecipientDetails?.selected_program?.division_id
      this.postBudgetEFCRecipientData(allDetails[index],  allDetails[index]?.id);
    } else {
      this.setState({ mandatoryNotExist: true })
    }

  };

  handleAddNewItem = (newDataItem) => {
    this.setState({ mandatoryNotExist: false })
    if (newDataItem?.name && newDataItem?.name?.length > 0 && newDataItem?.email && newDataItem?.email.length > 0 && validateEmail(newDataItem?.email)) {
      let newEFCRecipientData = {

        is_active: 1,
        name: newDataItem.name || null,
        email: newDataItem.email || null,
        company: newDataItem.company || null,
        is_wb: newDataItem.is_wb ? 1 : 0,
        is_horizon: newDataItem.is_horizon ? 1 : 0,
        show_season_id: this.state.budgetEfcRecipientDetails?.selected_program?.value === 'ALL' ? null : this.state.budgetEfcRecipientDetails?.season_id,
        type: this.state.budgetEfcRecipientDetails?.selected_program?.value !== 'ALL' ? "SHOW" : "SEASON",
        show_id: this.state.budgetEfcRecipientDetails?.selected_program?.value !== 'ALL' ? this.state.budgetEfcRecipientDetails?.selected_program?.value : null,
        division_id: this.state.budgetEfcRecipientDetails?.selected_program?.value === 'ALL' ? this.state.budgetEfcRecipientDetails?.division_id : this.state.budgetEfcRecipientDetails?.selected_program?.division_id
      };
      this.postBudgetEFCRecipientData(newEFCRecipientData, "newItemPost");
    } else {
      this.setState({ mandatoryNotExist: true })
    }
  };

  postBudgetEFCRecipientData = (recipientDetails, postFlag) => {
    this.setState({ isSubmit: postFlag });
    ClearTrackService.postDataParams(
      Constants.ClearTrackServiceBaseUrl + `/showSeasonBudgetVsEfcRecipients`,
      recipientDetails,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        if (response.data.error) {
          this.setState({
            isSubmit: false,
          });
          this.props.toastMessageNotification(true, "fail");
        } else {
          // this.setState({
          //   isSubmit: false,
          // });
          this.props.toastMessageNotification(true, "success");
          if (recipientDetails?.editing) {
            recipientDetails.editing = false;
          }

          this.getProgramsRenderList(false);
        }
      },
      (err) => {
        this.setState({ isSubmit: false });
        this.props.toastMessageNotification(true, "fail");
      }
    );
  };


  handleShowEdit = (field, value, callList = false) => {
    this.setState((prevState) => ({
      budgetEfcRecipientDetails: {
        ...prevState.budgetEfcRecipientDetails,
        [field]: value,
      },
    }), () => {
      if (this.state.budgetEfcRecipientDetails?.selected_program?.value === "ALL") {
        let configRecord = { ...this.state.config }
        configRecord = { ...budgetefcRecipientRecordConfig }
        this.setState({ config: configRecord })
      } else {
        let configRecord = { ...this.state.config }
        configRecord = { ...budgetefcRecipientConfig }
        this.setState({ config: configRecord })
      }
    });

  };


  deleteBudgetVsEFCRecipient = (recipientDetails) => {
    this.setState({ isSubmit: true })
    let tableName = this.state.budgetEfcRecipientDetails?.selected_program?.value !== 'ALL' ? 'show_season_budget_vs_efc_recipients' : 'season_budget_vs_efc_recipients'
    // let tableName = this.state.budgetEfcRecipientDetails?.selected_program?.value ? 'show_season_budget_vs_efc_recipients' : 'season_budget_vs_efc_recipients'
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=${tableName}&tableId=${recipientDetails?.id}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(res => {
      if (res.data[0].status === "SUCCESS") {
        let recipientData = {};
        if (this.state.budgetEfcRecipientDetails?.selected_program?.value !== 'ALL') {
          recipientData = {
            ...recipientDetails,
            is_delete: 1,
            show_season_id: this.state.budgetEfcRecipientDetails?.season_id,
            show_id: this.state.budgetEfcRecipientDetails?.selected_program?.value || null,
            show_season_budget_vs_efc_recipients_id: recipientDetails?.id,
            division_id: this.state.budgetEfcRecipientDetails?.selected_program?.division_id || null,
            type: 'SHOW'
          }
        } else {
          recipientData = {
            ...recipientDetails,
            is_delete: 1,
            season_budget_vs_efc_recipients_id: recipientDetails?.id,
            division_id: this.state.budgetEfcRecipientDetails?.division_id,
            type: 'SEASON',
            // show_season_id: this.state.budgetEfcRecipientDetails?.season_id,
            // show_id: null,
            // season_budget_vs_efc_recipients_id: recipientDetails?.id,
            // division_id: null,
          }
        }
        this.postBudgetEFCRecipientData(recipientData, recipientDetails?.id)
      } else {
        this.props?.fieldChanged(true, 'fail')
        this.setState({ isSubmit: false });
      }
    }),
      ((err) => {
        this.props?.fieldChanged(true)
        this.setState({ isSubmit: false });
      });
  };

  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
      config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName)
    }, () => {
      this.setState({
        sortBy: colName,
        renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Name": return "name";
      case "Email": return "email";
      case "Company": return "company";
    }
  }

  render() {
    let permissionValue = this.props?.permissionArray[0]
    console.log("value",permissionValue)
    return (
      <div className="Efc-Container">
        <MDBRow>

          <MDBCol md={2} className="inline-block">
            {this.state.isFetchingSeason ? <CircularProgress /> :
              <SelectField
                id={"key27"}
                label="Seasons"
                //isMandatory={true}
                value={this.state.budgetEfcRecipientDetails?.season_id}
                disabled={this.state.budgetEfcRecipientDetails?.selected_program?.value === 'ALL'}
                options={this.state.seasonOptions}
                width="80%"
                multiple={false}
                onChange={(e) => this.handleShowEdit('season_id', e.target.value, true)}
              />}
          </MDBCol>
          <MDBCol md={3} className="inline-block">
            <BasicLabel text="Program" />
            <SearchSelectField
              id={"key27"}
              detail_selected={
                this.state.budgetEfcRecipientDetails?.selected_program
              }
              options={this.state.showOptions}
              loading={this.state.loadingShows}
              placeholder={'- Search -'}
              width="80%"
              multiple={false}
              className="margint"
              searchSelect={true}
              searchText={(e) => {this.getProgramsDetails(e.target.value)}}
              valueSelected={(e, newValue) => this.selectedShow(newValue)}
            />
          </MDBCol>
          {this.state.budgetEfcRecipientDetails?.selected_program?.text === "All" ?
            <MDBCol md={2} className="inline-block marginl">
              {this.state.budgetEfcRecipientDetails?.selected_program?.value === 'ALL' && <SelectField
                id={"key27"}
                label="Division"
                options={this.state.divisionOptions}
                value={this.state.budgetEfcRecipientDetails?.division_id}
                detail_selected={this.state.budgetEfcRecipientDetails?.division_id}
                onChange={(e) => this.handleDivisionId("division_id", e.target.value)}
                width="80%"
                multiple={false}

              />}
            </MDBCol> : null}

        </MDBRow>
        <span className="errorText">{this.state.mandatoryNotExist && `Please enter name and email field's value`}</span>
        {this.state.isLoading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <MDBRow className="mt-2">
            <MDBCol md={4} id="table-bg-sync-efc">
              <TableComponent
                list={this.state.renderList || []}
                stickyHeader={true}
                className={"clearencRecord"}
                config={this.state.config}
                handleAddNewItem={permissionValue.edit === 1 ? this.handleAddNewItem.bind(this) : null}
                editCallback={permissionValue.edit === 1 ? this.handleInlineEdits.bind(this) : null}
                saveCallback={permissionValue.edit === 1 ? this.saveInlineEdits.bind(this) : null}
                addItemToList={true}
                // isLoading={this.state.isSubmit}
                postInitiated={this.state.isSubmit}
                handleDelCallBack={(id, item) => permissionValue.delete === 1 ?
                  this.deleteBudgetVsEFCRecipient(item) : null
                }
                readCallback={(index) => {
                  let budgetEFCData = [...this.state.renderList];
                  budgetEFCData[index].editing = false
                  this.setState({ renderList: budgetEFCData, mandatoryNotExist: false });
                }}
                sortCallback={this.handleTableColumnClick}
              />
            </MDBCol>

          </MDBRow>
        )}
      </div>
    );
  }
}

export default withUserContext(BudgetvsEFCRecipient);
