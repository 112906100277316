import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class LicenseTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            typeNotExist:false,
            typeDuplicate:false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config:JSON.parse(JSON.stringify({ ...this.props?.config}))
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps?.list !== this.props?.list && this.props?.list?.length > 0) {
            let configRecord = {...this.state.config}
            this.props?.permissionArray?.edit === 1 ? configRecord.actions = ["pen"] : configRecord.actions = [ ]
            this.setState({renderList:this.props.list, sortCount:0,sortBy:null,isSortByAsc: true,config: configRecord}, () => {
                this.handleTableColumnClick('License Type')
            })
        }
    }

    handleInlineEdits = (id, dataItem) => {
        let licenseTypes = [...this.state?.renderList]
        this.setState({
            typeNotExist: false,
            renderList: licenseTypes.map((item) => {
                let newItem = {...item}
                if (item.id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({typeNotExist:false,typeDuplicate:false})
        if (!data[index]?.license_type || data[index]?.license_type?.length == 0) {
            this.setState({ typeNotExist: true })
        } else if (data[index]?.license_type && this.state?.renderList?.some((item, i) => data[index]?.license_type?.toLowerCase() === item?.license_type?.toLowerCase() && index != i)) {
            this.setState({ typeDuplicate: true })
        }
        else{
            let licenseTypes = [...this.state?.renderList]
            licenseTypes[index].id = data[index].id,
                licenseTypes[index].license_type = data[index].license_type || null,
                licenseTypes[index].is_active = data[index].is_active ? 1 : 0
            this.setState({
                renderList: licenseTypes.map((item) => {
                    item['editing'] = false
                    return item
                })
            })
            let editedList = this.state.renderList.filter(item => item.id === pkValue);
            let list = {
                id: editedList[0].id,
                name: editedList[0].license_type || null,
                is_active: editedList[0].is_active ? 1 : 0
            }
            this.props.handleSave(list, "lu_license_type", list?.id);
        } 
    }

    handleAddNewItem = (newDataItem) => {
        this.setState({ typeNotExist: false })
        if (Object?.keys(newDataItem)?.length == 0 || !newDataItem?.license_type || newDataItem?.license_type?.length == 0) {
            this.setState({ typeNotExist: true })
        } else if (newDataItem?.license_type && this.state?.renderList?.some((item) => newDataItem?.license_type?.toLowerCase() === item?.license_type?.toLowerCase())) {
            this.setState({ typeDuplicate: true })
        }
        else{
       
            let addList = {
                id: null,
                name: newDataItem.license_type || null,
                is_active: 1,
            }
            this.props.handleSave(addList, "lu_license_type", 'newItemPost');
        } 
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column);
        })
    }

    handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
            sortBy: colName,
            renderList: tableSortList(colName === 'Active' ? 'Number':"Name", this.getSortNode(colName), this.state?.renderList, this.state.isSortByAsc)
          });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "License Type": return "license_type";
            case "Active": return "is_active";
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="detailContainer">
                <br/>
                {/* <MDBRow>
                    <span className="description">[description]</span>
                </MDBRow> */}
                <MDBRow className='pd'>
                <span className="errorText">{this.state.typeNotExist && 'Please enter License Type field value'}</span>
                <span className="errorText">{this.state.typeDuplicate && 'License Type already exist'}</span>
                
                </MDBRow>
                {this.props?.loadingOptions ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBRow className="tableComponent">
                            <TableComponent
                                newIsActive={true}
                                list={this.state?.renderList || []}
                                config={this.state?.config}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1 ? true : false}
                                postInitiated={this.props?.isSaveData}
                                //isLoading={this.props?.isSaveData}
                                readCallback={(index) => {
                                    let licenseTypes = [...this.state.renderList];
                                    licenseTypes[index].editing = false
                                    this.setState({ renderList: licenseTypes, typeNotExist:false });
                                }}
                                stickyHeader={true}
                                sortCallback={this.handleTableColumnClick}
                            />
                        </MDBRow>
                )}
            </MDBContainer>
        )
    }
}

export default LicenseTypes;