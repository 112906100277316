import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import TableComponent from '../../SharedComponents/Table';
import {rejectedHeading } from './config';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import './CueSheetAcknowledgementWarnings.scss';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';


class Rejected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: JSON.parse(JSON.stringify({ ...rejectedHeading })),
            renderList: [],
            showAllReps: false,
            isLoading:false,
            selectedItem:null,
        }
    }

    componentDidMount = () => {
        this.getData()
     } 
 
     getData = (show_all_reps = 0) => {
        this.setState({isLoading:true})
         ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
             `/cueSheetAck?type=REJECTED&show_all_reps=${show_all_reps}`,
             this.props.userContext?.active_tenant?.tenant_id, null)
             .then(response => {
                let formattedList = response.data?.map(item => {
                    let newItem = {...item}
                    newItem.check = 0
                    newItem.CS = false
                    if(item?.file_name) {
                        newItem.Ack = false
                        newItem.XML = false
                    } else {
                        newItem.Ack = true
                        newItem.XML = true
                    }
                    return newItem
                })
                 this.setState({ renderList : formattedList,isLoading:false ,selectedItem:null})
             })
     }


    cueSheetDownload = (dataItem, node) => {
        this.props?.setDownLoadRow(dataItem?.rc_xml_cue_id)
        this.props?.cueSheetDownload(dataItem, node, this.props?.userContext?.active_tenant?.tenant_id)
    }

    render() {
        let errorList = this.state.renderList?.find(item => item?.rc_xml_cue_id === this.state.selectedItem )
        return (
            <div id="warnigs">
                <div className='between mt-2'>
                    <div>
                        <span className='ml5 f-red'>The  Cue Sheets have been rejected. Click on a airing to view its errors</span>
                    </div>
                    <div className='p5 p-r2'>
                        <div>
                        <BasicCheckbox
                                id={"rejectedCueSheetACkShowAllRep"}
                                label='Show All Clearance Reps'
                                checked={this.state.showAllReps}
                                onChange={(e) =>
                                   this.setState({showAllReps : e.target.checked}, () => {
                                        this.getData(this.state.showAllReps ?  1 : 0)
                                   })
                                }
                            />
                        </div>
                    </div>
                </div>
                <div id="bg-header" className='table-component mt-2'>
                    <TableComponent
                        list={this.state.renderList || []}
                        config={this.state.config}
                        isLoading={this.state.isLoading}
                        hyperLinkNavigationCallback={(dataItem, node) => {this.setState({selectedItem : dataItem?.rc_xml_cue_id})}}
                        handleOpenInlineModal={this.cueSheetDownload}
                        postInitiated={this.props.downLoadRow}
                    />                 
                </div>
                {this.state.selectedItem && <div className='mt-2 warning-error-list'>
                <span className="label-with-cursor"  onClick={() => {this.setState({selectedItem:null})}}>Close Errors List</span>
                        <div className="file-selected-header">Errors for {errorList?.file_name}</div>
                        {errorList?.errors?.length > 0 ? errorList?.errors?.map((item,index) => <div key={item?.rc_xml_cue_error_id}>{index + 1}. {item?.description}</div>) :
                        <div>No Errors</div>}
                </div>}
                <MDBRow className='mt-2'>
                    <MDBCol sm={10}>

                    </MDBCol>
                    <MDBCol sm={2}>
                        <BasicButton
                            onClick={() => this.props.handleClose()}
                            variant="outlined"
                            text={"Close"}
                        />
                    </MDBCol>
                </MDBRow>
            </div>

        )
    }
}

export default withUserContext(Rejected);
