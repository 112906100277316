import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import TabsComponent from '../../../components/SharedComponents/Tabs/Tabs'
import { LicensorTabList, NewLicensorTabList, MSLicensorTabList } from "../../../constants/TabList";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import "./EditLicensor.scss";
import LicensorDetails from '../Licensor/LicensorDetails'
import LicensorContacts from '../Licensor/LicensorContacts'
import LicensorPayee from '../Licensor/LicensorPayee'
import CLRecords from '../Licensor/CLRecords';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import ClearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';
import { mockJson, mock_akas_list } from './Config';
import MessageModal from '../../../components/SharedComponents/MessageModal';
import CircularProgress from "@material-ui/core/CircularProgress";

class EditLicensor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1,
      subTabValue: 1,
      showChangeContactModal: false,
      showLicenseEntityInfo: false,
      licensorData: this.formatLicensorDetails(JSON.parse(JSON.stringify({ ...mockJson }))),
      contactsData: [],
      address_history_list: [],
      stateList: [],
      countryList: [],
      payees_list: [],
      payees_options: [],
      payeeOptions: [],
      saveTitle: "Success",
      saveEditMessage: "The changes are saved successfully!",
      failTitle: "Failure",
      failMessage: "Saving changes failed!",
      unsavedTitle: "Unsaved Changes",
      unsavedMessage: "Are you sure you want to discard your changes?",
      showSuccess: false,
      showFail: false,
      dataLoading: false,
      postingData: false,
      clearanceRecordList: [],
      currentAddress: null,
      errorFlag: null,
      deletedContacts:[],
      disabledSave:false
    };
  }
  componentDidMount() {
    if (this.props?.mode === "Edit") {
      this.getLicensorData(this.props?.id);
      this.getClearanceRecordList("LICENSOR", this.props?.id);
    }
    this.getStateList();
    this.getCountryList();
    if (this.props?.userContext?.active_tenant?.division_role?.some(item => item.role_name !== 'Music Supervisor')){
      this.getPayeesList();
    }
  }


  formatLicensorDetails = (response) => {
    if (response?.licensor_akas?.length === 0)
      response?.licensor_akas.push(JSON.parse(JSON.stringify({ ...mock_akas_list })))
    return response;
  }

  handleTabChange = (newTabValue, type) => {
    this.setState({
      [type]: newTabValue,
    });
  };
  handleShowLicenseEntityInfo = () => {
    this.setState({ showLicenseEntityInfo: true });
  }
  handleHideLicenseEntityInfo = () => {
    this.setState({ showLicenseEntityInfo: false });
    
  }
  handleshowChangeContactModal = () => {
    this.setState({ showChangeContactModal: true });
  }
  handleHideChangeContactModal = () => {
    this.setState({ showChangeContactModal: false });
  }
  onAkaChange = (akaIndex, value) => {
    let data = this.state.licensorData
    data.licensor_akas[akaIndex].aka = value || null
    data.is_aka_updated = 1
    this.setState({ licensorData: data })
    this.fieldChanged(true, "error")
  }
  getTabComponent = (canEditLicensorInformation, canAddNewLicensorInformation) => {
    switch (this.state.tabValue) {
      case 1:
        return <LicensorDetails
          disabled={(canEditLicensorInformation ? canEditLicensorInformation === 0 : true) && canAddNewLicensorInformation === 0 && this.props?.mode !== "New"}
          licensorData={this.state.licensorData}
          address_history_list={this.state.address_history_list}
          stateList={this.state.stateList}
          countryList={this.state.countryList}
          handleshowChangeContactModal={this.handleshowChangeContactModal}
          handleHideChangeContactModal={this.handleHideChangeContactModal}
          handleShowLicenseEntityInfo={this.handleShowLicenseEntityInfo}
          handleHideLicenseEntityInfo={this.handleHideLicenseEntityInfo}
          handleGeneralEdit={this.handleGeneralEdit}
          handleCheck={this.handleCheck}
          onAkaChange={this.onAkaChange}
          postInitiated={this.state.postInitiated} />;
      case 2:
        return <LicensorContacts
          disabled={(canEditLicensorInformation ? canEditLicensorInformation === 0 : true) && canAddNewLicensorInformation === 0 && this.props?.mode !== "New"}
          contactsData={this.state.contactsData}
          licensorData={this.state.licensorData}
          fieldChanged={this.fieldChanged}
          handleDisabledSave={this.handleDisabledSave}
          updateDeletedContacts={(item) => {
            let deletedContacts = [...this.state.deletedContacts]
            deletedContacts.push(item)
            this.setState({deletedContacts})
          }}
        />;
      case 3:
        return <LicensorPayee
          //payees_list={this.state.payees_list}
          disabled={(canEditLicensorInformation ? canEditLicensorInformation === 0 : true) && canAddNewLicensorInformation === 0 && this.props?.mode !== "New"}
          changeParentValue={(licensorData) => this.setState({licensorData:licensorData})}
          payees_options={this.state.payees_options}
          licensorData={this.state.licensorData}
          fieldChanged={this.fieldChanged} />;
      case 4:
        return <CLRecords
          clearanceRecordList={this.state.clearanceRecordList} />;

    }
  };

  // formatPayeesList = () => {
  //   let data = this.state.payees_list
  //   let payeesTableData = data?.map(item => (item.payee_name = this.state.payees_options?.find(data => data?.label === item.payee_name)?.value));
  //   this.setState({ payees_list: data })
  // }

  getPayeesList = () => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/entitySearch?entity=LICENSOR_PAYEES&searchString=""`, this.props.userContext?.active_tenant?.tenant_id,null)
      .then(response => {
        let formattedList = response.data?.map(item => ({ 
          value: item.payee_remit_to_id,
          payee_id: item.payee_id,
          text: item.payee_company, 
          is_active: item.is_active, 
          is_default: item.is_default, 
          is_auto_pop: item.is_auto_pop, 
          // tax_ssn:(item.tax === null && item.ssn === null) ? null : item.tax === null ? item.ssn : item.ssn === null ? item.tax : (item.tax + item.ssn),
          tax_ssn:item?.tax ? item?.tax : item?.ssn ? item?.ssn : null,
          vendor: item.vendor }));
        this.setState({ payees_options: formattedList, payeeOptions: response.data });
      },
        (err) => {
          console.log("Error in fetching Payee Types:", err)
        })
  }
  getStateList = () => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_state`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
        this.setState({ stateList: formattedList });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err)
        })
  }

  getLicensorData = (id) => {
    this.setState({ dataLoading: true })
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/licensor?licensor_id=${id}`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response?.data[0]
        let contacts_list = response?.data[0]?.licensor_contacts?.map(i=>{i.is_inactive = i.lc_is_active ? 0 : 1; return i;})
        let address_history = response?.data[0]?.address_history_list
        formattedList.licensor_akas = formattedList?.licensor_akas?.length > 0 ? formattedList?.licensor_akas :  [{ ...mock_akas_list }]
        formattedList.licensor_payees = response?.data[0]?.licensor_payees.map(item => {
          return {
            "licensor_payee_id": item.licensor_payee_id,
            "payee_remit_to_id": item.payee_remit_to_id,
            "payee_remit_to": {
              'text' : item.payee_company, 'value': item.payee_remit_to_id
            },
            "payee_remit_to_name": item.payee_company,
            "payee_id" : item.payee_id,
            "payee_name": item.payee_company,
            "is_default": item.is_default,
            "is_auto_pop": item.is_auto_pop,
            "tax_ssn": item?.tax ? item?.tax : item?.ssn ? item?.ssn : null,
            //"tax_ssn": (item.tax === null && item.ssn === null) ? null : item.tax === null ? item.ssn : item.ssn === null ? item.tax : (item.tax + item.ssn),
            "vendor": item.vendor
          }
        })
        this.setState({
          // payees_list: payees_list
          licensorData: formattedList, dataLoading: false,
          contactsData: contacts_list, address_history_list: address_history,
          currentAddress: {
            'address_1': formattedList?.address_1,
            'address_2': formattedList?.address_2,
            'city': formattedList?.city,
            'state': formattedList?.state,
            'country_id': formattedList?.country_id,
            'country': formattedList?.country,
            'zip': formattedList?.zip,
          }
        });
        //this.formatPayeesList();
      },
        (err) => {
          console.log("Error in fetching Licensor Details:", err)
        })
  }

  getClearanceRecordList = (entityName, id) => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/clearanceRecordList?entity=${entityName}&entity_id=${id}`, this.props.userContext?.active_tenant?.tenant_id, null)
      .then(response => {
        let formattedList = response.data.map(item => {
          return {
            "show_id": item.show_id,
            "show_name": item.show_name,
            "show_season_id": item.show_season_id,
            "season_name": item.season_name,
            "show_season_episode_id":item?.show_season_episode_id,
            "episode": item.episode_number,
            "song_id": item.song_id,
            "song_title": item.song_title,
            "use": "USE",
            "status": item.status,
            "rights": item.rights,
            "lic_fee": item.lic_fee,
            "song_fee": item.song_fee
          }
        })
        this.setState({ clearanceRecordList: formattedList })
      },
        (err) => {
          console.log("Error in fetching Clearance List:", err)
        })
  }

  getCountryList = () => {
    ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/staticData?staticTable=lu_country`, this.props.userContext?.active_tenant?.tenant_id)
      .then(response => {
        let formattedList = response.data?.map(item => ({ value: item.id, label: item.name, is_active: item.is_active }));
        // formattedList.unshift({ label: "Country", value: null })
        if (this.props?.mode === 'New') {
          let defaultCountry = formattedList.filter(item => {
            return item.label === "United States"
          })
          this.handleGeneralEdit('country_id', defaultCountry[0]?.value)
          this.handleGeneralEdit('country', defaultCountry[0]?.label)
        }
        this.setState({ countryList: formattedList });
      },
        (err) => {
          console.log("Error in fetching Address Types:", err)
        })
  }

  handleText = () => {
    switch (this.state.tabValue) {
      case 1:
        return "Edit";
      case 2:
        return "Add File";
    }
  };

  handleIcon = () => {
    switch (this.state.tabValue) {
      case 1:
        return "pen";
      case 2:
        return "plus-circle";
    }
  };
  fieldChanged = (value, mode = "error") => {
    switch (mode) {
      case "error": return this.setState({ fieldChanged: value });
      case "success": return this.setState({ showSuccess: value });
      case "fail": return this.setState({ showFail: value });
    }
  }
  handleDisabledSave = (value)=>{
    this.setState({disabledSave:value});
  }
  handleSave = () => {
    this.setState({ errorFlag: false });
    let data = { ...this.state.licensorData }
    let filteredAkas = data.licensor_akas.filter(item => (item.aka !== null));
    data.licensor_akas = filteredAkas;
    // data.licensor_payees.map(item => (item.payee_name = this.state.payeeOptions?.find(data => data?.payee_id === item.payee_id)?.payee_company));
    // data.licensor_payees.map(item => (item.tax = this.state.payeeOptions?.find(data => data?.payee_id === item.payee_id)?.tax));
    // data.licensor_payees.map(item => (item.ssn = this.state.payeeOptions?.find(data => data?.payee_id === item.payee_id)?.ssn));
    data.licensor_payees = this.state.licensorData?.licensor_payees?.map(item  => {
      let newItem = {...item}
      newItem.licensor_payee_id = isNaN(item?.licensor_payee_id) ? null : item?.licensor_payee_id
      newItem.payee_remit_to = item?.payee_remit_to?.value
      return newItem
    })
    if (data.is_address_updated === 1) {
      data.history_address_1 = this.state.currentAddress?.address_1
      data.history_address_2 = this.state.currentAddress?.address_2
      data.history_city = this.state.currentAddress?.city
      data.history_state = this.state.currentAddress?.state
      data.history_country_id = this.state.currentAddress?.country_id
      data.history_country = this.state.currentAddress?.country
      data.history_zip = this.state.currentAddress?.zip
    }
    if (data.licensor_name !== null) {
      this.setState({ errorFlag: false, postingData: true });
      data.licensor_contacts = [...data?.licensor_contacts, ...this.state?.deletedContacts]
      for(let i=0; i < data?.licensor_contacts?.length; i++) {
        if(isNaN(data.licensor_contacts[i]?.licensor_contact_id)) {
          data.licensor_contacts[i].licensor_contact_id = null
        }
      }
      ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/licensor`, data, this.props.userContext?.active_tenant?.tenant_id)
        .then(response => {
          if (!response.data.error) {
            this.setState({ postingData: false })
            this.fieldChanged(false, "error");
            if(this.props?.songModalUpdate) {
              this.props?.songModalUpdate({text:data.licensor_name, value:response?.data[0]?.licensor_id})
            }
            this.props?.notificationComponent(true, "success");
            if(this.props?.toggleRefreshPage) {
              this.props?.toggleRefreshPage('EpisodeClearance');
            }
          }
          else {
            this.setState({ postingData: false });
            this.props?.notificationComponent(true, "fail");
          }
        },
          (err) => {
            this.setState({ postingData: false })
            this.props?.notificationComponent(true, "fail")
          });
    } else {
      this.setState({ errorFlag: true })
    }

  }

  handleGeneralEdit = (field, newValue) => {
    let licensorData = this.state.licensorData
    
    if ((field === "country_id" || field === "address_1" || field === "address_2" ||
      field === "city" || field === "zip" || field === "state") && this.props?.mode === 'Edit') {
      let licensorData = this.state.licensorData
      licensorData.is_address_updated = 1
      licensorData[field] = newValue
      this.setState({ licensorData: licensorData , postInitiated: false})
    }
    else{
    licensorData[field] = newValue
    this.setState({ licensorData: licensorData, postInitiated: false })
    }
    this.fieldChanged(true, "error")
  }
  handleCheck = (e, field) => {
    let isChecked = e.target.checked
    let licensorData = { ...this.state.licensorData }
    if (isChecked) {
      field === "is_active" ? licensorData[field] = 0 : licensorData[field] = 1
    }
    else {
      field === "is_active" ? licensorData[field] = 1 : licensorData[field] = 0
    }
    this.setState({ licensorData: licensorData })
    this.fieldChanged(true, "error")
  }
  closeState = () => {
    if (this.state.fieldChanged) {
      this.setState({ showError: true });
    }
    else {
      this.props?.handleClose();
    }
  }
  onConfirm = () => {
    this.props?.handleClose();
  }

  render() {
    const showPayee = (this.props?.division_id === undefined ? this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name !== 'Music Supervisor')
        : this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name !== 'Music Supervisor' && item.division_id === this.props?.division_id));          
    let commonPermissions = this.props?.commonPermissions?.find(item => item?.permission_name === "Licensors" && item?.permission_group_name === "Licensors")
    let canEditLicensorInformation = commonPermissions?.edit;
    let canAddNewLicensorInformation = commonPermissions?.new;
    let canViewLicensorInformation = commonPermissions?.view;
    let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
      this.state.showSuccess ? this.state.saveEditMessage : "";
    let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failTitle :
      this.state.showSuccess ? this.state.saveTitle : "";
    return (
      <div>
        {this.state.dataLoading ?
          <div className="ContentLoader">
            <CircularProgress />
          </div>
          :
          <MDBContainer fluid className="weekend-detail-container">
            <div>
              {this.state.errorFlag === true && <p className="errorMessage">Please enter mandatory fields.</p>}
              <MDBRow className="tab-content">
                <MDBCol md={10}>
                  <TabsComponent
                    tabList={this.props?.mode === "Edit" ? showPayee ? LicensorTabList : MSLicensorTabList : showPayee ? NewLicensorTabList : MSLicensorTabList}
                    value={this.state.tabValue}
                    onChange={(e, v) => this.handleTabChange(v, "tabValue")}
                  />
                </MDBCol>
                <MDBCol md={2} className="tenantAddButton">
                  {this.state.tabValue === 1 ? <BasicCheckbox
                    id="licensorInactiveCheckbox"
                    label={"Inactive"}
                    checked={this.state.licensorData?.is_active === 1 ? false : true}
                    onChange={(e) => this.handleCheck(e, "is_active")}
                    disabled={(canEditLicensorInformation ? canEditLicensorInformation === 0 : false) && canAddNewLicensorInformation === 0 && this.props?.mode !== "New"}
                  /> : " "}
                </MDBCol>
              </MDBRow>
            </div>
            <div>
            <div>
              {this.getTabComponent(canEditLicensorInformation, canAddNewLicensorInformation)}
              <MDBRow>
                <MDBCol md={10}></MDBCol>
                <MDBCol md={2}>
                  <MDBRow>
                    <MDBCol>
                      <BasicButton
                        text={this.state.postingData ? <CircularProgress color="inherit" size={20} /> : "Save"}
                        onClick={this.handleSave}
                        disabled={this.state.disabledSave ? true : (canEditLicensorInformation ? canEditLicensorInformation === 0 : true) && canAddNewLicensorInformation === 0 && this.props?.mode !== "New"}
                      />
                    </MDBCol>
                    <MDBCol>
                      <BasicButton
                        variant="outlined"
                        text={"Cancel"}
                        onClick={this.closeState}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </div>
            </div>
            {this.state.showChangeContactModal && (
              <MainViewModalComponent
                open={this.state.showChangeContactModal}
                handleClose={this.handleHideChangeContactModal}
                headerText={"Default Licensor Contact"}
                mode={""}
                modalName={"Change Contact"}
              />
            )}
            {this.state.showLicenseEntityInfo && (
              <MainViewModalComponent
                open={this.state.showLicenseEntityInfo}
                headerText={"License Entity Info"}
                mode={""}
                modalName={"License Entity Info"}
                handleClose={this.handleHideLicenseEntityInfo}
                license_entity_id={this.state.licensorData?.licensor_entity_info_id}
                licensor_id={this.state?.licensorData?.licensor_id}     
                handleGeneralEdit = {this.handleGeneralEdit}        
                 />
            )}
            <MessageModal
              open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
              title={comfirmModalTitle}
              message={message}
              hideCancel={this.state.showFail || this.state.showSuccess || false}
              primaryButtonText={"OK"}
              secondaryButtonText={"Cancel"}
              onConfirm={this.onConfirm}
              handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
          </MDBContainer>
        }</div>);
  }
}
export default withUserContext(EditLicensor);