import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import { withUserContext } from '../../../contexts/UserContext';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableComponent from '../../SharedComponents/Table';
import './EpisodeAndClearence.scss';
import EpisodeFiles from './EpisodeFiles';
import CueSheet from './CueSheet';
import { clearenceRecordConfig, Licensor, syncLicensorConfig, licensorRightsConfig, clearanceRecordPermissionsConfig } from './Config';
import CompositionFiles from './CompositionFiles';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import MessageModal from '../../SharedComponents/MessageModal';
import { applyFilterTable } from '../../../Common/TableHelper';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import {getFormattedDate, validateNumbersOnly} from "../../../Common/Helper";
import { updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
import Popover from '@material-ui/core/Popover'
import { handlePaginationList } from '../../../Common/TableHelper';
import TablePagination from '@material-ui/core/TablePagination';
let songFilter = '', filtersNone = true, filterValues = [], filteredDivision = [], permissionArrClearanceMain = [], permissionArrCueSheets = [], permissionFilesTab = [], permissionArrClearanceRecords = [],
displayCuesheetAccordionPermission, displayFilesAccordionPermission, displayEFCButtonPermission, displayBatchesButtonPermission, displayBaiscCostPermission, displayClearanceRecordTablePermission, permissionNavigateToAnEpisode, permissionNavigateToAnClearanceRecord, editOptionalRightsPermissions, defaultRights={}, viewNewCRPermission, viewAirDate, viewMainTitle, efcPermissions, clearanceBatchesPermissions, songAndPerformerPermissions,episodeSongPermissions;
export default withRouter(withUserContext(class EpisodeAndClearence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tenant_id: null,
            showDescriptionDetails: false,
            isFetchingEpisodeAndClearenceDetails: false,
            isFetchingClearanceDetails: false,
            isFeatureTenant: false,
            config: JSON.parse(JSON.stringify({ ...clearenceRecordConfig })),
            showBatchesModal: false,
            showEpisodeViewClearanceRecord: false,
            show_season_id: this.props.match.params.ssid ? this.props.match.params.ssid : null, //Take it from URL Once direction is in place
            show_season_episode_id: this.props.match.params.epid ? this.props.match.params.epid : null,//Take it from URL Once direction is in place
            clearenceRecordsList: [],
            backUpclearenceRecordsList: [],
            clearancerowsPerPage: 20,
            clearancePage: 0,
            licensorsInfo: [],
            clearneceLicenserInfo: [],
            clearenceSongInfo: [],
            clearenceId: null,
            showSuccess: false,
            showFail: false,
            mode: "view",
            fieldChanged: false,
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            backUp: [],
            show_name: null,
            isExceldownload:false,
            season_name: null,
            episode_name: null,
            division_id: null,
            displayNewCrButtonPermission: false,
            clearance_divisionId: 1,/**Take it from URL Once direction is in place */
            season_id: null,/**Take it from URL Once direction is in place */
            show_id: null,/**Take it from URL Once direction is in place */
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            clearanceRedirect: [],
            setEFCPopover:null,
            clearancePermissions: null,
            EmailLetterPermission: null,
            clearanceRecordPermissionsConfig: clearanceRecordPermissionsConfig,
            applyHVPermission: null,
            cueSheetPermissions : [],
            isOpenExisting: false,
            showExcelAndDocModal: false,
            showCuesheetExcelAndDocModal: false,
            notificationMessage:null
        }
    }

    getClearancePermissions = (div_id) => {
        let tenantInfo = this.props.userContext?.user_profile?.tenants?.filter((item) => item.tenant_id == this.props.userContext?.active_tenant?.tenant_id)
        let divisionInfo = tenantInfo[0]?.division_role?.filter((item) => item.division_id === div_id)
        let episodePermissions = divisionInfo[0]?.permissions?.filter((item) => item.permission_group_name	=== "Episode")
        let clearancePermissions = divisionInfo[0]?.permissions?.filter((item) => item.permission_group_name === "Clearance Record")
        let EmailLetterPermission = clearancePermissions?.find((per)=> per.permission_name ==="Letters: Email") ? clearancePermissions?.find((per)=> per.permission_name ==="Letters: Email") : false;
        viewNewCRPermission = clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "New CR" && item.permissions.includes(per.permission_name)));
        viewAirDate = clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Air Date" && item.permissions.includes(per.permission_name)));
        viewMainTitle = clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "Main Title" && item.permissions.includes(per.permission_name)));
        this.setState({ clearancePermissions: clearancePermissions,EmailLetterPermission: EmailLetterPermission,
        applyHVPermission: clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "statusCROptions" && item.permissions.includes(per.permission_name))) })
        efcPermissions = clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "EFCButton" && item.permissions.includes(per.permission_name)));
        clearanceBatchesPermissions = clearancePermissions?.find((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "ClearanceBatches" && item.permissions.includes(per.permission_name)));
        let cueSheetPermission = divisionInfo[0]?.permissions?.filter((item) => item.permission_group_name	=== `Cue Sheet Access - ${divisionInfo[0]?.division_name} Cue Sheets`)
        this.setState({cueSheetPermissions : cueSheetPermission })
        songAndPerformerPermissions = clearancePermissions?.filter((per) => this.state.clearanceRecordPermissionsConfig.find((item) => item.buttonName == "song/performerInfo" && item.permissions.includes(per.permission_name) && item.permissionGroupName.includes(per.permission_group_name)));
        episodeSongPermissions = divisionInfo[0]?.permissions?.find((item) => item.permission_group_name==="Songs");
    }
    // for maintain edit mode
    setMode = (confirm, index) => {
        let clearneceLicenserInfo = this.state.clearneceLicenserInfo
        let r = clearneceLicenserInfo?.[0]?.licensors_list
        let editCheck = r?.findIndex((item, i) => item.editing === true)
        if (editCheck !== -1) {
            if (this.state.fieldChanged === true) {
                this.fieldChanged(true, "error");
            } else {
                if (editCheck === index) {
                    this.updateEdits(editCheck, false)
                }
                else {
                    this.updateEdits(editCheck, false, undefined, () => this.updateEdits(index, true))
                }
            }
        } else {
            this.updateEdits(index, true)
        }
        this.setState({
            index,
        })
    }
    // on error cancel popup
    onCancel = () => {
        this.setState({
            showError: false
        })
    }
    // to maintain multiple edits
    updateEdits = (variable, variableValue, confirm, callback) => {
        let clearneceLicenserInfo = JSON.parse(JSON.stringify(this.state.clearneceLicenserInfo));
        let r = JSON.parse(JSON.stringify(clearneceLicenserInfo?.[0]?.licensors_list));
        r[variable]['editing'] = variableValue
        if (r[variable]?.rights?.length === 0) {
            r[variable].rights = [...licensorRightsConfig]
        }
        r[variable].clearance_check_request?.map((option) => {
            // if (editOptionalRightsPermissions && variableValue) {
            if ( variableValue) {
                variableValue = true
            } else { variableValue = false }
            option['editing'] = variableValue;
            option.payee_remit_to = {
                text: option.payee_remit_to_name,
                value: option.payee_remit_to
            }
        })
        r[variable].clearance_licensor_optional_rights?.map((option) => {
            option['editing'] = variableValue
            option.clearance_licensor_optional_check_requests?.map((item) => {
                item['editing'] = variableValue;
                item.payee_remit_to = {
                    text: item.payee_remit_to_name,
                    value: item.payee_remit_to
                }
            })
        });
        if(clearneceLicenserInfo.length > 0){
            clearneceLicenserInfo[0].licensors_list = r;
        }
        this.setState({
            clearneceLicenserInfo
        }, () => {
            if (callback) callback()
        })
    }
    // on message confirmation popup
    onConfirm = () => {
        let clearneceLicenserInfo = this.state.clearneceLicenserInfo
        let r = clearneceLicenserInfo?.[0]?.licensors_list
        let editCheck = r?.findIndex((item) => item.editing === true)
        let index = this.state.index
        this.updateEdits(editCheck, false, true, editCheck !== index ? () => this.updateEdits(index, true, true) : undefined)
        this.setState({
            fieldChanged: false,
            showError: false
        })
    }

    navigationCallback = (obj, node) => {
        // this.props.history.push(`/episodeAndClearance/ss/${this.state.show_season_id}/ep/${this.state.show_season_episode_id}/clearence/${obj.clearance_id}`);
        this.setState({ clearenceId: obj })
        this.setState({ showDescriptionDetails: true })
        this.getClearanceData(obj);
    }

    handleListEdit = (field, value, obj, listName) => {
        let licInfo = { ...this.state.clearneceLicenserInfo };
        let id = (listName === "licensors_list") ? "clearance_licensor_id" : "";
        let list = licInfo[0][listName]?.map(item => {
            let currentVal = item;
            // if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
            //      currentVal[field] = value;
            //     // item[field] = value;
            // }
            // return currentVal;
            // //Need to have checks at this place
            if ((item[id] && obj[id] && (item[id] === obj[id]))) {

                item[field] = value;

            }
        })
        this.handleGeneralEdit(listName, list);
    }

    fieldChanged = (value, mode = "error") => {
        switch (mode) {
            case "change": return this.setState({ fieldChanged: value });
            case "error": return this.setState({ showError: value });
            // case "success": return this.setState({ showSuccess: value });
            // case "fail": return this.setState({ showFail: value });
        }
    }

    handleGeneralEdit = (field, value) => {
        this.setState(prevState => ({
            clearneceLicenserInfo: {
                ...prevState.clearneceLicenserInfo,
                [field]: value
            },
        }), () => {
            this.fieldChanged(true, "change");
        })
    }
    setDefaultRights=()=>{
        let licensorData = this.state?.licensorsInfo?.[0]
        defaultRights={
            initial_term:licensorData?.initial_term,
            initial_term_id:licensorData?.initial_term_id,
            initial_territory:licensorData?.initial_territory,
            initial_territory_id:licensorData?.initial_territory_id,
            initial_right_id:licensorData?.initial_right_id,
            initial_right_abbr:licensorData?.initial_right_abbr,
            alt_initial_term:licensorData?.alt_initial_term,
            alt_initial_term_id:licensorData?.alt_initial_term_id,
            alt_initial_territory:licensorData?.alt_initial_territory,
            alt_initial_territory_id:licensorData?.alt_initial_territory_id,
            alt_initial_right_id:licensorData?.alt_initial_right_id,
            alt_initial_right_abbr:licensorData?.alt_initial_right_abbr
         }
    }
    getClearanceData = (obj) => {
        this.setState({ isFetchingClearanceDetails: true });
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/clearance?clearance_id=${obj?.clearance_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, obj?.division_id)
            .then(response => {
                let data = response?.data
                document.title = `${obj?.clearance_id ? obj?.clearance_id : ''}${this.state?.episode_name ? '-'+this.state?.episode_name :''}`
                data.map?.((licensor) => {
                    licensor?.songs_list?.map((item) => {
                        item.song_restricted_loading = false,
                        item.restricted_message = item?.is_all_promo_use_restricted == 0 ? "Do you want to mark the Song as Restricted Promo Use?" : "Do you want to mark the Song as NOT a Restricted Promo Use?";
                    })
                })
                this.setState({
                    licensorsInfo: data,
                    isFetchingClearanceDetails: false
                }, () => {
                    this.setDefaultRights();
                    this.getClearanceLicensorsData(obj);
                    this.getClearancePermissions(data[0]?.division_id)
                });
               
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }

    getClearanceLicensorsData = (obj) => {
        this.setState({ isFetchingClearanceDetails: true });
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/clearanceLicensors?clearance_id=${obj?.clearance_id}&clearance_song_id=${this.state.licensorsInfo?.[0]?.songs_list?.[0]?.clearance_song_id}&show_season_id=${this.state.show_season_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.state.division_id)
            .then(response => {
                let data = response?.data
                data[0]?.licensors_list?.map((item) => {
                    item.editing = false
                    item.quote_request_date  = item.quote_request_date ? item?.quote_request_date?.split('T')[0] : null
                    item.confirmation_date  = item.confirmation_date ? item?.confirmation_date?.split('T')[0] : null
                    item.use_confirmation_date  = item.use_confirmation_date ? item?.use_confirmation_date?.split('T')[0] : null
                    item.license_request_date  = item.license_request_date ? item?.license_request_date?.split('T')[0] : null
                    item.clearance_licensor_name = {value : item?.licensor_id || null, text : item?.licensor_name || null}
                    if (item?.rights?.length === 0) {
                        item.rights = [...licensorRightsConfig]
                    }
                    if(item?.rights?.length === 1){
                        if(item.rights?.[0]?.is_initial === 1){
                            item.rights.push(...licensorRightsConfig.filter(i=>i.is_initial === 0));
                        }
                        else if(item.rights?.[0]?.is_alt_initial === 1){
                            item.rights.push(...licensorRightsConfig.filter(i=>i.is_alt_initial === 0));
                        }
                    }
                    item.clearance_check_request?.map(option => {
                        option['editing'] = false
                        option['payee_remit_to'] = option['payee_remit_to_id'];
                        option.requested_date = option.requested_date ? getFormattedDate(option.requested_date,true):null;
                        // delete option?.payee_remit_to_id;
                    })
                    item.clearance_licensor_optional_rights?.map(option => {
                        option['bch'] = 1;
                        option.option_acquired_date = option?.option_acquired_date ?  getFormattedDate(option?.option_acquired_date?.split('T')[0]) : null
                        option?.clearance_licensor_optional_check_requests?.map(optionPayee => {
                            optionPayee['payee_remit_to'] = optionPayee['payee_remit_to_id'];
                            optionPayee.requested_date = optionPayee?.requested_date ? optionPayee?.requested_date?.split('T')[0] : optionPayee?.requested_date
                            // delete optionPayee?.payee_remit_to_id;
                        })
                    })
                    return item;
                })
                this.setState({ clearneceLicenserInfo: data, backUp: data, isFetchingClearanceDetails: false },
                    // () => { this.checkLicensors() }
                );
            },
                (err) => {
                    console.log("Error in fetching Address Types:", err);
                    this.setState({ isFetchingClearanceDetails: false });
                })
    }
    createLicensorInstances = (list, type) => {
        let obj = [];
        list.map(function (item) {
            var licensor = JSON.parse(JSON.stringify({ ...Licensor }));
            licensor.licensor_id = item.s_licensor_id;
            licensor.licensor_name = item.licensor_name;
            licensor.licensor_type = type;
            licensor.share = item.licensor_share;
            licensor.is_active = item.licensor_is_active; //--Clarification to use this flag or other
            obj.push(licensor);
        })
        return obj;
    }

    createMasterLicensorInstances = (list, type) => {
        let obj = [];
        list.map(function (item) {
            var licensor = Licensor;
            licensor.licensor_id = item.p_licensor_id;
            licensor.licensor_name = item.performer_licensor_name;
            licensor.licensor_type = type;
            licensor.share = item.performer_licensor_share;
            // licensor.is_active = item.licensor_is_active; //--Clarification to use this flag or other
            obj.push(licensor);
        })
        return obj;
    }


    handleChangePage = () => {
        this.setState({ showDescriptionDetails: false }, () => {
            this.getClearenceRecordsList()
        })
    }
    handleShowSeasonChange = () => {
        //if (!this.state.showDescriptionDetails) {
            this.props?.history?.push(`/showseason/${this.state.show_season_id}`);
        // } else {
        //     this.setState({ showDescriptionDetails: false })
        //     // this.props.history.push(`/episodeAndClearance/ss/${this.state.show_season_id}/ep/${this.state.show_season_episode_id}`)
        // }
    }
    handleShowEpisodeViewClearanceRecord = () => {
        this.setState({ showEpisodeViewClearanceRecord: true });
    }
    handleHideEpisodeViewClearanceRecord = () => {
        this.setState({ showEpisodeViewClearanceRecord: false });
    }
    handleshowBatchesModal = () => {
        this.setState({ showBatchesModal: true });
    }
    handleHideBatchesModal = () => {
        this.setState({ showBatchesModal: false });
        this.getClearenceRecordsList()
    }
    //Data Calls

    componentDidMount() {
        if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Editor')) {
            this.props.history.push('/cueSheet');
        }
        // if (this.props?.userContext?.active_tenant?.tenant_id && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
        //     this.props.history.push('/userRoleManagement');
        // }
        if(this.props?.userContext?.user_profile?.is_user_provisioner === 1){
            this.props.history.push('/userRoleManagement');
        }
        this.getClearenceRecordsList();
    }

    componentDidUpdate(prevProps, prevState) {
        // if ((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
        //     && this.props?.userContext?.user_profile?.is_user_provisioner === 1) {
        //     this.props.history.push('/userRoleManagement');
        // }
        if(this.props?.userContext?.user_profile?.is_user_provisioner && this.props?.userContext?.user_profile?.is_user_provisioner === 1){
            this.props.history.push('/userRoleManagement');
        }
        if ((prevProps?.userContext?.active_tenant?.tenant_id !== this.props?.userContext?.active_tenant?.tenant_id)
                && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Editor')) {
                this.props.history.push('/cueSheet');
            }
        if (prevProps?.userContext?.active_tenant?.tenant_id != this.props?.userContext?.active_tenant?.tenant_id) {
            this.getClearenceRecordsList();
        }
        if (prevProps?.refreshPage?.value !== this.props?.refreshPage?.value && this.props?.refreshPage?.modal === 'EpisodeClearance') {
            this.getClearanceData(prevState?.clearenceId);
            this.props?.toggleRefreshPage('');
        }
    }


    getClearenceRecordsList = () => {
        if (this.props?.userContext?.active_tenant?.tenant_id) {
            this.setState({ isFetchingClearanceDetails: true });
            clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodeClearanceRecords?show_season_id=${this.state.show_season_id}&show_season_episode_id=${this.state.show_season_episode_id}`,
                this.props?.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    let clearenceRecordsList = response?.data[0]
                    document.title = `${clearenceRecordsList?.episode_number ? clearenceRecordsList?.episode_number : ''}${clearenceRecordsList?.episode_title ? '-'+clearenceRecordsList?.episode_title :''}`
                    this.setState({
                        clearancePage:0,
                        clearenceRecordsList: handlePaginationList(0, this.state.clearancerowsPerPage, clearenceRecordsList?.clearance_records),
                        backUpclearenceRecordsList: clearenceRecordsList?.clearance_records,
                        isFetchingClearanceDetails: false,
                        show_id: clearenceRecordsList?.show_id,
                        season_id: clearenceRecordsList?.season_id,
                        show_name: clearenceRecordsList?.show_name,
                        season_name: clearenceRecordsList?.season_name,
                        episode_name: clearenceRecordsList?.episode_title,
                        episode_number: clearenceRecordsList?.episode_number,
                        division_id: clearenceRecordsList?.division_id,
                        show_season_time :  clearenceRecordsList?.show_season_time,
                        air_date : clearenceRecordsList?.air_date,
                        clearance_rep: clearenceRecordsList?.clearance_rep,
                        main_title_song : clearenceRecordsList?.main_title_song,
                        total_initial_fees : clearenceRecordsList?.total_initial_fees,
                        productionEnity : clearenceRecordsList?.company
                    }, () => {
                        this.getClearancePermissions(this.state.division_id);
                        if(songFilter){
                        this.handleFilterText("songFilter",songFilter);}
                        this.handleTableColumnClick("Composition",true);
                        // filteredDivision = this.props.userContext?.active_tenant?.division_role.length > 0 && this.props.userContext?.active_tenant?.division_role.filter((item) => (item.division_id === this.state.division_id) ? item : null);
                        // this.getPermissions(filteredDivision?.[0]?.permissions);
                        if(this.props.location.state || this.state.clearanceRedirect.length > 0 || (window?.name && validateNumbersOnly(window?.name))) {
                            let compareItem = this.props.location.state ? this.props.location.state : window?.name && validateNumbersOnly(window?.name) ? window?.name : this.state.clearanceRedirect[2]
                            let clearanceRecord  = clearenceRecordsList?.clearance_records?.find(item => item?.clearance_id == compareItem)
                            if(clearanceRecord) {
                                this.navigationCallback(clearanceRecord,null)
                            }
                            this.props?.history.replace(location.pathname, null)
                            window.name = null
                            this.setState({clearanceRedirect:[]})
                        }
                    });
                },
                    (err) => {
                        console.log("Error in fetching Address Types:", err),
                            this.setState({ isFetchingClearanceDetails: false });
                    })
        }
    }
    // downloadReport = (reportName, searchPayload,reportType) => {
       
    //     let encryptTedStr = btoa(JSON.stringify(searchPayload));
    //     if (reportType === "isExcel") {
    //         return clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/exportExcel?reportName=${reportName}&searchJson=${encryptTedStr}`,
    //             this.props.userContext?.active_tenant?.tenant_id, 1, 1)
        
    //     }
    // }
    downloadReport = (reportName, searchPayload, fileName) => {
        if (!searchPayload || !reportName) {
          return;
        }
        Object.keys(searchPayload).map(obj => {
          if (searchPayload[obj] === undefined) {
            searchPayload[obj] = null;
          }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let load = {
         'payload' : 'exportExcel',
          'reportName': reportName,
          'fileName': "isExcel",
          'searchJson': encryptTedStr,
          'showHtml': 0,
          'encryptionType': 1,
          'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
          'divisionId': this.state.division_id,
        }
        return clearTrackService.getDataWS(load)
      }
    isOpenExisting=(data)=>{
        this.setState({isOpenExisting: data})
    }
    handleShowExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: true ,isOpenExisting: false, setEFCPopover: null});
    };
    handleHideExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: false });
    };
    handleShowCuesheetExcelAndDocModal = () => {
        this.setState({ showCuesheetExcelAndDocModal: true ,isOpenExisting: false});
    };
    handleHideCuesheetExcelAndDocModal = () => {
        this.setState({ showCuesheetExcelAndDocModal: false });
    };
    downloadExcel = (identifier) => {
        this.setState({setEFCPopover:null, postInitiated: true, isExcelPosting: true });
        let reportName = "rptClearanceEfc";
            let searchPayload={
                "show_season_episode_id":this.state.show_season_episode_id,
                "is_efc": 1,
                "is_costsheet": null,
                "is_show_notes":null,
                }
                if(identifier === "email"){
                    searchPayload["send_email"] = 1
                }
                if(identifier === "isExcel"){
                    searchPayload[ "isOpenExisting"] =  this.state.isOpenExisting ? 1 : 0
                }
                  this.downloadReport(reportName, searchPayload,"isExcel").then(webSocketConn => {
                      webSocketConn.onmessage = (e) => {
                        let response = JSON.parse(e.data)
                        this.setState({ isExcelPosting: false })
                      if (response?.statusCode == 200) {
                        this.setState({ isExcelPosting: false })
                        if(identifier !== "email"){
                        this.downloadUrl(response?.body);
                        }
                        this.notificationComponent(true, 'success')
                      }
                    }
                  }).catch((err) => {
                    this.notificationComponent(true, 'fail')
                  })
        //           then(response => {
        //     if (response.data.error) {
        //         this.setState({ isExcelPosting: false });
        //         this.notificationComponent(true, "fail");
        //         // this.props.fieldChanged(true, "reportNotFound");
        //     }
        //     else {
        //         let excelDownloadUrl = response.data;
        //         this.downloadUrl(excelDownloadUrl, "rptclearanceefc");
        //         this.setState({ isExcelPosting: false });
        //     }
        // }, (err) => {
        //     this.setState({ isExcelPosting: false });
        //     this.notificationComponent(true, "fail");
        //     console.log(err);
        //     // this.props.fieldChanged(true, "reportFail");
        // })
    }
    downloadUrl = (url) => {
        if (url) {
          fetch(url).then(response => response.blob()).then(blob => {
            const link = document.createElement('a');
            // link.href = window.URL.createObjectURL(blob);
            link.href = url;
            // link.setAttribute('download', reportName);
            document.body.appendChild(link);
            this.handleHideExcelAndDocModal()
            this.handleHideCuesheetExcelAndDocModal()
            link.click();
            link.remove();
          })
        }
      }
    // downloadUrl = (url, reportName) => {
    //     if (url) {
    //         fetch(url).then(response => response.blob()).then(blob => {
    //             const link = document.createElement('a');
    //             // link.href = window.URL.createObjectURL(blob);
    //             // link.setAttribute('download', reportName);
    //             link.href = url
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //             this.notificationComponent(true, "success");
    //         })
    //     }
    // }
    // downloadReport = (reportName, searchPayload, fileName) => {
    //     if (!searchPayload || !reportName) {
    //       return;
    //     }
    //     Object.keys(searchPayload).map(obj => {
    //       if (searchPayload[obj] === undefined) {
    //         searchPayload[obj] = null;
    //       }
    //     })
    //     let encryptTedStr = btoa(JSON.stringify(searchPayload));
    //     let load = {
    //      'payload' : 'exportExcel',
    //       'reportName': reportName,
    //       'fileName': "isExcel",
    //       'searchJson': encryptTedStr,
    //       'showHtml': 0,
    //       'encryptionType': 1,
    //       'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
    //       'divisionId': 1,
    //     }
    //     return clearTrackService.getDataWS(load)
    //   }
    downloadExcelCostSheet = () => {
        this.setState({ postInitiated: true, isExceldownload: true });
        let reportName = "rptClearanceCostSheet";
            let searchPayload={
                 "show_season_episode_id":this.state.show_season_episode_id,
                 "is_efc" : 0,
                 "is_costsheet": 1,
                 "is_show_notes":null,
                 "isOpenExisting": this.state.isOpenExisting ? 1 : 0
        }
                  this.downloadReport(reportName, searchPayload,"isExcel").then(webSocketConn => {
                    webSocketConn.onmessage = (e) => {
                      let response = JSON.parse(e.data)
                      if (response?.statusCode == 200) {
                        this.setState({ isExceldownload: false })
                        this.downloadUrl(response?.body);
                        //this.notificationComponent(true, 'success')
                      }
                    }
                  })
        
    }
    // downloadUrl = (url) => {
    //     if (url) {
    //       fetch(url).then(response => response.blob()).then(blob => {
    //         const link = document.createElement('a');
    //         // link.href = window.URL.createObjectURL(blob);
    //         link.href = url;
    //         // link.setAttribute('download', reportName);
    //         document.body.appendChild(link);
    //         link.click();
    //         link.remove();
    //       })
    //     }
    //   }

    handleTableColumnClick = (column,dontChangeOrder) => {
        if (column === this.state.sortBy) {
            this.setState({ sortCount: this.state.sortCount + 1 }, () => {
                this.handleTableSort(column,dontChangeOrder);
            })
        }
        else this.setState({ sortCount: 0 }, () => {
            this.handleTableSort(column,dontChangeOrder);
        })
    }

    handleTableSort = (colName,dontChangeOrder) => {
        this.setState({
            isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
            ...(dontChangeOrder && {isSortByAsc:this.state.isSortByAsc})
        }, () => {
            this.setState({
                sortBy: colName,
                config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, this.state.sortBy, colName),
            clearenceRecordsList: tableSortList(colName === 'TotalFee' ? 'Number':"Name", this.getSortNode(colName), this.state?.clearenceRecordsList, this.state.isSortByAsc)
            });
        })
    }

    getSortNode = (col) => {
        switch (col) {
            case "Composition": return "composition";
            case "Scene": return "scene";
            case "Type": return "licensor_type";
            case "Licensors": return "licensor_name";
            case "TotalFee": return "total_fee";
            case "Licensee Rep": return "licensee_rep";
            case "Status": return "status";
        }
    }

    getPermissions(permissions) {
        if (permissions?.length > 0) {
            let displayNewCrButtonPermission = [];
            permissionArrClearanceMain = permissions.filter(obj => obj.permission_group_name === "Episode/Clearance Records - Main");
            permissionArrCueSheets = permissions.filter(obj => obj.permission_group_name === "Episode/Clearance Records - Cue Sheets");
            permissionFilesTab = permissions.filter(obj => obj.permission_group_name === "Episode/Clearance Records - Files tab");
            permissionArrClearanceRecords = permissions.filter(obj => obj.permission_group_name === "Episode/Clearance Records - Clearance Records");
            permissionNavigateToAnEpisode = permissions.filter((obj, index) => obj.permission_name === "Can navigate to an Episode");
            permissionNavigateToAnEpisode = permissionNavigateToAnEpisode.length > 0 ? true : false;
            displayCuesheetAccordionPermission = permissions.filter((obj, index) => obj.permission_name === "Can see 'Cue Sheets' accordion");
            displayCuesheetAccordionPermission = displayCuesheetAccordionPermission.length > 0 ? true : false;
            displayFilesAccordionPermission = permissions.filter((obj, index) => obj.permission_name === "Can see 'Files' accordion");
            displayFilesAccordionPermission = displayFilesAccordionPermission.length > 0 ? true : false;
            displayEFCButtonPermission = permissions.filter((obj, index) => obj.permission_name === "Can see 'EFC' button");
            displayEFCButtonPermission = displayEFCButtonPermission.length > 0 ? true : false;
            displayBatchesButtonPermission = permissions.filter((obj, index) => obj.permission_name === "Can see 'Batches' button and manage Batches");
            displayBatchesButtonPermission = displayBatchesButtonPermission.length > 0 ? true : false;
            displayBaiscCostPermission = permissions.filter((obj, index) => obj.permission_name === "Can see 'Main Title', 'Total Fees', 'Air Date', and 'Cl. Rep' fields in header");
            displayBaiscCostPermission = displayBaiscCostPermission.length > 0 ? true : false;
            displayClearanceRecordTablePermission = permissions.filter((obj, index) => obj.permission_name === "Can see Clearance Record table");
            displayClearanceRecordTablePermission = displayClearanceRecordTablePermission.length > 0 ? true : false;
            displayNewCrButtonPermission = permissions.filter((obj, index) => obj.permission_name === "Can create a Clearance Record");
            displayNewCrButtonPermission = displayNewCrButtonPermission.length > 0 ? true : false;
            permissionNavigateToAnClearanceRecord = permissions.filter((obj, index) => obj.permission_name === "Can navigate to a Clearance Record");
            permissionNavigateToAnClearanceRecord = permissionNavigateToAnClearanceRecord.length > 0 ? true : false;
            editOptionalRightsPermissions = permissions.filter((obj, index) => obj.permission_name === "Can edit Option Rights");
            editOptionalRightsPermissions = editOptionalRightsPermissions.length > 0 ? true : false;
            this.setState({ displayNewCrButtonPermission })
        }
    }

    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
        }
    }

    notificationMessage(message){
        this.setState({notificationMessage: message || null})
    }

    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false });
        if (this.state.saveFlag) {
            this.props?.handleClose();
        }
    }
    handleCopyLicensor = (clearance_licensor_id) => {
        let licensorsInfo = this.state.clearneceLicenserInfo
        let copied_clearance_licensor = licensorsInfo[0]?.licensors_list?.filter(item => item.clearance_licensor_id === clearance_licensor_id)
        copied_clearance_licensor[0].clearance_licensor_id = null
        let lengthOfLicenserInfo = licensorsInfo[0]?.licensors_list?.length
        licensorsInfo.push(copied_clearance_licensor[0])
        this.setState({ clearneceLicenserInfo: licensorsInfo })
    }

    handleFilterText = (field, value) => {
        if (field === 'songFilter') { songFilter = value }
        if (songFilter.length > 0) {
            filtersNone = false;
            filterValues = [`Composition:${songFilter}`]
            var filteredList = applyFilterTable(this.state.config, this.state.backUpclearenceRecordsList, filterValues, filtersNone);
            this.setState({clearancePage:0, clearenceRecordsList: handlePaginationList(0, this.state.clearancerowsPerPage, filteredList) })
        }
        if (songFilter.length == 0) {
            filtersNone = true;
            this.setState({clearancePage:0,clearenceRecordsList:handlePaginationList(0, this.state.clearancerowsPerPage, this.state.backUpclearenceRecordsList), songFilter: '' });
        }
    }
    handleOpenEFCPopup = (e) => {
        this.setState({
            setEFCPopover: e.currentTarget
        })
    };
    handleCloseEFCPopup = () => {
        this.setState({
            setEFCPopover: null
        })
    };

    handleTableChangePage = (event, newPage) => {
        this.setState({
            clearancePage: newPage,
            clearenceRecordsList: handlePaginationList(newPage, this.state.clearancerowsPerPage, this.state.backUpclearenceRecordsList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            clearancerowsPerPage: parseInt(event.target.value, 10),
            clearancePage: 0,
            clearenceRecordsList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.backUpclearenceRecordsList)
        });
    }

    render() {
        let openEFCPopover = Boolean(this.state.setEFCPopover);
        let message = this.state.showError ? this.state.unsavedMessage : "";
        let comfirmModalTitle = this.state.showError ? this.state.unsavedTitle : "";
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : "";
        let messageNotification = 
        (this.state.showSuccess ? (this.state.notificationMessage ? this.state.notificationMessage : "The changes are saved successfully!") : this.state.showFail ? this.state.notificationMessage ?? "Saving changes failed!" : this.state.showEmailSave ? "Emails have been saved to your Drafts folder!" : "");
        return (
            <>
                {
                    this.state.isFetchingEpisodeAndClearenceDetails ? (
                        <div className="ContentLoader">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <div>
                                <NotificationComponent open={this.state.showSuccess || this.state.showFail} message={messageNotification} severity={severity}
                                    handleResetNotify={this.handleResetNotify.bind(this)} />
                                <MessageModal
                                    open={this.state.showError || false}
                                    title={comfirmModalTitle}
                                    message={message}
                                    hideCancel={false}
                                    primaryButtonText={"OK"}
                                    secondaryButtonText={"Cancel"}
                                    onConfirm={() => this.onConfirm()}
                                    handleClose={() => this.onCancel()} />
                            </div>
                            <MDBContainer fluid className="episode-clearence-container">
                                <div className='weekend'>
                                    {/* {permissionNavigateToAnEpisode && */}
                                        <>
                                            <div>
                                                <span className='Completed'> <MDBIcon
                                                    className='chevron'
                                                    icon={"chevron-left"}
                                                    onClick={this.handleShowSeasonChange}
                                                /></span>
                                                {this.state.show_name ?
                                                    <>
                                                        <span className='Completed ml5'>{this.state.show_name + ' ' + this.state.season_name}</span>
                                                        <br />
                                                        <span className='episode-header'>
                                                            {this.state.episode_name + ' ' + '(' + this.state.episode_number + ')'}
                                                        </span>
                                                        <br/>
                                                        <span className='episodeProdctuionEntity'>{this.state.productionEnity ? this.state.productionEnity : ''}</span>
                                                    </> : ' '
                                                }
                                            </div>
                                        </>
                                        {/* } */}
                                        <div className="btn-episode">
                                        <MDBRow>
                                        {efcPermissions?.view == 1 &&
                                                <MDBCol>
                                                    <BasicButton
                                                        variant="outlined"
                                                        type="inline"
                                                        text={this.state.isExceldownload ? (
                                                            <CircularProgress color="inherit" size={18} />
                                                        ) : ("Cost Sheet")}
                                                        className='costSheetBtn'
                                                    onClick={efcPermissions?.edit == 1 ? this.handleShowCuesheetExcelAndDocModal : null }
                                                        />
                                                </MDBCol>
                                        }
                                            
                                            {efcPermissions?.view == 1 &&
                                                <MDBCol>
                                                    <BasicButton
                                                        variant="outlined"
                                                        type="inline"
                                                        text={this.state.isExcelPosting ? (
                                                            <CircularProgress color="inherit" size={18} />
                                                        ) : ("EFC")}
                                                                
                                                    className='efcBtn'
                                                    onClick={this.handleOpenEFCPopup}
                                                        />
                                                         <Popover
                                                            open={openEFCPopover}
                                                            anchorEl={this.state.setEFCPopover}
                                                            onClose={() => { this.handleCloseEFCPopup() }}
                                                            getContentAnchorEl={null}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                            >
                                                <MDBRow >
                                                    <p className="popover-content-value-cl" onClick={this.handleShowExcelAndDocModal}>Display</p>
                                                    <p className="popover-content-value-cl" onClick={()=>this.downloadExcel("email")}>Email</p>
                                                </MDBRow>
                                                </Popover>
                                                </MDBCol>
                                            }
                                            {clearanceBatchesPermissions?.view == 1 &&
                                                <MDBCol>
                                                    <BasicButton
                                                        variant="outlined"
                                                        type="inline"
                                                        text={"Batches"}
                                                        className='batchBtn'
                                                        onClick={this.handleshowBatchesModal}
                                                    />
                                                </MDBCol>
                                            }
                                        </MDBRow>
                                    </div>
                                    { viewMainTitle?.view == 1 && 
                                        <div className="Completed">
                                            <span className="header-text">Clr. </span>
                                            <span className="header-text-value">{this.state.main_title_song}</span>
                                            <br />
                                            <span className="header-text">Total Fees </span>
                                            <span className="header-text-value">{this.state.total_initial_fees ? '$'+this.state.total_initial_fees : '-'}</span>
                                        </div>
                                    }
                                    { viewAirDate?.view == 1 && 
                                        <div className="Completed">
                                            <span className="header-text">Air Date </span>
                                            <span className="header-text-value">{this.state.air_date}</span>
                                            <br />
                                            <span className="header-text">Cl.Rrp </span>
                                            <span className="header-text-value">{this.state.clearance_rep}</span>
                                        </div>
                                    }
                                </div>
                                {this.state.cueSheetPermissions?.length > 0 && this.state.cueSheetPermissions?.some(item => item?.view === 1) && 
                                    <div>
                                        <CueSheet
                                            showSeasonId={this.state.show_season_id}
                                            showSeasonEpisodeId={this.state.show_season_episode_id}
                                            showId={this.state.show_id}
                                            seasonId={this.state.season_id}
                                            notificationComponent={this.notificationComponent}
                                            show_name={this.state.show_name}
                                            season_name={this.state.season_name}
                                            permissionArrCueSheets={this.state.cueSheetPermissions}
                                            division_id={this.state.division_id}
                                        />
                                    </div>
                                }
                                {/* {displayFilesAccordionPermission === true && */}
                                    <div>
                                        <EpisodeFiles
                                        //  permissionFilesTab={permissionFilesTab} 
                                         show_season_id={this.state.show_season_id} 
                                         show_name={this.state.show_name} season_name={this.state.season_name} division_id={this.state.division_id} episode_name={this.state.episode_name} {...this.props} />
                                    </div>
                                {/* } */}
                                {
                                    !this.state.showDescriptionDetails ?
                                        (<div>
                                            {this.state.isFetchingClearanceDetails ? (
                                                <div className="ContentLoader">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <>
                                                    <MDBRow className='cueSheet'>
                                                        <div className="cueSheetTable">
                                                            <div className="tab-content">
                                                                {/* {displayClearanceRecordTablePermission && */}
                                                                    <div className="file-heading">
                                                                        <span className='episode-header'>Clearance Records</span>
                                                                    </div>
                                                                {/* } */}
                                                                <div>
                                                                    <MDBRow>
                                                                        { viewNewCRPermission?.view &&
                                                                            <MDBCol className="btn-cr">
                                                                                <BasicButton
                                                                                    variant="contained"
                                                                                    type="inline"
                                                                                    color="primary"
                                                                                    text={"New CR"}
                                                                                    onClick={this.handleShowEpisodeViewClearanceRecord}

                                                                                />
                                                                            </MDBCol>
                                                                        } 
                                                                    </MDBRow>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </MDBRow>
                                                    {/* {displayClearanceRecordTablePermission && */}
                                                        <MDBRow className="songFilterField">
                                                            <MDBCol md={2} id="song-field">
                                                                <BasicTextField
                                                                    value={songFilter || ""}
                                                                    onChange={(e) => this.handleFilterText('songFilter', e.target.value)} />
                                                            </MDBCol>
                                                        </MDBRow>
                                                    {/* } */}
                                                    <MDBRow>
                                                        <MDBCol md={12}>
                                                            {/* {displayClearanceRecordTablePermission && */}
                                                                <div className="episodeClearanceTable" id="episode-clearance-table">
                                                                    <TableComponent
                                                                        list={this.state.clearenceRecordsList || []}
                                                                        className={"clearencRecord"}
                                                                        config={this.state.config}
                                                                        // hyperLinkNavigationCallback={permissionNavigateToAnClearanceRecord && this.navigationCallback}
                                                                        hyperLinkNavigationCallback={this.navigationCallback}
                                                                        episodeCallback={this.handleChangePage}
                                                                        sortCallback={this.handleTableColumnClick}
                                                                        stickyHeader={true}
                                                                    />
                                                                <div className="Pagination" >
                                                                    <TablePagination
                                                                        component="div"
                                                                        count={this.state.backUpclearenceRecordsList ? this.state.backUpclearenceRecordsList?.length : 0}
                                                                        rowsPerPageOptions={[20, 30, 50, 100]}
                                                                        page={this.state.clearancePage}
                                                                        onChangePage={this.handleTableChangePage}
                                                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                        rowsPerPage={this.state.clearancerowsPerPage}
                                                                    />
                                                                </div>
                                                                </div>
                                                            {/* } */}
                                                        </MDBCol>
                                                    </MDBRow>
                                                </>)}
                                        </div>)
                                        :
                                        <>
                                            {this.state.isFetchingClearanceDetails ?
                                                <div className="ContentLoader">
                                                    <CircularProgress />
                                                </div> :
                                                <CompositionFiles
                                                    show_name={this.state.show_name}
                                                    handleChangePage={this.handleChangePage}
                                                    licensorsInfo={this.state.licensorsInfo}
                                                    clearneceLicenserInfo={this.state.clearneceLicenserInfo}
                                                    handleGeneralEdit={this.handleGeneralEdit}
                                                    handleListEdit={this.handleListEdit}
                                                    mode={this.state.mode}
                                                    setMode={this.setMode}
                                                    notificationComponent={this.notificationComponent}
                                                    notificationMessage={this.notificationMessage.bind(this)}
                                                    fieldChanged={this.fieldChanged}
                                                    defaultRights={defaultRights}
                                                    // showSeasonId={6}
                                                    // showId={5}
                                                    // seasonId={8}
                                                    showSeasonId={this.state.show_season_id}
                                                    showId={this.state.show_id}
                                                    seasonId={this.state.season_id}
                                                    season_name={this.state.season_name}
                                                    episode_number={this.state.episode_number}
                                                    handleCopyLicensor={this.handleCopyLicensor}
                                                    clearenceId={this.state.clearenceId.clearance_id}
                                                    show_season_episode_id={this.state.show_season_episode_id}
                                                    song_id={this.state.selectedSong?.song_id}
                                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                                    // permissionArrClearanceRecords={permissionArrClearanceRecords}
                                                    division_id={this.state.division_id}
                                                    isMainTitle={false}
                                                    clearancePermissions = {this.state.clearancePermissions}
                                                    EmailLetterPermission={this.state.EmailLetterPermission}
                                                    applyHVPermission = {this.state.applyHVPermission}
                                                    songAndPerformerPermissions = {songAndPerformerPermissions}
                                                    clearanceBatchesPermissions = {clearanceBatchesPermissions}
                                                    episodeSongPermissions={episodeSongPermissions}
                                                />
                                            }
                                        </>
                                }
                                {/* </div> */}
                            </MDBContainer>
                            {
                                this.state.showBatchesModal &&
                                <MainViewModalComponent
                                    open={this.state.showBatchesModal}
                                    handleClose={this.handleHideBatchesModal}
                                    headerText={"Batches"}
                                    mode={""}
                                    modalName={"Batches"}
                                    show_season_episode_id={this.state.show_season_episode_id}
                                    isMainTitle={false}
                                    clearanceBatchesPermissions={clearanceBatchesPermissions}
                                    division_id={this.state.division_id}
                                />
                            }

                            {/* {this.state.showEpisodeViewClearanceRecord && (
                                <MainViewModalComponent
                                    open={this.state.showEpisodeViewClearanceRecord}
                                    handleClose={this.handleHideEpisodeViewClearanceRecord}
                                    headerText={"Clearance Record"}
                                    mode={"New"}
                                    seasonInfo={this.state.show_season_id}
                                    modalName={"Clearance Record"}
                                />
                            )} */}

                            {this.state.showEpisodeViewClearanceRecord && (
                                <MainViewModalComponent
                                    open={this.state.showEpisodeViewClearanceRecord}
                                        handleSubmit={(ssId,epId,clId)=> this.setState({clearanceRedirect : [ssId,epId,clId]})}
                                    handleClose={this.handleHideEpisodeViewClearanceRecord}
                                    headerText={"Clearance Record"}
                                    mode={"New"}
                                    from={false}
                                    seasonInfo={this.state.show_season_id}
                                    modalName={"Clearance Record"}
                                    isClearanceRecord={true}
                                    show_name={this.state.show_name}
                                    season_name={this.state.season_name}
                                    episode_name={this.state.episode_name}
                                    episodeId={this.state.show_season_episode_id}
                                    seasonId={this.state.season_id}
                                    showId={this.state.show_id}
                                    show_season_id={this.state.show_season_id}
                                    clearance_divisionId={this.state.division_id}
                                    toggleRefreshClearanceList={this.getClearenceRecordsList}
                                    // commonPermissions={filteredDivision[0].permissions}
                                    toggleRefreshPage={this.props?.toggleRefreshPage}
                                    viewNewCRPermission={viewNewCRPermission}
                                    applyHVPermission = {this.state.applyHVPermission}
                                    episodeSongPermissions={episodeSongPermissions}
                                />
                            )}
                            {this.state.showCuesheetExcelAndDocModal && (
                                <>
                                    <MainViewModalComponent
                                        open={this.state.showCuesheetExcelAndDocModal}
                                        handleClose={this.handleHideCuesheetExcelAndDocModal}
                                        headerText={"Generate Excel/DOC"}
                                        mode={" "}
                                        modalName={"Excel And Doc Modal"}
                                        downloadExcel={this.downloadExcelCostSheet}
                                        isOpenExisting = {this.isOpenExisting.bind(this)}
                                        isExcelPosting={this.state.isExceldownload}
                                        />
                                </>
                             )}
                              {this.state.showExcelAndDocModal && (
                                <>
                                    <MainViewModalComponent
                                        open={this.state.showExcelAndDocModal}
                                        handleClose={this.handleHideExcelAndDocModal}
                                        headerText={"Generate Excel/DOC"}
                                        mode={ " "}
                                        modalName={"Excel And Doc Modal"}
                                        downloadExcel={()=>this.downloadExcel("isExcel")}
                                        isOpenExisting = {this.isOpenExisting.bind(this)}
                                        isExcelPosting={this.state.isExcelPosting}
                                        />
                                </>
                             )}
                        </div>
                    )
                }
            </>

        );

    }
}));