import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import './AsynchronousSearch.scss';
import SearchIcon from "@material-ui/icons/Search";
import { selectOptions } from "./Asynchronous";
import SearchResultsLayout from './SearchResultsLayout';
import { withUserContext } from '../../../contexts/UserContext';
import * as Constants from '../../../constants/constants';
import clearTrackService from '../../../services/service';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';

class AsynchronousSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            options: [],
            loading: false,
            isOpen: false,
            parentDropDownSelection: 1,
            childDropDownSelection: [],
            defautSelection: [],
            searchCancelToken: null,
            searchKeyWord: '',
            totalCount : null,
            openModalName:null,
            itemId: null,
            headerText : null,
            globalSearchOptions:[],
            showLengthError: false,
            commonPermissions:[]
        }
    }

    componentDidMount () {
        if(this.props?.userContext?.active_tenant?.common_permissions?.length > 0) {
            let globalSearchOptions = [{
                label: "All",
                value: 1,
                searchType : "ALL",
                placeHolder: "All"
            }]
            for(let i = 0 ; i < selectOptions.length ; i ++) {
                if(this.props?.userContext?.active_tenant?.common_permissions?.find(item => item?.permission_name === selectOptions[i]?.permissionName)) {
                    globalSearchOptions.push(selectOptions[i]) 
                } else {
                    continue
                }
            }
            let common_permissions = this.props?.userContext?.active_tenant?.common_permissions
            this.setState({globalSearchOptions:globalSearchOptions,commonPermissions:common_permissions})
        }
    }


    componentDidUpdate (prevProps, prevState) {
        if((this.props.userContext?.active_tenant?.tenant_id !== prevProps?.userContext?.active_tenant?.tenant_id) || 
        (this.props.userContext?.active_tenant?.common_permissions !== prevProps?.userContext?.active_tenant?.common_permissions)) {
            if(this.props?.userContext?.active_tenant?.common_permissions?.length > 0) {
                let globalSearchOptions = [{
                    label: "All",
                    value: 1,
                    searchType : "ALL",
                    placeHolder: "All"
                }]
                for(let i = 0 ; i < selectOptions.length ; i ++) {
                    if(this.props?.userContext?.active_tenant?.common_permissions?.find(item => item?.permission_name === selectOptions[i]?.permissionName)) {
                        globalSearchOptions.push(selectOptions[i]) 
                    } else {
                        continue
                    }
                }
                let common_permissions = this.props?.userContext?.active_tenant?.common_permissions
                this.setState({globalSearchOptions:globalSearchOptions,commonPermissions:common_permissions})
            }
        }
    }
    
    onChangeHandle = (searchString) => {
        if (this.state.searchCancelToken != null) {
            this.state.searchCancelToken.cancel("Operation canceled due to new request.");}
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });
        if(searchString.length > 0) {
            let searchObj = `{"data_type":"${selectOptions.find(item => item.value === this.state.parentDropDownSelection).searchType}","search_string":"${searchString}","page_count":"10","page_no":"1"}`
            clearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl + '/globalSearch?input='+btoa(searchObj) ,  cancelToken ,this.props.userContext?.active_tenant?.tenant_id) 
                .then(response => {
                    this.setState({ options : response.data.data, loading: false, searchKeyWord: searchString, totalCount: response.data.total_count });
                },
                    (err) => {
                        this.setState({ options: [], loading: false })
                    })
        } else {
            this.setState({ options : [], loading: false, searchKeyWord: "", totalCount: 0 });
        }
    }

    filterOptions = createFilterOptions({
        stringify: (option) => option.name
    });

    handleParentSearchChange = (event) => {
        this.setState({ parentDropDownSelection: event.target.value, options: [], totalCount:0 })
    };

    

    handleSearchOption = (newValue) => {
        if(newValue.data_type == 'SHOW_SEASONS') {
            window.open(`/showseason/${newValue.id.slice(13)}`)
        } else if (newValue.data_type == 'CLEARANCE_RECORDS') {
            this.setState({openModalName: 'Clearance Record', headerText: 'Clearance Record', itemId : newValue.id.slice(18) })
        } else if (newValue.data_type == 'SONGS'){
            this.setState({openModalName: 'Song', headerText: 'Song',  itemId : newValue.id.slice(6) })
        } else if (newValue.data_type == 'LICENSORS'){
            this.setState({openModalName: 'Edit Licensor', headerText: 'Licensor', itemId : newValue.id.slice(10) })
        } else if (newValue.data_type == 'PAYEES'){
            this.setState({openModalName: 'Payee', headerText: 'Payee',  itemId : newValue.id.slice(7) })
        } else if (newValue.data_type == 'MUSIC_EDITORS') {
            this.setState({openModalName: 'Person', headerText: 'Person- Music Editor',  itemId : newValue.id.slice(14) })
        } else if(newValue.data_type == 'SHOW_COMPOSERS') {
            this.setState({openModalName: 'Show Composer', headerText: 'Show Composer',  itemId : newValue.id.slice(15) })
        }
    }

    render() {
        return (
            <>
                <div class='row PLSearch'>
                    <div class='col-3' id='txtAllSelect'>
                        <Select
                            variant="outlined"
                            displayEmpty className='selectStyle'
                            defaultValue={this.state.parentDropDownSelection}
                            onChange={this.handleParentSearchChange}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                }
                            }}
                        >
                            {
                                this.state.globalSearchOptions?.map((item, index) => {
                                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                })
                            }
                        </Select>

                    </div>
                    <div class='col-9 ptPt4' id='txtSearch'>
                        <Autocomplete value={this.state.defautSelection}
                            id="asynchronous-demo" className='autoCompleteStyle'
                            open={this.state.open}
                            onOpen={() => {
                                this.setState({ open: true })
                            }}
                            onClose={() => {
                                this.setState({ open: false ,showLengthError:false})
                            }}
                            renderOption={(props, option) => (
                                <>
                                    <li {...props}>
                                        <SearchResultsLayout searchResult={props}></SearchResultsLayout>
                                    </li>
                                </>
                            )}
                            onChange={(event, newValue) => {
                                if (newValue && newValue.result_type != 'recordCount') {
                                   this.handleSearchOption(newValue)
                                } else if (newValue && newValue.result_type == 'recordCount') {
                                    this.setState({ defautSelection: [] })
                                    this.props.history.push('/userSearch/' + this.state.parentDropDownSelection + '/' + this.state.searchKeyWord)
                                } else {
                                    this.setState({ defautSelection: [] })
                                }
                                this.setState({options : [], totalCount:0})
                            }}
                            filterOptions={(options, params) => {
                                options = this.filterOptions(options, params)
                                if (this.state.totalCount > 0) {
                                    let type = this.state.totalCount === 1 ? 'result' : 'results'
                                    options.unshift({
                                        sub_group : `${this.state.totalCount} search ${type}`
                                    })
                                    if (this.state.totalCount > 1) {
                                        options.push({
                                            name: `See all ${this.state.totalCount} results`,
                                            result_type: 'recordCount'
                                        });
                                    }
                                } else {
                                    options=[]
                                }
                                return options;
                            }}
                            getOptionLabel={(option) => option.name}
                            options={this.state.options}
                            loading={this.state.loading}
                            renderTags={() => null}
                            noOptionsText={this.state.showLengthError ? 'Please enter less than 50 chars':"No labels"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={this.state.globalSearchOptions?.find(item => item?.value === this.state.parentDropDownSelection)?.placeHolder}
                                    variant="outlined"
                                    onChange={ev => {
                                        this.setState({showLengthError : false} ,() => {
                                            if(ev.target.value?.length > 50) {
                                                this.setState({showLengthError:true})
                                            } else {
                                                if (ev.target.value !== "" || ev.target.value !== null) {
                                                    this.setState({ loading: true }, () => { this.onChangeHandle(ev.target.value) })
                                                } else { this.setState({ loading: true, options: [] }) }
                                            }
                                        })
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                <SearchIcon className='MRN3' />
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                {
                    this.state.openModalName &&
                    <MainViewModalComponent
                        open={this.state.openModalName ? true : false}
                        handleClose={() => this.setState({ openModalName: null, itemId:null, headerText:null,})}
                        headerText={this.state.headerText}
                        mode={'Edit'}
                        commonPermissions={this.state.commonPermissions}
                        modalName={this.state.openModalName}
                        {...(this.state?.openModalName === 'Person' ? {modeType:'Music Editor'} :{})}
                        {...(this.state?.openModalName === 'Person' ? {people_id:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Person' ? {origin:'profile'} :{})}
                        {...(this.state?.openModalName === 'Song' ? {songId:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Edit Licensor' ? {id:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Clearance Record' ? {clearenceId:this.state.itemId.split('_')[0]} :{})}
                        {...(this.state?.openModalName === 'Clearance Record' ? {from:false} :{})}
                        {...(this.state?.openModalName === 'Clearance Record' ? {clearance_divisionId:this.state.itemId.split('_')[1]} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {modeType:'SHOW_COMPOSER'} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {people_id:this.state.itemId} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {origin:'dashboard'} :{})}
                        {...(this.state?.openModalName === 'Show Composer' ? {showSecondtab:true} :{})}
                        {...(this.state?.openModalName === 'Payee' ? {id:this.state.itemId} :{})}
                    />
                }
            </>
        );
    }

}
export default  withRouter(withUserContext(AsynchronousSearch));