export const ShowSeasonDetails = {
    general: {
        "production_entity": "warner Bro's distribution",
        "mpm": 325435,
        "show_type": "TV",
        "network": "Show Time",
        "time": "9.00 PM - 10.00 PM (1 Hour)",
        "notes": "Initial Rights are locked as of season 4 at $5K perp or $8k AMXT. Option 1 is always MDN and Downloading in perp is locked at $4k. Home Video options are as follows: $6K 15/16, $7K 16/17, $7500 17/18 and beyond.",
        "options": [{ "name": "Music Replaced", "checked": false },
        { "name": "Create Excel w/batch", "checked": true },
        { "name": "Override CostSheet-MS", "checked": false },
        { "name": "Seperate HV Checks/Batch", "checked": false }]
    },
    contacts: {
        "production_exec": "Sue palladino",
        "clearance_rep": "Robert bejowxz",
        "Estimating_manager": "None",
        "cl_rep_assistant": "george stephen",
        "estimater": "John Bejowex"
    },
    default_rights: {
        "initial_rights": "ALL MEDIA 06/07 X-HV",
        "term": "Perp",
        "territory": "WW",
        clearance_budget: [{
            "cost_center": "4648-initial",
            "budget": "$00000000",
            "total_budget": "$00000000",
            "music_amort_code": "None",
            "series_amort": "T7438563"
        }, {
            "cost_center": "4648-Perp",
            "budget": "$0000000",
            "total_budget": "",
            "music_amort_code": "None",
            "series_amort": ""
        }]
    }
}

export const EpisodeCostConfig = {
    cost: null,
    description: null,


}

export const EpisodeListConfig = {
    headings: [
        {
            headingLabel: "#",
            icon: "",
            width: "4%"
        },
        {
            headingLabel: "Episode #",
            icon: "",
            inputType: "text",
            width: "6%",
            dataNode: "episode_number"
        },
        {
            headingLabel: "Title",
            icon: "",
            width: "6%",
            inputType: "text",
        },
        {
            headingLabel: "Air Date",
            inputType: "datefield",
            icon: "",
            width: "7%"
        },
        {
            headingLabel: "EFC",
            icon: "",
            width: "3%"
        },
        {
            headingLabel: "BCH",
            icon: "payment",
            width: "3%"
        },
        {
            headingLabel: "CS",
            icon: "",
            width: "8%",
        },
        {
            headingLabel: "Main Title",
            icon: "",
            width: "6%",
            inputType: "text"
        },
        {
            headingLabel: "Mix Date",
            icon: "",
            width: "6%",
            inputType: "datefield",
        },
        {
            headingLabel: "NoMT?",
            icon: "",
            width: "4%",
        },
        {
            headingLabel: "BdgtDiff?",
            icon: "",
            width: "5%",
            inputType: "checkbox"
        },
        {
            headingLabel: "Ngtd.4646",
            icon: "",
            width: "6%",
            inputType: "text"
        }, {
            headingLabel: "Ngtd Budget",
            icon: "",
            width: "6%",
            inputType: "text"
        }, {
            headingLabel: "Ngtd New",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "Ep.Costs",
            icon: "",
            width: "5%"
        },
        {
            headingLabel: "Notes",
            icon: "",
            width: "4%"
        },
        {
            headingLabel: "Cmplt",
            icon: "",
            width: "4%"
        },
        {
            headingLabel: "No Lic. Music",
            icon: "",
            width: "5%"
        },  
    ],
    primaryKey: "show_season_episode_id",
    dataNodes: ["index", "episode_number", "episode_title", "air_date", "efc", "bch", "cue_sheet_revision_send_date", "main_title", "mixed_date","is_no_mt","is_budget_difference", "ngtd_4646", "episode_negotiated_costs", "is_ngtd_new", "episode_cost", "notes", "is_completed", "is_no_lincensed_music"],
    actions: ["pen", "times"],
    nodeFields: [
        {
            column: "efc",
            icon: "money-bill"
        },
        {
            column: "bch",
            icon: "money-check"
        },
        {
            column: "episode_cost",
            icon: "file"
        },
        {
            column: "episode_negotiated_costs",
            icon: "file"
        },
        {
            column: "notes",
            icon: "file"
        },
        // {
        //     column: " ",
        //     icon: "flag"
        // },
        {
            column: "is_ngtd_new",
            icon: "check"
        },
        {
            column: "is_no_mt",
            icon: "check"
        },
        {
            column: "is_budget_difference",
            icon: "check"
        },
        {
            column: "is_completed",
            icon: "check"
        },
        {
            column: "is_no_lincensed_music",
            icon: "check"
        },
    ],

    primaryKey: "id",
    // toggleFields: ["is_ngtd_new", "is_budget_difference", "is_completed"],
    buttonFields: [{ column: "cue_sheet_revision_send_date", icon: " ", showDataNodeValue: true }],
    hyperlinks: ["episode_number"],
    inlineEdits: [
        {
            dataNode: "index",
            width: "5%"
        },
        {
            dataNode: "episode_number",
            width:"6%"
        },
        {
            dataNode: "episode_title",
            width:"6%"
        },
        {
            dataNode: "air_date",
            width:"7%"
        },
        {
            dataNode: "efc",
            width:"4%"
        },
        {
            dataNode: "bch",
            width:"4%"
        },
        {
            dataNode: "cue_sheet_revision_send_date",
            width:'8%'
        },
        {
            dataNode: "main_title",
            width:'6%'
        },
        {
            dataNode: "mixed_date",
            width:'6%'
        },
        {
            dataNode: "is_no_mt",
            width:'4%'
        },
        {
            dataNode: "is_budget_difference",
            width:'6%'
        },
        {
            dataNode: "ngtd_4646",
            width: '6%',
            inlineIcon:"$"
        },
        {
            dataNode: "episode_negotiated_costs",
            width:'8%'
        },
        {
            dataNode: "is_ngtd_new",
            width:'6%'
        },
        {
            dataNode: "episode_cost",
            width: '5%'
        },
        {
            dataNode: "notes",
            width: '4%'
        },
        {
            dataNode: "is_completed",
            width: '4%'
        }

    ]
};
export const PopoverOptions = [
    {
        title: "All Media 06/07 X-HV",
        name: "Perp",
        value: "$852,050.00"
    },
    {
        title: "All Media 06/07 X-THEA",
        name: "Perp",
        value: "$852,050.00"
    },
    {
        title: "6 Yr HOME VIDEO 06/07",
        name: "Perp",
        value: "$852,050.00"
    },
]
export const InfoPopoverOptions = [
    {
        title: "Default Alt Initial",
        name: "[Rights]",
        value: "[Term], [Territory]"
    },
    {
        title: "Default Option",
        name: "6 YRS HOME VIDEO 06/07",
        value: "1 year, WW"
    },
    {
        title: "Default Option",
        name: "[Rights]",
        value: "[Term], [Territory]"
    },
]
export const PopoverReportOptions = [
    {
        label: "Main Title Report",
        value: "mainTitleReport",
        permissionName : 'Main Title Report',
    },
    {
        label: "Mix Date Report",
        value: "mixDateReport",
        permissionName : 'Mix Date report',
    },
    {
        label: "Music Replacement Report",
        value: "musicReplacementReport",
        permissionName : 'Music Replacement report',
    },
    {
        label: "Show Season Song/Cost To Date",
        value: "showSeasonSongCostToDate",
        permissionName : 'Show Season Song/Cost To Date report',
    },
    {
        label: "Budget vs EFC",
        value: "budgetvsEFCbyEpisode",
        permissionName : 'Budget vs. EFC by Episode report',
    },
]
export const FilePopoverOptions = [
    {
        label: "No MT",
        checked: false
    },
    {
        label: "Budget Difference",
        checked: false
    },
    {
        label: "Music Replaced",
        checked: false
    }, {
        label: "Excl. from Bdgt/EFC Report",
        checked: false
    }, {
        label: "Mixed",
        checked: false
    },
]