import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateNumbersOnly } from '../../Common/Helper'
import { withUserContext } from '../../contexts/UserContext'
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class FringeDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            mandatoryNotExist: false,
            duplicateDetail:false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config:JSON.parse(JSON.stringify({ ...this.props?.config}))
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps?.list !== this.props?.list && this.props?.list?.length > 0) {
            let configRecord = {...this.state.config}
            this.props?.permissionArray?.edit === 1 ? configRecord.actions = ["pen"] : configRecord.actions = [ ]
            this.setState({renderList:this.props.list,sortCount:0,sortBy:null,isSortByAsc: true,config: configRecord}, () => {
                this.handleTableColumnClick('Detail')
            })
        }
    }

    handleInlineEdits = (id, dataItem) => {
        let fringeDetails = [...this.state?.renderList]
        this.setState({
            mandatoryNotExist: false,
            renderList: fringeDetails.map((item) => {
                let newItem = {...item}
                if (item.id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({mandatoryNotExist:false,duplicateDetail:false})
        if(!data[index]?.detail || !validateNumbersOnly(data[index]?.detail)){
            this.setState({mandatoryNotExist:true})
        }
       else  if(data[index]?.detail && this.state?.renderList?.some((item,i)=>item.detail == data[index]?.detail && index != i)){
            this.setState({duplicateDetail:true})
        }
        else{
        if(data[index]?.detail && validateNumbersOnly(data[index]?.detail)) {
            let fringeDetails = [...this.state?.renderList]
            fringeDetails[index].id = data[index].id,
            fringeDetails[index].detail = data[index].detail || null,
            fringeDetails[index].is_active = data[index].is_active?1:0
            this.setState({
                renderList: fringeDetails.map((item) => {
                    if (item.id == pkValue)
                        item['editing'] = false;
                    return item
                })
            })
            let postData = {
                id : fringeDetails[index]?.id,
                name: fringeDetails[index]?.detail || null,
                is_active: fringeDetails[index]?.is_active
            }
            this.props.handleSave(postData, "lu_fringe_detail" , postData?.id);
        } else {
            this.setState({mandatoryNotExist:true})
        }
    }
    }

    handleAddNewItem = (newDataItem) => {
        this.setState({mandatoryNotExist:false,duplicateDetail:false})
        if(!newDataItem?.detail || !validateNumbersOnly(newDataItem?.detail)){
            this.setState({mandatoryNotExist:true})
        }
       else  if(newDataItem?.detail && this.state?.renderList?.some(item=>item.detail == newDataItem?.detail)){
            this.setState({duplicateDetail:true})
        }
        else{

       
        if(newDataItem?.detail && validateNumbersOnly(newDataItem?.detail)) {
        let addList = {
            id: null,
            name: newDataItem.detail || null,
            is_active: 1
        }
        this.props.handleSave(addList, "lu_fringe_detail",'newItemPost');
    } else {
        this.setState({mandatoryNotExist:true})
    }
}
    }
    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
          this.setState({ sortCount: this.state.sortCount + 1 }, () => {
            this.handleTableSort(column);
          })
        }
        else this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        })
      }
      handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            sortBy: colName,
            config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, this.state.sortBy, colName),
            renderList: tableSortList(colName === 'Active' ? 'Number':"Name", this.getSortNode(colName), this.state?.renderList, this.state.isSortByAsc)
          });
        })
      }
    
      getSortNode = (col) => {
        switch (col) {
          case "Detail": return "detail";
          case "Active": return "is_active";
        }
      }    

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="detailContainer">
                <MDBRow>
                    <span className="description">Detail codes for Fringe costs on TV Music Creative Budgets</span>
                </MDBRow>
                <MDBRow className='pd'>
                <span className="errorText">{this.state.mandatoryNotExist && `Please enter Detail field's value`}</span>
                <spna className='errorText'>{this.state?.duplicateDetail && `Detail is already exist`}</spna>
                </MDBRow>
                {this.props?.loadingOptions ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <MDBRow className="tableComponent">
                            <TableComponent
                                list={this.state?.renderList || []}
                                config={this.state?.config}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1 ? true : false}
                                postInitiated={this.props?.isSaveData}
                                //isLoading={this.props?.isSaveData}
                                stickyHeader={true}
                                newIsActive={true}
                                readCallback={(index) => {
                                    let fringeData = [...this.state.renderList];
                                    fringeData[index].editing = false
                                    this.setState({ renderList:fringeData, nameNotExist:false });
                                }}
                                sortCallback={this.handleTableColumnClick}
                            />
                        </MDBRow>
                        )}
            </MDBContainer>
        )
    }
}

export default withUserContext(FringeDetail);