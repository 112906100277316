import React from "react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import TableComponent from "../SharedComponents/Table";
import ClearTrackService from "../../services/service";
import * as Constants from "../../constants/constants";
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from "../../contexts/UserContext";
import {validateNumbersOnly} from '../../Common/Helper'
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class Seasons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderList: [],
      isSubmit: false,
      isLoading: false,
      showCostSplitsModal: false,
      costSpiltFromSeason: false,
      mandatoryNotExist:false,
      sortBy: null,
      isSortByAsc: true,
      sortCount: 0,
      config: JSON.parse(JSON.stringify({ ...this.props?.config })),
      startEndYearInvalid:false
    };
  }
  componentDidMount() {
    this.getSeasonDetails();
  }

  getSeasonDetails = (loader = true) => {
    this.setState({ isLoading: loader, })
    ClearTrackService.getData(
      Constants.ClearTrackServiceBaseUrl +
      `/entitySearch?entity=${"SEASON"}&searchString=null`, this.props.userContext?.active_tenant?.tenant_id)
      .then((res) => {
        let seasonDetails = res.data;
        let configRecord = {...this.state.config}
        this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
        this.setState({ renderList: seasonDetails, isLoading: false, isSubmit:false,sortBy: null,
          isSortByAsc: true, config: configRecord,
          sortCount: 0,}, () => {this.handleTableColumnClick("Start Year");});
      })
      .catch((err) => {
        this.setState({ isLoading: false })
      });
  }

  handleInlineEdits = (id, dataItem) => {
    let seasonDetails = [...this.state.renderList];
    this.setState({
      mandatoryNotExist:false,
      renderList: seasonDetails.map((item) => {
        let newItem = {...item}
        if (item.season_id === id) newItem["editing"] = true;
        return newItem;
      }),
    });
  };

  saveInlineEdits = (data, index) => {
    this.setState({mandatoryNotExist : false , startYearDublicate: false, endYearDublicate: false,yearDublicate: false})
    if(this.state.renderList.some((item,i)=>i != index && item.start_year ===  data[index].start_year) && this.state.renderList.some((item,i)=>i != index && item.end_year ===  data[index].end_year)  ){
      this.setState({yearDublicate: true})
    }else if(this.state.renderList.some((item,i)=>i != index && item.end_year ===  data[index].end_year)){
      this.setState({endYearDublicate: true})
    }else if(this.state.renderList.some((item,i)=>i != index && item.start_year ===  data[index].start_year)){
        this.setState({startYearDublicate: true})
    } else if(validateNumbersOnly(data[index].start_year) && validateNumbersOnly(data[index].end_year) && parseInt(data[index].start_year) >= parseInt(data[index].end_year)){
      this.setState({startEndYearInvalid:true})
    }else{
      if(validateNumbersOnly(data[index]?.start_year) && data[index]?.start_year?.length > 0 && validateNumbersOnly(data[index]?.end_year) && data[index]?.end_year?.length > 0) {
        let list = [...this.state.renderList]
        list[index].season_name = data[index].start_year - data[index].end_year
        list[index].start_year  = data[index].start_year 
        list[index].end_year = data[index].end_year
        this.setState({
          renderList: list.map((item) => {
              item['editing'] = false
              return item
          })
      })
        this.postData(list[index],list[index]?.season_id);
    } else {
        this.setState({mandatoryNotExist : true })
    }
    }
  };

  postData = (data,postFlag) => {
    this.setState({ isSubmit: postFlag })
    ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/season`, data,this.props.userContext?.active_tenant?.tenant_id)
      .then((response) => {
        if (response.data.error) {
          this.setState({ isSubmit: false })
          this.props?.toastMessageNotification(true, "fail");
        }        else {
          //this.setState({ isSubmit: false })
          if (data?.editing) {
            data.editing=false
          }
          this.props?.toastMessageNotification(true, "success");
          this.getSeasonDetails(false)
        }
      },
        (err) => {
          this.setState({isSubmit: false })
          this.props?.toastMessageNotification(true, "fail");
        });
  }

  handleShowCostSplitsModal = (dataItem) => {
    this.setState({ showCostSplitsModal: true, costSpiltFromSeason: true , seasonId: dataItem?.season_id});
  }
  handleHideCostSplitsModal = () => {
    this.setState({ showCostSplitsModal: false , costSpiltFromSeason: false , seasonId:null});
  }

  // checkDuplicate (start, end) {
  //   let newSeason = 
  // }

  handleAddNewItem = (newDataItem) => {
    this.setState({mandatoryNotExist : false , startYearDublicate: false, endYearDublicate: false,yearDublicate: false,startEndYearInvalid:false})
    if(this.state.renderList.some(item=>item.start_year ===  newDataItem.start_year) && this.state.renderList.some(item=>item.end_year ===  newDataItem.end_year)  ){
      this.setState({yearDublicate: true})
    }else if(this.state.renderList.some(item=>item.end_year ===  newDataItem.end_year)){
      this.setState({endYearDublicate: true})
    }else if(this.state.renderList.some(item=>item.start_year ===  newDataItem.start_year)){
        this.setState({startYearDublicate: true})
    }
    else if(validateNumbersOnly(newDataItem.start_year) && validateNumbersOnly(newDataItem.end_year) && parseInt(newDataItem.start_year) >= parseInt(newDataItem.end_year)){
      this.setState({startEndYearInvalid:true})
    }else{
      if(validateNumbersOnly(newDataItem?.start_year) && newDataItem?.start_year?.length > 0 && validateNumbersOnly(newDataItem?.end_year) && newDataItem?.end_year?.length > 0) {
        let list = [...this.state.renderList]
        let newData = {
          "season_id": null,
          "start_year": newDataItem.start_year,
          "end_year": newDataItem.end_year,
          "season_name": newDataItem.start_year - newDataItem.end_year,
          "is_visible_online": 0,
          "is_current": 1,
          "is_active": 1,
          "is_delete": 0,
        }
        // list.push(newData)
        // this.setState({
        //   renderList: list
        // })
        this.postData(newData,'newItemPost');
        this.setState({startYearDublicate: false, endYearDublicate: false,yearDublicate: false,startEndYearInvalid:false})
      } else {
        this.setState({mandatoryNotExist : true })
      }
    }
   
  };
  handleTableColumnClick = (column) => {
    if (column === this.state.sortBy) {
      this.setState({ sortCount: this.state.sortCount + 1 }, () => {
        this.handleTableSort(column);
      })
    }
    else this.setState({ sortCount: 0 }, () => {
      this.handleTableSort(column);
    })
  }

  handleTableSort = (colName) => {
    this.setState({
      isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
    }, () => {
      this.setState({
        sortBy: colName,
        config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
        renderList: tableSortList("Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
      });
    })
  }

  getSortNode = (col) => {
    switch (col) {
      case "Start Year": return "start_year";
      case "End Year": return "end_year";
    }
  }
render() {
  let permissionValue = this.props?.permissionArray
    return (
      <MDBContainer fluid className="seasonContainer">
        <MDBRow>
          <span className="description">
            Selectable seasons in the system
          </span>
        </MDBRow>
        <MDBRow className="pd">
        <span className="errorText">{this.state.mandatoryNotExist && 'Please enter Start and End Year field value'}</span>
        <span className="errorText">{this.state.startYearDublicate && 'This Start Year Already Exist'}</span>
        <span className="errorText">{this.state.endYearDublicate && 'This End Year Already Exist'}</span>
        <span className="errorText">{this.state.yearDublicate && 'Start and End Year Already Exist'}</span>
        <span className="errorText">{this.state?.startEndYearInvalid && 'Invalid Start and End Year'}</span>
        </MDBRow>
        {this.state.isLoading ?( <div className="ContentLoader">
          <CircularProgress />
      </div> ) :( <MDBRow className="SeasonsTable" id={'adminsetupclrtrkseasontable'}>
          <TableComponent
            list={this.state.renderList || []}
            config={this.state.config}
            handleAddNewItem={this.handleAddNewItem.bind(this)}
            editCallback={this.handleInlineEdits.bind(this)}
            saveCallback={this.saveInlineEdits.bind(this)}
            addItemToList={permissionValue?.edit === 1}
            postInitiated={this.state.isSubmit}
           // isLoading={this.state.isSubmit}
            handleOpenInlineModal={this.handleShowCostSplitsModal} 
            readCallback={(index) => {
              let seasonOptions = [...this.state.renderList];
              seasonOptions[index].editing = false
              this.setState({ renderList: seasonOptions, mandatoryNotExist:false });
            }}
            stickyHeader={true}
            sortCallback={this.handleTableColumnClick}
          />
        </MDBRow>)}
        {
          this.state.showCostSplitsModal &&
          <MainViewModalComponent
            open={this.state.showCostSplitsModal}
            handleClose={this.handleHideCostSplitsModal}
            headerText={"Edit Cost Splits"}
            mode={""}
            modalName={"Edit Cost Splits"}
            seasonId={this.state.seasonId}
            costSpiltFromSeason={this.state.costSpiltFromSeason}
            isMaintitle={false}
            commonPermissions={this.props?.permissionArray}
          />
        }


      </MDBContainer>
    );
  }
}

export default withUserContext(Seasons);
