import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { showSeasonsConfig, seasonsList } from '../config';
import TableComponent from '../../../SharedComponents/Table';
import TablePagination from '@material-ui/core/TablePagination';
import '../MusicEditorDetails.scss';

export default class ShowComposerSeasons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headings: JSON.parse(JSON.stringify({ ...showSeasonsConfig })),
            renderList: this.props.associatedSeasonsDetails,
            isLoading: false,
            rowsPerPage: 10,
            page: 0,
            isSortByAsc: true,
            sortCount: 0,
            currentList: this.props.associatedSeasonsDetails
        }
    }
    navigationCallback = (obj, node) => {
        if (node === "show_name" || node === "season_name" || node === "share") {
            window.open(`/showseason/${obj.show_season_id}`, '_blank');
        }
    }
    
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.currentList)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.currentList)
        });
    }
    render() {
        return (
            <>
                {this.state.isFetchingEpisodeAndClearenceDetails ? (
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div>
                ) : (
                        <div className="ShowSeasonTableContainer" id="table-bg">
                            <TableComponent
                                list={this.state.renderList || []}
                                className={"showSeasonTable"}
                                config={this.state.headings}
                                hyperLinkNavigationCallback={this.navigationCallback}
                                handleOpenPopUp={(event, dataItem) => this.props.handleShareClick(event, dataItem, this)}
                            // handleOpenPopUp={(event, dataItem) => this.props.handleShareClick(event, dataItem, this)}
                            //episodeCallback={this.handleChangePage}
                            />
                            <div className="Pagination" >
                            <TablePagination
                            component="div"
                            count={this.state.currentList ? this.state.currentList?.length : 0}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                            </div>
                        </div>

                    )
                }
            </>
        )
    }
}