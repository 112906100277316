import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from 'mdbreact';
import { withUserContext } from '../../../contexts/UserContext';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { PayIncreaseDetails } from './Config';
import { CircularProgress } from "@material-ui/core";
import './TvMusicBudget.scss';
import { validateNumDotOnly } from '../../../Common/Helper';

class payIncrease extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payIncreaseDetails: PayIncreaseDetails,
            isPosting: false,
            postInitiated: false,
        }

    }

    postPayIncrease = () => {
        if(this.state.payIncreaseDetails?.on_camera_performers_pay_increase ? validateNumDotOnly(this.state.payIncreaseDetails?.on_camera_performers_pay_increase) : true &&
        this.state.payIncreaseDetails?.orchestral_sessions_pay_increase ? validateNumDotOnly(this.state.payIncreaseDetails?.orchestral_sessions_pay_increase) : true &&
        this.state.payIncreaseDetails?.recordings_pay_increase ? validateNumDotOnly(this.state.payIncreaseDetails?.recordings_pay_increase) : true ) {
        let payload = {
            union_id: this.props?.unionId,
            on_camera_performers_pay_increase: this.state.payIncreaseDetails?.on_camera_performers_pay_increase,
            orchestral_sessions_pay_increase: this.state.payIncreaseDetails?.orchestral_sessions_pay_increase,
            recordings_pay_increase: this.state.payIncreaseDetails?.recordings_pay_increase
        }
        this.setState({ isPosting: true,postInitiated : true })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/tvmbPayIncrease`, payload,
            this.props.userContext?.active_tenant?.tenant_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isPosting: false,});
                    this.props.notificationComponent(true, "fail")
                } else {
                    this.setState({ isPosting: false });
                    this.props.getTvMusicBudgetDetails(this.props?.unionId)
                    this.props.notificationComponent(true, "success")

                }
            },
            (err) => {
                this.setState({postInitiated:false})
                this.props.notificationComponent(true, "fail")
            }
        );
    }}
    handleChange = (field, value) => {
        this.setState((prevState) => ({
            payIncreaseDetails: {
                ...prevState.payIncreaseDetails,
                [field]: value
            }
        }))
    }
    handleResetNotify = () => {
        this.setState({ showFailMessage: false, showSuccessMessage: false });
        if (this.state.saveFlag) {
            this.closeToastMessage();
        }
    }
    closeToastMessage = () => {
        this.setState({ showErrorMessage: false, showFailMessage: false, showSuccessMessage: false });

    }
    render() {
        return (
            <MDBContainer>
                <div>
                <MDBRow >
                    <MDBCol md={4}>
                        <BasicTextField
                            label={"On Camera Performers"}
                             disabled={this.props.onCameraList?.length===0 ? true : false}
                            value={this.state.payIncreaseDetails?.on_camera_performers_pay_increase}
                            onChange={(e) => this.handleChange("on_camera_performers_pay_increase", e.target.value)}
                            fieldValid={this.state.payIncreaseDetails?.on_camera_performers_pay_increase ? !validateNumDotOnly(this.state.payIncreaseDetails?.on_camera_performers_pay_increase) : false}
                            inValidInput={'Please enter valid value'}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                        disabled={this.props.recordingsList?.length === 0 ? true: false}
                            label={"Recordings"}
                            value={this.state.payIncreaseDetails?.recordings_pay_increase}
                            onChange={(e) => this.handleChange("recordings_pay_increase", e.target.value)}
                            fieldValid={this.state.payIncreaseDetails?.recordings_pay_increase? !validateNumDotOnly(this.state.payIncreaseDetails?.recordings_pay_increase) : false}
                            inValidInput={'Please enter valid value'}
                        />
                    </MDBCol>
                    <MDBCol md={4}>
                        <BasicTextField
                        disabled={this.props.onOrchestralList?.length === 0 ? true:false}
                            label={"Orchestral Sessions"}
                            value={this.state.payIncreaseDetails?.orchestral_sessions_pay_increase}
                            onChange={(e) => this.handleChange("orchestral_sessions_pay_increase", e.target.value)}
                            fieldValid={this.state.payIncreaseDetails?.orchestral_sessions_pay_increase ? !validateNumDotOnly(this.state.payIncreaseDetails?.orchestral_sessions_pay_increase) : false}
                            inValidInput={'Please enter valid value'}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className='mt-2'>
                    <MDBCol></MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            type="inline"
                            variant="contained"
                            disabled={!this.state.payIncreaseDetails?.on_camera_performers_pay_increase && 
                                !this.state.payIncreaseDetails?.orchestral_sessions_pay_increase && 
                                !this.state.payIncreaseDetails?.recordings_pay_increase}
                            text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Save"}
                            onClick={this.postPayIncrease}
                        />
                    </MDBCol>
                    <MDBCol md={2}>
                        <BasicButton
                            text="Cancel"
                            onClick={() => this.props.handleClose()} />
                    </MDBCol>
                </MDBRow>
                </div>
            </MDBContainer>
        )
    }
}
export default withUserContext(payIncrease);