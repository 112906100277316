export const cueSheetsFilter = {
    show: null,
    season: null,
    showYr: null,
    episodeName: null,
    episode: null,
    cueSheetFor: null,
    status: null,
    dateFinalized: null,

}
export const cueSheetsTblConfig = {
    headings: [{
        headingLabel: "Show",
        icon: "",
        width: "20%",
        dataNode:"show_name"
    },
    {
        headingLabel: "Season",
        icon: "",
        width: "10%",
        dataNode:"season_name"
    },
    {
        headingLabel: "Episode",
        icon: "",
        width: "10%",
        dataNode:"episode"
    },
    {
        headingLabel: "CueSheet",
        icon: "",
        width: "15%"
    },
    {
        headingLabel: "Airing",
        icon: "",
        width: "30%"
    },
    {
        headingLabel: "Status",
        icon: "",
        width: "8%"
    }
    ],
    dataNodes: ["show_name", "season_name", "episode", "cuesheet_for", "airing", "status"],
    primaryKey: "show_season_episode_id",
    actions: ["share"],
    hyperlinks: ["season_name", "episode"]
}
export const cueSheetsDataConfig = [
    {
        id: 1,
        Show: "[Show]",
        Season: "[Season]",
        Episode: "[Episode]",
        CueSheet: "[CueSheet]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 2,
        Show: "[Show]",
        Season: "[Season]",
        Episode: "[Episode]",
        CueSheet: "[CueSheet]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 3,
        Show: "[Show]",
        Season: "[Season]",
        Episode: "[Episode]",
        CueSheet: "[CueSheet]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 4,
        Show: "[Show]",
        Season: "[Season]",
        Episode: "[Episode]",
        CueSheet: "[CueSheet]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
    {
        id: 5,
        Show: "[Show]",
        Season: "[Season]",
        Episode: "[Episode]",
        CueSheet: "[CueSheet]",
        Airing: "[Airing]",
        Status: "[Status]"
    },
]

export const filterConfig = {
    show: { id: 'show_id', name: 'show_name' },
    season: { id: 'season_id', name: 'season_name' },
    user: { id: 'user_id', name: 'full_name' }
}

export const statusOptions = [
    {
        label: "Select",
        value: null
    },
    {
        label: "Submitted",
        value: "submitted"
    },
    {
        label: "Pending",
        value: "pending"
    },
    {
        label: "Finalized",
        value: "finalized"
    },
    {
        label: "Approved",
        value: "approved"
    }
]
export const cueSheetsFilterJSON = {
    show_search_ids: null,
    season_search_ids: null,
    episode_title_search_text: null,
    episode_no_search_text: null,
    cue_sheet_for_ids: null,
    status_search_ids: null,
    date_finalized_search_text: null,
    order_by: null,
    order_by_clause: "Show", 
    page_count: 25, 
    page_no: 1
}