import { Collapse } from "@material-ui/core";



export const initialAdhocDetails = {
}
// export const initialAdhocDetails = {
//   adhoc_id: null,
//   selectedSeason: {},
//   episode_id: null,
//   selectedEpisode: {},
//   composition: null,
//   program_id: null,
//   selectedProgram: {},
//   episode_title_id: null,
//   writer: null,
//   show_year_id: null,
//   selectedshowYear: {},
//   episode_range_id: null,
//   selectedEpisodeRange: {},
//   artist: null,
//   product_entity_id: null,
//   selectedproductEntity: {},
//   clearance_rep_id: null,
//   selectedclerance_rep: {},
//   licensor: null,
//   initial_rights_id: null,
//   selected_initial_rghts: {},
//   option_rights_id: null,
//   selected_option_rights: {},
//   license_type_id: null,
//   selected_license_type: {},
//   term_id: null,
//   selected_term: {},
//   territory_id: null,
//   selected_territory: {},
//   use_timing: null,
//   status_id: null,
//   selected_status: {},
// };

export const initialPublicDomainDetails = {
  genres_ids: [],
  include_composer_ids: [],
  exclude_composer_ids: [],
  selected_composers: [],
  group_by_genre: null,
  show_display_criteria: true,
  song_title_only: null,
  selectedIncludedValue: "include_composer_ids"
};

export const initialshowSeasonDetails = {
  is_include_songs: 0,
  is_include_pending_songs: 0,
  is_display_artist_on_report: 0,
  is_exclude_main_title: 1,
  is_show_incomplete_episode: 0,
  is_show_complete_episode: 0,
  is_exclude_all_costs: 0,
  is_exclude_costs_breakdown: 0,
  is_show_home_video_cost: 0,
  is_display_criteria_on_report: 0,
  all_season_id: null,
  selected_all_season: {},
  show_id: null,
  selected_shows: {},
  show_id: null,
  selected_shows: {},
};

export const initialMainTitleDetails = {
  is_show_title: false,
  is_fee: false,
  is_season: false,
  is_licensor: false,
  is_licensed: false,
  is_show_grand_total: false,
  selectedValue: "summary",
  is_artist: false,
  is_intial_rights: false,
  is_right_type: false,
  is_show_episode: false,
  season_id: false,
  selected_season: {},
  is_internet_shows: false,
  show_id: false,
  selected_shows: {},
  is_wfh_list_view: false,
  is_no_main_title: false,
  is_studio_owned: false,
};

export const initialMixDetails = {
  mix_date_range_form: null,
  mix_date_range_to: null,
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
};

export const initialMusicReplacementDetails = {
  is_display_criteria_report: true,
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  selectedValue: "include"
};

export const initialpayeeCheckReportsDetails = {
  notes: null,
  is_foreign_only: false,
  check_pay_date_range_from: null,
  check_pay_date_range_to: null,
  payee_id: null,
  selected_payees: {},
};
export const initialAcquisitionReportsDetails = {
  is_not_acquired: true,
  is_page_break_option: false,
  is_acquired: true,
  season_id: false,
  selected_season: [],
  show_id: false,
  selected_show: [],
  option_id: false,
  selected_options: [],
  is_season_option_report: false,
  is_summary: true,
  is_display_criteria_report: true,
};

export const initialBudgetEFCDetails = {
  is_condensed_report: null,
  is_costs: null,
  is_exclude_main_title: null,
  is_negotiated_fees: null,
  option_id: null,
  selected_options: {},
  season_id: null,
  selected_season: {},
  is_warner_horizan_shows: null,
  is_all_shows: null,
  show_id: null,
  selected_shows: {},
  selected_all_list: "all_list",
  selected_choose_recipients: "default_recipients",
  selected_recipients_list: [],
  selected_form_show: null,
  show_details_id: null,
  selected_shows_details: {},
  recipient_id: null,
  selected_recipient_list: {},
};

export const initialCableDistributionPrep = {
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_inactive_shows: null,
  is_with_details: null,
  is_no_notes: null,
};

export const initialcableDistributionProjections = {
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_include_inactive_shows: null,
};

export const initialhomeVideoSummary = {
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_include_inactive_shows: null,
  is_with_details: null,
};

export const initialhomeVideoProjection = {
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_include_inactive_shows: null,
  is_no_notes: null,
};

export const initialIntlTVDistributionOption = {
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_include_inactive_shows: null,
  is_with_details: null,
  is_no_notes: null,
};

export const initialIntlTVDistributionPerp = {
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_include_inactive_shows: null,
  is_with_details: null,
  is_no_notes: null,
};

export const initialIntlTVDistributionProjection = {
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_include_inactive_shows: null,
};

export const initialLicenseTracking = {
  selectedValue: null,
  license_id: null,
  selected_license_type: {},
  is_pending: null,
  // is_pending: null,
  is_overdue: null,
  is_closed: null,
  is_reliance_letters: null,
  is_license_received: null,
  license_received_date: null,
  is_show_history: null,
  is_report_comment: null,
  is_license_request: null,
  licensor_id: null,
  selected_licensors: {},
  // selected_licensors_id: null,
  // selected_licensors_name: null,
  licensee_id: null,
  selected_licensee_rep: {},
  season_id: null,
  selected_season: {},
  show_id: null,
  selected_shows: {},
  is_episodic: "is_episodic",
  is_sync: null,
  is_master: null,
  is_sync_master: null
};

// finalPostFormat = {
//   is_clearance,
//   is_main_title,
//   license_type_id,
//   is_pending,
//   is_overdue,
//   is_closed,
//   is_reliance_letters,
//   is_show_history,
//   is_report_comment,
//   is_license_request_date,
//   license_received_date,
//   is_license_received,
//   is_show_internal_license,
//   licensor_id,
//   licensee_rep_id,
//   season_id,
//   show_id,
//   groupByColName
// }

export const existingAdHocReport = [
  {
    label: "Program, Season, Episode #/Title",
    value: 1,
  },
  {
    label: "Episode Range",
    value: 2,
  },
  {
    label: "Show Year",
    value: 3,
  },
  {
    label: "Composition",
    value: 4,
  },
  {
    label: "Writer",
    value: 5,
  },
  {
    label: "Performer",
    value: 6,
  },
  {
    label: "Production Entity",
    value: 7,
  },
  {
    label: "Licensor",
    value: 8,
  },
  {
    label: "Licensor Type",
    value: 9,
  },
  {
    label: "Initial Rights",
    value: 10,
  },
  {
    label: "Option Rights",
    value: 11,
  },
  {
    label: "Territory",
    value: 12,
  },
  {
    label: "Term",
    value: 13,
  },
  {
    label: "Use/Timing",
    value: 14,
  },
  {
    label: "Status",
    value: 15,
  },
  {
    label: "Clearance Rep",
    value: 16,
  },
  {
    label: "Air Date",
    value: 17,
  },
  {
    label: "Fee",
    value: 18,
  }
  ,
  {
    label: "Batch",
    value: 19,
  }
]

export const existingAdHocDropDownReport = [
  {
    label: "Program",
    value: 1,
  },
  {
    label: "Season",
    value: 2,
  },
  {
    label: "Episode #",
    value: 3,
  },
  {
    label: "Episode Title",
    value: 4,
  },
  {
    label: "Episode Range",
    value: 5,
  },
  {
    label: "Show Year",
    value: 6,
  },
  {
    label: "Composition",
    value: 7,
  },
  {
    label: "Writer",
    value: 8,
  },
  {
    label: "Performer",
    value: 9,
  },
  {
    label: "Production Entity",
    value: 10,
  },
  {
    label: "Licensor",
    value: 11,
  },
  {
    label: "Licensor Type",
    value: 12,
  },
  {
    label: "Initial Rights",
    value: 13,
  },
  {
    label: "Option Rights",
    value: 14,
  },
  {
    label: "Territory",
    value: 15,
  },
  {
    label: "Term",
    value: 16,
  },
  {
    label: "Use/Timing",
    value: 17,
  },
  {
    label: "Status",
    value: 18,
  },
  {
    label: "Clearance Rep",
    value: 19,
  },
  {
    label: "Air Date",
    value: 20,
  },
  {
    label: "Fee",
    value: 21,
  }
]

export const valueList = [
  {
    value: 1,

    label: "Program",

    icon: "check-circle",
  },

  {
    value: 2,

    label: "Season",

    icon: "check-circle",
  },
  {
    value: 3,

    label: "Episode Title",

    icon: "check-circle",
  },
  {
    value: 4,

    label: "Composition",

    icon: "check-circle",
  },
  {
    value: 5,

    label: "Writer",

    icon: "check-circle",
  },
  {
    value: 5,

    label: "Initial Rights",

    icon: "check-circle",
  },
];
export const labelList = [
  {
    value: 1,

    label: "Program",

    icon: "check-circle",
  },

  {
    value: 2,

    label: "Episode #",

    icon: "check-circle",
  },
];
export const agentListConfig = {
  headings: [
    {
      headingLabel: "Sort Order",
      inputType: "select",
      width: "17%",
      icon: "",
    },
  ],
  dataNodes: ["SortOrder"],
  primaryKey: "id",
  toggleFields: [""],
  actions: ["arrow-up", "arrow-down", "times"],
  inlineEdits: ["SortOrder"],
  hyperlinks: [""],
};
export const allContactList = [
  {
    id: 1,
    SortOrder: "Program",
  },
  {
    id: 2,
    SortOrder: "Season",
  },
  {
    id: 3,
    SortOrder: "Episode Title",
  },
  {
    id: 4,
    SortOrder: "Composition",
  },
  {
    id: 5,
    SortOrder: "Writer",
  },
  {
    id: 6,
    SortOrder: "Initial Rights",
  },
];
export const reportConfig = {
  headings: [
    {
      headingLabel: "GroupBy",
      inputType: "select",
      width: "17%",
      icon: "",
    },
  ],
  dataNodes: ["GroupBy"],
  primaryKey: "id",
  toggleFields: [""],
  actions: ["arrow-up", "arrow-down", "times"],
  inlineEdits: ["GroupBy"],
  hyperlinks: [""],
};
export const allreportList = [
  {
    id: 1,
    GroupBy: "Program",
  },
  {
    id: 2,
    GroupBy: "Episode",
  },
];

export const ReportAdHocLabel = [
  {
    label: "Ad Hoc Reporting Tool",
    value: "adHocReportTool",
    permissionName: "Ad Hoc Report"
  },
  {
    label: "Public Domain Report",
    value: "publicDomainReport",
    permissionName: "Public Domain report"
  },
];
export const ShowSeasonLabel = [
  {
    label: "Show Season Song/Cost To Date",
    value: "showSeasonSongCostToDate",
    permissionName: "Show Season Song/Cost To Date report"
  },
  {
    label: "Main Title Report",
    value: "mainTitleReport",
    permissionName: "Main Title Report"
  },
  {
    label: "Mix Date Report",
    value: "mixDateReport",
    permissionName: "Mix Date report"
  },
  {
    label: "Music Replacement Report",
    value: "musicReplacementReport",
    permissionName: "Music Replacement report"
  },
];
export const LicensingLabel = [
  {
    label: "License Tracking Report",
    value: "licenseTrackingReport",
    permissionName: "License Tracking Report"
  },
  {
    label: "Payee Check Report",
    value: "payeeCheckReport",
    permissionName: "Payee Check report"
  },
  {
    label: "Option Acquisition Report",
    value: "optionAcquisitionReport",
    permissionName: "Option Acquisition report"
  },
];
export const Budgeting = [
  {
    label: "Budget vs EFC by Episode",
    value: "budgetvsEFCbyEpisode",
    permissionName: "Budget vs. EFC by Episode report"
  },
];
export const WebReports = [
  {
    label: "Cable Distribution - Extend Initial Into Perp",
    value: "cableDistributionExtendInitialIntoPerp",
    width: "200px",
  },
  {
    label: "Cable Distribution - Sumamary Projections",
    value: "cableDistributionSumamaryProjections",
  },
  {
    label: "Home Video - Summary",
    value: "homeVideoSummary",
  },
  {
    label: "Home Video - Summary Projections",
    value: "homeVideoSummaryProjections",
  },
  {
    label: "Intl. TV Distribution - 3 Year all TV Option",
    value: "intlTVDistributionYearallTVOption",
  },

  {
    label: "Intl. TV Distribution - Extend Initial Into Perp",
    value: "intlTvDistributionExtendInitialIntoPerp",
  },

  {
    label: "Intl. TV Distribution - Summary Projectionls",
    value: "intlTvDistributionSummaryProjections",
  },
];

export const groupList = [
  {
    value: 1,

    label: "Due Date",

    icon: "check-circle",

    sortValue: "cl.license_due_date",
    mainTitleValue:"mtl.license_due_date"
  },

  {
    value: 2,

    label: "Licensor",

    icon: "check-circle",

    sortValue: "l.licensor_name",
    mainTitleValue:"l.licensor_name"
  },
  {
    value: 3,

    label: "Season",

    icon: "check-circle",
    sortValue: "s.start_year",
    mainTitleValue:"se.start_year"
  },
  {
    value: 4,

    label: "Show",

    icon: "check-circle",
    sortValue:"p.show_name",
    mainTitleValue:"s.show_name"
  },
  {
    value: 5,

    label: "Episode",

    icon: "check-circle",
    sortValue: "sse.episode_number",
    mainTitleValue:"sse.episode_number"
  },
  {
    value: 6,

    label: "Composition/Songs",

    icon: "check-circle",
    sortValue: "sng.song_title",
    mainTitleValue:"sng.song_title"
  },
];

export const dayOptions = [
  {
    value: 1,
    label: "Sunday",
  },
  {
    value: 2,
    label: "Monday",
  },
  {
    value: 3,
    label: "Tuesday",
  },
  {
    value: 4,
    label: "Wednesday",
  },
  {
    value: 5,
    label: "Thursday",
  },
  {
    value: 6,
    label: "Friday",
  },
  {
    value: 7,
    label: "Saturday",
  },
]

export const initialScheduleDetails = {
  frequency: null,
  full_name: "",
  last_email_sent: null,
  notified_user_id: null,
  production_group_id: null,
  production_group_name: null,
  schedule_day: "",
  schedule_day_id: null,
  schedule_time: undefined
};
export const filterConfig = {
  show: { id: 'show_id', name: 'show_name' },
  season: { id: 'season_id', name: 'season_name' },
  user: { id: 'user_id', name: 'full_name' },
  term: { id: 'term_id', name: 'term_name' },
  territory: { id: 'territory_id', name: 'territory_abbr' },
  production_entity: { id: 'production_entity_id', name: 'short_name' },
  show_durations: { id: 'show_duration_id', name: 'show_duration' },
  rights: { id: 'right_id', name: 'right_abbr' }
}

export const AdHocTabList = [
  {
    label: "Program, Season, Episode #/Title",
    value: 1,
  },
  {
    label: "Episode Range",
    value: 2,
  },
  {
    label: "Show Year",
    value: 3,
  },
  {
    label: "Composition",
    value: 4,
  },
  {
    label: "Writer",
    value: 5,
  },
  {
    label: "Performer",
    value: 6,
  },
  {
    label: "Production Entity",
    value: 7,
  },
  {
    label: "Licensor",
    value: 8,
  },
  {
    label: "Licensor Type",
    value: 9,
  },
  {
    label: "Initial Rights",
    value: 10,
  },
  {
    label: "Option Rights",
    value: 11,
  },
  {
    label: "Territory",
    value: 12,
  },
  {
    label: "Term",
    value: 13,
  },
  {
    label: "Use/Timing",
    value: 14,
  },
  {
    label: "Status",
    value: 15,
  },
  {
    label: "Clearance Rep",
    value: 16,
  }
]

export const LicenseTypeOptions = [
  {
    value: 1,
    label: "S"
  },
  {
    value: 2,
    label: "M"
  },
  {
    value: 3,
    label: "SM"
  }
]

export const ShowYearOptions = [
  {
    value: 2,
    label: "1"
  },
  {
    value: 3,
    label: "2"
  },
  {
    value: 4,
    label: "3"
  },
  {
    value: 5,
    label: "4"
  },
  {
    value: 6,
    label: "5"
  },
  {
    value: 7,
    label: "6"
  },
  {
    value: 8,
    label: "7"
  },
  {
    value: 9,
    label: "8"
  },
  {
    value: 10,
    label: "9"
  },
  {
    value: 11,
    label: "10"
  },
  {
    value: 12,
    label: "11"
  },
  {
    value: 13,
    label: "12"
  },
  {
    value: 14,
    label: "13"
  },
  {
    value: 15,
    label: "14"
  },
  {
    value: 16,
    label: "15"
  },
]

export const BatchOptions = [
  {
    value: null,
    label: 'Select'
  },
  {
    value: 'YES',
    label: "YES"
  },
  {
    value: 'NO',
    label: "NO"
  }
]