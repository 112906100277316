import React from 'react';
import { withUserContext } from '../../../../contexts/UserContext';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText, MDBTooltip } from 'mdb-react-ui-kit';
import BasicButton from '../../../SharedComponents/BasicButton/BasicButton';
import Popover from '@material-ui/core/Popover';
import BasicLabel from '../../../SharedComponents/BasicLabel/BasicLabel';
import { selectOptionList, CueSheetPublisherConfig, CueSheetPublisherList } from '../Config';
import MasterLicensorModal from '../SyncLicensor/MasterLicensorModal';
// import './MasterLicensor.scss';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainViewModalComponent from "../../../../Common/MainViewModalLayout/Modal/Modal";
import TableComponent from '../../../SharedComponents/Table';
import { optionalRightsList, optionalRightsConfig } from '../SyncLicensor/Config';
import CheckRequestPopUp from '../SyncLicensor/CheckRequestPopUp';



export default withUserContext(class MasterLicensor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchingMasterLicensorDetails: false,
            setPopover: null,
            setModal: null,
            config: selectOptionList,
            showLettersModal: false,
            showMainTitleLettersModal: false,
            showMainLicenseTackingModal: false,
            renderList: this.props?.optionalRightsList,
            configDetails: this.props?.optionalRightsConfig,
            CueSheetPublisherConfig: this.props?.CueSheetPublisherConfig,
            CueSheetPublisherList: this.props?.CueSheetPublisherList,
            setEditCuePopover: null,
            showEditLicensorModal: false,
            showContactsLicensorModal: false,
            isEdit: null,
        }
    }
    handleClick = (event) => {
        this.setState({
            setPopover: event.currentTarget
        })
    };
    handleClickModal = (event) => {
        this.setState({
            setModal: event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({
            setPopover: null
        })
    };
    handleCloseModal = () => {
        this.setState({
            setModal: null
        })
    };
    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded });
    };
    handleshowEditLettersModal = () => {
        this.setState({ showLettersModal: true })
    }
    handleHideLettersModal = () => {
        this.setState({ showLettersModal: false })
    }
    handleShowMainTitleLettersModal = () => {
        this.setState({ showMainTitleLettersModal: true })
    }
    handleHideMainTitleLettersModal = () => {
        this.setState({ showMainTitleLettersModal: false })
    }
    handleShowMainLicenseTackingModal = () => {
        this.setState({ showMainLicenseTackingModal: true })
    }
    handleHideMainLicenseTackingModal = () => {
        this.setState({ showMainLicenseTackingModal: false })
    }
    handleshowLicenseTackingModal = () => {
        this.setState({ showLicenseTackingModal: true })
    }
    handleHideLicenseTackingModal = () => {
        this.setState({ showLicenseTackingModal: false })
    }

    handleOptionalRightsInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                if (item.id == id)
                    item['editing'] = true
                return item
            })
        })
    }
    saveOptionalRightsInlineEdits = (data, index, pkValue, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            renderList: allDetails.map((item) => {
                item['editing'] = false
                return item
            })
        })
    }

    handlePublisherInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.CueSheetPublisherList]
        this.setState({
            CueSheetPublisherList: allDetails.map((item) => {
                if (item.id == id)
                    item['editing'] = true
                return item
            })
        })
    }
    savePublisherInlineEdits = (data, index, pkValue, dataItem) => {
        let allDetails = [...this.state.CueSheetPublisherList]
        this.setState({
            CueSheetPublisherList: allDetails.map((item) => {
                item['editing'] = false
                return item
            })
        })
    }

    handleCuePopupClick = (event) => {
        console.log("event", event)
        this.setState({
            // setPopover: event.currentTarget
            setEditCuePopover: true,

        })
    };
    handleEditCuePopupClose = () => {
        this.setState({
            setEditCuePopover: null,

        })
    };

    handleSyncPopupClick = (event) => {
        console.log("event", event)
        this.setState({
            // setPopover: event.currentTarget
            setEditPopover: true,

        })
    };
    handleSyncPopupClose = () => {
        this.setState({
            setEditPopover: null,

        })
    };
    handleshowEditLicensorModal = (mode) => {
        this.setState({ showEditLicensorModal: true, isEdit: mode });
    }
    handleHideEditLicensorModal = () => {
        this.setState({ showEditLicensorModal: false });
    }
    handleshowContactsLicensorModal = () => {
        this.setState({ showContactsLicensorModal: true });
    }
    handleHideContactsLicensorModal = () => {
        this.setState({ showContactsLicensorModal: false });
    }

    render() {
        let open = Boolean(this.state.setPopover);
        let id = open ? 'simple-popover' : undefined;
        let openModal = Boolean(this.state.setModal);
        let idModal = open ? 'modal-popover' : undefined;
        return (

            <MDBContainer fluid className="MasterLicensor-Container" style={{ padding: "0%" }}>
                <div>

                    {/* <div> */}
                    <MDBRow >
                        <MDBCol className="header-title" md={11}>
                            <div>
                                MasterLicensor
                            </div>
                        </MDBCol>
                        <MDBCol className='pb-2'>
                            <BasicButton className="plus-icon-style"
                                icon={"plus-circle"} text=" "
                                variant="contained"
                            />
                        </MDBCol>

                    </MDBRow>
                </div>
                <MDBCard className="MasterLicensor-Container">
                    <MDBCardBody>
                        <MDBCardText>
                            <div>
                                <div>
                                    <MDBRow>
                                        <MDBCol md={2} className="icon-licensor">
                                            <BasicButton
                                                className="icon-Details"
                                                text={"Licensor"}
                                                icon={"chevron-down"}
                                                variant="contained"
                                                type="inline"
                                                color="primary"
                                                iconRight={true}
                                                onClick={this.handleClick}
                                            />
                                            <div className="popover-text">
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    className={"popover-text"}
                                                    anchorEl={this.state.setPopover}
                                                    getContentAnchorEl={null}
                                                    onClose={this.handleClose}
                                                    anchorReference="anchorOrigin"
                                                    anchorOrigin={{
                                                        horizontal: "right",
                                                        vertical: "bottom "
                                                    }}
                                                    transformOrigin={{
                                                        horizontal: "left",
                                                        vertical: "top"
                                                    }}
                                                    anchorPosition={{ top: 100, left: 300 }}
                                                >
                                                    {this.state.config.map((item, index) => {
                                                        return <div className="popover-text" onClick={item.label === "Edit Licensor" ? (e) => this.handleshowEditLicensorModal("Edit") : item.label === "Add New Licensor to this CL Record" ? (e) => this.handleshowEditLicensorModal("New") : item.label === "Change/View Default Contact" ? this.handleshowContactsLicensorModal : null}>{item.label}</div>
                                                    })}

                                                </Popover>
                                            </div>

                                        </MDBCol>
                                        <MDBCol md={3} className="text-css">
                                            <div>
                                                <div>{this.props?.lic?.licensor_name}</div>
                                                {this.props?.lic?.rights?.map((item) =>
                                                    <div>{(item.is_initial_right === 1 ? "(Init) " : item.can_be_alt_init_right === 1 ? "(Alt Init) " : "") + item.right_abbr + " -- " + item.fee}</div>
                                                )}
                                            </div>
                                        </MDBCol>
                                        {/* <MDBCol md={1}>
                                            <div className="sub-header">
                                                <div className="header-text">Fee </div>
                                                <div>$500.00</div>
                                            </div>
                                        </MDBCol> */}
                                        <MDBCol md={1}>
                                            <div className="sub-header">
                                                <span className="header-text">Share </span>
                                                <span className="header-text-value">{this.props?.lic?.share}</span>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md={1}>
                                            <div className="sub-header">
                                                <span className="header-text">Status </span>
                                                <span className="header-text-value">{this.props?.lic?.status_id}</span>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <div className="sub-header">
                                                <span className="header-text">They Prvd.Licensor </span>
                                                <span className="header-text-value">{this.props?.lic?.is_they_provide_license === 1 ? "Yes" : "No"}</span>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md={1}>
                                            <div className="pen-button">
                                                <span className="edit-pen-btn">
                                                    <BasicButton
                                                        className={"pen-btn"}
                                                        variant="contained"
                                                        text=" "
                                                        icon={"pen"}

                                                    />
                                                </span>
                                                <span>
                                                    <BasicButton icon="arrows-alt-v"
                                                        text=" "
                                                        variant="contained"
                                                        className="arrow_alt_icon"
                                                        onClick={this.handleClickModal}
                                                    />
                                                    <Popover
                                                        id={idModal}
                                                        open={openModal}
                                                        className={"pop-over"}
                                                        anchorEl={this.state.setModal}
                                                        onClose={this.handleCloseModal}
                                                        anchorReference="anchorOrigin"
                                                        anchorOrigin={{
                                                            horizontal: "right",
                                                            vertical: "bottom "
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: "left",
                                                            vertical: "top"
                                                        }}
                                                        PaperProps={{ style: { width: '16%' } }}
                                                        anchorPosition={{ top: 700, left: 300 }}
                                                    >

                                                        <MasterLicensorModal />
                                                    </Popover>
                                                </span>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md={1}>
                                            <div className="sub-header">
                                                <span className="header-text">Licensor.Rep </span>
                                                <span className="header-text-value">{this.props?.lic?.licensee_rep_id}</span>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol md={10} >
                                            <div className="cueSheet files-container" class='row' id='mainGrid'>
                                                <Accordion onChange={this.handleChange('panel1')} className="icon-Details">
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        <Typography className="header-cue" >
                                                            Details
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails id="optional-rights-table-bg" className="optionalRightsTable">
                                                        <TableComponent
                                                            list={this.state.renderList || []}
                                                            className={"clearencRecord"}
                                                            config={this.state.configDetails}
                                                            editCallback={this.handleOptionalRightsInlineEdits.bind(this)}
                                                            saveCallback={this.saveOptionalRightsInlineEdits.bind(this)}
                                                            handlepopupCallback={(event) => this.handleCuePopupClick(event, this)}
                                                            addItemToList={true}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>

                                            </div>
                                        </MDBCol>
                                        <MDBCol md={2}>
                                            <div className="display-licensor">
                                                <div className='p-1'>
                                                    <span>
                                                        <BasicButton
                                                            className={"save-btn2"}
                                                            variant="outlined"
                                                            text=" "
                                                            icon={"envelope"}
                                                            onClick={this.state.showLettersModal ? this.handleshowEditLettersModal : this.handleShowMainTitleLettersModal}
                                                        />
                                                    </span>
                                                </div>
                                                <div>
                                                    <BasicButton
                                                        variant="contained"
                                                        type="inline"
                                                        color="primary"
                                                        onClick={this.state.showLicenseTackingModal ? this.handleshowLicenseTackingModal : this.handleShowMainLicenseTackingModal}
                                                        text={"Licensor Tracking"}
                                                    />
                                                </div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <div className="cueSheet files-container" class='row' id='mainGrid'>
                                            <Accordion onChange={this.handleChange('panel1')} className="icon-Options">
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography className="header-cue" >
                                                        Options
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails id="optional-rights-table-bg" className="optionalRightsTable">
                                                    <TableComponent
                                                        list={this.state.CueSheetPublisherList || []}
                                                        className={"clearencRecord"}
                                                        config={this.state.CueSheetPublisherConfig}
                                                        editCallback={this.handlePublisherInlineEdits.bind(this)}
                                                        saveCallback={this.savePublisherInlineEdits.bind(this)}
                                                        handlepopupCallback={(event) => this.handleSyncPopupClick(event, this)}
                                                        addItemToList={true}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>

                                        </div>
                                    </MDBRow>
                                </div>
                            </div>
                        </MDBCardText>

                    </MDBCardBody>
                </MDBCard>
                {
                    (this.state.showLettersModal || this.state.showMainTitleLettersModal) &&
                    <MainViewModalComponent
                        open={this.state.showLettersModal || this.state.showMainTitleLettersModal}
                        handleClose={this.state.showLettersModal
                            ? this.handleHideLettersModal
                            : this.handleHideMainTitleLettersModal}
                        headerText={"Letters"}
                        mode={""}
                        modalName={"Letters"}
                        mainTitle={this.state.showMainTitleLettersModal ? "mainTitle" : "clearence"}
                    />
                }
                {
                    (this.state.showLicenseTackingModal || this.state.showMainLicenseTackingModal) &&
                    <MainViewModalComponent
                        open={this.state.showLicenseTackingModal || this.state.showMainLicenseTackingModal}
                        handleClose={this.state.showLicenseTackingModal
                            ? this.handleHideLicenseTackingModal
                            : this.handleHideMainLicenseTackingModal}
                        headerText={"License Tracking"}
                        mode={""}
                        modalName={"LicenseTracking"}
                    />
                }
                {this.state.showEditLicensorModal && (
                    <MainViewModalComponent
                        open={this.state.showEditLicensorModal}
                        handleClose={this.handleHideEditLicensorModal}
                        headerText={"Licensor"}
                        mode={this.state.isEdit === "Edit" ? "Edit" : "New"}
                        modalName={"Edit Licensor"}
                    />
                )}
                {
                    this.state.showContactsLicensorModal && (
                        <MainViewModalComponent
                            open={this.state.showContactsLicensorModal}
                            handleClose={this.handleHideContactsLicensorModal}
                            headerText={"Licensor Contacts"}
                            mode={""}
                            modalName={"Licensor Contacts"}
                        />
                    )
                }
                <CheckRequestPopUp setEditCuePopover={this.state.setEditCuePopover} handleCueClose={this.handleEditCuePopupClose} open={this.state.setEditPopover} handleSyncClose={this.handleSyncPopupClose} />
            </MDBContainer>

        )
    }
});