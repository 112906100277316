import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table';
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class PublicDomainGenres extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderList: [],
            domainNotExist:false,
            domainDuplicate:false,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            config:JSON.parse(JSON.stringify({ ...this.props?.config}))
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps?.list !== this.props?.list && this.props?.list?.length > 0) {
            let configRecord = {...this.state.config}
            this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
            this.setState({renderList:this.props.list,sortCount:0,sortBy:null,isSortByAsc: true,config: configRecord}, () => {
                this.handleTableColumnClick('Public Domain Genre')
            })
        }
    }


    handleInlineEdits = (id, dataItem) => {
        let publicDomainGenres = [...this.state?.renderList]
        this.setState({
            networkNotExist:false,
            renderList: publicDomainGenres.map((item) => {
                let newItem = {...item}
                if (item.id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({domainNotExist:false,domainDuplicate:false}) 
        
        if(!data[index]?.public_domain_genre){
            this.setState({domainNotExist : true})
        }
        else if(data[index]?.public_domain_genre && this.state?.renderList?.some((item,i)=>item?.public_domain_genre?.toLowerCase() === data[index]?.public_domain_genre?.toLowerCase() && i != index)){
            this.setState({domainDuplicate:true})
        }
        else{
        
            let publicDomainGenres = [...this.state.renderList]
            publicDomainGenres[index].id = data[index].id,
                publicDomainGenres[index].public_domain_genre = data[index].public_domain_genre || null,
                publicDomainGenres[index].is_active = data[index].is_active?1:0
            this.setState({
                renderList: publicDomainGenres.map((item) => {
                    item['editing'] = false
                    return item
                })
            })
            let editedList = this.state.renderList.filter(item => item.id === pkValue);
            let list = {
                id: editedList[0].id,
                name: editedList[0].public_domain_genre || null,
                is_active: editedList[0].is_active?1:0
            }
            this.props.handleSave(list, "lu_public_domain_genre",list?.id);
        } 
    }

    handleAddNewItem = (newDataItem) => {
        this.setState({domainNotExist:false,domainDuplicate:false}) 
        
        if(!newDataItem?.public_domain_genre){
            this.setState({domainNotExist : true})
        }
        else if(newDataItem?.public_domain_genre && this.state?.renderList?.some(item=>item?.public_domain_genre?.toLowerCase() === newDataItem?.public_domain_genre?.toLowerCase())){
            this.setState({domainDuplicate:true})
        }
        else{
            let addList = {
                id: null,
                name: newDataItem.public_domain_genre || null,
                is_active: 1
            }
            this.props.handleSave(addList, "lu_public_domain_genre",'newItemPost');
        } 
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
          this.setState({ sortCount: this.state.sortCount + 1 }, () => {
            this.handleTableSort(column);
          })
        }
        else this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        })
    }
    
    handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            sortBy: colName,
            config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, this.state.sortBy, colName),
            renderList: tableSortList(colName === 'Active' ? 'Number':"Name", this.getSortNode(colName), this.state.renderList, this.state.isSortByAsc)
          });
        })
    }
    
    getSortNode = (col) => {
        switch (col) {
            case "Public Domain Genre": return "public_domain_genre";
            case "Active": return "is_active";
        }
    }

    render() {
        let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="detailContainer">
                <MDBRow>
                    <span className="description">
                        Define selectable genres for Public Domain songs.
                    </span>
                </MDBRow>
                <MDBRow className="tableComponent">
                    <MDBRow className='pd'>
                <span className="errorText">{this.state.domainNotExist && 'Please enter Public Domain genre field value'}</span>
                <span className="errorText">{this.state.domainDuplicate && 'Public Domain genre already exist'}</span>
                
                </MDBRow>
                    {this.props?.loadingOptions ? (
                        <div className="ContentLoader">
                            <CircularProgress />
                        </div>
                    ) : (
                            <TableComponent
                                newIsActive={true}
                                list={this.state.renderList || []}
                                config={this.state?.config}
                                handleAddNewItem={this.handleAddNewItem.bind(this)}
                                editCallback={this.handleInlineEdits.bind(this)}
                                saveCallback={this.saveInlineEdits.bind(this)}
                                addItemToList={permissionValue?.edit === 1}
                                stickyHeader={true}
                                postInitiated={this.props?.isSaveData}
                               // isLoading={this.props?.isSaveData}
                                readCallback={(index) => {
                                    let domainOptions = [...this.state.renderList];
                                    domainOptions[index].editing = false
                                    this.setState({ renderList: domainOptions, domainNotExist:false });
                                }}
                                sortCallback={this.handleTableColumnClick}
                            />)}
                </MDBRow>
            </MDBContainer>
        )
    }
}

export default PublicDomainGenres;