import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText, MDBTooltip } from 'mdb-react-ui-kit';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import { peopleConfig, peopleList, efcConfig, efcList, peopleTabs } from './PeopleConfig';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import './People.scss';
import BudgetVsEFC from './BudgetVsEFC';
import EFCRecipients from './EFCRecipients';
import CueSheetRecipients from './CueSheetRecipients';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { CircularProgress } from "@material-ui/core";
import TabsComponent from '../../../components/SharedComponents/Tabs/Tabs';
import { withUserContext } from '../../../contexts/UserContext';
import MessageModal from '../../SharedComponents/MessageModal';
let noShowComposerPresent = false, nomusicSupervisorPresent = false, noMusicEditorPresent = false, canViewMainDetails = false, canEditMainDetails = false, CanViewBudgetVsEFCRecipients = false, CanViewCueSheetRecipients = false, CanViewEFCRecipients = false, dataToDelete = {}, deletionFlag = "", detailsToDisplay = {}, indexOfDisplayItem = null, displayedItem = null;
class People extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowProducerContacts: false,
            ShowPersonModal: false,
            mode: null,
            modeType: null,
            origin: null,
            businessAffairsDetailsData: [],
            show_season_id: this.props.show_season_id,
            people_id: null,
            show_season_people_id: null,
            seasonProducersDetails: [],
            peopleTabs: [],
            tabValue: null,
            tabLabel: '',
            isLoading: false,
            isSecondaryLoading: false,
            showError: false,
            showSuccess: false,
            showFail: false,
            showADDSCModal: false,
        }
    }
    handleShowProducerContacts = () => {
        this.setState({ ShowProducerContacts: true });
    }
    handleHideProducerContacts = () => {
        this.setState({ ShowProducerContacts: false });
    }
    handleShowPersonModal = (mode, modeType) => {
        this.setState({ ShowPersonModal: true, mode, modeType: modeType });
    }
    handleHidePersonModal = () => {
        this.setState({ ShowPersonModal: false });
    }
    displayDetails = (item, index) => {
        indexOfDisplayItem = index;
        let arr = this.state.businessAffairsDetailsData;
        this.state.businessAffairsDetailsData.filter((elem, index) => { (elem.isClicked === true) ? delete elem.isClicked : null });
        item.isClicked = true;
        arr.splice(index, 1, item);
        this.setState({ businessAffairsDetailsData: arr })
        detailsToDisplay = item;
    }
    fetchData = (field, url, called = null) => {
        if (called === "calledOnLoad") {
            this.setState({ isLoading: true });
        }
        if (called === "calledSecondary") {
            this.setState({ isSecondaryLoading: true });
        }
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isLoading: false, isSecondaryLoading: false })
                let formattedList = [], tempData;
                switch (field) {
                    case 'businessAffairsDetailsData':
                        formattedList = response.data;
                        if (called !== "editing") {
                            detailsToDisplay = {};
                        }
                        let showComposerArr = response.data.length > 0 && response.data.filter(item => item?.people_type == "SHOW_COMPOSER");
                        if (response.data.length == 0 || showComposerArr.length == 0) {
                            noShowComposerPresent = true;
                        } else { noShowComposerPresent = false; }
                        let musicSupervisorArr = response.data.length > 0 && response.data.filter(item => item?.people_type == "MUSIC_SUPERVISOR");
                        if (response.data.length == 0 || musicSupervisorArr.length == 0) {
                            nomusicSupervisorPresent = true;
                        } else { nomusicSupervisorPresent = false; }
                        let musicEditorArr = response.data.length > 0 && response.data.filter(item => item?.people_type == "MUSIC_EDITOR");
                        if (response.data.length == 0 || musicEditorArr.length == 0) {
                            noMusicEditorPresent = true;
                        } else { noMusicEditorPresent = false; }
                        if (called === "editing" && formattedList.length > 0 && indexOfDisplayItem) {
                            displayedItem = formattedList[indexOfDisplayItem];
                            this.displayDetails(formattedList[indexOfDisplayItem], indexOfDisplayItem);
                        }
                        this.setState({
                            businessAffairsDetailsData: formattedList
                        })
                        break;
                    case 'seasonProducersDetails':
                        formattedList = response.data
                        this.setState({
                            seasonProducersDetails: formattedList
                        })
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false, isSecondaryLoading: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    handleShowShowComposer = (mode, modeType, people_id, show_season_people_id, origin = null, item = null, index = null) => {
        this.setState({ ShowPersonModal: true, mode, modeType, people_id, show_season_people_id, origin });
        if (mode === "Edit") {
            indexOfDisplayItem = index;
            displayedItem = item;
            this.displayDetails(item, index);
        }
    }
    componentDidMount() {
        detailsToDisplay = {};
        if (this.props?.show_season_id) {
            this.getPermissions(this.props.permissionArr);
            this.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.props.show_season_id}`, "calledOnLoad");
            this.fetchData("seasonProducersDetails", `/showSeasonProducers?show_season_id=${this.props.show_season_id}`, "calledOnLoad");
        }
    }
    handleTabChange = (newTabValue) => {
        let tabLabel = this.state.peopleTabs.filter(item => (item.value == newTabValue) ? item : null);
        this.setState({
            tabValue: newTabValue,
            tabLabel: tabLabel[0].label
        });
    };
    getTabComponent = () => {
        switch (this.state.tabLabel) {
            case 'Budget vs. EFC Recipients':
                return <BudgetVsEFC show_season_id={this.props?.show_season_id} season_id={this.props?.season_id} show_id={this.props?.show_id} division_id={this.props.division_id} permissionArr={this.props.permissionArr} />;
                break;
            case 'EFC Recipients':
                return <EFCRecipients show_season_id={this.props?.show_season_id} season_id={this.props?.season_id} show_id={this.props?.show_id} division_id={this.props.division_id} permissionArr={this.props.permissionArr} />;
                break;
            case 'Cue Sheet Recipients':
                return <CueSheetRecipients show_season_id={this.props?.show_season_id} season_id={this.props?.season_id} show_id={this.props?.show_id} division_id={this.props.division_id} permissionArr={this.props.permissionArr} />;
                break;
        }
    };
    deleteData = () => {
        if (dataToDelete.people_type === "SHOW_COMPOSER") {
            this.setState({ isSubmit: true, isFetchingShowComposer: true });
        }
        if (dataToDelete.people_type === "MUSIC_SUPERVISOR") {
            this.setState({ isSubmit: true, isFetchingMusicSupervisor: true });
        }
        if (dataToDelete.people_type === "MUSIC_EDITOR") {
            this.setState({ isSubmit: true, isFetchingMusicEditor: true });
        }
        clearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=show_season_business_affairs&tableId=${dataToDelete.show_season_people_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(res => {
            if (res.data[0].status === "SUCCESS") {
                if (detailsToDisplay?.show_season_people_id === dataToDelete?.show_season_people_id) {
                    detailsToDisplay = {};
                    this.setState({ renderList: [] })
                }
                dataToDelete.is_delete = 1;
                dataToDelete.show_season_id = this.props?.show_season_id;
                dataToDelete.season_id = this.props?.season_id;
                dataToDelete.show_id = this.props?.show_id;
                clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/people?people_id=${dataToDelete.people_id}`, dataToDelete, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                    .then((response) => {
                        if (response.data.error) {
                            this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false, isFetchingMusicEditor: false });
                            this.notificationComponent(true, "fail");
                        } else {
                            this.notificationComponent(true, "success");
                            this.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.state.show_season_id}`);
                            this.setState({ isSubmit: false, showDeleteModal: false, isFetchingShowComposer: false, isFetchingMusicSupervisor: false, isFetchingMusicEditor: false });
                        }
                    },
                        (err) => {
                            this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false, isFetchingMusicEditor: false });
                            this.notificationComponent(true, "fail");
                            console.log("Post agency details error: " + err);
                        });
            } else {
                this.notificationComponent(true, "checkRelationalFailure");
                this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false, isFetchingMusicEditor: false });
            }
        }),
            ((err) => {
                this.notificationComponent(true, "fail");
                this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false, isFetchingMusicEditor: false });
            });
    };
    openAddSCModal = (modeType) => {
        this.setState({ showADDSCModal: true, modeType: modeType })
    }
    closeAddSCModal = () => {
        this.setState({ showADDSCModal: false })
    }
    openDeleteModal = (item, flag) => {
        dataToDelete = { ...item };
        deletionFlag = flag;
        this.setState({ showDeleteModal: true })
    }
    closeDeleteModal = (mode) => {
        this.setState({ showDeleteModal: false })
    }
    getPermissions(permissions) {
        let TempPeopleTab = [], peopleTabs = [];
        if (permissions.length > 0) {
            canViewMainDetails = permissions.filter((obj) => obj.permission_name === "People (SCs/MSs/MEs) + Other Contacts (Producers, Publicist, and Marketing Contacts)" && obj.view === 1);
            canViewMainDetails = canViewMainDetails.length > 0 ? true : false;
            canEditMainDetails = permissions.filter((obj) => obj.permission_name === "People (SCs/MSs/MEs) + Other Contacts (Producers, Publicist, and Marketing Contacts)" && obj.view === 1 && obj.edit === 1);
            canEditMainDetails = canEditMainDetails.length > 0 ? true : false;
            //view Budget vs. EFC Recipients tab
            CanViewBudgetVsEFCRecipients = permissions.filter((obj, index) => obj.permission_name === "Budget vs. EFC Recipients" && obj.view === 1);
            CanViewBudgetVsEFCRecipients = CanViewBudgetVsEFCRecipients.length > 0 ? true : false;
            if (CanViewBudgetVsEFCRecipients) { TempPeopleTab.push("Budget vs. EFC Recipients") }
            //Can view EFC Recipients tab
            CanViewEFCRecipients = permissions.filter((obj, index) => obj.permission_name === "EFC Recipients" && obj.view === 1);
            CanViewEFCRecipients = CanViewEFCRecipients.length > 0 ? true : false;
            if (CanViewEFCRecipients) { TempPeopleTab.push("EFC Recipients") }
            //Can view Cue Sheet Recipients tab
            CanViewCueSheetRecipients = permissions.filter((obj, index) => obj.permission_name === "Cue Sheet Recipients" && obj.view === 1);
            CanViewCueSheetRecipients = CanViewCueSheetRecipients.length > 0 ? true : false;
            if (CanViewCueSheetRecipients) { TempPeopleTab.push("Cue Sheet Recipients") }
            TempPeopleTab.map((item, index) => { peopleTabs.push({ label: item, value: index + 1 }) });
            if (peopleTabs.length > 0) {
                ''
                this.setState({ tabValue: peopleTabs[0].value, tabLabel: peopleTabs[0].label, peopleTabs: peopleTabs });
            }
        }
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
            case "checkRelationalFailure": return this.setState({ checkRelationalFailure: value });
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false, checkRelationalFailure: false });
        // if (this.state.saveFlag) {

        // }
    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false, checkRelationalFailure: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : this.state.checkRelationalFailure ? "error" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : this.state.checkRelationalFailure ? "You can not delete this Record because it is being used" : "";
        let personDetail = [];
        detailsToDisplay?.address_1 ? personDetail.push(detailsToDisplay.address_1) : null;
        detailsToDisplay?.address_2 ? personDetail.push(detailsToDisplay?.address_2) : null;
        detailsToDisplay?.state ? personDetail.push(detailsToDisplay?.state) : null;
        detailsToDisplay?.zip_code ? personDetail.push(detailsToDisplay?.zip_code) : null;
        detailsToDisplay?.country_name ? personDetail.push(detailsToDisplay?.country_name) : null;
        return (
            <div className="People-Container">
                <NotificationComponent open={this.state.showSuccess || this.state.showFail || this.state.checkRelationalFailure} message={messageNotification} severity={severity}
                    handleResetNotify={this.handleResetNotify.bind(this)} />
                {this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (<>
                    {canViewMainDetails ?
                        <MDBRow>
                            <MDBCol md={7}>
                                {this.state.isSecondaryLoading ? (
                                    <div style={{ textAlign: "center" }}>
                                        <CircularProgress size={24} />
                                    </div>
                                ) : (<>
                                    <MDBCard className="card-border">
                                        <MDBCardBody>
                                            <MDBCardText>
                                                <MDBRow>
                                                    <MDBCol md={4} className="ScMsMeWrapper">
                                                        <MDBRow>
                                                            <div className="SCHeading">
                                                                <BasicLabel text={"Show Composer"} />
                                                            </div>
                                                            <div className="showComposerWrapper">
                                                                {this.state.isFetchingShowComposer ? (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <CircularProgress size={24} />
                                                                    </div>
                                                                ) : (<>
                                                                    <div className="showComposerContainer" id="showComposerContainer">
                                                                        {!noShowComposerPresent ? <>{this.state.businessAffairsDetailsData.map((item, index) => (<>{
                                                                            item.people_type == "SHOW_COMPOSER" ? (<div key={"SHOW_COMPOSER" + index} id={"SHOW_COMPOSER" + index} className="sub-text showComposerList">
                                                                                <p className={item.isClicked ? "selected" : "font-color"} onClick={() => this.displayDetails(item, index)}>{item.full_name} </p>
                                                                                {canEditMainDetails ?
                                                                                    <span className="icon-css">
                                                                                        <span className="ProfileIcon" onClick={() => this.handleShowShowComposer("Edit", "Show Composer", item.people_id, item.show_season_people_id, "profile", item, index)}> Profile</span>
                                                                                        {this.props?.peopleModalPopupPermissions?.view === 1 ? <MDBIcon icon="pen" onClick={() => this.handleShowShowComposer("Edit", "Show Composer", item.people_id, item.show_season_people_id, null, item, index)} /> : null }
                                                                                        <MDBIcon icon="times" onClick={() =>
                                                                                            this.openDeleteModal(item, "deleteArtist")} />
                                                                                    </span> : null}
                                                                            </div>) : null}</>
                                                                        ))}
                                                                        </> : <div className="noRecordsPresent"><p className="font-color">None Added</p>
                                                                                {canEditMainDetails && <div className="createNewArtistRow">
                                                                                    <div><p onClick={(e) => this.openAddSCModal("Show Composer")}>Add SC</p></div>{this.props?.peopleModalPopupPermissions?.edit === 1 && <div><p onClick={(e) => this.handleShowShowComposer("New", "Show Composer")}>Create New</p></div>}
                                                                                </div>}
                                                                            </div>}
                                                                    </div>
                                                                    {!noShowComposerPresent && canEditMainDetails && <div className="createNewArtistRow">
                                                                        <div><p onClick={(e) => this.openAddSCModal("Show Composer")}>Add SC</p></div>{this.props?.peopleModalPopupPermissions?.edit === 1 && <div><p onClick={(e) => this.handleShowShowComposer("New", "Show Composer")}>Create New</p></div>}
                                                                    </div>}
                                                                </>
                                                                    )}
                                                                {/* </div> */}

                                                            </div>

                                                        </MDBRow>
                                                        <MDBRow>
                                                            <div className="MSHeading">
                                                                <BasicLabel text={"Music Supervisor"} />
                                                            </div>
                                                            <div className="showComposerWrapper">
                                                                {this.state.isFetchingMusicSupervisor ? (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <CircularProgress size={24} />
                                                                    </div>
                                                                ) : (<>
                                                                    <div className="showComposerContainer" id="showComposerContainer">
                                                                        {!nomusicSupervisorPresent ? <>{this.state.businessAffairsDetailsData.map((item, index) => (<>
                                                                            {item.people_type == "MUSIC_SUPERVISOR" ? (<div key={"MUSIC_SUPERVISOR" + index} id={"MUSIC_SUPERVISOR" + index} className="sub-text showComposerList">
                                                                                <p className={item.isClicked ? "selected" : "font-color"} onClick={() => this.displayDetails(item, index)}>{item.full_name} </p>
                                                                                {canEditMainDetails ?
                                                                                    <span className="icon-css">
                                                                                        <span className="ProfileIcon" onClick={() => this.handleShowShowComposer("Edit", "Music Supervisor", item.people_id, item.show_season_people_id, "profile", item, index)}> Profile</span>
                                                                                        {this.props?.peopleModalPopupPermissions?.view === 1 ? <MDBIcon icon="pen" onClick={() => this.handleShowShowComposer("Edit", "Music Supervisor", item.people_id, item.show_season_people_id, null, item, index)} /> : null }
                                                                                        <MDBIcon icon="times" onClick={() =>
                                                                                            this.openDeleteModal(item, "deleteArtist")} />
                                                                                    </span> : null}
                                                                            </div>) : null}
                                                                        </>))}</> : <div className="noRecordsPresent"><p className="font-color">None Added</p>
                                                                                {canEditMainDetails && <div className="createNewArtistRow">
                                                                                    <div><p onClick={(e) => this.openAddSCModal("Music Supervisor")}>Add MS</p></div>{this.props?.peopleModalPopupPermissions?.edit === 1 && <div><p onClick={() => this.handleShowShowComposer("New", "Music Supervisor")}>Create New</p></div>}
                                                                                </div>}
                                                                            </div>}
                                                                    </div>
                                                                    {!nomusicSupervisorPresent && canEditMainDetails && <div className="createNewArtistRow">
                                                                        <div><p onClick={(e) => this.openAddSCModal("Music Supervisor")}>Add MS</p></div>{this.props?.peopleModalPopupPermissions?.edit === 1 && <div><p onClick={() => this.handleShowShowComposer("New", "Music Supervisor")}>Create New</p></div>}
                                                                    </div>}
                                                                </>)}
                                                            </div>

                                                        </MDBRow>
                                                        <MDBRow>
                                                            <div className="MSHeading">
                                                                <BasicLabel text={"Music Editor"} />
                                                            </div>
                                                            <div className="showComposerWrapper">
                                                                {this.state.isFetchingMusicEditor ? (
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <CircularProgress size={24} />
                                                                    </div>
                                                                ) : (<>
                                                                    <div className="showComposerContainer" id="showComposerContainer">
                                                                        {!noMusicEditorPresent ? <>{this.state.businessAffairsDetailsData.map((item, index) => (<>
                                                                            {item.people_type == "MUSIC_EDITOR" ? (<div key={"MUSIC_EDITOR" + index} id={"MUSIC_EDITOR" + index} className="sub-text showComposerList">
                                                                                <p className={item.isClicked ? "selected" : "font-color"} onClick={() => this.displayDetails(item, index)}>{item.full_name} </p>
                                                                                {canEditMainDetails ?
                                                                                    <span className="icon-css">
                                                                                        <span className="ProfileIcon" onClick={() => this.handleShowShowComposer("Edit", "Music Editor", item.people_id, item.show_season_people_id, "profile", item, index)}> Profile</span>
                                                                                        {this.props?.peopleModalPopupPermissions?.view === 1 ? <MDBIcon icon="pen" onClick={() => this.handleShowShowComposer("Edit", "Music Editor", item.people_id, item.show_season_people_id, null, item, index)} /> : null }
                                                                                        <MDBIcon icon="times" onClick={() =>
                                                                                            this.openDeleteModal(item, "deleteArtist")} />
                                                                                    </span> : null}
                                                                            </div>) : null}
                                                                        </>))}</> : <div className="noRecordsPresent"><p className="font-color">None Added</p>
                                                                                {canEditMainDetails && <div className="createNewArtistRow">
                                                                                    <div><p onClick={(e) => this.openAddSCModal("Music Editor")}>Add ME</p></div>{this.props?.peopleModalPopupPermissions?.edit === 1 && <div><p onClick={() => this.handleShowShowComposer("New", "Music Editor")}>Create New</p></div>}
                                                                                </div>}
                                                                            </div>}
                                                                    </div>
                                                                    {!noMusicEditorPresent && canEditMainDetails && <div className="createNewArtistRow">
                                                                        <div><p onClick={(e) => this.openAddSCModal("Music Editor")}>Add ME</p></div>{this.props?.peopleModalPopupPermissions?.edit === 1 && <div><p onClick={() => this.handleShowShowComposer("New", "Music Editor")}>Create New</p></div>}
                                                                    </div>}
                                                                </>)}
                                                            </div>
                                                        </MDBRow>
                                                    </MDBCol>
                                                    <MDBCol md={8}>
                                                        <MDBRow id="p-font">
                                                            <MDBCol md={7}>
                                                                <MDBRow>
                                                                    <BasicLabel text={"Company"} />
                                                                    <p className='content-font'>{detailsToDisplay?.company}</p>
                                                                </MDBRow>
                                                                <MDBRow>
                                                                    <BasicLabel text={"Email"} />
                                                                    <p className='content-font'>{detailsToDisplay?.email}</p>
                                                                </MDBRow>
                                                                <MDBRow>
                                                                    <BasicLabel text={"WorkPhone"} />
                                                                    <p className='content-font'>{detailsToDisplay?.work_phone}</p>
                                                                </MDBRow>
                                                                <MDBRow>
                                                                    <BasicLabel text={"Notes"} />
                                                                    <p className='content-font'>{detailsToDisplay?.notes}</p>
                                                                </MDBRow>
                                                            </MDBCol>
                                                            <MDBCol md={5}>
                                                                <MDBRow>
                                                                    <BasicLabel text={"Address"} />
                                                                    <p className='content-font'>{personDetail.map((item, index) => <span>{item}{index !== personDetail.length - 1 && ", "}</span>)}</p>
                                                                </MDBRow>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCardText>
                                        </MDBCardBody>
                                    </MDBCard>
                                </>)}
                            </MDBCol>
                            <MDBCol md={5} className="budget-text">
                                <MDBCard className="card-border">
                                    <MDBCardBody>
                                        <MDBCardText>
                                            <div className="display-icon space-css">
                                                {/* <p className="content-font-2">Other Contacts</p> */}
                                                <BasicLabel text={"Other Contacts"} className="content-font-2" />
                                                {canEditMainDetails &&
                                                    <div className="OtherContactsEditBtn">
                                                        <BasicButton className="pen-icon-style"
                                                            icon={"pen"} text=" "
                                                            variant="contained" onClick={this.handleShowProducerContacts} />
                                                    </div>
                                                }
                                            </div>
                                            <MDBRow>
                                                <MDBCol md={2}>
                                                    <BasicLabel text={"Prim."} className="content-font" />
                                                </MDBCol>
                                                <MDBCol md={5}>
                                                    <BasicLabel text={"Producer’s Name"} className="content-font" />
                                                </MDBCol>
                                                <MDBCol md={5}>

                                                </MDBCol>
                                            </MDBRow>
                                            <div className="producerWrapper">
                                                {this.state.seasonProducersDetails.length > 0 && this.state.seasonProducersDetails[0]?.show_season_producers.map((item, index) => {
                                                    return <MDBRow key={"producerRow" + index}>
                                                        <MDBCol md={2}>
                                                            <p className='content-font'>
                                                                {item?.is_primary == 1 ? <MDBIcon icon="check" /> : null}
                                                            </p>
                                                        </MDBCol>
                                                        <MDBCol md={5}>
                                                            <p className='content-font'>{item?.producer_name}</p>
                                                        </MDBCol>
                                                        <MDBCol md={5}>
                                                            <span className='content-font'>{item?.producer_email}</span>
                                                        </MDBCol>
                                                    </MDBRow>
                                                })}
                                            </div>
                                            <MDBRow>
                                                <MDBCol md={6}>
                                                    <BasicLabel text={"Publicst"} className="content-font" />
                                                </MDBCol>
                                                <MDBCol md={6}>

                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md={6}>
                                                    <p className='content-font'>{this.state.seasonProducersDetails[0]?.publicist_name}</p>
                                                </MDBCol>
                                                <MDBCol md={6}>
                                                    <p className='content-font'>{this.state.seasonProducersDetails[0]?.publicist_email}</p>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md={6}>
                                                    <BasicLabel text={"Marketing Contact"} className="content-font" />
                                                </MDBCol>
                                                <MDBCol md={6}>

                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md={6}>
                                                    <p className='content-font'>{this.state.seasonProducersDetails[0]?.marketing_contact_name}</p>
                                                </MDBCol>
                                                <MDBCol md={6}>
                                                    <p className='content-font'>{this.state.seasonProducersDetails[0]?.marketing_contact_email}</p>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCardText>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        : null}
                    <MDBRow style={{ marginBottom: "10px" }} className="mt-2">
                        <TabsComponent
                            tabList={this.state.peopleTabs}
                            value={this.state.tabValue}
                            onChange={(e, v) => this.handleTabChange(v)}
                        />
                        {this.state.peopleTabs.length > 0 && this.getTabComponent()}
                    </MDBRow>
                </>)}
                {/* delete Confirmation modal */}
                {this.state.showDeleteModal &&
                    <MessageModal
                        title={"Confirm Deletion"}
                        open={this.state.showDeleteModal}
                        message={"Are You Sure To Delete?"}
                        primaryButtonText={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : "Yes"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={(deletionFlag === "deleteArtist" && this.deleteData) || (deletionFlag === "deleteCR" && this.deleteTableRow)}
                        handleClose={this.closeDeleteModal} />
                }
                {this.state.ShowProducerContacts && (
                    <MainViewModalComponent
                        open={this.state.ShowProducerContacts}
                        handleClose={this.handleHideProducerContacts}
                        headerText={"Other Contacts"}
                        mode={""}
                        modalName={"Edit Producer Contacts"}
                        show_season_id={this.props.show_season_id}
                        season_id={this.props.season_id}
                        show_id={this.props.show_id}
                        division_id={this.props.division_id}
                        fetchData={this.fetchData.bind(this)}
                    />
                )}
                {
                    this.state.showADDSCModal && this.state?.show_season_id && (
                        <MainViewModalComponent
                            open={this.state.showADDSCModal}
                            handleClose={this.closeAddSCModal}
                            headerText={`Add a ${this.state.modeType}`}
                            mode={"Both"}
                            modeType={this.state.modeType}
                            modalName={"Add ShowComposer"}
                            show_season_id={this.state.show_season_id}
                            show_id={this.props.show_id}
                            season_id={this.props.season_id}
                            people_id={this.state.people_id}
                            division_id={this.props.division_id}
                            fetchData={this.fetchData.bind(this)}
                            peopleDetailsData={this.state.businessAffairsDetailsData}
                        />
                    )
                }
                {/** will get seasonId, showId,showSeasonId from people get API */}
                {this.state.ShowPersonModal && (
                    <MainViewModalComponent
                        open={this.state.ShowPersonModal}
                        handleClose={this.handleHidePersonModal}
                        headerText={`Person- ${this.state.modeType}`}
                        mode={this.state.mode}
                        modeType={this.state.modeType}
                        modalName={"Person"}
                        show_season_id={this.props.show_season_id}
                        show_id={this.props.show_id}
                        season_id={this.props.season_id}
                        people_id={this.state.people_id}
                        show_season_people_id={this.state.show_season_people_id}
                        division_id={this.props.division_id}
                        fetchData={this.fetchData.bind(this)}
                        origin={this.state.origin}
                        peopleModalPopupPermissions={this.props?.peopleModalPopupPermissions}
                    />
                )}
            </div>

        )
    }

}
export default withUserContext(People);