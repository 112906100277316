import React from 'react';
import { MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText, MDBTooltip } from 'mdb-react-ui-kit';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import BasicTextArea from '../../../components/SharedComponents/BasicTextArea/BasicTextArea';
import TableComponent from '../../SharedComponents/Table';
import { showComposerConfig, newDetailObject, musicSupervisorConfig } from './Config';
import TabsComponent from '../../../components/SharedComponents/Tabs/Tabs';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import BusinessAffairsDetails from './BusinessAffairsDetails';
import Popover from '@mui/material/Popover';
import MessageModal from '../../SharedComponents/MessageModal';
import './BusinessAffairs.scss';
import BusinessAffairsDeals from './BusinessAffairsDeals';
import MainViewModalComponent from '../../../Common/MainViewModalLayout/Modal/Modal';
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import moment from 'moment';
import { CircularProgress } from "@material-ui/core";
import { withUserContext } from '../../../contexts/UserContext';
import {validateNumDotOnly} from "../../../Common/Helper"
import { updateTableSortConfigObject, tableSortList } from '../../../Common/TableHelper';
let EditMusicSupervisorPermission = false, canGenerateCheckReq = false, generateCheckReqPermissions, noShowComposerPresent = false, nomusicSupervisorPresent = false, canEditMsSCDealCheckReq = false, viewDealMemoPDF = false, dataToDelete = {}, scAndMSDetails = [];
let BusinessTabList = [{ label: "Details", value: 1 }], detailsToDisplay = {};
let tempConfig = {}, formattedEpisodeList = [], costCenterOptions_SHOW_COMPOSER = [], costCenterOptions_MUSIC_SUPERVISOR = [], deletionFlag = "", crObjToEdit = null, indexOfDisplayItem = null, displayedItem = null;
class BusinessAffairs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            permissionArr: this.props.permissionArr,
            expanded: false,
            isLoading: false,
            isFetchingShowComposer: false,
            isFetchingMusicSupervisor: false,
            showEditSeasonModal: false,
            showSesonIdToEdit: null,
            isSecondaryLoading: false,
            show_season_people_id: null,
            renderList: [],
            config: showComposerConfig,
            tabValue: 1,
            BusinessTabList: BusinessTabList,
            subTabValue: 1,
            showShowComposer: false,
            setPopoverFile: null,
            showDeleteModal: false,
            isSubmit: false,
            mode: null,
            modeType: null,
            show_season_id: this.props.show_season_id,
            musicSupervisorCheckRequest: [],
            businessAffairsDetailsData: [],
            businessAffairsDealsData: {},
            people_id: null,
            costCenterOptions: [],
            showError: false,
            showSuccess: false,
            showFail: false,
            showADDSCModal: false,
            origin: null,
            initialRecordData: this.props.initialRecordData,
            popoverColumnClicked: null,
            tempNotesData: {},
            selectedPayeeId: null,
            showPayeeModal: false,
            isSubmitLoading: false,
            filesArr: [],
            isFileLoading: false,
            mandatoryNotExist: false,
            payeeRecordOptions: [],
            peopleId: null,
            formattedCategoryList: [],
            showExcelAndDocModal: false,
            isOpenExisting: false,
            tableRecord: null,
            sortBy: null,
            isSortByAsc: true,
            sortCount: 0,
            duplicateValues: false,
        };
    }

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1:
                return <BusinessAffairsDetails detailsToDisplay={detailsToDisplay} show_season_id={this.state.show_season_id} division_id={this.props.division_id} isSecondaryLoading={this.state.isSecondaryLoading} permissionArr={this.props.permissionArr} />;

            case 2:
                return <BusinessAffairsDeals detailsToDisplay={detailsToDisplay} fetchDealData={this.fetchDealData.bind(this)} businessAffairsDealsData={this.state.businessAffairsDealsData} show_season_id={this.state.show_season_id} division_id={this.props.division_id} isSecondaryLoading={this.state.isSecondaryLoading} permissionArr={this.props.permissionArr} initialRecordData={this.props.initialRecordData} filesArr={this.state.filesArr} viewDealMemoPDF={viewDealMemoPDF} isFileLoading={this.state.isFileLoading} />
        }
    };

    isOpenExisting=(data)=>{
        this.setState({isOpenExisting: data})
    }
    handleShowExcelAndDocModal = (value) => {
        this.setState({ showExcelAndDocModal: true ,isOpenExisting: false, tableRecord : value});
    };
    handleHideExcelAndDocModal = () => {
        this.setState({ showExcelAndDocModal: false });
    };
    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };
    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded });
    };
    handleClickFile = (obj, column) => (e) => {
        console.log("column test", column);
        crObjToEdit = obj;
        this.setState({
            setPopoverFile: e.currentTarget,
            popoverColumnClicked: column,
            tempNotesData: obj,
        })
    }
    handleCloseFile = () => {
        crObjToEdit = null;
        this.setState({
            setPopoverFile: null,
            popoverColumnClicked: null,
            tempNotesData: null,
        })
    };
    handleEditPayeeModal = (dataItem) => {
        this.setState({ selectedPayeeId: dataItem.payee_id, showPayeeModal: true })
    }
    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList];
        this.setState({
            mandatoryNotExist: false,
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                if (item.show_season_music_supervisor_check_requests_id == id && item.payee_remit_to_id === dataItem.payee_remit_to_id) {
                    newItem.payee = {
                        text: dataItem.payee_company,
                        value: dataItem.payee
                    };
                    newItem['editing'] = true;
                }

                return newItem
            })
        })
    }
    postUpdatedData = (dataToUpdate, postFlag) => {
        this.setState({ isSubmitLoading: postFlag });
        // this.setState({ isTableLoading: true });
        if (detailsToDisplay.people_type === "MUSIC_SUPERVISOR") {
            delete dataToUpdate["is_sr"];
            delete dataToUpdate["is_score"];
        }
        if (detailsToDisplay.people_type === "SHOW_COMPOSER") {
            delete dataToUpdate["is_packet"];
        }
        if (dataToUpdate?.req_date !== null && dataToUpdate?.req_date !== undefined) {
            dataToUpdate.req_date = moment(dataToUpdate.req_date).format('YYYY-MM-DD');
        } else {
            dataToUpdate.req_date = null;
        }
        if (dataToUpdate?.date_paid !== null && dataToUpdate?.date_paid !== undefined) {
            dataToUpdate.date_paid = moment(dataToUpdate.date_paid).format('YYYY-MM-DD');
        } else {
            dataToUpdate.date_paid = null;
        }
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonMusicSupervisorCheckRequests`,
            dataToUpdate,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id).then(
                (response) => {
                    // this.setState({ isSubmit: false });
                    if (response.data.error) {
                        this.setState({ isSubmitLoading: false });
                        this.fetchTableData("musicSupervisorCheckRequest", `/showSeasonMusicSupervisorCheckRequests?show_season_id=${this.state.show_season_id}&show_season_people_id=${detailsToDisplay?.show_season_people_id}`);
                        this.notificationComponent(true, "fail");
                    } else {
                        // this.setState({ isTableLoading: false });
                        if (dataToUpdate?.editing) {
                            budgetDetails.editing = false;
                        }
                        this.setState({ showDeleteModal: false });
                        //this.props?.fieldChanged(true, "success");
                        this.fetchTableData("musicSupervisorCheckRequest", `/showSeasonMusicSupervisorCheckRequests?show_season_id=${this.state.show_season_id}&show_season_people_id=${detailsToDisplay?.show_season_people_id}`, null, false);
                        this.notificationComponent(true, "success");
                    }
                },
                (err) => {
                    this.setState({ isSubmit: false });
                    this.notificationComponent(true, "fail");
                    console.log("Post budget details error: " + err);
                }
            );
    };
    handleReadCallback = (index) => {
        let allDetails = [...this.state.renderList]
        allDetails[index].editing = false
        this.setState({ renderList: allDetails, mandatoryNotExist: false })
    }
    handleAddNewItem = (newDataItem) => {
        this.setState({ mandatoryNotExist: false, duplicateValues:false })
        if (newDataItem.payee_remit_to_id) {
            if((newDataItem?.show_season_episode_id && newDataItem?.category_id )? 
            !this.state.renderList?.find(item => item?.show_season_episode_id === newDataItem?.show_season_episode_id && item?.category_id === newDataItem?.category_id) : true) {
            let newObject = { ...newDetailObject }
            newObject["payee_remit_to_id"] = newDataItem?.payee_remit_to_id;
            // newObject["payee_company"] = newDataItem?.payee?.text;
            newObject["show_season_episode_id"] = newDataItem?.show_season_episode_id;
            newObject["episode_number"] = newDataItem?.episode?.text;
            newObject["check_amount"] = newDataItem?.check_amount || null;
            newObject["category_id"] = newDataItem?.category_id;
            newObject["inv_notes"] = newDataItem?.inv_notes || null;
            if (detailsToDisplay.people_type === "SHOW_COMPOSER") {
                newObject["cost_center_id"] = costCenterOptions_SHOW_COMPOSER[0].cost_center_id;
                newObject["cost_center_code"] = costCenterOptions_SHOW_COMPOSER[0].cost_center_code;
            }
            if (detailsToDisplay.people_type === "MUSIC_SUPERVISOR") {
                newObject["cost_center_id"] = costCenterOptions_MUSIC_SUPERVISOR[0].cost_center_id;
                newObject["cost_center_code"] = costCenterOptions_MUSIC_SUPERVISOR[0].cost_center_code;
            }
            if (newDataItem?.req_date !== null && newDataItem?.req_date !== undefined) {
                newObject["req_date"] = moment(newDataItem?.req_date).format('YYYY-MM-DD');
            }
            else {
                newObject["req_date"] = null;
            }
            if (newDataItem?.date_paid !== null && newDataItem?.date_paid !== undefined) {
                newObject["date_paid"] = moment(newDataItem.date_paid).format('YYYY-MM-DD');
            }
            else {
                newObject["date_paid"] = null;
            }
            if (detailsToDisplay?.people_type === "MUSIC_SUPERVISOR") {
                if (newDataItem?.is_packet) {
                    newObject["is_packet"] = newDataItem.is_packet ? 1 : 0;
                } else {
                    newObject["is_packet"] = 0;
                }
            }
            if (detailsToDisplay?.people_type === "SHOW_COMPOSER") {
                if (newDataItem?.is_sr) {
                    newObject["is_sr"] = newDataItem.is_sr ? 1 : 0;
                } else {
                    newObject["is_sr"] = 0;
                }
                if (newDataItem?.is_score) {
                    newObject["is_score"] = newDataItem.is_score ? 1 : 0;
                } else {
                    newObject["is_score"] = 0;
                }
            }
            newObject["inv_number"] = newDataItem?.inv_number;
            newObject["show_season_id"] = this.state?.show_season_id;
            newObject["show_id"] = this.props?.show_id;
            newObject["season_id"] = this.props?.season_id;
            //harshit changes
            //newObject["music_supervisor_id"] = detailsToDisplay?.show_season_people_id;
            newObject["show_season_people_id"] = detailsToDisplay?.show_season_people_id;
            let renderedCRItems = [...this.state.renderList];
            
            if(newObject?.check_amount === null){
                this.setState({ mandatoryNotExist: false })
                this.postUpdatedData(newObject, 'newItemPost'); 
            } else if (validateNumDotOnly(newObject?.check_amount) === false) {
                this.setState({ mandatoryNotExist: true }) 
                
            }  else {
                this.setState({ mandatoryNotExist: false })
                this.postUpdatedData(newObject, 'newItemPost'); 
            }
            
            //renderedCRItems.push(newObject);
            //this.setState({ renderList: renderedCRItems });
        } else {
            this.setState({duplicateValues: true})
        }
        } else {
            this.setState({ mandatoryNotExist: true })
        }
    };
    openAddSCModal = (modeType, origin = null) => {
        this.setState({ showADDSCModal: true, modeType: modeType, origin });
    }
    closeAddSCModal = () => {
        this.setState({ showADDSCModal: false })
    }
    openDeleteModal = (item, flag) => {
        dataToDelete = { ...item };
        deletionFlag = flag;
        this.setState({ showDeleteModal: true })
    }
    closeDeleteModal = (mode) => {
        this.setState({ showDeleteModal: false })
    }
    deleteTableRow = () => {
this.setState({ showDeleteModal: false });
        clearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=show_season_music_supervisor_check_requests&tableId=${dataToDelete?.show_season_music_supervisor_check_requests_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(res => {
            if (res.data[0].status === "SUCCESS") {
                dataToDelete["is_delete"] = 1;
                dataToDelete.show_season_id = this.props?.show_season_id;
                dataToDelete.season_id = this.props?.season_id;
                dataToDelete.show_id = this.props?.show_id;
                if (dataToDelete?.req_date !== null && dataToDelete?.req_date !== undefined) {
                    dataToDelete.req_date = moment(dataToDelete.req_date).format('YYYY-MM-DD')
                } else {
                    dataToDelete.req_date = null;
                }
                if (dataToDelete?.date_paid !== null && dataToDelete?.date_paid !== undefined) {
                    dataToDelete.date_paid = moment(dataToDelete.date_paid).format('YYYY-MM-DD')
                } else {
                    dataToDelete.date_paid = null;
                }
                this.postUpdatedData(dataToDelete, dataToDelete.show_season_music_supervisor_check_requests_id);

            } else {
                //this.props?.fieldChanged(true, 'fail')
                this.notificationComponent(true, "checkRelationalFailure");
                this.setState({ isSubmit: false, showDeleteModal: false, isTableLoading: false });
            }
        }),
            ((err) => {
                //this.props?.fieldChanged(true)
                this.notificationComponent(true, "fail");
                this.setState({ isSubmit: false });
            });
    };
    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({ mandatoryNotExist: false, duplicateValues: false })
        if (data[index].payee_remit_to_id) {
            if((data[index]?.show_season_episode_id && data[index]?.category_id )? 
            !this.state.renderList?.find(item => item?.show_season_music_supervisor_check_requests_id !== data[index]?.show_season_music_supervisor_check_requests_id && item?.show_season_episode_id === data[index]?.show_season_episode_id && item?.category_id === data[index]?.category_id) : true) {
            let allDetails = [...this.state.renderList]
            let tempInlineSavedData = [...data];
            if (typeof tempInlineSavedData[index].payee == "object") {
                allDetails[index].payee_remit_to_id = tempInlineSavedData[index]?.payee?.value;
                allDetails[index].payee_company = tempInlineSavedData[index]?.payee?.text;
            } else {
                allDetails[index].payee_remit_to_id = tempInlineSavedData[index]?.payee_remit_to_id;
                allDetails[index].payee_company = tempInlineSavedData[index]?.payee_company;
            }
            allDetails[index].show_season_episode_id = tempInlineSavedData[index]?.show_season_episode_id;

            if (detailsToDisplay?.people_type === "MUSIC_SUPERVISOR") {
                if (tempInlineSavedData[index]?.is_packet) {
                    allDetails[index].is_packet = tempInlineSavedData[index].is_packet ? 1 : 0;
                }
                else {
                    allDetails[index].is_packet = 0;
                }

            }
            if (detailsToDisplay?.people_type === "SHOW_COMPOSER") {
                if (tempInlineSavedData[index]?.is_sr) {
                    allDetails[index].is_sr = tempInlineSavedData[index].is_sr ? 1 : 0;
                } else {
                    allDetails[index].is_sr = 0;
                }
                if (tempInlineSavedData[index]?.is_score) {
                    allDetails[index].is_score = tempInlineSavedData[index].is_score ? 1 : 0;
                } else {
                    allDetails[index].is_score = 0;
                }
            }
            allDetails[index].check_amount = validateNumDotOnly(tempInlineSavedData[index]?.check_amount) ? tempInlineSavedData[index]?.check_amount : null;
            allDetails[index].category_id = tempInlineSavedData[index]?.category_id;
            if (detailsToDisplay.people_type === "SHOW_COMPOSER") {
                allDetails[index].cost_center_id = costCenterOptions_SHOW_COMPOSER[0].cost_center_id;
                allDetails[index].cost_center_code = costCenterOptions_SHOW_COMPOSER[0].cost_center_code;
            }
            if (detailsToDisplay.people_type === "MUSIC_SUPERVISOR") {
                allDetails[index].cost_center_id = costCenterOptions_MUSIC_SUPERVISOR[0].cost_center_id;
                allDetails[index].cost_center_code = costCenterOptions_MUSIC_SUPERVISOR[0].cost_center_code;
            }
            allDetails[index].req_date = tempInlineSavedData[index]?.req_date ?moment(tempInlineSavedData[index]?.req_date).format("YYYY-MM-DD"):null;
            allDetails[index].inv_number = tempInlineSavedData[index]?.inv_number;
            allDetails[index].date_paid = tempInlineSavedData[index]?.date_paid?moment(tempInlineSavedData[index]?.date_paid).format("YYYY-MM-DD"):null;
            allDetails[index].inv_notes = tempInlineSavedData[index]?.inv_notes;
            allDetails[index].notes = tempInlineSavedData[index]?.notes;
            allDetails[index].show_season_id = this.state?.show_season_id;
            allDetails[index].show_id = this.props?.show_id;
            allDetails[index].season_id = this.props?.season_id;
            // harshit changes
            // allDetails[index].music_supervisor_id = detailsToDisplay?.show_season_people_id;
            allDetails[index].show_season_people_id = detailsToDisplay?.show_season_people_id;
            if (allDetails[index]?.check_amount === null) {
                this.setState({ mandatoryNotExist: false })
                this.postUpdatedData(allDetails[index], allDetails[index].show_season_music_supervisor_check_requests_id);
           }
            else if (validateNumDotOnly(allDetails[index]?.check_amount) === false) {
                this.setState({ mandatoryNotExist: true })
            } else {
                this.setState({ mandatoryNotExist: false })
                this.postUpdatedData(allDetails[index], allDetails[index].show_season_music_supervisor_check_requests_id);
            }
            this.setState({
                renderList: allDetails.map((item) => {
                    item['editing'] = false
                    return item
                })
            })
        } else {
            this.setState({duplicateValues: true})
        }   
        } else {
            this.setState({ mandatoryNotExist: true })
        }

    }
    handleShowShowComposer = (mode, modeType, people_id, show_season_people_id, origin = null, item = null, index = null) => {
        this.setState({ showShowComposer: true, mode, modeType, people_id, show_season_people_id, origin });
        if (mode === "Edit") {
            indexOfDisplayItem = index;
            displayedItem = item;
            this.displayDetails(item, index);
        }
    }
    handleHideShowComposer = () => {
        this.setState({ showShowComposer: false });
    }
    onLoadFetchData = (field, url) => {
        this.setState({ isLoading: true, isFetchingShowComposer: true, isFetchingMusicSupervisor: true });
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {

                let formattedList = [];
                switch (field) {
                    case 'costCenterOptions_SHOW_COMPOSER':
                        costCenterOptions_SHOW_COMPOSER = response.data?.map(item => ({ cost_center_id: item.cost_center_id, cost_center_code: item.cost_center_code }));
                        break;
                    case 'costCenterOptions_MUSIC_SUPERVISOR':
                        costCenterOptions_MUSIC_SUPERVISOR = response.data?.map(item => ({ cost_center_id: item.cost_center_id, cost_center_code: item.cost_center_code }));
                        break;
                    case 'businessAffairsDetailsData':
                        formattedList = response?.data;
                        scAndMSDetails = [];
                        let showComposerArr = response.data.length > 0 ? response.data.filter(item => item?.people_type == "SHOW_COMPOSER") : [];
                        if (response.data.length == 0 || showComposerArr.length == 0) {
                            noShowComposerPresent = true;
                        } else { noShowComposerPresent = false; }
                        let musicSupervisorArr = response.data.length > 0 ? response.data.filter(item => item?.people_type == "MUSIC_SUPERVISOR") : [];
                        if (response.data.length == 0 || musicSupervisorArr.length == 0) {
                            nomusicSupervisorPresent = true;
                        } else { nomusicSupervisorPresent = false; }
                        scAndMSDetails.push(...showComposerArr, ...musicSupervisorArr);
                        this.props.getScAndMSDetails(scAndMSDetails);
                        this.setState({
                            businessAffairsDetailsData: formattedList, isFetchingShowComposer: false, isFetchingMusicSupervisor: false, isLoading: false
                        });
                        break;
                    case 'episodeOptions':
                        formattedEpisodeList = response?.data?.map((item, index) => ({ value: item?.show_season_episode_id, label: item?.episode_number }));
                        let tempEpisodeData = { ...this.state.config }
                        tempEpisodeData.headings[2].selectOptions = formattedEpisodeList;
                        this.setState({
                            config: tempEpisodeData
                        })
                        break;
                    case 'categoryOptions':
                        let formattedCategoryList = [];
                        formattedCategoryList = response?.data?.map((item, index) => ({ value: item?.category_id, label: item?.category_name, abbr: item?.abbrevation }));
                        let tempCategoryData = { ...this.state.config }
                        tempCategoryData.headings[4].selectOptions = formattedCategoryList;
                        this.setState({
                            config: tempCategoryData,
                            formattedCategoryList
                        })
                        break;
                }
            },
                (err) => {
                    this.setState({ isLoading: false, isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
                    console.log("Error in fetching Address Types:", err)
                })
    }
    fetchTableData = (field, url, people_type, loader = true) => {
        this.setState({ isTableLoading: loader });
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                // this.setState({ isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
                let formattedList = [];
                switch (field) {
                    case 'musicSupervisorCheckRequest':
                        formattedList = [];
                        response?.data?.length > 0 && response?.data?.map((item, index) => {
                            if (item?.req_date !== null && item?.req_date !== undefined) {
                                item.req_date = moment(item.req_date).format('YYYY-MM-DD');
                            } else {
                                item.req_date = null;
                            }
                            if (item?.date_paid !== null && item?.date_paid !== undefined) {
                                item.date_paid = moment(item.date_paid).format('YYYY-MM-DD');
                            } else {
                                item.date_paid = null;
                            }
                            if (people_type === "SHOW_COMPOSER") {
                                item["cost_center_id"] = costCenterOptions_SHOW_COMPOSER[0].cost_center_id;
                                item["cost_center_code"] = costCenterOptions_SHOW_COMPOSER[0].cost_center_code;
                            }
                            if (people_type === "MUSIC_SUPERVISOR") {
                                item["cost_center_id"] = costCenterOptions_MUSIC_SUPERVISOR[0].cost_center_id;
                                item["cost_center_code"] = costCenterOptions_MUSIC_SUPERVISOR[0].cost_center_code;
                            }
                            if (item?.check_amount === null) {
                                item.check_amount = 0;
                            }
                            item["payee"] = item?.payee_remit_to_id;
                            item["payee_name"] = item?.payee_company;
                            item["episode"] = item?.show_season_episode_id;
                            item["notes"] = item?.notes ? item.notes : null;
                            item["inv_notes"] = item?.inv_notes ? item.inv_notes : null;
                            item["bch"] = item?.show_season_music_supervisor_check_requests_id ? true : false;
                            formattedList.push(item);
                        });
                        this.setState((prevState) => ({
                            renderList: formattedList,
                            musicSupervisorCheckRequest: formattedList,
                            isTableLoading: false,
                            isSubmitLoading: false
                        }), () => {
                            this.handleTableColumnClick('Episode')
                        });
                        break;
                }
            },
                (err) => {
                    this.setState({ isTableLoading: false });
                    console.log("Error in fetching Address Types:", err)
                })
    }
    fetchData = (field, url, calledFrom = null) => {
        this.setState({ isSecondaryLoading: true, isTableLoading: true });
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
                let formattedList = [];
                switch (field) {
                    case 'businessAffairsDealsData':
                        this.setState({ isTableLoading: false });
                        formattedList = response.data[0];
                        formattedList.memo_date = response?.data[0]?.memo_date ? response?.data[0].memo_date.split('T')[0] : null
                        this.setState({
                            businessAffairsDealsData: formattedList, isSecondaryLoading: false, isTableLoading: false
                        })
                        break;
                    case 'businessAffairsDetailsData':
                        formattedList = response.data;
                        scAndMSDetails = [];
                        if (calledFrom !== "editing") {
                            detailsToDisplay = {};
                        }
                        let showComposerArr = response.data.filter(item => item.people_type == "SHOW_COMPOSER");
                        if (showComposerArr.length == 0) {
                            noShowComposerPresent = true;
                        } else { noShowComposerPresent = false; }
                        let musicSupervisorArr = response.data.filter(item => item.people_type == "MUSIC_SUPERVISOR");
                        if (musicSupervisorArr.length == 0) {
                            nomusicSupervisorPresent = true;
                        } else { nomusicSupervisorPresent = false; }
                        scAndMSDetails.push(...showComposerArr, ...musicSupervisorArr);
                        this.props.getScAndMSDetails(scAndMSDetails);
                        if (calledFrom === "editing" && formattedList.length > 0) {
                            displayedItem = formattedList[indexOfDisplayItem];
                            this.displayDetails(formattedList[indexOfDisplayItem], indexOfDisplayItem);
                        }
                        this.setState({
                            businessAffairsDetailsData: formattedList, isFetchingShowComposer: false, isFetchingMusicSupervisor: false, isSecondaryLoading: false, isTableLoading: false
                        });
                        break;
                }
            },
                (err) => {
                    this.setState({ isSecondaryLoading: false });
                    console.log("Error in fetching Address Types:", err)
                })
    }
    onLoadFileFetchData = (url, queryParamObj, loader = true) => {
        this.setState({ isFileLoading: loader });
        clearTrackService.getDataParams(Constants.ClearTrackServiceBaseUrl + url, queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                this.setState({ isFileLoading: false });
                if (response?.data?.data?.length > 0) {
                    this.setState({ filesArr: response.data.data });
                    //this.props.disabletriggerGetFileData(null);
                } else {
                    this.setState({ filesArr: [] });
                }
            },
                (err) => {
                    this.setState({ isFileLoading: false });
                    console.log("Error in fetching data", err)
                })
    }
    fetchDealData(selected_show_season_people_deal_id) {
        this.fetchData("businessAffairsDealsData", `/dealDetails?show_season_people_deal_id=${selected_show_season_people_deal_id}`);
        let queryParam = {
            "showName": detailsToDisplay?.show_name,
            "season": detailsToDisplay?.season_name.replace(/ /g, ""),
            "module": "showseasondeal",
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "isBoxUpload": 1,
            "dealId": detailsToDisplay?.show_season_people_deal_id,
            "peopleName": detailsToDisplay?.full_name,
            "peopleId": detailsToDisplay?.show_season_people_id,
            'divisionId':this.props.division_id,
            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
        }
        this.onLoadFileFetchData(`/getFiles`, queryParam);
    }
    handleShareClick = (Id, dataItem, dataItem2) => {
        window.open(`/showseason/${dataItem.show_season_id}`, '_blank');
        // let showSesonIdToEdit = null;
        // this.setState({ mode: "Edit" })
        // console.log("share", Id, "dataItem", dataItem, "dataItem2", dataItem2);
        // showSesonIdToEdit = dataItem.show_season_id;
        // this.setState({ showEditSeasonModal: true, showSesonIdToEdit: showSesonIdToEdit });
    }
    displayDetails = (item, index) => {
        let id = item.people_id;
        let tempPayeeData = { ...this.state.config }
        tempPayeeData.headings[1].selectOptions = [];
        this.setState({ peopleId: id, config: tempPayeeData }, () => {
            this.getPayee(this.state.peopleId);
        })
        indexOfDisplayItem = index;
        let arr = this.state.businessAffairsDetailsData;
        this.state.businessAffairsDetailsData.filter((elem, index) => { (elem.isClicked === true) ? delete elem.isClicked : null });
        item.isClicked = true;
        arr.splice(index, 1, item);
        this.setState({ businessAffairsDetailsData: arr });
        detailsToDisplay = { ...item };
        if (item?.people_type === "SHOW_COMPOSER") {
            tempConfig = { ...showComposerConfig };
            tempConfig.headings[2].selectOptions = formattedEpisodeList;
            tempConfig.headings[4].selectOptions = [...this.state.formattedCategoryList];
            if (canGenerateCheckReq.length < 0) {
                let tempConfig = showComposerConfig;
                let payeeIcon = [{column: "show_season_music_supervisor_check_requests_id",
                    icon: "pen-square"} ];
                let tempNodeFields = tempConfig.nodeFields.map(item => {
                    if (item.icon === payeeIcon[0]?.icon) {
                       return item={column:item.column,icon:" "}
                    } else {
                        return item
                    }
                })
                showComposerConfig.nodeFields = tempNodeFields;
                this.setState({ config: showComposerConfig })
            } else {
                this.setState({ config: showComposerConfig })
            }
            if (canEditMsSCDealCheckReq === false) {
                let tempConfig = showComposerConfig;
                let actionsToRemove = ["pen", "times"];
                let tempActions = tempConfig.actions.filter(item => !actionsToRemove.includes(item));
                showComposerConfig.actions = tempActions;
                this.setState({ config: showComposerConfig })
            } else {
                this.setState({ config: showComposerConfig })
            }
        }
        if (item?.people_type === "MUSIC_SUPERVISOR") {
            tempConfig = { ...musicSupervisorConfig };
            tempConfig.headings[2].selectOptions = formattedEpisodeList;
            tempConfig.headings[4].selectOptions = [...this.state.formattedCategoryList];
            if (canEditMsSCDealCheckReq === false) {
                let tempConfig = showComposerConfig;
                let actionsToRemove = ["pen", "times"];
                let tempActions = tempConfig.actions.filter(item => !actionsToRemove.includes(item));
                showComposerConfig.actions = tempActions;
                this.setState({ config: showComposerConfig })
            } else {
                this.setState({ config: musicSupervisorConfig });
            }

        }
        if (canEditMsSCDealCheckReq === true || canEditMsSCDealCheckReq === false) {
            this.fetchTableData("musicSupervisorCheckRequest", `/showSeasonMusicSupervisorCheckRequests?show_season_id=${this.state.show_season_id}&show_season_people_id=${item.show_season_people_id}`, item.people_type);
        }

        this.fetchDealData(item.show_season_people_deal_id);
    }

    deleteData = () => {
        if (dataToDelete.people_type === "SHOW_COMPOSER") {
            this.setState({ isSubmit: true, isFetchingShowComposer: true });
        }
        if (dataToDelete.people_type === "MUSIC_SUPERVISOR") {
            this.setState({ isSubmit: true, isFetchingMusicSupervisor: true });
        }

        clearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=show_season_business_affairs&tableId=${dataToDelete.show_season_people_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(res => {
            if (res.data[0].status === "SUCCESS") {
                if (detailsToDisplay?.show_season_people_id === dataToDelete?.show_season_people_id) {
                    detailsToDisplay = {};
                    this.setState({ renderList: [] })
                }
                dataToDelete.is_delete = 1;
                dataToDelete.show_season_id = this.props?.show_season_id;
                dataToDelete.season_id = this.props?.season_id;
                dataToDelete.show_id = this.props?.show_id;
                clearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/people?people_id=${dataToDelete.people_id}`, dataToDelete, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
                    .then((response) => {
                        if (response.data.error) {
                            this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
                            this.notificationComponent(true, "fail");
                        } else {
                            this.notificationComponent(true, "success");
                            this.fetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.state.show_season_id}`, true);
                            this.setState({ isSubmit: false, showDeleteModal: false, isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
                        }
                    },
                        (err) => {
                            this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
                            this.notificationComponent(true, "fail");
                            console.log("Post agency details error: " + err);
                        });
            } else {
                this.notificationComponent(true, "checkRelationalFailure");
                this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
            }
        }),
            ((err) => {
                this.notificationComponent(true, "fail");
                this.setState({ isSubmit: false, showDeleteModal: true, isFetchingShowComposer: false, isFetchingMusicSupervisor: false });
            });
    };
    notificationComponent = (value, mode = "fail") => {
        console.log("mode in notification comp", mode, value);
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
            case "checkRelationalFailure": return this.setState({ checkRelationalFailure: value });
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false, checkRelationalFailure: false });
        // if (this.state.saveFlag) {

        // }
    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false, checkRelationalFailure: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            BusinessTabList = [{ label: "Details", value: 1 }];
            canEditMsSCDealCheckReq = permissions.filter(obj => obj.permission_name === "Show Composer/Music Supervisors" && obj.permission_group_name === "SS - Business Affairs" && obj.view === 1 && obj.edit === 1);
            if (canEditMsSCDealCheckReq.length > 0) { BusinessTabList.push({ label: "Deal", value: 2 }); }
            canEditMsSCDealCheckReq = canEditMsSCDealCheckReq.length > 0 ? true : false;
            this.setState({ BusinessTabList: BusinessTabList });


            canGenerateCheckReq = permissions.filter((obj, index) => obj.permission_name === "Check Requests Table");
            // canGenerateCheckReq = canGenerateCheckReq.length > 0 ? true : false;

            generateCheckReqPermissions = permissions.filter((obj, index) => obj.permission_name === "Generate check requests from table");
            viewDealMemoPDF = permissions.filter((obj, index) => obj.permission_name === "Deal Details: Deal Memo PDF(s)" && obj.view === 1);
            viewDealMemoPDF = viewDealMemoPDF.length > 0 ? true : false;

        }
    }
    getPayee = (people_Id) => {
        let entitytype = "SC_MS_PAYEE";
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${people_Id}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                let payees = response?.data?.map(item => ({
                    label: item.payee,
                    value: item.payee_remit_to_id,
                    is_active: item.is_active
                }));
                this.setState({ payeeRecordOptions: payees }, () => {
                    let tempPayeeData = { ...this.state.config }
                    tempPayeeData.headings[1].selectOptions = payees;
                    this.setState({ config: tempPayeeData })
                    // let tempConfig = { ...musicSupervisorConfig };
                    // tempConfig.headings[1].selectOptions = payees;
                    // this.setState({ config: tempConfig })
                })

            })
    }
    getEntity = (searchString, nodeName) => {
        let entitytype;
        switch (nodeName) {
            case 'payee':
                entitytype = "PAYEE";
                break;
            case 'episode':
                entitytype = "EPISODES";
                break;
        }
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (entitytype === "PAYEE") {
                    let payees = response?.data?.map(item => ({
                        text: item.payee_company,
                        value: item.payee_id,
                        is_active: item.is_active
                    }));
                    let tempPayeeData = { ...this.state.config }
                    tempPayeeData.headings[1].selectOptions = payees;
                    this.setState({
                        payeeOptions: payees,
                        config: tempPayeeData
                    })
                }
            })
    }
    handleNotesChange = (field, value) => {
        console.log("tempNotesData", this.state.tempNotesData);
        // let tempRenderList = { ...this.state.renderList };
        // tempNotesData
        // tempRenderList.filter((item => item.show_season_music_supervisor_check_requests_id === crObjToEdit.show_season_music_supervisor_check_requests_id));
        // let notesData = { ...this.state.initialRecordData };
        // notesData[field] = value;
        this.setState((prevState) => ({
            tempNotesData: {
                ...prevState.tempNotesData,
                [field]: value
            }
        }))
    }
    submitNotes = (field) => {
        this.setState({ isPostingNotes: true });
        let dataToUpdate = { ...this.state.tempNotesData };
        if (dataToUpdate?.req_date !== null && dataToUpdate?.req_date !== undefined) {
            dataToUpdate.req_date = moment(dataToUpdate.req_date).format('YYYY-MM-DD');
        } else {
            dataToUpdate.req_date = null;
        }
        if (dataToUpdate?.date_paid !== null && dataToUpdate?.date_paid !== undefined) {
            dataToUpdate.date_paid = moment(dataToUpdate.date_paid).format('YYYY-MM-DD');
        } else {
            dataToUpdate.date_paid = null;
        }
        console.log("dataToUpdate in submit", dataToUpdate);
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonMusicSupervisorCheckRequests`,
            dataToUpdate,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id).then(
                (response) => {
                    this.setState({ isPostingNotes: false });
                    if (response.data.error) {
                        this.setState({ isTableLoading: false });
                        this.fetchTableData("musicSupervisorCheckRequest", `/showSeasonMusicSupervisorCheckRequests?show_season_id=${this.state.show_season_id}&show_season_people_id=${detailsToDisplay?.show_season_people_id}`);
                        this.notificationComponent(true, "fail");
                    } else {
                        //this.props?.fieldChanged(true, "success");
                        this.fetchTableData("musicSupervisorCheckRequest", `/showSeasonMusicSupervisorCheckRequests?show_season_id=${this.state.show_season_id}&show_season_people_id=${detailsToDisplay?.show_season_people_id}`);
                        this.setState({
                            setPopoverFile: null,
                            popoverColumnClicked: null,
                            isTableLoading: false,
                            showDeleteModal: false
                        })
                        this.notificationComponent(true, "success");
                    }
                },
                (err) => {
                    this.setState({ isTableLoading: false });
                    this.notificationComponent(true, "fail");
                    console.log("Post budget details error: " + err);
                }
            );
    }
    downloadCheckRequest = () => {
        this.setState({ isSubmitLoading: this.state.tableRecord?.show_season_music_supervisor_check_requests_id });
        let reportName = "rptShowSeasonMusicSupervisorCheckRequestLetter";
        let searchPayload = {
            "show_season_id": this.state.tableRecord.show_season_id,
            "show_season_music_supervisor_check_requests_id": this.state.tableRecord.show_season_music_supervisor_check_requests_id,
            "generate_letter": 1,
            "type": this.state.tableRecord?.cost_center_name,
            "isOpenExisting": this.state.isOpenExisting ? 1 : 0
        }
        this.downloadReport(reportName, searchPayload)
            .then(webSocketConn => {
                webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data)
                    if (response?.statusCode == 200) {
                        this.downloadUrl(response?.body?.url, response?.body?.filename);
                        this.notificationComponent(true, 'success')
                        this.setState({ isSubmitLoading: false });
                    }
                    // } else {
                    //     // this.notificationComponent(true, 'fail')
                    //     this.setState({ isSubmitLoading: false });
                    // }
                }
            })
            // .catch((err) => {
            //     this.setState({ isSubmitLoading: false });
            //     this.notificationComponent(true, 'fail')
            // })

    }
    downloadReport = (reportName, searchPayload) => {
        if (!searchPayload || !reportName) {
            return;
        }
        Object.keys(searchPayload).map(obj => {
            if (searchPayload[obj] === undefined) {
                searchPayload[obj] = null;
            }
        })
        let encryptTedStr = btoa(JSON.stringify(searchPayload));
        let load = {}
        load = {
            'payload': 'exportDocx',
            'reportName': reportName,
            //'fileName':null,
            'searchJson': encryptTedStr,
            'showHtml': 0,
            'encryptionType': 1,
            'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
            'divisionId': this.props.division_id,
        }
        return clearTrackService.getDataWS(load)

    }
    downloadUrl = (url, reportName) => {
        if (url) {
            fetch(url).then(response => response.blob()).then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', reportName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }
    closePayeeModal = () => {
        this.setState({ showPayeeModal: false });
    }
    handleDefaultValuePopulation = (value,nodeName, obj) => {
        if (nodeName === 'show_season_episode_id' && value) {
            let check = this.props.dataList?.find((item) => item?.show_season_episode_id === value)
            let checkAmtValue = [];
            let checkAmt = {
                node: "check_amount",
                value: detailsToDisplay.people_type === "SHOW_COMPOSER"?check?.sc_budget_cost:check?.ms_budget_cost
            }
            checkAmtValue.push(checkAmt);
            let invNotes = {
                node: "inv_notes",
                value: `${this.props?.initialRecordData?.show_name} ${this.props?.initialRecordData?.seasons?.find(item => item?.season_id === this.props?.initialRecordData?.season_id)?.season_name}-${check?.episode_number}`
            }
            checkAmtValue.push(invNotes);
            if(obj?.category_id) {
                let invNumber = {
                    node: "inv_number",
                    value: `${check?.episode_number ? check?.episode_number?.slice(-4) : ''}${this.state.config?.headings[4]?.selectOptions?.find(item => item?.value === obj?.category_id)?.abbr}`
                } 
                checkAmtValue.push(invNumber); 
            }
            return checkAmtValue;
        } else if(nodeName === 'category_id' && value && obj?.show_season_episode_id){
            let check = this.props.dataList?.find((item) => item?.show_season_episode_id === obj?.show_season_episode_id)
            let checkAmtValue = [];
            let invNumber = {
                node: "inv_number",
                value: `${check?.episode_number ? check?.episode_number?.slice(-4) : ''}${this.state.config?.headings[4]?.selectOptions?.find(item => item?.value === obj?.category_id)?.abbr}`
            } 
            checkAmtValue.push(invNumber);
            return checkAmtValue;
        }
        return []
    }

    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
          this.setState({ sortCount: this.state.sortCount + 1 }, () => {
            this.handleTableSort(column);
          })
        }
        else this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        })
    }
    
    handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            sortBy: colName,
            config: updateTableSortConfigObject(this.state?.config, this.state.isSortByAsc, this.state.sortBy, colName),
            renderList: tableSortList(colName === 'Active' ? 'Number':"Name", this.getSortNode(colName), this.state?.renderList, this.state.isSortByAsc)
          });
        })
    }
    
    getSortNode = (col) => {
        switch (col) {
            case "Payee": return "payee_remit_to_id";
            case "Episode": return "show_season_episode_id";
            case "Check Amt": return "check_amount";
            case "Category": return "category_id";
            case "Cost Center": return "cost_center_code";
            case "Req.Dt": return "req_date";
            case "Inv#": return "inv_number";
            case "Check Req": return "bch";
            case "Dt.Paid": return "date_paid";
            case "Packet?": return "is_packet";
            case "SR?": return "is_sr";
            case "Score?": return "is_score";
            case "Inv.notes": return "inv_notes";
            case "Notes": return "notes";
        }
    }

    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : this.state.checkRelationalFailure ? "error" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : this.state.checkRelationalFailure ? "You can not delete this Record because it is being used" : "";
        let openFile = Boolean(this.state.setPopoverFile);
        return (
            <MDBCol className="BusinessAffairs-Container">
                {this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (<>
                    <NotificationComponent open={this.state.showSuccess || this.state.showFail || this.state.checkRelationalFailure} message={messageNotification} severity={severity}
                        handleResetNotify={this.handleResetNotify.bind(this)} />

                    <MDBRow className="buget-card-p">
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardText>
                                    <MDBRow>
                                        <MDBCol md={3}>
                                            <MDBRow className="budget-text">
                                                <div className="SCHeading">
                                                    <BasicLabel text={"Show Composer"} />
                                                </div>
                                                <div className="showComposerWrapper">
                                                    {this.state.isFetchingShowComposer ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <CircularProgress size={24} />
                                                        </div>
                                                    ) : (<>
                                                        <div className="showComposerContainer" id="showComposerContainer">

                                                            {!noShowComposerPresent ? <>{this.state.businessAffairsDetailsData.map((item, index) => (<>{
                                                                item.people_type == "SHOW_COMPOSER" ? (<div key={"SHOW_COMPOSER" + index} id={"SHOW_COMPOSER" + index} className="sub-text showComposerList">
                                                                    <p className={item.isClicked ? "selected" : "font-color"} onClick={() => this.displayDetails(item, index) }>{item.full_name} </p>
                                                                    {canEditMsSCDealCheckReq ?
                                                                        <span className="icon-css">
                                                                            <span className="ProfileIcon" onClick={() => this.handleShowShowComposer("Edit", "Show Composer", item.people_id, item.show_season_people_id, "profile", item, index)}> Profile</span>
                                                                            <MDBIcon icon="pen" onClick={() => this.handleShowShowComposer("Edit", "Show Composer", item.people_id, item.show_season_people_id, null, item, index)} />
                                                                            <MDBIcon icon="times" onClick={() =>
                                                                                this.openDeleteModal(item, "deleteArtist")} />
                                                                        </span> : null}
                                                                </div>) : null}</>
                                                            ))}
                                                            </> : <div className="noRecordsPresent"><p className="font-color">None Added</p>
                                                                    {canEditMsSCDealCheckReq && <div className="createNewArtistRow">
                                                                        <div><p onClick={(e) => this.openAddSCModal("Show Composer", "BusinessAffairs")}>Add SC</p></div><div><p onClick={(e) => this.handleShowShowComposer("New", "Show Composer", null, null, "BusinessAffairs")}>Create New</p></div>
                                                                    </div>}
                                                                </div>}


                                                        </div>
                                                        {!noShowComposerPresent && canEditMsSCDealCheckReq && < div className="createNewArtistRow">
                                                            <div><p onClick={(e) => this.openAddSCModal("Show Composer", "BusinessAffairs")}>Add SC</p></div><div><p onClick={(e) => this.handleShowShowComposer("New", "Show Composer", null, null, "BusinessAffairs")}>Create New</p></div>
                                                        </div>}
                                                    </>
                                                        )}
                                                    {/* </div> */}

                                                </div>
                                            </MDBRow>
                                            <MDBRow className="budget-text">
                                                <div className="MSHeading">
                                                    <BasicLabel text={"Music Supervisor"} />
                                                </div>
                                                <div className="showComposerWrapper">
                                                    {this.state.isFetchingMusicSupervisor ? (
                                                        <div style={{ textAlign: "center" }}>
                                                            <CircularProgress size={24} />
                                                        </div>
                                                    ) : (<>
                                                        <div className="showComposerContainer" id="showComposerContainer">
                                                            {!nomusicSupervisorPresent ? <>{this.state.businessAffairsDetailsData.map((item, index) => (<>
                                                                {item.people_type == "MUSIC_SUPERVISOR" ? (<div key={"MUSIC_SUPERVISOR" + index} id={"MUSIC_SUPERVISOR" + index} className="sub-text showComposerList">
                                                                    <p className={item.isClicked ? "selected" : "font-color"} onClick={() => this.displayDetails(item, index)}>{item.full_name} </p>
                                                                    {canEditMsSCDealCheckReq ?
                                                                        <span className="icon-css">
                                                                            <span className="ProfileIcon" onClick={() => this.handleShowShowComposer("Edit", "Music Supervisor", item.people_id, item.show_season_people_id, "profile", item, index)}> Profile</span>
                                                                            <MDBIcon icon="pen" onClick={() => this.handleShowShowComposer("Edit", "Music Supervisor", item.people_id, item.show_season_people_id, null, item, index)} />
                                                                            <MDBIcon icon="times" onClick={() =>
                                                                                this.openDeleteModal(item, "deleteArtist")} />
                                                                        </span> : null}
                                                                </div>) : null}
                                                            </>))}</> : <div className="noRecordsPresent"><p className="font-color">None Added</p>
                                                                    {canEditMsSCDealCheckReq && <div className="createNewArtistRow">
                                                                        <div><p onClick={(e) => this.openAddSCModal("Music Supervisor", "BusinessAffairs")}>Add MS</p></div><div><p onClick={(e) => this.handleShowShowComposer("New", "Music Supervisor", null, null, "BusinessAffairs")}>Create New</p></div>
                                                                    </div>}
                                                                </div>}
                                                        </div>
                                                        {!nomusicSupervisorPresent && canEditMsSCDealCheckReq && <div className="createNewArtistRow">
                                                            <div><p onClick={(e) => this.openAddSCModal("Music Supervisor", "BusinessAffairs")}>Add MS</p></div><div><p onClick={(e) => this.handleShowShowComposer("New", "Music Supervisor", null, null, "BusinessAffairs")}>Create New</p></div>
                                                        </div>}
                                                    </>)}
                                                </div>
                                            </MDBRow>
                                        </MDBCol>
                                        <MDBCol md={9} id={"Business-tab"}>
                                            <TabsComponent
                                                tabList={this.state.BusinessTabList}
                                                value={this.state.tabValue}
                                                onChange={(e, v) => this.handleTabChange(v)}
                                            />
                                            {this.getTabComponent()}
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBRow>
                    <MDBRow>
                        {canGenerateCheckReq[0]?.view === 1 && <div className="header-cue">
                            {detailsToDisplay?.full_name ? (<h6>Check Requests for {detailsToDisplay.full_name} {this.state.config?.headings[1]?.selectOptions?.length === 0 && `(Please link payee with ${detailsToDisplay.full_name} to view/add check request)`}</h6>) : <h6>Select a Show Composer or Music Supervisor to view Check Requests</h6>}
                        </div>}
                        <div className="errorText">{this.state.mandatoryNotExist ?  `Please enter Payee  field value` :  this.state.duplicateValues ? 'Check request for selected episode and category already exists' : ''}</div>
                        {canGenerateCheckReq[0]?.view === 1 &&
                        <>
                        {(this.state.isTableLoading) ? (
                            <div style={{ textAlign: "center" }}>
                                <CircularProgress size={24} />
                            </div>
                        ) : (<>
                            <div id="cuesheet-table">
                                <div className="cueSheetTable" id="business-table-bg">
                                    {(detailsToDisplay?.people_type && this.state.formattedCategoryList.length > 0 && this.state.config?.headings[1]?.selectOptions?.length > 0) &&
                                        <> <TableComponent
                                            list={this.state.renderList || []}
                                            className={"clearencRecord"}
                                            config={this.state.config || {}}
                                            editCallback={canGenerateCheckReq[0]?.edit === 1 ? this.handleInlineEdits.bind(this) : false}
                                            saveCallback={this.saveInlineEdits.bind(this)}
                                            addItemToList={canGenerateCheckReq[0]?.edit === 1 ? detailsToDisplay?.show_season_people_id && (!noShowComposerPresent || !nomusicSupervisorPresent) ? true : false : false}
                                            handleAddNewItem={canGenerateCheckReq[0]?.edit === 1 ? this.handleAddNewItem.bind(this) : false}
                                            handleDelCallBack={(id, item) => canGenerateCheckReq[0]?.delete === 1 ?
                                                this.openDeleteModal(item, "deleteCR") : null
                                            }
                                            stickyHeader={true}
                                            handleValuePopulation={this.handleDefaultValuePopulation.bind(this)}
                                            readCallback={this.handleReadCallback.bind(this)}
                                            // searchString={(value, nodeName) => this.getEntity(value, nodeName)}
                                            handlePopoverClick={this.handleClickFile.bind(this)}
                                            handleEditPayeeModal={(e, value) => this.handleEditPayeeModal.bind(this, value)}
                                            postInitiated={this.state.isSubmitLoading}
                                            sortCallback={this.handleTableColumnClick}
                                            handleBCHModal={(e, value) => generateCheckReqPermissions[0]?.view === 1 ? this.handleShowExcelAndDocModal.bind(this, value) : null}
                                        // tableMode={"light"}
                                        // isLoading={this.props.isLoading || false}
                                        // hyperLinkNavigationCallback={this.navigationCallback}
                                        // episodeCallback={this.handleChangePage}
                                        // sortCallback={this.handleTableColumnClick} 
                                        />
                                            <Popover
                                                open={openFile}
                                                anchorEl={this.state?.setPopoverFile}
                                                onClose={this.handleCloseFile}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        width: '30%',
                                                        backgroundColor: '#F2F2F2',
                                                        borderRadius: '1px',
                                                        fontSize: '0.8rem',
                                                        fontWeight: '300',
                                                    }
                                                }}
                                            >
                                                {/* <p className='content' style={{ wordBreak: "break-all" }}>{popoverNotes}</p> */}
                                                <BasicTextArea label={this.state.popoverColumnClicked === "notes" ? "Notes" : "Invoice Notes"} value={this.state.popoverColumnClicked === "notes" ? this.state.tempNotesData?.notes : this.state.tempNotesData?.inv_notes} onChange={(e) => this.handleNotesChange(this.state.popoverColumnClicked, e.target.value)} />
                                                <MDBRow className="mt-2">
                                                    <MDBCol md={8} ></MDBCol>
                                                    <MDBCol md={4} className={"saveCancelWrapper"}>
                                                        <div>
                                                            <BasicButton variant="contained" text={this.state.isPostingNotes ? <CircularProgress color="inherit" size={20} /> : " "} icon={this.state.isPostingNotes ? " " : "save"}
                                                                onClick={() => this.submitNotes(this.state.popoverColumnClicked)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <BasicButton variant="outlined" icon={"times"} text=" " onClick={this.handleCloseFile} />
                                                        </div>
                                                    </MDBCol>
                                                </MDBRow>
                                            </Popover></>}
                                </div>
                            </div>
                        </>)} 
                        </>}
                    </MDBRow>

                </>)
                }
                {/* delete Confirmation modal */}
                {
                    this.state.showDeleteModal &&
                    <MessageModal
                        title={"Confirm Deletion"}
                        open={this.state.showDeleteModal}
                        message={"Are You Sure To Delete?"}
                        primaryButtonText={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : "Yes"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={(deletionFlag === "deleteArtist" && this.deleteData) || (deletionFlag === "deleteCR" && this.deleteTableRow)}
                        handleClose={this.closeDeleteModal} />
                }
                {/** will get seasonId, showId,showSeasonId from business Affairs get API */}
                {
                    this.state.showShowComposer && this.state?.show_season_id && (
                        <MainViewModalComponent
                            open={this.state.showShowComposer}
                            handleClose={this.handleHideShowComposer}
                            headerText={this.state.modeType}
                            mode={this.state.mode}
                            modeType={this.state.modeType}
                            modalName={this.state.modeType}
                            show_season_id={this.state.show_season_id}
                            show_id={this.props.show_id}
                            season_id={this.props.season_id}
                            people_id={this.state.people_id}
                            show_season_people_id={this.state.show_season_people_id}
                            division_id={this.props.division_id}
                            fetchData={this.fetchData.bind(this)}
                            showSecondtab={this.state.origin === "profile" ? true : false}
                            origin={this.state.origin}
                            commonPermissions={this.props.permissionArr}
                            handleShareClick={(event, dataItem) => this.handleShareClick(event, dataItem, this)}
                            handleHideShowComposer={this.handleHideShowComposer.bind(this)}
                            linkedMsScName={this.state.mode === "Edit" ? detailsToDisplay?.full_name : null}
                            checkReqData={this.state.renderList}
                            notificationComponent={this.notificationComponent.bind(this)}
                        />
                    )
                }
                {
                    this.state.showADDSCModal && this.state?.show_season_id && (
                        <MainViewModalComponent
                            open={this.state.showADDSCModal}
                            handleClose={this.closeAddSCModal}
                            headerText={`Add a ${this.state.modeType}`}
                            mode={"Both"}
                            modeType={this.state.modeType}
                            modalName={"Add ShowComposer"}
                            show_season_id={this.state.show_season_id}
                            show_id={this.props.show_id}
                            season_id={this.props.season_id}
                            people_id={this.state.people_id}
                            division_id={this.props.division_id}
                            fetchData={this.fetchData.bind(this)}
                            peopleDetailsData={this.state.businessAffairsDetailsData}
                            origin={this.state.origin}
                        />
                    )
                }
                {
                    this.state.showEditSeasonModal &&
                    <MainViewModalComponent
                        open={this.state.showEditSeasonModal}
                        handleClose={() => this.setState({ showEditSeasonModal: false, showSesonIdToEdit: null })}
                        headerText={"Show Details"}
                        mode={"Edit"}
                        modalName={"Edit ShowDetails"}
                        show_season_id={this.state.showSesonIdToEdit}
                        fetchData={() => this.setState({ showEditSeasonModal: false, showSesonIdToEdit: null })}
                        permissionArr={this.props.permissionArrDetailsTab}
                    />
                }
                {
                    this.state.showPayeeModal &&
                    <MainViewModalComponent
                        open={this.state.showPayeeModal}
                        handleClose={this.closePayeeModal.bind(this)}
                        headerText={"Payee"}
                        mode={"Edit"}
                        modalName={"Payee"}
                        id={this.state.selectedPayeeId}
                        commonPermissions={this.state.permissionArr}
                        modeType={(detailsToDisplay.people_type === "SHOW_COMPOSER") ? "SHOW_COMPOSER" : "MUSIC_SUPERVISOR"}
                        linkedMsScName={detailsToDisplay?.full_name}
                        origin="BusinessAffairs"
                        getPayee={this.getPayee.bind(this)}
                        peopleId={this.state.peopleId}
                        show_season_id={this.state.show_season_id}
                        show_season_people_id={detailsToDisplay?.show_season_people_id}
                        fetchTableData={this.fetchTableData.bind(this)}
                    />
                }
                 {this.state.showExcelAndDocModal && (
                 <>
              <MainViewModalComponent
                open={this.state.showExcelAndDocModal}
                handleClose={this.handleHideExcelAndDocModal}
                headerText={"Generate Excel/DOC"}
                mode={" "}
                modalName={"Excel And Doc Modal"}
                downloadExcel={this.downloadCheckRequest}
                isOpenExisting = {this.isOpenExisting.bind(this)}
                isExcelPosting={this.state.isSubmitLoading}

              />
            </>
          )}

            </MDBCol >
        )
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state || nextProps !== this.props) {
            return true;
        }
        else { return false; }
    }
    componentDidUpdate(prevProps) {
        if (this.props.payIncreaseList !== prevProps.payIncreaseList) {
            this.props.payIncreaseList.map((item, index) => {
                if (item.people_id === detailsToDisplay.people_id) {
                    this.fetchDealData(detailsToDisplay.show_season_people_deal_id);
                }
            })
        }
    }
    componentDidMount() {
        this.getPermissions(this.props.permissionArr);
        detailsToDisplay = {};
        if (this.state?.show_season_id) {
            this.onLoadFetchData("costCenterOptions_SHOW_COMPOSER", `/entitySearch?entity=COST_CENTER&searchString=SHOW_COMPOSER`);
            this.onLoadFetchData("costCenterOptions_MUSIC_SUPERVISOR", `/entitySearch?entity=COST_CENTER&searchString=MUSIC_SUPERVISOR`);
            this.onLoadFetchData("episodeOptions", `/showSeasonEpisodes?show_season_id=${this.state.show_season_id}`);
            this.onLoadFetchData("categoryOptions", `/entitySearch?entity=CATEGORY&searchString=null`);
            this.onLoadFetchData("businessAffairsDetailsData", `/showSeasonBusinessAffairs?show_season_id=${this.state.show_season_id}`);
        }
    }
}
export default withUserContext(BusinessAffairs);