import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import BasicTextField from "../../../components/SharedComponents/BasicTextField/BasicTextField";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import BasicTextArea from "../../../components/SharedComponents/BasicTextArea/BasicTextArea";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import "./MusicReplacementReport.scss";
import TableComponent from "../../../components/SharedComponents/Table";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import { withUserContext } from "../../../contexts/UserContext";
import {
  agentListConfig,
  allContactList,
  reportConfig,
  allreportList,
  initialMusicReplacementDetails,
} from "../Reports/config";
import Radio from "@material-ui/core/Radio";
import DateField from "../../../components/SharedComponents/DateField/DateField";
import ShowSeason from "./ShowSeason";
import Chip from "@material-ui/core/Chip";
import { s2ab, genReportName } from '../../../Common/Helper';
class MusicReplacementReport extends Component {
  constructor() {
    super();
    this.state = {
      postInitiated: false,
      renderList: allContactList,
      config: agentListConfig,
      renderList1: allreportList,
      config1: reportConfig,
      musicReplacementDomainDetails: initialMusicReplacementDetails,
      seasonOptions: [],
      showOptions: [],
      filterValues: [],
      removedItem: null,
      seasonIdValues: [],
      showIdValues: [],
      options: [],
      removedItem: null,
      optionIdValues: [],
      isPosting: false,
      isClearFlag: false,
      found: [],
      rightOption: [],
      showOption:[]
    };
  }

  componentDidMount() {
    if (this.props?.userContext?.reportTabData?.length > 0) {
      this.setState({
        filterValues: [this.props?.userContext?.reportTabData[1], this.props?.userContext?.reportTabData[2]],
        showIdValues: [this.props?.userContext?.reportTabData[3]],
        seasonIdValues: [this.props?.userContext?.reportTabData[4]]
      })
    }
  }


  handleChange = (field, value) => {
    this.setState((prevState) => ({
      musicReplacementDomainDetails: {
        ...prevState.musicReplacementDomainDetails,
        [field]: value,
      },
    }));
  };

  handleFilterValues = (filterValues) => {
    let values = this.state.filterValues;
    values = filterValues
    this.setState({
      filterValues: values
    })
  }

  setSeasonId = (seasonIdValues) => {
    let seasonIdValue = this.state.seasonIdValues;
    seasonIdValue = seasonIdValues;
    this.setState({
      seasonIdValues: seasonIdValue
    })
  }

  setShowId = (showIdValues) => {
    let showIdValue = this.state.showIdValues;
    showIdValue = showIdValues;
    this.setState({
      showIdValues: showIdValue
    })
  }

  handleFilterDelete = (removeNode, index) => {
    let filterValues = this.state.filterValues;
    let removedItem = filterValues.splice(index, 1);
    this.setState({ filterValues: filterValues, removedItem: removeNode }, () => {
      let splitVal = this.state.removedItem.split(":");
      let name = splitVal[0].trim();
      let valueId = splitVal[1].trim();
      if (name === "Season") {
        let filterSeasonIdValues = this.state.found.find(item => item.label === valueId).value
        let index2 = this.state.seasonIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.seasonIdValues
        id.splice(index2, 1);
        this.setState({seasonIdValues: id})
      }
      else if (name === 'Show') {
        let filterSeasonIdValues = this.state.showOption.find(item => item.text === valueId).value
        let index2 = this.state.showIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.showIdValues
        id.splice(index2, 1);
        this.setState({showIdValues: id})
      }
      else if (name === "Options") {
        let filterSeasonIdValues = this.state.rightOption.find(item => item.text === valueId).value
        let index2 = this.state.optionIdValues.indexOf(filterSeasonIdValues)
        let id = this.state.optionIdValues
        id.splice(index2, 1);
        this.setState({optionIdValues: id})
      }
    });
  }

  seasonValues = (seasonValue) => {
    this.setState({found:seasonValue})
  }

  showValues = (showValue) => {
    let backup = this.state.showOption
    backup = backup.concat(showValue)
    this.setState({showOption:backup})
  }

  rightOptionValues=(rightOptionValue) => {
    this.setState({rightOption:rightOptionValue})
  }

  handleClearFilters = () => {
    let values = this.state.filterValues;
    values = [];
    this.setState({ filterValues: values, musicReplacementDomainDetails : {...initialMusicReplacementDetails},seasonIdValues:[], showIdValues:[] });
  }

  handleSubmitMusicReplacementReport = () => {
    this.setState({ postInitiated: true, isPosting: true });
    let { musicReplacementDomainDetails, filterValues, seasonIdValues, showIdValues } = this.state;
    let payload = {
      include: musicReplacementDomainDetails?.selectedValue === "include" ? 1 : 0,
      exclude: musicReplacementDomainDetails?.selectedValue === "include" ? 0 : 1,
      display_criteria: musicReplacementDomainDetails?.is_display_criteria_report ? 1 : 0,
      season_ids: seasonIdValues,
      show_ids: showIdValues
    }
    let reportName = "rptMusicReplacement";
    let reportDownloadName = "Music Replacement"
    this.props.generateReport(reportName, payload, reportDownloadName).then(webSocketConn => {
      webSocketConn.onmessage = (e) => {
        let response = JSON.parse(e.data)
        if (response?.statusCode == 200) {
          this.setState({ postInitiated: false })
          this.props?.downloadUrl(response?.body);
          // this.props?.notificationComponent(true, 'success')
        } else {
          this.setState({ postInitiated: false })
          // this.props?.notificationComponent(true, 'fail')
        }
      }
    })
  }

  GenerateCleranceDetails = () => { };
  render() {
    return (
      <>
        {" "}
        <p>
          <b>Music Replacement Report</b>
        </p>
        <MDBRow className={this.state.filterValues?.length > 0 ? "columnFilters d-block" : "d-none"}>
          <div style={{ display: "flex" }}>
            {this.state.filterValues &&
              this.state.filterValues?.map((item, index) => (
                <Chip
                  label={item}
                  onDelete={this.handleFilterDelete.bind(
                    this,
                    item,
                    index
                  )}
                // filterValues={this.state.filterValues}
                />
              ))}
          </div>
        </MDBRow>
        <MDBContainer
          style={{ background: "white" }}
          fluid
          className="MusicReplacementReport-Container"
        >
          <MDBRow>
            <MDBCol md={2}>
              <br />
              <BasicCheckbox
                id="Subtitle"
                label={"Display Criteria on Report"}
                checked={
                  this.state.musicReplacementDomainDetails
                    ?.is_display_criteria_report || false
                }
                onChange={(e) =>
                  this.handleChange(
                    "is_display_criteria_report",
                    e.target.checked
                  )
                }
              />
            </MDBCol>
            <MDBCol md={7}>
              <ShowSeason
                onChangeCallBack={this.handleFilterValues}
                removedItem={this.state.removedItem}
                // setSeasonId={this.setSeasonId}
                // setShowId={this.setShowId} 
                filterSeasonIdValues={this.state.seasonIdValues}
                filterShowIdValues={this.state.showIdValues}
                filterValues={this.state.filterValues} 
                seasonValues={this.seasonValues}
                showValues={this.showValues}/>
            </MDBCol>
            {/* <MDBCol md={3}>
              <BasicLabel text="Seasons" />
              <SearchSelectField
                placeHolderText={"All Seasons"}
                value={{ 'value': this.state.musicReplacementDomainDetails.selected_season?.value, 'text': this.state.musicReplacementDomainDetails.selected_season?.text } || {}}
                // value={this.state.showSeasonDetails.selected_all_season}
                options={this.state.seasonOptions || []}
                onChange={(e, value) => this.handleChange("selected_season", value)}
              />
            </MDBCol>
            <MDBCol md={3}>
              <BasicLabel text="Shows" />
              <SearchSelectField
                id={"All Shows"}
                placeHolderText={"All Shows in selected season(s)"}
                value={{ 'value': this.state.musicReplacementDomainDetails.selected_shows?.value, 'text': this.state.musicReplacementDomainDetails.selected_show?.text } || {}}
                options={this.state.showOptions || []}
                onChange={(e, value) => this.handleChange("selected_shows", value)}
              />
            </MDBCol> */}
            <MDBCol md={2} className="radioButtons">
              <br />
              <Radio
                name="radio-buttons"
                label="name"
                checked={
                  this.state.musicReplacementDomainDetails
                    .selectedValue === "include"
                }
                onChange={(e) =>
                  this.handleChange("selectedValue", e.target.value)
                }
                value="include"
              />
              <span style={{ fontSize: "10px" }}>Include </span>
              <br />

              <Radio
                name="radio-buttons"
                label="name"
                checked={
                  this.state.musicReplacementDomainDetails
                    .selectedValue === "exclude"
                }
                onChange={(e) =>
                  this.handleChange("selectedValue", e.target.value)
                }
                value="exclude"
              />
              <span style={{ fontSize: "10px" }}>Exclude </span>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow>
          <MDBCol md={9}></MDBCol>
          <MDBCol md={3}>
            <MDBRow>
              <MDBCol id="genpdf-btn">
                <br />
                <BasicButton className="efcBtn" text={this.state.postInitiated ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : "Generate PDF"} onClick={this.handleSubmitMusicReplacementReport} />
              </MDBCol>
              <MDBCol>
                <br />
                <BasicButton variant="outlined" text={"Clear Filters"}  onClick={this.handleClearFilters}/>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}

export default withUserContext(MusicReplacementReport);
