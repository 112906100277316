import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import { withUserContext } from '../../../contexts/UserContext';
import CircularProgress from "@material-ui/core/CircularProgress";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import './File.scss';
let fileQueryParam = {}, fileList = [];
export default withUserContext(class UploadFileModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: null,
            fileData: null,
            fileName: [],
            isPosting: false,
            initialRecordData: this.props?.initialRecordData,
            formData: null,
            fileList: [],
        }
    }
    formDataHandler = (fileList) => {
        let totalSize = 0;
        for (let i = 0; i < fileList.length; i++) {
            totalSize = fileList[i].size + totalSize;
        }
        if (totalSize > 4194304) {
            this.setState({ showError: true })
        } else {
            this.setState({ showError: false })
        }
        let formData = new FormData();
        formData.append("divisionId", this.props.division_id);
        formData.append("divisionName", this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name);
        if (this.props?.origin === "GeneralFileModal" || this.props?.origin === "MailHistoryFileModal") {
            formData.append("showName", this.props?.show_name);
            formData.append("season", this.props?.season_name);
            formData.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
            if (this.props?.origin === "GeneralFileModal") {
                formData.append("module", "clearancegeneral");
                //formData.append("General", file);
                for (let i = 0; i < fileList?.length; i++) {
                    formData.append("General", fileList[i]);
                }
                formData.append("episodeName", this.props.episode_name);
            }
            if (this.props?.origin === "MailHistoryFileModal") {
                formData.append("module", "clearanceemailhistory");
                //formData.append("EmailHistory", file);
                for (let i = 0; i < fileList?.length; i++) {
                    formData.append("EmailHistory", fileList[i]);
                }
                formData.append("episodeName", this.props.episode_name);
            }
            formData.append("isBoxUpload", 1);
            fileQueryParam = {
                "showName": this.props?.show_name,
                "season": this.props?.season_name,
                "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                "module": this.props?.origin === "GeneralFileModal" ? "clearancegeneral" : "clearanceemailhistory",
                "isBoxUpload": 1,
                "episodeName": this.props.episode_name,
                'divisionId':this.props.division_id,
                'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
            }
        } else {
            for (let i = 0; i < fileList?.length; i++) {
                formData.append("files", fileList[i]);
            }
            formData.append("showName", this.state?.initialRecordData?.show_name);
            let selectedSeason = this.state.initialRecordData?.seasons?.filter(item => (item.season_id == this.state.initialRecordData.season_id) ? item : null
            );
            selectedSeason = selectedSeason.length > 0 && selectedSeason[0].season_name;
            formData.append("season", selectedSeason);
            formData.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
            formData.append("module", "showseasonfiles");
            formData.append("isBoxUpload", 1);
            fileQueryParam = {
                "showName": this.state?.initialRecordData?.show_name,
                "season": selectedSeason,
                "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                "module": "showseasonfiles",
                "isBoxUpload": 1,
                'divisionId':this.props.division_id,
                'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
            }
        }
        this.setState({ fileData: formData, fileName: fileList.map(item => item.name), formData, fileList });
    }
    deleteFile(file, index) {
        let fileArr = [...this.state.fileName];
        fileArr.splice(index, 1);
        //this.formDataHandler(fileArr);
        let tempFilesList = [];
        this.state.fileList.map((item, index) => {
            fileArr.map((item2, index2) => {
                if (item.name === item2) {
                    tempFilesList.push(item);
                }
            })
        })
        this.formDataHandler(tempFilesList);
        this.setState({ fileName: fileArr, fileList: tempFilesList });
    }
    uploadFile(file) {
        this.setState({ showError: false });
        try {
            fileList = Array.from(file);
            this.formDataHandler(fileList);

        } catch (error) {
            console.log(error)
            this.setState({ fileData: null, fileName: [] });
        }
    }
    handleSubmit = () => {
        this.setState({ isPosting: true });
        let extensionUrl = '/uploadFile';
        clearTrackService.uploadFileFormData(Constants.ClearTrackServiceBaseUrl + extensionUrl, this.state.formData, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isPosting: false });
                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({ isPosting: false });
                    this.props?.notificationComponent(true, "success");
                    if (this.props?.origin === "GeneralFileModal") {
                        let episodeFileObj = {
                            "showName": this.props?.show_name,
                            "season": this.props?.season_name,
                            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                            "isBoxUpload": 1,
                            "module": "clearancegeneral",
                            "episodeName": this.props.episode_name,
                            'divisionId':this.props.division_id,
                            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
                        }
                        this.props.onLoadFetchData(`/getFiles`, episodeFileObj, true);
                    }
                    if (this.props?.origin === "MailHistoryFileModal") {
                        let episodeFileObj = {
                            "showName": this.props?.show_name,
                            "season": this.props?.season_name,
                            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                            "isBoxUpload": 1,
                            "module": "clearanceemailhistory",
                            "episodeName": this.props.episode_name,
                            'divisionId':this.props.division_id,
                            'divisionName': this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== this.props.division_id)?.division_name
                        }
                        this.props.onLoadFetchData(`/getFiles`, episodeFileObj, false);
                    }
                    if (this.props?.origin === "Files") {
                        this.props.triggerGetFileData(true);
                    }
                    console.log("post response", response);
                }
            },
                (err) => {
                    this.setState({ isPosting: false });
                    this.props?.notificationComponent(true, "fail");
                    console.log("Post agency details error: " + err);
                });
    }
    render() {
        return (
            <MDBContainer fluid className="File-Upload-Modal">
                <MDBRow>
                    <BasicLabel text={"Select File"} />
                    <MDBCol md={3}>
                        <BasicButton
                            variant="outlined"
                            // icon="paperclip"
                            text="Add File"
                            type="inline"
                            inputType="file"
                            multiple={true}
                            id={'uploadseaonfile'}
                            uploadFile={(file) => this.uploadFile(file)}
                        />
                    </MDBCol>
                    <MDBCol md={3}><BasicLabel text={"(upto 4mb)"} /></MDBCol>
                </MDBRow>
                <span className="filename">{this.state.fileName?.map((item, index) => <div className="files-display" key={"file" + index}>
                    <span className="file-text-normal">{item}</span>
                    <span className="icon-float">
                        <MDBIcon
                            icon={"times"}
                            onClick={() => this.deleteFile(item, index)}
                        />
                    </span>
                </div>)}</span>
                <span className="errorMessage">{this.state.showError && 'Please upload file having size upto 4MB'}</span>
                <MDBRow>
                    <MDBCol md={9}></MDBCol>
                    <MDBCol md={3} className="saveCancelWrapper" >
                        <BasicButton disabled={this.state.showError ? true : false} text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Upload"}
                            onClick={this.handleSubmit}
                        />
                        <BasicButton
                            onClick={() => this.props.handleClose()}
                            variant="outlined"
                            text={"Cancel"}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
})