import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import TableComponent from '../SharedComponents/Table'
import * as Constants from '../../constants/constants';
import ClearTrackService from '../../services/service';
import { CircularProgress } from "@material-ui/core";
import {validateNumbersOnly} from '../../Common/Helper'
import {withUserContext} from '../../contexts/UserContext'
import { updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';

class ShowDurations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSubmit: false,
            showDurationsOptions:[],
            mandatoryNotExist:false,
            sortBy:null,
            isSortByAsc: true,
            sortCount: 0,
            config: JSON.parse(JSON.stringify({ ...this.props?.config }))
        }
    }

    componentDidMount() {
        this.getShowDurations("showDurationsOptions", "SHOW_DURATIONS", "");
    }

    getShowDurations = (entity, entitytype, searchString,loader =true) => {
        this.setState({ isLoading: loader });
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`,this.props?.userContext?.active_tenant?.tenant_id)
          .then((res) => {
            let configRecord = {...this.state.config}
            this.props?.permissionArray.edit === 0 ? configRecord.actions = [ ] : configRecord.actions = ["pen"]
            this.setState({ [entity]: res.data, isLoading: false, isSubmit:false , sortBy:null,
              isSortByAsc: true,config: configRecord,
              sortCount: 0,},() => { this.handleTableColumnClick("Start Year");});
          })
          .catch((err) => {
            this.setState({ isLoading: false });
        });
    };

    postShowDurationsDetails = (showDurationsDetails, postFlag) => {
        this.setState({ isSubmit: postFlag });
        ClearTrackService.postDataParams(
          Constants.ClearTrackServiceBaseUrl + `/showDurations`,
          showDurationsDetails,
          this.props?.userContext?.active_tenant?.tenant_id
        ).then(
          (response) => {
            if (response.data.error) {
              this.setState({ isSubmit: false });
                this.props?.toastMessageNotification(true, "fail");
                this.getShowDurations("showDurationsOptions", "SHOW_DURATIONS", "",);
            } else {
              //this.setState({
                //isSubmit: false,
              //});
              if (showDurationsDetails?.editing) {
                showDurationsDetails.editing = false;
              }
                this.props?.toastMessageNotification(true, "success");
                this.getShowDurations("showDurationsOptions", "SHOW_DURATIONS", "",false);
            }
          },
          (err) => {
            this.setState({ isSubmit: false });
            this.props?.toastMessageNotification(true, "fail");
            console.log("Post showDurations Details error: " + err);
          }
        );
    };

    handleInlineEdits = (id, dataItem) => {
        let showDurations = [...this.state.showDurationsOptions]
        this.setState({
            mandatoryNotExist:false,
            showDurationsOptions: showDurations.map((item) => {
              let newItem = {...item}
                if (item.show_duration_id == id)
                    newItem['editing'] = true;
                return newItem
            })
        })
    }

    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({mandatoryNotExist : false })
        if(data[index]?.show_duration && data[index]?.show_duration?.length > 0 && data[index]?.minutes &&
            (isNaN(data[index]?.minutes) ?  validateNumbersOnly(data[index]?.minutes) && data[index]?.minutes?.length > 0 : data[index]?.minutes)) {
            let showDurations = [...data];
            let showDurationsDetails = [...this.state.showDurationsOptions];
            showDurationsDetails[index].show_duration_id = showDurations[index]?.show_duration_id;
            showDurationsDetails[index].show_duration = showDurations[index]?.show_duration || null;
            showDurationsDetails[index].minutes = showDurations[index]?.minutes || 0;
            showDurationsDetails[index].is_active = showDurations[index]
            ?.is_active
            ? 1
            : 0;
            this.setState({ showDurationsOptions: showDurationsDetails });
            this.postShowDurationsDetails(showDurationsDetails[index],showDurationsDetails[index]?.show_duration_id);
        } else {
            this.setState({mandatoryNotExist : true })
        }
    };

    handleAddNewItem = (newDataItem) => {
    this.setState({mandatoryNotExist : false })
        if(newDataItem?.show_duration && newDataItem?.show_duration?.length > 0 && validateNumbersOnly(newDataItem?.minutes) && newDataItem?.minutes?.length > 0) {
        let newShowDurationData = {
            minutes: newDataItem?.minutes || 0,
            show_duration: newDataItem?.show_duration || null,
            is_active:  1,
        };
        // let showDurationsDetails = [...this.state.showDurationsOptions];
        // showDurationsDetails.push(newShowDurationData);
        this.postShowDurationsDetails(newShowDurationData,'newItemPost');
        //this.setState({ showDurationsOptions: showDurationsDetails });
        } else {
        this.setState({mandatoryNotExist : true })
        }
    };
    handleTableColumnClick = (column) => {
        if (column === this.state.sortBy) {
          this.setState({ sortCount: this.state.sortCount + 1 }, () => {
            this.handleTableSort(column);
          })
        }
        else this.setState({ sortCount: 0 }, () => {
          this.handleTableSort(column);
        })
    }
    handleTableSort = (colName) => {
        this.setState({
          isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
        }, () => {
          this.setState({
            sortBy: colName,
            config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
            showDurationsOptions: tableSortList("Name", this.getSortNode(colName), this.state.showDurationsOptions, this.state.isSortByAsc)
          });
        })
    }
    getSortNode = (col) => {
        switch (col) {
          case "Show Duration": return "show_duration";
          case "Minutes": return "minutes";
          case "Active": return "is_active";
        }
    }

    render() {
      let permissionValue = this.props?.permissionArray
        return (
            <MDBContainer fluid className="ShowDurationContainer">
                <MDBRow>
                    <span className="description">Define selectable durations for Show/Seasons.</span>
                </MDBRow>
                <MDBRow className='pd'>
                <span className="errorText">{this.state.mandatoryNotExist && 'Please enter Active, Show Duration and Minutes fields value'}</span>
                </MDBRow>
                {this.state.isLoading ? (
                    <div className="ContentLoader">
                    <CircularProgress />
                </div> 
                ) : (
                    <MDBRow className="ShowDurationTable">
                        <TableComponent
                            newIsActive={true}
                            list={this.state.showDurationsOptions || []}
                            config={this.state.config}
                            handleAddNewItem={this.handleAddNewItem.bind(this)}
                            editCallback={this.handleInlineEdits.bind(this)}
                            saveCallback={this.saveInlineEdits.bind(this)}
                            addItemToList={permissionValue?.edit === 1}
                            postInitiated={this.state.isSubmit}
                           // isLoading={this.state.isSubmit}
                            readCallback={(index) => {
                                let seasonOptions = [...this.state.showDurationsOptions];
                                seasonOptions[index].editing = false
                                this.setState({ showDurationsOptions: seasonOptions, mandatoryNotExist:false });
                            }}
                            stickyHeader={true}
                            sortCallback = {this.handleTableColumnClick}
                        />
                    </MDBRow>
                )
                }
            </MDBContainer>
        )
    }
}

export default withUserContext(ShowDurations);