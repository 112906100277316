export const licensorsFilter = {
    licensorName: null,
    prvdsOwnLic: null,
    activeInactive: null,
    relianceLtr: null,
    intLic: null,
    email: null,
    phone: null,
    address: null,
    street: null,
    city: null,
    state: null,
    country: null,
    zipcode: null,
    notes: null,
    
}

export const licensorRecordJson = {
    licensor_search_ids: null,
    is_provides_own_lic_flag: null,
    is_active_flag: null,
    reliance_ltr_rec_flag: null,
    int_lic_w_change_flag: null,
    email_search_text: null,
    phone_search_text: null,
    address_1_search_text:null,
    address_2_search_text:null,
    city_search_text:null,
    state_search_ids:null,
    country_search_ids:null,
    zip_search_text:null,
    notes_search_text:null,
    order_by_clause: "Licensor",
    order_by: null,
    page_count: 25,
    page_no: 1
}

export const filterConfig = {
    licensorName: {id: 'licensor_id', name: 'licensor_name'},
}

export const licensorsTblConfig = {
    headings: [{
        headingLabel: "Licensor",
        icon: "",
        width:"15%"
    },
    {
        headingLabel: "Address",
        icon: "",
        width:"15%"
    },
    {
        headingLabel: "Primary Contact",
        icon: "",
        width:"12%"
    },
    {
        headingLabel: "Primary Phone",
        icon: "",
        width:"12%"
    },
    {
        headingLabel: "Primary Email",
        icon: "",
        width:"18%"
    },
    {
        headingLabel: "Prvs. Own Lic.",
        icon: "",
        width:"10%"
    },
    {
        headingLabel: "Rel. Ltr. Rcvr.",
        icon: "",
        width:"10%"
    },
    {
        headingLabel: "Active",
        icon: "",
        width:"5%"
    },
    // {
    //     headingLabel: "",
    //     icon: "",
    //     width:"3%"
    // }
],
    dataNodes: ["licensor_name", "address", "primary_contact","primary_phone","primary_email","is_provides_own_license","is_reliance_letter_receiver","is_active"],
    actions: [],
    hyperlinks: ["licensor_name"],
    primaryKey: "licensor_id",
}

export const licensorsDataConfig = [
    {
        id: 1,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "X",
        relLtrRcvr: "X",
        status: "Submitted"
    },
    {
        id: 2,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "X",
        relLtrRcvr: "X",
        status: "[Status]"
    },
    {
        id: 3,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "",
        relLtrRcvr: "",
        status: "[Status]"
    },
    {
        id: 4,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "",
        relLtrRcvr: "",
        status: "[Status]"
    },
    {
        id: 5,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "X",
        relLtrRcvr: "X",
        status: "[Status]"
    },
    {
        id: 6,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "[X/blnk]",
        relLtrRcvr: "[X/blnk]",
        status: "[Status]"
    },
    {
        id: 7,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "[X/blnk]",
        relLtrRcvr: "[X/blnk]",
        status: "[Status]"
    },
    {
        id: 8,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "[X/blnk]",
        relLtrRcvr: "[X/blnk]",
        status: "[Status]"
    },
    {
        id: 9,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "[X/blnk]",
        relLtrRcvr: "[X/blnk]",
        status: "[Status]"
    },
    {
        id: 10,
        licensor: "[Licensor Name]",
        address: "[Address Lines][City][State][Zip Code][Country]",
        primaryContact: "[Primary Contact]",
        primaryPhone: "[Primary Co. Phone]",
        primaryEmail: "[Primary Co. Email]",
        prvsOwnLic: "[X/blnk]",
        relLtrRcvr: "[X/blnk]",
        status: "[Status]"
    }
]