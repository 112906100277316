import React from 'react';
import { MDBCol, MDBRow,} from 'mdbreact';
import { MDBCard, MDBCardBody, MDBCardText} from 'mdb-react-ui-kit';
import TableComponent from '../../SharedComponents/Table';
import { efcConfig, newEFCDetailObject } from './PeopleConfig';
import MessageModal from '../../SharedComponents/MessageModal';
import './People.scss';
import { validateEmail } from '../../../Common/Helper';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import { CircularProgress } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import clearTrackService from '../../../services/service';
import * as Constants from '../../../constants/constants';
import { withUserContext } from '../../../contexts/UserContext';

let CanEditEFCRecipients = false, dataToDelete;
class EFCRecipients extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            renderList: [],
            config: efcConfig,
            selectedValue: "SHOW",
            show_season_id: this.props?.show_season_id,
            season_id: this.props?.season_id,
            show_id: this.props?.show_id,
            isTableLoading: false,
            mandatoryNotExist: false,
            showDeleteModal: false
        };
    }
    handleRadioChange = (e) => {
        this.setState({ mandatoryNotExist: false });
        if (e.target.value === "SEASON") {
            this.setState({ selectedValue: "SEASON" })
            this.fetchData("efcRecipientsDetailsSeason", `/showSeasonEFCRecipient?show_season_id=NULL&season_division_id=${this.props?.division_id}`, this.props?.division_id);
        }
        if (e.target.value === "SHOW") {
            this.setState({ selectedValue: "SHOW" })
            this.fetchData("efcRecipientsDetailsShow", `/showSeasonEFCRecipient?show_season_id=${this.state.show_season_id}&season_division_id=NULL`, this.props.division_id);
        }
        this.setState({ selectedValue: e.target.value })
    }
    postUpdatedData = (dataToUpdate) => {
        this.setState({ isTableLoading: true });
        clearTrackService.postDataParams(
            Constants.ClearTrackServiceBaseUrl + `/showSeasonEFCRecipient`,
            dataToUpdate,
            this.props.userContext?.active_tenant?.tenant_id, this.props.division_id
        ).then(
            (response) => {
                if (response.data.error) {
                    this.setState({ isTableLoading: false, isSubmit: false });
                    if (dataToUpdate?.editing) {
                        dataToUpdate.editing = false;
                    }
                    //this.props?.fieldChanged(true, "fail");
                    if (this.state.selectedValue === "SEASON") {
                        this.fetchData("efcRecipientsDetailsSeason", `/showSeasonEFCRecipient?show_season_id=NULL&season_division_id=${this.props?.division_id}`, this.props?.division_id);
                    }
                    if (this.state.selectedValue === "SHOW") {
                        this.fetchData("efcRecipientsDetailsShow", `/showSeasonEFCRecipient?show_season_id=${this.state.show_season_id}&season_division_id=NULL`, this.props.division_id);
                    }
                    this.notificationComponent(true, "fail");
                } else {
                    this.setState({
                        isTableLoading: false, isSubmit: false
                    });
                    if (dataToUpdate?.editing) {
                        dataToUpdate.editing = false;
                    }
                    //this.props?.fieldChanged(true, "success");
                    if (this.state.selectedValue === "SEASON") {
                        this.fetchData("efcRecipientsDetailsSeason", `/showSeasonEFCRecipient?show_season_id=NULL&season_division_id=${this.props?.division_id}`, this.props?.division_id);
                    }
                    if (this.state.selectedValue === "SHOW") {
                        this.fetchData("efcRecipientsDetailsShow", `/showSeasonEFCRecipient?show_season_id=${this.state.show_season_id}&season_division_id=NULL`, this.props.division_id);
                    }
                    this.setState({ showDeleteModal: false });
                    this.notificationComponent(true, "success");
                }
            },
            (err) => {
                this.setState({ isTableLoading: false, isSubmit: false });
                if (dataToUpdate?.editing) {
                    dataToUpdate.editing = false;
                }
                if (this.state.selectedValue === "SEASON") {
                    this.fetchData("efcRecipientsDetailsSeason", `/showSeasonEFCRecipient?show_season_id=NULL&season_division_id=${this.props?.division_id}`, this.props?.division_id);
                }
                if (this.state.selectedValue === "SHOW") {
                    this.fetchData("efcRecipientsDetailsShow", `/showSeasonEFCRecipient?show_season_id=${this.state.show_season_id}&season_division_id=NULL`, this.props.division_id);
                }
                this.notificationComponent(true, "fail");
                console.log("Post efc details error: " + err);
            }
        );
    };

    handleAddNewItem = (newDataItem) => {
        this.setState({ mandatoryNotExist: false });
        if (this.state.selectedValue == "SEASON") {
            newEFCDetailObject["season_id"] = this.props.season_id;
            newEFCDetailObject["show_season_id"] = this.props.show_season_id;
            newEFCDetailObject["type"] = "SEASON";
            newEFCDetailObject["efc_recipient_name"] = newDataItem?.efc_recipient_name;
            newEFCDetailObject["efc_recipient_email"] = newDataItem?.efc_recipient_email;
            newEFCDetailObject["division_id"] = this.props.division_id;
        }
        if (this.state.selectedValue == "SHOW") {
            newEFCDetailObject["show_season_id"] = this.props.show_season_id;
            newEFCDetailObject["show_id"] = this.props.show_id;
            newEFCDetailObject["season_id"] = this.props.season_id;
            newEFCDetailObject["type"] = "SHOW";
            newEFCDetailObject["efc_recipient_name"] = newDataItem?.efc_recipient_name;
            newEFCDetailObject["efc_recipient_email"] = newDataItem?.efc_recipient_email;
            newEFCDetailObject["division_id"] = this.props.division_id;
        }
        if (newEFCDetailObject?.efc_recipient_name && newEFCDetailObject?.efc_recipient_name?.length > 0 && newEFCDetailObject?.efc_recipient_email && newEFCDetailObject?.efc_recipient_email.length > 0 && validateEmail(newEFCDetailObject?.efc_recipient_email)) {
            this.setState({ mandatoryNotExist: false });
            this.postUpdatedData(newEFCDetailObject);
        } else {
            this.setState({ mandatoryNotExist: true });
        }
    }
    handleInlineEdits = (id, dataItem) => {
        let allDetails = [...this.state.renderList]
        this.setState({
            mandatoryNotExist: false,
            renderList: allDetails.map((item) => {
                let newItem = {...item}
                if (item.id == id)
                    newItem['editing'] = true
                return newItem
            })
        })
    }
    handleReadCallback = (index) => {
        let allDetails = [...this.state.renderList]
        allDetails[index].editing = false
        this.setState({ renderList: allDetails, mandatoryNotExist: false })
    }
    deleteTableRow = () => {
        this.setState({ isSubmit: true, isTableLoading: true });
        let type = null, table_id = null, division_id = null, table_name = null;
        if (this.state.selectedValue == "SEASON") {
            type = "SEASON";
            table_name = "season_efc_recipients";
            table_id = dataToDelete.season_efc_recipient_id;
            division_id = null;
        }
        if (this.state.selectedValue == "SHOW") {
            type = "SHOW";
            table_name = "efc_recipients";
            table_id = dataToDelete.show_season_efc_recipient_id;
            division_id = this.props.division_id;
        }

        clearTrackService.getData(
            Constants.ClearTrackServiceBaseUrl + `/checkRelationalTables?tableName=${table_name}&tableId=${table_id}`,
            this.props?.userContext?.active_tenant?.tenant_id, division_id
        ).then(res => {
            if (res.data[0].status === "SUCCESS") {
                dataToDelete.is_delete = 1;
                dataToDelete.show_season_id = this.props?.show_season_id;
                dataToDelete.season_id = this.props?.season_id;
                dataToDelete.show_id = this.props?.show_id;
                dataToDelete.type = type;
                this.postUpdatedData(dataToDelete);
                this.setState({ mandatoryNotExist: false })
            } else {
                //this.props?.fieldChanged(true, 'fail')
                this.notificationComponent(true, "checkRelationalFailure");
                this.setState({ isSubmit: false, showDeleteModal: false, isTableLoading: false, mandatoryNotExist: false });
            }
        }),
            ((err) => {
                //this.props?.fieldChanged(true)
                this.notificationComponent(true, "fail");
                this.setState({ isSubmit: false, mandatoryNotExist: false, });
            });
    };
    saveInlineEdits = (data, index, pkValue, dataItem) => {
        this.setState({ mandatoryNotExist: false })
        let allDetails = [...this.state.renderList]
        let tempInlineSavedData = [...data];
        if (this.state.selectedValue == "SEASON") {
            allDetails[index].season_id = this.props.season_id;
            allDetails[index].show_season_id = this.props.show_season_id;
            allDetails[index].type = "SEASON"
            allDetails[index].efc_recipient_name = tempInlineSavedData[index]?.efc_recipient_name;
            allDetails[index].efc_recipient_email = tempInlineSavedData[index]?.efc_recipient_email;
            allDetails[index].division_id = null;
        }
        if (this.state.selectedValue == "SHOW") {
            allDetails[index].show_season_id = this.props.show_season_id;
            allDetails[index].show_id = this.props.show_id;
            allDetails[index].season_id = this.props.season_id;
            allDetails[index].type = "SHOW"
            allDetails[index].efc_recipient_name = tempInlineSavedData[index]?.efc_recipient_name;
            allDetails[index].efc_recipient_email = tempInlineSavedData[index]?.efc_recipient_email;
            allDetails[index].division_id = this.props.division_id;
        }
        if (allDetails[index]?.efc_recipient_name && allDetails[index]?.efc_recipient_name?.length > 0 && allDetails[index]?.efc_recipient_email && allDetails[index]?.efc_recipient_email.length > 0 && validateEmail(allDetails[index]?.efc_recipient_email)
        ) {
            this.setState({ mandatoryNotExist: false });
            this.postUpdatedData(allDetails[index]);
        } else {
            this.setState({ mandatoryNotExist: true })
        }

    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.state) {
            return true;
        }
        else { return false; }
    }

    fetchData = (field, url, division_id) => {
        this.setState({ isTableLoading: true })
        clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + url, this.props.userContext?.active_tenant?.tenant_id, division_id)
            .then(response => {
                this.setState({ isTableLoading: false })
                let formattedList = [], tempData;
                switch (field) {
                    case 'efcRecipientsDetailsSeason':
                        let efcRecipientsDetailsSeason = [];
                        response?.data?.length > 0 && response?.data?.map((item, index) => {
                            item["id"] = item.season_efc_recipient_id;
                            efcRecipientsDetailsSeason.push(item);
                        });
                        this.setState((prevState) => ({
                            renderList: efcRecipientsDetailsSeason,
                            config: efcConfig
                        }));
                        break;
                    case 'efcRecipientsDetailsShow':
                        let efcRecipientsDetailsShow = []
                        response?.data?.length > 0 && response?.data?.map((item, index) => {
                            item["id"] = item.efc_recipient_id;
                            efcRecipientsDetailsShow.push(item);
                        });
                        this.setState((prevState) => ({
                            renderList: efcRecipientsDetailsShow,
                            config: efcConfig
                        }));
                        break;
                }
            },
                (err) => {
                    this.setState({ isTableLoading: false })
                    console.log("Error in fetching Address Types:", err)
                })
    }
    componentDidMount() {
        this.getPermissions(this.props.permissionArr);    
        this.fetchData("efcRecipientsDetailsShow", `/showSeasonEFCRecipient?show_season_id=${this.state.show_season_id}&season_division_id=NULL`, this.props.division_id);
    }
    notificationComponent = (value, mode = "fail") => {
        switch (mode) {
            case "success": return this.setState({ showSuccess: value });
            case "fail": return this.setState({ showFail: value });
            case "checkRelationalFailure": return this.setState({ checkRelationalFailure: value });
        }
    }
    closPopup = () => {
        this.setState({ showError: false, showFail: false, showSuccess: false, checkRelationalFailure: false });
        // if (this.state.saveFlag) {

        // }
    }
    handleResetNotify = () => {
        this.setState({ showFail: false, showSuccess: false, checkRelationalFailure: false });
        if (this.state.saveFlag) {
            this.closPopup();
        }
    }
    getPermissions(permissions) {
        if (permissions.length > 0) {
            CanEditEFCRecipients = permissions.filter((obj, index) => obj.permission_name === "EFC Recipients" && obj.view === 1 && obj.edit === 1);
            CanEditEFCRecipients = CanEditEFCRecipients.length > 0 ? true : false;
            if (CanEditEFCRecipients === false) {
                let tempConfig = efcConfig;
                let actionsToRemove = ["pen", "times"];
                let tempActions = tempConfig.actions.filter(item => !actionsToRemove.includes(item));
                efcConfig.actions = tempActions;
                this.setState({ config: efcConfig })
            }
        }
    }
    openDeleteModal = (item) => {
        dataToDelete = { ...item };
        this.setState({ showDeleteModal: true })
    }
    closeDeleteModal = (mode) => {
        this.setState({ showDeleteModal: false })
    }
    render() {
        let severity = this.state.showFail ? "error" : this.state.showSuccess ? "success" : this.state.checkRelationalFailure ? "error" : "";
        let messageNotification = this.state.showSuccess ? "The changes are saved successfully!" : this.state.showFail ? "Saving changes failed!" : this.state.checkRelationalFailure ? "You can not delete this Record because it is being used" : "";
        return (
            <div className="PeopleTable-Container">
                <MDBCard className="card-border mt-1">
                    {this.state.isTableLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress size={24} />
                        </div>
                    ) : (<>
                        <MDBCardBody>
                            <NotificationComponent open={this.state.showSuccess || this.state.showFail || this.state.checkRelationalFailure} message={messageNotification} severity={severity}
                                handleResetNotify={this.handleResetNotify.bind(this)} />
                            <MDBCardText>
                                <MDBRow>
                                    <MDBCol md={2}>
                                        <Radio name="radio-buttons" checked={this.state.selectedValue === 'SEASON'}
                                            onChange={this.handleRadioChange}
                                            value="SEASON" />
                                        <span className='content-font'>Season</span>
                                    </MDBCol>
                                    <MDBCol md={2}>
                                        <Radio name="radio-buttons" checked={this.state.selectedValue === 'SHOW'}
                                            onChange={this.handleRadioChange}
                                            value="SHOW" />
                                        <span className='content-font'>Show only</span>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <span className='errorText' >{this.state.mandatoryNotExist && `Please enter name and email field's value`}</span>
                                    {this.state?.renderList && this.state?.config &&
                                        <div className="cueSheetTable" id="people-table-bg">
                                            <TableComponent list={this.state.renderList}
                                                className={"clearencRecord"}
                                                config={this.state.config}
                                                editCallback={CanEditEFCRecipients ? this.handleInlineEdits.bind(this) : false}
                                                saveCallback={CanEditEFCRecipients ? this.saveInlineEdits.bind(this) : false}
                                                addItemToList={CanEditEFCRecipients ? true : false}
                                                handleAddNewItem={CanEditEFCRecipients ? this.handleAddNewItem.bind(this) : false}
                                                handleDelCallBack={CanEditEFCRecipients ? ((id, item) =>
                                                    this.openDeleteModal(item)) : false
                                                }
                                                readCallback={CanEditEFCRecipients ? this.handleReadCallback.bind(this) : false}
                                            /></div>}
                                </MDBRow>
                            </MDBCardText>
                        </MDBCardBody>
                    </>)}
                </MDBCard>
                {/* delete Confirmation modal */}
                {this.state.showDeleteModal &&
                    <MessageModal
                        title={"Delete Recipient"}
                        open={this.state.showDeleteModal}
                        message={"Are You Sure To Delete?"}
                        primaryButtonText={this.state.isSubmit ? <CircularProgress color="inherit" size={20} /> : "Yes"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={this.deleteTableRow}
                        handleClose={this.closeDeleteModal} />
                }
            </div>
        )
    }
}
export default withUserContext(EFCRecipients);

