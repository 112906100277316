import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import './Tabs.scss';

class TabComponent extends React.Component {
    render() {
        return (
            <Box className='TabComponent' sx={{ width: '100%' }}>
                <Badge color="primary" className='badge'>
                    <Tabs
                        value={this.props.value}
                        onChange={this.props.onChange}
                        textColor={"primary"}
                        className="root"
                        indicatorColor="primary"
                        aria-label="primary tabs example"
                    >
                        {this.props?.tabList.map((tabItem, index) => (
                            <Tab key={index} value={tabItem.value} label={tabItem.label} />

                        ))}
                    </Tabs>
                </Badge>
            </Box>
        )
    }

}
export default TabComponent;
