import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import TabsComponent from '../../SharedComponents/Tabs/Tabs';
import { CueSheetAcknowledgementWarningList } from '../../../constants/TabList';
import Warnings from './Warnings';
import NotAcknowledged from './NotAcknowledged';
import Rejected from './Rejected';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';

class CueSheetAcknowledgementWarnings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            downLoadRow : null,
            openNotification:null,
            anyErrorMessage :null,
        };
    }

    handleTabChange = (newTabValue) => {
        this.setState({
            tabValue: newTabValue,
        });
    };

    getTabComponent = () => {
        switch (this.state.tabValue) {
            case 1: return <Warnings 
                            handleClose={this.props?.handleClose} 
                            downLoadRow={this.state.downLoadRow} 
                            setDownLoadRow={(val) => {this.setState({downLoadRow:val})}}
                            cueSheetDownload={this.cueSheetDownload.bind(this)}
                            notificationComponent={(value) => {this.setState({openNotification:value})}}  />
            case 2: return <Rejected 
                            handleClose={this.props?.handleClose} 
                            downLoadRow={this.state.downLoadRow} 
                            setDownLoadRow={(val) => {this.setState({downLoadRow:val})}} 
                            cueSheetDownload={this.cueSheetDownload.bind(this)}/>
            case 3 : return <NotAcknowledged    
                            handleClose={this.props?.handleClose} 
                            downLoadRow={this.state.downLoadRow} 
                            setDownLoadRow={(val) => {this.setState({downLoadRow:val})}}
                            cueSheetDownload={this.cueSheetDownload.bind(this)} />
        }
    };


    downloadUrl = (url) => {
        if (url) {
            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const link = document.createElement("a");
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
        }
        this.setState({downLoadRow:null})
    };

    cueSheetDownload = (dataItem, nodeItem, tenantId) => {
        if (nodeItem === "CS") {
          let searchPayload = {
            show_season_id: dataItem.show_season_id || null,
            show_season_episode_id: dataItem.show_season_episode_id || null,
            cue_sheet_header_id: dataItem.cue_sheet_header_id || null,
            is_box_upload: 1,
          };
          let encryptTedStr = btoa(JSON.stringify(searchPayload));
          let load = {
            payload: "exportPdf",
            reportName: "rptCueSheetsReport",
            fileName: "cueSheetsReport",
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1,
            tenantId: tenantId,
            divisionId: dataItem?.division_id,
            divisionName: this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== dataItem?.division_id)?.division_name
          };
          ClearTrackService.getDataWS(load).then(
            (webSocketConn) =>
              (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                  this.setState({ isPosting: false });
                  this.downloadUrl(response?.body);
                }
              })
          );
        } else {
          if (nodeItem === "Ack") {
            let json = {
              platform: "BOX",
              filename: `${dataItem?.file_name}_Ack`,
              divisionName: this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== dataItem?.division_id)?.division_name,
              divisionId:  dataItem?.division_id
            };
            ClearTrackService.getData(
              Constants.ClearTrackServiceBaseUrl + `/downloadFiles?input=${btoa(JSON.stringify(json))}`, tenantId,dataItem?.division_id, true).then(
              (response) => {
                if(response?.data?.error) {
                  this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
                } else {
                  this.downloadUrl(response.data.data[0].url);
               }
              },
              (err) => {
                this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
              }
            );
          } else if (nodeItem === "XML") {
            let json = {
              platform: "BOX",
              filename: `${dataItem?.file_name}`,
              divisionName: this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id=== dataItem?.division_id)?.division_name,
              divisionId:  dataItem?.division_id
            };
            ClearTrackService.getData(
              Constants.ClearTrackServiceBaseUrl +`/downloadFiles?input=${btoa(JSON.stringify(json))}`,tenantId,dataItem?.division_id,true).then(
              (response) => {
                if(response?.data?.error) {
                  this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
                } else {
                  this.downloadUrl(response.data.data[0].url);
                }
              },
              (err) => {
                this.setState({openNotification:'error', anyErrorMessage:'File download failed', downLoadRow:null})
              }
            );
          }
        }
      };

    render() {
        return (
            <div className="cueSheetAckGlobalModal">
                <NotificationComponent
                    open={this.state.openNotification?.length > 0 ? true : false}
                    message={this.state.anyErrorMessage ? this.state.anyErrorMessage  : this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                    severity={this.state.openNotification || ''}
                    handleResetNotify={() => this.setState({ openNotification: null ,anyErrorMessage : null})}
                />
                <div>
                    <MDBRow className="tab-content">
                        <MDBCol md={10}>
                            <TabsComponent
                                tabList={CueSheetAcknowledgementWarningList}
                                value={this.state.tabValue}
                                onChange={(e, v) => this.handleTabChange(v)}
                            />
                        </MDBCol>
                    </MDBRow>
                </div>
                {this.getTabComponent()}
            </div>
        )
    }
}

export default CueSheetAcknowledgementWarnings;
