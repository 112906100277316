import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import { withUserContext } from '../../../contexts/UserContext';
import { validateNumDotOnly } from '../../../Common/Helper'
import ClearTrackServices from "../../../../src/services/service";
import { CircularProgress } from "@material-ui/core";
import * as Constants from "../../../constants/constants";
import './ApplyPayIncrease.scss';

export default withUserContext(class ApplyPayIncrease extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scAndMSdetails: this.props.scAndMSdetails,
            isPosting: false,
        }
    }

    componentDidMount = () => {
        for (let i = 0; i < this.props.scAndMSdetails.length; i++) {
            if (this.props.scAndMSdetails[i]?.show_season_people_deal_id) {
                this.getDealDetail(this.props.scAndMSdetails[i]?.show_season_people_deal_id, this.props.scAndMSdetails[i]?.people_id)
            } else {
                continue
            }
        }
    }

    handleChange = (field, value, index) => {
        let scAndMSdetails = [...this.state.scAndMSdetails];
        scAndMSdetails[index][field] = value;
        this.setState({ scAndMSdetails: scAndMSdetails });
        this.props?.fieldChanged()
    };

    getDealDetail = (dealId, peopleId) => {
        this.setState({ isLoading: true });
        ClearTrackServices.getData(
            Constants.ClearTrackServiceBaseUrl +
            `/dealDetails?show_season_people_deal_id=${dealId}`, this.props?.userContext?.active_tenant?.tenant_id, this.props?.divisionId)
            .then((res) => {
                this.setState({ isLoading: false });
                let formattedList = res.data[0];
                let scAndMSdetails = [...this.state.scAndMSdetails]
                this.setState((prevState) => ({
                    scAndMSdetails: prevState.scAndMSdetails?.map(item => {
                        let newItem = { ...item }
                        if (item?.people_id === peopleId) {
                            newItem.fee_per_episode = formattedList?.fee_per_episode
                        }
                        return newItem
                    })
                }));
            })
            .catch((err) => {
                this.setState({ isLoading: false });
            });
    };

    handleSubmit = () => {
        if (this.state.scAndMSdetails?.every(item => item?.payIncrease ? validateNumDotOnly(item?.payIncrease) : true)) {
            let postJson = {
                'show_season_id': this.props?.showSeasonId,
                //'season_id' : this.props?.showSeasonId,
                'people_list': this.state.scAndMSdetails?.filter(item => item?.payIncrease)?.map(ele => {
                    let increasedItem = {
                        "people_id": ele?.people_id,
                        "pay_increase": ele?.payIncrease
                    }
                    return increasedItem
                })
            }
            this.setState({ isPosting: true });
            console.log("postJson", postJson);
            ClearTrackServices.postDataParams(Constants.ClearTrackServiceBaseUrl + `/payIncrease`, postJson,
                this.props.userContext?.active_tenant?.tenant_id, this.props?.divisionId
            ).then(
                (response) => {
                    if (response.data.error) {
                        this.setState({ isPosting: false });
                        this.props?.notificationComponent(true, "fail");
                    } else {
                        this.setState({ isPosting: false, });
                        this.props?.notificationComponent(true, "success");
                        this.props.getApplyPayIncrease(postJson.people_list);
                    }
                },
                (err) => {
                    this.setState({ isPosting: false });
                    this.props?.notificationComponent(true, "fail");
                }
            );
        }
    }

    render() {
        return (
            <MDBContainer fluid className="applypayincrease-container">
                {this.state.isLoading ? (
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress size={24} />
                    </div>
                ) : (<>
                    <div>
                        <p className="payIncreaseHeading">
                            This action will overwrite the fees for Show Composers and Music Supervisiors on the current season with the specified pay increases.
                    </p>
                    </div>
                    <div>
                        {console.log("this.state.scAndMSdetails", this.state.scAndMSdetails)}
                        {this.state.scAndMSdetails.map((obj, index) => (
                            <MDBRow id={"scAndMSdetails" + index}>
                                <BasicLabel className="fieldlabel" text={`Pay Increase for ${obj.full_name}  (${obj.people_type === "SHOW_COMPOSER" ? "Show Composer" : "Music Supervisor"}) (%)`} />
                                {obj?.show_season_people_deal_id ? (obj?.fee_per_episode && obj?.fee_per_episode > 0) ? <BasicTextField md={2}
                                    className="percentfield"
                                    fieldValid={obj.payIncrease ? !validateNumDotOnly(obj.payIncrease) : false}
                                    inValidInput={'Please enter valid value'}
                                    placeholder={"00.00"}
                                    value={obj.payIncrease}
                                    onChange={(e) => this.handleChange("payIncrease", e.target.value.length > 0 ? e.target.value : null, index)}
                                /> : <span className='errortext'>Please add fee per episode first in deals</span> : <span className='errortext'>Please create a deal first</span>}
                            </MDBRow>
                        )
                        )}
                        <MDBRow>
                            <MDBCol md={8}></MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    className="basic-btn"
                                    type="inline"
                                    variant="contained"
                                    disabled={this.state.scAndMSdetails?.every(item => !item?.payIncrease)}
                                    text={this.state.isPosting ? <CircularProgress color="inherit" size={20} /> : "Apply"}
                                    onClick={this.handleSubmit}
                                />
                            </MDBCol>
                            <MDBCol md={2}>
                                <BasicButton
                                    variant="outlined"
                                    type="inline"
                                    text={"Cancel"}
                                    onClick={() => this.props.handleClose()}
                                />
                            </MDBCol>
                        </MDBRow>
                    </div>
                </>)}
            </MDBContainer>
        );
    }
})
