export const budgetFilter = {
    show: null,
    season: null,
    showYr: null,
    episodeName: null,
    episode: null,
    description: null,
    union: null,
    piotOther: null,
    shootDate: null,
    recordingDate: null,
    location: null,
    gt1: null,
    performerCost: null,
    gt2: null,
    recordCost: null,
    gt3: null,
    sessionCost: null,

}

export const budgetRecordJson = {
    show_search_ids : null,
    season_search_ids: null,
    show_year_search_text: null,
    episode_title_search_text:null,
    episode_no_search_text: null,
    description_search_text:null,
    union_search_ids: null,
    pilot_search_ids:null,
    shoot_date_search_text:null,
    record_date_search_text:null,
    location_search_text:null,
    gt1_search_ids: null,
    performer_cost_search_text:null,
    gt2_search_ids: null,
    record_cost_search_text:null,
    gt3_search_ids: null,
    session_cost_search_text: null,
    order_by:null,
    order_by_clause: "Show",
    page_count: 25,
    page_no:1,
    order_by:"ASC"
}

export const filterConfig = {
    show: {id: 'show_id', name: 'show_name'},
    season: {id: 'season_id', name: 'season_name'},
}

export const budgetTblConfig = {
    headings: [{
        headingLabel: "Show",
        icon: "",
        width: "15%",
        dataNode:"show_name"
    },
    {
        headingLabel: "Season",
        icon: "",
        width: "10%",
        dataNode:"season_name"
    },
    {
        headingLabel: "Episode",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Tenant",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Pilot/Series",
        icon: "",
        width: "10%"
    },
    {
        headingLabel: "Unions",
        icon: "",
        width: "20%"
    },
    {
        headingLabel: "Versions",
        icon: "",
        width: "8%"
    },
    {
        headingLabel: "Budget Finalized",
        icon: "",
        width: "12%"
    },
    ],
    dataNodes: ["show_name", "season_name", "episode_no", "tenant_name", "type", "unions", "version", "is_final_budget"],
    actions: ["share"],
    primaryKey: "show_season_id",
    hyperlinks: ["season_name"],
    dashboardFilters:true,
    nodeFields: [
        {
            column: "is_final_budget",
            icon: "check"
        }
    ],
}

export const budgetDataConfig = [
    {
        id: 1,
        Show: "[Show]",
        Season: "[Season]",
        Tenant: "[Tenant]",
        PilotSeries: "[Pilot/Series]",
        Unions: "[Unions]",
        Versions: "[Versions]",
        BugetFinalized: "[BugetFinalized]"
    },
    {
        id: 2,
        Show: "[Show]",
        Season: "[Season]",
        Tenant: "[Tenant]",
        PilotSeries: "[Pilot/Series]",
        Unions: "[Unions]",
        Versions: "[Versions]",
        BugetFinalized: "[BugetFinalized]"
    },
    {
        id: 3,
        Show: "[Show]",
        Season: "[Season]",
        Tenant: "[Tenant]",
        PilotSeries: "[Pilot/Series]",
        Unions: "[Unions]",
        Versions: "[Versions]",
        BugetFinalized: "[BugetFinalized]"
    },
    {
        id: 4,
        Show: "[Show]",
        Season: "[Season]",
        Tenant: "[Tenant]",
        PilotSeries: "[Pilot/Series]",
        Unions: "[Unions]",
        Versions: "[Versions]",
        BugetFinalized: "[BugetFinalized]"
    },
    {
        id: 5,
        Show: "[Show]",
        Season: "[Season]",
        Tenant: "[Tenant]",
        PilotSeries: "[Pilot/Series]",
        Unions: "[Unions]",
        Versions: "[Versions]",
        BugetFinalized: "[BugetFinalized]"
    },
]